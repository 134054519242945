import PdfReport from "../ReusableComponents/PdfReport";
import ReportAnalysisContainer from "../ReusableComponents/PdfReport/ReportAnalysisContainer";
import { AnalysisComponentContentType } from "../ReusableComponents/AnalysisComponent/AnalysisComponent";
import { CompanyDriverDeclarationAnalysisResponse } from "../../models/api/admin/companyDeclarationAnalysis";
import declarationAnalysisContent from "./declarationAnalysisContent";

type DeclarationsPdfReportProps = {
    analysisData?: CompanyDriverDeclarationAnalysisResponse;
    companyName?: string;
    departmentName?: string;
};
export default function DeclarationsPdfReport({
    analysisData,
    companyName,
    departmentName,
}: DeclarationsPdfReportProps) {
    const analysisContent: AnalysisComponentContentType<string>[] =
        declarationAnalysisContent(analysisData, undefined);

    return (
        <PdfReport companyName={companyName} departmentName={departmentName}>
            <div className="flex flex-col">
                {analysisContent &&
                    analysisContent.map(({ title, content }) => {
                        return (
                            <div key={title}>
                                <ReportAnalysisContainer title={title}>
                                    {content}
                                </ReportAnalysisContainer>
                            </div>
                        );
                    })}
            </div>
        </PdfReport>
    );
}
