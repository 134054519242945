import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { CaretDown, CaretUp } from "phosphor-react";
import FormInputLabel from "./FormInputLabel";
import { FieldError } from "react-hook-form";
import FormInputMessage from "./FormInputError";

// Redecalare forwardRef (https://fettblog.eu/typescript-react-generic-forward-refs/)
declare module "react" {
    function forwardRef<T, P = Record<string, unknown>>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export type FormDropdownOption<TValue> = {
    label: string;
    value: TValue;
    disabled?: boolean;
};

export type FormDropdownProps<TValue> = {
    options: FormDropdownOption<TValue>[];
    label?: string;
    pleaseSelectText?: string;
    value: TValue | null;
    onChange: (value: TValue, option: FormDropdownOption<TValue>) => void;
    onBlur?: (label?: string) => void;
    error?: FieldError;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    tooltip?: string;
    buttonClassName?: string;
    optionsAboveButton?: boolean;
    noOptionsText?: string;
    isLoading?: boolean;
    helpText?: string;
};

// https://devtrium.com/posts/react-typescript-using-generics-in-react#generics-syntax-for-arrow-functions-in-jsx
const FormDropdown = <TValue,>(
    {
        label,
        pleaseSelectText = "Select an option",
        options,
        isLoading = false,
        value,
        onChange,
        onBlur,
        error,
        required,
        disabled,
        className,
        tooltip,
        buttonClassName,
        optionsAboveButton,
        noOptionsText = "No options to choose from...",
        helpText,
        ...inputProps
    }: FormDropdownProps<TValue>,
    ref: React.ForwardedRef<HTMLButtonElement>
) => {
    const valueIsNumber = typeof value === "number";
    const canSelectValue = value !== null || !valueIsNumber;

    const selected = canSelectValue
        ? options.find((option) => {
              return JSON.stringify(option.value) === JSON.stringify(value);
          })
        : undefined;

    if (value && !selected)
        throw Error(
            `Couldn't find option with value ${value} in the ${label} dropdown.`
        );

    const handleChange = (value: TValue) => {
        const option = options.find((_) => _.value === value);

        if (!option) throw new Error(`Expected option with value ${value}.`);
        onChange(value, option);
    };

    return (
        <div className={className}>
            {label && (
                <FormInputLabel
                    className="whitespace-nowrap"
                    required={required}
                    tooltipText={tooltip}
                >
                    {label}
                </FormInputLabel>
            )}
            <Listbox
                value={value}
                onChange={handleChange}
                disabled={disabled}
                {...inputProps}
            >
                {({ open }) => (
                    <div className="relative mt-1 ">
                        <Listbox.Button
                            className={clsx(
                                "flex h-14 w-full items-center rounded-sm border pl-4 pr-8 transition-background-color",
                                "hover:bg-white focus:border-S2D-light-green-80  focus:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-S2D-light-green-80",
                                `${
                                    buttonClassName
                                        ? buttonClassName
                                        : "border-black bg-S2D-dark-green-70.1"
                                }`,
                                open && "z-50 ring-2 ring-S2D-light-green-80",
                                error &&
                                    " border-S2D-error-40 pr-10 outline-none ring-S2D-error-40 focus:border-S2D-error-40 focus:ring-S2D-error-40",
                                open && error && "ring-0",
                                disabled && "cursor-not-allowed"
                            )}
                            ref={ref}
                        >
                            <span
                                className={clsx(
                                    "block truncate",
                                    !selected && "opacity-60",
                                    disabled && "opacity-40"
                                )}
                            >
                                {selected ? selected.label : pleaseSelectText}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                                {open ? (
                                    <CaretUp
                                        weight="fill"
                                        className={clsx(
                                            "text-primary-text h-5 w-5",
                                            disabled && "text-disabled-text"
                                        )}
                                        aria-hidden
                                    />
                                ) : (
                                    <CaretDown
                                        weight="fill"
                                        className={clsx(
                                            "text-primary-text h-5 w-5",
                                            disabled && "text-disabled-text"
                                        )}
                                        aria-hidden
                                    />
                                )}
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className={clsx(
                                    "absolute mt-1 max-h-60 w-full overflow-auto rounded-md drop-shadow-lg",
                                    "bg-white py-1 text-base",
                                    "z-[1000] focus:outline-none sm:text-sm",
                                    optionsAboveButton && "bottom-full"
                                )}
                            >
                                {!options.length && !isLoading && (
                                    <Listbox.Option
                                        key="no-options"
                                        className={clsx(
                                            "relative flex h-12 cursor-default select-none items-center px-4"
                                        )}
                                        value={null}
                                        disabled
                                    >
                                        <>
                                            <span
                                                className={clsx(
                                                    "block truncate font-normal text-S2D-neutral-40"
                                                )}
                                            >
                                                {noOptionsText}
                                            </span>
                                        </>
                                    </Listbox.Option>
                                )}
                                {isLoading && (
                                    <Listbox.Option
                                        key="is-loading"
                                        className={clsx(
                                            "relative flex h-12 cursor-default select-none items-center px-4"
                                        )}
                                        value={null}
                                        disabled
                                    >
                                        <>
                                            <span
                                                className={clsx(
                                                    "block truncate font-normal text-S2D-neutral-40"
                                                )}
                                            >
                                                ...loading
                                            </span>
                                        </>
                                    </Listbox.Option>
                                )}
                                {options.map((option, index) => (
                                    <Listbox.Option
                                        key={`${label}${index}`}
                                        className={({ active }) =>
                                            clsx(
                                                "relative flex h-12 cursor-default select-none items-center px-4",
                                                active &&
                                                    "bg-S2D-light-green-80",
                                                option.disabled && "opacity-40",
                                                !option.disabled &&
                                                    "hover:bg-S2D-light-green-80"
                                            )
                                        }
                                        value={option.value}
                                        onBlur={() =>
                                            onBlur && onBlur(selected?.label)
                                        }
                                        disabled={option.disabled}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span
                                                    className={clsx(
                                                        "block truncate",
                                                        selected
                                                            ? "font-medium"
                                                            : "font-normal"
                                                    )}
                                                >
                                                    {option.label}
                                                </span>
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                )}
            </Listbox>
            <FormInputMessage error={error} helpText={helpText} />
        </div>
    );
};

export default React.forwardRef(FormDropdown);
