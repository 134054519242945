import * as yup from "yup";
import { SchemaKeys } from "../../../../../models/shared/schemaKeysHelper";
import { AddDepartmentDto } from "../../../../../models/app/admin/department";

export type AddDepartmentFormValues = AddDepartmentDto;

export const AddDepartmentFormValidationSchema: SchemaKeys<AddDepartmentFormValues> =
    {
        departmentName: yup
            .string()
            .min(1, "Department name must be at least 1 character.")
            .max(100, "Department name must be less than 100 characters.")
            .required("Please enter the department name."),
        contactName: yup
            .string()
            .max(100, "Contact name must be less than 100 characters.")
            .notRequired(),
        costCentre: yup
            .string()
            .max(20, "Cost centre must be less than 20 characters.")
            .notRequired(),
        invoiceAddressId: yup
            .string()
            .nullable()
            .optional(),
        isInvoiceDefault: yup
        .boolean()
        .required("Please select if this is a default department."),
    };
