import * as yup from "yup";
import { CompanyContact } from "../../../../models/api/admin/company";
import { AddCompanyContactSchema } from "../../../EditCompanyForms/EditCompanyFormValues";

export const defaultFormValues: CompanyContact = {
    firstName: "",
    lastName: "",
    telephone: "",
    emailAddress: "",
};

export const validationSchema = yup.object().shape(AddCompanyContactSchema);
