import { useParams } from "react-router-dom";
import { appPaths } from "../Routes";
import { AnalysisChartType } from "../models/app/admin/analysisChart";

export type DriverCategory =
    | "drivers"
    | "professionalDrivers"
    | "greyfleet"
    | string;
type PageParams = {
    chartType?: AnalysisChartType;
};

export const driverPageLocation = (
    location: string
): DriverCategory | string | undefined => {
    const { chartType } = useParams<PageParams>();
    if (location.includes(appPaths.adminProfessionalDrivers)) {
        if (chartType) return `professionalDrivers/${chartType}`;
        return "professionalDrivers";
    }
    if (location === appPaths.adminDrivers || location.includes("drivers")) {
        if (chartType) return `drivers/${chartType}`;
        return "drivers";
    }
    if (location.includes(appPaths.adminGreyFleet)) {
        if (chartType) return `greyfleet/${chartType}`;
        return "greyfleet";
    }
    return;
};
