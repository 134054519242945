import { Alarm, CheckCircle, Warning } from "phosphor-react";
import { DvlaDriverAnalysisResponse } from "../../models/api/admin/dvlaAnalysis";
import StatisticSummaryBox from "../StatisticSummaryBox";
import { handlePlurals } from "../../utils/handlePlurals";
import {
    ManualChecksFilters,
    UkDvlaCheckErrorsFilters,
    UkDvlaChecksFilters,
} from "../../models/api/safe2DriveAdmin/dvlaChecks";

type TabProps<TabType, FilterType> = {
    onLinkClick?: (key: keyof FilterType) => void;
    data?: TabType;
    isLoading?: boolean;
};

const DvlaUkAnalysis: React.FC<
    TabProps<DvlaDriverAnalysisResponse["ukDvlaChecks"], UkDvlaChecksFilters>
> = ({ data, onLinkClick, isLoading }) => {
    return (
        <div className="flex w-full flex-col flex-wrap gap-4 md:flex-row">
            <StatisticSummaryBox
                icon={CheckCircle}
                label={`${handlePlurals(
                    "check",
                    data?.checksComplete
                )} completed`}
                statistic={data?.checksComplete}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksComplete") : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Alarm}
                label={`${handlePlurals(
                    "check",
                    data?.checksScheduled
                )} scheduled`}
                statistic={data?.checksScheduled}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksScheduled") : undefined
                }
                iconColor="bg-S2D-neutral-100"
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label={`${handlePlurals("check", data?.checksOverdue)} overdue`}
                statistic={data?.checksOverdue}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksOverdue") : undefined
                }
                iconColor="bg-S2D-error-40 text-S2D-neutral-100"
                isLoadingAction={isLoading}
            />
        </div>
    );
};

const DvlaUkErrorsAnalysis: React.FC<
    TabProps<
        DvlaDriverAnalysisResponse["ukDvlaErrorChecks"],
        UkDvlaCheckErrorsFilters
    >
> = ({ data, onLinkClick, isLoading }) => {
    return (
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
            <StatisticSummaryBox
                icon={Warning}
                label={`400 error (bad request)`}
                statistic={data?.error400Checks}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("error400") : undefined
                }
                iconColor="bg-S2D-error-40 text-S2D-neutral-100"
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label={`403 error (forbidden)`}
                statistic={data?.error403Checks}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("error403") : undefined
                }
                iconColor="bg-S2D-error-40 text-S2D-neutral-100"
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label="429 error (throttling error)"
                statistic={data?.error429Checks}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("error429") : undefined
                }
                iconColor="bg-S2D-error-40 text-S2D-neutral-100"
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label={`500 error (server error)`}
                statistic={data?.error500Checks}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("error500") : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label="503 error (service unavailable)"
                statistic={data?.error503Checks}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("error503") : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label="Other error"
                statistic={data?.errorOther}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("errorOther") : undefined
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
};

const DvlaUKManualChecksAnalysis: React.FC<
    TabProps<DvlaDriverAnalysisResponse["ukManualChecks"], ManualChecksFilters>
> = ({ data, onLinkClick, isLoading }) => {
    return (
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
            <StatisticSummaryBox
                icon={CheckCircle}
                label={`${handlePlurals(
                    "check",
                    data?.checksComplete
                )} completed`}
                statistic={data?.checksComplete}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksComplete") : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Alarm}
                label={`${handlePlurals(
                    "check",
                    data?.checksScheduled
                )} scheduled`}
                statistic={data?.checksScheduled}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksScheduled") : undefined
                }
                iconColor="bg-S2D-neutral-100"
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Alarm}
                label={`${handlePlurals("check", data?.checksPending)} pending`}
                statistic={data?.checksPending}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksPending") : undefined
                }
                iconColor="bg-S2D-warning-90"
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label={`${handlePlurals("check", data?.checksOverdue)} overdue`}
                statistic={data?.checksOverdue}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksOverdue") : undefined
                }
                iconColor="bg-S2D-error-40 text-S2D-neutral-100"
                isLoadingAction={isLoading}
            />
        </div>
    );
};

const DvlaNiManualChecksAnalysis: React.FC<
    TabProps<
        DvlaDriverAnalysisResponse["northernIrishChecks"],
        ManualChecksFilters
    >
> = ({ data, onLinkClick, isLoading }) => {
    return (
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
            <StatisticSummaryBox
                icon={CheckCircle}
                label={`${handlePlurals(
                    "check",
                    data?.checksComplete
                )} completed`}
                statistic={data?.checksComplete}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksComplete") : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Alarm}
                label={`${handlePlurals(
                    "check",
                    data?.checksScheduled
                )} scheduled`}
                statistic={data?.checksScheduled}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksScheduled") : undefined
                }
                iconColor="bg-S2D-neutral-100"
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Alarm}
                label={`${handlePlurals("check", data?.checksPending)} pending`}
                statistic={data?.checksPending}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksPending") : undefined
                }
                iconColor="bg-S2D-warning-90"
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label={`${handlePlurals("check", data?.checksOverdue)} overdue`}
                statistic={data?.checksOverdue}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksOverdue") : undefined
                }
                iconColor="bg-S2D-error-40 text-S2D-neutral-100"
                isLoadingAction={isLoading}
            />
        </div>
    );
};

const DvlaForeignManualChecksAnalysis: React.FC<
    TabProps<DvlaDriverAnalysisResponse["foreignChecks"], ManualChecksFilters>
> = ({ data, onLinkClick, isLoading }) => {
    return (
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
            <StatisticSummaryBox
                icon={CheckCircle}
                label={`${handlePlurals(
                    "check",
                    data?.checksComplete
                )} completed`}
                statistic={data?.checksComplete}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksComplete") : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Alarm}
                label={`${handlePlurals(
                    "check",
                    data?.checksScheduled
                )} scheduled`}
                statistic={data?.checksScheduled}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksScheduled") : undefined
                }
                iconColor="bg-S2D-neutral-100"
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Alarm}
                label={`${handlePlurals("check", data?.checksPending)} pending`}
                statistic={data?.checksPending}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksPending") : undefined
                }
                iconColor="bg-S2D-warning-90"
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label={`${handlePlurals("check", data?.checksOverdue)} overdue`}
                statistic={data?.checksOverdue}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("checksOverdue") : undefined
                }
                iconColor="bg-S2D-error-40 text-S2D-neutral-100"
                isLoadingAction={isLoading}
            />
        </div>
    );
};

export {
    DvlaUkAnalysis,
    DvlaUkErrorsAnalysis,
    DvlaUKManualChecksAnalysis,
    DvlaNiManualChecksAnalysis,
    DvlaForeignManualChecksAnalysis,
};
