import { type FormDropdownOption } from "../components/FormDropdown";
import { DriverTypes } from "../models/api/admin/driver";
import {
    CheckType,
    LicenceStatus,
    LicenceType,
} from "../models/shared/statuses";
import { GREAT_BRITAIN, NORTHERN_IRELAND } from "./countries";

export const licenceStatusOptions: FormDropdownOption<string>[] = (
    Object.keys(LicenceStatus) as Array<keyof typeof LicenceStatus>
).map((key) => ({
    label: key,
    value: key,
}));
export const licenceTypeOptions: FormDropdownOption<string>[] = (
    Object.keys(LicenceType) as Array<keyof typeof LicenceType>
).map((key) => ({
    label: key,
    value: key,
}));

export const checkTypeOptions: FormDropdownOption<string>[] = (
    Object.keys(CheckType) as Array<keyof typeof CheckType>
).map((key) => ({
    label: key,
    value: key,
}));

export const dvlaErrorCodes = [{ code: "401" }, { code: "402" }];
export const driverTypeOptions: FormDropdownOption<DriverTypes>[] = [
    {
        label: "None",
        value: "None",
    },
    {
        label: "Co car",
        value: "CoCar",
    },
    {
        label: "Grey fleet",
        value: "GreyFleet",
    },
    {
        label: "Partner",
        value: "Partner",
    },
    {
        label: "Hcv",
        value: "Hcv",
    },
    {
        label: "Pcv",
        value: "Pcv",
    },
];

export const entitlementsFormLicenceStatusOptions: FormDropdownOption<string>[] =
    [
        {
            label: "None",
            value: "None",
        },
        {
            label: "Full",
            value: "Full",
        },
        {
            label: "Provisional",
            value: "Provisional",
        },
    ];

export enum ForeignLicenceTypes {
    UnitedKingdom = "UnitedKingdom",
    NorthernIreland = "NorthernIreland",
    Foreign = "Foreign"
}

export const foreignLicenceOptions = [
    {
        label: `${GREAT_BRITAIN} licence`,
        value: ForeignLicenceTypes.UnitedKingdom,
    },
    {
        label: `${NORTHERN_IRELAND} licence`,
        value: ForeignLicenceTypes.NorthernIreland,
    },
    {
        label: "Foreign licence",
        value: ForeignLicenceTypes.Foreign,
    },
    {
        label: "Any",
        value: null,
    },
];

export const categoryCodeWarning =
    "This list contains all codes since pre-1985 and from 2013 onwards, code assignments may differ based on the year the licence was created.";

export const adminsCanViewPiiMessage = "Admins can view PII by default";

export const categoryCodes = [
    "A",
    "AM",
    "A1",
    "A2",
    "B",
    "B1",
    "BE",
    "C",
    "C1",
    "C1E",
    "D",
    "D1",
    "D1E",
    "DE",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
];
