import {
    type ApiGetFileTransfersResponse,
    type ApiFileSafeItem,
} from "../../models/api/admin/fileSafe";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";
import useAuth from "../../auth";
import { FileSafeClaimValues, UserRoles } from "../../auth/decodeToken";
import { isS2dUser } from "../../auth/userAccessHelpers";

export const useCreateFileTransfer = () =>
    useS2DApiFetcher<FormData, string, { dto: FormData; role: UserRoles }>(
        "POST",
        ({ dto, role }) => ({
            url: isS2dUser(role)
                ? "api/admin/filetransfers"
                : "api/filetransfers",
            body: dto,
            contentType: "multipart/form-data",
        }),
        [],
        true
    );

export const useDeleteFileTransfer = () =>
    useS2DApiFetcher<string, string, { transferId: string; role: UserRoles }>(
        "DELETE",
        ({ transferId, role }) => ({
            url: isS2dUser(role)
                ? `api/admin/filetransfers/${transferId}`
                : `api/filetransfers/${transferId}`,
        }),
        [],
        true
    );

export const useMarkFileTransferForDeletion = () =>
    useS2DApiFetcher<
        FormData,
        ApiFileSafeItem,
        { transferId: string; dto: FormData; role: UserRoles }
    >(
        "PUT",
        ({ transferId, dto, role }) => ({
            url: isS2dUser(role)
                ? `api/admin/filetransfers/${transferId}`
                : `api/filetransfers/${transferId}`,
            body: dto,
            contentType: "multipart/form-data",
        }),
        [],
        true
    );

export const useFileTransfer = () => {
    const [createNewTransfer, isCreatingNewTransfer] = useCreateFileTransfer();
    const [deleteTransfer, isDeletingTransfer] = useDeleteFileTransfer();
    const [markForDeletion, isMarkingForDeletion] =
        useMarkFileTransferForDeletion();

    return {
        createNewTransfer: async (dto: FormData, role: UserRoles) =>
            await createNewTransfer({ dto, role }),
        isCreatingNewTransfer,
        deleteTransfer: async (transferId: string, role: UserRoles) =>
            await deleteTransfer({ transferId, role }),
        isDeletingTransfer,
        markForDeletion: async (
            transferId: string,
            dto: FormData,
            role: UserRoles
        ) => await markForDeletion({ transferId, dto, role }),
        isMarkingForDeletion,
    };
};

export const useGetFileTransfers = (
    exchange: "Sent" | "Received",
    pageNumber: number,
    pageSize: number,
    companyIds?: string[],
    departmentIds?: string[],
    dateFrom?: Date | null,
    dateTo?: Date | null,
    search?: string
) => {
    const { decodedToken } = useAuth();
    const role = decodedToken?.role;

    const endpointBasePath = isS2dUser(role)
        ? "api/admin/filetransfers"
        : "api/filetransfers";
    const pathArray = [`${endpointBasePath}/${exchange}/list`];

    pathArray.push(`?PageNumber=${pageNumber}&PageSize=${pageSize}`);

    if (search) {
        pathArray.push(`&Search=${search}`);
    }
    if (companyIds) {
        pathArray.push(`&CompanyIds=${companyIds}`);
    }
    if (departmentIds) {
        pathArray.push(`&DepartmentIds=${departmentIds}`);
    }
    if (dateFrom) {
        pathArray.push(`&TransferDate.From=${dateFrom.toISOString()}`);
    }
    if (dateTo) {
        pathArray.push(`&TransferDate.To=${dateTo.toISOString()}`);
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiGetFileTransfersResponse>(pathArray.join(""));

    return {
        fileTransferList: data,
        fileTransferListError: error,
        isFileTransferListLoading: isLoading,
        refetchFileTransferList: refetch,
    };
};

const useGetTransferredFiles = () =>
    useS2DApiFetcher<null, ArrayBuffer, { transferId: string }>(
        "GET",
        ({ transferId }) => ({
            url: `api/filetransfers/${transferId}/files`,
        }),
        [],
        true
    );

const useGetSingleTransferredFile = () =>
    useS2DApiFetcher<null, ArrayBuffer, { transferId: string; fileId: string }>(
        "GET",
        ({ transferId, fileId }) => ({
            url: `api/filetransfers/${transferId}/files/${fileId}`,
        }),
        [],
        true
    );

export const useGetTransferDetails = (id?: string) => {
    const { decodedToken } = useAuth();
    const baseUrl = isS2dUser(decodedToken?.role)
        ? `api/admin/filetransfers/${id}`
        : `api/filetransfers/${id}`;
    const { data, error, isLoading, refetch } = useS2DApiSWR<ApiFileSafeItem>(
        id ? baseUrl : null
    );

    const [getTransferredFiles, isGettingTransferredFiles] =
        useGetTransferredFiles();
    const [getSingleTransferredFile, isGettingSingleTransferredFile] =
        useGetSingleTransferredFile();

    return {
        transferData: data,
        transferError: error,
        isLoadingTransfer: isLoading,
        getTransferredFiles: async (transferId: string) =>
            await getTransferredFiles({ transferId }),
        isGettingTransferredFiles,
        getSingleTransferredFile: async (transferId: string, fileId: string) =>
            await getSingleTransferredFile({ transferId, fileId }),
        isGettingSingleTransferredFile,
        refetchTransferDetails: refetch,
    };
};

export const useGetFileTransferNotifications = (
    isFileSafe: FileSafeClaimValues | undefined
) => {
    // Only call endpoint if user has file safe claim
    const { data, error, isLoading, refetch } = useS2DApiSWR<string>(
        isFileSafe ? "api/filetransfers/notifications/count" : null
    );

    return {
        notificationCount: data,
        notificationError: error,
        isNotificationLoading: isLoading,
        refetchNotificationCount: refetch,
    };
};
