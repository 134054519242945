import { SetStateAction } from "react";
import DialogBox from "../DialogBox";
import { type AddStaffMemberFormValues } from "./AddStaffMemberFormValues";
import AddStaffMemberForm from "./AddStaffMemberForm";
import { useStaff } from "../../api/safe2Drive/staff";
import { errorToast, successToast } from "../../toast";
import { AddStaffMemberDto } from "../../models/app/safe2DriveAdmin/staff";

type AddStaffMemberModalProps = {
    isOpen: boolean;
    setIsOpen: (value: SetStateAction<boolean>) => void;
    refetchStaff: () => void;
};

const AddStaffMemberModal: React.FC<AddStaffMemberModalProps> = ({
    isOpen,
    setIsOpen,
    refetchStaff,
}) => {
    const { addStaffMember, isAddingStaffMember } = useStaff();

    const handleSubmit = async (
        values: AddStaffMemberDto,
        callback?: () => void
    ) => {
        const response = await addStaffMember(values);

        if (!response.success) {
            const errorMessage =
                response.error.statusCode === 422
                    ? "A user account already exists for the given email address"
                    : "Failed to add staff member, please try again later";
            errorToast(errorMessage);
        } else {
            successToast("Successfuly added staff member.");
            setIsOpen(false);
            refetchStaff();
            callback && callback();
        }
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title="Add staff member"
            size="xl"
            closeButton
        >
            <AddStaffMemberForm
                onSubmit={handleSubmit}
                isLoading={isAddingStaffMember}
                onCancel={handleCancel}
            />
        </DialogBox>
    );
};

export default AddStaffMemberModal;
