import { useState } from "react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { type ApiCompanyResponse } from "../../../models/api/admin/company";
import { type ApiDepartmentItem } from "../../../models/api/admin/department";
import { DvlaCheckOrderBy } from "../../../models/api/safe2DriveAdmin/dvlaChecks";
import {
    useDownloadDvlaChecksCsv,
    useGetDvlaChecks,
} from "../../../api/safe2Drive/dvlaChecks";
import { downloadBlobAsFile } from "../../../utils/downloadBlobAsFile";
import useDebounceValue from "../../../hooks/useDebounceValue";
import { DvlaChecksFilterFormValues } from "../../../components/DvlaChecksTableFilters/useDvlaChecksFilterForm";
import { errorToast, successToast } from "../../../toast";
import { useDvlaChecksTableFiltersLocalStorage } from "../../../components/DvlaChecksAnalysis/useDvlaChecksTableFiltersLocalStorage";
import { SEARCH_BAR_DEBOUNCE_MS } from "../../../constants/filterConstants";

export const useDvlaChecks = () => {
    const [pageNumber, setPageNumber] = useLocalStorage<number>(
        "DvlaChecksPageNumber",
        1
    );
    const [pageSize, setPageSize] = useLocalStorage<number>(
        "DvlaChecksPageSize",
        15
    );
    const [orderBy, setOrderBy] = useLocalStorage<string>(
        "DvlaChecksOrderBy",
        DvlaCheckOrderBy.LastName
    );

    // Filters:
    const [selectedCompany, setSelectedCompany] =
        useState<ApiCompanyResponse | null>(null);
    const [selectedDepartment, setSelectedDepartment] =
        useState<ApiDepartmentItem | null>(null);
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const query = useDebounceValue(searchQuery, SEARCH_BAR_DEBOUNCE_MS);

    const {
        ukDvlaChecks,
        setUkDvlaChecks,
        ukDvlaCheckErrors,
        setUkDvlaCheckErrors,
        ukManualChecks,
        setUkManualChecks,
        northernIrishManualChecks,
        setNorthernIrishManualChecks,
        foreignManualChecks,
        setForeignManualChecks,
    } = useDvlaChecksTableFiltersLocalStorage();

    const [isCsvReportDownloading, setIsCsvReportDownloading] = useState(false);

    const {
        dvlaChecksData,
        dvlaChecksError,
        isLoadingDvlaChecks,
        refetchDvlaChecks,
    } = useGetDvlaChecks(pageNumber, pageSize, orderBy, {
        companyId: selectedCompany?.companyId ?? null,
        departmentId: selectedDepartment?.departmentId ?? null,
        search: query,
        ukDvlaChecks: ukDvlaChecks,
        ukDvlaCheckErrors: ukDvlaCheckErrors,
        ukManualChecks: ukManualChecks,
        northernIrishManualChecks: northernIrishManualChecks,
        foreignManualChecks: foreignManualChecks,
    });

    const handleCompanyChange = (company: ApiCompanyResponse | null) => {
        setSelectedCompany(company);
        setSelectedDepartment(null);
    };
    const handleDepartmentChange = (department: ApiDepartmentItem | null) => {
        setSelectedDepartment(department);
    };
    const handleReset = () => {
        setSelectedCompany(null);
        setSelectedDepartment(null);
    };

    // if part of the filter contains only null/ undefined fields, remove the whole thing
    const removeEmptyFilters = <
        ObjectType extends { [key: string]: boolean | null } | undefined
    >(
        obj: ObjectType
    ) => {
        if (!obj) return undefined;

        const hasValues = Object.values(obj).some((value) => !!value);
        return hasValues ? obj : undefined;
    };

    const handleFormSubmit = (value: DvlaChecksFilterFormValues) => {
        setUkDvlaChecks(removeEmptyFilters(value.ukDvlaChecks));
        setUkDvlaCheckErrors(removeEmptyFilters(value.ukDvlaCheckErrors));
        setUkManualChecks(removeEmptyFilters(value.ukManualChecks));
        setNorthernIrishManualChecks(
            removeEmptyFilters(value.northernIrishManualChecks)
        );
        setForeignManualChecks(removeEmptyFilters(value.foreignManualChecks));
    };

    const {
        getDvlaChecksReport,
    } = useDownloadDvlaChecksCsv();

    const handleDownloadCsvReport = async () => {
        setIsCsvReportDownloading(true);

        const fileNameArray = ["DvlaChecks.csv"];
        if (selectedDepartment)
            fileNameArray.unshift(selectedDepartment.departmentName);
        if (selectedCompany) fileNameArray.unshift(selectedCompany.companyName);
        const fileName = fileNameArray.join("-");
        try {
            const response = await getDvlaChecksReport({
                companyId: selectedCompany?.companyId ?? null,
                departmentId: selectedDepartment?.departmentId ?? null,
                search: query,
                ukDvlaChecks,
                ukDvlaCheckErrors,
                ukManualChecks,
                northernIrishManualChecks,
                foreignManualChecks,
            });
            if (response.success) {
                downloadBlobAsFile(response.content, fileName);
                successToast(`Successfully downloaded Dvla checks report`);
                setTimeout(() => setIsCsvReportDownloading(false), 1000);
            }
        } catch (error) {
            setTimeout(() => setIsCsvReportDownloading(false), 1000);
            errorToast(`Failed to download Dvla checks report`);
        }
    };

    return {
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
        orderBy,
        setOrderBy,
        searchQuery,
        setSearchQuery,
        selectedCompany,
        selectedDepartment,
        dvlaCheckFilters: {
            ukDvlaChecks,
            ukDvlaCheckErrors,
            ukManualChecks,
            northernIrishManualChecks,
            foreignManualChecks,
        },
        handleFormSubmit,
        dvlaChecksData,
        dvlaChecksError,
        isLoadingDvlaChecks,
        refetchDvlaChecks,
        handleCompanyChange,
        handleDepartmentChange,
        handleReset,
        isCsvReportDownloading,
        handleDownloadCsvReport,
    };
};
