import {
    Button as MuiButton,
    Menu,
    MenuItem,
    ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import ActionableStatusModal from "./ActionableStatusModal";
import { DriverValidationTypes } from "../../models/app/admin/company";
import { ApiDriverByIdResponse } from "../../models/api/admin/driver";

export type StatusAction = {
    dropdownItem: keyof typeof StatusActions;
    modalButtonAction?: (licenceCountryOrigin?: string) => void;
    modalHeading?: string;
    modalSubHeading?: string;
    modalButtonText?: string;
    hasEmailAddress?: boolean;
    declarationComplete?: boolean;
    isLoading?: boolean;
};

export const StatusActions = {
    sendNewEDeclaration: "Send E-Declaration request",
    resendEDeclaration: "Resend E-Declaration request",
    switchToPaperDeclaration: "Switch to paper declaration",
    switchToEDeclaration: "Switch to E-Declaration",
    sendNewPaperDeclaration: "Send paper declaration request",
    resendPaperDeclarationEmail: "Resend paper declaration email",
    uploadSignedDeclaration: "Upload signed declaration",
    markAsRequested: "Mark as requested",
    //Grey Fleet Actions
    greyFleetRemoveVehicle: "Remove vehicle from Grey Fleet",
    greyFleetRemoveInvite: "Remove Grey Fleet invitation",
    greyFleetInstantEmailReminder: "Send Instant email reminder",
    greyFleetMarkInsuranceAsVerified: "Mark Insurance as verified",
    greyFleetIncompleteEmailReminder: "Send Grey Fleet completion reminder",
    //Licence Status Actions
    sendInstantReminder: "Send instant reminder",
    // Licence Status Check Actions
    retryCheck: "Retry check",
    changeToManual: "Change to manual",
    changeToAutomatic: "Change to automatic",
    disableFurtherChecks: "Disable further checks",
    enableFurtherChecks: "Enable further checks",
    editCheckDate: "Edit check date",
    markAsChecked: "Mark as checked",
    markAsPending: "Mark as pending",
    markAsFailed: "Mark as failed",
};

export type ActionableStatusThemes =
    | "green"
    | "grey"
    | "red"
    | "yellow"
    | "alertRed"
    | "alertAmber"
    | "alertGreen";

type ActionableStatusProps = {
    title: string;
    theme: ActionableStatusThemes;
    actions?: StatusAction[];
    contentIcon?: JSX.Element;
    content?: JSX.Element;
    children?: React.ReactNode;
    infoTag?: JSX.Element;
    statusIndicators?: JSX.Element[];
    actionable: boolean;
    driverId?: string;
    hasEmailAddress?: boolean;
    declarationComplete?: boolean;
    licenceCountryOrigin?: string;
    driverData?: ApiDriverByIdResponse;
    validateByCompanyPreference?: DriverValidationTypes;
};

const ActionableStatus = ({
    title,
    theme = "green",
    actions,
    content,
    children,
    infoTag,
    statusIndicators,
    actionable,
    driverId,
    hasEmailAddress = true,
    declarationComplete = false,
    licenceCountryOrigin,
    driverData,
    validateByCompanyPreference,
}: ActionableStatusProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showActionableStatusModal, setShowActionableStatusModal] =
        useState(false);
    const [dialogAction, setDialogAction] = useState<StatusAction | null>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const actionHandler = (action: StatusAction) => {
        setDialogAction(action);
        setShowActionableStatusModal(true);
        handleClose();
    };

    // silly hack to update loading status once the dialog action has been set
    // i hate this as much as you do
    useEffect(() => {
        if (!actions) return;
        if (!dialogAction) return;

        const currentAction = actions?.find(
            (action) => action.dropdownItem === dialogAction?.dropdownItem
        );
        if (!currentAction) return;

        setDialogAction(currentAction);
    }, [JSON.stringify(actions)]);

    const noCapitalize = createTheme({
        typography: {
            button: {
                textTransform: "none",
            },
        },
    });

    return (
        <>
            <div
                className={clsx(
                    "rounded",
                    theme === "green" &&
                        "bg-S2D-success-80 text-S2D-neutral-10",
                    theme === "grey" &&
                        "bg-S2D-neutral-30 text-S2D-neutral-100",
                    theme === "red" && "bg-S2D-error-40 text-S2D-neutral-99",
                    theme === "yellow" &&
                        "bg-S2D-warning-90 text-S2D-neutral-10",
                    theme === "alertRed" &&
                        "border-2 border-S2D-error-40 bg-S2D-error-90 text-S2D-neutral-10",
                    theme === "alertAmber" &&
                        "border-2 border-S2D-warning-60 bg-S2D-warning-95 text-S2D-neutral-10",
                    theme === "alertGreen" &&
                        "border-2 border-S2D-success-80 bg-S2D-success-80 text-S2D-neutral-10"
                )}
            >
                <div className="flex w-full flex-col gap-4 p-4">
                    <div className="flex items-center justify-between">
                        <span className="text-sm font-semibold">{title}</span>
                        {infoTag}
                    </div>
                    <div className="flex h-16 items-center">
                        {content && <div className="w-full">{content}</div>}
                        {children && <div className="w-full">{children}</div>}
                    </div>
                    <div className="flex flex-row gap-2">
                        {statusIndicators &&
                            statusIndicators.map((indicator, i) => (
                                <div
                                    key={i}
                                    className={`flex w-full basis-${
                                        1 / statusIndicators.length
                                    }`}
                                >
                                    {indicator}
                                </div>
                            ))}
                    </div>
                </div>
                {actionable && (
                    <>
                        <div className="border border-t-0 border-S2D-neutral-60" />
                        <div className="flex justify-end py-1">
                            <ThemeProvider theme={noCapitalize}>
                                <MuiButton
                                    id="basic-button"
                                    aria-controls={
                                        open ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleClick}
                                    style={{
                                        margin: "6px 8px",
                                        padding: 0,
                                    }}
                                >
                                    <div
                                        className={clsx(
                                            "text mr-4 rounded border-2 bg-transparent font-semibold",
                                            theme === "green" ||
                                                theme === "alertRed" ||
                                                theme === "yellow" ||
                                                theme === "alertAmber" ||
                                                theme === "alertGreen"
                                                ? "border-S2D-neutral-10 text-S2D-neutral-10"
                                                : "border-S2D-neutral-100 text-S2D-neutral-100 hover:text-S2D-neutral-10",
                                            "hover:bg-S2D-neutral-95",
                                            "text-black focus:outline-none focus:ring-2 focus:ring-black",
                                            "base-height flex items-center rounded-sm px-6 font-semibold transition-all"
                                        )}
                                    >
                                        Actions
                                    </div>
                                </MuiButton>
                            </ThemeProvider>
                        </div>
                    </>
                )}
            </div>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    disablePadding: true,
                }}
                disableScrollLock={true}
            >
                {actions?.map((action, i) => (
                    <MenuItem
                        key={i}
                        onClick={() => {
                            setShowActionableStatusModal(true);
                            actionHandler(action);
                        }}
                        sx={{
                            "&:hover": { backgroundColor: "var(--secondary)" },
                            height: 48,
                        }}
                    >
                        <div className="flex flex-row items-center">
                            <div className="pl-3">
                                {StatusActions[action.dropdownItem]}
                            </div>
                        </div>
                    </MenuItem>
                ))}
            </Menu>

            {dialogAction && (
                <ActionableStatusModal
                    action={dialogAction}
                    isOpen={showActionableStatusModal}
                    onClose={() => setShowActionableStatusModal(false)}
                    hasEmailAddress={hasEmailAddress}
                    declarationComplete={declarationComplete}
                    driverId={driverId}
                    licenceCountryOrigin={licenceCountryOrigin}
                    driverData={driverData}
                    validateByCompanyPreference={validateByCompanyPreference}
                />
            )}
        </>
    );
};

export default ActionableStatus;
