import { GroupBase, MenuProps } from "react-select";

const FormSearchDropdownMenu = ({
    children,
    innerProps,
    innerRef,
}: MenuProps<unknown, boolean, GroupBase<unknown>>) => (
    <div
        ref={innerRef}
        className="drop-shadow-lg rounded-b-lg absolute mt-1 w-full bg-white py-1 text-base focus:outline-none sm:text-sm z-40 border-t"
        {...innerProps}
    >
        {children}
    </div>
);

export default FormSearchDropdownMenu;
