import { type ApiCompanyResponse } from "../../models/api/admin/company";
import { type ApiDepartmentItem } from "../../models/api/admin/department";
import ReportingBox from "./ReportingBox";
import { premadeReports } from "./premadeReports";
import { useSpreadsheetReportDownload } from "../../pages/Admin/pageHooks/useSpreadsheetReportDownload";

type ReportingOptionsProps = {
    selectedCompany: ApiCompanyResponse | null;
    selectedDepartment: ApiDepartmentItem | null;
};

const ReportingOptions: React.FC<ReportingOptionsProps> = ({
    selectedCompany,
    selectedDepartment,
}) => {
    const { handleDownloadReport, isReportDownloading } = useSpreadsheetReportDownload(
        selectedCompany,
        selectedDepartment
    );

    const handleDeleteReport = (id: string) => {
        alert(`delete report ${id}`);
    };

    return (
        <div className="flex flex-col gap-6">
            {premadeReports.map((report) => (
                <div key={report.id}>
                    <ReportingBox
                        reportingBoxOption={report}
                        handleDownloadReport={handleDownloadReport}
                        isReportDownloading={
                            isReportDownloading 
                        }
                        handleDeleteReport={handleDeleteReport}
                        isDeletingReport={false}
                    />
                </div>
            ))}
            {/* then map over custom reports */}
        </div>
    );
};

export default ReportingOptions;
