import { CheckCircle, WarningCircle } from "phosphor-react";
import { DriverAddressInformation } from "../../../models/api/admin/driverDetails";
import { TableCellData, TableHeader } from "../Table";

export type DriverAddressTableContentAllowedIds = "postcode" | "addressMatch";

export const driverAddressTableHeaders: TableHeader<DriverAddressTableContentAllowedIds>[] =
    [
        {
            title: "Postcode",
            id: "postcode",
            hide: true,
            orderBy: "AddressPostcode",
            menuGroup: "Address",
        },
        {
            title: "Address match",
            id: "addressMatch",
            hide: true,
            menuGroup: "Address",
        },
    ];

export const driverAddressTableContent = (
    address: DriverAddressInformation
): TableCellData<DriverAddressTableContentAllowedIds>[] => [
    {
        id: "postcode",
        content: <p>{address.postCode ?? "-"}</p>,
    },
    {
        id: "addressMatch",
        content: (
            <>
                {!address.addressMatch && address.addressMatch != null ? (
                    <div className="flex flex-row items-start gap-2">
                        <WarningCircle
                            className="text-S2D-error-40"
                            weight="bold"
                            size={20}
                        />
                        <p>Address mismatch</p>
                    </div>
                ) : (
                    <div className="flex flex-row items-start gap-2">
                        <CheckCircle
                            className="text-S2D-success-80 brightness-90"
                            weight="bold"
                            size={20}
                        />
                        <p>Address match</p>
                    </div>
                )}
            </>
        ),
    },
];
