import { Disclosure } from "@headlessui/react";
import { CaretDown, CaretUp } from "phosphor-react";
import { ReactNode } from "react";
import clsx from "clsx";

type AccordionItemProps = {
    title: string;
    children: ReactNode;
    error?: boolean;
    defaultOpen?: boolean;
    modalStyling?: boolean;
    valueSummary?: string[];
};

const AccordionItem: React.FC<AccordionItemProps> = ({
    title,
    children,
    error,
    defaultOpen,
    modalStyling,
    valueSummary,
}) => {
    const backgroundColour = modalStyling ? "bg-white" : "bg-S2D-neutral-30";
    return (
        <div className={clsx(!modalStyling && "mb-3")}>
            <Disclosure defaultOpen={defaultOpen}>
                {({ open }) => (
                    <>
                        <Disclosure.Button
                            className={clsx(
                                "min-h-16 flex w-full flex-col px-6 py-4 text-S2D-text-menu",
                                !error ? backgroundColour : "bg-S2D-error-30",
                                modalStyling &&
                                    "border-t border-S2D-neutral-80 text-S2D-neutral-10",
                                modalStyling && open && "border-b"
                            )}
                        >
                            <div className="flex  w-full flex-row items-center justify-between ">
                                <h2
                                    className={clsx(
                                        "text-2xl",
                                        modalStyling && "text-xl"
                                    )}
                                >
                                    {title}
                                </h2>
                                <div className="ml-auto">
                                    {open ? (
                                        <CaretUp weight="fill" />
                                    ) : (
                                        <CaretDown weight="fill" />
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-wrap text-S2D-neutral-40">
                                {valueSummary?.join(", ")}
                            </div>
                        </Disclosure.Button>
                        <Disclosure.Panel
                            className={clsx(
                                "bg-white p-6",
                                !modalStyling &&
                                    "border border-t-0 border-S2D-neutral-80"
                            )}
                        >
                            {children}
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );
};

export default AccordionItem;
