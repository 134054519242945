import { Tab } from "@headlessui/react";
import { Plus, UserCircle, UserCircleGear } from "phosphor-react";
import { useState } from "react";
import CompanyUsersTable from "./CompanyUsersTable";
import CompanyUserPermissions from "./CompanyUserPermissions";
import useLocalStorage from "../../../hooks/useLocalStorage";
import Divider from "../../ReusableComponents/Divider";
import Button from "../../Button";
import AddCompanyUserModal from "../../AddCompanyUserModal";
import TabWithIcon from "../../ReusableComponents/HeadlessUiTabWithIcon/TabWithIcon";
import { useGetCompanyUsers } from "../../../api/admin/companyUser";
import useAuth from "../../../auth";
import { hasClientManagementClaim } from "../../../auth/userAccessHelpers";

type CompanyUsersTabProps = {
    companyId?: string;
    companyName?: string;
};

const CompanyUsersTab: React.FC<CompanyUsersTabProps> = ({
    companyId,
    companyName,
}) => {
    const [pageNumber, setPageNumber] = useLocalStorage<number>(
        "usersTablePageNumber",
        1
    );
    const [pageSize, setPageSize] = useLocalStorage<number>(
        "usersTablePageSize",
        25
    );
    const {
        companyUsersData,
        companyUsersError,
        isLoadingCompanyUsers,
        refetchCompanyUsers,
    } = useGetCompanyUsers(pageNumber, pageSize, companyId);

    const [addUserOpen, setAddUserOpen] = useState(false);

    const { decodedToken } = useAuth();
    const canEdit = hasClientManagementClaim(decodedToken);

    return (
        // 29/10/2023 remove tabs for v1.0 release
        // to get them back just remove the stuff at the bottom and uncomment everything else
        <div className="py-4">
            {/* <Tab.Group>
                <Tab.List className="mb-4 flex min-w-fit flex-row divide-x-2 divide-S2D-neutral-10 rounded-sm border-2 border-S2D-neutral-10 font-semibold">
                    <TabWithIcon
                        icon={<UserCircle size={24} weight="fill" />}
                        title={` Users(${companyUsersData?.items.length})`}
                    />
                    <TabWithIcon
                        icon={<UserCircleGear size={24} weight="fill" />}
                        title="Permissions"
                    />
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <div className="flex flex-col gap-2 py-2">
                            <Divider text="Users" />
                            <div className="flex justify-end">
                                <Button
                                    variant="primary"
                                    icon={Plus}
                                    onClick={() => setAddUserOpen(true)}
                                >
                                    Add User
                                </Button>
                            </div>
                            <AddCompanyUserModal
                                isOpen={addUserOpen}
                                setIsOpen={setAddUserOpen}
                                companyId={companyId}
                                companyName={companyName}
                                refetchCompanyUsers={refetchCompanyUsers}
                            />
                            <CompanyUsersTable
                                usersData={companyUsersData}
                                usersError={companyUsersError}
                                isLoadingUsers={isLoadingCompanyUsers}
                                refetchCompanyUsers={refetchCompanyUsers}
                                pageNumber={pageNumber}
                                setPageNumber={setPageNumber}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                companyName={companyName}
                                companyId={companyId}
                            />
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <CompanyUserPermissions />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group> */}
            <div className="flex flex-col gap-2 py-2">
                {canEdit && (
                    <>
                        <div className="flex justify-end">
                            <Button
                                variant="primary"
                                icon={Plus}
                                onClick={() => setAddUserOpen(true)}
                            >
                                Add User
                            </Button>
                            <AddCompanyUserModal
                                isOpen={addUserOpen}
                                setIsOpen={setAddUserOpen}
                                companyId={companyId}
                                companyName={companyName}
                                refetchCompanyUsers={refetchCompanyUsers}
                            />
                        </div>
                    </>
                )}
                <CompanyUsersTable
                    usersData={companyUsersData}
                    usersError={companyUsersError}
                    isLoadingUsers={isLoadingCompanyUsers}
                    refetchCompanyUsers={refetchCompanyUsers}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    companyName={companyName}
                    companyId={companyId}
                />
            </div>
        </div>
    );
};

export default CompanyUsersTab;
