import React, { DragEventHandler } from "react";
import clsx from "clsx";
import FormInputMessage from "./FormInputError";
import FileIcon from "./ReusableComponents/FileIcon";

export type FormFileUploaderProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "type" | "className"
> & {
    label?: string;
    required?: boolean;
    disabled?: boolean;
    onAutoAdd?: (data: File[]) => void;
    isDirty: boolean;
    error?: string;
    file?: File[];
    maxFileSizeKb?: number;
    onDrag: DragEventHandler<HTMLDivElement>;
    onDrop: DragEventHandler<HTMLDivElement>;
    dragActive: boolean;
    onReset: () => void;
};

const FormFileUploader = React.forwardRef<
    HTMLInputElement,
    FormFileUploaderProps
>(
    (
        {
            label,
            required,
            disabled,
            onAutoAdd,
            isDirty,
            error,
            file,
            maxFileSizeKb = 1000,
            onDrag,
            onDrop,
            dragActive,
            onReset,
            ...inputProps
        },
        ref
    ) => {
        return (
            <div
                className={clsx(
                    "flex justify-center rounded-lg border-2 border-dashed border-black p-4",
                    dragActive && "bg-S2D-neutral-90",
                    disabled && "opacity-40"
                )}
                onDragEnter={onDrag}
            >
                {isDirty ? (
                    <div className="flex flex-col items-center gap-4">
                        {file && (
                            <FileIcon
                                onReset={onReset}
                                fileName={file[0].name}
                            />
                        )}
                        <div className="flex justify-center gap-4">
                            <>
                                <input
                                    id="changeFile"
                                    type="file"
                                    ref={ref}
                                    aria-required={true}
                                    className="hidden"
                                    disabled={disabled}
                                    {...inputProps}
                                />
                                <label
                                    htmlFor="changeFile"
                                    role="button"
                                    className="shrink-0 rounded-sm py-2 px-4 font-bold hover:cursor-pointer hover:bg-S2D-surface-light-4"
                                >
                                    Change file
                                </label>
                            </>
                            {onAutoAdd && file && (
                                <button
                                    className={clsx(
                                        "text-primary-text max-w-fit  py-2 px-4 font-bold",
                                        "whitespace-nowrap rounded-sm border-2 border-black focus:outline",
                                        "hover:bg-S2D-surface-light-4",
                                        disabled &&
                                            `cursor-${
                                                disabled
                                                    ? "not-allowed"
                                                    : "pointer"
                                            }`
                                    )}
                                    onClick={() => onAutoAdd(file)}
                                    disabled={disabled}
                                >
                                    Add file
                                </button>
                            )}
                        </div>
                        {error && (
                            <div className="pl-3">
                                <FormInputMessage error={error} />
                            </div>
                        )}
                        {dragActive && (
                            <div
                                className="absolute inset-0 rounded-lg"
                                onDragEnter={onDrag}
                                onDragLeave={onDrag}
                                onDragOver={onDrag}
                                onDrop={onDrop}
                            />
                        )}
                    </div>
                ) : (
                    <div className="flex flex-col items-center gap-4">
                        <p className="text-xs">{label}</p>
                        <>
                            <input
                                id="file"
                                type="file"
                                ref={ref}
                                aria-required={true}
                                className="hidden"
                                disabled={disabled}
                                {...inputProps}
                                required={required}
                            />
                            <label
                                htmlFor="file"
                                role="button"
                                className={clsx(
                                    "text-primary-text max-w-fit cursor-pointer py-2 px-4 font-bold",
                                    "whitespace-nowrap rounded-sm border-2 border-black focus:outline"
                                )}
                            >
                                Select file
                            </label>
                        </>
                        <p className="text-xs">Size limit {maxFileSizeKb}Kb</p>
                        {dragActive && (
                            <div
                                className="absolute inset-0 rounded-lg"
                                onDragEnter={onDrag}
                                onDragLeave={onDrag}
                                onDragOver={onDrag}
                                onDrop={onDrop}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
);

export default FormFileUploader;
