import { createContext, SetStateAction } from "react";
import { S2DApiFetcherResponse } from "../api/common/apiResponse";
import { LoginApiResponse } from "../models/api/login";
import { ImpersonateUserDto, LogIn2FADto, LogInDto } from "../models/app/logIn";
import { SimplifiedDecodedToken } from "./decodeToken";
import { ApiCompanyResponse } from "../models/api/admin/company";
import { ApiDepartmentItem } from "../models/api/admin/department";

export type StoredAuthorization = {
    token?: string;
    decodedToken?: SimplifiedDecodedToken;
    userDetails?: UserDetails;
    twofaEnabled: boolean;
};

export interface AuthContext {
    token?: string;
    decodedToken?: SimplifiedDecodedToken;
    setToken(token: string): void;
    userDetails?: UserDetails;
    setUserDetails: (value: SetStateAction<UserDetails | undefined>) => void;
    twofaEnabled: boolean;
    setTwofaEnabled(twofaEnabled: boolean): void;
    expires?: string;
    authorize: (
        dto: LogInDto,
        callback: () => void
    ) => Promise<S2DApiFetcherResponse<LoginApiResponse>>;
    isAuthorizing: boolean;
    authorize2FA: (
        dto: LogIn2FADto
    ) => Promise<S2DApiFetcherResponse<LoginApiResponse>>;
    isAuthorizing2FA: boolean;
    storedAuthorization: StoredAuthorization | null;
    setStoredAuthorization: (value: StoredAuthorization | null) => void;
    authorizeImpersonatedUser: (
        dto: ImpersonateUserDto,
        callback: () => void
    ) => void;
    isAuthorizingImpersonatedUser: boolean;
    restoreAuthUser: (callback: () => void) => void;
    tempImpersonatedSelectedCompany?: ApiCompanyResponse | null;
    setTempImpersonatedSelectedCompany: (company: ApiCompanyResponse | null) => void;
    tempImpersonatedSelectedDepartment?: ApiDepartmentItem | null;
    setTempImpersonatedSelectedDepartment: (department: ApiDepartmentItem | null) => void;
    logOut: () => void;
}

export type UserDetails = {
    userId: string;
    userName: string;
    companyId: string;
    firstName?: string;
    lastName?: string;
};

const placeholderFunc = () => {
    throw new Error("Not implemented.");
};

const defaultValue: AuthContext = {
    setToken: placeholderFunc,
    decodedToken: undefined,
    userDetails: undefined,
    setUserDetails: placeholderFunc,
    expires: undefined,
    twofaEnabled: false,
    setTwofaEnabled: placeholderFunc,
    authorize: placeholderFunc,
    isAuthorizing: false,
    authorize2FA: placeholderFunc,
    isAuthorizing2FA: false,
    storedAuthorization: null,
    setStoredAuthorization: placeholderFunc,
    authorizeImpersonatedUser: placeholderFunc,
    isAuthorizingImpersonatedUser: false,
    restoreAuthUser: placeholderFunc,
    tempImpersonatedSelectedCompany: null,
    setTempImpersonatedSelectedCompany: placeholderFunc,
    tempImpersonatedSelectedDepartment: null,
    setTempImpersonatedSelectedDepartment: placeholderFunc,
    logOut: placeholderFunc,
};

export const AuthContext = createContext<AuthContext>(defaultValue);
