import { Dispatch, SetStateAction } from "react";
import FormTextInput from "../FormTextInput";
import FilterDropdown from "../ReusableComponents/FilterDropdown";
import { MagnifyingGlass } from "phosphor-react";
import Button from "../Button";
import { AuditAction } from "../../models/api/admin/audits";
import { type FormDropdownOption } from "../FormDropdown";
import TransferDateFilter from "../FileSafeHeader/TransferDateFilter";
import { type DateTimeRange } from "../../api/admin/audits";
import { useGetUsers } from "../../api/safe2Drive/user";

export type AuditTableFiltersProps = {
    searchQuery: string;
    setSearchQuery: Dispatch<SetStateAction<string>>;
    actionFilter: AuditAction | null;
    setActionFilter: Dispatch<SetStateAction<AuditAction | null>>;
    dateTimeRange: DateTimeRange;
    setDateTimeRange: Dispatch<SetStateAction<DateTimeRange>>;
    userId?: string;
    setUserId: Dispatch<SetStateAction<string | undefined>>;
};

const AuditTableFilters: React.FC<AuditTableFiltersProps> = ({
    searchQuery,
    setSearchQuery,
    actionFilter,
    setActionFilter,
    dateTimeRange,
    setDateTimeRange,
    userId,
    setUserId,
}) => {
    const actionOptions: FormDropdownOption<AuditAction>[] = new Array(0);
    Object.keys(AuditAction).map((action) =>
        actionOptions.push({
            label: action,
            value: action as AuditAction,
        })
    );

    const handleActionChange = (action: AuditAction | null) => {
        setActionFilter(action);
    };

    //just used some discrete values for users data count as there will never be too many of these
    const { usersData } = useGetUsers("LastName", "asc", 1, 100);

    const userOptions: FormDropdownOption<string>[] =
        usersData?.items.map((user) => ({
            label: user.firstName + " " + user.lastName,
            value: user.userId,
        })) ?? [];

    const handleUserChange = (userId: string | null) => {
        setUserId(userId ?? undefined);
    };

    const canClearAll =
        actionFilter ||
        searchQuery ||
        dateTimeRange.to ||
        dateTimeRange.from ||
        userId;

    const handleClearAll = () => {
        handleActionChange(null);
        setSearchQuery("");
        setDateTimeRange({
            to: null,
            from: null,
        });
        handleUserChange(null);
    };

    return (
        <div className="mb-6 flex w-full flex-wrap items-start justify-start gap-6">
            <FormTextInput
                StartAdornmentIcon={MagnifyingGlass}
                value={searchQuery}
                placeholder="Search history"
                className="max-w-lg"
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="flex w-full flex-col flex-wrap gap-6 md:w-fit md:flex-row">
                <TransferDateFilter
                    date={dateTimeRange}
                    setDate={setDateTimeRange}
                    label="Date"
                />
                <FilterDropdown
                    selectedFilter={actionFilter}
                    filterOptions={actionOptions}
                    handleFilterChange={handleActionChange}
                    label="Action"
                />
                <FilterDropdown
                    selectedFilter={userId ?? null}
                    filterOptions={userOptions}
                    handleFilterChange={handleUserChange}
                    label="Edited by"
                />
                <Button
                    variant="secondary"
                    type="button"
                    onClick={handleClearAll}
                    disabled={!canClearAll}
                    className="min-w-fit grow justify-center"
                >
                    Clear all
                </Button>
            </div>
        </div>
    );
};

export default AuditTableFilters;
