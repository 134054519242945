import { useRef, useState } from "react";
import {
    useBulkDriverImport,
    useBulkDriverImportDelete,
} from "../../api/admin/driver";
import { S2DApiFetcherWithContentResponse } from "../../api/common/apiResponse";
import { ApiBulkDriverImportResponse } from "../../models/api/admin/driver";
import { errorToast, successToast } from "../../toast";
import DialogBox from "../DialogBox";
import FormBulkUpload from "../FormBulkUpload";

type FormDocumentUploadModalProps = {
    isOpen: boolean;
    onClose: () => void;
    isBulkDelete: boolean;
    refetchDrivers?: () => void;
};

const FormDocumentUploadModal: React.FC<FormDocumentUploadModalProps> = ({
    isOpen,
    onClose,
    isBulkDelete,
    refetchDrivers,
}) => {
    const [submitBulkUpload, isSubmittingBulkUpload] = useBulkDriverImport();
    const [submitBulkUploadDelete, isSubmittingBulkUploadDelete] =
        useBulkDriverImportDelete();
    const [failureReasons, setFailureReasons] = useState<string[]>([]);
    const failureRef = useRef<HTMLDivElement | null>(null);

    const handleErrors = (
        response: S2DApiFetcherWithContentResponse<ApiBulkDriverImportResponse>
    ) => {
        response.content.failureReason.forEach((item) => {
            const reasons = item.split(".");
            reasons.forEach((val) => {
                const reason = val.replace("[0]", "");
                errorToast(`Import failed: ${reason}`, true);
            });
        });
    };

    const handleBulkImport = async (formData: FormData, companyId: string) => {
        if (!isBulkDelete) {
            const res = await submitBulkUpload({ formData, companyId });

            if (res.success) {
                successToast(`${res.content.totalCount} Drivers added`);
                onClose();
                refetchDrivers && refetchDrivers();
                setFailureReasons([]);
            } else {
                errorToast("Failed to import drivers, errors below.");
                setFailureReasons(res.content.failureReason);

                failureRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        } else {
            const res = await submitBulkUploadDelete(formData);

            if (res.success) {
                successToast(`${res.content.totalCount} Drivers deleted`);
                onClose();
                refetchDrivers && refetchDrivers();
            } else {
                handleErrors(res);
            }
        }
    };

    const handleClose = () => {
        onClose();
        setFailureReasons([]);
    };

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={handleClose}
            title={isBulkDelete ? "Delete drivers" : "Add drivers"}
            subtitle="Prepare and upload your file"
            description={
                isBulkDelete
                    ? "Add the drivers you’re importing for delete into a file and then upload it here."
                    : "Add the drivers you’re importing into a file and then upload it here."
            }
            size="xl"
        >
            <FormBulkUpload
                onSubmit={handleBulkImport}
                isLoading={
                    isSubmittingBulkUpload || isSubmittingBulkUploadDelete
                }
                onClose={handleClose}
                isBulkDelete={isBulkDelete}
                failureReasons={failureReasons}
                failureRef={failureRef}
            />
        </DialogBox>
    );
};

export default FormDocumentUploadModal;
