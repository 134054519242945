import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { CheckCircle } from "phosphor-react";

type TabStepperTabItemProps = {
    isActive: boolean;
    isComplete: boolean;
    isAllStepsComplete: boolean;
    title: string;
    isHidden: boolean;
    shrinkTabs?: boolean;
};

const TabStepperTabItem: React.FC<TabStepperTabItemProps> = ({
    isActive,
    isComplete,
    isAllStepsComplete,
    title,
    isHidden,
    shrinkTabs = false,
}) => {
    return (
        <Tab
            className={clsx(
                "pointer-events-none relative flex h-9 items-center py-1 text-xs font-semibold leading-5  ",
                !shrinkTabs && "p-2 transition-all md:pl-5",
                shrinkTabs && "p-2 transition-all",
                isHidden && "hidden",
                !isActive &&
                    !isComplete &&
                    "justify-center bg-S2D-neutral-40 text-white md:justify-start ",
                !isActive && !isComplete && !shrinkTabs && "w-11 md:w-full",
                !isActive && !isComplete && shrinkTabs && "w-11",
                isActive &&
                    "w-full min-w-fit bg-S2D-dark-green-30 text-S2D-text-menu",
                isComplete &&
                    !isAllStepsComplete &&
                    "justify-center bg-S2D-light-green-80 text-S2D-dark-green-30 md:justify-start",
                isComplete &&
                    !isAllStepsComplete &&
                    !shrinkTabs &&
                    "w-11 md:w-full",
                isComplete && !isAllStepsComplete && shrinkTabs && "w-11",
                isComplete &&
                    isAllStepsComplete &&
                    "w-full justify-center bg-S2D-light-green-80 text-S2D-dark-green-30 md:justify-start"
            )}
        >
            <div
                className={clsx(
                    "absolute -left-[12px] h-9 w-0 border-t-[18px] border-b-[18px] border-l-[12px] border-l-transparent transition-all",
                    !isActive &&
                        !isComplete &&
                        "border-t-S2D-neutral-40 border-b-S2D-neutral-40",
                    isActive &&
                        "border-t-S2D-dark-green-30 border-b-S2D-dark-green-30",
                    isComplete &&
                        "border-t-S2D-light-green-80 border-b-S2D-light-green-80"
                )}
            ></div>
            {/* //The below div is a duplicate of the above div, but with a
            different border-l width. This is to prevent a thin white line
            appearing between the main section of the tab and the end sections. */}
            <div
                className={clsx(
                    "absolute -left-[12px] h-9 w-0 border-t-[18px] border-b-[18px] border-l-[13px] border-l-transparent transition-all",
                    !isActive &&
                        !isComplete &&
                        "border-t-S2D-neutral-40 border-b-S2D-neutral-40",
                    isActive &&
                        "border-t-S2D-dark-green-30 border-b-S2D-dark-green-30",
                    isComplete &&
                        "border-t-S2D-light-green-80 border-b-S2D-light-green-80"
                )}
            ></div>
            <span
                className={clsx(
                    isActive && "inline-block",
                    !shrinkTabs && !isActive && "hidden md:inline-block",
                    shrinkTabs && !isActive && "hidden"
                )}
            >
                {title}
            </span>
            {isComplete && (
                <CheckCircle
                    weight="fill"
                    className="h-5 w-5 text-S2D-dark-green-30 md:ml-auto"
                />
            )}

            <div
                className={clsx(
                    "absolute -right-[11px] h-9 w-0 border-t-[18px] border-b-[18px] border-l-[12px] border-t-transparent border-b-transparent transition-all",
                    !isActive && !isComplete && "border-l-S2D-neutral-40",
                    isActive && "border-l-S2D-dark-green-30",
                    isComplete && "border-l-S2D-light-green-80"
                )}
            ></div>
            {/* //The above div is a duplicate of the below div, but with a
            different border-l width. This is to prevent a thin white line
            appearing between the main section of the tab and the end sections. */}
            <div
                className={clsx(
                    "absolute -right-[12px] h-0 w-0 border-t-[18px] border-b-[18px] border-l-[12px] border-t-transparent border-b-transparent transition-all",
                    !isActive && !isComplete && "border-l-S2D-neutral-40",
                    isActive && "border-l-S2D-dark-green-30",
                    isComplete && "border-l-S2D-light-green-80"
                )}
            ></div>
        </Tab>
    );
};

export default TabStepperTabItem;
