import { Dispatch, SetStateAction } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import Button from "../Button";
import licenceSample from "../../licence-sample.png";
import EditableContentBlock from "../EditableContentBlock";
import FormDropdown from "../FormDropdown";
import FormTextInput from "../FormTextInput";
import {
    EditDriverFormValues,
    driverDrivingLicenceValidationSchema,
} from "./EditDriverFormValues";
import {
    GREAT_BRITAIN,
    NORTHERN_IRELAND,
    countryList,
} from "../../constants/countries";
import useAuth from "../../auth/useAuth";
import { canEditPii } from "../../auth/userAccessHelpers";
import * as yup from "yup";
import { resolverDebugger } from "../../utils/yupResolverDebugger";
import { useStaleDataFormReset } from "../../hooks/useStaleDataFormReset";

type EditDriverLicenceInformationProps = {
    initialValues: EditDriverFormValues;
    isLoading: boolean;
    onSubmit: (data: EditDriverFormValues, callback: () => void) => void;
};

const countryOfOriginOptions = countryList.map((country) => ({
    label: country.name,
    value: country.name,
}));

const validationSchema = yup
    .object()
    .shape(driverDrivingLicenceValidationSchema);

const EditDriverLicenceInformation: React.FC<
    EditDriverLicenceInformationProps
> = ({ initialValues, isLoading, onSubmit }) => {
    const { decodedToken } = useAuth();

    const methods = useForm<EditDriverFormValues>({
        mode: "onSubmit",
        resolver: (data, context, options) =>
            resolverDebugger(data, context, options, validationSchema),
        defaultValues: initialValues,
    });

    const handleFormSubmit = async (
        values: EditDriverFormValues,
        setIsEdit: Dispatch<SetStateAction<boolean>>
    ) => {
        onSubmit(values, () => setIsEdit(false));
    };

    const control = methods.control;
    const currentForm = useWatch({ control });
    const countryOfOrigin = useWatch({ control, name: "licenceCountryOrigin" });

    const originIsNorthernIreland = countryOfOrigin === NORTHERN_IRELAND,
        originIsGreatBritain = countryOfOrigin === GREAT_BRITAIN;

    const licenceNumberHelpText = `Licence number must be ${
        originIsNorthernIreland ? "8" : "16"
    } characters with no spaces`;

    const handleEditLicenceOrigin = (value: string) => {
        methods.setValue("licenceCountryOrigin", value);
        if (value === GREAT_BRITAIN) {
            methods.setValue("foreignLicence", false);
        } else {
            methods.setValue("foreignLicence", true);
        }
    };

    useStaleDataFormReset(control, methods.reset, initialValues);

    return (
        <EditableContentBlock
            title="Driving Licence"
            disableEdit={!canEditPii(decodedToken)}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            <p>
                                <b>Country of origin:</b>{" "}
                                {initialValues.licenceCountryOrigin}
                            </p>
                            {canEditPii(decodedToken) && (
                                <p>
                                    <b>Licence number:</b>{" "}
                                    {initialValues.licenceNumber}
                                </p>
                            )}
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit((values) =>
                                    handleFormSubmit(values, setIsEdit)
                                )}
                            >
                                <div className="w-full">
                                    <Controller
                                        name="licenceCountryOrigin"
                                        control={methods.control}
                                        render={({ field: { value } }) => (
                                            <FormDropdown
                                                label="Driving licence country of origin"
                                                options={countryOfOriginOptions}
                                                value={value}
                                                onChange={
                                                    handleEditLicenceOrigin
                                                }
                                                pleaseSelectText="Select driving licence country of origin"
                                            />
                                        )}
                                    />
                                    {canEditPii(decodedToken) && (
                                        <FormTextInput
                                            label="Licence Number"
                                            required
                                            id="licence-number"
                                            placeholder="Enter licence number"
                                            error={
                                                methods.formState.errors
                                                    .licenceNumber
                                            }
                                            helpText={licenceNumberHelpText}
                                            {...methods.register(
                                                "licenceNumber"
                                            )}
                                            moreInfo={
                                                originIsGreatBritain
                                                    ? {
                                                          dialogPrompt:
                                                              "Where is my licence number?",
                                                          description:
                                                              "Your licence number can be found on your UK Driving Licence at the position illustrated below, this will be 16 characters with no spaces.",
                                                          content: (
                                                              <img
                                                                  className="w-full max-w-xl"
                                                                  src={
                                                                      licenceSample
                                                                  }
                                                                  alt="licence sample MORGA753116SM9IJ"
                                                              />
                                                          ),
                                                      }
                                                    : undefined
                                            }
                                        />
                                    )}
                                </div>
                                <div className="mt-8 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => {
                                            setIsEdit(false);
                                            methods.reset({
                                                ...currentForm,
                                                licenceCountryOrigin:
                                                    initialValues.licenceCountryOrigin,
                                                licenceNumber:
                                                    initialValues.licenceNumber,
                                            });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" loading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditDriverLicenceInformation;
