import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDriver } from "../../api/admin/driver";
import { useDriverDvlaInfo } from "../../api/admin/dvla";
import Alerts from "../../components/Alerts";
import DriverProfileHeader from "../../components/DriverProfileHeader/DriverProfileHeader";
import DriverRiskProfile from "../../components/DriverRiskProfile";
import EditDvlaForm from "../../components/Dvla/EditDvlaForm";
import FormMessageBox from "../../components/FormMessageBox";
import EditGreyFleetFormBase from "../../components/GreyFleet/EditGreyFleetFormBase";
import PageLayout from "../../components/PageLayout";
import { Vehicle } from "../../models/api/admin/vehicle";
import Divider from "../../components/ReusableComponents/Divider";
import AlertBadge from "../../components/ReusableComponents/AlertBadge";
import DriverInformationTab from "../../components/DriverProfileTabs/DriverInformationTab";
import useAuth from "../../auth/useAuth";
import { canEditPii } from "../../auth/userAccessHelpers";
import { useDownloadComponentToPdf } from "../../hooks/useDownloadComponentToPdf";
import DriverProfilePdfReport from "../../components/DriverRiskProfile/DriverProfilePdfReport";
import { useReactToPrint } from "react-to-print";
import Button from "../../components/Button";
import { UndeleteDriverDto } from "../../models/app/admin/driver";
import { successToast } from "../../toast";

type PageParams = {
    id: string;
};

export enum SectionTabs {
    DriverInformation = "Driver Information",
    DvlaInformation = "DVLA Information",
    GreyFleetInformation = "Grey Fleet Information",
}

const AdminEditDriver = () => {
    const { id } = useParams<PageParams>();

    const [selectedTab, setSelectedTab] = useState<SectionTabs | string>(
        SectionTabs.DvlaInformation
    );

    const [vehicle, setVehicle] = useState<Vehicle | null>(null);
    const [isUndeletingDriver, setIsUndeletingDriver] =
        useState<boolean>(false);

    const {
        driverData,
        driverError,
        isLoading,
        editDriver,
        isEditingDriver,
        deleteDriver,
        isDeletingDriver,
        refetch,
    } = useDriver(id);

    const { decodedToken } = useAuth();

    const { dvlaInfo, dvlaInfoError, dvlaInfoIsLoading, dvlaRefetch } =
        useDriverDvlaInfo(id);

    const tabSelectedStyle =
        "border-b-4 border-S2D-light-green-80 bg-S2D-dark-green-30 text-S2D-text-menu";

    const greyFleetVehicle = (id: string) => {
        const vehicles = driverData?.greyFleetInvite?.vehicles;
        const matchVehicle = vehicles?.find(
            (vehicle) => vehicle.vehicleId === id
        );

        if (matchVehicle) return matchVehicle.deletionRequestedAt;
        return null;
    };

    // when "upload document" action is triggered
    // send to doc upload form
    const documentUploadRef = useRef<HTMLButtonElement | null>(null);
    const handleScrollToDocumentUpload = () => {
        setSelectedTab(SectionTabs.DriverInformation);
        setTimeout(() => {
            if (!documentUploadRef?.current) return;
            window.scrollTo(0, documentUploadRef.current.offsetTop);
            documentUploadRef.current.click();
        }, 500);
    };

    const reportRef = useRef<HTMLDivElement | null>(null);
    const driverName = `${driverData?.firstName}${driverData?.lastName}`;
    const formattedDriverName = driverName
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "_");
    const { generatePDF } = useDownloadComponentToPdf(reportRef);
    const [showReportView, setShowReportView] = useState(false);
    const [isLoadingPdfReport, setIsLoadingPdfReport] = useState(false);
    const [printing, setPrinting] = useState(false);

    const handleDownloadPdfReport = (): void => {
        setIsLoadingPdfReport(true);
        setShowReportView(true);
        setPrinting(false);
        const fileName = `${formattedDriverName}_driver_report`;
        generatePDF(() => {
            setShowReportView(false);
            setContentReady(false);
            setIsLoadingPdfReport(false);
        }, fileName);
    };

    const handleUndeleteDriver = async () => {
        setIsUndeletingDriver(true);
        if (id) {
            try {
                const undeleteDriverDto: UndeleteDriverDto = {
                    isDeleted: false,
                };
                await editDriver(id, undeleteDriverDto);
                await refetch();
                setIsUndeletingDriver(false);
                successToast(`Successfully undeleted driver.`);
            } catch (error) {
                console.error("Error undeleting driver", error);
            } finally {
                setIsUndeletingDriver(false);
            }
        } else {
            setIsUndeletingDriver(false);
        }
    };

    const [contentReady, setContentReady] = useState(false);
    const handlePrint = useReactToPrint({
        content: () => reportRef.current,
    });
    useEffect(() => {
        // Check if the driver report content is available
        if (reportRef.current) {
            setContentReady(true);
        } else {
            setContentReady(false);
        }
    }, [reportRef.current]);

    // Call back function to handle printing
    const handlePrintPdfReport = useCallback(() => {
        // Reveal PDF report component on the page
        setShowReportView(true);
        setPrinting(true);
        // Check if the pdf content is ready for printing
        if (contentReady) {
            handlePrint();
            // Hide PDF component after printing
            setShowReportView(false);
            setPrinting(false);
        }
    }, [contentReady, handlePrint]);

    // Only trigger printing when content is ready and report view is shown
    useEffect(() => {
        if (contentReady && showReportView && printing) {
            handlePrintPdfReport();
        }
    }, [contentReady, showReportView, printing, handlePrintPdfReport]);

    useEffect(() => {
        if (selectedTab) {
            const foundVehicle = driverData?.vehicles?.find(vehicle => vehicle.vehicleId === selectedTab);
            if (foundVehicle) setVehicle(foundVehicle);
        }
    }, [selectedTab]);

    if (driverError?.statusCode === 404) {
        return (
            <PageLayout title="Driver Profile">
                <FormMessageBox
                    variant="error"
                    title={"Driver not found"}
                    message={
                        "Either this driver has been deleted or does not exist."
                    }
                />
            </PageLayout>
        );
    }

    const isDriverDeleted = driverData?.deletedAt ? true : false;

    return (
        <>
            <PageLayout title="Driver Profile">
                <div className="mx-auto w-full">
                    {isLoading && <p>Loading...</p>}
                    {driverData && (
                        <>
                            {isDriverDeleted && (
                                <FormMessageBox
                                    variant="error"
                                    title={"Driver deleted"}
                                    message={
                                        "This driver record has been deleted and cannot be updated without being restored."
                                    }
                                    actions={
                                        <Button
                                            className="border-S2D-error-40 bg-S2D-error-90 p-3"
                                            onClick={handleUndeleteDriver}
                                            loading={isUndeletingDriver}
                                        >
                                            Undelete
                                        </Button>
                                    }
                                />
                            )}
                            <DriverProfileHeader
                                {...driverData}
                                isDeletingDriver={isDeletingDriver}
                                id={id}
                                handleDownloadPdfReport={
                                    handleDownloadPdfReport
                                }
                                handlePrintPdfReport={handlePrintPdfReport}
                                isLoadingPdfReport={
                                    isLoadingPdfReport || printing
                                }
                                printing={printing || isLoadingPdfReport}
                                deleteDriver={deleteDriver}
                                isDriverDeleted={isDriverDeleted}
                            />
                            <DriverRiskProfile
                                driverId={id}
                                driverData={driverData}
                                isLoading={isLoading}
                                refetch={refetch}
                                handleScrollToDocumentUpload={
                                    handleScrollToDocumentUpload
                                }
                            />
                        </>
                    )}
                    {driverData && <Alerts alerts={driverData?.alerts} />}
                    <Divider text="Driver Information" />
                    {!canEditPii(decodedToken) && (
                        <FormMessageBox
                            variant="neutral"
                            title={"You have no PII permissions"}
                            message={
                                "This means you are not able to view or edit personally identifiable information. This includes dates of birth, addresses, and licence number. You also do not have permission to edit information returned by the DVLA."
                            }
                        />
                    )}
                    {
                        <div className="overflow-x-auto">
                            <div className="flex min-w-max">
                                <button
                                    className={clsx(
                                        "rounded-tl-sm rounded-tr-sm py-3 px-4 font-bold",
                                        selectedTab ===
                                            SectionTabs.DvlaInformation &&
                                            tabSelectedStyle,
                                        selectedTab !==
                                            SectionTabs.DvlaInformation &&
                                            "text-S2D-neutral-40"
                                    )}
                                    onClick={() =>
                                        setSelectedTab(
                                            SectionTabs.DvlaInformation
                                        )
                                    }
                                >
                                    {SectionTabs.DvlaInformation}
                                </button>
                                <button
                                    className={clsx(
                                        "min-w-max rounded-tl-sm rounded-tr-sm py-3 px-4 font-bold",
                                        selectedTab ===
                                            SectionTabs.DriverInformation &&
                                            tabSelectedStyle,
                                        selectedTab !==
                                            SectionTabs.DriverInformation &&
                                            "text-S2D-neutral-40"
                                    )}
                                    onClick={() =>
                                        setSelectedTab(
                                            SectionTabs.DriverInformation
                                        )
                                    }
                                >
                                    {SectionTabs.DriverInformation}
                                </button>
                                {driverData?.vehicles &&
                                    driverData?.vehicles.map((vehicle) => (
                                        <button
                                            key={vehicle.vehicleId}
                                            className={clsx(
                                                "rounded-tl-sm rounded-tr-sm py-3 px-4 font-bold",
                                                selectedTab ===
                                                    vehicle.vehicleId &&
                                                    tabSelectedStyle,
                                                selectedTab !==
                                                    vehicle.vehicleId &&
                                                    "text-S2D-neutral-40"
                                            )}
                                            onClick={() => {
                                                setSelectedTab(
                                                    vehicle.vehicleId
                                                );
                                                setVehicle(vehicle);
                                            }}
                                        >
                                            <div className="flex gap-4">
                                                {`Grey Fleet information - ${vehicle.vrm}`}
                                                {greyFleetVehicle(
                                                    vehicle.vehicleId
                                                ) && <AlertBadge value="!" />}
                                            </div>
                                        </button>
                                    ))}
                            </div>
                        </div>
                    }
                    {selectedTab === SectionTabs.DriverInformation && (
                        <DriverInformationTab
                            driverId={id}
                            driverData={driverData}
                            isLoadingDriver={isLoading}
                            editDriver={editDriver}
                            isEditingDriver={isEditingDriver}
                            refetchDriver={refetch}
                            dvlaRefetch={dvlaRefetch}
                            documentUploadRef={documentUploadRef}
                        />
                    )}
                    {selectedTab === SectionTabs.DvlaInformation && (
                        <>
                            {dvlaInfoError && (
                                <FormMessageBox
                                    variant="error"
                                    message="Couldn't retrieve dvla information"
                                    title={"Error"}
                                />
                            )}
                            {dvlaInfo && (
                                <EditDvlaForm
                                    initialValues={{
                                        ...dvlaInfo,
                                    }}
                                    isManual={driverData?.isManualCheck ?? true}
                                    refetch={() => {
                                        dvlaRefetch();
                                        refetch();
                                    }}
                                    isLoading={dvlaInfoIsLoading}
                                />
                            )}
                        </>
                    )}
                    {selectedTab !== SectionTabs.DriverInformation &&
                        selectedTab !== SectionTabs.DvlaInformation &&
                        vehicle &&
                        id && (
                            <EditGreyFleetFormBase
                                driverId={id}
                                vehicleId={vehicle.vehicleId}
                                refetch={refetch}
                                tabSelected={selectedTab}
                                setSelectedTab={setSelectedTab}
                                deletionRequestedAt={greyFleetVehicle(
                                    vehicle.vehicleId
                                )}
                            />
                        )}
                </div>
            </PageLayout>
            {showReportView && (
                // DriverProfile report is never actually intended to be seen by the user, only appended to the bottom of the page
                <>
                    <div
                        id="print-content"
                        className="avoid-page-break"
                        ref={reportRef}
                    >
                        <DriverProfilePdfReport
                            driverId={id}
                            vehicles={driverData?.greyFleetInvite?.vehicles}
                            driverData={driverData}
                            dvlaInfo={dvlaInfo}
                            dvlaInfoError={dvlaInfoError}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default AdminEditDriver;
