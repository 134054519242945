import type {
    ApiCompanyResponse,
    CompanyContact,
    CompanyStatusFilters,
} from "../../api/admin/company";
import { CompanyApiSettingsResponse } from "../../api/admin/companyApiSettings";
import { type DocumentBase64 } from "./document";

export type AddCompanyDto = {
    companyName: string;
    resellerId?: string;
    companyRiskProfiles: CompanyRiskProfile[];
    settings: CompanySettings;
};

export type CompanyRiskProfile = {
    severityLevel: 1 | 2 | 3; // the 'range' key used by the UI when fetching/editing companies
    name: string | null; // the user set name of this range/level
    lowerThreshold: number; // the penalty point value for this range/level
    checkFrequency: number | null; // how often drivers in this range/level should be checked
};

export type CompanySettings = {
    pricing?: {
        licenceCheckPrice: number | null;
        manualCheckPrice: number | null;
        irishLicenceCheckPrice: number | null;
        foreignLicenceCheckPrice: number | null;
        greyFleetCheckPrice: number | null;
    };
    correspondence?: CommunicationSettings;
    edeclaration?: {
        useParentContact: boolean;
        driverValidationType: DriverValidationTypes;
    };
    contactSettings?: {
        contacts: CompanyContact[];
    };
    apiSettings?: CompanyApiSettingsResponse;
    authSettings?: CompanyAuthSettings;
    privacySettings?: CompanyPrivacySettings;
    invoiceSettings?: CompanyInvoiceSettings;
};

export type CompanyAuthSettings = {
    enforce2FA: boolean;
};

export type CompanyPrivacySettings = {
    isDataHidden: boolean;
};

export type CompanyInvoiceSettings = {
    shouldInvoiceParent: boolean;
    lastInvoicedAt: Date | null;
    annualFee: number | null;
};

export enum DriverValidationTypes {
    DateOfBirth = "DateOfBirth",
    EmployeeId = "EmployeeId",
}

export type CommunicationSettings = {
    permitsEmailCorrespondence: boolean;
    permitsTelephoneCorrespondence: boolean;
    permitsSmsCorrespondence: boolean;
    permitsPostalCorrespondence: boolean;
};

export type AddCompanyLogoDto = {
    fileType: DocumentBase64["fileType"];
    imageBase64: string;
};

export type EditCompanyDto = Partial<ApiCompanyResponse>;

export type EditCompanyStatusDto = {
    status: CompanyStatusFilters;
    comment?: string;
};

export type StatusOption = {
    label: string;
    value: CompanyStatusFilters;
};
