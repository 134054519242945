import { useRef, useState } from "react";
import { appPaths } from "../../Routes";
import { useDriverVehicle } from "../../api/admin/driverVehicle";
import { useGreyFleet } from "../../api/greyFleet";
import {
    ApiGreyFleetInviteResponse,
    ApiGreyFleetVehicleResponse,
} from "../../models/api/greyFleet";
import { errorToast } from "../../toast";
import {
    GreyFleetFormPersonalDetailsValues,
    GreyFleetFormVehicleDetailsValues,
} from "./GreyFleetFormValues";
import { parseBoolean } from "./greyFleetHelpers";
import { format } from "date-fns";
import { DriverValidationTypes } from "../../models/app/admin/company";
import { formatDateWithoutTimezone } from "../../utils/formatDateWithoutTimezone";

export const useGreyFleetWizardStepHandlers = (
    greyFleetInviteId: string,
    selectedIndex: number,
    setSelectedIndex: (value: number) => void,
    onUpdateInsuranceJourney: boolean,
    onUpdateVehicleJourney: boolean,
    token?: string | null,
    greyFleetBaseData?: ApiGreyFleetInviteResponse,
    vehicleId?: string | null
) => {
    //only used when vehicleId is present
    const { editDriverVehicleWithAltToken, getVehicleWithAltToken } =
        useDriverVehicle();

    const { validateUser, finaliseGreyFleetProcess } = useGreyFleet();

    const [driverId, setDriverId] = useState<string>("");
    const [greyFleetToken, setGreyFleetToken] = useState(token ?? "");
    const [vehicleReg, setVehicleReg] = useState("");

    const changeStep = (direction: "back" | "next") => {
        const indexDifference = direction === "back" ? -1 : 1;
        setSelectedIndex(selectedIndex + indexDifference);
        window.scrollTo(0, 0);
    };

    const greyFleetInsuranceDefaultValues = {
        insuranceCertificate: null,
        insuranceCompanyName: "",
        insurancePolicyNumber: "",
        insuranceStartDate: null,
        insuranceEndDate: null,
        insuranceType: "",
    };

    const greyFleetFormVehicleDetailsDefaultValues = {
        greyFleetInviteId: greyFleetInviteId,
        vrm: "",
        make: "",
        taxExpiryDate: null,
        motExpiryDate: null,
        ...greyFleetInsuranceDefaultValues,
        hasBusinessCover: "",
        hasBreakdownCover: false,
        breakdownCompanyName: "",
        breakdownPolicyNumber: "",
        breakdownStartDate: null,
        breakdownEndDate: null,
    };

    // using useRef here as the data is not rendered in this component
    const greyFleetFormVehicleDetailsData =
        useRef<GreyFleetFormVehicleDetailsValues>(
            greyFleetFormVehicleDetailsDefaultValues
        );

    const setGreyFleetFormVehicleDetailsData = (
        data?: Partial<GreyFleetFormVehicleDetailsValues>
    ) => {
        if (data)
            greyFleetFormVehicleDetailsData.current = {
                ...greyFleetFormVehicleDetailsData.current,
                ...data,
            };
    };

    const saveDataThenChangeStep = (
        direction: "back" | "next",
        data?: Partial<GreyFleetFormVehicleDetailsValues>
    ) => {
        setGreyFleetFormVehicleDetailsData(data);
        changeStep(direction);
    };

    const submitUpdatedVehicleData = (
        data?: Partial<GreyFleetFormPersonalDetailsValues>
    ) => {
        setGreyFleetFormVehicleDetailsData(data);
        if (data) handleFinaliseGreyFleetProcess(data);
    };

    const handleVehicleDetailsSubmit = (
        data?: Partial<GreyFleetFormVehicleDetailsValues>
    ) => {
        if (onUpdateVehicleJourney) {
            submitUpdatedVehicleData(data);
        } else {
            // On other journeys, there are further steps to complete:
            saveDataThenChangeStep("next", data);
        }
    };

    const handleVerificationSubmit = async (
        data: GreyFleetFormPersonalDetailsValues,
        callback: (error: true) => void,
        driverValidationType?: DriverValidationTypes | undefined
    ) => {
        const response = await validateUser(greyFleetInviteId, {
            ...data,
            //^ if data.dateOfBirth has a null value it will be caught upstream by yup. This is a hack to match types.
            dateOfBirth: data.dateOfBirth
                ? format(data.dateOfBirth, "yyyy-MM-dd")
                : null,
            driverValidationType: driverValidationType ? driverValidationType : null
        });

        if (!response.success) {
            window.scrollTo(0, 0);
            callback(true);
            return;
        }
        if (vehicleId) {
            handleFetchVehicle(
                vehicleId,
                response.content.driver.driverId,
                response.content.token,
                () => {
                    setGreyFleetToken(response.content.token);
                    saveDataThenChangeStep("next", data);
                }
            );
            setDriverId(response.content.driver.driverId);
            return;
        }
        setGreyFleetToken(response.content.token);
        saveDataThenChangeStep("next", data);
    };

    const handleFetchVehicle = async (
        vehicleId: string,
        driverId: string,
        greyFleetToken: string,
        callback: () => void
    ) => {
        const response = await getVehicleWithAltToken(
            vehicleId,
            driverId,
            greyFleetToken
        );

        if (!response.success) {
            errorToast("error fetching vehicle details");
            return;
        }
        const vehicle = response.content;
        const vehicleDetails = {
            vrm: vehicle.vrm,
            make: vehicle.make,
            taxExpiryDate: new Date(vehicle.taxExpiryDate),
            motExpiryDate: new Date(vehicle.motExpiryDate),
            insuranceCompanyName: vehicle.insuranceCompanyName,
            insurancePolicyNumber: vehicle.insurancePolicyNumber,
            insuranceStartDate: new Date(vehicle.insuranceStartDate),
            insuranceEndDate: new Date(vehicle.insuranceEndDate),
            insuranceType: vehicle.insuranceType,
            hasBusinessCover: vehicle.hasBusinessCover.toString(),
            hasBreakdownCover: vehicle.hasBreakdownCover,
            breakdownCompanyName: vehicle.breakdownCoverProvider,
            breakdownPolicyNumber: vehicle.breakdownCoverPolicyNumber,
            breakdownStartDate: new Date(vehicle.breakdownCoverStartDate),
            breakdownEndDate: new Date(vehicle.breakdownCoverEndDate),
        };
        //remove prev insurance details when updating insurance
        //breakdown details are fine to leave as they are not required content
        if (onUpdateInsuranceJourney) {
            setGreyFleetFormVehicleDetailsData({
                ...greyFleetFormVehicleDetailsDefaultValues,
                ...vehicleDetails,
                ...greyFleetInsuranceDefaultValues,
            });
        } else {
            setGreyFleetFormVehicleDetailsData({
                ...greyFleetFormVehicleDetailsDefaultValues,
                ...vehicleDetails,
            });
        }

        // setting the vehicle reg here for the 'update insurance' journey
        // which does not use the 'Vehicle Details' step
        if (vehicle.vrm) setVehicleReg(vehicle.vrm);

        callback();
    };

    const handleFinaliseGreyFleetProcess = async (
        data: Partial<GreyFleetFormVehicleDetailsValues>
    ) => {

        const newVehicleData = {
            ...greyFleetFormVehicleDetailsData.current,
            hasBusinessCover: parseBoolean(
                greyFleetFormVehicleDetailsData.current["hasBusinessCover"]
            ),
            hasBreakdownCover: parseBoolean(data.hasBreakdownCover),
            breakdownCompanyName: data.breakdownCompanyName ?? "",
            breakdownPolicyNumber: data.breakdownPolicyNumber ?? "",
            breakdownStartDate: data.breakdownStartDate ?? null,
            breakdownEndDate: data.breakdownEndDate ?? null,
            motExpiryDate: formatDateWithoutTimezone(
                greyFleetFormVehicleDetailsData.current.motExpiryDate
            ),
            taxExpiryDate: formatDateWithoutTimezone(
                greyFleetFormVehicleDetailsData.current.taxExpiryDate
            ),
        };
        if (vehicleId) {
            const extraDetails: ApiGreyFleetVehicleResponse | undefined =
                greyFleetBaseData?.vehicles.find(
                    (v) => v.vehicleId === vehicleId
                );
            const response = await editDriverVehicleWithAltToken(
                vehicleId,
                driverId,
                greyFleetToken,
                {
                    vehicle: {
                        vrm: newVehicleData.vrm,
                        make: newVehicleData.make,
                        model: extraDetails?.model ?? null,
                        monthOfFirstRegistration: formatDateWithoutTimezone(extraDetails?.monthOfFirstRegistration),
                        taxExpiryDate: newVehicleData.taxExpiryDate,
                        motExpiryDate: newVehicleData.motExpiryDate,
                    },
                    insurance: {
                        insuranceCertificate:
                            newVehicleData.insuranceCertificate,
                        insuranceCompanyName:
                            newVehicleData.insuranceCompanyName,
                        insurancePolicyNumber:
                            newVehicleData.insurancePolicyNumber,
                        insuranceStartDate: newVehicleData.insuranceStartDate,
                        insuranceEndDate: newVehicleData.insuranceEndDate,
                        hasBusinessCover: newVehicleData.hasBusinessCover,
                        insuranceFileCheckedDate:
                            extraDetails?.insuranceFileCheckedDate ?? null,
                        insuranceType: newVehicleData.insuranceType,
                    },
                    breakdownCover: {
                        hasBreakdownCover: newVehicleData.hasBreakdownCover,
                        breakdownCoverProvider:
                            newVehicleData.breakdownCompanyName,
                        breakdownCoverPolicyNumber:
                            newVehicleData.breakdownPolicyNumber,
                        breakdownCoverStartDate:
                            newVehicleData.breakdownStartDate,
                        breakdownCoverEndDate: newVehicleData.breakdownEndDate,
                    },
                }
            );
            if (!response.success) return;
            changeStep("next");
            setGreyFleetFormVehicleDetailsData(
                greyFleetFormVehicleDetailsDefaultValues
            );
            return response.success;
        } else {
            const response = await finaliseGreyFleetProcess(
                greyFleetInviteId,
                greyFleetToken,
                newVehicleData
            );

            if (!response.success) return;
            changeStep("next");
            setGreyFleetFormVehicleDetailsData(
                greyFleetFormVehicleDetailsDefaultValues
            );
            return response.success;
        }
    };

    const handleAddAnotherVehicle = () => {
        window.location.href = appPaths.greyFleet(
            greyFleetInviteId,
            undefined,
            undefined,
            greyFleetToken
        );
    };

    return {
        greyFleetToken,
        changeStep,
        saveDataThenChangeStep,
        vehicleReg,
        setVehicleReg,
        greyFleetFormVehicleDetailsData,
        handleVehicleDetailsSubmit,
        handleVerificationSubmit,
        handleFinaliseGreyFleetProcess,
        handleAddAnotherVehicle,
    };
};
