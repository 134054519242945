import { ApiResellerResponse } from "../../models/api/admin/reseller";
import {
    AddResellerDto,
    EditResellerDto,
} from "../../models/app/admin/reseller";

import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

const useAddReseller = () =>
    useS2DApiFetcher<AddResellerDto, string, { dto: AddResellerDto }>(
        "POST",
        ({ dto }) => ({
            url: "api/reseller",
            body: dto,
        }),
        [],
        true
    );

const useEditReseller = () =>
    useS2DApiFetcher<
        EditResellerDto,
        string,
        { id: string; dto: EditResellerDto }
    >(
        "PUT",
        ({ id, dto }) => ({
            url: `api/reseller/${id}`,
            body: dto,
        }),
        [400],
        true
    );

const useDeleteReseller = () =>
    useS2DApiFetcher<void, string, { id: string }>(
        "DELETE",
        ({ id }) => ({
            url: `api/reseller/${id}`,
        }),
        [],
        true
    );

export const useReseller = (id?: string) => {
    const { data, error, isLoading } = useS2DApiSWR<ApiResellerResponse>(
        id ? `api/reseller/${id}` : null
    );
    const [addReseller, isAddingReseller] = useAddReseller();
    const [editReseller, isEditingReseller] = useEditReseller();
    const [deleteReseller, isDeletingReseller] = useDeleteReseller();

    return {
        resellerData: data,
        error,
        isLoading,
        addReseller: async (dto: AddResellerDto) => await addReseller({ dto }),
        isAddingReseller,
        editReseller: async (id: string, dto: EditResellerDto) =>
            await editReseller({ id, dto }),
        isEditingReseller,
        deleteReseller: async (id: string) => await deleteReseller({ id }),
        isDeletingReseller,
    };
};
