import { Menu } from "@headlessui/react";
import { useState } from "react";
import { type AlertsSectionContentType } from "./DashboardAlertsSection";
import AlertCountBadge from "./AlertCountBadge";
import CaretDropdown from "../ReusableComponents/CaretDropdown";
import { DashboardContentSections } from "../../pages/Dashboard";

function DropDownContentSelector({
    alertsSectionContent,
    setSelectedContent,
}: {
    alertsSectionContent: AlertsSectionContentType[];
    setSelectedContent: React.Dispatch<
        React.SetStateAction<DashboardContentSections>
    >;
}) {
    const [selectedDropDownOption, setSelectedDropDownOption] =
        useState<AlertsSectionContentType>(alertsSectionContent[0]);

    const handleContentSelect = (content: AlertsSectionContentType) => {
        setSelectedDropDownOption(content);
        setSelectedContent(content.title);
    };
    return (
        <div className="mb-4">
            <Menu>
                {({ open }) => (
                    <>
                        <Menu.Button className="w-full">
                            <CaretDropdown
                                open={open}
                                flattenBaseOnOpen
                                className="border-S2D-neutral-10 bg-S2D-neutral-100"
                                content={
                                    <div className="pl-25px flex items-center gap-2 p-3">
                                        {selectedDropDownOption.icon}
                                        {selectedDropDownOption.title}
                                        <AlertCountBadge
                                            count={selectedDropDownOption.count}
                                        />
                                    </div>
                                }
                            />
                        </Menu.Button>
                        {open && (
                            <Menu.Items
                                className={`rounded rounded-t-none border-2 border-t-0 border-S2D-neutral-10`}
                                static
                            >
                                {alertsSectionContent.map((content, i) => {
                                    const itemIsSelected =
                                        content.title ===
                                        selectedDropDownOption.title;

                                    return (
                                        <Menu.Item key={i}>
                                            {({ active }) => (
                                                <div
                                                    onClick={() =>
                                                        handleContentSelect(
                                                            content
                                                        )
                                                    }
                                                    className={`flex items-center gap-2 p-3 text-[16px] font-semibold ${
                                                        itemIsSelected
                                                            ? "bg-S2D-light-green-80"
                                                            : ""
                                                    } ${
                                                        active
                                                            ? "bg-S2D-neutral-90"
                                                            : ""
                                                    }`}
                                                >
                                                    {content.icon}
                                                    {content.title}
                                                </div>
                                            )}
                                        </Menu.Item>
                                    );
                                })}
                            </Menu.Items>
                        )}
                    </>
                )}
            </Menu>
            <div className="pt-[20px]">{selectedDropDownOption.content}</div>
        </div>
    );
}

export default DropDownContentSelector;
