import Button from "../../../Button";
import { UseFormReturn } from "react-hook-form";
import { CompanyContact } from "../../../../models/api/admin/company";
import FormTextInput from "../../../FormTextInput";

interface CompanyContactsFormFieldsProps {
    methods: UseFormReturn<CompanyContact>;
    resetAndCloseForm: () => void;
    contactBeingEdited: string | null;
    handleEditContact: (data: CompanyContact) => void;
    handleAddContact: (data: CompanyContact) => void;
    isEditingCompany: boolean;
}
export function CompanyContactsFormFields({
    methods,
    resetAndCloseForm,
    contactBeingEdited,
    handleEditContact,
    handleAddContact,
    isEditingCompany,
}: CompanyContactsFormFieldsProps) {
    const { formState, register, handleSubmit } = methods;

    const handleFormSubmit = (data: CompanyContact) => {
        if (contactBeingEdited) {
            handleEditContact(data);
        } else {
            handleAddContact(data);
        }
    };

    return (
        <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="flex flex-col space-y-3"
        >
            <FormTextInput
                label="First name"
                required
                id="firstName"
                placeholder="Enter contact first name"
                error={formState.errors.firstName}
                {...register("firstName")}
            />
            <FormTextInput
                label="Last name"
                required
                id="lastName"
                placeholder="Enter contact last name"
                error={formState.errors.lastName}
                {...register("lastName")}
            />
            <FormTextInput
                label="Telephone number"
                required
                id="telephone"
                placeholder="Enter telephone number"
                error={formState.errors.telephone}
                {...register("telephone")}
            />
            <FormTextInput
                label="Email Address"
                id="email"
                placeholder="Enter email"
                required
                error={formState.errors.emailAddress}
                {...register("emailAddress")}
            />
            <div className="flex flex-row justify-between space-x-6 pt-3">
                <Button
                    variant="tertiary"
                    type="button"
                    className="w-full justify-center md:w-auto "
                    onClick={resetAndCloseForm}
                >
                    Cancel
                </Button>
                <Button
                    variant="secondary"
                    type="submit"
                    className="w-full justify-center md:w-auto "
                    loading={isEditingCompany}
                >
                    Save Contact
                </Button>
            </div>
        </form>
    );
}
