import { useParams, useNavigate } from "react-router-dom";
import { useReseller } from "../../api/admin/reseller";
import DeleteReseller from "../../components/DeleteReseller/DeleteReseller";
import EditResellerForm from "../../components/EditResellerForm";
import { EditResellerFormValues } from "../../components/EditResellerForm/EditResellerFormValues";
import FormMessageBox from "../../components/FormMessageBox";
import PageLayout from "../../components/PageLayout";
import { appPaths } from "../../Routes";
import { errorToast, successToast } from "../../toast";

type PageParams = {
    id: string;
};

const AdminEditReseller = () => {
    const { id } = useParams<PageParams>();
    const {
        resellerData,
        isLoading,
        error,
        editReseller,
        isEditingReseller,
        deleteReseller,
        isDeletingReseller,
    } = useReseller(id);
    const navigate = useNavigate();

    const handleEditReseller = async (data: EditResellerFormValues) => {
        if (!id) {
            errorToast("There was an error");
            return;
        }

        const response = await editReseller(id, data);

        if (response.success) {
            successToast("Reseller has been edited succesfully");
        }
    };

    const handleDeleteReseller = async () => {
        if (!id) {
            errorToast("There was an error");
            return;
        }
        const response = await deleteReseller(id);

        if (response.success) {
            successToast("Reseller deleted successfully");
            navigate(appPaths.adminResellers);
        }
    };

    return (
        <PageLayout title="Edit Reseller">
            <>
                {isLoading && !error && <p>...loading</p>}
                {error && (
                    <FormMessageBox
                        variant="error"
                        title={"Failed"}
                        message="This reseller does not exist"
                    />
                )}
                {!isLoading && resellerData && (
                    <>
                        <DeleteReseller
                            onSubmit={handleDeleteReseller}
                            isLoading={isDeletingReseller}
                        />
                        <EditResellerForm
                            defaultValues={resellerData}
                            isLoading={isEditingReseller}
                            onSubmit={handleEditReseller}
                        />
                    </>
                )}
            </>
        </PageLayout>
    );
};

export default AdminEditReseller;
