import { useS2DApiSWR } from "../common/s2dApiSWR";
import { ApiCompanyUserResponse } from "../../models/api/admin/companyUser";

export const useAdminGetCompanyUsers = (
    companyId?: string,
    fileSafeOnly = false,
    hasPermission = true
) => {
    let url = "api/admin/companies/users/?";

    if (companyId) {
        url = url + `CompanyId=${companyId}`;
    }

    if (fileSafeOnly) {
        url = url + `&FileSafeOnly=${fileSafeOnly}`;
    }

    const { data, error, isLoading, refetch } = useS2DApiSWR<
        ApiCompanyUserResponse[]
    >(hasPermission && companyId ? url : null);

    return {
        companyUsersData: data,
        companyUsersError: error,
        isLoadingCompanyUsers: isLoading,
        refetchCompanyUsers: refetch,
    };
};
