import { Download } from "phosphor-react";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import Table, { TableHeader } from "../../../ReusableComponents/Table";
import { TableMenuItem } from "../../../ReusableComponents/Table/TableMenu";
import {
    initialInvoicesTableHeaders,
    initInvoicesTableContents,
} from "./CompanyInvoicesTableInit";
import {
    type ApiInvoiceItem,
    type ApiInvoiceResponse,
} from "../../../../models/api/admin/invoice";
import LoadingMessage from "../../../ReusableComponents/LoadingMessage/LoadingMessage";
import FormMessageBox from "../../../FormMessageBox";
import PaginationNav from "../../../PaginationNav";
import useAuth from "../../../../auth";
import { UserRoles } from "../../../../auth/decodeToken";

interface Props {
    invoices?: ApiInvoiceResponse;
    isLoadingInvoices?: boolean;
    invoicesError?: Error;
    pageNumber: number;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    handleDownloadInvoicePDF: (
        invoiceId: string,
        invoiceNumber: string
    ) => void;
    isInvoicePDFDownloading: boolean;
    handleDownloadChargeableItemsXLS: (
        invoiceId: string,
        invoiceNumber: string
    ) => void;
    isChargeableItemsXLSDownloading: boolean;
}

export default function CompanyInvoicesTable({
    invoices,
    isLoadingInvoices,
    invoicesError,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    handleDownloadInvoicePDF,
    isInvoicePDFDownloading,
    handleDownloadChargeableItemsXLS,
    isChargeableItemsXLSDownloading,
}: Props) {
    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "InvoicesTableHeaders",
        initialInvoicesTableHeaders
    );

    const { decodedToken } = useAuth();

    const shouldShowInvoices =
        !invoicesError &&
        !isLoadingInvoices &&
        invoices &&
        !!invoices.items.length;

    return (
        <>
            {isLoadingInvoices && invoices !== null && <LoadingMessage />}
            {invoicesError && <InvoiceLoadingError />}
            {!shouldShowInvoices && (
                <div className=" mb-3 w-max  rounded-sm border border-S2D-neutral-80 bg-S2D-neutral-95 p-3 font-semibold">
                    You have no invoices
                </div>
            )}

            {shouldShowInvoices && (
                <>
                    <Table
                        tableHeaders={tableHeaders}
                        setTableHeaders={setTableHeaders}
                        tableContents={initInvoicesTableContents(
                            invoices?.items
                        )}
                        menuItems={(data) => invoiceMenuItems(data)}
                    />
                    <PaginationNav
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalCount={invoices.totalCount}
                        totalPages={invoices.totalPages}
                        currentPage={pageNumber}
                        hasPreviousPage={invoices.hasPreviousPage}
                        hasNextPage={invoices.hasNextPage}
                    />
                </>
            )}
        </>
    );

    function InvoiceLoadingError() {
        return (
            <FormMessageBox
                variant="error"
                title={"Error loading invoices"}
                message={
                    "We could not load your invoice data, please try again in 10 minutes"
                }
            />
        );
    }

    function invoiceMenuItems(data: ApiInvoiceItem): TableMenuItem[] {
        const menuItems = [
            {
                key: "downloadInvoice",
                onClick: () =>
                    handleDownloadInvoicePDF(
                        data.invoiceId,
                        data.invoiceNumber
                    ),
                icon: <Download size={20} weight="fill" />,
                text: "Download Invoice PDF",
                isDisabled: isInvoicePDFDownloading,
                isLoading: isInvoicePDFDownloading,
            },
        ];

        if (decodedToken?.role === UserRoles.s2dAdmin) {
            menuItems.push({
                key: "downloadChargeableItems",
                onClick: () =>
                    handleDownloadChargeableItemsXLS(
                        data.invoiceId,
                        data.invoiceNumber
                    ),
                icon: <Download size={20} weight="fill" />,
                text: "Download Chargeable Items XLS",
                isDisabled: isChargeableItemsXLSDownloading,
                isLoading: isChargeableItemsXLSDownloading,
            });
        }
        return menuItems;
    }
}
