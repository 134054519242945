export const driverAnalysisDefaultValues = {
    cpcAndTacho: {
        cpcCardsDueToExpire: null,
        tachoCardsDueToExpire: null,
        cpcCardsExpired: null,
        tachoCardsExpired: null,
    },
    licenceStatus: {
        provisionalLicence: null,
        fullLicence: null,
        categoryCProvisional: null,
        categoryCEProvisional: null,
        categoryDProvisional: null,
        categoryDEProvisional: null,
        categoryCFull: null,
        categoryCEFull: null,
        categoryDFull: null,
        categoryDEFull: null,
    },
    licenceCategories: {
        categoriesDueToExpire: null,
        categoriesExpired: null,
    },
    photocard: {
        photocardsDueToExpire: null,
        photocardsExpired: null,
    },
    newDriver: {
        newLicence: null,
        newCategoryCOrCELicence: null,
        newCategoryDOrDELicence: null,
    },
    eyesight: {
        eyesightRestriction: null,
    },
    nonAddMatch: {
        nonAddressMatches: null,
    },
};
