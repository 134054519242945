import { VehicleRegLookup } from "../models/api/vehicleRegLookup";
import { useS2DApiFetcher } from "./common/fetching";
import { useS2DApiSWR } from "./common/s2dApiSWR";

const getVehicleData = () =>
    useS2DApiFetcher<
        null,
        VehicleRegLookup,
        { vehicleReg: string; token: string }
    >(
        "GET",
        ({ vehicleReg, token }) => ({
            url: `/api/vehicle/search/${vehicleReg}`,
            altToken: token,
        }),
        [500, 405],
        false
    );

export const useVehicleRegLookupSwr = (vehicleReg?: string) => {
    const { data, error, isLoading, isValidating, refetch } =
        useS2DApiSWR<VehicleRegLookup>(
            vehicleReg ? `/api/vehicle/search/${vehicleReg}` : null,
            { preventRedirectOn404: true }
        );
    return {
        vehicleRegLookupData: data,
        vehicleRegLookupError: error,
        vehicleRegLookupIsLoading: isLoading,
        vehicleRegLookupIsValidating: isValidating,
        refetchVehicleRegLookup: refetch,
    };
};

export const useVehicleRegLookup = () => {
    const [getVehicleRegData, isGettingVehicleData] = getVehicleData();

    return {
        vehicleRegLookupData: async (vehicleReg: string, token: string) =>
            await getVehicleRegData({ vehicleReg, token }),
        vehicleRegLookupIsLoading: isGettingVehicleData,
    };
};
