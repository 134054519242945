import { Switch } from "@headlessui/react";
import clsx from "clsx";

export type FormToggleProps = {
    checked: boolean;
    onChange?: (value: boolean) => void;
    disabled?: boolean;
    labelText: string;
    labelSubText?: string;
    className?: string;
    name?: string;
};

function FormToggle({
    checked,
    onChange,
    disabled = false,
    labelText,
    labelSubText,
    className,
    name,
}: FormToggleProps) {
    const switchWidth = "w-[175px]";
    return (
        <div
            className={clsx(
                `flex w-full flex-col items-start gap-3 
                    rounded-sm text-S2D-neutral-10 sm:flex-row
                    sm:items-center sm:justify-between sm:gap-0`,
                className ?? "bg-S2D-neutral-100 p-6"
            )}
        >
            <Switch.Group>
                <Switch.Label className="mr-4">
                    <div>{labelText}</div>
                    {labelSubText && (
                        <div className="pt-1 text-xs text-S2D-neutral-40">
                            {labelSubText}
                        </div>
                    )}
                </Switch.Label>
                <div
                    className={clsx(
                        " h-7 rounded-sm border-[1px] border-S2D-neutral-70",
                        checked ? "bg-S2D-light-green-80" : "bg-S2D-neutral-70"
                    )}
                >
                    <Switch
                        checked={checked}
                        onChange={onChange}
                        name={name}
                        value="enabled"
                        disabled={disabled}
                        className={clsx(
                            `relative inline-flex h-full items-center rounded-sm`,
                            switchWidth,
                            disabled && "opacity-80"
                        )}
                    >
                        <span className="sr-only">{labelText}</span>
                        <div
                            className={clsx(
                                switchWidth,
                                "absolute flex justify-between text-sm "
                            )}
                        >
                            <span className="w-1/2">Enabled</span>
                            <span className="w-1/2">Disabled</span>
                        </div>
                        <span
                            className={clsx(
                                checked
                                    ? "translate-x-[100%]"
                                    : "translate-x-0",
                                `inline-block h-full w-1/2 transform rounded-sm bg-S2D-neutral-100 transition`,
                                checked ? "rounded-l-none " : "rounded-r-none "
                            )}
                        />
                    </Switch>
                </div>
            </Switch.Group>
        </div>
    );
}

export default FormToggle;
