import { Button as MuiButton, Divider, Menu, MenuItem } from "@mui/material";
import { CaretDown, CaretUp, Check, FunnelSimple, Plus } from "phosphor-react";
import { useState } from "react";
import ApiError from "../../api/common/apiError";
import { type ApiParsedFiltersResponse } from "../../models/api/admin/filter";
import { type DriversListFiltersFormValues } from "./DriversListFiltersForm/DriversListFiltersFormValues";

export type DriverListFiltersDropdownProps = {
    onOpenFilters: () => void;
    savedFilters?: ApiParsedFiltersResponse[];
    onApplyFilter: (
        filters?: DriversListFiltersFormValues,
        filterId?: string
    ) => Promise<boolean>;
    onClearFilter: () => void;
    appliedFilter: ApiParsedFiltersResponse;
    filtersError: ApiError | undefined;
    isFiltersLoading: boolean;
};

const DriverListFiltersDropdown: React.FC<DriverListFiltersDropdownProps> = ({
    onOpenFilters,
    savedFilters,
    onApplyFilter,
    onClearFilter,
    appliedFilter,
    filtersError,
    isFiltersLoading,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleFilterSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseFilterSelect = () => {
        setAnchorEl(null);
    };

    const openAddFilter = () => {
        onOpenFilters();
        handleCloseFilterSelect();
    };

    return (
        <div className="w-full">
            <MuiButton
                id="filters-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleFilterSelect}
                disableRipple
                sx={{
                    textTransform: "none",
                    padding: 0,
                    minWidth: 203,
                    width: "100%",
                }}
            >
                <div
                    className={`flex h-12 w-full min-w-full flex-row items-center justify-between
                             rounded-sm px-6 font-semibold 
                             text-black transition-all hover:bg-S2D-surface-light-4 focus:outline-none focus:ring-1 focus:ring-black
                              ${open && "bg-S2D-surface-light-4"}`}
                >
                    <div className="flex gap-3 pr-3">
                        <FunnelSimple size={20} />
                        {appliedFilter?.name ?? <>Filters</>}
                    </div>
                    <div>
                        {open ? (
                            <CaretUp size={20} weight="fill" />
                        ) : (
                            <CaretDown size={20} weight="fill" />
                        )}
                    </div>
                </div>
            </MuiButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseFilterSelect}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    disablePadding: true,
                }}
                disableScrollLock
            >
                {filtersError && (
                    <MenuItem
                        sx={{
                            "&:hover": {
                                cursor: "not-allowed",
                                backgroundColor: "white",
                            },
                            height: 48,
                            minWidth: 203,
                        }}
                        key={"filter-error"}
                    >
                        <div className="opacity-60">
                            Could not retrieve filters
                        </div>
                    </MenuItem>
                )}
                {isFiltersLoading && (
                    <MenuItem
                        sx={{
                            "&:hover": {
                                cursor: "not-allowed",
                                backgroundColor: "white",
                            },
                            height: 48,
                            minWidth: 203,
                        }}
                        key={"filter-error"}
                    >
                        <div className="opacity-60">loading...</div>
                    </MenuItem>
                )}
                {savedFilters &&
                    savedFilters.map(
                        (f) =>
                            f && (
                                <MenuItem
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "var(--secondary)",
                                        },
                                        height: 48,
                                        minWidth: 203,
                                    }}
                                    key={f.filterId}
                                    onClick={() => {
                                        onApplyFilter(
                                            f.filterDetails,
                                            f.filterId
                                        );
                                        handleCloseFilterSelect();
                                    }}
                                >
                                    <div className="flex w-full flex-row items-center justify-between pl-1">
                                        {f.name}
                                        <div className="pl-3">
                                            {appliedFilter?.filterId ===
                                                f.filterId && (
                                                <Check weight="fill" />
                                            )}
                                        </div>
                                    </div>
                                </MenuItem>
                            )
                    )}
                <Divider />
                <MenuItem
                    onClick={() => openAddFilter()}
                    sx={{
                        "&:hover": { backgroundColor: "var(--secondary)" },
                        height: 48,
                    }}
                >
                    <div className="flex flex-row items-center justify-between">
                        <Plus size={18} weight="bold" />
                        <div className="pl-3 font-bold">Add filter</div>
                    </div>
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => {
                        onClearFilter();
                        handleCloseFilterSelect();
                    }}
                    sx={{
                        "&:hover": { backgroundColor: "var(--secondary)" },
                        height: 48,
                    }}
                >
                    <div className="flex flex-row items-center justify-between">
                        <div className="pl-1 font-bold">Clear filter</div>
                    </div>
                </MenuItem>
            </Menu>
        </div>
    );
};
export default DriverListFiltersDropdown;
