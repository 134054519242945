import DialogBox from "../DialogBox";
import { BulkInvoicesForm } from "./BulkInvoicesForm";

type BulkInvoicesModalProps = {
    isOpen: boolean;
    onClose: () => void;
};
export const BulkInvoicesModal: React.FC<BulkInvoicesModalProps> = ({
    isOpen,
    onClose,
}) => {
    return (
        <DialogBox
            isOpen={isOpen}
            onClose={onClose}
            title={"Generate Bulk Invoices"}
            description={"Choose a date range before generating invoices."}
            closeButton
        >
            <BulkInvoicesForm onClose={onClose} />
        </DialogBox>
    );
};
