export type Alert = {
    severity: AlertSeverity;
    type: AlertType;
    safe2DrivePostcode?: string;
    dvlaPostcode?: string;
    entitlementCode?: string;
    entitlementCodes?: string[];
    expiryDate?: string;
    tachocardNumber?: string;
    dateReminderScheduled?: string;
    dateReminderSent?: string;
    message: string;
};

export type AlertSeverity = "Green" | "Amber" | "Red";

export enum AlertType {
    AddressNonMatch = "AddressNonMatch",
    EntitlementExpired = "EntitlementExpired",
    EntitlementDueToExpire = "EntitlementDueToExpire",
    TachocardExpired = "TachocardExpired",
    TachocardDueToExpire = "TachocardDueToExpire",
    CpcExpired = "CpcExpired",
    CpcDueToExpire = "CpcDueToExpire",
    PhotocardExpired = "PhotocardExpired",
    PhotocardDueToExpire = "PhotocardDueToExpire",
}

export type DriverAlertDto = {
    alertIds: AlertType[];
};
