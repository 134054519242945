import { CompanyRiskProfile } from "../models/app/admin/company";

export type SimplifiedColors = "grey" | "green" | "yellow" | "red";

export const getSimplifiedDriverPointsColor = (
    points: number | null,
    companyRiskProfiles?: CompanyRiskProfile[]
): SimplifiedColors => {
    if (points === null || points === undefined) {
        return "grey";
    }

    const severityLevelOne = 0;
    const severityLevelTwo = companyRiskProfiles
        ? companyRiskProfiles[1].lowerThreshold
        : 3;
    const severityLevelThree = companyRiskProfiles
        ? companyRiskProfiles[2].lowerThreshold
        : 7;

    if (points >= severityLevelOne && points <= severityLevelTwo - 1) {
        return "green";
    } else if (points >= severityLevelTwo && points <= severityLevelThree - 1) {
        return "yellow";
    } else if (points >= severityLevelThree) {
        return "red";
    } else {
        return "grey";
    }
};
