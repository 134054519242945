import {
    ApiInvoiceAddressItem,
    ApiInvoiceAddressResponse,
} from "../../models/api/admin/invoiceAddress";
import { queryParamConnector } from "../../utils/queryParamHelpers";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useGetInvoiceAddresses = (
    companyId?: string,
    pageNumber?: number,
    pageSize?: number
) => {
    const baseUrl = "api/invoices/addresses";
    const pathArray = [baseUrl];

    if (companyId) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`CompanyId=${companyId}`);
    }

    if (pageNumber) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`PageNumber=${pageNumber}`);
    }

    if (pageSize) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`PageSize=${pageSize}`);
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiInvoiceAddressResponse>(pathArray.join(""));

    return {
        invoiceAddressesData: data,
        refetchInvoiceAddresses: refetch,
        invoiceAddressesError: error,
        isLoadingInvoiceAddresses: isLoading,
    };
};

const useAddInvoiceAddress = () =>
    useS2DApiFetcher<
        ApiInvoiceAddressItem,
        string,
        { dto: ApiInvoiceAddressItem }
    >(
        "POST",
        ({ dto }) => ({
            url: `api/invoices/addresses`,
            body: dto,
        }),
        [],
        true
    );

const useEditInvoiceAddress = () =>
    useS2DApiFetcher<
        ApiInvoiceAddressItem,
        string,
        {
            invoiceAddressId: string;
            dto: ApiInvoiceAddressItem;
        }
    >(
        "PUT",
        ({ invoiceAddressId, dto }) => ({
            url: `api/invoices/addresses/${invoiceAddressId}`,
            body: dto,
        }),
        [],
        true
    );

const useDeleteInvoiceAddress = () =>
    useS2DApiFetcher<null, string, { invoiceAddressId: string }>(
        "DELETE",
        ({ invoiceAddressId }) => ({
            url: `api/invoices/addresses/${invoiceAddressId}`,
        }),
        [],
        true
    );

export const useInvoiceAddress = () => {
    const [addInvoiceAddress, isAddingInvoiceAddress] = useAddInvoiceAddress();
    const [editInvoiceAddress, isEditingInvoiceAddress] =
        useEditInvoiceAddress();
    const [deleteInvoiceAddress, isDeletingInvoiceAddress] =
        useDeleteInvoiceAddress();

    return {
        addInvoiceAddress: async (dto: ApiInvoiceAddressItem) =>
            await addInvoiceAddress({ dto }),
        isAddingInvoiceAddress,
        editInvoiceAddress: async (
            invoiceAddressId: string,
            dto: ApiInvoiceAddressItem
        ) => await editInvoiceAddress({ invoiceAddressId, dto }),
        isEditingInvoiceAddress,
        deleteInvoiceAddress: async (invoiceAddressId: string) =>
            await deleteInvoiceAddress({ invoiceAddressId }),
        isDeletingInvoiceAddress,
    };
};
