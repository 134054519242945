import * as yup from "yup";
import { type TransferDate } from "../../pages/Admin/AdminFileSafe";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaKeys } from "../../models/shared/schemaKeysHelper";
import { dateValidation } from "../../utils/dateValidationYup";

const useFileSafeFilterForm = () => {
    const fileSafeFilterSchema: SchemaKeys<FileSafeFilterFormValues> = {
        from: dateValidation.nullable(),
        to: dateValidation
            .min(yup.ref("from"), "'to' date cannot be before 'from' date.")
            .nullable(),
        companyId: yup.string().nullable(),
        departmentId: yup.string().nullable(),
    };

    const validationSchema = yup.object().shape(fileSafeFilterSchema);

    const defaultFormValues: FileSafeFilterFormValues = {
        from: null,
        to: null,
        companyId: null,
        departmentId: null,
    };

    const methods = useForm<FileSafeFilterFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: defaultFormValues,
    });

    return { methods };
};

export type FileSafeFilterFormValues = TransferDate & {
    companyId: string | null;
    departmentId: string | null;
};

export default useFileSafeFilterForm;
