import { type ApiStaffMembersResponse } from "../../models/api/safe2DriveAdmin/staff";
import {
    type EditFileSafeClaimDto,
    type AddStaffMemberDto,
    type EditStaffMemberDto,
    StaffResetPasswordDto,
} from "../../models/app/safe2DriveAdmin/staff";
import { UserStatus } from "../../models/shared/statuses";
import { queryParamConnector } from "../../utils/queryParamHelpers";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

const useAddStaffMember = () =>
    useS2DApiFetcher<AddStaffMemberDto, string, { dto: AddStaffMemberDto }>(
        "POST",
        ({ dto }) => ({
            url: "api/admin/users",
            body: dto,
        }),
        [422],
        true
    );

const useEditStaffMember = () =>
    useS2DApiFetcher<
        EditStaffMemberDto,
        string,
        { userId: string; dto: EditStaffMemberDto }
    >(
        "PUT",
        ({ userId, dto }) => ({
            url: `api/admin/users/${userId}`,
            body: dto,
        }),
        [],
        true
    );

const useEditFileSafeClaim = () =>
    useS2DApiFetcher<
        EditFileSafeClaimDto,
        string,
        { dto: EditFileSafeClaimDto }
    >(
        "PUT",
        ({ dto }) => ({
            url: `api/admin/users/claims/filesafe`,
            body: dto,
        }),
        [],
        true
    );

const useDeleteStaffMember = () =>
    useS2DApiFetcher<string, string, { userId: string }>(
        "DELETE",
        ({ userId }) => ({
            url: `api/admin/users/${userId}`,
        }),
        [],
        true
    );

const useResetPassword = () =>
    useS2DApiFetcher<
        StaffResetPasswordDto,
        string,
        { dto: StaffResetPasswordDto }
    >(
        "POST",
        ({ dto }) => ({
            url: `api/admin/users/trigger-password-reset`,
            body: dto,
        }),
        [],
        true
    );

export const useStaff = () => {
    const [addStaffMember, isAddingStaffMember] = useAddStaffMember();
    const [editStaffMember, isEditingStaffMember] = useEditStaffMember();
    const [editFileSafeClaim, isEditingFileSafeClaim] = useEditFileSafeClaim();
    const [deleteStaffMember, isDeletingStaffMember] = useDeleteStaffMember();
    const [resetPassword, isResettingPassword] = useResetPassword();

    return {
        addStaffMember: async (dto: AddStaffMemberDto) =>
            await addStaffMember({ dto }),
        isAddingStaffMember,
        editStaffMember: async (userId: string, dto: EditStaffMemberDto) =>
            await editStaffMember({ userId, dto }),
        isEditingStaffMember,
        editFileSafeClaim: async (dto: EditFileSafeClaimDto) =>
            await editFileSafeClaim({ dto }),
        isEditingFileSafeClaim,
        deleteStaffMember: async (userId: string) =>
            await deleteStaffMember({ userId }),
        isDeletingStaffMember,
        resetPassword: async (dto: StaffResetPasswordDto) =>
            await resetPassword({ dto }),
        isResettingPassword,
    };
};

export const useGetStaff = (
    pageNumber: number,
    pageSize: number,
    search?: string,
    status?: UserStatus
) => {
    const pathArray = [`/api/admin/users`];

    pathArray.push(queryParamConnector(pathArray));
    pathArray.push(`PageNumber=${pageNumber}&PageSize=${pageSize}`);

    if (search) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`Search=${search}`);
    }
    if (status) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`Status=${status}`);
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiStaffMembersResponse>(pathArray.join(""));

    return {
        staffData: data,
        staffError: error,
        isLoadingStaff: isLoading,
        refetchStaff: refetch,
    };
};
