import { SetStateAction } from "react";
import DialogBox from "../../../DialogBox";
import AddInvoiceForm from "./AddInvoiceForm/AddInvoiceForm";
import { AddOrEditModalStates } from "../CompanyBillingTab";
import { capitaliseWord } from "../../../../utils/capitaliseWord";
import { ApiDepartmentItem } from "../../../../models/api/admin/department";
import { ApiInvoiceReferenceNumberItem } from "../../../../models/api/admin/invoiceReferenceNumber";

type AddInvoiceModalProps = {
    companyId: string;
    parentId: string | undefined;
    departments: ApiDepartmentItem[];
    invoiceReferenceNumbers: ApiInvoiceReferenceNumberItem[];
    isOpen: boolean;
    setModalState: (value: SetStateAction<AddOrEditModalStates>) => void;
    refetchInvoices: () => void;
    type: AddOrEditModalStates;
    shouldInvoiceParent: boolean;
};

const AddInvoiceModal: React.FC<AddInvoiceModalProps> = ({
    companyId,
    parentId,
    departments,
    invoiceReferenceNumbers,
    isOpen,
    setModalState,
    refetchInvoices,
    type = "closed",
    shouldInvoiceParent
}) => {
    return (
        <DialogBox
            isOpen={isOpen}
            onClose={() => setModalState("closed")}
            title={`${capitaliseWord(type)} invoice`}
            size="xl"
            closeButton
        >
            {type === "add" && (
                <AddInvoiceForm
                    companyId={companyId}
                    parentId={parentId}
                    departments={departments}
                    invoiceReferenceNumbers={invoiceReferenceNumbers}
                    setModalState={setModalState}
                    refetchInvoices={refetchInvoices}
                    shouldInvoiceParent={shouldInvoiceParent}
                />
            )}
        </DialogBox>
    );
};

export default AddInvoiceModal;
