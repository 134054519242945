import { AnalysisChartType } from "../../models/app/admin/analysisChart";
import TableFiltersAndActions from "../ReusableComponents/TableFiltersAndActions";
import { type FilterOption } from "../ReusableComponents/FilterDropdown";
import {
    DriversByAgeRangeOptions,
    DriversByAgeRangeValues,
    DriversByPointsOptions,
    DriversByPointsValues,
    FilterLicenceOriginOptions,
    FilterLicenceOriginValues,
    FilterLicenceTypeOptions,
    YearsFullLicenceHeldOptions,
    YearsFullLicenceHeldValues,
} from "../../models/app/admin/licenceAnalysis";
import { capitaliseWord } from "../../utils/capitaliseWord";
import { addSpacesToCamelCase } from "../../utils/addSpacesToCamelCase";
import { type DriverLicenceAnalysisFilterKey } from "./useDriverChartAnalysisFilters";

type DriverChartAnalysisProps = {
    chartType: AnalysisChartType;
    handleApplyFilter: (
        key: DriverLicenceAnalysisFilterKey | string | null
    ) => void;
    selectedDropdownFilter: DriverLicenceAnalysisFilterKey | string | null;
    customOptions: FilterOption<string>[];
};

const DriverChartAnalysisFilters: React.FC<DriverChartAnalysisProps> = ({
    chartType,
    handleApplyFilter,
    selectedDropdownFilter,
    customOptions,
}) => {
    // endorsements, restrictions and licence category provide a single string value
    // everything else provides a relevant key for that chart type
    let filterOptions: FilterOption<DriverLicenceAnalysisFilterKey | string>[];
    switch (chartType) {
        case AnalysisChartType.DriversByPoints:
            filterOptions = (
                Object.keys(DriversByPointsOptions) as Array<
                    keyof typeof DriversByPointsOptions
                >
            ).map((key) => ({
                value: key as DriversByPointsOptions,
                label: DriversByPointsValues[key],
            }));
            break;
        case AnalysisChartType.DriversByAgeRange:
            filterOptions = (
                Object.keys(DriversByAgeRangeOptions) as Array<
                    keyof typeof DriversByAgeRangeOptions
                >
            ).map((key) => ({
                value: key as DriversByAgeRangeOptions,
                label: DriversByAgeRangeValues[key],
            }));
            break;
        case AnalysisChartType.EndorsementCodes:
            filterOptions = customOptions;
            break;
        case AnalysisChartType.DriversByLicenceType:
            filterOptions = (
                Object.keys(FilterLicenceTypeOptions) as Array<
                    keyof typeof FilterLicenceTypeOptions
                >
            ).map((key) => ({
                value: key as FilterLicenceTypeOptions,
                label: capitaliseWord(addSpacesToCamelCase(key)),
            }));
            break;
        case AnalysisChartType.DriverRestrictions:
            filterOptions = customOptions;
            break;
        case AnalysisChartType.DriversByLicenceCategory:
            filterOptions = customOptions;
            break;
        case AnalysisChartType.DriversByLicenceOrigin:
            filterOptions = (
                Object.keys(FilterLicenceOriginOptions) as Array<
                    keyof typeof FilterLicenceOriginOptions
                >
            ).map((key) => ({
                value: key as FilterLicenceOriginOptions,
                label: FilterLicenceOriginValues[key],
            }));
            break;
        case AnalysisChartType.YearsFullLicenceHeld:
            filterOptions = (
                Object.keys(YearsFullLicenceHeldOptions) as Array<
                    keyof typeof YearsFullLicenceHeldOptions
                >
            ).map((key) => ({
                value: key as YearsFullLicenceHeldOptions,
                label: YearsFullLicenceHeldValues[key],
            }));
            break;
        default:
            filterOptions = [];
            break;
    }

    return (
        <TableFiltersAndActions
            selectedDropdownFilter={selectedDropdownFilter}
            dropdownFilterOptions={filterOptions}
            handleDropdownFilterChange={(filter) => handleApplyFilter(filter)}
            dropdownFilterLabel={"Filters"}
        />
    );
};

export default DriverChartAnalysisFilters;
