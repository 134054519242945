import clsx from "clsx";
import { GroupBase, OptionProps } from "react-select";

const FormSearchDropdownOption = ({
    children,
    innerProps,
    innerRef,
    isSelected,
    isFocused,
    isDisabled,
}: OptionProps<unknown, boolean, GroupBase<unknown>>) => (
    <div
        ref={innerRef}
        className={clsx(
            "relative cursor-default select-none py-2 px-4 transition-all hover:bg-S2D-light-green-80",
            !isDisabled && "hover:bg-surface-accent",
            isSelected && "bg-surface-accent",
            isDisabled && "text-disabled-text",
            isFocused && "bg-surface-accent"
        )}
        {...innerProps}
    >
        {children}
    </div>
);

export default FormSearchDropdownOption;
