import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { X } from "phosphor-react";
import { Controller, useForm } from "react-hook-form";
import Button from "../Button";
import Modal from "../Modal";
import { useDvlaChecks } from "../../api/admin/checks";
import FormDropdown from "../FormDropdown";
import FormTextInput from "../FormTextInput";
import { errorToast, successToast } from "../../toast";
import { appPaths } from "../../Routes";
import { CheckStatus } from "../../models/shared/statuses";

type MarkAsFailedModalProps = {
    isOpen: boolean;
    onClose: () => void;
    driverId: string;
};

type MarkAsFailedFormValues = {
    reasonForFailure: string;
    description?: string;
};

const validationSchema = yup.object().shape({
    reasonForFailure: yup.string().required("Reason for failure is required."),
    description: yup.string().when("reasonForFailure", {
        is: "Other",
        then: yup
            .string()
            .required("Description is required.")
            .max(50, "Description cannot be longer than 50 characters."),
    }),
});

const MarkAsFailedModal = ({
    isOpen,
    onClose,
    driverId,
}: MarkAsFailedModalProps) => {
    const { updateCheckStatus, isUpdatingCheckStatus } = useDvlaChecks();

    const methods = useForm<MarkAsFailedFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    const watchFailureOption = methods.watch("reasonForFailure");

    const submitHandler = async (data: MarkAsFailedFormValues) => {
        const failureReason =
            data.reasonForFailure === "Other"
                ? data.description
                : data.reasonForFailure;

        const response = await updateCheckStatus(driverId, {
            statusId: CheckStatus.Failed,
            failureReason,
        });

        if (!response.success) {
            errorToast("Failed to mark as failed.");
            return;
        }

        successToast("Successfully marked as failed.");
        window.location.href = appPaths.adminEditDriver(driverId);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            size="large"
            classNames="overflow-visible"
        >
            <form onSubmit={methods.handleSubmit(submitHandler)}>
                <div className="p-5">
                    <div className="flex items-center justify-between">
                        <p className="text-xl font-medium">Mark as failed</p>
                        <X
                            size={26}
                            weight="duotone"
                            onClick={() => onClose()}
                            className="cursor-pointer"
                        />
                    </div>
                    <div className="py-2" />
                    <Controller
                        name="reasonForFailure"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdown
                                required
                                label="Reason for failure"
                                options={[
                                    {
                                        label: "Invalid licence number",
                                        value: "Invalid licence number",
                                    },
                                    {
                                        label: "Other",
                                        value: "Other",
                                    },
                                ]}
                                value={value}
                                onChange={onChange}
                                pleaseSelectText="Select reason for failure"
                                error={
                                    methods.formState.errors.reasonForFailure
                                }
                            />
                        )}
                    />
                    <div className="py-2" />

                    {watchFailureOption === "Other" && (
                        <FormTextInput
                            required
                            label="Description"
                            id="description"
                            placeholder="Enter description"
                            error={methods.formState.errors.description}
                            {...methods.register("description")}
                        />
                    )}
                </div>
                <div className="flex flex-col-reverse justify-between rounded-b-sm border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                    <Button
                        variant="tertiary"
                        onClick={() => onClose()}
                        className="w-full justify-center focus:ring-0 md:w-auto "
                    >
                        Cancel
                    </Button>
                    <div className="flex flex-col-reverse md:flex-row md:justify-between md:gap-2">
                        <Button
                            type="submit"
                            loading={isUpdatingCheckStatus}
                            className="w-full justify-center md:w-auto"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default MarkAsFailedModal;
