import { ArrowSquareOut } from "phosphor-react";
import clsx from "clsx";
import { AlertTitle } from "../../../models/api/admin/dashboard";

export interface InfoCardProps {
    icon: JSX.Element;
    count: number;
    title: AlertTitle;
    subText?: string;
    highlightColour?:
        | "border-S2D-error-40"
        | "border-S2D-warning-90"
        | "border-S2D-success-80";
    backgroundColour?:
        | "bg-S2D-neutral-100"
        | "bg-S2D-error-40"
        | "bg-S2D-warning-90"
        | "bg-S2D-success-80";
    fontSize?: string;
    onClick?: (alertTitle: AlertTitle) => void;
}

export default function InfoCard({
    icon,
    count,
    title,
    subText,
    highlightColour,
    backgroundColour: bgColour = "bg-S2D-neutral-100",
    fontSize = "text-[40px]",
    onClick,
}: InfoCardProps) {
    const baseCardClasses =
        "rounded-sm border-l-[12px] p-5 pr-[24px] pb-4  text-S2D-neutral-30";
    const cardClasses = `${baseCardClasses} ${bgColour} ${highlightColour}`;

    return (
        <>
            <div className={cardClasses} key={title}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-row items-center gap-3">
                        <div>{icon}</div>
                        <span className={fontSize}>{count}</span>
                        <span className="inline-block max-w-[120px] text-[14px] font-semibold leading-tight">
                            {title.toLocaleUpperCase()}
                        </span>
                    </div>
                    {onClick && (
                        <div
                            className={clsx(
                                `hidden rounded border-2 border-S2D-neutral-10 p-2 text-S2D-neutral-10 hover:cursor-pointer hover:bg-S2D-surface-light-4 sm:inline`
                            )}
                            onClick={() => onClick(title)}
                        >
                            <ArrowSquareOut size={20} />
                        </div>
                    )}
                </div>
                {subText && <div className="pt-3 text-[14px]">{subText}</div>}
            </div>
        </>
    );
}
