import { Dispatch, SetStateAction } from "react";
import TableFiltersAndActions from "../ReusableComponents/TableFiltersAndActions";
import { UserStatus } from "../../models/shared/statuses";

type StatusOption = {
    label: string;
    value: UserStatus;
};

export type StaffFiltersProps = {
    searchQuery: string;
    setSearchQuery: Dispatch<SetStateAction<string>>;
    statusFilter: UserStatus | null;
    setStatusFilter: Dispatch<SetStateAction<UserStatus | null>>;
    setAddStaffModalOpen: Dispatch<SetStateAction<boolean>>;
    isLoadingStaff: boolean;
    // refetchStaff: () => void;
};

const StaffFilters: React.FC<StaffFiltersProps> = ({
    searchQuery,
    setSearchQuery,
    statusFilter,
    setStatusFilter,
    setAddStaffModalOpen,
    isLoadingStaff
    // refetchStaff,
}) => {
    const statusOptions: StatusOption[] = [
        {
            label: "Live",
            value: UserStatus.Live,
        },
        {
            label: "Disabled",
            value: UserStatus.Disabled,
        },
    ];

    const handleStatusChange = (status: UserStatus | null) => {
        setStatusFilter(status);
        // refetchStaff();
    };

    return (
        <TableFiltersAndActions
            selectedDropdownFilter={statusFilter}
            dropdownFilterOptions={statusOptions}
            handleDropdownFilterChange={handleStatusChange}
            dropdownFilterLabel={"Status"}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchPlaceholder={"Search clients"}
            tailoredAddButtonOnClick={() => setAddStaffModalOpen(true)}
            tailoredAddButtonText={"Add staff member"}
            handleDownloadCsvReport={() => alert("to implement")}
            isReportLoading={false}
            isListLoading={isLoadingStaff}
        />
    );
};

export default StaffFilters;
