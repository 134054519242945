import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { useGetDriversQuery } from "../api/admin/driver";
import { errorToast } from "../toast";
import FormRadioGroup from "./FormRadioGroup";
import FormSearchDropdown, {
    FormSearchDropdownOption,
} from "./FormSearchDropdown/FormSearchDropdown";
import { clientCopy } from "../copy/sharedCopy";
import FormMessageBox from "./FormMessageBox";

export type LinkedDriverSearchDropdownProps = {
    value?: string;
    companyId?: string;
    onChange: (
        value: string | null,
        option: FormSearchDropdownOption | null
    ) => void;
    disabled?: boolean;
    required?: boolean;
    error?: FieldError;
};

export type LinkedDriver = {
    driverId: string | null;
    firstName: string | null;
    lastName: string | null;
};

const LinkedDriverSearchDropdown: React.FC<LinkedDriverSearchDropdownProps> = ({
    value,
    companyId,
    onChange,
    error,
    disabled,
}) => {
    const defaultType = value ? "nominatedDriver" : "mainDriver";
    const [linkedDriverType, setLinkedDriverType] = useState(defaultType);
    const { getLinkedDrivers } = useGetDriversQuery();

    const loadOptions = async (
        value: string,
        callback: (options: FormSearchDropdownOption[]) => void
    ) => {
        const response = await getLinkedDrivers(companyId ?? "", value ?? "");

        if (!companyId) {
            errorToast(clientCopy.noClientIdError);
            return;
        }

        if (response.success) {
            const filteredDriverList = response.content.map((d) => ({
                value: d.driverId + " " + d.firstName + " " + d.lastName,
                label: `${d.firstName} ${d.lastName} (${
                    d.licenceNumber ?? "no licence information"
                })`,
            }));

            callback(filteredDriverList ?? []);
        }
    };

    const debouncedLoadOptions = AwesomeDebouncePromise(loadOptions, 700, {
        onlyResolvesLast: true,
    });

    const handleLinkedDriverTypeChange = (type: string) => {
        setLinkedDriverType(type);

        if (type === "mainDriver") {
            onChange(null, null);
        }
    };

    // reset main/nominated to default values when form is reset
    useEffect(() => {
        if (!value) {
            handleLinkedDriverTypeChange(defaultType);
        }
    }, [value]);

    return (
        <>
            <FormRadioGroup
                options={[
                    {
                        id: "mainDriver",
                        label: "Main Driver",
                        caption:
                            "This driver is the main driver of the vehicle.",
                    },
                    {
                        id: "nominatedDriver",
                        label: "Nominated Driver",
                        caption:
                            "This driver is the spouse of the main driver of the vehicle, a relative or a nominated driver.",
                    },
                ]}
                value={linkedDriverType}
                onChange={handleLinkedDriverTypeChange}
            />
            {linkedDriverType === "nominatedDriver" && (
                <>
                    {!companyId && (
                        <FormMessageBox
                            variant="error"
                            title="Please choose a client"
                            message="This driver must have an associate client before we can choose their primary driver."
                        />
                    )}
                    <FormSearchDropdown
                        label="Main driver"
                        tooltip="If you are not the main driver, search for the main driver here"
                        defaultInputValue={
                            value !== "null null" ? value : undefined
                        }
                        loadOptions={debouncedLoadOptions}
                        onChange={onChange}
                        error={error}
                        disabled={disabled}
                        isClearable
                    />
                </>
            )}
        </>
    );
};

export default LinkedDriverSearchDropdown;
