import { useMemo, useState } from "react";
import useDvlaChecksFilterForm, {
    type DvlaChecksFilterFormValues,
} from "./useDvlaChecksFilterForm";
import DialogBox from "../DialogBox";
import DvlaChecksFilterForm from "./DvlaChecksFilterForm";
import HeadlessGenericDropdown, {
    DropdownItem,
} from "../ReusableComponents/HeadlessGenericDropdown";
import FormTextInput from "../FormTextInput";
import { MagnifyingGlass } from "phosphor-react";
import FilterModalDropdownButton from "../ReusableComponents/FilterModalDropdownButton";
import {
    UkDvlaChecksFilters,
    type DvlaCheckItem,
} from "../../models/api/safe2DriveAdmin/dvlaChecks";
import { type TableCheckbox } from "../ReusableComponents/Table/tableTypes";
import { determineDvlaChecksBulkActionType } from "./determineDvlaChecksBulkActionType";
import Button from "../Button";
import {
    BulkActionTypes,
    useDvlaBulkActions,
} from "../../api/safe2Drive/dvlaBulkActions";
import EditCheckDateDialog from "../DvlaChecksTable/EditCheckDateDialog";
import { extractDriverIds } from "../../utils/extractDriverIdsFromCheckbox";
import { errorToast } from "../../toast";
import { handleDvlaBulkActionResponse } from "./handleDvlaBulkActionResponse";

type DvlaChecksTableFiltersProps = {
    searchQuery: string | null;
    setSearchQuery: (value: string) => void;
    dvlaCheckFilters: DvlaChecksFilterFormValues;
    handleFormSubmit: (value: DvlaChecksFilterFormValues) => void;
    selectedCheckboxes: TableCheckbox<DvlaCheckItem>[];
    refetchDvlaChecks: () => void;
    isLoadingDvlaChecks: boolean;
};

const DvlaChecksTableFilters: React.FC<DvlaChecksTableFiltersProps> = ({
    searchQuery,
    setSearchQuery,
    dvlaCheckFilters,
    handleFormSubmit,
    selectedCheckboxes,
    refetchDvlaChecks,
    isLoadingDvlaChecks
}) => {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    const { methods } = useDvlaChecksFilterForm(dvlaCheckFilters);

    const handleCloseFilterModal = () => {
        setIsFilterModalOpen(false);
    };

    const clearAll = () => {
        handleFormSubmit({});
        handleCloseFilterModal();
    };

    const areFieldsDefined = (obj: (UkDvlaChecksFilters | undefined)[]) => {
        return Object.values(obj).some(Boolean);
    };

    const handleSubmit = (value: DvlaChecksFilterFormValues) => {
        handleFormSubmit(value);
        handleCloseFilterModal();
    };

    const filterIsApplied = useMemo(
        () => areFieldsDefined(Object.values(dvlaCheckFilters)),
        [JSON.stringify(dvlaCheckFilters)]
    );

    // bulk actions
    const bulkActions = useDvlaBulkActions();
    const bulkActionOptions: DropdownItem[] | undefined = useMemo(
        () =>
            determineDvlaChecksBulkActionType(
                selectedCheckboxes,
                refetchDvlaChecks,
                bulkActions
            ),
        [selectedCheckboxes]
    );

    const handleBulkEditNextCheckDate = async (newNextCheckDate: Date) => {
        if (!selectedCheckboxes.length) {
            errorToast("No drivers are selected.");
            return;
        }

        const driverIds: string[] = extractDriverIds(selectedCheckboxes);

        const response = await bulkActions.editNextCheckDate({
            driverIds,
            newNextCheckDate,
        });
        if (response.success) {
            bulkActions.setIsEditNextCheckFilterOpen(false);
            refetchDvlaChecks();
            handleDvlaBulkActionResponse(
                BulkActionTypes.EditCheckDate,
                response.content
            );
        } else {
            errorToast(
                "Failed to perform bulk action. Please try again later."
            );
        }
    };

    return (
        <>
            <div className="flex flex-wrap items-start justify-between gap-6">
                <div className="flex w-full flex-col flex-wrap gap-6 md:w-fit md:flex-row">
                    <FormTextInput
                        StartAdornmentIcon={MagnifyingGlass}
                        value={searchQuery ?? ""}
                        placeholder="Search"
                        className="w-full md:w-[320px]"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        disabled={isLoadingDvlaChecks}
                    />
                    <FilterModalDropdownButton
                        isOpen={isFilterModalOpen ?? false}
                        setIsOpen={setIsFilterModalOpen}
                        filterIsApplied={filterIsApplied}
                    />
                    {filterIsApplied && (
                        <Button
                            variant="tertiary"
                            onClick={clearAll}
                            className="min-w-fit grow justify-center md:flex-none"
                        >
                            Clear all
                        </Button>
                    )}
                    <HeadlessGenericDropdown
                        className={"w-full md:w-fit"}
                        isLoading={
                            bulkActions.isTriggeringBulkAction ||
                            bulkActions.isDownloadingDeclarationForms
                        }
                        disabled={!bulkActionOptions}
                        dropdownPlaceholder="Bulk actions"
                        dropdownData={bulkActionOptions}
                    />
                </div>
                {/* <div className="flex w-full flex-col gap-6 md:w-fit md:flex-row">
                    <Button
                        className="min-w-fit grow justify-center"
                        onClick={() => alert("not yet implemented")}
                    >
                        Begin check
                    </Button>
                </div> */}
            </div>
            <DialogBox
                title="Filters"
                size="xl"
                closeButton
                isOpen={isFilterModalOpen}
                onClose={handleCloseFilterModal}
            >
                <DvlaChecksFilterForm
                    methods={methods}
                    clearAll={clearAll}
                    onSubmit={handleSubmit}
                />
            </DialogBox>
            <EditCheckDateDialog
                isOpen={bulkActions.isEditNextCheckFilterOpen}
                onClose={() => bulkActions.setIsEditNextCheckFilterOpen(false)}
                handleUpdateNextCheckDate={handleBulkEditNextCheckDate}
                isUpdatingNextCheck={bulkActions.isEditingNextCheckDate}
            />
        </>
    );
};

export default DvlaChecksTableFilters;
