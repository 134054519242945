import { X, File } from "phosphor-react";

type FileIconListProps = {
    fileArray: File[];
    handleRemoveFile: (index: number) => void;
};

const FileIconList: React.FC<FileIconListProps> = ({
    fileArray,
    handleRemoveFile,
}) => {
    return (
        <div className="flex flex-row gap-4 items-center justify-center max-w-full flex-wrap">
            {fileArray.map((file, index) => (
                <div
                    className="flex flex-col items-center w-28 h-[7.75rem] bg-S2D-neutral-99 border border-S2D-neutral-80 rounded-sm relative p-4"
                    key={index}
                >
                    <>
                        <X
                            weight="bold"
                            className="w-4 h-4 font-black absolute top-0 right-0 m-2 hover:cursor-pointer"
                            onClick={() => handleRemoveFile(index)}
                        />
                        <File
                            size="56"
                            className=" text-S2D-dark-green-30"
                            weight="duotone"
                        />
                        <div className="absolute bottom-0 flex items-center h-10 w-full p-2 bg-S2D-light-green-80">
                            {file.name && (
                                <div className="w-full text-ellipsis overflow-hidden whitespace-nowrap text-xs font-bold">
                                    {file.name}
                                </div>
                            )}
                        </div>
                    </>
                </div>
            ))}
        </div>
    );
};

export default FileIconList;
