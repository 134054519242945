import { Scroll } from "phosphor-react";
import { type MandateDetails } from "../../../models/api/admin/mandateDetails";
import { convertDateStringToReadableString } from "../../../utils/convertDateStringToReadableString";
import ActionableStatus from "../../ActionableStatus";
import ActionableStatusTag from "../../ActionableStatus/ActionableStatusTag";
import useDeclarationStatusActions from "./useDeclarationStatusActions";
import { DeclarationType } from "../../../models/app/eDeclarations";
import { ApiDriverByIdResponse } from "../../../models/api/admin/driver";
import { DriverValidationTypes } from "../../../models/app/admin/company";

export type DeclarationStatusExpiredProps = {
    mandateDetails: MandateDetails;
    driverData?: ApiDriverByIdResponse;
    validateByCompanyPreference?: DriverValidationTypes;
    isS2DUser: boolean;
    handleScrollToDocumentUpload: () => void;
};

const DeclarationStatusExpired: React.FC<DeclarationStatusExpiredProps> = ({
    mandateDetails,
    driverData,
    validateByCompanyPreference,
    isS2DUser,
    handleScrollToDocumentUpload,
}) => {
    const {
        declarationId,
        declarationType,
        declarationSigned,
        expiryDate,
        licenceCountryOrigin,
    } = mandateDetails;

    const {
        sendNewEDeclarationAction,
        sendNewPaperDeclarationAction,
        switchToEDeclarationAction,
        switchToPaperDeclarationAction,
        uploadSignedDeclarationAction,
    } = useDeclarationStatusActions(
        declarationId,
        mandateDetails,
        true,
        handleScrollToDocumentUpload,
        driverData
    );

    const statusIndicators = new Array(0);
    if (declarationType === "PaperDeclaration") {
        statusIndicators.push(
            <ActionableStatusTag theme="white" text="Paper" />
        );
    }
    statusIndicators.push(<ActionableStatusTag theme="expired" />);

    const sendStatusActions = () => {
        if (declarationType === DeclarationType.EDeclaration) {
            return [sendNewEDeclarationAction, switchToPaperDeclarationAction];
        } else {
            return isS2DUser
                ? [
                      sendNewPaperDeclarationAction,
                      switchToEDeclarationAction,
                      uploadSignedDeclarationAction,
                  ]
                : [sendNewPaperDeclarationAction, switchToEDeclarationAction];
        }
    };

    const StatusContent = () => {
        return (
            <div className="flex flex-col text-sm">
                <span>
                    Declaration signed :{" "}
                    {convertDateStringToReadableString(declarationSigned)}
                </span>
                <span>
                    Expired: {convertDateStringToReadableString(expiryDate)}
                </span>
            </div>
        );
    };

    return (
        <ActionableStatus
            title="Declaration status"
            theme="grey"
            actionable
            statusIndicators={statusIndicators}
            actions={sendStatusActions()}
            hasEmailAddress={!!driverData?.emailAddress}
            declarationComplete={true}
            licenceCountryOrigin={licenceCountryOrigin ?? undefined}
            driverData={driverData}
            validateByCompanyPreference={validateByCompanyPreference}
        >
            <div className="flex items-center">
                <div className="pr-3">
                    <Scroll size={24} weight="duotone" />
                </div>
                <StatusContent />
            </div>
        </ActionableStatus>
    );
};

export default DeclarationStatusExpired;
