import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { useFormFieldCollectiveValidation } from "../../hooks/useFormFieldCollectiveValidation";
import AccordionItem from "../AccordionItem";
import licenseSample from "../../licence-sample.png";
import FormDropdown from "../FormDropdown";
import FormTextInput from "../FormTextInput";
import { AddDriverFormValues } from "./AddDriverFormValues";
import FormInputMessage from "../FormInputError";
import { errorToast } from "../../toast";
import {
    GREAT_BRITAIN,
    NORTHERN_IRELAND,
    countryList,
} from "../../constants/countries";
import { DeclarationType } from "../../models/app/eDeclarations";

type DriverLicenceInformationFieldsProps = {
    methods: UseFormReturn<AddDriverFormValues>;
    handleDeclarationTypeChange: (value: DeclarationType) => void;
};

const countryOfOriginOptions = countryList.map((country) => ({
    label: country.name,
    value: country.name,
}));

const DriverLicenceInformationFields: React.FC<
    DriverLicenceInformationFieldsProps
> = ({ methods, handleDeclarationTypeChange }) => {
    const isSectionValid =
        useFormFieldCollectiveValidation<AddDriverFormValues>(
            ["licenceNumber", "licenceCountryOrigin"],
            methods
        );

    const control = methods.control;
    const countryOfOrigin = useWatch({ control, name: "licenceCountryOrigin" });

    const handleEditLicenceOrigin = (value: string) => {
        const foreignLicence = methods.getValues("foreignLicence");
        const prevType = methods.getValues("declarationType");

        methods.setValue("licenceCountryOrigin", value);
        if (value === GREAT_BRITAIN) {
            methods.setValue("foreignLicence", false);
        } else {
            if (!foreignLicence) {
                methods.setValue("foreignLicence", true);
                handleDeclarationTypeChange(DeclarationType.PaperDeclaration);
                if (prevType !== DeclarationType.EDeclaration)
                    errorToast(
                        "Declaration type set to paper as this driver has a foreign licence."
                    );
            }
        }
    };

    const originIsNorthernIreland = countryOfOrigin === NORTHERN_IRELAND,
        originIsGreatBritain = countryOfOrigin === GREAT_BRITAIN;

    const licenceNumberHelpText = `Licence number must be ${
        originIsNorthernIreland ? "8" : "16"
    } characters with no spaces`;

    return (
        <AccordionItem title="Add licence information" error={!isSectionValid}>
            <Controller
                name="licenceCountryOrigin"
                control={methods.control}
                render={({ field: { value } }) => (
                    <FormDropdown
                        label="Driving licence country of origin"
                        options={countryOfOriginOptions}
                        value={value}
                        onChange={handleEditLicenceOrigin}
                        pleaseSelectText="Select driving licence country of origin"
                    />
                )}
            />
            <div className="pl-3 pt-1">
                <FormInputMessage helpText="Drivers with a foreign licence must go through paper declaration" />
            </div>
            <FormTextInput
                label="Licence Number"
                id="licence-number"
                placeholder="Enter licence number"
                error={methods.formState.errors.licenceNumber}
                helpText={licenceNumberHelpText}
                {...methods.register("licenceNumber")}
                moreInfo={
                    originIsGreatBritain
                        ? {
                              dialogPrompt: "Where is my licence number?",
                              description:
                                  "Your licence number can be found on your UK Driving Licence at the position illustrated below, this will be 16 characters with no spaces.",
                              content: (
                                  <img
                                      className="w-full max-w-xl"
                                      src={licenseSample}
                                      alt="licence sample MORGA753116SM9IJ"
                                  />
                              ),
                          }
                        : undefined
                }
            />
        </AccordionItem>
    );
};

export default DriverLicenceInformationFields;
