import { useEffect, useState } from "react";
import Button from "../Button";
import Modal from "../Modal";

type ActionableStatusModalBaseProps = {
    isOpen: boolean;
    onClose: () => void;
    heading: string;
    subHeading?: string | null;
    buttonText?: string;
    buttonVariant?: "primary" | "secondary" | "tertiary" | "danger";
    action: () => void;
    disabled?: boolean;
    disabledReason?: string;
    isLoading?: boolean;
};

const ActionableStatusModalBase = ({
    isOpen,
    onClose,
    heading,
    subHeading = "",
    buttonText,
    buttonVariant = "primary",
    action,
    disabled = false,
    disabledReason,
    isLoading = undefined,
}: ActionableStatusModalBaseProps) => {
    // await load before closing modal
    const [hasTriggeredAction, setHasTriggeredAction] = useState(false);
    useEffect(() => {
        // when loading is set to true
        if (!hasTriggeredAction && isLoading) {
            setHasTriggeredAction(true);
            return;
        }

        // close dialog once loading is false again
        if (hasTriggeredAction && !isLoading) {
            onClose();
        }
    }, [isLoading]);

    const ignoreLoadingState = isLoading === undefined;

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            size="medium"
            classNames="p-6"
        >
            <div className="flex flex-col">
                <h2 className="pb-6 text-2xl font-semibold">{heading}</h2>

                <div>
                    {disabled ? (
                        <p className="pb-6">{disabledReason}</p>
                    ) : (
                        <p className="pb-6">{subHeading}</p>
                    )}
                </div>

                <div className="flex-col items-center justify-center">
                    <div className="flex justify-center">
                        <Button
                            className="bg-LC-accent-teal-70 rounded-md border-0 p-3 font-semibold focus:ring-0 focus:ring-transparent"
                            variant={buttonVariant}
                            onClick={() => {
                                action();
                                ignoreLoadingState && onClose();
                            }}
                            disabled={disabled}
                            loading={isLoading}
                        >
                            {buttonText}
                        </Button>
                    </div>
                    <div className="py-2" />
                    <div className="flex justify-center">
                        <Button
                            className="border-0 bg-white p-3"
                            variant="tertiary"
                            onClick={() => onClose()}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ActionableStatusModalBase;
