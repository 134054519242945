import {
    type DvlaCpc,
    type DvlaEndorsement,
    type DvlaEntitlement,
    type DvlaTachocard,
} from "../../models/api/admin/dvla";
import {
    type DvlaLicenceDto,
    type DvlaPersonalInfoDto,
} from "../../models/app/admin/dvla";
import * as yup from "yup";
import {
    conditionalAddressValidation,
    exhaustiveAddressDependencyArray,
} from "../../utils/addressValidation";
import { GREAT_BRITAIN } from "../../constants/countries";
import { LicenceStatus, LicenceType } from "../../models/shared/statuses";
import { DateSchema } from "yup";
import { dateValidation } from "../../utils/dateValidationYup";
import { DriverAddress } from "../../models/shared/address";

/**
 * Extends the Yup module to include the custom
 * method used in below validations
 */
declare module "yup" {
    interface DateSchema {
        datesInOrder(parentProperty: string): this;
    }
}

yup.addMethod<DateSchema>(
    yup.date,
    "datesInOrder",
    function (parentProperty: string) {
        return this.test(
            "datesInOrder",
            "Issue date cannot be greater than expiry date",
            function (expiryDate) {
                const { parent } = this;

                const issueDate = parent[parentProperty];

                // Do not validate unless both dates are present
                if (!issueDate || !expiryDate) return true;

                const isError = issueDate >= expiryDate;

                // Boolean is flipped because Yup triggers error on 'false', not 'true'
                return isError ? false : true;
            }
        );
    }
);

export const EditDvlaPersonalInfoFormValidationSchema = {
    firstName: yup.string().notRequired(),
    lastName: yup.string().notRequired(),
    dateOfBirth: dateValidation
        .notRequired()
        .max(new Date(), "Date of birth must be before today"),
    gender: yup.string().notRequired(),
};

export const EditDvlaDriverAddressFormValidationSchema = {
    address: yup.object().shape(
        {
            address1: conditionalAddressValidation(
                "address1",
                yup.string().required("Address line 1 is required").nullable()
            ).nullable(),
            address2: yup.string().nullable(),
            address3: yup.string().nullable(),
            address4: yup.string().nullable(),
            address5: yup.string().nullable(),
            town: conditionalAddressValidation(
                "addressTown",
                yup.string().required("City is required")
            ).nullable(),
            postcode: conditionalAddressValidation(
                "addressPostcode",
                yup
                    .string()
                    .matches(
                        /^([a-zA-Z][a-hj-yA-HJ-Y]?[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}|GIR ?0A{2})$/,
                        {
                            message: "Postcode is invalid",
                            excludeEmptyString: true,
                        }
                    )
                    .required("Postcode is required")
            ).nullable(),
        },
        exhaustiveAddressDependencyArray
    ),
};

export const EditDvlaLicenceInformationValidationSchema = {
    licenceCountryOrigin: yup.string().notRequired(),
    licenceNumber: yup
        .string()
        .required("Licence number is required")
        .max(64, "Licence number must not exceed 64 characters.")
        .when("licenceCountryOrigin", {
            is: (licenceCountryOrigin: string) => {
                if (licenceCountryOrigin === GREAT_BRITAIN) {
                    return true;
                }

                return false;
            },
            then: yup
                .string()
                .matches(
                    /^[A-Z9]{5}\d[0156]\d([0][1-9]|[12]\d|3[01])\d[A-Z9]{2}\d[A-Z]{2}$/,
                    "Licence number must be 16 characters with no spaces, eg. MORGA753116SM9IJ "
                ),
        }),
    licenceStatus: yup.string().notRequired(),
    licenceType: yup.string().notRequired(),
    licenceValidFrom: dateValidation.notRequired(),
    licenceValidTo: dateValidation
        .notRequired()
        .datesInOrder("licenceValidFrom"),
    licenceIssueNumber: yup
        .string()
        .trim()
        .max(4, "Licence issue number must not exceed 4 characters.")
        .required("Licence issue number is required.")
        .nullable(),
};

export const EditDvlaTachocardFormValidationSchema = {
    tachocardId: yup.string().notRequired().nullable(),
    cardStatus: yup.string().required("card status is required").nullable(),
    number: yup.string().required("card status is required").nullable(),
    validFrom: dateValidation
        .required("valid from date is required")
        .nullable(),
    validTo: dateValidation
        .required("valid to date is required")
        .nullable()
        .datesInOrder("validFrom"),
};

export const EditDvlaCpcFormValidationSchema = {
    cardValidFrom: dateValidation
        .required("valid from date is required")
        .nullable(),
    lgvValidTo: dateValidation
        .notRequired()
        .nullable()
        .datesInOrder("cardValidFrom"),
    pcvValidTo: dateValidation
        .notRequired()
        .nullable()
        .datesInOrder("cardValidFrom"),
};

const today = new Date();

export const EditDvlaEndorsementFormValidationSchema = {
    code: yup.string().required("Required"),
    offenceDate: dateValidation
        .max(today, "Offence date cannot be in the future.")
        .required("Required"),
    convictionDate: dateValidation
        .max(today, "Conviction date cannot be in the future.")
        .notRequired()
        .nullable()
        .transform((value) => {
            if (value) return value;
            return null;
        }),
    points: yup
        .number()
        .min(0)
        .max(12, "The maximum number of points to add is 12")
        .required("Required")
        .typeError("Points field is required and must be a number"),
};

export type EditDvlaFormValues = {
    firstName: string;
    lastName: string;
    dateOfBirth: Date | null;
    gender: string;
    licenceCountryOrigin: string;
    licenceNumber: string;
    licenceType: LicenceType | null;
    licenceStatus: LicenceStatus | null;
    licenceValidFrom: Date | null;
    licenceValidTo: Date | null;
    licenceIssueNumber: string;
    address: DriverAddress;
    endorsements: DvlaEndorsement[];
    // NB categories & entitlements are the same thing
    entitlements: DvlaEntitlement[];
    tachocards: DvlaTachocard[];
    cpc: DvlaCpc;
};

export type EditDvlaPersonalInfoFormValues = DvlaPersonalInfoDto;

export type EditDvlaAddressFormValues = { address: DriverAddress };

export type EditDvlaLicenceInformationFormValues = DvlaLicenceDto;

export type EditDvlaTachocardFormValues = DvlaTachocard;

export type EditDvlaCpcFormValues = DvlaCpc;

export type EditDvlaEntitlementFormValues = {
    entitlements: DvlaEntitlement[];
};

export type EditDvlaEndorsementFormValues = DvlaEndorsement[];
