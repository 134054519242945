import { useState, useEffect } from "react";
import { useDownloadCompaniesDeclarationsReport } from "../../../api/admin/companiesDeclarationsDownload";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { type ApiCompanyResponse } from "../../../models/api/admin/company";
import { type ApiDepartmentItem } from "../../../models/api/admin/department";
import { successToast, errorToast } from "../../../toast";
import { downloadBlobAsFile } from "../../../utils/downloadBlobAsFile";
import { useCompanyDepartmentSelector } from "../../../components/ReusableComponents/CompanyDepartmentSelector";
import { useGetEDeclarations } from "../../../api/admin/eDeclarations";
import useDebounceValue from "../../../hooks/useDebounceValue";
import {
    declarationTableFiltersDefaultValues,
    useDeclarationTableFiltersLocalStorage,
} from "../../../hooks/useDeclarationTableFiltersLocalStorage";
import { SEARCH_BAR_DEBOUNCE_MS } from "../../../constants/filterConstants";

export default function useAdminDeclarations() {
    const [isDeclarationAnalysisOpen, setIsDeclarationAnalysisOpen] =
        useLocalStorage<boolean>("isDeclarationAnalysisOpen", true);
    const {
        currentDepartment: selectedDepartment,
        handleDepartmentChange: setSelectedDepartment,
        selectedCompany,
        handleCompanyChange: setSelectedCompany,
    } = useCompanyDepartmentSelector();
    const [isCsvReportDownloading, setIsCsvReportDownloading] = useState(false);

    const [searchQuery, setSearchQuery] = useState<string>("");
    const query = useDebounceValue(searchQuery, SEARCH_BAR_DEBOUNCE_MS);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useLocalStorage<number>(
        "DriverDeclarationsTablePageSize",
        25
    );
    const [hasPageLoaded, setHasPageLoaded] = useState<boolean>(false);

    const { declarationTableFiltersStore, setDeclarationTableFiltersStore } =
        useDeclarationTableFiltersLocalStorage();

    const {
        eDeclarationsList,
        isLoadingEDeclarationsList,
        eDeclarationsListError,
        refetchEDeclarationsList,
    } = useGetEDeclarations(
        pageNumber,
        pageSize,
        query,
        declarationTableFiltersStore
    );

    // instead of drilling the refetch function down to the analysis actions, catch changes here
    useEffect(() => {
        if (hasPageLoaded) {
            refetchEDeclarationsList();
        }
        setHasPageLoaded(true);
    }, [JSON.stringify(declarationTableFiltersStore)]);

    const handleCompanyChange = (company: ApiCompanyResponse | null) => {
        if (company) {
            setSelectedCompany(company);
            setDeclarationTableFiltersStore({
                ...declarationTableFiltersStore,
                companyQuery: {
                    companyIds: [company.companyId],
                    departmentIds: null,
                    employeeId: null,
                },
            });
        }
    };
    const handleDepartmentChange = (department: ApiDepartmentItem | null) => {
        if (selectedCompany) {
            setSelectedDepartment(department);
            setDeclarationTableFiltersStore({
                ...declarationTableFiltersStore,
                companyQuery: {
                    companyIds: [selectedCompany?.companyId],
                    departmentIds: department
                        ? [department.departmentId]
                        : null,
                    employeeId: null,
                },
            });
        }
    };

    const handleReset = () => {
        setSelectedCompany(null);
        setSelectedDepartment(null);
        setDeclarationTableFiltersStore({
            ...declarationTableFiltersStore,
            companyQuery: null,
        });
    };

    const handleResetFilters = () => {
        setSelectedCompany(null);
        setSelectedDepartment(null);
        setDeclarationTableFiltersStore(declarationTableFiltersDefaultValues);
    };

    const { getDeclarationsReport } = useDownloadCompaniesDeclarationsReport();

    const handleDownloadCsvReport = async () => {
        setIsCsvReportDownloading(true);

        const fileNameArray = ["Declarations.csv"];
        if (selectedDepartment)
            fileNameArray.unshift(selectedDepartment.departmentName);
        if (selectedCompany) fileNameArray.unshift(selectedCompany.companyName);
        const fileName = fileNameArray.join("-");
        try {
            const response = await getDeclarationsReport(declarationTableFiltersStore);
            if (response.success) {
                downloadBlobAsFile(response.content, fileName);
                successToast(`successfully downloaded Declarations report`);
                setTimeout(() => setIsCsvReportDownloading(false), 1000);
            }
        } catch (error) {
            setTimeout(() => setIsCsvReportDownloading(false), 1000);
            errorToast(`failed to download Declarations report`);
        }
    };

    return {
        isDeclarationAnalysisOpen,
        setIsDeclarationAnalysisOpen,
        selectedCompany,
        selectedDepartment,
        isCsvReportDownloading,
        handleCompanyChange,
        handleDepartmentChange,
        handleReset,
        handleResetFilters,
        handleDownloadCsvReport,
        declarationTableFiltersStore,
        setDeclarationTableFiltersStore,
        eDeclarationsList,
        isLoadingEDeclarationsList,
        eDeclarationsListError,
        refetchEDeclarationsList,
        pageSize,
        setPageSize,
        pageNumber,
        setPageNumber,
        searchQuery,
        setSearchQuery,
    };
}
