import useLocalStorage from "../../hooks/useLocalStorage";
import { initDriverDeclarationsTable } from "./DriverDeclarationsTableInit";
import { HiddenCols, TableHeader } from "../ReusableComponents/Table";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../Routes";
import { useEdeclaration } from "../../api/edeclaration";
import { successToast, errorToast } from "../../toast";
import { useUpdateDriverDeclarationType } from "../../api/admin/driver";
import { ApiUpdateDriverDeclarationTypeDto } from "../../models/api/admin/driver";
import { DeclarationType } from "../../models/app/eDeclarations";

const useDriverDeclarationsTable = (refetchEDeclarationsList: () => void) => {
    const navigate = useNavigate();
    const { sendInstantDeclarationInviteEmail } = useEdeclaration();

    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "DriverDeclarationsTableHeaders",
        initDriverDeclarationsTable
    );
    const initHiddenCols = new Array(0);
    tableHeaders.map((item) =>
        initHiddenCols.push({
            id: item.id,
            hidden: item.hide ?? false,
        })
    );
    const [hiddenCols, setHiddenCols] = useLocalStorage<HiddenCols[]>(
        "DriverDeclarationsTableHiddenCols",
        initHiddenCols
    );

    // Click handlers:
    const declarationRequestAction = async (declarationId: string) => {
        const response = await sendInstantDeclarationInviteEmail(declarationId);

        if (response.success) {
            successToast("E-Declaration request sent");
            refetchEDeclarationsList();
        } else {
            errorToast("Error sending E-Declaration request");
        }
    };

    const editDriverClickHandler = (driverId?: string) => {
        if (driverId) navigate(appPaths.adminEditDriver(driverId));
    };

    const [updateDeclarationType, isUpdatingDeclarationType] =
        useUpdateDriverDeclarationType();

    const switchDeclarationTypeAction = async (
        declarationId: string,
        declarationType: DeclarationType
    ) => {
        const dto: ApiUpdateDriverDeclarationTypeDto = {
            declarationType,
        };
        const response = await updateDeclarationType({
            declarationId: declarationId,
            dto,
        });
        if (response.success) {
            successToast("Declaration type updated");
            refetchEDeclarationsList();
        } else {
            errorToast("Error updating declaration type");
        }
    };

    return {
        tableHeaders,
        setTableHeaders,
        hiddenCols,
        setHiddenCols,
        refetchEDeclarationsList,
        switchDeclarationTypeAction,
        isUpdatingDeclarationType,
        editDriverClickHandler,
        declarationRequestAction,
    };
};

export default useDriverDeclarationsTable;
