import {
    ArrowsClockwise,
    Calendar,
    CalendarX,
    CheckCircle,
    Download,
    Timer,
    UserGear,
} from "phosphor-react";
import { type DropdownItem } from "../ReusableComponents/HeadlessGenericDropdown";
import { CheckType } from "../../models/shared/statuses";
import { GREAT_BRITAIN, NORTHERN_IRELAND } from "../../constants/countries";
import { DvlaBulkActionOptionType } from "./determineDvlaChecksBulkActionType";
import {
    BulkActionFormTypes,
    BulkActionTypes,
    useDvlaBulkActions,
} from "../../api/safe2Drive/dvlaBulkActions";
import { DvlaCheckItem } from "../../models/api/safe2DriveAdmin/dvlaChecks";
import { TableCheckbox } from "../ReusableComponents/Table/tableTypes";
import { errorToast, successToast } from "../../toast";
import { downloadFromArrayBuffer } from "../../utils/downloadFromArrayBuffer";
import { handleDvlaBulkActionResponse } from "./handleDvlaBulkActionResponse";
import { extractDriverIds } from "../../utils/extractDriverIdsFromCheckbox";

export const dvlaChecksBulkActionOptions = (
    selectedItems: TableCheckbox<DvlaCheckItem>[],
    refetchDvlaChecks: () => void,
    bulkActions: ReturnType<typeof useDvlaBulkActions>,
    optionType?: DvlaBulkActionOptionType
): DropdownItem[] | undefined => {
    if (!optionType) return [];

    const handleBulkAction = async (type: BulkActionTypes) => {
        if (!selectedItems.length) {
            errorToast("No drivers are selected.");
            return;
        }

        const driverIds: string[] = extractDriverIds(selectedItems);

        const response = await bulkActions.triggerBulkAction(type, driverIds);
        if (response.success) {
            handleDvlaBulkActionResponse(type, response.content);
            refetchDvlaChecks();
        } else {
            errorToast(
                "Failed to perform bulk action. Please try again later."
            );
            if (
                type === BulkActionTypes.InstantCheck ||
                type === BulkActionTypes.RetryCheck
            ) {
                // Need to refetch here as the driver will now get an error status
                refetchDvlaChecks();
            }
        }
    };

    const handleBulkEditNextCheckDate = () =>
        bulkActions.setIsEditNextCheckFilterOpen(true);

    const handleDownloadDeclarationForm = async (type: BulkActionFormTypes) => {
        if (!selectedItems.length) {
            errorToast("No drivers are selected.");
            return;
        }

        const driverIds: string[] = extractDriverIds(selectedItems);

        const response = await bulkActions.downloadDeclarationForms(
            type,
            driverIds
        );
        if (response.success) {
            downloadFromArrayBuffer(response.content, `DeclarationOfConsent`);
            successToast(
                "Successfully downloaded driver declarations of consent."
            );
            refetchDvlaChecks();
        } else {
            errorToast("Failed to download driver declarations of consent.");
        }
    };

    const instantCheckItem: DropdownItem = {
        item: (
            <div className="flex flex-row gap-4 px-4">
                <CheckCircle size={20} weight="fill" />
                Instant check
            </div>
        ),
        id: "instant-check",
        onClick: () => handleBulkAction(BulkActionTypes.InstantCheck),
    };

    const changeToManualItem: DropdownItem = {
        item: (
            <div className="flex flex-row gap-4 px-4">
                <UserGear size={20} weight="fill" />
                Change to manual
            </div>
        ),
        id: "change-to-manual",
        onClick: () => handleBulkAction(BulkActionTypes.ChangeToManual),
    };

    const editNextCheckDateItem: DropdownItem = {
        item: (
            <div className="flex flex-row gap-4 px-4">
                <Calendar size={20} weight="fill" />
                Edit next check date
            </div>
        ),
        id: "edit-next-check-date",
        onClick: handleBulkEditNextCheckDate,
    };

    const disableFurtherChecksItem: DropdownItem = {
        item: (
            <div className="flex flex-row gap-4 px-4">
                <CalendarX size={20} weight="fill" />
                Disable further checks date
            </div>
        ),
        id: "disable-further-checks-date",
        onClick: () => handleBulkAction(BulkActionTypes.DisableFurtherChecks),
    };

    const retryCheckItem: DropdownItem = {
        item: (
            <div className="flex flex-row gap-4 px-4">
                <ArrowsClockwise size={20} weight="fill" />
                Retry check
            </div>
        ),
        id: "retry-check",
        onClick: () => handleBulkAction(BulkActionTypes.RetryCheck),
    };

    const downloadDvaFormItem: DropdownItem = {
        item: (
            <div className="flex flex-row gap-4 px-4">
                <Download size={20} weight="fill" />
                Download DVA form
            </div>
        ),
        id: "download-dva-form",
        onClick: () => handleDownloadDeclarationForm(BulkActionFormTypes.DVA),
    };

    const downloadD906FormItem: DropdownItem = {
        item: (
            <div className="flex flex-row gap-4 px-4">
                <Download size={20} weight="fill" />
                Download D906 form
            </div>
        ),
        id: "download-d906-form",
        onClick: () => handleDownloadDeclarationForm(BulkActionFormTypes.D906),
    };

    const markAsCheckedItem: DropdownItem = {
        item: (
            <div className="flex flex-row gap-4 px-4">
                <CheckCircle size={20} weight="fill" />
                Mark as checked
            </div>
        ),
        id: "mark-as-checked",
        onClick: () => handleBulkAction(BulkActionTypes.MarkAsChecked),
    };

    const markAsPendingItem: DropdownItem = {
        item: (
            <div className="flex flex-row gap-4 px-4">
                <Timer size={20} weight="fill" />
                Mark as pending
            </div>
        ),
        id: "mark-as-pending",
        onClick: () => handleBulkAction(BulkActionTypes.MarkAsPending),
    };

    const enableAndSetNextCheckDateItem: DropdownItem = {
        item: (
            <div className="flex flex-row gap-4 px-4">
                <Calendar size={20} weight="fill" />
                Enable and set next check date
            </div>
        ),
        id: "enable-and-set-next",
        onClick: handleBulkEditNextCheckDate,
    };

    const { licenceOrigin, checkStatus, checkType } = optionType;
    if (licenceOrigin === GREAT_BRITAIN) {
        switch (checkStatus) {
            case "scheduled":
                if (checkType === CheckType.DVLA) {
                    return [
                        instantCheckItem,
                        changeToManualItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                } else {
                    return [
                        downloadD906FormItem,
                        markAsPendingItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                }
            case "overdue":
                if (checkType === CheckType.DVLA) {
                    return [
                        instantCheckItem,
                        changeToManualItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                } else {
                    return [
                        downloadD906FormItem,
                        markAsCheckedItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                }
            case "dvla-error":
                // this status only occurs for DVLA types
                // if changing to manual, we change the status as well as the type
                return [
                    retryCheckItem,
                    changeToManualItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case "pending":
                // status only occurs for manual types
                return [
                    markAsCheckedItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case "checked":
                if (checkType === CheckType.DVLA) {
                    return [
                        instantCheckItem,
                        changeToManualItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                } else {
                    return [
                        downloadD906FormItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                }
            case "disabled":
                return [enableAndSetNextCheckDateItem];
        }
    } else if (licenceOrigin === NORTHERN_IRELAND) {
        switch (checkStatus) {
            case "scheduled":
                return [
                    downloadDvaFormItem,
                    markAsPendingItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case "overdue":
                return [
                    downloadDvaFormItem,
                    markAsCheckedItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case "pending":
                return [
                    markAsCheckedItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case "checked":
                return [
                    downloadDvaFormItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case "disabled":
                return [enableAndSetNextCheckDateItem];
        }
    } else {
        switch (checkStatus) {
            case "scheduled":
                return [
                    downloadD906FormItem,
                    markAsPendingItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case "overdue":
                return [
                    downloadD906FormItem,
                    markAsCheckedItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case "pending":
                return [
                    markAsCheckedItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case "checked":
                return [
                    downloadD906FormItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case "disabled":
                return [enableAndSetNextCheckDateItem];
        }
    }
};
