import { useGetCompanyDriverAnalysis } from "../../api/admin/companyDriverAnalysis";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import {
    DriverAnalysisCategories,
    DriverAnalysisTabs,
} from "../../models/api/admin/companyDriverAnalysis";
import { ApiDepartmentItem } from "../../models/api/admin/department";
import { DriverCategory } from "../../utils/driverPageLocation";
import AnalysisComponent, {
    AnalysisComponentContentType,
} from "../ReusableComponents/AnalysisComponent";
import CollapsibleSection from "../ReusableComponents/CollapsibleSection";
import { driverAnalysisContent } from "../../pages/Admin/AdminDriversPages/driverAnalysisContent";
import { DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm";
import { useMemo } from "react";

type DriversAnalysisProps = {
    selectedCompany: ApiCompanyResponse | null;
    selectedDepartment: ApiDepartmentItem | null;
    handleFetchDrivers: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef: React.MutableRefObject<HTMLDivElement | null>;
    location?: DriverCategory;
};

const DriversAnalysis: React.FC<DriversAnalysisProps> = ({
    selectedCompany,
    selectedDepartment,
    handleFetchDrivers,
    tableRef,
    location,
}) => {
    const [isDriverAnalysisOpen, setIsDriverAnalysisOpen] =
        useLocalStorage<boolean>(`is${location}AnalysisComponentOpen`, true);

    const [tabOption, setTabOption] = useLocalStorage<number>(
        `${location}AnalysisSelectedTab`,
        0
    );

    type Options = keyof typeof DriverAnalysisCategories;

    const driverTabOptions: Options[] = [
        "RiskCategories",
        "LicenceStatus",
        "LicenceChanged",
        "LicenceCategories",
        "PhotoCards",
        "NewDrivers",
        "Eyesight",
        "NonADDMatches",
    ];
    const professionalDriverTabOptions: Options[] = [
        "RiskCategories",
        "CpcAndTacho",
        "ProfessionalLicenceStatus",
        "LicenceChanged",
        "LicenceCategories",
        "PhotoCards",
        "ProfessionalNewDrivers",
        "Eyesight",
        "NonADDMatches",
    ];

    const handleTabSelect = (value: DriverAnalysisTabs) => {
        //find the key of the selected option
        //then get the index from the corresponding array above
        const optionKey =
            Object.keys(DriverAnalysisTabs)[
                Object.values(DriverAnalysisTabs).indexOf(value)
            ];
        let indexOfKey;
        if (location === "professionalDrivers") {
            indexOfKey = professionalDriverTabOptions.indexOf(
                optionKey as Options
            );
        } else {
            indexOfKey = driverTabOptions.indexOf(optionKey as Options);
        }
        setTabOption(indexOfKey);
    };

    const currentOption = useMemo(() => {
        // reverse of handleTabSelect to find the correct category to send to api
        let optionKey: Options;
        if (location === "professionalDrivers") {
            optionKey = professionalDriverTabOptions[tabOption];
        } else {
            optionKey = driverTabOptions[tabOption];
        }
        return DriverAnalysisCategories[
            optionKey as keyof typeof DriverAnalysisCategories
        ];
    }, [tabOption]);

    const { driverAnalysisData, driverAnalysisError } =
        useGetCompanyDriverAnalysis(
            selectedCompany?.companyId,
            selectedDepartment?.departmentId,
            location === "professionalDrivers",
            currentOption
        );

    const selectAnalysisComponentTitle = (page?: DriverCategory) => {
        if (page === "professionalDrivers") return "HGV/Coach driver analysis";
        return "Driver analysis";
    };

    const analysisContent: AnalysisComponentContentType<DriverAnalysisTabs>[] =
        driverAnalysisContent(
            driverAnalysisData,
            driverAnalysisError,
            handleFetchDrivers,
            tableRef,
            !!selectedCompany
        );

    return (
        <CollapsibleSection
            title={selectAnalysisComponentTitle(location)}
            isOpen={isDriverAnalysisOpen}
            setIsOpen={setIsDriverAnalysisOpen}
            content={
                <AnalysisComponent
                    analysisContent={analysisContent}
                    location={location}
                    selectedTab={tabOption}
                    onSelect={handleTabSelect}
                />
            }
        />
    );
};

export default DriversAnalysis;
