import { type S2DApiFetcherResponse } from "../../api/common/apiResponse";
import { useCompanyDepartmentSelector } from "../ReusableComponents/CompanyDepartmentSelector";
import { type FilterVehicleAnalysisDto } from "../../models/app/admin/driverFilters";
import {
    AnalysisChartType,
    type GreyFleetVehicleAnalysisCharts,
} from "../../models/app/admin/analysisChart";
import useLocalStorage from "../../hooks/useLocalStorage/useLocalStorage";
import {
    AgeRange,
    BusinessCover,
    Co2Emissions,
    EngineCapacity,
    EuroRating,
    FuelType,
    InsuranceType,
    Manufacturer,
} from "../../models/app/admin/vehicleAnalysis";
import { type GetAllVehiclesFilterFields } from "../../models/app/admin/vehicles";
import {
    vehicleFilterDefaultValues,
    vehicleFilterNulledValues,
} from "./vehicleFilterDefaultValues";
import { type ApiGetAllVehiclesResponse } from "../../models/api/admin/vehicle";

export type VehicleAnalysisFilterKey =
    | keyof Co2Emissions
    | keyof FuelType
    | keyof AgeRange
    | keyof Manufacturer
    | keyof EuroRating
    | keyof InsuranceType
    | keyof BusinessCover
    | keyof EngineCapacity;

const useVehicleChartAnalysisFilters = (
    chartType: GreyFleetVehicleAnalysisCharts,
    setAppliedFilter: (value: GetAllVehiclesFilterFields) => void,
    handleFetchVehicles: (
        filters?: GetAllVehiclesFilterFields
    ) => Promise<S2DApiFetcherResponse<ApiGetAllVehiclesResponse>>
) => {
    const [selectedDropdownFilter, setSelectedDropdownFilter] = useLocalStorage<
        VehicleAnalysisFilterKey | string | null
    >(`${chartType}SelectedFilter`, null);

    const { selectedCompany, currentDepartment: selectedDepartment } =
        useCompanyDepartmentSelector();

    const companyDepartmentVals: Pick<
        GetAllVehiclesFilterFields,
        "companyFilter"
    > = {
        companyFilter: {
            companyIds: selectedCompany ? [selectedCompany.companyId] : null,
            departmentIds: selectedDepartment
                ? [selectedDepartment.departmentId]
                : null,
        },
    };

    // manufacturer provides a single string value
    // everything else provides a relevant key for that chart types
    const vehicleAnalysisValues = (
        key: VehicleAnalysisFilterKey | string
    ): FilterVehicleAnalysisDto => {
        const {
            co2Emissions,
            fuelType,
            ageRange,
            euroRating,
            insuranceType,
            businessCover,
            engineCapacity,
        } = vehicleFilterDefaultValues.vehicleAnalysis;
        if (
            !co2Emissions ||
            !fuelType ||
            !ageRange ||
            !euroRating ||
            !insuranceType ||
            !businessCover ||
            !engineCapacity
        ) {
            throw Error(
                "Vehicle analysis default values must be defined in order to use these filters."
            );
        }

        switch (chartType) {
            default:
                return vehicleFilterNulledValues.vehicleAnalysis;
            case AnalysisChartType.Co2Emissions:
                return {
                    co2Emissions: {
                        [key]: true,
                    } as Co2Emissions,
                };
            case AnalysisChartType.FuelType:
                return {
                    fuelType: {
                        [key]: true,
                    } as FuelType,
                };
            case AnalysisChartType.AgeRange:
                return {
                    ageRange: {
                        [key]: true,
                    } as AgeRange,
                };
            case AnalysisChartType.Manufacturer:
                return {
                    ...vehicleFilterNulledValues.vehicleAnalysis,
                    manufacturer: {
                        name: key,
                    },
                };
            case AnalysisChartType.EuroRatings:
                return {
                    euroRating: {
                        [key]: true,
                    } as EuroRating,
                };
            case AnalysisChartType.InsuranceTypes:
                return {
                    insuranceType: {
                        [key]: true,
                    } as InsuranceType,
                };
            case AnalysisChartType.BusinessCover:
                return {
                    businessCover: {
                        [key]: true,
                    } as BusinessCover,
                };
            case AnalysisChartType.EngineCapacity:
                return {
                    engineCapacity: {
                        [key]: true,
                    } as EngineCapacity,
                };
        }
    };

    const handleApplyFilter = (
        key: VehicleAnalysisFilterKey | string | null
    ) => {
        if (!key) {
            setSelectedDropdownFilter(null);
            setAppliedFilter({
                ...companyDepartmentVals,
            });
            handleFetchVehicles({
                ...companyDepartmentVals,
            });
            return;
        }

        const filter: GetAllVehiclesFilterFields = {
            ...companyDepartmentVals,
            vehicleAnalysis: vehicleAnalysisValues(key),
        };
        setSelectedDropdownFilter(key);
        setAppliedFilter(filter);
        handleFetchVehicles(filter);
    };

    return { handleApplyFilter, selectedDropdownFilter };
};

export default useVehicleChartAnalysisFilters;
