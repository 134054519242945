import PageLayout from "../components/PageLayout";
import ReportingOptions from "../components/ReportingOptions";
import { useState } from "react";
import { type ApiCompanyResponse } from "../models/api/admin/company";
import { type ApiDepartmentItem } from "../models/api/admin/department";
import DialogBox from "../components/DialogBox";
import NewReportsForm from "../components/NewReportsForm";
import { type NewReportDto } from "../models/app/reporting";
import { summarizeObjectToString } from "../utils/summarizeObjectToString";
import HeaderOptions from "../components/ReusableComponents/HeaderOptions";

const Reporting = () => {
    const [selectedCompany, setSelectedCompany] =
        useState<ApiCompanyResponse | null>(null);
    const [selectedDepartment, setSelectedDepartment] =
        useState<ApiDepartmentItem | null>(null);

    const handleCompanyChange = (company: ApiCompanyResponse | null) => {
        if (selectedDepartment) setSelectedDepartment(null);
        setSelectedCompany(company);
    };

    const clearFilter = () => {
        setSelectedCompany(null);
        setSelectedDepartment(null);
    };

    const [isNewReportModalOpen, setIsNewReportModalOpen] = useState(false);

    const handleAddNewReport = () => {
        setIsNewReportModalOpen(!isNewReportModalOpen);
    };

    const handleFormSubmit = (data: NewReportDto) => {
        const summaryArray = new Array(0);
        summarizeObjectToString(data, summaryArray);

        alert(summaryArray.join(", "));
    };

    return (
        <PageLayout title="Reporting" header={false}>
            <div className="flex flex-col gap-6">
                <HeaderOptions
                    selectedDepartment={selectedDepartment}
                    handleDepartmentChange={setSelectedDepartment}
                    selectedCompany={selectedCompany}
                    handleCompanyChange={handleCompanyChange}
                    clearCompanyDepartment={clearFilter}
                    // tailoredButtonTitle="New report"
                    // tailoredButtonOnClick={handleAddNewReport}
                />
                <ReportingOptions
                    selectedCompany={selectedCompany}
                    selectedDepartment={selectedDepartment}
                />
                {/* <DialogBox
                    isOpen={isNewReportModalOpen}
                    onClose={() => setIsNewReportModalOpen(false)}
                    title="Create new report"
                    size="xl"
                    closeButton
                >
                    <NewReportsForm onSubmit={handleFormSubmit} />
                </DialogBox> */}
            </div>
        </PageLayout>
    );
};

export default Reporting;
