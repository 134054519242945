import {
    Bar,
    BarChart as ReBarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

export type StackedBarChartItem<AdditionalDataKeys = Record<string, unknown>> =
    {
        name: string;
    } & AdditionalDataKeys;

// NB: 'topDataKey' and 'bottomDataKey' must match the AdditionalDataKeys
// specified in the data
type StackedBarChartProps = {
    ticks?: number[];
    topDataKey: string;
    bottomDataKey: string;
    domain?: [number, number];
    data: StackedBarChartItem[];
};

const StackedBarChart = ({
    data,
    topDataKey,
    bottomDataKey,
    ticks,
    domain,
}: StackedBarChartProps) => {
    const barCategoryGap = data.length > 10 ? 2 : 6;
    return (
        <>
            <ResponsiveContainer width="100%" height={260}>
                <ReBarChart
                    data={data}
                    barCategoryGap={barCategoryGap}
                    margin={{
                        bottom: 0,
                        left: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="0" stroke="#E4E3DB" />
                    <XAxis
                        dataKey="name"
                        alignmentBaseline="central"
                        tickLine={false}
                        tick={{ fontSize: "0.55rem", fill: "#474741" }}
                        stroke="#E4E3DB"
                    />
                    <YAxis
                        domain={domain}
                        ticks={ticks}
                        tickLine={true}
                        tick={{ fontSize: "0.55rem", fill: "#474741" }}
                        stroke="#E4E3DB"
                        width={24}
                    />
                    <Tooltip cursor={false} />
                    <Bar dataKey={topDataKey} stackId="a" fill="#92B91C" />
                    <Bar dataKey={bottomDataKey} stackId="a" fill="#FFE252" />
                </ReBarChart>
            </ResponsiveContainer>
        </>
    );
};

export default StackedBarChart;
