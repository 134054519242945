import EDeclarationFormLayout from "./EDeclarationFormLayout";
import FormMessageBox from "../FormMessageBox";
import { useNavigate } from "react-router-dom";
import { errorToast } from "../../toast";
import { appPaths } from "../../Routes";
import Button from "../Button";

type Step5ConfirmationProps = {
    greyFleetInviteId: string;
    token: string | null;
};

const Step5Confirmation: React.FC<Step5ConfirmationProps> = ({
    greyFleetInviteId,
    token,
}) => {
    const navigate = useNavigate();
    const handleMoveToGreyFleet = () => {
        if (!token) {
            errorToast("There was an error");
            return;
        }
        navigate(
            appPaths.greyFleet(greyFleetInviteId, undefined, undefined, token)
        );
    };

    return (
        <EDeclarationFormLayout
            title="E-Declaration"
            subtitle="Process complete"
            content={
                <div className="flex flex-1 flex-col justify-between">
                    <div>
                        <FormMessageBox
                            title={"Process complete"}
                            message={
                                <span>
                                    We have now received your permission to
                                    check your licence on your employer’s
                                    behalf. Confirmation of this has also been
                                    sent to you by email. If you do not see the
                                    email in a few minutes, check your “junk
                                    mail” folder or “spam” folder.
                                    <br />
                                    You can now close this window.
                                </span>
                            }
                            variant="success"
                        />
                        <p>
                            If you decide to remove permission in future then
                            please contact your employer and ask them to remove
                            you via the Safe2Drive portal.
                        </p>
                    </div>
                    {
                        <div className="flex justify-end gap-8">
                            {greyFleetInviteId && (
                                <Button
                                    type="button"
                                    variant="primary"
                                    onClick={handleMoveToGreyFleet}
                                >
                                    <div className="flex flex-row items-center justify-between">
                                        <div className="pl-3 font-bold">
                                            Next: Grey Fleet details
                                        </div>
                                    </div>
                                </Button>
                            )}
                        </div>
                    }
                </div>
            }
        />
    );
};

export default Step5Confirmation;
