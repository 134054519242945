import * as yup from "yup";
import { type GetAllDriversDto } from "../../../models/app/admin/driver";
import { SchemaKeys } from "../../../models/shared/schemaKeysHelper";
import { type FilterLicenceInformationDto } from "../../../models/app/admin/driverFilters";
import { dateValidation } from "../../../utils/dateValidationYup";
import { ForeignLicenceTypes } from "../../../constants/dvlaConstants";

export const PersonalInformationFilterValidationSchema = {
    firstName: yup
        .string()
        .nullable()
        .notRequired()
        .transform((value) => {
            if (value !== "") return value;
            return null;
        }),
    lastName: yup
        .string()
        .nullable()
        .notRequired()
        .transform((value) => {
            if (value !== "") return value;
            return null;
        }),
    email: yup
        .string()
        .nullable()
        .notRequired()
        .transform((value) => {
            if (value !== "") return value;
            return null;
        }),
    dateOfBirthBefore: dateValidation
        .min(
            yup.ref("dateOfBirthAfter"),
            "upper limit must be more than lower limit"
        )
        .nullable()
        .notRequired(),
    dateOfBirthAfter: dateValidation
        .max(
            yup.ref("dateOfBirthBefore"),
            "lower limit must be less than upper limit"
        )
        .nullable()
        .notRequired(),
};

export const EmploymentFilterValidationSchema = {
    companyIds: yup
        .array()
        .of(yup.string())
        .transform((value) => {
            if (value?.length !== 0) return value;
            return null;
        })
        .nullable()
        .notRequired(),
    departmentIds: yup
        .array()
        .of(yup.string())
        .transform((value) => {
            if (value?.length !== 0) return value;
            return null;
        })
        .nullable()
        .notRequired(),
    employeeId: yup
        .string()
        .transform((value) => {
            if (value !== "") return value;
            return null;
        })
        .nullable()
        .notRequired(),
};

export const DriverAddressFilterValidationSchema = {
    postcode: yup
        .string()
        .nullable()
        .notRequired()
        .transform((value) => {
            if (value !== "") return value;
            return null;
        }),
};

export const LinkedDriverFilterValidationSchema = {
    firstName: yup
        .string()
        .nullable()
        .notRequired()
        .transform((value) => {
            if (value !== "") return value;
            return null;
        }),
    lastName: yup
        .string()
        .nullable()
        .notRequired()
        .transform((value) => {
            if (value !== "") return value;
            return null;
        }),
};

export const LicenceInformationFilterValidationSchema: SchemaKeys<FilterLicenceInformationDto> =
    {
        number: yup
            .string()
            .nullable()
            .notRequired()
            .transform((value) => {
                if (value !== "") return value;
                return null;
            }),
        isExpired: yup.boolean().nullable().notRequired(),
        hasExpiredPhotocard: yup.boolean().nullable().notRequired(),
        isRevoked: yup.boolean().nullable().notRequired(),
        licenceCountryOrigin: yup.mixed().nullable().notRequired().oneOf([...Object.values(ForeignLicenceTypes), null]),
        isDisqualified: yup.boolean().nullable().notRequired(),
        hasCpc: yup.boolean().nullable().notRequired(),
        hasExpiredCpc: yup.boolean().nullable().notRequired(),
        hasExpiringCpc: yup.boolean().nullable().notRequired(),
        hasTacho: yup.boolean().nullable().notRequired(),
        hasExpiredTacho: yup.boolean().nullable().notRequired(),
        hasExpiringTacho: yup.boolean().nullable().notRequired(),
        hasExpiredEntitlements: yup.boolean().nullable().notRequired(),
        hasExpiringEntitlements: yup.boolean().nullable().notRequired(),
        hasExpiringPhotocard: yup.boolean().nullable().notRequired(),
        endorsements: yup.object().shape({
            values: yup.array().of(yup.string()).nullable().notRequired(),
            hasCount: yup
                .object()
                .shape({
                    from: yup
                        .number()
                        .max(
                            yup.ref("to"),
                            "lower limit must be less than upper limit"
                        )
                        .nullable()
                        .notRequired(),
                    to: yup
                        .number()
                        .min(
                            yup.ref("from"),
                            "upper limit must be more than lower limit"
                        )
                        .nullable()
                        .notRequired(),
                })
                .nullable()
                .notRequired(),
        }),
        categories: yup.object().shape({
            values: yup.array().of(yup.string()).nullable().notRequired(),
            exclude: yup.boolean().nullable().notRequired(),
        }),
        points: yup
            .object()
            .shape({
                from: yup
                    .number()
                    .max(
                        yup.ref("to"),
                        "lower limit must be less than upper limit"
                    )
                    .nullable()
                    .notRequired(),
                to: yup
                    .number()
                    .min(
                        yup.ref("from"),
                        "upper limit must be more than lower limit"
                    )
                    .nullable()
                    .notRequired(),
            })
            .nullable()
            .notRequired(),
        type: yup.array().of(yup.string()).nullable().notRequired(),
    };

export type DriversListFiltersFormValues = Omit<
    GetAllDriversDto,
    "pageSize" | "pageNumber" | "orderBy"
>;
