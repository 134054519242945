import {
    AddEndorsementsDto,
    EditEndorsementsDto,
} from "../../models/app/admin/endorsements";
import { useS2DApiFetcher } from "../common/fetching";

const useAddEndorsement = () =>
    useS2DApiFetcher<
        AddEndorsementsDto,
        string,
        { driverId: string; dto: AddEndorsementsDto }
    >(
        "POST",
        ({ driverId, dto }) => ({
            url: `api/drivers/${driverId}/endorsements`,
            body: dto,
        }),
        [],
        true
    );

const useEditEndorsement = () =>
    useS2DApiFetcher<
        EditEndorsementsDto,
        string,
        { driverId: string; endorsementId: string; dto: EditEndorsementsDto }
    >(
        "PUT",
        ({ driverId, endorsementId, dto }) => ({
            url: `api/drivers/${driverId}/endorsements/${endorsementId}`,
            body: dto,
        }),
        [],
        true
    );

const useDeleteEndorsement = () =>
    useS2DApiFetcher<void, string, { driverId: string; endorsementId: string }>(
        "DELETE",
        ({ driverId, endorsementId }) => ({
            url: `api/drivers/${driverId}/endorsements/${endorsementId}`,
        }),
        [],
        true
    );

export const useEndorsement = (driverId?: string, endorsementId?: string) => {
    const [addEndorsement, isAddingEndorsement] = useAddEndorsement();
    const [editEndorsement, isEditingEndorsement] = useEditEndorsement();
    const [deleteEndorsement, isDeletingEndorsement] = useDeleteEndorsement();

    return {
        addEndorsement: async (driverId: string, dto: AddEndorsementsDto) =>
            await addEndorsement({ driverId, dto }),

        isAddingEndorsement,
        editEndorsement: async (dto: EditEndorsementsDto) =>
            await editEndorsement({
                driverId: driverId!,
                endorsementId: endorsementId!,
                dto,
            }),

        isEditingEndorsement,
        deleteEndorsement: async () =>
            await deleteEndorsement({
                driverId: driverId!,
                endorsementId: endorsementId!,
            }),

        isDeletingEndorsement,
    };
};
