import clsx from "clsx";
// import {
//     offset,
//     flip,
//     shift,
//     autoUpdate,
//     useFloating,
//     useInteractions,
//     useHover,
//     useFocus,
//     useRole,
//     useDismiss
// } from "@floating-ui/react-dom-interactions";
// import { motion, AnimatePresence } from "framer-motion";
import { Question } from "phosphor-react";
import { useState } from "react";

export type HelpIconPropsProps = {
    tooltipText: string;
    className?: string;
};

export const HelpIcon = ({ tooltipText, className }: HelpIconPropsProps) => {
    const [open, setOpen] = useState(false);

    // const { x, y, reference, floating, strategy, context } = useFloating({
    //     placement: "top",
    //     open,
    //     onOpenChange: setOpen,
    //     middleware: [
    //         offset(15),
    //         flip(),
    //         shift({ padding: 8 })],
    //     whileElementsMounted: autoUpdate
    // });

    // const { getReferenceProps, getFloatingProps } = useInteractions([
    //     useHover(context, { restMs: 40 }),
    //     useFocus(context),
    //     useRole(context, { role: "tooltip" }),
    //     useDismiss(context)
    // ]);

    return (
        <div>
            {/* <Question className={clsx("cursor-pointer", className)} size={18} ref={reference} {...getReferenceProps()} /> */}
            {/* <AnimatePresence>
                {open && (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.85 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: "spring", damping: 20, stiffness: 400 }}
                        ref={floating}
                        className="bg-white text-black pointer-events-none rounded-md px-6 py-6 w-96 shadow-xl font-normal whitespace-normal z-50"
                        style={{
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0
                        }}
                        {...getFloatingProps()}
                    >
                        {tooltipText}
                    </motion.div>
                )}
            </AnimatePresence> */}
        </div>
    );
};

export default HelpIcon;
