import { GreyFleetVehicleSubmissionValues } from "../components/GreyFleetWizard/GreyFleetFormValues";
import {
    ApiGreyFleetInviteResponse,
    ApiGreyFleetValidateResponse,
    ApiGreyFleetVehicleAnalysisResponse,
} from "../models/api/greyFleet";
import { GetAllDriversDto } from "../models/app/admin/driver";
import {
    GreyFleetSendInviteDto,
    GreyFleetValidateDto,
} from "../models/app/greyFleet";
import { addCompanyAndDepartmToPath } from "../utils/queryParamHelpers";
import { useS2DApiFetcher } from "./common/fetching";
import { useS2DApiSWR } from "./common/s2dApiSWR";

const useAuthenticateGreyFleet = () =>
    useS2DApiFetcher<
        GreyFleetValidateDto,
        ApiGreyFleetValidateResponse,
        { id: string; dto: GreyFleetValidateDto }
    >(
        "POST",
        ({ id, dto }) => ({
            url: `/api/greyfleet/authenticate/${id}`,
            body: dto,
        }),
        [400],
        false
    );

export const useFinaliseGreyFleet = () =>
    useS2DApiFetcher<
        GreyFleetVehicleSubmissionValues,
        ApiGreyFleetValidateResponse,
        { id: string; token: string; dto: GreyFleetVehicleSubmissionValues }
    >(
        "POST",
        ({ id, token, dto }) => ({
            url: `/api/greyfleet/${id}`,
            body: dto,
            altToken: token,
        }),
        [400],
        false
    );

export const useSendGreyFleetInvite = () =>
    useS2DApiFetcher<
        GreyFleetSendInviteDto,
        string,
        { dto: GreyFleetSendInviteDto }
    >(
        "POST",
        ({ dto }) => ({
            url: "/api/greyfleet",
            body: dto,
        }),
        [400],
        false
    );

export const useGreyFleetVerifyInsurance = () =>
    useS2DApiFetcher<null, string, { vehicleId: string; inviteId: string }>(
        "POST",
        ({ vehicleId, inviteId }) => ({
            url: `/api/greyfleet/${inviteId}/verify-insurance/${vehicleId}`,
        }),
        [400],
        true
    );

export const useGreyFleetExpiryReminder = () =>
    useS2DApiFetcher<null, string, { vehicleId: string; inviteId: string }>(
        "POST",
        ({ vehicleId, inviteId }) => ({
            url: `/api/greyfleet/${inviteId}/vehicles/${vehicleId}/expiry-reminder`,
        }),
        [400],
        true
    );

export const useGreyFleetIncompleteReminder = () =>
    useS2DApiFetcher<null, string, { inviteId: string }>(
        "POST",
        ({ inviteId }) => ({
            url: `/api/greyfleet/${inviteId}/send`,
        }),
        [400],
        true
    );

export const useGreyFleetRemoveVehicle = () =>
    useS2DApiFetcher<null, string, { vehicleId: string; inviteId: string }>(
        "DELETE",
        ({ vehicleId, inviteId }) => ({
            url: `/api/greyfleet/${inviteId}/vehicles/${vehicleId}`,
        }),
        [400],
        true
    );

export const useGreyFleetRemoveInvite = () =>
    useS2DApiFetcher<null, string, { inviteId: string }>(
        "DELETE",
        ({ inviteId }) => ({
            url: `/api/greyfleet/${inviteId}`,
        }),
        [400],
        true
    );

export const useGreyFleetVehicleAnalysis = (
    companyId?: string,
    departmentId?: string
) => {
    let pathArray = [`/api/greyfleet/vehicleAnalysis`];

    pathArray = addCompanyAndDepartmToPath(pathArray, companyId, departmentId);

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiGreyFleetVehicleAnalysisResponse>(pathArray.join(""));

    return { data, error, isLoading, refetch };
};

export const useGreyFleetBaseData = (id?: string) => {
    const { data, error, isLoading } = useS2DApiSWR<ApiGreyFleetInviteResponse>(
        id ? `/api/greyfleet/${id}` : null,
        { preventRedirectOn404: true }
    );

    return {
        greyFleetBaseData: data,
        error,
        isLoading,
    };
};

export const useDownloadGreyFleet = () => {
    const [getGreyFleetReport, isGettingGreyFleetReport] = useS2DApiFetcher<
        GetAllDriversDto,
        Blob,
        { dto: GetAllDriversDto }
    >(
        "POST",
        ({ dto }) => ({
            url: "api/greyfleet/download",
            body: dto,
            altToken: undefined,
            contentType: "application/json",
        }),
        [],
        true
    );

    return {
        getGreyFleetReport: async (dto: GetAllDriversDto) => await getGreyFleetReport({ dto }),
        isGettingGreyFleetReport,
    };
};

export const useGreyFleet = () => {
    const [validateGreyFleetUser, isValidatingGreyFleetUser] =
        useAuthenticateGreyFleet();

    const [finaliseGreyFleet, isFinalisingGreyFleetProcess] =
        useFinaliseGreyFleet();

    const [sendGreyFleetInvite] = useSendGreyFleetInvite();

    const [verifyInsurance, isVerifyingInsurance] =
        useGreyFleetVerifyInsurance();

    const [removeVehicle, isRemovingVehicle] = useGreyFleetRemoveVehicle();

    const [removeInvite] = useGreyFleetRemoveInvite();

    const [expiryReminder] = useGreyFleetExpiryReminder();

    const [incompleteReminder, isSendingIncompleteReminder] =
        useGreyFleetIncompleteReminder();

    return {
        validateUser: async (id: string, dto: GreyFleetValidateDto) =>
            await validateGreyFleetUser({ id, dto }),
        isValidatingGreyFleetUser,
        finaliseGreyFleetProcess: async (
            id: string,
            token: string,
            dto: GreyFleetVehicleSubmissionValues
        ) => await finaliseGreyFleet({ id, token, dto }),
        isFinalisingGreyFleetProcess,
        sendGreyFleetInvite: async (dto: GreyFleetSendInviteDto) =>
            await sendGreyFleetInvite({ dto }),
        verifyInsurance: async (vehicleId: string, inviteId: string) =>
            await verifyInsurance({ vehicleId, inviteId }),
        isVerifyingInsurance,
        removeVehicle: async (vehicleId: string, inviteId: string) =>
            await removeVehicle({ vehicleId, inviteId }),
        isRemovingVehicle,
        removeInvite: async (inviteId: string) =>
            await removeInvite({ inviteId }),
        expiryReminder: async (vehicleId: string, inviteId: string) =>
            await expiryReminder({ vehicleId, inviteId }),
        incompleteReminder: async (inviteId: string) =>
            await incompleteReminder({ inviteId }),
        isSendingIncompleteReminder,
    };
};
