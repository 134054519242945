import {
    type TableCellData,
    type HiddenCols,
} from "../components/ReusableComponents/Table";

export const findHiddenTableContent = (
    id: string,
    hiddenCols?: HiddenCols[]
) => {
    if (!hiddenCols) return false;

    const hidden = hiddenCols.find((c) => c.id === id)?.hidden;
    return hidden;
};

//basing cell content off of the id position in the table headers array
export const findTableCellContent = (
    item: TableCellData[],
    headerId: string
) => {
    const tableCellContent = item.find(
        (tableCellContent: TableCellData) => tableCellContent.id === headerId
    );

    return tableCellContent;
};
