import { type S2DStaffMember } from "../../models/api/safe2DriveAdmin/staff";
import { capitaliseWord } from "../../utils/capitaliseWord";
import { formatDateToString } from "../../utils/formatDateToString";
import { userStatusColor } from "../../utils/userStatusColour";
import StatusPill from "../ReusableComponents/StatusPill/StatusPill";
import {
    type TableContent,
    type TableHeader,
} from "../ReusableComponents/Table";
import UserIcon from "../UserIcon";

export type StaffTableAllowedIds = "name" | "email" | "lastLogin" | "status";

export type StaffTableHeaders = TableHeader<StaffTableAllowedIds>;
export type StaffTableContent = TableContent<
    S2DStaffMember,
    StaffTableAllowedIds
>;

export const initStaffTableHeaders: StaffTableHeaders[] = [
    {
        title: "Name",
        id: "name",
    },
    {
        title: "Email",
        id: "email",
    },
    {
        title: "Last login",
        id: "lastLogin",
    },
    {
        title: "Status",
        id: "status",
    },
];

export function initStaffTableContents(
    users?: S2DStaffMember[]
): StaffTableContent[] {
    const tableContents = new Array<StaffTableContent>(0);
    users?.map((user) => {
        tableContents.push({
            rowId: user.userId,
            rowData: user,
            cellData: [
                {
                    id: "name",
                    content: (
                        <div className="flex items-center gap-2">
                            {user.firstName && (
                                <UserIcon
                                    firstName={user.firstName}
                                    lastName={user.lastName}
                                    size="small"
                                />
                            )}

                            <p>{`${user.firstName} ${user.lastName}`}</p>
                        </div>
                    ),
                },
                {
                    id: "email",
                    content: <p>{user.emailAddress}</p>,
                },
                {
                    id: "lastLogin",
                    content: (
                        <p>
                            {user.lastLoginDate
                                ? formatDateToString(
                                      new Date(user.lastLoginDate),
                                      true
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "status",
                    content: (
                        <div className="pl-2">
                            <StatusPill
                                status={capitaliseWord(user.status)}
                                bgColour={userStatusColor(
                                    capitaliseWord(user.status)
                                )}
                            />
                        </div>
                    ),
                },
            ],
        });
    });
    return tableContents;
}
