import { useDvlaChecks } from "../../../api/admin/checks";
import {
    CheckStatus,
    DeclarationStatus,
    LicenceStatus,
} from "../../../models/shared/statuses";
import { errorToast, successToast } from "../../../toast";
import { convertDateStringToReadableString } from "../../../utils/convertDateStringToReadableString";
import { StatusAction } from "../../ActionableStatus/ActionableStatus";
import {
    buildLicenceCheckActions,
    LicenceChecksActions,
} from "./licenceCheckActions";
import LicenceCheckStatus from "./LicenceCheckStatus";
import {
    CheckedLicenceCheckStatus,
    DriverRemovedFromScheduleLicenceCheckStatus,
    DueForCheckLicenceCheckStatus,
    FailedLicenceCheckStatus,
    InProgressLicenceCheckStatus,
    NoGbEntitlementLicenceCheckStatus,
    ScheduledLicenceCheckStatus,
} from "./Statuses";

export type LicenceCheckProps = {
    driverId: string;
    checkStatus: CheckStatus;
    lastCheckDate: string | null;
    nextCheckDate: string | null;
    checkFailureReason: string | null;
    declarationStatus: DeclarationStatus | null;
    isCheckDue: boolean;
    licenceStatus: LicenceStatus | null;
    licenceNumber: string;
    isManual: boolean;
    refetchDriver: () => void;
};

const LicenceChecks = ({
    driverId,
    checkStatus,
    lastCheckDate,
    nextCheckDate,
    checkFailureReason,
    declarationStatus,
    isCheckDue,
    licenceStatus,
    licenceNumber,
    isManual,
    refetchDriver,
}: LicenceCheckProps) => {
    const {
        updateCheckType,
        isUpdatingCheckType,
        updateCheckStatus,
        isUpdatingCheckStatus,
        checkLicence,
        isCheckingLicence,
    } = useDvlaChecks();

    const handleBuildActions = (
        actionNames: LicenceChecksActions[]
    ): StatusAction[] => {
        const actions = buildLicenceCheckActions(
            actionNames,
            handleChangeCheckType,
            isUpdatingCheckType,
            handleToggleFurtherChecks,
            handleRetryCheck,
            isCheckingLicence,
            handleSetCheckStatus,
            isUpdatingCheckStatus,
            lastCheckDate
        );

        return actions;
    };

    const handleChangeCheckType = async (isManual: boolean) => {
        const response = await updateCheckType(driverId, {
            isManual: isManual,
        });

        if (!response.success) {
            errorToast("Failed to change check type.");
            return;
        }

        successToast("Successfully changed check type.");
        refetchDriver();
    };

    const handleToggleFurtherChecks = async (enable: boolean) => {
        const response = await updateCheckStatus(driverId, {
            statusId: enable ? CheckStatus.None : CheckStatus.Disabled,
        });

        if (!response.success) {
            errorToast(
                enable
                    ? "Failed to enable further checks."
                    : "Failed to disable further checks."
            );
            return;
        }

        successToast(
            enable
                ? "Successfully enabled further checks."
                : "Successfully disabled further checks."
        );
        refetchDriver();
    };

    const handleSetCheckStatus = async (checkStatus: CheckStatus) => {
        const response = await updateCheckStatus(driverId, {
            statusId: checkStatus,
        });

        if(response.success){
            if (checkStatus === CheckStatus.Checked)
                successToast("Successfully marked as checked.");
            else if (checkStatus === CheckStatus.Pending)
                successToast("Successfully marked as pending.");
            else if (checkStatus === CheckStatus.Failed)
                successToast("Successfully marked as failed.");
        }

        refetchDriver();
    };

    const handleRetryCheck = async () => {
        if (!licenceNumber) {
            errorToast("Cannot retry check as driver has no licence number.");
            return;
        }

        const response = await checkLicence(driverId);

        if (!response.success) {
            errorToast(response.message ?? "Failed to retry check.");
            return;
        }

        successToast("Successfully retried check.");
        refetchDriver();
    };

    const readableLastCheckDate = lastCheckDate
        ? convertDateStringToReadableString(lastCheckDate)
        : "Never";

    const readableNextCheckDate = nextCheckDate
        ? convertDateStringToReadableString(nextCheckDate)
        : "Not scheduled";

    if (!licenceStatus) {
        return (
            <NoGbEntitlementLicenceCheckStatus
                driverId={driverId}
                isManual={isManual}
                lastCheckDate={readableLastCheckDate}
                nextCheckDate={readableNextCheckDate}
                checkStatus={checkStatus}
                buildActions={handleBuildActions}
            />
        );
    }

    if (checkStatus === CheckStatus.Disabled) {
        return (
            <DriverRemovedFromScheduleLicenceCheckStatus
                driverId={driverId}
                isManual={isManual}
                declarationStatus={declarationStatus}
                buildActions={handleBuildActions}
            />
        );
    }

    if (checkStatus === CheckStatus.Pending) {
        return (
            <InProgressLicenceCheckStatus
                driverId={driverId}
                isManual={isManual}
                lastCheckDate={readableLastCheckDate}
                nextCheckDate={readableNextCheckDate}
                checkStatus={checkStatus}
                buildActions={handleBuildActions}
            />
        );
    }

    if (isCheckDue) {
        return (
            <DueForCheckLicenceCheckStatus
                driverId={driverId}
                isManual={isManual}
                lastCheckDate={readableLastCheckDate}
                nextCheckDate={readableNextCheckDate}
                buildActions={handleBuildActions}
            />
        );
    }

    if (checkStatus === "Checked") {
        return (
            <CheckedLicenceCheckStatus
                driverId={driverId}
                isManual={isManual}
                lastCheckDate={readableLastCheckDate}
                nextCheckDate={readableNextCheckDate}
                buildActions={handleBuildActions}
            />
        );
    }

    if (checkStatus === "Failed") {
        return (
            <FailedLicenceCheckStatus
                driverId={driverId}
                isManual={isManual}
                lastCheckDate={readableLastCheckDate}
                nextCheckDate={readableNextCheckDate}
                failureReason={checkFailureReason || "DVLA Error"}
                buildActions={handleBuildActions}
            />
        );
    }

    if (!isManual && nextCheckDate) {
        return (
            <ScheduledLicenceCheckStatus
                driverId={driverId}
                isManual={isManual}
                lastCheckDate={readableLastCheckDate}
                nextCheckDate={readableNextCheckDate}
                buildActions={handleBuildActions}
            />
        );
    }

    const actions = handleBuildActions(
        isManual
            ? [
                "editCheckDate",
                "markAsChecked",
                "markAsPending",
                "markAsFailed",
                "disableFurtherChecks",
                "changeToAutomatic",
            ]
            : [
                "editCheckDate",
                "retryCheck",
                "changeToManual",
                "disableFurtherChecks",
            ]
    );

    return (
        <LicenceCheckStatus
            actions={actions}
            theme="vrm"
            text="Unknown"
            driverId={driverId}
            isManual={isManual}
        >
            <span>Last check: {readableLastCheckDate}</span>
            <span>Next check: {readableNextCheckDate}</span>
        </LicenceCheckStatus>
    );
};

export default LicenceChecks;
