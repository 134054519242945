import { SetStateAction } from "react";
import { Controller } from "react-hook-form";
import FormTextInput from "../../../../FormTextInput";
import SubmitOrCancelButtonBar from "../../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import { type AddDepartmentFormValues } from "./AddDepartmentFormValues";
import useAddDepartmentForm from "./useAddDepartmentForm";
import { errorToast, successToast } from "../../../../../toast";
import { AddOrEditModalStates } from "../../CompanyBillingTab";
import FormDropdown, { FormDropdownOption } from "../../../../FormDropdown";
import { yesNoOptions } from "../../../../../constants/dropdownOptions";
import { ApiInvoiceAddressItem } from "../../../../../models/api/admin/invoiceAddress";

export default function AddDepartmentForm({
    setModalState,
    companyId,
    refetchDepartments,
    invoiceAddresses
}: {
    setModalState: (value: SetStateAction<AddOrEditModalStates>) => void;
    companyId: string | undefined;
    refetchDepartments: () => void;
    invoiceAddresses: ApiInvoiceAddressItem[];
}) {
    const { methods, addDepartment, isAddingDepartment } =
        useAddDepartmentForm(companyId);
    const { handleSubmit, register, control, formState } = methods;

    const handleAddDepartment = async (data: AddDepartmentFormValues) => {
        const response = await addDepartment(data);

        if (response.success) {
            refetchDepartments();
            successToast(`Department has been added`);
            setModalState("closed");
        } else {
            errorToast(`Failed to add department`);
        }
    };

    const invoiceAddressOptions: FormDropdownOption<string | number | null>[] = [
        { label: "No Invoice Address Selected", value: null },
        ...invoiceAddresses.map((invoiceAddress: ApiInvoiceAddressItem) => ({
            label: invoiceAddress.addressName,
            value: invoiceAddress.invoiceAddressId ?? "",
        }))
    ];

    return (
        <form onSubmit={handleSubmit(handleAddDepartment)}>
            <div className="w-full max-w-3xl mx-auto p-6 pb-8 max-h-[600px]">
                <FormTextInput
                    label={`Department name`}
                    required
                    id="departmentName"
                    placeholder={`Enter department name`}
                    {...register("departmentName")}
                    error={formState.errors.departmentName}
                />
                <FormTextInput
                    label={`Contact name`}
                    id="contactName"
                    placeholder={`Enter contact name`}
                    {...register("contactName")}
                    error={formState.errors.contactName}
                />
                <FormTextInput
                    label={`Cost centre`}
                    id="costCentre"
                    placeholder={`Enter cost centre`}
                    {...register("costCentre")}
                    error={formState.errors.costCentre}
                />
                <Controller
                    name="invoiceAddressId"
                    control={methods.control}
                    defaultValue={null} // Default to "No Invoice Address Selected"
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Invoice Address"
                            options={invoiceAddressOptions}
                            value={value ?? null}
                            onChange={onChange}
                            pleaseSelectText="Select an invoice address"
                            error={methods.formState.errors.invoiceAddressId}
                            buttonClassName="h-[48px] border-black bg-S2D-dark-green-70.1"
                        />
                    )}
                />
                <Controller
                    name="isInvoiceDefault"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <FormDropdown
                            label="Invoice default?"
                            options={yesNoOptions}
                            value={field.value}
                            onChange={field.onChange}
                            required
                            pleaseSelectText="Select Yes or No"
                            error={formState.errors.isInvoiceDefault}
                            buttonClassName="h-[48px] border-black bg-S2D-dark-green-70.1"
                        />
                    )}
                />
            </div>

            <div className="bg-S2D-neutral-90 rounded-b-sm border-t-[1px] border-S2D-neutral-80 py-3 mt-0 px-6">
                <SubmitOrCancelButtonBar
                    onCancel={() => setModalState("closed")}
                    isLoading={isAddingDepartment}
                    disabled={isAddingDepartment}
                />
            </div>
        </form>
    );
}
