import { Dialog } from "@headlessui/react";
import clsx from "clsx";

interface IModalProps {
    isOpen: boolean;
    onClose: () => void;
    size?: "xl" | "large" | "medium" | "small";
    children?: React.ReactNode;
    classNames?: string;
}

const Modal = ({
    isOpen,
    onClose,
    size,
    children,
    classNames,
}: IModalProps) => {
    return (
        <Dialog
            as="div"
            className="relative z-50"
            open={isOpen}
            onClose={onClose}
        >
            <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center text-center">
                    <Dialog.Panel
                        className={clsx(
                            "w-full transform overflow-hidden bg-white rounded-md text-left align-middle shadow-xl transition-all",
                            size === "xl" &&
                                "sm:max-w-lg md:max-w-2xl lg:max-w-3xl max-w-full",
                            size === "large" && "max-w-lg",
                            size === "medium" && "max-w-sm",
                            size === "small" && "max-w-xs",
                            classNames
                        )}
                    >
                        {children}
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};

export default Modal;
