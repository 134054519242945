import { ApiInvoiceSettingsItem } from "../../../../models/api/admin/company";
import { CompanyInvoiceSettings } from "../../../../models/app/admin/company";
import { errorToast, successToast } from "../../../../toast";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { formatDateToString } from "../../../../utils/formatDateToString";
import EditableContentBlock from "../../../EditableContentBlock";
import BillingInformationForm from "./EditBillingInformationForm";
import useBillingInfoSection, {
    BillingInfoSectionFormValues,
} from "./useBillingInformationSection";

type BillingInformationSectionProps = {
    companyId: string;
    parentId: string | undefined;
    companyInvoiceSettings: CompanyInvoiceSettings | undefined;
    refetchCompany: () => void;
};

const BillingInformationSection: React.FC<BillingInformationSectionProps> = ({ companyId, parentId, companyInvoiceSettings, refetchCompany }) => {
    const { methods, editInvoiceSettings, displayedValues } = useBillingInfoSection(companyInvoiceSettings);

    const resetAndCloseForm = (setIsEdit?: (isEdit: boolean) => void) => {
        methods.reset(displayedValues);
        if (typeof setIsEdit === "function") setIsEdit(false);
    };

    const handleSubmitBillingInfo = async (data: BillingInfoSectionFormValues, setIsEdit: (isEdit: boolean) => void) => {
        try {
            const newInvoiceSettings: ApiInvoiceSettingsItem = {
                shouldInvoiceParent: data.shouldInvoiceParent,
                lastInvoicedAt: data.lastInvoicedAt,
                annualFee: data.annualFee,
            };

            await editInvoiceSettings(companyId, newInvoiceSettings);
            successToast("Successfully updated billing information");
            setIsEdit(false);
            refetchCompany();
        } catch (error) {
            errorToast("Failed to update billing information");
        }
    };

    return (
        <EditableContentBlock
            title="Billing Information"
            onClosed={resetAndCloseForm}
        >
            {({ isEdit, setIsEdit }) => (
                <div className="p-6">
                    {!isEdit && nonEditableContent()}
                    {isEdit && (
                        <BillingInformationForm
                            methods={methods}
                            resetAndCloseForm={() =>
                                resetAndCloseForm(setIsEdit)
                            }
                            onSubmit={(data) => handleSubmitBillingInfo(data, setIsEdit)}
                            parentId={parentId}
                        />
                    )}
                </div>
            )}
        </EditableContentBlock>
    );

    function nonEditableContent() {
        return (
            <div className="space-y-2">
                {parentId && (
                    <p>
                        <b>Invoice the parent company?: </b>
                        {companyInvoiceSettings?.shouldInvoiceParent ? "Yes" : "No"}
                    </p>
                )}
                <p>
                    <b>Date last invoiced: </b>
                    {companyInvoiceSettings?.lastInvoicedAt ? formatDateToString(new Date(companyInvoiceSettings.lastInvoicedAt)) : ""}
                </p>
                <p>
                    <b>Annual fee: </b> {companyInvoiceSettings?.annualFee !== null && companyInvoiceSettings?.annualFee !== undefined ? formatCurrency(companyInvoiceSettings?.annualFee) : ""}
                </p>
            </div>
        );
    }
};

export default BillingInformationSection;
