import { Scroll } from "phosphor-react";
import { type MandateDetails } from "../../../models/api/admin/mandateDetails";
import { convertDateStringToReadableString } from "../../../utils/convertDateStringToReadableString";
import ActionableStatus from "../../ActionableStatus";
import ActionableStatusTag from "../../ActionableStatus/ActionableStatusTag";
import useDeclarationStatusActions from "./useDeclarationStatusActions";
import { DeclarationType } from "../../../models/app/eDeclarations";
import { ApiDriverByIdResponse } from "../../../models/api/admin/driver";
import { DriverValidationTypes } from "../../../models/app/admin/company";

export type DeclarationStatusCompleteProps = {
    mandateDetails: MandateDetails;
    driverData?: ApiDriverByIdResponse;
    validateByCompanyPreference?: DriverValidationTypes;
    handleScrollToDocumentUpload: () => void;
};

const DeclarationStatusComplete: React.FC<DeclarationStatusCompleteProps> = ({
    mandateDetails,
    driverData,
    validateByCompanyPreference,
    handleScrollToDocumentUpload,
}) => {
    const {
        declarationId,
        declarationType,
        declarationStatus,
        declarationSigned,
        expiryDate,
        licenceCountryOrigin,
    } = mandateDetails;

    // we cannot switch the declaration type once the declaration has been completed
    const { sendNewEDeclarationAction, sendNewPaperDeclarationAction } =
        useDeclarationStatusActions(
            declarationId,
            mandateDetails,
            true,
            handleScrollToDocumentUpload,
            driverData
        );
    const statusIndicators = new Array(0);
    if (declarationType === "PaperDeclaration") {
        statusIndicators.push(
            <ActionableStatusTag theme="white" text="Paper" />
        );
    }
    if (declarationStatus === "Completed") {
        statusIndicators.push(<ActionableStatusTag theme="completed" />);
    }
    if (declarationStatus === "DueToExpire") {
        statusIndicators.push(<ActionableStatusTag theme="dueToExpire" />);
    }

    const sendStatusActions =
        declarationType === DeclarationType.EDeclaration
            ? [sendNewEDeclarationAction]
            : [sendNewPaperDeclarationAction];

    const StatusContent = () => {
        return (
            <div className="flex flex-col text-sm">
                <span>
                    Declaration signed :{" "}
                    {convertDateStringToReadableString(declarationSigned)}
                </span>
                <span>
                    Expires: {convertDateStringToReadableString(expiryDate)}
                </span>
            </div>
        );
    };

    return (
        <ActionableStatus
            title="Declaration status"
            theme="grey"
            actionable
            statusIndicators={statusIndicators}
            actions={sendStatusActions}
            hasEmailAddress={!!driverData?.emailAddress}
            declarationComplete={true}
            licenceCountryOrigin={licenceCountryOrigin ?? undefined}
            driverData={driverData}
            validateByCompanyPreference={validateByCompanyPreference}
        >
            <div className="flex items-center">
                <div className="pr-3">
                    <Scroll size={24} weight="duotone" />
                </div>
                <StatusContent />
            </div>
        </ActionableStatus>
    );
};

export default DeclarationStatusComplete;
