import { useState } from "react";
import { useGetInvoices } from "../../../api/admin/invoice";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { errorToast, successToast } from "../../../toast";
import { useInvoice, useGetInvoiceChecks } from "../../../api/admin/invoice";
import { downloadFromArrayBuffer } from "../../../utils/downloadFromArrayBuffer";

const useInvoicesTab = (
    companyId: string,
    departmentId: string,
    dateFrom?: Date,
    dateTo?: Date
) => {
    const [pageNumber, setPageNumber] = useLocalStorage<number>(
        `InvoiceAddressTablePageNumber`,
        1
    );
    const [pageSize, setPageSize] = useLocalStorage<number>(
        `InvoiceAddressTablePageSize`,
        25
    );

    const { invoicesData, isLoadingInvoices, invoicesError, refetchInvoices } =
        useGetInvoices(companyId, pageNumber, pageSize);

    // Will remove line item related code after billing phase if not required.
    // const { lineItems, isLoadingLineItems, lineItemsError, refetchLineItems } =
    //     useGetInvoiceChecks(companyId, departmentId, dateFrom, dateTo);

    const { invoicePDF, chargableItemsXLS } = useInvoice();

    const [isInvoicePDFDownloading, setIsInvoicePDFDownloading] =
        useState(false);

    const handleDownloadInvoicePDF = async (
        invoiceId: string,
        fileName: string
    ) => {
        setIsInvoicePDFDownloading(true);

        try {
            const response = await invoicePDF(invoiceId, {
                FileName: fileName,
            });

            if (response.success) {
                downloadFromArrayBuffer(
                    response.content,
                    fileName,
                    undefined,
                    "application/pdf",
                    false
                );
                successToast(`Successfully downloaded invoice`);
                setTimeout(() => setIsInvoicePDFDownloading(false), 1000);
            }
        } catch (error) {
            setTimeout(() => setIsInvoicePDFDownloading(false), 1000);
            errorToast(`Failed to download invoice`);
        }
    };

    const [
        isChargeableItemsXLSDownloading,
        setIsChargeableItemsXLSDownloading,
    ] = useState(false);

    const handleDownloadChargeableItemsXLS = async (
        invoiceId: string,
        fileName: string
    ) => {
        setIsChargeableItemsXLSDownloading(true);

        try {
            const response = await chargableItemsXLS(invoiceId);

            if (response.success) {
                downloadFromArrayBuffer(
                    response.content,
                    fileName,
                    setIsChargeableItemsXLSDownloading,
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    false
                );
                successToast(`Successfully downloaded chargeable items`);
            }
        } catch (error) {
            setTimeout(() => setIsChargeableItemsXLSDownloading(false), 1000);
            errorToast(`Failed to download chargeable items`);
        }
    };

    return {
        invoices: invoicesData,
        refetchInvoices,
        isLoadingInvoices,
        invoicesError,
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
        // Will remove line item related code after billing phase if not required.
        // lineItems,
        // refetchLineItems,
        // isLoadingLineItems,
        // lineItemsError,
        handleDownloadInvoicePDF,
        isInvoicePDFDownloading,
        handleDownloadChargeableItemsXLS,
        isChargeableItemsXLSDownloading,
    };
};

export default useInvoicesTab;
