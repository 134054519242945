import {
    Bar,
    BarChart as ReBarChart,
    CartesianGrid,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { BarChartItem } from "./BarChart";

type VerticalBarChartProps = {
    ticks?: number[];
    domain?: [number, number];
    data: BarChartItem[];
};

const VerticalBarChart = ({ data, ticks, domain }: VerticalBarChartProps) => {
    return (
        <>
            <ResponsiveContainer height={260}>
                <ReBarChart
                    layout="vertical"
                    data={data}
                    margin={{
                        bottom: 0,
                        left: -4,
                    }}
                >
                    <CartesianGrid strokeDasharray="0" stroke="#E4E3DB" />
                    <XAxis
                        type="number"
                        tickLine={false}
                        tick={{ fontSize: "0.55rem", fill: "#474741" }}
                        stroke="#E4E3DB"
                        domain={domain}
                    />
                    <YAxis
                        dataKey="name"
                        type="category"
                        ticks={ticks}
                        tickLine={true}
                        tick={{ fontSize: "0.55rem", fill: "#474741" }}
                        stroke="#E4E3DB"
                        width={49}
                    />

                    <Tooltip cursor={false} />

                    <Bar dataKey="value">
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}-${entry.name}`}
                                fill={entry.color}
                            />
                        ))}
                    </Bar>
                </ReBarChart>
            </ResponsiveContainer>
        </>
    );
};

export default VerticalBarChart;
