import { Menu, MenuItem, createTheme } from "@mui/material";
import { Info } from "phosphor-react";
import { useState } from "react";

type InfoStatusIndicatorProps = {
    message: string;
};

const InfoStatusIndicator = ({ message }: InfoStatusIndicatorProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Info
                size={20}
                weight="fill"
                color="black"
                className="cursor-pointer"
                onClick={(e) => handleClick(e)}
            />

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    disablePadding: true,
                }}
                disableScrollLock={true}
            >
                <MenuItem
                    sx={{
                        width: 300,
                        cursor: "default",
                        whiteSpace: "normal",
                    }}
                >
                    <div className="flex flex-row items-center">
                        <div className="">{message}</div>
                    </div>
                </MenuItem>
            </Menu>
        </>
    );
};

export default InfoStatusIndicator;
