import clsx from "clsx";
import { Warning } from "phosphor-react";
import React from "react";
import { FieldError } from "react-hook-form";
import FormInputMessage from "./FormInputError";
import FormInputLabel from "./FormInputLabel";

export type FormTextAreaInputProps = Omit<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    "type" | "className" | "error"
> & {
    id: string;
    label?: string;
    placeholder: string;
    disabled?: boolean;
    error?: FieldError;
    defaultValue?: string;
    required?: boolean;
    rows?: number;
    tooltip?: string;
};

const FormTextAreaInput = React.forwardRef<
    HTMLTextAreaElement,
    FormTextAreaInputProps
>(
    (
        {
            id,
            label,
            placeholder,
            disabled,
            error,
            defaultValue,
            required,
            rows = 5,
            tooltip,
            ...inputProps
        },
        ref
    ) => {
        return (
            <div>
                {label && (
                    <FormInputLabel
                        htmlFor={id}
                        required={required}
                        tooltipText={tooltip}
                    >
                        {label}
                    </FormInputLabel>
                )}
                <div className="relative">
                    <textarea
                        id={id}
                        defaultValue={defaultValue}
                        rows={rows}
                        style={{ resize: "none" }}
                        disabled={disabled}
                        className={clsx(
                            "w-full rounded-sm border border-black p-4 transition-background-color",
                            "bg-S2D-dark-green-70.1 hover:bg-white focus:border-S2D-light-green-80 focus:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-S2D-light-green-80",
                            error &&
                                " border-S2D-error-40 pr-10 outline-none ring-S2D-error-40 focus:border-S2D-error-40 focus:ring-S2D-error-40"
                        )}
                        placeholder={placeholder}
                        ref={ref}
                        {...inputProps}
                    />
                    {error && (
                        <Warning
                            className="text-error pointer-events-none absolute top-1/2 right-3 h-6 w-6 -translate-y-1/2 transform"
                            weight="fill"
                        />
                    )}
                </div>
                <FormInputMessage error={error} />
            </div>
        );
    }
);

export default FormTextAreaInput;
