import { RadioGroup as HUIRadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { FieldError } from "react-hook-form";
import FormInputError from "./FormInputError";
import FormRadioButton from "./FormRadioButton";

export type FormRadioGroupOption<T> = {
    id: T;
    label: string;
    caption?: string;
    disabled?: boolean;
};

type FormRadioGroupProps<T> = {
    value?: T;
    options: FormRadioGroupOption<T>[];
    label?: string;
    error?: FieldError;
    onChange: (value: T) => void;
    disabled?: boolean;
    direction?: "row" | "col";
};

const FormRadioGroup = <T extends number | string>({
    value,
    options,
    label,
    error,
    onChange,
    disabled = false,
    direction = "col",
}: FormRadioGroupProps<T>) => {
    const handleChange = (value: T) => {
        const option = options.find((opt) => opt.id === value);
        if (!option) throw Error(`Couldn't find option with value ${value}`);

        if (option.disabled) return;
        onChange(value);
    };

    return (
        <div>
            <div className="w-full">
                <div className="">
                    <HUIRadioGroup
                        value={value}
                        onChange={handleChange}
                        className="flex flex-col gap-2"
                    >
                        {label && (
                            <HUIRadioGroup.Label>{label}</HUIRadioGroup.Label>
                        )}
                        <div
                            className={clsx(
                                "flex w-full flex-col justify-between gap-2",
                                direction === "col" && "md:flex-col",
                                direction === "row" && "md:flex-row"
                            )}
                        >
                            {options.map((option) => (
                                <HUIRadioGroup.Option
                                    key={option.id}
                                    value={option.id}
                                    className={({ checked }) =>
                                        clsx(
                                            "transition- relative mb-4 flex w-full cursor-pointer flex-col rounded-sm p-[0.125rem] px-5 py-4 outline-0 ring-0 md:flex-row",
                                            !checked && "border border-black",
                                            checked &&
                                                "border-2 border-S2D-light-green-80 bg-S2D-dark-green-70.1",
                                            disabled && "cursor-not-allowed",
                                            error &&
                                                !option.disabled &&
                                                "border-S2D-error-30 bg-S2D-error-90",
                                            option.disabled &&
                                                "cursor-not-allowed bg-S2D-neutral-95"
                                        )
                                    }
                                >
                                    {({ active, checked }) => (
                                        <>
                                            <div className="mb-2 flex flex-1 md:mb-0">
                                                <FormRadioButton
                                                    checked={checked}
                                                    active={active}
                                                    isError={!!error}
                                                    disabled={
                                                        disabled ||
                                                        option.disabled
                                                    }
                                                />
                                                <div className="relative flex-1 md:-top-[2px]">
                                                    <HUIRadioGroup.Label
                                                        as="p"
                                                        className="font-medium"
                                                    >
                                                        {option.label}
                                                    </HUIRadioGroup.Label>
                                                </div>
                                            </div>
                                            <div className="relative flex-1 md:-top-[2px]">
                                                <HUIRadioGroup.Label
                                                    as="p"
                                                    className="text-sm font-medium"
                                                >
                                                    {option.caption}
                                                </HUIRadioGroup.Label>
                                            </div>
                                        </>
                                    )}
                                </HUIRadioGroup.Option>
                            ))}
                        </div>
                    </HUIRadioGroup>
                </div>
            </div>
            <div className="text-center">
                <FormInputError error={error} />
            </div>
        </div>
    );
};

export default FormRadioGroup;
