import * as yup from "yup";

// This utility checks that the passed in SchemaType has the same keys as (extends) the OriginalType
type EnsureMatchingKeys<
    OriginalType,
    SchemaType extends { [Key in keyof OriginalType]: yup.AnySchema }
> = SchemaType;

// helper: allows you to check if a yup schema has the same keys as another type: 'FormType'
export type SchemaKeys<FormType> = EnsureMatchingKeys<
    FormType,
    {
        [Key in keyof FormType]: yup.AnySchema;
    }
>;

// function: if you require all keys in an object to have the same schema
export const createSchema = <ObjectKeys>(
    keys: (keyof ObjectKeys)[],
    valueSchema: yup.AnySchema
) =>
    yup
        .object()
        .shape(
            Object.fromEntries(
                keys.map((key) => [key, valueSchema])
            ) as SchemaKeys<ObjectKeys>
        );
