import { ApiDriverVehicleResponse } from "../../models/api/admin/driver";
import { EditDriverVehicleDto, UpdateDriverVehicleResponse } from "../../models/app/admin/driverVehicle";
import { S2DApiFetcherResponse } from "../common/apiResponse";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useEditDriverVehicle = () =>
    useS2DApiFetcher<
        Partial<EditDriverVehicleDto>,
        UpdateDriverVehicleResponse,
        { vehicleId: string; driverId: string; dto: Partial<EditDriverVehicleDto> }
    >(
        "PUT",
        ({ vehicleId, driverId, dto }) => ({
            url: `api/drivers/${driverId}/vehicles/${vehicleId}`,
            body: dto,
        }),
        [],
        true
    );

export const useEditDriverVehicleWithAltToken = () =>
    useS2DApiFetcher<
        EditDriverVehicleDto,
        null,
        {
            vehicleId: string;
            driverId: string;
            token: string;
            dto: EditDriverVehicleDto;
        }
    >(
        "PUT",
        ({ vehicleId, driverId, token, dto }) => ({
            url: `api/drivers/${driverId}/vehicles/${vehicleId}`,
            body: dto,
            altToken: token,
        }),
        [],
        false
    );

const useGetVehicleWithAltToken = () =>
    useS2DApiFetcher<
        null,
        ApiDriverVehicleResponse,
        { vehicleId: string; driverId: string; token: string }
    >(
        "GET",
        ({ vehicleId, driverId, token }) => ({
            url: `api/drivers/${driverId}/vehicles/${vehicleId}`,
            altToken: token,
        }),
        [],
        false
    );

export const useDriverVehicle = (vehicleId?: string, driverId?: string) => {
    const { data, error, isLoading, mutate } = useS2DApiSWR<ApiDriverVehicleResponse>(
        vehicleId && driverId ? `api/drivers/${driverId}/vehicles/${vehicleId}` : null
    );
    const [fetchVehicleData] = useS2DApiFetcher<null, ApiDriverVehicleResponse, { vehicleId?: string, driverId?: string }>(
        "GET",
        ({ vehicleId, driverId }) => ({
            url: `api/drivers/${driverId}/vehicles/${vehicleId}`,
        }),
        [],
        true
    );
    const refetchDriverVehicle = async (newVehicleId?: string) => {
        if (newVehicleId) {
            const response: S2DApiFetcherResponse<ApiDriverVehicleResponse> = await fetchVehicleData({ vehicleId: newVehicleId, driverId });
            if (response.success) {
                await mutate(response.content, false);
                return response.content;
            } else {
                throw new Error("Failed to fetch vehicle data.");
            }
        } else {
            return mutate();
        }
    };
    const [editDriverVehicle, isEditingDriverVehicle] = useEditDriverVehicle();
    const [editDriverVehicleWithAltToken, isEditingDriverVehicleWithAltToken] =
        useEditDriverVehicleWithAltToken();
    const [getVehicleWithAltToken, isGettingVehicleWithAltToken] =
        useGetVehicleWithAltToken();
    return {
        driverVehicle: data,
        driverVehicleError: error,
        isDriverVehicleLoading: isLoading,
        refetchDriverVehicle,
        editDriverVehicle: async (
            vehicleId: string,
            driverId: string,
            dto: Partial<EditDriverVehicleDto>
        ) => await editDriverVehicle({ vehicleId, driverId, dto }),
        isEditingDriverVehicle,
        editDriverVehicleWithAltToken: async (
            vehicleId: string,
            driverId: string,
            token: string,
            dto: EditDriverVehicleDto
        ) =>
            await editDriverVehicleWithAltToken({
                vehicleId,
                driverId,
                token,
                dto,
            }),
        isEditingDriverVehicleWithAltToken,
        getVehicleWithAltToken: async (
            vehicleId: string,
            driverId: string,
            token: string
        ) => await getVehicleWithAltToken({ vehicleId, driverId, token }),
        isGettingVehicleWithAltToken,
    };
};
