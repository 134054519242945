import WebpageReference from "./webpage-reference.svg";
import MobileReference from "./mobile-reference.svg";
import { Controller, UseFormReturn } from "react-hook-form";
import FormTextInput from "../../FormTextInput";
import { ColourDot } from "./NonEditableThemeView";
import Button from "../../Button";
import { ApiCompanyThemeDto } from "../../../models/api/admin/themes";

type CompanyAppearanceFormProps = {
    methods: UseFormReturn<ApiCompanyThemeDto>;
    onSubmit: (
        data: ApiCompanyThemeDto,
        callback: (value: boolean) => void
    ) => Promise<void>;
    isSubmitting?: boolean;
    onReset: (callback: (value: boolean) => void) => Promise<void>;
    isResetting?: boolean;
    setIsEdit: (value: boolean) => void;
};

const CompanyAppearanceForm: React.FC<CompanyAppearanceFormProps> = ({
    methods,
    onSubmit,
    isSubmitting,
    onReset,
    isResetting,
    setIsEdit,
}) => {
    const { register, control, formState, handleSubmit, reset } = methods;

    const handleFormSubmit = (data: ApiCompanyThemeDto) => {
        onSubmit(data, setIsEdit);
    };

    const handleCancel = () => {
        reset();
        setIsEdit(false);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-2 px-4">
                        <Controller
                            name="primaryColour"
                            control={control}
                            render={({ field: { value } }) => (
                                <FormTextInput
                                    label="1 - Primary"
                                    {...register("primaryColour")}
                                    id="primary"
                                    error={formState.errors.primaryColour}
                                    ValueHelper={<ColourDot colour={value} />}
                                />
                            )}
                        />
                        <div className="my-2 border border-t-0 border-S2D-dark-green-70.3" />
                        <Controller
                            name="primaryLightColour"
                            control={control}
                            render={({ field: { value } }) => (
                                <FormTextInput
                                    label="2 - Primary light"
                                    {...register("primaryLightColour")}
                                    id="primary-light"
                                    error={formState.errors.primaryLightColour}
                                    ValueHelper={<ColourDot colour={value} />}
                                />
                            )}
                        />
                        <Controller
                            name="primaryLighterColour"
                            control={control}
                            render={({ field: { value } }) => (
                                <FormTextInput
                                    label="3 - Primary lighter"
                                    {...register("primaryLighterColour")}
                                    id="primary-lighter"
                                    error={
                                        formState.errors.primaryLighterColour
                                    }
                                    ValueHelper={<ColourDot colour={value} />}
                                />
                            )}
                        />
                        <Controller
                            name="primaryVeryLightColour"
                            control={control}
                            render={({ field: { value } }) => (
                                <FormTextInput
                                    label="4 - Primary very light"
                                    {...register("primaryVeryLightColour")}
                                    id="primary-very-light"
                                    error={
                                        formState.errors.primaryVeryLightColour
                                    }
                                    ValueHelper={<ColourDot colour={value} />}
                                />
                            )}
                        />
                        <div className="my-2 border border-t-0 border-S2D-dark-green-70.3" />
                        <Controller
                            name="primaryDarkColour"
                            control={control}
                            render={({ field: { value } }) => (
                                <FormTextInput
                                    label="5 - Primary dark"
                                    {...register("primaryDarkColour")}
                                    id="primary-dark"
                                    error={formState.errors.primaryDarkColour}
                                    ValueHelper={<ColourDot colour={value} />}
                                />
                            )}
                        />
                        <Controller
                            name="primaryDarkerColour"
                            control={control}
                            render={({ field: { value } }) => (
                                <FormTextInput
                                    label="6 - Primary darker"
                                    {...register("primaryDarkerColour")}
                                    id="primary-darker"
                                    error={formState.errors.primaryDarkerColour}
                                    ValueHelper={<ColourDot colour={value} />}
                                />
                            )}
                        />
                        <div className="my-2 border border-t-0 border-S2D-dark-green-70.3" />
                        <Controller
                            name="secondaryColour"
                            control={control}
                            render={({ field: { value } }) => (
                                <FormTextInput
                                    label="7 - Secondary"
                                    {...register("secondaryColour")}
                                    id="secondary"
                                    error={formState.errors.secondaryColour}
                                    ValueHelper={<ColourDot colour={value} />}
                                />
                            )}
                        />
                        <div className="my-2 border border-t-0 border-S2D-dark-green-70.3" />
                        <Controller
                            name="menuTextColour"
                            control={control}
                            render={({ field: { value } }) => (
                                <FormTextInput
                                    label="8 - Menu text"
                                    {...register("menuTextColour")}
                                    id="menu-text"
                                    error={formState.errors.menuTextColour}
                                    ValueHelper={<ColourDot colour={value} />}
                                />
                            )}
                        />
                        <Controller
                            name="buttonTextColour"
                            control={control}
                            render={({ field: { value } }) => (
                                <FormTextInput
                                    label="9 - Button text"
                                    {...register("buttonTextColour")}
                                    id="button-text"
                                    error={formState.errors.buttonTextColour}
                                    ValueHelper={<ColourDot colour={value} />}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-2 px-4">
                    <div className="my-2 border border-t-0 border-S2D-dark-green-70.3 md:hidden" />
                    <img
                        src={WebpageReference}
                        alt="webpage theme reference"
                        className="w-full"
                    />
                    <img
                        src={MobileReference}
                        alt="mobile theme reference"
                        className="w-full"
                    />
                </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-between gap-4 md:flex-row">
                <Button
                    variant="tertiary"
                    className="w-full justify-center md:w-auto"
                    type="button"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <div className="flex flex-col-reverse gap-4 md:flex-row">
                    <Button
                        variant="secondary"
                        className="w-full justify-center md:w-auto"
                        onClick={() => onReset(setIsEdit)}
                        loading={isResetting}
                    >
                        Reset to defaults
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        loading={isSubmitting}
                        className="w-full justify-center md:w-auto"
                    >
                        Save
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default CompanyAppearanceForm;
