import { Controller, UseFormReturn } from "react-hook-form";
import FormDropdown from "../../../../FormDropdown";
import FormTextInput from "../../../../FormTextInput";
import { EditRiskCategoriesFormValues } from "./useEditRiskCategoriesForm";

interface RiskCategoriesFormFieldsProps {
    methods: UseFormReturn<EditRiskCategoriesFormValues>;
}

// TODO 12/04/23 See if there is a way to reuse this in CompanyRiskCategoriesFields (AddCompanyForm)
export default function RiskCategoriesFormFields({
    methods,
}: RiskCategoriesFormFieldsProps) {
    const { formState, register, control } = methods;

    const rangeOptions = new Array(0);
    for (let i = 0; i <= 12; i++) {
        rangeOptions.push({
            label: `${i} penalty points`,
            value: i,
        });
    }
    return (
        <div className="flex flex-col space-y-3">
        <FormTextInput
                label="Range 1 Name:"
                id="range1Name"
                placeholder="Please enter a name for this range"
                required
                error={methods.formState.errors.riskCategory?.range1Name}
                {...methods.register("riskCategory.range1Name")}
            />
            <FormTextInput
                label="Range starts at:"
                id="riskCategory.range1"
                placeholder="0 penalty points"
                error={formState.errors.riskCategory?.range1}
                disabled
                {...register("riskCategory.range1")}
            />
            <FormTextInput
                label="Range 2 Name:"
                id="range2Name"
                placeholder="Please enter a name for this range"
                required
                error={methods.formState.errors.riskCategory?.range2Name}
                {...methods.register("riskCategory.range2Name")}
            />
            <Controller
                name="riskCategory.range2"
                control={control}
                render={({ field: { value, onChange } }) => (
                    <FormDropdown
                        label="Range starts at:"
                        options={rangeOptions ?? []}
                        value={value ?? null}
                        onChange={onChange}
                        pleaseSelectText="Select penalty points"
                        required
                        error={formState.errors.riskCategory?.range2}
                    />
                )}
            />
            <FormTextInput
                label="Range 3 Name:"
                id="range3Name"
                placeholder="Please enter a name for this range"
                required
                error={methods.formState.errors.riskCategory?.range3Name}
                {...methods.register("riskCategory.range3Name")}
            />
            <Controller
                name="riskCategory.range3"
                control={control}
                render={({ field: { value, onChange } }) => (
                    <FormDropdown
                        label="Range starts at:"
                        options={rangeOptions ?? []}
                        value={value ?? null}
                        onChange={(value) => {
                            onChange(value);
                        }}
                        pleaseSelectText="Select penalty points"
                        required
                        error={formState.errors.riskCategory?.range3}
                    />
                )}
            />
        </div>
    );
}
