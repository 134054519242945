import { useParams } from "react-router-dom";
import { useDriver } from "../../../api/admin/driver";
import { useEdeclaration } from "../../../api/edeclaration";
import { MandateDetails } from "../../../models/api/admin/mandateDetails";
import { errorToast, successToast } from "../../../toast";
import { StatusAction } from "../../ActionableStatus/ActionableStatus";
import { DeclarationStatus } from "../../../models/shared/statuses";
import { DeclarationType } from "../../../models/app/eDeclarations";
import { ApiDriverByIdResponse } from "../../../models/api/admin/driver";

type PageParams = {
    id: string;
};

const useDeclarationStatusActions = (
    declarationId: string | null,
    mandateDetails: MandateDetails | null,
    declarationIsComplete: boolean,
    handleScrollToDocumentUpload: () => void,
    driverData?: ApiDriverByIdResponse
) => {
    const { id: driverId } = useParams<PageParams>();
    const {
        sendDeclaration,
        sendInstantDeclarationRequest,
        updateDeclarationType,
        refetch,
    } = useDriver(driverId);
    const { updateEDeclarationStatus } = useEdeclaration(
        declarationId ?? undefined
    );

    const sendNewDeclaration = async (declarationType: DeclarationType) => {
        if (!driverId) return;
        const response = await sendDeclaration(driverId, {
            declarationType: declarationType,
        });

        if (!response.success) {
            errorToast("Failed to create a new declaration");
            return;
        }

        successToast("Successfully created a new declaration");
        refetch();
    };

    const updateDeclarationTypeAction = async (
        declarationType: DeclarationType
    ) => {
        if (!driverId || !declarationId) return;
        const response = await updateDeclarationType(declarationId, {
            declarationType,
        });

        if (!response.success) {
            errorToast("Failed to change declaration type");
            return;
        }

        successToast("Successfully changed declaration type");
        refetch();
    };

    const updateDeclarationStatusAction = async () => {
        if (driverId && declarationId) {
            const response = await updateEDeclarationStatus(declarationId, {
                declarationStatus: DeclarationStatus.Requested,
            });

            if (!response.success) {
                errorToast("Failed to mark as requested");
                return;
            }
            successToast("Marked as Requested");
            refetch();
        }
    };

    const sendInstantDeclarationRequestAction = async () => {
        if (!declarationId) return;
        const response = await sendInstantDeclarationRequest(declarationId);

        if (!response.success) {
            errorToast("Failed to send instant request");
            return;
        }
        successToast("Request sent");
        refetch();
    };

    const sendNewEDeclarationAction: StatusAction = {
        dropdownItem: "sendNewEDeclaration",
    };

    const sendNewPaperDeclarationAction: StatusAction = {
        dropdownItem: "sendNewPaperDeclaration",
        modalButtonAction: async () => {
            await sendNewDeclaration(DeclarationType.PaperDeclaration);
        },
        modalHeading: "Send Paper Declaration request?",
        modalButtonText: "Send Paper Declaration request",
        hasEmailAddress: !!driverData?.emailAddress,
    };

    const resendEDeclarationAction: StatusAction = {
        dropdownItem: "resendEDeclaration",
        modalButtonAction: async () => {
            await sendInstantDeclarationRequestAction();
        },
        modalHeading: "Resend E-Declaration request?",
        modalButtonText: "Resend E-Declaration request",
        hasEmailAddress: !!driverData?.emailAddress,
    };

    const resendPaperDeclarationAction: StatusAction = {
        dropdownItem: "resendPaperDeclarationEmail",
        modalButtonAction: async () => {
            await sendInstantDeclarationRequestAction();
        },
        modalHeading: "Resend paper declaration email?",
        modalButtonText: "Resend paper declaration email",
        hasEmailAddress: !!driverData?.emailAddress,
    };

    const switchToEDeclarationAction: StatusAction = {
        dropdownItem: "switchToEDeclaration",
        modalButtonAction: async () => {
            await updateDeclarationTypeAction(DeclarationType.EDeclaration);
        },
        modalHeading: "Switch to E-Declaration?",
        modalButtonText: "Switch to E-Declaration",
        hasEmailAddress: !!driverData?.emailAddress,
        declarationComplete: declarationIsComplete,
    };

    const switchToPaperDeclarationAction: StatusAction = {
        dropdownItem: "switchToPaperDeclaration",
        modalButtonAction: async () => {
            await updateDeclarationTypeAction(DeclarationType.PaperDeclaration);
        },
        modalHeading: "Switch to paper declaration?",
        modalButtonText: "Switch to paper declaration",
        hasEmailAddress: !!driverData?.emailAddress,
        declarationComplete: declarationIsComplete,
    };

    const markAsRequestedAction: StatusAction = {
        dropdownItem: "markAsRequested",
        modalButtonAction: async () => {
            await updateDeclarationStatusAction();
        },
    };

    const uploadSignedDeclarationAction: StatusAction = {
        dropdownItem: "uploadSignedDeclaration",
        modalHeading: "Upload signed declaration",
        modalSubHeading: "Upload a signed declaration",
        modalButtonText: "Upload",
        modalButtonAction: handleScrollToDocumentUpload,
    };

    return {
        sendNewEDeclarationAction,
        sendNewPaperDeclarationAction,
        resendEDeclarationAction,
        resendPaperDeclarationAction,
        switchToEDeclarationAction,
        switchToPaperDeclarationAction,
        markAsRequestedAction,
        uploadSignedDeclarationAction,
    };
};

export default useDeclarationStatusActions;
