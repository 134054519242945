import {
    ApiInvoiceSettingsItem,
    CompanyStatusFilters,
    type ApiCompaniesResponse,
    type ApiCompanyResponse,
} from "../../models/api/admin/company";
import {
    EditCompanyStatusDto,
    type AddCompanyDto,
    type AddCompanyLogoDto,
    type EditCompanyDto,
} from "../../models/app/admin/company";

import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

const useAddCompany = () =>
    useS2DApiFetcher<AddCompanyDto, string, { dto: AddCompanyDto }>(
        "POST",
        ({ dto }) => ({
            url: "api/companies",
            body: dto,
        }),
        [],
        true
    );

export const useAddCompanyLogo = () =>
    useS2DApiFetcher<
        AddCompanyLogoDto,
        string,
        { id: string; dto: AddCompanyLogoDto }
    >(
        "POST",
        ({ id, dto }) => ({
            url: `api/companies/${id}/logo`,
            body: dto,
        }),
        [],
        true
    );

const useEditCompany = () =>
    useS2DApiFetcher<
        EditCompanyDto,
        string,
        { id: string; dto: EditCompanyDto }
    >(
        "PUT",
        ({ id, dto }) => ({
            url: `api/companies/${id}`,
            body: dto,
        }),
        [400],
        true
    );

const useDeleteCompany = () =>
    useS2DApiFetcher<void, string, { id: string }>(
        "DELETE",
        ({ id }) => ({
            url: `api/companies/${id}`,
        }),
        [],
        true
    );

const useEditCompanyStatus = () =>
    useS2DApiFetcher<
        EditCompanyStatusDto,
        ApiCompanyResponse,
        { companyId: string; dto: EditCompanyStatusDto }
    >(
        "PUT",
        ({ companyId, dto }) => ({
            url: `api/admin/companies/${companyId}`,
            body: dto,
        }),
        [],
        true
    );

const useEditInvoiceSettings = () =>
    useS2DApiFetcher<
        ApiInvoiceSettingsItem,
        string,
        {
            companyId: string;
            dto: ApiInvoiceSettingsItem;
        }
    >(
        "PUT",
        ({ companyId, dto }) => ({
            url: `api/companies/${companyId}/invoice-settings`,
            body: dto,
        }),
        [],
        true
    );

export const useGetCompanies = (
    pageNumber: number,
    pageSize: number,
    status?: CompanyStatusFilters | null,
    search?: string
) => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiCompaniesResponse>(
            `api/companies?${search ? `Search=${search}&` : ""}${status ? `StatusFilters=${status}&` : ""
            }PageNumber=${pageNumber}&PageSize=${pageSize}`
        );
    return {
        companyList: data,
        companyListError: error,
        isCompanyListLoading: isLoading,
        refetchCompanies: refetch,
    };
};

export const useCompany = (id?: string) => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiCompanyResponse>(id ? `api/companies/${id}` : null);
    const [addCompany, isAddingCompany] = useAddCompany();
    const [editCompany, isEditingCompany] = useEditCompany();
    const [deleteCompany, isDeletingCompany] = useDeleteCompany();
    const [addCompanyLogo, isAddingCompanyLogo] = useAddCompanyLogo();
    const [editCompanyStatus, isEditingCompanyStatus] = useEditCompanyStatus();
    const [editInvoiceSettings, isEditingInvoiceSettings] = useEditInvoiceSettings();

    return {
        companyData: data,
        companyError: error,
        isLoadingCompany: isLoading,
        addCompany: async (dto: AddCompanyDto) => await addCompany({ dto }),
        isAddingCompany,
        addCompanyLogo: async (id: string, dto: AddCompanyLogoDto) =>
            await addCompanyLogo({ id, dto }),
        isAddingCompanyLogo,
        editCompany: async (id: string, dto: EditCompanyDto) =>
            await editCompany({ id, dto }),
        isEditingCompany,
        editCompanyStatus: async (
            companyId: string,
            dto: EditCompanyStatusDto
        ) => await editCompanyStatus({ companyId, dto }),
        isEditingCompanyStatus,
        editInvoiceSettings: async (
            companyId: string,
            dto: ApiInvoiceSettingsItem
        ) => await editInvoiceSettings({ companyId, dto }),
        isEditingInvoiceSettings,
        deleteCompany: async (id: string) => await deleteCompany({ id }),
        isDeletingCompany,
        refetchCompany: refetch,
    };
};

export const useGetCompaniesQuery = () => {
    const [getCompanies, isGettingCompanies] = useS2DApiFetcher<
        null,
        ApiCompaniesResponse,
        { search: string; pageNumber: number; pageSize: number }
    >(
        "GET",
        ({ search, pageNumber, pageSize }) => ({
            url: `api/companies?Search=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
        }),
        [],
        true
    );

    return {
        getCompanies: async (
            search: string,
            pageNumber: number,
            pageSize: number
        ) => await getCompanies({ search, pageNumber, pageSize }),
        isGettingCompanies,
    };
};

export const useDownloadCompaniesReport = () => {
    const [getCompaniesReport, isGettingCompaniesReport] = useS2DApiFetcher<
        null,
        Blob,
        { search?: string; statusFilters?: CompanyStatusFilters[] }
    >(
        "GET",
        ({ search, statusFilters }) => {
            const queryParams = new URLSearchParams();
            if (search) queryParams.append("Search", search);
            if (statusFilters) statusFilters.forEach(status => queryParams.append("StatusFilters", status));
            const queryString = queryParams.toString();

            return {
                url: `api/companies/download?${queryString}`,
                body: null,
                altToken: undefined,
                contentType: "text/csv",
            };
        },
        [],
        true
    );

    return {
        getCompaniesReport: async (search?: string, statusFilters?: CompanyStatusFilters[]) => await getCompaniesReport({ search, statusFilters }),
        isGettingCompaniesReport,
    };
};
