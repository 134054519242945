import LoadingMessage from "../../ReusableComponents/LoadingMessage/LoadingMessage";
import Table from "../../ReusableComponents/Table";
import { initCompanyUsersTableContents } from "./CompanyUsersTableInit";
import PaginationNav from "../../PaginationNav";
import ApiError from "../../../api/common/apiError";
import FormMessageBox from "../../FormMessageBox";
import EditCompanyUserModal from "../../EditCompanyUserModal";
import ConfirmationDialog from "../../ReusableComponents/ConfirmationDialog";
import type { ApiCompanyUsersResponse } from "../../../models/api/admin/companyUser";
import useCompanyUsersTable from "./useCompanyUsersTable";
import DeleteConfirmation from "../../ReusableComponents/DeleteConfirmation";
import { UserStatus } from "../../../models/shared/statuses";

export type CompanyUsersTableProps = {
    usersData?: ApiCompanyUsersResponse;
    usersError?: ApiError;
    isLoadingUsers: boolean;
    refetchCompanyUsers: () => void;
    pageNumber: number;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    companyId?: string;
    companyName?: string;
};

const CompanyUsersTable: React.FC<CompanyUsersTableProps> = ({
    usersData,
    usersError,
    isLoadingUsers,
    refetchCompanyUsers,
    setPageNumber,
    pageSize,
    setPageSize,
    companyId,
    companyName,
}) => {
    const {
        tableHeaders,
        setTableHeaders,
        deleteUserDialogOpen,
        setDeleteUserDialogOpen,
        resetPasswordDialogOpen,
        setResetPasswordDialogOpen,
        disableUserDialogOpen,
        setDisableUserDialogOpen,
        editUserDialogOpen,
        setEditUserDialogOpen,
        selectedUser,
        handleDeleteUser,
        handleDisableUser,
        handleResetPassword,
        companyUserMenuItems,
        isDeletingCompanyUser,
    } = useCompanyUsersTable(refetchCompanyUsers, usersData, companyId);

    return (
        <>
            {isLoadingUsers && <LoadingMessage />}
            {!isLoadingUsers && usersData && (
                <>
                    <Table
                        tableHeaders={tableHeaders}
                        setTableHeaders={setTableHeaders}
                        tableContents={initCompanyUsersTableContents(
                            usersData.items
                        )}
                        menuItems={companyUserMenuItems}
                    />
                    <PaginationNav
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalCount={usersData.totalCount}
                        totalPages={usersData.totalPages}
                        currentPage={usersData.pageNumber}
                        hasPreviousPage={usersData.hasPreviousPage}
                        hasNextPage={usersData.hasNextPage}
                    />
                </>
            )}
            {usersError && (
                <FormMessageBox
                    variant="error"
                    title={"Error loading users"}
                    message={
                        "We could not load your user data, please try again in 10 minutes"
                    }
                />
            )}
            {selectedUser && (
                <>
                    <EditCompanyUserModal
                        isOpen={editUserDialogOpen}
                        setIsOpen={setEditUserDialogOpen}
                        refetchCompanyUsers={refetchCompanyUsers}
                        selectedUser={selectedUser}
                        companyId={companyId}
                        companyName={companyName}
                        onDelete={() => setDeleteUserDialogOpen(true)}
                        onResetPassword={() => setResetPasswordDialogOpen(true)}
                        onDisable={() => setDisableUserDialogOpen(true)}
                    />
                    <DeleteConfirmation
                        isOpen={deleteUserDialogOpen}
                        onClose={() => setDeleteUserDialogOpen(false)}
                        title={"You are about to delete a user!"}
                        onSubmit={handleDeleteUser}
                        isLoading={isDeletingCompanyUser}
                    />
                    <ConfirmationDialog
                        open={resetPasswordDialogOpen}
                        onClose={() => setResetPasswordDialogOpen(false)}
                        title={`Reset password for ${selectedUser.firstName} ${selectedUser.lastName}?`}
                        caption="An email will be sent to the user with a link to reset their password."
                        onConfirm={handleResetPassword}
                        isLoading={false}
                    />
                    <ConfirmationDialog
                        open={disableUserDialogOpen}
                        onClose={() => setDisableUserDialogOpen(false)}
                        title={`${
                            selectedUser.status.toLowerCase() !==
                            UserStatus.Disabled.toLowerCase()
                                ? "Disable"
                                : "Enable"
                        } ${selectedUser.firstName} ${selectedUser.lastName}?`}
                        caption={`${
                            selectedUser.status.toLowerCase() !==
                            UserStatus.Disabled.toLowerCase()
                                ? "This action will prevent the user from signing into Safe2Drive."
                                : "This action will allow the user to sign into Safe2Drive."
                        }`}
                        onConfirm={() => handleDisableUser(selectedUser)}
                        isLoading={false}
                    />
                </>
            )}
        </>
    );
};

export default CompanyUsersTable;
