import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { EditCompanyFormValues } from "../../../../EditCompanyForms/EditCompanyFormValues";
import { CheckSettings } from "../../../../../models/api/admin/company";
import { CheckPricingSchema } from "../../../../../schemas/companySchemas";
import { useEffect, useRef } from "react";

export type EditCheckPricingFormValues = Pick<
    CheckSettings["settings"],
    "pricing"
>;

const useEditCheckFrequenciesForm = (initialValues: EditCompanyFormValues) => {
    const initialPricing = initialValues?.settings?.pricing;
    const isLoading = useRef(false)

    const displayedValues: EditCheckPricingFormValues = {
        pricing: {
            licenceCheckPrice: initialPricing?.licenceCheckPrice ?? 0.0,
            manualCheckPrice: initialPricing?.manualCheckPrice ?? 0.0,
            irishLicenceCheckPrice:
                initialPricing?.irishLicenceCheckPrice ?? 0.0,
            foreignLicenceCheckPrice:
                initialPricing?.foreignLicenceCheckPrice ?? 0.0,
            greyFleetCheckPrice: initialPricing?.greyFleetCheckPrice ?? 0.0,
        },
    };

    const validationSchema = yup
        .object()
        .shape({ pricing: CheckPricingSchema });

    const methods = useForm<EditCheckPricingFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: displayedValues || null,
    });

    useEffect(() => {
        methods.reset(displayedValues);
        isLoading.current = false;
        }, [JSON.stringify(displayedValues)])

    return {
        methods,
        displayedValues,
        isLoading
    };
};

export default useEditCheckFrequenciesForm;
