import {
    type AlertTitle,
    type ApiAlertSummaryResponse,
} from "../../models/api/admin/dashboard";
import InfoCard from "../ReusableComponents/InfoCard.tsx/InfoCard";
import CardListContainer from "../ReusableComponents/CardListContainer";
import { Warning, WarningOctagon } from "phosphor-react";
import { useIsSmallScreen } from "../../hooks/screenSizes";

export type DashboardAlertsTabContentsProps<T> = {
    alertsData?: ApiAlertSummaryResponse;
    isLoadingAlerts: boolean;
    action?: (alertTitle: T | AlertTitle) => void;
};

const DashboardAlertsTabContents = <T,>({
    alertsData,
    isLoadingAlerts,
    action,
}: DashboardAlertsTabContentsProps<T>) => {
    const { immediateAlerts, upcomingAlerts } = alertsData || {};
    const isLargerDisplay = useIsSmallScreen();
    const countTextSize = isLargerDisplay ? "text-[40px]" : "text-[32px]";
    const iconSize = isLargerDisplay ? 32 : 24;
    return (
        <div className="flex flex-col gap-[30px] xl:flex-row">
            <CardListContainer
                title="Immediate alerts"
                isLoading={isLoadingAlerts}
                hasContent={!!immediateAlerts?.length}
                noContentMessage="No immediate alerts"
            >
                <>
                    {immediateAlerts?.map((alert) => (
                        <InfoCard
                            key={alert.name}
                            fontSize={countTextSize}
                            count={alert.driverCount}
                            title={alert.name}
                            highlightColour="border-S2D-error-40"
                            icon={
                                <WarningOctagon
                                    size={iconSize}
                                    weight="duotone"
                                />
                            }
                            onClick={action}
                        />
                    ))}
                </>
            </CardListContainer>

            <CardListContainer
                title="Upcoming alerts"
                isLoading={isLoadingAlerts}
                hasContent={!!upcomingAlerts?.length}
                noContentMessage="No upcoming alerts"
            >
                <>
                    {upcomingAlerts?.map((alert) => (
                        <InfoCard
                            key={alert.name}
                            fontSize={countTextSize}
                            count={alert.driverCount}
                            title={alert.name}
                            highlightColour="border-S2D-warning-90"
                            icon={<Warning size={iconSize} weight="duotone" />}
                            onClick={action}
                        />
                    ))}
                </>
            </CardListContainer>
        </div>
    );
};

export default DashboardAlertsTabContents;
