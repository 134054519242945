import GreyFleetVehicleAnalysisCharts from "../ChartGroups/GreyFleetVehicleAnalysisCharts";
import greyFleetAnalysisContent from "../GreyFleetAnalysis/greyFleetAnalysisContent";
import GreyFleetDriversSection from "./GreyFleetDriversSection";
import PdfReport from "../ReusableComponents/PdfReport";
import ReportAnalysisContainer from "../ReusableComponents/PdfReport/ReportAnalysisContainer";
import { type GreyFleetAlertsData } from "../../models/api/admin/greyFleet";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import { ApiDepartmentItem } from "../../models/api/admin/department";

type GreyFleetPdfReportProps = {
    analysisData?: GreyFleetAlertsData;
    company: ApiCompanyResponse | null;
    department: ApiDepartmentItem | null;
};
export default function GreyFleetPdfReport({
    analysisData,
    company,
    department,
}: GreyFleetPdfReportProps) {
    const analysisContent = greyFleetAnalysisContent(analysisData);
    const { companyName, companyId } = company || {};
    const { departmentName, departmentId } = department || {};

    return (
        <PdfReport companyName={companyName} departmentName={departmentName}>
            <div className="flex flex-col">
                {analysisContent &&
                    analysisContent.map(({ title, content }) => {
                        return (
                            <div key={title}>
                                <ReportAnalysisContainer title={title}>
                                    {content}
                                </ReportAnalysisContainer>
                            </div>
                        );
                    })}
            </div>

            <ReportAnalysisContainer title="Grey Fleet Vehicle Analysis">
                <GreyFleetVehicleAnalysisCharts
                    companyId={companyId}
                    departmentId={departmentId}
                />
            </ReportAnalysisContainer>
            <ReportAnalysisContainer title="Your grey fleet drivers">
                <GreyFleetDriversSection
                    driverCount={analysisData?.greyFleetDriverCount}
                />
            </ReportAnalysisContainer>
        </PdfReport>
    );
}
