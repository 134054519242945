import { useEffect, useState } from "react";
import { useAppliedFilterLocalStorage } from "../../hooks/useAppliedFilterLocalStorage";
import { type ApiCompanyResponse } from "../../models/api/admin/company";
import { type ApiDepartmentItem } from "../../models/api/admin/department";
import { driverPageLocation } from "../../utils/driverPageLocation";
import { DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm/DriversListFiltersFormValues";
import { type ApiDriversListResponse } from "../../models/api/admin/driver";
import ApiError from "../../api/common/apiError";
import { useDriver } from "../../api/admin/driver";
import {
    CompanyDashboardFilters,
    FilterDriverLicenceAnalysisDto,
} from "../../models/app/admin/driverFilters";
import useDriversListPaginationLocalStorage from "../../hooks/useDriversListPaginationLocalStorage";
import { type GetAllDriversDto } from "../../models/app/admin/driver";
import { driverListFiltersDefaultValues } from "../DriverListFilters/DriversListFiltersForm/driversListFiltersDefaultValues";
import { convertKebabCaseToCamelCase } from "../../utils/convertKebabCaseToCamelCase";

export const useDriverChartAnalysisTable = (
    selectedCompany: ApiCompanyResponse | null,
    selectedDepartment: ApiDepartmentItem | null,
    locationPath: string
) => {
    const location = driverPageLocation(locationPath);
    const defaultFilterVals = driverListFiltersDefaultValues(location);
    const { appliedFilter, setAppliedFilter } =
        useAppliedFilterLocalStorage(location);
    const paginationLocalStorage =
        useDriversListPaginationLocalStorage(location);

    // the list of drivers from the api and its error
    const [driversList, setDriversList] = useState<
        ApiDriversListResponse | undefined
    >();
    const [fetchDriversError, setFetchDriversError] = useState<
        ApiError | undefined
    >();

    const { getAllDrivers, isGettingAllDrivers } = useDriver();

    const createEmploymentFilters = (
        employeeId?: string | null,
        companyDashboard?: CompanyDashboardFilters,
        companyIdFromFilter?: string,
        departmentIdFromFilter?: string
    ) => {
        // If landing on page with a filter applied, use that filter until dept/company is changed
        const companyId = selectedCompany?.companyId ?? companyIdFromFilter;
        const departmentId =
            selectedDepartment?.departmentId ?? departmentIdFromFilter;

        const companyFilters: Pick<DriversListFiltersFormValues, "company"> = {
            company: {
                companyIds: companyId ? [companyId] : null,
                departmentIds: departmentId ? [departmentId] : null,
                employeeId: employeeId ?? null,
                companyDashboard:
                    companyDashboard ??
                    defaultFilterVals.company.companyDashboard,
            },
        };
        return companyFilters;
    };

    /* in order to only show drivers relevant to the table data 
    we need to send an empty object in the initial request.*/
    const chartTypeFilter = () => {
        const chartType = location
            ?.replace("drivers/", "")
            .replace("professionalDrivers/", "");
        const chartTypeCamel = convertKebabCaseToCamelCase(chartType ?? "");

        return chartTypeCamel;
    };

    const handleFetchDrivers = async (
        filters?: DriversListFiltersFormValues,
        filterId?: string
    ) => {
        const companyIdFromFilter = filters?.company?.companyIds?.[0];
        const departmentIdFromFilter = filters?.company?.departmentIds?.[0];
        const employeeIdFromFilter = filters?.company?.employeeId;
        const companyDashboardFromFilter = filters?.company?.companyDashboard;
        const chartType =
            chartTypeFilter() as keyof FilterDriverLicenceAnalysisDto;
        const addTableFilter: FilterDriverLicenceAnalysisDto | undefined =
            chartType && { [chartType]: {} };

        const getAllDriversBody: GetAllDriversDto = {
            pageNumber: paginationLocalStorage.pageNumber,
            pageSize: paginationLocalStorage.pageSize,
            orderBy:
                paginationLocalStorage.orderBy +
                " " +
                paginationLocalStorage.sortOrder,
            driverLicenceAnalysis: addTableFilter,
            ...(filters && { ...filters }),
            // 18/07/23 NB: Client/Dept are filters via the Dropdowns at top of page, hence:
            ...createEmploymentFilters(
                employeeIdFromFilter,
                companyDashboardFromFilter,
                companyIdFromFilter,
                departmentIdFromFilter
            ),
        };

        const response = await getAllDrivers(getAllDriversBody);

        if (response.success) {
            setFetchDriversError(undefined);
            setDriversList(response.content);
            if (filters) {
                setAppliedFilter({
                    filterId: filterId ?? "",
                    filterFields: filters,
                });
            }
        } else if (!response.success) {
            setFetchDriversError(response.error);
        }

        return response;
    };

    // if page number or page size etc changes, fetch drivers.
    useEffect(() => {
        handleFetchDrivers(
            appliedFilter.filterFields,
            appliedFilter.filterId ?? ""
        );
    }, [
        JSON.stringify(paginationLocalStorage),
        JSON.stringify(selectedCompany),
        JSON.stringify(selectedDepartment),
    ]);

    // if the selected company/dept changes, remove any company/dept filters from the applied filter:
    // 11/08/23 NB: if all filters depended on applied filter as a single source of truth, this
    // ...useEffect would not be necessary
    useEffect(() => {
        const companyIdFromFilter =
            appliedFilter.filterFields?.company?.companyIds?.[0];
        const departmentIdFromFilter =
            appliedFilter.filterFields?.company?.departmentIds?.[0];

        const userHasSelectedCompanyOrDept =
            selectedCompany?.companyId || selectedDepartment?.departmentId;
        const companyOrDeptFilterIsApplied =
            companyIdFromFilter || departmentIdFromFilter;

        if (userHasSelectedCompanyOrDept && companyOrDeptFilterIsApplied) {
            // remove company filter from applied filter
            const currentlyAppliedFilters = appliedFilter.filterFields;
            const currentFilterId = appliedFilter.filterId;

            const appliedFiltersWithoutCompanyFilters = {
                filterId: currentFilterId,
                filterFields: {
                    ...currentlyAppliedFilters,
                    company: defaultFilterVals.company,
                },
            };
            setAppliedFilter(appliedFiltersWithoutCompanyFilters);
            handleFetchDrivers(
                appliedFiltersWithoutCompanyFilters.filterFields,
                appliedFiltersWithoutCompanyFilters.filterId ?? ""
            );
        } else if (
            !companyOrDeptFilterIsApplied &&
            userHasSelectedCompanyOrDept
        ) {
            handleFetchDrivers(
                appliedFilter.filterFields,
                appliedFilter.filterId ?? ""
            );
        }
    }, [selectedCompany?.companyId, selectedDepartment?.departmentId]);

    return {
        driversList,
        fetchDriversError,
        setAppliedFilter,
        paginationLocalStorage,
        handleFetchDrivers,
        isFetchingDrivers: isGettingAllDrivers,
    };
};
