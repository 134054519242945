import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
    AddDepartmentFormValidationSchema,
    AddDepartmentFormValues,
} from "./AddDepartmentFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDepartment } from "../../../../../api/admin/department";

const useAddDepartmentForm = (companyId: string | undefined) => {

    const {addDepartment, isAddingDepartment} = useDepartment(companyId || "");

    const validationSchema = yup
        .object()
        .shape(AddDepartmentFormValidationSchema);

    const methods = useForm<AddDepartmentFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    return { methods, addDepartment, isAddingDepartment };
};

export default useAddDepartmentForm;

