import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { FieldError, useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../Button";
import FormTextAreaInput from "../FormTextAreaInput";
import {
    CommentFormValidationSchemaFields,
    CommentFormValues,
} from "./CommentFormValues";

export type CommentFormProps = {
    initialValues?: CommentFormValues;
    onSubmit: (
        dto: CommentFormValues,
        callback: (success: boolean) => void
    ) => void;
    onCancel?: () => void;
    error?: FieldError;
    isLoading: boolean;
};

const validationSchema = yup.object().shape(CommentFormValidationSchemaFields);

const CommentForm: React.FC<CommentFormProps> = ({
    initialValues,
    onSubmit,
    onCancel,
    error,
    isLoading,
}) => {
    const { handleSubmit, formState, register, reset } =
        useForm<CommentFormValues>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues: initialValues ?? {
                comment: "",
            },
        });

    const isEditing = Boolean(initialValues);

    const handleSubmitAndCallback = (data: CommentFormValues) => {
        onSubmit(data, () => reset({ comment: "" }));
    };

    return (
        <div className="pt-4">
            <form onSubmit={handleSubmit(handleSubmitAndCallback)}>
                <FormTextAreaInput
                    label={isEditing ? undefined : "Comments"}
                    id="comment"
                    placeholder="Enter comment"
                    {...register("comment")}
                    error={formState.errors.comment || error}
                    rows={2}
                />
                <div
                    className={clsx(
                        "mt-4 flex gap-4",
                        isEditing ? "justify-end" : "justify-between"
                    )}
                >
                    {onCancel ? (
                        <Button
                            type="button"
                            variant="tertiary"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    ) : (
                        <></>
                    )}
                    <Button type="submit" variant="primary" loading={isLoading}>
                        Save
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default CommentForm;
