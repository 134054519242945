import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../FormTextInput";
import Button from "../Button";
import {
    EditResellerFormValues,
    EditResellerValidationSchemaFields,
} from "./EditResellerFormValues";
import { useState } from "react";

export type EditResellerFormProps = {
    defaultValues: EditResellerFormValues;
    isLoading: boolean;
    onSubmit: (data: EditResellerFormValues) => Promise<void>;
};

const validationSchema = yup.object().shape(EditResellerValidationSchemaFields);

const EditResellerForm: React.FC<EditResellerFormProps> = ({
    defaultValues,
    isLoading,
    onSubmit,
}) => {
    const [logo, setLogo] = useState(defaultValues.logoUrl);
    const { handleSubmit, formState, register, getValues } =
        useForm<EditResellerFormValues>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues,
        });

    const handleLogo = () => {
        setLogo(getValues("logoUrl"));
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="max-w-xl m-auto bg-white p-4">
                    <FormTextInput
                        label="Reseller Name"
                        required
                        id="resellerName"
                        placeholder="Enter first name"
                        error={formState.errors.resellerName}
                        {...register("resellerName")}
                    />
                    <FormTextInput
                        label="Logo Url"
                        required
                        id="logoUrl"
                        placeholder="Enter surname"
                        error={formState.errors.logoUrl}
                        {...register("logoUrl")}
                        onBlur={handleLogo}
                    />
                    {logo && (
                        <div className="p-4 m-4 max-w-fit">
                            <img src={logo} alt={logo} />
                        </div>
                    )}
                </div>
                <div className="flex justify-end gap-8 mt-8">
                    <Button type="submit" variant="primary" loading={isLoading}>
                        Update reseller
                    </Button>
                </div>
            </form>
        </>
    );
};

export default EditResellerForm;
