import { X } from "phosphor-react";
import { Dialog } from "@headlessui/react";
import { useRef } from "react";
import clsx from "clsx";

type DialogBoxProps = {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    subtitle?: string;
    description?: string;
    children?: React.ReactNode;
    size?: "xl" | "large" | "medium" | "small";
    closeButton?: boolean;
};

const DialogBox: React.FC<DialogBoxProps> = ({
    title,
    subtitle,
    description,
    isOpen,
    onClose,
    children,
    size = "large",
    closeButton = false,
}: DialogBoxProps) => {
    const closeButtonRef = useRef(null);
    return (
        <Dialog
            as="div"
            className="relative z-50"
            open={isOpen}
            onClose={onClose}
            initialFocus={closeButtonRef}
        >
            <div
                className="fixed inset-0 bg-S2D-neutral-50/75"
                aria-hidden="true"
            />
            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center text-center">
                    <Dialog.Panel
                        className={clsx(
                            "w-full transform bg-white rounded-sm text-left align-middle shadow-xl transition-all rounded-b-sm",
                            size === "xl" && "sm:max-w-lg md:max-w-2xl lg:max-w-3xl max-w-full",
                            size === "large" && "max-w-lg",
                            size === "medium" && "max-w-sm",
                            size === "small" && "max-w-xs"
                        )}
                    >
                        {closeButton && (
                            <X
                                className="cursor-pointer w-6 h-6 float-right m-4"
                                onClick={onClose}
                                ref={closeButtonRef}
                            />
                        )}
                        {title && (
                            <Dialog.Title className="px-6 pt-6 mb-3 font-semibold text-xl break-words">
                                {title}
                            </Dialog.Title>
                        )}
                        {subtitle && (
                            <Dialog.Title className="px-6 mb-3 font-semibold text-large break-words overflow-wrap">
                                {subtitle}
                            </Dialog.Title>
                        )}
                        {description && (
                            <Dialog.Description className="px-6 mb-8 text-sm">
                                {description}
                            </Dialog.Description>
                        )}
                        {children}
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogBox;
