import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import FormDateInput from "../FormDateInput";
import clsx from "clsx";
import { FileSafeFilterFormValues } from "./useFileSafeFilterForm";

type Props = {
    methods: UseFormReturn<FileSafeFilterFormValues>;
};
export default function TransferDateFields({ methods }: Props) {
    const { control } = methods;
    const values = useWatch({ control });

    const maxDate = values.to;
    const minDate = values.from;

    return (
        <div className="flex gap-6">
            <Controller
                name="from"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                    <FormDateInput
                        label="From"
                        id="date-from"
                        maxDate={maxDate ?? undefined}
                        value={value}
                        onChange={onChange}
                        hideHelpText
                        error={methods.formState.errors.from}
                        // Why is this prop necessary to keep the calandar logo aligned?
                        adornmentClassName={clsx(
                            !methods.formState.errors.from && "-mt-[0.2rem]"
                        )}
                    />
                )}
            />
            <Controller
                name="to"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                    <FormDateInput
                        label="To"
                        id="date-to"
                        minDate={minDate ?? undefined}
                        value={value}
                        onChange={onChange}
                        error={methods.formState.errors.to}
                        hideHelpText={true}
                        adornmentClassName={clsx(
                            !methods.formState.errors.to && "-mt-[0.2rem]"
                        )}
                    />
                )}
            />
        </div>
    );
}
