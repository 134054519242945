import {
    type ApiCompaniesResponse,
    type ApiCompanyResponse,
} from "../../models/api/admin/company";
import Table, {
    type HiddenCols,
    type TableHeader,
} from "../ReusableComponents/Table";
import {
    initCompanyTableContents,
    initCompanyTableHeaders,
} from "./CompanyListInit";
import {
    CompanyListMenuKeys,
    companyMenuGroups,
    companyMenuItems,
    companyOrderByOptions,
} from "./CompanyListOptions";
import useLocalStorage from "../../hooks/useLocalStorage";
import PaginationNav from "../PaginationNav";
import { pluralTermForCompany } from "../../copy/sharedCopy";

type CompanyListProps = {
    companyList?: ApiCompaniesResponse;
    filteredCompanyList: ApiCompanyResponse[];
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    setOrderBy: (value: string | ((val: string) => string)) => void;
    filterIsApplied: boolean;
    handleTableMenuSelect: (id: string, key: CompanyListMenuKeys) => void;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};

const CompanyList: React.FC<CompanyListProps> = ({
    companyList,
    filteredCompanyList,
    setPageNumber,
    pageSize,
    setPageSize,
    setOrderBy,
    filterIsApplied,
    handleTableMenuSelect,
    tableRef,
}) => {
    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "CompanyTableHeaders",
        initCompanyTableHeaders
    );

    const initHiddenCols = new Array(0);
    tableHeaders.map((item) =>
        initHiddenCols.push({
            id: item.id,
            hidden: item.hide ?? false,
        })
    );

    const [hiddenCols, setHiddenCols] = useLocalStorage<HiddenCols[]>(
        "companyTableHiddenCols",
        initHiddenCols
    );

    return (
        <>
            {filteredCompanyList.length && companyList ? (
                <>
                    <Table
                        tableHeaders={tableHeaders}
                        setTableHeaders={setTableHeaders}
                        tableContents={initCompanyTableContents(
                            filteredCompanyList
                        )}
                        hiddenCols={hiddenCols}
                        setHiddenCols={setHiddenCols}
                        menuItems={(data) =>
                            companyMenuItems(data, handleTableMenuSelect)
                        }
                        menuGroups={companyMenuGroups}
                        orderByOptions={companyOrderByOptions}
                        tableRef={tableRef}
                    />
                    <PaginationNav
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalCount={companyList.totalCount}
                        totalPages={companyList.totalPages}
                        currentPage={companyList.pageNumber}
                        hasPreviousPage={companyList.hasPreviousPage}
                        hasNextPage={companyList.hasNextPage}
                    />
                </>
            ) : (
                <>
                    {filterIsApplied ? (
                        <p>{`No ${pluralTermForCompany} match query`}</p>
                    ) : (
                        <p>{`You currently have no ${pluralTermForCompany}`}</p>
                    )}
                </>
            )}
        </>
    );
};

export default CompanyList;
