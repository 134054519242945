import { PencilSimple, Bell, CheckCircle, XCircle } from "phosphor-react";
import { type TableMenuItem } from "../ReusableComponents/Table/TableMenu";
import { type GreyFleetTableData } from "../../models/api/admin/greyFleet";

export const greyFleetMenuItems = (
    data: GreyFleetTableData,
    handleTableMenuSelect: (
        data: GreyFleetTableData,
        key: GreyFleetMenuKeys
    ) => void,
    isDisabled: boolean,
    isS2dUser: boolean
): TableMenuItem[] => {
    const hasVehicles = !!data.vehicleRegistration?.length;
    const menuItems = [
        {
            key: GreyFleetMenuKeys.editDriver,
            onClick: () =>
                handleTableMenuSelect(data, GreyFleetMenuKeys.editDriver),
            icon: <PencilSimple size={20} weight="fill" />,
            text: "Edit Driver",
            isDisabled: isDisabled,
        },
    ];
    // If driver is not deleted add the rest of the menu items
    if (!data.deletedAt) {
        menuItems.push(
            {
                key: GreyFleetMenuKeys.emailReminder,
                onClick: () =>
                    handleTableMenuSelect(
                        data,
                        GreyFleetMenuKeys.emailReminder
                    ),
                icon: <Bell size={20} weight="fill" />,
                text: "Send instant email reminder",
                isDisabled: isDisabled,
            },
            {
                key: GreyFleetMenuKeys.markVerified,
                onClick: () =>
                    handleTableMenuSelect(data, GreyFleetMenuKeys.markVerified),
                icon: <CheckCircle size={20} weight="fill" />,
                text: "Mark insurance as verified",
                isDisabled: isDisabled || !hasVehicles || !isS2dUser,
            },
            {
                key: GreyFleetMenuKeys.removeVehicle,
                onClick: () =>
                    handleTableMenuSelect(
                        data,
                        GreyFleetMenuKeys.removeVehicle
                    ),
                icon: <XCircle size={20} weight="fill" />,
                text: "Remove vehicle from Grey Fleet",
                isDisabled: isDisabled || !hasVehicles,
            }
        );
    }

    return menuItems;
};

export enum GreyFleetMenuKeys {
    editDriver = "editDriver",
    emailReminder = "emailReminder",
    markVerified = "markVerified",
    removeVehicle = "removeVehicle",
}
