import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { useGetDepartments } from "../../api/admin/department";
import { useFormFieldCollectiveValidation } from "../../hooks/useFormFieldCollectiveValidation";
import AccordionItem from "../AccordionItem";
import FormDropdown from "../FormDropdown";
import FormTextInput from "../FormTextInput";
import { AddDriverFormValues } from "./AddDriverFormValues";
import {
    capitalisedTermForCompany,
    termForCompany,
} from "../../copy/sharedCopy";
import LoadingMessage from "../ReusableComponents/LoadingMessage/LoadingMessage";
import { errorToast } from "../../toast";
import { ApiCompaniesResponse } from "../../models/api/admin/company";
import { DriverValidationTypes } from "../../models/app/admin/company";
import { useEffect } from "react";

type DriverEmploymentFieldsProps = {
    methods: UseFormReturn<AddDriverFormValues>;
    companyList?: ApiCompaniesResponse;
    isCompanyListLoading: boolean;
    companyId?: string | null;
    isClientUser: boolean;
    setDriverIdentityValidationType: (value: DriverValidationTypes) => void;
    isReset: boolean;
};

const DriverEmploymentFields: React.FC<DriverEmploymentFieldsProps> = ({
    methods,
    companyList,
    companyId,
    isCompanyListLoading,
    isClientUser,
    setDriverIdentityValidationType,
    isReset,
}) => {
    const handleSelectCompany = (value: string | null) => {
        updateDriverIdentityValidationType(value);
        const driver = methods.getValues();
        // reset department id when the company id changes
        if (driver.departmentId) {
            methods.setValue("departmentId", null);
            errorToast(
                "Department has been reset since the selected client has been edited."
            );
        }
        // reset nominated driver if companyId changes
        if (driver.primaryDriverId) {
            methods.setValue("primaryDriverId", null);
            methods.setValue("primaryDriverName", null);
            errorToast(
                "Nominated driver has been reset since the selected client has been edited."
            );
        }

        methods.setValue("companyId", value);
    };

    const updateDriverIdentityValidationType = (companyId: string | null) => {
        const selectedCompany = companyList?.items.find(
            (company) => company.companyId === companyId
        );

        const companyDriverValidationType =
            selectedCompany?.settings?.edeclaration?.driverValidationType;

        if (companyDriverValidationType) {
            setDriverIdentityValidationType(companyDriverValidationType);
        }
    };

    useEffect(() => {
        if (isReset && companyId) {
            updateDriverIdentityValidationType(companyId);
        }
    }, [isReset]);

    const companyOptions = companyList?.items.map((company) => ({
        label: company.companyName,
        value: company.companyId,
    }));

    const { departmentsData, isLoadingDepartments } = useGetDepartments(
        companyId ?? undefined,
        1,
        100
    );
    const departmentOptions = departmentsData?.items.map((department) => ({
        label: department.departmentName,
        value: department.departmentId,
    }));

    const isSectionValid =
        useFormFieldCollectiveValidation<AddDriverFormValues>(
            ["departmentId", "jobRole", "externalReference", "companyId"],
            methods
        );

    return (
        <AccordionItem
            title="Add employment information"
            error={!isSectionValid}
        >
            <>
                <div className="w-full">
                    {/* Only S2d users can manually associate a driver with a company */}
                    {isCompanyListLoading && <LoadingMessage />}
                    {!isCompanyListLoading && (
                        <Controller
                            name="companyId"
                            control={methods.control}
                            render={({ field: { value } }) => (
                                <FormDropdown
                                    label={capitalisedTermForCompany}
                                    options={companyOptions ?? []}
                                    value={value}
                                    onChange={handleSelectCompany}
                                    pleaseSelectText={`Select ${termForCompany}`}
                                    error={methods.formState.errors.companyId}
                                />
                            )}
                        />
                    )}

                    <Controller
                        name="departmentId"
                        control={methods.control}
                        render={({ field: { value, onChange } }) => (
                            <FormDropdown
                                label="Department"
                                helpText={
                                    isClientUser || !!companyId
                                        ? undefined
                                        : "Cannot select a department without first selecting a client."
                                }
                                options={departmentOptions ?? []}
                                value={value ?? ""}
                                onChange={onChange}
                                pleaseSelectText="Select department"
                                noOptionsText="Selected company has no departments."
                                error={methods.formState.errors.departmentId}
                                isLoading={isLoadingDepartments}
                                disabled={!companyId}
                            />
                        )}
                    />
                    <FormTextInput
                        label="Job role"
                        id="jobRole"
                        placeholder="Enter job role"
                        error={methods.formState.errors.jobRole}
                        {...methods.register("jobRole")}
                    />
                    <FormTextInput
                        label="External reference"
                        id="externalReference"
                        placeholder="Enter external reference"
                        error={methods.formState.errors.externalReference}
                        {...methods.register("externalReference")}
                    />
                </div>
            </>
        </AccordionItem>
    );
};

export default DriverEmploymentFields;
