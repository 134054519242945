import { type ApiInvoiceReferenceNumberItem } from "../../../../models/api/admin/invoiceReferenceNumber";
import { addSpacesToTitleCase } from "../../../../utils/addSpacesToTitleCase";
import { formatDateToString } from "../../../../utils/formatDateToString";
import {
    type TableContent,
    type TableHeader,
} from "../../../ReusableComponents/Table/tableTypes";

type AllowedIds = "value" | "departmentName" | "type";

export type InvoiceReferenceNumberTableHeader = TableHeader<AllowedIds>;
export type InvoiceReferenceNumberTableContent = TableContent<
    ApiInvoiceReferenceNumberItem,
    AllowedIds
>;

export const initialInvoiceReferenceNumbersTableHeaders: InvoiceReferenceNumberTableHeader[] = [
    {
        title: "Number",
        id: "value",
    },
    {
        title: "Department",
        id: "departmentName",
    },
    {
        title: "Type",
        id: "type",
    },
];

export function initInvoiceReferenceNumbersTableContents(
    invoiceReferenceNumbers?: ApiInvoiceReferenceNumberItem[]
): InvoiceReferenceNumberTableContent[] {
    const tableContents: InvoiceReferenceNumberTableContent[] = [];
    invoiceReferenceNumbers?.map((invoiceReferenceNumber) => {
        tableContents.push({
            rowId: invoiceReferenceNumber.value,
            rowData: invoiceReferenceNumber,
            cellData: [
                {
                    id: "value",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {invoiceReferenceNumber.value}
                        </p>
                    ),
                },
                {
                    id: "departmentName",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {invoiceReferenceNumber.departmentName}
                        </p>
                    ),
                },
                {
                    id: "type",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {addSpacesToTitleCase(invoiceReferenceNumber.type)}
                        </p>
                    ),
                },
            ],
        });
    });
    return tableContents;
}
