import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../Button";
import EditableContentBlock from "../../EditableContentBlock";
import FormDateInput from "../../FormDateInput";
import {
    EditDvlaFormValues,
    EditDvlaTachocardFormValidationSchema,
    EditDvlaTachocardFormValues,
} from "../EditDvlaFormValues";
import TachocardUnit from "../TachocardUnit";
import FormTextInput from "../../FormTextInput";

export type EditTachocardFormProps = {
    initialValues: EditDvlaFormValues;
    isLoadingAdd: boolean;
    isLoadingEdit: boolean;
    onSubmit: (
        data: EditDvlaTachocardFormValues,
        callback: () => void,
        tachocardId?: string
    ) => Promise<void>;
    onDelete: (tachocardId: string) => Promise<void>;
    isDeleting: boolean;
    canEdit: boolean;
};

const tachocardValidationSchema = yup.object().shape({
    ...EditDvlaTachocardFormValidationSchema,
});

const EditTachocardForm: React.FC<EditTachocardFormProps> = ({
    initialValues,
    isLoadingAdd,
    isLoadingEdit,
    onSubmit,
    onDelete,
    isDeleting,
    canEdit,
}) => {
    const [isAddingTachocard, setIsAddingTachocard] = useState(false);
    const methods = useForm<EditDvlaTachocardFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(tachocardValidationSchema),
        defaultValues: {
            cardStatus: "",
            number: "",
            validFrom: null,
            validTo: null,
        },
    });

    return (
        <EditableContentBlock title="Tacho Cards" disableEdit={!canEdit}>
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="flex flex-col gap-2 p-6">
                            {initialValues.tachocards.length ? (
                                <>
                                    {initialValues.tachocards.map(
                                        (tachocard) => (
                                            <div
                                                key={`${tachocard.tachocardId} not editable`}
                                            >
                                                <TachocardUnit
                                                    tachocard={tachocard}
                                                />
                                            </div>
                                        )
                                    )}
                                </>
                            ) : (
                                <p>
                                    This driver does not have any tacho cards.
                                </p>
                            )}
                        </div>
                    )}
                    {isEdit && (
                        <div className="flex flex-col gap-2 p-6">
                            {initialValues.tachocards.map((tachocard) => (
                                <div key={`${tachocard.tachocardId} editable`}>
                                    <TachocardUnit
                                        tachocard={tachocard}
                                        isLoading={isLoadingEdit}
                                        onSubmit={onSubmit}
                                        onDelete={onDelete}
                                        isDeleting={isDeleting}
                                    />
                                </div>
                            ))}
                            {!isAddingTachocard && (
                                <div className="pt-4">
                                    <Button
                                        className="border border-black bg-S2D-neutral-100 hover:border-black hover:bg-S2D-neutral-10 hover:text-white"
                                        onClick={() =>
                                            setIsAddingTachocard(true)
                                        }
                                    >
                                        Add tacho card
                                    </Button>
                                </div>
                            )}
                            {isAddingTachocard && (
                                <form
                                    onSubmit={methods.handleSubmit(
                                        (data: EditDvlaTachocardFormValues) => {
                                            onSubmit(
                                                data,
                                                () => setIsEdit(false),
                                                undefined
                                            );
                                            methods.reset();
                                            setIsAddingTachocard(false);
                                        }
                                    )}
                                >
                                    <p className="pt-6 text-xl font-semibold">
                                        Add tacho card
                                    </p>
                                    <FormTextInput
                                        label="Card number"
                                        id="number"
                                        placeholder="Enter card number"
                                        error={methods.formState.errors.number}
                                        {...methods.register("number")}
                                        required
                                    />
                                    <FormTextInput
                                        label="Card status"
                                        id="card-status"
                                        placeholder="Enter card status"
                                        error={
                                            methods.formState.errors.cardStatus
                                        }
                                        {...methods.register("cardStatus")}
                                        required
                                    />
                                    <Controller
                                        name="validFrom"
                                        control={methods.control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormDateInput
                                                id="validFrom"
                                                label="Valid from"
                                                value={value}
                                                onChange={onChange}
                                                error={
                                                    methods.formState.errors
                                                        .validFrom
                                                }
                                                required
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="validTo"
                                        control={methods.control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormDateInput
                                                id="validTo"
                                                label="Valid to"
                                                value={value}
                                                onChange={onChange}
                                                error={
                                                    methods.formState.errors
                                                        .validTo
                                                }
                                                required
                                            />
                                        )}
                                    />
                                    <div className="mt-4 flex justify-between border-t-2 border-S2D-neutral-80 pt-4">
                                        <Button
                                            variant="tertiary"
                                            type="button"
                                            onClick={() => {
                                                methods.reset();
                                                setIsAddingTachocard(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            loading={isLoadingAdd}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditTachocardForm;
