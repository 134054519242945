import { debounce } from "@mui/material";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import { errorToast, successToast } from "../toast";
import { MutableRefObject } from "react";

export const useDownloadComponentToPdf = (
    ref: MutableRefObject<HTMLDivElement | null>
) => {
    const generatePDF = async (
        reportCompleteCallback: () => void,
        fileName?: string
    ) => {
        try {
            // NB!: domtoimage *does not* work well with the 'flex-wrap' class
            const blob = await domtoimage.toBlob(ref.current as HTMLDivElement);
            const img = new Image();

            // Set the source of the image (triggering img.onload below)
            img.src = URL.createObjectURL(blob);

            img.onload = function () {
                // Here 'this' is typed as EventTarget which doesn't have width and height
                // So we need to assert 'this' to be HTMLImageElement
                const imageElement = this as HTMLImageElement;
                const { width: imgWidth, height: imgHeight } = imageElement;

                const pdf = new jsPDF({
                    orientation:
                        imgWidth > imgHeight ? "landscape" : "portrait",
                    unit: "pt",
                    format: [imageElement.width, imageElement.height],
                });

                pdf.addImage(img.src, "PNG", 0, 0, imgWidth, imgHeight, undefined, "FAST");
                pdf.save(`${fileName || "report"}.pdf`);
            };

            successToast("PDF downloaded successfully.");
            reportCompleteCallback();
        } catch (error) {
            console.error("failed to convert page to PDF!", error);
            reportCompleteCallback();
            errorToast("Unable to convert page to PDF.");
        }
    };
    const debouncedGeneratePDF = debounce(generatePDF, 2000);

    return {
        generatePDF: debouncedGeneratePDF,
    };
};
