import { ArrowLeft } from "phosphor-react";
import { ReactNode } from "react";
import { useNavigate } from "react-router";

type PageHeaderProps = {
    title: string;
    actions?: ReactNode[];
};

const PageHeader: React.FC<PageHeaderProps> = ({ title, actions }) => {
    const navigate = useNavigate();

    const handleBackPage = () => {
        navigate(-1);
    };

    return (
        <div className="sticky top-[4.25rem] z-20 mb-8 flex w-full flex-col flex-wrap justify-between gap-4 border-b border-b-S2D-neutral-80 bg-white py-6 lg:flex-row lg:items-center">
            <div className="flex max-w-full items-center">
                <button className="mr-4 px-2" onClick={handleBackPage}>
                    <ArrowLeft className="h-6 w-6" />
                </button>
                <h1 className="w-full shrink-0 text-2xl sm:text-3xl">
                    {title}
                </h1>
            </div>
            {actions && (
                <div className="flex flex-col gap-4 md:flex-row">
                    {actions && actions.map((action) => action)}
                </div>
            )}
        </div>
    );
};

export default PageHeader;
