import { Envelope, NotePencil } from "phosphor-react";
import { type DropdownItem } from "../ReusableComponents/HeadlessGenericDropdown";
import { type TableCheckbox } from "../ReusableComponents/Table/tableTypes";
import { type DriverDeclaration } from "../../models/api/admin/eDeclarations";
import { useEdeclaration } from "../../api/edeclaration";
import { errorToast, successToast } from "../../toast";
import { DeclarationType } from "../../models/app/eDeclarations";
import { useUpdateDriverDeclarationType } from "../../api/admin/driver";

export type DriverDeclarationBulkActionType =
    | "eDeclaration"
    | "paperDeclaration"
    | undefined;

const sendEDecEmailOption: DropdownItem = {
    item: (
        <div className="flex flex-row gap-4 px-4">
            <Envelope size={20} weight="fill" />
            Send E-Declaration email
        </div>
    ),
    id: "send-email",
};

const sendPaperDecEmailOption: DropdownItem = {
    item: (
        <div className="flex flex-row gap-4 px-4">
            <Envelope size={20} weight="fill" />
            Send Paper Declaration email
        </div>
    ),
    id: "send-email",
};

const switchToPaperOption: DropdownItem = {
    item: (
        <div className="flex flex-row gap-4 px-4">
            <NotePencil size={20} weight="fill" />
            Switch to Paper Declaration
        </div>
    ),
    id: "switch-type",
};

const switchToEDecOption: DropdownItem = {
    item: (
        <div className="flex flex-row gap-4 px-4">
            <NotePencil size={20} weight="fill" />
            Switch to E-Declaration
        </div>
    ),
    id: "switch-type",
};

export const driverDeclarationsBulkActions = (
    optionType: DriverDeclarationBulkActionType
) => {
    switch (optionType) {
        case "eDeclaration":
            return [sendEDecEmailOption, switchToPaperOption];
        case "paperDeclaration":
            return [sendPaperDecEmailOption, switchToEDecOption];
        default:
            return undefined;
    }
};

export const useDriverDeclarationBulkActions = (
    selectedCheckboxes: TableCheckbox<DriverDeclaration>[],
    setSelectedCheckboxes: (value: TableCheckbox<DriverDeclaration>[]) => void,
    setIsLoading: (value: boolean) => void,
    refetchEDeclarationsList: () => void
) => {
    const { sendInstantDeclarationInviteEmail } = useEdeclaration();

    const handleSendDeclarationEmail = async () => {
        setIsLoading(true);
        // for each selected row, send email request.
        // if any error, error toast and terminate action, followed by noting any successes that have occurred.
        // otherwise one success toast noting action success.
        const successesArray = new Array(0);
        let error = false;
        for (const declaration of selectedCheckboxes) {
            const response = await sendInstantDeclarationInviteEmail(
                declaration.id
            );
            if (response.success) {
                successesArray.push(declaration.data.driverId);
            } else {
                error = true;
                errorToast(
                    `Failed to send declaration request to driver ${declaration.data.driverId}. Action has been terminated.`
                );
                if (successesArray.length)
                    successToast(
                        `Action succeeded for drivers ${successesArray.join(
                            ", "
                        )}.`
                    );
                setSelectedCheckboxes([]);
                break;
            }
        }
        if (!error)
            successToast("Action succeeded, declaration requests sent.");
        setSelectedCheckboxes([]);
        setIsLoading(false);
    };

    const [updateDeclarationType] = useUpdateDriverDeclarationType();

    const handleSwitchDeclarationType = async (
        declarationType: DeclarationType
    ) => {
        // same method as above
        setIsLoading(true);
        const successesArray = new Array(0);
        let error = false;
        for (const declaration of selectedCheckboxes) {
            const dto = { declarationType: declarationType };
            const response = await updateDeclarationType({
                declarationId: declaration.id,
                dto,
            });
            if (response.success) {
                successesArray.push(declaration.data.driverId);
            } else {
                error = true;
                errorToast(
                    `Failed to switch declaration type for driver ${declaration.data.driverId}. Action has been terminated.`
                );
                if (successesArray.length)
                    successToast(
                        `Action succeeded for drivers ${successesArray.join(
                            ", "
                        )}.`
                    );
                setSelectedCheckboxes([]);
                break;
            }
        }
        if (!error)
            successToast("Action succeeded, declaration requests sent.");
        setSelectedCheckboxes([]);
        refetchEDeclarationsList();
        setIsLoading(false);
    };

    return {
        handleSendDeclarationEmail,
        handleSwitchDeclarationType,
    };
};
