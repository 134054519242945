import {
    useParams,
    Outlet,
    useRoutes,
    useOutletContext,
} from "react-router-dom";
import { useCompany } from "../../../api/admin/company";
import { EditCompanyFormValues } from "../../../components/EditCompanyForms/EditCompanyFormValues";
import FormMessageBox from "../../../components/FormMessageBox";
import PageLayout from "../../../components/PageLayout";
import { errorToast, successToast } from "../../../toast";

import LoadingMessage from "../../../components/ReusableComponents/LoadingMessage/LoadingMessage";
import CompanyProfileHeader from "../../../components/CompanyProfile/CompanyProfileHeader";
import {
    capitalisedTermForCompany,
    termForCompany,
} from "../../../copy/sharedCopy";
import CompanyProfile from "./nestedRoutes/CompanyProfile";

type PageParams = {
    id: string;
};

const AdminEditCompany = () => {
    const { id } = useParams<PageParams>();
    const useCompanyReturn = useCompany(id);
    const {
        companyData,
        isLoadingCompany,
        companyError,
        editCompany,
        isEditingCompany,
        refetchCompany,
    } = useCompanyReturn;

    const handleEditCompany = async (
        data: EditCompanyFormValues,
        resetFormCallback: () => void
    ) => {
        if (!id) {
            errorToast("There was an error");
            return;
        }
        if (id === data.resellerId) {
            errorToast(`${capitalisedTermForCompany} cannot be its own parent`);
            return;
        }

        const response = await editCompany(id, data);

        if (response.success) {
            successToast(
                `${capitalisedTermForCompany} has been edited succesfully`
            );
            resetFormCallback();
            refetchCompany();
        } else {
            errorToast(`Error updating ${termForCompany}`);
            window.scrollTo(0, 0);
        }
    };

    const defaultRoute = useRoutes([
        {
            path: "/",
            element: (
                <CompanyProfile
                    companyData={companyData}
                    isLoadingCompany={isLoadingCompany}
                    refetchCompany={refetchCompany}
                    isEditingCompany={isEditingCompany}
                    handleEditCompany={handleEditCompany}
                    id={id}
                />
            ),
            index: true,
        },
    ]);

    return (
        <PageLayout title={`${capitalisedTermForCompany} Profile`}>
            <div className="mx-auto w-full">
                {isLoadingCompany && <LoadingMessage />}
                {companyError && (
                    <FormMessageBox
                        variant="error"
                        title={"Failed"}
                        message={`This ${termForCompany} does not exist`}
                    />
                )}
                <CompanyProfileHeader companyData={companyData} />

                <Outlet context={{ ...useCompanyReturn }} />
                {defaultRoute}
            </div>
        </PageLayout>
    );
};

export default AdminEditCompany;

type ContextType = ReturnType<typeof useCompany>;

// custom hook so components rendered by <Outlet/> can get typed context
export function useEditCompanyOutletContext() {
    return useOutletContext<ContextType>();
}
