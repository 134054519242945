import { useMemo } from "react";
import {
    type AllDriversAnalysisCharts,
    AnalysisChartType,
} from "../../models/app/admin/analysisChart";
import useDriverLicenceCharts from "../ChartGroups/useDriverLicenceCharts";
import BarChart, {
    type BarChartItem,
} from "../ReusableComponents/Charts/BarChart";
import DriverChartAnalysisTable from "./DriverChartAnalysisTable";
import StackedBarChart, {
    type StackedBarChartItem,
} from "../ReusableComponents/Charts/StackedBarChart";
import DriverChartAnalysisFilters from "./DriverChartAnalysisFilters";
import useDriverChartAnalysisFilters from "./useDriverChartAnalysisFilters";
import { type FilterOption } from "../ReusableComponents/FilterDropdown";
import { useDriverChartAnalysisTable } from "./useDriverChartAnalysisTable";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import { ApiDepartmentItem } from "../../models/api/admin/department";
import { useLocation } from "react-router-dom";

type DriverChartAnalysisProps = {
    chartType: AllDriversAnalysisCharts;
    selectedCompany: ApiCompanyResponse | null;
    selectedDepartment: ApiDepartmentItem | null;
};

const DriverChartAnalysis: React.FC<DriverChartAnalysisProps> = ({
    chartType,
    selectedCompany,
    selectedDepartment,
}) => {
    const location = useLocation();
    const {
        driversList,
        fetchDriversError,
        setAppliedFilter,
        paginationLocalStorage,
        handleFetchDrivers,
        isFetchingDrivers,
    } = useDriverChartAnalysisTable(
        selectedCompany,
        selectedDepartment,
        location.pathname
    );

    const driverLicenceChartData = useDriverLicenceCharts(
        selectedCompany?.companyId,
        selectedDepartment?.departmentId
    );

    const determineChartData = () => {
        switch (chartType) {
            case AnalysisChartType.DriversByPoints:
                return driverLicenceChartData?.driversByPoints;
            case AnalysisChartType.DriversByAgeRange:
                return driverLicenceChartData?.driversByAgeRange;
            case AnalysisChartType.EndorsementCodes:
                return driverLicenceChartData?.endorsementCodes;
            case AnalysisChartType.DriversByLicenceType:
                return driverLicenceChartData?.driversByLicenceType;
            case AnalysisChartType.DriversByLicenceCategory:
                return driverLicenceChartData?.driversByLicenceCategory;
            case AnalysisChartType.DriverRestrictions:
                return driverLicenceChartData?.driverRestrictions;
            case AnalysisChartType.DriversByLicenceOrigin:
                return driverLicenceChartData?.driversByLicenceOrigin;
            case AnalysisChartType.YearsFullLicenceHeld:
                return driverLicenceChartData?.yearsFullLicenceHeld;
            default:
                return undefined;
        }
    };

    const data = useMemo(determineChartData, [
        chartType,
        driverLicenceChartData,
    ]);

    const { handleApplyFilter, selectedDropdownFilter } =
        useDriverChartAnalysisFilters(
            chartType,
            setAppliedFilter,
            handleFetchDrivers
        );

    // Endorsement code and licence category filter options are decided by the data appearing in the chart
    const isCustomOption =
        chartType === AnalysisChartType.EndorsementCodes ||
        chartType === AnalysisChartType.DriversByLicenceCategory ||
        chartType === AnalysisChartType.DriverRestrictions;
    const customOptions: FilterOption<string>[] =
        isCustomOption && data
            ? data?.map((item) => ({
                  value: item.name,
                  label: item.name,
              }))
            : [];

    return (
        <div className="flex flex-col gap-4">
            {data &&
                (chartType === AnalysisChartType.DriversByLicenceCategory ? (
                    <StackedBarChart
                        data={data as StackedBarChartItem[]}
                        topDataKey="full"
                        bottomDataKey="provisional"
                    />
                ) : (
                    <BarChart data={data as BarChartItem[]} />
                ))}
            <DriverChartAnalysisFilters
                chartType={chartType}
                handleApplyFilter={handleApplyFilter}
                selectedDropdownFilter={selectedDropdownFilter}
                customOptions={customOptions}
            />
            <DriverChartAnalysisTable
                chartType={chartType}
                driversList={driversList}
                fetchDriversError={fetchDriversError}
                isFetchingDrivers={isFetchingDrivers}
                setPageNumber={paginationLocalStorage.setPageNumber}
                pageSize={paginationLocalStorage.pageSize}
                setPageSize={paginationLocalStorage.setPageSize}
                selectedCompany={selectedCompany ?? undefined}
            />
        </div>
    );
};

export default DriverChartAnalysis;
