import { handlePlurals } from "../../utils/handlePlurals";
import { type DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm";
import { useGreyFleetAnalysisActions } from "../GreyFleetAnalysis/useGreyFleetAnalysisActions";
import StatisticSummaryBox from "../StatisticSummaryBox";
import { Warning, WarningOctagon, CheckCircle } from "phosphor-react";

type GreyFleetInvitesProps = {
    completed?: number;
    pending?: number;
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function GreyFleetInvitesAnalysis({
    completed,
    pending,
    refetchData,
    tableRef,
}: GreyFleetInvitesProps) {
    // NB 31/07/23 avoiding 'flex-wrap' in the PDF report as it causes issues with domtoimage
    const style = refetchData
        ? "flex flex-wrap gap-3"
        : "grid grid-cols-1 gap-3 lg:grid-cols-2";
    const { applyGreyFleetQueryFilter, isLoading } =
        useGreyFleetAnalysisActions(refetchData, tableRef);
    return (
        <div className={style}>
            <StatisticSummaryBox
                icon={CheckCircle}
                label="Invites Completed"
                statistic={completed}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter("hasCompletedInvite", true)
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label="Invites Pending / incomplete"
                statistic={pending}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter("hasCompletedInvite", false)
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type MotsAnalysisProps = {
    expiring?: number;
    expired?: number;
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function MotsAnalysis({
    expiring,
    expired,
    refetchData,
    tableRef,
}: MotsAnalysisProps) {
    const { applyGreyFleetQueryFilter, isLoading } =
        useGreyFleetAnalysisActions(refetchData, tableRef);
    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <StatisticSummaryBox
                icon={Warning}
                label="mots expiring this month"
                statistic={expiring}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter("hasExpiringMot", true)
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="expired mots"
                statistic={expired}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter("hasMotExpired", true)
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type RoadTaxAnalysisProps = {
    expiring?: number;
    expired?: number;
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function RoadTaxAnalysis({
    expired,
    expiring,
    refetchData,
    tableRef,
}: RoadTaxAnalysisProps) {
    const { applyGreyFleetQueryFilter, isLoading } =
        useGreyFleetAnalysisActions(refetchData, tableRef);
    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <StatisticSummaryBox
                icon={Warning}
                label="Road tax expiring this month"
                statistic={expiring}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter("hasExpiringRoadTax", true)
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="Expired road tax"
                statistic={expired}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter("hasRoadTaxExpired", true)
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type InsuranceAnalysisProps = {
    expiring?: number;
    expired?: number;
    noBusinessCover?: number;
    notUploaded?: number;
    unverified?: number;
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function InsuranceAnalysis({
    expired,
    expiring,
    noBusinessCover,
    notUploaded,
    unverified,
    refetchData,
    tableRef,
}: InsuranceAnalysisProps) {
    const { applyGreyFleetQueryFilter, isLoading } =
        useGreyFleetAnalysisActions(refetchData, tableRef);
    // NB 31/07/23 avoiding 'flex-wrap' in the PDF report as it causes issues with domtoimage
    const style = refetchData
        ? "flex flex-wrap gap-3"
        : "grid grid-cols-1 gap-3 lg:grid-cols-2 xl:grid-cols-3";
    return (
        <div className={style}>
            <StatisticSummaryBox
                icon={Warning}
                label="insurance policies expiring this month"
                statistic={expiring}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter(
                        "hasExpiringInsurancePolicy",
                        true
                    )
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="expired insurance policies"
                statistic={expired}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter("hasInsurancePolicyExpired", true)
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="unverified insurance"
                statistic={unverified}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter("hasInsuranceUnverified", true)
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label={`insurance ${handlePlurals(
                    "certificate",
                    notUploaded
                )} to be uploaded`}
                statistic={notUploaded}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter(
                        "hasInsuranceCertificateToUpload",
                        true
                    )
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="policies without business cover"
                statistic={noBusinessCover}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter(
                        "hasPolicyWithoutBusinessCover",
                        true
                    )
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type BreakdownCoverAnalysisProps = {
    expiring?: number;
    expiredOrNoCover?: number;
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function BreakdownCoverAnalysis({
    expiredOrNoCover,
    expiring,
    refetchData,
    tableRef,
}: BreakdownCoverAnalysisProps) {
    const { applyGreyFleetQueryFilter, isLoading } =
        useGreyFleetAnalysisActions(refetchData, tableRef);
    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <StatisticSummaryBox
                icon={Warning}
                label="breakdown cover expiring this month"
                statistic={expiring}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter("hasBreakdownCoverExpiring", true)
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="expired / no breakdown cover"
                statistic={expiredOrNoCover}
                onLinkClick={() =>
                    applyGreyFleetQueryFilter(
                        "hasNoOrExpiredBreakdownCover",
                        true
                    )
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}
