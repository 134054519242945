import { useGetInvoiceReferenceNumbers } from "../../../api/admin/invoiceReferenceNumber";
import useLocalStorage from "../../../hooks/useLocalStorage";

const useInvoiceReferenceNumbersTab = (companyId: string | undefined) => {
    const [pageNumber, setPageNumber] = useLocalStorage<number>(
        `InvoiceReferenceNumberTablePageNumber`,
        1
    );
    const [pageSize, setPageSize] = useLocalStorage<number>(
        `InvoiceReferenceNumberTablePageSize`,
        25
    );

    const {
        invoiceReferenceNumbersData,
        isLoadingInvoiceReferenceNumbers,
        invoiceReferenceNumbersError,
        refetchInvoiceReferenceNumbers,
    } = useGetInvoiceReferenceNumbers(companyId, pageNumber, pageSize);

    return {
        invoiceReferenceNumbers: invoiceReferenceNumbersData,
        refetchInvoiceReferenceNumbers,
        isLoadingInvoiceReferenceNumbers,
        invoiceReferenceNumbersError,
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
    };
};

export default useInvoiceReferenceNumbersTab;
