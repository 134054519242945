import * as yup from "yup";
import { nullableNumber } from "./nullableNumber";

const rangeNameSchema = yup
    .string()
    .required("Please provide a name.")
    .min(3, "Name must be between 3 and 256 characters")
    .max(256, "Name must be between 3 and 256 characters")
    .test(
        "Valid name characters test",
        "Valid name characters are A-Z a-z 0-9 . -",
        (value) => !/(?=.[!@#$%^&()_=+{};:,<>]+)/gmu.test(value ?? "")
    );

export const RiskCategoriesSchema = yup.object().shape({
    range1Name: rangeNameSchema,
    range1: yup.number().required("Lower threshold for range 1 is required."),
    range2Name: rangeNameSchema,
    range2: yup
        .number()
        .moreThan(yup.ref("range1"), "range 2 must be higher than range 1")
        .required("Lower threshold for range 2 is required."),
    range3Name: rangeNameSchema,
    range3: yup
        .number()
        .moreThan(yup.ref("range2"), "range 3 must be higher than range 2")
        .required("Lower threshold for range 3 is required."),
});

export const CheckFrequencySchema = yup
    .object()
    .shape({
        range1: yup
            .number()
            .required("Check frequency for range 1 is required."),
        range2: yup
            .number()
            .required("Check frequency for range 2 is required."),
        range3: yup
            .number()
            .required("Check frequency for range 3 is required."),
    })
    .nullable();

export const CheckPricingSchema = yup
    .object()
    .shape({
        licenceCheckPrice: nullableNumber.required("Prices are required."),
        manualCheckPrice: nullableNumber.required("Prices are required."),
        irishLicenceCheckPrice: nullableNumber.required("Prices are required."),
        foreignLicenceCheckPrice: nullableNumber.required(
            "Prices are required."
        ),
        greyFleetCheckPrice: nullableNumber.required("Prices are required."),
    })
    .nullable();

export const CorrespondenceSchema = yup.object().shape({
    permitsEmailCorrespondence: yup.boolean(),
    permitsTelephoneCorrespondence: yup.boolean(),
    permitsSmsCorrespondence: yup.boolean(),
    permitsPostalCorrespondence: yup.boolean(),
});

export const EdeclarationSettingsSchema = yup.object().shape({
    useParentContact: yup.boolean(),
    driverValidationType: yup.string().oneOf(["DateOfBirth", "EmployeeId"]),
});

export const PrivacySettingsSchema = yup.object().shape({
    isDataHidden: yup.boolean(),
});

