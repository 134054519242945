import { useIsExtraLargeScreen } from "../../../hooks/screenSizes";
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import { type DriverCategory } from "../../../utils/driverPageLocation";
import OldDropdownAnalysisComponent from "./OldDropdownAnalysisComponent";
import OldTabbedAnalysisComponent from "./OldTabbedAnalysisComponent";
import { AnalysisComponentContentType } from "./AnalysisComponent";

export type AnalysisComponentProps = {
    analysisContent?: AnalysisComponentContentType<string>[];
    isLoadingAnalysis?: boolean;
    location?: DriverCategory;
};

export default function AnalysisComponent({
    analysisContent,
    isLoadingAnalysis,
    location,
}: AnalysisComponentProps) {
    const isExtraLargeScreen = useIsExtraLargeScreen();

    const showContent =
        analysisContent && location
            ? locationFilteredAnalysisContent(analysisContent, location)
            : analysisContent;

    return (
        <div className="flex flex-col gap-5">
            {!isLoadingAnalysis &&
                isExtraLargeScreen &&
                !!showContent?.length && (
                    <OldTabbedAnalysisComponent analysisContent={showContent} />
                )}
            {!isLoadingAnalysis &&
                !isExtraLargeScreen &&
                !!showContent?.length && (
                    <OldDropdownAnalysisComponent
                        analysisContent={showContent}
                    />
                )}
            {isLoadingAnalysis && <LoadingMessage />}
        </div>
    );
}

export const locationFilteredAnalysisContent = (
    analysisContent: AnalysisComponentContentType<string>[],
    location: DriverCategory
): AnalysisComponentContentType<string>[] => {
    const locationBasedAnalysisContent = new Array<
        AnalysisComponentContentType<string>
    >();

    analysisContent.map((content) => {
        if (location && content.locations?.includes(location)) {
            locationBasedAnalysisContent.push(content);
        }
    });
    return locationBasedAnalysisContent;
};
