import { SetStateAction, useState } from "react";
import FormTextInput from "../../../../FormTextInput";
import SubmitOrCancelButtonBar from "../../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import { type EditInvoiceAddressFormValues } from "./EditInvoiceAddressFormValues";
import useEditInvoiceAddressForm from "./useEditInvoiceAddressForm";
import { errorToast, successToast } from "../../../../../toast";
import { AddOrEditModalStates } from "../../CompanyBillingTab";
import { type ApiInvoiceAddressItem } from "../../../../../models/api/admin/invoiceAddress";
import ConfirmationDialog from "../../../../ReusableComponents/ConfirmationDialog";
import { ApiPostcodeLookupResponse } from "../../../../../models/api/postcodeLookup";
import PostcodeAddressLookup from "../../../../PostcodeAddressLookup";
import { mapDtoToApi } from "../../../../../models/app/admin/invoiceAddress";
import { Controller } from "react-hook-form";
import FormDropdown from "../../../../FormDropdown";
import { yesNoOptions } from "../../../../../constants/dropdownOptions";

type Props = {
    setModalState: (value: SetStateAction<AddOrEditModalStates>) => void;
    selectedInvoiceAddress: ApiInvoiceAddressItem | undefined;
    refetchInvoiceAddresses: () => void;
};

export default function EditInvoiceAddressForm({
    setModalState,
    selectedInvoiceAddress,
    refetchInvoiceAddresses,
}: Props) {
    const {
        methods,
        editInvoiceAddress,
        isEditingInvoiceAddress,
        deleteInvoiceAddress,
        isDeletingInvoiceAddress,
    } = useEditInvoiceAddressForm(selectedInvoiceAddress);
    const { handleSubmit, register, formState } = methods;

    const [deleteInvoiceAddressDialogOpen, setDeleteInvoiceAddressDialogOpen] =
        useState(false);

    const [selectedAddressValue, setSelectedAddressValue] = useState("");

    const handleEditInvoiceAddress = async (
        data: EditInvoiceAddressFormValues
    ) => {
        if (!selectedInvoiceAddress?.invoiceAddressId)
            return console.error("No invoiceAddressId");

        const apiData = mapDtoToApi({ ...data });
        const response = await editInvoiceAddress(
            selectedInvoiceAddress?.invoiceAddressId,
            apiData
        );

        if (response.success) {
            refetchInvoiceAddresses();
            successToast(`invoice address has been edited`);
            setModalState("closed");
        } else {
            errorToast(`Failed to edit invoice address`);
        }
    };

    const handleDeleteInvoiceAddress = async () => {
        if (!selectedInvoiceAddress?.invoiceAddressId) return;

        const response = await deleteInvoiceAddress(
            selectedInvoiceAddress.invoiceAddressId
        );
        if (!response.success) {
            errorToast(
                "Failed to delete invoice address, please try again later."
            );
        } else {
            successToast("Invoice address successfully deleted.");
            refetchInvoiceAddresses();
            setModalState("closed");
        }
    };

    const handleAddressSelect = (address: ApiPostcodeLookupResponse) => {
        setSelectedAddressValue(address.summaryline);
        methods.setValue("address", {
            addressLine1: address.addressline1,
            addressLine2: address.addressline2 ?? "",
            addressLine3: address.addressline3 ?? "",
            addressLine4: address.addressline4 ?? "",
            addressLine5: address.addressline5 ?? "",
            addressTown: address.posttown,
            addressPostcode: address.postcode,
        });
        methods.trigger("address");
    };

    return (
        <form onSubmit={handleSubmit(handleEditInvoiceAddress)}>
            <div className="mx-auto max-h-[500px] w-full max-w-3xl p-6 pb-8 overflow-auto">
                <FormTextInput
                    label={`Address Name`}
                    required
                    id="addressName"
                    placeholder={`Enter address name`}
                    {...register("addressName")}
                    error={formState.errors.addressName}
                />
                <PostcodeAddressLookup
                    value={selectedAddressValue}
                    onSelect={handleAddressSelect}
                />
                <FormTextInput
                    label={`Address Line 1`}
                    required
                    id="addressLine1"
                    placeholder={`Enter address line 1`}
                    {...register("address.addressLine1")}
                    error={formState.errors.address?.addressLine1}
                />
                <FormTextInput
                    label={`Address Line 2`}
                    id="addressLine2"
                    placeholder={`Enter address line 2`}
                    {...register("address.addressLine2")}
                    error={formState.errors.address?.addressLine2}
                />
                <FormTextInput
                    label={`Address Line 3`}
                    id="addressLine3"
                    placeholder={`Enter address line 3`}
                    {...register("address.addressLine3")}
                    error={formState.errors.address?.addressLine3}
                />
                <FormTextInput
                    label={`Address Line 4`}
                    id="addressLine4"
                    placeholder={`Enter address line 4`}
                    {...register("address.addressLine4")}
                    error={formState.errors.address?.addressLine4}
                />
                <FormTextInput
                    label={`Address Line 5`}
                    id="addressLine5"
                    placeholder={`Enter address line 5`}
                    {...register("address.addressLine5")}
                    error={formState.errors.address?.addressLine5}
                />
                <FormTextInput
                    label={`Address Postcode`}
                    required
                    id="addressPostcode"
                    placeholder={`Enter address postcode`}
                    {...register("address.addressPostcode")}
                    error={formState.errors.address?.addressPostcode}
                />
                <FormTextInput
                    label={`Address Town`}
                    required
                    id="addressTown"
                    placeholder={`Enter address town`}
                    {...register("address.addressTown")}
                    error={formState.errors.address?.addressTown}
                />
                <Controller
                    name="isPrimaryInvoiceAddress"
                    control={methods.control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                        <FormDropdown
                            label="Set as primary invoice address"
                            options={yesNoOptions}
                            value={value}
                            onChange={onChange}
                            pleaseSelectText="Select Yes or No"
                            error={formState.errors.isPrimaryInvoiceAddress}
                            // Disable dropdown if the invoice address is already the primary
                            disabled={selectedInvoiceAddress?.isPrimaryInvoiceAddress}
                            buttonClassName="h-[48px] border-black bg-S2D-dark-green-70.1"
                        />
                    )}
                />
            </div>

            <div className="mt-0 border-t-[1px] rounded-b-sm border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6">
                <SubmitOrCancelButtonBar
                    onCancel={() => setModalState("closed")}
                    onDelete={() => setDeleteInvoiceAddressDialogOpen(true)}
                    isLoading={isEditingInvoiceAddress}
                    disabled={isEditingInvoiceAddress}
                />
            </div>

            <ConfirmationDialog
                variant="danger"
                open={deleteInvoiceAddressDialogOpen}
                onClose={() => setDeleteInvoiceAddressDialogOpen(false)}
                title={`Delete invoice address ${selectedInvoiceAddress?.addressName}?`}
                caption="This action is non-reversible."
                onConfirm={handleDeleteInvoiceAddress}
                isLoading={isDeletingInvoiceAddress}
            />
        </form>
    );
}
