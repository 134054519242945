import { useS2DApiFetcher } from "./common/fetching";
import {
    AddUserDto,
    Enable2FaDto,
    ForgottenPasswordDto,
    ForgottenPasswordResetDto,
    ResetPasswordDto,
} from "../models/app/user";

// --------- with token ---------- //

const useAddUser = () =>
    useS2DApiFetcher<AddUserDto, string, { dto: AddUserDto }>(
        "POST",
        ({ dto }) => ({
            url: "api/users",
            body: dto,
        }),
        [],
        true
    );

const useResetPassword = () =>
    useS2DApiFetcher<
        ResetPasswordDto,
        string,
        { id: string; dto: ResetPasswordDto }
    >(
        "PUT",
        ({ id, dto }) => ({
            url: `api/users/${id}/password`,
            body: dto,
        }),
        [403],
        true
    );

const useEnable2fa = () =>
    useS2DApiFetcher<
        Enable2FaDto,
        undefined,
        { id: string; dto: Enable2FaDto }
    >(
        "PUT",
        ({ id, dto }) => ({
            url: `api/users/${id}`,
            body: dto,
        }),
        [],
        true
    );

export const useVerifiedUser = () => {
    const [addUser, isAddingUser] = useAddUser();
    const [resetPassword, isResettingPassword] = useResetPassword();
    const [enable2fa, isEnabling2fa] = useEnable2fa();

    return {
        addUser: async (dto: AddUserDto) => await addUser({ dto }),
        isAddingUser,
        resetPassword: async (id: string, dto: ResetPasswordDto) =>
            await resetPassword({ id, dto }),
        isResettingPassword,
        enable2fa: async (id: string, dto: Enable2FaDto) =>
            await enable2fa({ id, dto }),
        isEnabling2fa,
    };
};

// --------- without token ---------- //

const useForgottenPassword = () =>
    useS2DApiFetcher<
        ForgottenPasswordDto,
        string,
        { dto: ForgottenPasswordDto }
    >(
        "POST",
        ({ dto }) => ({
            url: "api/users/password/forgotten",
            body: dto,
        }),
        [],
        false
    );

const useForgottenPasswordReset = () =>
    useS2DApiFetcher<
        ForgottenPasswordResetDto,
        string,
        { id: string; dto: ForgottenPasswordResetDto }
    >(
        "POST",
        ({ id, dto }) => ({
            url: `api/users/${id}/password/reset`,
            body: dto,
        }),
        [400],
        false
    );

export const useUnverifiedUser = () => {
    const [forgottenPassword, isHandlingForgottenPassword] =
        useForgottenPassword();
    const [forgottenPasswordReset, isResettingPassword] =
        useForgottenPasswordReset();

    return {
        forgottenPassword: async (dto: ForgottenPasswordDto) =>
            await forgottenPassword({ dto }),
        isHandlingForgottenPassword,
        forgottenPasswordReset: async (
            id: string,
            dto: ForgottenPasswordResetDto
        ) => await forgottenPasswordReset({ id, dto }),
        isResettingPassword,
    };
};
