type EDeclarationFormLayoutProps = {
    title?: string;
    subtitle?: string;
    description?: string;
    content: JSX.Element;
    buttons?: JSX.Element;
};

const EDeclarationFormLayout: React.FC<EDeclarationFormLayoutProps> = ({
    title,
    subtitle,
    description,
    buttons,
    content,
}) => {
    return (
        <>
            {title && <h1 className="text-3xl mb-3">{title}</h1>}
            {subtitle && (
                <h2 className="font-semibold text-lg mb-2">{subtitle}</h2>
            )}
            {description && <p className="mb-4">{description}</p>}
            {content}
            {buttons && <div className="mt-auto pt-6">{buttons}</div>}
        </>
    );
};

export default EDeclarationFormLayout;
