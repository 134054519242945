import { ApiDocumentsResponse } from "../../models/api/admin/document";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useDeleteDocument = () =>
    useS2DApiFetcher<void, void, string>("DELETE", (fileId) => ({
        url: `api/documents/${fileId}`,
    }));

    // the api/documents endpoint accepts a vehicleId as part of the formData
    // allowing a document to be associated with a vehicle
export const useAddDocument = () =>
    useS2DApiFetcher<FormData, string, FormData>(
        "POST",
        (formData) => ({
            url: "api/documents",
            body: formData,
            contentType: "multipart/form-data",
        }),
        [],
        true
    );

export const useDocuments = (id: string) => {
    const { data, error, isLoading, refetch } = useS2DApiSWR<
        ApiDocumentsResponse
    >(id ? `api/documents?DriverId=${id}` : null);

    const [addDocument, isAddingDocument] = useAddDocument();
    const [deleteDocument, isDeletingDocument] = useDeleteDocument();

    return {
        documentsData: data,
        documentsError: error,
        documentsAreLoading: isLoading,
        refetchDocuments: refetch,
        addDocument: async (formData: FormData) => {
            const result = await addDocument(formData);

            if (result.success) {
                refetch();
            }
            return result;
        },
        deleteDocument: async (fileId: string) => {
            const result = await deleteDocument(fileId);

            if (result.success) {
                refetch();
            }
            return result;
        },
        isAddingDocument,
        isDeletingDocument,
    };
};