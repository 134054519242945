import { DeclarationStatus } from "../models/shared/statuses";

export const declarationStatusColor = (status: DeclarationStatus | null) => {
    switch (status) {
        case DeclarationStatus.Completed:
            return "bg-S2D-success-80";
        case DeclarationStatus.DueToExpire:
        case DeclarationStatus.Requested:
            return "bg-S2D-warning-90";
        case DeclarationStatus.Expired:
        case DeclarationStatus.Revoked:
            return "bg-S2D-error-40";
        default:
            return "bg-S2D-neutral-70";
    }
};
