import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import { DriverDeclaration } from "../../models/api/admin/eDeclarations";
import { declarationStatusColor } from "../../utils/declararationStatusColour";
import { formatDateToString } from "../../utils/formatDateToString";
import StatusPill from "../ReusableComponents/StatusPill/StatusPill";
import {
    type TableContent,
    type TableHeader,
} from "../ReusableComponents/Table";
import UserIcon from "../UserIcon";

type AllowedIds =
    | "name"
    | "company"
    | "department"
    | "email"
    | "declarationExpires"
    | "licenceOrigin"
    | "type"
    | "status";

enum MenuGroups {
    PersonalDetails = "Personal Details",
    Employment = "Employment",
    Declaration = "Declaration",
}

export const driverDeclarationsMenuGroups: MenuGroups[] = (
    Object.keys(MenuGroups) as Array<keyof typeof MenuGroups>
).map((key) => MenuGroups[key]);

export type DriverDeclarationsRowContent = TableContent<
    DriverDeclaration,
    AllowedIds
>;
export type DriverDeclarationsTableHeader = TableHeader<AllowedIds, MenuGroups>;

export const initDriverDeclarationsTable: DriverDeclarationsTableHeader[] = [
    {
        title: "Name",
        id: "name",
        menuGroup: MenuGroups.PersonalDetails,
    },
    {
        title: capitalisedTermForCompany,
        id: "company",
        menuGroup: MenuGroups.Employment,
    },
    {
        title: "Department",
        id: "department",
        menuGroup: MenuGroups.Employment,
    },
    {
        title: "Email",
        id: "email",
        menuGroup: MenuGroups.PersonalDetails,
    },
    {
        title: "Declaration expires",
        id: "declarationExpires",
        menuGroup: MenuGroups.Declaration,
    },
    {
        title: "Licence origin",
        id: "licenceOrigin",
        menuGroup: MenuGroups.Declaration,
    },
    {
        title: "Type",
        id: "type",
        menuGroup: MenuGroups.Declaration,
    },
    {
        title: "Status",
        id: "status",
        menuGroup: MenuGroups.Declaration,
    },
];

export function driverDeclarationsTableContents(
    declarations?: DriverDeclaration[]
): DriverDeclarationsRowContent[] | undefined {
    const tableContents = new Array<DriverDeclarationsRowContent>(0);
    if (!declarations) return undefined;

    declarations.forEach((declaration) => {
        const expirationDate = declaration.declarationExpires
            ? formatDateToString(new Date(declaration.declarationExpires))
            : null;

        const rowContent = (): DriverDeclarationsRowContent => ({
            rowId: declaration.declarationId,
            rowData: declaration,
            cellData: [
                {
                    id: "name",
                    content: (
                        <div className="flex items-center gap-2">
                            <UserIcon
                                firstName={declaration.firstName}
                                lastName={declaration.lastName}
                                size="small"
                            />
                            <p>{`${declaration.firstName}  ${declaration.lastName}`}</p>
                        </div>
                    ),
                },
                {
                    id: "company",
                    content: declaration.companyName,
                },
                {
                    id: "department",
                    content: declaration.department ?? "-",
                },
                {
                    id: "email",
                    content: declaration.emailAddress,
                },
                {
                    id: "declarationExpires",
                    content: expirationDate ?? "-",
                },
                {
                    id: "licenceOrigin",
                    content: declaration.licenceOrigin,
                },
                {
                    id: "type",
                    content: declaration.declarationType,
                },
                {
                    id: "status",
                    content: (
                        <div className="pl-2">
                            <StatusPill
                                status={declaration.declarationStatus ?? "-"}
                                bgColour={declarationStatusColor(
                                    declaration.declarationStatus
                                )}
                            />
                        </div>
                    ),
                },
            ],
        });

        tableContents.push(rowContent());
    });
    return tableContents;
}
