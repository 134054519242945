import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
    type CompanyRiskProfile,
    type AddCompanyDto,
} from "../../models/app/admin/company";
import Button from "../Button";
import {
    AddCompanyFormDefaultValues,
    type AddCompanyFormValues,
    AddCompanyValidationSchemaFields,
} from "./AddCompanyFormValues";
import CompanyCheckFrequenciesFields from "./CompanyCheckFrequenciesFields";
import CompanyCheckPricingFields from "./CompanyCheckPricingFields";
import CompanyDetailsFields from "./CompanyDetailsFields";
import CompanyEDeclarationSettingsFields from "./CompanyEDeclarationSettingsFields";
import CompanyRiskCategoriesFields from "./CompanyRiskCategoriesFields";
import useAuth from "../../auth";
import { useGetCompanies } from "../../api/admin/company";

type AddCompanyFormProps = {
    onSubmit: (
        data: AddCompanyDto,
        callback: (error: boolean) => void
    ) => Promise<void>;
    isLoading: boolean;
    onCancel: () => void;
};

const validationSchema = yup.object().shape(AddCompanyValidationSchemaFields);

const AddCompanyForm: React.FC<AddCompanyFormProps> = ({
    onSubmit,
    isLoading,
    onCancel,
}) => {
    const { userDetails } = useAuth();
    const { companyList, refetchCompanies } = useGetCompanies(1, 100);
    const methods = useForm<AddCompanyFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: AddCompanyFormDefaultValues,
    });

    const handleSubmitCallback = (error: boolean) => {
        if (!error) {
            methods.reset();
            refetchCompanies;
        }
    };

    const handleAddSubmit = (data: AddCompanyFormValues) => {
        const defaultResellerIdToUsersCompany =
            data.resellerId !== "" ? data.resellerId : userDetails?.companyId;

        const companyRiskProfileArray: CompanyRiskProfile[] = [
            {
                severityLevel: 1,
                name: data.riskCategory?.range1Name,
                lowerThreshold: data.riskCategory?.range1,
                checkFrequency: data.checkFrequency?.range1,
            },
            {
                severityLevel: 2,
                name: data.riskCategory?.range2Name,
                lowerThreshold: data.riskCategory?.range2,
                checkFrequency: data.checkFrequency?.range2,
            },
            {
                severityLevel: 3,
                name: data.riskCategory?.range3Name,
                lowerThreshold: data.riskCategory?.range3,
                checkFrequency: data.checkFrequency?.range3,
            },
        ];

        onSubmit(
            {
                companyName: data.companyName,
                resellerId: defaultResellerIdToUsersCompany,
                companyRiskProfiles: companyRiskProfileArray,
                settings: data.settings,
            },
            handleSubmitCallback
        );
    };

    return (
        <div className="mx-auto w-full max-w-3xl">
            <form onSubmit={methods.handleSubmit(handleAddSubmit)}>
                <CompanyDetailsFields
                    methods={methods}
                    companies={companyList}
                />
                <CompanyEDeclarationSettingsFields methods={methods} />
                <CompanyRiskCategoriesFields methods={methods} />
                <CompanyCheckFrequenciesFields methods={methods} />
                <CompanyCheckPricingFields methods={methods} />
                <div className="flex flex-col items-center justify-between gap-4 pt-4 pb-8 md:flex-row md:gap-6">
                    <Button
                        variant="tertiary"
                        type="button"
                        className="w-full justify-center md:w-auto"
                        loading={isLoading}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button type="submit" loading={isLoading}>
                        Add Company
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AddCompanyForm;
