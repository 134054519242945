import ApiError from "../../../api/common/apiError";
import { ApiCommentResponse } from "../../../models/api/admin/comment";
import CommentForm from "../../CommentForm";
import { CommentFormValues } from "../../CommentForm/CommentFormValues";
import CommentList from "../../CommentList";
import EditableContentBlock from "../../EditableContentBlock";
import FormMessageBox from "../../FormMessageBox";

type EditCompanyCommentsFormProps = {
    comments?: ApiCommentResponse[];
    isLoadingComments: boolean;
    commentsError?: ApiError;
    onAdd: (
        dto: CommentFormValues,
        callback: (success: boolean) => void
    ) => Promise<void>;
    isAdding: boolean;
    onEdit: (
        commentId: string,
        dto: CommentFormValues,
        callback: (success: boolean) => void
    ) => Promise<void>;
    isEditing: boolean;
    onDelete: (
        commentId: string,
        callback: (success: boolean) => void
    ) => Promise<void>;
    isDeleting: boolean;
};

export const EditCompanyCommentsForm: React.FC<
    EditCompanyCommentsFormProps
> = ({
    comments,
    isLoadingComments,
    commentsError,
    onAdd,
    isAdding,
    onEdit,
    isEditing,
    onDelete,
    isDeleting,
}) => {
    return (
        <>
            {!isLoadingComments && comments && (
                <EditableContentBlock title="Comments">
                    {({ isEdit, setIsEdit }) => (
                        <>
                            <div className="p-4">
                                {commentsError && (
                                    <FormMessageBox
                                        variant="error"
                                        title={"Error loading comments"}
                                        message={"Please try again."}
                                    />
                                )}
                                <CommentList
                                    comments={comments}
                                    disabledEditing={!isEdit}
                                    onDelete={onDelete}
                                    isDeleting={isDeleting}
                                    onEdit={onEdit}
                                    isEditing={isEditing}
                                />
                                {isEdit && (
                                    <CommentForm
                                        isLoading={isAdding}
                                        onCancel={() => setIsEdit(false)}
                                        onSubmit={onAdd}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </EditableContentBlock>
            )}
        </>
    );
};
