import { useMemo, useState } from "react";
import FormTextInput from "../FormTextInput";
import { MagnifyingGlass } from "phosphor-react";
import HeadlessGenericDropdown from "../ReusableComponents/HeadlessGenericDropdown";
import { TableCheckbox } from "../ReusableComponents/Table/tableTypes";
import { DriverDeclaration } from "../../models/api/admin/eDeclarations";
import {
    DriverDeclarationBulkActionType,
    driverDeclarationsBulkActions,
    useDriverDeclarationBulkActions,
} from "./driverDeclarationsBulkActions";
import { determineDriverDeclarationsBulkActionType } from "./determineDriverDeclarationsBulkActionType";
import { DeclarationType } from "../../models/app/eDeclarations";
import { DeclarationTableFilters } from "../../api/admin/eDeclarations";
import { declarationTableFiltersDefaultValues } from "../../hooks/useDeclarationTableFiltersLocalStorage";
import Button from "../Button";

type DriverDeclarationsTableFiltersProps = {
    searchQuery: string;
    setSearchQuery: (value: string) => void;
    selectedCheckboxes: TableCheckbox<DriverDeclaration>[];
    setSelectedCheckboxes: (value: TableCheckbox<DriverDeclaration>[]) => void;
    refetchEDeclarationsList: () => void;
    declarationTableFiltersStore: DeclarationTableFilters;
    handleResetFilters: () => void;
    isLoadingEDeclarationsList: boolean;
};
export default function DriverDeclarationsTableFilters({
    searchQuery,
    setSearchQuery,
    selectedCheckboxes,
    setSelectedCheckboxes,
    refetchEDeclarationsList,
    declarationTableFiltersStore,
    handleResetFilters,
    isLoadingEDeclarationsList
}: DriverDeclarationsTableFiltersProps) {
    const [isBulkActionsLoading, setIsBulkActionsLoading] = useState(false);

    const optionType: DriverDeclarationBulkActionType = useMemo(
        () => determineDriverDeclarationsBulkActionType(selectedCheckboxes),
        [selectedCheckboxes]
    );
    const bulkActionOptions = driverDeclarationsBulkActions(optionType);

    const { handleSendDeclarationEmail, handleSwitchDeclarationType } =
        useDriverDeclarationBulkActions(
            selectedCheckboxes,
            setSelectedCheckboxes,
            setIsBulkActionsLoading,
            refetchEDeclarationsList
        );

    const handleBulkActionDropdown = (id?: string) => {
        if (id === "send-email") {
            handleSendDeclarationEmail();
        }
        if (id === "switch-type") {
            const declarationType =
                optionType === "eDeclaration"
                    ? DeclarationType.PaperDeclaration
                    : DeclarationType.EDeclaration;
            handleSwitchDeclarationType(declarationType);
        }
    };

    const areFiltersApplied =
        JSON.stringify(declarationTableFiltersStore) !==
        JSON.stringify(declarationTableFiltersDefaultValues);

    return (
        <div className="flex flex-wrap items-start justify-between gap-6">
            <div className="flex w-full flex-col flex-wrap gap-6 md:w-fit md:flex-row">
                <FormTextInput
                    StartAdornmentIcon={MagnifyingGlass}
                    value={searchQuery}
                    placeholder="Search drivers"
                    className="w-full md:w-[320px]"
                    inputHeight="h-[48px]"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    disabled={isLoadingEDeclarationsList}
                />
                {areFiltersApplied && (
                    <Button
                        variant="secondary"
                        onClick={handleResetFilters}
                        className="min-w-fit grow justify-center md:flex-none"
                    >
                        Reset Filters
                    </Button>
                )}
                <HeadlessGenericDropdown
                    handleItemChange={(item) =>
                        handleBulkActionDropdown(item.id)
                    }
                    className={"w-full md:w-fit"}
                    isLoading={isBulkActionsLoading}
                    disabled={!optionType}
                    dropdownPlaceholder="Bulk actions"
                    dropdownData={bulkActionOptions}
                />
            </div>
        </div>
    );
}
