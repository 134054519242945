import { CheckCircle } from "phosphor-react";
import ActionableStatus from "../../ActionableStatus";
import { StatusAction } from "../../ActionableStatus/ActionableStatus";
import ActionableStatusTag, {
    actionableStatusTagTheme,
} from "../../ActionableStatus/ActionableStatusTag";

type LicenceCheckStatusProps = {
    actions: StatusAction[];
    theme: keyof typeof actionableStatusTagTheme;
    text: string;
    children: React.ReactNode;
    driverId: string;
    isManual: boolean;
};

const buildStatusIndicators = (
    checkTheme: keyof typeof actionableStatusTagTheme,
    checkText: string,
    isManual: boolean
) => {
    const statusIndicators = [];

    if (isManual) {
        statusIndicators.push(
            <ActionableStatusTag theme="white" text="Manual" />
        );
    }

    statusIndicators.push(
        <ActionableStatusTag theme={checkTheme} text={checkText} />
    );

    return statusIndicators;
};

const LicenceCheckStatus = ({
    actions,
    theme,
    text,
    children,
    driverId,
    isManual,
}: LicenceCheckStatusProps) => {
    const statusIndicators = buildStatusIndicators(theme, text, isManual);

    return (
        <ActionableStatus
            title="Licence checks"
            theme="grey"
            actionable={actions.length > 0}
            actions={actions}
            statusIndicators={statusIndicators}
            driverId={driverId}
        >
            <div className="flex items-center">
                <div className="pr-3">
                    <CheckCircle size={24} weight="duotone" />
                </div>
                <div className="flex flex-col text-sm">{children}</div>
            </div>
        </ActionableStatus>
    );
};

export default LicenceCheckStatus;
