import { MagnifyingGlass } from "phosphor-react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { formatDateToString } from "../../../utils/formatDateToString";
import Button from "../../Button";
import EditableContentBlock from "../../EditableContentBlock";
import FormDateInput from "../../FormDateInput";
import FormMessageBox from "../../FormMessageBox";
import FormTextInput from "../../FormTextInput";
import { EditGreyFleetFormValues } from "../EditGreyFleetFormValues";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useVehicleRegLookupSwr } from "../../../api/vehicleRegLookup";
import { addYears } from "../../../utils/addYears";
import clsx from "clsx";
import { calculateAgeOfVehicle } from "../../../utils/calculateAgeOfVehicle";

type EditVehicleInformationFormProps = {
    initialValues: EditGreyFleetFormValues;
    methods: UseFormReturn<EditGreyFleetFormValues>;
    isLoading: boolean;
    onSubmit: (data: EditGreyFleetFormValues, callback: () => void) => void;
};

const today = new Date();
const thirtyYearsFromToday = addYears(today, 30);

const EditVehicleInformationForm = ({
    initialValues,
    methods,
    isLoading,
    onSubmit,
}: EditVehicleInformationFormProps) => {
    const [vehicleRegLookupErrorMessage, setVehicleRegLookupErrorMessage] =
        useState<string | undefined>(undefined);
    const [validatedVehicleReg, setValidatedVehicleReg] = useState("");

    const { control } = methods;

    const watchVehicleReg = useWatch({ control, name: "vehicle.vrm" });
    const {
        vehicleRegLookupData,
        vehicleRegLookupError,
        vehicleRegLookupIsLoading,
        vehicleRegLookupIsValidating,
        refetchVehicleRegLookup,
    } = useVehicleRegLookupSwr(validatedVehicleReg);

    const handleVehicleRegLookup = async () => {
        setVehicleRegLookupErrorMessage(undefined);
        setValidatedVehicleReg(watchVehicleReg);
    };

    const yearOfManufacture = useWatch({ control: methods.control, name: "vehicle.yearOfManufacture" });
    const vehicleAge = yearOfManufacture ? calculateAgeOfVehicle(yearOfManufacture) : "";

    useEffect(() => {
        if (!vehicleRegLookupData) {
            return;
        }
        methods.setValue("vehicle.vrm", validatedVehicleReg);
        methods.setValue("vehicle.make", vehicleRegLookupData.make);
        methods.setValue(
            "vehicle.monthOfFirstRegistration",
            vehicleRegLookupData.monthOfFirstRegistration
        );
        methods.setValue(
            "vehicle.motExpiryDate",
            vehicleRegLookupData.motExpiryDate
        );
        methods.setValue(
            "vehicle.taxExpiryDate",
            vehicleRegLookupData.taxExpiryDate
        );
        methods.setValue(
            "vehicle.yearOfManufacture",
            vehicleRegLookupData.yearOfManufacture
        );
        methods.setValue(
            "vehicle.co2Emissions",
            vehicleRegLookupData.co2Emissions
        );
        methods.setValue(
            "vehicle.fuelType",
            vehicleRegLookupData.fuelType
        );
    }, [vehicleRegLookupData]);

    const handleFormSubmit = async (
        values: EditGreyFleetFormValues,
        setIsEdit: Dispatch<SetStateAction<boolean>>
    ) => {
        refetchVehicleRegLookup();
        setValidatedVehicleReg("");
        onSubmit(values, () => setIsEdit(false));
        setVehicleRegLookupErrorMessage(undefined);
    };

    const handleCancel = (setIsEdit: Dispatch<SetStateAction<boolean>>) => {
        setVehicleRegLookupErrorMessage(undefined);

        setValidatedVehicleReg("");
        methods.reset(initialValues);
        refetchVehicleRegLookup();

        setIsEdit(false);
    };

    const handleOnClosed = () => {
        setVehicleRegLookupErrorMessage(undefined);

        setValidatedVehicleReg("");
        methods.reset(initialValues);
        refetchVehicleRegLookup();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter") {
            e.preventDefault();
            handleVehicleRegLookup();
        }
    };

    return (
        <EditableContentBlock
            title="Vehicle Information"
            onClosed={() => handleOnClosed()}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            <p>
                                <b>Registration number:</b>{" "}
                                {initialValues.vehicle?.vrm}
                            </p>
                            <p>
                                <b>Registration date:</b>{" "}
                                {initialValues.vehicle
                                    ?.monthOfFirstRegistration instanceof Date
                                    ? formatDateToString(
                                        initialValues.vehicle
                                            .monthOfFirstRegistration
                                    )
                                    : ""}
                            </p>
                            <p>
                                <b>Make:</b> {initialValues.vehicle?.make}
                            </p>
                            <p>
                                <b>Model:</b> {initialValues.vehicle?.model}
                            </p>
                            <p>
                                <b>Age of vehicle:</b>{" "}
                                {initialValues.vehicle?.yearOfManufacture ? calculateAgeOfVehicle(initialValues.vehicle?.yearOfManufacture) : ""}
                            </p>
                            <p>
                                <b>CO2 Emissions:</b> {initialValues.vehicle?.co2Emissions}
                                <span
                                    className={clsx(
                                        initialValues.vehicle?.co2Emissions === undefined && "hidden"
                                    )}
                                >
                                    g/km
                                </span>
                            </p>
                            <p>
                                <b>Fuel type:</b> {initialValues.vehicle?.fuelType}
                            </p>
                            <p>
                                <b>Road Tax expiry date:</b>{" "}
                                {initialValues.vehicle?.taxExpiryDate instanceof
                                    Date
                                    ? formatDateToString(
                                        initialValues.vehicle?.taxExpiryDate
                                    )
                                    : ""}
                            </p>
                            <p>
                                <b>MOT expiry date:</b>{" "}
                                {initialValues.vehicle?.motExpiryDate instanceof
                                    Date
                                    ? formatDateToString(
                                        initialValues.vehicle?.motExpiryDate
                                    )
                                    : ""}
                            </p>
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit((values) =>
                                    handleFormSubmit(values, setIsEdit)
                                )}
                            >
                                {vehicleRegLookupError && (
                                    <FormMessageBox
                                        title={
                                            "Vehicle details could not be found"
                                        }
                                        message={
                                            "If you are sure that you entered the correct registration number please enter the requested information manually."
                                        }
                                        variant="error"
                                    />
                                )}
                                <div className="w-full">
                                    <div className="sm:flex sm:flex-1 sm:gap-4">
                                        <Controller
                                            name="vehicle.vrm"
                                            control={methods.control}
                                            render={({
                                                field: { onChange, value },
                                            }) => {
                                                // Force registration number to uppercase
                                                const upperCaseVrm =
                                                    value.toUpperCase();
                                                return (
                                                    <FormTextInput
                                                        label="Vehicle registration number (number plate)"
                                                        required
                                                        id="registrationNumber"
                                                        placeholder="Enter vehicle registration number"
                                                        value={upperCaseVrm}
                                                        error={
                                                            methods.formState
                                                                .errors.vehicle
                                                                ?.vrm ||
                                                            vehicleRegLookupErrorMessage
                                                        }
                                                        autoFocus
                                                        onChange={onChange}
                                                        helpText="For example, BU57ABC"
                                                        onKeyDown={(e) => {
                                                            handleKeyDown(e);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <div className="flex justify-end sm:translate-y-9 ">
                                            <Button
                                                type="button"
                                                variant="secondary"
                                                onClick={handleVehicleRegLookup}
                                                loading={
                                                    vehicleRegLookupIsLoading &&
                                                    vehicleRegLookupIsValidating
                                                }
                                                className="h-14"
                                            >
                                                <div className="pr-2">
                                                    <MagnifyingGlass
                                                        weight="fill"
                                                        size={20}
                                                    />
                                                </div>
                                                Search
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="py-2" />
                                    <Controller
                                        name="vehicle.monthOfFirstRegistration"
                                        control={methods.control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormDateInput
                                                id="monthOfFirstRegistration"
                                                label="Registration Date"
                                                required
                                                value={value}
                                                onChange={onChange}
                                                error={
                                                    methods.formState.errors
                                                        .vehicle
                                                        ?.monthOfFirstRegistration
                                                }
                                                maxDate={new Date()}
                                                keepLocalTime
                                            />
                                        )}
                                    />
                                    <div className="py-2" />
                                    <FormTextInput
                                        label="Make of vehicle"
                                        id="make"
                                        required
                                        placeholder="Enter make"
                                        error={
                                            methods.formState.errors.vehicle
                                                ?.make
                                        }
                                        {...methods.register("vehicle.make")}
                                    />
                                    <div className="py-2" />
                                    <FormTextInput
                                        label="Model of vehicle"
                                        id="model"
                                        placeholder="Enter model"
                                        error={
                                            methods.formState.errors.vehicle
                                                ?.model
                                        }
                                        {...methods.register("vehicle.model")}
                                    />
                                    <div className="py-2" />
                                    <FormTextInput
                                        label="Age of vehicle"
                                        id="ageOfVehicle"
                                        placeholder="Enter age of vehicle"
                                        disabled
                                        value={vehicleAge}
                                    />
                                    <div className="py-2" />
                                    <FormTextInput
                                        label="CO2 Emissions"
                                        id="co2Emissions"
                                        disabled
                                        {...methods.register("vehicle.co2Emissions")}
                                    />
                                    <div className="py-2" />

                                    <FormTextInput
                                        label="Fuel type"
                                        id="fuelType"
                                        disabled
                                        {...methods.register("vehicle.fuelType")}
                                    />
                                    <div className="py-2" />
                                    <Controller
                                        name="vehicle.taxExpiryDate"
                                        control={methods.control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormDateInput
                                                id="taxExpiryDate"
                                                label="Road Tax Expiry Date"
                                                required
                                                value={value}
                                                onChange={onChange}
                                                error={
                                                    methods.formState.errors
                                                        .vehicle?.taxExpiryDate
                                                }
                                                maxDate={thirtyYearsFromToday}
                                                keepLocalTime
                                            />
                                        )}
                                    />
                                    <div className="py-2" />
                                    <Controller
                                        name="vehicle.motExpiryDate"
                                        control={methods.control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormDateInput
                                                id="motExpiryDate"
                                                label="MOT Expiry Date (if applicable)"
                                                value={value}
                                                onChange={onChange}
                                                error={
                                                    methods.formState.errors
                                                        .vehicle?.motExpiryDate
                                                }
                                                maxDate={thirtyYearsFromToday}
                                                keepLocalTime
                                            />
                                        )}
                                    />
                                </div>
                                <div className="mt-8 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => handleCancel(setIsEdit)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" loading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditVehicleInformationForm;
