import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import clsx from "clsx";

// NB: this component can only be used inside a headlessui/react Tab.List
const TabWithIcon = ({
    icon,
    title,
    mobileAppearanceBreakpoint = "lg",
}: {
    icon: JSX.Element;
    title: string;
    mobileAppearanceBreakpoint?: "lg" | "xl";
}) => {
    return (
        <Tab as={Fragment}>
            {({ selected }) => (
                <div
                    className={clsx(
                        `flex h-11 min-w-fit flex-row items-center gap-2 px-4 ${mobileAppearanceBreakpoint}:basis-1/2 cursor-pointer justify-center`,
                        selected &&
                            "w-full bg-S2D-light-green-80 text-S2D-text-button focus:outline-none"
                    )}
                >
                    {icon}
                    <span
                        className={clsx(
                            !selected &&
                                `hidden ${mobileAppearanceBreakpoint}:inline-block`
                        )}
                    >
                        {title}
                    </span>
                </div>
            )}
        </Tab>
    );
};

export default TabWithIcon;
