import { PrebuiltReports } from "../models/api/reporting";
import { queryParamConnector } from "../utils/queryParamHelpers";
import { useS2DApiFetcher } from "./common/fetching";

export type ReportFileTypes = "CSV" | "XLS";

export const useDownloadReports = (
    companyId?: string,
    departmentId?: string
) => {
    const pathArray = [`api/report/download`];
    if (companyId) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`CompanyId=${companyId}`);
    }
    if (departmentId) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`DepartmentId=${departmentId}`);
    }

    const [getReport, isGettingReport] = useS2DApiFetcher<
        null,
        Blob,
        { reportName: PrebuiltReports; type?: ReportFileTypes }
    >(
        "GET",
        ({ reportName, type }) => ({
            url: `${pathArray.join("")}${queryParamConnector(
                pathArray
            )}ReportName=${reportName} ${
                type ? `&ReportFormatType=${type}` : ""
            }`,
            body: null,
            altToken: undefined,
            contentType:
                type == "CSV" ? "text/csv" : "application/vnd.ms-excel",
        }),
        [],
        true
    );

    return {
        getReport: async (reportName: PrebuiltReports, type?: ReportFileTypes) =>
            await getReport({ reportName, type }),
        isGettingReport,
    };
};
