import { Controller, UseFormReturn } from "react-hook-form";
import { CompanyStatusFormValues } from "./useAdminDisableCompany";
import { termForCompany } from "../../../../../copy/sharedCopy";
import FormDropdown from "../../../../../components/FormDropdown";
import Button from "../../../../../components/Button";
import FormTextAreaInput from "../../../../../components/FormTextAreaInput";
import { disableReasonOptions } from "./useAdminDisableCompany";
import {
    ApiCompanyResponse,
    CompanyStatusEnum,
} from "../../../../../models/api/admin/company";
import DisabledCompanyView from "./DisabledCompanyView";

interface Props {
    methods: UseFormReturn<CompanyStatusFormValues>;
    companyData: ApiCompanyResponse | undefined;
    handleSubmitCompanyStatus: (data: CompanyStatusFormValues) => void;
    setShowConfirmationDialog: (show: boolean) => void;
    onCancel: () => void;
    isLoading: boolean;
}

export default function CompanyStatusForm({
    methods,
    companyData,
    handleSubmitCompanyStatus,
    setShowConfirmationDialog,
    onCancel,
    isLoading,
}: Props) {
    const { control, formState, register, handleSubmit } = methods;
    const { status, statusId } = companyData || {};
    const companyIsDisabled: boolean =
        !!(statusId === CompanyStatusEnum.DisabledNonPayment) ||
        !!(statusId === CompanyStatusEnum.DisabledPermanent);

    return (
        <form onSubmit={handleSubmit(handleSubmitCompanyStatus)}>
            {companyIsDisabled && (
                <DisabledCompanyView companyData={companyData} />
            )}

            {!companyIsDisabled && (
                <div className="flex flex-col space-y-4">
                    <Controller
                        name="status"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <FormDropdown
                                label={`Disable ${termForCompany}`}
                                options={disableReasonOptions}
                                value={value ?? null}
                                required
                                onChange={onChange}
                                pleaseSelectText="Select option"
                                error={formState.errors.status}
                            />
                        )}
                    />
                    <FormTextAreaInput
                        label="Comments"
                        {...register("comment")}
                        id="comment"
                        placeholder={"Enter comment"}
                        error={formState.errors.comment}
                    />
                </div>
            )}

            <div className="flex justify-between pt-7">
                <Button
                    variant="tertiary"
                    type="button"
                    className="w-max justify-center md:w-auto"
                    loading={isLoading}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <div className="flex w-full flex-col items-center space-y-2 space-x-0 sm:w-auto sm:flex-row sm:space-x-4 sm:space-y-0">
                    <Button
                        type="button"
                        loading={isLoading}
                        variant="tertiary"
                        className="w-full text-S2D-error-40 sm:w-auto "
                        onClick={() => setShowConfirmationDialog(true)}
                    >
                        <span className="w-full">Delete permanently</span>
                    </Button>
                    {!companyIsDisabled && (
                        <Button
                            type="submit"
                            loading={isLoading} // CHANGE THIS
                            variant="primary"
                            className="w-full sm:w-auto"
                        >
                            <span className="w-full">
                                Disable {termForCompany}
                            </span>
                        </Button>
                    )}

                    {companyIsDisabled && (
                        <Button
                            type="button"
                            disabled={
                                statusId === CompanyStatusEnum.DisabledPermanent
                            }
                            onClick={() =>
                                handleSubmitCompanyStatus({ status: "Live" })
                            }
                            loading={false}
                            variant={"danger"}
                            className="w-full sm:w-auto"
                        >
                            <span className="w-full">
                                Enable {termForCompany}
                            </span>
                        </Button>
                    )}
                </div>
            </div>
        </form>
    );
}
