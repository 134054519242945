import { type Dispatch, type SetStateAction } from "react";
import UserIcon from "../UserIcon";
import StatusPill from "../ReusableComponents/StatusPill/StatusPill";
import { userStatusColor } from "../../utils/userStatusColour";
// import IconButton from "../IconButton";
// import { ClockCounterClockwise, Download, Printer } from "phosphor-react";
import { capitaliseWord } from "../../utils/capitaliseWord";
import { type ApiCompanyUserResponse } from "../../models/api/admin/companyUser";

type EditCompanyUserHeaderProps = {
    selectedTab: "edit" | "audit";
    setSelectedTab: Dispatch<SetStateAction<"edit" | "audit">>;
    initialValues: ApiCompanyUserResponse;
};

const EditCompanyUserHeader: React.FC<EditCompanyUserHeaderProps> = ({
    selectedTab,
    setSelectedTab,
    initialValues,
}) => {
    return (
        <div className="flex w-full flex-wrap items-center justify-between gap-4 px-6 py-2">
            <div className="flex items-center">
                <UserIcon
                    firstName={initialValues.firstName}
                    lastName={initialValues.lastName}
                    size="large"
                />
                <div className="flex flex-col">
                    <p className="mb-1 text-2xl leading-none">
                        {initialValues.firstName} {initialValues.lastName}
                    </p>
                    <StatusPill
                        status={capitaliseWord(initialValues.status)}
                        bgColour={userStatusColor(
                            capitaliseWord(initialValues.status)
                        )}
                    />
                </div>
            </div>
            {/* removed options here for v1.0 release */}

            {/* <div className="grid flex-none grid-flow-col items-start gap-5">
                <IconButton
                    label="Download driver"
                    icon={Download}
                    onClick={() => {
                        console.log("download user");
                    }}
                />
                <IconButton
                    label="Print driver"
                    icon={Printer}
                    onClick={() => {
                        console.log("print user");
                    }}
                />
                <IconButton
                    label="Audit driver"
                    icon={ClockCounterClockwise}
                    onClick={() => {
                        setSelectedTab(
                            selectedTab === "edit" ? "audit" : "edit"
                        );
                    }}
                    className={
                        selectedTab === "audit"
                            ? "border-2 border-S2D-light-green-80 bg-S2D-dark-green-70.1"
                            : ""
                    }
                />
            </div> */}
        </div>
    );
};

export default EditCompanyUserHeader;
