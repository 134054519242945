import { Menu } from "@headlessui/react";
import clsx from "clsx";
import ApiError from "../../api/common/apiError";
import { CaretDown, CaretUp, Check } from "phosphor-react";

export type DropdownItem<AllowedNames = string> = {
    item?: AllowedNames | JSX.Element;
    id?: string;
    onClick?: () => void;
};
type HeadlessGenericDropdownProps = {
    dropdownData?: DropdownItem[];
    selectedItem?: DropdownItem;
    handleItemChange?: (item: DropdownItem) => void;
    error?: ApiError;
    isLoading: boolean;
    disabled?: boolean;
    className?: string;
    dropdownPlaceholder?: string;
    errorMessage?: string;
    noItemsMessage?: string;
    textSize?: "14px" | "16px";
    label?: string;
    borderColour?: "border-S2D-neutral-10" | "border-S2D-light-green-80" | null;
    backgroundColour?: "bg-S2D-neutral-100" | "bg-S2D-dark-green-70.1" | null;
};

export default function HeadlessGenericDropdown({
    dropdownData,
    selectedItem,
    handleItemChange,
    dropdownPlaceholder = "Please select",
    noItemsMessage = "No items",
    error,
    errorMessage,
    isLoading,
    disabled,
    className,
    textSize = "16px",
    borderColour,
    backgroundColour,
    label,
}: HeadlessGenericDropdownProps) {
    const showAsDisabled = isLoading || disabled;
    return (
        <div className={clsx(`flex flex-col`, className)}>
            {label && <div className="text=[14px] pl-3 pb-1">{label}</div>}
            <Menu>
                {({ open }) => (
                    <div className="relative">
                        <Menu.Button
                            className={clsx(
                                `flex h-[48px] w-full items-center justify-between rounded-sm border-2 
                                 font-semibold text-S2D-neutral-10`,
                                borderColour ?? "border-S2D-neutral-10",
                                backgroundColour ?? "bg-S2D-neutral-100",
                                showAsDisabled && "opacity-40",
                                isLoading && "hover:cursor-wait"
                            )}
                            disabled={showAsDisabled}
                        >
                            <div
                                className={clsx(
                                    `flex max-w-[75vw] items-center gap-2 truncate whitespace-nowrap pr-6`,
                                    `text-[${textSize}]`,
                                    label ? "pl-3" : "pl-6"
                                )}
                            >
                                {selectedItem?.item ?? dropdownPlaceholder}
                            </div>
                            <div className="pr-3">
                                {open ? (
                                    <CaretUp size={20} weight="fill" />
                                ) : (
                                    <CaretDown size={20} weight="fill" />
                                )}
                            </div>
                        </Menu.Button>
                        <Menu.Items
                            className={clsx(
                                !label && "absolute",
                                `z-10 flex max-h-[60vh] w-full min-w-fit flex-col  
                            overflow-y-auto overflow-x-hidden rounded bg-white shadow-mui`
                            )}
                        >
                            {error && <MessageItem text={errorMessage} />}
                            {!dropdownData?.length && (
                                <MessageItem text={noItemsMessage} />
                            )}
                            {dropdownData &&
                                dropdownData.map((item, i) => (
                                    <Menu.Item key={i}>
                                        {({ active }) => (
                                            <div
                                                className={clsx(
                                                    `flex items-center justify-between gap-2 whitespace-normal p-3 md:whitespace-nowrap`,
                                                    active
                                                        ? "bg-S2D-light-green-80"
                                                        : ""
                                                )}
                                                onClick={() => {
                                                    // we can pass the handler from the dropdownItem itself
                                                    // or from the parent component
                                                    handleItemChange &&
                                                        handleItemChange(item);
                                                    item.onClick &&
                                                        item.onClick();
                                                }}
                                            >
                                                {item.item}
                                                {selectedItem?.id ===
                                                    item.id && (
                                                    <Check weight="fill" />
                                                )}
                                            </div>
                                        )}
                                    </Menu.Item>
                                ))}
                        </Menu.Items>
                    </div>
                )}
            </Menu>
        </div>
    );
}

const MessageItem = ({ text }: { text?: string }) => (
    <Menu.Item>
        <div className={clsx(`flex cursor-not-allowed items-center gap-2 p-3`)}>
            {text ?? "Error loading data"}
        </div>
    </Menu.Item>
);
