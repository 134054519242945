import { Dispatch, SetStateAction, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { CaretDown, CaretUp } from "phosphor-react";
import FormDateInput from "../FormDateInput";
import { type TransferDate } from "../../pages/Admin/AdminFileSafe";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dateValidation } from "../../utils/dateValidationYup";

export type TransferDateFilterProps = {
    date: TransferDate;
    setDate: Dispatch<SetStateAction<TransferDate>>;
    label: string;
};

const TransferDateFilter: React.FC<TransferDateFilterProps> = ({
    date,
    setDate,
    label,
}) => {
    const validationSchema = yup.object().shape({
        from: dateValidation.nullable(),
        to: dateValidation
            .min(yup.ref("from"), "'to' date cannot be before 'from' date.")
            .nullable(),
    });

    const methods = useForm<TransferDate>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: date,
    });

    const handleBlur = async () => {
        const hasTriggered = await methods.trigger();

        if (hasTriggered) {
            const noErrors =
                !methods.formState.errors.from && !methods.formState.errors.to;

            if (noErrors) {
                const values = methods.getValues();
                setDate(values);
            }
        }
    };

    const nullDates = {
        from: null,
        to: null,
    };

    // hack for clear all without prop drilling
    useEffect(() => {
        if (JSON.stringify(date) === JSON.stringify(nullDates)) {
            methods.setValue("from", null);
            methods.setValue("to", null);
        }
    }, [date]);

    return (
        <>
            <Disclosure>
                {({ open, close }) => (
                    <div
                        className=" flex flex-col"
                        onKeyDown={(e: any) => {
                            if (e.key === "Enter") {
                                close();
                            }
                        }}
                    >
                        <Disclosure.Button className="rounded-sm focus:outline-none focus:ring-1 focus:ring-black">
                            <div
                                className={clsx(
                                    "flex h-12 min-w-full items-center justify-between gap-4 rounded-sm border-2 border-black px-6 font-semibold",
                                    "text-black transition-all hover:bg-S2D-surface-light-4 ",
                                    open && "bg-S2D-surface-light-4"
                                )}
                            >
                                {label}
                                <div>
                                    {open ? (
                                        <CaretUp size={20} weight="fill" />
                                    ) : (
                                        <CaretDown size={20} weight="fill" />
                                    )}
                                </div>
                            </div>
                        </Disclosure.Button>
                        {open && (
                            <div
                                className="fixed inset-0 z-40"
                                aria-hidden="true"
                                onClick={() => close()}
                            />
                        )}
                        <Disclosure.Panel>
                            <div className="absolute z-50 flex flex-col rounded-sm bg-S2D-neutral-100 p-4 shadow-lg shadow-S2D-neutral-40">
                                <Controller
                                    name="from"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            label="From"
                                            id="date-from"
                                            maxDate={date.to ?? new Date()}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={() => handleBlur()}
                                            error={
                                                methods.formState.errors.from
                                            }
                                            hideHelpText={true}
                                            adornmentClassName={clsx(
                                                !methods.formState.errors
                                                    .from && "-mt-[0.2rem]"
                                            )}
                                        />
                                    )}
                                />
                                <Controller
                                    name="to"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            label="To"
                                            id="date-to"
                                            minDate={date.from ?? undefined}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={() => handleBlur()}
                                            error={methods.formState.errors.to}
                                            hideHelpText={true}
                                            adornmentClassName={clsx(
                                                !methods.formState.errors.to &&
                                                    "-mt-[0.2rem]"
                                            )}
                                        />
                                    )}
                                />
                            </div>
                        </Disclosure.Panel>
                    </div>
                )}
            </Disclosure>
        </>
    );
};

export default TransferDateFilter;
