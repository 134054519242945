import { FormDropdownOption } from "../components/FormDropdown";
import {
    AgeRange,
    AgeRangeOptions,
    AgeRangeValues,
    BusinessCover,
    BusinessCoverOptions,
    Co2Emissions,
    Co2EmissionsOptions,
    Co2EmissionsValues,
    EngineCapacity,
    EngineCapacityOptions,
    EngineCapacityValues,
    EuroRating,
    EuroRatingOptions,
    EuroRatingValues,
    FuelType,
    FuelTypeOptions,
    InsuranceType,
    InsuranceTypeOptions,
} from "../models/app/admin/vehicleAnalysis";
import { addSpacesToCamelCase } from "../utils/addSpacesToCamelCase";
import { capitaliseWord } from "../utils/capitaliseWord";

export const co2EmissionsOptions: FormDropdownOption<keyof Co2Emissions>[] = (
    Object.keys(Co2EmissionsOptions) as Array<keyof Co2Emissions>
).map((key) => ({
    label: Co2EmissionsValues[key],
    value: Co2EmissionsOptions[key],
}));

export const fuelTypeOptions: FormDropdownOption<keyof FuelType>[] = (
    Object.keys(FuelTypeOptions) as Array<keyof FuelType>
).map((key) => ({
    label: capitaliseWord(FuelTypeOptions[key]),
    value: FuelTypeOptions[key],
}));

export const ageRangeOptions: FormDropdownOption<keyof AgeRange>[] = (
    Object.keys(AgeRangeOptions) as Array<keyof AgeRange>
).map((key) => ({
    label: AgeRangeValues[key],
    value: AgeRangeOptions[key],
}));

export const euroRatingOptions: FormDropdownOption<keyof EuroRating>[] = (
    Object.keys(EuroRatingOptions) as Array<keyof EuroRating>
).map((key) => ({
    label: EuroRatingValues[key],
    value: EuroRatingOptions[key],
}));

export const insuranceTypeOptions: FormDropdownOption<keyof InsuranceType>[] = (
    Object.keys(InsuranceTypeOptions) as Array<keyof InsuranceType>
).map((key) => ({
    label: capitaliseWord(addSpacesToCamelCase(InsuranceTypeOptions[key])),
    value: InsuranceTypeOptions[key],
}));

export const businessCoverOptions: FormDropdownOption<keyof BusinessCover>[] = (
    Object.keys(BusinessCoverOptions) as Array<keyof BusinessCover>
).map((key) => ({
    label: capitaliseWord(addSpacesToCamelCase(BusinessCoverOptions[key])),
    value: BusinessCoverOptions[key],
}));

export const engineCapacityOptions: FormDropdownOption<keyof EngineCapacity>[] =
    (Object.keys(EngineCapacityOptions) as Array<keyof EngineCapacity>).map(
        (key) => ({
            label: EngineCapacityValues[key],
            value: EngineCapacityOptions[key],
        })
    );
