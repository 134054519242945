import { useState } from "react";
import { type ApiCompanyResponse } from "../../models/api/admin/company";
import { type ApiDepartmentItem } from "../../models/api/admin/department";
import Button, { ButtonProps } from "../Button";
import FormDocumentUploadDropdown from "../FormDocumentUpload/FormDocumentUploadDropdown";
import CompanyDepartmentSelector from "./CompanyDepartmentSelector";
import DownloadReportButton from "./DownloadReportButton";
import { Minus, Plus } from "phosphor-react";
import clsx from "clsx";
import useAuth from "../../auth";
import { isS2dUser } from "../../auth/userAccessHelpers";

export type TailoredButton = {
    title: string;
    onClick: () => void;
    icon?: ButtonProps["icon"];
    variant?: ButtonProps["variant"];
};

type HeaderOptionsProps = {
    // Client/department:
    selectedDepartment?: ApiDepartmentItem | null;
    handleDepartmentChange?: (department: ApiDepartmentItem | null) => void;
    selectedCompany?: ApiCompanyResponse | null;
    handleCompanyChange?: (company: ApiCompanyResponse | null) => void;
    clearCompanyDepartment?: () => void;
    // Reports:
    handleDownloadPdfReport?: () => void;
    handleDownloadCsvReport?: () => void;
    handleDownloadXlsReport?: () => void;
    isReportDownloading?: boolean;
    isReportDisabled?: boolean;
    // Import:
    refetch?: () => void;
    // Tailored button:
    tailoredButtons?: TailoredButton[];
    // Date picker (not yet implemented anywhere)
};

const HeaderOptions: React.FC<HeaderOptionsProps> = ({
    selectedDepartment,
    handleDepartmentChange,
    selectedCompany,
    handleCompanyChange,
    clearCompanyDepartment,
    handleDownloadPdfReport,
    handleDownloadCsvReport,
    handleDownloadXlsReport,
    isReportDownloading,
    isReportDisabled,
    refetch,
    tailoredButtons,
}) => {
    const { decodedToken, storedAuthorization, userDetails } = useAuth();
    const [isHidden, setIsHidden] = useState(true);
    const showFiltersText = isHidden
        ? "Show filters and actions"
        : "Hide filters and actions";
    const showFiltersIcon = isHidden ? Plus : Minus;

    const showCompanyDepartmentSelector = !!(
        handleDepartmentChange && handleCompanyChange
    );
    const showDownloadReports = !!(
        handleDownloadCsvReport && handleDownloadPdfReport
    );
    const showImportCSV = !!refetch;
    const showTailoredButton = !!tailoredButtons && tailoredButtons.length;

    const companyDepartmentIsSelected = selectedCompany;

    return (
        <div className="flex flex-wrap items-start justify-between gap-4">
            <Button
                variant="secondary"
                onClick={() => setIsHidden(!isHidden)}
                className="min-w-fit grow justify-center md:hidden md:flex-none"
                icon={showFiltersIcon}
                iconEnd
            >
                {showFiltersText}
            </Button>
            <div
                className={clsx(
                    "flex w-full flex-col gap-4 md:w-fit md:flex-row",
                    isHidden && "hidden md:flex"
                )}
            >
                {showCompanyDepartmentSelector && (
                    <>
                        <CompanyDepartmentSelector
                            currentDepartment={selectedDepartment ?? null}
                            handleDepartmentChange={handleDepartmentChange}
                            selectedCompany={selectedCompany ?? null}
                            handleCompanyChange={handleCompanyChange}
                        />
                        {companyDepartmentIsSelected && (
                            <Button
                                variant="tertiary"
                                onClick={clearCompanyDepartment}
                                className="min-w-fit grow justify-center md:flex-none"
                            >
                                Reset
                            </Button>
                        )}
                    </>
                )}
            </div>
            <div
                className={clsx(
                    "flex w-full flex-col gap-4 md:w-fit md:flex-row",
                    isHidden && "hidden md:flex"
                )}
            >
                {showDownloadReports && (
                    <DownloadReportButton
                        handleDownloadCsvReport={handleDownloadCsvReport}
                        handleDownloadPdfReport={handleDownloadPdfReport}
                        handleDownloadXlsReport={handleDownloadXlsReport}
                        isReportDownloading={isReportDownloading ?? false}
                        isDisabled={isReportDisabled}
                    />
                )}

                {showImportCSV && isS2dUser(decodedToken?.role) && (
                    <FormDocumentUploadDropdown refetchDrivers={refetch} />
                )}

                {showTailoredButton &&
                    tailoredButtons.map((button) => (
                        <Button
                            icon={button.icon ?? undefined}
                            className="min-w-fit grow justify-center md:flex-none"
                            onClick={button.onClick}
                            variant={button.variant ?? "primary"}
                            key={button.title}
                        >
                            {button.title}
                        </Button>
                    ))}
            </div>
        </div>
    );
};

export default HeaderOptions;
