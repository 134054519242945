import { useEdeclaration } from "../../api/edeclaration";
import { format } from "date-fns";
import { type Step1VerifyIdentityFields } from "./Step1VerifyIdentity";
import { type Step3PermissionsFields } from "./Step4Permissions";
import { errorToast } from "../../toast";
import { type EDeclarationFormValues } from "./EDeclarationFormValues";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Step2LicenceInformationFields } from "./Step2LicenceInformation";
import { NORTHERN_IRELAND } from "../../constants/countries";
import { DriverValidationTypes } from "../../models/app/admin/company";

export const useEdeclarationWizard = (
    eDeclarationId: string,
    handleStepSubmit: (
        nextStepIndex: number,
        data?: Partial<EDeclarationFormValues>
    ) => void,
    eDeclarationFormData: EDeclarationFormValues,
    setEDeclarationFormData: (value: EDeclarationFormValues) => void,
    driverValidationType?: DriverValidationTypes
) => {
    const { validateUser, updateDriverDetails, finaliseEdeclarationJourney } =
        useEdeclaration();

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [eDeclarationToken, setEDeclarationToken] = useState(token);

    const [manualCheck, setManualCheck] = useState(false);

    const handleVerificationSubmit = async (
        data: Step1VerifyIdentityFields,
        callback: (error: true) => void
    ) => {
        if (!driverValidationType) {
            errorToast("Error loading e-declaration data. Please try again.");
            return;
        }

        const response = await validateUser(eDeclarationId, {
            ...data,
            dateOfBirth: data.dateOfBirth
                ? format(data.dateOfBirth, "yyyy-MM-dd")
                : null,
            driverValidationType: driverValidationType,
        });

        if (!response.success) {
            window.scrollTo(0, 0);
            callback(true);
            return;
        }

        let driverAddress = response.content.driver.address;
        for (const [key, value] of Object.entries(driverAddress)) {
            if (value === null) {
                driverAddress = { ...driverAddress, [key]: "" };
            }
        }

        setEDeclarationToken(response.content.token);
        handleStepSubmit(1, {
            ...response.content.driver,
            dateOfBirth: new Date(response.content.driver.dateOfBirth),
            address: {
                ...driverAddress,
                addressTown: driverAddress.town,
                addressPostcode: driverAddress.postcode,
            },
        });
    };

    const handleDriverUpdateSubmit = async (
        data: Step3PermissionsFields,
        callback: (error: true) => void
    ) => {
        if (!eDeclarationToken) {
            callback(true);
            return;
        }

        const response = await updateDriverDetails(
            eDeclarationId,
            eDeclarationToken,
            {
                driver: {
                    ...eDeclarationFormData,
                },
            }
        );

        if (response.success) {
            finaliseEdeclarationJourney(eDeclarationId, eDeclarationToken);
        } else {
            callback(true);
        }

        handleStepSubmit(4, {
            ...data,
        });
    };

    const handleForeignLicenceSubmit = async (
        value: Step2LicenceInformationFields,
        errorCallback: () => void
    ) => {
        if (!eDeclarationToken) {
            errorCallback();
            return;
        }

        setEDeclarationFormData({ ...eDeclarationFormData, ...value });

        // if they've only declared as foreign, we won't know the country of origin
        const licenceOrigin =
            value.licenceCountryOrigin === NORTHERN_IRELAND
                ? value.licenceCountryOrigin
                : undefined;

        const response = await updateDriverDetails(
            eDeclarationId,
            eDeclarationToken,
            {
                driver: {
                    licenceCountryOrigin: licenceOrigin,
                    foreignLicence: true,
                },
            }
        );

        if (response.success) {
            setManualCheck(true);
        } else {
            errorCallback();
            return;
        }
    };

    return {
        handleVerificationSubmit,
        handleDriverUpdateSubmit,
        handleForeignLicenceSubmit,
        eDeclarationToken,
        manualCheck,
    };
};
