import { UserRoles } from "../../auth/decodeToken";
import { AddStaffMemberDto } from "../../models/app/safe2DriveAdmin/staff";
import * as yup from "yup";
import { SchemaKeys } from "../../models/shared/schemaKeysHelper";
import { S2DUser } from "../../models/api/safe2DriveAdmin/staff";

const addStaffMemberValidationSchemaKeys: SchemaKeys<AddStaffMemberFormValues> =
    {
        firstName: yup
            .string()
            .max(30, "First Name cannot be more than 30 characters long")
            .required("First name is required."),
        lastName: yup
            .string()
            .max(30, "Last Name cannot be more than 30 characters long")
            .required("Last name is required."),
        emailAddress: yup
            .string()
            .email()
            .required("Email address is required."),
        mobileNumber: yup.string().notRequired().nullable(),
        position: yup
            .string()
            .max(100, "Position must be at most 100 characters.")
            .notRequired()
            .nullable(),
        roles: yup
            .string()
            .required("Permission type is required."),
        claims: yup.object().shape({
            hasFileSafeClaim: yup.boolean(),
            hasViewPiiClaim: yup.boolean(),
        })
    };

export const addStaffMemberValidationSchema = yup
    .object()
    .shape(addStaffMemberValidationSchemaKeys);

export type AddStaffMemberFormValues = Omit<AddStaffMemberDto, "lastLoginDate" | "roles"> & { roles: S2DUser }

export const addStaffMemberDefaultValues: AddStaffMemberFormValues = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    mobileNumber: "",
    position: "",
    roles: UserRoles.s2dMember,
    claims: {
        hasFileSafeClaim: false,
        hasViewPiiClaim: true,
    }
};
