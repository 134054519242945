import {
    ApiDashboardResponse,
    DashboardCategories,
} from "../../models/api/admin/dashboard";
import { useS2DApiSWR } from "../common/s2dApiSWR";

type UseDashboardParameters = {
    companyId?: string;
    departmentId?: string;
    date?: string;
    compareMonths?: number;
};

export const useDashboard = (
    parameters: UseDashboardParameters,
    options?: DashboardCategories[]
) => {
    const urlQueryParams = [];
    let key: keyof typeof parameters;

    for (key in parameters) {
        if (parameters[key] !== undefined) {
            urlQueryParams.push(
                encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(`${parameters[key]}`)
            );
        }
    }
    if (options) {
        options.map((option) => {
            urlQueryParams.push(`Includes=${option}`);
        });
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiDashboardResponse>(
            `api/dashboard${
                urlQueryParams.length ? "?" + urlQueryParams.join("&") : ""
            }`
        );
    return {
        dashboardData: data,
        dashboardError: error,
        isLoadingDashboard: isLoading,
        refetchDashboard: refetch,
    };
};
