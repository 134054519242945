import { type ApiFileSafeItem } from "../../api/admin/fileSafe";

export type NewTransferDto = {
    company: string;
    recipient: string;
    notes: string;
    files: File[];
};

export enum TransferStatus {
    New = "New",
    Transferred = "Transferred",
    MarkedForDeletion = "MarkedForDeletion",
}

// 16/06/2023 currently only used for updating transfer status
export type EditTransferDto = ApiFileSafeItem;
