import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
    type DriversListFiltersFormValues,
    EmploymentFilterValidationSchema,
    PersonalInformationFilterValidationSchema,
    DriverAddressFilterValidationSchema,
    LinkedDriverFilterValidationSchema,
    LicenceInformationFilterValidationSchema,
} from "./DriversListFiltersFormValues";
import * as yup from "yup";
import FilterPersonalInformation from "./FilterPersonalInformation";
import { errorToast } from "../../../toast";
import clsx from "clsx";
import Button from "../../Button";
import FilterEmployment from "./FilterEmployment";
import FilterDriverAddress from "./FilterDriverAddress";
import FilterLinkedDriver from "./FilterLinkedDriver";
import FilterLicenceInformation from "./FilterLicenceInformation";
import {
    DriverCategory,
    driverPageLocation,
} from "../../../utils/driverPageLocation";
import { useLocation } from "react-router-dom";
import { useSelectedValues } from "./useSelectedValues";
import FilterLicenceAnalysis from "./FilterLicenceAnalysis";
import FilterVehicleAnalysis from "./FilterVehicleAnalysis";
import { driverListFiltersDefaultValues } from "./driversListFiltersDefaultValues";

const validationSchema = yup.object().shape({
    company: yup.object().shape(EmploymentFilterValidationSchema),
    personalInformation: yup
        .object()
        .shape(PersonalInformationFilterValidationSchema),
    driverAddress: yup.object().shape(DriverAddressFilterValidationSchema),
    linkedDriver: yup.object().shape(LinkedDriverFilterValidationSchema),
    licence: yup.object().shape(LicenceInformationFilterValidationSchema),
});
export type DriversListFiltersFormProps = {
    filterValues: DriversListFiltersFormValues | undefined;
    onSaveFilter: (value: DriversListFiltersFormValues) => void;
    onApplyFilter: (filters?: DriversListFiltersFormValues) => Promise<boolean>;
    isApplyingFilter: boolean;
};

const DriversListFiltersForm: React.FC<DriversListFiltersFormProps> = ({
    filterValues,
    onSaveFilter,
    onApplyFilter,
    isApplyingFilter,
}) => {
    const methods = useForm<DriversListFiltersFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: filterValues,
    });

    const pageLocation = useLocation();
    const location: DriverCategory | undefined = driverPageLocation(
        pageLocation.pathname
    );

    const checkIsNothingSelected = (values: DriversListFiltersFormValues) => {
        if (
            JSON.stringify(values) ===
            JSON.stringify(driverListFiltersDefaultValues(location))
        ) {
            errorToast("No filters are selected");
            return true;
        }

        return false;
    };

    const handleFormSubmit = (filters: DriversListFiltersFormValues) => {
        const nothingIsSelected = checkIsNothingSelected(filters);
        if (nothingIsSelected) return;
        onApplyFilter(filters);
    };

    const handleSaveFilter = (value: DriversListFiltersFormValues) => {
        const nothingIsSelected = checkIsNothingSelected(value);
        if (nothingIsSelected) return;
        onSaveFilter(value);
    };

    // values for summary in each dropdown section
    const { selectedValues, setSelectedValues, clearSelectedValues } =
        useSelectedValues(methods);

    const handleClearAll = () => {
        methods.reset(driverListFiltersDefaultValues(location));
        clearSelectedValues();
    };

    return (
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <div className="max-h-[32rem] overflow-y-auto">
                <FilterPersonalInformation
                    methods={methods}
                    selectedValues={selectedValues.personalInformation}
                    setSelectedValues={setSelectedValues.personalInformation}
                />
                <FilterEmployment
                    methods={methods}
                    selectedValues={selectedValues.employment}
                    setSelectedValues={setSelectedValues.employment}
                />
                <FilterDriverAddress
                    methods={methods}
                    selectedValues={selectedValues.driverAddress}
                    setSelectedValues={setSelectedValues.driverAddress}
                />
                <FilterLinkedDriver
                    methods={methods}
                    selectedValues={selectedValues.linkedDriver}
                    setSelectedValues={setSelectedValues.linkedDriver}
                />
                <FilterLicenceInformation
                    methods={methods}
                    selectedValues={selectedValues.licence}
                    setSelectedValues={setSelectedValues.licence}
                    location={location}
                />
                {location !== "greyfleet" && (
                    <FilterLicenceAnalysis methods={methods} />
                )}
                {location === "greyfleet" && (
                    <FilterVehicleAnalysis methods={methods} />
                )}
            </div>
            <div className="flex flex-col-reverse justify-between rounded-b-sm border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                <Button
                    variant="tertiary"
                    type="button"
                    onClick={handleClearAll}
                    className="w-full justify-center md:w-auto"
                >
                    Clear all
                </Button>
                <div
                    className={clsx(
                        "flex flex-col-reverse md:flex-row md:justify-between md:gap-2"
                    )}
                >
                    <div className={clsx("py-2 md:py-0")}>
                        <Button
                            variant="secondary"
                            type="button"
                            className="w-full justify-center md:w-auto"
                            onClick={methods.handleSubmit(handleSaveFilter)}
                        >
                            Save filters
                        </Button>
                    </div>
                    <Button
                        type="submit"
                        loading={isApplyingFilter}
                        className="w-full justify-center md:w-auto"
                    >
                        Apply filters
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default DriversListFiltersForm;
