import AM from "./AM.svg";
import A1 from "./A1.svg";
import A2 from "./A2.svg";
import A from "./A.svg";
import B1 from "./B1.svg";
import B from "./B.svg";
import C1 from "./C1.svg";
import C from "./C.svg";
import D1 from "./D1.svg";
import D from "./D.svg";
import BE from "./BE.svg";
import C1E from "./C1E.svg";
import CE from "./CE.svg";
import D1E from "./D1E.svg";
import DE from "./DE.svg";

//2013 onwards (DI 4)
export const categoriesDI4 = [
    {
        code: "AM",
        icon: AM,
    },
    {
        code: "A1",
        icon: A1,
    },
    {
        code: "A2",
        icon: A2,
    },
    {
        code: "A",
        icon: A,
    },
    {
        code: "B1",
        icon: B1,
    },
    {
        code: "B",
        icon: B,
    },
    {
        code: "C1",
        icon: C1,
    },
    {
        code: "C",
        icon: C,
    },
    {
        code: "D1",
        icon: D1,
    },
    {
        code: "D",
        icon: D,
    },
    {
        code: "BE",
        icon: BE,
    },
    {
        code: "C1E",
        icon: C1E,
    },
    {
        code: "CE",
        icon: CE,
    },
    {
        code: "D1E",
        icon: D1E,
    },
    {
        code: "DE",
        icon: DE,
    },
    {
        code: "F",
        icon: null,
    },
    {
        code: "G",
        icon: null,
    },
    {
        code: "H",
        icon: null,
    },
    {
        code: "K",
        icon: null,
    },
    {
        code: "L",
        icon: null,
    },
    {
        code: "M",
        icon: null,
    },
    {
        code: "N",
        icon: null,
    },
    {
        code: "P",
        icon: null,
    },
    {
        code: "Q",
        icon: null,
    },
];
