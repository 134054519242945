import { useGetDepartments } from "../../../api/admin/department";
import useLocalStorage from "../../../hooks/useLocalStorage";

const useDepartmentsTab = (companyId: string | undefined) => {
    const [pageNumber, setPageNumber] = useLocalStorage<number>(
        `DepartmentTablePageNumber`,
        1
    );
    const [pageSize, setPageSize] = useLocalStorage<number>(
        `DepartmentTablePageSize`,
        25
    );

    const {
        departmentsData,
        isLoadingDepartments,
        departmentsError,
        refetchDepartments,
    } = useGetDepartments(companyId, pageNumber, pageSize);

    return {
        departments: departmentsData,
        refetchDepartments,
        isLoadingDepartments,
        departmentsError,
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
    };
};

export default useDepartmentsTab;
