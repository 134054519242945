import { type ApiDepartmentItem } from "../../../../models/api/admin/department";
import StatusPill from "../../../ReusableComponents/StatusPill/StatusPill";
import {
    type TableContent,
    type TableHeader,
} from "../../../ReusableComponents/Table/tableTypes";

type AllowedIds = "departmentName" | "contactName" | "costCentre" | "invoiceAddressName" | "isInvoiceDefault";

export type DepartmentTableHeader = TableHeader<AllowedIds>;
export type DepartmentTableContent = TableContent<
    ApiDepartmentItem,
    AllowedIds
>;

export const initialDepartmentsTableHeaders: DepartmentTableHeader[] = [
    {
        title: "Department",
        id: "departmentName",
    },
    {
        title: "Contact name",
        id: "contactName",
    },
    {
        title: "Cost center",
        id: "costCentre",
    },
    {
        title: "Invoice address",
        id: "invoiceAddressName",
    },
    {
        title: "Invoice default",
        id: "isInvoiceDefault",
    },
];

export function initDepartmentsTableContents(
    departments?: ApiDepartmentItem[]
): DepartmentTableContent[] {
    const tableContents: DepartmentTableContent[] = [];
    departments?.map((department) => {
        tableContents.push({
            rowId: department.departmentId,
            rowData: department,
            cellData: [
                {
                    id: "departmentName",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {department.departmentName}
                        </p>
                    ),
                },
                {
                    id: "contactName",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {department.contactName}
                        </p>
                    ),
                },
                {
                    id: "costCentre",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {department.costCentre}
                        </p>
                    ),
                },
                {
                    id: "invoiceAddressName",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {department.invoiceAddressName}
                        </p>
                    ),
                },
                {
                    id: "isInvoiceDefault",
                    content: (
                        <div className="pl-2">
                            {department.isInvoiceDefault && (
                                <StatusPill
                                    bgColour="bg-black"
                                    textColour="text-white"
                                    status="Default"
                                />
                            )}
                        </div>
                    ),
                },
            ],
        });
    });
    return tableContents;
}
