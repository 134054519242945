import * as yup from "yup";
import { EditCompanyLogoSchema } from "../../EditCompanyFormValues";
import { DocumentBase64 } from "../../../../models/app/admin/document";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAddCompanyLogo } from "../../../../api/admin/company";

export const useEditCompanyLogoForm = () => {
    const validationSchema = yup.object().shape(EditCompanyLogoSchema);

    const [addCompanyLogo, isAddingCompanyLogo] = useAddCompanyLogo();

    const methods = useForm<DocumentBase64>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            documentBase64: "",
            fileType: "",
            fileName: "",
        },
    });

    function formatExtensions(extensions: string[]): string {
        const capitalizedExtens = extensions.map((ext) => ext.toUpperCase());
        const lastElement = capitalizedExtens.pop();
        const secondLastElement = capitalizedExtens.pop();

        return (
            capitalizedExtens.join(", ") +
            (secondLastElement ? ", " + secondLastElement : "") +
            " or " +
            lastElement
        );
    }

    return {
        methods,
        addCompanyLogo,
        isAddingCompanyLogo,
        formatExtensions,
    };
};
