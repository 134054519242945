import { PrebuiltReports } from "../../models/api/reporting";

export type ReportingBoxOption = {
    id: string;
    reportName: PrebuiltReports;
    title: string;
    description: string;
    createdBy?: {
        firstName: string;
        lastName: string;
    };
    createdAt?: Date | null;
    filterTags?: string[];
};

// 6 pre-made options that don't include createdAt dates
export const premadeReports: ReportingBoxOption[] = [
    {
        id: "drivers",
        reportName: PrebuiltReports.Drivers,
        title: "All drivers",
        description:
            "A complete list of your drivers along with their current licence information and previous check dates.",
        // createdBy: {
        //     firstName: "Example",
        //     lastName: "Creator",
        // },
        // createdAt: new Date(),
    },
    {
        id: "endorsements",
        reportName: PrebuiltReports.Endorsements,
        title: "All endorsements",
        description:
            "A list of your drivers with active endorsement codes on their licence.",
    },
    {
        id: "categories",
        reportName: PrebuiltReports.Categories,
        title: "All categories",
        description:
            "A list of your drivers along with details of the licence categories they hold.",
    },
    // 20/07 TODO decide what X is, will it be optional?
    {
        id: "categories-due-to-expire",
        reportName: PrebuiltReports.CategoriesDueToExpire,
        title: "Categories due to expire",
        description:
            "A list of your drivers with licence categories that are due to expire within the next X days.",
    },
    {
        id: "greyfleet",
        reportName: PrebuiltReports.GreyFleet,
        title: "Grey fleet",
        description:
            "A list of your grey drivers along with the vehicles they use.",
    },
    {
        id: "cpc-and-tacho",
        reportName: PrebuiltReports.CpcAndTacho,
        title: "CPC & Tacho",
        description:
            "A list of your professional drivers with CPC and/or Tacho cards.",
    },
];
