import { parseBoolean } from "../components/GreyFleetWizard/greyFleetHelpers";
import { formatDateToString } from "./formatDateToString";
import { isValidDate } from "./isValidDate";

export const stringifyValue = (value?: any) => {
    const dateTimeRegex =
        /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;

    // add extra cases to this if needed
    if (typeof value === "string") {
        // is a date/time string?
        if (value.match(dateTimeRegex)) {
            return formatDateToString(new Date(value));
        }
        return value;
    } else if (typeof value === "number") {
        return value.toString();
    } else if (value instanceof Date && isValidDate(value)) {
        return formatDateToString(value);
    } else if (value instanceof Array) {
        return value.join(", ");
    } else if (typeof value === "boolean") {
        return parseBoolean(value);
    } else return null;
};
