import { useEffect, useState } from "react";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import { ApiDepartmentItem } from "../../models/api/admin/department";
import { ApiGetAllVehiclesResponse } from "../../models/api/admin/vehicle";
import ApiError from "../../api/common/apiError";
import { useVehicle } from "../../api/admin/vehicle";
import {
    GetAllVehiclesDto,
    GetAllVehiclesFilterFields,
} from "../../models/app/admin/vehicles";
import { driverPageLocation } from "../../utils/driverPageLocation";
import useLocalStorage from "../../hooks/useLocalStorage";
import { vehicleFilterNulledValues } from "./vehicleFilterDefaultValues";
import { convertKebabCaseToCamelCase } from "../../utils/convertKebabCaseToCamelCase";
import { FilterVehicleAnalysisDto } from "../../models/app/admin/driverFilters";

export const useVehicleChartAnalysisTable = (
    selectedCompany: ApiCompanyResponse | null,
    selectedDepartment: ApiDepartmentItem | null,
    locationPath: string
) => {
    const location = driverPageLocation(locationPath);
    const [orderBy, setOrderBy] = useLocalStorage<string>(
        `${location}ListOrderBy`,
        ""
    );
    const [pageNumber, setPageNumber] = useLocalStorage<number>(
        `${location}ListPageNumber`,
        1
    );
    const [pageSize, setPageSize] = useLocalStorage<number>(
        `${location}ListPageSize`,
        25
    );

    const [appliedFilter, setAppliedFilter] =
        useLocalStorage<GetAllVehiclesFilterFields>(
            `${location}AppliedFilter`,
            { companyFilter: vehicleFilterNulledValues.companyFilter }
        );

    // the list of vehicles from the api and its error
    const [vehiclesData, setVehiclesData] = useState<
        ApiGetAllVehiclesResponse | undefined
    >();
    const [fetchVehiclesError, setFetchVehiclesError] = useState<
        ApiError | undefined
    >();

    const { getAllVehicles, isGettingAllVehicles } = useVehicle();

    const createEmploymentFilters = (
        companyIdFromFilter?: string,
        departmentIdFromFilter?: string
    ) => {
        // If landing on page with a filter applied, use that filter until dept/company is changed
        const companyId = selectedCompany?.companyId ?? companyIdFromFilter;
        const departmentId =
            selectedDepartment?.departmentId ?? departmentIdFromFilter;

        const companyFilters: Pick<
            GetAllVehiclesFilterFields,
            "companyFilter"
        > = {
            companyFilter: {
                companyIds: companyId ? [companyId] : null,
                departmentIds: departmentId ? [departmentId] : null,
            },
        };
        return companyFilters;
    };

    /* in order to only show vehicles relevant to the table data 
    we need to send an empty object in the initial request.*/
    const chartTypeFilter = () => {
        const chartType = location?.replace("greyfleet/", "");
        const chartTypeCamel = convertKebabCaseToCamelCase(chartType ?? "");

        return chartTypeCamel;
    };

    const handleFetchVehicles = async (
        filters?: GetAllVehiclesFilterFields
    ) => {
        const companyIdFromFilter = filters?.companyFilter?.companyIds?.[0];
        const departmentIdFromFilter =
            filters?.companyFilter?.departmentIds?.[0];
        const chartType = chartTypeFilter() as keyof FilterVehicleAnalysisDto;
        // engine capacity is either defined or unknown, so doesn't need an extra filter
        const addTableFilter = chartType && chartType !== "engineCapacity";
        const tableFilter: FilterVehicleAnalysisDto | undefined = addTableFilter
            ? { [chartType]: {} }
            : undefined;

        const getAllVehiclesBody: GetAllVehiclesDto = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            orderBy: orderBy,
            vehicleAnalysis: tableFilter,
            ...(filters ? { ...filters } : {}),
            // 18/07/23 NB: Client/Dept are filters via the Dropdowns at top of page, hence:
            ...createEmploymentFilters(
                companyIdFromFilter,
                departmentIdFromFilter
            ),
        };

        const response = await getAllVehicles(getAllVehiclesBody);

        if (response.success) {
            setFetchVehiclesError(undefined);
            setVehiclesData(response.content);
            if (filters) {
                setAppliedFilter(filters);
            }
        } else if (!response.success) {
            setFetchVehiclesError(response.error);
        }

        return response;
    };

    // if page number or page size etc changes, fetch drivers.
    useEffect(() => {
        handleFetchVehicles(appliedFilter);
    }, [
        pageNumber,
        pageSize,
        orderBy,
        JSON.stringify(selectedCompany),
        JSON.stringify(selectedDepartment),
    ]);

    // if the selected company/dept changes, remove any company/dept filters from the applied filter:
    // 11/08/23 NB: if all filters depended on applied filter as a single source of truth, this
    // ...useEffect would not be necessary
    useEffect(() => {
        const companyIdFromFilter =
            appliedFilter.companyFilter?.companyIds?.[0];
        const departmentIdFromFilter =
            appliedFilter.companyFilter?.departmentIds?.[0];

        const userHasSelectedCompanyOrDept =
            selectedCompany?.companyId || selectedDepartment?.departmentId;
        const companyOrDeptFilterIsApplied =
            companyIdFromFilter || departmentIdFromFilter;

        if (userHasSelectedCompanyOrDept && companyOrDeptFilterIsApplied) {
            // remove company filter from applied filter
            const currentlyAppliedFilters = appliedFilter;

            const appliedFiltersWithoutCompanyFilters = {
                ...currentlyAppliedFilters,
                company: vehicleFilterNulledValues.companyFilter,
            };
            setAppliedFilter(appliedFiltersWithoutCompanyFilters);
            handleFetchVehicles(appliedFiltersWithoutCompanyFilters);
        } else if (
            !companyOrDeptFilterIsApplied &&
            userHasSelectedCompanyOrDept
        ) {
            handleFetchVehicles(appliedFilter);
        }
    }, [selectedCompany?.companyId, selectedDepartment?.departmentId]);

    return {
        vehiclesData,
        fetchVehiclesError,
        setAppliedFilter,
        paginationLocalStorage: {
            pageNumber,
            setPageNumber,
            pageSize,
            setPageSize,
            orderBy,
            setOrderBy,
        },
        handleFetchVehicles,
        isFetchingVehicles: isGettingAllVehicles,
    };
};
