import { useLocation } from "react-router-dom";
import { type S2DApiFetcherResponse } from "../../api/common/apiResponse";
import { type ApiDriversListResponse } from "../../models/api/admin/driver";
import { type FilterType } from "../../models/app/admin/filter";
import { type DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm";
import { driverPageLocation } from "../../utils/driverPageLocation";

import { useCompanyDepartmentSelector } from "../ReusableComponents/CompanyDepartmentSelector";
import { type GetAllDriversDto } from "../../models/app/admin/driver";
import { type FilterDriverLicenceAnalysisDto } from "../../models/app/admin/driverFilters";
import { AnalysisChartType } from "../../models/app/admin/analysisChart";
import {
    DriverRestrictions,
    DriversByAgeRange,
    DriversByLicenceCategory,
    DriversByLicenceOrigin,
    DriversByPoints,
    EndorsementCodes,
    LicenceTypes,
    YearsFullLicenceHeld,
    YearsFullLicenceHeldOptions,
} from "../../models/app/admin/licenceAnalysis";
import useLocalStorage from "../../hooks/useLocalStorage/useLocalStorage";
import { driverListFiltersDefaultValues } from "../DriverListFilters/DriversListFiltersForm/driversListFiltersDefaultValues";
import { driverLicenceAnalysisDefaultValues } from "./driverLicenceAnalysisDefaultValues";

export type DriverLicenceAnalysisFilterKey =
    | keyof DriversByPoints
    | keyof DriversByAgeRange
    | keyof EndorsementCodes
    | keyof LicenceTypes
    | keyof DriverRestrictions
    | keyof DriversByLicenceCategory
    | keyof DriversByLicenceOrigin
    | keyof YearsFullLicenceHeld;

const useDriverChartAnalysisFilters = (
    chartType: AnalysisChartType,
    setAppliedFilter: (
        value: FilterType | ((val: FilterType) => FilterType)
    ) => void,
    handleFetchDrivers: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<S2DApiFetcherResponse<ApiDriversListResponse>>
) => {
    const location = useLocation();
    const locationDescriptor = driverPageLocation(location.pathname);
    const defaultVals = driverListFiltersDefaultValues(locationDescriptor);

    const [selectedDropdownFilter, setSelectedDropdownFilter] = useLocalStorage<
        DriverLicenceAnalysisFilterKey | string | null
    >(`${chartType}SelectedFilter`, null);

    const { selectedCompany, currentDepartment: selectedDepartment } =
        useCompanyDepartmentSelector();
    const companyDepartmentVals: Pick<GetAllDriversDto, "company"> = {
        company: {
            companyIds: selectedCompany ? [selectedCompany.companyId] : null,
            departmentIds: selectedDepartment
                ? [selectedDepartment.departmentId]
                : null,
            employeeId: null,
            companyDashboard: defaultVals.company.companyDashboard,
        },
    };

    const nullKeyFilter = {
        filterId: "",
        filterFields: {
            ...defaultVals,
            ...companyDepartmentVals,
        },
    };

    // endorsements, restrictions and licence category provide a single string value
    // everything else provides a relevant key for that chart types
    const driverLicenceAnalysisValues = (
        key: DriverLicenceAnalysisFilterKey | string
    ): FilterDriverLicenceAnalysisDto => {
        switch (chartType) {
            case AnalysisChartType.DriversByPoints:
                return {
                    driversByPoints: {
                        ...driverLicenceAnalysisDefaultValues.driversByPoints,
                        [key as YearsFullLicenceHeldOptions]: true,
                    } as DriversByPoints,
                };
            case AnalysisChartType.DriversByAgeRange:
                return {
                    driversByAgeRange: {
                        ...driverLicenceAnalysisDefaultValues.driversByAgeRange,
                        [key]: true,
                    } as DriversByAgeRange,
                };
            case AnalysisChartType.EndorsementCodes:
                return {
                    endorsementCodes: {
                        endorsementCode: key,
                    },
                };
            case AnalysisChartType.DriversByLicenceType:
                return {
                    licenceTypes: {
                        ...driverLicenceAnalysisDefaultValues.licenceTypes,
                        [key]: true,
                    } as LicenceTypes,
                };
            case AnalysisChartType.DriverRestrictions:
                return {
                    driverRestrictions: {
                        restrictionCode: key,
                    },
                };
            case AnalysisChartType.DriversByLicenceOrigin:
                return {
                    driversByLicenceOrigin: {
                        ...driverLicenceAnalysisDefaultValues.driversByLicenceOrigin,
                        [key]: true,
                    } as DriversByLicenceOrigin,
                };
            case AnalysisChartType.DriversByLicenceCategory:
                return {
                    driversByLicenceCategory: {
                        categoryCode: key,
                    },
                };
            case AnalysisChartType.YearsFullLicenceHeld:
                return {
                    yearsFullLicenceHeld: {
                        ...driverLicenceAnalysisDefaultValues.yearsFullLicenceHeld,
                        [key]: true,
                    } as YearsFullLicenceHeld,
                };
            default:
                return {};
        }
    };

    const handleApplyFilter = (
        key: DriverLicenceAnalysisFilterKey | string | null
    ) => {
        if (!key) {
            setSelectedDropdownFilter(null);
            setAppliedFilter(nullKeyFilter);
            handleFetchDrivers(
                nullKeyFilter.filterFields,
                nullKeyFilter.filterId
            );
            return;
        }

        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                driverLicenceAnalysis: driverLicenceAnalysisValues(key),
            },
        };
        setSelectedDropdownFilter(key);
        setAppliedFilter(filter);
        handleFetchDrivers(filter.filterFields, filter.filterId);
    };

    return { handleApplyFilter, selectedDropdownFilter };
};

export default useDriverChartAnalysisFilters;
