import { appPaths } from "../../Routes";
import { useAppliedFilterLocalStorage } from "../../hooks/useAppliedFilterLocalStorage";
import { type CompanyStatusFilters } from "../../models/api/admin/company";
import { type DriverCategory } from "../../utils/driverPageLocation";
import { useNavigate } from "react-router-dom";
import { type FilterType } from "../../models/app/admin/filter";
import { useCompanyDepartmentSelector } from "../ReusableComponents/CompanyDepartmentSelector";
import {
    type CompanyChecksThisMonthDto,
    type CompanyUpcomingChecksDto,
    type CompanyDeclarationsQueryDto,
    type CompanyDriversDto,
    type CompanyGreyFleetDriversDto,
} from "../../models/app/admin/companyDashboard";
import { successToast } from "../../toast";
import { DeclarationTableFilters } from "../../api/admin/eDeclarations";
import {
    declarationTableFiltersDefaultValues,
    useDeclarationTableFiltersLocalStorage,
} from "../../hooks/useDeclarationTableFiltersLocalStorage";
import {
    DeclarationType,
    EDeclarationsDeclarationQueryParams,
} from "../../models/app/eDeclarations";
import { driverListFiltersDefaultValues } from "../DriverListFilters/DriversListFiltersForm/driversListFiltersDefaultValues";
import { useScrollToTableBool } from "../../hooks/useScrollToTable";
import { DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm";

export const useCompanyDashboardActions = (
    setStatusFilter?: (value: CompanyStatusFilters | null) => void,
    tableRef?: React.MutableRefObject<HTMLDivElement | null>,
    id?: string,
    componentRef?: string,
    handleFetchDrivers?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>
) => {
    const navigate = useNavigate();
    const { setAppliedFilter: setDriversAppliedFilter } =
        useAppliedFilterLocalStorage("drivers");
    const driverDefaultVals = driverListFiltersDefaultValues("drivers");
    const { setAppliedFilter: setGreyfleetAppliedFilter } =
        useAppliedFilterLocalStorage("greyfleet");
    const greyfleetDefaultVals = driverListFiltersDefaultValues("greyFleet");

    const allCompaniesStatsActions = (status: CompanyStatusFilters) => {
        if (!setStatusFilter) return;
        setStatusFilter(status);
        successToast("Set status filter.");
        if (tableRef?.current) {
            window.scrollTo(0, tableRef.current.offsetTop);
        }
    };

    const { handleCompanyChange } = useCompanyDepartmentSelector();

    const { setScrollToTable } = useScrollToTableBool();

    const handleApplyDriverFilter = (
        filter: FilterType,
        location: DriverCategory
    ) => {
        handleCompanyChange(null);
        if (location === "drivers") {
            // For allDrivers page
            if (componentRef === "driversPageContent") {
                if (handleFetchDrivers) {
                    setDriversAppliedFilter(filter);
                    handleFetchDrivers(filter.filterFields, filter.filterId);
                }
            } else {
                setDriversAppliedFilter(filter);
                setScrollToTable(true);
                navigate(appPaths.adminDrivers);
            }
        }
        if (location === "greyfleet") {
            setGreyfleetAppliedFilter(filter);
            setScrollToTable(true);
            navigate(appPaths.adminGreyFleet);
        }
    };

    const { setDeclarationTableFiltersStore } =
        useDeclarationTableFiltersLocalStorage();

    const handleApplyDeclarationsFilter = (filter: DeclarationTableFilters) => {
        handleCompanyChange(null);
        setDeclarationTableFiltersStore(filter);
        setScrollToTable(true);
        navigate(appPaths.adminDeclarations);
    };

    const companyDriversStatsActions = (key: keyof CompanyDriversDto) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...driverDefaultVals,
                company: {
                    // reset any applied company for other pages,
                    // as we don't want them to affect the filter
                    companyIds: id ? [id] : null,
                    departmentIds: null,
                    employeeId: null,
                    companyDashboard: {
                        ...driverDefaultVals.company.companyDashboard,
                        companyDrivers: {
                            ...driverDefaultVals.company.companyDashboard
                                ?.companyDrivers,
                            [key]: true,
                        },
                    },
                },
            },
        };
        handleApplyDriverFilter(filter, "drivers");
    };

    const companyGreyfleetDriversStatsActions = (
        key: keyof CompanyGreyFleetDriversDto
    ) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...greyfleetDefaultVals,
                company: {
                    companyIds: id ? [id] : null,
                    departmentIds: null,
                    employeeId: null,
                    companyDashboard: {
                        ...greyfleetDefaultVals.company.companyDashboard,
                        companyGreyfleetDrivers: {
                            ...greyfleetDefaultVals.company.companyDashboard
                                ?.companyGreyfleetDrivers,
                            [key]: true,
                        },
                    },
                },
            },
        };
        handleApplyDriverFilter(filter, "greyfleet");
    };

    const declarationsQueryDefaultValues: EDeclarationsDeclarationQueryParams =
        {
            declarationTypes: null,
            hasExpiredDeclaration: null,
            hasCompletedDeclaration: null,
            hasIncompleteDeclaration: null,
            hasExpiringDeclaration: null,
        };

    const companyDeclarationsQueryStatsActions = (
        key: keyof CompanyDeclarationsQueryDto
    ) => {
        switch (key) {
            case "withDeclarations":
                handleApplyDeclarationsFilter(
                    declarationTableFiltersDefaultValues
                );
                break;
            case "completedDeclarations":
                handleApplyDeclarationsFilter({
                    ...declarationTableFiltersDefaultValues,
                    declarationsQuery: {
                        ...declarationsQueryDefaultValues,
                        declarationTypes: [
                            DeclarationType.EDeclaration,
                            DeclarationType.PaperDeclaration,
                        ],
                        hasCompletedDeclaration: true,
                    },
                });
                break;
            case "expiringDeclarations":
                handleApplyDeclarationsFilter({
                    ...declarationTableFiltersDefaultValues,
                    declarationsQuery: {
                        ...declarationsQueryDefaultValues,
                        declarationTypes: [
                            DeclarationType.EDeclaration,
                            DeclarationType.PaperDeclaration,
                        ],
                        hasExpiringDeclaration: true,
                    },
                });
                break;
            case "declarationsExpiredOrNotReturned":
                handleApplyDeclarationsFilter({
                    ...declarationTableFiltersDefaultValues,
                    declarationsQuery: {
                        ...declarationsQueryDefaultValues,
                        declarationTypes: [
                            DeclarationType.EDeclaration,
                            DeclarationType.PaperDeclaration,
                        ],
                        hasIncompleteDeclaration: true,
                        hasExpiredDeclaration: true,
                    },
                });
                break;
        }
    };

    const companyChecksThisMonthStatsActions = (
        key: keyof CompanyChecksThisMonthDto
    ) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...driverDefaultVals,
                company: {
                    companyIds: id ? [id] : null,
                    departmentIds: null,
                    employeeId: null,
                    companyDashboard: {
                        ...driverDefaultVals.company.companyDashboard,
                        companyChecksThisMonth: {
                            ...driverDefaultVals.company.companyDashboard
                                ?.companyChecksThisMonth,
                            [key]: true,
                        },
                    },
                },
            },
        };
        handleApplyDriverFilter(filter, "drivers");
    };

    const companyUpcomingChecksStatsActions = (
        key: keyof CompanyUpcomingChecksDto
    ) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...driverDefaultVals,
                company: {
                    companyIds: id ? [id] : null,
                    departmentIds: null,
                    employeeId: null,
                    companyDashboard: {
                        ...driverDefaultVals.company.companyDashboard,
                        companyUpcomingChecks: {
                            ...driverDefaultVals.company.companyDashboard
                                ?.companyUpcomingChecks,
                            [key]: true,
                        },
                    },
                },
            },
        };
        handleApplyDriverFilter(filter, "drivers");
    };

    return {
        allCompaniesStatsActions,
        companyDriversStatsActions,
        companyGreyfleetDriversStatsActions,
        companyDeclarationsQueryStatsActions,
        companyChecksThisMonthStatsActions,
        companyUpcomingChecksStatsActions,
    };
};
