import { MagnifyingGlass, Plus } from "phosphor-react";
import FormTextInput from "../FormTextInput";
import FilterDropdown, { FilterOption } from "./FilterDropdown";
import Button from "../Button";
import DownloadReportButton from "./DownloadReportButton";
import FilterModalDropdownButton from "./FilterModalDropdownButton";
import { SetStateAction } from "react";

type TableFiltersAndActionsProps<FilterType> = {
    // Search query field:
    searchQuery?: string;
    searchPlaceholder?: string;
    setSearchQuery?: (query: string) => void;
    // Dropdown filter:
    selectedDropdownFilter?: FilterType | null;
    dropdownFilterOptions?: FilterOption<FilterType>[];
    handleDropdownFilterChange?: (filter: FilterType | null) => void;
    dropdownFilterLabel?: string;
    // Filter modal button:
    setIsFilterModalOpen?: (value: SetStateAction<boolean>) => void;
    isFilterModalOpen?: boolean;
    isModalFilterApplied?: boolean;
    // Tailored 'Add' Button:
    tailoredAddButtonText?: string;
    tailoredAddButtonOnClick?: () => void;
    hideTailoredButton?: boolean;
    // Download report button:
    handleDownloadPdfReport?: () => void;
    handleDownloadCsvReport?: () => void;
    isReportLoading?: boolean;
    isListLoading?: boolean;
};

const TableFiltersAndActions = <FilterType,>({
    selectedDropdownFilter,
    dropdownFilterOptions,
    handleDropdownFilterChange,
    dropdownFilterLabel,
    searchQuery,
    setSearchQuery,
    searchPlaceholder,
    tailoredAddButtonText,
    tailoredAddButtonOnClick,
    hideTailoredButton = false,
    handleDownloadCsvReport,
    handleDownloadPdfReport,
    setIsFilterModalOpen,
    isFilterModalOpen,
    isModalFilterApplied,
    isReportLoading,
    isListLoading
}: TableFiltersAndActionsProps<FilterType>) => {
    const showDropDownFilter = !!(
        dropdownFilterOptions && handleDropdownFilterChange
    );
    const showSearchQuery = !!setSearchQuery;

    const showTailoredAddButton =
        !!(tailoredAddButtonOnClick && tailoredAddButtonText) &&
        !hideTailoredButton;
    const showDownloadReportButton = !!handleDownloadCsvReport;

    const showModalFilterButton = !!setIsFilterModalOpen;
    const showClearFilterButton =
        !!(showDropDownFilter || showSearchQuery) && !showModalFilterButton;
    return (
        <div className="flex flex-wrap items-start justify-between gap-6">
            <div className="flex w-full flex-col flex-wrap gap-6 md:w-fit md:flex-row">
                {showSearchQuery && (
                    <FormTextInput
                        StartAdornmentIcon={MagnifyingGlass}
                        value={searchQuery}
                        placeholder={searchPlaceholder ?? "Search"}
                        className="w-full md:w-[320px]"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        disabled={isListLoading}
                    />
                )}
                {showDropDownFilter && (
                    <FilterDropdown
                        selectedFilter={selectedDropdownFilter ?? null}
                        filterOptions={dropdownFilterOptions}
                        handleFilterChange={handleDropdownFilterChange}
                        label={dropdownFilterLabel ?? "Filter"}
                    />
                )}
                {showModalFilterButton && (
                    <FilterModalDropdownButton
                        isOpen={isFilterModalOpen ?? false}
                        setIsOpen={setIsFilterModalOpen}
                        filterIsApplied={isModalFilterApplied}
                    />
                )}

                {showClearFilterButton && (
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={() => {
                            handleDropdownFilterChange &&
                                handleDropdownFilterChange(null);
                            setSearchQuery && setSearchQuery("");
                        }}
                        disabled={!(selectedDropdownFilter || searchQuery)}
                        className="min-w-fit grow justify-center md:flex-none"
                    >
                        {showSearchQuery && showDropDownFilter
                            ? "Clear all"
                            : "Clear"}
                    </Button>
                )}
            </div>
            <div className="flex w-full flex-col gap-6 md:w-fit md:flex-row">
                {showDownloadReportButton && (
                    <DownloadReportButton
                        handleDownloadPdfReport={handleDownloadPdfReport}
                        handleDownloadCsvReport={handleDownloadCsvReport}
                        isReportDownloading={isReportLoading ?? false}
                    />
                )}
                {showTailoredAddButton && (
                    <Button
                        icon={Plus}
                        onClick={tailoredAddButtonOnClick}
                        className="min-w-fit grow justify-center"
                    >
                        {tailoredAddButtonText}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default TableFiltersAndActions;
