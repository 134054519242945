import * as yup from "yup";
import GreyFleetFormLayout from "./GreyFleetFormLayout";
import Button from "../Button";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../FormTextInput";
import {
    GreyFleetVehicleDetailsFormValidationSchemaFields,
    GreyFleetFormVehicleDetailsValues,
} from "./GreyFleetFormValues";
import FormDateInput from "../FormDateInput";
import React, { useEffect, useState } from "react";
import FormCheckbox from "../FormCheckbox";
import FormSimpleRadioGroup from "../FormSimpleRadioGroup";
import FormMessageBox from "../FormMessageBox";

export type Step4BreakdownFields = Pick<
    GreyFleetFormVehicleDetailsValues,
    | "hasBreakdownCover"
    | "breakdownCompanyName"
    | "breakdownPolicyNumber"
    | "breakdownStartDate"
    | "breakdownEndDate"
>;

type Step4BreakdownCoverProps = {
    defaultValues: Step4BreakdownFields;
    isLoading: boolean;
    onSubmit: (value: Step4BreakdownFields) => void;
    onBackClick: () => void;
    company: string;
    department: string;
    vehicleReg: string;
    breakdownCoverNotStarted: boolean;
    setBreakdownCoverNotStarted: (value: boolean) => void;
    breakdownCoverExpired: boolean;
    setBreakdownCoverExpired: (value: boolean) => void;
    insurerName: string;
    insurancePolicy: string;
    insuranceStart: Date | null;
    insuranceExpiry: Date | null;
};

const validationSchema = yup.object().shape({
    hasBreakdownCover:
        GreyFleetVehicleDetailsFormValidationSchemaFields.hasBreakdownCover,
    breakdownCompanyName:
        GreyFleetVehicleDetailsFormValidationSchemaFields.breakdownCompanyName,
    breakdownPolicyNumber:
        GreyFleetVehicleDetailsFormValidationSchemaFields.breakdownPolicyNumber,
    breakdownStartDate:
        GreyFleetVehicleDetailsFormValidationSchemaFields.breakdownStartDate,
    breakdownEndDate:
        GreyFleetVehicleDetailsFormValidationSchemaFields.breakdownEndDate,
});

const Step4BreakdownCover: React.FC<Step4BreakdownCoverProps> = ({
    defaultValues,
    isLoading,
    onSubmit,
    company,
    onBackClick,
    department,
    vehicleReg,
    breakdownCoverNotStarted,
    setBreakdownCoverNotStarted,
    breakdownCoverExpired,
    setBreakdownCoverExpired,
    insurerName,
    insurancePolicy,
    insuranceStart,
    insuranceExpiry,
}) => {
    const { handleSubmit, formState, register, control, setValue, resetField } =
        useForm<Step4BreakdownFields>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues,
        });

    const breakdownCoverOptions = [
        { id: "true", label: "Yes" },
        { id: "false", label: "No" },
    ];

    const [hasBreakdownCover, setHasBreakdownCover] = useState(defaultValues.hasBreakdownCover);

    const handleHasBreakdownCoverChange = (value: boolean) => {
        if (value) {
            setHasBreakdownCover(true);
        }
        if (!value) {
            setHasBreakdownCover(false);
            setBreakdownCoverNotStarted(false);
            setBreakdownCoverExpired(false);
            resetField("breakdownCompanyName");
            resetField("breakdownPolicyNumber");
            resetField("breakdownStartDate");
            resetField("breakdownEndDate");
        }
        setValue("hasBreakdownCover", value);
    };

    const handleBreakdownCoverExpired = (value: Date | null) => {
        if (value) {
            setBreakdownCoverExpired(value < new Date());
        }
    };

    const handleBreakdownCoverNotStarted = (value: Date | null) => {
        if (value) {
            setBreakdownCoverNotStarted(value > new Date());
        }
    };

    const watchBreakdownCoverCompany = useWatch({
        control,
        name: "breakdownCompanyName",
    });

    const watchBreakdownCoverPolicy = useWatch({
        control,
        name: "breakdownPolicyNumber",
    });

    const watchExpiryBreakdownCover = useWatch({
        control,
        name: "breakdownEndDate",
    });
    const watchStartBreakdownCover = useWatch({
        control,
        name: "breakdownStartDate",
    });

    const handleSameProvider = (value: boolean) => {
        if (value) {
            setValue("breakdownCompanyName", insurerName);
            setValue("breakdownPolicyNumber", insurancePolicy);
            setValue("breakdownStartDate", insuranceStart);
            setValue("breakdownEndDate", insuranceExpiry);
            handleBreakdownCoverExpired(insuranceExpiry);
            handleBreakdownCoverNotStarted(insuranceStart);
            return;
        } else {
            //if these three fields have the same info, remove, otherwise, don't change
            if (insurerName === watchBreakdownCoverCompany) {
                resetField("breakdownCompanyName");
            }
            if (insurancePolicy === watchBreakdownCoverPolicy) {
                resetField("breakdownPolicyNumber");
            }
            if (
                insuranceStart?.toString() ===
                watchStartBreakdownCover?.toString()
            ) {
                setBreakdownCoverNotStarted(false);
                resetField("breakdownStartDate");
            }
            if (
                insuranceExpiry?.toString() ===
                watchExpiryBreakdownCover?.toString()
            ) {
                setBreakdownCoverExpired(false);
                resetField("breakdownEndDate");
            }
        }
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex-1 flex flex-col"
        >
            <GreyFleetFormLayout
                title="Grey Fleet invitation"
                subtitle="Breakdown cover"
                description="Please confirm your current breakdown cover details."
                company={company}
                department={department}
                content={
                    <>
                        {breakdownCoverExpired && (
                            <FormMessageBox
                                title={`Your breakdown cover for ${vehicleReg} has expired`}
                                message={`You can continue with this form now, but please update your breakdown cover to be able
                                to drive this vehicle for business purposes. You will receive a reminder in a week 
                                to come back to this form and re-enter your breakdown cover details.`}
                                variant="error"
                            />
                        )}
                        {breakdownCoverNotStarted && (
                            <FormMessageBox
                                title={`Your breakdown cover for ${vehicleReg} has not started yet`}
                                message={`You can continue with this form now, but please update your breakdown cover to be able
                                to drive this vehicle for business purposes. You will receive a reminder in a week 
                                to come back to this form and re-enter your breakdown cover details.`}
                                variant="error"
                            />
                        )}
                        <div className="sm:grid sm:grid-cols-2 sm:col-start-1">
                            <FormSimpleRadioGroup
                                label="Do you have breakdown cover?"
                                options={breakdownCoverOptions}
                                error={formState.errors.hasBreakdownCover}
                                value={hasBreakdownCover ? "true" : "false"}
                                onChange={(value) =>
                                    handleHasBreakdownCoverChange(value === "true" ? true : false)
                                }
                            />
                            {hasBreakdownCover && (
                                <div className="sm:col-start-1">
                                    <FormCheckbox
                                        id="insuranceProviderIsBreakdownCoverProvider"
                                        label="Same provider and policy as vehicle insurance"
                                        onChange={(e) =>
                                            handleSameProvider(e.target.checked)
                                        }
                                    />
                                    <FormTextInput
                                        label="Breakdown cover company name"
                                        required
                                        id="breakdownCompanyName"
                                        placeholder="Enter breakdown cover company name"
                                        error={
                                            formState.errors
                                                .breakdownCompanyName
                                        }
                                        {...register("breakdownCompanyName")}
                                    />
                                    <FormTextInput
                                        label="Breakdown cover policy number"
                                        required
                                        id="breakdownPolicyNumber"
                                        placeholder="Enter breakdown cover policy number"
                                        error={
                                            formState.errors
                                                .breakdownPolicyNumber
                                        }
                                        {...register("breakdownPolicyNumber")}
                                    />
                                    <Controller
                                        name="breakdownStartDate"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormDateInput
                                                required
                                                id="breakdownStartDate"
                                                label="Breakdown cover start date"
                                                value={value}
                                                onChange={onChange}
                                                error={
                                                    formState.errors
                                                        .breakdownStartDate
                                                }
                                                onBlur={() =>
                                                    handleBreakdownCoverNotStarted(
                                                        value
                                                    )
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="breakdownEndDate"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormDateInput
                                                required
                                                id="breakdownEndDate"
                                                label="Breakdown cover expiry date"
                                                value={value}
                                                onChange={onChange}
                                                error={
                                                    formState.errors
                                                        .breakdownEndDate
                                                }
                                                onBlur={() =>
                                                    handleBreakdownCoverExpired(
                                                        value
                                                    )
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                }
                buttons={
                    <div className="flex justify-end gap-8">
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={onBackClick}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            loading={isLoading}
                        >
                            Next
                        </Button>
                    </div>
                }
            />
        </form>
    );
};

export default Step4BreakdownCover;
