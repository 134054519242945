import { Eye, Flag, Trash } from "phosphor-react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {
    type ApiFileTransferItem,
    type ApiGetFileTransfersResponse,
} from "../../../models/api/admin/fileSafe";
import PaginationNav from "../../PaginationNav";
import Table, { type TableHeader } from "../../ReusableComponents/Table";
import {
    initFileSafeInboxTableContents,
    initFileSafeInboxTableHeaders,
} from "./FileSafeInboxTableInit";
import { appPaths } from "../../../Routes";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../ReusableComponents/ConfirmationDialog";
import useFileSafeTableActions from "../useFileSafeTableActions";
import { hasClientManagementAccess } from "../../../auth/userAccessHelpers";
import useAuth from "../../../auth";
import { TransferStatus } from "../../../models/app/admin/fileSafe";

type FileSafeInboxTableProps = {
    tableData: ApiGetFileTransfersResponse;
    refetchTableData: () => void;
    pageNumber: number;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
};

const FileSafeInboxTable: React.FC<FileSafeInboxTableProps> = ({
    tableData,
    refetchTableData,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
}) => {
    const { decodedToken } = useAuth();
    const isClientManagement = hasClientManagementAccess(decodedToken);

    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "FileSafeInboxTableHeaders",
        initFileSafeInboxTableHeaders
    );

    const {
        handleSelectedTransfer,
        deleteDialogOpen,
        setDeleteDialogOpen,
        flagForDeletionDialogOpen,
        setFlagForDeletionDialogOpen,
        handleDeleteTransfer,
        handleFlagForDeletion,
        isDeletingTransfer,
        isMarkingForDeletion,
        allowMarkForDeletion,
        markForDeletionMessage,
        selectedTransfer,
    } = useFileSafeTableActions(tableData, refetchTableData);

    const isDeleteDisabled =
        selectedTransfer?.status.replaceAll(" ", "") !==
            TransferStatus.MarkedForDeletion && isClientManagement;

    const deleteDisabledMessage = isDeleteDisabled
        ? "Please flag the transfer for deletion before attempting to delete it."
        : undefined;

    const navigate = useNavigate();
    const transferTableMenuItems = (data: ApiFileTransferItem) => {
        const id = data.transferId;
        return [
            {
                key: "view",
                onClick: () => navigate(appPaths.adminViewTransfer(id)),
                icon: <Eye size={20} weight="fill" />,
                text: "View transfer",
            },
            {
                key: "flag",
                onClick: () => {
                    handleSelectedTransfer(id, "flag");
                },
                icon: <Flag size={20} weight="fill" />,
                text: "Flag for deletion",
            },
            {
                key: "delete",
                onClick: () => {
                    handleSelectedTransfer(id, "delete");
                },
                icon: <Trash size={20} weight="fill" />,
                text: "Delete transfer",
            },
        ];
    };

    return (
        <>
            <Table
                tableHeaders={tableHeaders}
                setTableHeaders={setTableHeaders}
                tableContents={initFileSafeInboxTableContents(tableData?.items)}
                menuItems={transferTableMenuItems}
            />
            <PaginationNav
                setPageNumber={setPageNumber}
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalCount={tableData.totalCount}
                totalPages={tableData.totalPages}
                currentPage={pageNumber}
                hasPreviousPage={tableData.hasPreviousPage}
                hasNextPage={tableData.hasNextPage}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                title="Delete transfer?"
                caption="This transfer will be permanently deleted."
                onConfirm={handleDeleteTransfer}
                isLoading={isDeletingTransfer}
                onClose={() => setDeleteDialogOpen(false)}
                variant="danger"
                disabled={isDeleteDisabled}
                error={deleteDisabledMessage}
            />
            <ConfirmationDialog
                open={flagForDeletionDialogOpen}
                title="Flag transfer for deletion?"
                caption={markForDeletionMessage()}
                onConfirm={handleFlagForDeletion}
                isLoading={isMarkingForDeletion}
                onClose={() => setFlagForDeletionDialogOpen(false)}
                variant="danger"
                disabled={!allowMarkForDeletion}
            />
        </>
    );
};

export default FileSafeInboxTable;
