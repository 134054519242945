import { useNavigate, useParams } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import {
    useFileTransfer,
    useGetFileTransferNotifications,
    useGetTransferDetails,
} from "../../api/admin/fileSafe";
import LoadingMessage from "../../components/ReusableComponents/LoadingMessage/LoadingMessage";
import FormMessageBox from "../../components/FormMessageBox";
import ViewTransfer from "../../components/ViewTransfer";
import { errorToast, successToast } from "../../toast";
import { appPaths } from "../../Routes";
import { TransferStatus } from "../../models/app/admin/fileSafe";
import { downloadFromArrayBuffer } from "../../utils/downloadFromArrayBuffer";
import useAuth from "../../auth";

type PageParams = {
    id: string;
};

const AdminViewTransfer = () => {
    const { id } = useParams<PageParams>();
    const {
        transferData,
        transferError,
        isLoadingTransfer,
        getTransferredFiles,
        refetchTransferDetails
    } = useGetTransferDetails(id);
    const { decodedToken } = useAuth();
    const { refetchNotificationCount } = useGetFileTransferNotifications(decodedToken?.fileSafe);

    const { deleteTransfer, markForDeletion, isMarkingForDeletion } =
        useFileTransfer();

    const navigate = useNavigate();

    const handleDelete = async () => {
        if (!id || !decodedToken?.role) {
            errorToast("Could not find transfer details. Please try again.");
            return;
        }

        const response = await deleteTransfer(id, decodedToken.role);
        if (!response.success) {
            errorToast("Error deleting file transfer, please try again later.");
        } else {
            navigate(appPaths.adminFileSafe);
        }
    };

    const handleFlagForDeletion = async () => {
        if (!id || !decodedToken?.role) {
            errorToast("Could not find transfer details. Please try again.");
            return;
        }

        const formData = new FormData();
        formData.append("Status", TransferStatus.MarkedForDeletion);

        const response = await markForDeletion(id, formData, decodedToken.role);
        if (!response.success) {
            errorToast("Error flagging for deletion, please try again later.");
        } else {
            successToast("Succesfully flagged for deletion");
            refetchTransferDetails();
            refetchNotificationCount();
        }
    };

    const handleAllFilesDownload = async (
        setIsDownloading: (value: boolean) => void
    ) => {
        setIsDownloading(true);

        if (!transferData?.fileTransferId) return;
        const response = await getTransferredFiles(
            transferData?.fileTransferId
        );

        if (!response.success) {
            errorToast("Failed to fetch all attachments.");
            return;
        }

        downloadFromArrayBuffer(
            response.content,
            `FileSafeTransfer${transferData.fileTransferId}`
        );

        refetchTransferDetails();
        refetchNotificationCount();
        setIsDownloading(false);
    };

    return (
        <PageLayout
            title={
                transferData
                    ? `File Transfer from ${transferData?.senderUser.firstName} ${transferData?.senderUser.lastName}`
                    : "File transfer"
            }
        >
            <>
                {isLoadingTransfer && <LoadingMessage />}
                {transferError && (
                    <FormMessageBox
                        variant="error"
                        title="Error"
                        message="This transfer doesn't exist."
                    />
                )}
                {transferData && !isLoadingTransfer && (
                    <ViewTransfer
                        data={transferData}
                        handleDelete={handleDelete}
                        handleFlagForDeletion={handleFlagForDeletion}
                        isFlaggingForDeletion={isMarkingForDeletion}
                        handleAllFilesDownload={handleAllFilesDownload}
                    />
                )}
            </>
        </PageLayout>
    );
};

export default AdminViewTransfer;
