import { Dispatch, SetStateAction } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { addYears } from "../../../utils/addYears";
import { formatDateToString } from "../../../utils/formatDateToString";
import Button from "../../Button";
import EditableContentBlock from "../../EditableContentBlock";
import FormDateInput from "../../FormDateInput";
import FormTextInput from "../../FormTextInput";
import Toggle from "../../Toggle";
import { EditGreyFleetFormValues } from "../EditGreyFleetFormValues";
import GreyFleetFormRadioGroup from "../GreyFleetFormRadioGroup";
import useAuth from "../../../auth";
import { isS2dUser } from "../../../auth/userAccessHelpers";

type EditInsuranceInformationFormProps = {
    initialValues: EditGreyFleetFormValues;
    methods: UseFormReturn<EditGreyFleetFormValues>;
    isLoading: boolean;
    onSubmit: (data: EditGreyFleetFormValues, callback: () => void) => void;
};

const insuranceOptions = [
    {
        id: "Comprehensive",
        label: "Fully Comp",
    },
    {
        id: "ThirdParty",
        label: "Third Party",
    },
];

const businessCoverOptions = [
    {
        id: "true",
        label: "Yes",
    },
    {
        id: "false",
        label: "No",
    },
];

const today = new Date();
const thirtyYearsFromToday = addYears(today, 30);

const EditInsuranceInformationForm = ({
    initialValues,
    methods,
    isLoading,
    onSubmit,
}: EditInsuranceInformationFormProps) => {
    const handleInsuranceTypeChange = (
        value: string,
        onChangeCallback: (value: string) => void
    ) => {
        onChangeCallback(value);
    };

    const handleBusinessCoverChange = (
        value: string,
        onChangeCallback: (value: boolean) => void
    ) => {
        if (value === "true" || value === "false")
            onChangeCallback(value === "true" ? true : false);
    };

    const insuranceExpiryDateVerifiedBySafe2Drive = initialValues.insurance
        ?.insuranceFileCheckedDate
        ? "Yes"
        : "No";

    const handleMarkInsuranceAsVerified = (verified: boolean) => {
        const today = new Date();
        methods.setValue(
            "insurance.insuranceFileCheckedDate",
            verified ? today : null
        );
    };

    const handleFormSubmit = async (
        values: EditGreyFleetFormValues,
        setIsEdit: Dispatch<SetStateAction<boolean>>
    ) => {
        onSubmit(values, () => setIsEdit(false));
    };

    const handleCancel = (setIsEdit: Dispatch<SetStateAction<boolean>>) => {
        methods.reset();
        setIsEdit(false);
    };

    const verifyInsuranceDisabled = initialValues.insurance
        ?.insuranceFileCheckedDate
        ? true
        : !initialValues.insurance?.insuranceCertificate?.fileBase64;

    const { decodedToken } = useAuth();

    return (
        <EditableContentBlock
            title="Insurance Information"
            onClosed={() => methods.reset()}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            <p>
                                <b>Insurance company name:</b>{" "}
                                {initialValues.insurance?.insuranceCompanyName}
                            </p>
                            <p>
                                <b>Insurance start date:</b>{" "}
                                {initialValues.insurance
                                    ?.insuranceStartDate instanceof Date
                                    ? formatDateToString(
                                          initialValues.insurance
                                              ?.insuranceStartDate
                                      )
                                    : ""}
                            </p>
                            <p>
                                <b>Insurance end date:</b>{" "}
                                {initialValues.insurance
                                    ?.insuranceEndDate instanceof Date
                                    ? formatDateToString(
                                          initialValues.insurance
                                              ?.insuranceEndDate
                                      )
                                    : ""}
                            </p>
                            <p>
                                <b>
                                    Insurance expiry date verified by
                                    Safe2Drive:
                                </b>{" "}
                                {insuranceExpiryDateVerifiedBySafe2Drive}
                            </p>
                            <p>
                                <b>Insurance policy number:</b>{" "}
                                {initialValues.insurance?.insurancePolicyNumber}
                            </p>
                            <p>
                                <b>Cover type</b>{" "}
                                {initialValues.insurance?.insuranceType}
                            </p>
                            <p>
                                <b>Business Cover:</b>{" "}
                                {initialValues.insurance?.hasBusinessCover
                                    ? "Yes"
                                    : "No"}
                            </p>
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit((values) => {
                                    handleFormSubmit(values, setIsEdit);
                                })}
                            >
                                <div className="w-full">
                                    <FormTextInput
                                        label="Insurance company name"
                                        required
                                        id="insuranceCompanyName"
                                        placeholder="Enter insurance company name"
                                        error={
                                            methods.formState.errors.insurance
                                                ?.insuranceCompanyName
                                        }
                                        autoFocus
                                        {...methods.register(
                                            "insurance.insuranceCompanyName"
                                        )}
                                    />
                                    <FormTextInput
                                        label="Insurance policy number"
                                        required
                                        id="insurancePolicyNumber"
                                        placeholder="Enter insurance policy number"
                                        error={
                                            methods.formState.errors.insurance
                                                ?.insurancePolicyNumber
                                        }
                                        {...methods.register(
                                            "insurance.insurancePolicyNumber"
                                        )}
                                    />
                                    <Controller
                                        name="insurance.insuranceStartDate"
                                        control={methods.control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormDateInput
                                                id="insuranceStartDate"
                                                label="Insurance Start Date"
                                                required
                                                value={value}
                                                onChange={onChange}
                                                error={
                                                    methods.formState.errors
                                                        .insurance
                                                        ?.insuranceStartDate
                                                }
                                                maxDate={new Date()}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="insurance.insuranceEndDate"
                                        control={methods.control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormDateInput
                                                id="insuranceEndDate"
                                                label="Insurance End Date"
                                                required
                                                value={value}
                                                onChange={onChange}
                                                error={
                                                    methods.formState.errors
                                                        .insurance
                                                        ?.insuranceEndDate
                                                }
                                                maxDate={thirtyYearsFromToday}
                                            />
                                        )}
                                    />
                                    {isS2dUser(decodedToken?.role) && (
                                        <>
                                            <Controller
                                                name="insurance.insuranceFileCheckedDate"
                                                control={methods.control}
                                                render={({
                                                    field: { value },
                                                }) => (
                                                    <Toggle
                                                        text="Mark insurance as verified"
                                                        value={!!value}
                                                        onChange={
                                                            handleMarkInsuranceAsVerified
                                                        }
                                                        disabled={
                                                            verifyInsuranceDisabled
                                                        }
                                                    />
                                                )}
                                            />
                                        </>
                                    )}
                                    <h2 className="pt-2 text-sm">
                                        Type of insurance
                                    </h2>
                                    <div className="flex">
                                        <Controller
                                            name="insurance.insuranceType"
                                            control={methods.control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <GreyFleetFormRadioGroup
                                                    value={value}
                                                    options={insuranceOptions}
                                                    error={
                                                        methods.formState.errors
                                                            .insurance
                                                            ?.insuranceType
                                                    }
                                                    onChange={(value) =>
                                                        handleInsuranceTypeChange(
                                                            value,
                                                            onChange
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                    <h2 className="text-sm">Business cover</h2>
                                    <div className="flex">
                                        <Controller
                                            name="insurance.hasBusinessCover"
                                            control={methods.control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <GreyFleetFormRadioGroup
                                                    value={value?.toString()}
                                                    options={
                                                        businessCoverOptions
                                                    }
                                                    error={
                                                        methods.formState.errors
                                                            .insurance
                                                            ?.hasBusinessCover
                                                    }
                                                    onChange={(value) =>
                                                        handleBusinessCoverChange(
                                                            value,
                                                            onChange
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="mt-4 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => handleCancel(setIsEdit)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" loading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditInsuranceInformationForm;
