import * as Yup from "yup";

export const LogIn2FAFormValidationSchema = {
    userName: Yup.string().required("please enter email address"),
    code: Yup.string().required("please enter the code"),
};

export type LogIn2FAFormValues = {
    userName: string;
    code: string;
};
