import { Scroll } from "phosphor-react";
import { type MandateDetails } from "../../../models/api/admin/mandateDetails";
import { convertDateStringToReadableString } from "../../../utils/convertDateStringToReadableString";
import ActionableStatus from "../../ActionableStatus";
import ActionableStatusTag from "../../ActionableStatus/ActionableStatusTag";
import useDeclarationStatusActions from "./useDeclarationStatusActions";
import { ApiDriverByIdResponse } from "../../../models/api/admin/driver";

export type DeclarationStatusForeignProps = {
    mandateDetails: MandateDetails;
    driverData?: ApiDriverByIdResponse;
    handleScrollToDocumentUpload: () => void;
};

const DeclarationStatusForeign: React.FC<DeclarationStatusForeignProps> = ({
    mandateDetails,
    driverData,
    handleScrollToDocumentUpload,
}) => {
    const { declarationId, cancelledDate, licenceCountryOrigin } =
        mandateDetails;

    const { switchToPaperDeclarationAction } = useDeclarationStatusActions(
        declarationId,
        mandateDetails,
        false,
        handleScrollToDocumentUpload,
        driverData
    );

    return (
        <ActionableStatus
            title="Declaration status"
            theme="grey"
            actionable
            statusIndicators={[<ActionableStatusTag theme="foreignLicence" />]}
            actions={[switchToPaperDeclarationAction]}
            hasEmailAddress={!!driverData?.emailAddress}
            declarationComplete={false}
            licenceCountryOrigin={licenceCountryOrigin ?? undefined}
        >
            <div className="flex items-center">
                <div className="pr-3">
                    <Scroll size={24} weight="duotone" />
                </div>
                <div className="flex flex-col text-sm">
                    {cancelledDate ? (
                        <span>
                            E-Declaration cancelled by user:{" "}
                            {convertDateStringToReadableString(cancelledDate)}
                        </span>
                    ) : (
                        <span>E-Declaration cancelled by user</span>
                    )}

                    {licenceCountryOrigin && (
                        <span>Licence origin: {licenceCountryOrigin}</span>
                    )}
                </div>
            </div>
        </ActionableStatus>
    );
};

export default DeclarationStatusForeign;
