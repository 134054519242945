import { capitalisedPluralTermForCompany } from "../../copy/sharedCopy";
import { type ApiCompanyResponse } from "../../models/api/admin/company";
import { type ApiDepartmentItem } from "../../models/api/admin/department";
import { type ApiParsedFiltersResponse } from "../../models/api/admin/filter";
import { summarizeObjectToString } from "../../utils/summarizeObjectToString";
import { type DriversListFiltersFormValues } from "./DriversListFiltersForm";

export const summariseAppliedFilters = (
    isEdit: boolean,
    companyData?: ApiCompanyResponse[],
    departmentsData?: ApiDepartmentItem[] | undefined,
    appliedFilter?: ApiParsedFiltersResponse,
    selectedFilter?: DriversListFiltersFormValues
) => {
    const findCompanyNameFromId = (id: string) => {
        const company = companyData?.find(
            (company) => company?.companyId === id
        );
        return company?.companyName;
    };

    const findDepartmentNameFromId = (id: string) => {
        const department = departmentsData?.find(
            (department) => department?.departmentId === id
        );
        return department?.departmentName;
    };

    const appliedFilters: string[] = new Array(0);
    const preSelectedFilterSetToUse = isEdit
        ? appliedFilter?.filterDetails
        : selectedFilter;
    if (preSelectedFilterSetToUse)
        for (const [key, value] of Object.entries(preSelectedFilterSetToUse)) {
            if (value !== null && value !== undefined) {
                if (key === "company") {
                    for (const [subKey, subValue] of Object.entries(value)) {
                        if (
                            subKey.includes("companyIds") &&
                            subValue instanceof Array &&
                            subValue.length
                        ) {
                            appliedFilters.push(
                                `${capitalisedPluralTermForCompany}: ${[
                                    subValue.map((val: string) =>
                                        findCompanyNameFromId(val)
                                    ),
                                ].join(", ")}`
                            );
                        } else if (
                            subKey.includes("departmentIds") &&
                            subValue instanceof Array &&
                            subValue.length
                        ) {
                            appliedFilters.push(
                                `Departments: ${[
                                    subValue.map((val: string) =>
                                        findDepartmentNameFromId(val)
                                    ),
                                ].join(", ")}`
                            );
                        } else if (subKey === "companyDashboard") {
                            summarizeObjectToString(subValue, appliedFilters);
                        }
                    }
                } else if (
                    key === "riskProfiles" &&
                    value instanceof Array &&
                    value.length
                ) {
                    appliedFilters.push(`Risk profile: ${value.join(", ")}`);
                } else {
                    summarizeObjectToString(value, appliedFilters);
                }
            }
        }

    return appliedFilters;
};
