import { Link } from "react-router-dom";
import FormMessageBox from "../components/FormMessageBox";
import { appPaths } from "../Routes";

const FourOFourPage: React.FC = () => {
    return (
        <div className="flex h-screen w-screen items-center justify-center">
            <div className="w-full max-w-fit px-4">
                <FormMessageBox
                    variant="neutral"
                    title="Oops"
                    message={
                        <>
                            <div>
                                You are not supposed to be here, please{" "}
                                <Link
                                    to={appPaths.dashboard}
                                    className="font-bold underline"
                                >
                                    click here
                                </Link>{" "}
                                to return to the dashboard.
                            </div>
                        </>
                    }
                />
            </div>
        </div>
    );
};

export default FourOFourPage;
