import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import DialogBox from "../DialogBox";
import FormTextInput from "../FormTextInput";
import Button from "../Button";
import { useEffect } from "react";

type deleteConfirmationForm = {
    confirmationString?: string;
};

const deleteValidation = yup.object().shape({
    confirmationString: yup
        .string()
        .oneOf(["DELETE"], "Insufficient statement provided."),
});

type DeleteConfirmationProps = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    onSubmit: () => void;
    isLoading: boolean;
};

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
    isOpen,
    onClose,
    title,
    onSubmit,
    isLoading,
}) => {
    const { handleSubmit, formState, register, reset } =
        useForm<deleteConfirmationForm>({
            mode: "onSubmit",
            resolver: yupResolver(deleteValidation),
            defaultValues: {
                confirmationString: "",
            },
        });

    // reset form any time we open/close so nothing is carried over
    useEffect(
        () =>
            reset({
                confirmationString: "",
            }),
        [isOpen]
    );

    return (
        <DialogBox isOpen={isOpen} onClose={onClose} title={title} size="large">
            <form
                className="m-6 flex flex-col gap-4"
                onSubmit={handleSubmit(onSubmit)}
            >
                <p>
                    This action is non-reversible. All data held about this
                    {title.includes("driver") ? " driver " : " client "} will be
                    deleted from the platform.
                </p>
                <p>
                    If you still want to proceed, please type the word ‘DELETE’
                    (uppercase) in the input field shown below.{" "}
                </p>
                <FormTextInput
                    required
                    id="confirmationString"
                    error={formState.errors.confirmationString}
                    {...register("confirmationString")}
                />
                <div className="flex flex-row-reverse justify-start gap-4 ">
                    <Button variant="danger" type="submit" loading={isLoading}>
                        Confirm
                    </Button>
                    <Button variant="tertiary" onClick={onClose}>
                        Cancel
                    </Button>
                </div>
            </form>
        </DialogBox>
    );
};

export default DeleteConfirmation;
