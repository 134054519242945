import { ApiGetAllVehiclesResponse } from "../../models/api/admin/vehicle";
import { GetAllVehiclesDto } from "../../models/app/admin/vehicles";
import { useS2DApiFetcher } from "../common/fetching";

const useGetAllVehicles = () =>
    useS2DApiFetcher<
        GetAllVehiclesDto,
        ApiGetAllVehiclesResponse,
        { dto: GetAllVehiclesDto }
    >(
        "POST",
        ({ dto }) => ({
            url: `api/vehicle/GetAll`,
            body: dto,
        }),
        [],
        true
    );

export const useVehicle = () => {
    const [getAllVehicles, isGettingAllVehicles] = useGetAllVehicles();

    return {
        getAllVehicles: async (dto: GetAllVehiclesDto) =>
            await getAllVehicles({ dto }),
        isGettingAllVehicles,
    };
};
