import React from "react";
import { useEffect, useState } from "react";
import useFileDropper from "../../hooks/useFileDropper";
import useFileType from "../../hooks/useFileType";
import { convertFileToDataUrl } from "../../utils/convertFileToDataUrl";
import FormFileUploader from "../FormFileUploader";
import { DocumentBase64 } from "../../models/app/admin/document";
import { validDocumentFileExtensions } from "../../constants/fileExtensions";

type FormDocumentUploadProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "type" | "className" | "onReset"
> & {
    maxFileSizeKb?: number;
    disabled?: boolean;
    onAutoAdd?: () => void;
    handleSetData: (
        documentBase64: string,
        fileType: DocumentBase64["fileType"],
        fileName: string,
        file?: File,
        fileError?: string
    ) => void;
    handleCancelAddDoc: () => void;
    defaultValues?: File[];
    validFileExtensions?: string[];
};

const FormDocumentUpload = React.forwardRef<
    HTMLInputElement,
    FormDocumentUploadProps
>(
    (
        {
            maxFileSizeKb = 1000,
            disabled,
            onAutoAdd,
            handleSetData,
            handleCancelAddDoc,
            defaultValues,
            validFileExtensions = validDocumentFileExtensions,
        },
        ref
    ) => {
        const [isDirty, setIsDirty] = useState(false);
        const [hasDefaultValues, setHasDefaultValues] = useState(false);

        //need a temp file to send to fileUploader
        const [storeFile, setStoreFile] = useState<File[]>([]);

        const { loadFile, fileError } = useFileType({
            fileType: validFileExtensions,
            maxFileSizeKb,
            setIsDirty,
        });

        const handleSetValue = async (file: File) => {
            if (!file) return;
            const documentDataUrl = await convertFileToDataUrl(file);
            const docBase64 = documentDataUrl
                .replace("data:", "")
                .replace(/^.+,/, "");
            const fileNameParts = file.name.split(".");
            const fileExtension = fileNameParts[
                fileNameParts.length - 1
            ].toLocaleLowerCase() as DocumentBase64["fileType"];

            handleSetData(docBase64, fileExtension, file.name, file, fileError);
            setIsDirty(true);
        };

        const { handleDrag, handleDrop, dragActive } = useFileDropper({
            handleSetValue,
            onLoadFile: loadFile,
            setStoreFile,
        });

        const handleReset = () => {
            setIsDirty(false);
            setStoreFile([]);
            handleCancelAddDoc();
        };

        const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
            event
        ) => {
            if (event.target.files) {
                setStoreFile([event.target.files[0]]);
                loadFile(event.target.files[0]);
            }
        };

        //check for a default value, if there is one then convert to file format and send to storeFile
        useEffect(() => {
            if (!hasDefaultValues) {
                if (defaultValues && defaultValues[0]) {
                    setStoreFile(defaultValues);
                    setIsDirty(true);
                }
                setHasDefaultValues(true);
            }
        }, []);

        useEffect(() => {
            handleSetValue(storeFile[0]);
        }, [storeFile]);

        const handleImport = (data: File[]) => {
            if (fileError || data === undefined || !onAutoAdd) return;
            onAutoAdd();
            setIsDirty(false);
            setStoreFile([]);
            handleSetData("", "", "");
        };

        return (
            <>
                <FormFileUploader
                    isDirty={isDirty}
                    error={fileError}
                    file={storeFile}
                    onDrag={handleDrag}
                    onDrop={handleDrop}
                    dragActive={dragActive}
                    onReset={handleReset}
                    label="Drop file here to upload or"
                    onChange={handleChange}
                    disabled={disabled}
                    onAutoAdd={onAutoAdd ? handleImport : undefined}
                    ref={ref}
                />
            </>
        );
    }
);

export default FormDocumentUpload;
