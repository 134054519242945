import { RiskProfiles } from "../../app/admin/driver";

export type DashboardCompanyRiskProfile = RiskProfiles & {
    upperThreshold: number | null;
};

export type ApiDashboardResponse = {
    riskProfile: {
        safe2DriveCount: number;
        mediumRiskCount: number;
        highRiskCount: number;
        disqualifiedCount: number;
        prevDisqualifiedCount: number;
        criticalCount: number;
    };
    companyRiskProfiles: DashboardCompanyRiskProfile[];
    alerts: {
        driverDeclarationAlerts: ApiAlertSummaryResponse;
        professionalDriverAlerts: ApiAlertSummaryResponse;
        licenceCheckAlerts: ApiAlertSummaryResponse;
        greyFleetAlerts: ApiAlertSummaryResponse;
        driverAlerts: ApiAlertSummaryResponse;
    };
    reports: {
        statuses: {
            declarations: {
                complete: number;
                pending: number;
                incomplete: number;
                expired: number;
                foreignLicence: number;
            };
            greyFleet: {
                complete: number;
                incomplete: number;
                expired: number;
            };
        } | null;
        checksCompleted: {
            name: string;
            standard: number;
            manual: number;
            irish: number;
            foreign: number;
            greyFleet: number;
        } | null;
    } | null;
    comparisons: {
        riskCategoryComparison:
            | {
                  name: string;
                  safe2Drive: number;
                  medium: number;
                  high: number;
              }[]
            | null;
        declarationSignupComparison:
            | {
                  name: string;
                  invited: number;
                  completed: number;
                  overdue: number;
              }[]
            | null;
        greyFleetSignupComparison:
            | {
                  name: string;
                  invited: number;
                  completed: number;
                  overdue: number;
              }[]
            | null;
        checksCompletedComparison:
            | {
                  name: string;
                  standard: number;
                  manual: number;
                  irish: number;
                  foreign: number;
                  greyFleet: number;
              }[]
            | null;
    } | null;
};

export type ApiAlertSummaryResponse = {
    immediateAlerts: DriverAlertSummary[] | null;
    upcomingAlerts: DriverAlertSummary[] | null;
};

export type DriverAlertSummary = {
    name: AlertTitle;
    driverCount: number;
};

export enum DriverAlertTitles {
    AddressNonmatch = "Address Nonmatch",
    EntitlementExpired = "Entitlement - Categories Expired",
    HasExpiringEntitlement = "Entitlement - Categories Due To Expire",
    TachocardExpired = "Tachocard Expired",
    HasExpiringTachocard = "Tachocard Due To Expire",
    CpcExpired = "Cpc Expired",
    HasExpiringCpc = "Cpc Due To Expire",
    PhotocardExpired = "Photocard Expired",
    HasExpiringPhotocard = "Photocard Due To Expire",
}

export enum DriverDeclarationAlertTitles {
    ExpiredDeclarations = "Expired Declarations",
    PendingDeclarations = "EDeclarations Requested",
    PaperDeclarationsNotRequested = "Paper Declarations Not Requested",
    PaperDeclarationsRequested = "Paper Declarations Requested",
}

export enum LicenceCheckAlertTitles {
    InvalidLicences = "Expired Photo Cards",
    FailedChecks = "Failed Checks",
    ChecksDue = "Checks Due",
    ChecksScheduled = "Checks Scheduled",
}

export enum GreyFleetAlertTitles {
    hasCompletedInvite = "Grey Fleet Invites Pending",
    hasExpiringMot = "Expiring MOT",
    hasMotExpired = "Expired MOT",
    hasExpiringRoadTax = "Expiring Road Tax",
    hasRoadTaxExpired = "Expired Road Tax",
    hasExpiringInsurancePolicy = "Expiring Insurance Policy",
    hasInsurancePolicyExpired = "Expired Insurance Policy",
    hasInsuranceToUpload = "Insurance Certificate to Upload",
    hasUnverifiedInsurance = "Unverified Insurance",
    hasBreakdownCoverExpiring = "Expiring Breakdown Cover",
    hasNoOrExpiredBreakdownCover = "Expired or No Breakdown Cover",
}

export type AlertTitle =
    | DriverAlertTitles
    | DriverDeclarationAlertTitles
    | LicenceCheckAlertTitles
    | GreyFleetAlertTitles;

export enum DashboardCategories {
    RiskProfiles = "RiskProfiles",
    Drivers = "Drivers",
    ProfessionalDrivers = "ProfessionalDrivers",
    Dvla = "Dvla",
    GreyFleet = "GreyFleet",
    DriverDeclarations = "DriverDeclarations",
    Reports = "Reports",
}
