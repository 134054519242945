import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { type DriversListFiltersFormValues } from "./DriversListFiltersFormValues";
import FormTextInput from "../../FormTextInput";
import FormDateInput from "../../FormDateInput";
import { type Dispatch, type SetStateAction } from "react";
import { generateSelectedValues } from "./generateSelectedValues";
import AccordionItem from "../../AccordionItem";
import useAuth from "../../../auth";
import {
    hasClientManagementAccess,
    isS2dAdminOrMember,
} from "../../../auth/userAccessHelpers";
import FormCheckbox from "../../FormCheckbox";

type FilterPersonalInformationProps = {
    methods: UseFormReturn<DriversListFiltersFormValues>;
    selectedValues: string[];
    setSelectedValues: Dispatch<SetStateAction<string[]>>;
};
const FilterPersonalInformation: React.FC<FilterPersonalInformationProps> = ({
    methods,
    selectedValues,
    setSelectedValues,
}) => {
    const { decodedToken } = useAuth();
    // Commenting out for the time being
    // const clientManagementAccess = hasClientManagementAccess(decodedToken);
    const s2dAdminOrMember = isS2dAdminOrMember(decodedToken);
    const canViewDeletedDrivers = s2dAdminOrMember;
    // Check if "Deleted: true" exists in selectedValues and if the value is true
    const isDeletedChecked = selectedValues.some(
        (value) => value === "Deleted: true"
    );
    return (
        <AccordionItem
            title="Personal information"
            valueSummary={selectedValues}
            modalStyling
        >
            <>
                <FormTextInput
                    label="First name contains"
                    id="firstName"
                    placeholder="Enter text"
                    error={
                        methods.formState.errors.personalInformation?.firstName
                    }
                    {...methods.register("personalInformation.firstName")}
                    onBlur={(e) =>
                        generateSelectedValues(
                            selectedValues,
                            setSelectedValues,
                            "First name contains",
                            e.target.value
                        )
                    }
                />
                <FormTextInput
                    label="Last name contains"
                    id="lastName"
                    placeholder="Enter text"
                    error={
                        methods.formState.errors.personalInformation?.lastName
                    }
                    {...methods.register("personalInformation.lastName")}
                    onBlur={(e) =>
                        generateSelectedValues(
                            selectedValues,
                            setSelectedValues,
                            "Last name contains",
                            e.target.value
                        )
                    }
                />
                <FormTextInput
                    label="Email address contains"
                    id="emailAddressContains"
                    placeholder="Enter text"
                    error={methods.formState.errors.personalInformation?.email}
                    {...methods.register("personalInformation.email")}
                    onBlur={(e) =>
                        generateSelectedValues(
                            selectedValues,
                            setSelectedValues,
                            "Email address contains",
                            e.target.value
                        )
                    }
                />
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <Controller
                        name="personalInformation.dateOfBirthAfter"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDateInput
                                id="dateOfBirthAfter"
                                label="Date of Birth is After"
                                value={value}
                                onChange={onChange}
                                error={
                                    methods.formState.errors.personalInformation
                                        ?.dateOfBirthAfter
                                }
                                maxDate={new Date()}
                                onBlur={(e) => {
                                    generateSelectedValues(
                                        selectedValues,
                                        setSelectedValues,
                                        "Date of birth after",
                                        e
                                    );
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="personalInformation.dateOfBirthBefore"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDateInput
                                id="dateOfBirthBefore"
                                label="Date of Birth is Before"
                                value={value}
                                onChange={onChange}
                                error={
                                    methods.formState.errors.personalInformation
                                        ?.dateOfBirthBefore
                                }
                                maxDate={new Date()}
                                onBlur={(e) =>
                                    generateSelectedValues(
                                        selectedValues,
                                        setSelectedValues,
                                        "Date of birth before",
                                        e
                                    )
                                }
                            />
                        )}
                    />
                </div>
                {canViewDeletedDrivers && (
                    <FormCheckbox
                        id="deleted"
                        label="Deleted Drivers"
                        {...methods.register("personalInformation.deleted")}
                        error={
                            methods.formState.errors.personalInformation
                                ?.deleted
                        }
                        defaultChecked={isDeletedChecked}
                        inline
                    />
                )}
            </>
        </AccordionItem>
    );
};

export default FilterPersonalInformation;
