import { useNavigate, useSearchParams } from "react-router-dom";
import { useFileTransfer } from "../../api/admin/fileSafe";
import NewTransferForm from "../../components/NewTransferForm";
import PageLayout from "../../components/PageLayout";
import { NewTransferDto } from "../../models/app/admin/fileSafe";
import { errorToast, successToast } from "../../toast";
import { appPaths } from "../../Routes";
import useAuth from "../../auth/useAuth";
import { FileTransferUser } from "../../models/api/admin/fileSafe";

const AdminNewTransfer = () => {
    const [searchParams] = useSearchParams();
    const recipientString = searchParams.get("Recipient");
    const recipient: FileTransferUser | null = recipientString
        ? JSON.parse(recipientString)
        : null;

    const { createNewTransfer, isCreatingNewTransfer } = useFileTransfer();
    const navigate = useNavigate();
    const { decodedToken } = useAuth();

    const handleSubmit = async (data: NewTransferDto) => {
        if (!decodedToken || !decodedToken.role) return;

        const formData = new FormData();
        formData.append("RecipientUserId", data.recipient);
        data.files.map((file) => formData.append("Files", file));
        formData.append("Notes", data.notes);

        const response = await createNewTransfer(formData, decodedToken.role);

        if (response.success) {
            successToast("New transfer created.");
            navigate(appPaths.adminFileSafe);
        } else {
            errorToast("Error creating transfer.");
        }
    };

    const pageTitle = recipient
        ? `Replying to ${recipient.firstName} ${recipient.lastName}`
        : "New transfer";

    return (
        <PageLayout title={pageTitle}>
            <div className="mx-auto w-full max-w-3xl">
                {decodedToken?.role && (
                    <NewTransferForm
                        onSubmit={handleSubmit}
                        isLoading={isCreatingNewTransfer}
                        userRole={decodedToken.role}
                        recipient={recipient}
                    />
                )}
            </div>
        </PageLayout>
    );
};

export default AdminNewTransfer;
