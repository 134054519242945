import { useState } from "react";
import { useAppliedFilterLocalStorage } from "../../hooks/useAppliedFilterLocalStorage";
import { type GetAllDriversDto } from "../../models/app/admin/driver";
import { type FilterGreyFleetDto } from "../../models/app/admin/driverFilters";
import { type FilterType } from "../../models/app/admin/filter";
import { errorToast, successToast } from "../../toast";
import { type DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm";
import { useCompanyDepartmentSelector } from "../ReusableComponents/CompanyDepartmentSelector";
import { driverListFiltersDefaultValues } from "../DriverListFilters/DriversListFiltersForm/driversListFiltersDefaultValues";

export const useGreyFleetAnalysisActions = (
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>,
    tableRef?: React.MutableRefObject<HTMLDivElement | null>
) => {
    const { setAppliedFilter } = useAppliedFilterLocalStorage("greyfleet");
    const defaultVals = driverListFiltersDefaultValues("greyfleet");
    const [isLoading, setIsLoading] = useState(false);

    const { selectedCompany, currentDepartment: selectedDepartment } =
        useCompanyDepartmentSelector();
    const companyDepartmentVals: Pick<GetAllDriversDto, "company"> = {
        company: {
            companyIds: selectedCompany ? [selectedCompany.companyId] : null,
            departmentIds: selectedDepartment
                ? [selectedDepartment.departmentId]
                : null,
            employeeId: null,
            companyDashboard: defaultVals.company.companyDashboard,
        },
    };

    const handleApplyFilter = async (filter: FilterType) => {
        if (!refetchData) return;

        setIsLoading(true);
        setAppliedFilter(filter);
        const success = await refetchData(filter.filterFields, filter.filterId);

        if (success) {
            successToast("Filter applied successfully");
            if (tableRef?.current) {
                window.scrollTo(0, tableRef.current.offsetTop);
            }
        } else {
            errorToast("Failed to apply filter");
        }
        setIsLoading(false);
    };

    const applyGreyFleetQueryFilter = (
        key: keyof FilterGreyFleetDto,
        value: boolean
    ) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                greyFleetQuery: {
                    ...defaultVals.greyFleetQuery,
                    [key]: value,
                },
            },
        };

        handleApplyFilter(filter);
    };

    return {
        applyGreyFleetQueryFilter,
        isLoading,
    };
};
