import EditableContentBlock from "../../EditableContentBlock";
import FormToggle from "../../FormToggle";
import SubmitOrCancelButtonBar from "../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import { EditCompanyFormValues } from "../../EditCompanyForms/EditCompanyFormValues";
import {
    EditCompanySecurityFormValues,
    useCompanySecurityTab,
} from "./useCompanySecurityTab";
import useAuth from "../../../auth";
import { hasClientManagementClaim } from "../../../auth/userAccessHelpers";

type Props = {
    initialValues?: EditCompanyFormValues;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetFormCallback: () => void
    ) => void;
};
export default function CompanySecurityTab({
    initialValues,
    handleEditCompany,
}: Props) {
    const { methods, isLoading } = useCompanySecurityTab(initialValues);

    const { decodedToken } = useAuth();
    const canEdit = hasClientManagementClaim(decodedToken);

    return (
        <EditableContentBlock title="Security Settings" disableEdit={!canEdit}>
            {({ isEdit, setIsEdit }) => {
                const resetAndCloseForm = () => {
                    // Timing out, in-case the hook's useEffect is not triggered
                    // (0ms as the component will then update when its props update)
                    setTimeout(() => (isLoading.current = false), 0);
                    methods.reset();
                    setIsEdit(false);
                };

                const onSubmit = (data: EditCompanySecurityFormValues) => {
                    const partialCompanyData = {
                        settings: { authSettings: { ...data } },
                    };
                    isLoading.current = true;
                    handleEditCompany(partialCompanyData, resetAndCloseForm);
                };
                return (
                    <>
                        <form
                            onSubmit={methods.handleSubmit(onSubmit)}
                            className="flex flex-col gap-6 p-6"
                        >
                            {/* global settings not yet available on api */}
                            {/* <FormToggle
                                onChange={(checked: boolean) =>
                                    methods.setValue("globalSettings", checked)
                                }
                                checked={methods.watch("globalSettings")}
                                name={"globalSettings"}
                                labelText="Allow global security settings"
                                labelSubText="If disabled, security settings will be defined at User level"
                                className={
                                    isEdit
                                        ? "bg-S2D-dark-green-70.1 p-6"
                                        : undefined
                                }
                                disabled={!isEdit}
                            /> */}
                            <FormToggle
                                name={"enforce2FA"}
                                onChange={(checked: boolean) =>
                                    methods.setValue("enforce2FA", checked)
                                }
                                checked={methods.watch("enforce2FA")}
                                labelText="Two factor authentication enabled"
                                className={
                                    isEdit
                                        ? "bg-S2D-dark-green-70.1 p-6"
                                        : undefined
                                }
                                disabled={!isEdit}
                            />
                            {/* SSO not yet available on api */}
                            {/* <FormToggle
                            name={"singleSignOn"}
                            checked={methods.watch("singleSignOn")}
                            onChange={(checked: boolean) =>
                                methods.setValue("singleSignOn", checked)
                            }
                            labelText="Single Sign-On (SSO) enabled"
                            className={
                                isEdit
                                    ? "bg-S2D-dark-green-70.1 p-6"
                                    : undefined
                            }
                            disabled={!isEdit}
                        /> */}
                            {isEdit && (
                                <SubmitOrCancelButtonBar
                                    onCancel={() => setIsEdit(false)}
                                />
                            )}
                        </form>
                    </>
                );
            }}
        </EditableContentBlock>
    );
}
