import { GREAT_BRITAIN, NORTHERN_IRELAND } from "../../constants/countries";
import { BarChartItem } from "../ReusableComponents/Charts/BarChart";
import { PieChartItem } from "../ReusableComponents/Charts/PieChart";
import { StackedBarChartItem } from "../ReusableComponents/Charts/StackedBarChart";
import {
    ApiDriversLicensesStatsResponse,
    LicenceTypeKeys,
} from "../../models/api/admin/driver";
import { useGetDriversLicensesStats } from "../../api/admin/driverStats";
import { useLocation } from "react-router-dom";

const useDriverLicenceCharts = (companyId?: string, departmentId?: string) => {
    const location = useLocation();
    const isProfessional = location.pathname.includes("professional-drivers");

    const { licenceStatsData, isLoadingLicenceStats } =
        useGetDriversLicensesStats(companyId, departmentId, isProfessional);

    const handleValue = (value?: number | undefined) => {
        if (typeof value === "number") {
            return value;
        } else {
            return 0;
        }
    };

    const driversByPoints: BarChartItem[] = [
        {
            name: "0",
            color: "#7A9D00",
            value: handleValue(licenceStatsData?.points["0"]),
        },
        {
            name: "1-3",
            color: "#ADD53A",
            value: handleValue(licenceStatsData?.points["1-3"]),
        },
        {
            name: "4-7",
            color: "#FFE252",
            value: handleValue(licenceStatsData?.points["4-7"]),
        },
        {
            name: "8+",
            color: "#BA1A1A",
            value: handleValue(licenceStatsData?.points["8+"]),
        },
    ];

    const { age } = licenceStatsData ?? {
        age: {} as ApiDriversLicensesStatsResponse["age"],
    };
    // TODO 13/06/23 Would be nice to figure out how to take these hex values from
    // the tailwind config file
    // perhaps move the custom colors into their own file (e.g: ./src/colors)
    // and them import those into tailwind.config.js and here
    const driversByAgeRange: PieChartItem[] = [
        {
            name: "18-21",
            value: handleValue(age["18-21"]),
            color: "#FFE252",
        },
        {
            name: "22-25",
            value: handleValue(age["22-25"]),
            color: "#C8F255",
        },
        {
            name: "26-35",
            value: handleValue(age["26-35"]),
            color: "#7A9D00",
        },
        {
            name: "36-50",
            value: handleValue(age["36-50"]),
            color: "#527F77",
        },
        {
            name: "51-65",
            value: handleValue(age["51-65"]),
            color: "#204E47",
        },
        {
            name: "66+",
            value: handleValue(age["66+"]),
            color: "#BA1A1A",
        },
    ];

    const { endorsements } = licenceStatsData ?? {
        endorsements: {} as ApiDriversLicensesStatsResponse["endorsements"],
    };
    // NB: 19/06/23 backend should (perhaps) only return top 5 endorsements added
    // this month (as bar chart can become cluttered) (Henry is thinking about it)
    const createEndorsementStats = () => {
        const barChartItems: BarChartItem[] = [];
        const colors = ["#92B91C", "#204E47"];

        let index = 0;
        for (const [key, value] of Object.entries(endorsements)) {
            barChartItems.push({
                name: key,
                color: colors[index % 2], // Alternate colors
                value: handleValue(value),
            });
            index++;
        }

        return barChartItems;
    };

    const endorsementCodes: BarChartItem[] = createEndorsementStats();

    const { licenceTypes: types } = licenceStatsData ?? {
        licenceTypes: {} as ApiDriversLicensesStatsResponse["licenceTypes"],
    };
    const createLicenceTypeStats = (): PieChartItem[] => {
        const pieChartItems: PieChartItem[] = [];
        for (const [key, value] of Object.entries(types)) {
            let color;
            switch (key) {
                case LicenceTypeKeys.ValidFullLicence:
                case LicenceTypeKeys.ValidFullLicenceInvitation:
                case LicenceTypeKeys.ValidFullLicenceReAssess:
                    color = "#92B91C";
                    break;
                case LicenceTypeKeys.ValidProvisionalLicence:
                case LicenceTypeKeys.ValidProvisionalLicenceInvitation:
                case LicenceTypeKeys.ValidProvisionalLicenceReAssess:
                    color = "#FFE252";
                    break;
                default:
                    color = "#BA1A1A"; // default color is red
                    break;
            }

            pieChartItems.push({
                name: key,
                color: color,
                value: handleValue(value),
            });
        }

        return pieChartItems;
    };
    const driversByLicenceType = createLicenceTypeStats();

    const { restrictions } = licenceStatsData ?? {
        restrictions: {} as ApiDriversLicensesStatsResponse["restrictions"],
    };
    const createDriverRestrictionStats = (): PieChartItem[] => {
        const pieChartItems: PieChartItem[] = [];
        const colors = ["#92B91C", "#204E47"];

        let index = 0;
        for (const [key, value] of Object.entries(restrictions)) {
            pieChartItems.push({
                name: key,
                color: colors[index % 2], // Alternate colors
                value: handleValue(value),
            });
            index++;
        }
        return pieChartItems;
    };
    const driverRestrictions = createDriverRestrictionStats();

    const { licenceCategories: categories } = licenceStatsData ?? {
        licenceCategories:
            {} as ApiDriversLicensesStatsResponse["licenceCategories"],
    };

    const createLicenceCategoryStats = (): StackedBarChartItem<{
        provisional: number;
        full: number;
    }>[] => {
        const stackedBarChartItems: StackedBarChartItem<{
            provisional: number;
            full: number;
        }>[] = [];

        const allDriverCategories = ["B", "BE", "C1", "C1E", "D1", "D1E"];

        const professionalCategories = ["C", "CE", "D", "DE"];

        for (const [key, value] of Object.entries(categories)) {
            if (isProfessional && professionalCategories.includes(key)) {
                stackedBarChartItems.push({
                    name: key,
                    provisional: handleValue(value.provisionalLicence),
                    full: handleValue(value.fullLicence),
                });
            }
            if (!isProfessional && allDriverCategories.includes(key)) {
                stackedBarChartItems.push({
                    name: key,
                    provisional: handleValue(value.provisionalLicence),
                    full: handleValue(value.fullLicence),
                });
            }
        }
        return stackedBarChartItems;
    };

    const driversByLicenceCategory = createLicenceCategoryStats();

    const { licenceOrigins: origins } = licenceStatsData ?? {
        licenceOrigins: {} as ApiDriversLicensesStatsResponse["licenceOrigins"],
    };

    const driversByLicenceOrigin: PieChartItem[] = [
        {
            name: "UK",
            value: handleValue(origins[GREAT_BRITAIN]),
            color: "#7A9D00",
        },
        {
            name: "Foreign",
            value: handleValue(origins["Foreign"]),
            color: "#BA1A1A",
        },
        {
            name: "N.Irish licences",
            value: handleValue(origins[NORTHERN_IRELAND]),
            color: "#FFE252",
        },
    ];

    const yearsFullLicenceHeld: BarChartItem[] = [
        {
            name: "0-2 years",
            value: handleValue(licenceStatsData?.fullLicenceYears["0-2"]),
            color: "#FFE252",
        },
        {
            name: "3-5 years",
            value: handleValue(licenceStatsData?.fullLicenceYears["3-5"]),
            color: "#C8F255",
        },
        {
            name: "6-10 years",
            value: handleValue(licenceStatsData?.fullLicenceYears["6-10"]),
            color: "#ADD53A",
        },
        {
            name: "11-20 years",
            value: handleValue(licenceStatsData?.fullLicenceYears["11-20"]),
            color: "#92B91C",
        },
        {
            name: "21+ years",
            value: handleValue(licenceStatsData?.fullLicenceYears["21+"]),
            color: "#7A9D00",
        },
    ];

    if (!licenceStatsData) return null;

    return {
        driversByPoints,
        driversByAgeRange,
        endorsementCodes,
        driversByLicenceType,
        driverRestrictions,
        driversByLicenceCategory,
        driversByLicenceOrigin,
        yearsFullLicenceHeld,
        isLoadingLicenceStats,
    };
};

export default useDriverLicenceCharts;
