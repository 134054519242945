import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";

type SideNavItemProps = {
    to: string;
    expanded: boolean;
    children: React.ReactNode;
    bgColor?: "bg-S2D-dark-green-30" | "bg-S2D-dark-green-20";
    disabled?: boolean;
};

const SideNavItem = ({
    to,
    expanded,
    children,
    bgColor = "bg-S2D-dark-green-30",
}: SideNavItemProps) => {
    return (
        <li>
            <NavLink
                to={to}
                className={({ isActive }) =>
                    clsx(
                        `border-1 flex h-14 items-center gap-4 border-b border-S2D-dark-green-20 transition-colors hover:brightness-75`,
                        isActive
                            ? "bg-S2D-light-green-80 font-semibold text-S2D-text-button"
                            : ` ${bgColor} text-S2D-text-menu`,
                        expanded ? "px-6" : "px-6 md:justify-center md:px-0"
                    )
                }
            >
                {children}
            </NavLink>
        </li>
    );
};

export default SideNavItem;
