import useLocalStorage from "../../hooks/useLocalStorage";
import {
    ManualChecksFilters,
    UkDvlaCheckErrorsFilters,
    UkDvlaChecksFilters,
} from "../../models/api/safe2DriveAdmin/dvlaChecks";

export const useDvlaChecksTableFiltersLocalStorage = () => {
    const [ukDvlaChecks, setUkDvlaChecks] = useLocalStorage<
        UkDvlaChecksFilters | undefined
    >(`UkDvlaChecksFilter`, undefined);
    const [ukDvlaCheckErrors, setUkDvlaCheckErrors] = useLocalStorage<
        UkDvlaCheckErrorsFilters | undefined
    >(`UkDvlaCheckErrorsFilter`, undefined);
    const [ukManualChecks, setUkManualChecks] = useLocalStorage<
        ManualChecksFilters | undefined
    >(`UkManualChecksFilter`, undefined);
    const [northernIrishManualChecks, setNorthernIrishManualChecks] =
        useLocalStorage<ManualChecksFilters | undefined>(
            `northernIrishManualChecksFilter`,
            undefined
        );
    const [foreignManualChecks, setForeignManualChecks] = useLocalStorage<
        ManualChecksFilters | undefined
    >(`foreignManualChecksFilter`, undefined);

    return {
        ukDvlaChecks,
        setUkDvlaChecks,
        ukDvlaCheckErrors,
        setUkDvlaCheckErrors,
        ukManualChecks,
        setUkManualChecks,
        northernIrishManualChecks,
        setNorthernIrishManualChecks,
        foreignManualChecks,
        setForeignManualChecks,
    };
};
