import { X, File, DotsThreeOutlineVertical } from "phosphor-react";
import { Button as MuiButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export type FileIconProps = {
    onReset?: React.FormEventHandler<HTMLInputElement> & (() => void);
    fileName: string;
    dataUrl?: string;
    fileObj?: File;
};

const FileIcon: React.FC<FileIconProps> = ({
    onReset,
    fileName,
    dataUrl,
    fileObj,
}) => {
    const fileUrl = fileObj
        ? URL.createObjectURL(fileObj)
        : dataUrl ?? undefined;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="relative flex h-[7.75rem] w-28 flex-col items-center rounded-sm border border-S2D-neutral-80 bg-S2D-neutral-99 p-4">
            <>
                {onReset && !fileUrl && (
                    <X
                        weight="bold"
                        className="absolute top-0 right-0 m-2 h-4 w-4 font-black hover:cursor-pointer"
                        onClick={onReset}
                    />
                )}
                {onReset && fileUrl && (
                    <div className="absolute top-0 right-0 m-2 h-4 w-4 font-black hover:cursor-pointer">
                        <MuiButton
                            id="file-menu-button"
                            aria-controls={open ? "file-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            sx={{
                                minWidth: "1rem",
                                padding: 0,
                            }}
                        >
                            <DotsThreeOutlineVertical
                                size={16}
                                weight="fill"
                                className="text-black"
                            />
                        </MuiButton>
                    </div>
                )}
                <Menu
                    id="file-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "file-menu-button",
                        disablePadding: true,
                    }}
                    disableScrollLock={true}
                >
                    {fileUrl && (
                        <a href={fileUrl} target="_blank">
                            <MenuItem
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "var(--secondary)",
                                    },
                                    height: 48,
                                }}
                            >
                                <div className="pl-3">View</div>
                            </MenuItem>
                        </a>
                    )}
                    {/* download isn't working in a lot of browsers */}
                    {/* <a href={fileUrl} download={fileName}>
                    <MenuItem
                        sx={{
                            "&:hover": { backgroundColor: "var(--secondary)" },
                            height: 48,
                        }}
                    >
                        <div className="pl-3">Download</div>
                    </MenuItem>
                </a> */}
                    {onReset && (
                        <MenuItem
                            onClick={onReset}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "var(--secondary)",
                                },
                                height: 48,
                            }}
                        >
                            <div className="pl-3">Delete</div>
                        </MenuItem>
                    )}
                </Menu>
                <File
                    size="56"
                    className=" text-S2D-dark-green-30"
                    weight="duotone"
                />
                <div className="absolute bottom-0 flex h-10 w-full items-center bg-S2D-light-green-80 p-2">
                    {fileName && (
                        <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-xs font-bold">
                            {fileName}
                        </div>
                    )}
                </div>
            </>
        </div>
    );
};

export default FileIcon;
