import PdfReport from "../ReusableComponents/PdfReport/PdfReport";
import { type ApiCompanyStatsResponse } from "../../models/api/admin/companyStats";
import { companyDashboardContents } from "../CompanyDashboard/companyDashboardContent";
import ReportAnalysisContainer from "../ReusableComponents/PdfReport/ReportAnalysisContainer";

type CompaniesPdfReportProps = {
    companyName?: string;
    departmentName?: string;
    companyStatsData?: ApiCompanyStatsResponse;
    removeSummary?: boolean;
};
export default function CompaniesPdfReport({
    companyName,
    departmentName,
    companyStatsData,
    removeSummary,
}: CompaniesPdfReportProps) {
    const analysisContent = companyDashboardContents(
        companyStatsData,
        undefined,
        undefined,
        removeSummary
    );

    return (
        <PdfReport companyName={companyName} departmentName={departmentName}>
            <div className="flex flex-col">
                {analysisContent &&
                    analysisContent.map(({ title, content }) => (
                        <div key={title}>
                            <ReportAnalysisContainer title={title}>
                                {content}
                            </ReportAnalysisContainer>
                        </div>
                    ))}
            </div>
        </PdfReport>
    );
}
