import React from "react";
import clsx from "clsx";

type UserIconProps = {
    firstName: string;
    lastName?: string;
    size: "small" | "medium" | "large";
};

const UserIcon: React.FC<UserIconProps> = ({ firstName, lastName, size }) => {
    const firstChar = firstName ? firstName.charAt(0) : "-";
    const lastChar = lastName?.charAt(0);

    return (
        <div
            className={clsx(
                "flex min-w-fit flex-none items-center justify-center rounded-full bg-S2D-neutral-30",
                size === "small" && "mr-2 h-8 w-8",
                size === "medium" && "h-10 w-10",
                size === "large" && "mr-8 h-[4.5rem] w-[4.5rem]"
            )}
        >
            <p
                className={clsx(
                    "font-semibold text-white",
                    size === "large" && "text-2xl"
                )}
            >
                {firstChar}
                {lastChar}
            </p>
        </div>
    );
};

export default UserIcon;
