import { SetStateAction, useEffect, useState } from "react";
import SubmitOrCancelButtonBar from "../../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import { type AddInvoiceFormValues } from "./AddInvoiceFormValues";
import useAddInvoiceForm from "./useAddInvoiceForm";
import { successToast } from "../../../../../toast";
import { AddOrEditModalStates } from "../../CompanyBillingTab";
import { AddInvoiceDto } from "../../../../../models/app/admin/invoice";
import { Controller } from "react-hook-form";
import FormDropdown, { FormDropdownOption } from "../../../../FormDropdown";
import FormDateInput from "../../../../FormDateInput";
import { ApiDepartmentItem } from "../../../../../models/api/admin/department";
import { yesNoOptions } from "../../../../../constants/dropdownOptions";
import { ApiInvoiceReferenceNumberItem } from "../../../../../models/api/admin/invoiceReferenceNumber";
import { AppInvoiceReferenceType, AppInvoiceReferenceUsedState } from "../../../../../models/app/admin/invoiceReferenceNumber";
import { useGetInvoiceReferenceNumbers } from "../../../../../api/admin/invoiceReferenceNumber";

export default function AddInvoiceForm({
    companyId,
    // parentId is used to determine if the selected company has a parent company
    parentId,
    departments,
    invoiceReferenceNumbers,
    setModalState,
    refetchInvoices,
    shouldInvoiceParent
}: {
    companyId: string;
    parentId: string | undefined;
    departments: ApiDepartmentItem[];
    invoiceReferenceNumbers: ApiInvoiceReferenceNumberItem[];
    setModalState: (value: SetStateAction<AddOrEditModalStates>) => void;
    refetchInvoices: () => void;
    shouldInvoiceParent: boolean;
}) {
    // TODO: Replace this temporary solution with a new endpoint for the invoice reference dropdown
    // This temporary solution fetches a large amount of data (1,000 records) which will be replaced
    // once the new, slimmed-down endpoint is introduced
    const {
        invoiceReferenceNumbersData,
    } = useGetInvoiceReferenceNumbers(companyId, 1, 1000);

    const { methods, addInvoice, isAddingInvoice } = useAddInvoiceForm();
    const { handleSubmit, formState, setValue } = methods;

    // State to manage whether the parent company is invoiced
    const [isParentInvoiced, setIsParentInvoiced] = useState(shouldInvoiceParent);

    useEffect(() => {
        setIsParentInvoiced(shouldInvoiceParent);
    }, [shouldInvoiceParent]);

    const handleAddInvoice = async (data: AddInvoiceFormValues) => {
        const invoiceDataWithCompanyId: AddInvoiceDto = {
            ...data,
            companyId: companyId
        };

        const response = await addInvoice(invoiceDataWithCompanyId);

        if (response.success) {
            successToast(`Invoice has been added`);
            refetchInvoices();
            setModalState("closed");
        }
    };

    const departmentOptions: FormDropdownOption<string | number | null>[] = [
        { label: "No Department", value: null },
        ...departments.map((department: ApiDepartmentItem) => ({
            label: department.departmentName,
            value: department.departmentId ?? "",
        }))
    ];

    const invoiceReferenceNumberOptions: FormDropdownOption<unknown>[] = [
        { label: "No PO/Reference Number", value: null },
        ...(invoiceReferenceNumbersData?.items
            ? invoiceReferenceNumbersData.items
                .map((invoiceReferenceNumber: ApiInvoiceReferenceNumberItem) => ({
                    label: `${invoiceReferenceNumber.value} (${invoiceReferenceNumber.type === AppInvoiceReferenceType.PurchaseOrder ? "PO #" : "Ref. #"})`,
                    value: invoiceReferenceNumber.value ?? "",
                }))
            : [])
    ];    

    return (
        <form onSubmit={handleSubmit(handleAddInvoice)}>
            <div className="w-full max-w-3xl p-6 pb-8 mx-auto">
                {/* Only show the option to invoice parent company if the company has a parent */}
                {parentId && (
                    <Controller
                        name="isParentInvoiced"
                        control={methods.control}
                        defaultValue={shouldInvoiceParent}
                        render={({ field }) => (
                            <FormDropdown
                                label="Invoice parent?"
                                options={yesNoOptions}
                                value={field.value}
                                onChange={(value) => {
                                    field.onChange(value);
                                    setIsParentInvoiced(value);
                                    // Reset departmentId if 'Invoice client' is set to true
                                    if (value) {
                                        setValue("departmentId", null);
                                    }
                                }}
                                pleaseSelectText="Select Yes or No"
                                error={formState.errors.isParentInvoiced}
                                buttonClassName="h-[48px] border-black bg-S2D-dark-green-70.1"
                            />
                        )}
                    />
                )}
                <Controller
                    name="departmentId"
                    control={methods.control}
                    defaultValue={null} // Default to "No Department"
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Department"
                            options={departmentOptions}
                            value={value ?? null}
                            onChange={onChange}
                            pleaseSelectText="Select a department"
                            error={methods.formState.errors.departmentId}
                            disabled={!!parentId && isParentInvoiced}
                            buttonClassName="h-[48px] border-black bg-S2D-dark-green-70.1"
                        />
                    )}
                />
                <Controller
                    name="reference"
                    control={methods.control}
                    defaultValue={null} // Default to "No PO/Reference Number"
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="PO/Reference Number"
                            options={invoiceReferenceNumberOptions}
                            value={value ?? null}
                            onChange={onChange}
                            pleaseSelectText="Select a PO/reference number"
                            error={methods.formState.errors.reference}
                            buttonClassName="h-[48px] border-black bg-S2D-dark-green-70.1"
                        />
                    )}
                />
                <Controller
                    name="dueDate"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                        <FormDateInput
                            required
                            id="dueDate"
                            label="Due date"
                            value={value}
                            onChange={onChange}
                            error={methods.formState.errors.dueDate}
                            helperText=""
                            adornmentClassName="mt-[0.06rem]"
                        />
                    )}
                />
                <Controller
                    name="invoiceDate"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                        <FormDateInput
                            required
                            id="invoiceDate"
                            label="Invoice date"
                            value={value}
                            onChange={onChange}
                            error={methods.formState.errors.invoiceDate}
                            helperText=""
                            adornmentClassName="mt-[0.06rem]"
                        />
                    )}
                />
            </div>

            <div className="mt-0 rounded-b-sm border-t-[1px] border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6">
                <SubmitOrCancelButtonBar
                    onCancel={() => setModalState("closed")}
                    isLoading={isAddingInvoice}
                    disabled={isAddingInvoice}
                />
            </div>
        </form>
    );
}
