import { useEffect, useRef, useState } from "react";
import {
    useGetAllCompanyStats,
    useGetCompanyStats,
} from "../../api/admin/companyStats";
import { useDownloadComponentToPdf } from "../../hooks/useDownloadComponentToPdf";
import {
    capitalisedTermForCompany,
    pluralTermForCompany,
} from "../../copy/sharedCopy";
import { ApiCompaniesResponse } from "../../models/api/admin/company";

export const useCompanyPdfReports = (companyList?: ApiCompaniesResponse) => {
    const [showPdfReportView, setShowPdfReportView] = useState(false);

    // When downloading a report for all clients
    const reportRef = useRef<HTMLDivElement | null>(null);
    const { generatePDF } = useDownloadComponentToPdf(reportRef);
    const { allCompanyStatsData, isAllCompanyStatsLoading } =
        useGetAllCompanyStats();

    const handleDownloadPdfReport = (): void => {
        setShowPdfReportView(true);

        generatePDF(
            () => setShowPdfReportView(false),
            `All ${pluralTermForCompany} Report`
        );
    };

    // When selecting an individual client from the table
    const [selectedCompanyIdForPdf, setSelectedCompanyIdForPdf] =
        useState<string>();
    const [selectedCompanyNameForPdf, setSelectedCompanyNameForPdf] =
        useState<string>();

    // stop downloading when we reset the company stats
    const [hasDownloaded, setHasDownloaded] = useState(false);
    const {
        companyStatsData: selectedCompanyStatsData,
        isCompanyStatsLoading: isSelectedCompanyStatsLoading,
    } = useGetCompanyStats(selectedCompanyIdForPdf);

    const resetSelectedCompany = () => {
        setSelectedCompanyIdForPdf(undefined);
        setSelectedCompanyNameForPdf(undefined);
        setShowPdfReportView(false);
    };

    // Generate PDF once api has returned the selected company's stats
    useEffect(() => {
        if (
            selectedCompanyStatsData &&
            selectedCompanyIdForPdf &&
            companyList &&
            !hasDownloaded
        ) {
            const selectedCompanyNameFromList = companyList.items.find(
                (company) => company.companyId === selectedCompanyIdForPdf
            )?.companyName;

            setSelectedCompanyNameForPdf(selectedCompanyNameFromList);
            setShowPdfReportView(true);
            generatePDF(
                resetSelectedCompany,
                `${
                    selectedCompanyNameFromList ?? capitalisedTermForCompany
                } Report`
            );
            setHasDownloaded(true);
        }
    }, [selectedCompanyStatsData]);

    return {
        allCompanyStatsData,
        isAllCompanyStatsLoading,
        selectedCompanyStatsData,
        isSelectedCompanyStatsLoading,
        setSelectedCompanyIdForPdf,
        selectedCompanyIdForPdf,
        selectedCompanyNameForPdf,
        showPdfReportView,
        reportRef,
        handleDownloadPdfReport,
    };
};
