import { Warning } from "phosphor-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useInstantEmailAlert } from "../../api/admin/alerts";
import { Alert } from "../../models/api/admin/alert";
import { errorToast, successToast } from "../../toast";
import { convertDateStringToReadableString } from "../../utils/convertDateStringToReadableString";
import ActionableStatus from "../ActionableStatus";
import { StatusAction } from "../ActionableStatus/ActionableStatus";
import InfoStatusIndicator from "./InfoStatusIndicator";
import Divider from "../ReusableComponents/Divider";

type AlertProps = {
    alerts: Alert[] | null;
};

type PageParams = {
    id: string;
};

const alertTypes = {
    AddressNonMatch: "Address non match",
    EntitlementExpired: "Categories expired",
    EntitlementDueToExpire: "Categories due to expire",
    TachocardExpired: "Tachocard expired",
    TachocardDueToExpire: "Tachocard due to expire",
    CpcExpired: "CPC expired",
    CpcDueToExpire: "CPC due to expire",
    PhotocardExpired: "Photocard expired",
    PhotocardDueToExpire: "Photocard due to expire",
};

const getTitle = (alert: Alert) => {
    const alertType = alertTypes[alert.type];
    return alertType;
};

const getTheme = (alert: Alert) => {
    switch (alert.severity) {
        case "Green":
            return "alertGreen";
        case "Amber":
            return "alertAmber";
        case "Red":
            return "alertRed";
    }
};

const getIconColor = (alert: Alert) => {
    switch (alert.severity) {
        case "Green":
            return "#92B91C";
        case "Amber":
            return "#A79000";
        case "Red":
            return "#BA1A1A";
    }
};

const addressNonMatchMessage = `The driver’s address on Safe2Drive’s 
    records doesn’t match the driver’s address on the DVLA records. 
    If scheduled, the platform will send the driver an email with 
    a link to update their DVLA records. Alternatively, manually 
    updating the driver’s address in Safe2Drive’s records will 
    update the status of this alert if both addresses match.`;

const getInfoTag = (alert: Alert) => {
    if (alert.type === "AddressNonMatch") {
        return <InfoStatusIndicator message={addressNonMatchMessage} />;
    }
};

const buildEmailReminderLine = (alert: Alert) => {
    return alert.dateReminderSent ? (
        <div className="text-S2D-neutral-10">
            {`Email reminder sent: ${convertDateStringToReadableString(
                alert.dateReminderSent
            )}`}
        </div>
    ) : alert.dateReminderScheduled ? (
        <div className="text-S2D-neutral-10">
            {`Email reminder scheduled: ${convertDateStringToReadableString(
                alert.dateReminderScheduled
            )}`}
        </div>
    ) : null;
};
//filter alerts going to buildAlertBody to not include categoryexpired/ due to expire
//all categoryExpired/due to expire will be built in buildCategoryAlertBody

const buildAlertBody = (alert: Alert) => {
    switch (alert.type) {
        default:
        case "AddressNonMatch":
            return (
                <>
                    <div className="text-S2D-neutral-10">
                        {`Postcode (DVLA): ${alert.dvlaPostcode ?? "none"}`}
                    </div>
                    <div className="text-S2D-neutral-10">
                        {`Postcode (Safe2Drive): ${
                            alert.safe2DrivePostcode ?? "none"
                        }`}
                    </div>
                    {buildEmailReminderLine(alert)}
                </>
            );
        case "EntitlementExpired":
            return (
                <>
                    {alert.entitlementCodes && (
                        <div className="text-S2D-neutral-10">
                            <div>
                                {alert.entitlementCodes.length > 1
                                    ? `Categories ${alert.entitlementCodes.join(
                                          ", "
                                      )}.`
                                    : `Category ${alert.entitlementCodes[0]}.`}
                            </div>
                            <div>{alert.message}</div>
                        </div>
                    )}
                </>
            );
        case "EntitlementDueToExpire":
            return (
                <>
                    {alert.entitlementCodes && (
                        <div className="text-S2D-neutral-10">
                            <div>
                                {alert.entitlementCodes?.length > 1
                                    ? `Categories ${alert.entitlementCodes?.join(
                                          ", "
                                      )}.`
                                    : `Category ${alert.entitlementCodes[0]}.`}
                            </div>
                            <div>{alert.message}</div>
                        </div>
                    )}
                </>
            );
        case "TachocardExpired":
            return (
                <>
                    <div className="text-S2D-neutral-10">
                        <div>Tachocard {alert.tachocardNumber}</div>
                        <div>{alert.message}</div>
                    </div>
                    {buildEmailReminderLine(alert)}
                </>
            );
        case "TachocardDueToExpire":
            return (
                <>
                    <div className="text-S2D-neutral-10">
                        <div>Tachocard {alert.tachocardNumber}</div>
                        <div>{alert.message}</div>
                    </div>
                    {buildEmailReminderLine(alert)}
                </>
            );
        case "CpcExpired":
            return (
                <>
                    <div className="text-S2D-neutral-10">
                        <div>The driver's CPC has expired.</div>
                        <div>
                            {alert.expiryDate &&
                                `CPC Expired: ${convertDateStringToReadableString(
                                    alert.expiryDate
                                )}`}
                        </div>
                        {buildEmailReminderLine(alert)}
                    </div>
                </>
            );
        case "CpcDueToExpire":
            return (
                <>
                    <div className="text-S2D-neutral-10">
                        <div>The driver's CPC is due to expire.</div>
                        <div>
                            {alert.expiryDate &&
                                `CPC Expires: ${convertDateStringToReadableString(
                                    alert.expiryDate
                                )}`}
                        </div>
                    </div>
                    {buildEmailReminderLine(alert)}
                </>
            );
        case "PhotocardExpired":
            return (
                <>
                    <div className="text-S2D-neutral-10">
                        <div>The driver's photocard has expired.</div>
                        <div>
                            {alert.expiryDate &&
                                `Photocard Expired: ${convertDateStringToReadableString(
                                    alert.expiryDate
                                )}`}
                        </div>
                        {buildEmailReminderLine(alert)}
                    </div>
                </>
            );
        case "PhotocardDueToExpire":
            return (
                <>
                    <div className="text-S2D-neutral-10">
                        <div>The driver's photocard is due to expire.</div>
                        <div>
                            {alert.expiryDate &&
                                `Photocard Expires: ${convertDateStringToReadableString(
                                    alert.expiryDate
                                )}`}
                        </div>
                    </div>
                    {buildEmailReminderLine(alert)}
                </>
            );
    }
};

const Alerts = ({ alerts }: AlertProps) => {
    const { id } = useParams<PageParams>();

    const [instantEmailAlert] = useInstantEmailAlert();
    const [alertBodies, setAlertBodies] = useState<JSX.Element[]>();
    const [alertsArray, setAlertsArray] = useState<Alert[]>();

    const mergeCategoriesIntoOneAlert = () => {
        const categoriesExpired = alerts?.filter(
            (alert) => alert.type === "EntitlementExpired"
        );
        const mergedCategoriesExpired = new Array(0);
        categoriesExpired?.map((category) =>
            mergedCategoriesExpired.push(category.entitlementCode)
        );

        const categoriesDueToExpire = alerts?.filter(
            (alert) => alert.type === "EntitlementDueToExpire"
        );
        const mergedCategoriesDueToExpire = new Array(0);
        categoriesDueToExpire?.map((category) => {
            mergedCategoriesDueToExpire.push(category.entitlementCode);
        });

        const otherAlerts = alerts?.filter(
            (alert) =>
                alert.type !== "EntitlementExpired" &&
                alert.type !== "EntitlementDueToExpire"
        );

        const mergedCategoryAlertsArray = new Array(0);
        otherAlerts?.map((alert) => mergedCategoryAlertsArray.push(alert));
        categoriesExpired?.length &&
            mergedCategoryAlertsArray.push({
                entitlementCodes: mergedCategoriesExpired,
                message: "The drivers licence has expired.",
                severity: "Red",
                type: "EntitlementExpired",
            });
        categoriesDueToExpire?.length &&
            mergedCategoryAlertsArray.push({
                entitlementCodes: mergedCategoriesDueToExpire,
                message: "The drivers licence is due to expire.",
                severity: "Amber",
                type: "EntitlementDueToExpire",
            });
        setAlertsArray(mergedCategoryAlertsArray);
    };

    useEffect(() => {
        mergeCategoriesIntoOneAlert();
    }, [JSON.stringify(alerts)]);

    useEffect(() => {
        const alertBodiesArray: JSX.Element[] = new Array(0);
        alertsArray?.map((alert) =>
            alertBodiesArray.push(buildAlertBody(alert))
        );
        setAlertBodies(alertBodiesArray);
    }, [JSON.stringify(alertsArray)]);

    const createInstantEmailReminderAction = (alert: Alert): StatusAction => {
        const modalButtonAction = async () => {
            if (id) {
                const response = await instantEmailAlert({
                    driverId: id,
                    dto: {
                        alertIds: [alert.type],
                    },
                });

                if (response.success) {
                    successToast("Instant email reminder sent");
                } else {
                    errorToast("Failed to send instant email reminder");
                }
            }
        };

        if (alert.dateReminderSent) {
            return {
                dropdownItem: "sendInstantReminder",
                modalHeading: "Send instant email reminder?",
                modalSubHeading: `An email reminder was sent to the driver on ${convertDateStringToReadableString(
                    alert.dateReminderSent
                )}.`,
                modalButtonText: "Send instant reminder",
                modalButtonAction: modalButtonAction,
            };
        }
        return {
            dropdownItem: "sendInstantReminder",
            modalHeading: "Send instant email reminder?",
            modalSubHeading:
                alert.dateReminderScheduled &&
                `An Email reminder is scheduled to be sent to the driver on ${convertDateStringToReadableString(
                    alert.dateReminderScheduled
                )}.`,
            modalButtonText: "Send instant reminder",
            modalButtonAction: modalButtonAction,
        };
    };

    return (
        <>
            {alertsArray && alertsArray?.length > 0 && (
                <>
                    <Divider text="Alerts" />
                    <div className="grid grid-cols-1 gap-5 pb-12 lg:grid-cols-3">
                        {alertsArray?.map((alert, i) => {
                            return (
                                <ActionableStatus
                                    key={i}
                                    title={getTitle(alert)}
                                    theme={getTheme(alert)}
                                    actionable
                                    infoTag={getInfoTag(alert)}
                                    actions={[
                                        createInstantEmailReminderAction(alert),
                                    ]}
                                >
                                    <div className="flex items-center gap-6">
                                        <Warning
                                            size={24}
                                            weight="duotone"
                                            color={getIconColor(alert)}
                                        />
                                        <div className="text-sm">
                                            {alertBodies ? (
                                                alertBodies[i]
                                            ) : (
                                                <p>...loading</p>
                                            )}
                                        </div>
                                    </div>
                                </ActionableStatus>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
};

export default Alerts;
