import { ApiDashboardResponse } from "../../../models/api/admin/dashboard";
import LineChart from "../../ReusableComponents/Charts/LineChart";

type DashboardLineChartsProps = {
    data?: ApiDashboardResponse;
    loading: boolean;
};

const DashboardLineCharts = ({ data, loading }: DashboardLineChartsProps) => {
    const comparisonData = data?.comparisons;
    return (
        <div className="grid lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 mb-6">
            <div className="w-full mb-6 border border-S2D-neutral-80 rounded p-4 pr-6 pt-6">
                <LineChart
                    title="Drivers by risk categories"
                    ticks={[5, 10, 15, 20, 25, 30]}
                    domain={[0, 30]}
                    keys={[
                        {
                            name: "safe2Drive",
                            colour: "#ADD53A",
                        },
                        {
                            name: "medium",
                            colour: "#FFE252",
                        },
                        {
                            name: "high",
                            colour: "#D25B35",
                        },
                    ]}
                    data={comparisonData?.riskCategoryComparison ?? []}
                />
            </div>
            <div className="w-full mb-6 border border-S2D-neutral-80 rounded p-4 pr-6 pt-6">
                <LineChart
                    title="E-Declaration sign ups"
                    keys={[
                        {
                            name: "invited",
                            colour: "#FFE252",
                        },
                        {
                            name: "completed",
                            colour: "#ADD53A",
                        },
                        {
                            name: "overdue",
                            colour: "#D25B35",
                        },
                    ]}
                    data={comparisonData?.declarationSignupComparison ?? []}
                />
            </div>
            <div className="w-full mb-6 border border-S2D-neutral-80 rounded p-4 pr-6 pt-6">
                <LineChart
                    title="Grey Fleet sign ups"
                    keys={[
                        {
                            name: "invited",
                            colour: "#FFE252",
                        },
                        {
                            name: "completed",
                            colour: "#ADD53A",
                        },
                        {
                            name: "overdue",
                            colour: "#D25B35",
                        },
                    ]}
                    data={comparisonData?.greyFleetSignupComparison ?? []}
                />
            </div>
            <div className="w-full mb-6 border border-S2D-neutral-80 rounded p-4 pr-6 pt-6">
                <LineChart
                    title="Checks completed by type"
                    keys={[
                        {
                            name: "standard",
                            colour: "#ADD53A",
                        },
                        {
                            name: "manual",
                            colour: "#39665F",
                        },
                        {
                            name: "irish",
                            colour: "#3D7ACD",
                        },
                        {
                            name: "foreign",
                            colour: "#8E417D",
                        },
                        {
                            name: "greyFleet",
                            colour: "#D25B35",
                        },
                    ]}
                    data={comparisonData?.checksCompletedComparison ?? []}
                />
            </div>
        </div>
    );
};

export default DashboardLineCharts;
