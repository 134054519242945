import { SetStateAction } from "react";
import clsx from "clsx";

type ProfileTabButtonProps = {
    option: string;
    onSelect: (value: SetStateAction<string>) => void;
    selected: string;
};

export const ProfileTabButton: React.FC<ProfileTabButtonProps> = ({
    option,
    onSelect,
    selected,
}) => {
    return (
        <>
            <button
                className={clsx(
                    "min-w-max rounded-tl-sm rounded-tr-sm py-3 px-4 font-bold",
                    selected === option
                        ? "border-b-4  border-S2D-light-green-80 bg-S2D-dark-green-30 text-S2D-text-menu"
                        : "text-S2D-neutral-40"
                )}
                onClick={() => onSelect(option)}
            >
                {option}
            </button>
        </>
    );
};

export default ProfileTabButton;
