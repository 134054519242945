export type AddInvoiceReferenceNumberValues = {
    departmentId: string | null;
    numbers: string[];
    type: string;
};

export type AddInvoiceReferenceNumberDto = {
    referenceNumbers: AddInvoiceReferenceNumberValues;
};

export type EditInvoiceReferenceNumberDto = AddInvoiceReferenceNumberDto;

export enum AppInvoiceReferenceType {
    Reference = "Reference",
    PurchaseOrder = "Purchase Order",
}

export enum AppInvoiceReferenceUsedState {
    Unused = "Unused",
    Used = "Used",
}