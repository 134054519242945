import { EditCompanyFormValues } from "../../../../EditCompanyForms/EditCompanyFormValues";
import EditableContentBlock from "../../../../EditableContentBlock";
import RiskRangeCard from "./RiskRangeCard";
import { Warning, WarningOctagon, Medal } from "phosphor-react";
import SubmitOrCancelButtonBar from "../../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import RiskCategoriesFormFields from "./RiskCategoriesFormFields";
import useEditRiskCategoriesForm, {
    defaultRiskCategories,
} from "./useEditRiskCategoriesForm";
import { defaultCheckFrequencies } from "../EditCheckFrequenciesForm/useEditCheckFrequenciesForm";
import { CheckSettings } from "../../../../../models/api/admin/company";
import { EditRiskCategoriesFormValues } from "./useEditRiskCategoriesForm";

interface EditRiskCategoriesFormProps {
    initialValues: EditCompanyFormValues;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetFormCallback: () => void
    ) => void;
    canEdit: boolean;
}

export default function EditRiskCategoriesForm({
    initialValues,
    handleEditCompany,
    canEdit,
}: EditRiskCategoriesFormProps) {
    const { methods, displayedValues } =
        useEditRiskCategoriesForm(initialValues);

    const resetAndCloseForm = (setIsEdit?: (isEdit: boolean) => void) => {
        methods.reset(displayedValues);
        if (typeof setIsEdit === "function") setIsEdit(false);
    };

    return (
        <EditableContentBlock
            title="Risk categories"
            onClosed={resetAndCloseForm}
            disableEdit={!canEdit}
        >
            {({ isEdit, setIsEdit }) => (
                <div className="p-6">
                    {!isEdit && nonEditableContent()}
                    {isEdit && editableContent(setIsEdit)}
                </div>
            )}
        </EditableContentBlock>
    );

    function constructNewRiskProfiles(data: EditRiskCategoriesFormValues) {
        const { range1, range2, range3 } = data.riskCategory;
        const { range1Name, range2Name, range3Name } = data.riskCategory;

        const existingRiskProfiles = initialValues?.companyRiskProfiles;
        const range1profile = existingRiskProfiles?.find(
            (profile) => profile.severityLevel === 1
        );
        const range2profile = existingRiskProfiles?.find(
            (profile) => profile.severityLevel === 2
        );
        const range3profile = existingRiskProfiles?.find(
            (profile) => profile.severityLevel === 3
        );
        const newRiskProfiles: CheckSettings["companyRiskProfiles"] = [
            {
                severityLevel: 1,
                // NB: Api requires all 4 values be given
                // so giving default checkFrequency/name values if they are not set
                name: range1Name ?? defaultRiskCategories.range1Name,
                checkFrequency:
                    range1profile?.checkFrequency ??
                    defaultCheckFrequencies.range1,
                lowerThreshold: range1,
            },
            {
                severityLevel: 2,
                name: range2Name ?? defaultRiskCategories.range2Name,
                checkFrequency:
                    range2profile?.checkFrequency ??
                    defaultCheckFrequencies.range2,
                lowerThreshold: range2,
            },
            {
                severityLevel: 3,
                name: range3Name ?? defaultRiskCategories.range3Name,
                checkFrequency:
                    range3profile?.checkFrequency ??
                    defaultCheckFrequencies.range3,
                lowerThreshold: range3,
            },
        ];
        return { companyRiskProfiles: newRiskProfiles };
    }

    function editableContent(setIsEdit: (isEdit: boolean) => void) {
        const handleEditRiskCategories = (
            data: EditRiskCategoriesFormValues
        ) => {
            const formattedData = constructNewRiskProfiles(data);
            handleEditCompany(formattedData, () =>
                resetAndCloseForm(setIsEdit)
            );
        };

        return (
            <form onSubmit={methods.handleSubmit(handleEditRiskCategories)}>
                <RiskCategoriesFormFields methods={methods} />
                <div className="pt-7" />
                <SubmitOrCancelButtonBar
                    onCancel={() => resetAndCloseForm(setIsEdit)}
                />
            </form>
        );
    }

    function nonEditableContent() {
        const { range1, range2, range3 } = displayedValues.riskCategory;
        const { range1Name, range2Name, range3Name } =
            displayedValues.riskCategory;

        const rowOneText = (rangeCategory: string) => `RANGE ${rangeCategory}`;
        const rowTwoText = (
            rangeStart: number | null,
            rangeEnd: number | null
        ) => `${rangeStart}-${rangeEnd} POINTS`;
        return (
            <div className="grid max-w-max grid-cols-1 gap-5 lg:grid-cols-2 2xl:grid-cols-3">
                <RiskRangeCard
                    icon={<Medal size={32} weight="duotone" />}
                    rowOneText={rowOneText("1")}
                    rowTwoText={rowTwoText(range1, range2 && range2 - 1)}
                    rangeName={range1Name}
                />
                <RiskRangeCard
                    icon={<Warning size={32} weight="duotone" />}
                    backgroundColour="bg-S2D-warning-90"
                    rowOneText={rowOneText("2")}
                    rowTwoText={rowTwoText(range2, range3 && range3 - 1)}
                    rangeName={range2Name}
                />
                <RiskRangeCard
                    icon={
                        <WarningOctagon
                            size={32}
                            weight="duotone"
                            color="white"
                        />
                    }
                    backgroundColour="bg-S2D-error-40"
                    rowOneText={rowOneText("3")}
                    rowTwoText={`${range3}+ POINTS`}
                    rangeName={range3Name}
                />
            </div>
        );
    }
}
