import { StatusAction } from "../../../ActionableStatus/ActionableStatus";
import { LicenceChecksActions } from "../licenceCheckActions";
import LicenceCheckStatus from "../LicenceCheckStatus";

type CheckedLicenceCheckStatusProps = {
    driverId: string;
    isManual: boolean;
    lastCheckDate: string;
    nextCheckDate: string;
    buildActions: (actionNames: LicenceChecksActions[]) => StatusAction[];
};

const CheckedLicenceCheckStatus = ({
    driverId,
    isManual,
    lastCheckDate,
    nextCheckDate,
    buildActions,
}: CheckedLicenceCheckStatusProps) => {
    const actions = buildActions(
        isManual
            ? [
                  "editCheckDate",
                  "markAsPending",
                  "markAsFailed",
                  "disableFurtherChecks",
                  "changeToAutomatic",
              ]
            : [
                  "editCheckDate",
                  "retryCheck",
                  "changeToManual",
                  "disableFurtherChecks",
              ]
    );

    return (
        <LicenceCheckStatus
            actions={actions}
            theme="completed"
            text="Checked"
            driverId={driverId}
            isManual={isManual}
        >
            <span>Last check: {lastCheckDate}</span>
            <span>Next check: {nextCheckDate}</span>
        </LicenceCheckStatus>
    );
};

export default CheckedLicenceCheckStatus;
