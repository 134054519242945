import { Car } from "phosphor-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDriver } from "../../../api/admin/driver";
import { useGreyFleet } from "../../../api/greyFleet";
import { GreyFleetInvite } from "../../../models/api/admin/greyFleetInvite";
import { errorToast, successToast } from "../../../toast";
import { addDays } from "../../../utils/addDays";
import { convertDateStringToReadableString } from "../../../utils/convertDateStringToReadableString";
import ActionableStatus from "../../ActionableStatus";
import { StatusAction } from "../../ActionableStatus/ActionableStatus";
import ActionableStatusTag from "../../ActionableStatus/ActionableStatusTag";

type GreyFleetPendingProps = {
    greyFleetInvite: GreyFleetInvite;
};

type PageParams = {
    id: string;
};

const GreyFleetPending = ({ greyFleetInvite }: GreyFleetPendingProps) => {
    const {
        greyFleetInviteId,
        invitationSentDate,
        firstReminderScheduledDate,
        secondReminderScheduledDate,
        thirdReminderScheduledDate,
        firstReminderSentDate,
        secondReminderSentDate,
        thirdReminderSentDate,
    } = greyFleetInvite;

    const today = new Date();

    const [line1, setLine1] = useState("");
    const [line2, setLine2] = useState("");
    const [statusIndicators, setStatusIndicators] = useState<JSX.Element[]>([]);
    const [actions, setActions] = useState<StatusAction[]>([]);

    const { id } = useParams<PageParams>();

    const { refetch } = useDriver(id);

    const { incompleteReminder, removeInvite } = useGreyFleet();

    const greyFleetIncompleteEmailReminder: StatusAction = {
        dropdownItem: "greyFleetIncompleteEmailReminder",
        modalSubHeading: "Send grey fleet incomplete email reminder?",
        modalButtonAction: async () => {
            const progress = await incompleteReminder(greyFleetInviteId);

            if (progress.success) {
                successToast("Reminder sent");
                refetch();
            } else {
                errorToast("Something went wrong");
            }
        },
    };

    const greyFleetRemoveInvite: StatusAction = {
        dropdownItem: "greyFleetRemoveInvite",
        modalButtonAction: async () => {
            const progress = await removeInvite(greyFleetInviteId);

            if (progress.success) {
                successToast("Invite removed");
                refetch();
            } else {
                errorToast("Something went wrong");
            }
        },
    };

    useEffect(() => {
        if (invitationSentDate) {
            setLine1(
                `Grey Fleet invitation sent: ${convertDateStringToReadableString(
                    invitationSentDate
                )}`
            );
            setStatusIndicators([<ActionableStatusTag theme="pending" />]);
            setActions([
                greyFleetIncompleteEmailReminder,
                greyFleetRemoveInvite,
            ]);
        }
        if (invitationSentDate && firstReminderScheduledDate) {
            setLine1(
                `Grey Fleet invitation sent: ${convertDateStringToReadableString(
                    invitationSentDate
                )}`
            );
            setLine2(
                `First reminder scheduled: ${convertDateStringToReadableString(
                    firstReminderScheduledDate
                )}`
            );
            setStatusIndicators([<ActionableStatusTag theme="pending" />]);
            setActions([
                greyFleetIncompleteEmailReminder,
                greyFleetRemoveInvite,
            ]);
        }
        if (firstReminderSentDate && secondReminderScheduledDate) {
            setLine1(
                `First reminder sent: ${convertDateStringToReadableString(
                    firstReminderSentDate
                )}`
            );
            setLine2(
                `Second reminder scheduled: ${convertDateStringToReadableString(
                    secondReminderScheduledDate
                )}`
            );
            setStatusIndicators([<ActionableStatusTag theme="pending" />]);
            setActions([
                greyFleetIncompleteEmailReminder,
                greyFleetRemoveInvite,
            ]);
        }
        if (secondReminderSentDate && thirdReminderScheduledDate) {
            setLine1(
                `Second reminder sent: ${convertDateStringToReadableString(
                    secondReminderSentDate
                )}`
            );
            setLine2(
                `Third reminder scheduled: ${convertDateStringToReadableString(
                    thirdReminderScheduledDate
                )}`
            );
            setStatusIndicators([<ActionableStatusTag theme="pending" />]);
            setActions([
                greyFleetIncompleteEmailReminder,
                greyFleetRemoveInvite,
            ]);
        }
        if (
            invitationSentDate &&
            thirdReminderSentDate &&
            today > new Date(thirdReminderSentDate ?? "")
        ) {
            setLine1(
                `Grey Fleet invitation sent: ${convertDateStringToReadableString(
                    invitationSentDate
                )}`
            );
            setLine2(
                `Third reminder sent: ${convertDateStringToReadableString(
                    thirdReminderSentDate
                )}`
            );
            setStatusIndicators([<ActionableStatusTag theme="pending" />]);
            setActions([
                greyFleetIncompleteEmailReminder,
                greyFleetRemoveInvite,
            ]);
        }
        if (
            invitationSentDate &&
            thirdReminderSentDate &&
            today > addDays(new Date(thirdReminderSentDate), 7)
        ) {
            setLine1(
                `Grey Fleet invitation sent: ${convertDateStringToReadableString(
                    invitationSentDate
                )}`
            );
            setLine2(
                `Third reminder sent: ${convertDateStringToReadableString(
                    thirdReminderSentDate
                )}`
            );
            setStatusIndicators([<ActionableStatusTag theme="incomplete" />]);
            setActions([
                greyFleetIncompleteEmailReminder,
                greyFleetRemoveInvite,
            ]);
        }
    }, []);

    return (
        <ActionableStatus
            title="Grey Fleet"
            theme="grey"
            actionable={true}
            statusIndicators={statusIndicators}
            actions={actions}
        >
            <div className="flex items-center">
                <div className="pr-3">
                    <Car size={24} weight="duotone" />
                </div>
                <div className="flex flex-col text-sm">
                    <span>{line1}</span>
                    <span>{line2}</span>
                </div>
            </div>
        </ActionableStatus>
    );
};

export default GreyFleetPending;
