import ApiError from "../../api/common/apiError";
import { AnalysisComponentContentType } from "../ReusableComponents/AnalysisComponent/AnalysisComponent";
import {
    DvlaAnalysisTabs,
    DvlaDriverAnalysisResponse,
} from "../../models/api/admin/dvlaAnalysis";
import {
    DvlaForeignManualChecksAnalysis,
    DvlaNiManualChecksAnalysis,
    DvlaUKManualChecksAnalysis,
    DvlaUkAnalysis,
    DvlaUkErrorsAnalysis,
} from "./DvlaChecksAnalysisTabs";
import { useDvlaCheckAnalysisActions } from "./useDvlaChecksAnalysisActions";

export const dvlaChecksAnalysisContent = (
    data: DvlaDriverAnalysisResponse | undefined,
    error: ApiError | undefined,
    dvlaChecksAnalysisActions?: ReturnType<typeof useDvlaCheckAnalysisActions>,
    isLoadingDvlaChecks?: boolean
) => {
    const analysisContent: AnalysisComponentContentType<DvlaAnalysisTabs>[] = [
        {
            title: DvlaAnalysisTabs.UkDvlaChecks,
            count: data?.ukDvlaChecks?.totalNumberOfAlerts,
            content: (
                <>
                    {!error && (
                        <DvlaUkAnalysis
                            data={data?.ukDvlaChecks}
                            onLinkClick={
                                dvlaChecksAnalysisActions?.ukDvlaCheckAction
                            }
                            isLoading={isLoadingDvlaChecks}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
        {
            title: DvlaAnalysisTabs.UkDvlaCheckErrors,
            count: data?.ukDvlaErrorChecks?.totalNumberOfAlerts,
            content: (
                <>
                    {!error && (
                        <DvlaUkErrorsAnalysis
                            data={data?.ukDvlaErrorChecks}
                            onLinkClick={
                                dvlaChecksAnalysisActions?.ukDvlaCheckErrorsAction
                            }
                            isLoading={isLoadingDvlaChecks}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
        {
            title: DvlaAnalysisTabs.UkManualChecks,
            count: data?.ukManualChecks?.totalNumberOfAlerts,

            content: (
                <>
                    {!error && (
                        <DvlaUKManualChecksAnalysis
                            data={data?.ukManualChecks}
                            onLinkClick={
                                dvlaChecksAnalysisActions?.ukManualCheckAction
                            }
                            isLoading={isLoadingDvlaChecks}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
        {
            title: DvlaAnalysisTabs.NorthernIrishChecks,
            count: data?.northernIrishChecks?.totalNumberOfAlerts,
            content: (
                <>
                    {!error && (
                        <DvlaNiManualChecksAnalysis
                            data={data?.northernIrishChecks}
                            onLinkClick={
                                dvlaChecksAnalysisActions?.northernIrishManualCheckAction
                            }
                            isLoading={isLoadingDvlaChecks}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
        {
            title: DvlaAnalysisTabs.ForeignChecks,
            count: data?.foreignChecks?.totalNumberOfAlerts,
            content: (
                <>
                    {!error && (
                        <DvlaForeignManualChecksAnalysis
                            data={data?.foreignChecks}
                            onLinkClick={
                                dvlaChecksAnalysisActions?.foreignManualCheckAction
                            }
                            isLoading={isLoadingDvlaChecks}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
    ];

    return analysisContent;
};

const ErrorMessage = () => {
    return (
        <div className="rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-4 text-S2D-neutral-10">
            Error fetching analysis data
        </div>
    );
};
