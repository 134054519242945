import {
    PencilSimple,
    XCircle,
    Trash,
    CheckCircle,
    Detective,
} from "phosphor-react";
import { useEffect, useState, useContext } from "react";
import useCompanyUser from "../../../api/admin/companyUser";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {
    ApiCompanyUserResponse,
    ApiCompanyUsersResponse,
} from "../../../models/api/admin/companyUser";
import { errorToast, successToast } from "../../../toast";
import { TableHeader } from "../../ReusableComponents/Table";
import { initCompanyUsersTableHeaders } from "./CompanyUsersTableInit";
import { useUnverifiedUser } from "../../../api/user";
import { UserStatus } from "../../../models/shared/statuses";
import { EditCompanyUserDto } from "../../../models/app/admin/companyUser";
import { TableMenuItem } from "../../ReusableComponents/Table/TableMenu";
import useAuth from "../../../auth";
import {
    canEditUser,
    hasClientManagementClaim,
} from "../../../auth/userAccessHelpers";
import { useNavigate } from "react-router-dom";
import { ImpersonateUserDto } from "../../../models/app/logIn";
import { appPaths } from "../../../Routes";
import { ApiDepartmentItem } from "../../../models/api/admin/department";
import { ApiCompanyResponse } from "../../../models/api/admin/company";
import { AuthContext } from "../../../auth/AuthContext";  // Update the path as necessary

const useCompanyUsersTable = (
    refetchCompanyUsers: () => void,
    usersData?: ApiCompanyUsersResponse,
    companyId?: string
) => {
    const {
        setTempImpersonatedSelectedCompany,
        setTempImpersonatedSelectedDepartment
    } = useContext(AuthContext);

    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "CompanyTableHeaders",
        initCompanyUsersTableHeaders
    );

    const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] =
        useState(false);
    const [disableUserDialogOpen, setDisableUserDialogOpen] = useState(false);
    const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<
        ApiCompanyUserResponse | undefined
    >();
    const [selectedCompany] =
        useLocalStorage<ApiCompanyResponse | null>("SelectedCompany", null);
    const [selectedDepartment] =
        useLocalStorage<ApiDepartmentItem | null>("SelectedDepartment", null);

    const {
        deleteCompanyUser,
        isDeletingCompanyUser,
        editCompanyUser,
        isEditingCompanyUser,
    } = useCompanyUser();

    const handleMenuSelect = (id: string) => {
        const user = usersData?.items.find((user) => user.userId === id);
        setSelectedUser(user);
    };

    const handleDeleteUser = async () => {
        if (!companyId || !selectedUser?.userId) {
            errorToast("Error deleting user");
            return;
        }
        const response = await deleteCompanyUser(
            companyId,
            selectedUser.userId
        );
        if (response.success) {
            setDeleteUserDialogOpen(false);
            refetchCompanyUsers();

            // timeout here as closing both dialogs at once causes an error I've been unable to fix using ids
            setTimeout(() => setEditUserDialogOpen(false), 1000);
        } else {
            errorToast("Error deleting user");
        }
    };

    const handleDisableUser = async (user: ApiCompanyUserResponse) => {
        const status =
            user.status.toLowerCase() === UserStatus.Disabled.toLowerCase()
                ? UserStatus.Live
                : UserStatus.Disabled;
        // destructure as dto requires slightly different object
        const {
            companyId,
            userId,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            lastLoginDate,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            departmentNames,
            roleNames,
            ...rest
        } = user;
        const userWithNewStatus: EditCompanyUserDto = {
            ...rest,
            roleName: roleNames[0],
            status: status,
        };

        const response = await editCompanyUser(
            userWithNewStatus,
            companyId,
            userId
        );

        if (!response.success) {
            errorToast("Failed to edit staff status, please try again later.");
            return;
        }
        successToast("Successfully edited staff status.");
        setDisableUserDialogOpen(false);
        refetchCompanyUsers();
    };

    const { forgottenPassword } = useUnverifiedUser();

    const handleResetPassword = async () => {
        const forgottenPasswordDto = {
            userEmail: selectedUser?.emailAddress ?? "",
        };
        const response = await forgottenPassword(forgottenPasswordDto);

        if (response.success) {
            setResetPasswordDialogOpen(false);
            successToast("Password reset email sent");
        } else {
            errorToast("Error resetting password");
        }
    };

    const { decodedToken, authorizeImpersonatedUser } = useAuth();
    // 29/10/2023 as this is a recent update, current client-admin need to be able to acquire the management claim
    const canEdit =
        canEditUser(decodedToken) || hasClientManagementClaim(decodedToken);

    const navigate = useNavigate();

    const companyUserMenuItems = (
        data: ApiCompanyUserResponse
    ): TableMenuItem[] => {
        const id = data.userId;
        const impersonateUserDetails: ImpersonateUserDto = {
            userName: data.emailAddress,
        };
        return [
            {
                key: "logIn",
                onClick: () => {
                    // Setting the company and department before impersonating
                    setTempImpersonatedSelectedCompany(selectedCompany);
                    setTempImpersonatedSelectedDepartment(selectedDepartment);

                    authorizeImpersonatedUser(impersonateUserDetails, () =>
                        navigate(appPaths.dashboard)
                    );
                },
                icon: <Detective size={20} weight="fill" />,
                text: "Login as user",
            },
            {
                key: "editUser",
                onClick: () => {
                    handleMenuSelect(id);
                    setEditUserDialogOpen(true);
                },
                icon: <PencilSimple size={20} weight="bold" />,
                text: "Edit user",
                isDisabled: !canEdit,
            },
            // 19/09 removed for v1.0 release
            // {
            //     key: "fileTransfer",
            //     // onClick: () => navigate(appPaths.adminEditCompany(id)),
            //     icon: <FilePlus size={20} weight="fill" />,
            //     text: "New file transfer",
            //     isDisabled: !canEdit,
            // },
            // {
            //     key: "download",
            //     // onClick: () => navigate(appPaths.adminEditCompany(id)),
            //     icon: <DownloadSimple size={20} weight="fill" />,
            //     text: "Download user details",
            // },
            {
                key: "disable",
                onClick: () => {
                    handleMenuSelect(id);
                    setDisableUserDialogOpen(true);
                },
                icon:
                    data.status.toLowerCase() !==
                    UserStatus.Disabled.toLowerCase() ? (
                        <XCircle size={20} weight="fill" />
                    ) : (
                        <CheckCircle size={20} weight="fill" />
                    ),
                text: `${
                    data.status.toLowerCase() !==
                    UserStatus.Disabled.toLowerCase()
                        ? "Disable"
                        : "Enable"
                } user`,
                isDisabled: !canEdit,
            },
            {
                key: "delete",
                onClick: () => {
                    handleMenuSelect(id);
                    setDeleteUserDialogOpen(true);
                },
                icon: <Trash size={20} weight="fill" />,
                text: "Delete user",
                isDisabled: !canEdit,
            },
        ];
    };

    // if table data updates while edit modal is open, we need to update the selected user data
    useEffect(() => {
        if (editUserDialogOpen) {
            const selectedUserId = selectedUser?.userId;
            const updatedUser = usersData?.items.find(
                (user) => user.userId === selectedUserId
            );

            setSelectedUser(updatedUser);
        }
    }, [usersData]);

    return {
        tableHeaders,
        setTableHeaders,
        deleteUserDialogOpen,
        setDeleteUserDialogOpen,
        resetPasswordDialogOpen,
        setResetPasswordDialogOpen,
        disableUserDialogOpen,
        setDisableUserDialogOpen,
        editUserDialogOpen,
        setEditUserDialogOpen,
        selectedUser,
        handleDeleteUser,
        handleDisableUser,
        handleResetPassword,
        companyUserMenuItems,
        isDeletingCompanyUser,
        isEditingCompanyUser,
    };
};

export default useCompanyUsersTable;
