import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormTextInput from "../FormTextInput";
import Button from "../Button";
import { useState } from "react";
import FormMessageBox from "../FormMessageBox";
import { LogInFormValidationSchema, LogInFormValues } from "./LogInFormValues";
import { Link } from "react-router-dom";
import { appPaths } from "../../Routes";
import Logo from "../../s2d-dark-logo.svg";

type LogInFormProps = {
    onSubmit: (
        data: LogInFormValues,
        callback: (error: boolean) => void
    ) => Promise<void>;
    isLoading: boolean;
};
const validationSchema = Yup.object().shape(LogInFormValidationSchema);

const LogInForm: React.FC<LogInFormProps> = ({ onSubmit, isLoading }) => {
    const [matchError, setMatchError] = useState(false);

    const { handleSubmit, formState, register } = useForm<LogInFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            userName: "",
            password: "",
        },
    });

    const handleLogIn = (data: LogInFormValues) => {
        onSubmit(data, setMatchError);
    };

    return (
        <div className="h-fit w-full max-w-lg rounded-sm bg-white p-8">
            <form onSubmit={handleSubmit(handleLogIn)}>
                <div className="flex flex-col items-center gap-4 align-top">
                    <img
                        src={Logo}
                        alt="Safe 2 Drive Logo"
                        className="max-w-sm"
                    />
                    <p className="pb-4 font-semibold">LOG IN</p>
                </div>
                {matchError && (
                    <FormMessageBox
                        variant="error"
                        title={"Invalid credentials"}
                        message={
                            "Email and/or password does not match our records"
                        }
                    />
                )}
                <FormTextInput
                    label="Email address"
                    required
                    id="userName"
                    placeholder="Enter email address"
                    error={formState.errors.userName}
                    {...register("userName")}
                />
                <FormTextInput
                    label="Password"
                    type="password"
                    required
                    id="password"
                    placeholder="Enter password"
                    error={formState.errors.password}
                    {...register("password")}
                />
                <div className="mt-8 flex w-full flex-col-reverse justify-end gap-4 md:flex-row">
                    <Link to={appPaths.forgottenPassword} className="w-full">
                        <Button
                            type="button"
                            variant="tertiary"
                            className="w-full min-w-fit grow justify-center md:w-fit md:flex-none"
                        >
                            Forgot password?
                        </Button>
                    </Link>
                    <Button
                        type="submit"
                        loading={isLoading}
                        className="min-w-fit grow justify-center md:flex-none"
                    >
                        Log in
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default LogInForm;
