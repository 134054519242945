import { Divider } from "@mui/material";
import EditableContentBlock from "../../../EditableContentBlock";
import { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { EditCompanyFormValues } from "../../EditCompanyFormValues";
import FormTextInput from "../../../FormTextInput";
import PostcodeAddressLookup from "../../../PostcodeAddressLookup";
import { ApiPostcodeLookupResponse } from "../../../../models/api/postcodeLookup";
import SubmitOrCancelButtonBar from "../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import { ApiCompaniesResponse } from "../../../../models/api/admin/company";
import FormDropdown from "../../../FormDropdown";
import {
    capitalisedTermForCompany,
    clientCopy,
    termForCompany,
} from "../../../../copy/sharedCopy";
import {
    formValues,
    useEditCompanyDetailsForm,
} from "./useEditCompanyDetailsForm";
import { yesNoOptions } from "../../../../constants/dropdownOptions";

interface EditCompanyDetailsFormProps {
    initialValues: EditCompanyFormValues;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetForm: () => void
    ) => void;
    companies: ApiCompaniesResponse | undefined;
    canEdit: boolean;
}

export default function EditCompanyDetailsForm({
    initialValues,
    handleEditCompany,
    companies,
    canEdit,
}: EditCompanyDetailsFormProps) {
    const [selectedAddressValue, setSelectedAddressValue] = useState("");

    const { methods, defaultValues, parentCompanyData, isEditing } =
        useEditCompanyDetailsForm(initialValues);

    const handleAddressSelect = (address: ApiPostcodeLookupResponse) => {
        setSelectedAddressValue(address.summaryline);
        methods.setValue("address", {
            addressLine1: address.addressline1,
            addressLine2: address.addressline2,
            addressLine3: address.addressline3,
            addressLine4: address.addressline4,
            town: address.posttown,
            postcode: address.postcode,
        });
        methods.trigger("address");
    };

    const companyOptions = new Array(0);
    companyOptions.push({
        label: `No Parent ${capitalisedTermForCompany}`,
        value: "", // 03/08/23 API doesn't currently allow removing parent company
    });
    const potentialParentCompanies = companies?.items.filter((company) => {
        const isNotThisCompanysChild =
            company.resellerId !== initialValues.companyId;
        const isNotThisCompany = company.companyId !== initialValues.companyId;
        return isNotThisCompanysChild && isNotThisCompany;
    });

    potentialParentCompanies?.map((company) =>
        companyOptions.push({
            label: company.companyName,
            value: company.companyId,
        })
    );

    const resellerIdValue = methods.watch("resellerId");

    const [hasClientParentSelected, setHasClientParentSelected] =
        useState<boolean>(false);

    useEffect(() => {
        const hasClientParentSelected = !!resellerIdValue;
        setHasClientParentSelected(hasClientParentSelected);
        // Set isDataHidden to false if they select no parent company
        if (!hasClientParentSelected) {
            methods.setValue("settings.privacySettings.isDataHidden", true);
        }
    }, [resellerIdValue]);

    return (
        <EditableContentBlock
            title={`${capitalisedTermForCompany} details`}
            disableEdit={!canEdit}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && nonEditableContent()}
                    {isEdit && editableContent(setIsEdit)}
                </>
            )}
        </EditableContentBlock>
    );

    function editableContent(setIsEdit: (isEdit: boolean) => void) {
        const resetAndCloseForm = () => {
            methods.reset(); //reset form to initial values
            setIsEdit(false);
        };

        const handleFormSubmit = (data: formValues) => {
            handleEditCompany(data, () => resetAndCloseForm());
        };

        const errors = methods.formState.errors;
        return (
            <div className="px-6 pb-4 pt-6">
                <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
                    <div className="flex flex-col space-y-2">
                        <FormTextInput
                            label={clientCopy.clientNameLabel}
                            required
                            id="companyName"
                            placeholder={clientCopy.clientNamePlaceholder}
                            error={errors.companyName?.message}
                            {...methods.register("companyName")}
                        />
                        <Controller
                            name="resellerId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    onChange={onChange}
                                    value={value}
                                    options={companyOptions}
                                    noOptionsText="No parent clients available"
                                    label={`Parent ${capitalisedTermForCompany}`}
                                    pleaseSelectText={
                                        clientCopy.clientParentSelectText
                                    }
                                />
                            )}
                        />
                        {hasClientParentSelected && (
                            <Controller
                                name="settings.privacySettings.isDataHidden"
                                control={methods.control}
                                render={({ field: { value, onChange } }) => (
                                    <FormDropdown
                                        label="Hidden From Parent Company?"
                                        options={yesNoOptions}
                                        value={value}
                                        onChange={onChange}
                                        pleaseSelectText="Please select..."
                                        error={
                                            errors.settings?.privacySettings
                                                ?.isDataHidden
                                        }
                                    />
                                )}
                            />
                        )}
                        <PostcodeAddressLookup
                            value={selectedAddressValue}
                            label={`Enter your postcode and select the ${termForCompany} address`}
                            onSelect={handleAddressSelect}
                        />
                        <FormTextInput
                            label="Address line 1"
                            id="address1"
                            placeholder="Enter address line 1"
                            error={errors.address?.addressLine1}
                            {...methods.register("address.addressLine1")}
                        />
                        <FormTextInput
                            label="Address line 2"
                            id="address2"
                            placeholder="Enter address line 2"
                            error={errors.address?.addressLine2}
                            {...methods.register("address.addressLine2")}
                        />
                        <FormTextInput
                            label="Address line 3"
                            id="address3"
                            placeholder="Enter address line 3"
                            error={errors.address?.addressLine3}
                            {...methods.register("address.addressLine3")}
                        />
                        <FormTextInput
                            label="Address line 4"
                            id="address4"
                            placeholder="Enter address line 3"
                            error={errors.address?.addressLine4}
                            {...methods.register("address.addressLine4")}
                        />
                        <FormTextInput
                            label="City/Town"
                            id="city"
                            placeholder="Enter city/town"
                            error={errors.address?.town}
                            {...methods.register("address.town")}
                        />
                        <FormTextInput
                            label="Postcode"
                            id="postcode"
                            placeholder="Enter postcode"
                            error={errors.address?.postcode}
                            {...methods.register("address.postcode")}
                        />
                        <div className="pt-7" />
                        <SubmitOrCancelButtonBar
                            onCancel={() => resetAndCloseForm()}
                            isLoading={isEditing}
                        />
                    </div>
                </form>
            </div>
        );
    }

    function nonEditableContent() {
        return (
            <div className="space-y-2 p-6">
                <p>
                    <b>{`${capitalisedTermForCompany} name: `}</b>
                    {defaultValues.companyName || "none"}
                </p>

                <p>
                    <b>{`Parent ${termForCompany}: `}</b>

                    {/* TODO 05/04/23 remove conditional if API returns parentCompanyName */}
                    {initialValues.parentCompanyName ||
                        parentCompanyData?.companyName ||
                        "none"}
                </p>

                {initialValues.parentCompanyName ||
                    (parentCompanyData?.companyName && (
                        <p>
                            <b>{"Hidden From Parent Company: "}</b>

                            {
                                initialValues.settings?.privacySettings
                                    ?.isDataHidden ? "Yes": "No"
                            }
                        </p>
                    ))}

                <Divider />

                <p>
                    <b>Address line 1: </b>
                    {defaultValues.address?.addressLine1 || "none"}
                </p>
                <p>
                    <b>Address line 2: </b>
                    {defaultValues.address?.addressLine2 || "none"}
                </p>
                <p>
                    <b>Address line 3: </b>
                    {defaultValues.address?.addressLine3 || "none"}
                </p>
                <p>
                    <b>Address line 4: </b>
                    {defaultValues.address?.addressLine4 || "none"}
                </p>
                <p>
                    <b>City/Town: </b>
                    {defaultValues.address?.town || "none"}
                </p>

                <p>
                    <b>Postcode: </b>
                    {defaultValues.address?.postcode || "none"}
                </p>
            </div>
        );
    }
}
