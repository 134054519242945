import { Car } from "phosphor-react";
import ActionableStatus from "../../ActionableStatus";
import ActionableStatusTag from "../../ActionableStatus/ActionableStatusTag";
import { GreyFleetCommonContentProps } from "./GreyFleet";

const GreyFleetIncompleteContent = ({
    vehicle,
    actions,
}: GreyFleetCommonContentProps) => {
    return (
        <ActionableStatus
            title="Grey Fleet"
            theme="grey"
            actionable={true}
            statusIndicators={[
                <ActionableStatusTag theme="vrm" text={vehicle.vrm} />,
                <ActionableStatusTag theme="incomplete" />,
            ]}
            actions={[...actions]}
        >
            <div className="flex items-center">
                <div className="pr-3">
                    <Car size={24} weight="duotone" />
                </div>
                <div className="flex flex-col text-sm">
                    {!vehicle.insuranceFileUploadDate && (
                        <p>Insurance certificate not uploaded</p>
                    )}
                    {!vehicle.hasBusinessCover && <p>No business cover</p>}
                </div>
            </div>
        </ActionableStatus>
    );
};

export default GreyFleetIncompleteContent;
