import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaKeys, createSchema } from "../../models/shared/schemaKeysHelper";
import {
    DvlaCheckFilters,
    ManualChecksFilters,
    UkDvlaCheckErrorsFilters,
    UkDvlaChecksFilters,
    manualCheckFilterKeys,
    ukDvlaCheckErrorFilterKeys,
    ukDvlaCheckFilterKeys,
} from "../../models/api/safe2DriveAdmin/dvlaChecks";
import { useEffect } from "react";

// company and department filters controlled at top of page rather than in filter modal

const useDvlaChecksFilterForm = (
    dvlaCheckFilters: DvlaChecksFilterFormValues
) => {
    const booleanSchema = yup.boolean().nullable();

    const dvlaChecksFilterSchema: SchemaKeys<DvlaChecksFilterFormValues> = {
        ukDvlaChecks: createSchema<UkDvlaChecksFilters>(
            ukDvlaCheckFilterKeys,
            booleanSchema
        ),
        ukDvlaCheckErrors: createSchema<UkDvlaCheckErrorsFilters>(
            ukDvlaCheckErrorFilterKeys,
            booleanSchema
        ),
        ukManualChecks: createSchema<ManualChecksFilters>(
            manualCheckFilterKeys,
            booleanSchema
        ),
        northernIrishManualChecks: createSchema<ManualChecksFilters>(
            manualCheckFilterKeys,
            booleanSchema
        ),
        foreignManualChecks: createSchema<ManualChecksFilters>(
            manualCheckFilterKeys,
            booleanSchema
        ),
    };

    const validationSchema = yup.object().shape(dvlaChecksFilterSchema);

    const methods = useForm<DvlaChecksFilterFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: dvlaCheckFilters,
    });

    // reset form if filters manipulated elsewhere
    useEffect(() => {
        methods.reset(dvlaCheckFilters);
    }, [JSON.stringify(dvlaCheckFilters)]);

    return { methods };
};

export type DvlaChecksFilterFormValues = Omit<
    DvlaCheckFilters,
    "companyId" | "departmentId" | "search"
>;

export default useDvlaChecksFilterForm;
