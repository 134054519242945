export const getTimezoneOffset = (date: Date) => date.getTimezoneOffset() * 60000;

export const makeLocalAppearUTC = (date: Date | null) => {
    if (!date) return null;
    const dateTime = new Date(date);
    const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
    return utcFromLocal;
};

export const localToUTC = (date: Date | null) => {
    if (!date) return null;
    const utcFromLocal = new Date(date.getTime() - getTimezoneOffset(date));
    return utcFromLocal;
};