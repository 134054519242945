import {
    capitalisedTermForCompany,
    termForCompany,
} from "../../copy/sharedCopy";
import { type CompanyStatusFilters } from "../../models/api/admin/company";
import {
    CompanyStatsTabs,
    type ApiCompanyStatsResponse,
} from "../../models/api/admin/companyStats";
import AnalysisComponent, {
    type AnalysisComponentContentType,
} from "../ReusableComponents/AnalysisComponent/AnalysisComponent";
import { companyDashboardContents } from "./companyDashboardContent";

export type CompanyDashboardProps = {
    companyStatsData?: ApiCompanyStatsResponse;
    tabOption: number;
    setTabOption: (value: number) => void;
    setStatusFilter?: (value: CompanyStatusFilters | null) => void;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
    removeSummary?: boolean;
};

const CompanyDashboard: React.FC<CompanyDashboardProps> = ({
    companyStatsData,
    tabOption,
    setTabOption,
    setStatusFilter,
    tableRef,
    removeSummary,
}) => {
    const companyDashboardTabbedContent: AnalysisComponentContentType<CompanyStatsTabs>[] =
        companyDashboardContents(
            companyStatsData,
            setStatusFilter,
            tableRef,
            removeSummary
        );

    const handleTabSelect = (value: CompanyStatsTabs) => {
        const indexOfKey = Object.values(CompanyStatsTabs).indexOf(
            value as unknown as CompanyStatsTabs
        );
        // client profile does not include "summary" tab so shift index to match content array
        setTabOption(removeSummary ? indexOfKey - 1 : indexOfKey);
    };

    return (
        <section className="mb-6 w-full rounded-sm bg-S2D-dark-green-70.3 p-6">
            <div className="flex flex-col gap-5">
                <h2 className="font-semibold">
                    {capitalisedTermForCompany} dashboard
                </h2>
                <AnalysisComponent
                    analysisContent={companyDashboardTabbedContent}
                    selectedTab={tabOption}
                    onSelect={handleTabSelect}
                />
            </div>
        </section>
    );
};

export default CompanyDashboard;
