import { useForm } from "react-hook-form";
import EditableContentBlock from "../../../EditableContentBlock";

import { EditCompanyFormValues } from "../../../EditCompanyForms/EditCompanyFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanyContact } from "../../../../models/api/admin/company";
import ContactCard from "./ContactCard";
import Button from "../../../Button";
import { useState } from "react";
import { CompanyContactsFormFields } from "./CompanyContactsFormFields";
import { defaultFormValues, validationSchema } from "./formConstants";
import ConfirmationDialog from "../../../ReusableComponents/ConfirmationDialog";
import { errorToast } from "../../../../toast";

interface EditCompanyContactsFormProps {
    contacts: CompanyContact[] | undefined;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetForm: () => void
    ) => void;
    isEditingCompany: boolean;
    refetchCompany: () => void;
    canEdit: boolean;
}

export default function EditCompanyContactsForm({
    contacts,
    handleEditCompany,
    isEditingCompany,
    refetchCompany,
    canEdit,
}: EditCompanyContactsFormProps) {
    const [formOpen, setFormOpen] = useState(false);
    const [contactBeingEdited, setContactBeingEdited] = useState<null | string>(
        null
    );
    const [contactToDelete, setContactToDelete] =
        useState<null | CompanyContact>(null);

    const methods = useForm<CompanyContact>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: defaultFormValues,
    });
    const { reset } = methods;

    function resetAndCloseForm(): void {
        reset(defaultFormValues); //reset form to initial values
        setContactBeingEdited(null);
        setFormOpen(false);
    }

    return (
        <EditableContentBlock
            onClosed={resetAndCloseForm}
            title="Contacts"
            disableEdit={!canEdit}
        >
            {({ isEdit }) => (
                <>
                    {!isEdit && nonEditableContent()}
                    {isEdit && editableContent()}
                </>
            )}
        </EditableContentBlock>
    );

    function nonEditableContent() {
        return (
            <div className="space-y-3 px-6 py-8">
                {contacts?.length ? (
                    contacts.map((contact, i) => (
                        <div key={i}>
                            <ContactCard contact={contact} />
                        </div>
                    ))
                ) : (
                    <div className="max-w-fit rounded-sm bg-white p-3">
                        No contacts added.
                    </div>
                )}
            </div>
        );
    }

    function editableContent() {
        function handleOpenForm(): void {
            setFormOpen(true);
        }

        function toggleEditContact(contact: CompanyContact): void {
            if (contactBeingEdited === contact.contactId) {
                setContactBeingEdited(null);
                setFormOpen(false);
                reset(defaultFormValues);
            } else {
                if (!contact.contactId) {
                    errorToast("Error: This contact has no id.");
                    return;
                }
                setContactBeingEdited(contact.contactId);
                reset(contact);
                setFormOpen(true);
            }
        }

        function handleCloseDeleteFileConfirmation(): void {
            setContactToDelete(null);
        }
        function handleDeleteFileConfirmation(): void {
            const newContactsList = contacts?.filter(
                (contact) => contact.contactId !== contactToDelete?.contactId
            );
            handleEditCompany(
                {
                    settings: {
                        contactSettings: { contacts: newContactsList ?? [] },
                    },
                },
                () => {
                    refetchCompany();
                    setFormOpen(false);
                    reset(defaultFormValues);
                    setContactToDelete(null);
                }
            );
        }

        return (
            <div className="space-y-3 px-6 py-8">
                {contacts?.length ? (
                    contacts.map((contact, i) => (
                        <div key={i}>
                            <ContactCard
                                {...{
                                    contact,
                                    contactBeingEdited,
                                    toggleEditContact,
                                    setContactToDelete,
                                }}
                            />
                        </div>
                    ))
                ) : (
                    <span>No contacts added.</span>
                )}
                {!formOpen && (
                    <div className="pt-3">
                        <Button
                            variant="secondary"
                            type="button"
                            className="w-full justify-center md:w-auto "
                            loading={false}
                            onClick={handleOpenForm}
                        >
                            Add Contact
                        </Button>
                    </div>
                )}
                {formOpen && <AddOrEditContactForm />}
                <ConfirmationDialog
                    open={Boolean(contactToDelete)}
                    title={`Delete contact: ${contactToDelete?.firstName} ${contactToDelete?.lastName}?`}
                    caption="This action can't be reversed."
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Delete"
                    variant="danger"
                    isLoading={false}
                    onClose={handleCloseDeleteFileConfirmation}
                    onConfirm={handleDeleteFileConfirmation}
                />
            </div>
        );
    }

    function AddOrEditContactForm() {
        function handleAddContact(data: CompanyContact): void {
            const newContactsList = [...(contacts ?? []), data];
            handleEditCompany(
                {
                    settings: {
                        contactSettings: { contacts: newContactsList },
                    },
                },
                () => {
                    refetchCompany();
                    setFormOpen(false);
                    reset(defaultFormValues);
                }
            );
        }

        function handleEditContact(data: CompanyContact): void {
            const contactsWithoutContactToEdit = contacts?.filter(
                (contact) => contact.contactId !== contactBeingEdited
            );
            const newContactsList = [
                ...(contactsWithoutContactToEdit ?? []),
                data,
            ];
            handleEditCompany(
                {
                    settings: {
                        contactSettings: { contacts: newContactsList },
                    },
                },
                () => {
                    setContactBeingEdited(null);
                    refetchCompany();
                    reset(defaultFormValues);
                    setFormOpen(false);
                }
            );
        }

        return (
            <div>
                <h3 className="pt-6 pb-3 text-[22px] font-bold">
                    {contactBeingEdited ? "Edit Contact" : "Add Contact"}
                </h3>
                <CompanyContactsFormFields
                    {...{
                        methods,
                        resetAndCloseForm,
                        contactBeingEdited,
                        handleEditContact,
                        handleAddContact,
                        isEditingCompany,
                    }}
                />
            </div>
        );
    }
}
