import { useMemo } from "react";
import {
    type GreyFleetVehicleAnalysisCharts,
    AnalysisChartType,
} from "../../models/app/admin/analysisChart";
import BarChart, { BarChartItem } from "../ReusableComponents/Charts/BarChart";
import useGreyFleetVehicleAnalysisCharts from "../ChartGroups/useGreyFleetVehicleAnalysisCharts";
import VehicleChartAnalysisTable from "./VehicleChartAnalysisTable";
import useVehicleChartAnalysisFilters from "./useVehicleChartAnalysisFilters";
import { FilterOption } from "../ReusableComponents/FilterDropdown";
import VehicleChartAnalysisFilters from "./VehicleChartAnalysisFilters";
import { type ApiCompanyResponse } from "../../models/api/admin/company";
import { type ApiDepartmentItem } from "../../models/api/admin/department";
import { useVehicleChartAnalysisTable } from "./useVehicleChartAnalysisTable";
import { useLocation } from "react-router-dom";

type VehicleChartAnalysisProps = {
    chartType: GreyFleetVehicleAnalysisCharts;
    selectedCompany: ApiCompanyResponse | null;
    selectedDepartment: ApiDepartmentItem | null;
};

const VehicleChartAnalysis: React.FC<VehicleChartAnalysisProps> = ({
    chartType,
    selectedCompany,
    selectedDepartment,
}) => {
    const location = useLocation();
    const {
        vehiclesData,
        fetchVehiclesError,
        setAppliedFilter,
        paginationLocalStorage,
        handleFetchVehicles,
        isFetchingVehicles,
    } = useVehicleChartAnalysisTable(
        selectedCompany,
        selectedDepartment,
        location.pathname
    );

    const greyFleetChartsData = useGreyFleetVehicleAnalysisCharts(
        selectedCompany?.companyId,
        selectedDepartment?.departmentId
    );

    const determineChartData = () => {
        switch (chartType) {
            case AnalysisChartType.Co2Emissions:
                return greyFleetChartsData?.co2Emissions;
            case AnalysisChartType.FuelType:
                return greyFleetChartsData?.fuelTypes;
            case AnalysisChartType.AgeRange:
                return greyFleetChartsData?.ageRange;
            case AnalysisChartType.Manufacturer:
                return greyFleetChartsData?.manufacturerTypes;
            case AnalysisChartType.EuroRatings:
                return greyFleetChartsData?.euroRatings;
            case AnalysisChartType.InsuranceTypes:
                return greyFleetChartsData?.insuranceTypes;
            case AnalysisChartType.BusinessCover:
                return greyFleetChartsData?.businessCover;
            case AnalysisChartType.EngineCapacity:
                return greyFleetChartsData?.engineCapacity;
            default:
                return undefined;
        }
    };

    const data = useMemo(determineChartData, [chartType, greyFleetChartsData]);

    const { handleApplyFilter, selectedDropdownFilter } =
        useVehicleChartAnalysisFilters(
            chartType,
            setAppliedFilter,
            handleFetchVehicles
        );

    // manufacturer filter options are decided by the data appearing in the chart
    const isCustomOption = chartType === AnalysisChartType.Manufacturer;
    const customOptions: FilterOption<string>[] =
        isCustomOption && data
            ? data?.map((item) => ({
                  value: item.name,
                  label: item.name,
              }))
            : [];

    return (
        <div className="flex flex-col gap-4">
            {data && <BarChart data={data as BarChartItem[]} />}
            <VehicleChartAnalysisFilters
                chartType={chartType}
                handleApplyFilter={handleApplyFilter}
                selectedDropdownFilter={selectedDropdownFilter}
                customOptions={customOptions}
            />
            <VehicleChartAnalysisTable
                chartType={chartType}
                vehiclesList={vehiclesData}
                fetchVehiclesError={fetchVehiclesError}
                isFetchingVehicles={isFetchingVehicles}
                setPageNumber={paginationLocalStorage.setPageNumber}
                pageSize={paginationLocalStorage.pageSize}
                setPageSize={paginationLocalStorage.setPageSize}
            />
        </div>
    );
};

export default VehicleChartAnalysis;
