import { Dispatch, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { ApiPostcodeLookupResponse } from "../../../models/api/postcodeLookup";
import Button from "../../Button";
import EditableContentBlock from "../../EditableContentBlock";
import FormTextInput from "../../FormTextInput";
import PostcodeAddressLookup from "../../PostcodeAddressLookup";
import {
    EditDvlaAddressFormValues,
    EditDvlaFormValues,
} from "../EditDvlaFormValues";

export type EditDriverAddressFormProps = {
    initialValues: EditDvlaFormValues;
    methods: UseFormReturn<EditDvlaAddressFormValues>;
    isLoading: boolean;
    onSubmit: (
        data: EditDvlaAddressFormValues,
        callback: () => void
    ) => Promise<void>;
    canEdit: boolean;
};

const EditDriverAddressForm: React.FC<EditDriverAddressFormProps> = ({
    initialValues,
    methods,
    isLoading,
    onSubmit,
    canEdit,
}) => {
    const [selectedAddressValue, setSelectedAddressValue] = useState("");

    const handleAddressSelect = (address: ApiPostcodeLookupResponse) => {
        setSelectedAddressValue(address.summaryline);
        methods.setValue("address", {
            address1: address.addressline1,
            address2: address.addressline2 ?? undefined,
            address3: address.addressline3 ?? undefined,
            address4: address.addressline4 ?? undefined,
            town: address.posttown,
            postcode: address.postcode,
        });
        methods.trigger("address");
    };

    const handleFormSubmit = async (
        values: EditDvlaAddressFormValues,
        setIsEdit: Dispatch<React.SetStateAction<boolean>>
    ) => {
        onSubmit(values, () => setIsEdit(false));
    };

    return (
        <EditableContentBlock title="Driver address" disableEdit={!canEdit}>
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            <p>
                                <b>Address line 1: </b>
                                {initialValues.address?.address1}
                            </p>
                            {initialValues.address?.address2 && (
                                <p>
                                    <b>Address line 2: </b>
                                    {initialValues.address?.address2}
                                </p>
                            )}
                            {initialValues.address?.address3 && (
                                <p>
                                    <b>Address line 3: </b>
                                    {initialValues.address?.address3}
                                </p>
                            )}
                            <p>
                                <b>Town/City: </b> {initialValues.address?.town}
                            </p>
                            <p>
                                <b>Postcode: </b>
                                {initialValues.address?.postcode}
                            </p>
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit((values) =>
                                    handleFormSubmit(values, setIsEdit)
                                )}
                            >
                                <div className="w-full">
                                    <PostcodeAddressLookup
                                        value={selectedAddressValue}
                                        onSelect={handleAddressSelect}
                                        autoFocus
                                    />
                                    <FormTextInput
                                        label="Address line 1"
                                        id="address1"
                                        placeholder="Enter address line 1"
                                        defaultValue={
                                            initialValues.address?.address1 ??
                                            ""
                                        }
                                        error={
                                            methods.formState.errors.address
                                                ?.address1
                                        }
                                        {...methods.register(
                                            "address.address1"
                                        )}
                                        required
                                    />
                                    <FormTextInput
                                        label="Address line 2"
                                        id="address2"
                                        placeholder="Enter address line 2"
                                        defaultValue={
                                            initialValues.address?.address2 ??
                                            ""
                                        }
                                        error={
                                            methods.formState.errors.address
                                                ?.address2
                                        }
                                        {...methods.register(
                                            "address.address2"
                                        )}
                                    />
                                    <FormTextInput
                                        label="Address line 3"
                                        id="address3"
                                        placeholder="Enter address line 3"
                                        defaultValue={
                                            initialValues.address?.address3 ??
                                            ""
                                        }
                                        error={
                                            methods.formState.errors.address
                                                ?.address3
                                        }
                                        {...methods.register(
                                            "address.address3"
                                        )}
                                    />
                                    <FormTextInput
                                        label="City/Town"
                                        id="city"
                                        placeholder="Enter city/town"
                                        defaultValue={
                                            initialValues.address?.town ?? ""
                                        }
                                        error={
                                            methods.formState.errors.address
                                                ?.town
                                        }
                                        {...methods.register("address.town")}
                                        required
                                    />
                                    <FormTextInput
                                        label="Postcode"
                                        id="postcode"
                                        placeholder="Enter postcode"
                                        defaultValue={
                                            initialValues.address?.postcode ??
                                            ""
                                        }
                                        error={
                                            methods.formState.errors.address
                                                ?.postcode
                                        }
                                        {...methods.register(
                                            "address.postcode"
                                        )}
                                        required
                                    />
                                </div>
                                <div className="mt-8 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => {
                                            methods.reset();
                                            setIsEdit(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" loading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditDriverAddressForm;
