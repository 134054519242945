import { CurrencyGbp } from "phosphor-react";
import { UseFormReturn } from "react-hook-form";
import { useFormFieldCollectiveValidation } from "../../hooks/useFormFieldCollectiveValidation";
import AccordionItem from "../AccordionItem";
import FormTextInput from "../FormTextInput";
import { type AddCompanyFormValues } from "./AddCompanyFormValues";

type CompanyCheckPricingFieldsProps = {
    methods: UseFormReturn<AddCompanyFormValues>;
};

const CompanyCheckPricingFields: React.FC<CompanyCheckPricingFieldsProps> = ({
    methods,
}) => {
    const isSectionValid =
        useFormFieldCollectiveValidation<AddCompanyFormValues>(
            ["settings.pricing"],
            methods
        );
    return (
        <AccordionItem title="Check pricing" error={!isSectionValid}>
            <FormTextInput
                label="Licence check price"
                placeholder="00.00"
                type="number"
                min="0"
                step={0.01}
                id="checkPricing.licence"
                //stops number changing on scroll
                onWheel={(e) => e.currentTarget.blur()}
                error={
                    methods.formState.errors.settings?.pricing
                        ?.licenceCheckPrice
                }
                {...methods.register("settings.pricing.licenceCheckPrice")}
                StartAdornmentIcon={CurrencyGbp}
            />
            <FormTextInput
                label="Manual check price"
                placeholder="00.00"
                type="number"
                min="0"
                step={0.01}
                id="checkPricing.manual"
                onWheel={(e) => e.currentTarget.blur()}
                error={
                    methods.formState.errors.settings?.pricing?.manualCheckPrice
                }
                {...methods.register("settings.pricing.manualCheckPrice")}
                StartAdornmentIcon={CurrencyGbp}
            />
            <FormTextInput
                label="Irish licence check price"
                placeholder="00.00"
                type="number"
                min="0"
                step={0.01}
                id="checkPricing.irish"
                onWheel={(e) => e.currentTarget.blur()}
                error={
                    methods.formState.errors.settings?.pricing
                        ?.irishLicenceCheckPrice
                }
                {...methods.register("settings.pricing.irishLicenceCheckPrice")}
                StartAdornmentIcon={CurrencyGbp}
            />
            <FormTextInput
                label="Foreign licence check price"
                placeholder="00.00"
                type="number"
                min="0"
                step={0.01}
                id="checkPricing.foreign"
                onWheel={(e) => e.currentTarget.blur()}
                error={
                    methods.formState.errors.settings?.pricing
                        ?.foreignLicenceCheckPrice
                }
                {...methods.register(
                    "settings.pricing.foreignLicenceCheckPrice"
                )}
                StartAdornmentIcon={CurrencyGbp}
            />
            <FormTextInput
                label="Grey fleet check price"
                placeholder="00.00"
                type="number"
                min="0"
                step={0.01}
                id="checkPricing.greyFleet"
                onWheel={(e) => e.currentTarget.blur()}
                error={
                    methods.formState.errors.settings?.pricing
                        ?.greyFleetCheckPrice
                }
                {...methods.register("settings.pricing.greyFleetCheckPrice")}
                StartAdornmentIcon={CurrencyGbp}
            />
        </AccordionItem>
    );
};

export default CompanyCheckPricingFields;
