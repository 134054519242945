import ApiError from "../../../api/common/apiError";
import { ApiCompanyThemeDto } from "../../../models/api/admin/themes";
import FormMessageBox from "../../FormMessageBox";
import LoadingMessage from "../../ReusableComponents/LoadingMessage/LoadingMessage";

type NonEditableThemeViewProps = {
    themeData?: ApiCompanyThemeDto;
    themeError?: ApiError;
    isLoadingTheme?: boolean;
};

const NonEditableThemeView: React.FC<NonEditableThemeViewProps> = ({
    themeData,
    themeError,
    isLoadingTheme,
}) => {
    return (
        <>
            {isLoadingTheme && <LoadingMessage />}
            {themeError && (
                <FormMessageBox
                    title={"Error loading theme"}
                    message={
                        "There was an error loading this theme, please try again later."
                    }
                    variant={"error"}
                />
            )}
            {themeData && (
                <div className="flex flex-col gap-2">
                    <ColourDescription
                        colour={themeData.primaryColour}
                        title="Primary"
                        number={1}
                    />
                    <div className="my-2 border border-t-0 border-S2D-dark-green-70.3" />
                    <ColourDescription
                        colour={themeData.primaryLightColour}
                        title="Primary light"
                        number={2}
                    />
                    <ColourDescription
                        colour={themeData.primaryLighterColour}
                        title="Primary lighter"
                        number={3}
                    />
                    <ColourDescription
                        colour={themeData.primaryVeryLightColour}
                        title="Primary very light"
                        number={4}
                    />
                    <div className="my-2 border border-t-0 border-S2D-dark-green-70.3" />
                    <ColourDescription
                        colour={themeData.primaryDarkColour}
                        title="Primary dark"
                        number={5}
                    />
                    <ColourDescription
                        colour={themeData.primaryDarkerColour}
                        title="Primary darker"
                        number={6}
                    />
                    <div className="my-2 border border-t-0 border-S2D-dark-green-70.3" />
                    <ColourDescription
                        colour={themeData.secondaryColour}
                        title="Secondary"
                        number={7}
                    />
                    <div className="my-2 border border-t-0 border-S2D-dark-green-70.3" />
                    <ColourDescription
                        colour={themeData.menuTextColour}
                        title="Menu text"
                        number={8}
                    />
                    <ColourDescription
                        colour={themeData.buttonTextColour}
                        title="Button text"
                        number={9}
                    />
                </div>
            )}
        </>
    );
};

type ColourDescriptionProps = {
    colour: string;
    title: string;
    number: number;
};

const ColourDescription = ({
    colour,
    title,
    number,
}: ColourDescriptionProps) => {
    return (
        <div className="flex flex-row  items-center gap-2">
            <ColourDot colour={colour} />
            <p>
                <b>
                    {number} - {title}:
                </b>{" "}
                #{colour}
            </p>
        </div>
    );
};

export const ColourDot = ({ colour }: { colour: string }) => {
    const formatColour = `#${colour.replaceAll("#", "")}`;
    return (
        <svg height="20" width="20">
            <circle
                cx="10"
                cy="10"
                r="8"
                stroke="black"
                strokeWidth="1"
                fill={formatColour}
            />
        </svg>
    );
};

export default NonEditableThemeView;
