import { useEffect } from "react";
import { UseFormWatch, UseFormTrigger, FieldValues } from "react-hook-form";

interface UsePasswordValidationProps<T extends FieldValues> {
  watch: UseFormWatch<T>;
  trigger: UseFormTrigger<T>;
  passwordField: keyof T;
  confirmPasswordField: keyof T;
}

function usePasswordValidation<T extends FieldValues>({ watch, trigger, passwordField, confirmPasswordField }: UsePasswordValidationProps<T>) {
  const passwordValue = watch(passwordField as never);
  const confirmPasswordValue = watch(confirmPasswordField as never);

  useEffect(() => {
    if (passwordValue && passwordValue.length > 0) {
      trigger(passwordField as never);
    }
  }, [passwordValue, passwordField, trigger]);

  useEffect(() => {
    if (confirmPasswordValue && confirmPasswordValue.length > 0) {
      trigger(confirmPasswordField as never);
    }
  }, [confirmPasswordValue, confirmPasswordField, trigger]);
}

export default usePasswordValidation;
