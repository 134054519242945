import { UserRoles } from "../../auth/decodeToken";
import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import { NewTransferDto } from "../../models/app/admin/fileSafe";
import * as yup from "yup";

export const NewTransferValidationSchema = (userRole: UserRoles) =>
    yup.object().shape({
        company: yup.string().when([], {
            is: () =>
                userRole === UserRoles.s2dAdmin ||
                userRole === UserRoles.s2dMember,
            then: yup
                .string()
                .required(`${capitalisedTermForCompany} is required`),
            otherwise: yup.string().transform(() => {
                return "";
            }),
        }),
        recipient: yup.string().required("Recipient is required"),
        notes: yup.string().notRequired(),
        files: yup
            .array()
            .min(1, "At least one file is required")
            .required("At least one file is required"),
    });

export type NewTransferFormValues = NewTransferDto;

export const newTransferFormDefaultValues = {
    company: "",
    recipient: "",
    notes: "",
    files: [],
};
