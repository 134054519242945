import PageLayout from "../../components/PageLayout";
import FormMessageBox from "../../components/FormMessageBox";
import LoadingMessage from "../../components/ReusableComponents/LoadingMessage/LoadingMessage";
import {
    capitalisedPluralTermForCompany,
    capitalisedTermForCompany,
    termForCompany,
} from "../../copy/sharedCopy";
import CompanyFilters from "../../components/CompanyFilters";
import CompanyList from "../../components/CompanyList";
import { appPaths } from "../../Routes";
import CompanyDashboard from "../../components/CompanyDashboard";
import CompanyMonthlyReportSettings from "../../components/CompanyDashboard/CompanyMonthlyReportSettings";
import HeaderOptions, {
    TailoredButton,
} from "../../components/ReusableComponents/HeaderOptions";
import { useAdminCompanies } from "./pageHooks/useAdminCompanies";
import CompaniesPdfReport from "../../components/AdminCompaniesPage/CompaniesPdfReport";
import { useRef, useState } from "react";
import { CompanyListMenuKeys } from "../../components/CompanyList/CompanyListOptions";
import { useCompanyPdfReports } from "../../components/AdminCompaniesPage/useCompanyPdfReports";
import { isS2dAdminOrMember } from "../../auth/userAccessHelpers";
import useAuth from "../../auth";
import { Plus } from "phosphor-react";
import { BulkInvoicesModal } from "../../components/BulkInvoices/BulkInvoicesModal";

const AdminCompanies = () => {
    const {
        companyList,
        companyListError,
        isCompanyListLoading,
        tabbedStatsData,
        tabOption,
        setTabOption,
        statusFilter,
        setStatusFilter,
        searchQuery,
        setSearchQuery,
        query,
        setPageNumber,
        pageSize,
        setPageSize,
        setOrderBy,
        isCsvReportDownloading,
        handleDownloadCsvReport,
        navigate,
    } = useAdminCompanies();

    const {
        allCompanyStatsData,
        selectedCompanyStatsData,
        setSelectedCompanyIdForPdf,
        selectedCompanyIdForPdf,
        selectedCompanyNameForPdf,
        showPdfReportView,
        reportRef,
        handleDownloadPdfReport,
    } = useCompanyPdfReports(companyList);

    const tableRef = useRef(null);

    const handleTableMenuSelect = (id: string, key: CompanyListMenuKeys) => {
        if (key === CompanyListMenuKeys.clientProfile) {
            navigate(appPaths.adminEditCompany(id));
        }
        if (key === CompanyListMenuKeys.clientReport) {
            setSelectedCompanyIdForPdf(id);
        }
    };

    const { decodedToken } = useAuth();
    const s2dAdminOrMember = isS2dAdminOrMember(decodedToken);

    const [isBulkInvoicesOpen, setIsBulkInvoicesOpen] = useState(false);

    const headerButtons: TailoredButton[] = [
        {
            title: `Generate Bulk Invoices`,
            onClick: () => setIsBulkInvoicesOpen(true),
            variant: "secondary",
        },
        {
            title: `Add ${capitalisedTermForCompany}`,
            onClick: () => navigate(appPaths.adminAddCompany),
            icon: Plus,
        },
    ];

    return (
        <LoadingWrapper isLoading={showPdfReportView}>
            <PageLayout
                title={`${capitalisedPluralTermForCompany}`}
                header={false}
            >
                <div className="flex flex-col gap-6">
                    <HeaderOptions
                        handleDownloadCsvReport={handleDownloadCsvReport}
                        handleDownloadPdfReport={handleDownloadPdfReport}
                        isReportDownloading={
                            !!isCsvReportDownloading || showPdfReportView
                        }
                        tailoredButtons={headerButtons}
                    />
                    <CompanyDashboard
                        companyStatsData={tabbedStatsData}
                        tabOption={tabOption}
                        setTabOption={setTabOption}
                        setStatusFilter={setStatusFilter}
                        tableRef={tableRef}
                    />
                    {s2dAdminOrMember && <CompanyMonthlyReportSettings />}
                    <CompanyFilters
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        isCompanyListLoading={isCompanyListLoading}
                    />
                    {companyListError && (
                        <FormMessageBox
                            variant="error"
                            title={`Error loading ${termForCompany} data`}
                            message={`There was an error loading ${termForCompany} data, please try again later.`}
                        />
                    )}
                    {isCompanyListLoading ? (
                        <LoadingMessage />
                    ) : (
                        <CompanyList
                            companyList={companyList}
                            filteredCompanyList={companyList?.items ?? []}
                            setPageNumber={setPageNumber}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            setOrderBy={setOrderBy}
                            filterIsApplied={!!query || !!statusFilter}
                            handleTableMenuSelect={handleTableMenuSelect}
                            tableRef={tableRef}
                        />
                    )}
                    {showPdfReportView && (
                        <div ref={reportRef}>
                            {!selectedCompanyIdForPdf ? (
                                <CompaniesPdfReport
                                    companyStatsData={allCompanyStatsData}
                                />
                            ) : (
                                // Report for a single company selected via TableMenu
                                <CompaniesPdfReport
                                    companyStatsData={selectedCompanyStatsData}
                                    removeSummary={true}
                                    companyName={selectedCompanyNameForPdf}
                                    departmentName={undefined}
                                />
                            )}
                        </div>
                    )}
                    <BulkInvoicesModal
                        isOpen={isBulkInvoicesOpen}
                        onClose={() => setIsBulkInvoicesOpen(false)}
                    />
                </div>
            </PageLayout>
        </LoadingWrapper>
    );
};

const LoadingWrapper = ({
    children,
    isLoading,
}: {
    children: JSX.Element;
    isLoading: boolean;
}) => {
    if (!isLoading) return children;

    return (
        <div className="relative">
            <div className="bg-black-100 absolute inset-0 z-10 cursor-wait bg-gray-500 bg-opacity-50" />
            {children}
        </div>
    );
};

export default AdminCompanies;
