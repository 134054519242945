import { SetStateAction, useState } from "react";
import DialogBox from "../DialogBox";
import EditCompanyUserForm from "./EditCompanyUserForm/EditCompanyUserForm";
import { type EditCompanyUserFormValues } from "./EditCompanyUserFormValues";
import { type ApiCompanyUserResponse } from "../../models/api/admin/companyUser";
import useCompanyUser from "../../api/admin/companyUser";
import { useGetDepartments } from "../../api/admin/department";
import { errorToast, successToast } from "../../toast";
import EditCompanyUserHeader from "./EditCompanyUserHeader";
// import AuditTable from "../AuditTable";
// import {
//     type ApiAuditsResponse,
//     type AuditAction,
// } from "../../models/api/admin/audits";
// import useLocalStorage from "../../hooks/useLocalStorage";
// import { type DateTimeRange } from "../../api/admin/audits";
// import { termForCompany } from "../../copy/sharedCopy";

type EditCompanyUserModalProps = {
    isOpen: boolean;
    setIsOpen: (value: SetStateAction<boolean>) => void;
    refetchCompanyUsers: () => void;
    selectedUser: ApiCompanyUserResponse;
    companyId?: string;
    companyName?: string;
    onDelete: () => void;
    onResetPassword: () => void;
    onDisable: () => void;
};

const EditCompanyUserModal: React.FC<EditCompanyUserModalProps> = ({
    isOpen,
    setIsOpen,
    refetchCompanyUsers,
    selectedUser,
    companyId,
    companyName,
    onDelete,
    onResetPassword,
    onDisable,
}) => {
    const { editCompanyUser, isEditingCompanyUser } = useCompanyUser();

    const handleSubmit = async (
        data: EditCompanyUserFormValues,
        callback?: () => void
    ) => {
        if (!companyId || !selectedUser.userId) {
            errorToast("Error editing user");
            return;
        }
        const response = await editCompanyUser(
            data,
            companyId,
            selectedUser.userId
        );

        if (response.success) {
            successToast("User edited successfully");
            setIsOpen(false);
            refetchCompanyUsers();
            if (callback) callback();
        } 
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const { departmentsData, departmentsError, isLoadingDepartments } =
        useGetDepartments(companyId ?? undefined, 1, 100);

    const departmentOptions = departmentsData?.items.map((department) => ({
        label: department.departmentName,
        value: department.departmentId,
    }));

    const [selectedTab, setSelectedTab] = useState<"edit" | "audit">("edit");
    // 18/09/23 removed company user audits for v1.0 release

    // const [pageSize, setPageSize] = useLocalStorage<number>(
    //     `${termForCompany}UserAuditListPageSize`,
    //     25
    // );
    // const [pageNumber, setPageNumber] = useState<number>(1);

    // // query params
    // const [dateTimeRange, setDateTimeRange] = useState<DateTimeRange>({
    //     from: null,
    //     to: null,
    // });
    // const [userId, setUserId] = useState<string>();
    // TODO add query and action to audits endpoint once implemented in api
    // const [query, setQuery] = useState<string>("");
    // const [actionFilter, setActionFilter] = useState<AuditAction | null>(null);

    // 1/6/23 TODO entity doesn't exist yet

    // const { auditsData, auditsError, isLoadingAudits } = useAudits(
    //     {
    //         entityName: "StaffEntity",
    //         entityId: staffId,
    //         userId: userId,
    //         dateTimeRange: dateTimeRange,
    //         includeRelatedEntities: true,
    //     },
    //     pageNumber,
    //     pageSize
    // );

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={() => {
                setIsOpen(false);
                setSelectedTab("edit");
            }}
            title="Edit user"
            size="xl"
            closeButton
        >
            <div className="md:max-h-screen ">
                <EditCompanyUserHeader
                    initialValues={selectedUser}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                />
                {selectedTab === "edit" && (
                    <EditCompanyUserForm
                        companyName={companyName}
                        isLoading={isEditingCompanyUser}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        isLoadingDepartments={isLoadingDepartments}
                        departmentsError={departmentsError}
                        departmentOptions={departmentOptions}
                        selectedUser={selectedUser}
                        onDelete={onDelete}
                        onResetPassword={onResetPassword}
                        onDisable={onDisable}
                    />
                )}
                {/* {selectedTab === "audit" && (
                    <div className="p-6">
                        <AuditTable
                            auditList={auditsData}
                            setPageNumber={setPageNumber}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            searchQuery={query}
                            setSearchQuery={setQuery}
                            actionFilter={actionFilter}
                            setActionFilter={setActionFilter}
                            dateTimeRange={dateTimeRange}
                            setDateTimeRange={setDateTimeRange}
                            userId={userId}
                            setUserId={setUserId}
                        />
                    </div>
                )} */}
            </div>
        </DialogBox>
    );
};

export default EditCompanyUserModal;
