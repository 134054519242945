import EditableContentBlock from "../../EditableContentBlock";
import {
    EditDvlaEntitlementFormValues,
    EditDvlaFormValues,
} from "../EditDvlaFormValues";
import { categoriesDI4 } from "../Categories/categories";
import CategoriesTable from "../CategoriesTable";
import { DvlaEntitlement } from "../../../models/api/admin/dvla";
import { useState } from "react";
import { errorToast } from "../../../toast";
import Button from "../../Button";
import { UseFormReturn } from "react-hook-form";
import { getEntitlementRestrictionCodes } from "../../../api/admin/dvla";
import { GREAT_BRITAIN, NORTHERN_IRELAND } from "../../../constants/countries";
import EditCategoriesTable from "../EditCategoriesTable";
import { isValidDate } from "../../../utils/isValidDate";
import FormMessageBox from "../../FormMessageBox";

const tableHeaders = [
    "Category",
    "Licence status",
    "Valid from",
    "Valid to",
    "Restrictions",
];

type EditCategoriesFormProps = {
    initialValues: EditDvlaFormValues;
    methods: UseFormReturn<EditDvlaEntitlementFormValues>;
    isLoading: boolean;
    onSubmit: (
        data: EditDvlaEntitlementFormValues,
        callback: () => void
    ) => Promise<void>;
    canEdit: boolean;
};

const EditCategoriesForm = ({
    initialValues,
    isLoading,
    onSubmit,
    canEdit,
}: EditCategoriesFormProps) => {
    const {
        entitlementRestrictionCodes,
        entitlementRestrictionCodesError,
        isLoadingEntitlementRestrictionCodes,
    } = getEntitlementRestrictionCodes();

    const [error, setError] = useState(false);
    const [errorCategories, setErrorCategories] = useState<string[]>();
    const findValueByCategory = (category: string) => {
        const rowData = initialValues.entitlements.find(
            (data) => data.code === category
        );
        return (
            rowData ?? {
                code: category,
                type: "None",
                validFrom: null,
                validTo: null,
                restrictionCodes: [],
            }
        );
    };

    const initTableRows = categoriesDI4.map((category) => {
        const object = findValueByCategory(category.code);
        const validFrom = object.validFrom;
        const validTo = object.validTo;
        return {
            code: category.code,
            type: object.type,
            validFrom: validFrom ? new Date(validFrom) : null,
            validTo: validTo ? new Date(validTo) : null,
            restrictionCodes: findValueByCategory(category.code)
                .restrictionCodes,
        };
    });

    const [entitlementValues, setEntitlementValues] =
        useState<DvlaEntitlement[]>(initTableRows);

    const handleArrayUpdate = (
        category: string,
        field: "type" | "validFrom" | "validTo",
        value: string | Date | null
    ) => {
        const object = entitlementValues.find((obj) => obj.code === category);
        if (!object) {
            errorToast("This category cannot be found");
            return;
        }
        const newObject = value
            ? {
                  code: category,
                  type: field === "type" ? value : object.type,
                  validFrom:
                      field === "validFrom" && value ? value : object.validFrom,
                  validTo:
                      field === "validTo" && value ? value : object.validTo,
                  restrictionCodes: object.restrictionCodes,
              }
            : {
                  code: category,
                  type: field === "type" ? "None" : object.type,
                  validFrom: field === "validFrom" ? null : object.validFrom,
                  validTo: field === "validTo" ? null : object.validTo,
                  restrictionCodes: object.restrictionCodes,
              };
        const newArray = new Array(0);
        entitlementValues.map((obj) => {
            if (obj.code === category) {
                newArray.push(newObject);
            } else {
                newArray.push(obj);
            }
        });
        setEntitlementValues(newArray);
    };

    const handleAddRestrictions = (category: string, value: string[]) => {
        const newArray = new Array(0);
        entitlementValues.map((obj) => {
            if (obj.code === category) {
                newArray.push({
                    ...obj,
                    restrictionCodes: value,
                });
            } else {
                newArray.push(obj);
            }
        });
        setEntitlementValues(newArray);
    };

    const dateOrderIsCorrect = (validFrom: Date, validTo: Date) => {
        if (validFrom < validTo) return true;
        return false;
    };

    // returns true or false error state for each row of the table
    const checkDataIsValid = () => {
        const categories = new Array(0);
        entitlementValues.map((obj) => {
            if (obj.type !== "None" || obj.validFrom || obj.validTo) {
                if (
                    obj.type === "None" ||
                    !obj.validFrom ||
                    !obj.validTo ||
                    !isValidDate(obj.validFrom) ||
                    !isValidDate(obj.validTo) ||
                    !dateOrderIsCorrect(obj.validFrom, obj.validTo)
                ) {
                    categories.push(obj.code);
                }
            }
        });
        setErrorCategories(categories);
        return categories.length ? true : false;
    };

    const handleSubmit = (callback: () => void) => {
        if (checkDataIsValid()) {
            setError(true);
            return;
        }
        setError(false);
        const submitVal = {
            entitlements: entitlementValues,
        };
        onSubmit(submitVal, callback);
    };

    const foreignLicence = !(
        initialValues.licenceCountryOrigin == GREAT_BRITAIN ||
        initialValues.licenceCountryOrigin == NORTHERN_IRELAND
    );

    return (
        <EditableContentBlock
            title="Categories"
            disableEdit={!canEdit || foreignLicence}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <>
                            {!foreignLicence && (
                                <CategoriesTable
                                    tableHeaders={tableHeaders}
                                    tableRows={entitlementValues}
                                />
                            )}
                            {foreignLicence && (
                                <div className="p-6">
                                    <div className=" rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-4 text-S2D-neutral-10">
                                        Licence categories are not accessible
                                        for drivers with a Foreign Licence/ no
                                        licence data.
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {isEdit && (
                        <>
                            <div className="w-full overflow-x-scroll">
                                <EditCategoriesTable
                                    tableHeaders={tableHeaders}
                                    entitlementValues={entitlementValues}
                                    errorCategories={errorCategories}
                                    handleArrayUpdate={handleArrayUpdate}
                                    entitlementRestrictionCodes={
                                        entitlementRestrictionCodes
                                    }
                                    entitlementRestrictionCodesError={
                                        entitlementRestrictionCodesError
                                    }
                                    isLoadingEntitlementRestrictionCodes={
                                        isLoadingEntitlementRestrictionCodes
                                    }
                                    handleAddRestrictions={
                                        handleAddRestrictions
                                    }
                                />
                            </div>
                            {error && (
                                <div className="p-4">
                                    <FormMessageBox
                                        variant="error"
                                        title="Error in form"
                                        message={
                                            <>
                                                <p>
                                                    Please make sure each edited
                                                    category is complete with
                                                    licence status, valid from
                                                    date and valid to date.
                                                </p>
                                                <p>
                                                    Dates must be written
                                                    chronologically and in the
                                                    format <b>dd/mm/yyyy</b>.
                                                </p>
                                            </>
                                        }
                                    />
                                </div>
                            )}
                            <div className="flex flex-row justify-between p-4">
                                <Button
                                    type="button"
                                    variant="tertiary"
                                    onClick={() => {
                                        setIsEdit(false);
                                        setEntitlementValues(initTableRows);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="button"
                                    variant="primary"
                                    onClick={() =>
                                        handleSubmit(() => setIsEdit(false))
                                    }
                                    loading={isLoading}
                                >
                                    Submit
                                </Button>
                            </div>
                        </>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditCategoriesForm;
