import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { AnalysisComponentContentType } from "./AnalysisComponent";
import TabWithoutIcon from "../HeadlessUiTabWithIcon/TabWithoutIcon";

type TabbedAnalysisComponentProps = {
    analysisContent: AnalysisComponentContentType<string>[];
};

export default function TabbedAnalysisComponent({
    analysisContent,
}: TabbedAnalysisComponentProps) {
    return (
        <Tab.Group>
            <Tab.List
                className={clsx(
                    `flex min-w-max flex-row divide-x-2 divide-S2D-neutral-10
             rounded-sm border-2 border-S2D-neutral-10 bg-S2D-neutral-100 font-semibold`
                )}
            >
                {analysisContent.map((tab) => (
                    <div
                        className={` w-1/${analysisContent.length} flex-shrink flex-grow`}
                        key={tab.title}
                    >
                        <TabWithoutIcon
                            content={tab.title}
                            tabCount={5}
                            alertCount={tab.count}
                        />
                    </div>
                ))}
            </Tab.List>
            <Tab.Panels>
                {analysisContent.map((tab) => (
                    <Tab.Panel key={tab.title}>{tab.content} </Tab.Panel>
                ))}
            </Tab.Panels>
        </Tab.Group>
    );
}
