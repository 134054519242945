import { ApiDriverDetailsResponse } from "../../models/api/admin/driver";
import DialogBox from "../DialogBox";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import FormRadioGroup, { FormRadioGroupOption } from "../FormRadioGroup";
import Button from "../Button";

export type VehicleSelectTypes = "verify-insurance" | "remove-vehicle";

type VehicleSelectDialogProps = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    title: string;
    selectedDriverData: ApiDriverDetailsResponse | null;
    resetSelectedDriverData: () => void;
    onSubmit: (vehicleId: string | null, inviteId?: string) => Promise<void>;
    isLoading: boolean;
    type?: VehicleSelectTypes;
};

type VehicleSelectFormValues = {
    vehicleId: string | null;
};

const validationSchema = yup.object().shape({
    vehicleId: yup.string().required("Please select a vehicle.").nullable(),
});

const VehicleSelectDialog: React.FC<VehicleSelectDialogProps> = ({
    isOpen,
    setIsOpen,
    title,
    selectedDriverData,
    resetSelectedDriverData,
    onSubmit,
    isLoading = false,
    type,
}) => {
    const inviteId = selectedDriverData?.greyFleetInviteId;

    const getVehicleLabel = (index: number) => {
        if (selectedDriverData?.vehicleRegistration) {
            return selectedDriverData.vehicleRegistration[index];
        }
        return "undefined vehicle";
    };

    const getVehicleDisablity = (index: number) => {
        if (type === "verify-insurance") {
            if (selectedDriverData?.vehicleInsuranceUploaded) {
                return selectedDriverData.vehicleInsuranceUploaded[index];
            }
        }
        return undefined;
    };

    const vehicleInsuranceCaption = (index: number) => {
        if (
            !selectedDriverData?.vehicleInsuranceUploaded ||
            !selectedDriverData.vehicleInsuranceUploaded[index]
        ) {
            return "This vehicle has no uploaded insurance documents.";
        } else if (
            selectedDriverData?.vehicleInsuranceVerified &&
            selectedDriverData?.vehicleInsuranceVerified[index]
        ) {
            return "This vehicle has already been marked as verified.";
        } else {
            return undefined;
        }
    };

    const vehicleOptions: FormRadioGroupOption<string>[] | undefined =
        selectedDriverData?.vehicleId?.map((id, index) => ({
            id: id,
            label: getVehicleLabel(index),
            disabled: getVehicleDisablity(index),
            caption:
                type === "verify-insurance"
                    ? vehicleInsuranceCaption(index)
                    : undefined,
        }));

    const { handleSubmit, formState, control, reset } =
        useForm<VehicleSelectFormValues>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues: {
                vehicleId: null,
            },
        });

    const handleClose = () => {
        setIsOpen(false);
        reset({ vehicleId: null });
        resetSelectedDriverData();
    };

    return (
        <DialogBox isOpen={isOpen} onClose={handleClose} title={title}>
            <form
                onSubmit={handleSubmit((data) =>
                    onSubmit(data.vehicleId, inviteId ?? undefined)
                )}
            >
                <div className="px-6 py-4">
                    <Controller
                        name="vehicleId"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <FormRadioGroup
                                label="Select a vehicle."
                                value={value ?? undefined}
                                options={vehicleOptions ?? []}
                                error={formState.errors.vehicleId}
                                onChange={onChange}
                            />
                        )}
                    />
                </div>
                <div className="flex flex-col-reverse justify-between rounded-b-sm border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                    <Button
                        variant="tertiary"
                        type="button"
                        onClick={handleClose}
                        className="w-full justify-center md:w-auto"
                    >
                        Cancel
                    </Button>

                    <Button
                        type="submit"
                        variant={
                            type === "remove-vehicle" ? "danger" : "primary"
                        }
                        loading={isLoading}
                        className="w-full justify-center md:w-auto"
                    >
                        {type === "remove-vehicle"
                            ? "Remove vehicle"
                            : "Submit"}
                    </Button>
                </div>
            </form>
        </DialogBox>
    );
};

export default VehicleSelectDialog;
