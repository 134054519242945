export type DocumentBase64 = {
    documentBase64: string;
    fileType:
        | "tiff"
        | "tif"
        | "svg"
        | "jpg"
        | "jpeg"
        | "ico"
        | "gif"
        | "webp"
        | "png"
        | "bmp"
        | "avif"
        | "pdf"
        | "txt"
        | "docx"
        | "doc"
        | "";
    fileName: string;
    documentType: number;
    signedDate: Date | undefined | null;
};

export type DocumentFile = {
    documentFile: File[];
    fileType: string;
    fileName: string;
};

export enum DocumentTypes {
    Other = 0,
    InsuranceCertificate = 1,
    Image = 2,
    DriverDeclaration = 3,
    DrivingLicence = 4,
    Passport = 5,
    ProofOfAddress = 6,
    Medical = 7,
    V5C = 8,
    ServiceLog = 9,
    Mot = 10,
    RoadTax = 11,
}
