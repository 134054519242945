import { yupResolver } from "@hookform/resolvers/yup";
import { SetStateAction, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { appPaths } from "../../Routes";
import Button from "../Button";
import {
    AddDriverFormValues,
    AddDriverValidationSchemaFields,
} from "./AddDriverFormValues";
import DriverAddressFields from "./DriverAddressFields";
import DriverCommentFields from "./DriverCommentFields";
import DriverLinkedDriverFields from "./DriverLinkedDriverFields";
import DriverDocumentFields from "./DriverDocumentFields";
import DriverInformationFields from "./DriverInformationFields";
import DriverLicenceInformationFields from "./DriverLicenceInformationFields";
import { EmailRequest } from "../../pages/Admin/AdminAddDriver";
import DriverDriverTypeFields from "./DriverDriverTypeFields";
import DriverEmploymentFields from "./DriverEmploymentFields";
import { useAddDriverCompanyInformation } from "./useAddDriverCompanyInformation";
import { DeclarationType } from "../../models/app/eDeclarations";
import { useAddDriverDocumentValidation } from "./useDriverDocumentValidation";
import { DriverValidationTypes } from "../../models/app/admin/company";

type AddDriverFormProps = {
    isLoading: boolean;
    onSubmit: (value: AddDriverFormValues, callback?: () => void) => void;
    onCancel: () => void;
    emailRequest: EmailRequest;
    setEmailRequest: (value: SetStateAction<EmailRequest>) => void;
    formDefaultValues: AddDriverFormValues;
};

const validationSchema = yup.object().shape(AddDriverValidationSchemaFields);

const AddDriverForm: React.FC<AddDriverFormProps> = ({
    isLoading,
    onSubmit,
    onCancel,
    emailRequest,
    setEmailRequest,
    formDefaultValues,
}) => {
    const [isReset, setIsReset] = useState(false);

    const methods = useForm<AddDriverFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: formDefaultValues,
    });

    const { companyList, isCompanyListLoading, isClientUser } =
        useAddDriverCompanyInformation(methods);

    const control = methods.control;
    const foreignLicence = useWatch({ control, name: "foreignLicence" });
    const formCompanyId = useWatch({ control, name: "companyId" });
    const declarationType = useWatch({ control, name: "declarationType" });
    const documents = useWatch({ control, name: "documents" });

    const navigate = useNavigate();

    // Document properties need to be checked when we submit
    const {
        documentMethods,
        notSubmittedError,
        setNotSubmittedError,
        documentTypeError,
        validDocumentsOnSubmit,
    } = useAddDriverDocumentValidation();

    const handleFormSubmit = (value: AddDriverFormValues) => {
        if (!validDocumentsOnSubmit(value)) return;

        onSubmit(value, () => navigate(appPaths.adminDrivers));
    };

    const handleSubmitFormAndAddAnotherDriver = (
        value: AddDriverFormValues
    ) => {
        if (!validDocumentsOnSubmit(value)) return;

        onSubmit(value, () => {
            methods.reset();
            documentMethods.reset();
            setIsReset(true);
        });
    };

    const handleError = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    // We require this method to handle declaration type change to update
    const handleDeclarationTypeChange = (value: DeclarationType) => {
        if (foreignLicence) return;
        methods.setValue("declarationType", value);
        setEmailRequest({
            eDeclaration: value === DeclarationType.EDeclaration,
            greyFleet: emailRequest.greyFleet,
        });
    };

    const setDriverIdentityValidationType = (value: DriverValidationTypes) => {
        methods.setValue("validateDriverIdentityBy", value);
    };

    useEffect(() => {
        if (isReset) {
            // Reseting the flag after handling the reset
            setIsReset(false);
        }
    }, [isReset]);

    return (
        <div className="mx-auto w-full max-w-3xl">
            <form
                onSubmit={methods.handleSubmit(handleFormSubmit, handleError)}
            >
                <DriverInformationFields
                    methods={methods}
                    emailRequest={emailRequest}
                    handleDeclarationTypeChange={handleDeclarationTypeChange}
                    isClientUser={isClientUser}
                />
                <DriverEmploymentFields
                    methods={methods}
                    companyList={companyList}
                    isCompanyListLoading={isCompanyListLoading}
                    companyId={formCompanyId}
                    isClientUser={isClientUser}
                    setDriverIdentityValidationType={
                        setDriverIdentityValidationType
                    }
                    isReset={isReset}
                />
                <DriverLicenceInformationFields
                    methods={methods}
                    handleDeclarationTypeChange={handleDeclarationTypeChange}
                />
                <DriverAddressFields methods={methods} />
                <DriverLinkedDriverFields methods={methods} />
                <DriverDriverTypeFields
                    methods={methods}
                    emailRequest={emailRequest}
                    setEmailRequest={setEmailRequest}
                />
                <DriverCommentFields methods={methods} />
                <DriverDocumentFields
                    methods={methods}
                    documentMethods={documentMethods}
                    savedDocuments={documents}
                    notSubmittedError={notSubmittedError}
                    setNotSubmittedError={setNotSubmittedError}
                    documentTypeError={documentTypeError}
                    declarationType={declarationType}
                />
                <div className="flex flex-col items-center gap-4 pt-4 pb-8 md:flex-row md:gap-6">
                    <Button
                        variant="tertiary"
                        type="button"
                        className="w-full justify-center md:w-auto"
                        loading={isLoading}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="secondary"
                        type="button"
                        className="w-full justify-center md:ml-auto md:w-auto"
                        loading={isLoading}
                        onClick={methods.handleSubmit(
                            handleSubmitFormAndAddAnotherDriver
                        )}
                    >
                        Save and add another
                    </Button>
                    <Button
                        type="submit"
                        className="w-full justify-center md:w-auto"
                        loading={isLoading}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AddDriverForm;
