import { useState } from "react";
import Button from "../Button";
import DialogBox from "../DialogBox";

export type DeleteResellerProps = {
    onSubmit: () => Promise<void>;
    isLoading: boolean;
};

const DeleteReseller: React.FC<DeleteResellerProps> = ({
    onSubmit,
    isLoading,
}) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="mb-4">
                <Button onClick={() => setOpen(true)}>Delete Reseller</Button>
            </div>
            <DialogBox
                isOpen={open}
                onClose={handleClose}
                description="Are you sure you want to delete this reseller?"
                size="small"
            >
                <div className="flex justify-center gap-4 m-4">
                    <Button variant="tertiary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} loading={isLoading}>
                        Delete
                    </Button>
                </div>
            </DialogBox>
        </>
    );
};

export default DeleteReseller;
