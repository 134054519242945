import {
    Buildings,
    CalendarBlank,
    CalendarCheck,
    GlobeHemisphereWest,
} from "phosphor-react";
import { type CompanyChecksThisMonthStats } from "../../models/api/admin/companyStats";
import StatisticSummaryBox from "../StatisticSummaryBox";
import NorthernIreland from "../../assets/NorthernIreland.png";
import { type CompanyChecksThisMonthDto } from "../../models/app/admin/companyDashboard";

type CompanyDashboardChecksThisMonthTabProps = {
    data?: CompanyChecksThisMonthStats;
    onLinkClick?: (key: keyof CompanyChecksThisMonthDto) => void;
};

const CompanyDashboardChecksThisMonthTab: React.FC<
    CompanyDashboardChecksThisMonthTabProps
> = ({ data, onLinkClick }) => {
    return (
        <div className="flex w-full flex-col gap-4">
            <StatisticSummaryBox
                icon={CalendarCheck}
                label="licences checked this month"
                statistic={data?.checksCompletedInMonth.total}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("licencesChecked") : undefined
                }
            />
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-3">
                <StatisticSummaryBox
                    icon={Buildings}
                    label="uk"
                    statistic={data?.checksCompletedInMonth.unitedKingdomChecks}
                    onLinkClick={() =>
                        onLinkClick
                            ? onLinkClick("ukLicencesCheckedThisMonth")
                            : undefined
                    }
                />
                <StatisticSummaryBox
                    customImg={NorthernIreland}
                    label="n.irish"
                    statistic={
                        data?.checksCompletedInMonth.northernIrelandChecks
                    }
                    onLinkClick={() =>
                        onLinkClick
                            ? onLinkClick("niLicencesCheckedThisMonth")
                            : undefined
                    }
                />
                <StatisticSummaryBox
                    icon={GlobeHemisphereWest}
                    label="foreign"
                    statistic={data?.checksCompletedInMonth.foreignChecks}
                    onLinkClick={() =>
                        onLinkClick
                            ? onLinkClick("foreignLicencesCheckedThisMonth")
                            : undefined
                    }
                />
            </div>
            <StatisticSummaryBox
                icon={CalendarBlank}
                iconColor="bg-S2D-warning-90"
                label="licences to be checked this month"
                statistic={data?.checksDueInMonth.total}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("licencesToBeChecked") : undefined
                }
            />
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-3">
                <StatisticSummaryBox
                    icon={Buildings}
                    iconColor="bg-S2D-warning-90"
                    label="uk"
                    statistic={data?.checksDueInMonth.unitedKingdomChecks}
                    onLinkClick={() =>
                        onLinkClick
                            ? onLinkClick("ukLicencesToBeCheckedThisMonth")
                            : undefined
                    }
                />
                <StatisticSummaryBox
                    customImg={NorthernIreland}
                    iconColor="bg-S2D-warning-90"
                    label="n.irish"
                    statistic={data?.checksDueInMonth.northernIrelandChecks}
                    onLinkClick={() =>
                        onLinkClick
                            ? onLinkClick("niLicencesToBeCheckedThisMonth")
                            : undefined
                    }
                />
                <StatisticSummaryBox
                    icon={GlobeHemisphereWest}
                    iconColor="bg-S2D-warning-90"
                    label="foreign"
                    statistic={data?.checksDueInMonth.foreignChecks}
                    onLinkClick={() =>
                        onLinkClick
                            ? onLinkClick("foreignLicencesToBeCheckedThisMonth")
                            : undefined
                    }
                />
            </div>
        </div>
    );
};

export default CompanyDashboardChecksThisMonthTab;
