import { Switch } from "@headlessui/react";
import clsx from "clsx";
import FormInputMessage from "./FormInputError";

export type ToggleProps = {
    value: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    loading?: boolean;
    text: string;
    error?: string;
    helpText?: string;
};

const Toggle: React.FC<ToggleProps> = ({
    value,
    onChange,
    disabled = false,
    loading = false,
    text,
    error,
    helpText,
}) => {
    return (
        <div className="flex flex-col">
            <div className="my-4 flex flex-row gap-4 ">
                <Switch
                    checked={value}
                    onChange={onChange}
                    className={clsx(
                        "relative inline-flex h-6 w-11 items-center rounded-full",
                        value && "bg-S2D-light-green-80",
                        !value && "bg-gray-200",
                        disabled && "hover:cursor-not-allowed",
                        loading && "cursor-wait"
                    )}
                    disabled={disabled}
                >
                    <span
                        className={`${
                            value ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                </Switch>
                <div className=" font-bold">{text}</div>
            </div>
            <FormInputMessage error={error} helpText={helpText} />
        </div>
    );
};

export default Toggle;
