import { CompanyDriverAnalysisResponse } from "../../models/api/admin/companyDriverAnalysis";
import { useS2DApiFetcher } from "../common/fetching";

// this hook is separate from 'useGetCompanyDriverAnalysis' so that the expensive
// report call (i.e. All driverAnalysis categories) is only made when asked for.

export const useGetCompanyDriverAnalysisReport = (
    companyId?: string,
    departmentId?: string,
    professional?: boolean
) => {
    const pathArray = [`api/companies/driverAnalysis`];
    const pushConnector = () =>
        pathArray.length === 1 ? pathArray.push("?") : pathArray.push("&");

    if (companyId) {
        pushConnector();
        pathArray.push(`CompanyId=${companyId}`);
    }
    if (departmentId) {
        pushConnector();
        pathArray.push(`DepartmentId=${departmentId}`);
    }
    if (professional) {
        pushConnector();
        pathArray.push(`IsProfessional=${professional}`);
    }

    const [getDriverAnalysisData, isGettingDriverAnalysisData] =
        useS2DApiFetcher<null, CompanyDriverAnalysisResponse, null>(
            "GET",
            () => ({
                url: pathArray.join(""),
                body: null,
                altToken: undefined,
            }),
            [],
            true
        );

    return {
        getDriverAnalysisData: async () => await getDriverAnalysisData(null),
        isGettingDriverAnalysisData,
    };
};
