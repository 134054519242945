import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormMessageBox from "../../components/FormMessageBox";
import { useCompanyReportSettings } from "../../api/admin/reports";
import { errorToast, successToast } from "../../toast";
import { downloadFromArrayBuffer } from "../../utils/downloadFromArrayBuffer";
import PageLayout from "../../components/PageLayout";

const AdminDownloadMonthlyReport: React.FC = () => {
    const { companyMonthlyReportId } = useParams<{
        companyMonthlyReportId?: string;
    }>();
    const { getMonthlyReport } = useCompanyReportSettings(false);
    const [message, setMessage] = React.useState<string | JSX.Element>(
        "Downloading monthly Report..."
    );
    console.log("Component rendering or re-rendering");

    useEffect(() => {
        const fetchData = async () => {
            if (companyMonthlyReportId) {
                setMessage("Downloading monthly Report...");
                try {
                    const response = await getMonthlyReport(
                        companyMonthlyReportId
                    );

                    if (response.success) {
                        downloadFromArrayBuffer(
                            response.content,
                            "MonthlyReport"
                        );
                        setMessage(
                            <>
                                <div>
                                    Successfully downloaded monthly report.
                                </div>
                                <div>Please check your downloads folder.</div>
                            </>
                        );
                        successToast("Successfully downloaded monthly report");
                    } else {
                        // Update message if success is false (assuming the API response structure allows this)
                        setMessage(
                            "Failed to download monthly report. Please try again later."
                        );
                    }
                } catch (error) {
                    setMessage(
                        "Failed to download monthly report. Please try again later."
                    );
                    errorToast("Failed to download monthly report");
                }
            } else {
                setMessage("No report ID provided. Unable to download report.");
            }
        };

        fetchData();
    }, []);

    return (
        <PageLayout title="Download Monthly Report">
            <FormMessageBox title="" variant="neutral" message={message} />
        </PageLayout>
    );
};

export default AdminDownloadMonthlyReport;
