import { Medal, Warning, WarningOctagon } from "phosphor-react";
import { useEffect, useState } from "react";
import ActionableStatus from "../ActionableStatus";
import { ActionableStatusThemes } from "../ActionableStatus/ActionableStatus";
import ActionableStatusTag from "../ActionableStatus/ActionableStatusTag";
import { DriverRiskProfile } from "../../models/api/admin/driver";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import { getSimplifiedDriverPointsColor } from "../../utils/simplifiedDriverPointsColor";
import { addSpacesToTitleCase } from "../../utils/addSpacesToTitleCase";

type PenaltyPointsProps = {
    pointsTotal: number;
    name: string;
    severityLevel?: DriverRiskProfile["severityLevel"];
    range?: string;
    endorsements?: string[];
    companyData: ApiCompanyResponse | undefined
};

const PenaltyPoints = ({
    pointsTotal,
    range,
    name,
    severityLevel,
    endorsements,
    companyData
}: PenaltyPointsProps) => {
    const [theme, setTheme] = useState<ActionableStatusThemes>();

    const isRedTheme =
        severityLevel === "High" ||
        severityLevel === "Critical" ||
        name === "High risk" ||
        name === "Disqualified" ||
        name === "Invalid licence" ||
        name === "Prev. disqualified";

    useEffect(() => {
        if (severityLevel === "Low") {
            setTheme("green");
        }
        if (severityLevel === "Medium") {
            setTheme("yellow");
        }
        if (isRedTheme) {
            setTheme("red");
        }
        if (name === "Prev. disqualified") {
            setTheme(getSimplifiedDriverPointsColor(pointsTotal, companyData?.companyRiskProfiles));
        }
    }, [severityLevel]);

    return (
        <ActionableStatus
            title="Penalty points"
            theme={theme || "green"}
            actionable={false}
            statusIndicators={[
                <ActionableStatusTag text={name} theme="safe2Drive" />,
            ]}
        >
            <div className="flex justify-between">
                <div className="flex flex-row items-end">
                    <div className="flex -translate-y-1 pr-2 ">
                        {severityLevel !== "Medium" && !isRedTheme && (
                            <Medal size={30} weight="duotone" />
                        )}
                        {severityLevel === "Medium" && (
                            <Warning size={30} weight="duotone" />
                        )}
                        {isRedTheme && (
                            <WarningOctagon size={30} weight="duotone" />
                        )}
                    </div>
                    <div className="flex items-end">
                        <span className="translate-y-1 pr-1 text-6xl font-medium">
                            {pointsTotal >= 0 ? pointsTotal : "?"}
                        </span>
                        <div className="flex flex-col pl-1">
                            <span className="font-semibold uppercase">
                                {" "}
                                points
                            </span>
                            <div className="flex flex-wrap text-sm font-extralight">
                                {endorsements?.map((endorsement, i) => {
                                    return (
                                        <div key={i}>
                                            {i < 8 && (
                                                <span>
                                                    <span className="">
                                                        {i < 8 && endorsement}
                                                    </span>
                                                    <span className="pr-1">
                                                        {i < 7 &&
                                                            endorsements.length >
                                                                8 &&
                                                            ","}
                                                    </span>
                                                </span>
                                            )}
                                            {i === 10 &&
                                                endorsements.length > 10 && (
                                                    <div className="italic">
                                                        <span className="pr-1">
                                                            {"+"}
                                                            {endorsements.length -
                                                                8}
                                                            {" more"}
                                                        </span>
                                                    </div>
                                                )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex w-1/3 flex-col items-end justify-end">
                    {severityLevel && (
                        <span className="text-xs font-semibold uppercase">
                            {addSpacesToTitleCase(severityLevel)} range
                        </span>
                    )}
                    {range && (
                        <div className="text-xs font-semibold uppercase">
                            {range} Points
                        </div>
                    )}
                </div>
            </div>
        </ActionableStatus>
    );
};

export default PenaltyPoints;
