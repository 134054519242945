import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import {
    type DvlaCheckItem,
    type ApiDvlaChecksResponse,
} from "../../models/api/safe2DriveAdmin/dvlaChecks";
import PaginationNav from "../PaginationNav";
import Table, {
    type HiddenCols,
    type TableHeader,
} from "../ReusableComponents/Table";
import {
    dvlaChecksTableMenuGroups,
    dvlaChecksTableMenuItems,
} from "./DvlaChecksTableOptions";
import {
    initDvlaChecksTableContents,
    initDvlaChecksTableHeaders,
} from "./dvlaChecksTableInit";
import { useEffect } from "react";
import { type TableCheckbox } from "../ReusableComponents/Table/tableTypes";
import { useDvlaChecksTableActions } from "./useDvlaChecksTableActions";
import EditCheckDateDialog from "./EditCheckDateDialog";
import ConfirmationDialog from "../ReusableComponents/ConfirmationDialog";
import { CheckStatus } from "../../models/shared/statuses";
import { ApiCompanyResponse } from "../../models/api/admin/company";

type DvlaChecksTableProps = {
    dvlaChecks?: ApiDvlaChecksResponse;
    refetchDvlaChecks: () => void;
    pageNumber: number;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    orderBy: string;
    setOrderBy: (orderBy: string) => void;
    isLoading?: boolean;
    selectedCheckboxes: TableCheckbox<DvlaCheckItem>[];
    setSelectedCheckboxes: (value: TableCheckbox<DvlaCheckItem>[]) => void;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
    selectedCompany?: ApiCompanyResponse;
};

const DvlaChecksTable: React.FC<DvlaChecksTableProps> = ({
    dvlaChecks,
    refetchDvlaChecks,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    isLoading,
    selectedCheckboxes,
    setSelectedCheckboxes,
    tableRef,
    selectedCompany,
}) => {
    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "DvlaChecksTableHeaders",
        initDvlaChecksTableHeaders
    );

    const initHiddenCols = new Array(0);
    tableHeaders.map((item) =>
        initHiddenCols.push({
            id: item.id,
            hidden: item.hide ?? false,
        })
    );

    const [hiddenCols, setHiddenCols] = useLocalStorage<HiddenCols[]>(
        "DvlaChecksTableHiddenCols",
        initHiddenCols
    );

    const navigate = useNavigate();

    // order by currently does not work on GET api/dvla
    // const handleSortColumn = (newOrderBy?: string) => {
    //     if (!newOrderBy) return;
    //     setOrderBy(newOrderBy);
    // };

    const menuActions = useDvlaChecksTableActions(refetchDvlaChecks);
    const tableContents = initDvlaChecksTableContents(
        dvlaChecks?.items ?? [],
        selectedCompany?.companyRiskProfiles
    );

    useEffect(() => {
        setSelectedCheckboxes([]);
    }, [pageNumber]);

    return !isLoading && !tableContents.length ? (
        <div className="rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-4 text-S2D-neutral-10">
            No drivers found
        </div>
    ) : (
        <>
            <Table
                tableHeaders={tableHeaders}
                setTableHeaders={setTableHeaders}
                tableContents={tableContents}
                hiddenCols={hiddenCols}
                setHiddenCols={setHiddenCols}
                menuItems={(data) =>
                    dvlaChecksTableMenuItems(data, navigate, menuActions)
                }
                menuGroups={dvlaChecksTableMenuGroups}
                // orderByOptions={dvlaChecksOrderByOptions}
                // orderBy={orderBy}
                // handleSortColumn={handleSortColumn}
                isLoading={isLoading}
                checkedBoxes={selectedCheckboxes}
                setCheckedBoxes={setSelectedCheckboxes}
                tableRef={tableRef}
            />
            {dvlaChecks && (
                <PaginationNav
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    totalCount={dvlaChecks.totalCount}
                    totalPages={dvlaChecks.totalPages}
                    currentPage={dvlaChecks.pageNumber}
                    hasPreviousPage={dvlaChecks.hasPreviousPage}
                    hasNextPage={dvlaChecks.hasNextPage}
                />
            )}
            <EditCheckDateDialog
                isOpen={!!menuActions.checkDateDriverId}
                onClose={() =>
                    menuActions.handleOpenNextCheckDateDialog(undefined)
                }
                handleUpdateNextCheckDate={(nextCheckDate: Date) =>
                    menuActions.handleUpdateNextCheckDate(
                        nextCheckDate,
                        menuActions.checkDateDriverId
                    )
                }
                isUpdatingNextCheck={menuActions.isUpdatingNextCheckDate}
            />

            <ConfirmationDialog
                open={!!menuActions.disableDriverId}
                title={"Disable further checks?"}
                caption={
                    "No checks will be performed on this driver in the future until manually rescheduled."
                }
                onClose={() =>
                    menuActions.handleOpenDisableDriverDialog(undefined)
                }
                onConfirm={() =>
                    menuActions.handleCheckStatus(
                        CheckStatus.Disabled,
                        menuActions.disableDriverId
                    )
                }
                variant="danger"
                confirmButtonLabel="Disable further checks"
            />
        </>
    );
};

export default DvlaChecksTable;
