import { PencilSimple, NotePencil, Envelope } from "phosphor-react";
import { type TableMenuItem } from "../ReusableComponents/Table/TableMenu";
import { type DriverDeclaration } from "../../models/api/admin/eDeclarations";
import { DeclarationStatus } from "../../models/shared/statuses";
import { DeclarationType } from "../../models/app/eDeclarations";

export const driverDeclarationsMenuItems = (
    data: DriverDeclaration,
    editDriverClickHandler: (driverId?: string) => void,
    declarationRequestAction: (driverId: string) => void,
    switchDeclarationTypeAction: (
        driverId: string,
        declarationType: DeclarationType
    ) => void,
    isLoading?: boolean
): TableMenuItem[] => {
    const declarationId = data.declarationId;
    const driverId = data.driverId;

    const editDriverOption: TableMenuItem = {
        key: "editDriver",
        onClick: () => editDriverClickHandler(driverId),
        icon: <PencilSimple size={20} weight="fill" />,
        text: `Edit Driver`,
    };

    const sendEDecEmailOption: TableMenuItem = {
        key: "sendEdecRequest",
        onClick: () => declarationRequestAction(declarationId),
        icon: <Envelope size={20} weight="fill" />,
        text: `Send E-Declaration email`,
    };

    const sendPaperDecEmailOption: TableMenuItem = {
        key: "sendEdecRequest",
        onClick: () => declarationRequestAction(declarationId),
        icon: <Envelope size={20} weight="fill" />,
        text: `Send Paper Declaration email`,
    };

    const switchToPaperOption: TableMenuItem = {
        key: "switchToPaper",
        onClick: () =>
            switchDeclarationTypeAction(
                declarationId,
                DeclarationType.PaperDeclaration
            ),
        icon: <NotePencil size={20} weight="fill" />,
        text: `Switch to Paper Declaration`,
        isLoading,
    };

    const switchToEDecOption: TableMenuItem = {
        key: "switchToEDec",
        onClick: () =>
            switchDeclarationTypeAction(
                declarationId,
                DeclarationType.EDeclaration
            ),
        icon: <NotePencil size={20} weight="fill" />,
        text: `Switch to E-Declaration`,
        isLoading,
    };

    const sendEmail =
        data.declarationType === "EDeclaration"
            ? sendEDecEmailOption
            : sendPaperDecEmailOption;
    const switchDeclarationType =
        data.declarationType === "EDeclaration"
            ? switchToPaperOption
            : switchToEDecOption;

    // we can't switch declaration type once the declaration has been completed
    if (data.declarationStatus === DeclarationStatus.Completed) {
        return [editDriverOption, sendEmail];
    } else {
        /* we do not want the ability to switch a foreign declaration to an edeclaration*/
        if (data.declarationType === DeclarationType.EDeclaration) {
            return [editDriverOption, sendEmail, switchDeclarationType];
        } else {
            return [editDriverOption, sendEmail];
        }
    }
};
