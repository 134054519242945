import {
    Barricade,
    CheckCircle,
    Warning,
    WarningOctagon,
} from "phosphor-react";
import { type ApiDashboardResponse } from "../../../models/api/admin/dashboard";
import RiskCategory, { type RiskCategoryProps } from "./RiskCategory";
import {
    RiskProfileLevels,
    type SeverityLevelPointsRange,
} from "../../../models/app/admin/driver";

export type DashboardDriverRiskCategoriesProps = {
    dashboardData?: ApiDashboardResponse;
    selectedCompany?: string;
    selectedDepartment?: string;
    isLoading: boolean;
};

const DashboardDriverRiskCategories = ({
    dashboardData,
    selectedCompany,
    selectedDepartment,
    isLoading,
}: DashboardDriverRiskCategoriesProps) => {
    const riskCategoryThresholds = (
        severityLevel: RiskProfileLevels
    ): SeverityLevelPointsRange | undefined => {
        const category = dashboardData?.companyRiskProfiles.find(
            (riskProfile) => riskProfile.severityLevel === severityLevel
        );
        if (!category) return undefined;

        const range = {
            from: category.lowerThreshold,
            to: category.upperThreshold,
        };

        return range;
    };

    const riskCategoryOptions: RiskCategoryProps[] = [
        {
            range: selectedCompany
                ? riskCategoryThresholds(RiskProfileLevels.Low)
                : undefined,
            severityLevel: [RiskProfileLevels.Low],
            count: dashboardData?.riskProfile.safe2DriveCount ?? 0,
            theme: "green",
            icon: CheckCircle,
        },
        {
            range: selectedCompany
                ? riskCategoryThresholds(RiskProfileLevels.Medium)
                : undefined,
            severityLevel: [RiskProfileLevels.Medium],
            count: dashboardData?.riskProfile.mediumRiskCount ?? 0,
            theme: "yellow",
            icon: Warning,
        },
        {
            range: selectedCompany
                ? riskCategoryThresholds(RiskProfileLevels.High)
                : undefined,
            severityLevel: [RiskProfileLevels.High],
            count: dashboardData?.riskProfile.highRiskCount ?? 0,
            theme: "red",
            icon: WarningOctagon,
        },
        {
            severityLevel: [RiskProfileLevels.Critical],
            count: dashboardData?.riskProfile.criticalCount ?? 0,
            theme: "black",
            icon: Barricade,
        },
    ];

    return (
        <div className="flex w-full flex-col rounded-sm bg-S2D-dark-green-30 p-6">
            <p className="pb-4 text-2xl text-S2D-text-menu">
                Drivers by risk category
            </p>
            {isLoading && (
                <p className="text-white">...loading risk categories</p>
            )}
            {dashboardData?.riskProfile && (
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-4 xl:flex-row">
                        {
                            //map low, med and high
                            riskCategoryOptions
                                .slice(0, 3)
                                .map(
                                    (
                                        {
                                            count,
                                            severityLevel,
                                            range,
                                            theme,
                                            icon,
                                        },
                                        i
                                    ) => (
                                        <RiskCategory
                                            key={`riskCategory ${i}`}
                                            count={count}
                                            range={range}
                                            severityLevel={severityLevel}
                                            theme={theme}
                                            icon={icon}
                                            selectedCompany={selectedCompany}
                                            selectedDepartment={
                                                selectedDepartment
                                            }
                                        />
                                    )
                                )
                        }
                    </div>
                    <div className="flex flex-row">
                        {riskCategoryOptions
                            .slice(3)
                            .map(
                                ({
                                    count,
                                    severityLevel,
                                    range,
                                    theme,
                                    icon,
                                }) => (
                                    <RiskCategory
                                        key="critical-status"
                                        count={count}
                                        range={range}
                                        severityLevel={severityLevel}
                                        theme={theme}
                                        icon={icon}
                                        selectedCompany={selectedCompany}
                                        selectedDepartment={selectedDepartment}
                                    />
                                )
                            )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DashboardDriverRiskCategories;
