import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useFormFieldCollectiveValidation } from "../../hooks/useFormFieldCollectiveValidation";
import { ApiPostcodeLookupResponse } from "../../models/api/postcodeLookup";
import AccordionItem from "../AccordionItem";
import FormTextInput from "../FormTextInput";
import PostcodeAddressLookup from "../PostcodeAddressLookup";
import { AddDriverFormValues } from "./AddDriverFormValues";

type DriverAddressFieldsProps = {
    methods: UseFormReturn<AddDriverFormValues>;
};

const DriverAddressFields: React.FC<DriverAddressFieldsProps> = ({
    methods,
}) => {
    const [selectedAddressValue, setSelectedAddressValue] = useState("");

    const isSectionValid =
        useFormFieldCollectiveValidation<AddDriverFormValues>(
            [
                "address.address1",
                "address.addressTown",
                "address.addressPostcode",
            ],
            methods
        );

    const handleAddressSelect = (address: ApiPostcodeLookupResponse) => {
        setSelectedAddressValue(address.summaryline);
        methods.setValue("address", {
            address1: address.addressline1,
            address2: address.addressline2,
            address3: address.addressline3,
            address4: address.addressline4,
            addressTown: address.posttown,
            addressPostcode: address.postcode,
        });
        methods.trigger("address");
    };

    return (
        <AccordionItem title="Add driver address" error={!isSectionValid}>
            <>
                <PostcodeAddressLookup
                    value={selectedAddressValue}
                    onSelect={handleAddressSelect}
                />
                <FormTextInput
                    label="Address line 1"
                    id="address1"
                    placeholder="Enter address line 1"
                    error={methods.formState.errors.address?.address1}
                    {...methods.register("address.address1")}
                />
                <FormTextInput
                    label="Address line 2"
                    id="address2"
                    placeholder="Enter address line 2"
                    error={methods.formState.errors.address?.address2}
                    {...methods.register("address.address2")}
                />
                <FormTextInput
                    label="Address line 3"
                    id="address3"
                    placeholder="Enter address line 3"
                    error={methods.formState.errors.address?.address3}
                    {...methods.register("address.address3")}
                />
                <FormTextInput
                    label="City/Town"
                    id="city"
                    placeholder="Enter city/town"
                    error={methods.formState.errors.address?.addressTown}
                    {...methods.register("address.addressTown")}
                />
                <FormTextInput
                    label="Postcode"
                    id="postcode"
                    placeholder="Enter postcode"
                    error={methods.formState.errors.address?.addressPostcode}
                    {...methods.register("address.addressPostcode")}
                />
            </>
        </AccordionItem>
    );
};

export default DriverAddressFields;
