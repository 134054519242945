import EditableContentBlock from "../../../../EditableContentBlock";
import { EditCompanyFormValues } from "../../../../EditCompanyForms/EditCompanyFormValues";
import CheckFrequenciesFields from "./EditCheckFrequenciesFields";
import SubmitOrCancelButtonBar from "../../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import useEditCheckFrequenciesForm from "./useEditCheckFrequenciesForm";
import { EditCheckFrequenciesFormValues } from "./useEditCheckFrequenciesForm";
import { defaultRiskCategories } from "../EditRiskCategoriesForm/useEditRiskCategoriesForm";
import { CheckSettings } from "../../../../../models/api/admin/company";

interface EditCheckFrequenciesFormProps {
    initialValues: EditCompanyFormValues;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetFormCallback: () => void
    ) => void;
    canEdit: boolean;
}

export default function EditCheckFrequenciesForm({
    initialValues,
    handleEditCompany,
    canEdit,
}: EditCheckFrequenciesFormProps) {
    const { methods, displayedValues } =
        useEditCheckFrequenciesForm(initialValues);

    const resetAndCloseForm = (setIsEdit?: (isEdit: boolean) => void) => {
        methods.reset(displayedValues);
        if (typeof setIsEdit === "function") setIsEdit(false);
    };

    return (
        <EditableContentBlock
            title="Check Frequencies"
            onClosed={resetAndCloseForm}
            disableEdit={!canEdit}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    <div className="p-6">
                        {!isEdit && nonEditableContent()}

                        {isEdit && editableContent(setIsEdit)}
                    </div>
                </>
            )}
        </EditableContentBlock>
    );

    function constructNewRiskProfiles(data: EditCheckFrequenciesFormValues) {
        const { range1, range2, range3 } = data.checkFrequency;

        const existingRiskProfiles = initialValues?.companyRiskProfiles;

        const range1profile = existingRiskProfiles?.find(
            (profile) => profile.severityLevel === 1
        );
        const range2profile = existingRiskProfiles?.find(
            (profile) => profile.severityLevel === 2
        );
        const range3profile = existingRiskProfiles?.find(
            (profile) => profile.severityLevel === 3
        );
        const newRiskProfiles: CheckSettings["companyRiskProfiles"] = [
            {
                severityLevel: 1,
                // NB: Api requires all 4 values be given
                // so giving default checkFrequency/name values if they are not set
                name: range1profile?.name ?? defaultRiskCategories.range1Name,
                lowerThreshold:
                    range1profile?.lowerThreshold ??
                    defaultRiskCategories.range1,
                checkFrequency: range1,
            },
            {
                severityLevel: 2,
                name: range2profile?.name ?? defaultRiskCategories.range2Name,
                lowerThreshold:
                    range2profile?.lowerThreshold ??
                    defaultRiskCategories.range2,
                checkFrequency: range2,
            },
            {
                severityLevel: 3,
                name: range3profile?.name ?? defaultRiskCategories.range3Name,
                lowerThreshold:
                    range3profile?.lowerThreshold ??
                    defaultRiskCategories.range3,
                checkFrequency: range3,
            },
        ];
        return { companyRiskProfiles: newRiskProfiles };
    }

    function editableContent(setIsEdit: (isEdit: boolean) => void) {
        function handleEditCheckFrequencies(
            data: EditCheckFrequenciesFormValues
        ) {
            const formattedData = constructNewRiskProfiles(data);
            handleEditCompany(formattedData, () =>
                resetAndCloseForm(setIsEdit)
            );
        }

        return (
            <form onSubmit={methods.handleSubmit(handleEditCheckFrequencies)}>
                <CheckFrequenciesFields methods={methods} />
                <div className="pt-7" />
                <SubmitOrCancelButtonBar
                    onCancel={() => resetAndCloseForm(setIsEdit)}
                />
            </form>
        );
    }

    function nonEditableContent() {
        return (
            <div className="space-y-2">
                <p>
                    <b>Range 1 check every: </b>
                    {displayedValues?.checkFrequency?.range1} months
                </p>
                <p>
                    <b>Range 2 check every: </b>
                    {displayedValues?.checkFrequency?.range2} months
                </p>
                <p>
                    <b>Range 3 check every: </b>
                    {displayedValues?.checkFrequency?.range3} months
                </p>
            </div>
        );
    }
}
