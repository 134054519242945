import { CompanyDriverAnalysisResponse } from "../../models/api/admin/companyDriverAnalysis";
import StatisticSummaryBox from "../StatisticSummaryBox";
import {
    Warning,
    WarningOctagon,
    CheckCircle,
    ArrowUp,
    ArrowDown,
    Barricade,
} from "phosphor-react";
import { useDriverAnalysisActions } from "./useDriverAnalysisActions";
import { type DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm";
import { RiskProfileLevels } from "../../models/app/admin/driver";

type RiskCategoriesProps = {
    data?: CompanyDriverAnalysisResponse["riskCategories"];
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
    isCompanySelected?: boolean;
};

export function RiskCategoriesAnalysis({
    data,
    refetchData,
    tableRef,
    isCompanySelected,
}: RiskCategoriesProps) {
    const { applyRiskCategoryFilter, isLoading } = useDriverAnalysisActions(
        refetchData,
        tableRef
    );
    return (
        <div className="flex w-full flex-col gap-4">
            <div className="flex flex-wrap gap-4">
                <StatisticSummaryBox
                    icon={CheckCircle}
                    label={
                        isCompanySelected
                            ? `${data?.lowRiskPointsRange ?? "-"} POINTS`
                            : ""
                    }
                    statistic={data?.lowRiskDriverCount}
                    onLinkClick={() =>
                        applyRiskCategoryFilter([RiskProfileLevels.Low])
                    }
                    isLoadingAction={isLoading}
                />
                <StatisticSummaryBox
                    icon={Warning}
                    label={
                        isCompanySelected
                            ? `${data?.mediumRiskPointsRange ?? "-"} POINTS`
                            : ""
                    }
                    statistic={data?.mediumRiskDriverCount}
                    onLinkClick={() =>
                        applyRiskCategoryFilter([RiskProfileLevels.Medium])
                    }
                    isLoadingAction={isLoading}
                />
                <StatisticSummaryBox
                    icon={WarningOctagon}
                    label={
                        isCompanySelected
                            ? `${data?.highRiskPointsRange ?? "-"} POINTS`
                            : ""
                    }
                    statistic={data?.highRiskDriverCount}
                    onLinkClick={() =>
                        applyRiskCategoryFilter([RiskProfileLevels.High])
                    }
                    isLoadingAction={isLoading}
                />
            </div>
            <StatisticSummaryBox
                icon={Barricade}
                label="Critical Status (Drivers who are not entitled to drive)"
                statistic={data?.criticalDriverCount}
                onLinkClick={() =>
                    applyRiskCategoryFilter([RiskProfileLevels.Critical])
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type CpcAndTachoAnalysisProps = {
    data?: CompanyDriverAnalysisResponse["cpcTacho"];
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function CPCAndTachoAnalysis({
    data,
    refetchData,
    tableRef,
}: CpcAndTachoAnalysisProps) {
    const { applyCpcAndTachoFilter, isLoading } = useDriverAnalysisActions(
        refetchData,
        tableRef
    );
    return (
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <StatisticSummaryBox
                icon={Warning}
                label="CPC cards due to expire 
                within 60 days"
                statistic={data?.cpcDueToExpireCount}
                onLinkClick={() =>
                    applyCpcAndTachoFilter("cpcCardsDueToExpire")
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="EXPIRED CPC"
                statistic={data?.cpcExpiredCount}
                onLinkClick={() => applyCpcAndTachoFilter("cpcCardsExpired")}
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label="tacho cards due to expire 
                within 60 days"
                statistic={data?.tachoDueToExpireCount}
                onLinkClick={() =>
                    applyCpcAndTachoFilter("tachoCardsDueToExpire")
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="EXPIRED TACHO"
                statistic={data?.tachoExpiredCount}
                onLinkClick={() => applyCpcAndTachoFilter("tachoCardsExpired")}
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type LicenceStatusAnalysisProps = {
    data?: CompanyDriverAnalysisResponse["licenceStatus"];
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function LicenceStatusAnalysis({
    data,
    refetchData,
    tableRef,
}: LicenceStatusAnalysisProps) {
    const { applyLicenceStatusFilter, isLoading } = useDriverAnalysisActions(
        refetchData,
        tableRef
    );
    return (
        <div className="flex w-full flex-col gap-4">
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                <StatisticSummaryBox
                    icon={WarningOctagon}
                    label="PROVISIONAL LICENCE"
                    statistic={data?.provisionalCount}
                    onLinkClick={() =>
                        applyLicenceStatusFilter("provisionalLicence")
                    }
                    isLoadingAction={isLoading}
                />
                <StatisticSummaryBox
                    icon={CheckCircle}
                    label="FULL LICENCE"
                    statistic={data?.fullCount}
                    onLinkClick={() => applyLicenceStatusFilter("fullLicence")}
                    isLoadingAction={isLoading}
                />
            </div>
        </div>
    );
}

type ProfessionalLicenceStatusAnalysisProps = {
    data?: CompanyDriverAnalysisResponse["professionalLicenceStatus"];
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function ProfessionalLicenceStatusAnalysis({
    data,
    refetchData,
    tableRef,
}: ProfessionalLicenceStatusAnalysisProps) {
    const { applyLicenceStatusFilter, isLoading } = useDriverAnalysisActions(
        refetchData,
        tableRef
    );
    return (
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="provisional Category C drivers"
                statistic={data?.catCProvisionalCount}
                onLinkClick={() =>
                    applyLicenceStatusFilter("categoryCProvisional")
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={CheckCircle}
                label="Full Category C drivers"
                statistic={data?.catCFullCount}
                onLinkClick={() => applyLicenceStatusFilter("categoryCFull")}
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="Provisional Category CE drivers"
                statistic={data?.catCEProvisionalCount}
                onLinkClick={() =>
                    applyLicenceStatusFilter("categoryCEProvisional")
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={CheckCircle}
                label="Full Category CE drivers "
                statistic={data?.catCEFullCount}
                onLinkClick={() => applyLicenceStatusFilter("categoryCEFull")}
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="Provisional Category D drivers"
                statistic={data?.catDProvisionalCount}
                onLinkClick={() =>
                    applyLicenceStatusFilter("categoryDProvisional")
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={CheckCircle}
                label="Full Category D drivers"
                statistic={data?.catDFullCount}
                onLinkClick={() => applyLicenceStatusFilter("categoryDFull")}
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="Provisional Category DE drivers"
                statistic={data?.catDEProvisionalCount}
                onLinkClick={() =>
                    applyLicenceStatusFilter("categoryDEProvisional")
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={CheckCircle}
                label="Full Category DE drivers"
                statistic={data?.catDEFullCount}
                onLinkClick={() => applyLicenceStatusFilter("categoryDEFull")}
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type LicenceChangedAnalysisProps = {
    data?: CompanyDriverAnalysisResponse["driverLicenceChanges"];
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function LicenceChangedAnalysis({
    data,
    refetchData,
    tableRef,
}: LicenceChangedAnalysisProps) {
    const { applyLicenceChangesFilter, isLoading } = useDriverAnalysisActions(
        refetchData,
        tableRef
    );

    /**
     * Calculate the counts for points/endorsements.
     * Example:
     * {
     *   endorsementChanges: [2, 4, 1, -2, 0, -1, -4],
     *   pointsChanges: [3, -1, 5, -2, 0, -3, 2]
     * }
     * - Positive numbers indicate gained points/endorsements.
     * - Negative numbers indicate lost points/endorsements.
     * - Zeroes indicate no change.
     *
     * Calculation:
     * - `gainedCount`: Count of positive numbers.
     * - `lostCount`: Count of negative numbers.
     *
     * Example results:
     * - endorsementChanges: gainedCount = 3, lostCount = 3
     * - pointsChanges: gainedCount = 3, lostCount = 3
     */
    const calculateCounts = (changes: number[]) => {
        const gainedCount = changes.filter((change) => change > 0).length;
        const lostCount = changes.filter((change) => change < 0).length;
        return { gainedCount, lostCount };
    };

    const { gainedCount: gainedPointsCount, lostCount: lostPointsCount } = data
        ? calculateCounts(data.pointsChanges)
        : { gainedCount: 0, lostCount: 0 };
    const {
        gainedCount: gainedEndorsementsCount,
        lostCount: lostEndorsementsCount,
    } = data
        ? calculateCounts(data.endorsementChanges)
        : { gainedCount: 0, lostCount: 0 };

    return (
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <StatisticSummaryBox
                icon={ArrowUp}
                label="GAINED POINTS SINCE LAST CHECK"
                statistic={gainedPointsCount}
                onLinkClick={() => applyLicenceChangesFilter("hasGainedPoints")}
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={ArrowDown}
                label="EXPIRED POINTS SINCE LAST CHECK"
                statistic={lostPointsCount}
                onLinkClick={() => applyLicenceChangesFilter("hasLostPoints")}
                isLoadingAction={isLoading}
            />

            <StatisticSummaryBox
                icon={ArrowUp}
                label="GAINED ENDORSEMENTS SINCE LAST CHECK"
                statistic={gainedEndorsementsCount}
                onLinkClick={() =>
                    applyLicenceChangesFilter("hasGainedEndorsements")
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={ArrowDown}
                label="EXPIRED ENDORSEMENTS SINCE LAST CHECK"
                statistic={lostEndorsementsCount}
                onLinkClick={() =>
                    applyLicenceChangesFilter("hasLostEndorsements")
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type LicenceCategoriesAnalysisProps = {
    data?: CompanyDriverAnalysisResponse["licenceCategories"];
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function LicenceCategoriesAnalysis({
    data,
    refetchData,
    tableRef,
}: LicenceCategoriesAnalysisProps) {
    const { applyLicenceCategoriesFilter, isLoading } =
        useDriverAnalysisActions(refetchData, tableRef);
    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <StatisticSummaryBox
                icon={Warning}
                label="PROVISIONAL/CATEGORIES DUE TO EXPIRE WITHIN 90 DAYS"
                statistic={data?.categoriesDueToExpireCount}
                onLinkClick={() =>
                    applyLicenceCategoriesFilter("categoriesDueToExpire")
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="EXPIRED/PROVISIONAL CATEGORIES"
                statistic={data?.categoriesExpiredCount}
                onLinkClick={() =>
                    applyLicenceCategoriesFilter("categoriesExpired")
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type PhotoCardsAnalysisProps = {
    data?: CompanyDriverAnalysisResponse["photocards"];
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function PhotoCardsAnalysis({
    data,
    refetchData,
    tableRef,
}: PhotoCardsAnalysisProps) {
    const { applyPhotocardFilter, isLoading } = useDriverAnalysisActions(
        refetchData,
        tableRef
    );
    return (
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <StatisticSummaryBox
                icon={Warning}
                label="PHOTO CARDS DUE TO EXPIRE WITHIN 90 DAYS"
                statistic={data?.photocardDueToExpireCount}
                onLinkClick={() =>
                    applyPhotocardFilter("photocardsDueToExpire")
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="EXPIRED PHOTO CARDS"
                statistic={data?.photocardExpiredCount}
                onLinkClick={() => applyPhotocardFilter("photocardsExpired")}
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type NewDriversAnalysisProps = {
    data?: CompanyDriverAnalysisResponse["newDrivers"];
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function NewDriversAnalysis({
    data,
    refetchData,
    tableRef,
}: NewDriversAnalysisProps) {
    const { applyNewDriverFilter, isLoading } = useDriverAnalysisActions(
        refetchData,
        tableRef
    );
    return (
        <div className="flex w-full">
            <StatisticSummaryBox
                icon={Warning}
                label="OBTAINED LICENCE WITHIN THE LAST 2 YEARS"
                statistic={data?.categoryB}
                onLinkClick={() => applyNewDriverFilter("newLicence")}
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type ProfessionalNewDriversAnalysisProps = {
    data?: CompanyDriverAnalysisResponse["professionalNewDrivers"];
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function ProfessionalNewDriversAnalysis({
    data,
    refetchData,
    tableRef,
}: ProfessionalNewDriversAnalysisProps) {
    const { applyNewDriverFilter, isLoading } = useDriverAnalysisActions(
        refetchData,
        tableRef
    );
    return (
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <StatisticSummaryBox
                icon={Warning}
                label="categories C or CE obtained within the last 2 years"
                statistic={data?.categoryCorCE}
                onLinkClick={() =>
                    applyNewDriverFilter("newCategoryCOrCELicence")
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label="Categories D or DE obtained within the last 2 years"
                statistic={data?.categoryDorDE}
                onLinkClick={() =>
                    applyNewDriverFilter("newCategoryDOrDELicence")
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type EyeSightAnalysisProps = {
    data?: CompanyDriverAnalysisResponse["eyesight"];
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function EyeSightAnalysis({
    data,
    refetchData,
    tableRef,
}: EyeSightAnalysisProps) {
    const { applyEyesightFilter, isLoading } = useDriverAnalysisActions(
        refetchData,
        tableRef
    );
    return (
        <div className="flex w-full">
            <StatisticSummaryBox
                icon={Warning}
                label="EYESIGHT RESTRICTION (01)"
                statistic={data?.eyesightRestrictionCount}
                onLinkClick={() => applyEyesightFilter("eyesightRestriction")}
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type NonAddressMatchAnalysisProps = {
    data?: CompanyDriverAnalysisResponse["nonAddressMatch"];
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export function NonAddressMatchAnalysis({
    data,
    refetchData,
    tableRef,
}: NonAddressMatchAnalysisProps) {
    const { applyNonAddMatchFilter, isLoading } = useDriverAnalysisActions(
        refetchData,
        tableRef
    );
    return (
        <div className="flex w-full">
            <StatisticSummaryBox
                icon={Warning}
                label="NON ADDRESS MATCHES"
                statistic={data?.nonAddressMatchCount}
                onLinkClick={() => applyNonAddMatchFilter("nonAddressMatches")}
                isLoadingAction={isLoading}
            />
        </div>
    );
}
