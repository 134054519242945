import EditEdeclarationSettingsForm from "./CheckSettingsSubForms/EditEdeclarationSettingsForm/EditEdeclarationSettingsForm";
import { EditCompanyFormValues } from "../../EditCompanyForms/EditCompanyFormValues";
import EditRiskCategoriesForm from "./CheckSettingsSubForms/EditRiskCategoriesForm";
import EditCheckFrequenciesForm from "./CheckSettingsSubForms/EditCheckFrequenciesForm/EditCheckFrequenciesForm";
import EditCheckPricingForm from "./CheckSettingsSubForms/EditCheckPricingForm/EditCheckPricingForm";
import useAuth from "../../../auth";
import { hasClientManagementClaim } from "../../../auth/userAccessHelpers";

interface CompanyCheckSettingsTabProps {
    initialValues: EditCompanyFormValues;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetFormCallback: () => void
    ) => void;
}

export default function CompanyCheckSettingsTab({
    initialValues,
    handleEditCompany,
}: CompanyCheckSettingsTabProps) {
    const { decodedToken } = useAuth();
    const canEdit = hasClientManagementClaim(decodedToken);

    return (
        <>
            <EditEdeclarationSettingsForm
                {...{ initialValues, handleEditCompany }}
                canEdit={canEdit}
            />
            <EditRiskCategoriesForm
                {...{ initialValues, handleEditCompany }}
                canEdit={canEdit}
            />
            <EditCheckFrequenciesForm
                {...{ initialValues, handleEditCompany }}
                canEdit={canEdit}
            />
            <EditCheckPricingForm
                {...{ initialValues, handleEditCompany }}
                canEdit={canEdit}
            />
        </>
    );
}
