import * as yup from "yup";
import {
    CompanyContact,
    type ApiCompanyResponse,
} from "../../models/api/admin/company";
import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import { EditAddressSchema } from "../../schemas/addressSchema";
import { SchemaKeys } from "../../models/shared/schemaKeysHelper";
import { companyLogoAllowedExtensions } from "./CompanySubForms/EditCompanyLogoForm/EditCompanyLogoForm";
import { type CommunicationSettings } from "../../models/app/admin/company";
import { PrivacySettingsSchema } from "../../schemas/companySchemas";

// TODO 11/10/2023 are account managers still relevant?
export const EditAccountInfoSchema: SchemaKeys<EditCompanyFormValues> = {
    accountManager: yup
        .string()
        .required("Please select an account manager")
        .nullable(),
};

export const EditCompanyDetailsSchema: SchemaKeys<EditCompanyDetailsFormValues> =
    {
        companyName: yup
            .string()
            .required(`${capitalisedTermForCompany} name is required`),
        resellerId: yup.string().notRequired().nullable(),
        address: yup.object().shape(EditAddressSchema),
        settings: yup.object().shape({
            privacySettings: PrivacySettingsSchema,
        }),
    };

export const EditCompanyLogoSchema = {
    documentBase64: yup.string().required("You must upload a new logo."),
    fileName: yup.string(),
    fileType: yup.string().oneOf(companyLogoAllowedExtensions),
};

export const AddCompanyContactSchema: SchemaKeys<CompanyContact> = {
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    telephone: yup.string().required("Telephone is required"),
    emailAddress: yup.string().required("Email is required"),
};

export const EditCompanyCommunicationSettingsSchema: SchemaKeys<CommunicationSettings> =
    {
        permitsEmailCorrespondence: yup.boolean().required(),
        permitsTelephoneCorrespondence: yup.boolean().required(),
        permitsSmsCorrespondence: yup.boolean().required(),
        permitsPostalCorrespondence: yup.boolean().required(),
    };

export type EditCompanyFormValues = Partial<ApiCompanyResponse>;

type EditCompanyDetailsFormValues = Pick<
    ApiCompanyResponse,
    "settings" | "address" | "companyName" | "resellerId"
>;
