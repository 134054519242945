import clsx from "clsx";
import { DvlaEntitlement } from "../../models/api/admin/dvla";
import { categoriesDI4 } from "./Categories/categories";

type CategoriesTableProps = {
    tableHeaders: string[];
    tableRows: DvlaEntitlement[];
};

const CategoriesTable: React.FC<CategoriesTableProps> = ({
    tableHeaders,
    tableRows,
}) => {
    const findCategoryIcon = (c: string) => {
        const category = categoriesDI4.find((obj) => obj.code === c);
        return category?.icon;
    };
    return (
        <div className="w-full overflow-x-scroll">
            <table className="divide-2 w-full min-w-max divide-y-2 divide-S2D-neutral-80 bg-S2D-dark-green-30">
                <thead>
                    <tr>
                        {tableHeaders.map((header) => (
                            <th
                                scope="col"
                                key={`${header} Header`}
                                className={clsx(
                                    "m-auto w-fit bg-S2D-dark-green-30 p-4 text-left text-sm text-[#FFFFFF]"
                                )}
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className=" m-auto divide-y-[2px] divide-S2D-neutral-80 bg-S2D-dark-green-70.1">
                    {tableRows.map((row) => (
                        <tr key={row.code}>
                            <td key={`category ${row.code}`}>
                                <div className="flex justify-start gap-4 py-2 px-4">
                                    <div className="rounded-sm bg-S2D-neutral-10 px-2 py-1 text-sm text-[#FFFFFF]">
                                        {row.code}
                                    </div>
                                    {findCategoryIcon(row.code) && (
                                        <img
                                            src={
                                                findCategoryIcon(row.code) ??
                                                undefined
                                            }
                                            alt={`category ${row.code} icon`}
                                        />
                                    )}
                                </div>
                            </td>
                            <td key={`status of ${row.code}`}>
                                <div className="py-2 px-4">
                                    {row.type && row.type !== "None"
                                        ? row.type
                                        : "-"}
                                </div>
                            </td>
                            <td key={`${row.code} valid from`}>
                                <div className="py-2 px-4">
                                    {row.validFrom
                                        ? row.validFrom.toLocaleDateString()
                                        : "-"}
                                </div>
                            </td>
                            <td key={`${row.code} valid to`}>
                                <div className="py-2 px-4">
                                    {row.validTo
                                        ? row.validTo.toLocaleDateString()
                                        : "-"}
                                </div>
                            </td>
                            <td key={`${row.code} restrictions`}>
                                <div className="flex justify-start gap-2 py-2 px-4">
                                    {row.restrictionCodes.length
                                        ? row.restrictionCodes.join(", ")
                                        : "-"}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CategoriesTable;
