import EditableContentBlock from "../../../../EditableContentBlock";
import { EditCompanyFormValues } from "../../../../EditCompanyForms/EditCompanyFormValues";
import EditCheckPricingFields from "./EditCheckPricingFields";
import SubmitOrCancelButtonBar from "../../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import useEditCheckPricingForm from "./useEditCheckPricingForm";
import { EditCheckPricingFormValues } from "./useEditCheckPricingForm";
import LoadingMessage from "../../../../ReusableComponents/LoadingMessage/LoadingMessage";

interface EditCheckPricingFormProps {
    initialValues: EditCompanyFormValues;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetFormCallback: () => void
    ) => void;
    canEdit: boolean;
}

export default function EditCheckPricingForm({
    initialValues,
    handleEditCompany,
    canEdit,
}: EditCheckPricingFormProps) {
    const { methods, displayedValues, isLoading } =
        useEditCheckPricingForm(initialValues);

    const resetAndCloseForm = (setIsEdit?: (isEdit: boolean) => void) => {
        methods.reset(displayedValues);
        setTimeout(() => (isLoading.current = false), 0);
        if (typeof setIsEdit === "function") setIsEdit(false);
    };

    return (
        <EditableContentBlock
            title="Check pricing"
            onClosed={resetAndCloseForm}
            disableEdit={!canEdit}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    <div className="p-6">
                        {!isEdit && nonEditableContent()}

                        {isEdit && editableContent(setIsEdit)}
                    </div>
                </>
            )}
        </EditableContentBlock>
    );

    function editableContent(setIsEdit: (isEdit: boolean) => void) {
        function handleEditCheckPricing(data: EditCheckPricingFormValues) {
            isLoading.current = true;
            const newSettingsData = { settings: { pricing: data.pricing } };
            handleEditCompany(newSettingsData, () =>
                resetAndCloseForm(setIsEdit)
            );
        }

        return (
            <form onSubmit={methods.handleSubmit(handleEditCheckPricing)}>
                <EditCheckPricingFields methods={methods} />
                <div className="pt-7" />
                <SubmitOrCancelButtonBar
                    onCancel={() => resetAndCloseForm(setIsEdit)}
                    isLoading={isLoading.current}
                />
            </form>
        );
    }

    function nonEditableContent() {
        const pricing = displayedValues?.pricing;
        return (
            <div className="space-y-2">
                <p>
                    <b>Licence check: </b>£
                    {loaderWrapper(pricing?.licenceCheckPrice?.toFixed(2))}
                </p>
                <p>
                    <b>Manual check: </b>£
                    {loaderWrapper(pricing?.manualCheckPrice?.toFixed(2))}
                </p>
                <p>
                    <b>Irish licence check: </b>£
                    {loaderWrapper(pricing?.irishLicenceCheckPrice?.toFixed(2))}
                </p>
                <p>
                    <b>Foreign licence check: </b>£
                    {loaderWrapper(
                        pricing?.foreignLicenceCheckPrice?.toFixed(2)
                    )}
                </p>
                <p>
                    <b>Grey Fleet check: </b>£
                    {loaderWrapper(pricing?.greyFleetCheckPrice?.toFixed(2))}
                </p>
            </div>
        );
    }

    function loaderWrapper(content?: string): JSX.Element | string | undefined {
        return isLoading.current && content ? <LoadingMessage /> : content;
    }
}
