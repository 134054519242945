import StatusPill from "../../../../ReusableComponents/StatusPill/StatusPill";

export interface InfoCardProps {
    icon: JSX.Element;
    rowOneText: string;
    rowTwoText: string;
    rangeName?: string;
    backgroundColour?:
        | "bg-S2D-error-40"
        | "bg-S2D-warning-90"
        | "bg-S2D-success-80";
}

export default function RiskRangeCard({
    icon,
    rowOneText,
    rowTwoText,
    backgroundColour = "bg-S2D-success-80",
    rangeName = "No Name Set",
}: InfoCardProps) {
    const textColour =
        backgroundColour === "bg-S2D-error-40"
            ? "text-white"
            : "text-S2D-neutral-10";
    const baseCardClasses =
        "inline-flex flex-row items-center space-x-5  xl:space-x-10 justify-around p-3 rounded-sm text-S2D-neutral-30";
    const cardClasses = `${baseCardClasses} ${backgroundColour}`;

    return (
        <div className={cardClasses} key={rowOneText}>
            <div className="flex flex-row items-center space-x-3">
                <div className="h-8 w-8">{icon}</div>
                <div
                    className={`flex flex-col whitespace-nowrap ${textColour}`}
                >
                    <div className="text-xs font-bold">
                        {rowOneText.toLocaleUpperCase()}
                    </div>
                    <div className="text-xl font-bold">
                        {rowTwoText.toLocaleUpperCase()}
                    </div>
                </div>
            </div>
            <StatusPill
                bgColour="bg-black"
                textColour="text-white"
                status={rangeName}
            />
        </div>
    );
}
