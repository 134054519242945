import PaginationNav from "../PaginationNav";
import {
    initAuditTableHeaders,
    initAuditsTableContents,
} from "./AuditTableInit";
import {
    AuditAction,
    type ApiAuditsResponse,
} from "../../models/api/admin/audits";
import Table from "../ReusableComponents/Table/Table";
import useLocalStorage from "../../hooks/useLocalStorage/useLocalStorage";
import { HiddenCols, TableHeader } from "../ReusableComponents/Table";
import AuditTableFilters from "./AuditTableFilters";
import { type Dispatch, type SetStateAction } from "react";
import { type DateTimeRange } from "../../api/admin/audits";

export type AuditTableProps = {
    auditList?: ApiAuditsResponse;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    searchQuery: string;
    setSearchQuery: Dispatch<SetStateAction<string>>;
    actionFilter: AuditAction | null;
    setActionFilter: Dispatch<SetStateAction<AuditAction | null>>;
    dateTimeRange: DateTimeRange;
    setDateTimeRange: Dispatch<SetStateAction<DateTimeRange>>;
    userId?: string;
    setUserId: Dispatch<SetStateAction<string | undefined>>;
};

const AuditTable: React.FC<AuditTableProps> = ({
    auditList,
    setPageNumber,
    pageSize,
    setPageSize,
    searchQuery,
    setSearchQuery,
    actionFilter,
    setActionFilter,
    dateTimeRange,
    setDateTimeRange,
    userId,
    setUserId,
}) => {
    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "AuditTableHeaders",
        initAuditTableHeaders
    );

    const initHiddenCols = new Array(0);
    tableHeaders.map((item) =>
        initHiddenCols.push({
            id: item.id,
            hidden: item.hide ?? false,
        })
    );

    const [hiddenCols, setHiddenCols] = useLocalStorage<HiddenCols[]>(
        "auditTableHiddenCols",
        initHiddenCols
    );

    const auditTableMenuGroups = ["Audit Details", "Editor Details"];

    return (
        <>
            <AuditTableFilters
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                actionFilter={actionFilter}
                setActionFilter={setActionFilter}
                dateTimeRange={dateTimeRange}
                setDateTimeRange={setDateTimeRange}
                userId={userId}
                setUserId={setUserId}
            />
            {auditList?.items.length ? (
                <>
                    <Table
                        tableHeaders={tableHeaders}
                        setTableHeaders={setTableHeaders}
                        tableContents={initAuditsTableContents(auditList.items)}
                        menuGroups={auditTableMenuGroups}
                        hiddenCols={hiddenCols}
                        setHiddenCols={setHiddenCols}
                    />
                    <PaginationNav
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalCount={auditList.totalCount}
                        totalPages={auditList.totalPages}
                        currentPage={auditList.pageNumber}
                        hasNextPage={auditList.hasNextPage}
                        hasPreviousPage={auditList.hasPreviousPage}
                    />
                </>
            ) : (
                <p>No changes have been made.</p>
            )}
        </>
    );
};

export default AuditTable;
