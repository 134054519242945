import AwesomeDebouncePromise from "awesome-debounce-promise";
import { FieldError } from "react-hook-form";
import { useGetUsers } from "../api/safe2Drive/user";
import { errorToast } from "../toast";
import FormSearchDropdown, {
    FormSearchDropdownOption,
} from "./FormSearchDropdown/FormSearchDropdown";
import useAuth from "../auth";

export type UserSearchDropdownProps = {
    value?: string;
    onChange: (
        value: string | null,
        option: FormSearchDropdownOption | null
    ) => void;
    disabled?: boolean;
    required?: boolean;
    error?: FieldError;
    sharedWith?: string[];
    label?: string;
};

const UserSearchDropdown: React.FC<UserSearchDropdownProps> = ({
    value,
    onChange,
    error,
    disabled,
    sharedWith,
    label = "Share with",
}) => {
    //just used some discrete values for users data count as there will never be too many of these
    const { usersData, usersError, isLoadingUsers } = useGetUsers(
        "LastName",
        "asc",
        1,
        100
    );
    const { userDetails } = useAuth();

    const sharedWithUserList: { name: string; userId: string }[] = new Array(0);
    sharedWith?.map((userId) => {
        if (userDetails && usersData && userId !== userDetails.userId) {
            const data = usersData.items.find((user) => user.userId === userId);
            sharedWithUserList.push({
                name: `${data?.firstName} ${data?.lastName}`,
                userId: userId,
            });
        }
    });

    const loadOptions = (
        value: string,
        callback: (options: FormSearchDropdownOption[]) => void
    ) => {
        if (!usersData || usersError || !userDetails) {
            errorToast("No users could be found");
            return;
        }
        const usersList: FormSearchDropdownOption[] = new Array(0);
        usersData.items.map((user) => {
            if (
                !JSON.stringify(sharedWithUserList).includes(user.userId) &&
                user.userId !== userDetails.userId
            ) {
                usersList.push({
                    value: user.userId,
                    label: `${user.firstName} ${user.lastName}`,
                });
            }
        });

        const checkQuery = (users: FormSearchDropdownOption) => {
            return users.label.toLowerCase().includes(value.toLowerCase());
        };

        const filteredUsersList = usersList.filter(checkQuery);

        callback(filteredUsersList ?? []);
    };

    const debouncedLoadOptions = AwesomeDebouncePromise(loadOptions, 700, {
        onlyResolvesLast: true,
    });

    return (
        <>
            <FormSearchDropdown
                label={label}
                tooltip="Share this filter with your colleagues"
                value={value}
                loadOptions={debouncedLoadOptions}
                onChange={onChange}
                error={error}
                disabled={disabled}
                isClearable
            />
            {sharedWithUserList.length > 0 && (
                <div className="mt-2">
                    <b>Shared with:</b>
                    {sharedWithUserList.map((user) => (
                        <div key={user.userId}>{user.name}</div>
                    ))}
                </div>
            )}
        </>
    );
};

export default UserSearchDropdown;
