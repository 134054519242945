import { Controller, UseFormReturn } from "react-hook-form";
import AccordionItem from "../AccordionItem";
import FormTextAreaInput from "../FormTextAreaInput";
import { AddDriverFormValues } from "./AddDriverFormValues";

type DriverCommentFieldsProps = {
    methods: UseFormReturn<AddDriverFormValues>;
};

const DriverCommentFields: React.FC<DriverCommentFieldsProps> = ({
    methods,
}) => {
    return (
        <AccordionItem title="Add comment">
            <Controller
                name="driverComment"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                    <FormTextAreaInput
                        id="comment"
                        placeholder="Enter comment"
                        rows={2}
                        value={value}
                        onChange={onChange}
                    />
                )}
            />
        </AccordionItem>
    );
};

export default DriverCommentFields;
