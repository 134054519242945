import { useState } from "react";
import { Upload, Plus, Trash } from "phosphor-react";
import FormDocumentUploadModal from "./FormDocumentUploadModal";
import { Menu } from "@headlessui/react";
import clsx from "clsx";

type FormDocumentUploadDropdownProps = {
    refetchDrivers: () => void;
};

const FormDocumentUploadDropdown: React.FC<FormDocumentUploadDropdownProps> = ({
    refetchDrivers,
}) => {
    const [isBulkUploadOpen, setBulkUploadOpen] = useState(false);
    const [isBulkDelete, setIsBulkDelete] = useState(false);

    const handleAddDriversClick = () => {
        setBulkUploadOpen(true);
    };
    const handleDeleteDriversClick = () => {
        setIsBulkDelete(true);
        setBulkUploadOpen(true);
    };

    const handleCloseBulkUpload = () => {
        setIsBulkDelete(false);
        setBulkUploadOpen(false);
    };
    return (
        <div className="min-w-fit grow justify-center md:flex-none">
            <Menu>
                {({ open }) => (
                    <div className="relative">
                        <Menu.Button className="w-full">
                            <div
                                className={clsx(`flex items-center justify-center
                                rounded-sm border-2 border-S2D-neutral-10 bg-S2D-neutral-100
                               text-[16px] font-semibold text-S2D-neutral-10  ${
                                   open ? "rounded-b-none" : ""
                               }`)}
                            >
                                <div className="flex h-[45px] items-center gap-4 px-4">
                                    <Upload size={18} weight="fill" />
                                    <div>Import CSV</div>
                                </div>
                            </div>
                        </Menu.Button>
                        <Menu.Items
                            className={`absolute top-[100%] z-10 flex min-w-[100%] flex-col 
                        gap-3 rounded bg-white shadow-mui`}
                        >
                            <Menu.Item>
                                {({ active, disabled }) => (
                                    <div
                                        className={`flex cursor-pointer items-center gap-2 p-3 ${
                                            active
                                                ? "bg-S2D-light-green-80"
                                                : ""
                                        }`}
                                        onClick={() => handleAddDriversClick()}
                                    >
                                        <div className="flex flex-row items-center">
                                            <div>
                                                <Plus size={20} weight="fill" />
                                            </div>
                                            <div className="pl-3">
                                                Add drivers
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Menu.Item>
                            {/* <Menu.Item>
                                {({ active, disabled }) => (
                                    <div
                                        className={clsx(
                                            `flex items-center gap-2 p-3`,
                                            active
                                                ? "bg-S2D-light-green-80"
                                                : "",
                                            disabled
                                                ? "cursor-not-allowed"
                                                : "cursor-pointer"
                                        )}
                                        onClick={() =>
                                            handleDeleteDriversClick()
                                        }
                                    >
                                        <div className="flex flex-row items-center whitespace-nowrap">
                                            <div>
                                                <Trash
                                                    size={20}
                                                    weight="fill"
                                                />
                                            </div>
                                            <div className="pl-3">
                                                Delete drivers
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Menu.Item> */}
                        </Menu.Items>
                    </div>
                )}
            </Menu>
            <FormDocumentUploadModal
                isOpen={isBulkUploadOpen}
                onClose={() => handleCloseBulkUpload()}
                isBulkDelete={isBulkDelete}
                refetchDrivers={refetchDrivers}
            />
        </div>
    );
};

export default FormDocumentUploadDropdown;
