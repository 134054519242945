import {
    Routes,
    Route,
    useNavigate,
    useLocation,
    Navigate,
} from "react-router-dom";
import useAuth from "./auth";
import { useEffect } from "react";
import FourOFourPage from "./pages/404";
import DashboardPage from "./pages/Dashboard";
import EDeclarationPage from "./pages/EDeclaration";
import AdminAddDriver from "./pages/Admin/AdminAddDriver";
import AdminEditDriver from "./pages/Admin/AdminEditDriver";
import AdminDrivers from "./pages/Admin/AdminDriversPages/AdminDrivers";
import AdminCompanies from "./pages/Admin/AdminCompanies";
import AdminAddCompany from "./pages/Admin/AdminAddCompany";
import AdminEditCompany from "./pages/Admin/AdminEditCompany";
import AdminDisableCompany from "./pages/Admin/AdminEditCompany/nestedRoutes/AdminDisableCompany";
import AdminResellers from "./pages/Admin/AdminResellers";
import AdminAddReseller from "./pages/Admin/AdminAddReseller";
import AdminEditReseller from "./pages/Admin/AdminEditReseller";
import LogInPage from "./pages/Unauthenticated/LogIn";
import ForgottenPasswordPage from "./pages/Unauthenticated/ForgottenPassword";
import ForgottenPasswordResetPage from "./pages/Unauthenticated/ForgottenPasswordReset";
import LogIn2FAPage from "./pages/Unauthenticated/LogIn2fa";
import AdminAuditDriver from "./pages/Admin/AdminAuditDriver";
import GreyFleetPage from "./pages/GreyFleet";
import AdminGreyFleet from "./pages/Admin/AdminGreyFleet";
import CreatePasswordPage from "./pages/Unauthenticated/CreatePassword";
import AdminFileSafe from "./pages/Admin/AdminFileSafe";
import AdminNewTransfer from "./pages/Admin/AdminNewTransfer";
import AdminViewTransfer from "./pages/Admin/AdminViewTransfer";
import AdminDownloadMonthlyReport from "./pages/Admin/AdminDownloadMonthlyReport";
import AdminProfessionalDrivers from "./pages/Admin/AdminDriversPages/AdminProfessionalDrivers";
import AdminAuditCompany from "./pages/Admin/AdminAuditCompany";
import Staff from "./pages/SafeToDriveAdmin/Staff";
import { isS2dUser } from "./auth/userAccessHelpers";
import AdminDeclarations from "./pages/Admin/AdminDeclarations";
import DvlaChecks from "./pages/SafeToDriveAdmin/DvlaChecks";
import PageLayout from "./components/PageLayout";
import Reporting from "./pages/Reporting";
import AdminAnalysisCharts from "./pages/Admin/AdminAnalysisCharts";
import { FileTransferUser } from "./models/api/admin/fileSafe";
import { ThemeProvider } from "./theme/ThemeContext";

const nestedPaths = {
    companyDisableDelete: "disable-delete",
    companyAudit: "audit",
};

export const appPaths = {
    home: "/",
    dashboard: "/dashboard",
    fourOFour: "/404",
    login: "/auth/login",
    login2fa: "/auth/login2fa",
    forgottenPassword: "/auth/password/forgotten",
    forgottenPasswordReset: (id: string) => `/auth/${id}/passwordReset`,
    createPassword: (id: string) => `/auth/password/create/${id}`,
    edeclaration: (id: string) => `/e-declaration/${id}`,
    greyFleet: (
        id: string,
        vehicleId?: string,
        update?: "insurance" | "vehicle",
        token?: string
    ) =>
        `/greyfleet/${id}${
            vehicleId
                ? update
                    ? `?vehicleId=${vehicleId}&update=${update}`
                    : `?vehicleId=${vehicleId}`
                : ""
        }${token ? `?token=${token}` : ""}`,
    // 11/07/23 NB: logic in SideNav requires all driver routes contain 'driver'
    adminDrivers: "/admin/all-drivers",
    adminAddDriver: "/admin/all-drivers/add",
    adminEditDriver: (id: string) => `/admin/all-drivers/${id}`,
    adminAuditDriver: (id: string) => `/admin/all-drivers/${id}/audit`,
    adminProfessionalDrivers: `/admin/professional-drivers`,
    adminDeclarations: "/admin/driver-declarations",
    adminAnalysisCharts: (path: string, chartType: string) =>
        `/admin/${path}/${chartType}`,
    adminCompanies: "/admin/company",
    adminAddCompany: "/admin/company/add",
    adminEditCompany: (id: string) => `/admin/company/${id}`,
    adminDisableCompany: (id: string) =>
        `/admin/company/${id}/${nestedPaths.companyDisableDelete}`,
    adminAuditCompany: (id: string) =>
        `/admin/company/${id}/${nestedPaths.companyAudit}`,
    adminGreyFleet: "/admin/greyfleet",
    adminResellers: "/admin/reseller",
    adminAddReseller: "/admin/reseller/add",
    adminEditReseller: (id: string) => `/admin/reseller/${id}`,
    adminFileSafe: "/admin/filesafe",
    adminNewTransfer: (recipient?: FileTransferUser) =>
        recipient
            ? `/admin/filesafe/new?Recipient=${JSON.stringify(recipient)}`
            : "/admin/filesafe/new",
    adminViewTransfer: (id: string) => `/admin/filesafe/view/${id}`,
    s2dStaff: "/s2dAdmin/staff",
    s2dDvlaChecks: "/s2dAdmin/dvla",
    communications: "/communications",
    emailLog: "/email-log",
    financials: "/financials",
    reporting: "/reporting",
    monthlyReportDownload: (companyMonthlyReportId: string) =>
        `/scheduled-reports/${companyMonthlyReportId}`,
};

const AppRoutes: React.FC = () => {
    const { token, expires, logOut, decodedToken, userDetails } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (
            !token &&
            !(
                location.pathname.includes("/auth/") ||
                location.pathname.includes("/e-declaration/") ||
                (location.pathname.includes("/greyfleet/") &&
                    !location.pathname.includes("/admin/greyfleet/"))
            )
        ) {
            navigate(appPaths.login);
        }
    }, [token]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const handleLogOut = () => {
        logOut();
        navigate(appPaths.login);
    };

    useEffect(() => {
        if (token) {
            if (!expires) return;
            //update the timer on every reload
            const expiryTime = new Date(expires);
            const start = new Date();
            const millsToExpire = expiryTime.getTime() - start.getTime();
            setTimeout(handleLogOut, millsToExpire);
        }
    }, []);

    useEffect(() => {
        // Log out if the token expires
        if (expires && new Date() > new Date(expires)) {
            handleLogOut();
        }
    }, [location]);

    return (
        <ThemeProvider companyId={userDetails?.companyId}>
            <Routes>
                <Route path="*" element={<FourOFourPage />} />
                <Route
                    path={appPaths.home}
                    element={<Navigate to={appPaths.dashboard} />}
                />
                <Route path={appPaths.dashboard} element={<DashboardPage />} />
                <Route path={appPaths.login} element={<LogInPage />} />
                <Route path={appPaths.login2fa} element={<LogIn2FAPage />} />
                <Route
                    path={appPaths.forgottenPassword}
                    element={<ForgottenPasswordPage />}
                />
                <Route
                    path={appPaths.forgottenPasswordReset(":id")}
                    element={<ForgottenPasswordResetPage />}
                />
                <Route
                    path={appPaths.createPassword(":id")}
                    element={<CreatePasswordPage />}
                />
                <Route
                    path={appPaths.edeclaration(":id")}
                    element={<EDeclarationPage />}
                />
                <Route
                    path={appPaths.greyFleet(":id")}
                    element={<GreyFleetPage />}
                />
                <Route
                    path={appPaths.adminAddDriver}
                    element={<AdminAddDriver />}
                />
                <Route
                    path={appPaths.adminDrivers}
                    element={<AdminDrivers />}
                />
                <Route
                    path={appPaths.adminEditDriver(":id")}
                    element={<AdminEditDriver />}
                />
                <Route
                    path={appPaths.adminProfessionalDrivers}
                    element={<AdminProfessionalDrivers />}
                />
                {isS2dUser(decodedToken?.role) && (
                    <>
                        {/* client pages */}
                        <Route
                            path={appPaths.adminCompanies}
                            element={<AdminCompanies />}
                        />
                        <Route
                            // the below '/*' is required to allow nested routes to work
                            path={`${appPaths.adminEditCompany(":id")}/*`}
                            element={<AdminEditCompany />}
                        >
                            <Route
                                path={nestedPaths.companyDisableDelete}
                                element={<AdminDisableCompany />}
                            />
                            <Route
                                path={nestedPaths.companyAudit}
                                element={<AdminAuditCompany />}
                            />
                        </Route>
                        <Route
                            path={appPaths.adminAddCompany}
                            element={<AdminAddCompany />}
                        />

                        {/* staff page */}
                        <Route path={appPaths.s2dStaff} element={<Staff />} />
                    </>
                )}
                <Route
                    path={appPaths.adminResellers}
                    element={<AdminResellers />}
                />
                <Route
                    path={appPaths.adminAddReseller}
                    element={<AdminAddReseller />}
                />
                <Route
                    path={appPaths.adminEditReseller(":id")}
                    element={<AdminEditReseller />}
                />
                <Route
                    path={appPaths.adminAuditDriver(":id")}
                    element={<AdminAuditDriver />}
                />
                <Route
                    path={appPaths.adminGreyFleet}
                    element={<AdminGreyFleet />}
                />
                {isS2dUser(decodedToken?.role) && (
                    <Route
                        path={appPaths.s2dDvlaChecks}
                        element={<DvlaChecks />}
                    />
                )}
                {decodedToken?.fileSafe && (
                    <>
                        <Route
                            path={appPaths.adminFileSafe}
                            element={<AdminFileSafe />}
                        />
                        <Route
                            path={appPaths.adminNewTransfer()}
                            element={<AdminNewTransfer />}
                        />
                        <Route
                            path={appPaths.adminViewTransfer(":id")}
                            element={<AdminViewTransfer />}
                        />
                    </>
                )}
                <Route
                    path={appPaths.adminDeclarations}
                    element={<AdminDeclarations />}
                />
                <Route
                    path={appPaths.adminAnalysisCharts(":path", ":chartType")}
                    element={<AdminAnalysisCharts />}
                />
                <Route
                    path={appPaths.communications}
                    element={
                        <PageLayout title="Communications">
                            <p>to be implemented</p>
                        </PageLayout>
                    }
                />
                <Route
                    path={appPaths.emailLog}
                    element={
                        <PageLayout title="Email log">
                            <p>to be implemented</p>
                        </PageLayout>
                    }
                />
                <Route
                    path={appPaths.financials}
                    element={
                        <PageLayout title="Financials">
                            <p>to be implemented</p>
                        </PageLayout>
                    }
                />
                <Route path={appPaths.reporting} element={<Reporting />} />
                <Route
                    path={appPaths.monthlyReportDownload(":companyMonthlyReportId")}
                    element={<AdminDownloadMonthlyReport />}
                />
            </Routes>
        </ThemeProvider>
    );
};

export default AppRoutes;
