import { type ApiUsersResponse } from "../../models/api/safe2DriveAdmin/user";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useGetUsers = (
    orderBy: string,
    sortOrder: string,
    pageNumber: number,
    pageSize: number
) => {
    const { data, error, isLoading, refetch } = useS2DApiSWR<ApiUsersResponse>(
        `api/users?OrderBy=${orderBy} ${sortOrder}&PageNumber=${pageNumber}&PageSize=${pageSize}`
    );

    return {
        usersData: data,
        usersError: error,
        isLoadingUsers: isLoading,
        refetchUsers: refetch,
    };
};
