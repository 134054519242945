import {
    CompanyDriverDeclarationAnalysisResponse,
    DeclarationAnalysisCategories,
} from "../../models/api/admin/companyDeclarationAnalysis";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useGetCompanyDeclarationAnalysis = (
    companyId?: string,
    departmentId?: string,
    option?: DeclarationAnalysisCategories
) => {
    const pathArray = ["api/companies/declarationAnalysis"];
    const pushConnector = () =>
        pathArray.length === 1 ? pathArray.push("?") : pathArray.push("&");

    if (companyId) {
        pushConnector();
        pathArray.push(`CompanyId=${companyId}`);
    }
    if (departmentId) {
        pushConnector();
        pathArray.push(`DepartmentId=${departmentId}`);
    }
    if (option) {
        pushConnector();
        pathArray.push(`Includes=${option}`);
    }
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<CompanyDriverDeclarationAnalysisResponse>(
            pathArray.join("")
        );

    return {
        companyDeclarationAnalysis: data,
        isCompanyDeclarationAnalysisLoading: isLoading,
        companyDeclarationAnalysisError: error,
        refetchCompanyDeclarationAnalysis: refetch,
    };
};
