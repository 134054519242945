import { CheckCircle, Scroll, Warning, WarningOctagon } from "phosphor-react";
import { type CompanyDeclarationsStats } from "../../models/api/admin/companyStats";
import StatisticSummaryBox from "../StatisticSummaryBox";
import { type CompanyDeclarationsQueryDto } from "../../models/app/admin/companyDashboard";

type CompanyDashboardDeclarationsTabProps = {
    data?: CompanyDeclarationsStats;
    onLinkClick?: (key: keyof CompanyDeclarationsQueryDto) => void;
};

const CompanyDashboardDeclarationsTab: React.FC<
    CompanyDashboardDeclarationsTabProps
> = ({ data, onLinkClick }) => {
    // NB avoiding 'flex-wrap' in the PDF report as it causes issues with domtoimage
    const compatibleStyle = onLinkClick
        ? "flex grow flex-wrap gap-4"
        : "flex flex-col xl:flex-row gap-4";
    return (
        <div className="flex w-full flex-col gap-4">
            <StatisticSummaryBox
                icon={Scroll}
                iconColor="bg-S2D-neutral-100"
                label="driver declarations"
                statistic={data?.driverDeclarations}
                onLinkClick={() =>
                    onLinkClick ? onLinkClick("withDeclarations") : undefined
                }
            />
            <div className={compatibleStyle}>
                <StatisticSummaryBox
                    icon={CheckCircle}
                    label="complete"
                    statistic={data?.declarationsComplete}
                    onLinkClick={() =>
                        onLinkClick
                            ? onLinkClick("completedDeclarations")
                            : undefined
                    }
                />
                <StatisticSummaryBox
                    icon={Warning}
                    label="expiring within 6 months"
                    statistic={data?.declarationsExpiring}
                    onLinkClick={() =>
                        onLinkClick
                            ? onLinkClick("expiringDeclarations")
                            : undefined
                    }
                />
                <StatisticSummaryBox
                    icon={WarningOctagon}
                    label="expired or not returned"
                    statistic={data?.declarationsExpired}
                    onLinkClick={() =>
                        onLinkClick
                            ? onLinkClick("declarationsExpiredOrNotReturned")
                            : undefined
                    }
                />
            </div>
        </div>
    );
};

export default CompanyDashboardDeclarationsTab;
