import { createTheme, ThemeProvider } from "@mui/material";
import { Button as MuiButton } from "@mui/material";
import { MagnifyingGlass, Pencil } from "phosphor-react";
import { Dispatch, SetStateAction, useState } from "react";
import { useFilter, useGetFilters } from "../../api/admin/filter";
import DialogBox from "../DialogBox";
import DriversListFiltersModal from "./DriversListFiltersModal";
import DriverListFiltersDropdown from "./DriverListFiltersDropdown";
import DriverListSaveEditFilter from "./DriverListSaveEditFilter";
import { type DriversListFiltersFormValues } from "./DriversListFiltersForm/DriversListFiltersFormValues";
import { useSavedFilters } from "./useSavedFilters";
import { type FilterType } from "../../models/app/admin/filter";
import { useLocation } from "react-router-dom";
import { driverPageLocation } from "../../utils/driverPageLocation";
import FormTextInput from "../FormTextInput";
import { driverListFiltersDefaultValues } from "./DriversListFiltersForm/driversListFiltersDefaultValues";

export type DriversListFiltersProps = {
    filterModalOpen: "add" | "edit" | false;
    setFilterModalOpen: Dispatch<SetStateAction<false | "add" | "edit">>;
    appliedFilter: FilterType;
    setAppliedFilter: (
        value:
            | FilterType
            | ((val: {
                  filterId: string;
                  filterFields: DriversListFiltersFormValues;
              }) => {
                  filterId: string;
                  filterFields: DriversListFiltersFormValues;
              })
    ) => void;
    handleFetchDrivers: (
        filters?: DriversListFiltersFormValues,
        filterId?: string,
        noSave?: boolean
    ) => Promise<boolean>;
    isFetchingDrivers: boolean;
    query: string | null;
    setQuery: (value: string | null) => void;
};

const DriversListFilters: React.FC<DriversListFiltersProps> = ({
    filterModalOpen,
    setFilterModalOpen,
    appliedFilter,
    setAppliedFilter,
    handleFetchDrivers,
    isFetchingDrivers,
    query,
    setQuery,
}) => {
    const filtersTheme = createTheme({
        typography: {
            fontFamily: "Inter",
        },
        palette: {
            primary: {
                main: "#1B1C17",
            },
        },
    });

    const { filter, isAddingFilter, isEditingFilter, isDeletingFilter } =
        useFilter(appliedFilter.filterId ?? null);
    const { filters, filtersError, isfiltersLoading, refetchFilters } =
        useGetFilters();

    const [isEdit, setIsEdit] = useState(false);

    // remembers form fields when adding a filter and going back to change something before saving
    const [selectedFilter, setSelectedFilter] =
        useState<DriversListFiltersFormValues>();

    const prefilledFormValues = filter?.filterId
        ? filter
        : appliedFilter.filterFields && {
              filterId: "",
              name: "",
              filterDetails: appliedFilter.filterFields,
              userIds: [],
          };

    const pageLocation = useLocation();
    const location = driverPageLocation(pageLocation.pathname);
    const filterDefaultValues = driverListFiltersDefaultValues(location);

    const handleEditMode = (edit: boolean) => {
        setIsEdit(edit);
        if (edit && filter) {
            setSelectedFilter(filter.filterDetails);
        } else {
            setSelectedFilter(filterDefaultValues);
        }
    };

    const handleOpenFilters = () => {
        setFilterModalOpen("add");
        handleEditMode(false);
    };

    const handleOpenEditDialog = () => {
        setFilterModalOpen("edit");
        handleEditMode(true);
    };

    const handleCloseEditDialog = () => {
        setFilterModalOpen(false);
        handleEditMode(false);
    };

    const handleEditFilterProperties = () => {
        setFilterModalOpen("add");
    };

    const filterValues =
        selectedFilter !== filterDefaultValues
            ? selectedFilter
            : appliedFilter.filterFields;

    const {
        handleSaveFilter,
        handleSubmitNewFilter,
        handleSubmitEditedFilter,
        handleDeleteFilter,
    } = useSavedFilters(
        isEdit,
        filterValues,
        appliedFilter,
        setAppliedFilter,
        setSelectedFilter,
        setFilterModalOpen,
        refetchFilters,
        handleFetchDrivers,
        handleEditMode,
        location
    );

    const filtersChanged =
        JSON.stringify(appliedFilter.filterFields) !==
        JSON.stringify(filterDefaultValues);
    const canEditFilter = filtersChanged;

    const handleClearFilters = () => {
        handleFetchDrivers(filterDefaultValues, "");
    };

    return (
        <div className="flex w-full flex-col flex-wrap items-center justify-start gap-6 md:flex-row">
            <FormTextInput
                StartAdornmentIcon={MagnifyingGlass}
                placeholder={"Search"}
                className="w-full md:w-[320px]"
                value={query ?? ""}
                onChange={(e) => setQuery(e.target.value ?? null)}
                disabled={isFetchingDrivers}
            />
            <div
                className={`w-full justify-start rounded-sm border-2 md:w-fit 
                    ${
                        canEditFilter
                            ? "border-S2D-light-green-80 bg-S2D-dark-green-70.1 "
                            : "border-S2D-neutral-10 "
                    }`}
            >
                <ThemeProvider theme={filtersTheme}>
                    <div className="flex min-w-fit flex-row justify-between">
                        <DriverListFiltersDropdown
                            onOpenFilters={handleOpenFilters}
                            savedFilters={filters}
                            onApplyFilter={handleFetchDrivers}
                            onClearFilter={handleClearFilters}
                            appliedFilter={filter}
                            filtersError={filtersError}
                            isFiltersLoading={isfiltersLoading}
                        />
                        <MuiButton
                            id="edit-filters-button"
                            aria-haspopup="true"
                            disableRipple
                            disabled={!canEditFilter}
                            onClick={handleOpenEditDialog}
                            sx={{
                                padding: 0,
                                borderLeft: 2,
                                borderColor: `${
                                    canEditFilter
                                        ? "var(--secondary)"
                                        : "#1B1C17"
                                }`,
                                borderRadius: 0,
                            }}
                        >
                            <div className="flex h-12 items-center">
                                <Pencil size={24} />
                            </div>
                        </MuiButton>
                    </div>
                </ThemeProvider>
            </div>
            <DialogBox
                isOpen={filterModalOpen === "add"}
                onClose={() => setFilterModalOpen(false)}
                title="Filters"
                size="xl"
                closeButton
            >
                <DriversListFiltersModal
                    isEditingFilter={isEdit}
                    selectedFilter={selectedFilter}
                    onSaveFilter={handleSaveFilter}
                    onApplyFilter={handleFetchDrivers}
                    isApplyingFilter={isFetchingDrivers}
                    appliedFilter={appliedFilter}
                />
            </DialogBox>
            <DialogBox
                isOpen={filterModalOpen === "edit"}
                title={isEdit ? "Edit filter" : "Save filter"}
                size="xl"
                closeButton
                onClose={() => handleCloseEditDialog()}
            >
                <DriverListSaveEditFilter
                    selectedFilter={selectedFilter}
                    onEditFilter={handleEditFilterProperties}
                    onSubmitNewFilter={handleSubmitNewFilter}
                    onSubmitEditedFilter={handleSubmitEditedFilter}
                    isEdit={isEdit}
                    isAddingFilter={isAddingFilter}
                    isEditingFilter={isEditingFilter}
                    handleCloseEditDialog={handleCloseEditDialog}
                    onDeleteFilter={handleDeleteFilter}
                    isDeletingFilter={isDeletingFilter}
                    appliedFilter={prefilledFormValues}
                    onApplyFilter={handleFetchDrivers}
                />
            </DialogBox>
        </div>
    );
};

export default DriversListFilters;
