import { useMemo, useRef, useState } from "react";
import { ApiDepartmentItem } from "../../models/api/admin/department";
import useLocalStorage from "../../hooks/useLocalStorage";
import { CompanyStatsCategories } from "../../models/api/admin/companyStats";
import { useGetCompanyStats } from "../../api/admin/companyStats";
import { useDownloadComponentToPdf } from "../../hooks/useDownloadComponentToPdf";
import { ApiCompanyResponse } from "../../models/api/admin/company";

export const useCompanyProfileStats = (
    selectedCompany?: ApiCompanyResponse | null,
    selectedDepartment?: ApiDepartmentItem | null
) => {
    // default to 1 here, as company profile skips the "summary" option
    const [tabOption, setTabOption] = useLocalStorage<number>(
        `ClientProfileAnalysisSelectedTab`,
        1
    );
    const tabOptions = Object.values(CompanyStatsCategories);
    // client profile does not include "summary" tab so shift index to match tab name
    const currentOption = useMemo(() => tabOptions[tabOption + 1], [tabOption]);
    const { companyStatsData, isCompanyStatsLoading } = useGetCompanyStats(
        selectedCompany?.companyId,
        selectedDepartment?.departmentId,
        undefined,
        undefined,
        currentOption
    );

    // PDF report
    const { companyStatsData: pdfStatsData } = useGetCompanyStats(
        selectedCompany?.companyId,
        selectedDepartment?.departmentId
    );

    const reportRef = useRef<HTMLDivElement | null>(null);
    const { generatePDF } = useDownloadComponentToPdf(reportRef);
    const [showReportView, setShowReportView] = useState(false);
    const [isLoadingPdfReport, setIsLoadingPdfReport] = useState(false);

    const handleDownloadPdfReport = (): void => {
        setIsLoadingPdfReport(true);
        setShowReportView(true);

        const fileNameArray = [
            selectedCompany?.companyName ?? "All clients",
            selectedDepartment?.departmentName ?? "All departments",
            "Driver Declarations report",
        ];
        generatePDF(() => {
            setShowReportView(false);
            setIsLoadingPdfReport(false);
        }, fileNameArray.join("-"));
    };

    return {
        tabOption,
        setTabOption,
        companyStatsData,
        isCompanyStatsLoading,
        pdfStatsData,
        isLoadingPdfReport,
        reportRef,
        showReportView,
        handleDownloadPdfReport,
    };
};
