import * as yup from "yup";
import GreyFleetFormLayout from "./GreyFleetFormLayout";
import Button from "../Button";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../FormTextInput";
import {
    GreyFleetVehicleDetailsFormValidationSchemaFields,
    GreyFleetFormVehicleDetailsValues,
} from "./GreyFleetFormValues";
import FormDateInput from "../FormDateInput";
import React, { useState } from "react";
import FormSimpleRadioGroup from "../FormSimpleRadioGroup";
import FormMessageBox from "../FormMessageBox";
import FormDocumentUpload from "../FormDocumentUpload";
import FormInputLabel from "../FormInputLabel";
import { localToUTC } from "../../utils/localToUTC";

export type Step3InsuranceFields = Pick<
    GreyFleetFormVehicleDetailsValues,
    | "insuranceCertificate"
    | "insuranceCompanyName"
    | "insurancePolicyNumber"
    | "insuranceStartDate"
    | "insuranceEndDate"
    | "insuranceType"
    | "hasBusinessCover"
>;

type Step3InsuranceDetailsProps = {
    defaultValues: Step3InsuranceFields;
    isLoading: boolean;
    onSubmit: (value: Step3InsuranceFields) => void;
    onBackClick: () => void;
    company: string;
    department: string;
    insuranceNotStarted: boolean;
    setInsuranceNotStarted: (value: boolean) => void;
    insuranceExpired: boolean;
    setInsuranceExpired: (value: boolean) => void;
    vehicleReg: string;
    storeFile: File[];
    setStoreFile: (value: React.SetStateAction<File[]>) => void;
};

const validationSchema = yup.object().shape({
    insuranceCertificate:
        GreyFleetVehicleDetailsFormValidationSchemaFields.insuranceCertificate,
    insuranceCompanyName:
        GreyFleetVehicleDetailsFormValidationSchemaFields.insuranceCompanyName,
    insurancePolicyNumber:
        GreyFleetVehicleDetailsFormValidationSchemaFields.insurancePolicyNumber,
    insuranceStartDate:
        GreyFleetVehicleDetailsFormValidationSchemaFields.insuranceStartDate,
    insuranceEndDate:
        GreyFleetVehicleDetailsFormValidationSchemaFields.insuranceEndDate,
    insuranceType:
        GreyFleetVehicleDetailsFormValidationSchemaFields.insuranceType,
    hasBusinessCover:
        GreyFleetVehicleDetailsFormValidationSchemaFields.hasBusinessCover,
});

const Step3InsuranceDetails: React.FC<Step3InsuranceDetailsProps> = ({
    defaultValues,
    isLoading,
    onSubmit,
    company,
    onBackClick,
    department,
    insuranceNotStarted,
    setInsuranceNotStarted,
    insuranceExpired,
    setInsuranceExpired,
    vehicleReg,
    storeFile,
    setStoreFile,
}) => {
    const { handleSubmit, formState, register, control, setValue, trigger } =
        useForm<Step3InsuranceFields>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues,
        });

    const insuranceTypeOptions = [
        { id: "Comprehensive", label: "Fully Comp" },
        { id: "ThirdParty", label: "Third Party" },
    ];

    const businessCoverOptions = [
        { id: "true", label: "Yes" },
        { id: "false", label: "No" },
    ];

    const businessUse = useWatch({ control, name: "hasBusinessCover" });

    const [hasFileError, setHasFileError] = useState(false);

    const handleSetFileData = (
        fileBase64: string,
        fileType: string,
        fileName: string,
        file?: File,
        fileError?: string
    ) => {
        setHasFileError(!!fileError);
        setValue("insuranceCertificate", {
            fileBase64: fileBase64,
            fileType: fileType,
            fileName: fileName,
        });
        if (file) {
            setStoreFile([file]);
        }
        trigger();
    };

    const handleCancelAddFile = () => {
        setValue("insuranceCertificate", null);
        setStoreFile([]);
        setHasFileError(false);
    };

    const handleInsuranceExpiry = (value: Date | null) => {
        if (value && localToUTC(value)) {
            setInsuranceExpired(value < new Date());
        }
    };

    const handleInsuranceNotStarted = (value: Date | null) => {
        if (value) {
            setInsuranceNotStarted(value > new Date());
        }
    };

    const handleSaveInsuranceData = (value: Step3InsuranceFields) => {
        if (hasFileError) return;
        onSubmit(value);
    };

    return (
        <form
            onSubmit={handleSubmit((value) => handleSaveInsuranceData(value))}
            className="flex flex-1 flex-col"
        >
            <GreyFleetFormLayout
                title="Grey Fleet invitation"
                subtitle="Insurance details"
                description="Please confirm your current insurance details."
                company={company}
                department={department}
                content={
                    <>
                        {insuranceExpired && (
                            <FormMessageBox
                                title={`Your insurance for ${vehicleReg} has expired`}
                                message={`You can continue with this form now, but please update your insurance to be able
                                    to drive this vehicle for business purposes. You will receive a reminder in a week 
                                    to come back to this form, re-enter your insurance details and upload your insurance certificate.`}
                                variant="error"
                            />
                        )}
                        {insuranceNotStarted && (
                            <FormMessageBox
                                title={`Your insurance for ${vehicleReg} has not started yet`}
                                message={`You can continue with this form now, but please update your insurance to be able
                                    to drive this vehicle for business purposes. You will receive a reminder in a week 
                                    to come back to this form, re-enter your insurance details and upload your insurance certificate.`}
                                variant="error"
                            />
                        )}
                        {businessUse === "false" && (
                            <FormMessageBox
                                title={"Arrange for business use"}
                                message={`You can continue with this form now, but please contact your insurance provider 
                                    and arrange “for business use” to be included in your policy to be able to drive 
                                    this vehicle for business purposes. You will receive a reminder in a week to come 
                                    back to this form, re-enter your insurance details and upload your insurance certificate.`}
                                variant="error"
                            />
                        )}
                        <div className="sm:grid sm:grid-cols-2 sm:gap-8 ">
                            <div className="flex flex-col">
                                <FormTextInput
                                    label="Insurance company name"
                                    required
                                    id="insuranceCompanyName"
                                    placeholder="Enter insurance company name"
                                    error={
                                        formState.errors.insuranceCompanyName
                                    }
                                    {...register("insuranceCompanyName")}
                                />
                                <FormTextInput
                                    label="Insurance policy number"
                                    required
                                    id="insurancePolicyNumber"
                                    placeholder="Enter insurance policy number"
                                    error={
                                        formState.errors.insurancePolicyNumber
                                    }
                                    {...register("insurancePolicyNumber")}
                                />
                                <Controller
                                    name="insuranceStartDate"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            required
                                            id="insuranceStartDate"
                                            label="Insurance start date"
                                            value={value}
                                            onChange={onChange}
                                            error={
                                                formState.errors
                                                    .insuranceStartDate
                                            }
                                            onBlur={handleInsuranceNotStarted}
                                        />
                                    )}
                                />
                                <Controller
                                    name="insuranceEndDate"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            required
                                            id="insuranceEndDate"
                                            label="Insurance end date"
                                            value={value}
                                            onChange={onChange}
                                            error={
                                                formState.errors
                                                    .insuranceEndDate
                                            }
                                            onBlur={handleInsuranceExpiry}
                                        />
                                    )}
                                />
                                <Controller
                                    name="insuranceType"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormSimpleRadioGroup
                                            label="Type of insurance"
                                            options={insuranceTypeOptions}
                                            value={value}
                                            error={
                                                formState.errors.insuranceType
                                            }
                                            onChange={onChange}
                                            required
                                        />
                                    )}
                                />
                                <Controller
                                    name="hasBusinessCover"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormSimpleRadioGroup
                                            label="Do you have business cover?"
                                            options={businessCoverOptions}
                                            value={value}
                                            error={
                                                formState.errors
                                                    .hasBusinessCover
                                            }
                                            onChange={onChange}
                                            required
                                        />
                                    )}
                                />
                                <div className="mt-4">
                                    <FormInputLabel>
                                        Upload insurance certificate
                                    </FormInputLabel>
                                    <FormDocumentUpload
                                        handleSetData={handleSetFileData}
                                        handleCancelAddDoc={handleCancelAddFile}
                                        defaultValues={storeFile}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
                buttons={
                    <div className="flex justify-end gap-8">
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={onBackClick}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            loading={isLoading}
                        >
                            Next
                        </Button>
                    </div>
                }
            />
        </form>
    );
};

export default Step3InsuranceDetails;
