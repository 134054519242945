import { useState } from "react";
import useAuth from "../../auth";
import { errorToast } from "../../toast";
import { buildApiError, isApiError } from "./apiError";
import {
    S2DApiFetcherResponse,
    S2DApiFetcherWithContentResponse,
    S2DApiResponse,
} from "./apiResponse";
import buildApiUrl from "./buildApiUrl";

// we can extend accepted content types if required
type AcceptedContentTypes =
    | "application/json"
    | "multipart/form-data"
    | "application/octet-stream"
    | "text/csv"
    | "application/vnd.ms-excel";

const buildHeaders = (token?: string, contentType?: AcceptedContentTypes) => {
    const headers = new Headers();

    if (token) headers.set("Authorization", `Bearer ${token}`);
    if (
        contentType === "application/json" ||
        contentType === "text/csv" ||
        contentType === "application/vnd.ms-excel"
    )
        headers.set("Content-Type", contentType);

    return headers;
};

const s2dApiFetcher = async <TReq, TRes>(
    path: string,
    method = "GET",
    body?: TReq,
    token?: string,
    ignoreToastHttpCodes?: number[],
    contentType: AcceptedContentTypes = "application/json"
) => {
    try {
        const url = buildApiUrl(path);

        // Required bodyData condition code to handle FormData body
        // We can assert type based
        let bodyData: BodyInit | undefined = body
            ? JSON.stringify(body)
            : undefined;

        if (contentType === "multipart/form-data") {
            bodyData = body as unknown as FormData;
        } else if (
            contentType === "text/csv" ||
            contentType === "application/vnd.ms-excel"
        ) {
            bodyData = body as unknown as string;
        }

        const response = await fetch(url, {
            method,
            headers: buildHeaders(token, body ? contentType : undefined),
            body: bodyData,
        });

        if (response.status === 204) {
            errorToast(`No content was found at '${path}'.`);
            const error = await buildApiError(method, url, response, path, ignoreToastHttpCodes);
            throw error;
        }

        if (!response.ok) {
            const error = await buildApiError(method, url, response, path, ignoreToastHttpCodes);
            throw error;
        }

        const responseContentType = await response.headers.get("content-type");
        if (
            responseContentType?.includes("application/octet-stream") ||
            responseContentType?.includes("application/vnd.ms-excel") || responseContentType?.includes("application/pdf")
        ) {
            const binaryData = await response.arrayBuffer();
            return binaryData as TRes;
        }
        if (responseContentType?.includes("text/csv")) {
            const csvData = await response.text();
            return csvData as unknown as TRes;
        }

        const responseContent: S2DApiResponse<TRes> = await response.json();

        return responseContent.content;
    } catch (error) {
        if (!isApiError(error)) {
            errorToast(`Failed to fetch '${path}'`);
        }

        throw error;
    }
};

const normalizedS2DApiFetcher = async <TReq, TRes>(
    path: string,
    method = "GET",
    body?: TReq,
    token?: string,
    ignoreToastHttpCodes?: number[],
    contentType?: AcceptedContentTypes
): Promise<S2DApiFetcherResponse<TRes>> => {
    try {
        const response = await s2dApiFetcher<TReq, TRes>(
            path,
            method,
            body,
            token,
            ignoreToastHttpCodes,
            contentType
        );

        return {
            success: true,
            content: response,
        };
    } catch (error) {
        if (isApiError(error)) {
            return {
                success: false,
                error,
            };
        }

        throw error;
    }
};

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

export const useS2DApiFetcher = <TReq, TRes, TArgs>(
    method: HttpMethod,
    requestBuilder: (arg: TArgs) => {
        url: string;
        body?: TReq;
        altToken?: string;
        contentType?: AcceptedContentTypes;
    },
    ignoreToastHttpCodes?: number[],
    authenticated = true
): [(arg: TArgs) => Promise<S2DApiFetcherResponse<TRes>>, boolean] => {
    const { token } = useAuth();
    const [isFetching, setIsFetching] = useState(false);

    const fetcher = async (args: TArgs) => {
        if (!token && authenticated)
            throw new Error(
                "Attempted to make authenticated request without access token."
            );

        const { url, altToken, body, contentType } = requestBuilder(args);

        setIsFetching(true);
        const response = await normalizedS2DApiFetcher<TReq, TRes>(
            url,
            method,
            body,
            altToken ? altToken : token,
            ignoreToastHttpCodes,
            contentType
        );
        setIsFetching(false);

        return response;
    };

    return [fetcher, isFetching];
};

//for when more information is supplied via response.content, eg bulk upload
const s2dApiFetcherWithContent = async <TReq, TRes>(
    path: string,
    method = "GET",
    body?: TReq,
    token?: string,
    ignoreToastHttpCodes?: number[],
    contentType: AcceptedContentTypes = "application/json"
): Promise<S2DApiFetcherWithContentResponse<TRes>> => {
    try {
        const url = buildApiUrl(path);

        let bodyData: BodyInit | undefined = body
            ? JSON.stringify(body)
            : undefined;

        if (contentType === "multipart/form-data")
            bodyData = body as unknown as FormData;

        const response = await fetch(url, {
            method,
            headers: buildHeaders(token, body ? contentType : undefined),
            body: bodyData,
        });

        const responseContent: S2DApiFetcherWithContentResponse<TRes> =
            await response.json();

        return {
            success: responseContent.success,
            message: responseContent.message,
            content: responseContent.content,
        };
    } catch (error) {
        if (!isApiError(error)) {
            errorToast(`Failed to fetch '${path}'`);
        }

        throw error;
    }
};

export const useS2DApiFetcherWithContent = <TReq, TRes, TArgs>(
    method: HttpMethod,
    requestBuilder: (arg: TArgs) => {
        url: string;
        body?: TReq;
        altToken?: string;
        contentType?: AcceptedContentTypes;
    },
    ignoreToastHttpCodes?: number[],
    authenticated = true
): [
        (arg: TArgs) => Promise<S2DApiFetcherWithContentResponse<TRes>>,
        boolean
    ] => {
    const { token } = useAuth();
    const [isFetching, setIsFetching] = useState(false);

    const fetcher = async (args: TArgs) => {
        if (!token && authenticated)
            throw new Error(
                "Attempted to make authenticated request without access token."
            );

        const { url, altToken, body, contentType } = requestBuilder(args);

        try {
            setIsFetching(true);
            const response = await s2dApiFetcherWithContent<TReq, TRes>(
                url,
                method,
                body,
                altToken ? altToken : token,
                ignoreToastHttpCodes,
                contentType
            );
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            setIsFetching(false);
        }
    };

    return [fetcher, isFetching];
};

export const thirdPartyApiFetcher = async <TReq, TRes>(
    url: string,
    method = "GET",
    body?: TReq,
    token?: string,
    ignoreToastHttpCodes?: number[]
) => {
    const response = await fetch(url, {
        method,
        headers: buildHeaders(token, body ? "application/json" : undefined),
        body: body ? JSON.stringify(body) : undefined,
    });

    if (!response.ok) {
        const error = await buildApiError(method, url, response, url, ignoreToastHttpCodes);
        throw error;
    }

    const responseContent: TRes = await response.json();

    return responseContent;
};

export default s2dApiFetcher;
