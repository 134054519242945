import { type UseFormReturn } from "react-hook-form";
import FormTextInput from "../../FormTextInput";
import { type DriversListFiltersFormValues } from "./DriversListFiltersFormValues";
import { type Dispatch, type SetStateAction } from "react";
import { generateSelectedValues } from "./generateSelectedValues";
import AccordionItem from "../../AccordionItem";

type FilterDriverAddressProps = {
    methods: UseFormReturn<DriversListFiltersFormValues>;
    selectedValues: string[];
    setSelectedValues: Dispatch<SetStateAction<string[]>>;
};

const FilterDriverAddress: React.FC<FilterDriverAddressProps> = ({
    methods,
    selectedValues,
    setSelectedValues,
}) => {
    return (
        <AccordionItem
            title="Driver address"
            valueSummary={selectedValues}
            modalStyling
        >
            <FormTextInput
                label="Postcode"
                placeholder="Enter postcode"
                error={methods.formState.errors.driverAddress?.postcode}
                {...methods.register("driverAddress.postcode")}
                onBlur={(e) =>
                    generateSelectedValues(
                        selectedValues,
                        setSelectedValues,
                        "Postcode",
                        e.target.value
                    )
                }
            />
        </AccordionItem>
    );
};

export default FilterDriverAddress;
