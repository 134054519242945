import { useState } from "react";
import { ReportFileTypes, useDownloadReports } from "../../../api/reporting";
import { ApiCompanyResponse } from "../../../models/api/admin/company";
import { ApiDepartmentItem } from "../../../models/api/admin/department";
import { downloadBlobAsFile } from "../../../utils/downloadBlobAsFile";
import { errorToast, successToast } from "../../../toast";
import { PrebuiltReports } from "../../../models/api/reporting";

export const useSpreadsheetReportDownload = (
    selectedCompany?: ApiCompanyResponse | null,
    selectedDepartment?: ApiDepartmentItem | null
) => {
    const { getReport } = useDownloadReports(
        selectedCompany?.companyId,
        selectedDepartment?.departmentId
    );

    const [isReportDownloading, setIsReportDownloading] = useState(false);

    const handleDownloadReport = async (
        reportName: PrebuiltReports,
        type: ReportFileTypes
    ) => {
        setIsReportDownloading(true);

        const suffix = type === "XLS" ? "xlsx" : "csv";

        const fileNameArray = [`${reportName}Report.${suffix}`];
        if (selectedDepartment)
            fileNameArray.unshift(selectedDepartment.departmentName);
        if (selectedCompany) fileNameArray.unshift(selectedCompany.companyName);
        const fileName = fileNameArray.join("-");

        try {
            const response = await getReport(reportName, type);
            if (response.success) {
                const fileMimeType =
                    type === "XLS"
                        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        : "text/csv;charset=utf-8;";
                downloadBlobAsFile(response.content, fileName, fileMimeType);
                successToast(`successfully downloaded report`);
                setTimeout(() => setIsReportDownloading(false), 1000);
            }
        } catch (error) {
            setTimeout(() => setIsReportDownloading(false), 1000);
            errorToast(`failed to download report`);
        }
    };
    const handleDownloadCsvReport = async (reportName: PrebuiltReports) => {
        return handleDownloadReport(reportName, "CSV");
    };

    const handleDownloadXlsReport = async (reportName: PrebuiltReports) => {
        return handleDownloadReport(reportName, "XLS");
    };

    return {
        handleDownloadCsvReport,
        handleDownloadXlsReport,
        handleDownloadReport,
        isReportDownloading,
    };
};
