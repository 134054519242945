export type CompanyDriverAnalysisResponse = {
    riskCategories: DriverRiskCategoriesAnalysis;
    //  Certificate of Professional Competence, and Tachograph
    cpcTacho: CpcTachoAnalysis;
    licenceStatus: LicenceStatusAnalysis;
    professionalLicenceStatus: ProfessionalLicenceStatusAnalysis;
    licenceCategories: LicenceCategoriesAnalysis;
    driverLicenceChanges: LicenceChangesAnalysis;
    photocards: PhotocardsAnalysis;
    newDrivers: NewDriversAnalysis;
    professionalNewDrivers: ProfessionalDriversAnalysis;
    eyesight: EyesightAnalysis;
    nonAddressMatch: NonAddressMatchAnalysis;
};

export enum DriverAnalysisCategories {
    RiskCategories = "RiskCategories",
    CpcAndTacho = "CpcTacho",
    LicenceStatus = "LicenceStatus",
    ProfessionalLicenceStatus = "ProfessionalLicenceStatus",
    LicenceChanged = "DriverLicenceChanges",
    LicenceCategories = "LicenceCategories",
    PhotoCards = "Photocards",
    NewDrivers = "NewDrivers",
    ProfessionalNewDrivers = "ProfessionalNewDrivers",
    Eyesight = "Eyesight",
    NonADDMatches = "NonAddressMatch",
}

export enum DriverAnalysisTabs {
    RiskCategories = "Risk categories",
    CpcAndTacho = "CPC & Tacho",
    LicenceStatus = "Licence status",
    ProfessionalLicenceStatus = "Professional licence status",
    LicenceChanged = "Licence changed",
    LicenceCategories = "Licence Categories",
    PhotoCards = "Photo cards",
    NewDrivers = "New drivers",
    ProfessionalNewDrivers = "Professional new drivers",
    Eyesight = "Eyesight",
    NonADDMatches = "Non ADD matches",
}

type DriverRiskCategoriesAnalysis = {
    lowRiskPointsRange: string;
    lowRiskDriverCount: number;
    mediumRiskPointsRange: string;
    mediumRiskDriverCount: number;
    highRiskPointsRange: string;
    highRiskDriverCount: number;
    criticalDriverCount: number;
};

type CpcTachoAnalysis = {
    cpcDueToExpireCount: number;
    cpcExpiredCount: number;
    tachoDueToExpireCount: number;
    tachoExpiredCount: number;
};

type LicenceStatusAnalysis = {
    provisionalCount: number;
    fullCount: number;
};

type ProfessionalLicenceStatusAnalysis = {
    catCProvisionalCount: number;
    catCFullCount: number;
    catCEProvisionalCount: number;
    catCEFullCount: number;
    catDProvisionalCount: number;
    catDFullCount: number;
    catDEProvisionalCount: number;
    catDEFullCount: number;
};

type LicenceCategoriesAnalysis = {
    categoriesDueToExpireCount: number;
    categoriesExpiredCount: number;
};

type PhotocardsAnalysis = {
    photocardDueToExpireCount: number;
    photocardExpiredCount: number;
};

type NewDriversAnalysis = {
    categoryB: number;
};

type ProfessionalDriversAnalysis = {
    categoryCorCE: number; // HCV = Heavy Commercial Vehicle (cat C or CE)
    categoryDorDE: number; // PCV = Passenger Carrying Vehicle (cat D or DE)
};

type EyesightAnalysis = {
    eyesightRestrictionCount: number;
};

type NonAddressMatchAnalysis = {
    nonAddressMatchCount: number;
};

type LicenceChangesAnalysis = {
    endorsementChanges: number[];
    pointsChanges: number[];
};
