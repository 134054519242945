import { format } from "date-fns";
import { useDocuments } from "../../api/admin/document";
import { useDriverVehicle } from "../../api/admin/driverVehicle";
import { SectionTabs } from "../../pages/Admin/AdminEditDriver";
import { successToast } from "../../toast";
import { capitaliseWord } from "../../utils/capitaliseWord";
import { formatDateToString } from "../../utils/formatDateToString";
import FormMessageBox from "../FormMessageBox";
import GreyFleetInformation from "./EditGreyFleetForm";
import { EditGreyFleetFormValues } from "./EditGreyFleetFormValues";
import { EditDriverVehicleDto } from "../../models/app/admin/driverVehicle";
import { formatDateWithoutTimezone } from "../../utils/formatDateWithoutTimezone";

type EditGreyFleetFormBaseProps = {
    driverId: string;
    vehicleId: string;
    refetch: () => void;
    tabSelected: string;
    deletionRequestedAt: Date | null;
    setSelectedTab?: (tab: SectionTabs | string) => void;
};

export const EditGreyFleetFormBase = ({
    driverId,
    vehicleId,
    refetch,
    tabSelected,
    deletionRequestedAt,
    setSelectedTab
}: EditGreyFleetFormBaseProps) => {
    const {
        driverVehicle: vehicle,
        driverVehicleError,
        editDriverVehicle,
        isEditingDriverVehicle,
        refetchDriverVehicle,
    } = useDriverVehicle(vehicleId, driverId);

    const { documentsData } = useDocuments(driverId);

    const handleEditDriverVehicle = async (
        data: EditGreyFleetFormValues,
        formType: string,
        callback: () => void
    ) => {
        let submitData: Partial<EditDriverVehicleDto> = {};

        if (formType === "vehicle") {
            submitData = {
                vehicle: data.vehicle ? {
                    ...data.vehicle,
                    monthOfFirstRegistration: formatDateWithoutTimezone(data.vehicle.monthOfFirstRegistration),
                    motExpiryDate: formatDateWithoutTimezone(data.vehicle.motExpiryDate),
                    taxExpiryDate: formatDateWithoutTimezone(data.vehicle.taxExpiryDate)
                } : null,
            };
        } else if (formType === "insurance") {
            submitData = {
                insurance: data.insurance
                    ? { ...data.insurance, insuranceCertificate: null }
                    : null,
            };
        } else if (formType === "breakdown") {
            submitData = {
                breakdownCover: data.breakdownCover
                    ? { ...data.breakdownCover }
                    : null,
            };
        }

        const response = await editDriverVehicle(vehicleId, driverId, submitData);

        if (response.success) {
            successToast(`${capitaliseWord(formType)} information updated successfully.`);

            await refetch();

            const newVehicleId = response.content.vehicleId;
            await refetchDriverVehicle(newVehicleId);
            
            if (setSelectedTab) setSelectedTab(newVehicleId);

            callback();
        }
    };

    return vehicle && !driverVehicleError ? (
        <>
            {deletionRequestedAt && (
                <FormMessageBox
                    variant="error"
                    title="Requested for deletion"
                    message={`This vehicle was requested for deletion on ${formatDateToString(
                        new Date(deletionRequestedAt)
                    )}`}
                />
            )}
            <GreyFleetInformation
                initialValues={{
                    vehicle: {
                        vrm: vehicle.vrm,
                        make: vehicle.make,
                        model: vehicle.model ?? "unknown",
                        monthOfFirstRegistration:
                            vehicle.monthOfFirstRegistration
                                ? new Date(vehicle.monthOfFirstRegistration)
                                : null,
                        taxExpiryDate: vehicle.taxExpiryDate
                            ? new Date(vehicle.taxExpiryDate)
                            : null,
                        motExpiryDate: vehicle.motExpiryDate
                            ? new Date(vehicle.motExpiryDate)
                            : null,
                        yearOfManufacture: vehicle.yearOfManufacture,
                        co2Emissions: vehicle.co2Emissions,
                        fuelType: vehicle.fuelType
                    },
                    vehicleDocuments: documentsData?.vehicleDocuments
                        ? documentsData.vehicleDocuments.filter(
                            (vehicle) => vehicle.vehicleId === vehicleId
                        )
                        : [],
                    insurance: {
                        insuranceCertificate: {
                            fileBase64:
                                vehicle.insuranceCertificateFile?.documentUrl ??
                                "",
                            fileType: "",
                            fileName:
                                vehicle.insuranceCertificateFile?.fileName ??
                                "",
                        },
                        insuranceCompanyName: vehicle.insuranceCompanyName,
                        insurancePolicyNumber: vehicle.insurancePolicyNumber,
                        insuranceStartDate: vehicle.insuranceStartDate
                            ? new Date(vehicle.insuranceStartDate)
                            : null,
                        insuranceEndDate: vehicle.insuranceEndDate
                            ? new Date(vehicle.insuranceEndDate)
                            : null,
                        hasBusinessCover: vehicle.hasBusinessCover,
                        insuranceFileCheckedDate:
                            vehicle.insuranceFileCheckedDate
                                ? new Date(vehicle.insuranceFileCheckedDate)
                                : null,
                        insuranceType: vehicle.insuranceType,
                    },
                    breakdownCover: {
                        hasBreakdownCover: vehicle.hasBreakdownCover,
                        breakdownCoverProvider: vehicle.breakdownCoverProvider,
                        breakdownCoverPolicyNumber:
                            vehicle.breakdownCoverPolicyNumber,
                        breakdownCoverStartDate: vehicle.breakdownCoverStartDate
                            ? new Date(vehicle.breakdownCoverStartDate)
                            : null,
                        breakdownCoverEndDate: vehicle.breakdownCoverEndDate
                            ? new Date(vehicle.breakdownCoverEndDate)
                            : null,
                    },
                }}
                isLoading={isEditingDriverVehicle}
                onSubmit={handleEditDriverVehicle}
                tabSelected={tabSelected}
                driverId={driverId}
                vehicleId={vehicleId}
            />
        </>
    ) : (
        <></>
    );
};

export default EditGreyFleetFormBase;
