import { CommentFormValues } from "../../components/CommentForm/CommentFormValues";
import { ApiCommentResponse } from "../../models/api/admin/comment";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

const useAddCompanyComment = () =>
    useS2DApiFetcher<
        CommentFormValues,
        string,
        { companyId: string; dto: CommentFormValues }
    >(
        "POST",
        ({ companyId, dto }) => ({
            url: `api/companies/${companyId}/comment`,
            body: dto,
        }),
        [],
        true
    );

const useEditCompanyComment = () =>
    useS2DApiFetcher<
        CommentFormValues,
        string,
        { companyId: string; companyCommentId: string; dto: CommentFormValues }
    >(
        "PUT",
        ({ companyId, companyCommentId, dto }) => ({
            url: `api/companies/${companyId}/comment/${companyCommentId}`,
            body: dto,
        }),
        [],
        true
    );

const useDeleteCompanyComment = () =>
    useS2DApiFetcher<
        string,
        string,
        { companyId: string; companyCommentId: string }
    >(
        "DELETE",
        ({ companyId, companyCommentId }) => ({
            url: `api/companies/${companyId}/comment/${companyCommentId}`,
        }),
        [],
        true
    );

export const useCompanyComments = (companyId?: string) => {
    const { data, error, isLoading, refetch } = useS2DApiSWR<
        ApiCommentResponse[]
    >(companyId ? `api/companies/${companyId}/comment` : null);

    const [addCompanyComment, isAddingcompanyComment] = useAddCompanyComment();
    const [editCompanyComment, isEditingCompanyComment] =
        useEditCompanyComment();
    const [deleteCompanyComment, isDeletingCompanyComment] =
        useDeleteCompanyComment();

    return {
        companyCommentsData: data,
        companyCommentsError: error,
        isLoadingCompanyComments: isLoading,
        refetchCompanyComments: refetch,
        addCompanyComment: async (companyId: string, dto: CommentFormValues) =>
            await addCompanyComment({ companyId, dto }),
        isAddingcompanyComment,
        editCompanyComment: async (
            companyId: string,
            companyCommentId: string,
            dto: CommentFormValues
        ) => await editCompanyComment({ companyId, companyCommentId, dto }),
        isEditingCompanyComment,
        deleteCompanyComment: async (
            companyId: string,
            companyCommentId: string
        ) => await deleteCompanyComment({ companyId, companyCommentId }),
        isDeletingCompanyComment,
    };
};
