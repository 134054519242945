import clsx from "clsx";
import { DragEventHandler, useState } from "react";
import useFileType from "../../hooks/useFileType";
import React from "react";
import FileIconList from "../ReusableComponents/FileIconList";
import FileSelectorButton from "./FileSelectorButton";
import FormInputMessage from "../FormInputError";
import { validDocumentFileExtensions } from "../../constants/fileExtensions";

export type FormMultipleFileUploaderProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "type" | "className"
> & {
    handleAddFile: (file: File) => void;
    handleRemoveFile: (index: number) => void;
    label: string;
    error?: string;
    maxFileSizeKb?: number;
    fileArray: File[];
    disabled?: boolean;
    required?: boolean;
};

const FormMultipleFileUploader = React.forwardRef<
    HTMLInputElement,
    FormMultipleFileUploaderProps
>(
    (
        {
            handleAddFile,
            handleRemoveFile,
            label,
            error,
            maxFileSizeKb = 1000,
            fileArray,
            disabled = false,
            required = false,
            ...inputProps
        },
        ref
    ) => {
        const { loadFile, fileError } = useFileType({
            fileType: validDocumentFileExtensions,
            maxFileSizeKb,
        });

        const handleLoadFile = async (file: File) => {
            const response = await loadFile(file);
            if (response?.error) return;
            handleAddFile(file);
        };

        const handleSelect: React.ChangeEventHandler<HTMLInputElement> = (
            event
        ) => {
            if (event.target.files) {
                handleLoadFile(event.target.files[0]);
            }
        };

        const [dragActive, setDragActive] = useState(false);

        const handleDrag: DragEventHandler<HTMLDivElement> = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (event.type === "dragenter" || event.type === "dragover") {
                setDragActive(true);
            } else if (event.type === "dragleave") {
                setDragActive(false);
            }
        };

        const handleDrop: React.DragEventHandler<HTMLDivElement> = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setDragActive(false);
            if (event.dataTransfer.files) {
                handleLoadFile(event.dataTransfer.files[0]);
            }
        };
        return (
            <div
                className={clsx(
                    "mb-4 flex justify-center rounded-lg border-2 border-dashed border-black p-4",
                    dragActive && "bg-S2D-neutral-90",
                    disabled && "opacity-40"
                )}
                onDragEnter={handleDrag}
            >
                <div className="flex flex-col items-center gap-4">
                    {fileArray.length ? (
                        <FileIconList
                            fileArray={fileArray}
                            handleRemoveFile={handleRemoveFile}
                        />
                    ) : (
                        <p className="text-xs">{label}</p>
                    )}
                    <FileSelectorButton
                        maxFileSizeKb={maxFileSizeKb}
                        ref={ref}
                        disabled={disabled}
                        required={required}
                        onChange={handleSelect}
                        {...inputProps}
                    />
                    {/* error relating to file type/size */}
                    {fileError && (
                        <div className="pl-3">
                            <FormInputMessage error={fileError} />
                        </div>
                    )}
                    {/* error relating to yup validation */}
                    {error && (
                        <div className="pl-3">
                            <FormInputMessage error={error} />
                        </div>
                    )}
                </div>
                {dragActive && (
                    <div
                        className="absolute inset-0 rounded-lg"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                    />
                )}
            </div>
        );
    }
);

export default FormMultipleFileUploader;
