import { useEffect } from "react";
import { Control, useWatch } from "react-hook-form";

export const useStaleDataFormReset = (
    control: Control<any, any>,
    reset: (values: any) => void,
    initialValues: any
) => {
    const currentForm = useWatch({ control });

    useEffect(() => {
        // If initialValues change, e.g. if SWR has given stale data, we need to reset the form
        const checkIfFormShouldReset = async () => {
            // this prevents the first initial values from triggering a reset
            await new Promise((resolve) => setTimeout(resolve, 0));

            const stringForm = JSON.stringify(currentForm);
            const stringInitialValues = JSON.stringify(initialValues);
            const formShouldReset = !(stringForm === stringInitialValues);
            if (formShouldReset) {
                reset(initialValues);
            }
        };
        checkIfFormShouldReset();
    }, [JSON.stringify(initialValues)]);
};
