import DriverSummaryCard from "./DriverSummaryCard";
import { CaretDown, CaretUp, Truck } from "phosphor-react";
import clsx from "clsx";
import NorthernIreland from "../../assets/NorthernIreland.png";
import UnitedKingdom from "../../assets/UnitedKingdom.png";
import { ApiAllDriversStatsResponse } from "../../models/api/admin/driver";
import LoadingMessage from "../ReusableComponents/LoadingMessage/LoadingMessage";
import useLocalStorage from "../../hooks/useLocalStorage";

type DriverSummaryProps = {
    loading: boolean;
    stats: ApiAllDriversStatsResponse | undefined;
};
export default function HGVCoachDriversSummary({
    loading,
    stats,
}: DriverSummaryProps) {
    const [isDriverSummaryOpen, setIsDriverSummaryOpen] =
        useLocalStorage<boolean>(`isHgvCoachDriverSummaryOpen`, true);

    return (
        <section
            about="Drivers summary"
            className="w-full rounded bg-S2D-dark-green-70.3 p-6"
        >
            <div
                onClick={() => setIsDriverSummaryOpen(!isDriverSummaryOpen)}
                className={clsx(
                    `flex items-center gap-3`,
                    isDriverSummaryOpen ? "pb-3" : ""
                )}
            >
                <p className="text-lg font-semibold">HGV/Coach drivers</p>
                {isDriverSummaryOpen ? (
                    <CaretUp weight="fill" />
                ) : (
                    <CaretDown weight="fill" />
                )}
            </div>
            {isDriverSummaryOpen && !loading && (
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
                    <DriverSummaryCard
                        label={"NUMBER OF PROFESSIONAL DRIVERS"}
                        statistic={stats?.totalDrivers ?? 0}
                        icon={Truck}
                    />
                    <DriverSummaryCard
                        label="DRIVERS WITH UK LICENCES"
                        statistic={stats?.totalUkLicenceDrivers ?? 0}
                        customImg={UnitedKingdom}
                    />
                    <DriverSummaryCard
                        label="DRIVERS WITH N.IRISH LICENCES"
                        statistic={stats?.totalNiLicenceDrivers ?? 0}
                        customImg={NorthernIreland}
                    />
                </div>
            )}
            {isDriverSummaryOpen && loading && <LoadingMessage />}
        </section>
    );
}
