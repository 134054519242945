import { type VehiclesResponseItem } from "../../models/api/admin/vehicle";
import {
    AnalysisChartType,
    type GreyFleetVehicleAnalysisCharts,
} from "../../models/app/admin/analysisChart";
import { calculateAgeOfVehicle } from "../../utils/calculateAgeOfVehicle";
import {
    type TableContent,
    type TableHeader,
} from "../ReusableComponents/Table";
import UserIcon from "../UserIcon";

export type VehicleChartAnalysisTableAllowedIds =
    | "name"
    | "reg"
    | "manufacturer"
    | "age"
    | "co2"
    | "fuelType"
    | "model"
    | "euroRating"
    | "insuranceCompany"
    | "insuranceType"
    | "businessCover"
    | "engineCapacity";

export type VehicleChartAnalysisTableHeader =
    TableHeader<VehicleChartAnalysisTableAllowedIds>;
export type VehicleChartAnalysisTableContent = TableContent<
    VehiclesResponseItem,
    VehicleChartAnalysisTableAllowedIds
>;

const hideVehicleModel = (chartType: GreyFleetVehicleAnalysisCharts) => {
    switch (chartType) {
        case AnalysisChartType.AgeRange:
        case AnalysisChartType.Manufacturer:
        case AnalysisChartType.EuroRatings:
            return false;
        default:
            return true;
    }
};

const hideFuelType = (chartType: GreyFleetVehicleAnalysisCharts) => {
    switch (chartType) {
        case AnalysisChartType.FuelType:
        case AnalysisChartType.EuroRatings:
            return false;
        default:
            return true;
    }
};

const hideInsuranceCompany = (chartType: GreyFleetVehicleAnalysisCharts) => {
    switch (chartType) {
        case AnalysisChartType.InsuranceTypes:
        case AnalysisChartType.BusinessCover:
            return false;
        default:
            return true;
    }
};

export const initVehicleChartAnalysisTableHeaders = (
    chartType: GreyFleetVehicleAnalysisCharts
): VehicleChartAnalysisTableHeader[] => [
    {
        title: "Name",
        id: "name",
        hide: false,
    },
    {
        title: "Registration No",
        id: "reg",
        hide: false,
    },
    {
        title: "Manufacturer",
        id: "manufacturer",
        hide: false,
    },
    {
        title: "Age of vehicle",
        id: "age",
        hide: false,
    },
    {
        title: "CO2 Emissions",
        id: "co2",
        hide: chartType === AnalysisChartType.Co2Emissions ? false : true,
    },
    {
        title: "Fuel type",
        id: "fuelType",
        hide: hideFuelType(chartType),
    },
    {
        title: "Model",
        id: "model",
        hide: hideVehicleModel(chartType),
    },
    {
        title: "Euro rating",
        id: "euroRating",
        hide: chartType === AnalysisChartType.EuroRatings ? false : true,
    },
    {
        title: "Insurance company",
        id: "insuranceCompany",
        hide: hideInsuranceCompany(chartType),
    },
    {
        title: "Insurance type",
        id: "insuranceType",
        hide: chartType === AnalysisChartType.InsuranceTypes ? false : true,
    },
    {
        title: "Business cover",
        id: "businessCover",
        hide: chartType === AnalysisChartType.BusinessCover ? false : true,
    },
    {
        title: "Engine capacity",
        id: "engineCapacity",
        hide: chartType === AnalysisChartType.EngineCapacity ? false : true,
    },
];

export const initVehicleChartAnalysisTableContents = (
    vehicles: VehiclesResponseItem[]
): VehicleChartAnalysisTableContent[] => {
    const tableContents = new Array<VehicleChartAnalysisTableContent>(0);

    vehicles.map((vehicle) => {
        const ageOfVehicle = vehicle.yearOfManufacture
            ? calculateAgeOfVehicle(vehicle.yearOfManufacture)
            : "-";

        tableContents.push({
            rowId: vehicle.vehicleId + vehicle.driver.driverId,
            // note that rowData does not discern which vehicle if the vehicle has multiple
            // we don't currently need to discern this, but if we ever do then this will have to change.
            rowData: vehicle,
            cellData: [
                {
                    id: "name",
                    content: (
                        <div className="flex items-center gap-2">
                            <UserIcon
                                firstName={vehicle.driver.firstName}
                                lastName={vehicle.driver.lastName}
                                size="small"
                            />
                            <p>
                                {vehicle.driver.firstName}{" "}
                                {vehicle.driver.lastName}
                            </p>
                        </div>
                    ),
                },
                {
                    id: "reg",
                    content: <p>{vehicle.vrm}</p>,
                },
                {
                    id: "manufacturer",
                    content: <p>{vehicle.make}</p>,
                },
                {
                    id: "age",
                    content: <p>{ageOfVehicle}</p>,
                },
                {
                    id: "co2",
                    content: <p>{vehicle.co2Emissions}</p>,
                },
                {
                    id: "fuelType",
                    content: <p>{vehicle.fuelType}</p>,
                },
                {
                    id: "model",
                    content: <p>{vehicle.model}</p>,
                },
                {
                    id: "euroRating",
                    content: <p>{vehicle.euroRating}</p>,
                },
                {
                    id: "insuranceCompany",
                    content: <p>{vehicle.insuranceCompanyName}</p>,
                },
                {
                    id: "insuranceType",
                    content: <p>{vehicle.insuranceType}</p>,
                },
                {
                    id: "businessCover",
                    content: <p>{vehicle.hasBusinessCover ? "Yes" : "No"}</p>,
                },
                {
                    id: "engineCapacity",
                    content: <p>{vehicle.engineCapacity}</p>,
                },
            ],
        });
    });

    return tableContents;
};
