import { Controller, UseFormReturn } from "react-hook-form";
import { type DvlaChecksFilterFormValues } from "./useDvlaChecksFilterForm";
import Button from "../Button";
import clsx from "clsx";
import FormDropdownMultiSelect from "../FormDropdownMultiSelect";
import { createDropdownOptionsFromArray } from "../../constants/dropdownOptions";
import {
    manualCheckFilterKeys,
    ukDvlaCheckErrorFilterKeys,
    ukDvlaCheckFilterKeys,
} from "../../models/api/safe2DriveAdmin/dvlaChecks";
import { useDvlaCheckFilterSelectedValues } from "./useDvlaCheckFilterSelectedValues";

type DvlaChecksFilterFormProps = {
    clearAll: () => void;
    methods: UseFormReturn<DvlaChecksFilterFormValues>;
    onSubmit: (value: DvlaChecksFilterFormValues) => void;
};

const DvlaChecksFilterForm: React.FC<DvlaChecksFilterFormProps> = ({
    clearAll,
    methods,
    onSubmit,
}) => {
    const selectedValues = useDvlaCheckFilterSelectedValues(methods);

    const handleChange = (
        selectedValues: string[],
        fieldName: keyof DvlaChecksFilterFormValues,
        defaultKeys: string[]
    ) => {
        const nullValues = Object.fromEntries(
            defaultKeys.map((key) => [key, null])
        );
        const chosenValues = Object.fromEntries(
            selectedValues.map((key) => [key, true])
        );

        methods.setValue(`${fieldName}`, {
            ...nullValues,
            ...chosenValues,
        });
    };

    return (
        <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex max-h-[32rem] flex-col gap-2 overflow-y-auto p-6 pt-0">
                <Controller
                    name="ukDvlaChecks"
                    control={methods.control}
                    render={() => (
                        <FormDropdownMultiSelect
                            label="Uk DVLA checks"
                            options={createDropdownOptionsFromArray(
                                ukDvlaCheckFilterKeys
                            )}
                            value={selectedValues.ukDvlaCheckSelectedValues}
                            onChange={(value) =>
                                handleChange(
                                    value,
                                    "ukDvlaChecks",
                                    ukDvlaCheckFilterKeys
                                )
                            }
                            pleaseSelectText="Select options"
                        />
                    )}
                />
                <Controller
                    name="ukDvlaCheckErrors"
                    control={methods.control}
                    render={() => (
                        <FormDropdownMultiSelect
                            label="Uk DVLA check errors"
                            options={createDropdownOptionsFromArray(
                                ukDvlaCheckErrorFilterKeys
                            )}
                            value={
                                selectedValues.ukDvlaCheckErrorSelectedValues
                            }
                            onChange={(value) =>
                                handleChange(
                                    value,
                                    "ukDvlaCheckErrors",
                                    ukDvlaCheckErrorFilterKeys
                                )
                            }
                            pleaseSelectText="Select options"
                        />
                    )}
                />
                <Controller
                    name="ukManualChecks"
                    control={methods.control}
                    render={() => (
                        <FormDropdownMultiSelect
                            label="Uk manual checks"
                            options={createDropdownOptionsFromArray(
                                manualCheckFilterKeys
                            )}
                            value={selectedValues.ukManualCheckSelectedValues}
                            onChange={(value) =>
                                handleChange(
                                    value,
                                    "ukManualChecks",
                                    manualCheckFilterKeys
                                )
                            }
                            pleaseSelectText="Select options"
                        />
                    )}
                />
                <Controller
                    name="northernIrishManualChecks"
                    control={methods.control}
                    render={() => (
                        <FormDropdownMultiSelect
                            label="Northern Irish manual checks"
                            options={createDropdownOptionsFromArray(
                                manualCheckFilterKeys
                            )}
                            value={
                                selectedValues.northernIrishManualCheckSelectedValues
                            }
                            onChange={(value) =>
                                handleChange(
                                    value,
                                    "northernIrishManualChecks",
                                    manualCheckFilterKeys
                                )
                            }
                            pleaseSelectText="Select options"
                        />
                    )}
                />
                <Controller
                    name="foreignManualChecks"
                    control={methods.control}
                    render={() => (
                        <FormDropdownMultiSelect
                            label="Foreign manual checks"
                            options={createDropdownOptionsFromArray(
                                manualCheckFilterKeys
                            )}
                            value={
                                selectedValues.foreignManualCheckSelectedValues
                            }
                            onChange={(value) =>
                                handleChange(
                                    value,
                                    "foreignManualChecks",
                                    manualCheckFilterKeys
                                )
                            }
                            pleaseSelectText="Select options"
                        />
                    )}
                />
            </div>

            <div className="flex flex-col-reverse justify-between gap-2 rounded-b-sm border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                <Button
                    variant="tertiary"
                    type="button"
                    onClick={clearAll}
                    className="w-full justify-center md:w-auto"
                >
                    Clear all
                </Button>
                <div
                    className={clsx(
                        "flex flex-col-reverse md:flex-row md:justify-between md:gap-2"
                    )}
                >
                    <Button
                        type="submit"
                        loading={false}
                        className="w-full justify-center md:w-auto"
                    >
                        Apply filters
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default DvlaChecksFilterForm;
