import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../auth";
import LogIn2FAForm from "../../components/LogIn2FAForm";
import { LogIn2FAFormValues } from "../../components/LogIn2FAForm/LogIn2FAFormValues";
import { appPaths } from "../../Routes";

const LogIn2FAPage = () => {
    const { token, authorize2FA, isAuthorizing2FA } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate(appPaths.home);
        }
    }, [token]);

    const handleSubmit = async (
        data: LogIn2FAFormValues,
        callback: (error: boolean) => void
    ) => {
        const res = await authorize2FA(data);
        if (res.success) {
            callback(false);
        } else {
            callback(true);
        }
    };

    return (
        <div className="w-screen h-screen flex justify-center items-center bg-S2D-surface-light-3">
            <LogIn2FAForm
                onSubmit={handleSubmit}
                isLoading={isAuthorizing2FA}
            />
        </div>
    );
};

export default LogIn2FAPage;
