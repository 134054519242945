import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { X } from "phosphor-react";
import { Controller, useForm } from "react-hook-form";
import Button from "../Button";
import Modal from "../Modal";
import FormDateInput from "../FormDateInput";
import { useDvlaChecks } from "../../api/admin/checks";
import { errorToast, successToast } from "../../toast";
import { appPaths } from "../../Routes";
import { anchorDate } from "../../utils/anchorDate";
import { addDays } from "date-fns";
import { dateValidation } from "../../utils/dateValidationYup";

type EditCheckDateModalProps = {
    isOpen: boolean;
    onClose: () => void;
    driverId: string;
};

const tomorrow = anchorDate(addDays(new Date(), 1), "min") as Date;

type EditCheckDateFormValues = {
    checkDate: Date;
};

const validationSchema = yup.object().shape({
    checkDate: dateValidation
        .min(tomorrow, "Next check date must be in the future")
        .required("Next check date is required"),
});

const EditCheckDateModal = ({
    isOpen,
    onClose,
    driverId,
}: EditCheckDateModalProps) => {
    const { updateNextCheckDate, isUpdatingNextCheckDate } = useDvlaChecks();

    const methods = useForm<EditCheckDateFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            checkDate: tomorrow,
        },
    });

    const submitHandler = async (data: EditCheckDateFormValues) => {
        const response = await updateNextCheckDate(driverId, {
            nextCheckDateTimeUtc: data.checkDate.toISOString(),
        });

        if (!response.success) {
            errorToast("Failed to update next check date.");
            return;
        }

        successToast("Successfully updated next check date.");

        // Reloading page because "Next check" on the status does not update automatically
        window.location.href = appPaths.adminEditDriver(driverId);
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            size="large"
            classNames="overflow-visible"
        >
            <form onSubmit={methods.handleSubmit(submitHandler)}>
                <div className="p-5">
                    <div className="flex items-center justify-between">
                        <p className="text-xl font-medium">Edit check date</p>
                        <X
                            size={26}
                            weight="duotone"
                            onClick={() => onClose()}
                            className="cursor-pointer"
                        />
                    </div>
                    <div className="py-2" />
                    <Controller
                        name="checkDate"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDateInput
                                required
                                id="checkDate"
                                label="Next check date"
                                value={value}
                                minDate={tomorrow}
                                onChange={onChange}
                                error={methods.formState.errors.checkDate}
                            />
                        )}
                    />
                    <div className="py-2" />
                </div>
                <div className="flex flex-col-reverse justify-between rounded-b-sm border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                    <Button
                        variant="tertiary"
                        onClick={() => onClose()}
                        className="w-full justify-center focus:ring-0 md:w-auto "
                    >
                        Cancel
                    </Button>
                    <div className="flex flex-col-reverse md:flex-row md:justify-between md:gap-2">
                        <Button
                            type="submit"
                            loading={isUpdatingNextCheckDate}
                            className="w-full justify-center md:w-auto"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default EditCheckDateModal;
