import { FunnelSimple } from "phosphor-react";
import CaretDropdown from "./CaretDropdown";
import { type SetStateAction } from "react";
import clsx from "clsx";

type FilterModalDropdownButtonProps = {
    isOpen: boolean;
    setIsOpen: (value: SetStateAction<boolean>) => void;
    filterIsApplied?: boolean;
};

const FilterModalDropdownButton: React.FC<FilterModalDropdownButtonProps> = ({
    isOpen,
    setIsOpen,
    filterIsApplied,
}) => {
    return (
        <button onClick={() => setIsOpen(!isOpen)}>
            <CaretDropdown
                open={isOpen}
                content={
                    <div className="flex items-center gap-3 p-3 pr-4">
                        <FunnelSimple size={20} />
                        Filters
                    </div>
                }
                className={clsx(
                    "base-height",
                    filterIsApplied
                        ? "border-S2D-light-green-80 bg-S2D-dark-green-70.1"
                        : " border-S2D-neutral-10 bg-S2D-neutral-100"
                )}
            />
        </button>
    );
};

export default FilterModalDropdownButton;
