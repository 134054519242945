import { Dispatch, SetStateAction } from "react";
import { type CompanyStatusFilters } from "../../models/api/admin/company";
import { termForCompany } from "../../copy/sharedCopy";
import TableFiltersAndActions from "../ReusableComponents/TableFiltersAndActions";
import { companyStatusOptions } from "../../constants/statusOptions";

export type CompanyHeaderProps = {
    searchQuery: string;
    setSearchQuery: Dispatch<SetStateAction<string>>;
    statusFilter: CompanyStatusFilters | null;
    setStatusFilter: Dispatch<SetStateAction<CompanyStatusFilters | null>>;
    isCompanyListLoading: boolean;
};

const CompanyHeader: React.FC<CompanyHeaderProps> = ({
    searchQuery,
    setSearchQuery,
    statusFilter,
    setStatusFilter,
    isCompanyListLoading
}) => {
    
    const handleStatusChange = (status: CompanyStatusFilters | null) => {
        setStatusFilter(status);
    };

    return (
        <TableFiltersAndActions
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchPlaceholder={`Search ${termForCompany}`}
            selectedDropdownFilter={statusFilter}
            dropdownFilterOptions={companyStatusOptions}
            handleDropdownFilterChange={handleStatusChange}
            dropdownFilterLabel="Status"
            isListLoading={isCompanyListLoading}
        />
    );
};

export default CompanyHeader;
