export enum DeclarationType {
    EDeclaration = "EDeclaration",
    PaperDeclaration = "PaperDeclaration",
}

export type EDeclarationsQueryParams = {
    companyQuery: EDeclarationsCompanyQueryParams | null;
    personalInformationQuery: EDeclarationsPersonalInformationQueryParams | null;
    declarationsQuery: EDeclarationsDeclarationQueryParams | null;
    licenceOriginQuery: EDeclarationsLicenceOriginQueryParams | null;
};

export type EDeclarationsCompanyQueryParams = {
    companyIds: string[] | null;
    departmentIds: string[] | null;
    employeeId: string | null;
};

export type EDeclarationsPersonalInformationQueryParams = {
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirthAfter: Date | null;
    dateOfBirthBefore: Date | null;
};

export type EDeclarationsDeclarationQueryParams = {
    declarationTypes: DeclarationType[] | null;
    hasExpiredDeclaration: boolean | null;
    hasCompletedDeclaration : boolean | null;
    hasIncompleteDeclaration : boolean | null;
    hasExpiringDeclaration: boolean | null;
};

export type EDeclarationsLicenceOriginQueryParams = {
    ukOrigin: boolean | null;
    niOrigin: boolean | null;
    foreignOrigin: boolean | null;
};

export enum DeclarationStatusAnalysisTitles {
    EDeclarationsComplete = "E-Declarations complete",
    EDeclarationsIncomplete = "E-Declarations Incomplete / not returned",
    EDeclarationsDueToExpire = "E-Declarations due to expire",
    EDeclarationsExpired = "E-declarations Expired",
    PaperDeclarationsComplete = "Paper Declarations complete",
    PaperDeclarationsIncomplete = "Paper Declarations Incomplete / not returned",
    PaperDeclarationsDueToExpire = "Paper Declarations due to expire",
    PaperDeclarationsExpired = "Paper declarations Expired",
    DriversWithUKLicences = "DriversWithUKLicences",
    DriversWithNILicences = "DriversWithNILicences",
    DriversWithForeignLicences = "DriversWithForeignLicences",
}
