import { useEffect, useState } from "react";
import { successToast } from "../../toast";
import {
    ApiDvlaChecksResponse,
    ManualChecksFilters,
    UkDvlaCheckErrorsFilters,
    UkDvlaChecksFilters,
    manualCheckFilterKeys,
    ukDvlaCheckErrorFilterKeys,
    ukDvlaCheckFilterKeys,
} from "../../models/api/safe2DriveAdmin/dvlaChecks";
import { DvlaChecksFilterFormValues } from "../DvlaChecksTableFilters/useDvlaChecksFilterForm";

const filterNulledValues = (keys: string[]) =>
    keys.reduce((acc, key) => ({ ...acc, [key]: null }), {});

export const useDvlaCheckAnalysisActions = (
    handleSetFilters?: (value: DvlaChecksFilterFormValues) => void,
    dvlaChecksData?: ApiDvlaChecksResponse,
    tableRef?: React.MutableRefObject<HTMLDivElement | null>
) => {
    const [scrollOnLoad, setScrollOnLoad] = useState(false);

    const handleApplyFilter = (value: DvlaChecksFilterFormValues) => {
        if (!handleSetFilters) return;

        setScrollOnLoad(true);
        handleSetFilters(value);
    };

    useEffect(() => {
        // scroll once list has loaded
        if (scrollOnLoad && dvlaChecksData) {
            if (!tableRef?.current) return;
            successToast("Filter successfully applied");
            window.scrollTo(0, tableRef.current.offsetTop);

            setScrollOnLoad(false);
        }
    }, [dvlaChecksData]);

    const ukDvlaCheckAction = (key: keyof UkDvlaChecksFilters) => {
        const ukDvlaChecksFilterDefaultValues = filterNulledValues(
            ukDvlaCheckFilterKeys
        );
        const value: DvlaChecksFilterFormValues = {
            ukDvlaChecks: {
                ...ukDvlaChecksFilterDefaultValues,
                [key]: true,
            },
        };
        handleApplyFilter(value);
    };

    const ukDvlaCheckErrorsAction = (key: keyof UkDvlaCheckErrorsFilters) => {
        const ukDvlaCheckErrorsFilterDefaultValues = filterNulledValues(
            ukDvlaCheckErrorFilterKeys
        );
        const value: DvlaChecksFilterFormValues = {
            ukDvlaCheckErrors: {
                ...ukDvlaCheckErrorsFilterDefaultValues,
                [key]: true,
            },
        };
        handleApplyFilter(value);
    };

    const manualChecksFilterDefaultValues = filterNulledValues(
        manualCheckFilterKeys
    );
    const ukManualCheckAction = (key: keyof ManualChecksFilters) => {
        const value: DvlaChecksFilterFormValues = {
            ukManualChecks: {
                ...manualChecksFilterDefaultValues,
                [key]: true,
            },
        };
        handleApplyFilter(value);
    };

    const northernIrishManualCheckAction = (key: keyof ManualChecksFilters) => {
        const value: DvlaChecksFilterFormValues = {
            northernIrishManualChecks: {
                ...manualChecksFilterDefaultValues,
                [key]: true,
            },
        };
        handleApplyFilter(value);
    };

    const foreignManualCheckAction = (key: keyof ManualChecksFilters) => {
        const value: DvlaChecksFilterFormValues = {
            foreignManualChecks: {
                ...manualChecksFilterDefaultValues,
                [key]: true,
            },
        };
        handleApplyFilter(value);
    };

    return {
        ukDvlaCheckAction,
        ukDvlaCheckErrorsAction,
        ukManualCheckAction,
        northernIrishManualCheckAction,
        foreignManualCheckAction,
    };
};
