import * as yup from "yup";
import { EditCompanyFormValues } from "../../../../EditCompanyForms/EditCompanyFormValues";
import { CheckSettings } from "../../../../../models/api/admin/company";
import { EditEdeclarationSettingsSchema } from "../CheckSettingsFormSchemas";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";
import { DriverValidationTypes } from "../../../../../models/app/admin/company";

export type EditEdeclarationSettingsFormValues = NonNullable<
    CheckSettings["settings"]["edeclaration"]
>;

const useEditEdeclarationSettingsForm = (
    initialValues: EditCompanyFormValues
) => {
    const isLoading = useRef(false);

    const displayedValues: EditEdeclarationSettingsFormValues = {
        useParentContact: true,
        driverValidationType: DriverValidationTypes.DateOfBirth,
        ...initialValues.settings?.edeclaration,
    };

    const validationSchema = yup.object().shape(EditEdeclarationSettingsSchema);

    const methods = useForm<EditEdeclarationSettingsFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: displayedValues || null,
    });

    useEffect(() => {
        methods.reset(displayedValues);
        isLoading.current = false;
    }, [JSON.stringify(displayedValues)]);

    return {
        methods,
        displayedValues,
        isLoading,
    };
};

export default useEditEdeclarationSettingsForm;
