import { ApiDashboardResponse } from "../../../models/api/admin/dashboard";
// import CompareSelector from "../../CompareSelector";
// import MonthPicker from "../../MonthPicker";
import DriverLicenceCharts from "../../ChartGroups/DriverLicenceCharts";
import DashboardLineCharts from "./DashboardLineCharts";
import { type DashboardContentSections } from "../../../pages/Dashboard";
import GreyFleetVehicleAnalysisCharts from "../../ChartGroups/GreyFleetVehicleAnalysisCharts";
import useDriverLicenceCharts from "../../ChartGroups/useDriverLicenceCharts";

type DashboardReportsProps = {
    date: Date;
    compareMonths?: number;
    data?: ApiDashboardResponse;
    loading: boolean;
    onDateChange: (date: Date) => void;
    onCompareMonthsChange: (months: number | undefined) => void;
    selectedContent: DashboardContentSections;
    companyId?: string;
    departmentId?: string;
};

const DashboardReports = ({
    // date,
    compareMonths,
    data,
    loading,
    // onDateChange,
    // onCompareMonthsChange,
    selectedContent,
    companyId,
    departmentId,
}: DashboardReportsProps) => {
    const sectionTitle =
        selectedContent === "Grey Fleet"
            ? "Vehicle analysis"
            : "Driver licence analysis";

    const driverLicenceChartData = useDriverLicenceCharts(
        companyId,
        departmentId
    );

    return (
        <section
            about="driver licence analysis"
            className="mb-4 flex flex-col gap-6 rounded-sm bg-S2D-dark-green-70.3 p-6"
        >
            <p className=" text-lg font-semibold">{sectionTitle}</p>
            {/* <div className="mb-4 flex flex-wrap items-center gap-5">
                <MonthPicker
                    value={date}
                    maxDate={new Date()}
                    onChange={onDateChange}
                />
                <CompareSelector
                    label="Compare"
                    options={[
                        {
                            label: "Compare against the previous month",
                            value: 2,
                        },
                        {
                            label: "Compare against the previous 2 months",
                            value: 3,
                        },
                        {
                            label: "Compare against the previous 3 months",
                            value: 4,
                        },
                        {
                            label: "Compare against the previous 4 months",
                            value: 5,
                        },
                        {
                            label: "Compare against the previous 5 months",
                            value: 6,
                        },
                        {
                            label: "Compare against the previous 6 months",
                            value: 7,
                        },
                    ]}
                    defaultOption={2}
                    value={compareMonths}
                    onChange={onCompareMonthsChange}
                />
            </div> */}
            {!compareMonths && selectedContent !== "Grey Fleet" && (
                <DriverLicenceCharts data={driverLicenceChartData} />
            )}
            {!compareMonths && selectedContent == "Grey Fleet" && (
                <GreyFleetVehicleAnalysisCharts
                    companyId={companyId}
                    departmentId={departmentId}
                />
            )}
            {compareMonths && (
                <DashboardLineCharts data={data} loading={loading} />
            )}
        </section>
    );
};

export default DashboardReports;
