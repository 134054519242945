import * as yup from "yup";
import { GREAT_BRITAIN } from "../../constants/countries";
import { DriverValidationTypes } from "../../models/app/admin/company";
import { dateValidation } from "../../utils/dateValidationYup";

export const eDeclarationFormVerifyIdentitySchemaFields = (
    validateDriverIdentityBy: DriverValidationTypes
) => {
    return {
        firstName: yup.string().trim().required("First name is required"),
        lastName: yup.string().trim().required("Surname is required"),
        dateOfBirth:
            validateDriverIdentityBy === DriverValidationTypes.DateOfBirth
                ? dateValidation
                      .required("Date of birth is required")
                      .nullable()
                      .max(new Date(), "Date of birth must be before today")
                : dateValidation
                      .notRequired()
                      .nullable()
                      .transform((value) => {
                          if (value !== "") return value;
                          return null;
                      }),
        employeeId:
            validateDriverIdentityBy === DriverValidationTypes.EmployeeId
                ? yup.string().trim().required("Employee ID is required")
                : yup
                      .string()
                      .notRequired()
                      .nullable()
                      .transform((value) => {
                          if (value !== "") return value;
                          return null;
                      }),
        emailAddress: yup
            .string()
            .trim()
            .email(
                "Email address must be a valid email address, eg. hello@example.com "
            )
            .required("Email address is required"),
    };
};

export const eDeclarationFormValidationSchemaFields = {
    licenceNumber: yup
        .string()
        .typeError("Licence number must be 16 characters with no spaces, eg. MORGA753116SM9IJ")
        .matches(
            /^[A-Z9]{5}\d[0156]\d([0][1-9]|[12]\d|3[01])\d[A-Z9]{2}\d[A-Z]{2}$/,
            "Licence number must be 16 characters with no spaces, eg. MORGA753116SM9IJ"
        )
        .required("Licence number must be 16 characters with no spaces, eg. MORGA753116SM9IJ"),
    licenceCountryOrigin: yup.string().required("Licence type is required"),
    employerPermission: yup
        .bool()
        .oneOf([true], "Please tick the box to confirm your acceptance")
        .required("Please tick the box to confirm your acceptance"),
    confirmValidLicence: yup
        .bool()
        .oneOf([true], "Please tick the box to confirm your acceptance")
        .required("Please tick the box to confirm your acceptance"),
    address: yup.object({
        address1: yup.string().required("Address line 1 is required"),
        address2: yup.string().nullable(),
        address3: yup.string().nullable(),
        address4: yup.string().nullable(),
        address5: yup.string().nullable(),
        addressTown: yup.string().required("City is required"),
        addressPostcode: yup
            .string()
            .matches(
                /^([a-zA-Z][a-hj-yA-HJ-Y]?[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}|GIR ?0A{2})$/,
                "Postcode is invalid"
            )
            .required("Postcode is required"),
    }),
};

export type EDeclarationFormValues = {
    firstName: string;
    lastName: string;
    licenceNumber: string;
    foreignLicence: boolean;
    dateOfBirth: Date | null;
    employeeId: string;
    emailAddress: string;
    address: EDeclarationFormAddressValues;
    licenceCountryOrigin: string;
    mobileNumber: string | null;
    driverId: string;
    employerPermission: boolean;
    confirmValidLicence: boolean;
};

export type EDeclarationFormAddressValues = {
    address1?: string;
    address2?: string;
    address3?: string;
    address4?: string;
    address5?: string;
    addressTown?: string;
    addressPostcode?: string;
};

export type EDeclarationFormVerificationCode = {
    verificationCode: string;
};

export const EdeclarationFormDefaultValues: EDeclarationFormValues = {
    firstName: "",
    lastName: "",
    licenceNumber: "",
    foreignLicence: false,
    dateOfBirth: null,
    employeeId: "",
    emailAddress: "",
    address: {
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        address5: "",
        addressTown: "",
        addressPostcode: "",
    },
    licenceCountryOrigin: GREAT_BRITAIN,
    mobileNumber: null,
    driverId: "",
    employerPermission: false,
    confirmValidLicence: false,
};
