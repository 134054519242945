import clsx from "clsx";
import { ControlProps, GroupBase } from "react-select";

const FormSearchDropdownControl = ({
    selectProps,
    innerRef,
    menuIsOpen,
    innerProps,
    children,
    isDisabled,
    isFocused,
}: ControlProps<unknown, boolean, GroupBase<unknown>>) => (
    <div
        ref={innerRef}
        className={clsx(
            "relative flex h-14 w-full cursor-default flex-row items-center rounded-sm border p-3 px-4 pr-10 text-left transition-background-color hover:bg-white",
            !isFocused && "border-black",
            !isFocused && !isDisabled && "bg-S2D-dark-green-70.1",
            isFocused &&
                "border-S2D-light-green-80 bg-white outline-none ring-2 ring-inset ring-S2D-light-green-80",
            menuIsOpen &&
                "z-50 rounded-b-none border-b ring-2 ring-inset ring-S2D-light-green-80",
            selectProps.error &&
                "border-S2D-error-40 pr-10 outline-none ring-S2D-error-40 focus:border-S2D-error-40 focus:ring-S2D-error-40",
            menuIsOpen && selectProps.error && "border-b",
            isDisabled && "cursor-not-allowed opacity-40"
        )}
        {...innerProps}
    >
        {children}
    </div>
);

export default FormSearchDropdownControl;
