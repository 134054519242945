import { Controller, UseFormReturn } from "react-hook-form";
import { formatDateToString } from "../../../utils/formatDateToString";
import { makeLocalAppearUTC } from "../../../utils/localToUTC";
import Button from "../../Button";
import EditableContentBlock from "../../EditableContentBlock";
import clsx from "clsx";
import FormDateInput from "../../FormDateInput";
import {
    EditDvlaFormValues,
    EditDvlaCpcFormValues,
} from "../EditDvlaFormValues";

export type EditCpcFormProps = {
    initialValues: EditDvlaFormValues;
    methods: UseFormReturn<EditDvlaCpcFormValues>;
    isLoading: boolean;
    onSubmit: (
        data: EditDvlaCpcFormValues,
        callback: () => void
    ) => Promise<void>;
    onDelete: (callback: () => void) => Promise<void>;
    isDeleting: boolean;
    canEdit: boolean;
};

const EditCpcForm: React.FC<EditCpcFormProps> = ({
    initialValues,
    methods,
    isLoading,
    onSubmit,
    onDelete,
    isDeleting,
    canEdit,
}) => {
    return (
        <EditableContentBlock title="Cpc" disableEdit={!canEdit}>
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            {initialValues.cpc ? (
                                <>
                                    <p>
                                        <b>Card valid from:</b>{" "}
                                        {initialValues.cpc.cardValidFrom
                                            ? formatDateToString(
                                                  makeLocalAppearUTC(
                                                      initialValues.cpc
                                                          .cardValidFrom
                                                  ) ??
                                                      initialValues.cpc
                                                          .cardValidFrom
                                              )
                                            : ""}
                                    </p>
                                    <p>
                                        <b>LGV valid to:</b>{" "}
                                        {initialValues.cpc.lgvValidTo
                                            ? formatDateToString(
                                                  makeLocalAppearUTC(
                                                      initialValues.cpc
                                                          .lgvValidTo
                                                  ) ??
                                                      initialValues.cpc
                                                          .lgvValidTo
                                              )
                                            : ""}
                                    </p>
                                    <p>
                                        <b>PCV valid to:</b>{" "}
                                        {initialValues.cpc.pcvValidTo
                                            ? formatDateToString(
                                                  makeLocalAppearUTC(
                                                      initialValues.cpc
                                                          .pcvValidTo
                                                  ) ??
                                                      initialValues.cpc
                                                          .pcvValidTo
                                              )
                                            : ""}
                                    </p>
                                </>
                            ) : (
                                <p>
                                    This driver does not have a certificate of
                                    professional competence.
                                </p>
                            )}
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit(
                                    (data: EditDvlaCpcFormValues) =>
                                        onSubmit(data, () => setIsEdit(false))
                                )}
                            >
                                <Controller
                                    name="cardValidFrom"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            id="cardValidFrom"
                                            label="Card valid from"
                                            value={value}
                                            onChange={onChange}
                                            error={
                                                methods.formState.errors
                                                    .cardValidFrom
                                            }
                                        />
                                    )}
                                />
                                <Controller
                                    name="lgvValidTo"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            id="lgvValidTo"
                                            label="LGV valid to"
                                            value={value}
                                            onChange={onChange}
                                            error={
                                                methods.formState.errors
                                                    .lgvValidTo
                                            }
                                        />
                                    )}
                                />
                                <Controller
                                    name="pcvValidTo"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            id="pcvValidTo"
                                            label="PCV valid to"
                                            value={value}
                                            onChange={onChange}
                                            error={
                                                methods.formState.errors
                                                    .pcvValidTo
                                            }
                                        />
                                    )}
                                />
                                <div className="mt-8 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => {
                                            methods.reset();
                                            setIsEdit(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <div className="flex">
                                        {initialValues.cpc != null && (
                                            <Button 
                                                variant="danger" 
                                                loading={isLoading} 
                                                type="button" 
                                                onClick={() => {
                                                    onDelete(() => setIsEdit(false));
                                                    methods.setValue("cardValidFrom", null);
                                                    methods.setValue("lgvValidTo", null);
                                                    methods.setValue("pcvValidTo", null);
                                                }}
                                                className={clsx(
                                                    "mr-4",
                                                    !isDeleting && "cursor-pointer",
                                                    isDeleting &&
                                                        "cursor-not-allowed text-S2D-neutral-30"
                                                )}
                                                >
                                                Delete
                                            </Button>
                                        )}
                                        <Button type="submit" loading={isLoading}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditCpcForm;
