import * as Yup from "yup";

export const PasswordResetFormSchema = {
    existingPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()])[A-Za-z\d@$!%*?&()]{8,}$/,
            "Password must contain at least 8 characters, a mix of lower and upper case, at least 1 number and 1 special character (@$!%*?&())."
        )
        .required("please enter a new password"),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Must confirm password before submitting"),
};

export type PasswordResetFormValues = {
    existingPassword: string;
    newPassword: string;
    confirmNewPassword: string;
};
