import clsx from "clsx";
import { ArrowSquareOut, IconProps } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../../Routes";
import { driverPageLocation } from "../../../utils/driverPageLocation";
import { useAppliedFilterLocalStorage } from "../../../hooks/useAppliedFilterLocalStorage";
import { useIsSmallScreen } from "../../../hooks/screenSizes";
import {
    RiskProfileLevels,
    type SeverityLevelPointsRange,
} from "../../../models/app/admin/driver";
import { type FilterLicenceInformationDto } from "../../../models/app/admin/driverFilters";
import { driverListFiltersDefaultValues } from "../../DriverListFilters/DriversListFiltersForm/driversListFiltersDefaultValues";
import { useScrollToTableBool } from "../../../hooks/useScrollToTable";

export type RiskCategoryProps = {
    count: number;
    range?: SeverityLevelPointsRange;
    severityLevel: RiskProfileLevels[];
    theme: "green" | "yellow" | "red" | "black";
    icon?: React.ForwardRefExoticComponent<
        IconProps & React.RefAttributes<SVGSVGElement>
    >;
    selectedCompany?: string;
    selectedDepartment?: string;
};

const RiskCategory = ({
    count,
    range,
    severityLevel,
    theme = "green",
    icon: Icon,
    selectedCompany,
    selectedDepartment,
}: RiskCategoryProps) => {
    const sendToLocation = driverPageLocation(appPaths.adminDrivers);
    const { setAppliedFilter } = useAppliedFilterLocalStorage(sendToLocation);
    const navigate = useNavigate();
    const { setScrollToTable } = useScrollToTableBool();

    const handleFiltering = () => {
        const defaultVals = driverListFiltersDefaultValues();

        const shouldIncludeLicenceFilter =
            severityLevel[0] === RiskProfileLevels.Low ||
            severityLevel[0] === RiskProfileLevels.Medium ||
            severityLevel[0] === RiskProfileLevels.High;
        const licenceFilter: FilterLicenceInformationDto | undefined =
            shouldIncludeLicenceFilter
                ? {
                      ...defaultVals.licence,
                      points: range ?? {
                          from: null,
                          to: null,
                      },
                  }
                : undefined;

        setAppliedFilter({
            filterId: "",
            filterFields: {
                ...defaultVals,
                riskProfiles: [...severityLevel],
                company: {
                    companyIds: selectedCompany ? [selectedCompany] : null,
                    departmentIds: selectedDepartment
                        ? [selectedDepartment]
                        : null,
                    employeeId: null,
                    companyDashboard: defaultVals.company.companyDashboard,
                },
                licence: licenceFilter,
            },
        });

        setScrollToTable(true);
        navigate(appPaths.adminDrivers);
    };

    const isBiggerScreen = useIsSmallScreen();

    const contentColor = clsx(
        theme === "green" && "S2D-neutral-10",
        theme === "yellow" && "S2D-neutral-10",
        theme === "red" && "S2D-neutral-100",
        theme === "black" && "S2D-neutral-100"
    );

    const bgColor = clsx(
        theme === "green" && "bg-S2D-success-80",
        theme === "yellow" && "bg-S2D-warning-90",
        theme === "red" && "bg-S2D-error-40",
        theme === "black" && "bg-S2D-neutral-20"
    );

    const rangeToString = (range: SeverityLevelPointsRange) => {
        if (
            severityLevel[0] === RiskProfileLevels.Low ||
            severityLevel[0] === RiskProfileLevels.Medium
        ) {
            if (range.from === null || !range.to) return;
            if (range.from === range.to) return range.from.toString();
            return `${range.from}-${range.to}`;
        } else {
            if (range.from === null) return;
            return `${range.from}+`;
        }
    };

    return (
        <button
            className={clsx(
                "flex h-[84px] min-w-[250px] grow items-center justify-between space-x-3 px-4 py-2",
                "rounded border-2 border-S2D-dark-green-70.3",
                `text-${contentColor}`,
                bgColor
            )}
            onClick={handleFiltering}
        >
            <div className="flex items-center space-x-2 ">
                {Icon && (
                    <Icon
                        size={isBiggerScreen ? 32 : 25}
                        className={clsx(`text-${contentColor}`)}
                    />
                )}
                <span className={"text-4xl font-medium md:text-6xl"}>
                    {count}
                </span>
                <div className="text-start text-sm font-semibold uppercase">
                    {severityLevel[0] !== RiskProfileLevels.Critical ? (
                        <>
                            {range && (
                                <span>
                                    {rangeToString(range)}
                                    <br />
                                    POINTS
                                </span>
                            )}
                        </>
                    ) : (
                        <span>
                            Critical status <br /> (drivers who are not entitled
                            to drive){" "}
                        </span>
                    )}
                </div>
            </div>
            <div
                className={clsx(
                    `hidden rounded border-2 p-2 sm:inline`,
                    `border-${contentColor}`
                )}
            >
                <ArrowSquareOut size={20} />
            </div>
        </button>
    );
};

export default RiskCategory;
