import { useState } from "react";
import { type DriversListFiltersFormValues } from "./DriversListFiltersFormValues";
import { summarizeObjectToString } from "../../../utils/summarizeObjectToString";
import { type UseFormReturn } from "react-hook-form";

type ValueOf<Type> = Type[keyof Type];
type GetAllFilter = NonNullable<ValueOf<DriversListFiltersFormValues>>;

export const useSelectedValues = (
    methods: UseFormReturn<DriversListFiltersFormValues>
) => {
    const initSelectedValues = (defaultValues: GetAllFilter) => {
        const initialSelectedValues: string[] = new Array(0);
        summarizeObjectToString(defaultValues, initialSelectedValues);
        return initialSelectedValues.length ? initialSelectedValues : [];
    };
    const defaultValues = methods.getValues();

    // split selected values by dropdown compartment
    const [
        personalInformationSelectedValues,
        setPersonalInformationSelectedValues,
    ] = useState<string[]>(
        defaultValues.personalInformation
            ? initSelectedValues(defaultValues.personalInformation)
            : []
    );
    const [employmentSelectedValues, setEmploymentSelectedValues] = useState<
        string[]
    >(defaultValues.company ? initSelectedValues(defaultValues.company) : []);
    const [driverAddressSelectedValues, setDriverAddressSelectedValues] =
        useState<string[]>(
            defaultValues.driverAddress
                ? initSelectedValues(defaultValues.driverAddress)
                : []
        );
    const [linkedDriverSelectedValues, setLinkedDriverSelectedValues] =
        useState<string[]>(
            defaultValues.linkedDriver
                ? initSelectedValues(defaultValues.linkedDriver)
                : []
        );
    const [
        licenceInformationSelectedValues,
        setLicenceInformationSelectedValues,
    ] = useState<string[]>(
        defaultValues.licence ? initSelectedValues(defaultValues.licence) : []
    );

    const clearSelectedValues = () => {
        setPersonalInformationSelectedValues([]);
        setEmploymentSelectedValues([]);
        setDriverAddressSelectedValues([]);
        setLinkedDriverSelectedValues([]);
        setLicenceInformationSelectedValues([]);
    };

    return {
        selectedValues: {
            personalInformation: personalInformationSelectedValues,
            employment: employmentSelectedValues,
            driverAddress: driverAddressSelectedValues,
            linkedDriver: linkedDriverSelectedValues,
            licence: licenceInformationSelectedValues,
        },
        setSelectedValues: {
            personalInformation: setPersonalInformationSelectedValues,
            employment: setEmploymentSelectedValues,
            driverAddress: setDriverAddressSelectedValues,
            linkedDriver: setLinkedDriverSelectedValues,
            licence: setLicenceInformationSelectedValues,
        },
        clearSelectedValues,
    };
};
