import { type EditCompanyUserDto } from "../../models/app/admin/companyUser";
import { SchemaKeys } from "../../models/shared/schemaKeysHelper";
import { AddCompanyUserFormValidationSchema } from "../AddCompanyUserModal/AddCompanyUserFormValues";
import { ApiCompanyUserResponse } from "../../models/api/admin/companyUser";
import * as yup from "yup";

export const EditCompanyUserFormValidationSchema: SchemaKeys<EditCompanyUserFormValues> =
    { ...AddCompanyUserFormValidationSchema, status: yup.string().required() };

export type EditCompanyUserFormValues = EditCompanyUserDto;

export const editCompanyUserFromDefaultValues = (
    selectedUser: ApiCompanyUserResponse
): EditCompanyUserFormValues => {
    return {
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        emailAddress: selectedUser.emailAddress,
        departmentIds: selectedUser.departmentIds,
        roleName: selectedUser.roleNames[0],
        status: selectedUser.status,
        claims: {
            "file-safe": selectedUser.claims?.["file-safe"] ?? false,
            "view-pii": selectedUser.claims?.["view-pii"] ?? false,
            "client-management":
                selectedUser.claims?.["client-management"] ?? false,
        },
        shouldReceiveEmailNotifications: selectedUser.shouldReceiveEmailNotifications ?? false
    };
};
