import { useParams } from "react-router-dom";
import { useEdeclaration } from "../api/edeclaration";
import EDeclarationForm from "../components/EDeclarationForm/EDeclarationForm";

import Logo from "../s2d-dark-logo.svg";
import { ThemeProvider } from "../theme/ThemeContext";

type PageParams = {
    id: string;
};

const EDeclarationPage: React.FC = () => {
    const { id } = useParams<PageParams>();

    const { edeclarationBaseData } = useEdeclaration(id);

    const jointEdecGreyFleet =
        edeclarationBaseData?.greyFleetInviteId &&
        edeclarationBaseData?.isGreyFleetInviteCompleted === false
            ? true
            : false;

    const greyFleetInviteId = edeclarationBaseData?.greyFleetInviteId ?? "";

    return (
        <ThemeProvider companyId={edeclarationBaseData?.employerId}>
            <div className="flex min-h-screen w-screen flex-col items-center justify-center bg-S2D-dark-green-40 sm:px-4 sm:py-8">
                <div className="flex w-full max-w-6xl flex-1 flex-col rounded-sm bg-white">
                    <header className="flex flex-none flex-col items-center justify-between px-6 py-4 sm:flex-row sm:px-8">
                        <div className="mb-4 flex h-12 items-center text-2xl font-semibold sm:mb-0">
                            <img src={Logo} alt="Safe 2 Drive Logo" />
                        </div>
                        <div className="flex items-center justify-center gap-8 sm:ml-auto">
                            {edeclarationBaseData?.resellerLogoUrl && (
                                <div className="h-12">
                                    <img
                                        className="h-full w-full object-contain"
                                        src={
                                            edeclarationBaseData.resellerLogoUrl
                                        }
                                        alt={edeclarationBaseData.resellerName}
                                    />
                                </div>
                            )}
                            {edeclarationBaseData?.employerLogoUrl && (
                                <div className="h-12">
                                    <img
                                        className="h-full w-full object-contain"
                                        src={
                                            edeclarationBaseData.employerLogoUrl
                                        }
                                        alt={edeclarationBaseData.employerName}
                                    />
                                </div>
                            )}
                        </div>
                    </header>
                    <main className="flex flex-1 flex-col px-6 py-8 pt-0 sm:px-8">
                        {id && (
                            <EDeclarationForm
                                eDeclarationId={id}
                                defaultStep={0}
                                jointEdecGreyFleet={jointEdecGreyFleet}
                                greyFleetInviteId={greyFleetInviteId}
                                driverValidationType={
                                    edeclarationBaseData?.driverValidationType
                                }
                            />
                        )}
                    </main>
                </div>
            </div>
        </ThemeProvider>
    );
};

export default EDeclarationPage;
