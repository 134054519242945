/**
 * This is to either ground a date to exactly midnight, or to ceiling it
 * to one minute before midnight the following day.
 */
export const anchorDate = (date: Date | null, type: "min" | "max") => {
    if (!date || !type) return;

    if (typeof date === "string") {
        date = new Date(date);
    }

    if (type === "min") return new Date(date.setHours(0, 0, 0, 0));
    if (type === "max") return new Date(date.setHours(23, 59, 59, 0));
};
