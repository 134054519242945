import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useUnverifiedUser } from "../../api/user";
import ForgottenPasswordResetForm from "../../components/ForgottenPasswordResetForm";
import { ForgottenPasswordResetFormValues } from "../../components/ForgottenPasswordResetForm/ForgottenPasswordResetFormValues";
import { errorToast, successToast } from "../../toast";
import { ForgottenPasswordResetDto } from "../../models/app/user";
import { appPaths } from "../../Routes";

const ForgottenPasswordResetPage = () => {
    const { forgottenPasswordReset, isResettingPassword } = useUnverifiedUser();
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const navigate = useNavigate();
    const token = searchParams.get("token")?.replaceAll(" ", "+");

    const handlePasswordReset = async (
        data: ForgottenPasswordResetFormValues
    ) => {
        if (!id || !token) {
            return;
        }

        const requestBody: ForgottenPasswordResetDto = {
            newPassword: data.newPassword,
            passwordResetToken: token,
        };

        const res = await forgottenPasswordReset(id, requestBody);
        if (res.success) {
            successToast("Password has been reset");
            navigate(appPaths.login)
        } 
    };

    return (
        <div className="w-screen h-screen flex justify-center items-center bg-S2D-surface-light-3">
            <ForgottenPasswordResetForm
                onSubmit={handlePasswordReset}
                isLoading={isResettingPassword}
            />
        </div>
    );
};

export default ForgottenPasswordResetPage;
