import { capitaliseWord } from "../utils/capitaliseWord";

// NB: setting 'client' etc as constants here just in-case S2D want to switch back to
// 'company' or 'organisation' etc in the future.
// Also, should make it more clear why the code has many uses of 'company'/'companies' 
export const termForCompany = `client`;
export const capitalisedTermForCompany = capitaliseWord(termForCompany);
export const pluralTermForCompany = `clients`;
export const capitalisedPluralTermForCompany = capitaliseWord(pluralTermForCompany);

export const clientCopy = {
    addClientTitle: `${capitaliseWord(termForCompany)} details`,
    clientNameLabel: `${capitaliseWord(termForCompany)} Name`,
    clientNamePlaceholder: `Enter ${termForCompany} name`,
    clientParentSelectText: `Select parent ${termForCompany}`,
    clientParentLabel: `Parent ${capitaliseWord(termForCompany)}`, 
    clientSearchTooltip: `Type to search for ${termForCompany} options`,
    clientsFailedToFetchError: `failed to fetch ${termForCompany}s`,
    noClientIdError: `No ${termForCompany} Id found`,
    useParentForEmailLabel: `Use parent ${termForCompany} for email contact`
};

