import { SetStateAction } from "react";
import DialogBox from "../../../DialogBox";
import AddInvoiceReferenceNumberForm from "./AddInvoiceReferenceNumberForm/AddInvoiceReferenceNumberForm";
import { AddOrEditModalStates } from "../CompanyBillingTab";
import { capitaliseWord } from "../../../../utils/capitaliseWord";
import { ApiDepartmentItem } from "../../../../models/api/admin/department";

type AddInvoiceReferenceNumberModalProps = {
    companyId: string;
    companyName: string;
    isOpen: boolean;
    setModalState: (value: SetStateAction<AddOrEditModalStates>) => void;
    refetchInvoiceReferenceNumbers: () => void;
    type: AddOrEditModalStates;
    departments: ApiDepartmentItem[];
};

const AddInvoiceReferenceNumberModal: React.FC<AddInvoiceReferenceNumberModalProps> = ({
    companyId,
    companyName,
    isOpen,
    setModalState,
    refetchInvoiceReferenceNumbers,
    type = "closed",
    departments
}) => {
    return (
        <DialogBox
            isOpen={isOpen}
            onClose={() => setModalState("closed")}
            title={`${capitaliseWord(type)} invoice reference/PO number`}
            size="xl"
            closeButton
        >
            {type === "add" && (
                <AddInvoiceReferenceNumberForm
                    setModalState={setModalState}
                    departments={departments}
                    refetchInvoiceReferenceNumbers={refetchInvoiceReferenceNumbers}
                    companyName={companyName}
                    companyId={companyId}       
                />
            )}
        </DialogBox>
    );
};

export default AddInvoiceReferenceNumberModal;
