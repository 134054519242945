import { useGetDriversReport } from "../../../api/admin/driversDownload";
import { useState, useRef } from "react";
import { DriverCategory } from "../../../utils/driverPageLocation";
import { downloadBlobAsFile } from "../../../utils/downloadBlobAsFile";
import { errorToast, successToast } from "../../../toast";
import { type ApiCompanyResponse } from "../../../models/api/admin/company";
import { type ApiDepartmentItem } from "../../../models/api/admin/department";
import { useDownloadComponentToPdf } from "../../../hooks/useDownloadComponentToPdf";
import useDriversPage from "./useDriversPage";
import { FilterType } from "../../../models/app/admin/filter";

const useDownloadDriversReports = (
    location?: DriverCategory,
    selectedCompany?: ApiCompanyResponse | null,
    selectedDepartment?: ApiDepartmentItem | null,
    appliedFilter?: FilterType,
) => {
    const {
        constructAllDriversBodyForReport,
    } = useDriversPage(location ?? "drivers", true);
    const { getDriversReport } = useGetDriversReport();
    const [isCsvReportDownloading, setIsCsvReportDownloading] =
        useState<boolean>(false);

    const handleDownloadCsvReport = async () => {
        setIsCsvReportDownloading(true);

        const fileNameArray = ["Drivers.csv"];
        if (selectedDepartment)
            fileNameArray.unshift(selectedDepartment.departmentName);
        if (selectedCompany) fileNameArray.unshift(selectedCompany.companyName);
        const fileName = fileNameArray.join("-");
        try {
            const driversReportBody = constructAllDriversBodyForReport(appliedFilter?.filterFields, undefined, selectedCompany?.companyId, selectedDepartment?.departmentId);
            const response = await getDriversReport(
                driversReportBody
            );
            if (response.success) {
                downloadBlobAsFile(response.content, fileName);
                successToast(`successfully downloaded drivers report`);
                setTimeout(() => setIsCsvReportDownloading(false), 1000);
            }
        } catch (error) {
            setTimeout(() => setIsCsvReportDownloading(false), 1000);
            errorToast(`failed to download drivers report`);
        }
    };

    const reportRef = useRef<HTMLDivElement | null>(null);
    const { generatePDF } = useDownloadComponentToPdf(reportRef);
    const [showReportView, setShowReportView] = useState(false);

    const handleDownloadPdfReport = (): void => {
        setShowReportView(true);

        const fileNameArray = [
            selectedCompany?.companyName ?? "All clients",
            selectedDepartment?.departmentName ?? "All departments",
            location === "professionalDrivers"
                ? "Professional drivers report"
                : "Drivers report",
        ];
        generatePDF(() => setShowReportView(false), fileNameArray.join("-"));
    };

    return {
        handleDownloadCsvReport,
        isReportDownloading: isCsvReportDownloading || showReportView,
        handleDownloadPdfReport,
        showReportView,
        reportRef,
    };
};

export default useDownloadDriversReports;
