import { useState } from "react";
import { useFileTransfer, useGetFileTransferNotifications } from "../../api/admin/fileSafe";
import { errorToast, successToast } from "../../toast";
import {
    ApiFileTransferItem,
    type ApiGetFileTransfersResponse,
} from "../../models/api/admin/fileSafe";
import { TransferStatus } from "../../models/app/admin/fileSafe";
import useAuth from "../../auth/useAuth";

const useFileSafeTableActions = (
    tableData: ApiGetFileTransfersResponse,
    refetchTableData: () => void
) => {
    const [selectedTransfer, setSelectedTransfer] =
        useState<ApiFileTransferItem>();
    const [allowMarkForDeletion, setAllowMarkForDeletion] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [flagForDeletionDialogOpen, setFlagForDeletionDialogOpen] =
        useState(false);

    const {
        deleteTransfer,
        isDeletingTransfer,
        markForDeletion,
        isMarkingForDeletion,
    } = useFileTransfer();

    const { decodedToken } = useAuth();
    const { refetchNotificationCount } = useGetFileTransferNotifications(decodedToken?.fileSafe);

    const handleSelectedTransfer = (id: string, type: "delete" | "flag") => {
        const newTransfer = tableData.items.find(
            (transfer) => transfer.transferId === id
        );

        if (!newTransfer) {
            errorToast("Couldn't identify file transfer.");
            setSelectedTransfer(undefined);
            setAllowMarkForDeletion(false);
        } else {
            setSelectedTransfer(newTransfer);
            setAllowMarkForDeletion(
                newTransfer?.status.replaceAll(" ", "") !==
                    TransferStatus.MarkedForDeletion
            );
            switch (type) {
                case "delete":
                    setDeleteDialogOpen(true);
                    break;
                case "flag":
                    setFlagForDeletionDialogOpen(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleDeleteTransfer = async () => {
        if (!selectedTransfer?.transferId || !decodedToken?.role) {
            errorToast("Could not find transfer details. Please try again.");
            return;
        }

        const response = await deleteTransfer(
            selectedTransfer?.transferId,
            decodedToken.role
        );

        if (!response.success) {
            errorToast("Error deleting file transfer, please try again later.");
        } else {
            successToast("File transfer succesfully deleted.");
            setDeleteDialogOpen(false);
            refetchTableData();
            refetchNotificationCount();
        }
    };

    const markForDeletionMessage = () => {
        if (allowMarkForDeletion) {
            return "This will notify Safe2Drive that you want to delete this transfer.";
        } else {
            return "This transfer is already marked for deletion.";
        }
    };

    const handleFlagForDeletion = async () => {
        if (!selectedTransfer?.transferId || !decodedToken?.role) {
            errorToast("Could not find transfer details. Please try again.");
            return;
        }
        if (!allowMarkForDeletion) return;

        const formData = new FormData();
        formData.append("Status", TransferStatus.MarkedForDeletion);

        const response = await markForDeletion(
            selectedTransfer.transferId,
            formData,
            decodedToken.role
        );
        if (!response.success) {
            errorToast("Error flagging for deletion, please try again later.");
        } else {
            successToast("File transfer has been flagged for deletion.");
            setFlagForDeletionDialogOpen(false);
            refetchTableData();
            refetchNotificationCount();
        }
    };

    return {
        handleSelectedTransfer,
        deleteDialogOpen,
        setDeleteDialogOpen,
        flagForDeletionDialogOpen,
        setFlagForDeletionDialogOpen,
        handleDeleteTransfer,
        handleFlagForDeletion,
        isDeletingTransfer,
        isMarkingForDeletion,
        allowMarkForDeletion,
        markForDeletionMessage,
        selectedTransfer
    };
};

export default useFileSafeTableActions;
