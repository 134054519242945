import { useState } from "react";
import * as yup from "yup";

import GreyFleetFormLayout from "./GreyFleetFormLayout";
import Button from "../Button";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../FormTextInput";

import {
    GreyFleetPersonalDetailsFormValidationSchemaFields,
    GreyFleetFormPersonalDetailsValues,
} from "./GreyFleetFormValues";
import FormMessageBox from "../FormMessageBox";
import FormDateInput from "../FormDateInput";
import { DriverValidationTypes } from "../../models/app/admin/company";

type Step1VerifyIdentityProps = {
    defaultValues: GreyFleetFormPersonalDetailsValues;
    isLoading: boolean;
    onSubmit: (
        values: GreyFleetFormPersonalDetailsValues,
        callback: (error: true) => void,
        driverValidationType: DriverValidationTypes | undefined
    ) => void;
    onBackClick: () => void;
    company: string;
    department: string;
    driverValidationType?: DriverValidationTypes;
    jointEdecGreyFleet?: boolean;
};

const Step1VerifyIdentity: React.FC<Step1VerifyIdentityProps> = ({
    defaultValues,
    isLoading,
    onSubmit,
    company,
    department,
    driverValidationType,
    jointEdecGreyFleet
}) => {
    const [validationFailed, setValidationFailed] = useState(false);
    const schemaFields = GreyFleetPersonalDetailsFormValidationSchemaFields(
        driverValidationType
    );

    const validationSchema = yup.object().shape({
        firstName: schemaFields.firstName,
        lastName: schemaFields.lastName,
        ...(jointEdecGreyFleet && driverValidationType === DriverValidationTypes.DateOfBirth ? { dateOfBirth: schemaFields.dateOfBirth } : {}),
        ...(jointEdecGreyFleet && driverValidationType === DriverValidationTypes.EmployeeId ? { employeeId: schemaFields.employeeId } : {}),
        emailAddress: schemaFields.emailAddress,
    });
    const { handleSubmit, formState, register, control } =
        useForm<GreyFleetFormPersonalDetailsValues>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues,
        });

    const handleVerificationSubmit = (
        data: GreyFleetFormPersonalDetailsValues
    ) => {
        onSubmit(data, setValidationFailed, driverValidationType);
    };

    return (
        <form
            onSubmit={handleSubmit(handleVerificationSubmit)}
            className="flex flex-1 flex-col"
        >
            <GreyFleetFormLayout
                title="Grey Fleet invitation"
                subtitle="Verify your Identity"
                description="To verify your identity, please enter the requested information below about yourself."
                company={company}
                department={department}
                content={
                    <>
                        {validationFailed && (
                            <FormMessageBox
                                title="Could not verify identity."
                                message="Some of the information entered does not
                            match our records. Please correct any
                            mistakes in the information entered. "
                                variant="error"
                            />
                        )}
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <FormTextInput
                                    label="First Name"
                                    required
                                    id="firstName"
                                    placeholder="Enter first name"
                                    error={formState.errors.firstName}
                                    {...register("firstName")}
                                />
                                <FormTextInput
                                    label="Surname"
                                    required
                                    id="lastName"
                                    placeholder="Enter surname"
                                    error={formState.errors.lastName}
                                    {...register("lastName")}
                                />
                                {driverValidationType ===
                                    DriverValidationTypes.DateOfBirth && jointEdecGreyFleet && (
                                        <Controller
                                            name="dateOfBirth"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <FormDateInput
                                                    required={
                                                        driverValidationType ===
                                                        DriverValidationTypes.DateOfBirth
                                                    }
                                                    id="dateOfBirth"
                                                    label="Date of Birth"
                                                    value={value}
                                                    onChange={onChange}
                                                    error={
                                                        formState.errors.dateOfBirth
                                                    }
                                                    maxDate={new Date()}
                                                />
                                            )}
                                        />
                                    )}
                                {driverValidationType ===
                                    DriverValidationTypes.EmployeeId && jointEdecGreyFleet && (
                                        <FormTextInput
                                            label="Employee Id"
                                            required={
                                                driverValidationType ===
                                                DriverValidationTypes.EmployeeId
                                            }
                                            id="employeeId"
                                            placeholder="Enter employee id"
                                            error={formState.errors.employeeId}
                                            {...register("employeeId")}
                                        />
                                    )}
                                <FormTextInput
                                    label="Email Address"
                                    required
                                    id="emailAddress"
                                    placeholder="Enter email address"
                                    error={formState.errors.emailAddress}
                                    {...register("emailAddress")}
                                />
                            </div>
                        </div>
                    </>
                }
                buttons={
                    <div className="flex justify-end gap-8">
                        <Button type="submit" loading={isLoading}>
                            Next
                        </Button>
                    </div>
                }
            />
        </form>
    );
};

export default Step1VerifyIdentity;
