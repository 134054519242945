import { capitaliseWord } from "./capitaliseWord";
import { queryParamConnector } from "./queryParamHelpers";

export const breakDownApiQueryParams = <QueryObject,>(
    pathArray: string[],
    queryParams: QueryObject,
    label: string
) => {
    if (queryParams) {
        Object.entries(queryParams).forEach(([key, value]) => {
            if (!value) return;
            if (value instanceof Date) {
                pathArray.push(queryParamConnector(pathArray));
                pathArray.push(
                    `${capitaliseWord(label)}.${capitaliseWord(
                        key
                    )}=${value.toISOString()}`
                );
            } else if (value instanceof Array) {
                value.forEach((val) => {
                    pathArray.push(queryParamConnector(pathArray));
                    pathArray.push(
                        `${capitaliseWord(label)}.${capitaliseWord(key)}=${val}`
                    );
                });
            } else {
                pathArray.push(queryParamConnector(pathArray));
                pathArray.push(
                    `${capitaliseWord(label)}.${capitaliseWord(key)}=${value}`
                );
            }
        });
    }
};
