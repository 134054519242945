import Button from "../Button";
import * as yup from "yup";

import FormCheckbox from "../FormCheckbox";
import EDeclarationFormLayout from "./EDeclarationFormLayout";
import { useForm } from "react-hook-form";
import {
    eDeclarationFormValidationSchemaFields,
    EDeclarationFormValues,
} from "./EDeclarationFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import FormMessageBox from "../FormMessageBox";

export type Step3PermissionsFields = Pick<
    EDeclarationFormValues,
    "employerPermission" | "confirmValidLicence"
>;

type Step3PermissionsProps = {
    defaultValues: Step3PermissionsFields;
    isLoading: boolean;
    onSubmit: (
        value: Step3PermissionsFields,
        callback: (error: true) => void
    ) => void;
    onBackClick: () => void;
};

const validationSchema = yup.object().shape({
    employerPermission:
        eDeclarationFormValidationSchemaFields.employerPermission,
    confirmValidLicence:
        eDeclarationFormValidationSchemaFields.confirmValidLicence,
});

const Step3Permissions: React.FC<Step3PermissionsProps> = ({
    defaultValues,
    isLoading,
    onSubmit,
    onBackClick,
}) => {
    const [updateFailed, setUpdateFailed] = useState(false);
    const { handleSubmit, formState, register } =
        useForm<Step3PermissionsFields>({
            mode: "onChange",
            resolver: yupResolver(validationSchema),
            defaultValues,
        });

    const handleDriverUpdateSubmit = (data: Step3PermissionsFields) => {
        onSubmit(data, setUpdateFailed);
    };

    return (
        <form
            onSubmit={handleSubmit(handleDriverUpdateSubmit)}
            className="flex-1 flex flex-col"
        >
            <EDeclarationFormLayout
                title="E-Declaration"
                subtitle="Permission"
                description="Confirm your permission for Safe2Drive to check your licence on behalf of your employer."
                content={
                    <>
                        {updateFailed && (
                            <FormMessageBox
                                title="Could not update driver details."
                                message="There was an error updating driver details, please go back and try again."
                                variant="error"
                            />
                        )}
                        <FormCheckbox
                            {...register("employerPermission")}
                            id="employerPermission"
                            error={formState.errors.employerPermission}
                            label="I provide permission for Safe2Drive to check my licence on my employer’s behalf."
                            contained
                        />
                        <FormCheckbox
                            {...register("confirmValidLicence")}
                            id="confirmValidLicence"
                            error={formState.errors.confirmValidLicence}
                            label="I confirm that I hold a valid and current driving licence issued by the DVLA."
                            contained
                        />
                        <p>
                            I am the person referred in Section 1 of Electronic
                            Fair Processing Declaration.
                        </p>
                        <p>
                            I understand the Safe2drive will ask DVLA for my
                            driver record information held in the computerised
                            register of drivers maintained by DVLA. This
                            includes personal details, driving entitlements,
                            valid endorsements and disqualifications (if
                            relevant), photo images, Certificate of Professional
                            Competence (CPC) and Digital Tachograph Card details
                            (where appropriate).
                        </p>
                        <p>
                            Medical information will not be provided. This
                            declaration will expire when I cease driving in
                            connection with the company or in any case, three
                            years from the date of my signature.
                        </p>
                    </>
                }
                buttons={
                    <div className="flex justify-end gap-8 mt-8">
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={onBackClick}
                        >
                            Back
                        </Button>
                        <Button type="submit" loading={isLoading}>
                            Next
                        </Button>
                    </div>
                }
            />
        </form>
    );
};

export default Step3Permissions;
