import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { SchemaKeys } from "../../../../../models/shared/schemaKeysHelper";
import { useNavigate } from "react-router-dom";
import { useEditCompanyOutletContext } from "../../AdminEditCompany";
import { ApiCompanyResponse } from "../../../../../models/api/admin/company";
import { useState } from "react";
import { errorToast, successToast } from "../../../../../toast";
import { appPaths } from "../../../../../Routes";
import { EditCompanyStatusDto } from "../../../../../models/app/admin/company";

export type CompanyStatusFormValues = EditCompanyStatusDto;

const DisableCompanySchema: SchemaKeys<CompanyStatusFormValues> = {
    status: yup.string().required("Please select a disable type."),
    comment: yup
        .string()
        .max(10000, "Comments must be less than 10,000 characters.")
        .notRequired(),
};

const useAdminDeleteCompany = () => {
    const validationSchema = yup.object().shape(DisableCompanySchema);
    const navigate = useNavigate();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const editCompanyContext = useEditCompanyOutletContext();
    const { companyData, deleteCompany, editCompanyStatus, refetchCompany } =
        editCompanyContext;
    const { companyId } = companyData || {};

    const methods = useForm<CompanyStatusFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    const handleSubmitCompanyStatus = async (data: CompanyStatusFormValues) => {
        if (!companyId || !editCompanyStatus) {
            errorToast("There was an error");
            return;
        }
        const response = await editCompanyStatus(companyId, data);
        if (response.success) {
            successToast("Client status edited successfully");
            refetchCompany();
        }
        methods.reset();
    };

    const handleDeleteCompany = async () => {
        setShowConfirmationDialog(false);

        if (!companyId || !deleteCompany) {
            errorToast("There was an error");
            return;
        }
        const response = await deleteCompany(companyId);

        if (response.success) {
            successToast("Client deleted successfully");
            navigate(appPaths.adminCompanies);
        }
    };

    const onCancel = () => {
        methods.reset();
        companyId && navigate(appPaths.adminEditCompany(companyId));
    };

    return {
        methods,
        editCompanyContext,
        showConfirmationDialog,
        setShowConfirmationDialog,
        handleSubmitCompanyStatus,
        onCancel,
        handleDeleteCompany,
    };
};

type DisableReasonOption = {
    value: ApiCompanyResponse["status"];
    label: string;
};

export const disableReasonOptions: DisableReasonOption[] = new Array(0);
disableReasonOptions.push({
    value: "DisabledNonPayment",
    label: "Temporary (Non payment)",
});
disableReasonOptions.push({ value: "DisabledPermanent", label: "Permanent" });

export default useAdminDeleteCompany;
