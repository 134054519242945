import { useEffect, useState } from "react";
import { type ApiAuditsResponse } from "../../models/api/admin/audits";
import { capitaliseWord } from "../../utils/capitaliseWord";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export type AuditsParams = {
    entityName?: string;
    entityId?: string;
    userId?: string;
    dateTimeRange?: DateTimeRange;
    includeRelatedEntities?: boolean;
};

export type DateTimeRange = {
    from: Date | null;
    to: Date | null;
};

export const useAudits = (
    auditsParams: AuditsParams,
    pageNumber: number,
    pageSize: number
) => {
    const [dateToString, setDateToString] = useState<string | null>(null);
    const [dateFromString, setDateFromString] = useState<string | null>(null);
    const [refetchIndex, setRefetchIndex] = useState(0);

    function isValidDate(date: Date): boolean {
        return !isNaN(date.getTime());
    }

    const dateTo = auditsParams?.dateTimeRange?.to;
    const dateFrom = auditsParams?.dateTimeRange?.from;

    useEffect(() => {
        if (dateTo && isValidDate(dateTo)) {
            if (!dateFrom || (dateFrom && dateFrom <= dateTo)) {
                setDateToString(dateTo?.toISOString());
            }
        } else {
            setDateToString(null);
        }
        if (dateFrom && isValidDate(dateFrom)) {
            if (!dateTo || (dateTo && dateTo >= dateFrom)) {
                setDateFromString(dateFrom?.toISOString());
            }
        } else {
            setDateFromString(null);
        }
    }, [dateTo, dateFrom]);

    const buildAuditsApiUrl = () => {
        let baseUrl = `api/audits?PageNumber=${pageNumber}&PageSize=${pageSize}`;

        for (const [key, value] of Object.entries(auditsParams)) {
            // split out the nested date entries
            if (key === "dateTimeRange" && auditsParams.dateTimeRange) {
                if (dateToString) {
                    baseUrl += `&DateTimeRange.To=${dateToString}`;
                }
                if (dateFromString) {
                    baseUrl += `&DateTimeRange.From=${dateFromString}`;
                }
            } else if (value) {
                baseUrl += `&${capitaliseWord(key)}=${value}`;
            }
        }

        return baseUrl;
    };

    // Update the API URL to include the refetchIndex
    const apiUrl = `${buildAuditsApiUrl()}&refetchIndex=${refetchIndex}`;

    const { data, error, isLoading } = useS2DApiSWR<ApiAuditsResponse>(apiUrl);

    const refetch = () => {
        setRefetchIndex((prevIndex) => prevIndex + 1);
    };

    return {
        auditsData: data,
        auditsError: error,
        isLoadingAudits: isLoading,
        refetchAudits: refetch,
    };
};
