import * as yup from "yup";

export const EditResellerValidationSchemaFields = {
    resellerName: yup.string().required("Reseller name is required"),
    logoUrl: yup.string().required("logo url is required"),
};

export type EditResellerFormValues = {
    resellerName: string;
    logoUrl: string;
};
