import { useEffect, useState } from "react";
import { CompanyApiSettingsResponse } from "../../../models/api/admin/companyApiSettings";
import {
    useCompanyApiSettings,
    useGetCompanyApiSettings,
} from "../../../api/admin/companyApiSettings";
import { EditCompanyApiSettingsDto } from "../../../models/app/admin/companyApiSettings";
import { errorToast, successToast } from "../../../toast";

export type ApiIntegrationSectionValues = CompanyApiSettingsResponse;
export type ApiIntegrationFormValues = EditCompanyApiSettingsDto;

export const useApiIntegrationsSection = (companyId?: string) => {
    const [displayedValues, setDisplayedValues] = useState<
        ApiIntegrationSectionValues | undefined
    >();

    const { companyApiSettings, refetchCompanyApiSettings } =
        useGetCompanyApiSettings(companyId);

    const initialValues: ApiIntegrationSectionValues = {
        apiStatus: companyApiSettings?.apiStatus ?? null,
        apiKey: companyApiSettings?.apiKey ?? null,
        apiKeyCreated: companyApiSettings?.apiKeyCreated ?? null,
        apiKeyLastUsed: companyApiSettings?.apiKeyLastUsed ?? null,
        apiStatusId: companyApiSettings?.apiStatusId ?? null,
    };

    const {
        editCompanyApiSettings,
        postNewApiKey,
        isPostingNewApiKey,
    } = useCompanyApiSettings();

    const handleSubmit = async (
        data: Pick<EditCompanyApiSettingsDto, "apiStatus">
    ) => {
        if (!companyId) return;

        const dataToSubmit = { ...data, companyId };
        const response = await editCompanyApiSettings(companyId, dataToSubmit);
        if (response.success) {
            successToast("Successfully changed API status.");
            refetchCompanyApiSettings();
        } else {
            errorToast("Failed to update API settings");
        }
    };

    const handleGenerateNewApiKey = async () => {
        if (!companyId) return;
        const response = await postNewApiKey(companyId);
        if (response.success) {
            refetchCompanyApiSettings();
        } else {
            errorToast("Failed to generate new API key");
        }
    };

    useEffect(() => {
        setDisplayedValues(initialValues);
    }, [companyApiSettings]);

    return {
        displayedValues,
        handleSubmit,
        handleGenerateNewApiKey,
        isPostingNewApiKey,
    };
};
