import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pencil, Trash, X } from "phosphor-react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DvlaTachocard } from "../../models/api/admin/dvla";
import { formatDateToString } from "../../utils/formatDateToString";
import {
    EditDvlaTachocardFormValidationSchema,
    EditDvlaTachocardFormValues,
} from "./EditDvlaFormValues";
import Button from "../Button";
import FormDateInput from "../FormDateInput";
import FormTextInput from "../FormTextInput";
import clsx from "clsx";
import { makeLocalAppearUTC } from "../../utils/localToUTC";

export type TachocardUnitProps = {
    tachocard: DvlaTachocard;
    isLoading?: boolean;
    onSubmit?: (
        data: EditDvlaTachocardFormValues,
        callback: () => void,
        tachocardId?: string
    ) => Promise<void>;
    onDelete?: (tachocardId: string) => Promise<void>;
    isDeleting?: boolean;
};

const tachocardValidationSchema = yup.object().shape({
    ...EditDvlaTachocardFormValidationSchema,
});

const TachocardUnit: React.FC<TachocardUnitProps> = ({
    tachocard,
    isLoading,
    onSubmit,
    onDelete,
    isDeleting,
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const { handleSubmit, formState, control, register } =
        useForm<EditDvlaTachocardFormValues>({
            mode: "onSubmit",
            resolver: yupResolver(tachocardValidationSchema),
            defaultValues: {
                ...tachocard,
            },
        });

    return (
        <div key={`${tachocard.tachocardId} unit controller`}>
            <div
                className="flex flex-col items-center justify-between rounded-sm border border-S2D-surface-light-5 bg-S2D-surface-light-1 p-4 sm:flex-row"
                key={`${tachocard.tachocardId} card`}
            >
                <div className="flex flex-col">
                    <p>
                        <b>Card number:</b> {tachocard.number}
                    </p>
                    <p>
                        <b>Card status:</b> {tachocard.cardStatus}
                    </p>
                    <p>
                        <b>Valid from:</b>{" "}
                        {tachocard.validFrom
                            ? formatDateToString(
                                  makeLocalAppearUTC(tachocard.validFrom) ??
                                      tachocard.validFrom
                              )
                            : ""}
                    </p>
                    <p>
                        <b>Valid to:</b>{" "}
                        {tachocard.validTo
                            ? formatDateToString(
                                  makeLocalAppearUTC(tachocard.validTo) ??
                                      tachocard.validTo
                              )
                            : ""}
                    </p>
                </div>
                {onDelete && onSubmit && (
                    <>
                        <div className="flex items-center justify-center gap-4">
                            {!isEdit ? (
                                <Pencil
                                    size={24}
                                    weight="fill"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setIsEdit(true);
                                    }}
                                />
                            ) : (
                                <X
                                    size={24}
                                    weight="fill"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setIsEdit(false);
                                    }}
                                />
                            )}

                            <Trash
                                size={24}
                                weight="fill"
                                className={clsx(
                                    !isDeleting && "cursor-pointer",
                                    isDeleting &&
                                        "cursor-not-allowed text-S2D-neutral-30"
                                )}
                                onClick={() => onDelete(tachocard.tachocardId)}
                            />
                        </div>
                    </>
                )}
            </div>
            <div key={`${tachocard.tachocardId} form`}>
                {isEdit && onSubmit && (
                    <form
                        onSubmit={handleSubmit(
                            (data: EditDvlaTachocardFormValues) =>
                                onSubmit(
                                    data,
                                    () => setIsEdit(false),
                                    tachocard.tachocardId
                                )
                        )}
                    >
                        <p className="pt-6 text-xl font-semibold">
                            Edit tacho card
                        </p>
                        <FormTextInput
                            label="Card number"
                            id="number"
                            placeholder="Enter card number"
                            error={formState.errors.number}
                            {...register("number")}
                            required
                        />
                        <FormTextInput
                            label="Card status"
                            id="card-status"
                            placeholder="Enter card status"
                            error={formState.errors.cardStatus}
                            {...register("cardStatus")}
                            required
                        />
                        <Controller
                            name="validFrom"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormDateInput
                                    id="validFrom"
                                    label="Valid from"
                                    value={value}
                                    onChange={onChange}
                                    error={formState.errors.validFrom}
                                    required
                                />
                            )}
                        />
                        <Controller
                            name="validTo"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormDateInput
                                    id="validTo"
                                    label="Valid to"
                                    value={value}
                                    onChange={onChange}
                                    error={formState.errors.validTo}
                                    required
                                />
                            )}
                        />
                        <div className="my-4 flex justify-between border-b-2 border-S2D-neutral-80 pb-4">
                            <Button
                                type="submit"
                                variant="tertiary"
                                onClick={() => {
                                    setIsEdit(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="secondary"
                                loading={isLoading}
                            >
                                Save tacho card
                            </Button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default TachocardUnit;
