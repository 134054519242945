import { useCompany } from "../../api/admin/company";
import { useDriver } from "../../api/admin/driver";
import { useDriverComment } from "../../api/admin/driverComment";
import { useDriverDocument } from "../../api/admin/driverDocuments";
import { S2DApiFetcherResponse } from "../../api/common/apiResponse";
import { useEdeclaration } from "../../api/edeclaration";
import useAuth from "../../auth";
import { canEditPii, hasClientManagementAccess, isS2dUser } from "../../auth/userAccessHelpers";
import { DocumentBase64, DocumentTypes } from "../../models/app/admin/document";
import { EditDriverDto } from "../../models/app/admin/driver";
import { DeclarationStatus } from "../../models/shared/statuses";
import { errorToast, successToast } from "../../toast";
import { CommentFormValues } from "../CommentForm/CommentFormValues";
import EditDriverForm, {
    EditDriverCommentsForm,
    EditDriverDocumentsForm,
} from "../EditDriverForm";
import { EditDriverFormValues } from "../EditDriverForm/EditDriverFormValues";

export type DriverInformationTabProps = {
    driverId?: string;
    driverData?: ReturnType<typeof useDriver>["driverData"];
    isLoadingDriver: boolean;
    editDriver: (
        id: string,
        dto: EditDriverDto
    ) => Promise<S2DApiFetcherResponse<string>>;
    isEditingDriver: boolean;
    refetchDriver: () => void;
    dvlaRefetch: () => void;
    documentUploadRef: React.MutableRefObject<HTMLButtonElement | null>;
};

const DriverInformationTab: React.FC<DriverInformationTabProps> = ({
    driverId,
    driverData,
    isLoadingDriver,
    editDriver,
    isEditingDriver,
    refetchDriver,
    dvlaRefetch,
    documentUploadRef,
}) => {
    const {
        data: driverCommentList,
        isLoading: isLoadingDriverCommentList,
        error: driverCommentsError,
        addDriverComment,
        isAddingDriverComment,
        refetchDriverComments,
        editDriverComment,
        isEditingDriverComment,
        deleteDriverComment,
        isDeletingDriverComment,
    } = useDriverComment(driverId);

    const {
        documentData,
        documentIsLoading,
        refetchDocuments,
        editDocument,
        deleteDocument,
        isDeletingDocument,
    } = useDriverDocument(driverId);

    const { companyData } = useCompany(
        driverData?.company.companyId ?? undefined
    );

    const declarationId = driverData?.mandateDetails?.declarationId ?? null;
    const { updateEDeclarationStatus } = useEdeclaration(declarationId ?? "");

    const auth = useAuth();

    const handleEditDriver = async (
        data: EditDriverFormValues,
        callback: () => void
    ) => {
        if (!driverId) {
            errorToast("There was an error");
            return;
        }

        const editDriverFormDto: EditDriverDto = {
            ...data,
            address: data.address
                ? data.address.address1 === ""
                    ? null
                    : { ...data.address }
                : null,
            // 21/04/23 Manually setting departmentId and companyId as there is currently
            //  a mismatch between EditDriverFormValues and EditDriverDto
            // TODO 21/04/23: Try to define EditDriverFormValues from EditDriverDto
            departmentId: data.department?.departmentId ?? null,
            companyId: data.company?.companyId ?? null,
        };

        const response = await editDriver(driverId, editDriverFormDto);

        if (response.success) {
            successToast("Driver edited successfully");
            refetchDriver();
            dvlaRefetch();
            callback();
        } else if (response.error.statusCode === 409) {
            errorToast(`${data.emailAddress} is already taken`);
        }
    };

    const handleAddDriverComment = async (
        dto: CommentFormValues,
        callback: (success: boolean) => void
    ) => {
        if (!driverId) {
            errorToast("There was an error");
            return;
        }
        const response = await addDriverComment(driverId, dto);

        if (response.success) {
            successToast("Comment added successfully");
            refetchDriverComments();
            callback(true);
        }
    };

    const handleAddDriverDocument = async (
        data: DocumentBase64,
        callback: (value: boolean) => void
    ) => {
        if (!driverId) {
            errorToast("There was an error");
            return;
        }
        const response = await editDocument(driverId, data);
        if (response.success) {
            successToast("Document added successfully");

            const isSignedDriverDeclaration =
                !!data.signedDate &&
                data.documentType === DocumentTypes.DriverDeclaration;
            if (declarationId && isSignedDriverDeclaration) {
                await updateEDeclarationStatus(declarationId, {
                    declarationStatus: DeclarationStatus.Completed,
                });
                setTimeout(refetchDriver, 1000);
            }
            callback(false);
            refetchDocuments();
        }
    };

    return (
        <>
            {!isLoadingDriver && driverData && (
                <>
                    <EditDriverForm
                        initialValues={driverData}
                        companyData={companyData}
                        isLoading={isEditingDriver}
                        onSubmit={handleEditDriver}
                    />
                    <EditDriverCommentsForm
                        driverId={driverId}
                        isLoading={isLoadingDriverCommentList}
                        error={driverCommentsError}
                        driverCommentList={driverCommentList}
                        deleteDriverComment={deleteDriverComment}
                        isDeletingDriverComment={isDeletingDriverComment}
                        refetchDriverComments={refetchDriverComments}
                        editDriverComment={editDriverComment}
                        isEditingDriverComment={isEditingDriverComment}
                        handleAddDriverComment={handleAddDriverComment}
                        isAddingDriverComment={isAddingDriverComment}
                    />
                    {(isS2dUser(auth.decodedToken?.role) || (hasClientManagementAccess(auth.decodedToken) && canEditPii(auth.decodedToken))) &&
                        !documentIsLoading &&
                        documentData && (
                            <EditDriverDocumentsForm
                                onSubmit={handleAddDriverDocument}
                                onDelete={deleteDocument}
                                isDeleting={isDeletingDocument}
                                initialValues={documentData}
                                documentUploadRef={documentUploadRef}
                            />
                        )}
                </>
            )}
        </>
    );
};

export default DriverInformationTab;
