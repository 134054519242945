import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import {
    ApiDriverDetailsResponse,
    DriverListOrderByOptions,
} from "../../models/api/admin/driver";
import { driverPointsColor } from "../../utils/driverPointsColour";
import { formatDateToString } from "../../utils/formatDateToString";
import { getAge } from "../../utils/getAge";
import { TableContent, TableHeader } from "../ReusableComponents/Table";
import UserIcon from "../UserIcon";
import {
    driverVehicleTableContent,
    driverVehicleTableHeaders,
} from "../ReusableComponents/SharedTableContent/driverVehicleTableContent";
import {
    DriverAddressInformation,
    DriverVehicleInformation,
} from "../../models/api/admin/driverDetails";
import {
    driverAddressTableContent,
    driverAddressTableHeaders,
} from "../ReusableComponents/SharedTableContent/driverAddressTableContent";
import { CompanyRiskProfile } from "../../models/app/admin/company";

export type DriverListContent = {
    content: string | JSX.Element;
    id: string;
};

enum MenuGroups {
    PersonalInformation = "Personal information",
    Declaration = "Declaration",
    Employment = "Employment",
    DrivingLicence = "Driving licence",
    VehicleInformation = "Vehicle information",
    Address = "Address",
    LinkedDriver = "Linked driver",
    Comments = "Comments",
}

export const driverListMenuGroups: MenuGroups[] = (
    Object.keys(MenuGroups) as Array<keyof typeof MenuGroups>
).map((key) => MenuGroups[key]);

export const initTableHeaders: TableHeader[] = [
    {
        title: "Driver Id",
        id: "driverId",
        menuGroup: MenuGroups.PersonalInformation,
        hide: true,
    },
    {
        title: "Name",
        id: "name",
        orderBy: DriverListOrderByOptions.LastName,
        menuGroup: MenuGroups.PersonalInformation,
    },
    {
        title: "Email address",
        id: "email",
        hide: true,
        orderBy: "EmailAddress",
        menuGroup: MenuGroups.PersonalInformation,
    },
    {
        title: "Date of birth",
        id: "DOB",
        hide: true,
        orderBy: "DateOfBirth",
        menuGroup: MenuGroups.PersonalInformation,
    },
    {
        title: "Deleted at",
        id: "deletedAt",
        hide: true,
        orderBy: "DeletedAt",
        menuGroup: MenuGroups.PersonalInformation,
    },
    {
        title: "Declaration status",
        id: "declarationStatus",
        hide: true,
        menuGroup: MenuGroups.Declaration,
    },
    {
        title: "Declaration expires",
        id: "declarationExpiry",
        hide: true,
        menuGroup: MenuGroups.Declaration,
    },
    {
        title: capitalisedTermForCompany,
        id: "company",
        // 30/08 currently getting errors when ordering by company/dep
        // orderBy: DriverListOrderByOptions.Company,
        menuGroup: MenuGroups.Employment,
    },
    {
        title: "Department",
        id: "department",
        // orderBy: DriverListOrderByOptions.Department,
        menuGroup: MenuGroups.Employment,
    },
    {
        title: "Job role",
        id: "jobRole",
        hide: true,
        menuGroup: MenuGroups.Employment,
    },
    {
        title: "Employee number",
        id: "employeeNumber",
        hide: true,
        orderBy: "EmployeeId",
        menuGroup: MenuGroups.Employment,
    },
    {
        title: "External reference",
        id: "externalReference",
        hide: true,
        orderBy: "ExternalReference",
        menuGroup: MenuGroups.Employment,
    },
    {
        title: "Points",
        id: "points",
        menuGroup: MenuGroups.DrivingLicence,
    },
    {
        title: "Age range",
        id: "ageRange",
        menuGroup: MenuGroups.DrivingLicence,
        hide: true,
    },
    {
        title: "Endorsement codes",
        id: "endorsementCodes",
        menuGroup: MenuGroups.DrivingLicence,
        hide: true,
    },
    {
        title: "Licence type",
        id: "licenceType",
        menuGroup: MenuGroups.DrivingLicence,
        hide: true,
    },
    {
        title: "Driver restrictions",
        id: "driverRestrictions",
        menuGroup: MenuGroups.DrivingLicence,
        hide: true,
    },
    {
        title: "Licence category",
        id: "licenceCategory",
        menuGroup: MenuGroups.DrivingLicence,
        hide: true,
    },
    {
        title: "Licence origin",
        id: "licenceOrigin",
        menuGroup: MenuGroups.DrivingLicence,
        hide: true,
    },
    {
        title: "Licence expires",
        id: "licenceExpires",
        menuGroup: MenuGroups.DrivingLicence,
        hide: true,
    },
    {
        title: "Years held",
        id: "yearsHeld",
        menuGroup: MenuGroups.DrivingLicence,
        hide: true,
    },
    {
        title: "Licence number",
        id: "licenceNumber",
        hide: true,
        orderBy: "LicenseNumber",
        menuGroup: MenuGroups.DrivingLicence,
    },
    {
        title: "Last Checked",
        id: "licenceLastCheckedAtDate",
        hide: true,
        orderBy: "licenceLastCheckedAtDate",
        menuGroup: MenuGroups.DrivingLicence,
    },
    {
        title: "Next Check",
        id: "licenceNextCheckDate",
        hide: true,
        orderBy: "licenceNextCheckDate",
        menuGroup: MenuGroups.DrivingLicence,
    },
    {
        title: "Linked driver status",
        id: "linkedDriverStatus",
        hide: true,
        menuGroup: MenuGroups.LinkedDriver,
    },
    {
        title: "Main driver name",
        id: "mainDriverName",
        hide: true,
        menuGroup: MenuGroups.LinkedDriver,
    },
    {
        title: "Comments",
        id: "comments",
        hide: true,
        menuGroup: MenuGroups.Comments,
    },
    ...driverAddressTableHeaders,
    ...driverVehicleTableHeaders,
];

export function initTableContents(
    drivers?: ApiDriverDetailsResponse[] | null,
    companyRiskProfiles?: CompanyRiskProfile[]
): TableContent<ApiDriverDetailsResponse>[] {
    const tableContents = new Array<TableContent<ApiDriverDetailsResponse>>(0);
    drivers?.map((driver) => {
        const pointsColor = driverPointsColor(
            driver.totalPoints,
            companyRiskProfiles
        );

        const address: DriverAddressInformation = {
            postCode: driver.postCode,
            addressMatch: driver.addressMatch,
        };
        const vehicles: DriverVehicleInformation = {
            vehicleId: driver.vehicleId,
            vehicleRegistration: driver.vehicleRegistration,
            manufacturer: driver.manufacturer,
            euroRating: driver.euroRating,
            model: driver.model,
            yearOfManufacture: driver.yearOfManufacture,
            motExpiry: driver.motExpiry,
            engineCapacity: driver.engineCapacity,
            roadTaxExpiry: driver.roadTaxExpiry,
            co2Emissions: driver.co2Emissions,
            fuelType: driver.fuelType,
            insuranceType: driver.insuranceType,
            vehicleInsuranceUploaded: driver.vehicleInsuranceUploaded,
            vehicleInsuranceVerified: driver.vehicleInsuranceVerified,
            businessCover: driver.businessCover,
        };

        tableContents.push({
            rowId: driver.driverId,
            rowData: driver,
            cellData: [
                {
                    id: "driverId",
                    content: <p>{driver.driverId}</p>,
                },
                {
                    id: "name",
                    content: (
                        <div className="flex items-center">
                            <UserIcon
                                firstName={driver.firstName}
                                lastName={driver.lastName}
                                size="small"
                            />
                            <p>{`${driver.firstName} ${driver.lastName}`}</p>
                        </div>
                    ),
                },
                {
                    id: "email",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.emailAddress}
                        </p>
                    ),
                },
                {
                    id: "DOB",
                    content: (
                        <p>
                            {driver.dateOfBirth
                                ? formatDateToString(
                                      new Date(driver.dateOfBirth)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "deletedAt",
                    content: (
                        <p>
                            {driver.deletedAt
                                ? formatDateToString(
                                      new Date(driver.deletedAt)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "declarationStatus",
                    content: <p>{driver.declarationStatus}</p>,
                },
                {
                    id: "declarationExpiry",
                    content: (
                        <p>
                            {driver.declarationExpiry
                                ? formatDateToString(
                                      new Date(driver.declarationExpiry)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "company",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.companyName}
                        </p>
                    ),
                },
                {
                    id: "department",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.departmentName}
                        </p>
                    ),
                },
                {
                    id: "jobRole",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.jobRole}
                        </p>
                    ),
                },
                {
                    id: "employeeNumber",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.employeeNumber}
                        </p>
                    ),
                },
                {
                    id: "externalReference",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.externalReference}
                        </p>
                    ),
                },
                {
                    id: "points",
                    content: (
                        <div className="pl-2">
                            <div
                                className={`h-7 w-7 rounded-full ${pointsColor} flex items-center justify-center`}
                            >
                                {driver.totalPoints ?? "-"}
                            </div>
                        </div>
                    ),
                },
                {
                    id: "ageRange",
                    content: (
                        <p>
                            {driver.dateOfBirth
                                ? getAge(driver.dateOfBirth)
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "endorsementCodes",
                    content: <p>{driver.endorsementCodes?.join(", ")}</p>,
                },
                {
                    id: "licenceType",
                    content: <p>{driver.licenceType}</p>,
                },
                {
                    id: "driverRestrictions",
                    content: <p>{driver.driverRestrictions?.join(", ")}</p>,
                },
                {
                    id: "licenceCategory",
                    content: <p>{driver.licenceCategories?.join(", ")}</p>,
                },
                {
                    id: "licenceOrigin",
                    content: <p>{driver.licenceOrigin}</p>,
                },
                {
                    id: "licenceExpires",
                    content: (
                        <p>
                            {driver.licenceExpires
                                ? formatDateToString(
                                      new Date(driver.licenceExpires)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "yearsHeld",
                    content: <p>{driver.yearsHeldPhotocard}</p>,
                },
                {
                    id: "licenceNumber",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.licenceNumber}
                        </p>
                    ),
                },
                {
                    id: "licenceLastCheckedAtDate",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.licenceLastCheckedAtDate
                                ? formatDateToString(
                                      new Date(driver.licenceLastCheckedAtDate)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "licenceNextCheckDate",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.licenceNextCheckDate
                                ? formatDateToString(
                                      new Date(driver.licenceNextCheckDate)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "linkedDriverStatus",
                    content: <p>{driver.linkedDriverStatus}</p>,
                },
                {
                    id: "mainDriverName",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.mainDriverName}
                        </p>
                    ),
                },
                {
                    id: "comments",
                    content: (
                        <div className="pl-6">
                            <div className="flex items-center justify-center rounded-full h-7 w-7 bg-S2D-light-green-80">
                                {driver.comments}
                            </div>
                        </div>
                    ),
                },
                ...driverAddressTableContent(address),
                ...driverVehicleTableContent(vehicles),
            ],
        });
    });
    return tableContents;
}
