import { ApiInvoiceAddressItem } from "../../../../models/api/admin/invoiceAddress";
import StatusPill from "../../../ReusableComponents/StatusPill/StatusPill";
import {
    type TableContent,
    type TableHeader,
} from "../../../ReusableComponents/Table/tableTypes";

type AllowedIds =
    | "addressName"
    | "addressLine1"
    | "addressLine2"
    | "addressLine3"
    | "addressLine4"
    | "addressLine5"
    | "addressTown"
    | "addressPostcode"
    | "isPrimaryInvoiceAddress";

export type InvoiceAddressesTableHeader = TableHeader<AllowedIds>;
export type InvoiceAddressesTableContent = TableContent<
    ApiInvoiceAddressItem,
    AllowedIds
>;

export const initialInvoiceAddressesTableHeaders: InvoiceAddressesTableHeader[] =
    [
        {
            title: "Address name",
            id: "addressName",
        },
        {
            title: "Address line 1",
            id: "addressLine1",
        },
        {
            title: "Address line 2",
            id: "addressLine2",
        },
        {
            title: "Address line 3",
            id: "addressLine3",
        },
        {
            title: "Address line 4",
            id: "addressLine4",
        },
        {
            title: "Address line 5",
            id: "addressLine5",
        },
        {
            title: "Address town",
            id: "addressTown",
        },
        {
            title: "Postcode",
            id: "addressPostcode",
        },
        {
            title: "Primary address",
            id: "isPrimaryInvoiceAddress",
        },
    ];

export function initInvoiceAddressesTableContents(
    invoiceAddresses?: ApiInvoiceAddressItem[]
): InvoiceAddressesTableContent[] {
    const tableContents: InvoiceAddressesTableContent[] = [];
    invoiceAddresses?.map((invoiceAddress) => {
        tableContents.push({
            rowId: invoiceAddress.invoiceAddressId || "",
            rowData: invoiceAddress,
            cellData: [
                {
                    id: "addressName",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {invoiceAddress.addressName}
                        </p>
                    ),
                },
                {
                    id: "addressLine1",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {invoiceAddress.addressLine1}
                        </p>
                    ),
                },
                {
                    id: "addressLine2",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {invoiceAddress.addressLine2}
                        </p>
                    ),
                },
                {
                    id: "addressLine3",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {invoiceAddress.addressLine3}
                        </p>
                    ),
                },
                {
                    id: "addressLine4",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {invoiceAddress.addressLine4}
                        </p>
                    ),
                },
                {
                    id: "addressLine5",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {invoiceAddress.addressLine5}
                        </p>
                    ),
                },
                {
                    id: "addressTown",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {invoiceAddress.addressTown}
                        </p>
                    ),
                },
                {
                    id: "addressPostcode",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {invoiceAddress.addressPostcode}
                        </p>
                    ),
                },
                {
                    id: "isPrimaryInvoiceAddress",
                    content: (
                        <div className="pl-2">
                            {invoiceAddress.isPrimaryInvoiceAddress && (
                                <StatusPill
                                    bgColour="bg-black"
                                    textColour="text-white"
                                    status="Primary"
                                />
                            )}
                        </div>
                    ),
                },
            ],
        });
    });
    return tableContents;
}
