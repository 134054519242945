import clsx from "clsx";
import {
    type DvlaEntitlement,
    type EntitlementRestrictionCodes,
} from "../../models/api/admin/dvla";
import FormDropdown from "../FormDropdown";
import { entitlementsFormLicenceStatusOptions } from "../../constants/dvlaConstants";
import FormDateInput from "../FormDateInput";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import FormDropdownMultiSelect from "../FormDropdownMultiSelect";
import ApiError from "../../api/common/apiError";
import { categoriesDI4 } from "./Categories/categories";

type EditCategoriesTableProps = {
    tableHeaders: string[];
    entitlementValues: DvlaEntitlement[];
    errorCategories?: string[];
    handleArrayUpdate: (
        category: string,
        field: "type" | "validFrom" | "validTo",
        value: string | Date | null
    ) => void;
    entitlementRestrictionCodes?: EntitlementRestrictionCodes;
    entitlementRestrictionCodesError?: ApiError;
    isLoadingEntitlementRestrictionCodes: boolean;
    handleAddRestrictions: (category: string, value: string[]) => void;
};

const EditCategoriesTable: React.FC<EditCategoriesTableProps> = ({
    tableHeaders,
    entitlementValues,
    errorCategories,
    handleArrayUpdate,
    entitlementRestrictionCodes,
    entitlementRestrictionCodesError,
    isLoadingEntitlementRestrictionCodes,
    handleAddRestrictions,
}) => {
    const findCategoryIcon = (c: string) => {
        const category = categoriesDI4.find((obj) => obj.code === c);
        return category?.icon;
    };

    const debounceChanges = AwesomeDebouncePromise(handleArrayUpdate, 300);

    const handleValueChange = async (
        category: string,
        field: "type" | "validFrom" | "validTo",
        value: string | Date | null
    ) => {
        //debounce the table values to minimize DOM updates
        await debounceChanges(category, field, value);
    };

    const today = new Date();
    const thirtyYearsFromToday = new Date(
        today.setFullYear(today.getFullYear() + 30)
    );

    const restrictionCodeOptions =
        entitlementRestrictionCodes?.map((restriction) => ({
            label: restriction.code,
            value: restriction.code,
        })) || [];

    const dropdownWithSelectedItemsStyle =
        "border-2 border-S2D-light-green-80 bg-S2D-dark-green-70.1";

    return (
        <table className="divide-2 w-full divide-y-2 divide-S2D-neutral-80 border-2  border-S2D-neutral-80 bg-[#474741] ">
            <thead>
                <tr>
                    {tableHeaders.map((header) => (
                        <th
                            scope="col"
                            key={`${header} Header editable`}
                            className={clsx(
                                "m-auto w-fit bg-S2D-dark-green-30 p-4 text-left text-sm text-[#FFFFFF]"
                            )}
                        >
                            {header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className=" m-auto divide-y-[2px] divide-S2D-neutral-80 bg-white">
                {entitlementValues.map((row) => (
                    <tr
                        key={`${row.code} editable`}
                        className={clsx(
                            "h-fit",
                            errorCategories?.includes(row.code) &&
                                "bg-S2D-error-90"
                        )}
                    >
                        <td className="">
                            <div
                                key={`category ${row.code} editable`}
                                className="lg:min-w-32 flex w-48 justify-start gap-4 py-2 px-4"
                            >
                                <div className="rounded-sm bg-S2D-neutral-10 px-2 py-1 text-sm text-[#FFFFFF]">
                                    {row.code}
                                </div>
                                {findCategoryIcon(row.code) && (
                                    <img
                                        src={
                                            findCategoryIcon(row.code) ??
                                            undefined
                                        }
                                        alt={""}
                                    />
                                )}
                            </div>
                        </td>
                        <td>
                            <div
                                key={`status of ${row.code} editable`}
                                className="lg:min-w-56 w-56 py-2 px-4"
                            >
                                <FormDropdown
                                    options={
                                        entitlementsFormLicenceStatusOptions
                                    }
                                    pleaseSelectText="Select"
                                    value={row.type}
                                    onChange={(value) =>
                                        handleArrayUpdate(
                                            row.code,
                                            "type",
                                            value
                                        )
                                    }
                                    buttonClassName={
                                        row.type !== "None"
                                            ? dropdownWithSelectedItemsStyle
                                            : ""
                                    }
                                />
                            </div>
                        </td>
                        <td>
                            <div
                                key={`${row.code} valid from editable`}
                                className="lg:min-w-56 my-2 w-56 px-4"
                            >
                                <FormDateInput
                                    id={`${row.code}.validFrom`}
                                    hideHelpText
                                    label=""
                                    value={row.validFrom}
                                    onChange={(value) =>
                                        handleValueChange(
                                            row.code,
                                            "validFrom",
                                            value
                                        )
                                    }
                                    maxDate={today}
                                    adornmentClassName="-mt-[0.22rem]"
                                />
                            </div>
                        </td>
                        <td>
                            <div
                                key={`${row.code} valid to editable`}
                                className="lg:min-w-56 my-2 w-56 px-4"
                            >
                                <FormDateInput
                                    id={`${row.code}.validTo`}
                                    hideHelpText
                                    label=""
                                    value={row.validTo}
                                    onChange={(value) =>
                                        handleValueChange(
                                            row.code,
                                            "validTo",
                                            value
                                        )
                                    }
                                    maxDate={thirtyYearsFromToday}
                                    adornmentClassName="-mt-[0.22rem]"
                                />
                            </div>
                        </td>
                        <td>
                            <div
                                key={`${row.code} restrictions editable`}
                                className="px-4 pt-2"
                            >
                                {isLoadingEntitlementRestrictionCodes && (
                                    <p>...loading</p>
                                )}
                                {!entitlementRestrictionCodesError && (
                                    <FormDropdownMultiSelect
                                        options={restrictionCodeOptions}
                                        pleaseSelectText="Select"
                                        value={row.restrictionCodes}
                                        onChange={(value: string[]) =>
                                            handleAddRestrictions(
                                                row.code,
                                                value
                                            )
                                        }
                                        buttonClassName={
                                            row.restrictionCodes.length
                                                ? dropdownWithSelectedItemsStyle
                                                : undefined
                                        }
                                    />
                                )}
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default EditCategoriesTable;
