import { useEffect, useState } from "react";
import ApiError from "../../../api/common/apiError";
import { ApiDriversListResponse } from "../../../models/api/admin/driver";
import useDriversListPaginationLocalStorage from "../../../hooks/useDriversListPaginationLocalStorage";
import { useDriver } from "../../../api/admin/driver";
import { GetAllDriversDto } from "../../../models/app/admin/driver";
import { type DriversListFiltersFormValues } from "../../../components/DriverListFilters/DriversListFiltersForm";
import { driverPageLocation } from "../../../utils/driverPageLocation";
import { useAppliedFilterLocalStorage } from "../../../hooks/useAppliedFilterLocalStorage";
import { useCompanyDepartmentSelector } from "../../../components/ReusableComponents/CompanyDepartmentSelector";
import { CompanyDashboardFilters, FilterPersonalInformationDto } from "../../../models/app/admin/driverFilters";
import { driverListFiltersDefaultValues } from "../../../components/DriverListFilters/DriversListFiltersForm/driversListFiltersDefaultValues";
import { useDriversStats } from "../../../api/admin/driverStats";
import useDebounceValue from "../../../hooks/useDebounceValue";
import { debounce } from "@mui/material";
import { SEARCH_BAR_DEBOUNCE_MS } from "../../../constants/filterConstants";

const useDriversPage = (locationPath: string, skipFetch = false) => {
    const location = driverPageLocation(locationPath);
    const defaultFilterVals = driverListFiltersDefaultValues(location);

    const {
        currentDepartment: selectedDepartment,
        handleDepartmentChange,
        selectedCompany,
        handleCompanyChange,
    } = useCompanyDepartmentSelector();

    // state for filter form & modal
    const [filterModalOpen, setFilterModalOpen] = useState<
        "add" | "edit" | false
    >(false);

    // the list of drivers from the api and its error
    const [driversList, setDriversList] = useState<
        ApiDriversListResponse | undefined
    >();
    const [fetchDriversError, setFetchDriversError] = useState<
        ApiError | undefined
    >();

    const paginationLocalStorage =
        useDriversListPaginationLocalStorage(location);

    const { getAllDrivers, isGettingAllDrivers } = useDriver();

    const { driverStatsData, isLoadingDriverStats } = useDriversStats(
        location === "professionalDrivers",
        selectedCompany?.companyId,
        selectedDepartment?.departmentId
    );

    const createEmploymentFilters = (
        employeeId?: string | null,
        companyDashboard?: CompanyDashboardFilters,
        companyId?: string,
        departmentId?: string
    ) => {
        const companyFilters: Pick<DriversListFiltersFormValues, "company"> = {
            company: {
                companyIds: companyId ? [companyId] : null,
                departmentIds: departmentId ? [departmentId] : null,
                employeeId: employeeId ?? null,
                companyDashboard:
                    companyDashboard ??
                    defaultFilterVals.company.companyDashboard,
            },
        };
        return companyFilters;
    };

    const { appliedFilter, setAppliedFilter } =
        useAppliedFilterLocalStorage(location);
    /* search query will be part of the whole filter,
        but will need to refresh when the component refreshes,
        otherwise stay applied. */
    const [query, setQuery] = useState<string | null>(null);
    const searchQuery = useDebounceValue(query, SEARCH_BAR_DEBOUNCE_MS);

    // 03/04/24 debouncing to prevent duplicate calls made by useEffects
    const debouncedHandleFetchDrivers = debounce(
        (filters?: DriversListFiltersFormValues, filterId?: string) => {
            handleFetchDrivers(filters, filterId);
        },
        0
    );

    const createGetAllDriversBody = (
        filters: DriversListFiltersFormValues | undefined,
        personalInformationFromFilterOrQuery: FilterPersonalInformationDto | undefined,
        employeeIdFromFilter: string | null | undefined,
        companyDashboardFromFilter: CompanyDashboardFilters | undefined,
        companyId: string | undefined,
        departmentId: string | undefined,
        reportFormatType?: string
    ): GetAllDriversDto => {
        return {
            pageNumber: paginationLocalStorage.pageNumber,
            pageSize: paginationLocalStorage.pageSize,
            orderBy:
                paginationLocalStorage.orderBy +
                " " +
                paginationLocalStorage.sortOrder,
            ...(filters && { ...filters }),
            // apply the query when we search, rather than saving it with the rest of the filter
            personalInformation: personalInformationFromFilterOrQuery,
            // 18/07/23 NB: Client/Dept are filters via the Dropdowns at top of page, hence:
            ...createEmploymentFilters(
                employeeIdFromFilter,
                companyDashboardFromFilter,
                companyId,
                departmentId
            ),
            ...(reportFormatType && { reportFormatType }),
        };
    };

    const constructAllDriversBody = (
        filters?: DriversListFiltersFormValues
    ): GetAllDriversDto => {
        // If landing on page with a filter applied, use that filter until dept/company is changed
        const companyIdFromFilter = filters?.company?.companyIds?.[0];
        const companyId = selectedCompany?.companyId ?? companyIdFromFilter;
        const departmentIdFromFilter = filters?.company?.departmentIds?.[0];
        const departmentId = selectedDepartment?.departmentId ?? departmentIdFromFilter;
    
        const employeeIdFromFilter = filters?.company?.employeeId;
        const companyDashboardFromFilter = filters?.company?.companyDashboard;
    
        const personalInformationFromFilterOrQuery = searchQuery
            ? {
                  ...defaultFilterVals.personalInformation,
                  ...filters?.personalInformation,
                  firstName: searchQuery,
                  lastName: searchQuery,
                  email: searchQuery,
              }
            : {
                  ...defaultFilterVals.personalInformation,
                  ...filters?.personalInformation,
              };
    
        return createGetAllDriversBody(
            filters,
            personalInformationFromFilterOrQuery,
            employeeIdFromFilter,
            companyDashboardFromFilter,
            companyId,
            departmentId
        );
    };

    const constructAllDriversBodyForReport = (
        filters?: DriversListFiltersFormValues,
        reportFormatType?: string,
        companyId?: string,
        departmentId?: string
    ): GetAllDriversDto => {
        const employeeIdFromFilter = filters?.company?.employeeId;
        const companyDashboardFromFilter = filters?.company?.companyDashboard;
    
        const personalInformationFromFilterOrQuery = searchQuery
            ? {
                  ...defaultFilterVals.personalInformation,
                  ...filters?.personalInformation,
                  firstName: searchQuery,
                  lastName: searchQuery,
                  email: searchQuery,
              }
            : {
                  ...defaultFilterVals.personalInformation,
                  ...filters?.personalInformation,
              };
    
        return createGetAllDriversBody(
            filters,
            personalInformationFromFilterOrQuery,
            employeeIdFromFilter,
            companyDashboardFromFilter,
            companyId,
            departmentId,
            reportFormatType
        );
    };

    const handleFetchDrivers = async (filters?: DriversListFiltersFormValues, filterId?: string) => {
        const getAllDriversBody = constructAllDriversBody(filters);
        const response = await getAllDrivers(getAllDriversBody);

        if (response.success) {
            setFetchDriversError(undefined);
            setDriversList(response.content);
            if (filters) {
                setFilterModalOpen(false);
                setAppliedFilter({
                    filterId: filterId ?? "",
                    filterFields: filters,
                });
            }
        } else if (!response.success) {
            setFetchDriversError(response.error);
        }
        return response.success;
    };

    // if page number or page size etc changes, fetch drivers.
    useEffect(() => {
        if (!skipFetch) {
            debouncedHandleFetchDrivers(
                appliedFilter.filterFields,
                appliedFilter.filterId ?? ""
            );
        }
    }, [
        JSON.stringify(paginationLocalStorage),
        JSON.stringify(selectedCompany),
        JSON.stringify(selectedDepartment),
        searchQuery,
    ]);

    // if the selected company/dept changes, remove any company/dept filters from the applied filter:
    // 11/08/23 NB: if all filters depended on applied filter as a single source of truth, this
    // ...useEffect would not be necessary
    useEffect(() => {
        const companyIdFromFilter =
            appliedFilter.filterFields?.company?.companyIds?.[0];
        const departmentIdFromFilter =
            appliedFilter.filterFields?.company?.departmentIds?.[0];

        const userHasSelectedCompanyOrDept =
            selectedCompany?.companyId || selectedDepartment?.departmentId;
        const companyOrDeptFilterIsApplied =
            companyIdFromFilter || departmentIdFromFilter;

        if (userHasSelectedCompanyOrDept && companyOrDeptFilterIsApplied) {
            // remove company filter from applied filter
            const currentlyAppliedFilters = appliedFilter.filterFields;
            const currentFilterId = appliedFilter.filterId;

            const appliedFiltersWithoutCompanyFilters = {
                filterId: currentFilterId,
                filterFields: {
                    ...currentlyAppliedFilters,
                    company: defaultFilterVals.company,
                },
            };
            setAppliedFilter(appliedFiltersWithoutCompanyFilters);
            if (!skipFetch) {
                debouncedHandleFetchDrivers(
                    appliedFiltersWithoutCompanyFilters.filterFields,
                    appliedFiltersWithoutCompanyFilters.filterId ?? ""
                );
            }
        } else if (
            !companyOrDeptFilterIsApplied &&
            userHasSelectedCompanyOrDept && !skipFetch
        ) {
            debouncedHandleFetchDrivers(
                appliedFilter.filterFields,
                appliedFilter.filterId ?? ""
            );
        }
    }, [selectedCompany?.companyId, selectedDepartment?.departmentId]);

    return {
        filterModalOpen,
        setFilterModalOpen,
        driversList,
        fetchDriversError,
        appliedFilter,
        setAppliedFilter,
        paginationLocalStorage,
        handleFetchDrivers,
        isFetchingDrivers: isGettingAllDrivers,
        constructAllDriversBodyForReport,
        driverPageLocation: location,
        driverStatsData,
        isLoadingDriverStats,
        handleCompanyChange,
        handleDepartmentChange,
        selectedCompany,
        selectedDepartment,
        query,
        setQuery,
    };
};

export default useDriversPage;
