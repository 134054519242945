import {
    Cell,
    Pie,
    PieChart as RePieChart,
    ResponsiveContainer,
    Tooltip,
} from "recharts";

export type PieChartItem = { name: string; value: number; color: string };

type PieChartProps = {
    data: PieChartItem[];
};

export default function PieChart({ data }: PieChartProps) {
    return (
        <>
            <ResponsiveContainer width="100%" height={260}>
                <RePieChart width={730} height={250}>
                    <Pie
                        data={data}
                        dataKey="value"
                        nameKey="name"
                        outerRadius={85}
                        innerRadius={55}
                    >
                        {data.map((item, index) => (
                            <Cell key={`cell-${index}`} fill={item.color} />
                        ))}
                    </Pie>
                    <Tooltip cursor={false} />
                </RePieChart>
            </ResponsiveContainer>
        </>
    );
}
