import ApiError from "../../../api/common/apiError";
import {
    CPCAndTachoAnalysis,
    EyeSightAnalysis,
    LicenceCategoriesAnalysis,
    LicenceChangedAnalysis,
    LicenceStatusAnalysis,
    NewDriversAnalysis,
    NonAddressMatchAnalysis,
    PhotoCardsAnalysis,
    ProfessionalLicenceStatusAnalysis,
    ProfessionalNewDriversAnalysis,
    RiskCategoriesAnalysis,
} from "../../../components/AnalysisViews/DriverAnalysisViews";
import { type DriversListFiltersFormValues } from "../../../components/DriverListFilters/DriversListFiltersForm";
import { type AnalysisComponentContentType } from "../../../components/ReusableComponents/AnalysisComponent/AnalysisComponent";
import {
    DriverAnalysisTabs,
    type CompanyDriverAnalysisResponse,
} from "../../../models/api/admin/companyDriverAnalysis";

export const driverAnalysisContent = (
    data?: CompanyDriverAnalysisResponse,
    error?: ApiError,
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>,
    tableRef?: React.MutableRefObject<HTMLDivElement | null>,
    isCompanySelected?: boolean
) => {
    const analysisContent: AnalysisComponentContentType<DriverAnalysisTabs>[] =
        [
            {
                title: DriverAnalysisTabs.RiskCategories,
                content: (
                    <>
                        {!error && (
                            <RiskCategoriesAnalysis
                                data={data?.riskCategories}
                                refetchData={refetchData}
                                tableRef={tableRef}
                                isCompanySelected={isCompanySelected}
                            />
                        )}
                        {error && <ErrorMessage />}
                    </>
                ),
                locations: ["drivers", "professionalDrivers"],
            },
            {
                title: DriverAnalysisTabs.CpcAndTacho,
                content: (
                    <>
                        {!error && (
                            <CPCAndTachoAnalysis
                                data={data?.cpcTacho}
                                refetchData={refetchData}
                                tableRef={tableRef}
                            />
                        )}
                        {error && <ErrorMessage />}
                    </>
                ),
                locations: ["professionalDrivers"],
            },
            {
                title: DriverAnalysisTabs.LicenceStatus,
                content: (
                    <>
                        {!error && (
                            <LicenceStatusAnalysis
                                data={data?.licenceStatus}
                                refetchData={refetchData}
                                tableRef={tableRef}
                            />
                        )}
                        {error && <ErrorMessage />}
                    </>
                ),
                locations: ["drivers"],
            },
            {
                title: DriverAnalysisTabs.ProfessionalLicenceStatus,
                content: (
                    <>
                        {!error && (
                            <ProfessionalLicenceStatusAnalysis
                                data={data?.professionalLicenceStatus}
                                refetchData={refetchData}
                                tableRef={tableRef}
                            />
                        )}
                        {error && <ErrorMessage />}
                    </>
                ),
                locations: ["professionalDrivers"],
            },
            {
                title: DriverAnalysisTabs.LicenceChanged,
                content: (
                    <>
                        {!error && (
                            <LicenceChangedAnalysis
                                data={data?.driverLicenceChanges}
                                refetchData={refetchData}
                                tableRef={tableRef}
                            />
                        )}
                        {error && <ErrorMessage />}
                    </>
                ),
                locations: ["drivers", "professionalDrivers"],
            },
            {
                title: DriverAnalysisTabs.LicenceCategories,
                content: (
                    <>
                        {!error && (
                            <LicenceCategoriesAnalysis
                                data={data?.licenceCategories}
                                refetchData={refetchData}
                                tableRef={tableRef}
                            />
                        )}
                        {error && <ErrorMessage />}
                    </>
                ),
                locations: ["drivers", "professionalDrivers"],
            },
            {
                title: DriverAnalysisTabs.PhotoCards,
                content: (
                    <>
                        {!error && (
                            <PhotoCardsAnalysis
                                data={data?.photocards}
                                refetchData={refetchData}
                                tableRef={tableRef}
                            />
                        )}
                        {error && <ErrorMessage />}
                    </>
                ),
                locations: ["drivers", "professionalDrivers"],
            },
            {
                title: DriverAnalysisTabs.NewDrivers,
                content: (
                    <>
                        {!error && (
                            <NewDriversAnalysis
                                data={data?.newDrivers}
                                refetchData={refetchData}
                                tableRef={tableRef}
                            />
                        )}
                        {error && <ErrorMessage />}
                    </>
                ),
                locations: ["drivers"],
            },
            {
                title: DriverAnalysisTabs.ProfessionalNewDrivers,
                content: (
                    <>
                        {!error && (
                            <ProfessionalNewDriversAnalysis
                                data={data?.professionalNewDrivers}
                                refetchData={refetchData}
                                tableRef={tableRef}
                            />
                        )}
                        {error && <ErrorMessage />}
                    </>
                ),
                locations: ["professionalDrivers"],
            },
            {
                title: DriverAnalysisTabs.Eyesight,
                content: (
                    <>
                        {!error && (
                            <EyeSightAnalysis
                                data={data?.eyesight}
                                refetchData={refetchData}
                                tableRef={tableRef}
                            />
                        )}
                        {error && <ErrorMessage />}
                    </>
                ),
                locations: ["drivers", "professionalDrivers"],
            },
            {
                title: DriverAnalysisTabs.NonADDMatches,
                content: (
                    <>
                        {!error && (
                            <NonAddressMatchAnalysis
                                data={data?.nonAddressMatch}
                                refetchData={refetchData}
                                tableRef={tableRef}
                            />
                        )}
                        {error && <ErrorMessage />}
                    </>
                ),
                locations: ["drivers", "professionalDrivers"],
            },
        ];

    return analysisContent;
};

const ErrorMessage = () => {
    return (
        <div className="rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-4 text-S2D-neutral-10">
            Error fetching driver analysis data
        </div>
    );
};
