import { useState } from "react";
import { DocumentBase64, DocumentTypes } from "../../models/app/admin/document";
import { DeclarationType } from "../../models/app/eDeclarations";
import { AddDriverFormValues, documentSchema } from "./AddDriverFormValues";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export const initDocData: DocumentBase64 = {
    documentBase64: "",
    fileType: "",
    fileName: "",
    documentType: DocumentTypes.Other,
    signedDate: null,
};

export const useAddDriverDocumentValidation = () => {
    const [notSubmittedError, setNotSubmittedError] = useState(false);
    const [documentTypeError, setDocumentTypeError] = useState(false);

    const documentMethods = useForm<DocumentBase64>({
        mode: "all",
        resolver: yupResolver(documentSchema),
        defaultValues: initDocData,
    });

    // don't want to upload a declaration document if we're set to eDeclaration
    const checkDocumentType = (
        docs: DocumentBase64[],
        declarationType: DeclarationType
    ) => {
        if (declarationType === DeclarationType.PaperDeclaration) return;

        const hasIncorrectDocumentType = docs.some(
            (doc) => doc.documentType === DocumentTypes.DriverDeclaration
        );

        if (hasIncorrectDocumentType) {
            setDocumentTypeError(true);
        }
        return hasIncorrectDocumentType;
    };

    const validDocumentsOnSubmit = (value: AddDriverFormValues) => {
        const currentDoc = documentMethods.getValues();
        if (currentDoc.fileName) {
            setNotSubmittedError(true);
            return false;
        }
        setNotSubmittedError(false);

        if (checkDocumentType(value.documents, value.declarationType)) {
            return false;
        }
        setDocumentTypeError(false);
        return true;
    };

    return {
        documentMethods,
        notSubmittedError,
        setNotSubmittedError,
        documentTypeError,
        validDocumentsOnSubmit,
    };
};
