import { ClockCounterClockwise, Download, Printer, Gear } from "phosphor-react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    type ApiCompanyResponse,
    CompanyStatusEnum,
    type CompanyStatusValues,
} from "../../models/api/admin/company";
import IconButton from "../IconButton";
import StatusPill from "../ReusableComponents/StatusPill/StatusPill";
import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import { appPaths } from "../../Routes";
import ProfileLabel from "../ReusableComponents/ProfileLabel";

type CompanyProfileHeaderProps = {
    companyData: ApiCompanyResponse | undefined;
};

const CompanyProfileHeader: React.FC<CompanyProfileHeaderProps> = ({
    companyData,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const companyId = companyData?.companyId;
    const disableCompanyPath =
        companyId && appPaths.adminDisableCompany(companyId);
    const auditCompanyPath = companyId && appPaths.adminAuditCompany(companyId);
    const editCompanyPath = companyId && appPaths.adminEditCompany(companyId);
    const isAuditPath = location.pathname.includes("audit");
    const isDisablePath = location.pathname.includes("delete");
    const highLightedClass =
        "bg-S2D-dark-green-70.1 border-2 border-S2D-light-green-80";

    const handleNavigate = (pathToNavigateTo: string) => {
        if (editCompanyPath && location.pathname === pathToNavigateTo) {
            navigate(editCompanyPath);
        } else if (pathToNavigateTo) {
            navigate(pathToNavigateTo);
        }
    };

    const { status, statusId } = companyData || {};
    return (
        <>
            {companyData && auditCompanyPath && disableCompanyPath && (
                <div className="mb-12 flex flex-row flex-wrap justify-between gap-4">
                    <ProfileLabel
                        firstName={companyData.companyName}
                        title={companyData.companyName}
                        hasLargeText
                        subtitle={`[${companyData.companyId}]`}
                    >
                        <StatusPill
                            status={status as string}
                            bgColour={statusColor(statusId)}
                            textColour={
                                status === "Live" ? "text-black" : "text-white"
                            }
                        />
                    </ProfileLabel>
                    <div className="grid flex-none grid-flow-col items-start gap-5">
                        <IconButton
                            label="Download driver"
                            icon={Download}
                            onClick={() => {
                                console.log("download driver");
                            }}
                        />
                        <IconButton
                            label="Print driver"
                            icon={Printer}
                            onClick={() => {
                                console.log("print driver");
                            }}
                        />
                        {companyData.companyId && (
                            <IconButton
                                label="Audit driver"
                                icon={ClockCounterClockwise}
                                onClick={() => handleNavigate(auditCompanyPath)}
                                className={isAuditPath ? highLightedClass : ""}
                            />
                        )}

                        <IconButton
                            label={`Disable or delete ${capitalisedTermForCompany}`}
                            icon={Gear}
                            onClick={() => handleNavigate(disableCompanyPath)}
                            className={isDisablePath ? highLightedClass : ""}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

const statusColor = (statusId: CompanyStatusValues | undefined) => {
    if (statusId === undefined) return "bg-S2D-neutral-70";
    if (statusId === CompanyStatusEnum["Live"]) return "bg-S2D-success-80";
    return "bg-S2D-error-40";
};

export default CompanyProfileHeader;
