import { useGetDepartments } from "../../../api/admin/department";
import { type ApiDepartmentItem } from "../../../models/api/admin/department";
import HeadlessGenericDropdown, {
    DropdownItem,
} from "../HeadlessGenericDropdown";

export type DepartmentDropdownProps = {
    companyId?: string;
    currentDepartment?: ApiDepartmentItem | null;
    handleDepartmentChange: (department: ApiDepartmentItem | null) => void;
    showLabel?: boolean;
};

const DepartmentDropdown: React.FC<DepartmentDropdownProps> = ({
    companyId,
    currentDepartment,
    handleDepartmentChange,
    showLabel,
}) => {
    const { departmentsData, departmentsError, isLoadingDepartments } =
        useGetDepartments(companyId, 1, 100);

    const departmentsList = departmentsData?.items;
    const departmentDropdownItems =
        departmentsList &&
        departmentsList.map((department) => ({
            item: department.departmentName,
            id: department.departmentId,
        }));
    const selectedItem: DropdownItem<string> = {
        item: currentDepartment?.departmentName,
        id: currentDepartment?.departmentId,
    };

    const handleItemSelect = (item: DropdownItem | null) => {
        const departmentFromId: ApiDepartmentItem | undefined =
            departmentsList?.find(
                (department) => department.departmentId === item?.id
            );
        if (departmentFromId) handleDepartmentChange(departmentFromId);
    };

    return (
        <div>
            <HeadlessGenericDropdown
                dropdownPlaceholder="All departments"
                noItemsMessage="No departments found"
                dropdownData={departmentDropdownItems}
                selectedItem={selectedItem}
                handleItemChange={handleItemSelect}
                error={departmentsError}
                isLoading={isLoadingDepartments}
                label={showLabel ? "Department" : undefined}
            />
        </div>
    );
};

export default DepartmentDropdown;
