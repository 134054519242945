import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../FormTextInput";
import Button from "../Button";
import {
    editStaffMemberValidationSchema,
    type EditStaffMemberFormValues,
} from "./EditStaffMemberFormValues";
import { type S2DStaffMember } from "../../models/api/safe2DriveAdmin/staff";
import FormDropdown from "../FormDropdown";
import {
    staffUserRoles,
    yesNoOptions,
} from "../../constants/dropdownOptions";
import useAuth from "../../auth";
import { UserStatus } from "../../models/shared/statuses";
import { EditStaffMemberDto } from "../../models/app/safe2DriveAdmin/staff";
import { canControlClaims } from "../../auth/userAccessHelpers";
import { UserRoles } from "../../auth/decodeToken";
import { useEffect } from "react";
import { adminsCanViewPiiMessage } from "../../constants/dvlaConstants";

type EditStaffMemberFormProps = {
    isLoading: boolean;
    onSubmit: (value: EditStaffMemberDto, callback?: () => void) => void;
    onDelete: () => void;
    onResetPassword: () => void;
    onCancel: () => void;
    initialValues: S2DStaffMember;
    handleToggleStaffStatus: (
        status: UserStatus,
        staffMember: S2DStaffMember
    ) => void;
};

const EditStaffMemberForm: React.FC<EditStaffMemberFormProps> = ({
    isLoading,
    onSubmit,
    onResetPassword,
    onDelete,
    onCancel,
    initialValues,
    handleToggleStaffStatus,
}) => {
    const roles = initialValues.roleNames[0];

    const methods = useForm<EditStaffMemberFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(editStaffMemberValidationSchema),
        defaultValues: { ...initialValues, roles },
    });
    const { handleSubmit, register, formState, control, setValue, watch } =
        methods;

    const handleFormSubmit = (data: EditStaffMemberFormValues) => {
        const { roles } = data;
        onSubmit({ ...data, roles: [roles] });
    };

    const { decodedToken } = useAuth();
    const canSetFileSafeAccess = !!decodedToken?.fileSafe;

    const currentStaffMemberStatus = watch("status");
    const isStaffMemberLive =
        currentStaffMemberStatus.toLowerCase() ===
        UserStatus.Live.toLowerCase();
    const newStaffMemberStatus = isStaffMemberLive
        ? UserStatus.Disabled
        : UserStatus.Live;

    const changeStaffMemberStatus = () => {
        // setting the status value incase the user Submits the form after clicking 'Enable/Disable'
        setValue("status", newStaffMemberStatus);
        handleToggleStaffStatus(newStaffMemberStatus, initialValues);
    };

    const editedUserRole = useWatch({ control, name: "roles" });
    const userIsAdmin = editedUserRole === UserRoles.s2dAdmin;

    useEffect(() => {
        if (userIsAdmin) {
            setValue("claims.hasViewPiiClaim", true);
            setValue("claims.hasFileSafeClaim", true);
        } else {
            setValue("claims.hasFileSafeClaim", false);
        }
    }, [editedUserRole]);

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="mx-auto max-h-[400px] w-full max-w-3xl overflow-y-scroll p-6">
                <FormTextInput
                    label="First Name"
                    required
                    id="firstName"
                    placeholder="Enter first name"
                    error={formState.errors.firstName}
                    {...register("firstName")}
                />
                <FormTextInput
                    label="Surname"
                    required
                    id="lastName"
                    placeholder="Enter surname"
                    error={formState.errors.lastName}
                    {...register("lastName")}
                />
                <FormTextInput
                    label="Email Address"
                    required
                    id="emailAddress"
                    placeholder="Enter email address"
                    error={formState.errors.emailAddress}
                    {...register("emailAddress")}
                />
                <FormTextInput
                    label="Mobile number"
                    id="mobileNumber"
                    placeholder="Enter mobile number"
                    error={formState.errors.mobileNumber}
                    {...register("mobileNumber")}
                />
                {canSetFileSafeAccess && (
                    <Controller
                        name="claims.hasFileSafeClaim"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <FormDropdown
                                label="Has access to FileSafe?"
                                options={yesNoOptions}
                                value={value}
                                onChange={onChange}
                                pleaseSelectText="Please select..."
                                error={
                                    formState.errors.claims?.hasFileSafeClaim
                                }
                                disabled={!userIsAdmin}
                            />
                        )}
                    />
                )}
                <Controller
                    name="roles"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Permission"
                            options={staffUserRoles}
                            value={value ?? ""}
                            onChange={onChange}
                            pleaseSelectText="Select permission"
                            error={formState.errors.roles}
                        />
                    )}
                />
                {canControlClaims(decodedToken) && (
                    <Controller
                        name="claims.hasViewPiiClaim"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <FormDropdown
                                label="Can view personally identifiable information?"
                                options={yesNoOptions}
                                value={value}
                                onChange={onChange}
                                pleaseSelectText="Please select..."
                                error={
                                    formState.errors.claims?.["hasViewPiiClaim"]
                                }
                                disabled={userIsAdmin}
                                helpText={
                                    userIsAdmin ? adminsCanViewPiiMessage : ""
                                }
                            />
                        )}
                    />
                )}
                <FormTextInput
                    label="Position"
                    id="position"
                    placeholder="Enter staff position"
                    error={formState.errors.position}
                    {...register("position")}
                />
            </div>
            <div className="flex flex-col-reverse justify-between border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                <Button
                    variant="tertiary"
                    type="button"
                    onClick={onCancel}
                    className="w-full justify-center md:w-auto"
                >
                    Cancel
                </Button>

                <div className="flex flex-col-reverse gap-4 md:flex-row">
                    <div className="flex grow flex-col-reverse gap-4 md:flex-row">
                        <Button
                            variant="tertiary"
                            type="button"
                            className="w-full justify-center text-S2D-error-40 md:w-auto"
                            onClick={onDelete}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="tertiary"
                            type="button"
                            className="w-full justify-center md:w-auto"
                            onClick={changeStaffMemberStatus}
                        >
                            {isStaffMemberLive ? "Disable" : "Enable"}
                        </Button>
                    </div>
                    <div className="flex grow flex-col-reverse gap-4 md:flex-row">
                        <Button
                            variant="secondary"
                            type="button"
                            className="w-full justify-center md:w-auto"
                            onClick={onResetPassword}
                        >
                            Reset Password
                        </Button>
                        <Button
                            type="submit"
                            loading={isLoading}
                            className="w-full justify-center md:w-auto"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EditStaffMemberForm;
