import { useDvlaBulkActions } from "../../api/safe2Drive/dvlaBulkActions";
import { GREAT_BRITAIN, NORTHERN_IRELAND } from "../../constants/countries";
import { type DvlaCheckItem } from "../../models/api/safe2DriveAdmin/dvlaChecks";
import { CheckStatus, CheckType } from "../../models/shared/statuses";
import { DropdownItem } from "../ReusableComponents/HeadlessGenericDropdown";
import { type TableCheckbox } from "../ReusableComponents/Table/tableTypes";
import { dvlaChecksBulkActionOptions } from "./dvlaChecksBulkActions";

type DvlaBulkActionCheckStatusGroups =
    | "scheduled"
    | "overdue"
    | "dvla-error"
    | "pending"
    | "checked"
    | "disabled";

const determineGroupCheckStatus = (
    data: DvlaCheckItem
): DvlaBulkActionCheckStatusGroups => {
    // put similar statuses together to determine a group status to which we can apply actions
    switch (data.checkStatus) {
        case CheckStatus.Scheduled:
            return "scheduled";
        case CheckStatus.Overdue:
            return "overdue";
        case CheckStatus.DvlaError500:
        case CheckStatus.DvlaError503:
        case CheckStatus.DvlaError400:
        case CheckStatus.DvlaError403:
        case CheckStatus.DvlaErrorOther:
        case CheckStatus.Failed:
            return "dvla-error";
        case CheckStatus.Pending:
            return "pending";
        case CheckStatus.Checked:
            return "checked";
        case CheckStatus.Disabled:
        case CheckStatus.None:
            return "disabled";
    }
};

const determineLicenceCountryOrigin = (origin: string | null) => {
    if (origin === GREAT_BRITAIN || origin === NORTHERN_IRELAND) {
        return origin;
    } else {
        return "Foreign";
    }
};

export type DvlaBulkActionOptionType = {
    licenceOrigin: string;
    checkStatus: DvlaBulkActionCheckStatusGroups;
    checkType: CheckType;
};

// we need to compare the three different properties between everything in the selected group
// which make up the bulk action option type.
export const determineDvlaChecksBulkActionType = (
    selectedItems: TableCheckbox<DvlaCheckItem>[],
    clearSelectedItems: () => void,
    bulkActions: ReturnType<typeof useDvlaBulkActions>
): DropdownItem[] | undefined => {
    if (!selectedItems.length) return undefined;

    let licenceOrigin: string | undefined;
    let checkType: CheckType | undefined;
    let checkStatus: DvlaBulkActionCheckStatusGroups | undefined;

    const length = selectedItems.length;
    if (length === 1) {
        licenceOrigin = determineLicenceCountryOrigin(
            selectedItems[0].data.licenceCountryOrigin
        );
        checkType = selectedItems[0].data.checkType ?? undefined;
        checkStatus = determineGroupCheckStatus(selectedItems[0].data);

        if (licenceOrigin && checkType && checkStatus) {
            return dvlaChecksBulkActionOptions(
                selectedItems,
                clearSelectedItems,
                bulkActions,
                {
                    licenceOrigin,
                    checkStatus,
                    checkType,
                }
            );
        }
        return undefined;
    }

    // compare licence origin group (uk, ni, foreign)
    for (let i = 0; i < selectedItems.length - 1; i++) {
        const firstItem = determineLicenceCountryOrigin(
            selectedItems[i].data.licenceCountryOrigin
        );
        const secondItem = determineLicenceCountryOrigin(
            selectedItems[i + 1].data.licenceCountryOrigin
        );

        if (firstItem === secondItem) {
            licenceOrigin = firstItem;
        } else {
            licenceOrigin = undefined;
            break;
        }
    }
    if (!licenceOrigin) return undefined;

    // compare check type (manual, dvla)
    for (let i = 0; i < selectedItems.length - 1; i++) {
        const firstItem = selectedItems[i].data.checkType;
        const secondItem = selectedItems[i + 1].data.checkType;

        if (firstItem && firstItem === secondItem) {
            checkType = firstItem;
        } else {
            checkType = undefined;
            break;
        }
    }
    if (!checkType) return undefined;

    // compare status group
    for (let i = 0; i < selectedItems.length - 1; i++) {
        const firstItem = determineGroupCheckStatus(selectedItems[i].data);
        const secondItem = determineGroupCheckStatus(selectedItems[i + 1].data);

        if (firstItem === secondItem) {
            checkStatus = firstItem;
        } else {
            checkStatus = undefined;
            break;
        }
    }
    if (!checkStatus) return undefined;

    return dvlaChecksBulkActionOptions(
        selectedItems,
        clearSelectedItems,
        bulkActions,
        {
            licenceOrigin,
            checkStatus,
            checkType,
        }
    );
};
