import { DesktopDatePicker } from "@mui/x-date-pickers";
import { FieldError } from "react-hook-form";
import { CalendarBlank } from "phosphor-react";
import clsx from "clsx";

import FormInputLabel from "./FormInputLabel";
import FormTextInput from "./FormTextInput";
import { createTheme, ThemeProvider } from "@mui/material";
import { localToUTC, makeLocalAppearUTC } from "../utils/localToUTC";
import useTheme from "../theme/useTheme";

export type FormDateInputProps = {
    id: string;
    label: string;
    value: Date | null;
    onChange: (value: Date | null) => void;
    onBlur?: (value: Date | null) => void;
    error?: FieldError | string;
    minDate?: Date;
    maxDate?: Date;
    required?: boolean;
    hideHelpText?: boolean;
    helperText?: string;
    adornmentClassName?: string;
    disabled?: boolean;
    keepLocalTime?: boolean;
};

const FormDateInput = ({
    id,
    label,
    value,
    onChange,
    onBlur,
    error,
    minDate,
    maxDate,
    required,
    hideHelpText,
    helperText = "DD/MM/YYYY",
    disabled = false,
    adornmentClassName,
    keepLocalTime = false,
}: FormDateInputProps) => {
    const companyTheme = useTheme();

    const theme = createTheme({
        typography: {
            fontFamily: "Inter",
        },
        palette: {
            primary: {
                main: `#${companyTheme?.secondaryColour ?? "ADD53A"}`,
                dark: `#${companyTheme?.secondaryColour ?? "ADD53A"}`,
            },
        },
    });

    const handleSelectFromPicker = (value: Date | null) => {
        if (keepLocalTime) {
            onChange(value);
        } else {
            onChange(localToUTC(value));
        }
        if (onBlur) {
            onBlur(keepLocalTime ? value : localToUTC(value));
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const date = e.target.value.split("/");
        const value = new Date(
            Number(date[2]),
            Number(date[1]) - 1,
            Number(date[0])
        );
        if (!value) return;
        if (!onBlur) return;
        onBlur(keepLocalTime ? value : localToUTC(value));
    };

    const handleChange = (date: Date | null) => {
        onChange(keepLocalTime ? date : localToUTC(date));
    };

    return (
        <div className="w-full">
            <FormInputLabel htmlFor={id} required={required}>
                {label}
            </FormInputLabel>
            <ThemeProvider theme={theme}>
                {!disabled && (
                    <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        value={keepLocalTime ? value : makeLocalAppearUTC(value)}
                        onOpen={() => {
                            if (!value && minDate) {
                                onChange(minDate);
                            }
                        }}
                        components={{
                            OpenPickerIcon: () => (
                                <CalendarBlank weight="fill" />
                            ),
                        }}
                        OpenPickerButtonProps={{ style: { color: "black" } }}
                        onChange={handleChange}
                        onAccept={handleSelectFromPicker}
                        minDate={minDate}
                        maxDate={maxDate}
                        renderInput={(params) => (
                            <div className="relative flex" ref={params.ref}>
                                <FormTextInput
                                    id={id}
                                    className="w-full"
                                    ref={params.inputRef}
                                    error={error}
                                    helpText={
                                        hideHelpText ? undefined : helperText
                                    }
                                    {...params.inputProps}
                                    onBlur={(e) => handleBlur(e)}
                                />
                                <span
                                    className={clsx(
                                        "absolute right-0 -mt-[0.65rem] flex h-full items-center",
                                        error ? "mr-12" : "mr-4",
                                        adornmentClassName
                                    )}
                                >
                                    {params.InputProps?.endAdornment}
                                </span>
                            </div>
                        )}
                    />
                )}
                {disabled && (
                    <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        readOnly
                        onOpen={() => {
                            if (!value && minDate) {
                                onChange(minDate);
                            }
                        }}
                        onChange={onChange}
                        minDate={minDate}
                        maxDate={maxDate}
                        renderInput={(params) => (
                            <div className="relative flex" ref={params.ref}>
                                <FormTextInput
                                    id={id}
                                    inputFieldClassName="opacity-40 cursor-not-allowed border border-black rounded-sm w-full px-4 h-14 flex items-center transition-background-color"
                                    className="w-full"
                                    ref={params.inputRef}
                                    error={error}
                                    {...params.inputProps}
                                />
                            </div>
                        )}
                    />
                )}
            </ThemeProvider>
        </div>
    );
};

export default FormDateInput;
