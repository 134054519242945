import { UseFormReturn, useWatch } from "react-hook-form";
import { DvlaChecksFilterFormValues } from "./useDvlaChecksFilterForm";
import { useMemo } from "react";

export const useDvlaCheckFilterSelectedValues = (
    methods: UseFormReturn<DvlaChecksFilterFormValues>
) => {
    const { control } = methods;
    const {
        ukDvlaChecks,
        ukDvlaCheckErrors,
        ukManualChecks,
        northernIrishManualChecks,
        foreignManualChecks,
    } = useWatch({ control });

    const selectedValues = <
        FieldType extends { [key: string]: boolean | null | undefined }
    >(
        value: FieldType | undefined
    ): string[] => {
        if (!value) return [];

        const values = new Array(0);
        Object.entries(value).map(([key, value]) => value && values.push(key));

        return values;
    };

    const ukDvlaCheckSelectedValues = useMemo(
        () => selectedValues(ukDvlaChecks),
        [ukDvlaChecks]
    );
    const ukDvlaCheckErrorSelectedValues = useMemo(
        () => selectedValues(ukDvlaCheckErrors),
        [ukDvlaCheckErrors]
    );
    const ukManualCheckSelectedValues = useMemo(
        () => selectedValues(ukManualChecks),
        [ukManualChecks]
    );
    const northernIrishManualCheckSelectedValues = useMemo(
        () => selectedValues(northernIrishManualChecks),
        [northernIrishManualChecks]
    );
    const foreignManualCheckSelectedValues = useMemo(
        () => selectedValues(foreignManualChecks),
        [foreignManualChecks]
    );

    return {
        ukDvlaCheckSelectedValues,
        ukDvlaCheckErrorSelectedValues,
        ukManualCheckSelectedValues,
        northernIrishManualCheckSelectedValues,
        foreignManualCheckSelectedValues,
    };
};
