import * as yup from "yup";
import { SchemaKeys } from "../../../../../models/shared/schemaKeysHelper";
import { AddInvoiceAddressFormData } from "../../../../../models/app/admin/invoiceAddress";

export type AddInvoiceAddressFormValues = AddInvoiceAddressFormData;

export const AddInvoiceAddressFormValidationSchema: SchemaKeys<AddInvoiceAddressFormValues> =
    {
        addressName: yup
            .string()
            .max(100, "Invoice address name must be less than 100 characters.")
            .required("Please enter the address name."),
        address: yup.object().shape({
            addressLine1: yup
                .string()
                .max(200, "Invoice address line 1 must be less than 200 characters.")
                .required("Please enter the invoice address line 1."),
            addressLine2: yup
                .string()
                .max(200, "Invoice address line 2 must be less than 200 characters.")
                .notRequired(),
            addressLine3: yup
                .string()
                .max(200, "Invoice address line 3 must be less than 200 characters.")
                .notRequired(),
            addressLine4: yup
                .string()
                .max(200, "Invoice address line 4 must be less than 200 characters.")
                .notRequired(),
            addressLine5: yup
                .string()
                .max(200, "Invoice address line 5 must be less than 200 characters.")
                .notRequired(),
            addressTown: yup
                .string()
                .max(200, "Invoice address town must be less than 200 characters.")
                .required("Please enter the invoice address town."),
            addressPostcode: yup
                .string()
                .matches(
                    /^([a-zA-Z][a-hj-yA-HJ-Y]?[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}|GIR ?0A{2})$/,
                    "Postcode is invalid"
                )
                .required("Please enter the invoice address postcode."),
        }),
        isPrimaryInvoiceAddress: yup
        .boolean()
    };
