import { SetStateAction, useEffect, useState } from "react";
import { useGetFileTransfers } from "../../../api/admin/fileSafe";
import useDebounceValue from "../../../hooks/useDebounceValue";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { FileSafeFilterFormValues } from "../../../components/FileSafeHeader/useFileSafeFilterForm";
import { SEARCH_BAR_DEBOUNCE_MS } from "../../../constants/filterConstants";

const useAdminFileSafe = () => {
    const [searchQuery, setSearchQuery] = useState<string>("");
    const query = useDebounceValue(searchQuery, SEARCH_BAR_DEBOUNCE_MS);

    const [inboxPageNumber, setInboxPageNumber] = useLocalStorage<number>(
        "FileSafeInboxPageNumber",
        1
    );
    const [inboxPageSize, setInboxPageSize] = useLocalStorage<number>(
        "FileSafeInboxPageSize",
        15
    );
    const [sentPageNumber, setSentPageNumber] = useLocalStorage<number>(
        "FileSafeSentPageNumber",
        1
    );
    const [sentPageSize, setSentPageSize] = useLocalStorage<number>(
        "FileSafeSentPageSize",
        15
    );

    const [fileSafeFilters, setFileSafeFilters] = useState<
        FileSafeFilterFormValues | undefined
    >();

    // using useGetFileTransfers twice: once for sent and once for received
    const {
        fileTransferList: sentFileList,
        fileTransferListError: sentFileListError,
        isFileTransferListLoading: isSentFileListLoading,
        refetchFileTransferList: refetchSentFileList,
    } = useGetFileTransfers(
        "Sent",
        sentPageNumber,
        sentPageSize,

        fileSafeFilters?.companyId ? [fileSafeFilters?.companyId] : undefined,
        fileSafeFilters?.departmentId
            ? [fileSafeFilters?.departmentId]
            : undefined,

        fileSafeFilters?.from,
        fileSafeFilters?.to,
        query
    );
    const {
        fileTransferList: receivedFileList,
        fileTransferListError: receivedFileListError,
        isFileTransferListLoading: isReceivedFileListLoading,
        refetchFileTransferList: refetchReceivedFileList,
    } = useGetFileTransfers(
        "Received",
        inboxPageNumber,
        inboxPageSize,

        fileSafeFilters?.companyId ? [fileSafeFilters?.companyId] : undefined,
        fileSafeFilters?.departmentId
            ? [fileSafeFilters?.departmentId]
            : undefined,
        fileSafeFilters?.from,
        fileSafeFilters?.to,
        query
    );

    const inboxTabText = receivedFileList
        ? `Inbox (${receivedFileList?.totalCount})`
        : "Inbox";
    const sentTabText = sentFileList
        ? `Sent (${sentFileList?.totalCount})`
        : "Sent";
    const [selectedTab, setSelectedTab] = useLocalStorage<string>(
        "fileSafeSelectedTab",
        inboxTabText
    );

    const sectionTabs = [inboxTabText, sentTabText];

    const handleChangeTab = (value: SetStateAction<string>) => {
        setSelectedTab(value);
    };

    useEffect(() => {
        const isInbox = selectedTab.includes("Inbox");
        setSelectedTab(isInbox ? inboxTabText : sentTabText);
    }, [
        JSON.stringify(receivedFileList?.totalCount),
        JSON.stringify(sentFileList?.totalCount),
    ]);
    return {
        sectionTabs,
        selectedTab,
        inboxTabText,
        sentTabText,
        handleChangeTab,
        setFileSafeFilters,
        sentFileList,
        sentFileListError,
        isSentFileListLoading,
        refetchSentFileList,
        receivedFileList,
        receivedFileListError,
        isReceivedFileListLoading,
        refetchReceivedFileList,
        searchQuery,
        setSearchQuery,
        inboxPageNumber,
        setInboxPageNumber,
        inboxPageSize,
        setInboxPageSize,
        sentPageNumber,
        setSentPageNumber,
        sentPageSize,
        setSentPageSize,
    };
};

export default useAdminFileSafe;
