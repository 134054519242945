import { Controller, useForm } from "react-hook-form";
import DialogBox from "../DialogBox";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormDateInput from "../FormDateInput";
import Button from "../Button";
import { dateValidation } from "../../utils/dateValidationYup";

type EditCheckDateDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    handleUpdateNextCheckDate: (nextCheckDate: Date) => Promise<void>;
    isUpdatingNextCheck: boolean;
};

type CheckDateFormFields = {
    nextCheckDate: Date | null;
};

const EditCheckDateDialog = ({
    isOpen,
    onClose,
    handleUpdateNextCheckDate,
    isUpdatingNextCheck,
}: EditCheckDateDialogProps) => {
    const today = new Date();
    const validationSchema = yup.object().shape({
        nextCheckDate: dateValidation
            .min(today, "Date must be in the future.")
            .required("Date is required.")
            .nullable(),
    });

    const { handleSubmit, control, formState } = useForm<CheckDateFormFields>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            nextCheckDate: null,
        },
    });

    const handleFormSubmit = (data: CheckDateFormFields) => {
        if (!data.nextCheckDate) return;

        handleUpdateNextCheckDate(data.nextCheckDate);
    };

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={onClose}
            title="Edit check date"
            closeButton
        >
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="flex flex-col gap-4 p-4">
                    <Controller
                        name="nextCheckDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormDateInput
                                id="nextCheckDate"
                                label="Next check date"
                                value={value}
                                onChange={onChange}
                                error={formState.errors.nextCheckDate}
                                required
                                minDate={today}
                            />
                        )}
                    />
                </div>
                <div className="flex flex-col-reverse justify-between rounded-b-sm border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                    <Button
                        variant="tertiary"
                        type="button"
                        onClick={onClose}
                        className="w-full justify-center md:w-auto"
                    >
                        Cancel
                    </Button>

                    <Button
                        type="submit"
                        loading={isUpdatingNextCheck}
                        className="w-full justify-center md:w-auto"
                    >
                        Save
                    </Button>
                </div>
            </form>
        </DialogBox>
    );
};

export default EditCheckDateDialog;
