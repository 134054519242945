import { ApiDocumentResponse } from "../../models/api/admin/document";
import { DocumentBase64 } from "../../models/app/admin/document";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";
import { useDeleteDocument } from "./document";

const useEditDocument = () =>
    useS2DApiFetcher<
        DocumentBase64,
        string,
        { id: string; dto: DocumentBase64 }
    >(
        "POST",
        ({ id, dto }) => ({
            url: `api/drivers/${id}/Documents`,
            body: dto,
        }),
        [],
        true
    );

export const useDriverDocument = (id?: string) => {
    const { data, error, isLoading, refetch } = useS2DApiSWR<
        ApiDocumentResponse[]
    >(id ? `api/drivers/${id}/Documents` : null);

    const [editDocument, isEditingDocument] = useEditDocument();
    const [deleteDocument, isDeletingDocument] = useDeleteDocument();

    return {
        documentData: data,
        documentError: error,
        documentIsLoading: isLoading,
        refetchDocuments: refetch,
        editDocument: async (id: string, dto: DocumentBase64) =>
            await editDocument({ id, dto }),
        deleteDocument: async (fileId: string) => {
            const result = await deleteDocument(fileId);

            if (result.success) {
                refetch();
            }

            return result;
        },
        isEditingDocument,
        isDeletingDocument,
    };
};
