import {
    type TableContent,
    type TableHeader,
} from "../ReusableComponents/Table";
import StatusPill from "../ReusableComponents/StatusPill/StatusPill";
import UserIcon from "../UserIcon";
import {
    DvlaCheckOrderBy,
    type DvlaCheckItem,
} from "../../models/api/safe2DriveAdmin/dvlaChecks";
import { formatDateToString } from "../../utils/formatDateToString";
import { driverPointsColor } from "../../utils/driverPointsColour";
import { CheckStatus } from "../../models/shared/statuses";
import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import { Timer } from "phosphor-react";
import { capitaliseWord } from "../../utils/capitaliseWord";
import { addSpacesToCamelCase } from "../../utils/addSpacesToCamelCase";
import { CompanyRiskProfile } from "../../models/app/admin/company";

export type DvlaChecksTableAllowedIds =
    | "driverId"
    | "name"
    | "client"
    | "department"
    | "points"
    | "lastChecked"
    | "nextCheck"
    | "licenceNumber"
    | "origin"
    | "checkStatus"
    | "checkType";

export type DvlaChecksTableHeader = TableHeader<DvlaChecksTableAllowedIds>;
export type DvlaChecksTableContent = TableContent<
    DvlaCheckItem,
    DvlaChecksTableAllowedIds
>;

export const initDvlaChecksTableHeaders: DvlaChecksTableHeader[] = [
    {
        title: `Driver Id`,
        id: "driverId",
        menuGroup: `Driver Details`,
        hide: true,
    },
    {
        title: `Name`,
        id: "name",
        orderBy: DvlaCheckOrderBy.LastName,
        menuGroup: `Driver Details`,
    },
    {
        title: capitalisedTermForCompany,
        id: "client",
        orderBy: DvlaCheckOrderBy.Company,
        menuGroup: `Driver Details`,
    },
    {
        title: "Department",
        id: "department",
        orderBy: DvlaCheckOrderBy.Department,
        menuGroup: `Driver Details`,
    },
    {
        title: "Points",
        id: "points",
        menuGroup: `Licence Details`,
    },
    {
        title: "Last checked",
        id: "lastChecked",
        menuGroup: "Licence Details",
    },
    {
        title: "Next check",
        id: "nextCheck",
        menuGroup: "Licence Details",
    },
    {
        title: "Licence number",
        id: "licenceNumber",
        menuGroup: "Licence Details",
    },
    {
        title: "Origin",
        id: "origin",
        menuGroup: "Licence Details",
    },
    {
        title: "Check Status",
        id: "checkStatus",
        menuGroup: "Licence Details",
    },
    {
        title: "Check Type",
        id: "checkType",
        menuGroup: "Licence Details",
    },
];

const checkStatusColor = (status: CheckStatus | null) => {
    if (status === null || status === undefined) return "bg-S2D-neutral-70";

    if (status === CheckStatus.Scheduled) return "bg-S2D-neutral-95";

    const showSuccess = status === CheckStatus.Checked;
    const showWarning =
        status === CheckStatus.Pending ||
        status === CheckStatus.DvlaError500 ||
        status === CheckStatus.DvlaError503;
    const showError =
        status === CheckStatus.Overdue ||
        status === CheckStatus.Failed ||
        status === CheckStatus.DvlaError400 ||
        status === CheckStatus.DvlaError403;

    if (showSuccess) {
        return "bg-S2D-success-80";
    } else if (showWarning) {
        return "bg-S2D-warning-90";
    } else if (showError) {
        return "bg-S2D-error-40 text-S2D-neutral-99";
    } else {
        return "bg-S2D-neutral-80";
    }
};

export function initDvlaChecksTableContents(
    drivers: DvlaCheckItem[],
    companyRiskProfile?: CompanyRiskProfile[]
): DvlaChecksTableContent[] {
    const tableContents = new Array<DvlaChecksTableContent>(0);

    drivers.map((driver) => {
        const pointsColor = driverPointsColor(
            driver.totalPoints,
            companyRiskProfile
        );

        tableContents.push({
            rowId: driver.driverId,
            rowData: driver,
            cellData: [
                {
                    id: "driverId",
                    content: <p>{driver.driverId}</p>,
                },
                {
                    id: "name",
                    content: (
                        <div className="flex items-center gap-2">
                            <UserIcon
                                firstName={driver.firstName}
                                lastName={driver.lastName}
                                size="small"
                            />
                            <p>
                                {driver.firstName} {driver.lastName}
                            </p>
                        </div>
                    ),
                },
                {
                    id: "client",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.companyName}
                        </p>
                    ),
                },
                {
                    id: "department",
                    content: <p>{driver.departmentName}</p>,
                },
                {
                    id: "points",
                    content: (
                        <div className="pl-2">
                            <div
                                className={`h-7 w-7 rounded-full ${pointsColor} flex items-center justify-center`}
                            >
                                {driver.totalPoints ?? "-"}
                            </div>
                        </div>
                    ),
                },
                {
                    id: "lastChecked",
                    content: (
                        <p>
                            {driver.lastCheckDate
                                ? formatDateToString(
                                      new Date(driver.lastCheckDate)
                                  )
                                : "unchecked"}
                        </p>
                    ),
                },
                {
                    id: "nextCheck",
                    content: (
                        <p>
                            {driver.nextCheckDate
                                ? formatDateToString(
                                      new Date(driver.nextCheckDate)
                                  )
                                : "unscheduled"}
                        </p>
                    ),
                },
                {
                    id: "licenceNumber",
                    content: <p>{driver.licenceNumber ?? "-"}</p>,
                },
                {
                    id: "origin",
                    content: <p>{driver.licenceCountryOrigin ?? "-"}</p>,
                },
                {
                    id: "checkStatus",
                    content: (
                        <div className="pl-2">
                            <StatusPill
                                status={capitaliseWord(
                                    addSpacesToCamelCase(driver.checkStatus)
                                )}
                                bgColour={checkStatusColor(driver.checkStatus)}
                                icon={
                                    driver.checkStatus === CheckStatus.Scheduled
                                        ? Timer
                                        : undefined
                                }
                            />
                        </div>
                    ),
                },
                {
                    id: "checkType",
                    content: (
                        <div className="pl-2">
                            <StatusPill
                                status={driver.checkType}
                                bgColour={
                                    "bg-S2D-neutral-95 border border-S2D-neutral-90"
                                }
                            />
                        </div>
                    ),
                },
            ],
        });
    });
    return tableContents;
}
