import Button from "../Button";
import DialogBox from "../DialogBox";

type ConfirmationDialogProps = {
    open: boolean;
    isLoading?: boolean;
    title: string;
    caption: string;
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    variant?: "danger" | "default";
    error?: string;
    disabled?: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    isLoading = false,
    title,
    caption,
    cancelButtonLabel = "Cancel",
    confirmButtonLabel = "Confirm",
    variant = "default",
    error,
    disabled = false,
    onClose,
    onConfirm,
}) => {
    return (
        <DialogBox
            isOpen={open}
            onClose={onClose}
            title={title}
            description={caption}
            size="small"
        >
            <>
                {error && (
                    <div className="px-6 pb-4 text-sm text-S2D-error-40">
                        {error}
                    </div>
                )}
                <div className="flex flex-col-reverse justify-center gap-4 p-6 pt-0">
                    <Button
                        variant="tertiary"
                        onClick={onClose}
                        className="min-w-fit flex-none grow justify-center"
                    >
                        {cancelButtonLabel}
                    </Button>
                    <Button
                        variant={variant === "danger" ? "danger" : "primary"}
                        className="min-w-fit flex-none grow justify-center"
                        disabled={disabled}
                        onClick={onConfirm}
                        loading={isLoading}
                    >
                        {confirmButtonLabel}
                    </Button>
                </div>
            </>
        </DialogBox>
    );
};

export default ConfirmationDialog;
