import clsx from "clsx";
import TransferDateFields from "./TransferDateFields";
import Button from "../Button";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { type FileSafeFilterFormValues } from "./useFileSafeFilterForm";
import { ApiCompaniesResponse } from "../../models/api/admin/company";
import { ApiDepartmentResponse } from "../../models/api/admin/department";
import useAuth from "../../auth";
import { hasS2dFileSafeClaim } from "../../auth/userAccessHelpers";
import AccordionItem from "../AccordionItem";
import { useMemo } from "react";
import {
    capitalisedTermForCompany,
    termForCompany,
} from "../../copy/sharedCopy";
import { formatDateToString } from "../../utils/formatDateToString";
import FormDropdown from "../FormDropdown";
import LoadingMessage from "../ReusableComponents/LoadingMessage/LoadingMessage";
import { errorToast } from "../../toast";

type Props = {
    clearAll: () => void;
    methods: UseFormReturn<FileSafeFilterFormValues>;
    handleApplyFilters: (data: FileSafeFilterFormValues) => void;
    companyList?: ApiCompaniesResponse;
    isCompanyListLoading?: boolean;
    departmentsList?: ApiDepartmentResponse;
    isDepartmentsListLoading?: boolean;
};
export default function FileSafeFilterForm({
    clearAll,
    methods,
    handleApplyFilters,
    companyList,
    isCompanyListLoading,
    departmentsList,
    isDepartmentsListLoading,
}: Props) {
    const handleSelectCompany = (value: string | null) => {
        const filter = methods.getValues();
        // reset department id when the company id changes
        if (filter.departmentId) {
            methods.setValue("departmentId", null);
            errorToast(
                "Department has been reset since the selected client has been edited."
            );
        }

        methods.setValue("companyId", value);
    };

    const { decodedToken } = useAuth();
    const { control } = methods;
    const values = useWatch({ control });

    const selectedDates = useMemo(() => {
        if (values.from && values.to) {
            return [
                `From: ${formatDateToString(values.from)}`,
                `To: ${formatDateToString(values.to)}`,
            ];
        }
    }, [values.from, values.to]);

    const selectedEmploymentDetails = useMemo(() => {
        const companyName = companyList?.items.find(
            (company) => company.companyId === values.companyId
        )?.companyName;
        const departmentName = departmentsList?.items.find(
            (department) => department.departmentId === values.departmentId
        )?.departmentName;

        if (companyName && departmentName) {
            return [
                `${capitalisedTermForCompany}: ${companyName}`,
                `Department: ${departmentName}`,
            ];
        } else if (companyName) {
            return [`${capitalisedTermForCompany}: ${companyName}`];
        } else if (departmentName) {
            return [`Department: ${departmentName}`];
        }
    }, [values.companyId, values.departmentId]);

    const companyOptions = companyList?.items.map((company) => ({
        label: company.companyName,
        value: company.companyId,
    }));

    const departmentOptions = departmentsList?.items.map((department) => ({
        label: department.departmentName,
        value: department.departmentId,
    }));

    return (
        <form onSubmit={methods.handleSubmit(handleApplyFilters)}>
            <div className="max-h-[32rem] overflow-y-auto">
                <AccordionItem
                    title="Transfer date"
                    modalStyling
                    valueSummary={selectedDates}
                >
                    <TransferDateFields methods={methods} />
                </AccordionItem>
                {hasS2dFileSafeClaim(decodedToken) && (
                    <AccordionItem
                        title="Client and Department"
                        modalStyling
                        valueSummary={selectedEmploymentDetails}
                    >
                        {isCompanyListLoading && <LoadingMessage />}
                        {!isCompanyListLoading && (
                            <Controller
                                name="companyId"
                                control={methods.control}
                                render={({ field: { value } }) => (
                                    <FormDropdown
                                        label={capitalisedTermForCompany}
                                        options={companyOptions ?? []}
                                        value={value}
                                        onChange={handleSelectCompany}
                                        pleaseSelectText={`Select ${termForCompany}`}
                                        error={
                                            methods.formState.errors.companyId
                                        }
                                    />
                                )}
                            />
                        )}

                        <Controller
                            name="departmentId"
                            control={methods.control}
                            render={({ field: { value, onChange } }) => (
                                <FormDropdown
                                    label="Department"
                                    helpText={
                                        values.companyId
                                            ? undefined
                                            : "Cannot select a department without first selecting a client."
                                    }
                                    options={departmentOptions ?? []}
                                    value={value ?? ""}
                                    onChange={onChange}
                                    pleaseSelectText="Select department"
                                    noOptionsText="Selected company has no departments."
                                    error={
                                        methods.formState.errors.departmentId
                                    }
                                    isLoading={isDepartmentsListLoading}
                                    disabled={!values.companyId}
                                    optionsAboveButton
                                />
                            )}
                        />
                    </AccordionItem>
                )}
            </div>
            <div className="flex flex-col-reverse justify-between gap-2 rounded-b-sm border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                <Button
                    variant="tertiary"
                    type="button"
                    onClick={clearAll}
                    className="w-full justify-center md:w-auto"
                >
                    Clear all
                </Button>
                <div
                    className={clsx(
                        "flex flex-col-reverse gap-2 md:flex-row md:justify-between"
                    )}
                >
                    <Button
                        type="submit"
                        loading={false}
                        className="w-full justify-center md:w-auto"
                    >
                        Apply filters
                    </Button>
                </div>
            </div>
        </form>
    );
}
