import { SetStateAction } from "react";
import DialogBox from "../../../DialogBox";
import AddDepartmentForm from "./AddDepartmentForm/AddDepartmentForm";
import { AddOrEditModalStates } from "../CompanyBillingTab";
import { capitaliseWord } from "../../../../utils/capitaliseWord";
import EditDepartmentForm from "./EditDepartmentForm/EditDepartmentForm";
import { type ApiDepartmentItem } from "../../../../models/api/admin/department";
import { ApiInvoiceAddressItem } from "../../../../models/api/admin/invoiceAddress";

type AddOrEditDepartmentModalProps = {
    isOpen: boolean;
    setModalState: (value: SetStateAction<AddOrEditModalStates>) => void;
    companyId: string | undefined;
    selectedDepartment: ApiDepartmentItem | undefined;
    refetchDepartments: () => void;
    type: AddOrEditModalStates;
    invoiceAddresses: ApiInvoiceAddressItem[];
};

const AddOrEditDepartmentModal: React.FC<AddOrEditDepartmentModalProps> = ({
    isOpen,
    setModalState,
    companyId,
    selectedDepartment,
    refetchDepartments,
    type = "closed",
    invoiceAddresses
}) => {
    return (
        <DialogBox
            isOpen={isOpen}
            onClose={() => setModalState("closed")}
            title={`${capitaliseWord(type)} department`}
            size="large"
            closeButton
        >
            {type === "add" && (
                <AddDepartmentForm
                    companyId={companyId}
                    setModalState={setModalState}
                    refetchDepartments={refetchDepartments}
                    invoiceAddresses={invoiceAddresses}
                />
            )}

            {type === "edit" && (
                <EditDepartmentForm
                    setModalState={setModalState}
                    companyId={companyId}
                    selectedDepartment={selectedDepartment}
                    refetchDepartments={refetchDepartments}
                    invoiceAddresses={invoiceAddresses}
                />
            )}
        </DialogBox>
    );
};

export default AddOrEditDepartmentModal;
