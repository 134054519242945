import * as yup from "yup";
import { SchemaLike } from "yup/lib/types";
import { DriverTypes } from "../../models/api/admin/driver";
import { DocumentBase64, DocumentTypes } from "../../models/app/admin/document";
import { GREAT_BRITAIN } from "../../constants/countries";
import { SchemaKeys } from "../../models/shared/schemaKeysHelper";
import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import { DriverValidationTypes } from "../../models/app/admin/company";
import { localToUTC } from "../../utils/localToUTC";
import { anchorDate } from "../../utils/anchorDate";
import { dateValidation } from "../../utils/dateValidationYup";
import { mobileNumberValidation } from "../../utils/mobileNumberValidationYup";
import { DeclarationType } from "../../models/app/eDeclarations";
import { setYear } from "date-fns";

const addressSchemaProperties = [
    "address1",
    "address2",
    "address3",
    "address4",
    "address5",
    "addressTown",
    "addressPostcode",
];

// We need to define array of dependency arrays to satisfy Yup validation cyclic dependency
const exhaustiveAddressDependencyArray = addressSchemaProperties
    .filter(
        (addressSchemaProperty) =>
            addressSchemaProperty === "address1" ||
            addressSchemaProperty === "addressTown" ||
            addressSchemaProperty === "addressPostcode"
    )
    .map((primaryAddressSchemaProperty) =>
        addressSchemaProperties
            .filter(
                (addressSchemaProperty) =>
                    addressSchemaProperty !== primaryAddressSchemaProperty
            )
            .map((secondaryAddressSchemaProperty): [string, string] => [
                primaryAddressSchemaProperty,
                secondaryAddressSchemaProperty,
            ])
    )
    .flat();

const conditionalAddressValidation = (
    ignoreProperty: string,
    validationSchema: SchemaLike
) => {
    const propertiesToCheck = addressSchemaProperties.filter(
        (property) => property !== ignoreProperty
    );

    // there will always be 6 address properties left after filtering current
    // ignore property - prop1, prop2 etc
    return yup.string().when(propertiesToCheck, {
        is: (
            prop1: string,
            prop2: string,
            prop3: string,
            prop4: string,
            prop5: string,
            prop6: string
        ) => {
            if (
                prop1 !== "" ||
                prop2 !== "" ||
                prop3 !== "" ||
                prop4 !== "" ||
                prop5 !== "" ||
                prop6 !== ""
            ) {
                return true;
            }
            return false;
        },
        then: validationSchema,
    });
};

const today = anchorDate(localToUTC(new Date()), "max"),
    threeYearsAgo = today && setYear(today, today.getFullYear() - 3);

export const documentSchema = yup.object().shape({
    documentBase64: yup.string(),
    fileType: yup.string(),
    fileName: yup.string(),
    documentType: yup.number(),
    signedDate: dateValidation
        .max(today, "Date of signing cannot be in the future")
        .min(threeYearsAgo, "This declaration has already expired.")
        .when("documentType", {
            is: (documentType: number) => {
                if (documentType === DocumentTypes.DriverDeclaration) {
                    return true;
                }
                return false;
            },
            then: dateValidation.required("Date signed is required").nullable(),
        })
        .notRequired()
        .nullable(),
});

export const AddDriverValidationSchemaFields: SchemaKeys<AddDriverFormValues> =
    {
        declarationType: yup
            .string()
            .required("Driver declaration from is required"),
        firstName: yup
            .string()
            .max(30, "'First Name' must be 30 characters or fewer.")
            .required("First name is required")
            .trim(),
        lastName: yup
            .string()
            .max(30, "'Last Name' must be 30 characters or fewer.")
            .required("Surname is required")
            .trim(),
        dateOfBirth: dateValidation
            .max(new Date(), "Date of birth must be before today")
            .when("validateDriverIdentityBy", {
                is: (validateDriverIdentityBy: string) => {
                    if (
                        validateDriverIdentityBy ===
                        DriverValidationTypes.DateOfBirth
                    ) {
                        return true;
                    }

                    return false;
                },
                then: dateValidation.required("Date of birth is required"),
            })
            .nullable(),
        employeeId: yup
            .string()
            .trim()
            .when("validateDriverIdentityBy", {
                is: (validateDriverIdentityBy: string) => {
                    if (
                        validateDriverIdentityBy ===
                        DriverValidationTypes.EmployeeId
                    ) {
                        return true;
                    }

                    return false;
                },
                then: yup.string().required("Employee ID is required"),
            }),
        emailAddress: yup
            .string()
            .email(
                "Email address must be a valid email address, eg. hello@example.com"
            )
            .trim()
            .when("declarationType", {
                is: (declarationType: string) => {
                    if (declarationType === DeclarationType.EDeclaration) {
                        return true;
                    }

                    return false;
                },
                then: yup
                    .string()
                    .email(
                        "Email address must be a valid email address, eg. hello@example.com"
                    )
                    .required("Email address is required"),
            }),
        foreignLicence: yup.boolean().notRequired(),
        licenceNumber: yup
            .string()
            .when("licenceCountryOrigin", {
                is: (licenceCountryOrigin: string) => {
                    if (licenceCountryOrigin === GREAT_BRITAIN) {
                        return true;
                    }

                    return false;
                },
                then: yup
                    .string()
                    .matches(
                        /^[A-Z9]{5}\d[0156]\d([0][1-9]|[12]\d|3[01])\d[A-Z9]{2}\d[A-Z]{2}$/,
                        {
                            message:
                                "Licence number must be 16 characters with no spaces, eg. MORGA753116SM9IJ",
                            excludeEmptyString: true,
                        }
                    ),
            })
            .notRequired(),
        primaryDriverId: yup.string().nullable().notRequired(),
        mobileNumber: mobileNumberValidation
            .nullable()
            .notRequired(),
        licenceCountryOrigin: yup.string().notRequired(),
        address: yup.object().shape(
            {
                address1: conditionalAddressValidation(
                    "address1",
                    yup.string().required("Address line 1 is required")
                ),
                address2: yup.string().nullable(),
                address3: yup.string().nullable(),
                address4: yup.string().nullable(),
                address5: yup.string().nullable(),
                addressTown: conditionalAddressValidation(
                    "addressTown",
                    yup.string().required("City is required")
                ),
                addressPostcode: conditionalAddressValidation(
                    "addressPostcode",
                    yup
                        .string()
                        .matches(
                            /^([a-zA-Z][a-hj-yA-HJ-Y]?[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}|GIR ?0A{2})$/,
                            "Postcode is invalid"
                        )
                        .required("Postcode is required")
                ),
            },
            exhaustiveAddressDependencyArray
        ),
        driverComment: yup.string().notRequired(),
        // documentSchema used when adding a document to this array, not when submitting full form
        documents: yup.array().notRequired().nullable(),
        companyId: yup
            .string()
            .required(
                `A driver must be associated with a ${capitalisedTermForCompany}`
            )
            .nullable(),
        departmentId: yup.string().nullable().notRequired(),
        jobRole: yup.string().nullable().notRequired(),
        externalReference: yup.string().max(20, "'External Reference' must be 20 characters or fewer.").nullable().notRequired(),
        driverType: yup.string().nullable().notRequired(),
        primaryDriverName: yup.string().nullable().notRequired(),
        validateDriverIdentityBy: yup.string().notRequired(),
    };

export type AddDriverFormValues = {
    declarationType: DeclarationType;
    firstName: string;
    lastName: string;
    dateOfBirth: Date | null;
    emailAddress: string;
    departmentId: string | null;
    companyId: string | null;
    jobRole: string | null;
    employeeId: string | null;
    externalReference: string | null;
    licenceNumber: string;
    foreignLicence: boolean;
    primaryDriverId: string | null;
    primaryDriverName: string | null;
    driverType: DriverTypes | null;
    validateDriverIdentityBy: DriverValidationTypes;
    mobileNumber: string | null;
    licenceCountryOrigin: string;
    address: {
        address1: string;
        address2?: string;
        address3?: string;
        address4?: string;
        address5?: string;
        addressTown: string;
        addressPostcode: string;
    };
    driverComment?: string;
    documents: DocumentBase64[];
};

export const addDriverFormDefaultValues = (
    userCompanyId: string | null
): AddDriverFormValues => {
    return {
        declarationType: DeclarationType.EDeclaration,
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        emailAddress: "",
        departmentId: null,
        companyId: userCompanyId,
        jobRole: "",
        employeeId: "",
        externalReference: "",
        licenceNumber: "",
        foreignLicence: false,
        primaryDriverId: null,
        primaryDriverName: null,
        driverType: "None",
        validateDriverIdentityBy: DriverValidationTypes.DateOfBirth,
        mobileNumber: null,
        licenceCountryOrigin: "",
        address: {
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            address5: "",
            addressPostcode: "",
            addressTown: "",
        },
        driverComment: "",
        documents: [],
    };
};
