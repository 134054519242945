import { Listbox } from "@headlessui/react";
import clsx from "clsx";
import useAuth from "../auth";
import UserIcon from "./UserIcon";
import { useCompany } from "../api/admin/company";
import { FingerprintSimple, Lock, SignOut } from "phosphor-react";
import { useState } from "react";
import PasswordResetModal from "../pages/User/PasswordReset";
import Toggle2FAModal from "../pages/User/Toggle2FA";

const UserMenu = () => {
    const { logOut, userDetails } = useAuth();
    const { companyData, isLoadingCompany } = useCompany(
        userDetails?.companyId
    );

    const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
    const [openToggle2faModal, setOpenToggle2faModal] = useState(false);

    const enforced2FA = companyData?.settings.authSettings?.enforce2FA;
    const waitForCompanyLoad = !!userDetails?.companyId && !!isLoadingCompany;
    return (
        <Listbox>
            <Listbox.Button>
                <UserIcon
                    firstName={userDetails?.firstName ?? ""}
                    lastName={userDetails?.lastName ?? ""}
                    size={"medium"}
                />
            </Listbox.Button>

            <Listbox.Options
                className={clsx(
                    "absolute top-[74px] right-6 left-6 h-fit max-h-60 w-full overflow-auto rounded-md md:left-auto md:w-fit md:min-w-[340px]",
                    "bg-white text-base shadow-S2D-neutral-50 drop-shadow-xl focus:outline-none",
                    "divide-y-2 divide-solid divide-S2D-neutral-80 overflow-visible"
                )}
            >
                <Listbox.Option value="details">
                    <div
                        className={clsx(
                            "flex flex-row items-center gap-4 rounded-t-sm p-4",
                            "hover:bg-S2D-neutral-90"
                        )}
                    >
                        <UserIcon
                            firstName={userDetails?.firstName ?? ""}
                            lastName={userDetails?.lastName ?? ""}
                            size={"medium"}
                        />
                        <div className="flex flex-col text-sm">
                            <p className="font-semibold">
                                {userDetails?.firstName} {userDetails?.lastName}
                            </p>
                            {userDetails?.userName}
                        </div>
                    </div>
                </Listbox.Option>
                <Listbox.Option value="reset-password">
                    <div
                        className={clsx(
                            "flex flex-row items-center gap-4 p-4",
                            "hover:cursor-pointer hover:bg-S2D-neutral-90"
                        )}
                        onClick={() => setOpenResetPasswordModal(true)}
                    >
                        <Lock size={24} weight="duotone" />{" "}
                        <p>Reset Password</p>
                    </div>
                </Listbox.Option>
                <Listbox.Option value="2fa-settings">
                    <div
                        className={clsx(
                            "flex flex-row items-center gap-4 p-4",
                            "hover:cursor-pointer hover:bg-S2D-neutral-90"
                        )}
                        onClick={() => setOpenToggle2faModal(true)}
                    >
                        <FingerprintSimple size={24} weight="duotone" />{" "}
                        <p>2FA Settings</p>
                    </div>
                </Listbox.Option>
                <Listbox.Option value="logout">
                    <div
                        className={clsx(
                            "flex flex-row items-center gap-4 rounded-b-sm p-4",
                            "hover:cursor-pointer hover:bg-S2D-neutral-90"
                        )}
                        onClick={logOut}
                    >
                        <SignOut size={24} weight="duotone" /> <p>Logout</p>
                    </div>
                </Listbox.Option>
            </Listbox.Options>
            <PasswordResetModal
                isOpen={openResetPasswordModal}
                onClose={() => setOpenResetPasswordModal(false)}
            />
            <Toggle2FAModal
                isOpen={openToggle2faModal}
                onClose={() => setOpenToggle2faModal(false)}
                enforced2FA={enforced2FA}
                waitForCompanyLoad={waitForCompanyLoad}
            />
        </Listbox>
    );
};

export default UserMenu;
