
// TODO: Just a simple loader for now, but could add a spinner or something
export default function LoadingMessage({ message = "loading" }) {
    return (
        <span className="animate-pulse text-S2D-neutral-30">
            <span className="animate-pulse">.</span>
            <span className="animate-pulse">.</span>
            <span className="animate-pulse">.</span>
            {message}
        </span>
    );
}
