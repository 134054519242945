import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import FormDropdown, { FormDropdownOption } from "../../FormDropdown";
import { DriversListFiltersFormValues } from "./DriversListFiltersFormValues";
import {
    handleVehicleAnalysisDropdownSelect,
    analysisSelectedValue,
} from "./handleAnalysisDropdownSelect";
import { addNullDropdownOption } from "../../../constants/dropdownOptions";
import {
    ageRangeOptions,
    businessCoverOptions,
    co2EmissionsOptions,
    engineCapacityOptions,
    euroRatingOptions,
    fuelTypeOptions,
    insuranceTypeOptions,
} from "../../../constants/vehicleAnalysisOptions";
import {
    AgeRange,
    AgeRangeOptions,
    BusinessCover,
    BusinessCoverOptions,
    Co2Emissions,
    Co2EmissionsOptions,
    EngineCapacity,
    EngineCapacityOptions,
    EuroRating,
    EuroRatingOptions,
    FuelType,
    FuelTypeOptions,
    InsuranceType,
    InsuranceTypeOptions,
} from "../../../models/app/admin/vehicleAnalysis";
import { useGreyFleetVehicleAnalysis } from "../../../api/greyFleet";
import LoadingMessage from "../../ReusableComponents/LoadingMessage/LoadingMessage";
import FormMessageBox from "../../FormMessageBox";
import AccordionItem from "../../AccordionItem";

type FilterVehicleAnalysisProps = {
    methods: UseFormReturn<DriversListFiltersFormValues>;
};

const FilterVehicleAnalysis: React.FC<FilterVehicleAnalysisProps> = ({
    methods,
}) => {
    const control = methods.control;
    const companyInfo = useWatch({ control, name: "company" });

    const {
        data: vehicleAnalysisData,
        isLoading: isLoadingVehicleAnalysisData,
        error: errorVehicleAnalysisData,
    } = useGreyFleetVehicleAnalysis(
        companyInfo?.companyIds ? companyInfo?.companyIds[0] : undefined,
        companyInfo?.departmentIds ? companyInfo?.departmentIds[0] : undefined
    );

    const manufacturerOptions: FormDropdownOption<string>[] =
        vehicleAnalysisData?.manufacturerType.manufacturers.map(
            (manufacturer) => ({
                label: manufacturer.name,
                value: manufacturer.name,
            })
        ) ?? [];

    return (
        <AccordionItem title="Vehicle analysis" modalStyling>
            <>
                <Controller
                    name="vehicleAnalysis.co2Emissions"
                    control={methods.control}
                    render={({ field: { value } }) => {
                        const selectedValue = analysisSelectedValue(
                            value ?? {}
                        ) as Co2EmissionsOptions;

                        return (
                            <FormDropdown
                                label="CO2 Emissions"
                                options={addNullDropdownOption(
                                    co2EmissionsOptions
                                )}
                                value={selectedValue}
                                onChange={(value: keyof Co2Emissions | null) =>
                                    handleVehicleAnalysisDropdownSelect(
                                        methods,
                                        "co2Emissions",
                                        value
                                    )
                                }
                            />
                        );
                    }}
                />
                <Controller
                    name="vehicleAnalysis.fuelType"
                    control={methods.control}
                    render={({ field: { value } }) => {
                        const selectedValue = analysisSelectedValue(
                            value ?? {}
                        ) as FuelTypeOptions;

                        return (
                            <FormDropdown
                                label="Fuel type"
                                options={addNullDropdownOption(fuelTypeOptions)}
                                value={selectedValue}
                                onChange={(value: keyof FuelType | null) =>
                                    handleVehicleAnalysisDropdownSelect(
                                        methods,
                                        "fuelType",
                                        value
                                    )
                                }
                            />
                        );
                    }}
                />
                <Controller
                    name="vehicleAnalysis.ageRange"
                    control={methods.control}
                    render={({ field: { value } }) => {
                        const selectedValue = analysisSelectedValue(
                            value ?? {}
                        ) as AgeRangeOptions;

                        return (
                            <FormDropdown
                                label="Age range"
                                options={addNullDropdownOption(ageRangeOptions)}
                                value={selectedValue}
                                onChange={(value: keyof AgeRange | null) =>
                                    handleVehicleAnalysisDropdownSelect(
                                        methods,
                                        "ageRange",
                                        value
                                    )
                                }
                            />
                        );
                    }}
                />
                {isLoadingVehicleAnalysisData && !vehicleAnalysisData && (
                    <LoadingMessage />
                )}
                {vehicleAnalysisData && (
                    <Controller
                        name="vehicleAnalysis.manufacturer.name"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <FormDropdown
                                    label="Manufacturer"
                                    options={addNullDropdownOption(
                                        manufacturerOptions
                                    )}
                                    value={value}
                                    onChange={onChange}
                                    // onBlur={}
                                />
                            );
                        }}
                    />
                )}
                {errorVehicleAnalysisData && (
                    <FormMessageBox
                        variant="error"
                        title={"Error"}
                        message={
                            "Could not load manufacturer options, please try again later."
                        }
                    />
                )}

                <Controller
                    name="vehicleAnalysis.euroRating"
                    control={methods.control}
                    render={({ field: { value } }) => {
                        const selectedValue = analysisSelectedValue(
                            value ?? {}
                        ) as EuroRatingOptions;

                        return (
                            <FormDropdown
                                label="Euro rating"
                                options={addNullDropdownOption(
                                    euroRatingOptions
                                )}
                                value={selectedValue}
                                onChange={(value: keyof EuroRating | null) =>
                                    handleVehicleAnalysisDropdownSelect(
                                        methods,
                                        "euroRating",
                                        value
                                    )
                                }
                            />
                        );
                    }}
                />
                <Controller
                    name="vehicleAnalysis.insuranceType"
                    control={methods.control}
                    render={({ field: { value } }) => {
                        const selectedValue = analysisSelectedValue(
                            value ?? {}
                        ) as InsuranceTypeOptions;

                        return (
                            <FormDropdown
                                label="Insurance type"
                                options={addNullDropdownOption(
                                    insuranceTypeOptions
                                )}
                                value={selectedValue}
                                onChange={(value: keyof InsuranceType | null) =>
                                    handleVehicleAnalysisDropdownSelect(
                                        methods,
                                        "insuranceType",
                                        value
                                    )
                                }
                            />
                        );
                    }}
                />
                <Controller
                    name="vehicleAnalysis.businessCover"
                    control={methods.control}
                    render={({ field: { value } }) => {
                        const selectedValue = analysisSelectedValue(
                            value ?? {}
                        ) as BusinessCoverOptions;

                        return (
                            <FormDropdown
                                label="Business cover"
                                options={addNullDropdownOption(
                                    businessCoverOptions
                                )}
                                value={selectedValue}
                                onChange={(value: keyof BusinessCover | null) =>
                                    handleVehicleAnalysisDropdownSelect(
                                        methods,
                                        "businessCover",
                                        value
                                    )
                                }
                            />
                        );
                    }}
                />
                <Controller
                    name="vehicleAnalysis.engineCapacity"
                    control={methods.control}
                    render={({ field: { value } }) => {
                        const selectedValue = analysisSelectedValue(
                            value ?? {}
                        ) as EngineCapacityOptions;

                        return (
                            <FormDropdown
                                label="Engine capacity"
                                options={addNullDropdownOption(
                                    engineCapacityOptions
                                )}
                                value={selectedValue}
                                onChange={(
                                    value: keyof EngineCapacity | null
                                ) =>
                                    handleVehicleAnalysisDropdownSelect(
                                        methods,
                                        "engineCapacity",
                                        value
                                    )
                                }
                            />
                        );
                    }}
                />
            </>
        </AccordionItem>
    );
};

export default FilterVehicleAnalysis;
