import React from "react";
import { type DriversListFiltersFormValues } from "./DriversListFiltersForm";
import DriversListFiltersForm from "./DriversListFiltersForm";
import { type FilterType } from "../../models/app/admin/filter";
import { useLocation } from "react-router-dom";
import { driverPageLocation } from "../../utils/driverPageLocation";
import { driverListFiltersDefaultValues } from "./DriversListFiltersForm/driversListFiltersDefaultValues";

type DriversListFiltersModalProps = {
    isEditingFilter: boolean;
    selectedFilter?: DriversListFiltersFormValues;
    onSaveFilter: (value: DriversListFiltersFormValues) => void;
    onApplyFilter: (filters?: DriversListFiltersFormValues) => Promise<boolean>;
    isApplyingFilter: boolean;
    appliedFilter: FilterType;
};

const DriversListFiltersModal: React.FC<DriversListFiltersModalProps> = ({
    isEditingFilter,
    selectedFilter,
    onSaveFilter,
    onApplyFilter,
    isApplyingFilter,
    appliedFilter,
}) => {
    //tells us whether to use temporarily stored data or locally stored data
    const pageLocation = useLocation();
    const location = driverPageLocation(pageLocation.pathname);
    const isEditValues =
        appliedFilter.filterFields ?? driverListFiltersDefaultValues(location);
    const prefilledFormValues = isEditingFilter ? isEditValues : selectedFilter;

    //TODO once accordion summarries are working, need to pass company data to them to match companyID/companyNAme

    // const selectedCompanyId = useWatch({ control, name: "companyId" });
    // const { companyData } = useCompany(selectedCompanyId);

    // useEffect(() => {
    //     if (companyData?.companyName) {
    //         // selectedCompanyName is displayed in the 'employment' filter summary
    //         setSelectedCompanyName(companyData?.companyName);
    //     }
    // }, [companyData?.companyName]);

    return (
        <>
            <div>
                <DriversListFiltersForm
                    filterValues={prefilledFormValues}
                    onApplyFilter={onApplyFilter}
                    isApplyingFilter={isApplyingFilter}
                    onSaveFilter={onSaveFilter}
                />
            </div>
        </>
    );
};

export default DriversListFiltersModal;
