import BarChart, { BarChartItem } from "../ReusableComponents/Charts/BarChart";
import PieChart, { PieChartItem } from "../ReusableComponents/Charts/PieChart";
import LoadingMessage from "../ReusableComponents/LoadingMessage/LoadingMessage";
import useGreyFleetVehicleAnalysisCharts from "./useGreyFleetVehicleAnalysisCharts";
import VerticalBarChart from "../ReusableComponents/Charts/VerticalBarChart";
import FormMessageBox from "../FormMessageBox";
import ChartContainer from "../ReusableComponents/Charts/ChartContainer";
import { useNavigate } from "react-router-dom";
import {
    AnalysisChartType,
    GreyFleetVehicleAnalysisCharts as GreyFleetVehicleAnalysisChartTypes,
} from "../../models/app/admin/analysisChart";
import { appPaths } from "../../Routes";
import { StackedBarChartItem } from "../ReusableComponents/Charts/StackedBarChart";

type GreyFleetVehicleAnalysisChartsProps = {
    companyId?: string;
    departmentId?: string;
};
const GreyFleetVehicleAnalysisCharts = ({
    companyId,
    departmentId,
}: GreyFleetVehicleAnalysisChartsProps) => {
    const navigate = useNavigate();
    const handleClick = (chartType: GreyFleetVehicleAnalysisChartTypes) => {
        navigate(appPaths.adminAnalysisCharts("greyfleet", chartType));
    };

    // making the call here so that the dashboard does not make this call until
    // the grey fleet tab is selected.
    const data = useGreyFleetVehicleAnalysisCharts(companyId, departmentId);

    const hasNoData = (
        data: BarChartItem[] | PieChartItem[] | StackedBarChartItem[]
    ) => {
        for (const item of data) {
            if (item.value !== 0) {
                return false;
            }
        }
        return true;
    };

    return (
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            {data?.errorVehicleAnalysisData && (
                <FormMessageBox
                    title={""}
                    message={"Error loading vehicle analysis"}
                    variant={"error"}
                />
            )}
            {!data && <LoadingMessage />}

            {data && !data?.errorVehicleAnalysisData && (
                <>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.Co2Emissions)
                        }
                        title={
                            <p>
                                CO<sub>2</sub> Emissions
                            </p>
                        }
                        hasNoData={hasNoData(data.co2Emissions)}
                    >
                        <PieChart data={data.co2Emissions} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.FuelType)
                        }
                        title="Fuel Type"
                        hasNoData={hasNoData(data.fuelTypes)}
                    >
                        <BarChart data={data.fuelTypes} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.AgeRange)
                        }
                        title="Age range"
                        hasNoData={hasNoData(data.ageRange)}
                    >
                        <PieChart data={data.ageRange} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.Manufacturer)
                        }
                        title="Manufacturer Types"
                        hasNoData={hasNoData(data.manufacturerTypes)}
                    >
                        <PieChart data={data.manufacturerTypes} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.EuroRatings)
                        }
                        title="Euro Ratings"
                        hasNoData={hasNoData(data.euroRatings)}
                    >
                        <VerticalBarChart data={data.euroRatings} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.InsuranceTypes)
                        }
                        title="Insurance Types"
                        hasNoData={hasNoData(data.insuranceTypes)}
                    >
                        <PieChart data={data.insuranceTypes} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.BusinessCover)
                        }
                        title="Business Cover"
                        hasNoData={hasNoData(data.businessCover)}
                    >
                        <PieChart data={data.businessCover} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.EngineCapacity)
                        }
                        title="Engine Capacity"
                        hasNoData={hasNoData(data.engineCapacity)}
                    >
                        <PieChart data={data.engineCapacity} />
                    </ChartContainer>
                </>
            )}
        </div>
    );
};

export default GreyFleetVehicleAnalysisCharts;
