import { type ApiFileSafeItem } from "../../models/api/admin/fileSafe";
import TransferDetails from "./TransferDetails";
import AttachedFiles from "./AttachedFiles";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../Routes";
import { useState } from "react";
import ConfirmationDialog from "../ReusableComponents/ConfirmationDialog";
import useAuth from "../../auth";
import { isS2dUser } from "../../auth/userAccessHelpers";
import { TransferStatus } from "../../models/app/admin/fileSafe";

export type ViewTransferProps = {
    data: ApiFileSafeItem;
    handleDelete: () => void;
    handleFlagForDeletion: () => void;
    isFlaggingForDeletion: boolean;
    handleAllFilesDownload: (
        setIsDownloading: (value: boolean) => void
    ) => Promise<void>;
};

const ViewTransfer: React.FC<ViewTransferProps> = ({
    data,
    handleDelete,
    handleFlagForDeletion,
    isFlaggingForDeletion,
    handleAllFilesDownload,
}) => {
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { decodedToken, userDetails } = useAuth();

    // block the user from replying to themself
    const senderIsSelf = data.senderUser.userId === userDetails?.userId;
    const disableDeletionFlagButton = data.status === TransferStatus.MarkedForDeletion;

    return (
        <div className="mx-auto flex w-full max-w-3xl flex-col gap-6 ">
            <TransferDetails data={data} />
            <AttachedFiles data={data} />
            <div className="flex justify-end">
                <div className="flex gap-2">
                    <Button
                        variant="tertiary"
                        className=" text-S2D-error-40"
                        onClick={() => setDeleteDialogOpen(true)}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="tertiary"
                        onClick={handleFlagForDeletion}
                        disabled={isFlaggingForDeletion || disableDeletionFlagButton}
                    >
                        Flag for deletion
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => handleAllFilesDownload(setDownloading)}
                        loading={downloading}
                    >
                        Download all attachments
                    </Button>
                    {!isS2dUser(decodedToken?.role) && !senderIsSelf && (
                        <Button
                            variant="primary"
                            onClick={() =>
                                navigate(
                                    appPaths.adminNewTransfer(data.senderUser)
                                )
                            }
                        >
                            Reply
                        </Button>
                    )}
                </div>
            </div>
            <ConfirmationDialog
                open={isDeleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                title={"Delete transfer"}
                caption={"Are you sure you want to delete this transfer?"}
                onConfirm={() => handleDelete()}
            />
        </div>
    );
};

export default ViewTransfer;
