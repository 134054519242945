import FormMessageBox from "../FormMessageBox";

type BulkUploadFailureReasonsProps = {
    failureReasons: string[];
    failureRef: React.MutableRefObject<HTMLDivElement | null>;
};

const BulkUploadFailureReasons: React.FC<BulkUploadFailureReasonsProps> = ({
    failureReasons,
    failureRef,
}) => {
    const showFailures = !!failureReasons.length;
    return (
        <div ref={failureRef} className="flex w-full flex-col">
            {showFailures && (
                <FormMessageBox
                    title="Upload failed"
                    variant="error"
                    message={
                        <ul className="list-inside list-disc">
                            {failureReasons.map((reason) => (
                                <li>{reason}</li>
                            ))}
                        </ul>
                    }
                />
            )}
        </div>
    );
};

export default BulkUploadFailureReasons;
