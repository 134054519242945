import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormTextInput from "../FormTextInput";
import Button from "../Button";
import { useState } from "react";
import FormMessageBox from "../FormMessageBox";
import { Link } from "react-router-dom";
import { appPaths } from "../../Routes";
import {
    LogIn2FAFormValidationSchema,
    LogIn2FAFormValues,
} from "./LogIn2FAFormValues";
import Logo from "../../s2d-dark-logo.svg";

type LogIn2FAFormProps = {
    onSubmit: (
        data: LogIn2FAFormValues,
        callback: (error: boolean) => void
    ) => Promise<void>;
    isLoading: boolean;
};
const validationSchema = Yup.object().shape(LogIn2FAFormValidationSchema);

const LogIn2FAForm: React.FC<LogIn2FAFormProps> = ({ onSubmit, isLoading }) => {
    const [matchError, setMatchError] = useState(false);

    const { handleSubmit, formState, register } = useForm<LogIn2FAFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            userName: "",
            code: "",
        },
    });

    const handleLogIn = (data: LogIn2FAFormValues) => {
        onSubmit(data, setMatchError);
    };

    return (
        <div className="w-full max-w-lg rounded-sm bg-white p-8">
            <form onSubmit={handleSubmit(handleLogIn)}>
                <div className="flex flex-col items-center gap-4 align-top">
                    <img
                        src={Logo}
                        alt="Safe 2 Drive Logo"
                        className="max-w-sm"
                    />
                    <p className="pb-4 font-semibold">
                        2 FACTOR AUTHENTICATION
                    </p>
                    <p className="pb-4 text-sm">
                        We have sent you an email with a unique code.
                    </p>
                </div>

                {matchError && (
                    <FormMessageBox
                        variant="error"
                        title={"Invalid credentials"}
                        message={"Email/Code is not valid"}
                    />
                )}
                <FormTextInput
                    label="Email address"
                    required
                    id="userName"
                    placeholder="Enter email address"
                    error={formState.errors.userName}
                    {...register("userName")}
                />
                <FormTextInput
                    label="Code from email"
                    required
                    id="code"
                    placeholder="Enter code"
                    error={formState.errors.code}
                    {...register("code")}
                />
                <div className="mt-8 flex justify-end gap-4">
                    <Link to={appPaths.login}>
                        <Button type="button" variant="tertiary">
                            Cancel
                        </Button>
                    </Link>
                    <Button type="submit" loading={isLoading}>
                        Log in
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default LogIn2FAForm;
