import {
    ApiCompanyProfileStatsResponse,
    CompanyStatsCategories,
    type ApiCompanyStatsResponse,
} from "../../models/api/admin/companyStats";
import { useS2DApiSWR } from "../common/s2dApiSWR";
import { queryParamConnector } from "../../utils/queryParamHelpers";

export const useGetCompanyStats = (
    companyId?: string,
    departmentId?: string,
    year?: number,
    month?: number,
    // used for individual companies, company summary is not included
    option?: Omit<CompanyStatsCategories, "Company">
) => {
    const pathArray = [`api/companies/${companyId}/stats`];

    if (departmentId) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`DepartmentId=${departmentId}`);
    }
    if (year && month) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`Month.Year=${year}`);
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`Month.Month=${month}`);
    }
    if (option) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`Includes=${option}`);
    }

    const path = pathArray.join("");

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiCompanyProfileStatsResponse>(companyId ? path : null);

    // we want the data returned here to match the ApiCompanyStatsResponse model
    // since both are used in the same context
    let matchedData: ApiCompanyStatsResponse | undefined = undefined;
    if (data) {
        matchedData = {
            allCompaniesStats: null,
            companyDriversStats: data.drivers,
            companyGreyfleetStats: data.greyfleet,
            companyDeclarationsStats: data.declarations,
            companyCurrentChecksStats: data.currentChecks,
            companyUpcomingChecksStats: data.upcomingChecks,
        };
    }

    return {
        companyStatsData: matchedData,
        isCompanyStatsLoading: isLoading,
        companyStatsError: error,
        refetchCompanyStatsData: refetch,
    };
};

export const useGetAllCompanyStats = (
    year?: number,
    month?: number,
    option?: CompanyStatsCategories,
    disableRequest?: boolean
) => {
    const pathArray = [`api/companies/stats`];
    if (year && month) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`Month.Year=${year}`);
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`Month.Month=${month}`);
    }

    if (option) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`Includes=${option}`);
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiCompanyStatsResponse>(
            disableRequest ? null : pathArray.join("")
        );

    return {
        allCompanyStatsData: data,
        allCompanyStatsError: error,
        isAllCompanyStatsLoading: isLoading,
        refetchAllCompanyStats: refetch,
    };
};
