import { PencilSimple, Trash } from "phosphor-react";
import {
    DriverListOrderByOptions,
    type ApiDriverDetailsResponse,
} from "../../models/api/admin/driver";
import { type TableMenuItem } from "../ReusableComponents/Table/TableMenu";

export const driverListMenuItems = (
    data: ApiDriverDetailsResponse,
    handleTableMenuSelect: (
        data: ApiDriverDetailsResponse,
        key: DriverListMenuKeys
    ) => void
): TableMenuItem[] => {
    const menuItems = [
        {
            key: DriverListMenuKeys.editDriver,
            onClick: () =>
                handleTableMenuSelect(data, DriverListMenuKeys.editDriver),
            icon: <PencilSimple size={20} weight="fill" />,
            text: "Edit driver",
        }
    ];

    // If driver is not deleted add 'Delete Driver' menu item
    if (!data.deletedAt) {
        menuItems.push({
            key: DriverListMenuKeys.deleteDriver,
            onClick: () =>
                handleTableMenuSelect(data, DriverListMenuKeys.deleteDriver),
            icon: <Trash size={20} weight="fill" />,
            text: "Delete driver",
        });
    }

    return menuItems;
};

export enum DriverListMenuKeys {
    editDriver = "editDriver",
    deleteDriver = "deleteDriver",
}

export const driverListOrderByOptions = [
    DriverListOrderByOptions.LastName,
    DriverListOrderByOptions.Company,
    DriverListOrderByOptions.Department,
];