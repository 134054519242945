import { useGetCompanies } from "../../api/admin/company";
import { isS2dUser } from "../../auth/userAccessHelpers";
import { UseFormReturn } from "react-hook-form";
import { AddDriverFormValues } from "./AddDriverFormValues";
import { useEffect } from "react";
import { DriverValidationTypes } from "../../models/app/admin/company";
import useAuth from "../../auth";

export const useAddDriverCompanyInformation = (
    methods: UseFormReturn<AddDriverFormValues>
) => {
    const user = useAuth();
    const { companyList, isCompanyListLoading } = useGetCompanies(1, 100);

    const userCompanyId = user.userDetails?.companyId ?? null;
    const isClientUser = !isS2dUser(user.decodedToken?.role);

    // company id will be automatically set to the user's company
    // unless the user is an s2d member and has no associated company.
    useEffect(() => {
        if (isClientUser) {
            methods.setValue("companyId", userCompanyId);
        }
    }, [userCompanyId]);

    const companyDetails = companyList?.items.find(
        (company) => userCompanyId === company.companyId
    );
    const companyValidationPreference =
        companyDetails?.settings.edeclaration?.driverValidationType;

    // for users with an associate client, companyValidationPreference should be automatically set to that client's preference.
    // this will be ignored for s2d users.
    useEffect(() => {
        if (isClientUser) {
            methods.setValue(
                "validateDriverIdentityBy",
                companyValidationPreference ?? DriverValidationTypes.DateOfBirth
            );
        }
    }, [companyValidationPreference]);

    return {
        companyList,
        isCompanyListLoading,
        isClientUser,
    };
};
