import { UserRoles } from "../auth/decodeToken";
import { type FormDropdownOption } from "../components/FormDropdown";
import { addSpacesToCamelCase } from "../utils/addSpacesToCamelCase";
import { capitaliseWord } from "../utils/capitaliseWord";
import { GREAT_BRITAIN, NORTHERN_IRELAND } from "./countries";

// Options for companyUser Add/Edit forms
export const companyUserRoles: { value: UserRoles; label: string }[] = [
    { value: UserRoles.clientMember, label: "Member" },
    { value: UserRoles.clientAdmin, label: "Admin" },
];

// Options for staff Add/Edit forms
export const staffUserRoles: { value: UserRoles; label: string }[] = [
    { value: UserRoles.s2dMember, label: "Member" },
    { value: UserRoles.s2dAdmin, label: "Admin" },
];

export const yesNoOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
];
// ---------------------------------------------------------------------------------------------

export const expiryDateOptions: FormDropdownOption<string>[] = [
    { value: "1 day", label: "1 day" },
    { value: "1 week", label: "1 week" },
    { value: "1 month", label: "1 month" },
    { value: "3 months", label: "3 months" },
    { value: "6 months", label: "6 months" },
];

export const licenceCountryOriginOptions: FormDropdownOption<string>[] = [
    { value: GREAT_BRITAIN, label: GREAT_BRITAIN },
    { value: NORTHERN_IRELAND, label: NORTHERN_IRELAND },
    { value: "Foreign", label: "Foreign" },
];

// ----------------------------------------------------------------------------------------------
export const addNullDropdownOption = <TValue>(
    arr: FormDropdownOption<TValue>[],
    label?: string
) => {
    return [
        {
            label: label ? label : "Any",
            value: null,
        },
        ...arr,
    ];
};

export const createDropdownOptionsFromArray = (
    array: string[]
): FormDropdownOption<string>[] =>
    array.map((key) => ({
        label: capitaliseWord(addSpacesToCamelCase(key.toString())),
        value: key,
    }));
