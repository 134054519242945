import { BulkActionTypes } from "../../api/safe2Drive/dvlaBulkActions";
import { ApiBulkActionResponse } from "../../models/api/safe2DriveAdmin/dvlaBulkActions";
import { errorToast, successToast } from "../../toast";

type DvlaBulkActionResponseMessage = {
    count: number;
    reason: string;
    ids: string[];
};

export const handleDvlaBulkActionResponse = (
    type: BulkActionTypes,
    content: ApiBulkActionResponse
) => {
    if (content.totalSuccess) {
        successToast(
            <>
                <p>Successfully performed bulk action.</p>
                <p>
                    <b>Count:</b> {content.totalSuccess}
                </p>
            </>
        );
    }

    const {
        instantCheckFailureResponse,
        editCheckDateFailureResponse,
        disableFurtherChecksFailureResponse,
        markAsCheckedFailureResponse,
        markAsPendingFailureResponse,
        bulkChangeToManualFailureResponse,
    } = content;

    let failures: DvlaBulkActionResponseMessage[] = [];
    const handleAddToFailures = (failure: DvlaBulkActionResponseMessage) => {
        failures = [...failures, failure];
    };

    switch (type) {
        case BulkActionTypes.InstantCheck:
        case BulkActionTypes.RetryCheck:
            if (instantCheckFailureResponse.totalNullCountry) {
                handleAddToFailures({
                    count: instantCheckFailureResponse.totalNullCountry,
                    ids: instantCheckFailureResponse.nullCountryDriverIds,
                    reason: "The driver's licence does not specify a country of origin.",
                });
            }
            if (instantCheckFailureResponse.totalNonUkLicence) {
                handleAddToFailures({
                    count: instantCheckFailureResponse.totalNonUkLicence,
                    ids: instantCheckFailureResponse.nonUkLicenceDriverIds,
                    reason: "The driver's licence cannot be checked by the DVLA. The licence requires a manual check",
                });
            }
            if (instantCheckFailureResponse.totalManualCheck) {
                handleAddToFailures({
                    count: instantCheckFailureResponse.totalManualCheck,
                    ids: instantCheckFailureResponse.manualCheckDriverIds,
                    reason: "The driver's licence should be checked manually.",
                });
            }
            if (instantCheckFailureResponse.totalDisabledCheck) {
                handleAddToFailures({
                    count: instantCheckFailureResponse.totalDisabledCheck,
                    ids: instantCheckFailureResponse.disabledCheckDriverIds,
                    reason: "Checks are disabled for this driver.",
                });
            }
            if (instantCheckFailureResponse.totalNoPreviousFailure) {
                handleAddToFailures({
                    count: instantCheckFailureResponse.totalNoPreviousFailure,
                    ids: instantCheckFailureResponse.noPreviousFailureDriverIds,
                    reason: "No previous checks have been made on this driver.",
                });
            }
            if (instantCheckFailureResponse.totalInstantCheckFailures) {
                handleAddToFailures({
                    count: instantCheckFailureResponse.totalInstantCheckFailures,
                    ids: instantCheckFailureResponse.instantCheckFailureDriverIds,
                    reason: "Failed to check the licence data with the DVLA. Please check individually for more details.",
                });
            }
            break;
        case BulkActionTypes.EditCheckDate:
            if (editCheckDateFailureResponse.totalDisabledDvlaChecks) {
                handleAddToFailures({
                    count: editCheckDateFailureResponse.totalDisabledDvlaChecks,
                    ids: editCheckDateFailureResponse.disabledDvlaChecksDriverIds,
                    reason: "Checks are disabled for this driver.",
                });
            }
            if (editCheckDateFailureResponse.totalFailedDateChange) {
                handleAddToFailures({
                    count: editCheckDateFailureResponse.totalFailedDateChange,
                    ids: editCheckDateFailureResponse.failedDateChangeDriverIds,
                    reason: "Failed to change check date. Please try again later.",
                });
            }
            break;
        case BulkActionTypes.DisableFurtherChecks:
            if (
                disableFurtherChecksFailureResponse.totalChecksCurrentlyDisabled
            ) {
                handleAddToFailures({
                    count: disableFurtherChecksFailureResponse.totalChecksCurrentlyDisabled,
                    ids: disableFurtherChecksFailureResponse.checksCurrentlyDisabledDriverIds,
                    reason: "Checks are already disabled for this driver.",
                });
            }
            if (
                disableFurtherChecksFailureResponse.totalFailedDisableFurtherChecks
            ) {
                handleAddToFailures({
                    count: disableFurtherChecksFailureResponse.totalFailedDisableFurtherChecks,
                    ids: disableFurtherChecksFailureResponse.failedDisableFurtherChecksDriverIds,
                    reason: "Failed to disable checks for this driver. Please try again later.",
                });
            }
            break;
        case BulkActionTypes.MarkAsChecked:
            if (markAsCheckedFailureResponse.totalAutomaticChecks) {
                handleAddToFailures({
                    count: markAsCheckedFailureResponse.totalAutomaticChecks,
                    ids: markAsCheckedFailureResponse.automaticChecksDriverIds,
                    reason: "These drivers licences are checked automatically by the DVLA. Change to manual to update them manually.",
                });
            }
            if (markAsCheckedFailureResponse.totalDisabledChecks) {
                handleAddToFailures({
                    count: markAsCheckedFailureResponse.totalDisabledChecks,
                    ids: markAsCheckedFailureResponse.disabledChecksDriverIds,
                    reason: "Checks are disabled for this driver.",
                });
            }
            if (markAsCheckedFailureResponse.totalFailedMarkAsChecked) {
                handleAddToFailures({
                    count: markAsCheckedFailureResponse.totalFailedMarkAsChecked,
                    ids: markAsCheckedFailureResponse.failedMarkAsCheckedDriverIds,
                    reason: "Failed to mark driver as checked. Please try again later.",
                });
            }
            break;
        case BulkActionTypes.MarkAsPending:
            if (markAsPendingFailureResponse.totalAutomaticChecks) {
                handleAddToFailures({
                    count: markAsPendingFailureResponse.totalAutomaticChecks,
                    ids: markAsPendingFailureResponse.automaticChecksDriverIds,
                    reason: "These drivers licences are Pending automatically by the DVLA. Change to manual to update them manually.",
                });
            }
            if (markAsPendingFailureResponse.totalPendingCheck) {
                handleAddToFailures({
                    count: markAsPendingFailureResponse.totalPendingCheck,
                    ids: markAsPendingFailureResponse.pendingCheckDriverIds,
                    reason: "Checks are already pending for this driver.",
                });
            }
            // 16/10 typo in api response, here should be mark as pending
            if (markAsPendingFailureResponse.totalFailedMarkAsChecked) {
                handleAddToFailures({
                    count: markAsPendingFailureResponse.totalFailedMarkAsChecked,
                    ids: markAsPendingFailureResponse.failedMarkAsCheckedDriverIds,
                    reason: "Failed to mark driver as Pending. Please try again later.",
                });
            }
            break;
        case BulkActionTypes.ChangeToManual:
            if (bulkChangeToManualFailureResponse.totalManualChecks) {
                handleAddToFailures({
                    count: bulkChangeToManualFailureResponse.totalManualChecks,
                    ids: bulkChangeToManualFailureResponse.manualChecksDriverIds,
                    reason: "Checks are already manual for this driver.",
                });
            }
            if (bulkChangeToManualFailureResponse.totalFailedChangeToManual) {
                handleAddToFailures({
                    count: bulkChangeToManualFailureResponse.totalFailedChangeToManual,
                    ids: bulkChangeToManualFailureResponse.failedChangeToManualDriverIds,
                    reason: "Failed to change driver to manual. Please try again later.",
                });
            }
            break;
    }

    failures.forEach((failure) => {
        const toastContent: JSX.Element = (
            <>
                <p>
                    <b>Failed IDs:</b> {failure.ids.join(", ")}
                </p>
                <p>
                    <b>Reason:</b> {failure.reason}
                </p>
            </>
        );

        errorToast(toastContent, true);
    });
};
