import {
    Briefcase,
    CheckCircle,
    IdentificationCard,
    Warning,
    WarningOctagon,
} from "phosphor-react";
import StatisticSummaryBox from "../StatisticSummaryBox";
import { CompanyStatusFilters } from "../../models/api/admin/company";
import { AllCompaniesStats } from "../../models/api/admin/companyStats";

type Props = {
    data?: AllCompaniesStats;
    onLinkClick?: (status: CompanyStatusFilters) => void;
};
const CompanyBreakdown = ({ data, onLinkClick }: Props) => {
    return (
        <div className="flex w-full flex-col gap-4">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <StatisticSummaryBox
                    icon={Briefcase}
                    label="total clients"
                    statistic={data?.totalClients ?? "-"}
                />
                <StatisticSummaryBox
                    icon={IdentificationCard}
                    label="total drivers"
                    statistic={data?.totalDrivers ?? "-"}
                />
            </div>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <StatisticSummaryBox
                    icon={Briefcase}
                    label="non-api clients"
                    statistic={data?.totalNonApi ?? "-"}
                />
                <StatisticSummaryBox
                    icon={IdentificationCard}
                    label="api clients"
                    statistic={data?.totalApi ?? "-"}
                />
            </div>
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-3">
                <StatisticSummaryBox
                    icon={CheckCircle}
                    label="live clients"
                    onLinkClick={
                        onLinkClick ? () => onLinkClick("Live") : undefined
                    }
                    statistic={data?.totalLive ?? "-"}
                />
                <StatisticSummaryBox
                    icon={Warning}
                    label="disabled (non-payment)"
                    onLinkClick={
                        onLinkClick
                            ? () => onLinkClick("DisabledNonPayment")
                            : undefined
                    }
                    statistic={data?.totalDisabledNonPayment ?? "-"}
                />
                <StatisticSummaryBox
                    icon={WarningOctagon}
                    label="permanently disabled"
                    onLinkClick={
                        onLinkClick
                            ? () => onLinkClick("DisabledPermanent")
                            : undefined
                    }
                    statistic={data?.totalDisabledPermanently ?? "-"}
                />
            </div>
        </div>
    );
};

export default CompanyBreakdown;
