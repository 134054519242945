import { ApiEndorsementCodeResponse } from "../../models/api/admin/endorsementCodes";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useEndorsementCodes = () => {
    const { data, error, isLoading } = useS2DApiSWR<ApiEndorsementCodeResponse>(
        "api/endorsements/codes"
    );

    return {
        endorsementCodes: data,
        endorsementCodesError: error,
        endorsementCodesIsLoading: isLoading,
    };
};
