import { File, X } from "phosphor-react";
import { ApiDocumentResponse } from "../models/api/admin/document";
import Button from "./Button";

type DocumentItemProps = {
    document: ApiDocumentResponse;
    onDelete: (fileId: string) => void;
    showDeleteButton?: boolean;
};

const DocumentItem = ({ document, onDelete, showDeleteButton = true }: DocumentItemProps) => {
    return (
        <div className="flex items-center gap-4">
            <div className="flex items-center h-12 border border-S2D-neutral-80 rounded-sm overflow-hidden">
                <div className="p-4 bg-S2D-neutral-99">
                    <File
                        size="32"
                        className=" text-S2D-dark-green-30"
                        weight="duotone"
                    />
                </div>
                <div className="p-4 bg-S2D-light-green-80 flex gap-2 items-center overflow-hidden">
                    <div className="flex-1 text-ellipsis overflow-hidden whitespace-nowrap text-xs font-bold">
                        {document.fileName}
                    </div>
                    {showDeleteButton && (
                        <button
                            className="flex-none"
                            onClick={() => onDelete(document.fileId)}
                        >
                            <X />
                            <span className="sr-only">
                                Delete document {document.fileName}
                            </span>
                        </button>
                    )}
                </div>
            </div>
            <a href={document.documentUrl} target="_blank">
                <Button variant="tertiary">View</Button>
            </a>
        </div>
    );
};

export default DocumentItem;
