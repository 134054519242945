import { useParams } from "react-router-dom";
import EditableContentBlock from "../../EditableContentBlock";
import { useGetTheme, useModifyTheme } from "../../../api/admin/themes";
import useAuth from "../../../auth";
import { hasClientManagementClaim } from "../../../auth/userAccessHelpers";
import NonEditableThemeView from "./NonEditableThemeView";
import CompanyAppearanceForm from "./CompanyAppearanceForm";
import { errorToast, successToast } from "../../../toast";
import {
    capitalisedTermForCompany,
    termForCompany,
} from "../../../copy/sharedCopy";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { EditCompanyAppearanceSchema } from "./companyAppearanceFormValues";
import { useEffect } from "react";
import { ApiCompanyThemeDto } from "../../../models/api/admin/themes";

type PageParams = {
    id: string;
};

const CompanyAppearanceTab: React.FC = () => {
    const { id } = useParams<PageParams>();
    const { themeData, themeError, isLoadingTheme, refetchTheme } =
        useGetTheme(id);
    const { editTheme, isEditingTheme, deleteTheme, isDeletingTheme } =
        useModifyTheme();

    const { decodedToken } = useAuth();
    const canEdit = hasClientManagementClaim(decodedToken);

    const validationSchema = yup.object().shape(EditCompanyAppearanceSchema);
    const methods = useForm<ApiCompanyThemeDto>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: themeData,
    });

    useEffect(() => methods.reset(themeData), [themeData]);

    const handleEditTheme = async (
        data: ApiCompanyThemeDto,
        callback: (value: boolean) => void
    ) => {
        if (!id) {
            errorToast(`${capitalisedTermForCompany} not found.`);
            return;
        }

        const response = await editTheme(id, data);
        if (!response.success) {
            errorToast(`Failed to update ${termForCompany} theme.`);
            return;
        }
        successToast(`Successfully edited ${termForCompany} theme.`);
        callback(false);
        refetchTheme();
    };

    const handleResetTheme = async (callback: (value: boolean) => void) => {
        if (!id) {
            errorToast(`${capitalisedTermForCompany} not found.`);
            return;
        }

        const response = await deleteTheme(id);
        if (!response.success) {
            errorToast(`Failed to update ${termForCompany} theme.`);
            return;
        }
        successToast(`Successfully reset ${termForCompany} theme.`);
        callback(false);
        refetchTheme();
    };

    return (
        <EditableContentBlock
            title="Colour settings"
            onClosed={() => methods.reset(themeData)}
            disableEdit={!canEdit}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    <div className="p-6">
                        {!isEdit && (
                            <NonEditableThemeView
                                themeData={themeData}
                                themeError={themeError}
                                isLoadingTheme={isLoadingTheme}
                            />
                        )}

                        {isEdit && (
                            <CompanyAppearanceForm
                                methods={methods}
                                onSubmit={handleEditTheme}
                                isSubmitting={isEditingTheme}
                                onReset={handleResetTheme}
                                isResetting={isDeletingTheme}
                                setIsEdit={setIsEdit}
                            />
                        )}
                    </div>
                </>
            )}
        </EditableContentBlock>
    );
};

export default CompanyAppearanceTab;
