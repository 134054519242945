import { CurrencyGbp } from "phosphor-react";
import { UseFormReturn } from "react-hook-form";
import FormTextInput from "../../../../FormTextInput";
import { EditCheckPricingFormValues } from "./useEditCheckPricingForm";

type CompanyCheckPricingFieldsProps = {
    methods: UseFormReturn<EditCheckPricingFormValues>;
};

const CompanyCheckPricingFields: React.FC<CompanyCheckPricingFieldsProps> = ({
    methods,
}) => {
    const {
        register,
        formState: { errors },
    } = methods;

    return (
        <>
            <FormTextInput
                label="Licence check price"
                placeholder="00.00"
                type="number"
                step={0.01}
                onWheel={(e) => e.currentTarget.blur()} // prevents scroll changing value
                min="0"
                id="pricing.licence"
                error={errors.pricing?.licenceCheckPrice}
                {...register("pricing.licenceCheckPrice")}
                StartAdornmentIcon={CurrencyGbp}
            />
            <FormTextInput
                label="Manual check price"
                placeholder="00.00"
                type="number"
                step={0.01}
                onWheel={(e) => e.currentTarget.blur()}
                min="0"
                id="pricing.manual"
                error={errors.pricing?.manualCheckPrice}
                {...register("pricing.manualCheckPrice")}
                StartAdornmentIcon={CurrencyGbp}
            />
            <FormTextInput
                label="Irish licence check price"
                placeholder="00.00"
                type="number"
                step={0.01}
                onWheel={(e) => e.currentTarget.blur()}
                min="0"
                id="pricing.irish"
                error={errors.pricing?.irishLicenceCheckPrice}
                {...register("pricing.irishLicenceCheckPrice")}
                StartAdornmentIcon={CurrencyGbp}
            />
            <FormTextInput
                label="Foreign licence check price"
                placeholder="00.00"
                type="number"
                step={0.01}
                onWheel={(e) => e.currentTarget.blur()}
                min="0"
                id="pricing.foreign"
                error={errors.pricing?.foreignLicenceCheckPrice}
                {...register("pricing.foreignLicenceCheckPrice")}
                StartAdornmentIcon={CurrencyGbp}
            />
            <FormTextInput
                label="Grey fleet check price"
                placeholder="00.00"
                type="number"
                step={0.01}
                onWheel={(e) => e.currentTarget.blur()}
                min="0"
                id="pricing.greyFleet"
                error={errors.pricing?.greyFleetCheckPrice}
                {...register("pricing.greyFleetCheckPrice")}
                StartAdornmentIcon={CurrencyGbp}
            />
        </>
    );
};

export default CompanyCheckPricingFields;
