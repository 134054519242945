import FormMessageBox from "../FormMessageBox";
import EDeclarationFormLayout from "./EDeclarationFormLayout";
import { EDeclarationFormValues } from "./EDeclarationFormValues";

type ForeignLicenceConfirmationScreenProps = {
    values: EDeclarationFormValues;
};

const ForeignLicenceConfirmationScreen: React.FC<
    ForeignLicenceConfirmationScreenProps
> = ({ values }) => {
    const licenceType =
        values.licenceCountryOrigin === "Foreign"
            ? values.licenceCountryOrigin
            : "Northern Irish";
    return (
        <EDeclarationFormLayout
            title="E-Declaration"
            subtitle="E-declaration process cancelled"
            content={
                <>
                    <FormMessageBox
                        title={`You have declared you are a ${licenceType} Licence holder.`}
                        message="An email has been sent to the email address you've provided with an attached Paper Declaration document and details of how to complete and return the declaration. If you do not see the email in a few minutes, check your 'junk mail' and 'spam' folders. You can now close this window."
                        variant="success"
                    />
                    <p>
                        If you made this step by mistake please contact your
                        employer.
                    </p>
                </>
            }
        />
    );
};

export default ForeignLicenceConfirmationScreen;
