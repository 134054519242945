import { debouncedErrorToast, errorToast } from "../../toast";
import { S2DApiErrorResponse } from "./apiResponse";

class ApiError extends Error {
    statusCode?: number;
    userMessages: string[] = [];
    isApiError = true;

    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}

export const buildApiError = async (
    method: string,
    url: string,
    response: Response,
    path?: string,
    ignoreToastHttpCodes?: number[]
) => {
    const error = new ApiError(`Error calling ${method} ${url}.`);
    error.statusCode = response.status;

    if (error.statusCode === 401) {
        // Debouncing to prevent multiple errors when unauthenticated
        debouncedErrorToast("You do not have access to this page. Please log in to continue.");
        return error;
    }

    try {
        const responseContent: S2DApiErrorResponse = await response.json();
        error.userMessages = parseErrorMessages(responseContent);
    } catch (_) {
        // Do nothing, we'll make do with just the main error message.
    }

    const shouldIgnore = ignoreToastHttpCodes ?? (error.statusCode === 401 ? [401] : []);

    if (error.userMessages.length > 0) {
        for (const message of error.userMessages) {
            if (!shouldIgnore.includes(error.statusCode)) {
                errorToast(`${message}`);
            }
        }
    } else if(!ignoreToastHttpCodes?.includes(response.status) &&
                response.status >= 400 &&
                response.status != 404) {
        errorToast(
            `There was a problem fetching '${path}'. It responded with status ${response.status}.`
        );
    }

    return error;
};

export const isApiError = (error: unknown): error is ApiError => {
    return (error as ApiError).isApiError !== undefined;
};

const parseErrorMessages = (apiError: S2DApiErrorResponse): string[] => {
    const messages: string[] = [];

    if (apiError.message) {
        messages.push(apiError.message);
    }

    const errors = apiError.errors;

    if (errors) {
        Object.keys(errors).forEach((errorKey) => {
            const errorMessages = errors[errorKey];
            messages.push(...errorMessages);
        });
    }

    return messages;
};

export default ApiError;
