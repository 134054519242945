import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../auth";
import LogInForm from "../../components/LogInForm/LogInForm";
import { LogInFormValues } from "../../components/LogInForm/LogInFormValues";
import { appPaths } from "../../Routes";

const LogInPage = () => {
    const { token, authorize, isAuthorizing } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate(appPaths.dashboard);
        }
    }, [token]);

    const handle2fa = () => {
        navigate(appPaths.login2fa);
    };

    const handleSubmit = async (
        data: LogInFormValues,
        callback: (error: boolean) => void
    ) => {
        const res = await authorize(data, handle2fa);
        if (res.success) {
            callback(false);
        } else {
            callback(true);
        }
    };

    return (
        <div className="flex h-screen w-screen items-center justify-center bg-S2D-surface-light-3">
            <LogInForm onSubmit={handleSubmit} isLoading={isAuthorizing} />
        </div>
    );
};

export default LogInPage;
