import { useEffect, useState } from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import AccordionItem from "../AccordionItem";
import FormDateInput from "../FormDateInput";
import FormDocumentUpload from "../FormDocumentUpload/FormDocumentUpload";
import FormDropdown, { FormDropdownOption } from "../FormDropdown";
import { AddDriverFormValues } from "./AddDriverFormValues";
import { DocumentBase64, DocumentTypes } from "../../models/app/admin/document";
import FileIcon from "../ReusableComponents/FileIcon";
import FormInputMessage from "../FormInputError";
import { DeclarationType } from "../../models/app/eDeclarations";
import { initDocData } from "./useDriverDocumentValidation";
import FormMessageBox from "../FormMessageBox";
import { canEditPii, hasClientManagementAccess } from "../../auth/userAccessHelpers";
import useAuth from "../../auth";

type DriverDocumentFieldsProps = {
    // this file uses two instances of hook form - one for the full form and one for documents only
    // validate a single document before adding it to the entire form.
    methods: UseFormReturn<AddDriverFormValues>;
    documentMethods: UseFormReturn<DocumentBase64>;
    savedDocuments: DocumentBase64[];
    notSubmittedError: boolean;
    setNotSubmittedError: (value: boolean) => void;
    documentTypeError: boolean;
    declarationType: DeclarationType;
};

const today = new Date();

const DriverDocumentFields: React.FC<DriverDocumentFieldsProps> = ({
    methods,
    documentMethods,
    savedDocuments,
    notSubmittedError,
    setNotSubmittedError,
    documentTypeError,
    declarationType,
}) => {
    const auth = useAuth();
    const isClientUserWithPii = hasClientManagementAccess(auth.decodedToken) && canEditPii(auth.decodedToken);

    const documentTypeOptions: FormDropdownOption<DocumentTypes>[] = [
        {
            label: "Driver declaration",
            value: DocumentTypes.DriverDeclaration,
            disabled: declarationType === DeclarationType.EDeclaration,
        },
        {
            label: "Other document",
            value: DocumentTypes.Other,
        },
    ];

    const control = documentMethods.control,
        currentDocumentType = useWatch({
            control,
            name: "documentType",
        });

    const handleDocumentType = (documentType: number) => {
        documentMethods.setValue(`signedDate`, null);
        documentMethods.setValue(`documentType`, documentType);
        // remove any error if we're switching to "other documents"
        if (documentType === DocumentTypes.Other) {
            documentMethods.trigger("signedDate");
        }
    };

    useEffect(() => {
        if (
            (currentDocumentType === DocumentTypes.DriverDeclaration &&
                declarationType === DeclarationType.EDeclaration) ||
            isClientUserWithPii
        ) {
            handleDocumentType(DocumentTypes.Other);
        }
    }, [declarationType, isClientUserWithPii]);

    const handleSignedDate = (value: Date | null) => {
        const { trigger, setValue } = documentMethods;

        setValue(`signedDate`, value as Date);
        trigger(); // trigger re-validation upon setting date
    };
    const [fileObj, setFileObj] = useState<File[]>([]);

    const handleCurrentDoc = (
        documentBase64: string,
        fileType: DocumentBase64["fileType"],
        fileName: string,
        file?: File
    ) => {
        const { setValue } = documentMethods;
        setValue("documentBase64", documentBase64);
        setValue("fileName", fileName);
        setValue("fileType", fileType);
        if (file) {
            setFileObj((prevFileObj) => {
                return [...prevFileObj, file];
            });
        }
    };

    const handleRemoveDoc = (index: number) => {
        const prevDocuments = methods.getValues("documents");
        methods.setValue("documents", [
            ...prevDocuments.slice(0, index),
            ...prevDocuments.slice(index + 1, prevDocuments.length),
        ]);
        setFileObj((prevFileObj) => {
            return [
                ...prevFileObj.slice(0, index),
                ...prevFileObj.slice(index + 1, fileObj.length),
            ];
        });
    };

    const handleCancelAddDoc = () => {
        const currentDocument = documentMethods.getValues();
        documentMethods.reset({
            ...initDocData,
            signedDate: currentDocument.signedDate,
            documentType: currentDocument.documentType,
        });
        setFileObj((prevFileObj) => {
            return [...prevFileObj.slice(0, fileObj.length - 1)];
        });
    };

    const handleSubmit = () => {
        const prevDocuments = methods.getValues("documents");
        const currentDocument = documentMethods.getValues();
        methods.setValue("documents", [...prevDocuments, currentDocument]);
        setNotSubmittedError(false);
    };

    return (
        <AccordionItem
            title="Add documents"
            error={notSubmittedError || documentTypeError}
        >
            <div className="relative flex flex-col gap-4">
                {!isClientUserWithPii && (
                    <Controller
                        name="documentType"
                        control={documentMethods.control}
                        render={({ field: { value } }) => (
                            <FormDropdown
                                label="Document type"
                                options={documentTypeOptions}
                                pleaseSelectText="Select document type"
                                value={value}
                                onChange={handleDocumentType}
                            />
                        )}
                    />
                )}
                {currentDocumentType === DocumentTypes.DriverDeclaration && (
                    <Controller
                        name="signedDate"
                        control={documentMethods.control}
                        render={({ field: { value } }) => (
                            <FormDateInput
                                required
                                id="signedDate"
                                label="Date signed"
                                value={value ?? null}
                                onChange={handleSignedDate}
                                maxDate={today}
                                error={
                                    documentMethods.formState.errors.signedDate
                                }
                            />
                        )}
                    />
                )}
                <FormDocumentUpload
                    onAutoAdd={handleSubmit}
                    handleSetData={handleCurrentDoc}
                    handleCancelAddDoc={handleCancelAddDoc}
                    disabled={!!documentMethods.formState.errors.signedDate}
                />
                {notSubmittedError && (
                    <FormInputMessage
                        error={"Please add the document before continuing"}
                    />
                )}
                <div className="flex flex-wrap gap-4">
                    {savedDocuments.map((doc, index) => (
                        <div key={doc.documentBase64}>
                            <FileIcon
                                fileName={doc.fileName}
                                fileObj={fileObj[index]}
                                onReset={() => handleRemoveDoc(index)}
                            />
                        </div>
                    ))}
                </div>
                {documentTypeError && (
                    <FormMessageBox
                        variant="error"
                        title="Incorrect document type selected"
                        message={
                            <>
                                <p>
                                    The declaration type for this driver is set
                                    to <b>E-Declaration</b> however you have
                                    uploaded a <b>Paper Declaration</b>{" "}
                                    document.
                                </p>

                                <p>
                                    Please either remove the document or change
                                    the declaration type for this driver.
                                </p>
                            </>
                        }
                    />
                )}
            </div>
        </AccordionItem>
    );
};

export default DriverDocumentFields;
