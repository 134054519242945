import React, { useEffect, useState } from "react";
import { appPaths } from "../../Routes";
import { ApiDriverByIdResponse } from "../../models/api/admin/driver";
import IconButton from "../../components/IconButton";
import { useNavigate } from "react-router-dom";
import {
    Trash,
    ClockCounterClockwise,
    Download,
    Printer,
} from "phosphor-react";
import { errorToast, successToast } from "../../toast";
import { S2DApiFetcherResponse } from "../../api/common/apiResponse";
import ProfileLabel from "../ReusableComponents/ProfileLabel";
import DeleteConfirmation from "../ReusableComponents/DeleteConfirmation";

type DriverProfileHeaderProps = {
    firstName: string;
    lastName: string;
    company: ApiDriverByIdResponse["company"];
    id: string | undefined;
    isDeletingDriver: boolean;
    deleteDriver: (id: string) => Promise<S2DApiFetcherResponse<string>>;
    handleDownloadPdfReport?: () => void;
    handlePrintPdfReport?: () => void;
    isLoadingPdfReport?: boolean;
    printing?: boolean;
    isDriverDeleted: boolean;
};

const DriverProfileHeader: React.FC<DriverProfileHeaderProps> = ({
    firstName,
    lastName,
    company,
    id,
    isDeletingDriver,
    deleteDriver,
    handleDownloadPdfReport,
    handlePrintPdfReport,
    isLoadingPdfReport,
    printing,
    isDriverDeleted,
}) => {
    const navigate = useNavigate();
    const [isAuditPage, setIsAuditPage] = useState<boolean>(false);

    //if the url contains the word audit, then set isAuditPage to true
    useEffect(() => {
        if (window.location.href.includes("audit")) {
            setIsAuditPage(true);
        }
    }, []);

    const [
        deleteDriverConfirmationDialogIsOpen,
        setDeleteDriverConfirmationDialogIsOpen,
    ] = useState(false);

    const handleDeleteDriver = async () => {
        if (!id) {
            errorToast("There was an error");
            return;
        }
        const response = await deleteDriver(id);

        if (response.success) {
            successToast("Driver deleted successfully");
            navigate(appPaths.adminDrivers);
        }
    };

    return (
        <>
            <div className="mb-12 flex flex-wrap justify-between bg-white">
                <ProfileLabel
                    firstName={firstName}
                    lastName={lastName}
                    title={`${firstName} ${lastName}`}
                    subtitle={company.companyName ?? undefined}
                    hasLargeText
                />
                <div className="flex flex-row items-start gap-5">
                    <IconButton
                        label="Download driver"
                        icon={Download}
                        loading={isLoadingPdfReport}
                        onClick={handleDownloadPdfReport}
                    />
                    <IconButton
                        label="Print driver"
                        icon={Printer}
                        loading={printing}
                        onClick={handlePrintPdfReport}
                    />
                    {id && (
                        <IconButton
                            label="Audit driver"
                            icon={ClockCounterClockwise}
                            onClick={() => {
                                isAuditPage
                                    ? navigate(appPaths.adminEditDriver(id))
                                    : navigate(appPaths.adminAuditDriver(id));
                            }}
                            className={
                                isAuditPage
                                    ? "border-2 border-S2D-light-green-80 bg-S2D-dark-green-70.1"
                                    : ""
                            }
                        />
                    )}
                    {!isDriverDeleted && (
                        <IconButton
                            label="Delete driver"
                            icon={Trash}
                            onClick={() =>
                                setDeleteDriverConfirmationDialogIsOpen(true)
                            }
                        />
                    )}
                </div>
            </div>
            <DeleteConfirmation
                isOpen={deleteDriverConfirmationDialogIsOpen}
                onClose={() => setDeleteDriverConfirmationDialogIsOpen(false)}
                title={"You are about to delete a driver!"}
                onSubmit={handleDeleteDriver}
                isLoading={isDeletingDriver}
            />
        </>
    );
};

export default DriverProfileHeader;
