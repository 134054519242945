import {
    AnalysisChartType,
    GreyFleetVehicleAnalysisCharts,
} from "../../models/app/admin/analysisChart";
import TableFiltersAndActions from "../ReusableComponents/TableFiltersAndActions";
import { type FilterOption } from "../ReusableComponents/FilterDropdown";
import { capitaliseWord } from "../../utils/capitaliseWord";
import { addSpacesToCamelCase } from "../../utils/addSpacesToCamelCase";
import { type VehicleAnalysisFilterKey } from "./useVehicleChartAnalysisFilters";
import {
    AgeRangeOptions,
    AgeRangeValues,
    BusinessCoverOptions,
    Co2EmissionsOptions,
    Co2EmissionsValues,
    EngineCapacityOptions,
    EngineCapacityValues,
    EuroRatingOptions,
    EuroRatingValues,
    FuelTypeOptions,
    InsuranceTypeOptions,
} from "../../models/app/admin/vehicleAnalysis";

type VehicleChartAnalysisProps = {
    chartType: GreyFleetVehicleAnalysisCharts;
    handleApplyFilter: (key: VehicleAnalysisFilterKey | string | null) => void;
    selectedDropdownFilter: VehicleAnalysisFilterKey | string | null;
    customOptions: FilterOption<string>[];
};

const VehicleChartAnalysisFilters: React.FC<VehicleChartAnalysisProps> = ({
    chartType,
    handleApplyFilter,
    selectedDropdownFilter,
    customOptions,
}) => {
    // manufacturer provides a single string value
    // everything else provides a relevant key for that chart type
    let filterOptions: FilterOption<VehicleAnalysisFilterKey | string>[];
    switch (chartType) {
        case AnalysisChartType.Co2Emissions:
            filterOptions = (
                Object.keys(Co2EmissionsOptions) as Array<
                    keyof typeof Co2EmissionsOptions
                >
            ).map((key) => ({
                value: key as Co2EmissionsOptions,
                label: Co2EmissionsValues[key],
            }));
            break;
        case AnalysisChartType.FuelType:
            filterOptions = (
                Object.keys(FuelTypeOptions) as Array<
                    keyof typeof FuelTypeOptions
                >
            ).map((key) => ({
                value: key as FuelTypeOptions,
                label: capitaliseWord(addSpacesToCamelCase(key)),
            }));
            break;
        case AnalysisChartType.AgeRange:
            filterOptions = (
                Object.keys(AgeRangeOptions) as Array<
                    keyof typeof AgeRangeOptions
                >
            ).map((key) => ({
                value: key as AgeRangeOptions,
                label: AgeRangeValues[key],
            }));
            break;
        case AnalysisChartType.Manufacturer:
            filterOptions = customOptions;
            break;
        case AnalysisChartType.EuroRatings:
            filterOptions = (
                Object.keys(EuroRatingOptions) as Array<
                    keyof typeof EuroRatingOptions
                >
            ).map((key) => ({
                value: key as EuroRatingOptions,
                label: EuroRatingValues[key],
            }));
            break;
        case AnalysisChartType.InsuranceTypes:
            filterOptions = (
                Object.keys(InsuranceTypeOptions) as Array<
                    keyof typeof InsuranceTypeOptions
                >
            ).map((key) => ({
                value: key as InsuranceTypeOptions,
                label: capitaliseWord(addSpacesToCamelCase(key)),
            }));
            break;
        case AnalysisChartType.BusinessCover:
            filterOptions = (
                Object.keys(BusinessCoverOptions) as Array<
                    keyof typeof BusinessCoverOptions
                >
            ).map((key) => ({
                value: key as BusinessCoverOptions,
                label: capitaliseWord(addSpacesToCamelCase(key)),
            }));
            break;
        case AnalysisChartType.EngineCapacity:
            filterOptions = (
                Object.keys(EngineCapacityOptions) as Array<
                    keyof typeof EngineCapacityOptions
                >
            ).map((key) => ({
                value: key as EngineCapacityOptions,
                label: EngineCapacityValues[key],
            }));
            break;
        default:
            filterOptions = [];
            break;
    }

    return (
        <TableFiltersAndActions
            selectedDropdownFilter={selectedDropdownFilter}
            dropdownFilterOptions={filterOptions}
            handleDropdownFilterChange={(filter) => handleApplyFilter(filter)}
            dropdownFilterLabel={"Filters"}
        />
    );
};

export default VehicleChartAnalysisFilters;
