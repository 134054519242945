import { useLocation } from "react-router-dom";
import useDriversPage from "../pageHooks/useDriversPage";
import DriversPageContent from "./DriversPageContent";

export default function AdminProfessionalDrivers() {
    const location = useLocation();
    const {
        filterModalOpen,
        setFilterModalOpen,
        driversList,
        fetchDriversError,
        appliedFilter,
        setAppliedFilter,
        paginationLocalStorage,
        handleFetchDrivers,
        isFetchingDrivers,
        driverPageLocation,
        driverStatsData,
        isLoadingDriverStats,
        handleCompanyChange,
        handleDepartmentChange,
        selectedCompany,
        selectedDepartment,
        query,
        setQuery,
    } = useDriversPage(location.pathname);

    return (
        <DriversPageContent
            title="Professional Drivers"
            driverPageLocation={driverPageLocation}
            filterModalOpen={filterModalOpen}
            setFilterModalOpen={setFilterModalOpen}
            appliedFilter={appliedFilter}
            setAppliedFilter={setAppliedFilter}
            paginationLocalStorage={paginationLocalStorage}
            handleFetchDrivers={handleFetchDrivers}
            isFetchingDrivers={isFetchingDrivers}
            driversList={driversList}
            fetchDriversError={fetchDriversError}
            driversStats={driverStatsData}
            isGettingAllDriversStats={isLoadingDriverStats}
            handleCompanyChange={handleCompanyChange}
            handleDepartmentChange={handleDepartmentChange}
            selectedCompany={selectedCompany}
            selectedDepartment={selectedDepartment}
            query={query}
            setQuery={setQuery}
        />
    );
}
