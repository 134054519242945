import { useNavigate } from "react-router-dom";
import { type ApiDriverByIdResponse } from "../../models/api/admin/driver";
import { appPaths } from "../../Routes";
import DeclarationStatus from "./DeclarationStatus/DeclarationStatus";
import GreyFleet from "./GreyFleet";
import GreyFleetPending from "./GreyFleet/GreyFleetPending";
import LicenceChecks from "./LicenceChecks/LicenceChecks";
import LicenceStatus from "./LicenseStatus";
import PenaltyPoints from "./PenaltyPoints";
import Divider from "../ReusableComponents/Divider";
import { useCompany } from "../../api/admin/company";

type DriverRiskProfileProps = {
    driverId: string | undefined;
    driverData?: ApiDriverByIdResponse;
    isLoading: boolean;
    refetch: () => void;
    handleScrollToDocumentUpload: () => void;
};

const DriverRiskProfile = ({
    driverId,
    driverData,
    isLoading,
    refetch,
    handleScrollToDocumentUpload,
}: DriverRiskProfileProps) => {
    const navigate = useNavigate();
    const { companyData } = useCompany(
        driverData?.company.companyId ?? undefined
    );
    if (isLoading) return <p>Loading...</p>;

    if (!driverData) {
        navigate(appPaths.fourOFour);
        return <></>;
    }

    return (
        <>
            <Divider text="Risk Profile" />
            <div className="grid grid-cols-1 gap-5 pb-5 lg:grid-cols-2">
                {driverData.pointsTotal !== null && (
                    <PenaltyPoints
                        pointsTotal={driverData.pointsTotal}
                        name={driverData.riskProfile?.name}
                        severityLevel={driverData.riskProfile?.severityLevel}
                        range={driverData.riskProfile?.range}
                        endorsements={driverData.endorsements ?? undefined}
                        companyData={companyData}
                    />
                )}

                {driverData?.licenceStatus && (
                    <LicenceStatus
                        licenceNumber={driverData.licenceNumber}
                        licenceCountryOrigin={driverData.licenceCountryOrigin}
                        licenceExpiryDate={driverData.licenceExpiryDate}
                        licenceStatus={driverData.licenceStatus}
                        licenceType={driverData.licenceType}
                        licenceStatusQualifier={
                            driverData.licenceStatusQualifier
                        }
                        licenceExpiryReminderScheduledDate={
                            driverData.licenceExpiryReminderScheduledDate
                        }
                        disqualificationDate={driverData.disqualificationDate}
                        disqualifiedUntil={driverData.disqualifiedUntil}
                        isLicenceDueToExpire={
                            driverData.isLicenceDueToExpire ?? false
                        }
                        driverId={driverId}
                    />
                )}
            </div>
            <div className="grid grid-cols-1 gap-5 pb-12 md:grid-cols-2 xl:grid-cols-3">
                <DeclarationStatus
                    mandateDetails={driverData.mandateDetails}
                    driverData={driverData}
                    handleScrollToDocumentUpload={handleScrollToDocumentUpload}
                    companyData={companyData}
                />

                <LicenceChecks
                    driverId={driverData.driverId}
                    checkStatus={driverData.checkStatus}
                    lastCheckDate={driverData.lastCheckDate}
                    nextCheckDate={driverData.nextCheckDate}
                    checkFailureReason={driverData.checkFailureReason}
                    declarationStatus={
                        driverData.mandateDetails?.declarationStatus
                    }
                    isCheckDue={driverData.isCheckDue ?? false}
                    licenceStatus={driverData.licenceStatus}
                    licenceNumber={driverData.licenceNumber || ""}
                    isManual={driverData.isManualCheck ?? false}
                    refetchDriver={refetch}
                />

                {driverData.vehicles &&
                    driverData.vehicles?.length > 0 &&
                    driverData.vehicles?.map((vehicle) => (
                        <GreyFleet
                            key={vehicle.vehicleId}
                            vehicle={vehicle}
                            hasEmailAddress={
                                driverData.emailAddress ? true : false
                            }
                        />
                    ))}
                {driverData.greyFleetInvite?.greyFleetInviteId &&
                    !driverData.vehicles?.length && (
                        <GreyFleetPending
                            greyFleetInvite={driverData.greyFleetInvite}
                            key={driverData.greyFleetInvite.greyFleetInviteId}
                        />
                    )}
            </div>
        </>
    );
};

export default DriverRiskProfile;
