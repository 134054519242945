import { DriverTypes } from "../../api/admin/driver";
import { GreyFleetReportTypes } from "../../api/reporting";
import { DriverAddress } from "../../shared/address";
import { DeclarationType } from "../eDeclarations";
import { DriverValidationTypes } from "./company";
import { DocumentBase64 } from "./document";
import {
    FilterEmploymentDto,
    FilterPersonalInformationDto,
    FilterDriverAddressDto,
    FilterLinkedDriverDto,
    FilterLicenceInformationDto,
    FilterLicenceChecksDto,
    FilterGreyFleetDto,
    FilterDeclarationsDto,
    FilterDriverAnalysisDto,
    FilterLicenceChangesDto,
    FilterDriverLicenceAnalysisDto,
    FilterVehicleAnalysisDto,
} from "./driverFilters";

export type RiskProfiles = {
    severityLevel: RiskProfileLevels; // the 'range' key used by the UI
    name: string | null; // the user set name of this range/level
    lowerThreshold: number | null; // the penalty point value for this range/level
    checkFrequency: number | null; // how often drivers in this range/level should be checked
};

export enum RiskProfileLevels {
    Low = "Low",
    Medium = "Medium",
    High = "High",
    PrevDisqualified = "PreviouslyDisqualified",
    Disqualified = "Disqualified",
    Critical = "Critical",
}

export type GetAllDriversDto = {
    pageNumber: number;
    pageSize: number;
    orderBy?: string;
    driverIds?: string[];
    riskProfiles?: RiskProfileLevels[];
    greyFleet?: boolean | null;
    company: FilterEmploymentDto;
    personalInformation?: FilterPersonalInformationDto;
    driverAddress?: FilterDriverAddressDto;
    linkedDriver?: FilterLinkedDriverDto;
    licence?: FilterLicenceInformationDto;
    dvlaQuery?: FilterLicenceChecksDto;
    greyFleetQuery?: FilterGreyFleetDto;
    declarationsQuery?: FilterDeclarationsDto;
    driverLicenceAnalysis?: FilterDriverLicenceAnalysisDto;
    vehicleAnalysis?: FilterVehicleAnalysisDto;
    driverAnalysis?: FilterDriverAnalysisDto;
    licenceChanges?: FilterLicenceChangesDto;
    professional?: boolean | null;
};

export type SeverityLevelPointsRange = {
    from: number | null;
    to: number | null;
};

export type AddDriverDto = {
    firstName?: string | null;
    lastName?: string | null;
    dateOfBirth?: Date | null;
    employeeId?: string | null;
    emailAddress: string | null;
    licenceNumber?: string | null;
    driverComment?: string | null;
    licenceCountryOrigin?: string | null;
    primaryDriverId: string | null;
    primaryDriverName: string | null;
    address?: {
        address1?: string | null;
        address2?: string | null;
        address3?: string | null;
        address4?: string | null;
        address5?: string | null;
        addressTown?: string | null;
        addressPostcode?: string | null;
    } | null;
    document?: DocumentBase64[];
};

export type EditDriverDto = {
    firstName: string | null;
    lastName: string | null;
    licenceNumber: string | null;
    foreignLicence: boolean | null;
    dateOfBirth: Date | null;
    employeeId: string | null;
    emailAddress: string | null;
    address: DriverAddress | null;
    primaryDriverId: string | null;
    externalReference: string | null;
    licenceCountryOrigin: string | null;
    mobileNumber: string | null;
    jobRole: string | null;
    companyId: string | null;
    departmentId: string | null;
    driverType: DriverTypes | null;
};

export type UndeleteDriverDto = {
    isDeleted: boolean;
};

export type SendDeclarationDto = {
    declarationType: DeclarationType;
    driverValidationType?: DriverValidationTypes;
};

export type SendPaperDriverDeclarationDto = {
    licenceCountryOrigin: string;
};
