import { addSpacesToCamelCase } from "./addSpacesToCamelCase";
import { capitaliseWord } from "./capitaliseWord";
import { stringifyValue } from "./stringifyValue";

export const summarizeObjectToString = (
    jsonObject: any,
    summaryArray: string[]
) => {
    for (const [key, value] of Object.entries(jsonObject)) {
        if (value || value === 0) {
            // if the value is an object, break it down further, otherwise process as is
            if (value instanceof Object && !(value instanceof Date)) {
                for (const [subKey, subValue] of Object.entries(value)) {
                    if (subValue || subValue === 0) {
                        if (
                            subValue instanceof Object &&
                            !(subValue instanceof Array)
                        ) {
                            if (
                                // ie obj doesn't have null values
                                !Object.values(subValue).every(
                                    (x) =>
                                        x === null ||
                                        x === "" ||
                                        x === undefined
                                )
                            )
                                // at this point it gets a bit ridiculous so we can just stringify it :)
                                summaryArray.push(
                                    `${capitaliseWord(
                                        addSpacesToCamelCase(subKey)
                                    )}: ${JSON.stringify(subValue).replace(
                                        /[,*;:{}=\-_`~()"]/g,
                                        " "
                                    )}`
                                );
                        } else {
                            summaryArray.push(
                                `${capitaliseWord(
                                    addSpacesToCamelCase(subKey)
                                )}: ${stringifyValue(subValue)}`
                            );
                        }
                    }
                }
            } else {
                summaryArray.push(
                    `${capitaliseWord(
                        addSpacesToCamelCase(key)
                    )}: ${stringifyValue(value)}`
                );
            }
        }
    }
};
