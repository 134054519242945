import { Circle, RadioButton } from "phosphor-react";
import { FieldError } from "react-hook-form";
import clsx from "clsx";

export type FormRadioInputProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "type" | "className"
> & {
    label?: string;
    error?: FieldError;
    helpText?: string;
    required?: boolean;
    className?: string;
};

const FormRadioInput: React.FC<FormRadioInputProps> = ({
    value,
    checked,
    label,
    name,
    onChange,
    disabled,
}) => {
    return (
        <div className="">
            <label className="relative flex items-center gap-2">
                {!checked && (
                    <Circle
                        weight="regular"
                        className={clsx(
                            "h-6 w-6 -translate-y-[1px] fill-S2D-dark-green-30",
                            disabled && "opacity-30"
                        )}
                    />
                )}
                {checked && (
                    <RadioButton
                        className={clsx(
                            "h-6 w-6 -translate-y-[1px] fill-S2D-dark-green-30",
                            disabled && "opacity-30"
                        )}
                        weight="fill"
                    />
                )}
                <input
                    type="radio"
                    name={name}
                    disabled={disabled}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    className={clsx(
                        "absolute inset-0 opacity-0",
                        disabled && "cursor-not-allowed"
                    )}
                />
                <span className="-translate-y-[1px]">{label}</span>
            </label>
        </div>
    );
};

export default FormRadioInput;
