import useLocalStorage from "./useLocalStorage";
import { type DriverCategory } from "../utils/driverPageLocation";

function useDriversListPaginationLocalStorage(
    driverCategory: DriverCategory = "drivers"
) {
    const [orderBy, setOrderBy] = useLocalStorage<string>(
        `${driverCategory}ListOrderBy`,
        "LastName"
    );
    const [sortOrder, setSortOrder] = useLocalStorage<string>(
        `${driverCategory}ListSortOrder`,
        "asc"
    );
    const [pageNumber, setPageNumber] = useLocalStorage<number>(
        `${driverCategory}ListPageNumber`,
        1
    );
    const [pageSize, setPageSize] = useLocalStorage<number>(
        `${driverCategory}ListPageSize`,
        25
    );

    return {
        orderBy,
        setOrderBy,
        sortOrder,
        setSortOrder,
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
    };
}

export default useDriversListPaginationLocalStorage;
