const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
};

const padTo4Digits = (num: number) => {
    return num.toString().padStart(4, "0");
};

export const formatDateToString = (date: Date, addTime?: boolean): string => {
    return (
        [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            padTo4Digits(date.getFullYear()),
        ].join("/") + (addTime ? ` ${new Date(date).toLocaleTimeString()}` : "")
    );
};
