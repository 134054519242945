import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
    AddInvoiceReferenceNumberFormValidationSchema,
    AddInvoiceReferenceNumberFormValues,
} from "./AddInvoiceReferenceNumberFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInvoiceReferenceNumber } from "../../../../../api/admin/invoiceReferenceNumber";

const useAddInvoiceReferenceNumberForm = (companyId: string) => {

    const {addInvoiceReferenceNumber, isAddingInvoiceReferenceNumber} = useInvoiceReferenceNumber(companyId);

    const validationSchema = yup
        .object()
        .shape(AddInvoiceReferenceNumberFormValidationSchema);

    const methods = useForm<AddInvoiceReferenceNumberFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    return { methods, addInvoiceReferenceNumber, isAddingInvoiceReferenceNumber };
};

export default useAddInvoiceReferenceNumberForm;

