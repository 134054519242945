import EditableContentBlock from "../../../../EditableContentBlock";
import { EditCompanyFormValues } from "../../../../EditCompanyForms/EditCompanyFormValues";
import { Controller } from "react-hook-form";
import SubmitOrCancelButtonBar from "../../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import useEditEdeclarationSettingsForm, {
    type EditEdeclarationSettingsFormValues,
} from "./useEditEdeclarationSettingsForm";
import FormCheckbox from "../../../../FormCheckbox";
import FormRadioInput from "../../../../FormRadioInput";
import { clientCopy } from "../../../../../copy/sharedCopy";
import LoadingMessage from "../../../../ReusableComponents/LoadingMessage/LoadingMessage";

interface EditEdeclarationSettingsFormProps {
    initialValues: EditCompanyFormValues;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetFormCallback: () => void
    ) => void;
    canEdit: boolean;
}

export default function EditEdeclarationSettingsForm({
    initialValues,
    handleEditCompany,
    canEdit,
}: EditEdeclarationSettingsFormProps) {
    const { methods, displayedValues, isLoading } =
        useEditEdeclarationSettingsForm(initialValues);
    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
    } = methods;

    return (
        <EditableContentBlock
            title="E-Declaration Settings"
            onClosed={() => reset()}
            disableEdit={!canEdit}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    <div className="p-6">
                        {!isEdit && nonEditableContent()}

                        {isEdit && editableContent(setIsEdit)}
                    </div>
                </>
            )}
        </EditableContentBlock>
    );

    function editableContent(setIsEdit: (isEdit: boolean) => void) {
        const resetAndCloseForm = () => {
            // Timing out, in-case the hook's useEffect is not triggered
            // (0ms as the component will then update when its props update)
            setTimeout(() => (isLoading.current = false), 0);
            reset();
            setIsEdit(false);
        };

        const onSubmit = (data: EditEdeclarationSettingsFormValues) => {
            const partialCompanyData = {
                settings: { edeclaration: { ...data } },
            };
            isLoading.current = true;
            handleEditCompany(partialCompanyData, resetAndCloseForm);
        };

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* TODO 11/04/23  re-use the form elements used in AddCompanyForm */}
                <div className="flex flex-col items-start space-y-2">
                    <h3 className="text-lg font-bold">E-Declaration Form</h3>
                    <FormCheckbox
                        {...register("useParentContact")}
                        id="useParentContact"
                        defaultChecked={displayedValues.useParentContact}
                        error={errors.useParentContact}
                        label={clientCopy.useParentForEmailLabel}
                        inline
                    />

                    <h4 className="pb-3">Validate driver identity with</h4>

                    <div className="flex space-x-6">
                        <Controller
                            name="driverValidationType"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormRadioInput
                                    name="validateBy"
                                    value="DateOfBirth"
                                    checked={value === "DateOfBirth"}
                                    defaultChecked={
                                        displayedValues.driverValidationType ===
                                        "DateOfBirth"
                                    }
                                    label="Date of birth"
                                    onChange={() => onChange("DateOfBirth")}
                                />
                            )}
                        />
                        <Controller
                            name="driverValidationType"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormRadioInput
                                    name="validateBy"
                                    value="EmployeeId"
                                    checked={value === "EmployeeId"}
                                    defaultChecked={
                                        displayedValues.driverValidationType ===
                                        "EmployeeId"
                                    }
                                    label="Employee number"
                                    onChange={() => onChange("EmployeeId")}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="pt-7" />
                <SubmitOrCancelButtonBar
                    isLoading={isLoading.current}
                    onCancel={resetAndCloseForm}
                />
            </form>
        );
    }

    function nonEditableContent() {
        const validateDriverIdentityCopy =
            displayedValues.driverValidationType === "EmployeeId"
                ? "Employee number"
                : "Date of birth";
        return (
            <div className="space-y-2">
                <p>
                    {isLoading.current ? (
                        <LoadingMessage />
                    ) : (
                        displayedValues.useParentContact && (
                            <b>{clientCopy.useParentForEmailLabel}</b>
                        )
                    )}
                </p>

                <p>
                    <b>Validate driver identity with: </b>
                    {isLoading.current ? (
                        <LoadingMessage />
                    ) : (
                        validateDriverIdentityCopy
                    )}
                </p>
            </div>
        );
    }
}
