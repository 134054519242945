import { SetStateAction } from "react";
import DialogBox from "../../../DialogBox";
import AddInvoiceAddressForm from "./AddInvoiceAddressForm/AddInvoiceAddressForm";
import { AddOrEditModalStates } from "../CompanyBillingTab";
import { capitaliseWord } from "../../../../utils/capitaliseWord";
import EditInvoiceAddressForm from "./EditInvoiceAddressForm/EditInvoiceAddressForm";
import { type ApiInvoiceAddressItem } from "../../../../models/api/admin/invoiceAddress";

type AddOrEditInvoiceAddressModalProps = {
    companyId: string;
    isOpen: boolean;
    setModalState: (value: SetStateAction<AddOrEditModalStates>) => void;
    selectedInvoiceAddress: ApiInvoiceAddressItem | undefined;
    refetchInvoiceAddresses: () => void;
    type: AddOrEditModalStates;
};

const AddOrEditInvoiceAddressModal: React.FC<AddOrEditInvoiceAddressModalProps> = ({
    companyId,
    isOpen,
    setModalState,
    selectedInvoiceAddress,
    refetchInvoiceAddresses,
    type = "closed",
}) => {
    return (
        <DialogBox
            isOpen={isOpen}
            onClose={() => setModalState("closed")}
            title={`${capitaliseWord(type)} invoice address`}
            size="xl"
            closeButton
        >
            {type === "add" && (
                <AddInvoiceAddressForm
                    companyId={companyId}
                    setModalState={setModalState}
                    refetchInvoiceAddresses={refetchInvoiceAddresses}
                />
            )}

            {type === "edit" && (
                <EditInvoiceAddressForm
                    setModalState={setModalState}
                    selectedInvoiceAddress={selectedInvoiceAddress}
                    refetchInvoiceAddresses={refetchInvoiceAddresses}
                />
            )}
        </DialogBox>
    );
};

export default AddOrEditInvoiceAddressModal;
