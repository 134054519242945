import { Car, CheckCircle, Warning } from "phosphor-react";
import { type CompanyGreyFleetDriversStats } from "../../models/api/admin/companyStats";
import StatisticSummaryBox from "../StatisticSummaryBox";
import { type CompanyGreyFleetDriversDto } from "../../models/app/admin/companyDashboard";

type CompanyDashboardGreyFleetDriversTabProps = {
    data?: CompanyGreyFleetDriversStats;
    onLinkClick?: (key: keyof CompanyGreyFleetDriversDto) => void;
};

const CompanyDashboardGreyFleetDriversTab: React.FC<
    CompanyDashboardGreyFleetDriversTabProps
> = ({ data, onLinkClick }) => {
    // NB avoiding 'flex-wrap' in the PDF report as it causes issues with domtoimage
    const compatibleStyle = onLinkClick
        ? "flex grow flex-wrap gap-4"
        : "grid grid-cols-1 xl:grid-cols-3 gap-4";
    return (
        <div className="flex w-full flex-col gap-4">
            <StatisticSummaryBox
                icon={Car}
                iconColor="bg-S2D-neutral-100"
                label="grey fleet drivers"
                statistic={data?.totalDrivers}
                // onLinkClick={() => onLinkClick ? onLinkClick()}
            />
            <div className={compatibleStyle}>
                <StatisticSummaryBox
                    icon={CheckCircle}
                    label="invites complete"
                    statistic={data?.invitesComplete}
                    onLinkClick={() =>
                        onLinkClick ? onLinkClick("invitesComplete") : undefined
                    }
                />
                <StatisticSummaryBox
                    icon={Warning}
                    label="invites incomplete"
                    statistic={data?.invitesPending}
                    onLinkClick={() =>
                        onLinkClick
                            ? onLinkClick("invitesIncomplete")
                            : undefined
                    }
                />
            </div>
        </div>
    );
};

export default CompanyDashboardGreyFleetDriversTab;
