import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { useFormFieldCollectiveValidation } from "../../hooks/useFormFieldCollectiveValidation";
import { EmailRequest } from "../../pages/Admin/AdminAddDriver";
import AccordionItem from "../AccordionItem";
import FormDateInput from "../FormDateInput";
import FormRadioGroup, { FormRadioGroupOption } from "../FormRadioGroup";
import FormRadioInput from "../FormRadioInput";
import FormTextInput from "../FormTextInput";
import { AddDriverFormValues } from "./AddDriverFormValues";
import { DriverValidationTypes } from "../../models/app/admin/company";
import { DeclarationType } from "../../models/app/eDeclarations";

type DriverInformationFieldsProps = {
    methods: UseFormReturn<AddDriverFormValues>;
    emailRequest: EmailRequest;
    handleDeclarationTypeChange: (value: DeclarationType) => void;
    isClientUser: boolean;
};

const declarationTypeOptions: FormRadioGroupOption<DeclarationType>[] = [
    {
        id: DeclarationType.EDeclaration,
        label: "E-Declaration",
        caption:
            "The driver will be sent an E-Declaration request email when this form is saved.",
    },
    {
        id: DeclarationType.PaperDeclaration,
        label: "Paper declaration",
        caption:
            "The driver will be added to the system but for them to be checked you must upload a signed copy of the completed Fair Processing Declaration Form. You can do so as a step below in this form or later by editing the drivers record.",
    },
];

const DriverInformationFields: React.FC<DriverInformationFieldsProps> = ({
    methods,
    emailRequest,
    handleDeclarationTypeChange,
    isClientUser,
}) => {
    const isSectionValid =
        useFormFieldCollectiveValidation<AddDriverFormValues>(
            [
                "firstName",
                "lastName",
                "emailAddress",
                "dateOfBirth",
                "employeeId",
            ],
            methods
        );

    const control = methods.control;
    const foreignLicence = useWatch({ control, name: "foreignLicence" });
    const validateBy = useWatch({ control, name: "validateDriverIdentityBy" });

    const handleValidateDriverIdentityByChange = (
        value: DriverValidationTypes
    ) => {
        if (foreignLicence) return;

        methods.setValue("validateDriverIdentityBy", value);
    };

    return (
        <AccordionItem
            title="Driver information"
            error={!isSectionValid}
            defaultOpen
        >
            <>
                <h3 className="mb-4 text-lg font-bold">
                    Driver Declaration Form
                </h3>
                <Controller
                    name="declarationType"
                    control={methods.control}
                    render={({ field: { value } }) => (
                        <FormRadioGroup
                            value={value}
                            options={declarationTypeOptions}
                            error={methods.formState.errors.declarationType}
                            onChange={(value: DeclarationType) =>
                                handleDeclarationTypeChange(value)
                            }
                            disabled={foreignLicence}
                        />
                    )}
                />
                <h3 className="mb-4 text-lg font-bold">Driver Information</h3>
                <FormTextInput
                    label="First Name"
                    required
                    id="firstName"
                    placeholder="Enter first name"
                    error={methods.formState.errors.firstName}
                    {...methods.register("firstName")}
                />
                <FormTextInput
                    label="Surname"
                    required
                    id="lastName"
                    placeholder="Enter surname"
                    error={methods.formState.errors.lastName}
                    {...methods.register("lastName")}
                />
                <FormTextInput
                    label="Email Address"
                    required={emailRequest.eDeclaration}
                    id="emailAddress"
                    placeholder="Enter email address"
                    error={methods.formState.errors.emailAddress}
                    {...methods.register("emailAddress")}
                />

                <div className="py-4">
                    <h4>Validate driver identity with</h4>
                    <div className="mt-2 flex gap-6">
                        <FormRadioInput
                            name="validateBy"
                            value={DriverValidationTypes.DateOfBirth}
                            disabled={isClientUser}
                            checked={
                                validateBy === DriverValidationTypes.DateOfBirth
                            }
                            label="Date of birth"
                            onChange={() =>
                                handleValidateDriverIdentityByChange(
                                    DriverValidationTypes.DateOfBirth
                                )
                            }
                        />
                        <FormRadioInput
                            name="validateBy"
                            value={DriverValidationTypes.EmployeeId}
                            disabled={isClientUser}
                            checked={
                                validateBy === DriverValidationTypes.EmployeeId
                            }
                            label="Employee Id"
                            onChange={() =>
                                handleValidateDriverIdentityByChange(
                                    DriverValidationTypes.EmployeeId
                                )
                            }
                        />
                    </div>
                </div>

                <Controller
                    name="dateOfBirth"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                        <FormDateInput
                            required={
                                validateBy === DriverValidationTypes.DateOfBirth
                            }
                            id="dateOfBirth"
                            label="Date of Birth"
                            value={value}
                            onChange={onChange}
                            error={methods.formState.errors.dateOfBirth}
                            maxDate={new Date()}
                        />
                    )}
                />
                <FormTextInput
                    label="Employee ID"
                    required={validateBy === DriverValidationTypes.EmployeeId}
                    id="employeeId"
                    placeholder="Enter employee id"
                    error={methods.formState.errors.employeeId}
                    {...methods.register("employeeId")}
                />
                <FormTextInput
                    label="Mobile number"
                    id="mobileNumber"
                    placeholder="Enter mobile number"
                    error={methods.formState.errors.mobileNumber}
                    {...methods.register("mobileNumber")}
                />
            </>
        </AccordionItem>
    );
};

export default DriverInformationFields;
