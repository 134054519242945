import {
    ApiEDeclarationBasicInfoResponse,
    ApiEDeclarationValidateResponse,
} from "../models/api/edeclaration";
import {
    EDeclarationDriverUpdateDto,
    EDeclarationUpdateDto,
    EDeclarationValidateDto,
} from "../models/app/edeclaration";
import { useS2DApiFetcher } from "./common/fetching";
import { useS2DApiSWR } from "./common/s2dApiSWR";

const useValidateEdeclaration = () =>
    useS2DApiFetcher<
        EDeclarationValidateDto,
        ApiEDeclarationValidateResponse,
        { id: string; dto: EDeclarationValidateDto }
    >(
        "POST",
        ({ id, dto }) => ({
            url: `api/EDeclarations/${id}/validate`,
            body: dto,
        }),
        [400],
        false
    );

const useEdeclarationDriverUpdate = () =>
    useS2DApiFetcher<
        EDeclarationDriverUpdateDto,
        void,
        { id: string; token: string; dto: EDeclarationDriverUpdateDto }
    >(
        "PUT",
        ({ id, token, dto }) => ({
            url: `api/EDeclarations/${id}/driver`,
            body: dto,
            altToken: token,
        }),
        [],
        false
    );

const useEdeclarationFinalise = () =>
    useS2DApiFetcher<undefined, void, { id: string; token: string }>(
        "PUT",
        ({ id, token }) => ({
            url: `api/EDeclarations/${id}/finalise`,
            altToken: token,
        }),
        [],
        false
    );

const useUpdateEdeclaration = () =>
    useS2DApiFetcher<
        EDeclarationUpdateDto,
        void,
        { eDeclarationId: string; dto: EDeclarationUpdateDto }
    >(
        "PUT",
        ({ eDeclarationId, dto }) => ({
            url: `api/EDeclarations/${eDeclarationId}`,
            body: dto,
        }),
        [400],
        false
    );

const useSendInstantDeclarationInviteEmail = () =>
    useS2DApiFetcher<
        { declarationId: string },
        void,
        { declarationId: string }
    >(
        "POST",
        ({ declarationId }) => ({
            url: `api/EDeclarations/${declarationId}/send`,
            body: {
                declarationId,
            },
        }),
        [400],
        false
    );

export const useEdeclaration = (id?: string, skipBasicInfoReq?: boolean) => {
    const { data, error, isLoading } =
        useS2DApiSWR<ApiEDeclarationBasicInfoResponse>(
            id && !skipBasicInfoReq ? `/api/EDeclarations/${id}` : null,
            { preventRedirectOn404: true }
        );

    const [validateEDeclarationUser, isValidatingEDeclarationUser] =
        useValidateEdeclaration();

    const [updateDriverDetails, isUpdatingEdeclarationDriver] =
        useEdeclarationDriverUpdate();

    const [finaliseEDeclaration, isFinalisingEDeclaration] =
        useEdeclarationFinalise();

    const [updateEDeclarationStatus, isUpdatingEDeclarationStatus] =
        useUpdateEdeclaration();
    const [
        sendInstantDeclarationInviteEmail,
        isSendingInstantDeclarationInviteEmail,
    ] = useSendInstantDeclarationInviteEmail();

    return {
        edeclarationBaseData: data,
        error,
        isLoading,
        validateUser: async (id: string, dto: EDeclarationValidateDto) =>
            await validateEDeclarationUser({ id, dto }),
        isValidatingEDeclarationUser,
        updateDriverDetails: async (
            id: string,
            token: string,
            dto: EDeclarationDriverUpdateDto
        ) => await updateDriverDetails({ id, token, dto }),
        isUpdatingEdeclarationDriver,
        finaliseEdeclarationJourney: async (id: string, token: string) =>
            await finaliseEDeclaration({ id, token }),
        isFinalisingEDeclaration,
        updateEDeclarationStatus: async (
            eDeclarationId: string,
            dto: EDeclarationUpdateDto
        ) => await updateEDeclarationStatus({ eDeclarationId, dto }),
        isUpdatingEDeclarationStatus,
        sendInstantDeclarationInviteEmail: async (declarationId: string) =>
            await sendInstantDeclarationInviteEmail({ declarationId }),
        isSendingInstantDeclarationInviteEmail,
    };
};
