import { Disclosure } from "@headlessui/react";
import { List } from "phosphor-react";
import { Link } from "react-router-dom";
import { appPaths } from "../../Routes";
import Logo from "../../s2d-light-logo.svg";
import useTheme from "../../theme/useTheme";
import UserMenu from "../UserMenu";

const TopNav: React.FC = () => {
    const companyTheme = useTheme();

    return (
        <header className="fixed top-0 left-0 z-40 flex h-[4.25rem] w-full items-center border-b border-b-S2D-dark-green-30 bg-S2D-dark-green-40 px-6">
            <Disclosure.Button className="mr-auto flex h-10 items-center rounded-md font-semibold text-S2D-neutral-10 md:hidden">
                <span className="sr-only">Open main menu</span>
                <List className="block h-6 w-6" aria-hidden="true" />
            </Disclosure.Button>
            <Link
                to={appPaths.home}
                className="flex h-10 items-center text-white"
            >
                <img
                    src={companyTheme?.companyLogoUrl ?? Logo}
                    alt="Safe 2 Drive Logo"
                    className="h-full"
                />
            </Link>
            <div className="ml-auto flex items-center gap-2">
                <UserMenu />
            </div>
        </header>
    );
};

export default TopNav;
