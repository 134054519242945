import { FormDropdownOption } from "../components/FormDropdown";
import {
    DriversByAgeRange,
    DriversByAgeRangeOptions,
    DriversByAgeRangeValues,
    DriversByLicenceOrigin,
    DriversByPoints,
    DriversByPointsOptions,
    DriversByPointsValues,
    FilterLicenceOriginOptions,
    FilterLicenceOriginValues,
    FilterLicenceTypeOptions,
    LicenceTypes,
    YearsFullLicenceHeld,
    YearsFullLicenceHeldOptions,
    YearsFullLicenceHeldValues,
} from "../models/app/admin/licenceAnalysis";
import { addSpacesToCamelCase } from "../utils/addSpacesToCamelCase";
import { capitaliseWord } from "../utils/capitaliseWord";

export const driversByPointsOptions: FormDropdownOption<
    keyof DriversByPoints
>[] = (Object.keys(DriversByPointsOptions) as Array<keyof DriversByPoints>).map(
    (key) => ({
        label: DriversByPointsValues[key],
        value: DriversByPointsOptions[key],
    })
);

export const driversByAgeRangeOptions: FormDropdownOption<
    keyof DriversByAgeRange
>[] = (
    Object.keys(DriversByAgeRangeOptions) as Array<
        keyof typeof DriversByAgeRangeOptions
    >
).map((key) => ({
    label: DriversByAgeRangeValues[key],
    value: DriversByAgeRangeOptions[key],
}));

export const filterLicenceTypeOptions: FormDropdownOption<
    keyof LicenceTypes
>[] = (
    Object.keys(FilterLicenceTypeOptions) as Array<
        keyof typeof FilterLicenceTypeOptions
    >
).map((key) => ({
    label: capitaliseWord(addSpacesToCamelCase(FilterLicenceTypeOptions[key])),
    value: FilterLicenceTypeOptions[key],
}));

export const filterLicenceOriginOptions: FormDropdownOption<
    keyof DriversByLicenceOrigin
>[] = (
    Object.keys(FilterLicenceOriginOptions) as Array<
        keyof typeof FilterLicenceOriginOptions
    >
).map((key) => ({
    label: FilterLicenceOriginValues[key],
    value: FilterLicenceOriginOptions[key],
}));

export const yearsFullLicenceHeldOptions: FormDropdownOption<
    keyof YearsFullLicenceHeld
>[] = (
    Object.keys(YearsFullLicenceHeldOptions) as Array<
        keyof typeof YearsFullLicenceHeldOptions
    >
).map((key) => ({
    label: YearsFullLicenceHeldValues[key],
    value: YearsFullLicenceHeldOptions[key],
}));
