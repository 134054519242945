import { Controller, UseFormReturn } from "react-hook-form";
import { formatDateToString } from "../../../utils/formatDateToString";
import Button from "../../Button";
import EditableContentBlock from "../../EditableContentBlock";
import FormDateInput from "../../FormDateInput";
import FormDropdown from "../../FormDropdown";
import FormTextInput from "../../FormTextInput";
import {
    EditDvlaFormValues,
    EditDvlaLicenceInformationFormValues,
} from "../EditDvlaFormValues";
import licenceSample from "../../../licence-sample.png";
import { useWatch } from "react-hook-form";
import { GREAT_BRITAIN, countryList } from "../../../constants/countries";
import {
    licenceStatusOptions,
    licenceTypeOptions,
} from "../../../constants/dvlaConstants";

export type EditLicenceInformationFormProps = {
    initialValues: EditDvlaFormValues;
    isManual: boolean;
    methods: UseFormReturn<EditDvlaLicenceInformationFormValues>;
    isLoading: boolean;
    onSubmit: (
        data: EditDvlaLicenceInformationFormValues,
        callback: () => void
    ) => Promise<void>;
    canEdit: boolean;
};

const countryOfOriginOptions = countryList.map((country) => ({
    label: country.name,
    value: country.name,
}));

const EditLicenceInformationForm: React.FC<EditLicenceInformationFormProps> = ({
    initialValues,
    isManual,
    methods,
    isLoading,
    onSubmit,
    canEdit,
}) => {
    const control = methods.control;
    const countryOfOrigin = useWatch({ control, name: "licenceCountryOrigin" });

    return (
        <EditableContentBlock
            title="Licence information"
            disableEdit={!canEdit}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            <p>
                                <b>Country of origin:</b>{" "}
                                {initialValues.licenceCountryOrigin}
                            </p>
                            <p>
                                <b>Licence number:</b>{" "}
                                {initialValues.licenceNumber}
                            </p>
                            <p>
                                <b>Licence status:</b>{" "}
                                {initialValues.licenceStatus}
                            </p>
                            <p>
                                <b>Licence type:</b> {initialValues.licenceType}
                            </p>
                            <p>
                                <b>Valid from:</b>{" "}
                                {initialValues.licenceValidFrom instanceof Date
                                    ? formatDateToString(
                                          initialValues.licenceValidFrom
                                      )
                                    : initialValues.licenceValidFrom &&
                                      formatDateToString(
                                          new Date(
                                              initialValues.licenceValidFrom
                                          )
                                      )}
                            </p>
                            <p>
                                <b>Valid to:</b>{" "}
                                {initialValues.licenceValidTo instanceof Date
                                    ? formatDateToString(
                                          initialValues.licenceValidTo
                                      )
                                    : initialValues.licenceValidTo &&
                                      formatDateToString(
                                          new Date(initialValues.licenceValidTo)
                                      )}
                            </p>
                            <p>
                                <b>Issue number:</b>{" "}
                                {initialValues.licenceIssueNumber}
                            </p>
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit(
                                    (
                                        data: EditDvlaLicenceInformationFormValues
                                    ) => onSubmit(data, () => setIsEdit(false))
                                )}
                            >
                                <Controller
                                    name="licenceCountryOrigin"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDropdown
                                            label="Driving licence country of origin"
                                            options={countryOfOriginOptions}
                                            value={value}
                                            onChange={onChange}
                                            pleaseSelectText="Select driving licence country of origin"
                                        />
                                    )}
                                />
                                <FormTextInput
                                    label="Licence Number"
                                    required
                                    id="licence-number"
                                    placeholder="Enter licence number"
                                    error={
                                        methods.formState.errors.licenceNumber
                                    }
                                    helpText={
                                        countryOfOrigin === GREAT_BRITAIN
                                            ? "Licence number must be 16 characters with no spaces"
                                            : undefined
                                    }
                                    {...methods.register("licenceNumber")}
                                    moreInfo={
                                        countryOfOrigin === GREAT_BRITAIN
                                            ? {
                                                  dialogPrompt:
                                                      "Where is my licence number?",
                                                  description:
                                                      "Your licence number can be found on your UK Driving Licence at the position illustrated below, this will be 16 characters with no spaces.",
                                                  content: (
                                                      <img
                                                          className="w-full max-w-xl"
                                                          src={licenceSample}
                                                          alt="licence sample MORGA753116SM9IJ"
                                                      />
                                                  ),
                                              }
                                            : undefined
                                    }
                                    autoFocus
                                />
                                <Controller
                                    name="licenceStatus"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDropdown
                                            label="Licence status"
                                            options={licenceStatusOptions}
                                            value={value}
                                            onChange={onChange}
                                            pleaseSelectText="Select licence status"
                                        />
                                    )}
                                />
                                <Controller
                                    name="licenceType"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDropdown
                                            label="Licence type"
                                            options={licenceTypeOptions}
                                            value={value}
                                            onChange={onChange}
                                            pleaseSelectText="Select licence type"
                                        />
                                    )}
                                />
                                <Controller
                                    name="licenceValidFrom"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            id="licenceValidFrom"
                                            label="Valid from"
                                            value={value}
                                            onChange={onChange}
                                            error={
                                                methods.formState.errors
                                                    .licenceValidFrom
                                            }
                                        />
                                    )}
                                />
                                <Controller
                                    name="licenceValidTo"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            id="licenceValidTo"
                                            label="Valid to"
                                            value={value}
                                            onChange={onChange}
                                            error={
                                                methods.formState.errors
                                                    .licenceValidTo
                                            }
                                        />
                                    )}
                                />
                                <FormTextInput
                                    label="Issue number"
                                    defaultValue={
                                        initialValues.licenceIssueNumber
                                    }
                                    id="licenseIssueNumber"
                                    placeholder="Enter licence issue number"
                                    error={
                                        methods.formState.errors
                                            .licenceIssueNumber
                                    }
                                    {...methods.register("licenceIssueNumber")}
                                />
                                <div className="mt-8 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => {
                                            methods.reset();
                                            setIsEdit(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" loading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditLicenceInformationForm;
