import { DeclarationStatus } from "../../../../models/shared/statuses";
import { StatusAction } from "../../../ActionableStatus/ActionableStatus";
import { LicenceChecksActions } from "../licenceCheckActions";
import LicenceCheckStatus from "../LicenceCheckStatus";

type DriverRemovedFromScheduleLicenceCheckStatusProps = {
    driverId: string;
    isManual: boolean;
    declarationStatus: DeclarationStatus | null;
    buildActions: (actionNames: LicenceChecksActions[]) => StatusAction[];
};

const DriverRemovedFromScheduleLicenceCheckStatus = ({
    driverId,
    isManual,
    declarationStatus,
    buildActions,
}: DriverRemovedFromScheduleLicenceCheckStatusProps) => {
    const statusComplete = declarationStatus === "Completed";
    // we only have permission to perform DVLAchecks if there is a completed declaration
    // If a driver is on the 'Manual' checks list, DVLA checks are done manually
    const showEnableFurtherChecks = statusComplete && !isManual;
    const actions = buildActions(
        showEnableFurtherChecks ? ["enableFurtherChecks"] : ["editCheckDate"]
    );

    return (
        <LicenceCheckStatus
            actions={actions}
            theme="vrm"
            text="Not scheduled"
            driverId={driverId}
            isManual={isManual}
        >
            Driver removed from schedule
        </LicenceCheckStatus>
    );
};

export default DriverRemovedFromScheduleLicenceCheckStatus;
