import React, { useState, useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useFormFieldCollectiveValidation } from "../../hooks/useFormFieldCollectiveValidation";
import AccordionItem from "../AccordionItem";
import FormTextInput from "../FormTextInput";
import { AddCompanyFormValues } from "./AddCompanyFormValues";
import { ApiCompaniesResponse } from "../../models/api/admin/company";
import FormDropdown from "../FormDropdown";
import { clientCopy } from "../../copy/sharedCopy";
import { yesNoOptions } from "../../constants/dropdownOptions";

type CompanyDetailsFieldsProps = {
    methods: UseFormReturn<AddCompanyFormValues>;
    companies: ApiCompaniesResponse | undefined;
};

const CompanyDetailsFields: React.FC<CompanyDetailsFieldsProps> = ({
    methods,
    companies,
}) => {
    const resellerIdValue = methods.watch("resellerId");

    const [hasClientParentSelected, setHasClientParentSelected] =
        useState<boolean>(false);

    useEffect(() => {
        setHasClientParentSelected(!!resellerIdValue);
    }, [resellerIdValue]);

    const isSectionValid =
        useFormFieldCollectiveValidation<AddCompanyFormValues>(
            [
                "companyName",
                "resellerId",
                "settings.privacySettings.isDataHidden",
            ],
            methods
        );

    const companyOptions = new Array(0);
    companies?.items.map((company) =>
        companyOptions.push({
            label: company.companyName,
            value: company.companyId,
        })
    );

    return (
        <AccordionItem
            title={clientCopy.addClientTitle}
            error={!isSectionValid}
            defaultOpen
        >
            <FormTextInput
                label={clientCopy.clientNameLabel}
                required
                id="companyName"
                placeholder={clientCopy.clientNamePlaceholder}
                error={methods.formState.errors.companyName}
                {...methods.register("companyName")}
            />

            <Controller
                name="resellerId"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                    <FormDropdown
                        onChange={onChange}
                        value={value}
                        options={companyOptions}
                        label={clientCopy.clientParentLabel}
                        pleaseSelectText={clientCopy.clientParentSelectText}
                    />
                )}
            />
            {hasClientParentSelected && (
                <Controller
                    name="settings.privacySettings.isDataHidden"
                    control={methods.control}
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Hidden From Parent Company?"
                            options={yesNoOptions}
                            value={value}
                            onChange={onChange}
                            pleaseSelectText="Please select..."
                            error={
                                methods.formState.errors.settings
                                    ?.privacySettings?.isDataHidden
                            }
                        />
                    )}
                />
            )}
        </AccordionItem>
    );
};

export default CompanyDetailsFields;
