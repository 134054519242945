import { Address, DriverAddress } from "../../shared/address";
import { ArbitraryKeysWithNumberValues } from "../../shared/arbitraryKeysWithNumberValues";
import {
    CheckStatus,
    LicenceStatus,
    LicenceStatusQualifier,
    LicenceType,
} from "../../shared/statuses";
import { Alert } from "./alert";
import {
    DriverAddressInformation,
    DriverCommentsInformation,
    DriverDeclarationInformation,
    DriverEmploymentInformation,
    DriverGreyFleetInformation,
    DriverLicenceInformation,
    DriverLinkedDriverInformation,
    DriverPersonalInformation,
    DriverVehicleInformation,
} from "./driverDetails";
import { GreyFleetInvite } from "./greyFleetInvite";
import { MandateDetails } from "./mandateDetails";
import { Vehicle } from "./vehicle";

export type ApiAddDriverResponse = {
    driverId: string;
    documentFailureCount: number;
    failureReasons: string[];
};

export type ApiDriversListResponse = {
    orderBy: string;
    sortOrder: string;
    totalCount: number;
    totalPages: number;
    pageNumber: number;
    items: ApiDriverDetailsResponse[];
    hasPreviousPage: boolean;
    hasNextPage: boolean;
};

export type ApiDriverDetailsResponse = DriverPersonalInformation &
    DriverDeclarationInformation &
    DriverEmploymentInformation &
    DriverLicenceInformation &
    DriverGreyFleetInformation &
    DriverVehicleInformation &
    DriverAddressInformation &
    DriverLinkedDriverInformation &
    DriverCommentsInformation;

export type ApiDriverByIdResponse = Omit<
    DriverPersonalInformation,
    "dateOfBirth"
> & {
    foreignLicence: boolean;
    dateOfBirth: Date | null;
    mobileNumber: string | null;
    address: DriverAddress;
    primaryDriverId: string | null;
    primaryDriverName: string | null;
    linkedDrivers: {
        [key: string]: string;
    };
    company: {
        companyId: string;
        companyName: string;
    };
    department: {
        departmentId: string | null;
        departmentName: string | null;
        costCentre: string | null;
    };
} & Omit<
        DriverEmploymentInformation,
        "companyName" | "departmentName" | "employeeNumber"
    > & {
        employeeId: string | null;
        driverType: DriverTypes | null;
        mandateDetails: MandateDetails;
    } & DriverCommentsInformation & {
        dateCreated: string | null;
        createdByUser: CreatedByUser | null;
        dateLastUpdated: string | null;
        lastUpdatedByUser: CreatedByUser | null;
        greyFleetInvite: GreyFleetInvite | null;
        vehicles: Vehicle[] | null;
        licenceUpdatedAt: string | null;
        licenceObtainedAt: string | null;
        licenceNumber: string | null;
        licenceCountryOrigin: string | null;
        licenceType: LicenceType | null;
        licenceStatus: LicenceStatus | null;
        licenceStatusQualifier: LicenceStatusQualifier | null;
        licenceExpiryDate: string | null;
        licenceExpiryReminderScheduledDate: string | null;
        isLicenceDueToExpire: boolean | null;
        licenceCategories: [
            {
                code: string | null;
                type: LicenceTypes | null;
                validFrom: string | null;
                validTo: string | null;
                restrictionCodes: string[] | null;
            }
        ];
        licenceRestrictions: [
            {
                code: string | null;
                description: string | null;
            }
        ];
        disqualificationType: string | null;
        disqualificationDate: string | null;
        disqualifiedUntil: string | null;
        checkStatus: CheckStatus;
        isManualCheck: boolean | null;
        isCheckDue: boolean | null;
        lastCheckDate: string | null;
        nextCheckDate: string | null;
        checkFailureReason: string | null;
        isDisabled: boolean | null;
        pointsTotal: number | null;
        riskProfile: DriverRiskProfile;
        endorsements: string[] | null;
        driverType: DriverTypes | null;
        alerts: Alert[];
    };

export type DriverRiskProfile = {
    name: string;
    severityLevel: "Low" | "Medium" | "High" | "Critical";
    lowerThreshold: number;
    upperThreshold: number | null;
    range: string;
};

export type ApiDriverByIdResponseUnModified = Omit<
    ApiDriverByIdResponse,
    "foreignLicence"
> & {
    foreignLicence: { foreignLicenceCheckId: string }[];
};

export type CreatedByUser = {
    userId: string;
    userName: string;
    companyId: string;
    firstName: string;
    lastName: string;
    isDeleted: boolean;
    driverIds: string[];
};

export type ApiDriverSummaryResponse = {
    driverId: string;
    firstName: string;
    lastName: string;
    licenceNumber: string;
    emailAddress: string;
    dateOfBirth: string;
    address: Address;
};

export type ApiLinkedDriversResponse = [
    {
        driverId: string;
        firstName: string;
        lastName: string;
        licenceNumber: string;
    }
];
export type ApiBulkDriverImportResponse = {
    totalCount: number;
    successCount: number;
    failureCount: number;
    failureReason: string[];
    primaryDriverId: string | null;
    licenceCountryOrigin: string;
};

export type ApiDriverAuditList = {
    items: ApiDriverAuditResponse[];
    totalCount: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
};

export type ApiDriverAuditResponse = {
    auditId: string;
    userId: string;
    userFirstName: string;
    userLastName: string;
    tableName: string;
    type: string;
    auditDate: string;
};

export type SendDeclarationResponse = {
    success: boolean;
    message: string;
    content: string;
};

export type ApiDriverVehicleResponse = {
    vehicleId: string;
    vrm: string;
    make: string;
    model: string;
    colour: string;
    yearOfManufacture: number;
    monthOfFirstRegistration: string;
    co2Emissions: number;
    fuelType: string;
    taxStatus: string;
    taxExpiryDate: string;
    motExpiryDate: string;
    insuranceCertificateFile: {
        documentUrl: string;
        documentCheckedDate: string;
        fileName: string;
    } | null;
    insuranceCompanyName: string;
    insurancePolicyNumber: string;
    insuranceStartDate: string;
    insuranceEndDate: string;
    insuranceFileUploadDate: string;
    insuranceFileCheckedDate: string;
    insuranceFileCheckedBy: {
        userId: string;
        userName: string;
        companyId: string;
        firstName: string;
        lastName: string;
    };
    insuranceType: "Comprehensive" | "ThirdParty";
    hasBusinessCover: boolean;
    hasBreakdownCover: boolean;
    breakdownCoverProvider: string;
    breakdownCoverPolicyNumber: string;
    breakdownCoverStartDate: string;
    breakdownCoverEndDate: string;
};

export type DriverTypes =
    | "None"
    | "CoCar"
    | "GreyFleet"
    | "Partner"
    | "Hcv"
    | "Pcv";

export type ApiDeclarationType = "EDeclaration" | "PaperDeclaration";

export type ApiUpdateDriverDeclarationTypeDto = {
    declarationType: ApiDeclarationType;
};

export type ApiAllDriversStatsResponse = {
    totalDrivers: number;
    totalUkLicenceDrivers: number;
    totalNiLicenceDrivers: number;
    totalForeignLicenceDrivers: number;
};

export type ApiDriversLicensesStatsResponse = {
    points: Points;
    age: Age;
    endorsements: ArbitraryKeysWithNumberValues;
    restrictions: ArbitraryKeysWithNumberValues;
    licenceCategories: LicenceCategories;
    licenceOrigins: LicenceOrigins;
    fullLicenceYears: FullLicenceYears;
    licenceTypes: LicenceTypes;
};

type Points = {
    "0": number;
    "1-3": number;
    "4-7": number;
    "8+": number;
};

type Age = {
    "18-21": number;
    "22-25": number;
    "26-35": number;
    "36-50": number;
    "51-65": number;
    "66+": number;
};

type LicenceCategories = {
    [key: string]: licenceCategoryLevel;
};

type licenceCategoryLevel = {
    fullLicence: number;
    provisionalLicence: number;
};

type LicenceOrigins = ArbitraryKeysWithNumberValues & {
    "United Kingdom (ex. N.I)": number;
    "Northern Ireland": number;
    Foreign: number;
};

type FullLicenceYears = {
    "0-2": number;
    "3-5": number;
    "6-10": number;
    "11-20": number;
    "21+": number;
};

export enum LicenceTypeKeys {
    ValidFullLicence = "Valid Full Licence",
    ValidProvisionalLicence = "Valid Provisional Licence",
    ValidFullLicenceReAssess = "Valid Full Licence For Re-assessment Only",
    ValidProvisionalLicenceReAssess = "Valid Provisional Licence For Re-assessment Only",
    ValidFullLicenceInvitation = "Valid Full Licence Photo Licence Invitation Sent",
    ValidProvisionalLicenceInvitation = "Valid Provisional Licence Photo Licence Invitation Sent",
    RevokedFullLicence = "Revoked Full Licence",
    RevokedProvisionalLicence = "Revoked Provisional Licence",
    ExpiredFullLicence = "Expired full licence",
    ExpiredProvisionalLicence = "Expired provisional licence",
    Disqualified = "Disqualified",
    DisqualifiedForLife = "Disqualified for life",
}

type LicenceTypes = Record<LicenceTypeKeys, number>;

export enum DriverListOrderByOptions {
    FirstName = "FirstName",
    LastName = "LastName",
    LicenceNumber = "LicenceNumber",
    DateOfBirth = "DateOfBirth",
    EmailAddress = "EmailAddress",
    EmployeeId = "EmployeeId",
    ExternalReference = "ExternalReference",
    AddressPostcode = "AddressPostcode",
    Company = "company.CompanyName",
    Department = "Department.DepartmentName",
}
