import { ApiInvoiceItem } from "../../../../models/api/admin/invoice";
import { capitaliseWord } from "../../../../utils/capitaliseWord";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { formatDateToString } from "../../../../utils/formatDateToString";
import StatusPill from "../../../ReusableComponents/StatusPill/StatusPill";
import {
    type TableContent,
    type TableHeader,
} from "../../../ReusableComponents/Table/tableTypes";

type AllowedIds = "invoiceNumber" | "issuedDate" | "price" | "status";

export type InvoicesTableHeader = TableHeader<AllowedIds>;
export type InvoicesTableContent = TableContent<ApiInvoiceItem, AllowedIds>;

export const initialInvoicesTableHeaders: InvoicesTableHeader[] = [
    {
        title: "Invoice Number",
        id: "invoiceNumber",
    },
    {
        title: "Issued Date",
        id: "issuedDate",
    },
    {
        title: "Price",
        id: "price",
    },
    {
        title: "Status",
        id: "status",
    },
];

export function initInvoicesTableContents(
    invoices?: ApiInvoiceItem[]
): InvoicesTableContent[] {
    const tableContents: InvoicesTableContent[] = [];
    invoices?.map((invoice) => {
        tableContents.push({
            rowId: invoice.invoiceId,
            rowData: invoice,
            cellData: [
                {
                    id: "invoiceNumber",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {invoice.invoiceNumber}
                        </p>
                    ),
                },
                {
                    id: "issuedDate",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {formatDateToString(new Date(invoice.issuedDate))}
                        </p>
                    ),
                },
                {
                    id: "price",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {formatCurrency(invoice.price)}
                        </p>
                    ),
                },
                {
                    id: "status",
                    content: (
                        <div>
                            <StatusPill
                                status={capitaliseWord(invoice.status)}
                                bgColour={"bg-black"}
                                textColour={"text-white"}
                            />
                        </div>
                    ),
                },
            ],
        });
    });
    return tableContents;
}
