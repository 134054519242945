import DriverSummaryCard from "./DriverSummaryCard";
import {
    CaretDown,
    CaretUp,
    GlobeHemisphereWest,
    IdentificationCard,
    Truck,
} from "phosphor-react";
import clsx from "clsx";
import NorthernIreland from "../../assets/NorthernIreland.png";
import UnitedKingdom from "../../assets/UnitedKingdom.png";
import { DriverCategory } from "../../utils/driverPageLocation";
import { ApiAllDriversStatsResponse } from "../../models/api/admin/driver";
import LoadingMessage from "../ReusableComponents/LoadingMessage/LoadingMessage";
import useLocalStorage from "../../hooks/useLocalStorage";

type DriverSummaryProps = {
    type?: DriverCategory;
    loading: boolean;
    stats: ApiAllDriversStatsResponse | undefined;
};
export default function DriversSummary({
    type,
    loading,
    stats,
}: DriverSummaryProps) {
    const [isDriverSummaryOpen, setIsDriverSummaryOpen] =
        useLocalStorage<boolean>(`isDriverSummaryOpen`, true);

    const cardOneTitle =
        type === "professionalDrivers"
            ? "NUMBER OF PROFESSIONAL DRIVERS"
            : "TOTAL NUMBER OF DRIVERS";
    const cardOneLogo =
        type === "professionalDrivers" ? Truck : IdentificationCard;
    const sectionTitle =
        type === "professionalDrivers" ? "HGV/Coach drivers" : "All drivers";
    return (
        <section
            about="Drivers summary"
            className="w-full rounded bg-S2D-dark-green-70.3 p-6"
        >
            <div
                onClick={() => setIsDriverSummaryOpen(!isDriverSummaryOpen)}
                className={clsx(
                    `flex items-center gap-3`,
                    isDriverSummaryOpen ? "pb-3" : ""
                )}
            >
                <p className="text-lg font-semibold">{sectionTitle}</p>
                {isDriverSummaryOpen ? (
                    <CaretUp weight="fill" />
                ) : (
                    <CaretDown weight="fill" />
                )}
            </div>
            {isDriverSummaryOpen && !loading && (
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                    <DriverSummaryCard
                        label={cardOneTitle}
                        statistic={stats?.totalDrivers ?? 0}
                        icon={cardOneLogo}
                    />
                    <DriverSummaryCard
                        label="DRIVERS WITH UK LICENCES"
                        statistic={stats?.totalUkLicenceDrivers ?? 0}
                        customImg={UnitedKingdom}
                    />

                    <DriverSummaryCard
                        label="DRIVERS WITH N.IRISH LICENCES"
                        statistic={stats?.totalNiLicenceDrivers ?? 0}
                        customImg={NorthernIreland}
                    />
                    <DriverSummaryCard
                        label="DRIVERS WITH FOREIGN LICENCES"
                        statistic={stats?.totalForeignLicenceDrivers ?? 0}
                        icon={GlobeHemisphereWest}
                    />
                </div>
            )}
            {isDriverSummaryOpen && loading && <LoadingMessage />}
        </section>
    );
}
