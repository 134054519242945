import { type ApiDepartmentItem } from "../../../models/api/admin/department";
import { type ApiCompanyResponse } from "../../../models/api/admin/company";
import useLocalStorage from "../../../hooks/useLocalStorage/useLocalStorage";
import useAuth from "../../../auth";
import { useCompany } from "../../../api/admin/company";
import { useEffect } from "react";

export const useCompanyDepartmentSelector = () => {
    const [currentDepartment, setCurrentDepartment] =
        useLocalStorage<ApiDepartmentItem | null>("SelectedDepartment", null);

    const handleDepartmentChange = (
        department: ApiDepartmentItem | null,
        callback?: () => void
    ) => {
        setCurrentDepartment(department);
        callback && callback();
    };

    const [selectedCompany, setSelectedCompany] =
        useLocalStorage<ApiCompanyResponse | null>("SelectedCompany", null);

    // default selected company is the user's associated company
    const auth = useAuth();
    const { companyData } = useCompany(
        auth.userDetails?.companyId ?? undefined
    );

    useEffect(() => {
        if (companyData) {
            setSelectedCompany(companyData);
        }
    }, [companyData]);

    const handleCompanyChange = (
        company: ApiCompanyResponse | null,
        callback?: () => void
    ) => {
        setSelectedCompany(company);
        handleDepartmentChange(null, callback);
    };

    return {
        currentDepartment,
        handleDepartmentChange,
        selectedCompany,
        handleCompanyChange,
    };
};
