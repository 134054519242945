import { Dispatch, SetStateAction, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useGreyFleet } from "../../api/greyFleet";
import { DriverTypes } from "../../models/api/admin/driver";
import { errorToast, successToast } from "../../toast";
import Button from "../Button";
import EditableContentBlock from "../EditableContentBlock";
import FormDropdown from "../FormDropdown";
import {
    EditDriverFormValues,
    driverTypeValidationSchema,
} from "./EditDriverFormValues";
import { driverTypeOptions } from "../../constants/dvlaConstants";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import { DriverValidationTypes } from "../../models/app/admin/company";
import FormRadioButton from "../FormRadioButton";
import clsx from "clsx";
import FormInputMessage from "../FormInputError";
import * as yup from "yup";
import { resolverDebugger } from "../../utils/yupResolverDebugger";
import { useStaleDataFormReset } from "../../hooks/useStaleDataFormReset";
import { EditDriverDto } from "../../models/app/admin/driver";
import { useDriver } from "../../api/admin/driver";

type EditDriverTypeFormProps = {
    initialValues: EditDriverFormValues;
    companyData?: ApiCompanyResponse;
    isLoading: boolean;
    onSubmit: (data: EditDriverFormValues, callback: () => void) => void;
};

const validationSchema = yup.object().shape(driverTypeValidationSchema);

const EditDriverTypeForm: React.FC<EditDriverTypeFormProps> = ({
    initialValues,
    companyData,
    isLoading,
    onSubmit,
}) => {
    const methods = useForm<EditDriverFormValues>({
        mode: "onSubmit",
        resolver: (data, context, options) =>
            resolverDebugger(data, context, options, validationSchema),
        defaultValues: initialValues,
    });

    const {
        editDriver,
        isEditingDriver,
    } = useDriver(initialValues.driverId);

    const { sendGreyFleetInvite } = useGreyFleet();
    const [sendInvite, setSendInvite] = useState(false);

    const validateByCompanyPreference =
        companyData?.settings.edeclaration?.driverValidationType;
    const defaultValidateBy: DriverValidationTypes = validateByCompanyPreference ?? DriverValidationTypes.DateOfBirth;

    const handleFormSubmit = async (
        values: EditDriverFormValues,
        setIsEdit: Dispatch<SetStateAction<boolean>>
    ) => {
        onSubmit(values, () => setIsEdit(false));
        if (sendInvite && currentForm.driverType === "GreyFleet") {
            if (!values.emailAddress) {
                errorToast(
                    "Cannot send invitation as driver does not have an email address."
                );
                return;
            }

            const editDriverFormDto: EditDriverDto = {
                ...values,
                
                driverType : currentForm.driverType,
                // 21/04/23 Manually setting departmentId and companyId as there is currently
                //  a mismatch between EditDriverFormValues and EditDriverDto
                // TODO 21/04/23: Try to define EditDriverFormValues from EditDriverDto
                departmentId: values.department?.departmentId ?? null,
                companyId: values.company?.companyId ?? null,
            };

            const editDriverResponse = await editDriver(values.driverId, editDriverFormDto);
            if(editDriverResponse.success){
                const greyFleetInviteResponse = await sendGreyFleetInvite({
                    driverId: values.driverId,
                    // Providing a default value for DriverValidationType
                    // which is not utilised in GreyFleet validation but required for consistency as of 12/08/2024
                    driverValidationType: defaultValidateBy
                });
                if (greyFleetInviteResponse.success) {
                    successToast("Grey fleet invite sent");
                }
            }
        }
    };

    const control = methods.control;
    const currentForm = useWatch({ control });
    const emailAddress = useWatch({ control, name: "emailAddress" });
    const disabled = emailAddress ? false : true;

    const driverTypeToString = (driverType: DriverTypes | null) => {
        const option = driverTypeOptions.find(
            (opt) => opt.value === driverType
        );
        if (option) {
            return option.label;
        } else {
            return null;
        }
    };

    useStaleDataFormReset(control, methods.reset, initialValues);

    return (
        <EditableContentBlock title="Driver type">
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            {initialValues.driverType !== null ? (
                                <p>
                                    <b>Driver type:</b>{" "}
                                    {driverTypeToString(
                                        initialValues.driverType
                                    )}
                                </p>
                            ) : (
                                <p>
                                    No driver type has been selected for this
                                    driver
                                </p>
                            )}
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit((values) =>
                                    handleFormSubmit(values, setIsEdit)
                                )}
                            >
                                <div className="flex w-full flex-col gap-2">
                                    <Controller
                                        name="driverType"
                                        control={methods.control}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <FormDropdown
                                                label="Driver type"
                                                options={driverTypeOptions}
                                                value={value}
                                                onChange={onChange}
                                                pleaseSelectText="Select driver type"
                                                error={
                                                    methods.formState.errors
                                                        .driverType
                                                }
                                            />
                                        )}
                                    />
                                    {currentForm.driverType === "GreyFleet" && (
                                        <div
                                            className={clsx(
                                                "relative my-4 flex cursor-pointer flex-col rounded-sm p-[0.125rem] px-5 py-4 outline-0 ring-0 transition-all md:flex-row",
                                                !sendInvite &&
                                                    "border border-black",
                                                sendInvite &&
                                                    "border-2 border-S2D-light-green-80 bg-S2D-dark-green-70.1",
                                                disabled &&
                                                    "cursor-not-allowed opacity-40"
                                            )}
                                        >
                                            <div
                                                className="mb-2 flex flex-1 items-center md:mb-0"
                                                onClick={() =>
                                                    disabled
                                                        ? undefined
                                                        : setSendInvite(
                                                              !sendInvite
                                                          )
                                                }
                                            >
                                                <FormRadioButton
                                                    checked={sendInvite}
                                                    active={sendInvite}
                                                    isError={false}
                                                />
                                                <div className="relative flex-1 md:-top-[2px]">
                                                    Send grey fleet request
                                                </div>
                                                <div className="relative flex-1 md:-top-[2px]">
                                                    The driver will be sent a
                                                    Grey Fleet request email
                                                    when this form is saved.
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {disabled && (
                                        <FormInputMessage helpText="We cannot send a grey fleet request without an email address provided." />
                                    )}
                                </div>
                                <div className="mt-8 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => {
                                            setIsEdit(false);
                                            methods.reset({ 
                                                ...currentForm,
                                                driverType:
                                                    initialValues.driverType,
                                            });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" loading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditDriverTypeForm;
