import {
    ApiAllDriversStatsResponse,
    ApiDriversLicensesStatsResponse,
} from "../../models/api/admin/driver";
import {
    addCompanyAndDepartmToPath,
    queryParamConnector,
} from "../../utils/queryParamHelpers";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useDriversStats = (
    professional: boolean,
    companyId?: string,
    departmentId?: string
) => {
    let pathArray = [`api/drivers/stats`];
    if (professional) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push("Professional=true");
    }
    pathArray = addCompanyAndDepartmToPath(pathArray, companyId, departmentId);

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiAllDriversStatsResponse>(pathArray.join(""));

    return {
        driverStatsData: data,
        driverStatsError: error,
        isLoadingDriverStats: isLoading,
        refetchDriverStats: refetch,
    };
};

export const useGetDriversLicensesStats = (
    companyId?: string,
    departmentId?: string,
    isProfessional?: boolean
) => {
    let pathArray = ["api/drivers/licences/stats"];
    pathArray = addCompanyAndDepartmToPath(pathArray, companyId, departmentId);
    if (isProfessional) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push("Professional=true");
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiDriversLicensesStatsResponse>(pathArray.join(""));

    return {
        licenceStatsData: data,
        licenceStatsError: error,
        isLoadingLicenceStats: isLoading,
        refetchLicenceStats: refetch,
    };
};
