import { useUnverifiedUser } from "../../api/user";
import ForgottenPasswordForm, {
    ForgottenPasswordFormValues,
} from "../../components/ForgottenPasswordForm/ForgottenPasswordForm";

const ForgottenPasswordPage = () => {
    const { forgottenPassword, isHandlingForgottenPassword } =
        useUnverifiedUser();

    const handleForgotPassword = async (
        data: ForgottenPasswordFormValues,
        callback: (error: string) => void
    ) => {
        const res = await forgottenPassword(data);
        if (res.success) {
            callback("success");
        } else {
            callback("error");
        }
    };

    return (
        <div className="w-screen h-screen flex justify-center items-center bg-S2D-surface-light-3">
            <ForgottenPasswordForm
                onSubmit={handleForgotPassword}
                isLoading={isHandlingForgottenPassword}
            />
        </div>
    );
};

export default ForgottenPasswordPage;
