import {
    ApiAddDriverResponse,
    ApiBulkDriverImportResponse,
    ApiDriverAuditList,
    ApiDriverByIdResponse,
    ApiDriverByIdResponseUnModified,
    ApiDriversListResponse,
    ApiDriverVehicleResponse,
    ApiLinkedDriversResponse,
    ApiUpdateDriverDeclarationTypeDto,
} from "../../models/api/admin/driver";
import {
    AddDriverDto,
    EditDriverDto,
    GetAllDriversDto,
    SendDeclarationDto,
    SendPaperDriverDeclarationDto,
    UndeleteDriverDto,
} from "../../models/app/admin/driver";
import { DriverAddress } from "../../models/shared/address";
import {
    useS2DApiFetcher,
    useS2DApiFetcherWithContent,
} from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

const useAddDriver = () =>
    useS2DApiFetcherWithContent<AddDriverDto, ApiAddDriverResponse, { dto: AddDriverDto }>(
        "POST",
        ({ dto }) => ({
            url: "api/drivers",
            body: dto,
        }),
        [409],
        true
    );

const useEditDriver = () => {
    return useS2DApiFetcher<
        EditDriverDto | UndeleteDriverDto,
        string,
        { id: string; dto: EditDriverDto | UndeleteDriverDto }
    >(
        "PUT",
        ({ id, dto }) => {
            return {
                url: `api/drivers/${id}`,
                body: dto,
            };
        },
        [409],
        true
    );
};

export const useGetAllDrivers = () =>
    useS2DApiFetcher<
        GetAllDriversDto,
        ApiDriversListResponse,
        { dto: GetAllDriversDto }
    >(
        "POST",
        ({ dto }) => ({
            url: `api/drivers/GetAllDriversTable`,
            body: dto,
        }),
        [],
        true
    );

const useDeleteDriver = () =>
    useS2DApiFetcher<void, string, { id: string }>(
        "DELETE",
        ({ id }) => ({
            url: `api/drivers/${id}`,
        }),
        [],
        true
    );

export const useBulkDriverImport = () =>
    useS2DApiFetcherWithContent<
        FormData,
        ApiBulkDriverImportResponse,
        {
            formData: FormData;
            companyId: string;
        }
    >(
        "POST",
        ({ formData, companyId }) => ({
            url: `api/drivers/import?companyId=${companyId}`,
            body: formData,
            contentType: "multipart/form-data",
        }),
        [],
        true
    );

export const useBulkDriverImportDelete = () =>
    useS2DApiFetcherWithContent<
        FormData,
        ApiBulkDriverImportResponse,
        FormData
    >(
        "DELETE",
        (formData) => ({
            url: "api/drivers/import",
            body: formData,
            contentType: "multipart/form-data",
        }),
        [],
        true
    );

export const useGetDriversQuery = () => {
    const [getLinkedDrivers, isGettingLinkedDrivers] = useS2DApiFetcher<
        null,
        ApiLinkedDriversResponse,
        { companyId: string; searchString: string }
    >(
        "GET",
        ({ companyId, searchString }) => ({
            url: `api/drivers/driver?CompanyId=${companyId}&SearchString=${searchString}`,
        }),
        [],
        true
    );

    return {
        getLinkedDrivers: async (companyId: string, searchString: string) =>
            await getLinkedDrivers({ companyId, searchString }),
        isGettingLinkedDrivers,
    };
};

export const useGetDriverAuditList = (
    id: string,
    pageNumber: number,
    pageSize: number
) => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiDriverAuditList>(
            `api/drivers/${id}/audit?pageNumber=${pageNumber}&pageSize=${pageSize}`
        );
    return {
        driverAuditList: data,
        driverAuditListError: error,
        isDriverAuditListLoading: isLoading,
        refetchDriverAuditList: refetch,
    };
};

const useSendDriverDeclaration = () =>
    useS2DApiFetcher<
        SendDeclarationDto,
        string,
        { id: string; dto: SendDeclarationDto }
    >(
        "POST",
        ({ id, dto }) => ({
            url: `api/drivers/${id}/declarations`,
            body: dto,
        }),
        [],
        true
    );

const useSendPaperDriverDeclaration = () =>
    useS2DApiFetcher<
        SendPaperDriverDeclarationDto,
        string,
        {
            id: string;
            declarationId: string;
            dto: SendPaperDriverDeclarationDto;
        }
    >(
        "POST",
        ({ id, declarationId, dto }) => ({
            url: `api/drivers/${id}/declarations/${declarationId}/send`,
            body: dto,
        }),
        [],
        true
    );

const useSendInstantDeclarationRequest = () =>
    useS2DApiFetcher<
        null,
        string,
        {
            declarationId: string;
        }
    >(
        "POST",
        ({ declarationId }) => ({
            url: `api/EDeclarations/${declarationId}/send`,
        }),
        [],
        true
    );

export const useGetDriverVehicle = (vehicleId: string, driverId: string) => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiDriverVehicleResponse>(
            `api/drivers/${driverId}/vehicles/${vehicleId}`
        );
    return {
        driverVehicle: data,
        driverVehicleError: error,
        isDriverVehicleLoading: isLoading,
        refetchDriverVehicle: refetch,
    };
};

export const useUpdateDriverDeclarationType = () =>
    useS2DApiFetcher<
        ApiUpdateDriverDeclarationTypeDto,
        void,
        { declarationId: string; dto: ApiUpdateDriverDeclarationTypeDto }
    >("PUT", ({ declarationId, dto }) => ({
        url: `api/edeclarations/${declarationId}`,
        body: dto,
    }));

export const translateAddress = (address: any | DriverAddress) => {
    // typing as any: the intention for this function is to flag when/if the API changes
    // 24/11/23 this is an experiment to see if this kind of function could help with flagging API changes.
    const translatedAddress: DriverAddress = {
        address1: address.address1,
        address2: address.address2,
        address3: address.address3,
        address4: address.address4,
        address5: address.address5,
        town: address.town,
        postcode: address.postcode,
        addressPostcode: address.postcode,
        addressTown: address.town,
    };
    // 24/11/23 addressTown and addressPostcode are here as the API expects them
    // (despite the same endpoint needing town and postcode for GET)

    if (
        !(
            translatedAddress.address1 &&
            translatedAddress.town &&
            translatedAddress.postcode
        )
    ) {
        console.error(
            "Address is missing required fields and cannot be translated"
        );
    }

    return translatedAddress;
};

export const useDriver = (id?: string) => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiDriverByIdResponseUnModified>(
            id ? `api/drivers/${id}` : null,
            {
                preventRedirectOn404: true,
            }
        );
    const [addDriver, isAddingDriver] = useAddDriver();
    const [editDriver, isEditingDriver] = useEditDriver();
    const [getAllDrivers, isGettingAllDrivers] = useGetAllDrivers();
    const [deleteDriver, isDeletingDriver] = useDeleteDriver();
    const [sendDeclaration, isSendingDeclaration] = useSendDriverDeclaration();
    const [sendPaperDeclaration, isSendingPaperDeclaration] =
        useSendPaperDriverDeclaration();
    const [sendInstantDeclarationRequest, isSendingInstantDeclarationRequest] =
        useSendInstantDeclarationRequest();
    const [updateDeclarationType, isUpdatingDeclarationType] =
        useUpdateDriverDeclarationType();

    const driverData: ApiDriverByIdResponse | undefined = data
        ? {
              ...data,
              dateOfBirth: data.dateOfBirth ? new Date(data.dateOfBirth) : null,
              foreignLicence: !!data.foreignLicence?.length,
              // somewhere licence number is being padded with spaces and I am unable to find where
              // this solution is not ideal
              licenceNumber: data.licenceNumber?.replace(/\s/g, "") ?? "",
              address: translateAddress(data.address),
          }
        : undefined;

    return {
        driverData,
        driverError: error,
        isLoading,
        addDriver: async (dto: AddDriverDto) => await addDriver({ dto }),
        isAddingDriver,
        getAllDrivers: async (dto: GetAllDriversDto) =>
            await getAllDrivers({ dto }),
        isGettingAllDrivers,
        editDriver: async (id: string, dto: EditDriverDto | UndeleteDriverDto) =>
            await editDriver({ id, dto }),
        isEditingDriver,
        refetch,
        deleteDriver: async (id: string) => await deleteDriver({ id }),
        isDeletingDriver,
        sendDeclaration: async (id: string, dto: SendDeclarationDto) =>
            await sendDeclaration({ id, dto }),
        isSendingDeclaration,
        sendPaperDeclaration: async (
            id: string,
            declarationId: string,
            dto: SendPaperDriverDeclarationDto
        ) => await sendPaperDeclaration({ id, declarationId, dto }),
        isSendingPaperDeclaration,
        sendInstantDeclarationRequest: async (declarationId: string) =>
            await sendInstantDeclarationRequest({ declarationId }),
        isSendingInstantDeclarationRequest,
        updateDeclarationType: async (
            declarationId: string,
            dto: ApiUpdateDriverDeclarationTypeDto
        ) => await updateDeclarationType({ declarationId, dto }),
        isUpdatingDeclarationType,
    };
};
