import useSWR, { KeyedMutator } from "swr";

import authenticatedS2DApiFetcher from "./fetching";
import ApiError, { isApiError } from "./apiError";

import useAuth from "../../auth/useAuth";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../Routes";

export type UseS2DApiSWRReturn<T> = {
    data?: T;
    isLoading: boolean;
    isValidating: boolean;
    error?: ApiError;
    refetch: () => void;
    mutate: KeyedMutator<T>;
};

export type UseS2DApiSWROptions = {
    preventRedirectOn404?: boolean;
};

export const useS2DApiSWR = <T>(
    url: string | null,
    options?: UseS2DApiSWROptions
): UseS2DApiSWRReturn<T> => {
    const { token } = useAuth();
    const navigate = useNavigate();

    const fetcher = async (path: string) => {
        try {
            return await authenticatedS2DApiFetcher<unknown, T>(
                path,
                "GET",
                undefined,
                token || undefined
            );
        } catch (error) {
            if (
                isApiError(error) &&
                error.statusCode === 404 &&
                !options?.preventRedirectOn404
            ) {
                navigate(appPaths.fourOFour);
            }

            throw error;
        }
    };

    const { data, error, mutate, isValidating } = useSWR<T, ApiError>(
        url !== null ? url : null,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        data,
        isLoading: !error && !data,
        isValidating,
        error,
        refetch: () => mutate(),
        mutate,
    };
};
