import { type Dispatch, type SetStateAction } from "react";
import { type S2DStaffMember } from "../../models/api/safe2DriveAdmin/staff";
// import UserIcon from "../UserIcon";
import StatusPill from "../ReusableComponents/StatusPill/StatusPill";
import { userStatusColor } from "../../utils/userStatusColour";
// import IconButton from "../IconButton";
// import { ClockCounterClockwise, Download, Printer } from "phosphor-react";
import { capitaliseWord } from "../../utils/capitaliseWord";
import ProfileLabel from "../ReusableComponents/ProfileLabel";

type EditStaffHeaderProps = {
    selectedTab: "edit" | "audit";
    setSelectedTab: Dispatch<SetStateAction<"edit" | "audit">>;
    initialValues: S2DStaffMember;
};

const EditStaffHeader: React.FC<EditStaffHeaderProps> = ({
    selectedTab,
    setSelectedTab,
    initialValues,
}) => {
    return (
        <div className="flex w-full flex-wrap items-center justify-between gap-4 px-6 py-2">
            <ProfileLabel
                firstName={initialValues.firstName}
                lastName={initialValues.lastName}
                title={`${initialValues.firstName} ${initialValues.lastName}`}
            >
                <StatusPill
                    status={capitaliseWord(initialValues.status)}
                    bgColour={userStatusColor(
                        capitaliseWord(initialValues.status)
                    )}
                />
            </ProfileLabel>
            {/* 18/09/23 removed audit/print/download options for v1.0 release */}

            {/* <div className="grid flex-none grid-flow-col items-start gap-5">
                <IconButton
                    label="Download driver"
                    icon={Download}
                    onClick={() => {
                        console.log("download user");
                    }}
                />
                <IconButton
                    label="Print driver"
                    icon={Printer}
                    onClick={() => {
                        console.log("print user");
                    }}
                />
                <IconButton
                    label="Audit driver"
                    icon={ClockCounterClockwise}
                    onClick={() => {
                        setSelectedTab(
                            selectedTab === "edit" ? "audit" : "edit"
                        );
                    }}
                    className={
                        selectedTab === "audit"
                            ? "border-2 border-S2D-light-green-80 bg-S2D-dark-green-70.1"
                            : ""
                    }
                />
            </div> */}
        </div>
    );
};

export default EditStaffHeader;
