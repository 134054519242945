import { ApiEDeclarationsResponse } from "../../models/api/admin/eDeclarations";
import {
    EDeclarationsLicenceOriginQueryParams,
    type EDeclarationsCompanyQueryParams,
    type EDeclarationsDeclarationQueryParams,
    type EDeclarationsPersonalInformationQueryParams,
} from "../../models/app/eDeclarations";
import { breakDownApiQueryParams } from "../../utils/breakDownApiQueryParams";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export type DeclarationTableFilters = {
    fromDate: Date | null;
    toDate: Date | null;
    companyQuery: EDeclarationsCompanyQueryParams | null;
    personalInformationQuery: EDeclarationsPersonalInformationQueryParams | null;
    declarationsQuery: EDeclarationsDeclarationQueryParams | null;
    licenceOriginQuery: EDeclarationsLicenceOriginQueryParams | null;
};

export const useGetEDeclarations = (
    pageNumber: number,
    pageSize: number,
    search: string | null,
    declarationTableFilters: DeclarationTableFilters
) => {
    const pathArray = ["api/EDeclarations"];
    const pushConnector = () =>
        pathArray.length === 1 ? pathArray.push("?") : pathArray.push("&");

    // page number and size are required
    pushConnector();
    pathArray.push(`PageNumber=${pageNumber}`);
    pushConnector();
    pathArray.push(`PageSize=${pageSize}`);

    const {
        fromDate,
        toDate,
        companyQuery,
        personalInformationQuery,
        declarationsQuery,
        licenceOriginQuery,
    } = declarationTableFilters;

    if (fromDate) {
        pushConnector();
        pathArray.push(`FromDate=${fromDate.toISOString()}`);
    }
    if (toDate) {
        pushConnector();
        pathArray.push(`ToDate=${toDate.toISOString()}`);
    }
    if (search) {
        pushConnector();
        pathArray.push(`Search=${search}`);
    }

    if (companyQuery) {
        breakDownApiQueryParams(pathArray, companyQuery, "companyQuery");
    }
    if (personalInformationQuery) {
        breakDownApiQueryParams(
            pathArray,
            personalInformationQuery,
            "personalInformationQuery"
        );
    }
    if (declarationsQuery) {
        breakDownApiQueryParams(
            pathArray,
            declarationsQuery,
            "declarationsQuery"
        );
    }
    if (licenceOriginQuery) {
        breakDownApiQueryParams(
            pathArray,
            licenceOriginQuery,
            "licenceOriginQuery"
        );
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiEDeclarationsResponse>(pathArray.join(""));
    return {
        eDeclarationsList: data,
        isLoadingEDeclarationsList: isLoading,
        eDeclarationsListError: error,
        refetchEDeclarationsList: refetch,
    };
};
