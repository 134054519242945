import React from "react";

type ReportAnalysisContainerProps = {
    title?: string;
    children: React.ReactNode;
};
const ReportAnalysisContainer = ({
    title,
    children,
}: ReportAnalysisContainerProps) => {
    return (
        <section
            about={title}
            className={`mb-6 flex flex-col gap-6 rounded-sm bg-S2D-dark-green-70.3 p-6`}
        >
            {title && (
                <span className="flex items-center gap-2">
                    <p className="text-lg font-semibold">{title}</p>
                </span>
            )}
            {children}
        </section>
    );
};

export default ReportAnalysisContainer;
