import * as yup from "yup";

// basic validations that all dates should have
export const dateValidation = yup
    .date()
    .typeError("Must be a valid date, eg. 22/11/2003")
    .test("date", "Invalid format (DD/MM/YYYY)", function (date) {
        const year = date?.getFullYear();
        // let other validations take place if this doesn't exist
        if (!year) return true;

        if (year?.toString().length < 4) return false;
        return true;
    });
