import * as Yup from "yup";

export const LogInFormValidationSchema = {
    userName: Yup.string().required("please enter email address"),
    password: Yup.string().required("please enter password"),
};

export type LogInFormValues = {
    userName: string;
    password: string;
};
