import { useState } from "react";
import { useParams } from "react-router-dom";
import AuditTable from "../../components/AuditTable";
import FormMessageBox from "../../components/FormMessageBox";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useAudits } from "../../api/admin/audits";
import { formatDateToString } from "../../utils/formatDateToString";
import LoadingMessage from "../../components/ReusableComponents/LoadingMessage/LoadingMessage";
import { useCompany } from "../../api/admin/company";
import { AuditAction } from "../../models/api/admin/audits";

type PageParams = {
    id: string;
};

const AdminAuditCompany = () => {
    const { id } = useParams<PageParams>();

    // Storing pageSize in local storage but not pageNumber as this page
    // will be used for various drivers with varying lenghts of audit trail.
    // The user should start at the top of the list every time,
    // but probably has a persisting preference for page size.

    const [pageSize, setPageSize] = useLocalStorage<number>(
        "driverAuditListPageSize",
        25
    );
    const [pageNumber, setPageNumber] = useState<number>(1);

    // query params
    const [dateTimeRange, setDateTimeRange] = useState<{
        from: Date | null;
        to: Date | null;
    }>({
        from: null,
        to: null,
    });
    const [userId, setUserId] = useState<string>();
    // TODO add query and action to audits endpoint once implemented in api
    const [query, setQuery] = useState<string>("");
    const [actionFilter, setActionFilter] = useState<AuditAction | null>(null);

    const { auditsData, auditsError, isLoadingAudits } = useAudits(
        {
            entityName: "CompanyEntity",
            entityId: id,
            userId: userId,
            dateTimeRange: dateTimeRange,
            includeRelatedEntities: true,
        },
        pageNumber,
        pageSize
    );

    const { companyData } = useCompany(id);

    return (
        <div className="mx-auto w-full max-w-6xl">
            {isLoadingAudits && <LoadingMessage />}
            {auditsError && (
                <FormMessageBox
                    title={"error"}
                    message={
                        <span>
                            There was an error loading driver audit data.
                            <br />
                            Please try again in 10 minutes.
                        </span>
                    }
                    variant={"error"}
                />
            )}
            {!auditsError && !isLoadingAudits && auditsData && (
                <>
                    {companyData && (
                        <>
                            {/* Profile History Summary */}

                            <p className="pb-2 font-semibold">
                                Profile History
                            </p>

                            <div className="mb-8 border-b border-S2D-neutral-80"></div>

                            <div className="mb-8 rounded-sm border border-S2D-neutral-80 bg-S2D-dark-green-70.1 p-4">
                                <p className="pb-1 font-semibold">
                                    Date Created:{" "}
                                    <span className="font-light">
                                        {companyData.createdAt &&
                                            formatDateToString(
                                                new Date(companyData.createdAt),
                                                true
                                            )}
                                        {!companyData.createdAt && ""}
                                    </span>
                                </p>
                                <p className="pb-1 font-semibold">
                                    Date last edited:{" "}
                                    <span className="font-light">
                                        {companyData.updatedAt &&
                                            formatDateToString(
                                                new Date(companyData.updatedAt)
                                            )}
                                        {!companyData.updatedAt && ""}
                                    </span>
                                </p>
                            </div>
                        </>
                    )}
                    <AuditTable
                        auditList={auditsData}
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        searchQuery={query}
                        setSearchQuery={setQuery}
                        actionFilter={actionFilter}
                        setActionFilter={setActionFilter}
                        dateTimeRange={dateTimeRange}
                        setDateTimeRange={setDateTimeRange}
                        userId={userId}
                        setUserId={setUserId}
                    />
                </>
            )}
        </div>
    );
};

export default AdminAuditCompany;
