import {
    CartesianGrid,
    Line,
    LineChart as ReLineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { capitaliseWord } from "../../../utils/capitaliseWord";

type KeyItem<T> = {
    name: Exclude<keyof T, "name">;
    colour: string;
};

type DataItem<T> = {
    name: string;
} & T;

type LineChartProps<T> = {
    title?: string;
    ticks?: number[];
    domain?: [number, number];
    keys: KeyItem<T>[];
    data: DataItem<T>[];
};

const LineChart = <T,>({
    title,
    ticks,
    domain,
    keys,
    data,
}: LineChartProps<T>) => {
    return (
        <>
            {title && (
                <h3 className="text-xl font-bold mb-6 text-center">{title}</h3>
            )}
            <div className="flex">
                <div className="flex flex-wrap gap-3 mx-auto items-center pb-3 mb-5 border-b border-b-neutral-90">
                    {keys.map((key) => {
                        const name = String(key.name);
                        const capitalisedName = capitaliseWord(name)
                        return (
                            <div key={name} className="flex items-center gap-1">
                                <div
                                    className="rounded-full h-3 w-3"
                                    style={{ backgroundColor: key.colour }}
                                />
                                <span className="text-xs font-semibold">
                                    {capitalisedName}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>

            <ResponsiveContainer width="100%" height={260}>
                <ReLineChart
                    data={data}
                    margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                >
                    <CartesianGrid strokeDasharray="0" stroke="#E4E3DB" />
                    <XAxis
                        dataKey="name"
                        alignmentBaseline="central"
                        tickLine={false}
                        tick={{ fontSize: "0.55rem", fill: "#474741" }}
                        stroke="#E4E3DB"
                    />
                    <YAxis
                        domain={domain}
                        ticks={ticks}
                        tickLine={false}
                        tick={{ fontSize: "0.55rem", fill: "#474741" }}
                        stroke="#E4E3DB"
                        width={24}
                    />
                    <Tooltip />
                    {keys.map((key, i) => (
                            <Line
                                key={i}
                                dataKey={String(key.name)}
                                stroke={key.colour}
                                strokeWidth={2}
                            />
                    ))}
                </ReLineChart>
            </ResponsiveContainer>
        </>
    );
};

export default LineChart;
