import { Question } from "phosphor-react";
import { useState } from "react";
import DialogBox from "./DialogBox";

type FormInputMoreInfoProps = {
    moreInfo: {
        dialogPrompt?: string;
        title?: string;
        description?: string;
        content?: React.ReactNode;
    };
};

const FormInputMoreInfo: React.FC<FormInputMoreInfoProps> = ({
    moreInfo,
}: FormInputMoreInfoProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { dialogPrompt, title, description, content } = moreInfo;

    return (
        <>
            <div
                className="flex flex-row items-center gap-2 text-sm font-semibold text-S2D-dark-green-30 hover:cursor-pointer"
                onClick={() => setIsOpen(true)}
            >
                {dialogPrompt}
                <Question weight="fill" size={16.25} />
            </div>

            <DialogBox
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={title}
                description={description}
                closeButton
            >
                <div className="m-4">{content}</div>
            </DialogBox>
        </>
    );
};

export default FormInputMoreInfo;
