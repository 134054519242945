import { Controller, useForm, useWatch } from "react-hook-form";
import AccordionItem from "../AccordionItem";
import {
    type NewTransferFormValues,
    NewTransferValidationSchema,
    newTransferFormDefaultValues,
} from "./NewTransferFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormFieldCollectiveValidation } from "../../hooks/useFormFieldCollectiveValidation";
import { useGetCompanies } from "../../api/admin/company";
import FormDropdown from "../FormDropdown";
import {
    capitalisedTermForCompany,
    termForCompany,
} from "../../copy/sharedCopy";
import FormTextAreaInput from "../FormTextAreaInput";
import FormMultipleFileUploader from "../FormMultipleFileUploader";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../Routes";
import Button from "../Button";
import { useAdminGetCompanyUsers } from "../../api/admin/companyUsers";
import { UserRoles } from "../../auth/decodeToken";
import FormMessageBox from "../FormMessageBox";
import { FileTransferUser } from "../../models/api/admin/fileSafe";

type NewTransferFormProps = {
    onSubmit: (data: NewTransferFormValues) => void;
    isLoading: boolean;
    userRole: UserRoles;
    recipient: FileTransferUser | null;
};

const NewTransferForm: React.FC<NewTransferFormProps> = ({
    onSubmit,
    isLoading,
    userRole,
    recipient,
}) => {
    const methods = useForm<NewTransferFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(NewTransferValidationSchema(userRole)),
        defaultValues: {
            ...newTransferFormDefaultValues,
            recipient:
                recipient?.userId ?? newTransferFormDefaultValues.recipient,
        },
    });

    const isSectionValid =
        useFormFieldCollectiveValidation<NewTransferFormValues>(
            ["company", "recipient", "notes", "files"],
            methods
        );

    const { companyList } = useGetCompanies(1, 100);
    const companyOptions = new Array(0);
    companyList?.items.map((company) =>
        companyOptions.push({
            label: company.companyName,
            value: company.companyId,
        })
    );

    const control = methods.control;
    const companyId = useWatch({ control, name: "company" });

    const handleSelectCompany = (company: string) => {
        methods.setValue("recipient", "");
        methods.setValue("company", company);
        refetchCompanyUsers();
    };

    const canChooseRecipient =
        userRole === UserRoles.s2dAdmin || userRole === UserRoles.s2dMember;

    const { companyUsersData, companyUsersError, refetchCompanyUsers } =
        useAdminGetCompanyUsers(companyId, true, canChooseRecipient);

    const userOptions = new Array(0);
    companyUsersData?.map((user) =>
        userOptions.push({
            label: `${user.firstName} ${user.lastName}`,
            value: user.userId,
        })
    );

    const fileArray = useWatch({ control, name: "files" });

    const handleAddFileToArray = (file: File) => {
        const newFileArray: File[] = fileArray;
        newFileArray.push(file);
        methods.setValue("files", newFileArray);
    };

    const handleRemoveFileFromArray = (index: number) => {
        const newFileArray = new Array(0);
        fileArray.map((file, i) => {
            if (index !== i) {
                newFileArray.push(file);
            }
        });
        methods.setValue("files", newFileArray);
    };

    const navigate = useNavigate();

    const handleCancel = () => {
        methods.reset();
        navigate(appPaths.adminFileSafe);
    };

    return (
        <form onSubmit={methods.handleSubmit(onSubmit)}>
            <AccordionItem
                title="Transfer details"
                error={!isSectionValid}
                defaultOpen
            >
                <div className="flex flex-col gap-4">
                    {canChooseRecipient && (
                        <>
                            <Controller
                                name="company"
                                control={methods.control}
                                render={({ field: { value } }) => (
                                    <FormDropdown
                                        onChange={(company) =>
                                            handleSelectCompany(company)
                                        }
                                        value={value}
                                        options={companyOptions}
                                        label={capitalisedTermForCompany}
                                        pleaseSelectText={`Select ${termForCompany}`}
                                        error={methods.formState.errors.company}
                                        required
                                    />
                                )}
                            />
                            {companyUsersError ? (
                                <FormMessageBox
                                    variant="error"
                                    title={`Error`}
                                    message="Could not load recipients, please try again later."
                                />
                            ) : (
                                <Controller
                                    name="recipient"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDropdown
                                            onChange={onChange}
                                            value={value}
                                            options={
                                                userOptions.length
                                                    ? userOptions
                                                    : []
                                            }
                                            label="Recipient"
                                            pleaseSelectText={`Select recipient`}
                                            noOptionsText={
                                                companyId
                                                    ? `${capitalisedTermForCompany} has no users`
                                                    : `Must select a ${termForCompany} before selecting a recipient.`
                                            }
                                            error={
                                                methods.formState.errors
                                                    .recipient
                                            }
                                            required
                                        />
                                    )}
                                />
                            )}
                        </>
                    )}
                    <FormTextAreaInput
                        label="Notes"
                        id="notes"
                        placeholder="Enter notes"
                        error={methods.formState.errors.notes}
                        {...methods.register("notes")}
                    />
                    <FormMultipleFileUploader
                        handleAddFile={handleAddFileToArray}
                        handleRemoveFile={handleRemoveFileFromArray}
                        fileArray={fileArray}
                        label="Drop file here to upload or"
                        error={methods.formState.errors.files?.message}
                    />
                </div>
            </AccordionItem>
            <div className="flex flex-row items-center justify-between p-8">
                <Button
                    variant="tertiary"
                    type="button"
                    className="w-full justify-center md:w-auto"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    className="w-full justify-center md:w-auto"
                    loading={isLoading}
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default NewTransferForm;
