import {
    CompanyDriverAnalysisResponse,
    DriverAnalysisCategories,
} from "../../models/api/admin/companyDriverAnalysis";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useGetCompanyDriverAnalysis = (
    companyId?: string,
    departmentId?: string,
    professional?: boolean,
    category?: DriverAnalysisCategories
) => {
    const pathArray = [`api/companies/driverAnalysis`];
    const pushConnector = () =>
        pathArray.length === 1 ? pathArray.push("?") : pathArray.push("&");

    if (companyId) {
        pushConnector();
        pathArray.push(`CompanyId=${companyId}`);
    }
    if (departmentId) {
        pushConnector();
        pathArray.push(`DepartmentId=${departmentId}`);
    }
    if (professional) {
        pushConnector();
        pathArray.push(`IsProfessional=${professional}`);
    }
    if (category) {
        pushConnector();
        pathArray.push(`Includes=${category}`);
    }
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<CompanyDriverAnalysisResponse>(pathArray.join(""));

    return {
        driverAnalysisData: data,
        isDriverAnalysisLoading: isLoading,
        driverAnalysisError: error,
        refetchDriverAnalysis: refetch,
    };
};
