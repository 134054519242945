import {
    PasswordResetFormSchema,
    PasswordResetFormValues,
} from "./PasswordResetFormValues";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../FormTextInput";
import Button from "../Button";
import usePasswordValidation from "../../hooks/usePasswordValidation";

export type PasswordResetFormProps = {
    onSubmit: (data: PasswordResetFormValues) => void;
    onClose: () => void;
    isLoading: boolean;
};

const validationSchema = Yup.object().shape(PasswordResetFormSchema);

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({
    onSubmit,
    onClose,
    isLoading,
}) => {
    const { handleSubmit, register, formState, trigger, watch } =
        useForm<PasswordResetFormValues>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues: {
                existingPassword: "",
                newPassword: "",
                confirmNewPassword: "",
            },
        });

    usePasswordValidation({
        watch,
        trigger,
        passwordField: "newPassword",
        confirmPasswordField: "confirmNewPassword",
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p className="pb-4 font-semibold">RESET PASSWORD</p>
            <FormTextInput
                type="password"
                label="Current password"
                required
                id="existingPassword"
                placeholder="Enter current password"
                error={formState.errors.existingPassword}
                {...register("existingPassword")}
            />
            <FormTextInput
                type="password"
                label="New password"
                required
                id="newPassword"
                placeholder="Enter new password"
                error={formState.errors.newPassword}
                {...register("newPassword")}
            />
            <FormTextInput
                type="password"
                label="Confirm new password"
                required
                id="confirmNewPassword"
                placeholder="Re-enter new password"
                error={formState.errors.confirmNewPassword}
                {...register("confirmNewPassword")}
            />
            <div className="mt-8 flex justify-end gap-4">
                <Button type="button" variant="tertiary" onClick={onClose}>
                    Cancel
                </Button>

                <Button type="submit" loading={isLoading}>
                    Submit
                </Button>
            </div>
        </form>
    );
};

export default PasswordResetForm;
