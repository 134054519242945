import { Dispatch, SetStateAction, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import { appPaths } from "../../Routes";
import Button from "../Button";
import EditableContentBlock from "../EditableContentBlock";
import LinkedDriverSearchDropdown, {
    LinkedDriver,
} from "../LinkedDriverSearchDropdown";
import {
    EditDriverFormValues,
    driverLinkedDriverValidationSchema,
} from "./EditDriverFormValues";
import * as yup from "yup";
import { resolverDebugger } from "../../utils/yupResolverDebugger";
import { useStaleDataFormReset } from "../../hooks/useStaleDataFormReset";

type EditDriverLinkedDriverProps = {
    initialValues: EditDriverFormValues;
    isLoading: boolean;
    onSubmit: (data: EditDriverFormValues, callback: () => void) => void;
};

const validationSchema = yup.object().shape(driverLinkedDriverValidationSchema);

const EditDriverLinkedDriver: React.FC<EditDriverLinkedDriverProps> = ({
    initialValues,
    isLoading,
    onSubmit,
}) => {
    const methods = useForm<EditDriverFormValues>({
        mode: "onSubmit",
        resolver: (data, context, options) =>
            resolverDebugger(data, context, options, validationSchema),
        defaultValues: initialValues,
    });

    const [linkedDriverString, setLinkedDriverString] = useState(
        initialValues.primaryDriverName
            ? `${initialValues.primaryDriverName}`
            : ""
    );

    const handleLinkedDriverValues = (linkedDriver: LinkedDriver) => {
        const primaryDriverName = linkedDriver.firstName
            ? `${linkedDriver.firstName} ${linkedDriver.lastName}`
            : null;
        methods.setValue("primaryDriverId", linkedDriver.driverId);
        methods.setValue("primaryDriverName", primaryDriverName);
        methods.trigger(["primaryDriverId", "primaryDriverName"]);
    };

    const handleLinkedDriverSelect = (value: string | null) => {
        if (!value) {
            setLinkedDriverString("");
            handleLinkedDriverValues({
                driverId: "",
                firstName: null,
                lastName: null,
            });
        } else {
            const selected: string[] = value.split(" ");
            const primaryDriver: LinkedDriver = {
                driverId: selected[0],
                firstName: selected[1],
                lastName: selected[2],
            };
            setLinkedDriverString(
                `${primaryDriver.firstName} ${primaryDriver.lastName}`
            );
            handleLinkedDriverValues(primaryDriver);
        }
    };

    const handleFormSubmit = async (
        values: EditDriverFormValues,
        setIsEdit: Dispatch<SetStateAction<boolean>>
    ) => {
        onSubmit(values, () => setIsEdit(false));
    };

    const control = methods.control;
    const currentForm = useWatch({ control });

    const linkedDrivers = initialValues.linkedDrivers
        ? Object.entries(initialValues.linkedDrivers)
        : undefined;

    useStaleDataFormReset(control, methods.reset, initialValues);

    return (
        <EditableContentBlock title="Linked Driver">
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            {!initialValues.primaryDriverId && (
                                <>
                                    <p>
                                        <b>
                                            {initialValues.firstName}{" "}
                                            {initialValues.lastName} is the main
                                            driver
                                        </b>{" "}
                                    </p>
                                </>
                            )}
                            {initialValues.primaryDriverId && (
                                <Link
                                    to={appPaths.adminEditDriver(
                                        initialValues.primaryDriverId
                                    )}
                                >
                                    <p>
                                        <b className="underline">
                                            {initialValues.primaryDriverName ??
                                                initialValues.primaryDriverId}
                                        </b>{" "}
                                        is the main driver.
                                    </p>
                                </Link>
                            )}
                            {linkedDrivers ? (
                                <div className="flex w-full flex-row flex-wrap gap-2">
                                    <p>Linked drivers:</p>
                                    {linkedDrivers.map((driver, i) => (
                                        <Link
                                            to={appPaths.adminEditDriver(
                                                driver[0]
                                            )}
                                        >
                                            <p>
                                                <u>{driver[1]}</u>
                                                {i !==
                                                    linkedDrivers.length - 1 &&
                                                    ","}
                                            </p>
                                        </Link>
                                    ))}
                                </div>
                            ) : (
                                <p>No linked Drivers</p>
                            )}
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit((values) =>
                                    handleFormSubmit(values, setIsEdit)
                                )}
                            >
                                <div className="w-full">
                                    <LinkedDriverSearchDropdown
                                        value={linkedDriverString}
                                        onChange={handleLinkedDriverSelect}
                                        companyId={
                                            initialValues.company?.companyId ??
                                            undefined
                                        }
                                    />
                                </div>
                                <div className="mt-8 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => {
                                            setIsEdit(false);
                                            methods.reset({
                                                ...currentForm,
                                                primaryDriverId:
                                                    initialValues.primaryDriverId,
                                            });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" loading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditDriverLinkedDriver;
