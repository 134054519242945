import { useEffect } from "react";

const useWarnBeforeUnload = (prompt?: boolean) => {
    useEffect(() => {
        const eventListener = (event: BeforeUnloadEvent) => {
            if (prompt) {
                event.preventDefault();
                event.returnValue = "";
            }
        };
        window.addEventListener("beforeunload", eventListener);
        return () => {
            window.removeEventListener("beforeunload", eventListener);
        };
    }, []);
};

export default useWarnBeforeUnload;
