import { type S2DApiPaginatedData } from "../../../api/common/apiResponse";
import { CheckStatus, CheckType } from "../../shared/statuses";

export type ApiDvlaChecksResponse = S2DApiPaginatedData<DvlaCheckItem>;

export type DvlaCheckItem = {
    driverId: string;
    firstName: string;
    lastName: string;
    companyName: string;
    departmentName: string | null;
    totalPoints: number | null;
    lastCheckDate: string | null;
    nextCheckDate: string | null;
    licenceNumber: string | null;
    licenceCountryOrigin: string | null;
    checkType: CheckType;
    checkStatus: CheckStatus;
};

export enum DvlaCheckOrderBy {
    FirstName = "FirstName",
    LastName = "LastName",
    LicenceNumber = "LicenceNumber",
    DateOfBirth = "DateOfBirth",
    EmailAddress = "EmailAddress",
    EmployeeId = "EmployeeId",
    ExternalReference = "ExternalReference",
    Postcode = "AddressPostcode",
    Company = "Company.CompanyName",
    Department = "Department.DepartmentName",
}

export type DvlaCheckFilters = {
    companyId?: string | null;
    departmentId?: string | null;
    search?: string | null;
    ukDvlaChecks?: UkDvlaChecksFilters;
    ukDvlaCheckErrors?: UkDvlaCheckErrorsFilters;
    ukManualChecks?: ManualChecksFilters;
    northernIrishManualChecks?: ManualChecksFilters;
    foreignManualChecks?: ManualChecksFilters;
};

export const ukDvlaCheckFilterKeys = [
    "checksComplete",
    "checksScheduled",
    "checksOverdue",
];
// using [number] gives us the type of an array's elements
// combining with typeof, we get the element type of that array
export type UkDvlaChecksFilters = {
    [key: (typeof ukDvlaCheckFilterKeys)[number]]: boolean | null;
};

export const ukDvlaCheckErrorFilterKeys = [
    "error400",
    "error403",
    "error429",
    "error500",
    "error503",
    "errorOther",
];
export type UkDvlaCheckErrorsFilters = {
    [key: (typeof ukDvlaCheckErrorFilterKeys)[number]]: boolean | null;
};

export const manualCheckFilterKeys = [
    "checksComplete",
    "checksPending",
    "checksScheduled",
    "checksOverdue",
];
export type ManualChecksFilters = {
    [key: (typeof manualCheckFilterKeys)[number]]: boolean | null;
};
