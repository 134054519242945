import { UseFormReturn } from "react-hook-form";
import { DriversListFiltersFormValues } from "./DriversListFiltersFormValues";
import { useGetCompanies } from "../../../api/admin/company";
import FormTextInput from "../../FormTextInput";
import { type Dispatch, type SetStateAction } from "react";
import { generateSelectedValues } from "./generateSelectedValues";
import AccordionItem from "../../AccordionItem";

type FilterEmploymentProps = {
    methods: UseFormReturn<DriversListFiltersFormValues>;
    selectedValues: string[];
    setSelectedValues: Dispatch<SetStateAction<string[]>>;
};

const FilterEmployment: React.FC<FilterEmploymentProps> = ({
    methods,
    selectedValues,
    setSelectedValues,
}) => {
    const { companyList } = useGetCompanies(1, 100);

    const companyOptions = new Array(0);
    //  24/05/2023 setting value to an array for now so we don't have to mess around with types :)
    companyList?.items.map((company) => {
        companyOptions.push({
            label: company?.companyName,
            value: [company?.companyId],
        });
    });

    return (
        <AccordionItem
            title="Employment"
            valueSummary={selectedValues}
            modalStyling
        >
            <FormTextInput
                id="employeeId"
                label="Employee number contains"
                placeholder="Enter text"
                error={methods.formState.errors.company?.employeeId}
                {...methods.register("company.employeeId")}
                onBlur={(e) =>
                    generateSelectedValues(
                        selectedValues,
                        setSelectedValues,
                        "Employee id",
                        e.target.value
                    )
                }
            />
        </AccordionItem>
    );
};

export default FilterEmployment;
