import { useState } from "react";
import Divider from "../../../../components/ReusableComponents/Divider";
import LoadingMessage from "../../../../components/ReusableComponents/LoadingMessage/LoadingMessage";
import CompanyUsersTab from "../../../../components/CompanyProfile/CompanyUsersTab";
import CompanyBillingTab from "../../../../components/CompanyProfile/CompanyBillingTab/CompanyBillingTab";
import CompanyCheckSettingsTab from "../../../../components/CompanyProfile/CompanyCheckSettingsTab/CompanyCheckSettingsTab";
import ProfileTabs from "../../../../components/ReusableComponents/ProfileTabs";
import { EditCompanyFormValues } from "../../../../components/EditCompanyForms/EditCompanyFormValues";
import CompanyDashboard from "../../../../components/CompanyDashboard";
import CompanyIntegrationsTab from "../../../../components/CompanyProfile/CompanyIntegrationsTab";
import DownloadReportButton from "../../../../components/ReusableComponents/DownloadReportButton";
import DepartmentDropdown from "../../../../components/ReusableComponents/CompanyDepartmentSelector/DepartmentDropdown";
import { type ApiCompanyResponse } from "../../../../models/api/admin/company";
import { type ApiDepartmentItem } from "../../../../models/api/admin/department";
import Button from "../../../../components/Button";
import CompanySecurityTab from "../../../../components/CompanyProfile/CompanySecurityTab/CompanySecurityTab";
import CompanyCommentsTab from "../../../../components/CompanyProfile/CompanyCommentsTab/CompanyCommentsTab";
import CompanyAccountTab from "../../../../components/CompanyProfile/CompanyAccountTab/CompanyAccountTab";
import CompaniesPdfReport from "../../../../components/AdminCompaniesPage/CompaniesPdfReport";
import { useCompanyProfileStats } from "../../../../components/CompanyDashboard/useCompanyProfileStats";
import CompanyAppearanceTab from "../../../../components/CompanyProfile/CompanyAppearanceTab/CompanyAppearanceTab";
import { isS2dAdminOrMember } from "../../../../auth/userAccessHelpers";
import useAuth from "../../../../auth";

interface Props {
    companyData: ApiCompanyResponse | undefined;
    isLoadingCompany: boolean;
    refetchCompany: () => void;
    isEditingCompany: boolean;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetFormCallback: () => void
    ) => Promise<void>;
    id: string | undefined;
}

export enum CompanySectionTabs {
    Account = "Account",
    Appearance = "Appearance",
    CheckSettings = "Check Settings",
    Billing = "Billing",
    Users = "Users",
    Security = "Security",
    Integrations = "Integrations",
    Comments = "Comments",
}

const sectionTabs: CompanySectionTabs[] = Object.values(CompanySectionTabs);

export default function CompanyProfile({
    companyData,
    isLoadingCompany,
    refetchCompany,
    isEditingCompany,
    handleEditCompany,
    id,
}: Props) {
    const { decodedToken } = useAuth();
    const s2dAdminOrMember = isS2dAdminOrMember(decodedToken);
    const canViewSettingsSection = s2dAdminOrMember;

    const [selectedTab, setSelectedTab] = useState<string>(
        CompanySectionTabs.Account
    );
    const [selectedDepartment, setSelectedDepartment] = useState<
        ApiDepartmentItem | null | undefined
    >(undefined);

    const {
        tabOption,
        setTabOption,
        companyStatsData,
        pdfStatsData,
        isLoadingPdfReport,
        reportRef,
        showReportView,
        handleDownloadPdfReport,
    } = useCompanyProfileStats(companyData, selectedDepartment);

    return (
        <>
            <div>
                <div className="mb-6 flex flex-col flex-wrap justify-between gap-6 md:flex-row ">
                    <div className="flex flex-col gap-6 md:flex-row">
                        <DepartmentDropdown
                            companyId={id}
                            currentDepartment={selectedDepartment}
                            handleDepartmentChange={setSelectedDepartment}
                        />
                        {selectedDepartment && (
                            <Button
                                variant="tertiary"
                                disabled={!selectedDepartment}
                                onClick={() => setSelectedDepartment(undefined)}
                                className="w-full min-w-fit justify-center md:w-fit md:flex-none"
                            >
                                Reset
                            </Button>
                        )}
                    </div>
                    <div className="w-full md:w-fit">
                        <DownloadReportButton
                            handleDownloadPdfReport={handleDownloadPdfReport}
                            handleDownloadCsvReport={() =>
                                alert("Download report not implemented")
                            }
                            isReportDownloading={isLoadingPdfReport}
                        />
                    </div>
                </div>
                <CompanyDashboard
                    companyStatsData={companyStatsData}
                    tabOption={tabOption}
                    setTabOption={setTabOption}
                    removeSummary={true}
                />
                {canViewSettingsSection && (
                    <>
                        <Divider text="Settings" />
                        <ProfileTabs
                            selected={selectedTab}
                            onSelect={setSelectedTab}
                            options={sectionTabs}
                        />
                        {selectedTab === "Account" && (
                            <>
                                {!isLoadingCompany && companyData && (
                                    <>
                                        <CompanyAccountTab
                                            initialValues={companyData}
                                            refetchCompany={refetchCompany}
                                            isEditingCompany={isEditingCompany}
                                            handleEditCompany={
                                                handleEditCompany
                                            }
                                        />
                                    </>
                                )}
                                {isLoadingCompany && <LoadingMessage />}
                            </>
                        )}
                        {selectedTab === CompanySectionTabs.Appearance && (
                            <CompanyAppearanceTab />
                        )}
                        {selectedTab === "Check Settings" &&
                            !isLoadingCompany &&
                            companyData && (
                                <CompanyCheckSettingsTab
                                    initialValues={companyData}
                                    handleEditCompany={handleEditCompany}
                                />
                            )}
                        {selectedTab === "Billing" && id && (
                            <CompanyBillingTab companyId={id} companyName={companyData?.companyName ?? ""} parentId={companyData?.resellerId} departmentId={selectedDepartment?.departmentId ?? ""} companyCreationDate={companyData?.createdAt} companyInvoiceSettings={companyData?.settings?.invoiceSettings} refetchCompany={refetchCompany} />
                        )}
                        {selectedTab === "Users" && (
                            <CompanyUsersTab
                                companyId={id}
                                companyName={companyData?.companyName}
                            />
                        )}
                        {selectedTab === "Security" && (
                            <CompanySecurityTab
                                initialValues={companyData}
                                handleEditCompany={handleEditCompany}
                            />
                        )}
                        {selectedTab === "Integrations" && (
                            <CompanyIntegrationsTab companyId={id} />
                        )}
                        {selectedTab === "Comments" && (
                            <CompanyCommentsTab companyId={id} />
                        )}
                    </>
                )}
            </div>

            {showReportView && (
                <div ref={reportRef}>
                    <CompaniesPdfReport
                        companyStatsData={pdfStatsData}
                        removeSummary={true}
                        companyName={companyData?.companyName}
                        departmentName={undefined}
                    />
                </div>
            )}
        </>
    );
}
