import {
    ApiFiltersListResponse,
    ApiFiltersResponse,
    ApiParsedFiltersResponse,
} from "../../models/api/admin/filter";
import { AddFilterDto } from "../../models/app/admin/filter";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

const useAddFilter = () =>
    useS2DApiFetcher<AddFilterDto, string, { dto: AddFilterDto }>(
        "POST",
        ({ dto }) => ({
            url: "api/filters",
            body: dto,
        }),
        [],
        true
    );
const useEditFilter = () =>
    useS2DApiFetcher<
        AddFilterDto,
        null,
        { dto: AddFilterDto; filterId: string }
    >(
        "PUT",
        ({ dto, filterId }) => ({
            url: `api/filters/${filterId}`,
            body: dto,
        }),
        [],
        true
    );
const useDeleteFilter = () =>
    useS2DApiFetcher<void, string, { filterId: string }>(
        "DELETE",
        ({ filterId }) => ({
            url: `api/filters/${filterId}`,
        }),
        [],
        true
    );

export const useFilter = (id?: string) => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiFiltersResponse>(id ? `api/filters/${id}` : null);

    const parsedFilter = data ? JSON.parse(data.filterJson) : null;
    const filter: ApiParsedFiltersResponse =
        data && parsedFilter
            ? {
                  filterId: data.filterId,
                  name: data.name,
                  userIds: data.userIds,
                  filterDetails: { ...parsedFilter },
              }
            : null;

    const [addFilter, isAddingFilter] = useAddFilter();
    const [editFilter, isEditingFilter] = useEditFilter();
    const [deleteFilter, isDeletingFilter] = useDeleteFilter();

    return {
        filter,
        filterError: error,
        filterIsLoading: isLoading,
        refetchFilter: refetch,
        addFilter: async (dto: AddFilterDto) => await addFilter({ dto }),
        isAddingFilter,
        editFilter: async (dto: AddFilterDto, filterId: string) =>
            await editFilter({ dto, filterId }),
        isEditingFilter,
        deleteFilter: async (filterId: string) =>
            await deleteFilter({ filterId }),
        isDeletingFilter,
    };
};

export const useGetFilters = () => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiFiltersListResponse>("api/filters");

    const filters = new Array(0);
    data?.map((d) => {
        const parsedFilter = JSON.parse(d.filterJson);
        filters.push({
            filterId: d.filterId,
            name: d.name,
            filterDetails: parsedFilter,
            userIds: d.userIds,
        });
    });
    return {
        filters,
        filtersError: error,
        isfiltersLoading: isLoading,
        refetchFilters: refetch,
    };
};
