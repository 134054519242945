import { useState } from "react";
import { Button as MuiButton, Menu, MenuItem } from "@mui/material";
import { CaretUp, CaretDown, Check } from "phosphor-react";
import clsx from "clsx";

export type FilterOption<FilterType> = {
    value: FilterType;
    label: string;
};

export type FilterDropdownProps<FilterType> = {
    selectedFilter: FilterType | null;
    filterOptions: FilterOption<FilterType>[];
    handleFilterChange: (filter: FilterType | null) => void;
    disabled?: boolean;
    label: string;
};

//https://devtrium.com/posts/react-typescript-using-generics-in-react#generics-syntax-for-arrow-functions-in-jsx
const FilterDropdown = <FilterType,>({
    selectedFilter,
    filterOptions,
    handleFilterChange,
    disabled = false,
    label,
}: FilterDropdownProps<FilterType>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleDropdownSelect = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilterSelect = () => {
        setAnchorEl(null);
    };

    const handleFilterSelect = (filter: FilterOption<FilterType> | null) => {
        if (filter) {
            handleFilterChange(filter.value);
            handleCloseFilterSelect();
        }
    };

    const selected = filterOptions.find(
        (filter) => selectedFilter === filter.value
    );

    const isDisabled = disabled || !filterOptions.length;

    return (
        <>
            <MuiButton
                id="button1"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleDropdownSelect}
                disableRipple
                sx={{ textTransform: "none", padding: 0 }}
                disabled={isDisabled}
            >
                <div
                    className={clsx(
                        `flex h-12 min-w-fit grow items-center justify-between gap-4 rounded-sm border-2 border-black px-6
                         font-sans text-base font-semibold text-S2D-neutral-10 transition-all hover:bg-S2D-surface-light-4 
                         focus:outline-none focus:ring-1 focus:ring-black`,
                        open && "bg-S2D-surface-light-4",
                        isDisabled && "cursor-not-allowed opacity-40"
                    )}
                >
                    <div className="max-w-[70px] truncate">
                        {selected?.label ?? label}
                    </div>
                    <div>
                        {open ? (
                            <CaretUp size={20} weight="fill" />
                        ) : (
                            <CaretDown size={20} weight="fill" />
                        )}
                    </div>
                </div>
            </MuiButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseFilterSelect}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    disablePadding: true,
                }}
                disableScrollLock
            >
                {filterOptions.map((filter) =>
                    dropdownOption(
                        filter,
                        selectedFilter || null,
                        handleFilterSelect
                    )
                )}
            </Menu>
        </>
    );
};

const dropdownOption = <FilterType,>(
    filter: FilterOption<FilterType>,
    selectedFilter: FilterType | null,
    handleFilterSelect: (filter: FilterOption<FilterType> | null) => void
) => {
    const isSelected = selectedFilter === filter.value;
    return (
        <MenuItem
            sx={{
                "&:hover": {
                    backgroundColor: "var(--secondary)",
                    color: "var(--button-text)",
                },
                height: 48,
                minWidth: 203,
            }}
            key={filter.label + " " + filter.value}
            onClick={() => {
                handleFilterSelect(filter);
            }}
        >
            <div className="flex w-full flex-row items-center justify-between pl-1">
                {filter.label}
                <div className="pl-3">
                    {isSelected && <Check weight="fill" />}
                </div>
            </div>
        </MenuItem>
    );
};

export default FilterDropdown;
