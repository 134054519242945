import { type GetAllVehiclesFilterFields } from "../../models/app/admin/vehicles";

export const vehicleFilterDefaultValues: Required<GetAllVehiclesFilterFields> =
    {
        vehicleAnalysis: {
            co2Emissions: {
                lowEmissions: null,
                mediumEmissions: null,
                highEmissions: null,
            },
            fuelType: {
                petrol: null,
                diesel: null,
                hybrid: null,
                electric: null,
                other: null,
            },
            ageRange: {
                zeroToTwoYears: null,
                threeToFiveYears: null,
                sixToTenYears: null,
                elevenToFifteen: null,
                sixteenToTwenty: null,
                twentyOneAndOver: null,
            },
            manufacturer: {
                name: null,
            },
            engineCapacity: {
                unknownCapacity: null,
                zeroToOnePointTwo: null,
                onePointTwoToOnePointEight: null,
                onePointEightToTwoPointFive: null,
                twoPointFiveAndOver: null,
            },
            euroRating: {
                euro1: null,
                euro2: null,
                euro3: null,
                euro4: null,
                euro5: null,
                euro6: null,
            },
            insuranceType: {
                fullComprehensive: null,
                thirdParty: null,
            },
            businessCover: {
                withBusinessCover: null,
                withoutBusinessCover: null,
            },
        },
        companyFilter: {
            companyIds: null,
            departmentIds: null,
        },
    };

export const vehicleFilterNulledValues: Required<GetAllVehiclesFilterFields> = {
    vehicleAnalysis: {
        co2Emissions: null,
        fuelType: null,
        ageRange: null,
        manufacturer: null,
        engineCapacity: null,
        euroRating: null,
        insuranceType: null,
        businessCover: null,
    },
    companyFilter: {
        companyIds: null,
        departmentIds: null,
    },
};
