import { termForCompany } from "../../copy/sharedCopy";
import { type ApiFileSafeItem } from "../../models/api/admin/fileSafe";
import { formatDateToString } from "../../utils/formatDateToString";

export type TransferDetailsProps = {
    data: ApiFileSafeItem;
};

const TransferDetails: React.FC<TransferDetailsProps> = ({ data }) => {
    return (
        <div>
            <div className="flex h-16 w-full items-center bg-S2D-neutral-30 px-6 text-S2D-neutral-100">
                <h2 className="text-2xl">Transfer details</h2>
            </div>
            <div className="rounded-b-sm border border-t-0 border-S2D-neutral-80 bg-white p-6">
                <div className="flex items-start justify-between">
                    <div className="flex flex-col items-start">
                        <div className="mb-4 flex flex-col">
                            <span className="inline-block text-xs leading-tight text-S2D-neutral-40">
                                USER
                            </span>
                            {data?.senderUser.firstName
                                ? data.senderUser.firstName
                                : "-"}{" "}
                            {data?.senderUser.lastName
                                ? data.senderUser.lastName
                                : "-"}
                        </div>
                        <div className="mb-4 flex flex-col">
                            <span className="inline-block text-xs leading-tight text-S2D-neutral-40">
                                {termForCompany.toUpperCase()}
                            </span>
                            <span>{data?.client ? data.client : "-"}</span>
                        </div>
                        <div className="flex flex-col">
                            <span className="inline-block text-xs leading-tight text-S2D-neutral-40">
                                NOTES
                            </span>
                            <span>{data?.notes ? data.notes : "-"}</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-start">
                        <div className="mb-4 flex flex-col">
                            <span className="inline-block text-xs leading-tight text-S2D-neutral-40">
                                SENT
                            </span>
                            {data?.createdAt
                                ? formatDateToString(
                                    new Date(data.createdAt),
                                    true
                                )
                                : "-"}
                        </div>
                        <div className="flex flex-col">
                            <span className="inline-block text-xs leading-tight text-S2D-neutral-40">
                                STATUS
                            </span>
                            <span>
                                {data?.statusDescription
                                    ? data.statusDescription
                                    : "-"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransferDetails;
