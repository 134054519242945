import { DriverVehicleInformation } from "../../../models/api/admin/driverDetails";
import { formatDateToString } from "../../../utils/formatDateToString";
import { TableCellData, TableHeader } from "../Table";

export type DriverVehicleTableContentAllowedIds =
    | "vrm"
    | "make"
    | "model"
    | "euroRating"
    | "age"
    | "motExpiry"
    | "roadTaxExpiry"
    | "co2Emissions"
    | "fuelType"
    | "insuranceType"
    | "businessCover"
    | "engineCapacity";

export const driverVehicleTableHeaders: TableHeader<DriverVehicleTableContentAllowedIds>[] =
    [
        {
            title: "Registration No",
            id: "vrm",
            menuGroup: "Vehicle information",
            hide: false,
        },
        {
            title: "Manufacturer",
            id: "make",
            menuGroup: "Vehicle information",
            hide: true,
        },
        {
            title: "Euro rating",
            id: "euroRating",
            menuGroup: "Vehicle information",
            hide: true,
        },
        {
            title: "Model",
            id: "model",
            menuGroup: "Vehicle information",
            hide: true,
        },
        {
            title: "Year of Manufacture",
            id: "age",
            menuGroup: "Vehicle information",
            hide: true,
        },
        {
            title: "MOT Expiry",
            id: "motExpiry",
            menuGroup: "Vehicle information",
            hide: true,
        },
        {
            title: "Road Tax Expiry",
            id: "roadTaxExpiry",
            menuGroup: "Vehicle information",
            hide: true,
        },
        {
            title: "CO2 Emissions",
            id: "co2Emissions",
            menuGroup: "Vehicle information",
            hide: true,
        },
        {
            title: "Fuel type",
            id: "fuelType",
            menuGroup: "Vehicle information",
            hide: true,
        },
        {
            title: "Insurance type",
            id: "insuranceType",
            menuGroup: "Vehicle information",
            hide: true,
        },
        {
            title: "Business cover",
            id: "businessCover",
            menuGroup: "Vehicle information",
            hide: true,
        },
        {
            title: "Engine capacity",
            id: "engineCapacity",
            menuGroup: "Vehicle information",
            hide: true,
        },
    ];

export const driverVehicleTableContent = (
    vehicles: DriverVehicleInformation | null
): TableCellData<DriverVehicleTableContentAllowedIds>[] => [
    {
        id: "vrm",
        content: (
            <p>
                {vehicles?.vehicleRegistration?.map((vrm) => vrm)?.join(", ")}
            </p>
        ),
    },
    {
        id: "make",
        content: <p>{vehicles?.manufacturer?.map((man) => man)?.join(", ")}</p>,
    },
    {
        id: "model",
        content: (
            <p>
                {vehicles?.model
                    ?.map((model) => {
                        if (model) {
                            return model;
                        } else {
                            return "unknown";
                        }
                    })
                    ?.join(", ")}
            </p>
        ),
    },
    {
        id: "euroRating",
        content: (
            <p>
                {vehicles?.euroRating
                    ?.map((euroRating) => {
                        if (euroRating) {
                            return euroRating;
                        } else {
                            return "unknown";
                        }
                    })
                    ?.join(", ")}
            </p>
        ),
    },
    {
        id: "age",
        content: (
            <p>
                {vehicles?.yearOfManufacture
                    ?.map((yearOfManufacture) => {
                        if (yearOfManufacture) {
                            return yearOfManufacture;
                        } else {
                            return "unknown";
                        }
                    })
                    ?.join(", ")}
            </p>
        ),
    },
    {
        id: "motExpiry",
        content: (
            <p>
                {vehicles?.motExpiry
                    ?.map((motExpiry) => {
                        if (motExpiry) {
                            return formatDateToString(new Date(motExpiry));
                        } else {
                            return "";
                        }
                    })
                    ?.join(", ")}
            </p>
        ),
    },
    {
        id: "roadTaxExpiry",
        content: (
            <p>
                {vehicles?.roadTaxExpiry
                    ?.map((roadTaxExpiry) => {
                        if (roadTaxExpiry) {
                            return formatDateToString(new Date(roadTaxExpiry));
                        } else {
                            return "";
                        }
                    })
                    ?.join(", ")}
            </p>
        ),
    },
    {
        id: "co2Emissions",
        content: (
            <p>
                {vehicles?.co2Emissions
                    ?.map((co2Emissions) => {
                        if (co2Emissions) {
                            return co2Emissions;
                        } else {
                            return "unknown";
                        }
                    })
                    ?.join("(g/km), ")}
            </p>
        ),
    },
    {
        id: "fuelType",
        content: (
            <p>
                {vehicles?.fuelType
                    ?.map((fuelType) => {
                        if (fuelType) {
                            return fuelType;
                        } else {
                            return "unknown";
                        }
                    })
                    ?.join(", ")}
            </p>
        ),
    },
    {
        id: "insuranceType",
        content: (
            <p>
                {vehicles?.insuranceType
                    ?.map((insuranceType) => insuranceType)
                    ?.join(", ")}
            </p>
        ),
    },
    {
        id: "businessCover",
        content: (
            <p>
                {vehicles?.businessCover
                    ?.map((businessCover) => (businessCover ? "Yes" : "No"))
                    ?.join(", ")}
            </p>
        ),
    },
    {
        id: "engineCapacity",
        content: (
            <p>
                {vehicles?.engineCapacity
                    ?.map((engineCapacity) => {
                        if (engineCapacity) {
                            return engineCapacity;
                        } else {
                            return "unknown";
                        }
                    })
                    ?.join("cc, ")}
            </p>
        ),
    },
];
