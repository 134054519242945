import { FilterDriverLicenceAnalysisDto } from "../../models/app/admin/driverFilters";

export const driverLicenceAnalysisDefaultValues: Required<FilterDriverLicenceAnalysisDto> =
    {
        driversByPoints: {
            zeroPoints: null,
            oneToThreePoints: null,
            fourToSevenPoints: null,
            eightAndOverPoints: null,
        },
        driversByAgeRange: {
            eighteenToTwentyOne: null,
            twentyTwoToTwentyFive: null,
            twentySixToThirtyFive: null,
            thirtySixToFifty: null,
            fiftyOneToSixtyFive: null,
            sixtySixAndOver: null,
        },
        endorsementCodes: {
            endorsementCode: null,
        },
        licenceTypes: {
            currentFullLicence: null,
            currentProvisionalLicence: null,
            expiredFullLicence: null,
            expiredProvisionalLicence: null,
            surrenderedFullLicence: null,
            surrenderedProvisionalLicence: null,
            disqualified: null,
            previouslyDisqualified: null,
            disqualifiedDate: null,
            disqualifiedTest: null,
            disqualifiedSentence: null,
            disqualifiedLife: null,
            revokedPassed: null,
            noEntitlement: null,
        },
        driverRestrictions: {
            restrictionCode: null,
        },
        driversByLicenceCategory: {
            categoryCode: null,
        },
        driversByLicenceOrigin: {
            ukOrigin: null,
            niOrigin: null,
            foreignOrigin: null,
        },
        yearsFullLicenceHeld: {
            zeroToTwoYears: null,
            threeToFiveYears: null,
            sixToTenYears: null,
            elevenToTwentyYears: null,
            twentyOneAndOverYears: null,
        },
    };
