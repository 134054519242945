import LoadingMessage from "../LoadingMessage/LoadingMessage";

interface CardListContainerProps {
    children: JSX.Element | undefined;
    isLoading: boolean;
    loadingMessage?: string;
    hasContent?: boolean;
    noContentMessage?: string;
    title?: string;
}

const CardListContainer = ({
    isLoading,
    loadingMessage = "loading",
    hasContent = true,
    noContentMessage = "no content to show",
    title,
    children,
}: CardListContainerProps) => {
    return (
        <div className="grow rounded bg-S2D-dark-green-70.3 p-[24px]">
            {title && <h3 className="text-[18px] font-semibold">{title}</h3>}

            {isLoading && <LoadingMessage message={loadingMessage} />}

            <div className="my-4 flex flex-col gap-6">
                {hasContent
                    ? children
                    : !isLoading && (
                          <p className="text-S2D-neutral-30">
                              {noContentMessage}
                          </p>
                      )}
            </div>
        </div>
    );
};

export default CardListContainer;
