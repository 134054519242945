import { CheckStatus } from "../../../models/shared/statuses";
import { convertDateStringToReadableString } from "../../../utils/convertDateStringToReadableString";
import { StatusAction } from "../../ActionableStatus/ActionableStatus";

export type LicenceChecksActions =
    | "editCheckDate"
    | "retryCheck"
    | "changeToManual"
    | "changeToAutomatic"
    | "disableFurtherChecks"
    | "enableFurtherChecks"
    | "stopCheck"
    | "markAsChecked"
    | "markAsPending"
    | "markAsFailed";

export const buildLicenceCheckActions = (
    actionNames: LicenceChecksActions[],
    updateCheckType: (isManual: boolean) => Promise<void>,
    isUpdatingCheckType: boolean,
    toggleFurtherChecks: (enable: boolean) => Promise<void>,
    checkLicence: () => Promise<void>,
    isCheckingLicence: boolean,
    updateCheckStatus: (checkStatus: CheckStatus) => Promise<void>,
    isUpdatingCheckStatus: boolean,
    lastCheckDate: string | null
): StatusAction[] => {
    const actions: StatusAction[] = [];

    if (actionNames.length === 0) return actions;

    // Action and loading state come from the dialog itself rather than this action
    if (actionNames.includes("editCheckDate")) {
        actions.push({
            dropdownItem: "editCheckDate",
            modalHeading: "Edit check date",
            modalButtonText: "Save",
        });
    }

    if (actionNames.includes("retryCheck")) {
        actions.push({
            dropdownItem: "retryCheck",
            modalButtonAction: checkLicence,
            modalHeading: "Retry check?",
            modalSubHeading: lastCheckDate
                ? `This driver was last checked on ${convertDateStringToReadableString(
                      lastCheckDate
                  )}`
                : "Retry the driver licence check?",
            modalButtonText: "Retry check",
            isLoading: isCheckingLicence,
        });
    }

    if (actionNames.includes("changeToManual")) {
        actions.push({
            dropdownItem: "changeToManual",
            modalButtonAction: () => updateCheckType(true),
            modalHeading: "Change to manual check?",
            modalSubHeading:
                "The driver will be moved to the manual check queue.",
            modalButtonText: "Change to manual check",
            isLoading: isUpdatingCheckType,
        });
    }

    if (actionNames.includes("changeToAutomatic")) {
        actions.push({
            dropdownItem: "changeToAutomatic",
            modalButtonAction: () => updateCheckType(false),
            modalHeading: "Change to automatic checks?",
            modalSubHeading:
                "The driver will be rescheduled to automated checks.",
            modalButtonText: "Change to automatic checks",
            isLoading: isUpdatingCheckType,
        });
    }

    if (actionNames.includes("disableFurtherChecks")) {
        actions.push({
            dropdownItem: "disableFurtherChecks",
            modalButtonAction: () => toggleFurtherChecks(false),
            modalHeading: "Disable further checks?",
            modalSubHeading:
                "No checks will be performed on this driver in the future until manually rescheduled.",
            modalButtonText: "Disable further checks",
        });
    }

    if (actionNames.includes("enableFurtherChecks")) {
        actions.push({
            dropdownItem: "enableFurtherChecks",
            modalButtonAction: () => toggleFurtherChecks(true),
            modalHeading: "Enable further checks?",
            modalSubHeading:
                "Checks for this driver will be performed automatically.",
            modalButtonText: "Enable further checks",
        });
    }

    if (actionNames.includes("markAsChecked")) {
        actions.push({
            dropdownItem: "markAsChecked",
            modalButtonAction: () => updateCheckStatus(CheckStatus.Checked),
            modalHeading: "Mark as checked?",
            modalSubHeading:
                "The status of the licence check will be set to checked.",
            modalButtonText: "Mark as checked",
            isLoading: isUpdatingCheckStatus,
        });
    }

    if (actionNames.includes("markAsFailed")) {
        actions.push({
            dropdownItem: "markAsFailed",
            modalButtonAction: () => updateCheckStatus(CheckStatus.Failed),
            modalHeading: "Mark as failed?",
            modalSubHeading:
                "The status of the licence check will be set to failed.",
            modalButtonText: "Mark as failed",
            isLoading: isUpdatingCheckStatus,
        });
    }

    if (actionNames.includes("markAsPending")) {
        actions.push({
            dropdownItem: "markAsPending",
            modalButtonAction: () => updateCheckStatus(CheckStatus.Pending),
            modalHeading: "Mark as pending?",
            modalSubHeading:
                "The status of the licence check will be set to pending.",
            modalButtonText: "Mark as pending",
            isLoading: isUpdatingCheckStatus,
        });
    }

    return actions;
};
