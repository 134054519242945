import clsx from "clsx";
import { Warning } from "phosphor-react";
import { FieldError } from "react-hook-form";

export type FormInputMessageProps = {
    error?: FieldError | string;
    helpText?: string;
    className?: string;
    warningTriangle?: boolean;
};

const FormInputMessage = ({
    error,
    helpText,
    className,
    warningTriangle,
}: FormInputMessageProps) => {
    const commonClassNames = clsx("text-xs ml-1 block", className);

    if (error) {
        return (
            <div className="flex items-center gap-1 max-w-full">
                {warningTriangle && (
                    <Warning
                        className="text-S2D-error-40"
                        weight="fill"
                        size={20}
                    />
                )}
                <span
                    className={clsx(
                        commonClassNames,
                        "text-S2D-error-40 justify-between"
                    )}
                >
                    <div>
                        {typeof error === "string" ? error : error?.message}
                    </div>
                </span>
            </div>
        );
    }

    if (helpText) {
        return (
            <span className={clsx(commonClassNames, "text-primary-text")}>
                {helpText}
            </span>
        );
    }

    return null;
};

export default FormInputMessage;
