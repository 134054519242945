import { useEffect, useRef } from "react";
import EditableContentBlock from "../../EditableContentBlock";
import EditApiIntegrationsForm from "./EditApiIntegrationsForm";
import { useApiIntegrationsSection } from "./useApiIntegrationsSection";
import { formatDateToString } from "../../../utils/formatDateToString";
import LoadingMessage from "../../ReusableComponents/LoadingMessage/LoadingMessage";
import { ApiStatus } from "../../../models/app/admin/companyApiSettings";
import useAuth from "../../../auth";
import { hasClientManagementClaim } from "../../../auth/userAccessHelpers";

type Props = {
    companyId?: string;
};
export default function CompanyIntegrationsTab({ companyId }: Props) {
    const {
        displayedValues,
        handleSubmit,
        handleGenerateNewApiKey,
        isPostingNewApiKey,
    } = useApiIntegrationsSection(companyId);

    const myRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // scrolling to show the user the content in the tab.
        myRef.current && myRef.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    const { decodedToken } = useAuth();
    const canEdit = hasClientManagementClaim(decodedToken);
    return (
        <EditableContentBlock title="API" disableEdit={!canEdit}>
            {({ isEdit, setIsEdit }) => (
                <div className="p-6">
                    {!isEdit && nonEditableContent()}
                    {isEdit && (
                        <EditApiIntegrationsForm
                            currentApiKey={displayedValues?.apiKey ?? null}
                            apiStatus={
                                displayedValues?.apiStatusId as ApiStatus
                            }
                            resetAndCloseForm={() => setIsEdit(false)}
                            onSubmit={(data) => handleSubmit(data)}
                            handleGenerateNewApiKey={handleGenerateNewApiKey}
                            isPostingNewApiKey={isPostingNewApiKey}
                        />
                    )}
                </div>
            )}
        </EditableContentBlock>
    );

    function nonEditableContent() {
        const formattedDateTime = (date?: Date) => {
            if (!date) return;
            const newDate = new Date(date);
            const formattedDate = formatDateToString(newDate, true);
            return formattedDate.replace(" ", " at ");
        };

        const { apiKeyCreated, apiKeyLastUsed } = displayedValues || {};
        return (
            <div ref={myRef} className="space-y-2">
                {!displayedValues && <LoadingMessage />}
                {displayedValues && (
                    <>
                        <p>
                            <b>API enabled: </b>
                            {displayedValues?.apiStatusId === 1
                                ? "True"
                                : "False"}
                        </p>
                        <p>
                            <b>API key: </b>
                            {displayedValues?.apiKey ?? "N/A"}
                        </p>
                        <p>
                            <b>Date created: </b>
                            {apiKeyCreated
                                ? formattedDateTime(apiKeyCreated)
                                : "N/A"}
                        </p>
                        <p>
                            <b>Last used: </b>
                            {apiKeyLastUsed
                                ? formattedDateTime(apiKeyLastUsed)
                                : "N/A"}
                        </p>
                    </>
                )}
            </div>
        );
    }
}
