import { useState } from "react";
import type { ApiIntegrationFormValues } from "./useApiIntegrationsSection";
import FormDropdown from "../../FormDropdown";
import FormTextInput from "../../FormTextInput";
import Button from "../../Button";
import DialogBox from "../../DialogBox";
import { ApiStatus } from "../../../models/app/admin/companyApiSettings";

interface EditApiIntegrationsFormProps {
    apiStatus: ApiStatus;
    currentApiKey: string | null;
    resetAndCloseForm: () => void;
    onSubmit: (data: Pick<ApiIntegrationFormValues, "apiStatus">) => void;
    handleGenerateNewApiKey: () => void;
    isPostingNewApiKey: boolean;
}

export default function EditApiIntegrationsForm({
    apiStatus,
    currentApiKey,
    onSubmit,
    handleGenerateNewApiKey,
    isPostingNewApiKey,
}: EditApiIntegrationsFormProps) {
    const [isWarningDialogOpen, setWarningDialogOpen] =
        useState<boolean>(false);

    const handleGenerateKeyClick = () => {
        if (currentApiKey) {
            setWarningDialogOpen(true);
        } else {
            handleGenerateNewApiKey();
        }
    };

    const handleDialogSubmit = () => {
        handleGenerateNewApiKey();
        setWarningDialogOpen(false);
    };

    const handleSubmitOnChange = (value: any) => {
        const valuesToSubmit = { apiStatus: value };
        onSubmit(valuesToSubmit);
    };

    const apiEnabled = apiStatus === ApiStatus.Enabled;
    return (
        <>
            <FormDropdown
                options={[
                    { label: "True", value: ApiStatus.Enabled },
                    { label: "False", value: ApiStatus.Disabled },
                ]}
                value={apiStatus}
                label="API enabled"
                onChange={handleSubmitOnChange}
            />

            <div className="pt-4" />
            <div className="flex items-end gap-3">
                <FormTextInput
                    label="API key"
                    id="apiKey"
                    required
                    readOnly
                    value={currentApiKey ?? ""}
                    placeholder="No API key generated"
                />
                <Button
                    className="mb-1 h-14 whitespace-nowrap"
                    type="button"
                    variant="secondary"
                    loading={isPostingNewApiKey}
                    disabled={!apiEnabled}
                    onClick={() => handleGenerateKeyClick()}
                >
                    Generate new API key
                </Button>
            </div>
            <div className="pt-7" />

            <DialogBox
                isOpen={isWarningDialogOpen}
                title={`Generate new API key`}
                onClose={() => setWarningDialogOpen(false)}
                size="medium"
            >
                <p className="px-6 pb-2">
                    <b>Warning:</b> Generating a new API key will cause existing
                    connections to stop working. Any connections using the old
                    key should be updated with the new key as soon as possible
                    to avoid unnecessary downtime.
                </p>
                <div className="m-4 flex justify-center gap-4">
                    <Button
                        variant="tertiary"
                        onClick={() => setWarningDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleDialogSubmit()}
                        loading={isPostingNewApiKey}
                    >
                        <span className="whitespace-nowrap">
                            Generate new API key
                        </span>
                    </Button>
                </div>
            </DialogBox>
        </>
    );
}
