import ApiError from "../../api/common/apiError";
import {
    CompanyDriverDeclarationAnalysisResponse,
    DeclarationAnalysisTabs,
} from "../../models/api/admin/companyDeclarationAnalysis";
import { DeclarationStatusAnalysisTitles } from "../../models/app/eDeclarations";
import {
    EDeclarationStatusAnalysis,
    LicenceOriginAnalysis,
    PaperDeclarationStatusAnalysis,
} from "../AnalysisViews/DeclarationAnalysisViews";
import { AnalysisComponentContentType } from "../ReusableComponents/AnalysisComponent";

const declarationAnalysisContent = (
    data?: CompanyDriverDeclarationAnalysisResponse,
    error?: ApiError,
    declarationStatusAnalysisActions?: (
        title: DeclarationStatusAnalysisTitles
    ) => void,
    isLoading?: boolean
): AnalysisComponentContentType<DeclarationAnalysisTabs>[] => {
    return [
        {
            title: DeclarationAnalysisTabs.EDeclarations,
            content: (
                <>
                    {!error && (
                        <EDeclarationStatusAnalysis
                            data={data?.declarations}
                            onLinkClick={declarationStatusAnalysisActions}
                            isLoading={isLoading}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
        {
            title: DeclarationAnalysisTabs.PaperDeclarations,
            content: (
                <>
                    {!error && (
                        <PaperDeclarationStatusAnalysis
                            data={data?.declarations}
                            onLinkClick={declarationStatusAnalysisActions}
                            isLoading={isLoading}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
        {
            title: DeclarationAnalysisTabs.LicenceOrigin,
            content: (
                <>
                    {!error && (
                        <LicenceOriginAnalysis
                            data={data?.licenceOrigins}
                            onLinkClick={declarationStatusAnalysisActions}
                            isLoading={isLoading}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
    ];
};

const ErrorMessage = () => {
    return (
        <div className="rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-4 text-S2D-neutral-10">
            Error fetching driver analysis data
        </div>
    );
};

export default declarationAnalysisContent;
