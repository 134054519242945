import React, { useEffect, useRef, useState } from "react";
import usePostcodeLookup from "../api/postcodeLookup";
import { ApiPostcodeLookupResponse } from "../models/api/postcodeLookup";
import Button from "./Button";
import FormDropdown from "./FormDropdown";
import FormTextInput from "./FormTextInput";
import { postcodeValidationSchema } from "../schemas/addressSchema";

type PostcodeAddressLookupProps = {
    value: string;
    autoFocus?: boolean;
    label?: string;
    onSelect: (address: ApiPostcodeLookupResponse) => void;
};

type ValidationFailureResult = {
    isValid: false;
    errorMessage: string;
};

type ValidationSuccessResult = {
    isValid: true;
};

export type ValidationResult =
    | ValidationFailureResult
    | ValidationSuccessResult;

const validatePostcode = async (
    postcode: string
): Promise<ValidationResult> => {
    try {
        await postcodeValidationSchema.validate(postcode);

        return { isValid: true };
    } catch (error) {
        return {
            isValid: false,
            errorMessage: "Please enter a valid postcode",
        };
    }
};

const PostcodeAddressLookup: React.FC<PostcodeAddressLookupProps> = ({
    value,
    autoFocus,
    onSelect,
    label = "Enter your postcode and select your address",
}) => {
    const addressDropdownRef = useRef<HTMLButtonElement>(null);
    const [postcode, setPostcode] = useState("");
    const [error, setError] = useState<string>();
    const {
        postcodeLookupData,
        postcodeLookupError,
        postcodeLookupIsLoading,
        mutate,
    } = usePostcodeLookup(postcode);

    const handlePostcodeInputChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => setPostcode(e.currentTarget.value);

    const handlePostcodeInputKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleAddressLookup();
        }
    };

    const handleAddressLookup = async () => {
        setError(undefined);
        const postcodeValidation = await validatePostcode(postcode);

        if (postcodeValidation.isValid) mutate();

        if (!postcodeValidation.isValid)
            setError(postcodeValidation.errorMessage);
    };

    const handleAddressSelect = (summaryLine: string) => {
        const addressObj = postcodeLookupData.find(
            (p) => p.summaryline === summaryLine
        );
        if (addressObj) {
            onSelect(addressObj);
        }
    };

    useEffect(() => {
        if (postcodeLookupData.length > 0) {
            addressDropdownRef.current?.click();
        }
    }, [postcodeLookupData]);

    //reset data when component mounts
    useEffect(() => {
        mutate();
    }, []);

    const options = postcodeLookupData.map((p) => ({
        label: p.summaryline,
        value: p.summaryline,
    }));

    const selectedOption = options.find(o => o.value === value);
    const selectedValue = selectedOption?.value || "";

    return (
        <>
            <div className="md:flex flex-row items-start gap-4 ">
                <div className="flex-1 relative mb-2 md:mb-0">
                    <FormTextInput
                        label={label}
                        id="postcode"
                        labelClassName="font-bold mx-0"
                        placeholder="Enter your postcode"
                        value={postcode}
                        onChange={handlePostcodeInputChange}
                        onKeyDown={handlePostcodeInputKeyDown}
                        autoFocus={autoFocus}
                    />
                    {error && (
                        <p className="text-xs ml-1 block text-S2D-error-40">
                            {error}
                        </p>
                    )}
                </div>
                <div className="flex-none md:translate-y-9 flex justify-end">
                    <Button
                        type="button"
                        variant="secondary"
                        onClick={handleAddressLookup}
                        loading={postcodeLookupIsLoading}
                        className="h-14"
                    >
                        Search address
                    </Button>
                </div>
            </div>
            {postcodeLookupData.length > 0 && !postcodeLookupError && (
                <FormDropdown
                    label="Select address"
                    options={options}
                    value={selectedValue}
                    onChange={handleAddressSelect}
                    pleaseSelectText="Select your address"
                    ref={addressDropdownRef}
                />
            )}
        </>
    );
};

export default PostcodeAddressLookup;
