import { CompanyDriverDeclarationAnalysisResponse } from "../../models/api/admin/companyDeclarationAnalysis";
import { useS2DApiFetcher } from "../common/fetching";

// this hook is separate from 'useGetCompanyDeclarationAnalysis' so that the expensive
// report call (i.e. All declarationAnalysis categories) is only made when asked for.

export const useGetCompanyDeclarationAnalysisReport = (
    companyId?: string,
    departmentId?: string
) => {
    const pathArray = ["api/companies/declarationAnalysis"];
    const pushConnector = () =>
        pathArray.length === 1 ? pathArray.push("?") : pathArray.push("&");

    if (companyId) {
        pushConnector();
        pathArray.push(`CompanyId=${companyId}`);
    }
    if (departmentId) {
        pushConnector();
        pathArray.push(`DepartmentId=${departmentId}`);
    }

    const [getDeclarationAnalysisData, isGettingDeclarationAnalysisData] =
        useS2DApiFetcher<null, CompanyDriverDeclarationAnalysisResponse, null>(
            "GET",
            () => ({
                url: pathArray.join(""),
                body: null,
                altToken: undefined,
            }),
            [],
            true
        );

    return {
        getDeclarationAnalysisData: async () =>
            await getDeclarationAnalysisData(null),
        isGettingDeclarationAnalysisData,
    };
};
