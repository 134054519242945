import { useGetCompanies } from "../../../api/admin/company";
import HeadlessGenericDropdown, {
    DropdownItem,
} from "../HeadlessGenericDropdown";
import { ApiCompanyResponse } from "../../../models/api/admin/company";

type CompanyDropdownProps = {
    handleCompanyChange: (company: ApiCompanyResponse | null) => void;
    selectedCompany: ApiCompanyResponse | null;
    showLabels?: boolean;
};
export default function CompanyDropdown({
    handleCompanyChange,
    selectedCompany,
    showLabels,
}: CompanyDropdownProps) {
    const { companyList, companyListError, isCompanyListLoading } =
        useGetCompanies(1, 100);
    const companyDropdownItems = companyList?.items.map((company) => ({
        item: company.companyName,
        id: company.companyId,
    }));

    const handleCompanySelect = (item: DropdownItem | null) => {
        const companyFromId: ApiCompanyResponse | undefined =
            companyList?.items.find(
                (company) => company.companyId === item?.id
            );
        if (companyFromId) handleCompanyChange(companyFromId);
    };
    return (
        <>
            {companyList && (
                <HeadlessGenericDropdown
                    dropdownPlaceholder="All clients"
                    dropdownData={companyDropdownItems}
                    selectedItem={{
                        item: selectedCompany?.companyName,
                        id: selectedCompany?.companyId,
                    }}
                    handleItemChange={handleCompanySelect}
                    error={companyListError}
                    isLoading={isCompanyListLoading}
                    label={showLabels ? "Client" : undefined}
                />
            )}
        </>
    );
}
