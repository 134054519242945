import { useEffect, useState } from "react";
import {
    DeclarationStatusAnalysisTitles,
    DeclarationType,
    EDeclarationsDeclarationQueryParams,
} from "../../models/app/eDeclarations";
import { successToast } from "../../toast";
import { ApiEDeclarationsResponse } from "../../models/api/admin/eDeclarations";
import { DeclarationTableFilters } from "../../api/admin/eDeclarations";
import { declarationTableFiltersDefaultValues } from "../../hooks/useDeclarationTableFiltersLocalStorage";

export const declarationsQueryDefaultValues: EDeclarationsDeclarationQueryParams =
    {
        declarationTypes: null,
        hasExpiredDeclaration : null,
        hasCompletedDeclaration: null,
        hasIncompleteDeclaration : null,
        hasExpiringDeclaration: null,
    };

export const declarationAnalysisActions = (
    declarationTableFiltersStore: DeclarationTableFilters,
    setDeclarationTableFiltersStore: (
        value:
            | DeclarationTableFilters
            | ((val: DeclarationTableFilters) => DeclarationTableFilters)
    ) => void,
    setIsLoadingFilter: (value: boolean) => void,
    eDeclarationsList?: ApiEDeclarationsResponse,
    tableRef?: React.MutableRefObject<HTMLDivElement | null>
) => {
    const [scrollOnLoad, setScrollOnLoad] = useState(false);

    const handleApplyFilter = () => {
        setScrollOnLoad(true);
        setIsLoadingFilter(true);
    };

    useEffect(() => {
        // scroll once list has loaded
        if (scrollOnLoad && eDeclarationsList) {
            if (!tableRef?.current) return;
            successToast("Filter successfully applied");
            window.scrollTo(0, tableRef.current.offsetTop);

            setScrollOnLoad(false);
            setIsLoadingFilter(false);
        }
    }, [eDeclarationsList]);

    // keep company filters on when clicking through
    const defaultValues = {
        ...declarationTableFiltersDefaultValues,
        companyQuery: declarationTableFiltersStore.companyQuery,
    };

    const declarationStatusAnalysisActions = (
        title: DeclarationStatusAnalysisTitles
    ) => {
        switch (title) {
            case DeclarationStatusAnalysisTitles.EDeclarationsComplete:
                setDeclarationTableFiltersStore({
                    ...defaultValues,
                    declarationsQuery: {
                        ...declarationsQueryDefaultValues,
                        declarationTypes: [DeclarationType.EDeclaration],
                        hasCompletedDeclaration: true,
                    },
                });
                handleApplyFilter();
                break;
            case DeclarationStatusAnalysisTitles.EDeclarationsIncomplete:
                setDeclarationTableFiltersStore({
                    ...defaultValues,
                    declarationsQuery: {
                        ...declarationsQueryDefaultValues,
                        declarationTypes: [DeclarationType.EDeclaration],
                        hasIncompleteDeclaration: true,
                    },
                });
                handleApplyFilter();
                break;
            case DeclarationStatusAnalysisTitles.EDeclarationsDueToExpire:
                setDeclarationTableFiltersStore({
                    ...defaultValues,
                    declarationsQuery: {
                        ...declarationsQueryDefaultValues,
                        declarationTypes: [DeclarationType.EDeclaration],
                        hasExpiringDeclaration: true,
                    },
                });
                handleApplyFilter();
                break;
            case DeclarationStatusAnalysisTitles.EDeclarationsExpired:
                setDeclarationTableFiltersStore({
                    ...defaultValues,
                    declarationsQuery: {
                        ...declarationsQueryDefaultValues,
                        declarationTypes: [DeclarationType.EDeclaration],
                        hasExpiredDeclaration : true,
                    },
                });
                handleApplyFilter();
                break;
            case DeclarationStatusAnalysisTitles.PaperDeclarationsComplete:
                setDeclarationTableFiltersStore({
                    ...defaultValues,
                    declarationsQuery: {
                        ...declarationsQueryDefaultValues,
                        declarationTypes: [DeclarationType.PaperDeclaration],
                        hasCompletedDeclaration: true,
                    },
                });
                handleApplyFilter();
                break;
            case DeclarationStatusAnalysisTitles.PaperDeclarationsIncomplete:
                setDeclarationTableFiltersStore({
                    ...defaultValues,
                    declarationsQuery: {
                        ...declarationsQueryDefaultValues,
                        declarationTypes: [DeclarationType.PaperDeclaration],
                        hasIncompleteDeclaration: true,
                    },
                });
                handleApplyFilter();
                break;
            case DeclarationStatusAnalysisTitles.PaperDeclarationsDueToExpire:
                setDeclarationTableFiltersStore({
                    ...defaultValues,
                    declarationsQuery: {
                        ...declarationsQueryDefaultValues,
                        declarationTypes: [DeclarationType.PaperDeclaration],
                        hasExpiringDeclaration : true,
                    },
                });
                handleApplyFilter();
                break;
            case DeclarationStatusAnalysisTitles.PaperDeclarationsExpired:
                setDeclarationTableFiltersStore({
                    ...defaultValues,
                    declarationsQuery: {
                        ...declarationsQueryDefaultValues,
                        declarationTypes: [DeclarationType.PaperDeclaration],
                        hasExpiredDeclaration: true,
                    },
                });
                handleApplyFilter();
                break;
            case DeclarationStatusAnalysisTitles.DriversWithUKLicences:
                setDeclarationTableFiltersStore({
                    ...defaultValues,
                    licenceOriginQuery: {
                        ukOrigin: true,
                        niOrigin: false,
                        foreignOrigin: false,
                    },
                });
                handleApplyFilter();
                break;
            case DeclarationStatusAnalysisTitles.DriversWithNILicences:
                setDeclarationTableFiltersStore({
                    ...defaultValues,
                    licenceOriginQuery: {
                        ukOrigin: false,
                        niOrigin: true,
                        foreignOrigin: false,
                    },
                });
                handleApplyFilter();
                break;
            case DeclarationStatusAnalysisTitles.DriversWithForeignLicences:
                setDeclarationTableFiltersStore({
                    ...defaultValues,
                    licenceOriginQuery: {
                        ukOrigin: false,
                        niOrigin: false,
                        foreignOrigin: true,
                    },
                });
                handleApplyFilter();
                break;
        }
    };

    return {
        declarationStatusAnalysisActions,
    };
};
