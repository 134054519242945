import * as yup from "yup";
import { SchemaKeys } from "../../../../../models/shared/schemaKeysHelper";
import { AddInvoiceFormData } from "../../../../../models/app/admin/invoice";

export type AddInvoiceFormValues = AddInvoiceFormData;

export const AddInvoiceFormValidationSchema: SchemaKeys<AddInvoiceFormValues> =
    {
        isParentInvoiced: yup
            .boolean(),
        departmentId: yup
            .string()
            .nullable()
            .optional(),
        reference: yup
            .string()
            .nullable()
            .optional(),
        dueDate: yup
            .date()
            .required("Please select a due date."),
        invoiceDate: yup
            .date()
            .required("Please select an invoice date."),
    };
