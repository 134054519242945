import * as yup from "yup";
import { dateValidation } from "../../utils/dateValidationYup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormDateInput from "../FormDateInput";
import SubmitOrCancelButtonBar from "../ReusableComponents/SubmitOrCancelButtonBar";
import { BulkInvoiceDto } from "../../models/app/admin/invoice";
import { useInvoice } from "../../api/admin/invoice";
import { errorToast, successToast } from "../../toast";
import { isApiError } from "../../api/common/apiError";

const bulkInvoicesSchemaFields = {
    dueDate: dateValidation.required("Due date is required").nullable(),
    invoiceDate: dateValidation.nullable(),
};

const defaultValues: BulkInvoiceDto = {
    dueDate: null,
    invoiceDate: null,
};

type BulkInvoicesFormProps = {
    onClose: () => void;
};

export const BulkInvoicesForm: React.FC<BulkInvoicesFormProps> = ({
    onClose,
}) => {
    const validationSchema = yup.object().shape(bulkInvoicesSchemaFields);
    const { control, handleSubmit, formState } = useForm<BulkInvoiceDto>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues,
    });

    const { getBulkInvoice, isGettingBulkInvoice } = useInvoice();

    const handleFormSubmit = async (values: BulkInvoiceDto) => {
        const response = await getBulkInvoice(values);

        if (response.success) {
            successToast(`Generating bulk invoice`);
            onClose();
        } else if (
            isApiError(response.error) &&
            response.error.statusCode === 400
        ) {
            errorToast(response.error.message);
        } else {
            errorToast(`Failed to generate bulk invoice`);
        }
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="mx-auto w-full px-6 pb-8">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <Controller
                        name="dueDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormDateInput
                                id="dueDate"
                                label="Due Date"
                                required
                                value={value}
                                onChange={onChange}
                                error={formState.errors.dueDate}
                            />
                        )}
                    />
                    <Controller
                        name="invoiceDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormDateInput
                                id="invoiceDate"
                                label="Invoice Date"
                                value={value}
                                onChange={onChange}
                                error={formState.errors.invoiceDate}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="mt-0 rounded-b-sm border-t-[1px] border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6">
                <SubmitOrCancelButtonBar
                    onCancel={onClose}
                    isLoading={isGettingBulkInvoice}
                />
            </div>
        </form>
    );
};
