import { useState } from "react";
import { useEdeclaration } from "../../api/edeclaration";
import useWarnBeforeUnload from "../../hooks/useWarnBeforeUnload";
import TabStepper from "../TabStepper";
import {
    EdeclarationFormDefaultValues,
    EDeclarationFormValues,
} from "./EDeclarationFormValues";
import ForeignLicenceConfirmationScreen from "./ForeignLicenceConfirmationScreen";
import Step1VerifyIdentity from "./Step1VerifyIdentity";
import { useEdeclarationWizard } from "./useEdeclarationWizard";
import Step2LicenceInformation from "./Step2LicenceInformation";
import Step3Address from "./Step3Address";
import Step4Permissions from "./Step4Permissions";
import { DriverValidationTypes } from "../../models/app/admin/company";
import Step5Confirmation from "./Step5Confirmation";

type EDeclarationFormProps = {
    eDeclarationId: string;
    defaultStep: number;
    jointEdecGreyFleet: boolean;
    greyFleetInviteId: string;
    driverValidationType?: DriverValidationTypes;
};

const EDeclarationForm: React.FC<EDeclarationFormProps> = ({
    eDeclarationId,
    defaultStep = 0,
    jointEdecGreyFleet,
    greyFleetInviteId,
    driverValidationType,
}) => {
    const {
        isValidatingEDeclarationUser,
        isUpdatingEdeclarationDriver,
        isFinalisingEDeclaration,
    } = useEdeclaration();

    const [selectedIndex, setSelectedIndex] = useState(defaultStep);
    const [eDeclarationFormData, setEDeclarationFormData] =
        useState<EDeclarationFormValues>(EdeclarationFormDefaultValues);

    useWarnBeforeUnload(selectedIndex < 3);

    const handleStepSubmit = (
        nextStepIndex: number,
        data?: Partial<EDeclarationFormValues>
    ) => {
        setEDeclarationFormData((prevEDeclarationFormData) => ({
            ...prevEDeclarationFormData,
            ...data,
        }));
        setSelectedIndex(nextStepIndex);
        window.scrollTo(0, 0);
    };

    const handleBackClick = () => {
        setSelectedIndex((prevSelectedIndex) => prevSelectedIndex - 1);
        window.scrollTo(0, 0);
    };

    const {
        handleVerificationSubmit,
        handleDriverUpdateSubmit,
        handleForeignLicenceSubmit,
        eDeclarationToken,
        manualCheck,
    } = useEdeclarationWizard(
        eDeclarationId,
        handleStepSubmit,
        eDeclarationFormData,
        setEDeclarationFormData,
        driverValidationType
    );

    const edecPages = [
        {
            title: "1. Verify Identity",
            content: (
                <Step1VerifyIdentity
                    defaultValues={{
                        firstName: eDeclarationFormData.firstName,
                        lastName: eDeclarationFormData.lastName,
                        dateOfBirth: eDeclarationFormData.dateOfBirth,
                        employeeId: eDeclarationFormData.employeeId,
                        emailAddress: eDeclarationFormData.emailAddress,
                    }}
                    isLoading={isValidatingEDeclarationUser}
                    onSubmit={handleVerificationSubmit}
                    onBackClick={handleBackClick}
                    driverValidationType={
                        driverValidationType ??
                        DriverValidationTypes.DateOfBirth
                    }
                />
            ),
        },
        {
            title: "2. Licence Information",
            content: (
                <Step2LicenceInformation
                    defaultValues={eDeclarationFormData}
                    onSubmit={(data) => handleStepSubmit(2, data)}
                    onForeignLicenceSubmit={handleForeignLicenceSubmit}
                    onBackClick={handleBackClick}
                />
            ),
        },
        {
            title: "3. Address",
            content: (
                <Step3Address
                    defaultValues={eDeclarationFormData}
                    onSubmit={(data) => handleStepSubmit(3, data)}
                    onBackClick={handleBackClick}
                />
            ),
        },
        {
            title: "4. Permission",
            content: (
                <Step4Permissions
                    defaultValues={{
                        employerPermission:
                            eDeclarationFormData.employerPermission,
                        confirmValidLicence:
                            eDeclarationFormData.confirmValidLicence,
                    }}
                    isLoading={
                        isUpdatingEdeclarationDriver || isFinalisingEDeclaration
                    }
                    onSubmit={handleDriverUpdateSubmit}
                    onBackClick={handleBackClick}
                />
            ),
        },
        {
            title: "5. E-Declaration Complete",
            content: (
                <Step5Confirmation
                    greyFleetInviteId={greyFleetInviteId}
                    token={eDeclarationToken}
                />
            ),
            hiddenTab: greyFleetInviteId ? false : true,
        },
    ];

    const dummyPage = {
        title: "",
        content: <></>,
    };

    const jointPages = [
        ...edecPages,
        ...Array.from({ length: 3 }, () => ({ ...dummyPage })),
    ];
    const pages = jointEdecGreyFleet ? jointPages : edecPages;

    return (
        <>
            {!manualCheck && (
                <TabStepper
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    pages={pages}
                />
            )}
            {manualCheck && (
                <ForeignLicenceConfirmationScreen
                    values={eDeclarationFormData}
                />
            )}
        </>
    );
};

export default EDeclarationForm;
