import { type FormDropdownOption } from "../components/FormDropdown";
import { CompanyStatusFilters } from "../models/api/admin/company";
import {
    type PhotocardStatus,
    type CategoryStatus,
    CheckStatus,
    type TachocardStatus,
    type CpcCardStatus,
    type MotStatus,
    type RoadTaxStatus,
    type InsuranceStatus,
    DeclarationStatus,
} from "../models/shared/statuses";
import { addSpacesToCamelCase } from "../utils/addSpacesToCamelCase";
import { capitaliseWord } from "../utils/capitaliseWord";

export const declarationStatusOptions: FormDropdownOption<string>[] = (
    Object.keys(DeclarationStatus) as Array<keyof typeof DeclarationStatus>
).map((key) => ({
    label: capitaliseWord(addSpacesToCamelCase(key)),
    value: key,
}));

export const checkStatusOptions: FormDropdownOption<string>[] = (
    Object.keys(CheckStatus) as Array<keyof typeof CheckStatus>
).map((key) => ({
    label: capitaliseWord(addSpacesToCamelCase(key)),
    value: key,
}));

const categoryStatuses: CategoryStatus[] = ["Full", "Provisional"];
export const categoryStatusOptions: FormDropdownOption<string>[] =
    categoryStatuses.map((status) => ({
        label: capitaliseWord(addSpacesToCamelCase(status)),
        value: status,
    }));

const photocardStatuses: PhotocardStatus[] = ["Full", "Provisional"];
export const photocardStatusOptions: FormDropdownOption<string>[] =
    photocardStatuses.map((status) => ({
        label: capitaliseWord(addSpacesToCamelCase(status)),
        value: status,
    }));

const tachoCardStatuses: TachocardStatus[] = [
    "Disqualified",
    "Full",
    "Provisional",
    "Revoked",
    "Expired",
];
export const tachoCardStatusOptions: FormDropdownOption<string>[] =
    tachoCardStatuses.map((status) => ({
        label: capitaliseWord(addSpacesToCamelCase(status)),
        value: status,
    }));

const cpcCardStatuses: CpcCardStatus[] = [
    "Disqualified",
    "Full",
    "Provisional",
    "Revoked",
    "Expired",
];
export const cpcCardStatusOptions: FormDropdownOption<string>[] =
    cpcCardStatuses.map((status) => ({
        label: capitaliseWord(addSpacesToCamelCase(status)),
        value: status,
    }));

const motStatuses: MotStatus[] = ["Pass", "Fail"];
export const motStatusOptions: FormDropdownOption<string>[] = motStatuses.map(
    (status) => ({
        label: capitaliseWord(addSpacesToCamelCase(status)),
        value: status,
    })
);

const roadTaxStatuses: RoadTaxStatus[] = ["Valid", "Invalid"];
export const roadTaxStatusOptions: FormDropdownOption<string>[] =
    roadTaxStatuses.map((status) => ({
        label: capitaliseWord(addSpacesToCamelCase(status)),
        value: status,
    }));

const insuranceStatuses: InsuranceStatus[] = ["Provided", "Not provided"];
export const insuranceStatusOptions: FormDropdownOption<string>[] =
    insuranceStatuses.map((status) => ({
        label: capitaliseWord(addSpacesToCamelCase(status)),
        value: status,
    }));

const companyStatuses: CompanyStatusFilters[] = [
    "Live",
    "DisabledNonPayment",
    "DisabledPermanent",
];
export const companyStatusOptions: FormDropdownOption<CompanyStatusFilters>[] =
    companyStatuses.map((status) => ({
        label: capitaliseWord(addSpacesToCamelCase(status)),
        value: status,
    }));
