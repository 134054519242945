export const downloadBlobAsFile = (
    content: Blob,
    fileName: string,
    fileType?: string
) => {
    const blob = new Blob([content], {
        type: fileType ? fileType : "text/csv;charset=utf-8;",
    });

    // the blob url allows us the treat the Blob data as if it were a file on a server.
    const blobURL = URL.createObjectURL(blob);
    // Create a new link element, and have it point to our Blob
    const link = document.createElement("a");
    link.href = blobURL;
    link.download = fileName;

    // Simulate a click to download file
    link.click();
};
