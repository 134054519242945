import clsx from "clsx";
import { CaretDown, CaretUp } from "phosphor-react";
import { ReactNode } from "react";

type CollapsibleSectionProps = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    title: string;
    content: ReactNode;
    className?: string;
};

const CollapsibleSection = ({
    isOpen,
    setIsOpen,
    title,
    content,
    className,
}: CollapsibleSectionProps) => {
    return (
        <section
            about={title}
            className={clsx(
                `flex flex-col gap-6 rounded-sm bg-S2D-dark-green-70.3 p-6`,
                className
            )}
        >
            <span
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center gap-2"
            >
                <p className=" text-lg font-semibold">{title}</p>
                {isOpen ? (
                    <CaretUp weight="fill" />
                ) : (
                    <CaretDown weight="fill" />
                )}
            </span>
            {isOpen && <>{content}</>}
        </section>
    );
};

export default CollapsibleSection;
