import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormTextInput from "../FormTextInput";
import Button from "../Button";
import { useState } from "react";
import FormMessageBox from "../FormMessageBox";
import {
    CreatePasswordFormValidationSchema,
    type CreatePasswordFormValues,
} from "./CreatePasswordFormValues";
import usePasswordValidation from "../../hooks/usePasswordValidation";

type CreatePasswordFormProps = {
    onSubmit: (
        data: CreatePasswordFormValues,
        callback: (error: boolean) => void
    ) => Promise<void>;
    isLoading: boolean;
};
const validationSchema = Yup.object().shape(CreatePasswordFormValidationSchema);

const CreatePasswordForm: React.FC<CreatePasswordFormProps> = ({
    onSubmit,
    isLoading,
}) => {
    const [matchError, setMatchError] = useState(false);

    const { handleSubmit, formState, register, trigger, watch } =
        useForm<CreatePasswordFormValues>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues: {
                password: "",
                confirmPassword: "",
            },
        });

    const handleLogIn = (data: CreatePasswordFormValues) => {
        onSubmit(data, setMatchError);
    };

    usePasswordValidation({
        watch,
        trigger,
        passwordField: "password",
        confirmPasswordField: "confirmPassword",
    });

    return (
        <div className="w-full max-w-lg rounded-sm bg-white p-8">
            <form onSubmit={handleSubmit(handleLogIn)}>
                <p className="pb-4 font-semibold">CREATE PASSWORD</p>
                {matchError && (
                    <FormMessageBox
                        variant="error"
                        title={"Invalid credentials"}
                        message={
                            "Email and/or password does not match our records"
                        }
                    />
                )}
                <FormTextInput
                    label="Password"
                    type="password"
                    required
                    id="password"
                    placeholder="Enter password"
                    error={formState.errors.password}
                    {...register("password")}
                />
                <FormTextInput
                    label="Confirm password"
                    type="password"
                    required
                    id="confirmPassword"
                    placeholder="Confirm password"
                    error={formState.errors.confirmPassword}
                    {...register("confirmPassword")}
                />
                <div className="mt-8 flex justify-end gap-4">
                    <Button type="submit" loading={isLoading}>
                        Create Password
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default CreatePasswordForm;
