import { Car } from "phosphor-react";
import { convertDateStringToReadableString } from "../../../utils/convertDateStringToReadableString";
import ActionableStatus from "../../ActionableStatus";
import ActionableStatusTag from "../../ActionableStatus/ActionableStatusTag";
import { GreyFleetCommonContentProps } from "./GreyFleet";

const GreyFleetCompleteContent = ({
    vehicle,
    actions,
}: GreyFleetCommonContentProps) => {
    return (
        <ActionableStatus
            title="Grey Fleet"
            theme="grey"
            actionable={true}
            statusIndicators={[
                <ActionableStatusTag theme="vrm" text={vehicle.vrm} />,
                <ActionableStatusTag theme="completed" />,
            ]}
            actions={[...actions]}
        >
            <div className="flex items-center">
                <div className="pr-3">
                    <Car size={24} weight="duotone" />
                </div>
                <div className="flex flex-col text-sm">
                    <p>
                        Vehicle added to Grey Fleet:{" "}
                        {convertDateStringToReadableString(vehicle.createdAt)}
                    </p>
                    {vehicle.updatedAt && (
                        <p>
                            Last updated:{" "}
                            {convertDateStringToReadableString(
                                vehicle.updatedAt
                            )}
                        </p>
                    )}
                    {vehicle.insuranceFileCheckedDate ? (
                        <p>
                            Insurance verified:{" "}
                            {convertDateStringToReadableString(
                                vehicle.insuranceFileCheckedDate
                            )}
                        </p>
                    ) : (
                        <p>Insurance not verified</p>
                    )}
                </div>
            </div>
        </ActionableStatus>
    );
};

export default GreyFleetCompleteContent;
