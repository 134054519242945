import { ApiInvoiceReferenceNumberResponse } from "../../models/api/admin/invoiceReferenceNumber";
import {
    AddInvoiceReferenceNumberDto,
    AppInvoiceReferenceUsedState,
    EditInvoiceReferenceNumberDto,
} from "../../models/app/admin/invoiceReferenceNumber";
import { queryParamConnector } from "../../utils/queryParamHelpers";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useGetInvoiceReferenceNumbers = (
    companyId?: string,
    pageNumber?: number,
    pageSize?: number,
    referenceNumberState?: AppInvoiceReferenceUsedState
) => {
    const baseUrl = companyId ? `api/invoices/${companyId}/reference-numbers` : "";
    const pathArray = [baseUrl];

    if (baseUrl && pageNumber) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`PageNumber=${pageNumber}`);
    }

    if (baseUrl && pageSize) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`PageSize=${pageSize}`);
    }

    if (baseUrl && referenceNumberState) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`ReferenceNumberState=${referenceNumberState}`);
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiInvoiceReferenceNumberResponse>(
            companyId ? pathArray.join("") : null
        );

    return {
        invoiceReferenceNumbersData: data,
        refetchInvoiceReferenceNumbers: refetch,
        invoiceReferenceNumbersError: error,
        isLoadingInvoiceReferenceNumbers: isLoading,
    };
};

const useAddInvoiceReferenceNumber = () =>
    useS2DApiFetcher<
        AddInvoiceReferenceNumberDto,
        string,
        { companyId: string; dto: AddInvoiceReferenceNumberDto }
    >(
        "PUT",
        ({ companyId, dto }) => ({
            url: `api/companies/${companyId}`,
            body: dto,
        }),
        [],
        true
    );
// NOT FUNCTIONAL, MIGHT BE USED IN THE NEAR FUTURE
// const useEditInvoiceReferenceNumber = () =>
//     useS2DApiFetcher<
//         EditInvoiceReferenceNumberDto,
//         string,
//         { companyId: string; invoiceReferenceNumberId: string; dto: EditInvoiceReferenceNumberDto }
//     >(
//         "PUT",
//         ({ companyId, invoiceReferenceNumberId, dto }) => ({
//             url: `api/companies/${companyId}/invoiceReferenceNumbers/${invoiceReferenceNumberId}`,
//             body: dto,
//         }),
//         [],
//         true
//     );
// NOT FUNCTIONAL, MIGHT BE USED IN THE NEAR FUTURE
// const useDeleteInvoiceReferenceNumber = () =>
//     useS2DApiFetcher<null, string, { companyId: string; invoiceReferenceNumberId: string }>(
//         "DELETE",
//         ({ companyId, invoiceReferenceNumberId }) => ({
//             url: `api/companies/${companyId}/invoiceReferenceNumbers/${invoiceReferenceNumberId}`,
//         }),
//         [],
//         true
//     );

export const useInvoiceReferenceNumber = (companyId: string) => {
    const [addInvoiceReferenceNumber, isAddingInvoiceReferenceNumber] = useAddInvoiceReferenceNumber();
    // const [editInvoiceReferenceNumber, isEditingInvoiceReferenceNumber] = useEditInvoiceReferenceNumber();
    // const [deleteInvoiceReferenceNumber, isDeletingInvoiceReferenceNumber] = useDeleteInvoiceReferenceNumber();

    return {
        addInvoiceReferenceNumber: async (dto: AddInvoiceReferenceNumberDto) =>
            await addInvoiceReferenceNumber({ companyId, dto }),
        isAddingInvoiceReferenceNumber,
        // editInvoiceReferenceNumber: async (invoiceReferenceNumberId: string, dto: EditInvoiceReferenceNumberDto) =>
        //     await editInvoiceReferenceNumber({ companyId, invoiceReferenceNumberId, dto }),
        // isEditingInvoiceReferenceNumber,
        // deleteInvoiceReferenceNumber: async (invoiceReferenceNumberId: string) =>
        //     await deleteInvoiceReferenceNumber({ companyId, invoiceReferenceNumberId }),
        // isDeletingInvoiceReferenceNumber,
    };
};
