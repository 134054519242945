import FormTextInput from "../../../FormTextInput";
import { CurrencyGbp } from "phosphor-react";
import SubmitOrCancelButtonBar from "../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import { Controller, UseFormReturn } from "react-hook-form";
import {
    BillingInfoSectionFormValues,
} from "./useBillingInformationSection";
import { yesNoOptions } from "../../../../constants/dropdownOptions";
import FormDropdown from "../../../FormDropdown";
import FormDateInput from "../../../FormDateInput";
import { useEffect } from "react";

interface BillingInformationFormProps {
    methods: UseFormReturn<BillingInfoSectionFormValues>;
    resetAndCloseForm: () => void;
    onSubmit: (data: BillingInfoSectionFormValues) => void;
    // parentId is used to determine if the selected company has a parent company
    parentId: string | undefined;
}

const today = new Date();

export default function BillingInformationForm({
    methods,
    resetAndCloseForm,
    onSubmit,
    parentId
}: BillingInformationFormProps) {
    const { formState: { errors }, handleSubmit, register, setValue } = methods;

    // Ensure shouldInvoiceParent is false if parentId is undefined
    // Realistically, this should be false anyway, but this is just a precaution
    useEffect(() => {
        if (!parentId) {
            setValue("shouldInvoiceParent", false);
        }
    }, [parentId, setValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* Only show the option to invoice parent company if the company has a parent */}
            {parentId && (
                <Controller
                    name="shouldInvoiceParent"
                    control={methods.control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                        <FormDropdown
                            label="Invoice the parent company?"
                            options={yesNoOptions}
                            value={value}
                            onChange={onChange}
                            pleaseSelectText="Select Yes or No"
                            error={errors.shouldInvoiceParent}
                            buttonClassName="h-[48px] border-black bg-S2D-dark-green-70.1"
                        />
                    )}
                />
            )}
            <Controller
                name="lastInvoicedAt"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                    <FormDateInput
                        required
                        id="lastInvoicedAt"
                        label="Date last invoiced"
                        value={value}
                        onChange={onChange}
                        error={errors.lastInvoicedAt}
                        helperText=""
                        maxDate={today}
                        adornmentClassName="mt-[0.06rem]"
                    />
                )}
            />
            <FormTextInput
                label="Annual fee"
                placeholder="00.00"
                step={0.01}
                onWheel={(e) => e.currentTarget.blur()} // prevents scroll changing value
                type="number"
                min="0"
                required
                id="annualFee"
                error={errors.annualFee}
                {...register("annualFee")}
                StartAdornmentIcon={CurrencyGbp}
            />
            <div className="pt-7" />
            <SubmitOrCancelButtonBar onCancel={resetAndCloseForm} />
        </form>
    );
}
function setValue(arg0: string, arg1: boolean) {
    throw new Error("Function not implemented.");
}

