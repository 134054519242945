import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import clsx from "clsx";
import AlertCountBadge from "../../DashboardAlerts/AlertCountBadge";

// NB: this component can only be used inside a headlessui/react Tab.List
const TabWithoutIcon = ({
    content,
    tabCount,
    alertCount,
}: {
    content: string;
    tabCount: number;
    alertCount?: number;
}) => {
    return (
        <Tab as={Fragment}>
            {({ selected }) => (
                <div
                    className={clsx(
                        `flex h-11 cursor-pointer flex-row items-center justify-center gap-2 px-4 text-xs`,
                        selected &&
                            "bg-S2D-light-green-80 text-S2D-text-button focus:outline-none"
                    )}
                >
                    <span>{content}</span>
                    {typeof alertCount === "number" && (
                        <AlertCountBadge count={alertCount} />
                    )}
                </div>
            )}
        </Tab>
    );
};

export default TabWithoutIcon;
