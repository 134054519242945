export type CompanyDriverDeclarationAnalysisResponse = {
    declarations: {
        eDeclarationsComplete: number;
        eDeclarationsDueToExpire: number;
        eDeclarationsIncomplete: number;
        eDeclarationsExpired: number;
        paperDeclarationsComplete: number;
        paperDeclarationsDueToExpire: number;
        paperDeclarationsIncomplete: number;
        paperDeclarationsExpired: number;
    };
    licenceOrigins: {
        ukLicences: number;
        northernIrishLicences: number;
        foreignLicences: number;
    };
    declarationTypes: {
        eDeclarations: number;
        paperDeclarations: number;
    };
};

export enum DeclarationAnalysisCategories {
    Declarations = "Declarations",
    LicenceOrigins = "LicenceOrigins",
    DeclarationTypes = "DeclarationTypes",
}
// unlike other analysis objects, categories do not correspond to tab labels
export enum DeclarationAnalysisTabs {
    EDeclarations = "E-Declaration Status",
    PaperDeclarations = "Paper Declaration Status",
    LicenceOrigin = "Licence Origin",
}
