import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { appPaths } from "../../Routes";
import CreatePasswordForm from "../../components/CreatePassWordForm.tsx/CreatePasswordForm";
import { CreatePasswordFormValues } from "../../components/CreatePassWordForm.tsx/CreatePasswordFormValues";
import { useUnverifiedUser } from "../../api/user";
import { errorToast, successToast } from "../../toast";
import { ForgottenPasswordResetDto } from "../../models/app/user";
import useAuth from "../../auth";
import { useEffect } from "react";

type PageParams = {
    id: string;
};

const CreatePasswordPage = () => {
    const { forgottenPasswordReset, isResettingPassword } = useUnverifiedUser();

    const { id: userId } = useParams<PageParams>();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const resetPasswordToken = searchParams.get("token")?.replaceAll(" ", "+");
    const authContext = useAuth();

    useEffect(() => {
        if (authContext.token) {
            // removing any token from local storage if there is one
           authContext.logOut();
        }
    }, []);


    // 01/05/23 When creating a 'new' password, we're actually resetting their default password
    const handlePasswordReset = async (data: CreatePasswordFormValues) => {
        if (!userId || !resetPasswordToken) {
            return;
        }

        const requestBody: ForgottenPasswordResetDto = {
            newPassword: data.password,
            passwordResetToken: resetPasswordToken,
        };

        const res = await forgottenPasswordReset(userId, requestBody);
        if (res.success) {
            successToast("Successfully created your password.");
            navigate(appPaths.login);
        } 
    };

    return (
        <div className="w-screen h-screen flex justify-center items-center bg-S2D-surface-light-3">
            <CreatePasswordForm
                onSubmit={handlePasswordReset}
                isLoading={isResettingPassword}
            />
        </div>
    );
};

export default CreatePasswordPage;
