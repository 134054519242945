import * as yup from "yup";

import { EditCompanyCommunicationSettingsSchema } from "../../EditCompanyFormValues";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";
import { type CommunicationSettings } from "../../../../models/app/admin/company";

export const useEditCompanyCommunicationSettingsForm = (
    commsSettings: CommunicationSettings | undefined
) => {
    const isLoading = useRef(false);

    const defaultCommunicationSettings: CommunicationSettings = {
        permitsEmailCorrespondence: false,
        permitsTelephoneCorrespondence: false,
        permitsSmsCorrespondence: false,
        permitsPostalCorrespondence: false,
    };

    const validationSchema = yup
        .object()
        .shape(EditCompanyCommunicationSettingsSchema);

    const displayedValues: CommunicationSettings =
        commsSettings ?? defaultCommunicationSettings;

    const {
        permitsEmailCorrespondence,
        permitsTelephoneCorrespondence,
        permitsSmsCorrespondence,
        permitsPostalCorrespondence,
    } = displayedValues;

    const methods = useForm<CommunicationSettings>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: displayedValues,
    });

    // filter(Boolean) removes any falsy values from the array
    const allowedCommunicationsArray = [
        permitsEmailCorrespondence && "Email",
        permitsTelephoneCorrespondence && "Phone call",
        permitsSmsCorrespondence && "Text message",
        permitsPostalCorrespondence && "Post",
    ].filter(Boolean);

    if (!allowedCommunicationsArray.length)
        allowedCommunicationsArray.push("None set");
    const allowedCommunicationsString = allowedCommunicationsArray.join(", ");

    useEffect(() => {
        methods.reset(displayedValues);
        isLoading.current = false;
    }, [JSON.stringify(displayedValues)]);

    return {
        methods,
        allowedCommunicationsString,
        displayedValues,
        isLoading,
    };
};
