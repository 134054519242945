import { Dispatch, SetStateAction } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { resolverDebugger } from "../../utils/yupResolverDebugger";
import { errorToast } from "../../toast";
import { formatDateToString } from "../../utils/formatDateToString";
import Button from "../Button";
import EditableContentBlock from "../EditableContentBlock";
import FormDateInput from "../FormDateInput";
import FormTextInput from "../FormTextInput";
import {
    driverInformationValidationSchema,
    type EditDriverFormValues,
} from "./EditDriverFormValues";
import useAuth from "../../auth/useAuth";
import { canEditPii } from "../../auth/userAccessHelpers";
import * as yup from "yup";
import { useStaleDataFormReset } from "../../hooks/useStaleDataFormReset";

type EditPersonalInformationFormProps = {
    initialValues: EditDriverFormValues;
    isLoading: boolean;
    onSubmit: (data: EditDriverFormValues, callback: () => void) => void;
};

const validationSchema = yup.object().shape(driverInformationValidationSchema);

const EditPersonalInformationForm: React.FC<
    EditPersonalInformationFormProps
> = ({ initialValues, isLoading, onSubmit }) => {
    const { decodedToken } = useAuth();

    const methods = useForm<EditDriverFormValues>({
        mode: "onSubmit",
        resolver: (data, context, options) =>
            resolverDebugger(data, context, options, validationSchema),
        defaultValues: initialValues,
    });

    const nonEmailEditedFields = () => {
        const editedFields = methods.formState.dirtyFields;
        if (
            editedFields.firstName ||
            editedFields.lastName ||
            editedFields.dateOfBirth ||
            editedFields.mobileNumber
        ) {
            return true;
        }
        return false;
    };

    const handleEmailIsEmptyString = () => {
        methods.setValue("emailAddress", initialValues.emailAddress);
        errorToast("Cannot remove an existing email address");
    };

    const handleFormSubmit = async (
        values: EditDriverFormValues,
        setIsEdit: Dispatch<SetStateAction<boolean>>
    ) => {
        //stop form if the only edit was removing the email address, otherwise continue.
        if (!nonEmailEditedFields() && values.emailAddress === "") {
            handleEmailIsEmptyString();
            return;
        }

        //if user tries to remove email address, leave the email unchanged
        const valuesDisallowingEmptyEmail: EditDriverFormValues = {
            ...values,
            emailAddress:
                values.emailAddress !== ""
                    ? values.emailAddress
                    : initialValues.emailAddress,
        };
        onSubmit(valuesDisallowingEmptyEmail, () => {
            setIsEdit(false);
            if (values.emailAddress === "") {
                handleEmailIsEmptyString();
            }
        });
    };

    const control = methods.control;
    const currentForm = useWatch({ control });

    useStaleDataFormReset(control, methods.reset, initialValues);

    return (
        <EditableContentBlock title="Personal Information">
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            <p>
                                <b>First name:</b> {initialValues.firstName}
                            </p>
                            <p>
                                <b>Last name:</b> {initialValues.lastName}
                            </p>
                            {canEditPii(decodedToken) && (
                                <p>
                                    <b>Date of birth:</b>{" "}
                                    {initialValues.dateOfBirth instanceof Date
                                        ? formatDateToString(
                                              initialValues.dateOfBirth
                                          )
                                        : ""}
                                </p>
                            )}
                            <p>
                                <b>Email address:</b>{" "}
                                {initialValues.emailAddress}
                            </p>
                            <p>
                                <b>Mobile number:</b>{" "}
                                {initialValues.mobileNumber}
                            </p>
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit((values) =>
                                    handleFormSubmit(values, setIsEdit)
                                )}
                            >
                                <div className="w-full">
                                    <FormTextInput
                                        label="First Name"
                                        required
                                        id="firstName"
                                        placeholder="Enter first name"
                                        error={
                                            methods.formState.errors.firstName
                                        }
                                        autoFocus
                                        {...methods.register("firstName")}
                                    />
                                    <FormTextInput
                                        label="Surname"
                                        required
                                        id="lastName"
                                        placeholder="Enter surname"
                                        error={
                                            methods.formState.errors.lastName
                                        }
                                        {...methods.register("lastName")}
                                    />
                                    {canEditPii(decodedToken) && (
                                        <Controller
                                            name="dateOfBirth"
                                            control={methods.control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <FormDateInput
                                                    id="dateOfBirth"
                                                    label="Date of Birth"
                                                    value={value}
                                                    onChange={onChange}
                                                    error={
                                                        methods.formState.errors
                                                            .dateOfBirth
                                                    }
                                                    maxDate={new Date()}
                                                />
                                            )}
                                        />
                                    )}
                                    <FormTextInput
                                        label="Email Address"
                                        id="emailAddress"
                                        placeholder="Enter email address"
                                        required={!!initialValues.emailAddress}
                                        helpText="Cannot remove an email address"
                                        error={
                                            methods.formState.errors
                                                .emailAddress
                                        }
                                        {...methods.register("emailAddress")}
                                    />
                                    <FormTextInput
                                        label="Mobile number"
                                        id="mobileNumber"
                                        placeholder="Enter mobile number"
                                        error={
                                            methods.formState.errors
                                                .mobileNumber
                                        }
                                        {...methods.register("mobileNumber")}
                                    />
                                </div>
                                <div className="mt-8 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => {
                                            setIsEdit(false);
                                            methods.reset({
                                                ...currentForm,
                                                firstName:
                                                    initialValues.firstName,
                                                lastName:
                                                    initialValues.lastName,
                                                dateOfBirth:
                                                    initialValues.dateOfBirth,
                                                emailAddress:
                                                    initialValues.emailAddress,
                                                mobileNumber:
                                                    initialValues.mobileNumber,
                                            });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" loading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditPersonalInformationForm;
