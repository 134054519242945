import PdfReport from "../ReusableComponents/PdfReport";
import ReportAnalysisContainer from "../ReusableComponents/PdfReport/ReportAnalysisContainer";
import { ApiDriverByIdResponse } from "../../models/api/admin/driver";
import { S2DApiFetcherResponse } from "../../api/common/apiResponse";
import DriverRiskProfile from "./DriverRiskProfile";
import Alerts from "../Alerts";
import DriverInformationTab from "../DriverProfileTabs/DriverInformationTab";
import { EditDriverDto } from "../../models/app/admin/driver";
import { useRef } from "react";
import FormMessageBox from "../FormMessageBox";
import EditDvlaForm from "../Dvla/EditDvlaForm";
import ApiError from "../../api/common/apiError";
import EditGreyFleetFormBase from "../GreyFleet/EditGreyFleetFormBase";
import { GreyFleetInviteVehicle } from "../../models/api/admin/greyFleetInvite";

type DriverProfilePdfReportProps = {
    driverId: string | undefined;
    vehicles: GreyFleetInviteVehicle[] | null | undefined;
    driverData: ApiDriverByIdResponse | undefined;
    dvlaInfo: any;
    dvlaInfoError: ApiError | undefined;
};

const DriverProfilePdfReport = ({
    driverId,
    vehicles,
    driverData,
    dvlaInfo,
    dvlaInfoError,
}:
DriverProfilePdfReportProps) => {
    const documentUploadRef = useRef<HTMLButtonElement | null>(null);
    return (
        <PdfReport
            companyName={driverData?.company?.companyName}
            isDriverReport={true}
            driverName={`${driverData?.firstName} ${driverData?.lastName}`}
        >
            <div className="flex flex-col">
                <ReportAnalysisContainer>
                    <DriverRiskProfile
                        driverData={driverData}
                        driverId={driverId}
                        isLoading={false}
                        refetch={function (): void {
                            throw new Error("Function not implemented.");
                        }}
                        handleScrollToDocumentUpload={function (): void {
                            throw new Error("Function not implemented.");
                        }}
                    />
                </ReportAnalysisContainer>
                {driverData && (
                    <ReportAnalysisContainer>
                        <Alerts alerts={driverData?.alerts} />
                    </ReportAnalysisContainer>
                )}

                <ReportAnalysisContainer title="Driver Information">
                    <DriverInformationTab
                        driverId={driverId}
                        driverData={driverData}
                        isLoadingDriver={false}
                        editDriver={function (
                            id: string,
                            dto: EditDriverDto
                        ): Promise<S2DApiFetcherResponse<string>> {
                            throw new Error("Function not implemented.");
                        }}
                        isEditingDriver={false}
                        refetchDriver={function (): void {
                            throw new Error("Function not implemented.");
                        }}
                        dvlaRefetch={function (): void {
                            throw new Error("Function not implemented.");
                        }}
                        documentUploadRef={documentUploadRef}
                    />
                </ReportAnalysisContainer>
                <ReportAnalysisContainer title="DVLA Information">
                    <>
                        {dvlaInfoError && (
                            <FormMessageBox
                                variant="error"
                                message="Couldn't retrieve dvla information"
                                title={"Error"}
                            />
                        )}
                        {dvlaInfo && (
                            <EditDvlaForm
                                initialValues={{
                                    ...dvlaInfo,
                                }}
                                isManual={driverData?.isManualCheck ?? true}
                                refetch={function (): void {
                                    throw new Error(
                                        "Function not implemented."
                                    );
                                }}
                                isLoading={false}
                            />
                        )}
                    </>
                </ReportAnalysisContainer>
                {vehicles?.map((vehicle) => (
                    <ReportAnalysisContainer title={`Grey Fleet Information - ${vehicle.vrm}`}>
                        <EditGreyFleetFormBase
                            driverId={driverId || ""}
                            vehicleId={vehicle.vehicleId || ""}
                            refetch={function (): void {
                                throw new Error("Function not implemented.");
                            }}
                            tabSelected={""}
                            deletionRequestedAt={null}
                        />
                    </ReportAnalysisContainer>
                ))}
            </div>
        </PdfReport>
    );
};

export default DriverProfilePdfReport;
