import { Controller, UseFormReturn } from "react-hook-form";
import FormDropdown from "../../FormDropdown";
import FormTextInput from "../../FormTextInput";
import FormDropdownMultiSelect from "../../FormDropdownMultiSelect";
import { DriversListFiltersFormValues } from "./DriversListFiltersFormValues";
import {
    categoryCodeWarning,
    categoryCodes,
    foreignLicenceOptions,
} from "../../../constants/dvlaConstants";
import { useEndorsementCodes } from "../../../api/admin/endorsementCodes";
import LoadingMessage from "../../ReusableComponents/LoadingMessage/LoadingMessage";
import FormCheckbox from "../../FormCheckbox";
import { type Dispatch, type SetStateAction } from "react";
import { generateSelectedValues } from "./generateSelectedValues";
import AccordionItem from "../../AccordionItem";

type FilterLicenceInformationProps = {
    methods: UseFormReturn<DriversListFiltersFormValues>;
    selectedValues: string[];
    setSelectedValues: Dispatch<SetStateAction<string[]>>;
    location: string | undefined;
};

const FilterLicenceInformation: React.FC<FilterLicenceInformationProps> = ({
    methods,
    selectedValues,
    setSelectedValues,
    location,
}) => {
    const { endorsementCodes, endorsementCodesIsLoading } =
        useEndorsementCodes();

    const endorsementCodeOptions = new Array(0);
    endorsementCodes?.map((endorsement) => {
        endorsementCodeOptions.push({
            label: endorsement.code,
            value: endorsement.code,
        });
    });

    const categoryCodeOptions = new Array(0);
    categoryCodes.map((code) => {
        categoryCodeOptions.push({
            label: code,
            value: code,
        });
    });

    // Check if "Exclude: true" exists in selectedValues and if the value is true
    const isExcludeChecked = selectedValues.some(
        (value) => value === "Exclude: true"
    );

    return (
        <AccordionItem
            title="Licence information"
            valueSummary={selectedValues}
            modalStyling
        >
            <>
                <FormTextInput
                    label="Licence number contains"
                    id="licenceNumberContains"
                    placeholder="Enter text"
                    error={methods.formState.errors.licence?.number}
                    {...methods.register("licence.number")}
                    onBlur={(e) =>
                        generateSelectedValues(
                            selectedValues,
                            setSelectedValues,
                            "Number",
                            e.target.value
                        )
                    }
                />
                <Controller
                    name="licence.licenceCountryOrigin"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                        <FormDropdown
                            label="Licence origin"
                            options={foreignLicenceOptions}
                            value={value}
                            onChange={onChange}
                            pleaseSelectText="Licence status"
                            onBlur={(e) =>
                                generateSelectedValues(
                                    selectedValues,
                                    setSelectedValues,
                                    "",
                                    e
                                )
                            }
                        />
                    )}
                />

                <p className="mt-4 text-xl font-semibold">Endorsements</p>
                {endorsementCodesIsLoading && <LoadingMessage />}
                {endorsementCodes && (
                    <Controller
                        name="licence.endorsements.values"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdownMultiSelect
                                label="Endorsement codes"
                                options={endorsementCodeOptions}
                                value={value ?? []}
                                onChange={onChange}
                                onBlur={(e) =>
                                    generateSelectedValues(
                                        selectedValues,
                                        setSelectedValues,
                                        "Endorsement values",
                                        e
                                    )
                                }
                            />
                        )}
                    />
                )}
                <div className="flex gap-6">
                    <FormTextInput
                        label="Endorsement count from"
                        type="number"
                        id="countFrom"
                        placeholder="Enter count from"
                        error={
                            methods.formState.errors.licence?.endorsements
                                ?.hasCount?.from
                        }
                        {...methods.register(
                            "licence.endorsements.hasCount.from"
                        )}
                        onBlur={(e) =>
                            generateSelectedValues(
                                selectedValues,
                                setSelectedValues,
                                "Endorsement count from",
                                e.target.value
                            )
                        }
                    />
                    <FormTextInput
                        label="Endorsement count to"
                        type="number"
                        id="countTo"
                        placeholder="Enter count to"
                        error={
                            methods.formState.errors.licence?.endorsements
                                ?.hasCount?.to
                        }
                        {...methods.register(
                            "licence.endorsements.hasCount.to"
                        )}
                        onBlur={(e) =>
                            generateSelectedValues(
                                selectedValues,
                                setSelectedValues,
                                "Endorsement count to",
                                e.target.value
                            )
                        }
                    />
                </div>

                {location !== "professionalDrivers" && (
                    <>
                        <p className="mt-4 text-xl font-semibold">Categories</p>
                        <Controller
                            name="licence.categories.values"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdownMultiSelect
                                    label="Categories"
                                    options={categoryCodeOptions}
                                    value={value ?? []}
                                    onChange={onChange}
                                    helpText={categoryCodeWarning}
                                    onBlur={(e) =>
                                        generateSelectedValues(
                                            selectedValues,
                                            setSelectedValues,
                                            "Categories values",
                                            e
                                        )
                                    }
                                />
                            )}
                        />
                        <FormCheckbox
                            id="exclude"
                            label="Exclude these values"
                            {...methods.register("licence.categories.exclude")}
                            error={
                                methods.formState.errors.licence?.categories
                                    ?.exclude
                            }
                            defaultChecked={isExcludeChecked}
                            inline
                        />
                    </>
                )}
            </>
        </AccordionItem>
    );
};

export default FilterLicenceInformation;
