import { useState } from "react";

export type useFileDropperParams = {
    handleSetValue: (file: File) => void;
    onLoadFile: (file: File) => Promise<unknown>;
    setStoreFile?: (value: React.SetStateAction<File[]>) => void;
};

const useFileDropper = ({
    handleSetValue,
    onLoadFile,
    setStoreFile,
}: useFileDropperParams) => {
    const [dragActive, setDragActive] = useState(false);

    const handleDrag: React.DragEventHandler<HTMLDivElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop: React.DragEventHandler<HTMLDivElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files) {
            setStoreFile && setStoreFile([event.dataTransfer.files[0]]);
            handleSetValue(event.dataTransfer.files[0]);
            onLoadFile(event.dataTransfer.files[0]);
        }
    };

    return {
        dragActive,
        handleDrag,
        handleDrop,
    };
};

export default useFileDropper;
