import { Controller, UseFormReturn } from "react-hook-form";
import { useFormFieldCollectiveValidation } from "../../hooks/useFormFieldCollectiveValidation";
import AccordionItem from "../AccordionItem";
import FormDropdown from "../FormDropdown";
import { type AddCompanyFormValues } from "./AddCompanyFormValues";
import FormTextInput from "../FormTextInput";

type CompanyRiskCategoriesFieldsProps = {
    methods: UseFormReturn<AddCompanyFormValues>;
};

const rangeOptions = new Array(0);
for (let i = 0; i <= 12; i++) {
    rangeOptions.push({
        label: `${i} penalty points`,
        value: i,
    });
}

const CompanyRiskCategoriesFields: React.FC<
    CompanyRiskCategoriesFieldsProps
> = ({ methods }) => {
    const isSectionValid =
        useFormFieldCollectiveValidation<AddCompanyFormValues>(
            ["riskCategory"],
            methods
        );
    return (
        <AccordionItem title="Risk category settings" error={!isSectionValid}>
            <div className="flex flex-col space-y-3">
                <FormTextInput
                    label="Range 1 Name:"
                    id="range1Name"
                    placeholder="Please enter a name for this range"
                    required
                    error={methods.formState.errors.riskCategory?.range1Name}
                    {...methods.register("riskCategory.range1Name")}
                />
                <Controller
                    name="riskCategory.range1"
                    control={methods.control}
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Range starts at:"
                            options={rangeOptions ?? []}
                            value={value}
                            disabled
                            required
                            onChange={onChange}
                            pleaseSelectText="Select penalty points"
                            error={
                                methods.formState.errors.riskCategory?.range1
                            }
                        />
                    )}
                />
                <FormTextInput
                    label="Range 2 Name:"
                    id="range2Name"
                    placeholder="Please enter a name for this range"
                    required
                    error={methods.formState.errors.riskCategory?.range2Name}
                    {...methods.register("riskCategory.range2Name")}
                />

                <Controller
                    name="riskCategory.range2"
                    control={methods.control}
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Range starts at:"
                            options={rangeOptions ?? []}
                            value={value ?? null}
                            required
                            onChange={onChange}
                            pleaseSelectText="Select penalty points"
                            error={
                                methods.formState.errors.riskCategory?.range2
                            }
                        />
                    )}
                />
                <FormTextInput
                    label="Range 3 Name:"
                    id="range3Name"
                    placeholder="Please enter a name for this range"
                    required
                    error={methods.formState.errors.riskCategory?.range3Name}
                    {...methods.register("riskCategory.range3Name")}
                />
                <Controller
                    name="riskCategory.range3"
                    control={methods.control}
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Range starts at:"
                            options={rangeOptions ?? []}
                            value={value ?? null}
                            required
                            onChange={(value) => {
                                onChange(value);
                            }}
                            pleaseSelectText="Select penalty points"
                            error={
                                methods.formState.errors.riskCategory?.range3
                            }
                        />
                    )}
                />
            </div>
        </AccordionItem>
    );
};

export default CompanyRiskCategoriesFields;
