import clsx from "clsx";
import HelpIcon from "./HelpIcon";

export type FormInputLabelProps = {
    htmlFor?: string;
    children: string;
    required?: boolean;
    tooltipText?: string;
    className?: string;
};

const FormInputLabel = ({
    htmlFor,
    children,
    required,
    tooltipText,
    className,
}: FormInputLabelProps) => {
    return (
        <label
            htmlFor={htmlFor}
            className={clsx(
                "text-sm m-2 flex flex-row items-center select-none",
                className
            )}
        >
            {required && <span className="text-S2D-error-40 mr-2"> * </span>}
            {children}
            {tooltipText && (
                <HelpIcon tooltipText={tooltipText} className="ml-2" />
            )}
        </label>
    );
};

export default FormInputLabel;
