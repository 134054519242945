import { type ApiDriverDetailsResponse } from "../../models/api/admin/driver";
import {
    AnalysisChartType,
    type AllDriversAnalysisCharts,
} from "../../models/app/admin/analysisChart";
import { CompanyRiskProfile } from "../../models/app/admin/company";
import { driverPointsColor } from "../../utils/driverPointsColour";
import { getAge } from "../../utils/getAge";
import {
    type TableContent,
    type TableHeader,
} from "../ReusableComponents/Table";
import UserIcon from "../UserIcon";

export type DriverChartAnalysisTableAllowedIds =
    | "name"
    | "client"
    | "department"
    | "points"
    | "age"
    | "endorsementCodes"
    | "licenceType"
    | "categoriesHeld"
    | "restrictionCodes"
    | "licenceOrigin"
    | "yearsHeldPhotocard"
    | "yearsHeldCategoryB"
    | "yearsHeldCategoryC"
    | "yearsHeldCategoryCE"
    | "yearsHeldCategoryD"
    | "yearsHeldCategoryDE";

export type DriverChartAnalysisTableHeader =
    TableHeader<DriverChartAnalysisTableAllowedIds>;
export type DriverChartAnalysisTableContent = TableContent<
    ApiDriverDetailsResponse,
    DriverChartAnalysisTableAllowedIds
>;

export const initDriverChartAnalysisTableHeaders = (
    chartType: AllDriversAnalysisCharts,
    isProfessional?: boolean
): DriverChartAnalysisTableHeader[] => [
    {
        title: "Name",
        id: "name",
        hide: false,
    },
    {
        title: "Client",
        id: "client",
        hide: false,
    },
    {
        title: "Department",
        id: "department",
        hide: false,
    },
    {
        title: "Points",
        id: "points",
        hide: chartType === AnalysisChartType.DriversByPoints ? false : true,
    },
    {
        title: "Age",
        id: "age",
        hide: chartType === AnalysisChartType.DriversByAgeRange ? false : true,
    },
    {
        title: "Endorsement codes",
        id: "endorsementCodes",
        hide: chartType === AnalysisChartType.EndorsementCodes ? false : true,
    },
    {
        title: "Licence type",
        id: "licenceType",
        hide:
            chartType === AnalysisChartType.DriversByLicenceType ||
            chartType === AnalysisChartType.DriversByLicenceCategory
                ? false
                : true,
    },
    {
        title: "Categories held",
        id: "categoriesHeld",
        hide:
            chartType === AnalysisChartType.DriversByLicenceCategory
                ? false
                : true,
    },
    {
        title: "Restriction codes",
        id: "restrictionCodes",
        hide: chartType === AnalysisChartType.DriverRestrictions ? false : true,
    },
    {
        title: "Licence origin",
        id: "licenceOrigin",
        hide:
            chartType === AnalysisChartType.DriversByLicenceOrigin
                ? false
                : true,
    },
    {
        title: "Years held photocard",
        id: "yearsHeldPhotocard",
        hide:
            !isProfessional &&
            chartType === AnalysisChartType.YearsFullLicenceHeld
                ? false
                : true,
    },
    {
        title: "Years held Category B",
        id: "yearsHeldCategoryB",
        hide:
            !isProfessional &&
            chartType === AnalysisChartType.YearsFullLicenceHeld
                ? false
                : true,
    },
    {
        title: "Years held Category C",
        id: "yearsHeldCategoryC",
        hide:
            isProfessional &&
            chartType === AnalysisChartType.YearsFullLicenceHeld
                ? false
                : true,
    },
    {
        title: "Years held Category CE",
        id: "yearsHeldCategoryCE",
        hide:
            isProfessional &&
            chartType === AnalysisChartType.YearsFullLicenceHeld
                ? false
                : true,
    },
    {
        title: "Years held Category D",
        id: "yearsHeldCategoryD",
        hide:
            isProfessional &&
            chartType === AnalysisChartType.YearsFullLicenceHeld
                ? false
                : true,
    },
    {
        title: "Years held Category DE",
        id: "yearsHeldCategoryDE",
        hide:
            isProfessional &&
            chartType === AnalysisChartType.YearsFullLicenceHeld
                ? false
                : true,
    },
];

export const initDriverChartAnalysisTableContents = (
    drivers: ApiDriverDetailsResponse[],
    companyRiskProfiles?: CompanyRiskProfile[]
): DriverChartAnalysisTableContent[] => {
    const tableContents = new Array<DriverChartAnalysisTableContent>(0);

    drivers.map((driver) => {
        const pointsColor = driverPointsColor(
            driver.totalPoints,
            companyRiskProfiles
        );

        tableContents.push({
            rowId: driver.driverId,
            rowData: driver,
            cellData: [
                {
                    id: "name",
                    content: (
                        <div className="flex items-center gap-2">
                            <UserIcon
                                firstName={driver.firstName}
                                lastName={driver.lastName}
                                size="small"
                            />
                            <p>
                                {driver.firstName} {driver.lastName}
                            </p>
                        </div>
                    ),
                },
                {
                    id: "client",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.companyName}
                        </p>
                    ),
                },
                {
                    id: "department",
                    content: <p>{driver.departmentName}</p>,
                },
                {
                    id: "points",
                    content: (
                        <div className="pl-2">
                            <div
                                className={`h-7 w-7 rounded-full ${pointsColor} flex items-center justify-center`}
                            >
                                {driver.totalPoints ?? "-"}
                            </div>
                        </div>
                    ),
                },
                {
                    id: "age",
                    content: (
                        <p>
                            {driver.dateOfBirth
                                ? getAge(driver.dateOfBirth)
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "endorsementCodes",
                    content: <p>{driver.endorsementCodes?.join(", ")}</p>,
                },
                {
                    id: "licenceType",
                    content: <p>{driver.licenceType}</p>,
                },
                {
                    id: "categoriesHeld",
                    content: <p>{driver.licenceCategories?.join(", ")}</p>,
                },
                {
                    id: "restrictionCodes",
                    content: <p>{driver.driverRestrictions?.join(", ")}</p>,
                },
                {
                    id: "licenceOrigin",
                    content: <p>{driver.licenceOrigin}</p>,
                },
                {
                    id: "yearsHeldPhotocard",
                    content: (
                        <p>
                            <p>{driver.yearsHeldPhotocard}</p>
                        </p>
                    ),
                },
                {
                    id: "yearsHeldCategoryB",
                    content: (
                        <p>
                            <p>{driver.yearsHeldCategoryB}</p>
                        </p>
                    ),
                },
                {
                    id: "yearsHeldCategoryC",
                    content: (
                        <p>
                            <p>{driver.yearsHeldCategoryC}</p>
                        </p>
                    ),
                },
                {
                    id: "yearsHeldCategoryCE",
                    content: (
                        <p>
                            <p>{driver.yearsHeldCategoryCE}</p>
                        </p>
                    ),
                },
                {
                    id: "yearsHeldCategoryD",
                    content: (
                        <p>
                            <p>{driver.yearsHeldCategoryD}</p>
                        </p>
                    ),
                },
                {
                    id: "yearsHeldCategoryDE",
                    content: (
                        <p>
                            <p>{driver.yearsHeldCategoryDE}</p>
                        </p>
                    ),
                },
            ],
        });
    });

    return tableContents;
};
