export const downloadFromArrayBuffer = (
    file: ArrayBuffer,
    fileName: string,
    setDownloading?: (value: boolean) => void,
    fileType?: string,
    usePrefix = true
) => {
    setDownloading && setDownloading(true);
    const prefix = usePrefix ? "FileSafeTransfer" : "";
    const finalFileName = `${prefix}${fileName}`;

    const blob = new Blob([file], { type: fileType ? fileType : "application/zip" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = finalFileName;
    link.click();
    URL.revokeObjectURL(url);
    setDownloading && setDownloading(false);
};
