import { SetStateAction, useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import FormTextInput from "../../../../FormTextInput";
import SubmitOrCancelButtonBar from "../../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import { errorToast, successToast } from "../../../../../toast";
import { AddOrEditModalStates } from "../../CompanyBillingTab";
import { Plus, Minus } from "phosphor-react";
import FormDropdown, { FormDropdownOption } from "../../../../FormDropdown";
import IconButton from "../../../../IconButton";
import Button from "../../../../Button";
import { ApiDepartmentItem } from "../../../../../models/api/admin/department";
import useAddInvoiceReferenceNumberForm from "./useAddInvoiceReferenceNumberForm";
import { AddInvoiceReferenceNumberDto, AddInvoiceReferenceNumberValues } from "../../../../../models/app/admin/invoiceReferenceNumber";
import { AddInvoiceReferenceNumberFormValues } from "./AddInvoiceReferenceNumberFormValues";
import { ApiInvoiceReferenceType } from "../../../../../models/api/admin/invoiceReferenceNumber";

const typeOptions: FormDropdownOption<ApiInvoiceReferenceType>[] = [
    { label: "Reference", value: ApiInvoiceReferenceType.Reference },
    { label: "Purchase Order", value: ApiInvoiceReferenceType.PurchaseOrder },
];

export default function AddReferenceNumbersForm({
    setModalState,
    departments,
    refetchInvoiceReferenceNumbers,
    companyId,
    companyName
}: {
    setModalState: (value: SetStateAction<AddOrEditModalStates>) => void;
    departments: ApiDepartmentItem[];
    refetchInvoiceReferenceNumbers: () => void;
    companyId: string;
    companyName: string;
}) {
    const { methods, addInvoiceReferenceNumber, isAddingInvoiceReferenceNumber } =
        useAddInvoiceReferenceNumberForm(companyId);
    const { control, handleSubmit, formState } = methods;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "numbers",
    });

    // Add initial field
    useEffect(() => {
        if (fields.length === 0) {
            append({ value: "" }, { shouldFocus: false });
        }
    }, [fields, append]);

    const handleSave = async (data: AddInvoiceReferenceNumberFormValues) => {
        const cleanedNumbers = data.numbers.map(field => field.value).filter(value => value.trim() !== "");
        if (cleanedNumbers.length === 0) {
            errorToast("Please add at least one reference number.");
            return;
        }

        // Set departmentId to null if company is selected
        const departmentId = data.departmentId === companyId ? null : data.departmentId;

        const invoiceReferenceNumberValues: AddInvoiceReferenceNumberValues = {
            departmentId,
            numbers: cleanedNumbers,
            type: data.type,
        };

        const invoiceReferenceNumberData: AddInvoiceReferenceNumberDto = {
            referenceNumbers: invoiceReferenceNumberValues
        };

        const response = await addInvoiceReferenceNumber(invoiceReferenceNumberData);

        if (response.success) {
            successToast("Reference number(s) added successfully");
            refetchInvoiceReferenceNumbers();
            setModalState("closed");
        } else {
            errorToast("Failed to add reference numbers");
        }
    };

    const handleAddField = () => {
        // shouldFocus added to prevent validation triggering upon adding a field
        append({ value: "" }, { shouldFocus: false });
    };

    const departmentOptions: FormDropdownOption<string>[] = [
        { label: companyName, value: companyId },
        ...departments.map(department => ({
            label: department.departmentName,
            value: department.departmentId
        }))
    ];

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <div className="w-full max-w-3xl p-6 pb-8 mx-auto">
                <div className="grid grid-cols-2 gap-4">
                    <Controller
                        name="departmentId"
                        control={control}
                        render={({ field }) => (
                            <FormDropdown
                                label="Assign to"
                                options={departmentOptions}
                                value={field.value}
                                onChange={field.onChange}
                                required
                                pleaseSelectText="Assign reference/PO number to"
                                error={formState.errors.departmentId}
                            />
                        )}
                    />
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <FormDropdown
                                label="Type"
                                options={typeOptions}
                                value={field.value}
                                onChange={field.onChange}
                                required
                                pleaseSelectText="Select type"
                                error={formState.errors.type}
                            />
                        )}
                    />
                </div>
                {fields.map((field, index) => (
                    <div key={field.id} className={`flex items-center mb-3 ${index === 0 ? "mt-3" : ""}`}>
                        <div className="flex-grow">
                            <Controller
                                name={`numbers.${index}.value`}
                                control={control}
                                render={({ field }) => (
                                    <FormTextInput
                                        label={`Number ${index + 1}`}
                                        placeholder="Enter number"
                                        error={formState.errors.numbers?.[index]?.value}
                                        {...field}
                                        required
                                    />
                                )}
                            />
                        </div>
                        <div className={`flex items-center justify-center ${formState.errors.numbers?.[index]?.value ? "mt-4" : "mt-8"}`}>
                            <IconButton className="pr-0" onClick={() => remove(index)} disabled={fields.length === 1} icon={Minus} label={"Remove Item"} />
                        </div>
                    </div>
                ))}
                <div className="mt-5">
                    <Button variant="primary" onClick={handleAddField} icon={Plus} type="button" disabled={fields.length > 9}>
                        Add Field
                    </Button>
                </div>
            </div>

            <div className="mt-0 rounded-b-sm border-t-[1px] border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6">
                <SubmitOrCancelButtonBar
                    onCancel={() => setModalState("closed")}
                    isLoading={isAddingInvoiceReferenceNumber}
                    disabled={isAddingInvoiceReferenceNumber}
                />
            </div>
        </form>
    );
}
