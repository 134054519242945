import {
    ApiDriverDvlaInfoResponse,
    DvlaTachocard,
    EntitlementRestrictionCodes,
} from "../../models/api/admin/dvla";
import {
    DvlaAddressDto,
    DvlaCpcDto,
    DvlaEntitlementDto,
    DvlaLicenceDto,
    DvlaPersonalInfoDto,
} from "../../models/app/admin/dvla";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

const useEditDvlaPersonalInfo = () =>
    useS2DApiFetcher<
        DvlaPersonalInfoDto,
        null,
        { id: string; dto: DvlaPersonalInfoDto }
    >(
        "PUT",
        ({ id, dto }) => ({
            url: `api/drivers/${id}/dvla-personal-info`,
            body: dto,
        }),
        [],
        true
    );

const useEditDvlaAddress = () =>
    useS2DApiFetcher<DvlaAddressDto, null, { id: string; dto: DvlaAddressDto }>(
        "PUT",
        ({ id, dto }) => ({
            url: `api/drivers/${id}/dvla-address`,
            body: dto,
        }),
        [],
        true
    );

const useEditDvlaLicence = () =>
    useS2DApiFetcher<DvlaLicenceDto, null, { id: string; dto: DvlaLicenceDto }>(
        "PUT",
        ({ id, dto }) => ({
            url: `api/drivers/${id}/dvla-licence`,
            body: dto,
        }),
        [],
        true
    );

//elsewhere in code these are referred to as categories
const useEditDvlaEntitlements = () =>
    useS2DApiFetcher<
        DvlaEntitlementDto,
        null,
        { id: string; dto: DvlaEntitlementDto }
    >(
        "PUT",
        ({ id, dto }) => ({
            url: `api/drivers/${id}/entitlements`,
            body: dto,
        }),
        [],
        true
    );

export const getEntitlementRestrictionCodes = () => {
    const { data, error, isLoading } =
        useS2DApiSWR<EntitlementRestrictionCodes>("api/restrictions/codes");

    return {
        entitlementRestrictionCodes: data,
        entitlementRestrictionCodesError: error,
        isLoadingEntitlementRestrictionCodes: isLoading,
    };
};

const useEditDvlaTachoCard = () =>
    useS2DApiFetcher<
        DvlaTachocard,
        null,
        { driverId: string; tachocardId: string; dto: DvlaTachocard }
    >(
        "PUT",
        ({ driverId, tachocardId, dto }) => ({
            url: `api/drivers/${driverId}/tachocards/${tachocardId}`,
            body: dto,
        }),
        [],
        true
    );

const useAddDvlaTachoCard = () =>
    useS2DApiFetcher<
        DvlaTachocard,
        null,
        { driverId: string; dto: DvlaTachocard }
    >(
        "POST",
        ({ driverId, dto }) => ({
            url: `api/drivers/${driverId}/tachocards`,
            body: dto,
        }),
        [],
        true
    );

const useDeleteDvlaTachocard = () =>
    useS2DApiFetcher<null, null, { driverId: string; tachocardId: string }>(
        "DELETE",
        ({ driverId, tachocardId }) => ({
            url: `api/drivers/${driverId}/tachocards/${tachocardId}`,
        }),
        [],
        true
    );

const useDeleteDvlaCpc = () =>
    useS2DApiFetcher<null, null, { driverId: string }>(
        "DELETE",
        ({ driverId }) => ({
            url: `api/drivers/${driverId}/cpc`,
        }),
        [],
        true
    );

const useEditDvlaCpc = () =>
    useS2DApiFetcher<DvlaCpcDto, null, { driverId: string; dto: DvlaCpcDto }>(
        "PUT",
        ({ driverId, dto }) => ({
            url: `api/drivers/${driverId}/cpc`,
            body: dto,
        }),
        [],
        true
    );

export const useDriverDvlaInfo = (id?: string) => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiDriverDvlaInfoResponse>(
            id ? `api/drivers/${id}/dvla-info` : null,
            { preventRedirectOn404: true }
        );

    const dvlaInfo = data
        ? {
              ...data,
              // somewhere licence number is being padded with spaces and I am unable to find where
              // this solution is not ideal
              licenceNumber: data.licenceNumber?.replace(/\s/g, "") ?? "",
          }
        : undefined;

    const [editDvlaPersonalInfo, isEditingDvlaPersonalInfo] =
        useEditDvlaPersonalInfo();
    const [editDvlaAddress, isEditingDvlaAddress] = useEditDvlaAddress();
    const [editDvlaLicence, isEditingDvlaLicence] = useEditDvlaLicence();
    const [editDvlaEntitlements, isEditingDvlaEntitlements] =
        useEditDvlaEntitlements();
    const [editDvlaTachocard, isEditingDvlaTachocard] = useEditDvlaTachoCard();
    const [addDvlaTachocard, isAddingDvlaTachocard] = useAddDvlaTachoCard();
    const [deleteDvlaTachocard, isDeletingDvlaTachocard] =
        useDeleteDvlaTachocard();
    const [editDvlaCpc, isEditingDvlaCpc] = useEditDvlaCpc();
    const [deleteDvlaCpc, isDeletingDvlaCpc] = useDeleteDvlaCpc();

    return {
        dvlaInfo,
        dvlaRefetch: refetch,
        dvlaInfoError: error,
        dvlaInfoIsLoading: isLoading,
        refetchDvlaInfo: refetch,
        editDvlaPersonalInfo: async (id: string, dto: DvlaPersonalInfoDto) =>
            await editDvlaPersonalInfo({ id, dto }),
        isEditingDvlaPersonalInfo,
        editDvlaAddress: async (id: string, dto: DvlaAddressDto) =>
            await editDvlaAddress({ id, dto }),
        isEditingDvlaAddress,
        editDvlaLicence: async (id: string, dto: DvlaLicenceDto) =>
            await editDvlaLicence({ id, dto }),
        isEditingDvlaLicence,
        editDvlaEntitlements: async (id: string, dto: DvlaEntitlementDto) =>
            await editDvlaEntitlements({ id, dto }),
        isEditingDvlaEntitlements,
        editDvlaTachocard: async (
            driverId: string,
            tachocardId: string,
            dto: DvlaTachocard
        ) => await editDvlaTachocard({ driverId, tachocardId, dto }),
        isEditingDvlaTachocard,
        addDvlaTachocard: async (driverId: string, dto: DvlaTachocard) =>
            await addDvlaTachocard({ driverId, dto }),
        isAddingDvlaTachocard,
        deleteDvlaTachocard: async (driverId: string, tachocardId: string) =>
            await deleteDvlaTachocard({ driverId, tachocardId }),
        isDeletingDvlaTachocard,
        editDvlaCpc: async (driverId: string, dto: DvlaCpcDto) =>
            await editDvlaCpc({ driverId, dto }),
        deleteDvlaCpc: async (driverId: string) => await deleteDvlaCpc({driverId}),
        isDeletingDvlaCpc,
        isEditingDvlaCpc,
    };
};
