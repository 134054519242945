import clsx from "clsx";
import { IconProps } from "phosphor-react";
import React from "react";

type IconButtonProps = {
    type?: JSX.IntrinsicElements["button"]["type"];
    disabled?: boolean;
    onClick?: () => void;
    loading?: boolean;
    value?: string;
    label: string;
    icon?: React.ForwardRefExoticComponent<
        IconProps & React.RefAttributes<SVGSVGElement>
    >;
    className?: string;
};

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
    (
        {
            type,
            disabled,
            onClick,
            loading,
            value,
            icon: Icon,
            label,
            className,
        },
        ref
    ) => {
        const _disabled = disabled || loading;

        return (
            <button
                value={value}
                className={clsx(
                    "flex items-center rounded-sm p-2 font-semibold text-black transition-all hover:opacity-75",
                    loading && "cursor-not-allowed opacity-75",
                    className
                )}
                type={type}
                disabled={_disabled || loading}
                onClick={onClick}
                ref={ref}
            >
                {Icon && (
                    <div className="text-S2D-neutral-10">
                        <Icon size={24} weight="fill" />
                    </div>
                )}
                <span className="sr-only">{label}</span>
            </button>
        );
    }
);

export default IconButton;
