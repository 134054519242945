import UserIcon from "../UserIcon";
import { ReactNode } from "react";
import clsx from "clsx";

type ProfileLabelProps = {
    firstName: string;
    lastName?: string;
    title: string;
    subtitle?: string;
    children?: ReactNode;
    hasLargeText?: boolean;
};

const ProfileLabel: React.FC<ProfileLabelProps> = ({
    firstName,
    lastName,
    title,
    subtitle,
    children,
    hasLargeText = false,
}) => {
    return (
        <div className="flex items-center">
            <UserIcon firstName={firstName} lastName={lastName} size="large" />
            <div className="flex flex-col gap-4">
                <p
                    className={clsx(
                        `mb-1 max-w-[200px] text-2xl leading-none 
                        sm:max-w-[300px] md:max-w-[400px] md:text-3xl lg:max-w-[500px]`,
                        hasLargeText && "lg:text-[2.75rem]"
                    )}
                >
                    {title}
                </p>
                {subtitle && (
                    <p className="font-semibold text-S2D-neutral-40">
                        {subtitle}
                    </p>
                )}
                {children && children}
            </div>
        </div>
    );
};

export default ProfileLabel;
