import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
    EditDvlaAddressFormValues,
    EditDvlaCpcFormValidationSchema,
    EditDvlaCpcFormValues,
    EditDvlaDriverAddressFormValidationSchema,
    EditDvlaEntitlementFormValues,
    EditDvlaLicenceInformationFormValues,
    EditDvlaLicenceInformationValidationSchema,
    EditDvlaPersonalInfoFormValidationSchema,
    EditDvlaPersonalInfoFormValues,
    EditDvlaTachocardFormValues,
} from "./EditDvlaFormValues";
import EditCategoriesForm from "./forms/EditCategoriesForm";
import { ApiDriverDvlaInfoResponse } from "../../models/api/admin/dvla";
import EditEndorsementsForm from "./forms/EditEndorsementsForm";
import { useDriverDvlaInfo } from "../../api/admin/dvla";
import { useParams } from "react-router-dom";
import { errorToast, successToast } from "../../toast";
import EditPersonalInformationForm from "./forms/EditPersonalInformationForm";
import EditDriverAddressForm from "./forms/EditDriverAddressForm";
import EditLicenceInformationForm from "./forms/EditLicenceInformationForm";
import EditCpcForm from "./forms/EditCpcForm";
import EditTachocardForm from "./forms/EditTachocardForm";
import { GREAT_BRITAIN } from "../../constants/countries";
import useAuth from "../../auth";
import { canEditPii } from "../../auth/userAccessHelpers";

type EditDvlaFormProps = {
    initialValues: ApiDriverDvlaInfoResponse;
    isManual: boolean;
    refetch: () => void;
    isLoading: boolean;
};

type PageParams = {
    id: string;
};

const personalInformationValidationSchema = yup.object().shape({
    ...EditDvlaPersonalInfoFormValidationSchema,
});

const addressValidationSchema = yup.object().shape({
    ...EditDvlaDriverAddressFormValidationSchema,
});

const licenceValidationSchema = yup.object().shape({
    ...EditDvlaLicenceInformationValidationSchema,
});

const cpcValidationSchema = yup.object().shape({
    ...EditDvlaCpcFormValidationSchema,
});

const EditDvlaForm = ({
    initialValues,
    isManual,
    isLoading,
    refetch,
}: EditDvlaFormProps) => {
    const { id: driverId } = useParams<PageParams>();

    const { decodedToken } = useAuth();
    const canEdit = canEditPii(decodedToken);

    const {
        editDvlaPersonalInfo,
        isEditingDvlaPersonalInfo,
        editDvlaAddress,
        isEditingDvlaAddress,
        editDvlaLicence,
        isEditingDvlaLicence,
        editDvlaEntitlements,
        isEditingDvlaEntitlements,
        editDvlaTachocard,
        isEditingDvlaTachocard,
        addDvlaTachocard,
        isAddingDvlaTachocard,
        deleteDvlaTachocard,
        isDeletingDvlaTachocard,
        editDvlaCpc,
        isEditingDvlaCpc,
        deleteDvlaCpc,
        isDeletingDvlaCpc
    } = useDriverDvlaInfo();

    const entitlementMethods = useForm<EditDvlaEntitlementFormValues>({
        mode: "onSubmit",
        defaultValues: { entitlements: initialValues.entitlements },
    });

    const handleEditDvlaEntitlements = async (
        data: EditDvlaEntitlementFormValues,
        callback: () => void
    ) => {
        if (!driverId) return;
        const response = await editDvlaEntitlements(driverId, data);
        if (!response.success) {
            errorToast("There was an error");
            return;
        }
        successToast("Succesfully updated driver categories");
        refetch();
        callback();
    };

    const personalInformationMethods = useForm<EditDvlaPersonalInfoFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(personalInformationValidationSchema),
        defaultValues: {
            firstName: initialValues.firstName,
            lastName: initialValues.lastName,
            dateOfBirth: initialValues.dateOfBirth,
            gender: initialValues.gender,
        },
    });

    const handleEditDvlaPersonalInformation = async (
        data: EditDvlaPersonalInfoFormValues,
        callback: () => void
    ) => {
        if (!driverId) return;
        const response = await editDvlaPersonalInfo(driverId, data);
        if (!response.success) {
            errorToast("There was an error");
            return;
        }
        successToast("Succesfully updated driver personal information");
        refetch();
        callback();
    };

    const driverAddressMethods = useForm<EditDvlaAddressFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(addressValidationSchema),
        defaultValues: { address: initialValues.address },
    });

    const handleEditDvlaAddress = async (
        data: EditDvlaAddressFormValues,
        callback: () => void
    ) => {
        if (!driverId) return;
        const response = await editDvlaAddress(driverId, data.address);
        if (!response.success) {
            errorToast("There was an error");
            return;
        }
        successToast("Succesfully updated driver address");
        refetch();
        callback();
    };

    const licenceMethods = useForm<EditDvlaLicenceInformationFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(licenceValidationSchema),
        defaultValues: {
            licenceCountryOrigin: initialValues.licenceCountryOrigin,
            licenceNumber: initialValues.licenceNumber,
            licenceStatus: initialValues.licenceStatus,
            licenceType: initialValues.licenceType,
            licenceValidFrom: initialValues.licenceValidFrom,
            licenceValidTo: initialValues.licenceValidTo,
            licenceIssueNumber: initialValues.licenceIssueNumber,
        },
    });

    const handleEditDvlaLicence = async (
        data: EditDvlaLicenceInformationFormValues,
        callback: () => void
    ) => {
        if (!driverId) return;
        const isForeignLicence = data.licenceCountryOrigin !== GREAT_BRITAIN;
        const dataToSubmit = { ...data, foreignLicence: isForeignLicence };
        const response = await editDvlaLicence(driverId, dataToSubmit);
        if (!response.success) {
            errorToast("There was an error");
            return;
        }
        successToast("Successfully updated driver licence");
        refetch();
        callback();
    };

    const handleEditDvlaTachocard = async (
        data: EditDvlaTachocardFormValues,
        callback: () => void,
        tachocardId?: string
    ) => {
        if (!driverId) return;
        let response;
        if (tachocardId) {
            response = await editDvlaTachocard(driverId, tachocardId, data);
        } else {
            response = await addDvlaTachocard(driverId, data);
        }
        if (!response.success) {
            errorToast("There was an error");
            return;
        }
        successToast("Successfully updated tacho cards");
        refetch();
        callback();
    };

    const handleDeleteDvlaTachocard = async (tachocardId: string) => {
        if (!driverId) return;
        const response = await deleteDvlaTachocard(driverId, tachocardId);
        if (!response.success) {
            errorToast("There was an error");
            return;
        }
        successToast("Successfully updated tacho cards");
        refetch();
    };

    const handleDeleteDvlaCpc = async (callback: () => void) => {
        if (!driverId) return;
        const response = await deleteDvlaCpc(driverId);
        if (!response.success) {
            errorToast("There was an error");
            return;
        }
        successToast("Successfully updated CPC information");
        refetch();
        callback();
    };

    const cpcMethods = useForm<EditDvlaCpcFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(cpcValidationSchema),
        defaultValues: {
            ...initialValues.cpc,
        },
    });

    const handleEditDvlaCpc = async (
        data: EditDvlaCpcFormValues,
        callback: () => void
    ) => {
        if (!driverId) return;
        const response = await editDvlaCpc(driverId, data);
        if (!response.success) {
            errorToast("There was an error");
            return;
        }
        successToast("Successfully updated cpc");
        refetch();
        callback();
    };

    return (
        <>
            {isLoading && <p>...loading</p>}
            <EditPersonalInformationForm
                initialValues={initialValues}
                isLoading={isEditingDvlaPersonalInfo}
                onSubmit={handleEditDvlaPersonalInformation}
                methods={personalInformationMethods}
                canEdit={canEdit}
            />
            <EditDriverAddressForm
                initialValues={initialValues}
                isLoading={isEditingDvlaAddress}
                methods={driverAddressMethods}
                onSubmit={handleEditDvlaAddress}
                canEdit={canEdit}
            />
            <EditLicenceInformationForm
                initialValues={initialValues}
                isManual={isManual}
                isLoading={isEditingDvlaLicence}
                methods={licenceMethods}
                onSubmit={handleEditDvlaLicence}
                canEdit={canEdit}
            />
            <EditCategoriesForm
                initialValues={initialValues}
                methods={entitlementMethods}
                isLoading={isEditingDvlaEntitlements}
                onSubmit={handleEditDvlaEntitlements}
                canEdit={canEdit}
            />
            <EditEndorsementsForm
                endorsements={initialValues.endorsements}
                refetch={refetch}
                canEdit={canEdit}
            />
            <EditTachocardForm
                initialValues={initialValues}
                isLoadingAdd={isAddingDvlaTachocard}
                isLoadingEdit={isEditingDvlaTachocard}
                onSubmit={handleEditDvlaTachocard}
                onDelete={handleDeleteDvlaTachocard}
                isDeleting={isDeletingDvlaTachocard}
                canEdit={canEdit}
            />
            <EditCpcForm
                initialValues={initialValues}
                methods={cpcMethods}
                isLoading={isEditingDvlaCpc}
                onSubmit={handleEditDvlaCpc}
                onDelete={handleDeleteDvlaCpc}
                isDeleting={isDeletingDvlaCpc}
                canEdit={canEdit}
            />
        </>
    );
};

export default EditDvlaForm;
