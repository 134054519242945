import { RadioGroup as HUIRadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { Circle, RadioButton } from "phosphor-react";
import { FieldError } from "react-hook-form";
import FormInputError from "../FormInputError";

type FormRadioGroupOption<T> = {
    id: T;
    label: string;
};

type FormRadioGroupProps<T> = {
    value?: T;
    options: FormRadioGroupOption<T>[];
    label?: string;
    error?: FieldError;
    onChange: (value: T) => void;
};

const FormRadioGroup = <T extends number | string>({
    value,
    options,
    label,
    error,
    onChange,
}: FormRadioGroupProps<T>) => {
    return (
        <div>
            <div className="w-full">
                <div className="">
                    <HUIRadioGroup value={value} onChange={onChange}>
                        {label && (
                            <HUIRadioGroup.Label>{label}</HUIRadioGroup.Label>
                        )}
                        <div className="flex">
                            {options.map((option) => (
                                <HUIRadioGroup.Option
                                    key={option.id}
                                    value={option.id}
                                    className={clsx(
                                        "relative flex cursor-pointer flex-col rounded-sm p-[0.125rem] px-5 py-4 outline-0 ring-0 transition-all md:flex-row"
                                    )}
                                >
                                    {({ active, checked }) => (
                                        <>
                                            <div className="flex flex-1 md:mb-0">
                                                <div
                                                    className={clsx(
                                                        "relative mr-2 h-6 w-6 flex-none -translate-y-[2px] rounded-full",
                                                        active &&
                                                            error &&
                                                            "ring2 ring-S2D-error-40"
                                                    )}
                                                >
                                                    {!checked && (
                                                        <Circle
                                                            weight="regular"
                                                            className="h-6 w-6 fill-S2D-dark-green-30"
                                                        />
                                                    )}
                                                    {checked && (
                                                        <RadioButton
                                                            className="h-6 w-6 fill-S2D-dark-green-30"
                                                            weight="fill"
                                                        />
                                                    )}
                                                </div>
                                                <div className="relative flex-1 md:-top-[2px]">
                                                    <HUIRadioGroup.Label
                                                        as="p"
                                                        className="font-medium"
                                                    >
                                                        {option.label}
                                                    </HUIRadioGroup.Label>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </HUIRadioGroup.Option>
                            ))}
                        </div>
                    </HUIRadioGroup>
                </div>
            </div>
            <div className="text-center">
                <FormInputError error={error} />
            </div>
        </div>
    );
};

export default FormRadioGroup;
