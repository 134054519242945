import { ApiDepartmentResponse } from "../../models/api/admin/department";
import {
    AddDepartmentDto,
    EditDepartmentDto,
} from "../../models/app/admin/department";
import { queryParamConnector } from "../../utils/queryParamHelpers";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useGetDepartments = (
    companyId?: string,
    pageNumber?: number,
    pageSize?: number
) => {
    const baseUrl = companyId ? `api/companies/${companyId}/departments` : "";
    const pathArray = [baseUrl];

    if (baseUrl && pageNumber) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`PageNumber=${pageNumber}`);
    }

    if (baseUrl && pageSize) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`PageSize=${pageSize}`);
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiDepartmentResponse>(
            companyId ? pathArray.join("") : null
        );

    return {
        departmentsData: data,
        refetchDepartments: refetch,
        departmentsError: error,
        isLoadingDepartments: isLoading,
    };
};

const useAddDepartment = () =>
    useS2DApiFetcher<
        AddDepartmentDto,
        string,
        { companyId: string; dto: AddDepartmentDto }
    >(
        "POST",
        ({ companyId, dto }) => ({
            url: `api/companies/${companyId}/departments`,
            body: dto,
        }),
        [],
        true
    );

const useEditDepartment = () =>
    useS2DApiFetcher<
        EditDepartmentDto,
        string,
        { companyId: string; departmentId: string; dto: EditDepartmentDto }
    >(
        "PUT",
        ({ companyId, departmentId, dto }) => ({
            url: `api/companies/${companyId}/departments/${departmentId}`,
            body: dto,
        }),
        [],
        true
    );

const useDeleteDepartment = () =>
    useS2DApiFetcher<null, string, { companyId: string; departmentId: string }>(
        "DELETE",
        ({ companyId, departmentId }) => ({
            url: `api/companies/${companyId}/departments/${departmentId}`,
        }),
        [],
        true
    );

export const useDepartment = (companyId: string) => {
    const [addDepartment, isAddingDepartment] = useAddDepartment();
    const [editDepartment, isEditingDepartment] = useEditDepartment();
    const [deleteDepartment, isDeletingDepartment] = useDeleteDepartment();

    return {
        addDepartment: async (dto: AddDepartmentDto) =>
            await addDepartment({ companyId, dto }),
        isAddingDepartment,
        editDepartment: async (departmentId: string, dto: EditDepartmentDto) =>
            await editDepartment({ companyId, departmentId, dto }),
        isEditingDepartment,
        deleteDepartment: async (departmentId: string) =>
            await deleteDepartment({ companyId, departmentId }),
        isDeletingDepartment,
    };
};
