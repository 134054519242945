import PageLayout from "../../components/PageLayout";
import Table, {
    type HiddenCols,
} from "../../components/ReusableComponents/Table";
import {
    greyfleetMenuGroups,
    initGreyFleetTableContents,
} from "../../components/AdminGreyFleetPage/GreyFleetTableInit";
import CollapsibleSection from "../../components/ReusableComponents/CollapsibleSection";
import GreyFleetVehicleAnalysisCharts from "../../components/ChartGroups/GreyFleetVehicleAnalysisCharts";
import GreyFleetDriversSection from "../../components/AdminGreyFleetPage/GreyFleetDriversSection";
import PaginationNav from "../../components/PaginationNav";
import useAdminGreyFleet from "./pageHooks/useAdminGreyFleet";
import GreyFleetPdfReport from "../../components/AdminGreyFleetPage/GreyFleetPdfReport";
import HeaderOptions from "../../components/ReusableComponents/HeaderOptions";
import DriversListFilters from "../../components/DriverListFilters/DriversListFilters";
import { greyFleetMenuItems } from "../../components/AdminGreyFleetPage/greyFleetTableMenuOptions";
import { type GreyFleetTableData } from "../../models/api/admin/greyFleet";
import greyFleetAnalysisContent from "../../components/GreyFleetAnalysis/greyFleetAnalysisContent";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useRef } from "react";
import { useScrollToTable } from "../../hooks/useScrollToTable";
import { useGreyFleetTableActions } from "../../components/AdminGreyFleetPage/useGreyFleetTableActions";
import VehicleSelectDialog from "../../components/AdminGreyFleetPage/VehicleSelectDialog";
import useAuth from "../../auth";
import { isS2dUser as isS2dUserHelper } from "../../auth/userAccessHelpers";
import OldAnalysisComponent from "../../components/ReusableComponents/AnalysisComponent/OldAnalysisComponent";
import { GreyFleetReportTypes } from "../../models/api/reporting";

export default function AdminGreyFleet() {
    const {
        greyFleetAlertsData,
        isLoadingGreyFleetAlertsData,
        greyFleetAlertsError,
        greyFleetDriversList,
        isGettingGreyFleetDriversList,
        setPageNumber,
        pageSize,
        setPageSize,
        tableHeaders,
        setTableHeaders,
        isVehicleAnalysisOpen,
        setIsVehicleAnalysisOpen,
        isGreyFleetDriversOpen,
        setIsGreyFleetDriversOpen,
        selectedCompany,
        handleCompanyChange,
        selectedDepartment,
        handleDepartmentChange,
        handleResetCompanyAndDepartment,
        showReportView,
        handleDownloadPdfReport,
        reportRef,
        handleFetchDrivers,
        filterModalOpen,
        setFilterModalOpen,
        appliedFilter,
        setAppliedFilter,
        query,
        setQuery,
        handleDownloadReport,
        isReportDownloading
    } = useAdminGreyFleet();

    const {
        handleTableMenuSelect,
        isLoadingTableMenu,
        isVerifyInsuranceDialogOpen,
        setVerifyInsuranceDialogOpen,
        isRemoveVehicleDialogOpen,
        setRemoveVehicleDialogOpen,
        selectedDriverData,
        resetSelectedDriverData,
        handleVerifyInsurance,
        handleRemoveVehicle,
    } = useGreyFleetTableActions(handleFetchDrivers);

    const { decodedToken } = useAuth();
    const isS2dUser = isS2dUserHelper(decodedToken?.role);

    const tableRef = useRef<HTMLDivElement | null>(null);

    const analysisContent = greyFleetAnalysisContent(
        greyFleetAlertsData,
        greyFleetAlertsError,
        handleFetchDrivers,
        tableRef
    );
    const initHiddenCols = new Array(0);
    tableHeaders.map((item) =>
        initHiddenCols.push({
            id: item.id,
            hidden: item.hide ?? false,
        })
    );
    const [hiddenCols, setHiddenCols] = useLocalStorage<HiddenCols[]>(
        "GreyFleetTableHiddenCols",
        initHiddenCols
    );

    const [isGreyFleetAnalysisOpen, setIsGreyFleetAnalysisOpen] =
        useLocalStorage<boolean>("isGreyFleetAnalysisOpen", true);

    const hasLoaded = !!greyFleetDriversList && !isGettingGreyFleetDriversList;
    useScrollToTable(hasLoaded, tableRef);

    return (
        <>
            <PageLayout title="Grey Fleet Dashboard" header={false}>
                <div className="flex flex-col gap-6">
                    <HeaderOptions
                        selectedDepartment={selectedDepartment}
                        handleDepartmentChange={handleDepartmentChange}
                        selectedCompany={selectedCompany}
                        handleCompanyChange={handleCompanyChange}
                        clearCompanyDepartment={handleResetCompanyAndDepartment}
                        handleDownloadPdfReport={handleDownloadPdfReport}
                        handleDownloadCsvReport={() =>
                            handleDownloadReport(GreyFleetReportTypes.CSV)
                        }
                        handleDownloadXlsReport={() =>
                            handleDownloadReport(GreyFleetReportTypes.XLS)
                        }
                        isReportDownloading={
                            showReportView || isReportDownloading
                        }
                    />
                    <CollapsibleSection
                        title={"Grey Fleet analysis"}
                        isOpen={isGreyFleetAnalysisOpen}
                        setIsOpen={setIsGreyFleetAnalysisOpen}
                        content={
                            <OldAnalysisComponent
                                analysisContent={analysisContent}
                                isLoadingAnalysis={isLoadingGreyFleetAlertsData}
                            />
                        }
                    />
                    <CollapsibleSection
                        isOpen={isVehicleAnalysisOpen}
                        setIsOpen={setIsVehicleAnalysisOpen}
                        title={"Vehicle Analysis"}
                        content={
                            <GreyFleetVehicleAnalysisCharts
                                companyId={selectedCompany?.companyId}
                                departmentId={selectedDepartment?.departmentId}
                            />
                        }
                    />
                    <CollapsibleSection
                        isOpen={isGreyFleetDriversOpen}
                        setIsOpen={setIsGreyFleetDriversOpen}
                        title={"Your grey fleet drivers"}
                        content={
                            <GreyFleetDriversSection
                                driverCount={
                                    greyFleetAlertsData?.greyFleetDriverCount
                                }
                            />
                        }
                    />
                    <DriversListFilters
                        filterModalOpen={filterModalOpen}
                        setFilterModalOpen={setFilterModalOpen}
                        appliedFilter={appliedFilter}
                        setAppliedFilter={setAppliedFilter}
                        isFetchingDrivers={isGettingGreyFleetDriversList}
                        handleFetchDrivers={handleFetchDrivers}
                        query={query}
                        setQuery={setQuery}
                    />
                    <Table
                        tableHeaders={tableHeaders}
                        setTableHeaders={setTableHeaders}
                        tableContents={initGreyFleetTableContents(
                            greyFleetDriversList?.items,
                            selectedCompany?.companyRiskProfiles
                        )}
                        menuItems={(data: GreyFleetTableData) =>
                            greyFleetMenuItems(
                                data,
                                handleTableMenuSelect,
                                isLoadingTableMenu,
                                isS2dUser
                            )
                        }
                        menuGroups={greyfleetMenuGroups}
                        hiddenCols={hiddenCols}
                        setHiddenCols={setHiddenCols}
                        isLoading={isGettingGreyFleetDriversList}
                        tableRef={tableRef}
                    />
                    {/*verify insurance*/}
                    <VehicleSelectDialog
                        isOpen={isVerifyInsuranceDialogOpen}
                        setIsOpen={setVerifyInsuranceDialogOpen}
                        title="Verify vehicle insurance"
                        selectedDriverData={selectedDriverData}
                        resetSelectedDriverData={resetSelectedDriverData}
                        onSubmit={handleVerifyInsurance}
                        isLoading={isLoadingTableMenu}
                        type="verify-insurance"
                    />
                    {/*remove vehicle*/}
                    <VehicleSelectDialog
                        isOpen={isRemoveVehicleDialogOpen}
                        setIsOpen={setRemoveVehicleDialogOpen}
                        title="Remove a vehicle"
                        selectedDriverData={selectedDriverData}
                        resetSelectedDriverData={resetSelectedDriverData}
                        onSubmit={handleRemoveVehicle}
                        isLoading={isLoadingTableMenu}
                        type="remove-vehicle"
                    />
                    {greyFleetDriversList && (
                        <>
                            {greyFleetDriversList?.items.length === 0 &&
                                !isGettingGreyFleetDriversList && (
                                    <div className="rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-4 text-S2D-neutral-10">
                                        No drivers found
                                    </div>
                                )}
                            <PaginationNav
                                setPageNumber={setPageNumber}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                totalCount={greyFleetDriversList?.totalCount}
                                totalPages={greyFleetDriversList?.totalPages}
                                currentPage={greyFleetDriversList?.pageNumber}
                                hasPreviousPage={
                                    greyFleetDriversList?.hasPreviousPage
                                }
                                hasNextPage={greyFleetDriversList?.hasNextPage}
                            />
                        </>
                    )}
                </div>
            </PageLayout>
            {showReportView && (
                // NB report is never actually intended to be seen by the user, only appended to the bottom of the page
                <>
                    <div ref={reportRef}>
                        <GreyFleetPdfReport
                            analysisData={greyFleetAlertsData}
                            company={selectedCompany}
                            department={selectedDepartment}
                        />
                    </div>
                </>
            )}
        </>
    );
}
