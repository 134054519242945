import { useLocation } from "react-router-dom";
import { useAppliedFilterLocalStorage } from "../../hooks/useAppliedFilterLocalStorage";
import { driverPageLocation } from "../../utils/driverPageLocation";
import { DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm/DriversListFiltersFormValues";
import { useCompanyDepartmentSelector } from "../ReusableComponents/CompanyDepartmentSelector";
import {
    type LicenceStatusAnalysis,
    type CpcAndTachoAnalysis,
    type LicenceCategoriesAnalysis,
    type PhotocardAnalysis,
    type NewDriverAnalysis,
    type EyesightAnalysis,
    type NonAddMatchAnalysis,
    type FilterGreyFleetDto,
    type FilterLicenceChangesDto,
} from "../../models/app/admin/driverFilters";
import {
    RiskProfileLevels,
    type GetAllDriversDto,
} from "../../models/app/admin/driver";
import { type FilterType } from "../../models/app/admin/filter";
import { errorToast, successToast } from "../../toast";
import { useState } from "react";
import { driverListFiltersDefaultValues } from "../DriverListFilters/DriversListFiltersForm/driversListFiltersDefaultValues";
import { driverAnalysisDefaultValues } from "./driverAnalysisDefaultValues";

export const useDriverAnalysisActions = (
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>,
    tableRef?: React.MutableRefObject<HTMLDivElement | null>
) => {
    const location = useLocation();
    const sendToLocation = driverPageLocation(location.pathname);
    const { setAppliedFilter } = useAppliedFilterLocalStorage(sendToLocation);
    const defaultVals = driverListFiltersDefaultValues(sendToLocation);
    const [isLoading, setIsLoading] = useState(false);

    const { selectedCompany, currentDepartment: selectedDepartment } =
        useCompanyDepartmentSelector();
    const companyDepartmentVals: Pick<GetAllDriversDto, "company"> = {
        company: {
            companyIds: selectedCompany ? [selectedCompany.companyId] : null,
            departmentIds: selectedDepartment
                ? [selectedDepartment.departmentId]
                : null,
            employeeId: null,
            companyDashboard: defaultVals.company.companyDashboard,
        },
    };

    const handleApplyFilter = async (filter: FilterType) => {
        if (!refetchData) return;

        setIsLoading(true);
        setAppliedFilter(filter);
        const success = await refetchData(filter.filterFields, filter.filterId);

        if (success) {
            successToast("Filter applied successfully");
            if (!tableRef?.current) return;
            window.scrollTo(0, tableRef.current.offsetTop);
        } else {
            errorToast("Failed to apply filter");
        }
        setIsLoading(false);
    };

    const applyRiskCategoryFilter = (severityLevel: RiskProfileLevels[]) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                riskProfiles: severityLevel,
            },
        };
        handleApplyFilter(filter);
    };

    const applyCpcAndTachoFilter = (key: keyof CpcAndTachoAnalysis) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                driverAnalysis: {
                    ...defaultVals.driverAnalysis,
                    cpcAndTacho: {
                        ...driverAnalysisDefaultValues.cpcAndTacho,
                        [key]: true,
                    },
                },
            },
        };
        handleApplyFilter(filter);
    };

    const applyLicenceStatusFilter = (key: keyof LicenceStatusAnalysis) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                driverAnalysis: {
                    ...defaultVals.driverAnalysis,
                    licenceStatus: {
                        ...driverAnalysisDefaultValues.licenceStatus,
                        [key]: true,
                    },
                },
            },
        };

        handleApplyFilter(filter);
    };

    const applyLicenceCategoriesFilter = (
        key: keyof LicenceCategoriesAnalysis
    ) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                driverAnalysis: {
                    ...defaultVals.driverAnalysis,
                    licenceCategories: {
                        ...driverAnalysisDefaultValues.licenceCategories,
                        [key]: true,
                    },
                },
            },
        };

        handleApplyFilter(filter);
    };

    const applyPhotocardFilter = (key: keyof PhotocardAnalysis) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                driverAnalysis: {
                    ...defaultVals.driverAnalysis,
                    photocard: {
                        ...driverAnalysisDefaultValues.photocard,
                        [key]: true,
                    },
                },
            },
        };

        handleApplyFilter(filter);
    };

    const applyNewDriverFilter = (key: keyof NewDriverAnalysis) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                driverAnalysis: {
                    ...defaultVals.driverAnalysis,
                    newDriver: {
                        ...driverAnalysisDefaultValues.newDriver,
                        [key]: true,
                    },
                },
            },
        };

        handleApplyFilter(filter);
    };

    const applyEyesightFilter = (key: keyof EyesightAnalysis) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                driverAnalysis: {
                    ...defaultVals.driverAnalysis,
                    eyesight: {
                        ...driverAnalysisDefaultValues.eyesight,
                        [key]: true,
                    },
                },
            },
        };

        handleApplyFilter(filter);
    };

    const applyNonAddMatchFilter = (key: keyof NonAddMatchAnalysis) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                driverAnalysis: {
                    ...defaultVals.driverAnalysis,
                    nonAddMatch: {
                        ...driverAnalysisDefaultValues.nonAddMatch,
                        [key]: true,
                    },
                },
            },
        };

        handleApplyFilter(filter);
    };

    const applyGreyFleetQueryFilter = (
        key: keyof FilterGreyFleetDto,
        value: boolean
    ) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                greyFleetQuery: {
                    ...defaultVals.greyFleetQuery,
                    [key]: value,
                },
            },
        };

        handleApplyFilter(filter);
    };

    const applyLicenceChangesFilter = (key: keyof FilterLicenceChangesDto) => {
        const filter: FilterType = {
            filterId: "",
            filterFields: {
                ...defaultVals,
                ...companyDepartmentVals,
                licenceChanges: {
                    ...defaultVals.licenceChanges,
                    [key]: true,
                },
            },
        };

        handleApplyFilter(filter);
    };

    return {
        applyRiskCategoryFilter,
        applyCpcAndTachoFilter,
        applyLicenceStatusFilter,
        applyLicenceCategoriesFilter,
        applyPhotocardFilter,
        applyNewDriverFilter,
        applyEyesightFilter,
        applyNonAddMatchFilter,
        applyGreyFleetQueryFilter,
        applyLicenceChangesFilter,
        isLoading,
    };
};
