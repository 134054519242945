import useAuth from "../../../auth";
import { isS2dUser } from "../../../auth/userAccessHelpers";
import { Vehicle } from "../../../models/api/admin/vehicle";
import { addDays } from "../../../utils/addDays";
import { StatusAction } from "../../ActionableStatus/ActionableStatus";
import GreyFleetCompleteContent from "./GreyFleetCompleteContent";
import GreyFleetExpiredContent from "./GreyFleetExpiredContent";
import GreyFleetIncompleteContent from "./GreyFleetIncompleteContent";
import useGreyFleetActions from "./useGreyFleetActions";

export type GreyFleetProps = {
    vehicle: Vehicle;
    hasEmailAddress: boolean;
};

export type GreyFleetCommonContentProps = {
    actions: StatusAction[];
} & Omit<GreyFleetProps, "hasEmailAddress">;

const GreyFleet = ({ vehicle, hasEmailAddress }: GreyFleetProps) => {
    const {
        greyFleetRemoveVehicle,
        greyFleetInstantEmailReminder,
        greyFleetIncompleteEmailReminder,
        greyFleetInsuranceVerification,
    } = useGreyFleetActions(vehicle, hasEmailAddress);
    const { decodedToken } = useAuth();
    const isS2d = isS2dUser(decodedToken?.role);

    const today = new Date();

    const mostRecentExpiredDocumentEmailReminderDate = vehicle.expiries
        .map((expiryItem) => expiryItem.expiredEmailSentDate)
        .reduce((a, b) => {
            if (a === null) {
                return b;
            }
            if (b === null) {
                return a;
            }
            return new Date(a) < new Date(b) ? b : a;
        });

    const expiryTypes = vehicle.expiries.map((expiry) => {
        if (expiry.expiryDueDate) {
            const dueDate = new Date(expiry.expiryDueDate);
            const hasExpired = today > dueDate;
            const isDueWithin30Days = today > addDays(dueDate, -30);

            // not relevant whether breakdown cover is expired/due
            if (expiry.expiryType !== "BreakdownCover") {
                if (hasExpired)
                    return { hasExpired: true, expiryType: expiry.expiryType };
                if (isDueWithin30Days)
                    return { hasExpired: false, expiryType: expiry.expiryType };
            }
        }
    });
    const vehicleHasExpiredOrExpiringProperty = expiryTypes.some(
        (type) => type
    );
    // we only want to be able to verify insurance if insurance is not expired
    const insuranceIsExpired = expiryTypes.some(
        (type) => type?.hasExpired && type.expiryType === "Insurance"
    );

    let content: React.ReactNode = <></>;

    // Following conditions are in reverse priority order
    // last condition that is met is the one that is rendered
    if (
        !vehicleHasExpiredOrExpiringProperty &&
        vehicle.insuranceFileUploadDate &&
        vehicle.hasBusinessCover
    ) {
        content = (
            <GreyFleetCompleteContent
                vehicle={vehicle}
                actions={[
                    ...(!vehicle.insuranceFileCheckedDate && isS2d
                        ? [greyFleetInsuranceVerification]
                        : []),
                    greyFleetRemoveVehicle,
                ]}
            />
        );
    }

    if (vehicleHasExpiredOrExpiringProperty) {
        content = (
            <GreyFleetExpiredContent
                vehicle={vehicle}
                actions={[
                    greyFleetInstantEmailReminder(
                        mostRecentExpiredDocumentEmailReminderDate
                    ),
                    ...(!vehicle.insuranceFileCheckedDate && !insuranceIsExpired && isS2d
                        ? [greyFleetInsuranceVerification]
                        : []),
                    greyFleetRemoveVehicle,
                ]}
            />
        );
    }

    if (!vehicle.insuranceFileUploadDate || !vehicle.hasBusinessCover) {
        content = (
            <GreyFleetIncompleteContent
                vehicle={vehicle}
                actions={[
                    greyFleetIncompleteEmailReminder(),
                    greyFleetRemoveVehicle,
                ]}
            />
        );
    }

    return content;
};

export default GreyFleet;
