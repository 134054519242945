import PdfReport from "../../../components/ReusableComponents/PdfReport";
import {
    AnalysisComponentContentType,
    locationFilteredAnalysisContent,
} from "../../../components/ReusableComponents/AnalysisComponent/AnalysisComponent";
import ReportAnalysisContainer from "../../../components/ReusableComponents/PdfReport/ReportAnalysisContainer";
import { DriverCategory } from "../../../utils/driverPageLocation";
import DriverLicenceCharts from "../../../components/ChartGroups/DriverLicenceCharts";
import useDriverLicenceCharts from "../../../components/ChartGroups/useDriverLicenceCharts";
import HGVCoachDriversSummary from "../../../components/DriversSummary/HGVCoachDriversSummary";
import DriversSummary from "../../../components/DriversSummary/DriversSummary";
import { ApiAllDriversStatsResponse } from "../../../models/api/admin/driver";

type DriversPageReportProps<AnalysisComponentTitles extends string> = {
    chartData: ReturnType<typeof useDriverLicenceCharts>;
    analysisContent?: AnalysisComponentContentType<AnalysisComponentTitles>[];
    companyName?: string;
    departmentName?: string;
    driversStats?: ApiAllDriversStatsResponse;
    isGettingAllDriversStats: boolean;
    location?: DriverCategory;
};

const DriversPageReport = <AnalysisComponentTitles extends string>({
    chartData,
    analysisContent,
    companyName,
    departmentName,
    driversStats,
    isGettingAllDriversStats,
    location,
}: DriversPageReportProps<AnalysisComponentTitles>) => {
    return (
        <PdfReport companyName={companyName} departmentName={departmentName}>
            <div className="flex flex-col">
                {analysisContent &&
                    location &&
                    locationFilteredAnalysisContent(
                        analysisContent,
                        location
                    ).map(({ title, content }) => {
                        return (
                            <div key={title}>
                                <ReportAnalysisContainer title={title}>
                                    {content}
                                </ReportAnalysisContainer>
                            </div>
                        );
                    })}
                <ReportAnalysisContainer title={"Driver licence analysis"}>
                    <DriverLicenceCharts data={chartData} />
                </ReportAnalysisContainer>
            </div>
            {location === "professionalDrivers" && (
                <HGVCoachDriversSummary
                    stats={driversStats}
                    loading={isGettingAllDriversStats}
                />
            )}
            {location === "drivers" && (
                // TODO 25/07/23 refactor this as DriversSummary is no longer responsible for professional drivers
                <DriversSummary
                    type={location}
                    stats={driversStats}
                    loading={isGettingAllDriversStats}
                />
            )}
        </PdfReport>
    );
};

export default DriversPageReport;
