import { useGetInvoiceAddresses } from "../../../api/admin/invoiceAddress";
import useLocalStorage from "../../../hooks/useLocalStorage";

const useInvoiceAddrressesTab = (companyId: string) => {
    const [pageNumber, setPageNumber] = useLocalStorage<number>(
        `InvoiceAddressTablePageNumber`,
        1
    );
    const [pageSize, setPageSize] = useLocalStorage<number>(
        `InvoiceAddressTablePageSize`,
        25
    );

    const {
        invoiceAddressesData,
        isLoadingInvoiceAddresses,
        invoiceAddressesError,
        refetchInvoiceAddresses,
    } = useGetInvoiceAddresses(companyId, pageNumber, pageSize);

    return {
        invoiceAddresses: invoiceAddressesData,
        refetchInvoiceAddresses,
        isLoadingInvoiceAddresses,
        invoiceAddressesError,
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
    };
};

export default useInvoiceAddrressesTab;
