import { useMemo } from "react";
import { AnalysisComponentContentType } from "./AnalysisComponent";
import { Menu } from "@headlessui/react";
import { CaretDown, CaretUp } from "phosphor-react";
import clsx from "clsx";

type DropdownAnalysisComponentProps<AnalysisComponentTitles extends string> = {
    analysisContent: AnalysisComponentContentType<AnalysisComponentTitles>[];
    selectedTab: number;
    onSelect: (value: AnalysisComponentTitles) => void;
};

const DropdownAnalysisComponent = <AnalysisComponentTitles extends string>({
    analysisContent,
    selectedTab,
    onSelect,
}: DropdownAnalysisComponentProps<AnalysisComponentTitles>) => {
    const handleContentSelect = (
        content: AnalysisComponentContentType<AnalysisComponentTitles>
    ) => {
        onSelect && onSelect(content.title);
    };

    const isItemSelected = (contentTitle?: string, selectedTitle?: string) => {
        if (!contentTitle || !selectedTitle) return false;
        return contentTitle === selectedTitle;
    };

    const tabContent = useMemo(
        () => analysisContent[selectedTab],
        [selectedTab, analysisContent]
    );

    return (
        <div className="mb-4">
            <Menu>
                {({ open }) => (
                    <>
                        <Menu.Button className="w-full">
                            <div
                                className={clsx(`flex items-center justify-between 
                     border-2 border-S2D-neutral-10 bg-S2D-neutral-100 p-3 pl-[25px]
                     text-[16px] font-semibold text-S2D-neutral-10  ${
                         open ? "rounded-sm rounded-b-none" : "rounded-sm"
                     }`)}
                            >
                                <div className="flex items-center gap-2">
                                    {tabContent.title}
                                </div>
                                <div>
                                    {open ? (
                                        <CaretUp size={20} weight="fill" />
                                    ) : (
                                        <CaretDown size={20} weight="fill" />
                                    )}
                                </div>
                            </div>
                        </Menu.Button>
                        <Menu.Items
                            className={`rounded rounded-t-none border-2 border-t-0 border-S2D-neutral-10 bg-S2D-neutral-100`}
                        >
                            {analysisContent &&
                                analysisContent.map((content, i) => {
                                    return (
                                        <Menu.Item key={i}>
                                            {({ active }) => (
                                                <div
                                                    onClick={() =>
                                                        handleContentSelect(
                                                            content
                                                        )
                                                    }
                                                    className={`flex items-center gap-2 p-3 text-[16px] font-semibold ${
                                                        isItemSelected(
                                                            content.title,
                                                            tabContent.title
                                                        )
                                                            ? "bg-S2D-light-green-80 text-S2D-text-button"
                                                            : ""
                                                    } ${
                                                        active
                                                            ? "bg-S2D-neutral-90"
                                                            : ""
                                                    }`}
                                                >
                                                    {content.title}
                                                </div>
                                            )}
                                        </Menu.Item>
                                    );
                                })}
                        </Menu.Items>
                    </>
                )}
            </Menu>
            <div className="pt-4">{tabContent.content}</div>
        </div>
    );
};

export default DropdownAnalysisComponent;
