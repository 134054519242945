export type DvlaDriverAnalysisResponse = {
    ukDvlaChecks: DvlaUKAnalysis;
    ukDvlaErrorChecks: DvlaUKErrorsAnalysis;
    ukManualChecks: DvlaUKManualChecksAnalysis;
    northernIrishChecks: DvlaNIManualChecksAnalysis;
    foreignChecks: DvlaForeignManualChecksAnalysis;
};

export type DvlaUKAnalysis = {
    totalNumberOfAlerts: number;
    checksComplete: number;
    checksScheduled: number;
    checksOverdue: number;
};

export type DvlaUKErrorsAnalysis = {
    totalNumberOfAlerts: number;
    error400Checks: number;
    error403Checks: number;
    error429Checks: number;
    error500Checks: number;
    error503Checks: number;
    errorOther: number;
};

export type DvlaUKManualChecksAnalysis = {
    totalNumberOfAlerts: number;
    checksComplete: number;
    checksScheduled: number;
    checksPending: number;
    checksOverdue: number;
};

export type DvlaNIManualChecksAnalysis = {
    totalNumberOfAlerts: number;
    checksComplete: number;
    checksScheduled: number;
    checksPending: number;
    checksOverdue: number;
};

export type DvlaForeignManualChecksAnalysis = {
    totalNumberOfAlerts: number;
    checksComplete: number;
    checksScheduled: number;
    checksPending: number;
    checksOverdue: number;
};

export enum DvlaAnalysisCategories {
    UkDvlaChecks = "UkDvlaChecks",
    UkDvlaCheckErrors = "UkDvlaErrorChecks",
    UkManualChecks = "UkManualChecks",
    NorthernIrishChecks = "NorthernIrishChecks",
    ForeignChecks = "ForeignChecks",
}

export enum DvlaAnalysisTabs {
    UkDvlaChecks = "UK (DVLA)",
    UkDvlaCheckErrors = "UK (DVLA errors)",
    UkManualChecks = "UK (Manual)",
    NorthernIrishChecks = "N.Irish (Manual)",
    ForeignChecks = "Foreign (Manual)",
}
