import * as yup from "yup";
import { DriverValidationTypes } from "../../models/app/admin/company";
import { dateValidation } from "../../utils/dateValidationYup";

export const GreyFleetPersonalDetailsFormValidationSchemaFields = (
    validateDriverIdentityBy: DriverValidationTypes | undefined
) => {
    return {
        firstName: yup.string().required("Forename is required"),
        lastName: yup.string().required("Surname is required"),
        dateOfBirth:
            validateDriverIdentityBy === DriverValidationTypes.DateOfBirth
                ? dateValidation
                      .required("Date of birth is required")
                      .nullable()
                      .max(new Date(), "Date of birth must be before today")
                : dateValidation
                      .notRequired()
                      .nullable()
                      .transform((value) => {
                          if (value !== "") return value;
                          return null;
                      }),
        employeeId:
            validateDriverIdentityBy === DriverValidationTypes.EmployeeId
                ? yup.string().required("Employee ID is required").nullable()
                : yup
                      .string()
                      .notRequired()
                      .nullable()
                      .transform((value) => {
                          if (value !== "") return value;
                          return null;
                      }),
        emailAddress: yup
            .string()
            .email(
                "Email address must be a valid email address, eg. hello@example.com "
            )
            .required("Email address is required"),
    };
};

export const GreyFleetVehicleDetailsFormValidationSchemaFields = {
    vrm: yup
        .string()
        .trim()
        .transform((value: string) => value.replaceAll(" ", ""))
        .required("Registration number is required"),
    make: yup
        .string()
        .required("Make of vehicle is required")
        .max(16, "Make of vehicle cannot exceed 16 characters."),
    taxExpiryDate: dateValidation.required("Road tax expiry date is required"),
    motExpiryDate: dateValidation.required("MOT expiry date is required"),
    insuranceCertificate: yup
        .object({
            fileBase64: yup.string().notRequired(),
            fileType: yup.string().notRequired(),
            fileName: yup.string().notRequired(),
        })
        .notRequired()
        .nullable(),
    insuranceCompanyName: yup
        .string()
        .required("Insurance company name is required"),
    insurancePolicyNumber: yup
        .string()
        .required("Insurance policy number is required"),
    insuranceStartDate: dateValidation.required(
        "Insurance start date is required"
    ),

    insuranceEndDate: dateValidation
        .required("Insurance expiry date is required")
        .min(
            yup.ref("insuranceStartDate"),
            "Policy end date cannot be before the start date"
        ),
    insuranceType: yup.string().required("Type of insurance is required"),
    hasBusinessCover: yup.string().required("Type of insurance is required"),
    hasBreakdownCover: yup.string().required("This field is required"),
    breakdownCompanyName: yup
        .string()
        .nullable()
        .when("hasBreakdownCover", {
            is: (hasBreakdownCover: string) => hasBreakdownCover === "true",
            then: yup
                .string()
                .required("Breakdown cover company name is required"),
        }),
    breakdownPolicyNumber: yup
        .string()
        .nullable()
        .when("hasBreakdownCover", {
            is: (hasBreakdownCover: string) => hasBreakdownCover === "true",
            then: yup
                .string()
                .required("Breakdown cover policy number is required"),
        }),
    breakdownStartDate: dateValidation
        .nullable()
        .when("hasBreakdownCover", {
            is: (hasBreakdownCover: string) => hasBreakdownCover === "true",
            then: dateValidation.required(
                "Breakdown cover start date is required"
            ),
        })
        .when("hasBreakdownCover", {
            is: (hasBreakdownCover: string) => hasBreakdownCover === "false",
            then: dateValidation.nullable(true),
        }),
    breakdownEndDate: dateValidation
        .nullable()
        .when("hasBreakdownCover", {
            is: (hasBreakdownCover: string) => hasBreakdownCover === "true",
            then: dateValidation
                .required("Breakdown cover expiry date is required")
                .min(
                    yup.ref("breakdownStartDate"),
                    "End date cannot be before the start date"
                ),
        })
        .when("hasBreakdownCover", {
            is: (hasBreakdownCover: string) => hasBreakdownCover === "false",
            then: dateValidation.nullable(true),
        }),
};

export type GreyFleetFormPersonalDetailsValues = {
    greyFleetInviteId: string;
    //Step 1: Personal Info
    firstName: string;
    lastName: string;
    dateOfBirth: Date | null;
    employeeId: string;
    emailAddress: string;
};

export type GreyFleetFormVehicleDetailsValues = {
    greyFleetInviteId: string;
    //Step 2: Vehicle Details
    vrm: string;
    make: string;
    taxExpiryDate: Date | null;
    motExpiryDate: Date | null;
    //Step 3: Insurance Details
    insuranceCertificate: {
        fileBase64: string;
        fileType: string;
        fileName: string;
    } | null;
    insuranceCompanyName: string;
    insurancePolicyNumber: string;
    insuranceStartDate: Date | null;
    insuranceEndDate: Date | null;
    insuranceType: string;
    hasBusinessCover: string;
    //4. Breakdown cover
    hasBreakdownCover: boolean;
    breakdownCompanyName: string;
    breakdownPolicyNumber: string;
    breakdownStartDate: Date | null;
    breakdownEndDate: Date | null;
};

export type GreyFleetVehicleSubmissionValues = {
    greyFleetInviteId: string;
    //Step 2: Vehicle Details
    vrm: string;
    make: string;
    taxExpiryDate: string | null;
    motExpiryDate: string | null;
    //Step 3: Insurance Details
    insuranceCompanyName: string;
    insurancePolicyNumber: string;
    insuranceStartDate: Date | null;
    insuranceEndDate: Date | null;
    insuranceType: string;
    hasBusinessCover: boolean;
    //4. Breakdown cover
    hasBreakdownCover: boolean;
    breakdownCompanyName: string;
    breakdownPolicyNumber: string;
    breakdownStartDate: Date | null;
    breakdownEndDate: Date | null;
};
