import { useParams } from "react-router-dom";
import { useDriver } from "../../../api/admin/driver";
import { useDriverVehicle } from "../../../api/admin/driverVehicle";
import { useGreyFleet } from "../../../api/greyFleet";
import { Vehicle } from "../../../models/api/admin/vehicle";
import { errorToast, successToast } from "../../../toast";
import { convertDateStringToReadableString } from "../../../utils/convertDateStringToReadableString";
import { StatusAction } from "../../ActionableStatus/ActionableStatus";

type PageParams = {
    id: string;
};

const useGreyFleetActions = (vehicle: Vehicle, hasEmailAddress: boolean) => {
    const { id } = useParams<PageParams>();
    const {
        removeVehicle,
        sendGreyFleetInvite,
        expiryReminder,
        verifyInsurance,
        incompleteReminder,
    } = useGreyFleet();
    const { refetch } = useDriver(id);
    const { refetchDriverVehicle } = useDriverVehicle(vehicle.vehicleId, id);

    const greyFleetRemoveVehicle: StatusAction = {
        dropdownItem: "greyFleetRemoveVehicle",
        modalButtonAction: async () => {
            const progress = await removeVehicle(
                vehicle.vehicleId,
                vehicle.greyFleetInviteId
            );

            if (progress.success) {
                successToast("Vehicle removed");
                refetch();
            } else {
                errorToast("Something went wrong");
            }
        },
        hasEmailAddress,
    };

    const greyFleetInstantEmailReminder = (
        mostRecentEmailReminderDate: string | null
    ): StatusAction => ({
        dropdownItem: "greyFleetInstantEmailReminder",
        modalSubHeading:
            mostRecentEmailReminderDate === null
                ? ""
                : `An email reminder was sent to the driver on ${convertDateStringToReadableString(
                      mostRecentEmailReminderDate
                  )}.`,
        modalButtonAction: async () => {
            const progress = await expiryReminder(
                vehicle.vehicleId,
                vehicle.greyFleetInviteId
            );

            if (progress.success) {
                successToast("Reminder sent");
                refetch();
            } else {
                errorToast("Something went wrong");
            }
        },
    });

    const greyFleetIncompleteEmailReminder = (): StatusAction => ({
        dropdownItem: "greyFleetIncompleteEmailReminder",
        modalSubHeading: "Send grey fleet incomplete email reminder?",
        modalButtonAction: async () => {
            const progress = await incompleteReminder(
                vehicle.greyFleetInviteId
            );

            if (progress.success) {
                successToast("Reminder sent");
                refetch();
            } else {
                errorToast("Something went wrong");
            }
        },
    });

    const greyFleetInsuranceVerification: StatusAction = {
        dropdownItem: "greyFleetMarkInsuranceAsVerified",
        modalButtonAction: async () => {
            const progress = await verifyInsurance(
                vehicle.vehicleId,
                vehicle.greyFleetInviteId
            );

            if (progress.success) {
                successToast("Insurance marked as verified");
                refetch();
                refetchDriverVehicle();
            } else {
                errorToast("Something went wrong");
            }
        },
    };

    return {
        greyFleetRemoveVehicle,
        greyFleetInstantEmailReminder,
        greyFleetIncompleteEmailReminder,
        greyFleetInsuranceVerification,
    };
};

export default useGreyFleetActions;
