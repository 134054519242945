import { ApiCommentResponse } from "../models/api/admin/comment";
import CommentCard from "./CommentCard";
import { CommentFormValues } from "./CommentForm/CommentFormValues";

type CommentListProps = {
    comments: ApiCommentResponse[];
    disabledEditing?: boolean;
    onEdit: (
        commentId: string,
        dto: CommentFormValues,
        callback: (success: boolean) => void
    ) => Promise<void>;
    isEditing: boolean;
    onDelete: (
        commentId: string,
        callback: (success: boolean) => void
    ) => Promise<void>;
    isDeleting: boolean;
};

const CommentList: React.FC<CommentListProps> = ({
    comments,
    disabledEditing = false,
    onDelete,
    isDeleting,
    onEdit,
    isEditing,
}) => {
    return (
        <>
            {comments.length === 0 && (
                <div className="p-2">
                    <p>No comments</p>
                </div>
            )}
            {comments.map((comment) => (
                <CommentCard
                    key={comment.id}
                    comment={comment}
                    disableEditing={disabledEditing}
                    onEdit={onEdit}
                    isEditing={isEditing}
                    onDelete={onDelete}
                    isDeleting={isDeleting}
                />
            ))}
        </>
    );
};

export default CommentList;
