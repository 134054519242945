import {
    capitalisedTermForCompany,
    termForCompany,
} from "../../../../../copy/sharedCopy";
import Divider from "../../../../../components/ReusableComponents/Divider";
import useAdminDisableCompany from "./useAdminDisableCompany";
import ConfirmationDialog from "../../../../../components/ReusableComponents/ConfirmationDialog";
import CompanyStatusForm from "./CompanyStatusForm";
import LoadingMessage from "../../../../../components/ReusableComponents/LoadingMessage/LoadingMessage";
import DialogBox from "../../../../../components/DialogBox";
import DeleteConfirmation from "../../../../../components/ReusableComponents/DeleteConfirmation";

export default function AdminDisableCompany() {
    const {
        methods,
        editCompanyContext,
        showConfirmationDialog,
        setShowConfirmationDialog,
        handleSubmitCompanyStatus,
        onCancel,
        handleDeleteCompany,
    } = useAdminDisableCompany();
    const {
        companyData,
        isDeletingCompany,
        isEditingCompany,
        isEditingCompanyStatus,
    } = editCompanyContext;
    const { companyName } = companyData || {};

    const displayCompanyForm = !isDeletingCompany && companyData;
    const isApiEditing =
        isDeletingCompany || isEditingCompany || isEditingCompanyStatus;
    return (
        <>
            <Divider text={`Disable/activate ${termForCompany}`} />
            {!companyData && <LoadingMessage />}

            {displayCompanyForm && (
                <CompanyStatusForm
                    methods={methods}
                    companyData={{
                        ...companyData,
                    }}
                    handleSubmitCompanyStatus={handleSubmitCompanyStatus}
                    onCancel={onCancel}
                    isLoading={isApiEditing}
                    setShowConfirmationDialog={setShowConfirmationDialog}
                />
            )}

            <DeleteConfirmation
                isOpen={showConfirmationDialog}
                onClose={() => setShowConfirmationDialog(false)}
                title={`You are about to permanently delete a ${termForCompany}!`}
                onSubmit={handleDeleteCompany}
                isLoading={isApiEditing}
            />
        </>
    );
}
