import EditableContentBlock from "../../../EditableContentBlock";
import FormDocumentUpload from "../../../FormDocumentUpload";
import SubmitOrCancelButtonBar from "../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import { DocumentBase64 } from "../../../../models/app/admin/document";
import {
    capitalisedTermForCompany,
    termForCompany,
} from "../../../../copy/sharedCopy";
import { useEditCompanyLogoForm } from "./useEditCompanyLogoForm";
import { EditCompanyFormValues } from "../../EditCompanyFormValues";
import { errorToast, successToast } from "../../../../toast";
import FormInputMessage from "../../../FormInputError";

export const companyLogoAllowedExtensions = [
    "png",
    "jpg",
    "jpeg",
    "svg",
    "tiff",
    "tif",
    "ico",
    "gif",
    "webp",
    "bmp",
];

interface EditCompanyLogoFormProps {
    initialValues: EditCompanyFormValues;
    refetchCompany: () => void;
    canEdit: boolean;
}
export default function EditCompanyLogoForm({
    initialValues,
    refetchCompany,
    canEdit,
}: EditCompanyLogoFormProps) {
    const { methods, addCompanyLogo, isAddingCompanyLogo, formatExtensions } =
        useEditCompanyLogoForm();
    const { setValue, trigger, handleSubmit, reset, formState } = methods;

    const { companyId, logoUrl } = initialValues;

    return (
        <EditableContentBlock
            title={`${capitalisedTermForCompany} logo`}
            disableEdit={!canEdit}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && nonEditableContent()}
                    {isEdit && editableContent(setIsEdit)}
                </>
            )}
        </EditableContentBlock>
    );

    function editableContent(setIsEdit: (isEdit: boolean) => void) {
        const resetAndCloseForm = () => {
            reset(); //reset form to initial values
            setIsEdit(false);
        };

        const handleSetData = (
            documentBase64: DocumentBase64["documentBase64"],
            fileType: DocumentBase64["fileType"],
            fileName: DocumentBase64["fileName"]
        ) => {
            setValue("documentBase64", documentBase64);
            setValue("fileType", fileType);
            setValue("fileName", fileName);
            trigger();
        };

        const handleDocumentSubmit = async (data: DocumentBase64) => {
            if (companyId) {
                const response = await addCompanyLogo({
                    id: companyId,
                    dto: {
                        fileType: data.fileType,
                        imageBase64: data.documentBase64,
                    },
                });

                if (response.success) {
                    successToast(
                        `${capitalisedTermForCompany} logo has been added`
                    );
                    resetAndCloseForm();
                    refetchCompany();
                }
                if (!response.success) {
                    errorToast("Failed to add logo");
                }
            } else {
                errorToast("No company ID found");
            }
        };

        const handleCancelAddDoc = () => {
            setValue("documentBase64", "");
            setValue("fileType", "");
            setValue("fileName", "");
        };

        const yupErrors = formState.errors;
        const yupMissingDocumentError = yupErrors.documentBase64;
        const otherYupErrors = yupErrors.fileType || yupErrors.fileName;
        return (
            <div className="px-6 pb-10 pt-6">
                <form onSubmit={handleSubmit(handleDocumentSubmit)}>
                    <div>
                        <b>{`Add ${termForCompany} logo (${formatExtensions(
                            companyLogoAllowedExtensions
                        )}) `}</b>
                    </div>
                    <div className="pb-6">Recommended size: 600x300 px </div>
                    <FormDocumentUpload
                        handleSetData={handleSetData}
                        handleCancelAddDoc={handleCancelAddDoc}
                        validFileExtensions={companyLogoAllowedExtensions}
                    />
                    <FormInputMessage error={yupMissingDocumentError} />
                    {logoUrl && clientLogo(logoUrl)}

                    <div className="pt-7" />
                    <SubmitOrCancelButtonBar
                        isLoading={isAddingCompanyLogo}
                        onCancel={resetAndCloseForm}
                        disabled={!!(yupMissingDocumentError || otherYupErrors)}
                    />
                </form>
            </div>
        );
    }

    function nonEditableContent() {
        return (
            <div className="p-6">
                {logoUrl && clientLogo(logoUrl)}
                {!logoUrl && (
                    <div className="max-w-fit rounded-sm bg-white p-3">
                        No logo uploaded.
                    </div>
                )}
            </div>
        );
    }

    function clientLogo(logoUrl: string): JSX.Element {
        return (
            <img className="max-h-[150px] pt-3" src={logoUrl} alt={logoUrl} />
        );
    }
}
