export type ApiCompanyStatsResponse = {
    allCompaniesStats: AllCompaniesStats | null;
    companyDriversStats: CompanyDriversStats | null;
    companyGreyfleetStats: CompanyGreyFleetDriversStats | null;
    companyDeclarationsStats: CompanyDeclarationsStats | null;
    companyCurrentChecksStats: CompanyChecksThisMonthStats | null;
    companyUpcomingChecksStats: CompanyUpcomingChecksStats | null;
};

export enum CompanyStatsCategories {
    Company = "Company",
    Drivers = "Drivers",
    GreyFleet = "GreyFleet",
    Declarations = "Declarations",
    CurrentChecks = "CurrentChecks",
    UpcomingChecks = "UpcomingChecks",
}

export enum CompanyStatsTabs {
    Company = "Summary",
    Drivers = "Drivers",
    GreyFleet = "Grey Fleet Drivers",
    Declarations = "Declarations",
    CurrentChecks = "Checks this month",
    UpcomingChecks = "Upcoming checks",
}

export type ApiCompanyProfileStatsResponse = {
    drivers: CompanyDriversStats;
    greyfleet: CompanyGreyFleetDriversStats;
    declarations: CompanyDeclarationsStats;
    currentChecks: CompanyChecksThisMonthStats;
    upcomingChecks: CompanyUpcomingChecksStats;
};

export type AllCompaniesStats = {
    totalClients: number;
    totalDrivers: number;
    totalApi: number;
    totalNonApi: number;
    totalLive: number;
    totalDisabledNonPayment: number;
    totalDisabledPermanently: number;
};

export type CompanyDriversStats = {
    totalDrivers: number;
    checkedDrivers: number;
    driversAdded: number;
    driversRemoved: number;
};

export type CompanyGreyFleetDriversStats = {
    totalDrivers: number;
    invitesComplete: number;
    invitesPending: number;
};

export type CompanyDeclarationsStats = {
    driverDeclarations: number;
    declarationsComplete: number;
    declarationsExpiring: number;
    declarationsExpired: number;
};

export type CompanyChecksThisMonthStats = {
    checksCompletedInMonth: {
        total: number;
        unitedKingdomChecks: number;
        northernIrelandChecks: number;
        foreignChecks: number;
    };
    checksDueInMonth: {
        total: number;
        unitedKingdomChecks: number;
        northernIrelandChecks: number;
        foreignChecks: number;
    };
};

export type CompanyUpcomingChecksStats = {
    checksInOneMonth: number;
    checksInTwoMonths: number;
    checksInThreeMonths: number;
};
