import GreyFleetFormLayout from "./GreyFleetFormLayout";
import Button from "../Button";
import React from "react";
import FormMessageBox from "../FormMessageBox";
import { Plus } from "phosphor-react";

type Step5ProcessCompleteProps = {
    isLoading: boolean;
    handleAddAnotherVehicle: () => void;
    vehicleReg: string;
    vehicleExpiryErrorText: string;
    insuranceExpired: boolean;
    insuranceNotStarted: boolean;
    businessUse: boolean;
    breakdownCoverExpired: boolean;
    breakdownCoverNotStarted: boolean;
    showInsuranceCertificateWarning: boolean;
    hideButton: boolean;
};

const Step5ProcessComplete: React.FC<Step5ProcessCompleteProps> = ({
    isLoading,
    handleAddAnotherVehicle,
    vehicleReg,
    vehicleExpiryErrorText,
    insuranceExpired,
    insuranceNotStarted,
    businessUse,
    breakdownCoverExpired,
    breakdownCoverNotStarted,
   showInsuranceCertificateWarning,
    hideButton,
}) => {
    return (
        <GreyFleetFormLayout
            title="Grey Fleet invitation"
            content={
                <>
                    {isLoading && "Loading..."}
                    {!isLoading && (
                        <>
                            <FormMessageBox
                                title="Process complete"
                                message={
                                    <span>
                                        Thank you for submitting your vehicle
                                        details.
                                    </span>
                                }
                                variant="success"
                            />
                            {vehicleExpiryErrorText && (
                                <FormMessageBox
                                    title={`Your ${vehicleExpiryErrorText} ${`for ${vehicleReg}`} ${
                                        vehicleExpiryErrorText ===
                                        "Road Tax and MOT"
                                            ? "have"
                                            : "has"
                                    } expired`}
                                    message={`You can continue with this form now, but please update your ${vehicleExpiryErrorText} 
                                to be able to drive this vehicle for business purposes. You will receive a reminder in a week to come 
                                back to this form and re-enter your registration number. When you do so, the form will pre-populate 
                                with the updated ${vehicleExpiryErrorText} expiry ${
                                        vehicleExpiryErrorText ===
                                        "Road Tax and MOT"
                                            ? "dates"
                                            : "date"
                                    }.`}
                                    variant="error"
                                />
                            )}
                            {insuranceExpired && (
                                <FormMessageBox
                                    title={`Your insurance for ${vehicleReg} has expired`}
                                    message={`You can continue with this form now, but please update your insurance to be able
                                    to drive this vehicle for business purposes. You will receive a reminder in a week 
                                    to come back to this form, re-enter your insurance details and upload your insurance certificate.`}
                                    variant="error"
                                />
                            )}
                            {insuranceNotStarted && (
                                <FormMessageBox
                                    title={`Your insurance for ${vehicleReg} has not started yet`}
                                    message={`You can continue with this form now, but please update your insurance to be able
                                    to drive this vehicle for business purposes. You will receive a reminder in a week 
                                    to come back to this form, re-enter your insurance details and upload your insurance certificate.`}
                                    variant="error"
                                />
                            )}
                            {businessUse && (
                                <FormMessageBox
                                    title={"Arrange for business use"}
                                    message={`You can continue with this form now, but please contact your insurance provider 
                                    and arrange “for business use” to be included in your policy to be able to drive 
                                    this vehicle for business purposes. You will receive a reminder in a week to come 
                                    back to this form, re-enter your insurance details and upload your insurance certificate.`}
                                    variant="error"
                                />
                            )}
                            {breakdownCoverExpired && (
                                <FormMessageBox
                                    title={`Your breakdown cover for ${vehicleReg} has expired`}
                                    message={`You can continue with this form now, but please update your breakdown cover to be able
                                to drive this vehicle for business purposes. You will receive a reminder in a week 
                                to come back to this form and re-enter your breakdown cover details.`}
                                    variant="error"
                                />
                            )}
                            {breakdownCoverNotStarted && (
                                <FormMessageBox
                                    title={`Your breakdown cover for ${vehicleReg} has not started yet`}
                                    message={`You can continue with this form now, but please update your breakdown cover to be able
                                to drive this vehicle for business purposes. You will receive a reminder in a week 
                                to come back to this form and re-enter your breakdown cover details.`}
                                    variant="error"
                                />
                            )}
                            {showInsuranceCertificateWarning && (
                                <FormMessageBox
                                    title={`Please send us your insurance certificate for ${vehicleReg}`}
                                    message={
                                        <>
                                            Thank you for submitting your
                                            vehicle details. This form is now
                                            complete, but you haven’t uploaded
                                            your insurance certificate for this
                                            vehicle. Please email a copy to{" "}
                                            <b>greyfleet@safe2drive.co.uk</b>{" "}
                                            quoting your name and registration
                                            number. If you don’t send us your
                                            insurance certificate, you will
                                            receive a reminder in a week to come
                                            back to this form, re-enter your
                                            insurance details and upload your
                                            insurance certificate.
                                        </>
                                    }
                                    variant="error"
                                />
                            )}
                            <div>
                                <div>
                                    If you change your vehicle, please advise
                                    your employer or email
                                    <b> greyfleet@safe2drive.co.uk</b>, quoting
                                    your name and previous registration number.
                                    We will clear your old vehicle details and
                                    generate a new invite.
                                </div>
                                <div className="py-4">
                                    If you drive another vehicle for business
                                    purposes, please click on the button “Add
                                    another vehicle” below to add the details to
                                    our records.
                                </div>
                                <div>
                                    If you have finished adding all the vehicles
                                    you drive for business purposes, you can now
                                    close this window.
                                </div>
                            </div>
                        </>
                    )}
                </>
            }
            buttons={
                !hideButton ? (
                    <div className="flex justify-end gap-8">
                        <Button
                            type="button"
                            variant="primary"
                            onClick={handleAddAnotherVehicle}
                        >
                            <div className="flex flex-row items-center justify-between">
                                <Plus size={22} />
                                <div className="pl-3 font-bold">
                                    Add another vehicle
                                </div>
                            </div>
                        </Button>
                    </div>
                ) : undefined
            }
        />
    );
};

export default Step5ProcessComplete;
