import { DownloadSimple, WarningCircle } from "phosphor-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useFileDropper from "../../hooks/useFileDropper";
import useFileType from "../../hooks/useFileType";
import Button from "../Button";
import FormFileUploader from "../FormFileUploader";
import { useGetCompanies } from "../../api/admin/company";
import { useCompanyDepartmentSelector } from "../ReusableComponents/CompanyDepartmentSelector";
import HeadlessGenericDropdown, {
    DropdownItem,
} from "../ReusableComponents/HeadlessGenericDropdown";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import BulkUploadFailureReasons from "./BulkUploadFailureReasons";

type FormBulkUploadValues = {
    company: {
        id: string;
        item: string;
    };
    file: File[];
};

type FormBulkUploadProps = {
    onSubmit: (formData: FormData, companyId: string) => Promise<void>;
    isLoading: boolean;
    maxFileSizeKb?: number;
    disabled?: boolean;
    onClose: () => void;
    isBulkDelete?: boolean;
    failureReasons: string[];
    failureRef: React.MutableRefObject<HTMLDivElement | null>;
};

const FormBulkUpload: React.FC<FormBulkUploadProps> = ({
    onSubmit,
    isLoading,
    maxFileSizeKb = 1000,
    disabled = false,
    onClose,
    isBulkDelete,
    failureReasons,
    failureRef,
}) => {
    const [isDirty, setIsDirty] = useState(false);

    const { selectedCompany } = useCompanyDepartmentSelector();

    const { companyList, companyListError, isCompanyListLoading } =
        useGetCompanies(1, 100);

    const companyDropdownItems = companyList?.items.map((company) => ({
        item: company.companyName,
        id: company.companyId,
    }));

    const {
        handleSubmit,
        reset,
        register,
        formState,
        getValues,
        setValue,
        watch,
    } = useForm<FormBulkUploadValues>({
        mode: "onSubmit",
        defaultValues: {
            company: {
                id: selectedCompany?.companyId,
                item: selectedCompany?.companyName,
            },
            file: undefined,
        },
    });

    const file = getValues("file");
    const company = watch("company");

    const isDisabled = disabled || !company.id;
    const hasFile = file?.length > 0

    const handleCompanySelect = (item: DropdownItem | null) => {
        const company: ApiCompanyResponse | undefined = companyList?.items.find(
            (company) => company.companyId === item?.id
        );

        if (company) {
            const { companyId: id, companyName: item } = company;
            setValue("company", { id, item });
        }
    };

    const { loadFile, fileError } = useFileType({
        fileType: ["csv"],
        maxFileSizeKb,
        setIsDirty,
    });

    const handleSetValue = (file: File) => {
        setValue("file", [file]);
    };

    const { handleDrag, handleDrop, dragActive } = useFileDropper({
        handleSetValue,
        onLoadFile: loadFile,
    });

    useEffect(() => {
        setIsDirty(formState.isDirty);
        if (formState.isDirty) {
            file && loadFile(file[0]);
        }
    }, [formState.isDirty]);

    const handleImport = (data: FormBulkUploadValues) => {
        if (fileError) return;
        if (file === undefined) return;

        const formData = new FormData();
        const companyId = data.company.id;

        if (data.file) {
            formData.append("importFile", data.file[0]);
        }
        onSubmit(formData, companyId);
    };

    const handleReset = () => {
        reset();
        setIsDirty(false);
    };

    return (
        <form onSubmit={handleSubmit(handleImport)}>
            <div className="sticky mx-4 border border-t-0 border-S2D-neutral-80" />
            <div className=" flex max-h-96 flex-col gap-4 overflow-y-auto p-4">
                <div className="my-2 text-sm font-bold ">
                    <a
                        href={
                            isBulkDelete
                                ? require("../../DriversDelete.csv")
                                : require("../../DriversCreate.csv")
                        }
                        download={
                            isBulkDelete
                                ? "DriversDelete.csv"
                                : "DriversCreate.csv"
                        }
                        className="flex w-fit flex-row items-baseline gap-2 rounded-sm border-2 border-black py-2 px-4"
                    >
                        <DownloadSimple />
                        Download a sample spreadsheet
                    </a>
                </div>
                {companyList ? (
                    <>
                        <HeadlessGenericDropdown
                            dropdownPlaceholder="All clients"
                            dropdownData={companyDropdownItems}
                            selectedItem={company}
                            handleItemChange={handleCompanySelect}
                            error={companyListError}
                            isLoading={isCompanyListLoading}
                            label={undefined}
                        />
                        <FormFileUploader
                            isDirty={isDirty}
                            error={fileError}
                            file={file}
                            maxFileSizeKb={maxFileSizeKb}
                            onDrag={handleDrag}
                            onDrop={handleDrop}
                            dragActive={dragActive}
                            onReset={handleReset}
                            label="Drop a CSV file here to upload or"
                            {...register("file")}
                            disabled={isDisabled}
                        />
                    </>
                ) : null}
                <div className="flex gap-2 text-sm">
                    <WarningCircle size={20} weight="fill" />
                    {isBulkDelete
                        ? "To undo this action you will have to contact Safe2Drive"
                        : "Duplicate drivers will be removed"}
                </div>
                <BulkUploadFailureReasons
                    failureReasons={failureReasons}
                    failureRef={failureRef}
                />
            </div>
            <div className="flex justify-between rounded-b-sm border-t border-S2D-neutral-80 bg-S2D-neutral-90 p-2">
                <Button variant="tertiary" onClick={onClose}>
                    Cancel
                </Button>
                <Button type="submit" loading={isLoading} disabled={!hasFile}>
                    Start import
                </Button>
            </div>
        </form>
    );
};

export default FormBulkUpload;
