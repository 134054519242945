import { Car } from "phosphor-react";
import React from "react";
import { ExpiryType } from "../../../models/api/admin/vehicle";
import { addDays } from "../../../utils/addDays";
import { convertDateStringToReadableString } from "../../../utils/convertDateStringToReadableString";
import ActionableStatus from "../../ActionableStatus";
import ActionableStatusTag from "../../ActionableStatus/ActionableStatusTag";
import { GreyFleetCommonContentProps } from "./GreyFleet";

type VehicleExpiriesStatusItem = {
    documentStatusLabel: string;
    emailReminderLabel?: string;
    type: "expired" | "due";
};

const priorityOrder = {
    expired: 1,
    due: 2,
};

const statusPropertyReadableLabelMap: Record<ExpiryType, string> = {
    RoadTax: "Road tax",
    MOT: "MOT",
    Insurance: "Insurance",
    BreakdownCover: "Breakdown cover",
};

const GreyFleetExpiredContent = ({
    vehicle,
    actions,
}: GreyFleetCommonContentProps) => {
    const contentList: VehicleExpiriesStatusItem[] = [];
    const today = new Date();

    vehicle.expiries
        .filter((expiryItem) => expiryItem.expiryType !== "BreakdownCover")
        .map((expiryItem) => {
            if (expiryItem.expiryDueDate) {
                const dueDate = new Date(expiryItem.expiryDueDate);
                const hasExpired = today > dueDate;
                const isDueWithin30Days = today > addDays(dueDate, -30);

                if (hasExpired) {
                    contentList.push({
                        documentStatusLabel: `${
                            statusPropertyReadableLabelMap[
                                expiryItem.expiryType
                            ]
                        } expired: ${convertDateStringToReadableString(
                            expiryItem.expiryDueDate
                        )}`,
                        emailReminderLabel: expiryItem.expiredEmailSentDate
                            ? `Email reminder sent: ${convertDateStringToReadableString(
                                  expiryItem.expiredEmailSentDate
                              )}`
                            : undefined,
                        type: "expired",
                    });
                    return;
                }

                if (isDueWithin30Days) {
                    contentList.push({
                        documentStatusLabel: `${
                            statusPropertyReadableLabelMap[
                                expiryItem.expiryType
                            ]
                        } due to expire: ${convertDateStringToReadableString(
                            expiryItem.expiryDueDate
                        )}`,
                        type: "due",
                    });
                }
            }
        });

    contentList.sort((a, b) => priorityOrder[a.type] - priorityOrder[b.type]);
    const contentListContainsExpiredItem = Boolean(
        contentList.find((item) => item.type === "expired")
    );

    return (
        <ActionableStatus
            title="Grey Fleet"
            theme="grey"
            actionable={true}
            statusIndicators={[
                <ActionableStatusTag theme="vrm" text={vehicle.vrm} />,
                <ActionableStatusTag
                    theme={
                        contentListContainsExpiredItem
                            ? "expired"
                            : "dueToExpire"
                    }
                />,
            ]}
            actions={[...actions]}
        >
            <div className="flex items-center">
                <div className="pr-3">
                    <Car size={24} weight="duotone" />
                </div>
                <div className="flex flex-col text-sm">
                    {contentList.map((contentItem) => (
                        <React.Fragment
                            key={`greyfleet-expiry-item-${contentItem.documentStatusLabel}`}
                        >
                            <p>{contentItem.documentStatusLabel}</p>
                            {contentItem.emailReminderLabel && (
                                <p>{contentItem.emailReminderLabel}</p>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </ActionableStatus>
    );
};

export default GreyFleetExpiredContent;
