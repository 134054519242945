// 18/05/23 these endpoints are used for non-s2d users
// ie. those with "client-administrator-role" and "client-member-role"

import {
    ApiAddCompanyUserResponse,
    ApiCompanyUsersResponse,
} from "../../models/api/admin/companyUser";
import {
    AddCompanyUserDto,
    EditCompanyUserDto,
} from "../../models/app/admin/companyUser";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useGetCompanyUsers = (
    // orderBy: string,
    // sortOrder: string, // 23/05/23 not currently accepted by endpoint
    pageNumber: number,
    pageSize: number,
    companyId?: string
) => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiCompanyUsersResponse>(
            companyId
                ? `api/companies/${companyId}/users/?PageNumber=${pageNumber}&PageSize=${pageSize}`
                : null
        );

    return {
        companyUsersData: data,
        companyUsersError: error,
        isLoadingCompanyUsers: isLoading,
        refetchCompanyUsers: refetch,
    };
};

const useAddCompanyUser = () =>
    useS2DApiFetcher<
        AddCompanyUserDto,
        ApiAddCompanyUserResponse,
        { companyId: string; dto: AddCompanyUserDto }
    >(
        "POST",
        ({ companyId, dto }) => ({
            url: `api/companies/${companyId}/users`,
            body: dto,
        }),
        [500],
        true
    );

const useEditCompanyUser = () =>
    useS2DApiFetcher<
        EditCompanyUserDto,
        string,
        { companyId: string; userId: string; dto: EditCompanyUserDto }
    >(
        "PUT",
        ({ companyId, userId, dto }) => ({
            url: `api/companies/${companyId}/users/${userId}`,
            body: dto,
        }),
        [],
        true
    );

const useDeleteCompanyUser = () =>
    useS2DApiFetcher<void, string, { companyId: string; userId: string }>(
        "DELETE",
        ({ companyId, userId }) => ({
            url: `api/companies/${companyId}/users/${userId}`,
        }),
        [],
        true
    );

const useCompanyUser = () => {
    const [editCompanyUser, isEditingCompanyUser] = useEditCompanyUser();
    const [deleteCompanyUser, isDeletingCompanyUser] = useDeleteCompanyUser();
    const [addCompanyUser, isAddingCompanyUser] = useAddCompanyUser();

    return {
        addCompanyUser: async (dto: AddCompanyUserDto, companyId: string) =>
            await addCompanyUser({ companyId, dto }),
        isAddingCompanyUser,
        editCompanyUser: async (
            dto: EditCompanyUserDto,
            companyId: string,
            userId: string
        ) => await editCompanyUser({ companyId, userId, dto }),
        isEditingCompanyUser,
        deleteCompanyUser: async (companyId: string, userId: string) =>
            await deleteCompanyUser({ companyId, userId }),
        isDeletingCompanyUser,
    };
};

export default useCompanyUser;
