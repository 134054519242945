import { useState } from "react";
import { useCompany } from "../api/admin/company";
import { useDashboard } from "../api/admin/dashboard";
import useAuth from "../auth";
import DashboardAlertsSection from "../components/DashboardAlerts/DashboardAlertsSection";
import DashboardDriverRiskCategories from "../components/Dashboard/DashboardDriverRiskCategories";
import DashboardReports from "../components/Dashboard/DashboardReports";
import PageLayout from "../components/PageLayout";
import { useCompanyDepartmentSelector } from "../components/ReusableComponents/CompanyDepartmentSelector";
import HeaderOptions, {
    TailoredButton,
} from "../components/ReusableComponents/HeaderOptions";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../Routes";
import { DashboardCategories } from "../models/api/admin/dashboard";
import { Plus } from "phosphor-react";

const DashboardPage: React.FC = () => {
    const [date, setDate] = useState(new Date());
    const [compareMonths, setCompareMonths] = useState<number>();
    const auth = useAuth();
    const { companyData } = useCompany(
        auth.userDetails?.companyId ?? undefined
    );
    const [selectedContent, setSelectedContent] =
        useState<DashboardContentSections>("Driver Declarations");

    const {
        currentDepartment,
        handleDepartmentChange,
        selectedCompany,
        handleCompanyChange,
    } = useCompanyDepartmentSelector();

    // split into two requests as risk categories take a long time to calculate
    const {
        dashboardData: riskProfileData,
        isLoadingDashboard: isLoadingRiskProfiles,
        refetchDashboard: refetchRiskProfiles,
    } = useDashboard(
        {
            companyId: selectedCompany?.companyId ?? undefined,
            departmentId: currentDepartment?.departmentId ?? undefined,
            date: date.toISOString(),
            compareMonths,
        },
        [DashboardCategories.RiskProfiles]
    );

    const { dashboardData, isLoadingDashboard, refetchDashboard } =
        useDashboard(
            {
                companyId: selectedCompany?.companyId ?? undefined,
                departmentId: currentDepartment?.departmentId ?? undefined,
                date: date.toISOString(),
                compareMonths,
            },
            [
                DashboardCategories.Drivers,
                DashboardCategories.ProfessionalDrivers,
                DashboardCategories.Dvla,
                DashboardCategories.GreyFleet,
                DashboardCategories.DriverDeclarations,
                DashboardCategories.Reports,
            ]
        );

    const navigate = useNavigate();

    const handleClearCompanyDepartment = () => {
        handleCompanyChange(null);
        handleDepartmentChange(null);
    };

    const refetchAll = () => {
        refetchDashboard();
        refetchRiskProfiles();
    };

    const headerButtons: TailoredButton[] = [
        {
            title: `Add driver`,
            onClick: () => navigate(appPaths.adminAddDriver),
            icon: Plus,
        },
    ];

    return (
        <PageLayout header={false} title="Dashboard">
            <div className="flex flex-col gap-6">
                <HeaderOptions
                    selectedDepartment={currentDepartment}
                    handleDepartmentChange={(department) =>
                        handleDepartmentChange(department, refetchAll)
                    }
                    selectedCompany={selectedCompany}
                    handleCompanyChange={(company) =>
                        handleCompanyChange(company, refetchAll)
                    }
                    clearCompanyDepartment={() =>
                        handleClearCompanyDepartment()
                    }
                    tailoredButtons={headerButtons}
                />
                <DashboardDriverRiskCategories
                    dashboardData={riskProfileData}
                    isLoading={isLoadingRiskProfiles}
                    selectedCompany={selectedCompany?.companyId}
                    selectedDepartment={currentDepartment?.departmentId}
                />
                <DashboardAlertsSection
                    alertsData={dashboardData?.alerts}
                    isLoadingAlerts={isLoadingDashboard}
                    setSelectedContent={setSelectedContent}
                    selectedCompany={selectedCompany}
                    selectedDepartment={currentDepartment}
                />
                <DashboardReports
                    date={date}
                    compareMonths={compareMonths}
                    data={dashboardData}
                    loading={isLoadingDashboard}
                    onDateChange={setDate}
                    onCompareMonthsChange={setCompareMonths}
                    selectedContent={selectedContent}
                    companyId={selectedCompany?.companyId}
                    departmentId={currentDepartment?.departmentId}
                />
            </div>
        </PageLayout>
    );
};

export type DashboardContentSections =
    | "All Drivers"
    | "Professional Drivers"
    | "DVLA Checks"
    | "Grey Fleet"
    | "Driver Declarations";

export default DashboardPage;
