import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { TabStepperTabItem } from ".";

export type TabStepperPage = {
    title: string;
    content: JSX.Element;
    hiddenTab?: boolean;
};

type TabStepperProps = {
    pages: TabStepperPage[];
    selectedIndex: number;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
};

const TabStepper = ({
    pages,
    selectedIndex,
    setSelectedIndex,
}: TabStepperProps) => {
    const [maxCompletedIndex, setMaxCompletedIndex] = useState(-1);

    const allStepsComplete =
        selectedIndex >= pages.filter((page) => !page.hiddenTab).length;

    useEffect(() => {
        setMaxCompletedIndex(Math.max(maxCompletedIndex, selectedIndex));
    }, [selectedIndex]);

    return (
        <div className="flex flex-1 flex-col">
            <Tab.Group
                selectedIndex={selectedIndex}
                onChange={(newIndex) => setSelectedIndex(newIndex)}
            >
                <div className="flex-none">
                    <Tab.List className="mb-4 flex gap-[18px] p-4">
                        {pages.map((page, index) => {
                            return (
                                <TabStepperTabItem
                                    key={index}
                                    isActive={selectedIndex === index}
                                    isComplete={selectedIndex > index}
                                    isAllStepsComplete={allStepsComplete}
                                    title={page.title}
                                    isHidden={!!page.hiddenTab}
                                    shrinkTabs={pages.length > 6}
                                />
                            );
                        })}
                    </Tab.List>
                </div>
                <Tab.Panels className="flex flex-1 flex-col">
                    {pages.map((page, index) => (
                        <Tab.Panel className="flex flex-1 flex-col" key={index}>
                            {page.content}
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};

export default TabStepper;
