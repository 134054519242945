import { ApiDriverByIdResponse } from "../../models/api/admin/driver";
import { DriverValidationTypes } from "../../models/app/admin/company";
import EditCheckDateModal from "../DriverRiskProfile/EditCheckDateModal";
import MarkAsFailedModal from "../DriverRiskProfile/MarkAsFailedModal";
import ValidateByModal from "../DriverRiskProfile/ValidateByModal";
import { StatusAction } from "./ActionableStatus";
import ActionableStatusModalBase from "./ActionableStatusModalBase";

type ActionableStatusModalProps = {
    action: StatusAction;
    isOpen: boolean;
    onClose: () => void;
    driverId?: string;
    hasEmailAddress?: boolean;
    declarationComplete?: boolean;
    licenceCountryOrigin?: string;
    driverData?: ApiDriverByIdResponse;
    validateByCompanyPreference?: DriverValidationTypes;
};

const ActionableStatusModal = ({
    action,
    isOpen,
    onClose,
    driverId,
    hasEmailAddress = true,
    declarationComplete = false,
    driverData,
    validateByCompanyPreference,
}: ActionableStatusModalProps) => {
    const {
        dropdownItem,
        modalHeading,
        modalSubHeading,
        modalButtonAction,
        modalButtonText,
        isLoading,
    } = action;

    // TODO this does not need to switch for every case
    // as most are just needing us to pass the whole action object through
    // to a new component.
    switch (dropdownItem) {
        //greyfleet cases
        case "greyFleetRemoveVehicle":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || "Remove vehicle from Grey Fleet?"}
                    subHeading={
                        modalSubHeading ||
                        "The driver will remain in the platform but all vehicle details will be removed."
                    }
                    buttonText={
                        modalButtonText || "Remove vehicle from Grey Fleet"
                    }
                    buttonVariant="danger"
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );
        case "greyFleetRemoveInvite":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || "Remove Grey Fleet invitation?"}
                    subHeading={
                        modalSubHeading ||
                        "The invitation will be removed. If you change your mind, you will have to add them to Grey Fleet again via the 'Driver Type' section in their profile."
                    }
                    buttonText={
                        modalButtonText || "Remove Grey Fleet invitation"
                    }
                    buttonVariant="danger"
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );
        case "greyFleetInstantEmailReminder":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || "Send instant email reminder?"}
                    subHeading={modalSubHeading}
                    buttonText={modalButtonText || "Send instant reminder"}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    disabled={!hasEmailAddress}
                    disabledReason="We cannot send an email request as this driver has no email address."
                    isLoading={isLoading}
                />
            );
        case "greyFleetIncompleteEmailReminder":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={
                        modalHeading ||
                        "Send grey fleet incomplete email reminder?"
                    }
                    subHeading={modalSubHeading}
                    buttonText={
                        modalButtonText || "Send grey fleet incomplete reminder"
                    }
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    disabled={!hasEmailAddress}
                    disabledReason="We cannot send an email request as this driver has no email address."
                    isLoading={isLoading}
                />
            );
        case "greyFleetMarkInsuranceAsVerified":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || "Mark insurance as verified?"}
                    subHeading={
                        modalSubHeading ||
                        "The driver will be able to continue with the Grey Fleet process."
                    }
                    buttonText={modalButtonText || "Mark insurance as verified"}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );
        //driver declaration cases
        case "sendNewEDeclaration":
            return (
                <ValidateByModal
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    driverData={driverData}
                    validateByCompanyPreference={validateByCompanyPreference}
                />
            );
        case "resendEDeclaration":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || "Resend E-Declaration request?"}
                    subHeading={modalSubHeading}
                    buttonText={
                        modalButtonText || "Resend E-Declaration request"
                    }
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    disabled={!hasEmailAddress}
                    disabledReason="We cannot send an email request as this driver has no email address."
                    isLoading={isLoading}
                />
            );
        case "switchToEDeclaration":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || "Switch to E-Declaration?"}
                    subHeading={
                        modalSubHeading ||
                        "The current paper declaration process will be cancelled."
                    }
                    buttonText={modalButtonText || "Switch to E-Declaration"}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    disabled={!hasEmailAddress || declarationComplete}
                    disabledReason={
                        !hasEmailAddress
                            ? "We cannot switch to an E-Declaration as this driver has no email address."
                            : "We cannot switch to an E-Declaration as this drivers declaration is already complete."
                    }
                    isLoading={isLoading}
                />
            );
        case "switchToPaperDeclaration":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || "Switch to paper declaration?"}
                    subHeading={
                        modalSubHeading ||
                        "The current E-Declaration process will be cancelled."
                    }
                    buttonText={
                        modalButtonText || "Switch to paper declaration"
                    }
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    disabled={declarationComplete}
                    disabledReason="We cannot switch to a paper declaration as this drivers declaration is already complete."
                    isLoading={isLoading}
                />
            );
        case "sendNewPaperDeclaration":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={
                        modalHeading || "Send new paper declaration email?"
                    }
                    subHeading={
                        modalSubHeading ||
                        "The current paper declaration process will be cancelled."
                    }
                    buttonText={
                        modalButtonText || "Send new paper declaration email"
                    }
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    disabled={!hasEmailAddress}
                    disabledReason="We cannot send an email request as this driver has no email address."
                    isLoading={isLoading}
                />
            );
        case "resendPaperDeclarationEmail":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || "Resend paper declaration email?"}
                    subHeading={modalSubHeading}
                    buttonText={
                        modalButtonText || "Resend paper declaration email"
                    }
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    disabled={!hasEmailAddress}
                    disabledReason="We cannot send an email request as this driver has no email address."
                    isLoading={isLoading}
                />
            );
        case "markAsRequested":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={"Mark as requested"}
                    subHeading={"Mark as requested."}
                    buttonText={"Mark as requested"}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );
        case "uploadSignedDeclaration":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || "Upload signed declaration?"}
                    subHeading={
                        modalSubHeading || "Upload a signed declaration."
                    }
                    buttonText={modalButtonText || "Upload signed declaration"}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );

        case "sendInstantReminder":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || ""}
                    subHeading={modalSubHeading || ""}
                    buttonText={modalButtonText || ""}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    disabled={!hasEmailAddress}
                    disabledReason="We cannot send an email request as this driver has no email address."
                    isLoading={isLoading}
                />
            );

        case "retryCheck":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || ""}
                    subHeading={modalSubHeading || ""}
                    buttonText={modalButtonText || ""}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );

        case "disableFurtherChecks":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || ""}
                    subHeading={modalSubHeading || ""}
                    buttonText={modalButtonText || ""}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );

        case "enableFurtherChecks":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || ""}
                    subHeading={modalSubHeading || ""}
                    buttonText={modalButtonText || ""}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );

        case "changeToManual":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || ""}
                    subHeading={modalSubHeading || ""}
                    buttonText={modalButtonText || ""}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );

        case "changeToAutomatic":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || ""}
                    subHeading={modalSubHeading || ""}
                    buttonText={modalButtonText || ""}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );

        case "editCheckDate":
            return (
                <EditCheckDateModal
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    driverId={driverId || ""}
                />
            );

        case "markAsChecked":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || ""}
                    subHeading={modalSubHeading || ""}
                    buttonText={modalButtonText || ""}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );

        case "markAsPending":
            return (
                <ActionableStatusModalBase
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    heading={modalHeading || ""}
                    subHeading={modalSubHeading || ""}
                    buttonText={modalButtonText || ""}
                    action={() =>
                        modalButtonAction ? modalButtonAction() : undefined
                    }
                    isLoading={isLoading}
                />
            );

        case "markAsFailed":
            return (
                <MarkAsFailedModal
                    isOpen={isOpen}
                    onClose={() => onClose()}
                    driverId={driverId || ""}
                />
            );

        default:
            return null;
    }
};

export default ActionableStatusModal;
