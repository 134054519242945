import {
    BreakdownCoverInformationValidationSchema,
    EditGreyFleetFormValues,
    InsuranceInformationValidationSchema,
    VehicleInformationValidationSchema,
} from "./EditGreyFleetFormValues";
import EditVehicleInformationForm from "./Forms/EditVehicleInformationForm";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EditInsuranceInformationForm from "./Forms/EditInsuranceInformationForm";
import EditBreakdownCoverInformationForm from "./Forms/EditBreakdownCoverInformationForm";
import { useEffect } from "react";
import EditInsuranceDocumentForm from "./Forms/EditInsuranceDocumentForm";
import { canEditPii, hasClientManagementAccess, isS2dUser } from "../../auth/userAccessHelpers";
import useAuth from "../../auth";

type GreyFleetInformationProps = {
    initialValues: EditGreyFleetFormValues;
    isLoading: boolean;
    onSubmit: (
        data: EditGreyFleetFormValues,
        formType: string,
        callback: () => void
    ) => Promise<void>;
    tabSelected: string;
    driverId: string;
    vehicleId: string;
};

const vehicleValidationSchema = yup.object().shape({
    ...VehicleInformationValidationSchema,
});

const insuranceValidationSchema = yup.object().shape({
    ...InsuranceInformationValidationSchema,
});

const breakdownValidationSchema = yup.object().shape({
    ...BreakdownCoverInformationValidationSchema,
});

const GreyFleetInformation = ({
    initialValues,
    isLoading,
    onSubmit,
    tabSelected,
    driverId,
    vehicleId,
}: GreyFleetInformationProps) => {
    const auth = useAuth();

    const vehicleMethods = useForm<EditGreyFleetFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(vehicleValidationSchema),
        defaultValues: initialValues,
    });

    const insuranceMethods = useForm<EditGreyFleetFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(insuranceValidationSchema),
        defaultValues: initialValues,
    });

    const breakdownMethods = useForm<EditGreyFleetFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(breakdownValidationSchema),
        defaultValues: initialValues,
    });


    useEffect(() => {
        vehicleMethods.reset(initialValues);
        insuranceMethods.reset(initialValues);
        breakdownMethods.reset(initialValues);
    }, [tabSelected]);

    return (
        <>
            <EditVehicleInformationForm
                initialValues={initialValues}
                onSubmit={(data, callback) => onSubmit(data, "vehicle", callback)}
                isLoading={isLoading}
                methods={vehicleMethods}
            />
            <EditInsuranceInformationForm
                initialValues={initialValues}
                onSubmit={(data, callback) => onSubmit(data, "insurance", callback)}
                isLoading={isLoading}
                methods={insuranceMethods}
            />
            <EditBreakdownCoverInformationForm
                initialValues={initialValues}
                onSubmit={(data, callback) => onSubmit(data, "breakdown", callback)}
                isLoading={isLoading}
                methods={breakdownMethods}
            />
            {(isS2dUser(auth.decodedToken?.role) || (hasClientManagementAccess(auth.decodedToken) && canEditPii(auth.decodedToken))) && (
                <EditInsuranceDocumentForm
                    initialValues={{
                        ...initialValues,
                    }}
                    driverId={driverId}
                    vehicleId={vehicleId}
                />
            )}
        </>
    );
};

export default GreyFleetInformation;
