import {
    Bar,
    BarChart as ReBarChart,
    CartesianGrid,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

export type BarChartItem = {
    name: string;
    color: string;
    value: number;
};

type BarChartProps = {
    ticks?: number[];
    domain?: [number, number];
    data: BarChartItem[];
};

const BarChart = ({ data, ticks, domain }: BarChartProps) => {
    return (
        <>
            <ResponsiveContainer width="100%" height={260}>
                <ReBarChart
                    data={data}
                    barCategoryGap={8}
                    margin={{
                        bottom: 0,
                        left: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="0" stroke="#E4E3DB" />
                    <XAxis
                        dataKey="name"
                        alignmentBaseline="central"
                        tickLine={false}
                        tick={{ fontSize: "0.55rem", fill: "#474741" }}
                        stroke="#E4E3DB"
                    />
                    <YAxis
                        domain={domain}
                        ticks={ticks}
                        tickLine={true}
                        tick={{ fontSize: "0.55rem", fill: "#474741" }}
                        stroke="#E4E3DB"
                        width={24}
                    />
                    <Tooltip cursor={false} />
                    <Bar dataKey="value" maxBarSize={204}>
                        {data.map((entry, index) => (
                            <Cell
                                fill={entry.color}
                                key={`cell-${index}-${entry.name}`}
                            />
                        ))}
                    </Bar>
                </ReBarChart>
            </ResponsiveContainer>
        </>
    );
};

export default BarChart;
