import { useForm } from "react-hook-form";
import { CompanyAuthSettings } from "../../../models/app/admin/company";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { EditCompanySecuritySettingsSchema } from "../CompanyCheckSettingsTab/CheckSettingsSubForms/CheckSettingsFormSchemas";
import { EditCompanyFormValues } from "../../EditCompanyForms/EditCompanyFormValues";
import { useEffect, useRef } from "react";

export type EditCompanySecurityFormValues = CompanyAuthSettings;

export type ValidSecurityFormNames = keyof CompanyAuthSettings;

export const useCompanySecurityTab = (
    initialValues?: EditCompanyFormValues
) => {
    const isLoading = useRef(false);

    const displayedValues: EditCompanySecurityFormValues = {
        enforce2FA: initialValues?.settings?.authSettings?.enforce2FA ?? false,
    };

    const validationSchema = yup
        .object()
        .shape(EditCompanySecuritySettingsSchema);

    const methods = useForm<EditCompanySecurityFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: displayedValues || null,
    });

    useEffect(() => {
        methods.reset(displayedValues);
        isLoading.current = false;
    }, [JSON.stringify(displayedValues)]);

    return { methods, displayedValues, isLoading };
};
