import DriverSummaryCard from "../DriversSummary/DriverSummaryCard";
import { Car } from "phosphor-react";

type GreyFleetDriversSectionProps = {
    driverCount?: number;
};
export default function GreyFleetDriversSection({
    driverCount,
}: GreyFleetDriversSectionProps) {
    return (
        <DriverSummaryCard
            label={"Number of Grey Fleet Drivers"}
            statistic={driverCount}
            icon={Car}
        />
    );
}
