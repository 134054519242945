import { SetStateAction } from "react";
import ProfileTabButton from "./ProfileTabButton";

type ProfileTabButtonProps = {
    options: string[];
    onSelect: (value: SetStateAction<string>) => void;
    selected: string;
};

const ProfileTabs: React.FC<ProfileTabButtonProps> = ({
    options,
    onSelect,
    selected,
}) => {
    return (
        <div className="overflow-x-auto">
            <div className="flex min-w-max">
                {options.map((option) => (
                    <ProfileTabButton
                        key={option}
                        option={option}
                        onSelect={onSelect}
                        selected={selected}
                    />
                ))}
            </div>
        </div>
    );
};

export default ProfileTabs;
