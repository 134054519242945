import React from "react";
import { formatDateToString } from "../../../utils/formatDateToString";

type PdfReportProps = {
    companyName?: string;
    departmentName?: string;
    isDriverReport?: boolean;
    driverName?: string;
    children: React.ReactNode;
};
export default function PdfReport({
    companyName: companyName,
    departmentName,
    isDriverReport = false,
    driverName,
    children,
}: PdfReportProps) {
    const dateTime = formatDateToString(new Date(), true);

    return (
        <div className="cursor-wait p-8">
            <div className="flex flex-col justify-between gap-3 pb-6 lg:flex-row">
                <div className="flex flex-col gap-3 lg:flex-row">
                    <span className="whitespace-nowrap">
                        <b>Client:</b> {companyName ?? "All clients"}
                    </span>
                    {isDriverReport ? (
                        <span className="whitespace-nowrap">
                            <b>Driver:</b> {driverName ?? "Unknown driver"}
                        </span>
                    ) : (
                        <span className="whitespace-nowrap">
                            <b>Department:</b>{" "}
                            {departmentName ?? "All departments"}
                        </span>
                    )}
                </div>
                <span>
                    <b>Report created:</b> {dateTime}
                </span>
            </div>
            {children}
        </div>
    );
}
