import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { appPaths } from "../../Routes";
import { Plus } from "phosphor-react";
import PageLayout from "../../components/PageLayout";

const AdminResellers = () => {
    return (
        <PageLayout title="Resellers">
            <>
                <div className="flex justify-end mb-8">
                    <Link to={appPaths.adminAddReseller}>
                        <Button icon={Plus}>ADD RESELLER</Button>
                    </Link>
                </div>
            </>
        </PageLayout>
    );
};

export default AdminResellers;
