import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { SchemaKeys } from "../../../../models/shared/schemaKeysHelper";
import { nullableNumber } from "../../../../schemas/nullableNumber";
import { useCompany } from "../../../../api/admin/company";
import { ApiInvoiceSettingsItem } from "../../../../models/api/admin/company";
import { CompanyInvoiceSettings } from "../../../../models/app/admin/company";
import { dateValidation } from "../../../../utils/dateValidationYup";
import { localToUTC } from "../../../../utils/localToUTC";
import { anchorDate } from "../../../../utils/anchorDate";

export type BillingInfoSectionFormValues = ApiInvoiceSettingsItem;

const today = anchorDate(localToUTC(new Date()), "max");

const EditBillingInfoSchema: SchemaKeys<BillingInfoSectionFormValues> = {
    shouldInvoiceParent: yup.boolean(),
    annualFee: nullableNumber
        .required("Annual Fee is required")
        .min(0, "Annual fee must be greater than or equal to zero")
        .max(9999.99, "Annual fee must be less than or equal to 9999.99"),
    lastInvoicedAt: dateValidation
        .required("Last invoiced at is required")
        .max(today, "Last invoiced at date can not be in the future"),
};

const useBillingInfoSection = (companyInvoiceSettings: CompanyInvoiceSettings | undefined) => {
    const { editInvoiceSettings, isEditingInvoiceSettings } = useCompany();

    const displayedValues: BillingInfoSectionFormValues = {
        shouldInvoiceParent: companyInvoiceSettings?.shouldInvoiceParent ?? false,
        lastInvoicedAt: companyInvoiceSettings?.lastInvoicedAt ?? null,
        annualFee: companyInvoiceSettings?.annualFee ?? null
    };

    const validationSchema = yup.object().shape(EditBillingInfoSchema);

    const methods = useForm<BillingInfoSectionFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: displayedValues
    });

    return {
        methods,
        editInvoiceSettings,
        isEditingInvoiceSettings,
        displayedValues
    };
};

export default useBillingInfoSection;
