import { yupResolver } from "@hookform/resolvers/yup";
import { Pencil } from "phosphor-react";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { type ApiParsedFiltersResponse } from "../../models/api/admin/filter";
import Button from "../Button";
import ConfirmationDialog from "../ReusableComponents/ConfirmationDialog";
import FormTextInput from "../FormTextInput";
import { type DriversListFiltersFormValues } from "./DriversListFiltersForm/DriversListFiltersFormValues";
import {
    SaveEditFilterValidationSchema,
    type SaveEditFilterValues,
} from "./DriversListSaveEditFilterValues";
import ShareFilterFormField from "./ShareFilterFormField";
import { summariseAppliedFilters } from "./summariseAppliedFilters";
import { useGetCompanies } from "../../api/admin/company";
import { useGetDepartments } from "../../api/admin/department";
import LoadingMessage from "../ReusableComponents/LoadingMessage/LoadingMessage";
import { useLocation } from "react-router-dom";
import { driverPageLocation } from "../../utils/driverPageLocation";
import { driverListFiltersDefaultValues } from "./DriversListFiltersForm/driversListFiltersDefaultValues";

export type DriverListEditFilterProps = {
    selectedFilter?: DriversListFiltersFormValues;
    onEditFilter: () => void;
    onSubmitNewFilter: (data: SaveEditFilterValues) => void;
    onSubmitEditedFilter: (data: SaveEditFilterValues) => void;
    isEdit: boolean;
    isAddingFilter: boolean;
    isEditingFilter: boolean;
    handleCloseEditDialog: () => void;
    onDeleteFilter: () => Promise<void>;
    isDeletingFilter: boolean;
    onApplyFilter: (filters?: DriversListFiltersFormValues) => Promise<boolean>;
    appliedFilter?: ApiParsedFiltersResponse;
};

const DriverListSaveEditFilter: React.FC<DriverListEditFilterProps> = ({
    selectedFilter,
    onEditFilter,
    onSubmitNewFilter,
    onSubmitEditedFilter,
    isEdit,
    isAddingFilter,
    isEditingFilter,
    handleCloseEditDialog,
    onDeleteFilter,
    isDeletingFilter,
    appliedFilter,
    onApplyFilter,
}) => {
    const validationSchema = yup.object().shape(SaveEditFilterValidationSchema);

    const blankFilter = {
        name: "",
        filterJSON: JSON.stringify(selectedFilter),
        userIds: undefined,
    };

    const methods = useForm<SaveEditFilterValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: isEdit
            ? {
                  name: appliedFilter?.name,
                  filterJSON: JSON.stringify(selectedFilter),
                  userIds: appliedFilter?.userIds ?? [],
              }
            : blankFilter,
    });

    const control = methods.control;
    const userIds = useWatch({ control, name: "userIds" });

    const pageLocation = useLocation();
    const location = driverPageLocation(pageLocation.pathname);

    const filterValues =
        JSON.stringify(selectedFilter) !==
        JSON.stringify(driverListFiltersDefaultValues(location))
            ? selectedFilter
            : appliedFilter?.filterDetails;

    const handleFormSubmit = (value: SaveEditFilterValues) => {
        if (
            !filterValues ||
            JSON.stringify(filterValues) ===
                JSON.stringify(driverListFiltersDefaultValues(location))
        )
            return;

        const filters = JSON.stringify(filterValues);
        const data = {
            name: value.name,
            filterJSON: filters,
            userIds: userIds,
        };
        if (
            isEdit &&
            appliedFilter?.filterDetails ===
                driverListFiltersDefaultValues(location)
        ) {
            onSubmitEditedFilter(data);
            onApplyFilter(filterValues);
        } else {
            onSubmitNewFilter(data);
            onApplyFilter(filterValues);
        }
    };

    const { companyList, isCompanyListLoading } = useGetCompanies(1, 100);

    const selectedCompanyIds = filterValues?.company?.companyIds;
    const selectedCompanyId =
        selectedCompanyIds && selectedCompanyIds.length > 0
            ? selectedCompanyIds[0]
            : undefined;
    const { departmentsData, isLoadingDepartments } = useGetDepartments(
        selectedCompanyId,
        1,
        100
    );

    const summarisedAppliedFilters = summariseAppliedFilters(
        isEdit,
        companyList?.items,
        departmentsData?.items,
        appliedFilter,
        selectedFilter
    );

    const [
        deleteFilterConfirmationDialogIsOpen,
        setDeleteFilterConfirmationDialogIsOpen,
    ] = useState(false);
    const resetClose = () => {
        methods.reset();
        handleCloseEditDialog();
    };

    const filterName = useWatch({ control, name: "name" });
    const deleteFilterText = `"${filterName}" will still be visible to other users.`;

    const appliedFilterStatement = (appliedFilters: string[]) => {
        switch (appliedFilters.length) {
            case 0:
                return <b>no filters applied</b>;
            case 1:
                return <b>1 filter applied</b>;
            default:
                return <b>{appliedFilters.length} filters applied</b>;
        }
    };

    return (
        <>
            <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
                <div className="flex flex-col gap-4 p-4">
                    <FormTextInput
                        label="Filter name"
                        required
                        placeholder="Enter filter name"
                        {...methods.register("name")}
                    />
                    <div>
                        {isCompanyListLoading ||
                        (selectedCompanyId && isLoadingDepartments) ? (
                            <LoadingMessage />
                        ) : (
                            <>
                                {appliedFilterStatement(
                                    summarisedAppliedFilters
                                )}
                                <div className="flex justify-between">
                                    <div>
                                        {summarisedAppliedFilters.map(
                                            (filter) => (
                                                <p key={filter}>{filter}</p>
                                            )
                                        )}
                                    </div>
                                    <button
                                        className="flex h-fit justify-start pr-2"
                                        onClick={onEditFilter}
                                    >
                                        <Pencil size={24} />
                                    </button>
                                </div>
                            </>
                        )}
                    </div>

                    <ShareFilterFormField methods={methods} />
                </div>
                <div className="flex flex-col-reverse justify-between rounded-b-sm border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                    <Button
                        variant="tertiary"
                        type="button"
                        className="w-full justify-center md:w-auto"
                        onClick={() => resetClose()}
                    >
                        Cancel
                    </Button>
                    <div className="flex flex-col-reverse md:flex-row md:justify-between md:gap-2">
                        {isEdit && (
                            <>
                                {appliedFilter?.filterId && (
                                    <div className="py-2 md:py-0">
                                        <Button
                                            variant="tertiary"
                                            type="button"
                                            className="w-full justify-center text-S2D-error-40 md:w-auto"
                                            onClick={() =>
                                                setDeleteFilterConfirmationDialogIsOpen(
                                                    true
                                                )
                                            }
                                            loading={isDeletingFilter}
                                        >
                                            Delete filter
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                        <Button
                            type="submit"
                            className="w-full justify-center md:w-auto"
                            loading={isEdit ? isEditingFilter : isAddingFilter}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <ConfirmationDialog
                    open={deleteFilterConfirmationDialogIsOpen}
                    title="Delete filter?"
                    caption={deleteFilterText}
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Delete filter"
                    variant="danger"
                    isLoading={isDeletingFilter}
                    onClose={() =>
                        setDeleteFilterConfirmationDialogIsOpen(false)
                    }
                    onConfirm={() => onDeleteFilter()}
                />
            </form>
        </>
    );
};

export default DriverListSaveEditFilter;
