import { SchemaLike } from "yup/lib/types";
import * as yup from "yup";

export const addressSchemaProperties = [
    "address1",
    "address2",
    "address3",
    "address4",
    "address5",
    "addressTown",
    "addressPostcode",
];

// We need to define array of dependency arrays to satisfy Yup validation cyclic dependency
export const exhaustiveAddressDependencyArray = addressSchemaProperties
    .filter(
        (addressSchemaProperty) =>
            addressSchemaProperty === "address1" ||
            addressSchemaProperty === "addressTown" ||
            addressSchemaProperty === "addressPostcode"
    )
    .map((primaryAddressSchemaProperty) =>
        addressSchemaProperties
            .filter(
                (addressSchemaProperty) =>
                    addressSchemaProperty !== primaryAddressSchemaProperty
            )
            .map((secondaryAddressSchemaProperty): [string, string] => [
                primaryAddressSchemaProperty,
                secondaryAddressSchemaProperty,
            ])
    )
    .flat();

export const conditionalAddressValidation = (
    ignoreProperty: string,
    validationSchema: SchemaLike
) => {
    const propertiesToCheck = addressSchemaProperties.filter(
        (property) => property !== ignoreProperty
    );

    // there will always be 6 address properties left after filtering current
    // ignore property - prop1, prop2 etc
    return yup.string().when(propertiesToCheck, {
        is: (
            prop1: string,
            prop2: string,
            prop3: string,
            prop4: string,
            prop5: string,
            prop6: string
        ) => {
            if (
                prop1 !== "" ||
                prop2 !== "" ||
                prop3 !== "" ||
                prop4 !== "" ||
                prop5 !== "" ||
                prop6 !== ""
            ) {
                return true;
            }
            return false;
        },
        then: validationSchema,
    });
};
