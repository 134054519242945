import * as yup from "yup";
import { useContext } from "react";
import { useCompany } from "../../../../api/admin/company";
import {
    EditCompanyDetailsSchema,
    EditCompanyFormValues,
} from "../../EditCompanyFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { IsEditingContext } from "../../../CompanyProfile/CompanyAccountTab/CompanyAccountTab";

export type formValues = NonNullable<
    Pick<EditCompanyFormValues, "companyName" | "resellerId" | "address" | "settings">
>;

export const useEditCompanyDetailsForm = (
    initialValues: EditCompanyFormValues
) => {
    // TODO 05/04/23 call could be removed if ApiCompanyResponse includes name as
    // well as id of parent company
    const { companyData: parentCompanyData } = useCompany(
        initialValues?.resellerId
    );
    const isEditing = useContext(IsEditingContext);

    const validationSchema = yup.object().shape(EditCompanyDetailsSchema);

    const defaultValues: formValues = {
        companyName: initialValues.companyName ?? undefined,
        resellerId: initialValues.resellerId ?? undefined,
        address: initialValues.address ?? undefined,
        settings: {
            privacySettings: {
                isDataHidden: initialValues.settings?.privacySettings?.isDataHidden ?? true
            }
        }
    };

    const methods = useForm<formValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues,
    });

    useEffect(() => {
        methods.reset(defaultValues);
    }, [JSON.stringify(initialValues)]);

    return {
        isEditing,
        methods,
        defaultValues,
        parentCompanyData,
    };
};
