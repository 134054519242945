import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pencil, X } from "phosphor-react";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import Button from "../../Button";
import {
    EditGreyFleetFormValues,
    GreyFleetDocumentValues,
} from "../EditGreyFleetFormValues";
import FormDropdown from "../../FormDropdown";
import DocumentItem from "../../DocumentItem";
import ConfirmationDialog from "../../ReusableComponents/ConfirmationDialog";
import { DocumentTypes } from "../../../models/app/admin/document";
import { errorToast, successToast } from "../../../toast";
import FormFileUploader from "../../FormFileUploader";
import useFileType from "../../../hooks/useFileType";
import useFileDropper from "../../../hooks/useFileDropper";
import { useDocuments } from "../../../api/admin/document";
import FormInputError from "../../FormInputError";
import { validDocumentFileExtensions } from "../../../constants/fileExtensions";
import EditableContentBlock from "../../EditableContentBlock";

export type EditInsuranceDocumentFormProps = {
    initialValues: EditGreyFleetFormValues;
    driverId: string;
    vehicleId: string;
};

type Option = {
    label: string;
    value: number;
};

const documentTypeOptions: Option[] = [
    {
        label: "Insurance certificate",
        value: DocumentTypes.InsuranceCertificate,
    },
    {
        label: "V5C",
        value: DocumentTypes.V5C,
    },
    {
        label: "Mot certificate",
        value: DocumentTypes.Mot,
    },
    {
        label: "Road tax certificate",
        value: DocumentTypes.RoadTax,
    },
];

const EditInsuranceDocumentForm: React.FC<EditInsuranceDocumentFormProps> = ({
    initialValues,
    driverId,
    vehicleId,
}) => {
    const [showFileIcon, setShowFileIcon] = useState(false);
    const [fileIdToBeDeleted, setFileIdToBeDeleted] = useState<string>();
    const {
        addDocument,
        isAddingDocument,
        deleteDocument,
        isDeletingDocument,
    } = useDocuments(driverId);

    const validationSchema = yup.object().shape({
        file: yup
            .mixed()
            .test("fileRequired", "Please upload a file.", (value) => {
                return value && value.length > 0;
            }),
        documentType: yup.number().required("Please select a document type"),
    });

    const {
        handleSubmit,
        reset: resetForm,
        register,
        formState,
        setValue,
        control,
    } = useForm<GreyFleetDocumentValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            file: [],
            documentType: 1,
            driverId: driverId,
            vehicleId: vehicleId,
        },
    });

    const { loadFile, fileError } = useFileType({
        fileType: validDocumentFileExtensions,
        maxFileSizeKb: 1000,
        setIsDirty: setShowFileIcon,
    });

    const handleSetValue = (file: File) => {
        setValue("file", [file]);
    };

    const { handleDrag, handleDrop, dragActive } = useFileDropper({
        handleSetValue,
        onLoadFile: loadFile,
    });

    const file = useWatch({ control, name: "file" });

    useEffect(() => {
        setShowFileIcon(!!file[0]);
        file[0] && loadFile(file[0]);
    }, [file]);

    useEffect(() => {
        // as the driverId and vehicleId have no input fields
        // we need to register them and then set their values
        register("driverId");
        register("vehicleId");
        setValue("driverId", driverId);
        setValue("vehicleId", vehicleId);
    }, [driverId, vehicleId]);

    const handleFileSubmit = async (
        data: GreyFleetDocumentValues,
        callback: (value: boolean) => void
    ) => {
        if (fileError) return;
        const formData = new FormData();
        formData.append("File", data.file[0]);
        formData.append("DocumentType", data.documentType.toString());
        formData.append("DriverId", data.driverId);
        formData.append("VehicleId", data.vehicleId);

        const res = await addDocument(formData);

        if (res.success) {
            successToast("document added successfully");
            callback(false);
            setShowFileIcon(false);
            resetForm();
        } else {
            errorToast("failed to add document");
        }
    };

    const handleCloseDeleteFileConfirmation = () =>
        setFileIdToBeDeleted(undefined);

    const handleDeleteFileConfirmation = async () => {
        if (fileIdToBeDeleted) {
            const response = await deleteDocument(fileIdToBeDeleted);
            if (response.success) {
                handleCloseDeleteFileConfirmation();
                successToast("File has been deleted");
                return;
            }

            errorToast(
                "There was a problem deleting this file, please try again"
            );
        }
    };

    const handleReset = () => {
        resetForm();
        setShowFileIcon(false);
    };

    const handleCancel = (callback: (value: boolean) => void) => {
        callback(false);
        resetForm();
    };

    return (
        <>
            <EditableContentBlock title="Documents">
                {({ isEdit, setIsEdit }) => (
                    <>
                        {!isEdit && (
                            <div className="flex flex-col gap-4 space-y-2 border border-t-0 p-6">
                                {!initialValues.vehicleDocuments?.length && (
                                    <p>
                                        This driver has no associated documents.
                                    </p>
                                )}
                                {initialValues.vehicleDocuments?.map((document) => {
                                    return (
                                        <DocumentItem
                                            key={document.fileId}
                                            document={document}
                                            onDelete={setFileIdToBeDeleted}
                                        />
                                    );
                                })}
                            </div>
                        )}

                        {isEdit && (
                            <form
                                className="border border-t-0 border-S2D-neutral-80 p-6"
                                onSubmit={handleSubmit((data) =>
                                    handleFileSubmit(data, setIsEdit)
                                )}
                            >
                                <div className="relative flex flex-col gap-4">
                                    <Controller
                                        name="documentType"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormDropdown
                                                label="Document type"
                                                options={documentTypeOptions}
                                                pleaseSelectText="Select document type"
                                                value={value}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                    <FormFileUploader
                                        isDirty={showFileIcon}
                                        error={fileError}
                                        file={file}
                                        maxFileSizeKb={1000}
                                        onDrag={handleDrag}
                                        onDrop={handleDrop}
                                        dragActive={dragActive}
                                        onReset={handleReset}
                                        label="Drop a file here to upload or"
                                        {...register("file")}
                                    />
                                    <FormInputError
                                        error={
                                            formState.errors.file?.message ?? ""
                                        }
                                    />
                                    <div className="flex justify-between">
                                        <Button
                                            type="button"
                                            variant="tertiary"
                                            onClick={() =>
                                                handleCancel(setIsEdit)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            loading={isAddingDocument}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </>
                )}
            </EditableContentBlock>
            <ConfirmationDialog
                open={Boolean(fileIdToBeDeleted)}
                title="Delete document?"
                caption="This action can't be reversed."
                cancelButtonLabel="Cancel"
                confirmButtonLabel="Delete"
                variant="danger"
                isLoading={isDeletingDocument}
                onClose={handleCloseDeleteFileConfirmation}
                onConfirm={handleDeleteFileConfirmation}
            />
        </>
    );
};

export default EditInsuranceDocumentForm;
