import useAuth from "../../../auth/useAuth";
import { isS2dUser } from "../../../auth/userAccessHelpers";
import { ApiDriverByIdResponse } from "../../../models/api/admin/driver";
import { MandateDetails } from "../../../models/api/admin/mandateDetails";
import DeclarationStatusComplete from "./DeclarationStatusComplete";
import DeclarationStatusEmpty from "./DeclarationStatusEmpty";
import DeclarationStatusExpired from "./DeclarationStatusExpired";
import DeclarationStatusForeign from "./DeclarationStatusForeign";
import DeclarationStatusPending from "./DeclarationStatusPending";
import { GREAT_BRITAIN, NORTHERN_IRELAND } from "../../../constants/countries";
import { ApiCompanyResponse } from "../../../models/api/admin/company";

type DeclarationStatusProps = {
    mandateDetails: MandateDetails;
    driverData?: ApiDriverByIdResponse;
    handleScrollToDocumentUpload: () => void;
    companyData: ApiCompanyResponse | undefined
};

const DeclarationStatus = ({
    mandateDetails,
    driverData,
    handleScrollToDocumentUpload,
    // send this to all that require the "send new eDeclaration" action
    // this company choice is based on the driver's association, rather than the user's.
    companyData
}: DeclarationStatusProps) => {
    const { declarationStatus, declarationType, declarationRequested } = mandateDetails;
    const { decodedToken } = useAuth();
    const isS2DUser = isS2dUser(decodedToken?.role);

    const foreignLicence = !(
        mandateDetails.licenceCountryOrigin == GREAT_BRITAIN ||
        mandateDetails.licenceCountryOrigin == NORTHERN_IRELAND ||
        mandateDetails.licenceCountryOrigin == null
    );

    return (
        <>
            {((declarationStatus === "Requested" && driverData?.mandateDetails.declarationSigned === null) 
            || (declarationStatus === "None" && declarationType === "PaperDeclaration" && declarationRequested != null)) && (
                <DeclarationStatusPending
                    mandateDetails={mandateDetails}
                    driverData={driverData}
                    isS2DUser={isS2DUser}
                    handleScrollToDocumentUpload={handleScrollToDocumentUpload}
                />
            )}
            {((declarationStatus === "Completed" ||
                declarationStatus === "DueToExpire") && driverData?.mandateDetails.declarationSigned !== null) && (
                <DeclarationStatusComplete
                    mandateDetails={mandateDetails}
                    driverData={driverData}
                    validateByCompanyPreference={
                        companyData?.settings.edeclaration?.driverValidationType
                    }
                    handleScrollToDocumentUpload={handleScrollToDocumentUpload}
                />
            )}
            {declarationStatus === "Expired" && (
                <DeclarationStatusExpired
                    mandateDetails={mandateDetails}
                    driverData={driverData}
                    validateByCompanyPreference={
                        companyData?.settings.edeclaration?.driverValidationType
                    }
                    isS2DUser={isS2DUser}
                    handleScrollToDocumentUpload={handleScrollToDocumentUpload}
                />
            )}

            {foreignLicence && declarationType === "EDeclaration" &&
                <DeclarationStatusForeign
                    mandateDetails={mandateDetails}
                    driverData={driverData}
                    handleScrollToDocumentUpload={handleScrollToDocumentUpload}
                />
            }

            {declarationStatus === "None" && declarationType !== "PaperDeclaration" && (
                <DeclarationStatusEmpty
                    driverData={driverData}
                    validateByCompanyPreference={
                        companyData?.settings.edeclaration?.driverValidationType
                    }
                    handleScrollToDocumentUpload={handleScrollToDocumentUpload}
                />
            )}
        </>
    );
};

export default DeclarationStatus;
