import { Tab } from "@headlessui/react";
import clsx from "clsx";
import {
    Car,
    CheckCircle,
    IdentificationCard,
    Scroll,
    Truck,
} from "phosphor-react";
import {
    ApiAlertSummaryResponse,
    DriverAlertSummary,
    GreyFleetAlertTitles,
} from "../../models/api/admin/dashboard";
import DashboardAlertsTabContents from "./DashboardAlertsTabContents";
import { useIsExtraLargeScreen } from "../../hooks/screenSizes";
import DropDownContentSelector from "./AlertsDropDownContentSelector";
import NewTabWithIcon from "../ReusableComponents/HeadlessUiTabWithIcon/NewTabWithIcon";
import { DashboardContentSections } from "../../pages/Dashboard";
import { useDashboardAlertActions } from "./useDashboardAlertActions";
import { type ApiCompanyResponse } from "../../models/api/admin/company";
import { type ApiDepartmentItem } from "../../models/api/admin/department";
import { useEffect, useState } from "react";

export type AlertsSectionContentType = {
    title: DashboardContentSections;
    icon: JSX.Element;
    content: JSX.Element;
    count: number;
};

export type DashboardAlertSectionProps = {
    alertsData?: {
        driverDeclarationAlerts: ApiAlertSummaryResponse;
        professionalDriverAlerts: ApiAlertSummaryResponse;
        licenceCheckAlerts: ApiAlertSummaryResponse;
        greyFleetAlerts: ApiAlertSummaryResponse;
        driverAlerts: ApiAlertSummaryResponse;
    };
    isLoadingAlerts: boolean;
    setSelectedContent: React.Dispatch<
        React.SetStateAction<DashboardContentSections>
    >;
    selectedCompany: ApiCompanyResponse | null;
    selectedDepartment: ApiDepartmentItem | null;
};

const DashboardAlertsSection: React.FC<DashboardAlertSectionProps> = ({
    alertsData,
    isLoadingAlerts,
    setSelectedContent,
    selectedCompany,
    selectedDepartment,
}) => {
    const isBiggerDisplay = useIsExtraLargeScreen();
    const {
        driverAlertAction,
        professionalDriverAlertAction,
        driverDeclarationAlertAction,
        licenceCheckAlertAction,
        greyFleetAlertAction,
    } = useDashboardAlertActions(
        selectedCompany?.companyId ?? null,
        selectedDepartment?.departmentId ?? null
    );

    function sumDriverCounts(
        summaryResponse: ApiAlertSummaryResponse | undefined
    ): number {
        let total = 0;

        if (summaryResponse && summaryResponse.immediateAlerts) {
            summaryResponse.immediateAlerts.map(() => (total += 1));
        }
        if (summaryResponse && summaryResponse.upcomingAlerts) {
            summaryResponse.upcomingAlerts.map(() => (total += 1));
        }
        return total;
    }

    const alertsSectionContent: AlertsSectionContentType[] = [
        {
            title: "All Drivers",
            icon: <IdentificationCard size={24} weight="duotone" />,
            count: sumDriverCounts(alertsData?.driverAlerts),
            content: (
                <DashboardAlertsTabContents
                    alertsData={alertsData?.driverAlerts}
                    isLoadingAlerts={isLoadingAlerts}
                    action={driverAlertAction}
                />
            ),
        },
        {
            title: "Professional Drivers",
            icon: <Truck size={24} weight="duotone" />,
            count: sumDriverCounts(alertsData?.professionalDriverAlerts),
            content: (
                <DashboardAlertsTabContents
                    alertsData={alertsData?.professionalDriverAlerts}
                    isLoadingAlerts={isLoadingAlerts}
                    action={professionalDriverAlertAction}
                />
            ),
        },
        {
            title: "DVLA Checks",
            icon: <CheckCircle size={24} weight="duotone" />,
            count: sumDriverCounts(alertsData?.licenceCheckAlerts),
            content: (
                <DashboardAlertsTabContents
                    alertsData={alertsData?.licenceCheckAlerts}
                    isLoadingAlerts={isLoadingAlerts}
                    action={licenceCheckAlertAction}
                />
            ),
        },
        {
            title: "Grey Fleet",
            icon: <Car size={24} weight="duotone" />,
            count: sumDriverCounts(alertsData?.greyFleetAlerts),
            content: (
                <DashboardAlertsTabContents<GreyFleetAlertTitles>
                    alertsData={alertsData?.greyFleetAlerts}
                    isLoadingAlerts={isLoadingAlerts}
                    action={greyFleetAlertAction}
                />
            ),
        },
        {
            title: "Driver Declarations",
            icon: <Scroll size={24} weight="duotone" />,
            count: sumDriverCounts(alertsData?.driverDeclarationAlerts),
            content: (
                <DashboardAlertsTabContents
                    alertsData={alertsData?.driverDeclarationAlerts}
                    isLoadingAlerts={isLoadingAlerts}
                    action={driverDeclarationAlertAction}
                />
            ),
        },
    ];

    return (
        <section className="flex-1 mt-4 2xl:border-r" about="alerts">
            {!isBiggerDisplay && alertsData && (
                <DropDownContentSelector
                    alertsSectionContent={alertsSectionContent}
                    setSelectedContent={setSelectedContent}
                />
            )}

            {isBiggerDisplay && (
                <Tab.Group>
                    <Tab.List
                        className={clsx(
                            "mb-4 flex min-w-max flex-row divide-x-2 divide-S2D-neutral-10 rounded-sm border-2 border-S2D-neutral-10"
                        )}
                    >
                        {alertsSectionContent.map((content) => (
                            <div
                                key={content.title}
                                className="flex-grow flex-shrink w-1/4"
                                onClick={() =>
                                    setSelectedContent(content.title)
                                }
                            >
                                <NewTabWithIcon
                                    icon={content.icon}
                                    title={content.title}
                                    count={content.count}
                                />
                            </div>
                        ))}
                    </Tab.List>
                    <Tab.Panels>
                        {alertsSectionContent.map((content) => (
                            <Tab.Panel key={`${content.title}-content`}>
                                {content.content}
                            </Tab.Panel>
                        ))}
                    </Tab.Panels>
                </Tab.Group>
            )}
        </section>
    );
};

export default DashboardAlertsSection;
