import clsx from "clsx";
import { Pencil, Trash } from "phosphor-react";
import { useState } from "react";
import useAuth from "../auth";
import { ApiCommentResponse } from "../models/api/admin/comment";
import { formatDateToString } from "../utils/formatDateToString";
import CommentForm from "./CommentForm";
import IconButton from "./IconButton";
import DeleteConfirmation from "./ReusableComponents/DeleteConfirmation";
import { CommentFormValues } from "./CommentForm/CommentFormValues";

type CommentCardProps = {
    comment: ApiCommentResponse;
    disableEditing?: boolean;
    onEdit: (
        commentId: string,
        dto: CommentFormValues,
        callback: (success: boolean) => void
    ) => Promise<void>;
    isEditing: boolean;
    onDelete: (
        commentId: string,
        callback: (success: boolean) => void
    ) => Promise<void>;
    isDeleting: boolean;
};

const CommentCard: React.FC<CommentCardProps> = ({
    comment,
    disableEditing = false,
    onEdit,
    isEditing,
    onDelete,
    isDeleting,
}) => {
    const { userDetails } = useAuth();
    const [isEditingOpen, setIsEditingOpen] = useState(false);
    const [deleteConfirmationDialogIsOpen, setDeleteConfirmationDialogIsOpen] =
        useState(false);

    const isCommenter = userDetails?.userId === comment.createdByUserId;

    const handleEditComment = (
        dto: CommentFormValues,
        callback: (success: boolean) => void
    ) => {
        onEdit(comment.id, dto, callback);
    };

    const handleDelete = () => {
        onDelete(comment.id, () => setDeleteConfirmationDialogIsOpen(false));
    };

    const handleCancelEditComment = () => {
        setIsEditingOpen(false);
    };

    const handleCancelDeleteComment = () => {
        setDeleteConfirmationDialogIsOpen(false);
    };

    return (
        <div className="mb-3">
            {!isEditing && (
                <div
                    className={clsx(
                        " rounded-sm border border-S2D-dark-green-50 bg-S2D-neutral-99",
                        disableEditing ? "px-2 py-2" : "px-3 py-4"
                    )}
                    key={comment.id}
                >
                    <div className="flex items-center gap-4">
                        <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                            <span className="mr-2">
                                {formatDateToString(
                                    new Date(comment.createdAt)
                                )}
                            </span>
                            <b>
                                {comment.createdByFirstName}{" "}
                                {comment.createdByLastName}
                            </b>
                        </div>
                        {isCommenter && !disableEditing && (
                            <div className="ml-auto flex flex-none items-center gap-4">
                                <IconButton
                                    label="Edit comment"
                                    icon={Pencil}
                                    onClick={() => setIsEditingOpen(true)}
                                />
                                <IconButton
                                    label="Delete comment"
                                    icon={Trash}
                                    onClick={() =>
                                        setDeleteConfirmationDialogIsOpen(true)
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <p className="text-sm">{comment.comment}</p>
                </div>
            )}
            {isEditingOpen && (
                <CommentForm
                    initialValues={comment}
                    onSubmit={handleEditComment}
                    onCancel={handleCancelEditComment}
                    isLoading={isEditing}
                />
            )}
            <DeleteConfirmation
                isOpen={deleteConfirmationDialogIsOpen}
                title="You are about to delete a comment!"
                isLoading={isDeleting}
                onClose={handleCancelDeleteComment}
                onSubmit={handleDelete}
            />
        </div>
    );
};

export default CommentCard;
