import { toast } from "react-toastify";
import _ from "lodash";

// Define the type for toast functions
type ToastFunction = (
    message: string | JSX.Element,
    disableAutoClose?: boolean
) => void;

export const errorToast: ToastFunction = (
    message,
    disableAutoClose = false
) => {
    // re-set toastify css variable here so it is declared after toastify
    // can't set in css file as toastify loads and declares after
    document.documentElement.style.setProperty(
        "--toastify-color-error",
        "#ba1a1a"
    );
    const content =
        typeof message === "string" ? (
            <p style={{ wordBreak: "break-word" }}>{message}</p>
        ) : (
            message
        );
    toast(content, {
        type: "error",
        autoClose: disableAutoClose ? false : 5000,
    });
};

export const successToast: ToastFunction = (
    message,
    disableAutoClose = false
) => {
    document.documentElement.style.setProperty(
        "--toastify-color-success",
        "#add53a"
    );
    const content =
        typeof message === "string" ? (
            <p style={{ wordBreak: "break-word" }}>{message}</p>
        ) : (
            message
        );
    toast(content, {
        type: "success",
        autoClose: disableAutoClose ? false : 5000,
    });
};

// Debouncing wrapper function
export const createDebouncedToast = (
    toastFunction: ToastFunction,
    delay = 2000
) => {
    return _.debounce(toastFunction, delay, { leading: true, trailing: false });
};

export const debouncedErrorToast = createDebouncedToast(errorToast);
export const debouncedSuccessToast = createDebouncedToast(successToast);
