import { DeclarationTableFilters } from "../api/admin/eDeclarations";
import useLocalStorage from "./useLocalStorage";

export const declarationTableFiltersDefaultValues = {
    fromDate: null,
    toDate: null,
    companyQuery: null,
    personalInformationQuery: null,
    declarationsQuery: null,
    licenceOriginQuery: null,
};

export const useDeclarationTableFiltersLocalStorage = () => {
    const [declarationTableFiltersStore, setDeclarationTableFiltersStore] =
        useLocalStorage<DeclarationTableFilters>(
            `DeclarationFilter`,
            declarationTableFiltersDefaultValues
        );

    return {
        declarationTableFiltersStore,
        setDeclarationTableFiltersStore,
    };
};
