import { useNavigate } from "react-router-dom";
import { appPaths } from "../../Routes";
import { useAppliedFilterLocalStorage } from "../../hooks/useAppliedFilterLocalStorage";
import {
    type AlertTitle,
    DriverAlertTitles,
    DriverDeclarationAlertTitles,
    LicenceCheckAlertTitles,
    GreyFleetAlertTitles,
} from "../../models/api/admin/dashboard";
import { type DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm/DriversListFiltersFormValues";
import {
    FilterGreyFleetDto,
    NonAddMatchAnalysis,
    type FilterDeclarationsDto,
    type FilterLicenceChecksDto,
    type FilterLicenceInformationDto,
} from "../../models/app/admin/driverFilters";
import { driverListFiltersDefaultValues } from "../DriverListFilters/DriversListFiltersForm/driversListFiltersDefaultValues";
import { useScrollToTableBool } from "../../hooks/useScrollToTable";
import { DeclarationType } from "../../models/app/eDeclarations";
import { errorToast } from "../../toast";

export const useDashboardAlertActions = (
    selectedCompany: string | null,
    selectedDepartment: string | null
) => {
    const { setAppliedFilter: setAppliedDriverFilter } =
        useAppliedFilterLocalStorage("drivers");
    const { setAppliedFilter: setAppliedProfessionalDriverFilter } =
        useAppliedFilterLocalStorage("professionalDrivers");
    const { setAppliedFilter: setAppliedGreyFleetDriverFilter } =
        useAppliedFilterLocalStorage("greyfleet");
    const navigate = useNavigate();

    const defaultFilterValues = driverListFiltersDefaultValues();
    const defaultProfessionalDriverFilterValues =
        driverListFiltersDefaultValues("professionalDrivers");

    const {
        licence: defaultLicenceValues,
        declarationsQuery: defaultDeclarationValues,
        dvlaQuery: defaultLicenceCheckValues,
        greyFleetQuery: defaultGreyFleetValues,
        driverAnalysis: defaultDriverAnalysisValues,
    } = defaultFilterValues;

    const { licence: defaultProfessionalDriversLicenceValues } =
        defaultProfessionalDriverFilterValues;

    const companyDepartmentValues: Pick<
        DriversListFiltersFormValues,
        "company"
    > = {
        company: {
            companyIds: selectedCompany ? [selectedCompany] : null,
            departmentIds: selectedDepartment ? [selectedDepartment] : null,
            employeeId: null,
            companyDashboard: defaultFilterValues.company.companyDashboard,
        },
    };

    const { setScrollToTable } = useScrollToTableBool();

    const setDriverAnalysisBoolean = (
        key: keyof NonAddMatchAnalysis
    ) => {
        setAppliedDriverFilter({
            filterId: "",
            filterFields: {
                ...defaultFilterValues,
                ...companyDepartmentValues,
                driverAnalysis: {
                    ...defaultDriverAnalysisValues,
                    nonAddMatch: {
                        ...defaultDriverAnalysisValues.nonAddMatch,
                        [key]: true,
                    },
                },
            },
        });
        setScrollToTable(true);
        navigate(appPaths.adminDrivers);
    };

    const setProfessionalDriverAnalysisBoolean = (
        key: keyof NonAddMatchAnalysis
    ) => {
        setAppliedProfessionalDriverFilter({
            filterId: "",
            filterFields: {
                ...defaultProfessionalDriverFilterValues,
                ...companyDepartmentValues,
                driverAnalysis: {
                    ...defaultDriverAnalysisValues,
                    nonAddMatch: {
                        ...defaultDriverAnalysisValues.nonAddMatch,
                        [key]: true,
                    },
                },
            },
        });
        setScrollToTable(true);
        navigate(appPaths.adminProfessionalDrivers);
    };

    const setDriverLicenceBoolean = (
        key: keyof FilterLicenceInformationDto
    ) => {
        setAppliedDriverFilter({
            filterId: "",
            filterFields: {
                ...defaultFilterValues,
                ...companyDepartmentValues,
                licence: {
                    ...defaultLicenceValues,
                    [key]: true,
                },
            },
        });
        setScrollToTable(true);
        navigate(appPaths.adminDrivers);
    };

    const setProfessionalDriverLicenceBoolean = (
        key: keyof FilterLicenceInformationDto
    ) => {
        setAppliedProfessionalDriverFilter({
            filterId: "",
            filterFields: {
                ...defaultProfessionalDriverFilterValues,
                ...companyDepartmentValues,
                licence: {
                    ...defaultProfessionalDriversLicenceValues,
                    [key]: true,
                },
            },
        });
        setScrollToTable(true);
        navigate(appPaths.adminProfessionalDrivers);
    };

    type FilterDeclarationsKey = keyof FilterDeclarationsDto;
    // array of key/values here since some filters require more than one
    const setDeclarationBoolean = (
        entries: { key: FilterDeclarationsKey; value: boolean }[],
        type?: DeclarationType
    ) => {
        const keysObject = Object.fromEntries(
            entries.map(({ key, value }) => [key, value])
        );
        const declarationType = type ? { declarationTypes: [type] } : null;

        setAppliedDriverFilter({
            filterId: "",
            filterFields: {
                ...defaultFilterValues,
                ...companyDepartmentValues,
                declarationsQuery: {
                    ...defaultDeclarationValues,
                    ...declarationType,
                    ...keysObject,
                },
            },
        });
        setScrollToTable(true);
        navigate(appPaths.adminDrivers);
    };

    const setLicenceCheckBoolean = (key: keyof FilterLicenceChecksDto) => {
        setAppliedDriverFilter({
            filterId: "",
            filterFields: {
                ...defaultFilterValues,
                ...companyDepartmentValues,
                dvlaQuery: {
                    ...defaultLicenceCheckValues,
                    [key]: true,
                },
            },
        });
        setScrollToTable(true);
        navigate(appPaths.adminDrivers);
    };

    const setGreyFleetBoolean = (
        key: keyof FilterGreyFleetDto,
        value?: boolean
    ) => {
        setAppliedGreyFleetDriverFilter({
            filterId: "",
            filterFields: {
                ...defaultFilterValues,
                ...companyDepartmentValues,
                greyFleet: true,
                greyFleetQuery: {
                    ...defaultGreyFleetValues,
                    [key]: value ?? true,
                },
            },
        });
        setScrollToTable(true);
        navigate(appPaths.adminGreyFleet);
    };

    const driverAlertAction = (
        alertTitle: AlertTitle
    ) => {
        switch (alertTitle) {
            case DriverAlertTitles.CpcExpired:
                setDriverLicenceBoolean("hasExpiredCpc");
                break;
            case DriverAlertTitles.HasExpiringCpc:
                setDriverLicenceBoolean("hasExpiringCpc");
                break;
            case DriverAlertTitles.EntitlementExpired:
                setDriverLicenceBoolean("hasExpiredEntitlements");
                break;
            case DriverAlertTitles.HasExpiringEntitlement:
                setDriverLicenceBoolean("hasExpiringEntitlements");
                break;
            case DriverAlertTitles.TachocardExpired:
                setDriverLicenceBoolean("hasExpiredTacho");
                break;
            case DriverAlertTitles.HasExpiringTachocard:
                setDriverLicenceBoolean("hasExpiringTacho");
                break;
            case DriverAlertTitles.PhotocardExpired:
                setDriverLicenceBoolean("hasExpiredPhotocard");
                break;
            case DriverAlertTitles.HasExpiringPhotocard:
                setDriverLicenceBoolean("hasExpiringPhotocard");
                break;
            case DriverAlertTitles.AddressNonmatch:
                setDriverAnalysisBoolean("nonAddressMatches");
                break;
            default:
                errorToast(`Unhandled alert title: ${alertTitle}`);
                break;
        }
    };

    const professionalDriverAlertAction = (
        alertTitle: AlertTitle
    ) => {
        switch (alertTitle) {
            case DriverAlertTitles.CpcExpired:
                setProfessionalDriverLicenceBoolean("hasExpiredCpc");
                break;
            case DriverAlertTitles.HasExpiringCpc:
                setProfessionalDriverLicenceBoolean("hasExpiringCpc");
                break;
            case DriverAlertTitles.EntitlementExpired:
                setProfessionalDriverLicenceBoolean("hasExpiredEntitlements");
                break;
            case DriverAlertTitles.HasExpiringEntitlement:
                setProfessionalDriverLicenceBoolean("hasExpiringEntitlements");
                break;
            case DriverAlertTitles.TachocardExpired:
                setProfessionalDriverLicenceBoolean("hasExpiredTacho");
                break;
            case DriverAlertTitles.HasExpiringTachocard:
                setProfessionalDriverLicenceBoolean("hasExpiringTacho");
                break;
            case DriverAlertTitles.PhotocardExpired:
                setProfessionalDriverLicenceBoolean("hasExpiredPhotocard");
                break;
            case DriverAlertTitles.HasExpiringPhotocard:
                setProfessionalDriverLicenceBoolean("hasExpiringPhotocard");
                break;
            case DriverAlertTitles.AddressNonmatch:
                setProfessionalDriverAnalysisBoolean("nonAddressMatches");
                break;
            default:
                errorToast(`Unhandled alert title: ${alertTitle}`);
                break;
        }
    };

    const driverDeclarationAlertAction = (
        alertTitle: AlertTitle
    ) => {
        switch (alertTitle) {
            case DriverDeclarationAlertTitles.ExpiredDeclarations:
                setDeclarationBoolean([
                    { key: "hasExpiredDeclaration", value: true },
                ]);
                break;
            case DriverDeclarationAlertTitles.PendingDeclarations:
                setDeclarationBoolean([
                    { key: "hasIncompleteDeclaration", value: true }], 
                    DeclarationType.EDeclaration
                );
                break;
            case DriverDeclarationAlertTitles.PaperDeclarationsRequested:
                setDeclarationBoolean(
                    [{ key: "hasRequestedDeclaration", value: true }],
                    DeclarationType.PaperDeclaration
                );
                break;
            case DriverDeclarationAlertTitles.PaperDeclarationsNotRequested:
                setDeclarationBoolean(
                    [{ key: "hasNotRequestedDeclaration", value: true }],
                    DeclarationType.PaperDeclaration
                );
                break;
            default:
                errorToast(`Unhandled alert title: ${alertTitle}`);
                break;
        }
    };

    const licenceCheckAlertAction = (alertTitle: AlertTitle) => {
        switch (alertTitle) {
            case LicenceCheckAlertTitles.InvalidLicences:
                setLicenceCheckBoolean("hasInvalidLicence");
                break;
            case LicenceCheckAlertTitles.FailedChecks:
                setLicenceCheckBoolean("hasFailedChecks");
                break;
            case LicenceCheckAlertTitles.ChecksDue:
                setLicenceCheckBoolean("hasCheckDueThisMonth");
                break;
            case LicenceCheckAlertTitles.ChecksScheduled:
                setLicenceCheckBoolean("hasCheckScheduled");
                break;
        }
    };

    const greyFleetAlertAction = (
        alertTitle: AlertTitle
    ) => {
        switch (alertTitle) {
            case GreyFleetAlertTitles.hasCompletedInvite:
                setGreyFleetBoolean("hasCompletedInvite", false);
                break;
            case GreyFleetAlertTitles.hasExpiringMot:
                setGreyFleetBoolean("hasExpiringMot");
                break;
            case GreyFleetAlertTitles.hasMotExpired:
                setGreyFleetBoolean("hasMotExpired");
                break;
            case GreyFleetAlertTitles.hasExpiringRoadTax:
                setGreyFleetBoolean("hasExpiringRoadTax");
                break;
            case GreyFleetAlertTitles.hasRoadTaxExpired:
                setGreyFleetBoolean("hasRoadTaxExpired");
                break;
            case GreyFleetAlertTitles.hasExpiringInsurancePolicy:
                setGreyFleetBoolean("hasExpiringInsurancePolicy");
                break;
            case GreyFleetAlertTitles.hasInsurancePolicyExpired:
                setGreyFleetBoolean("hasInsurancePolicyExpired");
                break;
            case GreyFleetAlertTitles.hasInsuranceToUpload:
                setGreyFleetBoolean("hasInsuranceCertificateToUpload");
                break;
            case GreyFleetAlertTitles.hasUnverifiedInsurance:
                setGreyFleetBoolean("hasInsuranceUnverified");
                break;
            case GreyFleetAlertTitles.hasBreakdownCoverExpiring:
                setGreyFleetBoolean("hasBreakdownCoverExpiring");
                break;
            case GreyFleetAlertTitles.hasNoOrExpiredBreakdownCover:
                setGreyFleetBoolean("hasNoOrExpiredBreakdownCover");
                break;
            default:
                errorToast(`Unhandled alert title: ${alertTitle}`);
                break;
        }
    };

    return {
        driverAlertAction,
        driverDeclarationAlertAction,
        licenceCheckAlertAction,
        greyFleetAlertAction,
        professionalDriverAlertAction,
    };
};
