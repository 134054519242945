import {
    ApiCompanyResponse,
    CompanyStatusEnum,
} from "../../../../../models/api/admin/company";
import { formatDateToString } from "../../../../../utils/formatDateToString";

interface DisabledCompanyViewProps {
    companyData: ApiCompanyResponse | undefined;
}

export default function DisabledCompanyView({
    companyData,
}: DisabledCompanyViewProps) {
    const { createdAt, statusId, comment, createdByUser } =
        companyData?.lastStatusLog || {};
    const { firstName, lastName } = createdByUser || {};

    const dateDisabled = createdAt
        ? formatDateToString(new Date(createdAt))
        : "(No date available)";

    const userWhoDisabled =
        firstName && lastName ? `${firstName} ${lastName}` : "Unknown user";
    let disabledReasonString = "";
    if (statusId === CompanyStatusEnum.DisabledNonPayment)
        disabledReasonString = "for non-payment";
    if (statusId === CompanyStatusEnum.DisabledPermanent)
        disabledReasonString = "permanently";

    return (
        <div>
            <div className="rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-3 font-bold">
                {`Disabled ${disabledReasonString} on ${dateDisabled}`}
            </div>
            <div className=" mt-8 rounded bg-S2D-neutral-95 p-3">
                <p className="font-bold">
                    {dateDisabled} {userWhoDisabled}
                </p>
                <p>{comment !== "" ? comment : "No comment provided"}</p>
            </div>
        </div>
    );
}
