import { Menu } from "@headlessui/react";
import { FileCsv, Trash } from "phosphor-react";
import clsx from "clsx";
import CaretDropdown from "../ReusableComponents/CaretDropdown";

type ReportActionsDropdownProps = {
    handleDownloadReport: () => void;
    isReportDownloading: boolean;
    handleDeleteReport: () => void;
    isDeletingReport: boolean;
};

const ReportActionsDropdown: React.FC<ReportActionsDropdownProps> = ({
    handleDownloadReport,
    isReportDownloading,
    handleDeleteReport,
    isDeletingReport,
}) => {
    return (
        <div className="w-full md:w-fit">
            <Menu>
                {({ open }) => (
                    <div className="relative">
                        <Menu.Button
                            className={clsx(
                                "w-full",
                                isReportDownloading || isDeletingReport
                                    ? "cursor-wait opacity-40"
                                    : "cursor-pointer"
                            )}
                            disabled={isReportDownloading || isDeletingReport}
                        >
                            <CaretDropdown
                                open={open}
                                content={
                                    <div className="flex h-[48px] items-center whitespace-nowrap px-4">
                                        Actions
                                    </div>
                                }
                                className="border-S2D-neutral-10 bg-S2D-neutral-100"
                            />
                        </Menu.Button>

                        <Menu.Items
                            className={`z-1 absolute top-[100%] right-0 flex w-fit flex-col
                                        whitespace-nowrap rounded bg-white shadow-mui`}
                        >
                            <Menu.Item>
                                {({ active, disabled }) => (
                                    <div
                                        className={clsx(
                                            "flex cursor-pointer items-center gap-2 py-3 px-6",
                                            active && "bg-S2D-light-green-80",
                                            disabled &&
                                                "cursor-wait text-S2D-neutral-20"
                                        )}
                                        onClick={handleDownloadReport}
                                    >
                                        <FileCsv weight="fill" size={20} />{" "}
                                        Download CSV
                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active, disabled }) => (
                                    <div
                                        className={clsx(
                                            "flex cursor-pointer items-center gap-2 py-3 px-6",
                                            active && "bg-S2D-light-green-80",
                                            disabled &&
                                                "cursor-wait text-S2D-neutral-20"
                                        )}
                                        onClick={handleDeleteReport}
                                    >
                                        <Trash weight="fill" size={20} /> Delete
                                        report
                                    </div>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </div>
                )}
            </Menu>
        </div>
    );
};

export default ReportActionsDropdown;
