import EditableContentBlock from "../../../EditableContentBlock";
import { type EditCompanyFormValues } from "../../EditCompanyFormValues";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Controller } from "react-hook-form";
import SubmitOrCancelButtonBar from "../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import FormInputMessage from "../../../FormInputError";
import { useEditCompanyCommunicationSettingsForm } from "./useEditCompanyCommunicationSettingsForm";
import LoadingMessage from "../../../ReusableComponents/LoadingMessage/LoadingMessage";
import { type CommunicationSettings } from "../../../../models/app/admin/company";

interface EditCompanyCommunicationSettingsFormProps {
    commsSettings: CommunicationSettings | undefined;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetForm: () => void
    ) => Promise<void>;
    canEdit: boolean;
}

export default function EditCompanyCommunicationSettingsForm({
    commsSettings,
    handleEditCompany,
    canEdit,
}: EditCompanyCommunicationSettingsFormProps) {
    const { methods, allowedCommunicationsString, displayedValues, isLoading } =
        useEditCompanyCommunicationSettingsForm(commsSettings);

    const { reset, handleSubmit, control, formState } = methods;

    return (
        <EditableContentBlock
            onClosed={() => reset(displayedValues)}
            title="Communication Settings"
            disableEdit={!canEdit}
        >
            {({ isEdit, setIsEdit }) => (
                <div className="px-6 py-8">
                    {!isEdit && (
                        <div>
                            {isLoading.current ? (
                                <LoadingMessage />
                            ) : (
                                allowedCommunicationsString
                            )}
                        </div>
                    )}
                    {isEdit && editableContent(setIsEdit)}
                </div>
            )}
        </EditableContentBlock>
    );

    function editableContent(setIsEdit: (isEdit: boolean) => void) {
        type CommunicationOption = {
            label: string;
            value: keyof CommunicationSettings;
        };
        const communicationOptions: CommunicationOption[] = [
            { label: "Email", value: "permitsEmailCorrespondence" },
            { label: "Phone call", value: "permitsTelephoneCorrespondence" },
            { label: "Text message", value: "permitsSmsCorrespondence" },
            { label: "Post", value: "permitsPostalCorrespondence" },
        ];

        const onSubmit = (data: CommunicationSettings) => {
            const newCompanySettings = { settings: { correspondence: data } };
            isLoading.current = true;
            handleEditCompany(newCompanySettings, () => resetAndCloseForm());
        };

        const resetAndCloseForm = () => {
            reset(); //reset form to initial values
            setIsEdit(false);
            // Timing out, in-case the hook's useEffect is not triggered
            // (0ms as the component will then update when its props update)
            setTimeout(() => (isLoading.current = false), 0);
        };

        return (
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col items-start space-y-2">
                        <FormGroup>
                            {communicationOptions.map(({ label, value }) => (
                                <FormControlLabel
                                    key={value}
                                    control={
                                        <Controller
                                            name={value}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    checked={field.value}
                                                    {...field}
                                                    color="default"
                                                />
                                            )}
                                        />
                                    }
                                    label={label}
                                />
                            ))}
                            <FormInputMessage
                                error={
                                    formState.errors
                                        .permitsTelephoneCorrespondence ||
                                    formState.errors
                                        .permitsEmailCorrespondence ||
                                    formState.errors
                                        .permitsPostalCorrespondence ||
                                    formState.errors.permitsSmsCorrespondence
                                }
                            />
                        </FormGroup>
                    </div>
                    <div className="pt-7" />
                    <SubmitOrCancelButtonBar
                        isLoading={isLoading.current}
                        onCancel={resetAndCloseForm}
                    />
                </form>
            </>
        );
    }
}
