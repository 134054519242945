import { useState } from "react";
import { useGetStaff, useStaff } from "../../../api/safe2Drive/staff";
import { ValidStaffMenuItems } from "../../../components/Safe2DriveStaff/StaffTable";
import useDebounceValue from "../../../hooks/useDebounceValue";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { S2DStaffMember } from "../../../models/api/safe2DriveAdmin/staff";
import { errorToast, successToast } from "../../../toast";
import { UserStatus } from "../../../models/shared/statuses";
import { EditStaffMemberDto } from "../../../models/app/safe2DriveAdmin/staff";
import { SEARCH_BAR_DEBOUNCE_MS } from "../../../constants/filterConstants";

export const useStaffPage = () => {
    const [searchQuery, setSearchQuery] = useState<string>("");
    const query = useDebounceValue(searchQuery, SEARCH_BAR_DEBOUNCE_MS);
    const [statusFilter, setStatusFilter] = useState<UserStatus | null>(null);

    const [pageNumber, setPageNumber] = useLocalStorage<number>(
        "staffTablePageNumber",
        1
    );
    const [pageSize, setPageSize] = useLocalStorage<number>(
        "staffTablePageSize",
        25
    );

    const { staffData, isLoadingStaff, staffError, refetchStaff } = useGetStaff(
        pageNumber,
        pageSize,
        query,
        statusFilter ?? undefined
    );
    const { deleteStaffMember, isDeletingStaffMember } = useStaff();

    const [addStaffModalOpen, setAddStaffModalOpen] = useState(false);
    const [editStaffModalOpen, setEditStaffModalOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<S2DStaffMember | null>(
        null
    );
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] =
        useState(false);

    const {
        editStaffMember,
        isEditingStaffMember,
        editFileSafeClaim,
        resetPassword,
        isResettingPassword,
    } = useStaff();

    const handleEditFileSafeClaim = async (value: boolean) => {
        const claimHasChanged = value !== selectedUser?.claims.hasFileSafeClaim;
        if (!claimHasChanged || !selectedUser?.userId) return;

        const response = await editFileSafeClaim({
            userId: selectedUser?.userId,
            isFileSafeAdministrator: value,
        });

        if (!response.success) {
            errorToast(
                "Failed to update fileSafe claim, please try again later."
            );
        }
    };

    const handleSubmit = async (
        values: EditStaffMemberDto,
        callback?: () => void
    ) => {
        if (!selectedUser?.userId) return;
        const response = await editStaffMember(selectedUser.userId, values);

        if (!response.success) {
            errorToast("Failed to edit staff member, please try again later.");
        } else {
            handleEditFileSafeClaim(values.claims.hasFileSafeClaim);
            successToast("Successfully edited staff member.");
            setEditStaffModalOpen(false);
            refetchStaff();
            callback && callback();
        }
    };

    const handleToggleStaffStatus = async (
        status: UserStatus,
        staffMember: S2DStaffMember
    ) => {
        const userWithNewStatus = { ...staffMember, status: status };
        const response = await editStaffMember(
            userWithNewStatus.userId,
            userWithNewStatus
        );

        if (!response.success) {
            errorToast("Failed to edit staff status, please try again later.");
        } else {
            successToast("Successfully edited staff status.");
            setSelectedUser(userWithNewStatus);
            refetchStaff();
        }
    };

    const handleMenuSelect = (id: string, type: ValidStaffMenuItems) => {
        if (!staffData) return;

        const staffMember = staffData.items.find(
            (member) => member.userId === id
        );
        if (!staffMember) {
            errorToast("Staff member does not exist");
            return;
        }

        setSelectedUser(staffMember);

        switch (type) {
            case "edit":
                setEditStaffModalOpen(true);
                break;
            case "delete":
                setDeleteDialogOpen(true);
                break;
            case "disable":
                handleToggleStaffStatus(UserStatus.Disabled, staffMember);
                break;
            case "enable":
                handleToggleStaffStatus(UserStatus.Live, staffMember);
                break;
            default:
                break;
        }
    };

    const handleDeleteStaffMember = async () => {
        if (!selectedUser?.userId) return;
        const response = await deleteStaffMember(selectedUser.userId);

        if (!response.success) {
            errorToast(
                "Failed to delete staff member, please try again later."
            );
        } else {
            setDeleteDialogOpen(false);
            successToast("Successfully deleted staff member.");

            // timeout here as closing both dialogs at once causes an error I've been unable to fix using ids
            setTimeout(() => {
                setEditStaffModalOpen(false);
                setSelectedUser(null);
                refetchStaff();
            }, 1000);
        }
    };

    const handleResetPassword = async () => {
        if (!selectedUser?.userId) return;
        const response = await resetPassword({ userId: selectedUser.userId });

        if (!response.success) {
            errorToast("Failed to reset password, please try again later.");
        } else {
            setResetPasswordDialogOpen(false);
            successToast("Reset password email sent.");
        }
    };

    return {
        searchQuery,
        setSearchQuery,
        statusFilter,
        setStatusFilter,
        addStaffModalOpen,
        setAddStaffModalOpen,
        editStaffModalOpen,
        setEditStaffModalOpen,
        selectedUser,
        setSelectedUser,
        deleteDialogOpen,
        setDeleteDialogOpen,
        resetPasswordDialogOpen,
        setResetPasswordDialogOpen,
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
        staffData,
        isLoadingStaff,
        staffError,
        refetchStaff,
        handleMenuSelect,
        handleEditFileSafeClaim,
        handleSubmit,
        handleDeleteStaffMember,
        handleResetPassword,
        handleToggleStaffStatus,
        isEditingStaffMember,
        isDeletingStaffMember,
        isResettingPassword,
    };
};
