import { ScheduleMonthlyReportDto } from "../../models/app/admin/reportSetting";
import { ApiReportSettingResponse } from "../../models/api/admin/reportSetting";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

const useDownloadMonthlyReport = () =>
    useS2DApiFetcher<null, ArrayBuffer, { companyMonthlyReportId: string }>(
        "GET",
        ({ companyMonthlyReportId }) => ({
            url: `api/scheduled-reports/${companyMonthlyReportId}`,
            body: null,
            altToken: undefined,
            contentType: "text/csv",
        }),
        [],
        true
    );

const useScheduleMonthlyReport = () =>
    useS2DApiFetcher<
        ScheduleMonthlyReportDto,
        ApiReportSettingResponse,
        { dto: ScheduleMonthlyReportDto }
    >(
        "POST",
        ({ dto }) => ({
            url: `api/scheduled-reports/schedule-monthly`,
            body: dto,
        }),
        [],
        true
    );

const useDeleteScheduledReport = () =>
    // eslint-disable-next-line @typescript-eslint/ban-types
    useS2DApiFetcher<{}, null, void>(
        "DELETE",
        () => ({
            url: `api/scheduled-reports`,
            body: {},
            contentType: "application/json",
        }),
        [],
        true
    );

export const useCompanyReportSettings = (shouldFetchReportSettings = true) => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiReportSettingResponse>(
            shouldFetchReportSettings
                ? "/api/scheduled-reports/schedule-monthly/get-latest-info"
                : null
        );

    const [getMonthlyReport, isGettingMonthlyReport] =
        useDownloadMonthlyReport();
    const [scheduleMonthlyReport, isSchedulingMonthlyReport] =
        useScheduleMonthlyReport();
    const [deletedScheduledReport, isDeletingScheduledReport] =
        useDeleteScheduledReport();

    return {
        companyReportSettingsData: data,
        companyReportSettingsError: error,
        isLoadingCompanyReportSettings: isLoading,
        refetchMonthlyReportSettings: refetch,
        getMonthlyReport: async (companyMonthlyReportId: string) =>
            await getMonthlyReport({ companyMonthlyReportId }),
        isGettingMonthlyReport,
        scheduleMonthlyReport: async (dto: ScheduleMonthlyReportDto) =>
            await scheduleMonthlyReport({ dto }),
        isSchedulingMonthlyReport,
        deleteScheduledReport: deletedScheduledReport,
        isDeletingScheduledReport,
    };
};
