import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
    EditCompanyUserFormValidationSchema,
    EditCompanyUserFormValues,
    editCompanyUserFromDefaultValues,
} from "../EditCompanyUserFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiCompanyUserResponse } from "../../../models/api/admin/companyUser";

const validationSchema = yup
    .object()
    .shape(EditCompanyUserFormValidationSchema);

const useEditCompanyUserForm = (
    selectedUser: ApiCompanyUserResponse,
    onSubmit: (value: EditCompanyUserFormValues, callback?: () => void) => void
) => {
    const methods = useForm<EditCompanyUserFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: editCompanyUserFromDefaultValues(selectedUser),
    });

    const handleFormSubmit = (data: EditCompanyUserFormValues) => {
        onSubmit(data, () => console.log("callback"));
    };

    return {
        methods,
        handleFormSubmit,
    };
};

export default useEditCompanyUserForm;
