export type ApiInvoiceReferenceNumberItem = {
    value: string;
    usedAt: string;
    associatedInvoiceNumber: string;
    usedState: string;
    departmentName: string;
    type: string;
};

export type ApiInvoiceReferenceNumberResponse = {
    items: ApiInvoiceReferenceNumberItem[];
    totalCount: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
};

export enum ApiInvoiceReferenceType {
    Reference = "Reference",
    PurchaseOrder = "PurchaseOrder",
}