import * as yup from "yup";

export const SaveEditFilterValidationSchema = {
    name: yup.string().nullable().required(),
    userIds: yup.array().nullable().notRequired(),
};

export type SaveEditFilterValues = {
    name: string;
    filterJSON: string;
    userIds?: string[];
};
