import { useParams } from "react-router-dom";
import { type CompanyStatusFilters } from "../../models/api/admin/company";
import {
    CompanyStatsTabs,
    type ApiCompanyStatsResponse,
} from "../../models/api/admin/companyStats";
import CompanyBreakdown from "../CompanyBreakdown";
import { type AnalysisComponentContentType } from "../ReusableComponents/AnalysisComponent/AnalysisComponent";
import CompanyDashboardChecksThisMonthTab from "./CompanyDashboardChecksThisMonthTab";
import CompanyDashboardDeclarationsTab from "./CompanyDashboardDeclarationsTab";
import CompanyDashboardDriversTab from "./CompanyDashboardDriversTab";
import CompanyDashboardGreyFleetDriversTab from "./CompanyDashboardGreyFleetDriversTab";
import CompanyDashboardUpcomingChecksTab from "./CompanyDashboardUpcomingChecks";
import { useCompanyDashboardActions } from "./useCompanyDashboardActions";
import { DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm";

type PageParams = {
    id?: string;
};

export const companyDashboardContents = (
    companyStatsData?: ApiCompanyStatsResponse,
    setStatusFilter?: (value: CompanyStatusFilters | null) => void,
    tableRef?: React.MutableRefObject<HTMLDivElement | null>,
    removeSummary?: boolean,
    componentRef?: string,
    handleFetchDrivers?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>
): AnalysisComponentContentType<CompanyStatsTabs>[] => {
    const { id } = useParams<PageParams>();
    const shouldRemoveSummary = id || removeSummary;

    const {
        allCompaniesStatsActions,
        companyDriversStatsActions,
        companyGreyfleetDriversStatsActions,
        companyDeclarationsQueryStatsActions,
        companyChecksThisMonthStatsActions,
        companyUpcomingChecksStatsActions,
    } = useCompanyDashboardActions(
        setStatusFilter,
        tableRef,
        id,
        componentRef,
        handleFetchDrivers
    );

    const clientSummaryTab = {
        title: CompanyStatsTabs.Company,
        content: (
            <CompanyBreakdown
                data={companyStatsData?.allCompaniesStats ?? undefined}
                onLinkClick={allCompaniesStatsActions}
            />
        ),
    };

    const driverTab = {
        title: CompanyStatsTabs.Drivers,
        content: (
            <CompanyDashboardDriversTab
                data={companyStatsData?.companyDriversStats ?? undefined}
                onLinkClick={companyDriversStatsActions}
            />
        ),
    };

    const greyFleetDriverTab = {
        title: CompanyStatsTabs.GreyFleet,
        content: (
            <CompanyDashboardGreyFleetDriversTab
                data={companyStatsData?.companyGreyfleetStats ?? undefined}
                onLinkClick={companyGreyfleetDriversStatsActions}
            />
        ),
    };

    const declarationsTab = {
        title: CompanyStatsTabs.Declarations,
        content: (
            <CompanyDashboardDeclarationsTab
                data={companyStatsData?.companyDeclarationsStats ?? undefined}
                onLinkClick={companyDeclarationsQueryStatsActions}
            />
        ),
    };

    const checksThisMonthTab = {
        title: CompanyStatsTabs.CurrentChecks,
        content: (
            <CompanyDashboardChecksThisMonthTab
                data={companyStatsData?.companyCurrentChecksStats ?? undefined}
                onLinkClick={companyChecksThisMonthStatsActions}
            />
        ),
    };

    const upcomingChecksTab = {
        title: CompanyStatsTabs.UpcomingChecks,
        content: (
            <CompanyDashboardUpcomingChecksTab
                data={companyStatsData?.companyUpcomingChecksStats ?? undefined}
                onLinkClick={companyUpcomingChecksStatsActions}
            />
        ),
    };

    const allClientsContent: AnalysisComponentContentType<CompanyStatsTabs>[] =
        [
            clientSummaryTab,
            driverTab,
            greyFleetDriverTab,
            declarationsTab,
            checksThisMonthTab,
            upcomingChecksTab,
        ];

    const clientProfileContent: AnalysisComponentContentType<CompanyStatsTabs>[] =
        [
            driverTab,
            greyFleetDriverTab,
            declarationsTab,
            checksThisMonthTab,
            upcomingChecksTab,
        ];

    // only need to show all if we're specifically downloading all company stats data
    if (shouldRemoveSummary) {
        return clientProfileContent;
    } else {
        return allClientsContent;
    }
};
