import { Checkbox } from "@mui/material";
import clsx from "clsx";
import { type TableMenuItem } from "./TableMenu";
import { useDraggableTableHeaders } from "./tableUtils";
import TableHideContentMenu from "./TableHideContentMenu";
import {
    HiddenCols,
    TableCheckbox,
    TableContent,
    TableHeader,
} from "./tableTypes";
import { ArrowCircleDown, ArrowCircleUp, Circle } from "phosphor-react";
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import { findHiddenTableContent } from "../../../utils/tableUtils";
import TableRow from "./TableRow";
import { useEffect, useState } from "react";

export type TableProps<ContentType> = {
    tableHeaders: TableHeader[];
    setTableHeaders: (
        value: TableHeader[] | ((val: TableHeader[]) => TableHeader[])
    ) => void;
    checkedBoxes?: TableCheckbox<ContentType>[];
    setCheckedBoxes?: (value: TableCheckbox<ContentType>[]) => void;
    tableContents?: TableContent<ContentType>[];
    hiddenCols?: HiddenCols[];
    setHiddenCols?: (
        value: HiddenCols[] | ((val: HiddenCols[]) => HiddenCols[])
    ) => void;
    menuItems?: (data: ContentType) => TableMenuItem[];
    menuGroups?: string[];
    sortOrder?: "asc" | "desc";
    orderBy?: string;
    orderByOptions?: string[];
    pageNumber?: number;
    handleSortColumn?: (orderBy?: string) => void;
    isLoading?: boolean;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
    exposeInlineItems?: boolean;
};

const Table = <ContentType,>({
    tableHeaders,
    setTableHeaders,
    checkedBoxes,
    setCheckedBoxes,
    tableContents,
    hiddenCols,
    setHiddenCols,
    menuItems,
    menuGroups,
    sortOrder,
    orderBy,
    orderByOptions,
    handleSortColumn,
    isLoading,
    tableRef,
    exposeInlineItems,
}: TableProps<ContentType>) => {
    const { handleDragStart, handleDragEnter, handleDragEnd } =
        useDraggableTableHeaders(tableHeaders, setTableHeaders);

    const canOrderBy = (orderBy?: string) => {
        if (!orderBy) return false;
        if (orderByOptions?.includes(orderBy)) return true;
        return false;
    };

    const orderByIcon = (headerOrderBy?: string) => {
        // sometimes there will be an "orderBy" but no "sortOrder" attribute
        // added in new icons to handle these cases
        if (!orderBy) return;
        if (orderBy === headerOrderBy && sortOrder === "desc")
            return <ArrowCircleDown size={18} weight="fill" color="white" />;
        else if (orderBy === headerOrderBy && sortOrder === "asc")
            return <ArrowCircleUp size={18} weight="fill" color="white" />;
        else if (orderBy === headerOrderBy)
            return <Circle size={16} weight="fill" color="white" />;
        else return <Circle size={16} weight="bold" color="white" />;
    };

    const hideCheckboxes = !setCheckedBoxes || !checkedBoxes;

    const handleCheckBoxes = (id: string, data: ContentType) => {
        if (!setCheckedBoxes || !checkedBoxes) return;

        // if item isn't already checked, add it in,
        // otherwise remove it.
        const valueIsChecked = checkedBoxes
            ? checkedBoxes.find((value) => value.id === id)
            : undefined;

        if (!valueIsChecked) {
            setCheckedBoxes([
                ...checkedBoxes,
                {
                    id: id,
                    data: data,
                },
            ]);
        } else {
            const newArray = checkedBoxes.filter((value) => value.id !== id);
            setCheckedBoxes(newArray);
        }
    };

    const [areAllBoxesChecked, setAreAllBoxesChecked] = useState(false);

    const handleCheckAllBoxes = (value: boolean) => {
        if (!setCheckedBoxes || !checkedBoxes) return;
        if (!value) {
            setCheckedBoxes([]);
            setAreAllBoxesChecked(false);
        } else {
            const allRows: TableCheckbox<ContentType>[] | undefined =
                tableContents?.map((row) => ({
                    id: row.rowId,
                    data: row.rowData,
                }));
            setCheckedBoxes(allRows ?? []);
            setAreAllBoxesChecked(true);
        }
    };

    useEffect(() => {
        if (!checkedBoxes) return;
        if (checkedBoxes.length === tableContents?.length) return;
        // when page data changes, clear "select all" checkbox
        setAreAllBoxesChecked(false);
    });

    return (
        <>
            <div
                className="grid place-items-end overflow-x-auto rounded-sm border border-S2D-neutral-80"
                ref={tableRef}
            >
                <table className="w-full min-w-max divide-y-[2px] rounded-sm bg-[#474741] ">
                    <thead>
                        <tr>
                            {hideCheckboxes ? (
                                // replacing the styling of the checkbox
                                <td
                                    className="m-auto py-6"
                                    key="hidden-checkboxes"
                                />
                            ) : (
                                <th
                                    scope="col"
                                    key="checkboxHeader"
                                    className={clsx(
                                        "m-auto w-fit rounded-sm bg-[#474741] text-left text-sm font-semibold text-S2D-text-menu",
                                        "flex justify-start"
                                    )}
                                >
                                    <Checkbox
                                        name="selectAllCheckbox"
                                        onChange={(event) =>
                                            handleCheckAllBoxes(
                                                event.target.checked
                                            )
                                        }
                                        checked={areAllBoxesChecked}
                                        sx={{
                                            color: ["#FFFFFF"],
                                            "&.Mui-checked": {
                                                color: ["#FFFFFF"],
                                            },
                                        }}
                                    />
                                </th>
                            )}
                            {tableHeaders
                                .filter(
                                    (header) =>
                                        findHiddenTableContent(
                                            header.id,
                                            hiddenCols
                                        ) === false
                                )
                                .map((header: TableHeader) => (
                                    <th
                                        scope="col"
                                        key={`${header.id}Header`}
                                        className={clsx(
                                            "m-auto rounded-sm bg-[#474741] px-2 text-left text-sm font-semibold text-[#FFFFFF]",
                                            "hover:cursor-pointer"
                                        )}
                                        draggable
                                        onDragStart={(e) =>
                                            handleDragStart(e, header.id)
                                        }
                                        onDragEnter={(e) =>
                                            handleDragEnter(e, header.id)
                                        }
                                        onDragEnd={() => handleDragEnd()}
                                    >
                                        <div
                                            className="flex justify-between gap-4 pr-2"
                                            onClick={() => {
                                                canOrderBy(header.orderBy) &&
                                                    handleSortColumn &&
                                                    handleSortColumn(
                                                        header.orderBy
                                                    );
                                            }}
                                            key={`${header.id}HeaderClickHandler`}
                                        >
                                            {header.title}
                                            {canOrderBy(header.orderBy) && (
                                                <>
                                                    {orderByIcon(
                                                        header.orderBy
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </th>
                                ))}
                            {hiddenCols && setHiddenCols && menuGroups && (
                                <th
                                    scope="col"
                                    key="menuHeader"
                                    className={clsx(
                                        "m-auto max-w-fit rounded-sm bg-[#474741] text-left text-sm font-semibold text-[#FFFFFF]"
                                    )}
                                >
                                    <TableHideContentMenu
                                        hiddenCols={hiddenCols}
                                        setHiddenCols={setHiddenCols}
                                        tableHeaders={tableHeaders}
                                        menuGroups={menuGroups}
                                    />
                                </th>
                            )}
                        </tr>
                    </thead>
                    {!isLoading && !!tableContents?.length && (
                        <tbody className="m-auto divide-y-[1px] divide-[#C8C7BF] bg-white">
                            {tableContents.map((item) => (
                                <tr
                                    key={`${item.rowId}TableRow`}
                                    className="even:bg-S2D-dark-green-70.1"
                                >
                                    <TableRow
                                        rowItem={item}
                                        tableHeaders={tableHeaders}
                                        hiddenCols={hiddenCols}
                                        menuGroups={menuGroups}
                                        menuItems={menuItems}
                                        checkedBoxes={checkedBoxes ?? []}
                                        hideCheckboxes={hideCheckboxes}
                                        handleCheckBoxes={handleCheckBoxes}
                                        exposeInlineItems={exposeInlineItems}
                                    />
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>
            </div>
            {isLoading && <LoadingMessage />}
        </>
    );
};

export default Table;
