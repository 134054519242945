import { CompanyRiskProfile } from "../models/app/admin/company";

export const driverPointsColor = (
    points: number | null,
    companyRiskProfiles?: CompanyRiskProfile[]
) => {
    if (points === null || points === undefined) return "bg-S2D-neutral-70";

    const severityLevelOne = 0;
    const severityLevelTwo = companyRiskProfiles
        ? companyRiskProfiles[1].lowerThreshold
        : 3;
    const severityLevelThree = companyRiskProfiles
        ? companyRiskProfiles[2].lowerThreshold
        : 7;

    if (points >= severityLevelOne && points <= severityLevelTwo - 1) {
        return "bg-S2D-success-80";
    } else if (points >= severityLevelTwo && points <= severityLevelThree - 1) {
        return "bg-S2D-warning-90";
    } else if (points >= severityLevelThree) {
        return "bg-S2D-error-40 text-S2D-neutral-99";
    } else {
        return "bg-S2D-neutral-70";
    }
};
