import {
    CheckCircle,
    IdentificationCard,
    Warning,
    WarningOctagon,
} from "phosphor-react";
import { type CompanyDriversStats } from "../../models/api/admin/companyStats";
import StatisticSummaryBox from "../StatisticSummaryBox";
import { type CompanyDriversDto } from "../../models/app/admin/companyDashboard";
import useAuth from "../../auth";
import { isS2dAdminOrMember, hasClientManagementAccess } from "../../auth/userAccessHelpers";

type CompanyDashboardDriversTabProps = {
    data?: CompanyDriversStats;
    onLinkClick?: (key: keyof CompanyDriversDto) => void;
};

const CompanyDashboardDriversTab: React.FC<CompanyDashboardDriversTabProps> = ({
    data,
    onLinkClick,
}) => {
    const { decodedToken } = useAuth();
    const s2dAdminOrMember = isS2dAdminOrMember(decodedToken);
    const isClientManagement = hasClientManagementAccess(decodedToken);
    const canViewDeletedAddedDrivers = s2dAdminOrMember;

    return (
        <div className="flex w-full flex-col gap-4">
            <StatisticSummaryBox
                icon={IdentificationCard}
                iconColor="bg-S2D-neutral-100"
                label="total number of drivers"
                statistic={data?.totalDrivers}
                // onLinkClick={onLinkClick()}
            />
            <div className={`${isClientManagement ? "flex w-full flex-col gap-4" : "grid grid-cols-1 gap-4 xl:grid-cols-3"}`}>
                <StatisticSummaryBox
                    icon={CheckCircle}
                    label="drivers checked"
                    statistic={data?.checkedDrivers}
                    onLinkClick={() =>
                        onLinkClick ? onLinkClick("driversChecked") : undefined
                    }
                />
                {canViewDeletedAddedDrivers && (
                    <StatisticSummaryBox
                        icon={Warning}
                        label="drivers added"
                        statistic={data?.driversAdded}
                        onLinkClick={() =>
                            onLinkClick
                                ? onLinkClick("driversAdded")
                                : undefined
                        }
                    />
                )}
                {canViewDeletedAddedDrivers && (
                    <StatisticSummaryBox
                        icon={WarningOctagon}
                        label="drivers removed"
                        statistic={data?.driversRemoved}
                        onLinkClick={() =>
                            onLinkClick
                                ? onLinkClick("driversRemoved")
                                : undefined
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default CompanyDashboardDriversTab;
