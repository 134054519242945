export const queryParamConnector = (pathArray: string[]) => {
    if (pathArray.length === 1) return "?";
    if (pathArray.length > 1) return "&";
    return "";
};

export const addCompanyAndDepartmToPath = (
    pathArray: string[],
    companyId?: string,
    departmentId?: string
): string[] => {
    const newPathArray = [...pathArray];

    if (companyId) {
        newPathArray.push(queryParamConnector(newPathArray));
        newPathArray.push(`CompanyId=${companyId}`);
    }
    if (departmentId) {
        newPathArray.push(queryParamConnector(newPathArray));
        newPathArray.push(`DepartmentId=${departmentId}`);
    }
    return newPathArray;
};
