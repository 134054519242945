import { useState, useEffect } from "react";
import { useDriver } from "../../api/admin/driver";
import ApiError from "../../api/common/apiError";
import useLocalStorage from "../../hooks/useLocalStorage";
import {
    type ApiDriverDetailsResponse,
    type ApiDriversListResponse,
} from "../../models/api/admin/driver";
import { errorToast, successToast } from "../../toast";
import FormMessageBox from "../FormMessageBox";
import PaginationNav from "../PaginationNav";
import Table, { HiddenCols, TableHeader } from "../ReusableComponents/Table";
import {
    driverListMenuGroups,
    initTableContents,
    initTableHeaders,
} from "./DriverListInit";
import {
    DriverListMenuKeys,
    driverListMenuItems,
    driverListOrderByOptions,
} from "./DriverListTableOptions";
import { appPaths } from "../../Routes";
import { useNavigate } from "react-router-dom";
import DeleteConfirmation from "../ReusableComponents/DeleteConfirmation";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import useAuth from "../../auth";
import { isS2dAdminOrMember } from "../../auth/userAccessHelpers";

export type DriverListTableProps = {
    isLoading: boolean;
    error: ApiError | undefined;
    driverList?: ApiDriversListResponse;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    sortOrder: "asc" | "desc";
    setSortOrder: (val: string) => void;
    orderBy: string;
    setOrderBy: (val: string) => void;
    handleRefetchDriverList: () => void;
    tableRef: React.MutableRefObject<HTMLDivElement | null>;
    selectedCompany?: ApiCompanyResponse;
};

const DriverListTable: React.FC<DriverListTableProps> = ({
    isLoading,
    error,
    driverList,
    setPageNumber,
    pageSize,
    setPageSize,
    sortOrder,
    setSortOrder,
    orderBy,
    setOrderBy,
    handleRefetchDriverList,
    tableRef,
    selectedCompany,
}) => {
    const { decodedToken } = useAuth();
    // Commenting out for the time being
    // const clientManagementAccess = hasClientManagementAccess(decodedToken);
    const s2dAdminOrMember = isS2dAdminOrMember(decodedToken);
    const canViewDeletedDrivers = s2dAdminOrMember;

    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "driversListTableHeaders",
        initTableHeaders
    );

    useEffect(() => {
        // Grab initial table headers
        const initialTableHeaders = initTableHeaders;

        // Only remove deletedAt header if permissions are not met
        if (!canViewDeletedDrivers) {
            const filteredTableHeaders = initialTableHeaders.filter(
                (header) => header.id !== "deletedAt"
            );

            setTableHeaders(filteredTableHeaders);
        } else {
            // If permissions are met set table headers as normal, which includes deletedAt
            setTableHeaders(initialTableHeaders);
        }
    }, []);

    const navigate = useNavigate();

    const { deleteDriver, isDeletingDriver } = useDriver();
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState<
        ApiDriverDetailsResponse | undefined
    >();

    const handleDeleteDriver = async () => {
        const id = selectedDriver?.driverId;
        if (!id) {
            errorToast("There was an error");
            return;
        }
        const response = await deleteDriver(id);

        if (response.success) {
            successToast("Driver deleted successfully");
            handleRefetchDriverList();
            setDeleteDialogOpen(false);
        }
    };

    const handleTableMenuSelect = (
        data: ApiDriverDetailsResponse,
        key: DriverListMenuKeys
    ) => {
        setSelectedDriver(data);
        const id = data.driverId;
        switch (key) {
            case DriverListMenuKeys.editDriver:
                navigate(appPaths.adminEditDriver(id));
                break;
            case DriverListMenuKeys.deleteDriver:
                setDeleteDialogOpen(true);
                break;
        }
    };

    const initHiddenCols = new Array(0);
    tableHeaders.map((item) =>
        initHiddenCols.push({
            id: item.id,
            hidden: item.hide ?? false,
        })
    );
    const [hiddenCols, setHiddenCols] = useLocalStorage<HiddenCols[]>(
        "DriverTableHiddenCols",
        initHiddenCols
    );

    const handleSortColumn = (newOrderBy?: string) => {
        if (!newOrderBy) return;
        if (orderBy === newOrderBy) {
            if (sortOrder === "asc") {
                setSortOrder("desc");
            } else {
                setSortOrder("asc");
            }
        }
        setOrderBy(newOrderBy);
    };

    return (
        <>
            {error && (
                <FormMessageBox
                    title={"error"}
                    message={
                        <span>
                            There was an error loading driver data.
                            <br />
                            Please try again in 10 minutes.
                        </span>
                    }
                    variant={"error"}
                />
            )}
            <Table
                tableHeaders={tableHeaders}
                setTableHeaders={setTableHeaders}
                tableContents={initTableContents(
                    driverList?.items,
                    selectedCompany?.companyRiskProfiles
                )}
                menuItems={(data: ApiDriverDetailsResponse) =>
                    driverListMenuItems(data, handleTableMenuSelect)
                }
                menuGroups={driverListMenuGroups}
                hiddenCols={hiddenCols}
                setHiddenCols={setHiddenCols}
                orderByOptions={driverListOrderByOptions}
                orderBy={orderBy}
                sortOrder={sortOrder}
                handleSortColumn={handleSortColumn}
                isLoading={isLoading}
                tableRef={tableRef}
            />

            {driverList && (
                <>
                    {driverList?.items.length === 0 && !isLoading && (
                        <div className="rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-4 text-S2D-neutral-10">
                            No drivers found
                        </div>
                    )}
                    <PaginationNav
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalCount={driverList?.totalCount}
                        totalPages={driverList?.totalPages}
                        currentPage={driverList?.pageNumber}
                        hasPreviousPage={driverList?.hasPreviousPage}
                        hasNextPage={driverList?.hasNextPage}
                    />
                    <DeleteConfirmation
                        isOpen={isDeleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                        title={"You are about to delete a driver!"}
                        onSubmit={handleDeleteDriver}
                        isLoading={isDeletingDriver}
                    />
                </>
            )}
        </>
    );
};

export default DriverListTable;
