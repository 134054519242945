import { useEffect, useState } from "react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

// Check the overall validation status of a collection of form fields
// (using react-hook-form)
export const useFormFieldCollectiveValidation = <T extends FieldValues>(
    fields: Path<T>[],
    methods: UseFormReturn<T>
): boolean => {
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        let hasErrors = false;

        fields.forEach((field) => {
            const hasError = Boolean(methods.getFieldState(field).error);

            if (hasError) hasErrors = true;
        });

        setIsValid(!hasErrors);
    }, [methods.formState]);

    return isValid;
};
