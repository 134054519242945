import DriverDeclarationsTable from "../../components/DriverDeclarationsTable/DriverDeclarationsTable";
import PageLayout from "../../components/PageLayout/PageLayout";
import CollapsibleSection from "../../components/ReusableComponents/CollapsibleSection";
import useAdminDeclarations from "./pageHooks/useAdminDeclarations";
import DeclarationsPdfReport from "../../components/AdminDeclarationsPage/DeclarationsPdfReport";
import declarationAnalysisContent from "../../components/AdminDeclarationsPage/declarationAnalysisContent";
import HeaderOptions from "../../components/ReusableComponents/HeaderOptions";
import { declarationAnalysisActions } from "../../components/AnalysisViews/declarationAnalysisActions";
import { useRef, useState } from "react";
import { useScrollToTable } from "../../hooks/useScrollToTable";
import { useDeclarationAnalysis } from "../../components/AdminDeclarationsPage/useDeclarationAnalysis";
import AnalysisComponent, {
    AnalysisComponentContentType,
} from "../../components/ReusableComponents/AnalysisComponent";
import { DeclarationAnalysisTabs } from "../../models/api/admin/companyDeclarationAnalysis";

export default function AdminDeclarations() {
    const {
        isDeclarationAnalysisOpen,
        setIsDeclarationAnalysisOpen,
        selectedCompany,
        selectedDepartment,
        isCsvReportDownloading,
        handleCompanyChange,
        handleDepartmentChange,
        handleReset,
        handleResetFilters,
        handleDownloadCsvReport,
        declarationTableFiltersStore,
        setDeclarationTableFiltersStore,
        eDeclarationsList,
        isLoadingEDeclarationsList,
        eDeclarationsListError,
        refetchEDeclarationsList,
        setPageSize,
        pageSize,
        pageNumber,
        setPageNumber,
        searchQuery,
        setSearchQuery,
    } = useAdminDeclarations();

    const {
        companyDeclarationAnalysis,
        companyDeclarationAnalysisError,
        tabOption,
        setTabLabel,
        pdfDeclarationAnalysisData,
        isLoadingPdfDeclarationAnalysisData,
        reportRef,
        showReportView,
        isLoadingPdfReport,
        handleDownloadPdfReport,
        refetchDeclarationAnalysisData,
    } = useDeclarationAnalysis(selectedCompany, selectedDepartment);

    const tableRef = useRef<HTMLDivElement | null>(null);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);

    const { declarationStatusAnalysisActions } = declarationAnalysisActions(
        declarationTableFiltersStore,
        setDeclarationTableFiltersStore,
        setIsLoadingFilter,
        eDeclarationsList,
        tableRef
    );

    const analysisContent: AnalysisComponentContentType<DeclarationAnalysisTabs>[] =
        declarationAnalysisContent(
            companyDeclarationAnalysis,
            companyDeclarationAnalysisError,
            declarationStatusAnalysisActions,
            isLoadingFilter
        );

    const hasLoaded = !!eDeclarationsList && !isLoadingEDeclarationsList;

    useScrollToTable(hasLoaded, tableRef);

    return (
        <PageLayout title="Driver Declarations" header={false}>
            <div className="flex flex-col gap-6">
                <HeaderOptions
                    selectedDepartment={selectedDepartment}
                    handleDepartmentChange={handleDepartmentChange}
                    selectedCompany={selectedCompany}
                    handleCompanyChange={handleCompanyChange}
                    clearCompanyDepartment={handleReset}
                    handleDownloadPdfReport={handleDownloadPdfReport}
                    handleDownloadCsvReport={handleDownloadCsvReport}
                    isReportDownloading={
                        isCsvReportDownloading ||
                        isLoadingPdfDeclarationAnalysisData ||
                        isLoadingPdfReport
                    }
                />
                <CollapsibleSection
                    title="Driver Declaration Analysis"
                    content={
                        <AnalysisComponent
                            analysisContent={analysisContent}
                            selectedTab={tabOption}
                            onSelect={setTabLabel}
                        />
                    }
                    isOpen={isDeclarationAnalysisOpen}
                    setIsOpen={setIsDeclarationAnalysisOpen}
                />
                <DriverDeclarationsTable
                    eDeclarationsList={eDeclarationsList}
                    isLoadingEDeclarationsList={isLoadingEDeclarationsList}
                    eDeclarationsListError={eDeclarationsListError}
                    refetchEDeclarationsList={refetchEDeclarationsList}
                    refetchCompanyDeclarationAnalysis={
                        refetchDeclarationAnalysisData
                    }
                    setPageSize={setPageSize}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    declarationTableFiltersStore={declarationTableFiltersStore}
                    handleResetFilters={handleResetFilters}
                    tableRef={tableRef}
                />
                {showReportView && (
                        <div ref={reportRef}>
                            <DeclarationsPdfReport
                                analysisData={pdfDeclarationAnalysisData}
                                companyName={selectedCompany?.companyName}
                                departmentName={
                                    selectedDepartment?.departmentName
                                }
                            />
                        </div>
                )}
            </div>
        </PageLayout>
    );
}
