import UserSearchDropdown from "../UsersSearchDropdown";
import { UseFormReturn, useWatch } from "react-hook-form";
import { SaveEditFilterValues } from "./DriversListSaveEditFilterValues";
import { useState } from "react";
import { FormSearchDropdownOption } from "../FormSearchDropdown/FormSearchDropdown";

export type ShareFilterFormFieldProps = {
    methods: UseFormReturn<SaveEditFilterValues>;
};

const ShareFilterFormField: React.FC<ShareFilterFormFieldProps> = ({
    methods,
}) => {
    const [userSearch, setUserSearch] = useState<string>("");
    const control = methods.control;
    const userIds = useWatch({ control, name: "userIds" });

    const handleShareWithSelect = (
        newUserId: string | null,
        option: FormSearchDropdownOption | null
    ) => {
        const tempArray = new Array(0);
        userIds?.map((id) => {
            tempArray.push(id);
        });
        if (newUserId && userIds?.includes(newUserId)) return;
        if (!newUserId) return;
        tempArray.push(newUserId);
        methods.setValue("userIds", tempArray);
        methods.trigger(["userIds"]);
        setUserSearch("");
    };

    return (
        <div>
            <UserSearchDropdown
                value={userSearch}
                onChange={handleShareWithSelect}
                sharedWith={userIds}
            />
        </div>
    );
};

export default ShareFilterFormField;
