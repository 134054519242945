import CaretDropdown from "./CaretDropdown";
import { Menu } from "@headlessui/react";
import { FileCsv, FilePdf, FileXls } from "phosphor-react";
import clsx from "clsx";

type Props = {
    handleDownloadPdfReport?: () => void;
    handleDownloadCsvReport: () => void;
    handleDownloadXlsReport?: () => void;
    isReportDownloading: boolean;
    isDisabled?: boolean;
    className?: string;
};
export default function DownloadReportButton({
    handleDownloadCsvReport,
    handleDownloadPdfReport,
    handleDownloadXlsReport,
    isReportDownloading,
    isDisabled,
    className,
}: Props) {
    const cursorStyle = () => {
        if (isDisabled) return "cursor-not-allowed";
        if (isReportDownloading) return "cursor-wait";
        return "cursor-pointer";
    };
    return (
        <div className={clsx(className ?? `w-full md:w-fit`)}>
            <Menu>
                {({ open }) => (
                    <div className="relative">
                        <Menu.Button
                            className={clsx(
                                "w-full",
                                cursorStyle(),
                                isDisabled && "opacity-40",
                                isReportDownloading && "opacity-40"
                            )}
                            disabled={isReportDownloading || isDisabled}
                        >
                            <CaretDropdown
                                open={open}
                                content={
                                    <div className="flex h-[45px] items-center whitespace-nowrap px-4">
                                        Download report
                                    </div>
                                }
                                className="border-S2D-neutral-10 bg-S2D-neutral-100"
                            />
                        </Menu.Button>

                        <Menu.Items
                            className={`absolute top-[100%] z-10 flex w-[100%] flex-col 
                                        gap-3 rounded bg-white shadow-mui`}
                        >
                            {handleDownloadPdfReport && (
                                <Menu.Item>
                                    {({ active, disabled }) => (
                                        <div
                                            className={clsx(
                                                `flex cursor-pointer items-center gap-2 p-3`,
                                                active &&
                                                    "bg-S2D-light-green-80",
                                                disabled && "opacity-40"
                                            )}
                                            onClick={handleDownloadPdfReport}
                                        >
                                            <FilePdf weight="fill" size={20} />{" "}
                                            Download PDF
                                        </div>
                                    )}
                                </Menu.Item>
                            )}
                            {handleDownloadXlsReport && (
                                <Menu.Item>
                                    {({ active, disabled }) => (
                                        <div
                                            className={clsx(
                                                `flex cursor-pointer items-center gap-2 p-3`,
                                                active &&
                                                    "bg-S2D-light-green-80",
                                                disabled && "opacity-40"
                                            )}
                                            onClick={handleDownloadXlsReport}
                                        >
                                            <FileXls weight="fill" size={20} />{" "}
                                            Download XLS
                                        </div>
                                    )}
                                </Menu.Item>
                            )}
                            <Menu.Item>
                                {({ active, disabled }) => (
                                    <div
                                        className={clsx(
                                            `flex cursor-pointer items-center gap-2 p-3`,
                                            active && "bg-S2D-light-green-80",
                                            disabled && "opacity-40"
                                        )}
                                        onClick={handleDownloadCsvReport}
                                    >
                                        <FileCsv weight="fill" size={20} />{" "}
                                        Download CSV
                                    </div>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </div>
                )}
            </Menu>
        </div>
    );
}
