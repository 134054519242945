import * as yup from "yup";
import { RiskCategoriesSchema } from "../../../../schemas/companySchemas";
import { SchemaKeys } from "../../../../models/shared/schemaKeysHelper";
import { EditEdeclarationSettingsFormValues } from "./EditEdeclarationSettingsForm/useEditEdeclarationSettingsForm";
import { EditCompanySecurityFormValues } from "../../CompanySecurityTab/useCompanySecurityTab";

export const EditEdeclarationSettingsSchema: SchemaKeys<EditEdeclarationSettingsFormValues> =
    {
        useParentContact: yup.boolean().required(),
        driverValidationType: yup
            .string()
            .oneOf(["DateOfBirth", "EmployeeId"])
            .required(),
    };

export const EditCompanySecuritySettingsSchema: SchemaKeys<EditCompanySecurityFormValues> =
    {
        enforce2FA: yup.boolean().required(),
    };

export const EditRiskCategoriesSchema = { riskCategory: RiskCategoriesSchema };
