import { FileCsv } from "phosphor-react";
import { PrebuiltReports } from "../../models/api/reporting";
import { formatDateToString } from "../../utils/formatDateToString";
import Button from "../Button";
import ReportActionsDropdown from "./ReportActionsDropdown";
import { type ReportingBoxOption } from "./premadeReports";
import clsx from "clsx";
import { ReportFileTypes } from "../../api/reporting";
import DownloadReportButton from "../ReusableComponents/DownloadReportButton";

type ReportingBoxProps = {
    reportingBoxOption: ReportingBoxOption;
    handleDownloadReport: (
        reportName: PrebuiltReports,
        type: ReportFileTypes
    ) => void;
    isReportDownloading: boolean;
    handleDeleteReport: (id: string) => void;
    isDeletingReport: boolean;
};

const ReportingBox: React.FC<ReportingBoxProps> = ({
    reportingBoxOption,
    handleDownloadReport,
    isReportDownloading,
    handleDeleteReport,
    isDeletingReport,
}) => {
    const isPrebuiltReport = !!reportingBoxOption.reportName;

    const useDropdown = reportingBoxOption.id === "greyfleet";

    return (
        <div
            className={clsx(
                "flex w-full flex-col flex-wrap gap-4 rounded-sm p-6",
                "border border-S2D-dark-green-70.3 bg-S2D-dark-green-70.1"
            )}
            key={reportingBoxOption.id}
        >
            <p className="font-bold">{reportingBoxOption.title}</p>
            <div className="flex flex-col justify-between gap-6 md:flex-row">
                <div className="flex w-full flex-col gap-2 md:w-2/3">
                    <p>{reportingBoxOption.description}</p>
                    {reportingBoxOption.createdAt &&
                        reportingBoxOption.createdBy && (
                            <p className="text-S2D-neutral-40">
                                Created on{" "}
                                {formatDateToString(
                                    reportingBoxOption.createdAt,
                                    true
                                )}{" "}
                                by {reportingBoxOption.createdBy.firstName}{" "}
                                {reportingBoxOption.createdBy.lastName}
                            </p>
                        )}
                </div>
                <div className="flex w-full md:w-1/3 md:justify-end">
                    {isPrebuiltReport ? (
                        useDropdown ? (
                            <DownloadReportButton
                                handleDownloadCsvReport={() =>
                                    handleDownloadReport(
                                        reportingBoxOption.reportName,
                                        "CSV"
                                    )
                                }
                                handleDownloadXlsReport={() =>
                                    handleDownloadReport(
                                        reportingBoxOption.reportName,
                                        "XLS"
                                    )
                                }
                                isReportDownloading={isReportDownloading}
                            />
                        ) : (
                            <Button
                                icon={FileCsv}
                                onClick={() =>
                                    handleDownloadReport(
                                        reportingBoxOption.reportName,
                                        "CSV"
                                    )
                                }
                                loading={isReportDownloading}
                                variant="secondary"
                            >
                                Download CSV
                            </Button>
                        )
                    ) : (
                        <ReportActionsDropdown
                            handleDownloadReport={() =>
                                handleDownloadReport(
                                    reportingBoxOption.reportName,
                                    "CSV"
                                )
                            }
                            isReportDownloading={isReportDownloading}
                            handleDeleteReport={() =>
                                handleDeleteReport(reportingBoxOption.id)
                            }
                            isDeletingReport={isDeletingReport}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReportingBox;
