import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDriver } from "../../api/admin/driver";
import AddDriverForm from "../../components/AddDriverForm";
import PageLayout from "../../components/PageLayout";
import { appPaths } from "../../Routes";
import { errorToast, successToast } from "../../toast";
import { DeclarationType } from "../../models/app/eDeclarations";
import useAuth from "../../auth";
import {
    AddDriverFormValues,
    addDriverFormDefaultValues,
} from "../../components/AddDriverForm/AddDriverFormValues";
import { DriverValidationTypes } from "../../models/app/admin/company";

export type EmailRequest = {
    eDeclaration: boolean;
    greyFleet: boolean;
};

const AdminAddDriverPage: React.FC = () => {
    const { addDriver, isAddingDriver, sendDeclaration } = useDriver();
    const [emailRequest, setEmailRequest] = useState<EmailRequest>({
        eDeclaration: true,
        greyFleet: false,
    });

    const navigate = useNavigate();

    const handleSendDeclaration = async (
        userId: string,
        validateBy: DriverValidationTypes
    ) => {
        const response = await sendDeclaration(userId, {
            declarationType: emailRequest.eDeclaration
                ? DeclarationType.EDeclaration
                : DeclarationType.PaperDeclaration,
            driverValidationType: validateBy,
        });

        if (response.success) {
            successToast("Declaration created");
        } else if (!response.success) {
            errorToast(
                "Failed to create a declaration, please try again in the driver profile."
            );
        }
    };

    const handleAddDriver = async (
        data: AddDriverFormValues,
        callback?: () => void
    ) => {
        const addDriverDto = {
            ...data,
            emailAddress: data.emailAddress === "" ? null : data.emailAddress,
            address: data.address?.address1 === "" ? null : data.address,
            licenceNumber:
                data.licenceNumber === "" ? null : data.licenceNumber,
            licenceCountryOrigin:
                data.licenceCountryOrigin === ""
                    ? null
                    : data.licenceCountryOrigin,
        };

        const response = await addDriver(addDriverDto);

        if (response.success) {
            successToast("Driver has been added");
            handleSendDeclaration(
                response.content.driverId,
                data.validateDriverIdentityBy
            );
            if (callback) callback();
        } else if (response.error?.statusCode === 409) {
            errorToast(`${data.emailAddress} is already taken`);
        } else if (response.content.failureReasons) {
            response.content.failureReasons.map((reason) => errorToast(reason));
        }
    };

    const handleCancel = () => {
        navigate(appPaths.adminDrivers);
    };

    const user = useAuth();
    const userCompanyId = user.userDetails?.companyId ?? null;
    const formDefaultValues = addDriverFormDefaultValues(userCompanyId);

    return (
        <PageLayout title="Add Driver">
            <AddDriverForm
                onSubmit={handleAddDriver}
                isLoading={isAddingDriver}
                onCancel={handleCancel}
                emailRequest={emailRequest}
                setEmailRequest={setEmailRequest}
                formDefaultValues={formDefaultValues}
            />
        </PageLayout>
    );
};

export default AdminAddDriverPage;
