import { type ApiCompanyResponse } from "../../../models/api/admin/company";
import { ApiDepartmentItem } from "../../../models/api/admin/department";
import DepartmentDropdown from "./DepartmentDropdown";
import CompanyDropdown from "./CompanyDropdown";

export type CompanyDepartmentSelectorProps = {
    currentDepartment: ApiDepartmentItem | null;
    handleDepartmentChange: (department: ApiDepartmentItem | null) => void;
    selectedCompany: ApiCompanyResponse | null;
    handleCompanyChange: (company: ApiCompanyResponse | null) => void;
    containerStyles?: string;
    showLabels?: boolean;
};

const CompanyDepartmentSelector: React.FC<CompanyDepartmentSelectorProps> = ({
    currentDepartment,
    handleDepartmentChange,
    selectedCompany,
    handleCompanyChange,
    containerStyles,
    showLabels,
}) => {
    return (
        <div
            className={
                containerStyles ??
                "flex w-full flex-col gap-4 md:w-fit md:flex-row"
            }
        >
            <CompanyDropdown
                handleCompanyChange={handleCompanyChange}
                selectedCompany={selectedCompany}
                showLabels={showLabels}
            />
            {selectedCompany && (
                <DepartmentDropdown
                    companyId={selectedCompany?.companyId}
                    currentDepartment={currentDepartment}
                    handleDepartmentChange={handleDepartmentChange}
                    showLabel={showLabels}
                />
            )}
        </div>
    );
};

export default CompanyDepartmentSelector;
