import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./Routes";
import { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./auth/AuthProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function App() {
    return (
        <ErrorBoundary>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AuthProvider>
                    <Router>
                        <AppRoutes />
                    </Router>
                    <ToastContainer />
                </AuthProvider>
            </LocalizationProvider>
        </ErrorBoundary>
    );
}

interface Props {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    info: React.ErrorInfo | null;
}

function ErrorBoundary(props: Props): JSX.Element {
    const [state, setState] = useState<ErrorBoundaryState>({
        hasError: false,
        error: null,
        info: null,
    });

    useEffect(() => {
        const handleError = (error: ErrorEvent) => {
            // 09/05/23 Ignoring this error as it doesn't seem to effect the app.
            // Seems to only happen if you have the LastPass Chrome extension.
            if (
                error.message === "ResizeObserver loop limit exceeded" ||
                error.message ===
                    "ResizeObserver loop completed with undelivered notifications." ||
                error.message.includes(
                    "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node."
                )
            ) {
                console.error("Ignoring error: ", error.message);
                return;
            }
            const errorEvent = error.error ?? error;
            setState({
                hasError: true,
                error: errorEvent,
                info:
                    errorEvent && errorEvent.stack
                        ? { componentStack: errorEvent.stack }
                        : null,
            });
            console.error(errorEvent.message);
        };

        window.addEventListener("error", handleError);

        return () => {
            window.removeEventListener("error", handleError);
        };
    }, []);

    if (state.hasError) {
        const errorMessage = state.error?.message ?? state.error?.toString();
        return (
            <div style={{ padding: "20px" }}>
                <h2 style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                    Oops! Something went wrong. Please go back and try again or
                    contact your system administrator for help.
                </h2>
                <p>{errorMessage}</p>
                <p>{state.info?.componentStack}</p>
            </div>
        );
    }

    return <>{props.children}</>;
}

export default App;
