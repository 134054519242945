import { capitaliseWord } from "./capitaliseWord";

export const convertKebabCaseToCamelCase = (value: string) => {
    const sentenceArray = value.split("-");

    let newValue: string;
    newValue = sentenceArray[0];

    sentenceArray.shift();
    sentenceArray.forEach((word) => {
        newValue += capitaliseWord(word);
    });

    return newValue;
};
