import React from "react";
import clsx from "clsx";
import { CaretDown, CaretUp, IconProps } from "phosphor-react";

export default function SideNavDropDown({
    isDropDownOpen,
    expanded,
    toggleOpen,
    children,
    dropDownText,
    icon: Icon,
}: {
    isDropDownOpen: boolean;
    expanded: boolean;
    toggleOpen: () => void;
    children: React.ReactNode;
    dropDownText: string;
    icon: React.ForwardRefExoticComponent<
        IconProps & React.RefAttributes<SVGSVGElement>
    >;
}) {
    return (
        <li>
            <button
                className={clsx(
                    `border-1 flex h-14 w-full items-center border-b border-S2D-dark-green-20 text-S2D-text-menu`,
                    `transition-colors hover:brightness-50`,
                    expanded
                        ? "justify-between gap-4 px-6"
                        : "justify-center pl-3",
                    isDropDownOpen
                        ? "bg-S2D-dark-green-20"
                        : "bg-S2D-dark-green-30"
                )}
                onClick={toggleOpen}
                aria-expanded={isDropDownOpen}
            >
                <div className="flex items-center gap-3">
                    <Icon size={32} />
                    {expanded ? dropDownText : ""}
                </div>

                {isDropDownOpen ? (
                    <CaretUp size={expanded ? 32 : 15} />
                ) : (
                    <CaretDown size={expanded ? 32 : 15} />
                )}
            </button>
            {isDropDownOpen && <ul>{children}</ul>}
        </li>
    );
}
