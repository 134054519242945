import { type FilterType } from "../models/app/admin/filter";
import useLocalStorage from "./useLocalStorage";
import { type DriverCategory } from "../utils/driverPageLocation";
import { driverListFiltersDefaultValues } from "../components/DriverListFilters/DriversListFiltersForm/driversListFiltersDefaultValues";

export const useAppliedFilterLocalStorage = (
    location?: DriverCategory | string
) => {
    const [appliedFilter, setAppliedFilter] = useLocalStorage<FilterType>(
        `${location}AppliedFilter`,
        {
            filterId: "",
            filterFields: { ...driverListFiltersDefaultValues(location) },
        }
    );

    return {
        appliedFilter,
        setAppliedFilter,
    };
};
