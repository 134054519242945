import { ApiInvoiceAddressItem } from "../../api/admin/invoiceAddress";

export type AddressFields = {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    addressLine5: string;
    addressTown: string;
    addressPostcode: string;
};

export type AddInvoiceAddressFormData = {
    address: AddressFields;
    addressName: string;
    isPrimaryInvoiceAddress: boolean;
};

export type AddInvoiceAddressDto = AddInvoiceAddressFormData & {
    companyId?: string | undefined;
    invoiceAddressId?: string | undefined;
    isPrimaryInvoiceAddress?: boolean | undefined;
};

export type EditInvoiceAddressDto = AddInvoiceAddressFormData;
export type EditInvoiceAddressFormData = AddInvoiceAddressFormData;

export function mapDtoToApi(dto: AddInvoiceAddressDto): ApiInvoiceAddressItem {
    const { companyId, invoiceAddressId, address, addressName, isPrimaryInvoiceAddress } = dto;

    return {
        companyId,
        invoiceAddressId,
        addressName,
        isPrimaryInvoiceAddress,
        ...address,
    };
}
