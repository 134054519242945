export enum Co2EmissionsOptions {
    lowEmissions = "lowEmissions",
    mediumEmissions = "mediumEmissions",
    highEmissions = "highEmissions",
}

export enum Co2EmissionsValues {
    lowEmissions = "Low emissions",
    mediumEmissions = "Medium emissions",
    highEmissions = "High emissions",
}

export type Co2Emissions =
    | {
          [key in Co2EmissionsOptions]: boolean | null;
      }
    | Record<string, never>;

export enum FuelTypeOptions {
    petrol = "petrol",
    diesel = "diesel",
    hybrid = "hybrid",
    electric = "electric",
    other = "other",
}

export type FuelType =
    | {
          [key in FuelTypeOptions]: boolean | null;
      }
    | Record<string, never>;

export enum AgeRangeOptions {
    zeroToTwoYears = "zeroToTwoYears",
    threeToFiveYears = "threeToFiveYears",
    sixToTenYears = "sixToTenYears",
    elevenToFifteen = "elevenToFifteen",
    sixteenToTwenty = "sixteenToTwenty",
    twentyOneAndOver = "twentyOneAndOver",
}

export enum AgeRangeValues {
    zeroToTwoYears = "0-2 years",
    threeToFiveYears = "3-5 years",
    sixToTenYears = "6-10 years",
    elevenToFifteen = "11-15 tears",
    sixteenToTwenty = "16-20 years",
    twentyOneAndOver = "21+ years",
}

export type AgeRange =
    | {
          [key in AgeRangeOptions]: boolean | null;
      }
    | Record<string, never>;

export type Manufacturer =
    | {
          name: string | null;
      }
    | Record<string, never>;

export enum EuroRatingOptions {
    euro1 = "euro1",
    euro2 = "euro2",
    euro3 = "euro3",
    euro4 = "euro4",
    euro5 = "euro5",
    euro6 = "euro6",
}

export enum EuroRatingValues {
    euro1 = "Euro 1",
    euro2 = "Euro 2",
    euro3 = "Euro 3",
    euro4 = "Euro 4",
    euro5 = "Euro 5",
    euro6 = "Euro 6",
}

export type EuroRating =
    | {
          [key in EuroRatingOptions]: boolean | null;
      }
    | Record<string, never>;

export enum InsuranceTypeOptions {
    fullComprehensive = "fullComprehensive",
    thirdParty = "thirdParty",
}
export type InsuranceType =
    | {
          [key in InsuranceTypeOptions]: boolean | null;
      }
    | Record<string, never>;

export enum BusinessCoverOptions {
    withBusinessCover = "withBusinessCover",
    withoutBusinessCover = "withoutBusinessCover",
}

export type BusinessCover =
    | {
          [key in BusinessCoverOptions]: boolean | null;
      }
    | Record<string, never>;

export enum EngineCapacityOptions {
    unknownCapacity = "unknownCapacity",
    zeroToOnePointTwo = "zeroToOnePointTwo",
    onePointTwoToOnePointEight = "onePointTwoToOnePointEight",
    onePointEightToTwoPointFive = "onePointEightToTwoPointFive",
    twoPointFiveAndOver = "twoPointFiveAndOver",
}

export enum EngineCapacityValues {
    unknownCapacity = "Unknown capacity",
    zeroToOnePointTwo = "0-1200 cc",
    onePointTwoToOnePointEight = "1200-1800 cc",
    onePointEightToTwoPointFive = "1800-2500 cc",
    twoPointFiveAndOver = "2500+ cc",
}

export type EngineCapacity =
    | {
          [key in EngineCapacityOptions]: boolean | null;
      }
    | Record<string, never>;
