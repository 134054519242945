import * as Yup from "yup";

export const CreatePasswordFormValidationSchema = {
    password: Yup.string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()])[A-Za-z\d@$!%*?&()]{8,}$/,
            "Password must contain at least 8 characters, a mix of lower and upper case, at least 1 number and 1 special character (@$!%*?&())."
        )
        .required("Please enter new password."),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match.")
        .required("Please confirm new password."),
};

export type CreatePasswordFormValues = {
    password: string;
    confirmPassword: string;
};
