import PageLayout from "../../components/PageLayout";
import ProfileTabs from "../../components/ReusableComponents/ProfileTabs";
import LoadingMessage from "../../components/ReusableComponents/LoadingMessage/LoadingMessage";
import FileSafeInboxTable from "../../components/FileSafe/Inbox";
import FileSafeSentTable from "../../components/FileSafe/Sent";
import FormMessageBox from "../../components/FormMessageBox";
import { dataErrorMessage } from "../../components/ReusableComponents/errorMessage";
import FileSafeHeader from "../../components/FileSafeHeader/FileSafeHeader";
import useAdminFileSafe from "./pageHooks/useAdminFileSafe";

export type TransferDate = {
    from: Date | null;
    to: Date | null;
};

const AdminFileSafe = () => {
    const {
        sectionTabs,
        selectedTab,
        inboxTabText,
        sentTabText,
        handleChangeTab,
        setFileSafeFilters,
        sentFileList,
        sentFileListError,
        isSentFileListLoading,
        refetchSentFileList,
        receivedFileList,
        receivedFileListError,
        isReceivedFileListLoading,
        refetchReceivedFileList,
        searchQuery,
        setSearchQuery,
        inboxPageNumber,
        setInboxPageNumber,
        inboxPageSize,
        setInboxPageSize,
        sentPageNumber,
        setSentPageNumber,
        sentPageSize,
        setSentPageSize,
    } = useAdminFileSafe();

    const listsLoading = isReceivedFileListLoading || isSentFileListLoading;

    return (
        <PageLayout title={"FileSafe"} header={false}>
            <div className="flex flex-col gap-6">
                <FileSafeHeader
                    setFileSafeFilters={setFileSafeFilters}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    listsLoading={listsLoading}
                />
                <section about="inbox-sent tabs">
                    <ProfileTabs
                        selected={selectedTab}
                        onSelect={handleChangeTab}
                        options={sectionTabs}
                    />
                    {selectedTab === inboxTabText && (
                        <>
                            {receivedFileList && !isReceivedFileListLoading && (
                                <FileSafeInboxTable
                                    tableData={receivedFileList}
                                    refetchTableData={refetchReceivedFileList}
                                    pageNumber={inboxPageNumber}
                                    setPageNumber={setInboxPageNumber}
                                    pageSize={inboxPageSize}
                                    setPageSize={setInboxPageSize}
                                />
                            )}
                            {isReceivedFileListLoading && <LoadingMessage />}
                            {receivedFileListError && (
                                <FormMessageBox
                                    variant="error"
                                    title="Error loading data"
                                    message={dataErrorMessage}
                                />
                            )}
                        </>
                    )}
                    {selectedTab === sentTabText && (
                        <>
                            {sentFileList && !isSentFileListLoading && (
                                <FileSafeSentTable
                                    tableData={sentFileList}
                                    refetchTableData={refetchSentFileList}
                                    pageNumber={sentPageNumber}
                                    setPageNumber={setSentPageNumber}
                                    pageSize={sentPageSize}
                                    setPageSize={setSentPageSize}
                                />
                            )}
                            {isSentFileListLoading && <LoadingMessage />}
                            {sentFileListError && (
                                <FormMessageBox
                                    variant="error"
                                    title="Error loading data"
                                    message={dataErrorMessage}
                                />
                            )}
                        </>
                    )}
                </section>
            </div>
        </PageLayout>
    );
};

export default AdminFileSafe;
