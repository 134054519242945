import { ApiCompanyThemeDto } from "../../models/api/admin/themes";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

// separate an async get and a put/delete
// as they will be used at different points in the app
export const useGetTheme = (companyId?: string) => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiCompanyThemeDto>(
            companyId ? `api/company/${companyId}/theme` : ""
        );
    return {
        themeData: data,
        themeError: error,
        isLoadingTheme: isLoading,
        refetchTheme: refetch,
    };
};

const useEditTheme = () =>
    useS2DApiFetcher<
        ApiCompanyThemeDto,
        string,
        { companyId: string; dto: ApiCompanyThemeDto }
    >(
        "PUT",
        ({ companyId, dto }) => ({
            url: `api/company/${companyId}/theme`,
            body: dto,
        }),
        [],
        true
    );

const useDeleteTheme = () =>
    useS2DApiFetcher<null, string, { companyId: string }>(
        "DELETE",
        ({ companyId }) => ({
            url: `api/company/${companyId}/theme`,
        }),
        [],
        true
    );

export const useModifyTheme = () => {
    const [editTheme, isEditingTheme] = useEditTheme();
    const [deleteTheme, isDeletingTheme] = useDeleteTheme();

    return {
        editTheme: async (companyId: string, dto: ApiCompanyThemeDto) =>
            await editTheme({ companyId, dto }),
        isEditingTheme,
        deleteTheme: async (companyId: string) =>
            await deleteTheme({ companyId }),
        isDeletingTheme,
    };
};
