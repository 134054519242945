import { useState } from "react";
import { useDvlaChecks } from "../../api/admin/checks";
import {
    UpdateCheckStatusDto,
    UpdateCheckTypeDto,
    UpdateNextCheckDateDto,
} from "../../models/api/admin/checks";
import { CheckStatus, CheckType } from "../../models/shared/statuses";
import { errorToast, successToast } from "../../toast";
import {
    BulkActionFormTypes,
    useDvlaBulkActions,
} from "../../api/safe2Drive/dvlaBulkActions";
import { downloadFromArrayBuffer } from "../../utils/downloadFromArrayBuffer";

export type DvlaCheckTableActions = {
    handleInstantCheck: (
        driverId: string,
        licenceNumber?: string
    ) => Promise<void>;
    isCheckingLicence: boolean;
    checkDateDriverId: string | undefined;
    handleOpenNextCheckDateDialog: (driverId: string | undefined) => void;
    disableDriverId: string | undefined;
    handleOpenDisableDriverDialog: (driverId: string | undefined) => void;
    handleUpdateNextCheckDate: (
        nextCheckDate: Date,
        driverId?: string
    ) => Promise<void>;
    isUpdatingNextCheckDate: boolean;
    handleCheckStatus: (
        checkStatus: CheckStatus,
        driverId?: string
    ) => Promise<void>;
    handleCheckType: (
        driverId: string,
        currentType: CheckType
    ) => Promise<void>;
    handleDownloadD906: (driverId?: string) => Promise<void>;
    handleDownloadDVA: (driverId?: string) => Promise<void>;
};

export const useDvlaChecksTableActions = (
    refetchData: () => void
): DvlaCheckTableActions => {
    const {
        checkLicence,
        isCheckingLicence,
        updateCheckStatus,
        updateCheckType,
        updateNextCheckDate,
        isUpdatingNextCheckDate,
    } = useDvlaChecks();

    const { downloadDeclarationForms } = useDvlaBulkActions();

    const handleInstantCheck = async (
        driverId: string,
        licenceNumber?: string
    ) => {
        if (!licenceNumber) {
            errorToast("Driver requires a licence number.");
            return;
        }

        const response = await checkLicence(driverId);

        if (!response.success) {
            errorToast(response.message ?? "Failed to check licence.");
            // Need to refetch here as the driver will now get an error status
            refetchData();
        } else {
            successToast("Successfully checked licence.");
            refetchData();
        }
    };

    const [checkDateDriverId, setCheckDateDriverId] = useState<
        string | undefined
    >();
    const handleOpenNextCheckDateDialog = (driverId: string | undefined) => {
        setCheckDateDriverId(driverId);
    };

    const [disableDriverId, setDisableDriverId] = useState<
        string | undefined
    >();
    const handleOpenDisableDriverDialog = (driverId: string | undefined) => {
        setDisableDriverId(driverId);
    };

    const handleUpdateNextCheckDate = async (
        nextCheckDate: Date,
        driverId?: string
    ) => {
        if (!driverId) {
            errorToast("No driver is selected.");
            return;
        }
        const data: UpdateNextCheckDateDto = {
            nextCheckDateTimeUtc: nextCheckDate.toISOString(),
        };

        const response = await updateNextCheckDate(driverId, data);
        if (!response.success) {
            errorToast("Failed to update next check date.");
        } else {
            successToast("Successfully updated next check date.");
            handleOpenNextCheckDateDialog(undefined);
            refetchData();
        }
    };

    const handleCheckStatus = async (
        checkStatus: CheckStatus,
        driverId?: string
    ) => {
        if (!driverId) {
            errorToast("Select a driver to perform this action.");
            return;
        }

        // at this point we are only marking as pending or checked, so no failure reason is needed.
        const data: UpdateCheckStatusDto = {
            statusId: checkStatus,
        };

        const response = await updateCheckStatus(driverId, data);
        if(response.success){
            successToast("Successfully updated check status.");
            disableDriverId && handleOpenDisableDriverDialog(undefined);
            refetchData();
        }
    };

    const handleCheckType = async (
        driverId: string,
        currentType: CheckType
    ) => {
        let data: UpdateCheckTypeDto;
        if (currentType === CheckType.DVLA) {
            data = {
                isManual: true,
            };
        } else {
            data = {
                isManual: false,
            };
        }

        const response = await updateCheckType(driverId, data);
        if (!response.success) {
            errorToast("Failed to update check type.");
        } else {
            successToast("Successfully updated check type.");
            refetchData();
        }
    };

    const handleDownloadD906 = async (driverId?: string) => {
        if (!driverId) return;

        const response = await downloadDeclarationForms(
            BulkActionFormTypes.D906,
            [driverId]
        );
        if (response.success) {
            downloadFromArrayBuffer(response.content, `D906InformationConsent`);
            successToast("Successfully downloaded D906 form.");
            refetchData();
        } else {
            errorToast("Failed to download D906 form.");
        }
    };

    const handleDownloadDVA = async (driverId?: string) => {
        if (!driverId) return;

        const response = await downloadDeclarationForms(
            BulkActionFormTypes.DVA,
            [driverId]
        );
        if (response.success) {
            downloadFromArrayBuffer(response.content, `DVAInformationConsent`);
            successToast("Successfully downloaded DVA form.");
            refetchData();
        } else {
            errorToast("Failed to download DVA form.");
        }
    };

    return {
        handleInstantCheck,
        isCheckingLicence,
        checkDateDriverId,
        handleOpenNextCheckDateDialog,
        disableDriverId,
        handleOpenDisableDriverDialog,
        handleUpdateNextCheckDate,
        isUpdatingNextCheckDate,
        handleCheckStatus,
        handleCheckType,
        handleDownloadD906,
        handleDownloadDVA,
    };
};
