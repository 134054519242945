import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
    EditInvoiceAddressFormValidationSchema,
    EditInvoiceAddressFormValues,
} from "./EditInvoiceAddressFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInvoiceAddress } from "../../../../../api/admin/invoiceAddress";
import { type ApiInvoiceAddressItem } from "../../../../../models/api/admin/invoiceAddress";

const useEditInvoiceAddressForm = (
    selectedInvoiceAddress: ApiInvoiceAddressItem | undefined
) => {
    const {
        editInvoiceAddress,
        isEditingInvoiceAddress,
        deleteInvoiceAddress,
        isDeletingInvoiceAddress,
    } = useInvoiceAddress();

    const displayedValues: EditInvoiceAddressFormValues = {
        addressName: selectedInvoiceAddress?.addressName || "",
        address: {
            addressLine1: selectedInvoiceAddress?.addressLine1 || "",
            addressLine2: selectedInvoiceAddress?.addressLine2 || "",
            addressLine3: selectedInvoiceAddress?.addressLine3 || "",
            addressLine4: selectedInvoiceAddress?.addressLine4 || "",
            addressLine5: selectedInvoiceAddress?.addressLine5 || "",
            addressTown: selectedInvoiceAddress?.addressTown || "",
            addressPostcode: selectedInvoiceAddress?.addressPostcode || "",
        },
        isPrimaryInvoiceAddress: selectedInvoiceAddress?.isPrimaryInvoiceAddress || false,
    };

    const validationSchema = yup
        .object()
        .shape(EditInvoiceAddressFormValidationSchema);

    const methods = useForm<EditInvoiceAddressFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: displayedValues,
    });

    return {
        methods,
        editInvoiceAddress,
        isEditingInvoiceAddress,
        deleteInvoiceAddress,
        isDeletingInvoiceAddress,
    };
};

export default useEditInvoiceAddressForm;
