import { useEffect, useRef } from "react";
import { useCompanyComments } from "../../../api/admin/companyComments";
import { EditCompanyCommentsForm } from "./EditCompanyCommentsForm";
import { CommentFormValues } from "../../CommentForm/CommentFormValues";

type Props = {
    companyId?: string;
};
export default function CompanyCommentsTab({ companyId }: Props) {
    const myRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // scrolling to show the user the content in the tab.
        myRef.current && myRef.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    const {
        companyCommentsData,
        companyCommentsError,
        isLoadingCompanyComments,
        refetchCompanyComments,
        addCompanyComment,
        isAddingcompanyComment,
        editCompanyComment,
        isEditingCompanyComment,
        deleteCompanyComment,
        isDeletingCompanyComment,
    } = useCompanyComments(companyId);

    const handleAddCompanyComment = async (
        dto: CommentFormValues,
        callback: (success: boolean) => void
    ) => {
        if (!companyId) return;
        const response = await addCompanyComment(companyId, dto);

        if (response.success) {
            refetchCompanyComments();
            callback(true);
            return;
        }

        callback(false);
    };

    const handleDeleteComment = async (
        commentId: string,
        callback: (success: boolean) => void
    ) => {
        if (!companyId) return;
        const response = await deleteCompanyComment(companyId, commentId);

        if (response.success) {
            refetchCompanyComments();
            callback(true);
            return;
        }

        callback(false);
    };

    const handleEditCompanyComment = async (
        commentId: string,
        dto: CommentFormValues,
        callback: (success: boolean) => void
    ) => {
        if (!companyId) return;
        const response = await editCompanyComment(companyId, commentId, dto);

        if (response.success) {
            refetchCompanyComments();
            callback(true);
            return;
        }

        callback(false);
    };

    return (
        <EditCompanyCommentsForm
            comments={companyCommentsData}
            isLoadingComments={isLoadingCompanyComments}
            commentsError={companyCommentsError}
            onAdd={handleAddCompanyComment}
            isAdding={isAddingcompanyComment}
            onEdit={handleEditCompanyComment}
            isEditing={isEditingCompanyComment}
            onDelete={handleDeleteComment}
            isDeleting={isDeletingCompanyComment}
        />
    );
}
