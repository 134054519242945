import { StatusAction } from "../../../ActionableStatus/ActionableStatus";
import { LicenceChecksActions } from "../licenceCheckActions";
import LicenceCheckStatus from "../LicenceCheckStatus";

type ScheduledLicenceCheckStatusProps = {
    driverId: string;
    isManual: boolean;
    lastCheckDate: string;
    nextCheckDate: string;
    buildActions: (actionNames: LicenceChecksActions[]) => StatusAction[]
};

const ScheduledLicenceCheckStatus = ({
    driverId,
    isManual,
    lastCheckDate,
    nextCheckDate,
    buildActions
 }: ScheduledLicenceCheckStatusProps) => {
    const actions = buildActions(["editCheckDate", "retryCheck", "changeToManual", "disableFurtherChecks"]);

    return (
        <LicenceCheckStatus 
            actions={actions} 
            theme="vrm" 
            text="Scheduled" 
            driverId={driverId} 
            isManual={isManual}
        >
            <span>Last check: {lastCheckDate}</span>
            <span>Next check: {nextCheckDate}</span>
        </LicenceCheckStatus>
    );
};

export default ScheduledLicenceCheckStatus;