import clsx from "clsx";
import { Pencil, Prohibit, X } from "phosphor-react";
import { Dispatch, SetStateAction, useState } from "react";

type EditableContentBlockProps = {
    title: string;
    onClosed?: () => void;
    children: ({
        isEdit,
        setIsEdit,
    }: {
        isEdit: boolean;
        setIsEdit: Dispatch<SetStateAction<boolean>>;
    }) => React.ReactNode;
    disableEdit?: boolean;
    editButtonId?: string;
    buttonRef?: React.MutableRefObject<HTMLButtonElement | null>;
};

const EditableContentBlock = ({
    title,
    children,
    onClosed,
    disableEdit = false,
    editButtonId,
    buttonRef,
}: EditableContentBlockProps) => {
    const [isEdit, setIsEdit] = useState(false);

    return (
        <div
            className={clsx(
                "mb-3 rounded-sm",
                isEdit ? "bg-white" : "bg-S2D-dark-green-70.1"
            )}
        >
            <div
                className={
                    "flex h-14 w-full items-center rounded-t-sm bg-S2D-dark-green-30 px-6 text-S2D-text-menu"
                }
            >
                <h2 className="text-xl">{title}</h2>
                <div className="ml-auto">
                    <button
                        disabled={disableEdit}
                        onClick={() => setIsEdit(!isEdit)}
                        id={editButtonId}
                        ref={buttonRef}
                    >
                        <>
                            {disableEdit && <Prohibit />}
                            {!disableEdit &&
                                (isEdit ? (
                                    <X onClick={onClosed} />
                                ) : (
                                    <Pencil />
                                ))}
                        </>
                    </button>
                </div>
            </div>
            <div
                className={`rounded-b-sm border border-t-0 border-S2D-neutral-80`}
            >
                {children({ isEdit, setIsEdit })}
            </div>
        </div>
    );
};

export default EditableContentBlock;
