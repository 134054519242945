import {
    UpdateCheckStatusDto,
    UpdateCheckTypeDto,
    UpdateNextCheckDateDto,
} from "../../models/api/admin/checks";
import {
    useS2DApiFetcher,
    useS2DApiFetcherWithContent,
} from "../common/fetching";

const useUpdateNextCheckDate = () =>
    useS2DApiFetcher<
        UpdateNextCheckDateDto,
        string,
        { driverId: string; dto: UpdateNextCheckDateDto }
    >(
        "POST",
        ({ driverId, dto }) => ({
            url: `api/drivers/${driverId}/checks/update-next`,
            body: dto,
        }),
        [],
        true
    );

const useUpdateCheckType = () =>
    useS2DApiFetcher<
        UpdateCheckTypeDto,
        string,
        { driverId: string; dto: UpdateCheckTypeDto }
    >(
        "POST",
        ({ driverId, dto }) => ({
            url: `api/drivers/${driverId}/checks/type`,
            body: dto,
        }),
        [],
        true
    );

const useUpdateCheckStatus = () =>
    useS2DApiFetcher<
        UpdateCheckStatusDto,
        string,
        { driverId: string; dto: UpdateCheckStatusDto }
    >(
        "POST",
        ({ driverId, dto }) => ({
            url: `api/drivers/${driverId}/checks/status`,
            body: dto,
        }),
        [],
        true
    );

const useDvlaCheck = () =>
    useS2DApiFetcherWithContent<
        string,
        string,
        { driverId: string }
    >(
        "POST",
        ({ driverId }) => ({
            url: `api/drivers/${driverId}/licence/recheck`,
        }),
        [],
        true
    );

export const useDvlaChecks = () => {
    const [checkLicence, isCheckingLicence] = useDvlaCheck();
    const [updateCheckStatus, isUpdatingCheckStatus] = useUpdateCheckStatus();
    const [updateCheckType, isUpdatingCheckType] = useUpdateCheckType();
    const [updateNextCheckDate, isUpdatingNextCheckDate] =
        useUpdateNextCheckDate();

    return {
        checkLicence: async (driverId: string) =>
            await checkLicence({ driverId }),
        isCheckingLicence,
        updateCheckStatus: async (
            driverId: string,
            dto: UpdateCheckStatusDto
        ) => await updateCheckStatus({ driverId, dto }),
        isUpdatingCheckStatus,
        updateCheckType: async (driverId: string, dto: UpdateCheckTypeDto) =>
            await updateCheckType({ driverId, dto }),
        isUpdatingCheckType,
        updateNextCheckDate: async (
            driverId: string,
            dto: UpdateNextCheckDateDto
        ) => await updateNextCheckDate({ driverId, dto }),
        isUpdatingNextCheckDate,
    };
};
