import { ReactElement } from "react";
import { ArrowSquareOut } from "phosphor-react";
import ChartNoData from "./Chart-No-Data.svg";

const ChartContainer = ({
    children,
    title,
    onLinkClick,
    hasNoData = false,
}: {
    children: ReactElement;
    title?: string | JSX.Element;
    onLinkClick?: () => void;
    hasNoData?: boolean;
}) => (
    <div className=" w-full rounded border border-S2D-neutral-80 bg-S2D-neutral-100 p-4 pt-6">
        <div className="mb-6 flex items-start justify-between">
            {title && <h3 className="px-2 text-xl font-bold">{title}</h3>}
            {onLinkClick && (
                <button
                    className="flex items-center justify-center rounded-sm p-2 hover:bg-S2D-surface-light-4"
                    onClick={onLinkClick}
                >
                    <ArrowSquareOut size={25} />
                </button>
            )}
        </div>
        {hasNoData ? (
            <div className="flex h-[260px] flex-col items-center justify-center">
                <img src={ChartNoData} className="w-20 opacity-50" />
                <div className="p-2" />
                <p className="font-bold text-S2D-neutral-10">
                    This chart is empty for now
                </p>
                <p className=" text-center text-S2D-neutral-40">
                    There is no data yet and it will show up as soon as it's
                    available
                </p>
            </div>
        ) : (
            <>{children}</>
        )}
    </div>
);

export default ChartContainer;
