import { Warning, Envelope, WarningCircle } from "phosphor-react";
import clsx from "clsx";

type FormMessageBoxProps = {
    title: string | JSX.Element;
    message: string | JSX.Element;
    actions?: JSX.Element;
    variant: "success" | "error" | "neutral";
};

const FormMessageBox: React.FC<FormMessageBoxProps> = ({
    title,
    message,
    actions,
    variant,
}: FormMessageBoxProps) => {
    return (
        <div
            className={clsx(
                "my-4 flex items-center gap-2 p-6",
                variant === "success" &&
                    "border-[3px] border-S2D-light-green-80 bg-S2D-dark-green-70.1",
                variant === "error" &&
                    "border-[3px] border-S2D-error-40 bg-S2D-error-90",
                variant === "neutral" &&
                    "border-[3px] border-S2D-dark-green-30 bg-S2D-dark-green-70.3"
            )}
        >
            <div className="hidden flex-none pr-6 sm:block">
                {variant === "error" && (
                    <Warning
                        className="text-S2D-error-40"
                        size={56}
                        weight="duotone"
                    />
                )}
                {variant === "success" && (
                    <Envelope
                        className=" text-S2D-dark-green-30"
                        size={56}
                        weight="duotone"
                    />
                )}
                {variant === "neutral" && (
                    <WarningCircle
                        className=" text-S2D-dark-green-30"
                        size={56}
                        weight="duotone"
                    />
                )}
            </div>
            <div className="flex-1">
                <h3 className="mb-1 text-xl font-semibold">{title}</h3>
                <span>{message}</span>
            </div>
            {actions && <div className="actions">{actions}</div>}
        </div>
    );
};

export default FormMessageBox;
