import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
    EditDepartmentFormValidationSchema,
    EditDepartmentFormValues,
} from "./EditDepartmentFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDepartment } from "../../../../../api/admin/department";
import { type ApiDepartmentItem } from "../../../../../models/api/admin/department";

const useEditDepartmentForm = (
    companyId: string | undefined,
    selectedDepartment: ApiDepartmentItem | undefined
) => {
    const {
        editDepartment,
        isEditingDepartment,
        deleteDepartment,
        isDeletingDepartment,
    } = useDepartment(companyId || "");

    const displayedValues: EditDepartmentFormValues = {
        departmentName: selectedDepartment?.departmentName || "",
        contactName: selectedDepartment?.contactName || "",
        costCentre: selectedDepartment?.costCentre || "",
        invoiceAddressId: selectedDepartment?.invoiceAddressId || null,
        isInvoiceDefault: selectedDepartment?.isInvoiceDefault || false
    };

    const validationSchema = yup
        .object()
        .shape(EditDepartmentFormValidationSchema);

    const methods = useForm<EditDepartmentFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: displayedValues,
    });

    return {
        methods,
        editDepartment,
        isEditingDepartment,
        deleteDepartment,
        isDeletingDepartment,
    };
};

export default useEditDepartmentForm;
