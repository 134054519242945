import * as yup from "yup";
import { SchemaKeys } from "../../../models/shared/schemaKeysHelper";
import { ApiCompanyThemeDto } from "../../../models/api/admin/themes";

const colourSchema = yup
    .string()
    .matches(
        /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
        "Colour must be a combination of 6 lower or upper case characters (A-F) or numbers (0-9)."
    )
    .transform((value) => {
        return value.replaceAll(" ", "").replaceAll("#", "");
    });

export const EditCompanyAppearanceSchema: SchemaKeys<
    Omit<ApiCompanyThemeDto, "companyLogoUrl">
> = {
    primaryColour: colourSchema,
    primaryLightColour: colourSchema,
    primaryLighterColour: colourSchema,
    primaryVeryLightColour: colourSchema,
    primaryDarkColour: colourSchema,
    primaryDarkerColour: colourSchema,
    secondaryColour: colourSchema,
    menuTextColour: colourSchema,
    buttonTextColour: colourSchema,
};
