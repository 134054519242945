import * as yup from "yup";
import { ApiDocumentsResponse } from "../../models/api/admin/document";
import { dateValidation } from "../../utils/dateValidationYup";

export const VehicleInformationValidationSchema = {
    vehicle: yup.object().shape({
        vrm: yup.string().required("Registration number is required"),
        monthOfFirstRegistration: dateValidation.required(
            "Month of first registration is required"
        ),
        make: yup
            .string()
            .max(16, "Make must be 16 characters or less")
            .required("Make is required"),
        model: yup.string().nullable(true),
        fuelType: yup.string().nullable(true),
        co2Emissions: yup.number().nullable(true),
        ageOfVehicle: yup.string().nullable(true),
        taxExpiryDate: dateValidation.required("Tax expiry date is required"),
        motExpiryDate: dateValidation,
    }),
};

export const InsuranceInformationValidationSchema = {
    insurance: yup.object().shape({
        insuranceCompanyName: yup
            .string()
            .required("Insurance company name is required"),
        insurancePolicyNumber: yup
            .string()
            .required("Insurance policy number is required"),
        insuranceStartDate: dateValidation.required(
            "Insurance start date is required"
        ),
        insuranceEndDate: dateValidation
            .required("Insurance end date is required")
            .min(
                yup.ref("insuranceStartDate"),
                "Policy end date cannot be before the start date"
            ),
        typeOfInsurance: yup.string(),
        businessCover: yup.boolean(),
        insuranceFileCheckedDate: dateValidation.nullable(true),
    }),
};

export const BreakdownCoverInformationValidationSchema = {
    breakdownCover: yup.object().shape({
        breakdownCoverProvider: yup
            .string()
            .when("hasBreakdownCover", {
                is: (hasBreakdownCover: boolean) => hasBreakdownCover === true,
                then: yup
                    .string()
                    .required("Breakdown cover company name is required"),
            })
            .nullable(true),
        breakdownCoverPolicyNumber: yup
            .string()
            .when("hasBreakdownCover", {
                is: (hasBreakdownCover: boolean) => hasBreakdownCover === true,
                then: yup
                    .string()
                    .required("Breakdown cover policy number is required")
                    .typeError(
                        "Breakdown cover policy number must be a valid number, eg. 123456"
                    ),
            })
            .nullable(true),
        breakdownCoverStartDate: dateValidation
            .when("hasBreakdownCover", {
                is: (hasBreakdownCover: boolean) => hasBreakdownCover === true,
                then: dateValidation.required(
                    "Breakdown cover start date is required"
                ),
            })
            .when("hasBreakdownCover", {
                is: (hasBreakdownCover: boolean) => hasBreakdownCover === false,
                then: dateValidation.nullable(true),
            })
            .nullable(true),
        breakdownCoverEndDate: dateValidation
            .when("hasBreakdownCover", {
                is: (hasBreakdownCover: boolean) => hasBreakdownCover === true,
                then: dateValidation
                    .required("Breakdown cover end date is required")
                    .min(
                        yup.ref("breakdownCoverStartDate"),
                        "End date cannot be before the start date"
                    ),
            })
            .when("hasBreakdownCover", {
                is: (hasBreakdownCover: boolean) => hasBreakdownCover === false,
                then: dateValidation.nullable(true),
            })
            .nullable(true),
        hasBreakdownCover: yup.boolean(),
    }),
};

export type EditGreyFleetFormValues = {
    vehicle: {
        vrm: string;
        make: string;
        model: string;
        monthOfFirstRegistration: Date | null;
        taxExpiryDate: Date | null;
        motExpiryDate: Date | null;
        yearOfManufacture: number;
        co2Emissions: number;
        fuelType: string;
    } | null;
    insurance: {
        insuranceCertificate: {
            fileBase64: string;
            fileType: string;
            fileName: string;
        } | null;
        insuranceCompanyName: string;
        insurancePolicyNumber: string;
        insuranceStartDate: Date | null;
        insuranceEndDate: Date | null;
        insuranceType: "Comprehensive" | "ThirdParty";
        insuranceFileCheckedDate: Date | null;
        hasBusinessCover: boolean;
    } | null;
    breakdownCover: {
        hasBreakdownCover: boolean;
        breakdownCoverProvider: string;
        breakdownCoverPolicyNumber: string;
        breakdownCoverStartDate: Date | null;
        breakdownCoverEndDate: Date | null;
    } | null;
    vehicleDocuments?: ApiDocumentsResponse["vehicleDocuments"];
};

export type GreyFleetDocumentValues = {
    file: File[];
    documentType: number;
    driverId: string;
    vehicleId: string;
    signedDate: Date | null;
};
