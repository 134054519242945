import { useMemo, useState } from "react";
import TabStepper from "../TabStepper";
import Step1VerifyIdentity from "./Step1VerifyIdentity";
import { useGreyFleet, useGreyFleetBaseData } from "../../api/greyFleet";
import { GreyFleetFormPersonalDetailsValues } from "./GreyFleetFormValues";
import Step2VehicleDetails from "./Step2VehicleDetails";
import Step3InsuranceDetails from "./Step3InsuranceDetails";
import Step4BreakdownCover from "./Step4BreakdownCover";
import Step5ProcessComplete from "./Step5ProcessComplete";
import { useGreyFleetWizardStepHandlers } from "./useGreyFleetWizardStepHandlers";
import { useDriverVehicle } from "../../api/admin/driverVehicle";

type GreyFleetFormProps = {
    greyFleetInviteId: string;
    defaultStep: number;
    jointEdecGreyFleet?: boolean;
    token?: string | null;
    vehicleId?: string | null;
    update?: string | null;
};

const GreyFleetForm: React.FC<GreyFleetFormProps> = ({
    greyFleetInviteId,
    defaultStep = 0,
    jointEdecGreyFleet = false,
    token,
    vehicleId,
    update,
}) => {
    const { greyFleetBaseData, isLoading } =
        useGreyFleetBaseData(greyFleetInviteId);

    const { isValidatingGreyFleetUser, isFinalisingGreyFleetProcess } =
        useGreyFleet();

    const { isEditingDriverVehicleWithAltToken } = useDriverVehicle();

    const [selectedIndex, setSelectedIndex] = useState(defaultStep);
    const onUpdateInsuranceJourney = update === "insurance";
    const onUpdateVehicleJourney = update === "vehicle";

    //info for error states:
    const [vehicleExpiryErrorText, setVehicleExpiryErrorText] = useState("");
    const [insuranceNotStarted, setInsuranceNotStarted] = useState(false);
    const [insuranceExpired, setInsuranceExpired] = useState(false);
    const [breakdownCoverNotStarted, setBreakdownCoverNotStarted] =
        useState(false);
    const [breakdownCoverExpired, setBreakdownCoverExpired] = useState(false);

    //temporary file storage for when wanting to edit
    const [storeFile, setStoreFile] = useState<File[]>([]);

    const {
        greyFleetToken,
        changeStep,
        saveDataThenChangeStep,
        vehicleReg,
        setVehicleReg,
        greyFleetFormVehicleDetailsData,
        handleVehicleDetailsSubmit,
        handleVerificationSubmit,
        handleFinaliseGreyFleetProcess,
        handleAddAnotherVehicle,
    } = useGreyFleetWizardStepHandlers(
        greyFleetInviteId,
        selectedIndex,
        setSelectedIndex,
        onUpdateInsuranceJourney,
        onUpdateVehicleJourney,
        token,
        greyFleetBaseData,
        vehicleId
    );

    const showInsuranceCertificateWarning = useMemo(() => {
        const newDocumentUploaded = !!storeFile.length;
        // the only scenario a user won't be uploading a new certificate is on the update=vehicle journey
        return !onUpdateVehicleJourney && !newDocumentUploaded;
    }, [onUpdateInsuranceJourney, storeFile]);

    const [greyFleetFormPersonalDetailsData] =
        useState<GreyFleetFormPersonalDetailsValues>({
            greyFleetInviteId: greyFleetInviteId,
            firstName: "",
            lastName: "",
            dateOfBirth: null,
            employeeId: "",
            emailAddress: "",
        });
    
    // If the GF invite is being completed alongside the e-declaration
    // we set the driverValidationType for validation, otherwise, we skip it
    const driverValidationType = jointEdecGreyFleet ? greyFleetBaseData?.driverValidationType : undefined;

    const greyFleetSteps = () => {
        const vehicleData = greyFleetFormVehicleDetailsData.current;
        return [
            {
                title: "1. Verify Identity",
                content: (
                    <Step1VerifyIdentity
                        defaultValues={{
                            greyFleetInviteId: greyFleetInviteId,
                            firstName:
                                greyFleetFormPersonalDetailsData.firstName,
                            lastName: greyFleetFormPersonalDetailsData.lastName,
                            dateOfBirth:
                                greyFleetFormPersonalDetailsData.dateOfBirth,
                            employeeId:
                                greyFleetFormPersonalDetailsData.employeeId,
                            emailAddress:
                                greyFleetFormPersonalDetailsData.emailAddress,
                        }}
                        isLoading={isValidatingGreyFleetUser}
                        onSubmit={handleVerificationSubmit}
                        onBackClick={() => changeStep("back")}
                        company={greyFleetBaseData?.companyName ?? ""}
                        department={greyFleetBaseData?.departmentName ?? ""}
                        driverValidationType={driverValidationType}
                        jointEdecGreyFleet={jointEdecGreyFleet}
                    />
                ),
            },
            {
                title: `${jointEdecGreyFleet ? "6" : "2"}. Vehicle details`,
                content: (
                    <Step2VehicleDetails
                        defaultValues={vehicleData}
                        onSubmit={handleVehicleDetailsSubmit}
                        isLoading={isLoading}
                        company={greyFleetBaseData?.companyName ?? ""}
                        department={greyFleetBaseData?.departmentName ?? ""}
                        setVehicleReg={setVehicleReg}
                        vehicleExpiryErrorText={vehicleExpiryErrorText}
                        setVehicleExpiryErrorText={setVehicleExpiryErrorText}
                        greyFleetToken={greyFleetToken}
                    />
                ),
            },
            {
                title: `${
                    jointEdecGreyFleet
                        ? "7"
                        : onUpdateInsuranceJourney
                        ? "2"
                        : "3"
                }. Insurance details`,
                content: (
                    <Step3InsuranceDetails
                        defaultValues={{
                            insuranceCertificate:
                                vehicleData.insuranceCertificate,
                            insuranceCompanyName:
                                vehicleData.insuranceCompanyName,
                            insurancePolicyNumber:
                                vehicleData.insurancePolicyNumber,
                            insuranceStartDate: vehicleData.insuranceStartDate,
                            insuranceEndDate: vehicleData.insuranceEndDate,
                            insuranceType: vehicleData.insuranceType,
                            hasBusinessCover: vehicleData.hasBusinessCover,
                        }}
                        isLoading={isLoading}
                        onSubmit={(data) =>
                            saveDataThenChangeStep("next", data)
                        }
                        onBackClick={() => changeStep("back")}
                        company={greyFleetBaseData?.companyName ?? ""}
                        department={greyFleetBaseData?.departmentName ?? ""}
                        insuranceNotStarted={insuranceNotStarted}
                        setInsuranceNotStarted={setInsuranceNotStarted}
                        insuranceExpired={insuranceExpired}
                        setInsuranceExpired={setInsuranceExpired}
                        vehicleReg={vehicleReg}
                        storeFile={storeFile}
                        setStoreFile={setStoreFile}
                    />
                ),
            },
            {
                title: `${
                    jointEdecGreyFleet
                        ? "8"
                        : onUpdateInsuranceJourney
                        ? "3"
                        : "4"
                }. Breakdown cover`,
                content: (
                    <Step4BreakdownCover
                        defaultValues={{
                            hasBreakdownCover: vehicleData.hasBreakdownCover,
                            breakdownCompanyName:
                                vehicleData.breakdownCompanyName,
                            breakdownPolicyNumber:
                                vehicleData.breakdownPolicyNumber,
                            breakdownStartDate: vehicleData.breakdownStartDate,
                            breakdownEndDate: vehicleData.breakdownEndDate,
                        }}
                        isLoading={
                            vehicleId
                                ? isEditingDriverVehicleWithAltToken
                                : isFinalisingGreyFleetProcess
                        }
                        onSubmit={handleFinaliseGreyFleetProcess}
                        onBackClick={() => changeStep("back")}
                        company={greyFleetBaseData?.companyName ?? ""}
                        department={greyFleetBaseData?.departmentName ?? ""}
                        vehicleReg={vehicleReg}
                        breakdownCoverNotStarted={breakdownCoverNotStarted}
                        setBreakdownCoverNotStarted={
                            setBreakdownCoverNotStarted
                        }
                        breakdownCoverExpired={breakdownCoverExpired}
                        setBreakdownCoverExpired={setBreakdownCoverExpired}
                        insurerName={
                            greyFleetFormVehicleDetailsData.current
                                .insuranceCompanyName
                        }
                        insurancePolicy={
                            greyFleetFormVehicleDetailsData.current
                                .insurancePolicyNumber
                        }
                        insuranceStart={vehicleData.insuranceStartDate}
                        insuranceExpiry={vehicleData.insuranceEndDate}
                    />
                ),
            },
            {
                title: "5. Process Complete",
                content: (
                    <Step5ProcessComplete
                        isLoading={
                            vehicleId
                                ? isEditingDriverVehicleWithAltToken
                                : isFinalisingGreyFleetProcess
                        }
                        handleAddAnotherVehicle={handleAddAnotherVehicle}
                        vehicleReg={vehicleReg}
                        vehicleExpiryErrorText={vehicleExpiryErrorText}
                        insuranceExpired={insuranceExpired}
                        insuranceNotStarted={insuranceNotStarted}
                        businessUse={
                            greyFleetFormVehicleDetailsData.current
                                .hasBusinessCover === "true"
                        }
                        breakdownCoverExpired={breakdownCoverExpired}
                        breakdownCoverNotStarted={breakdownCoverNotStarted}
                        showInsuranceCertificateWarning={
                            showInsuranceCertificateWarning
                        }
                        hideButton={vehicleId ? true : false}
                    />
                ),
                hiddenTab: true,
            },
        ];
    };

    const dummyPage = {
        title: "",
        content: <></>,
    };

    const jointPages = [
        ...Array.from({ length: 5 }, () => ({ ...dummyPage })),
        ...greyFleetSteps(),
    ];
    const pages = jointEdecGreyFleet ? jointPages : greyFleetSteps();
    const updatePages = onUpdateInsuranceJourney
        ? [
              greyFleetSteps()[0],
              greyFleetSteps()[2],
              greyFleetSteps()[3],
              greyFleetSteps()[4],
          ]
        : [greyFleetSteps()[0], greyFleetSteps()[1], greyFleetSteps()[4]];

    return (
        <>
            {greyFleetBaseData && (
                <TabStepper
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    pages={update ? updatePages : pages}
                />
            )}
        </>
    );
};

export default GreyFleetForm;
