import { useMemo, useRef, useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import {
    CompanyDriverDeclarationAnalysisResponse,
    DeclarationAnalysisCategories,
    DeclarationAnalysisTabs,
} from "../../models/api/admin/companyDeclarationAnalysis";
import { useGetCompanyDeclarationAnalysis } from "../../api/admin/companyDeclarationAnalysis";
import { useDownloadComponentToPdf } from "../../hooks/useDownloadComponentToPdf";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import { ApiDepartmentItem } from "../../models/api/admin/department";
import { useGetCompanyDeclarationAnalysisReport } from "../../api/admin/companyDeclarationAnalysisReport";
import ApiError from "../../api/common/apiError";

export const useDeclarationAnalysis = (
    selectedCompany: ApiCompanyResponse | null,
    selectedDepartment: ApiDepartmentItem | null
) => {
    /* since "E-Declaration Status" and "Paper Declaration Status" use the same api option
        we need to manually define the tab number for showing the correct content. */
    const [tabLabel, setTabLabel] = useLocalStorage<DeclarationAnalysisTabs>(
        `DeclarationAnalysisSelectedTabLabel`,
        DeclarationAnalysisTabs.EDeclarations
    );

    const tabOption: number = useMemo(() => {
        switch (tabLabel) {
            case DeclarationAnalysisTabs.EDeclarations:
                return 0;
            case DeclarationAnalysisTabs.PaperDeclarations:
                return 1;
            case DeclarationAnalysisTabs.LicenceOrigin:
                return 2;
        }
    }, [tabLabel]);

    const apiTabCategory: DeclarationAnalysisCategories = useMemo(() => {
        switch (tabLabel) {
            case DeclarationAnalysisTabs.EDeclarations:
            case DeclarationAnalysisTabs.PaperDeclarations:
                return DeclarationAnalysisCategories.Declarations;
            case DeclarationAnalysisTabs.LicenceOrigin:
                return DeclarationAnalysisCategories.LicenceOrigins;
        }
    }, [tabLabel]);

    const {
        companyDeclarationAnalysis,
        isCompanyDeclarationAnalysisLoading,
        companyDeclarationAnalysisError,
        refetchCompanyDeclarationAnalysis,
    } = useGetCompanyDeclarationAnalysis(
        selectedCompany?.companyId,
        selectedDepartment?.departmentId,
        apiTabCategory
    );

    const { getDeclarationAnalysisData, isGettingDeclarationAnalysisData } =
        useGetCompanyDeclarationAnalysisReport(
            selectedCompany?.companyId,
            selectedDepartment?.departmentId
        );

    const reportRef = useRef<HTMLDivElement | null>(null);
    const { generatePDF } = useDownloadComponentToPdf(reportRef);
    const [showReportView, setShowReportView] = useState(false);
    const [isLoadingPdfReport, setIsLoadingPdfReport] = useState(false);
    const [reportData, setReportData] =
        useState<CompanyDriverDeclarationAnalysisResponse>();
    const [reportDataError, setReportDataError] = useState<ApiError>();

    const getReportDataAndDownloadPdf = async () => {
        try {
            const response = await getDeclarationAnalysisData();
            if (response.success) {
                setReportData(response.content);
            }
        } catch (error) {
            setReportDataError(error as ApiError);
        }
        handleDownloadPdfReport();
    };

    const handleDownloadPdfReport = (): void => {
        setIsLoadingPdfReport(true);
        setShowReportView(true);

        const fileNameArray = [
            selectedCompany?.companyName ?? "All clients",
            selectedDepartment?.departmentName ?? "All departments",
            "Driver Declarations report",
        ];
        generatePDF(() => {
            setShowReportView(false);
            setIsLoadingPdfReport(false);
        }, fileNameArray.join("-"));
    };

    const refetchDeclarationAnalysisData = () => {
        refetchCompanyDeclarationAnalysis();
    };

    return {
        companyDeclarationAnalysis,
        isCompanyDeclarationAnalysisLoading,
        companyDeclarationAnalysisError,
        tabOption,
        setTabLabel,
        pdfDeclarationAnalysisData: reportData,
        isLoadingPdfDeclarationAnalysisData: isGettingDeclarationAnalysisData,
        pdfDeclarationAnalysisError: reportDataError,
        reportRef,
        showReportView,
        isLoadingPdfReport,
        handleDownloadPdfReport: getReportDataAndDownloadPdf,
        refetchDeclarationAnalysisData,
    };
};
