import { Pencil, Trash, X } from "phosphor-react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormDropdown from "../FormDropdown";
import FormDateInput from "../FormDateInput";
import FormTextInput from "../FormTextInput";
import Button from "../Button";
import { DvlaEndorsement } from "../../models/api/admin/dvla";
import { convertDateStringToReadableString } from "../../utils/convertDateStringToReadableString";
import { useEndorsement } from "../../api/admin/endorsements";
import { useParams } from "react-router-dom";
import { EndorsementCodeOption } from "./forms/EditEndorsementsForm";
import { errorToast, successToast } from "../../toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { EditDvlaEndorsementFormValidationSchema } from "./EditDvlaFormValues";
import clsx from "clsx";

type EndorsementUnitProps = {
    endorsement: DvlaEndorsement;
    showEditable: boolean;
    index: number;
    endorsementCodes: EndorsementCodeOption[];
    refetch: () => void;
};

const validationSchema = yup.object().shape({
    ...EditDvlaEndorsementFormValidationSchema,
});

const EndorsementUnit = ({
    endorsement,
    showEditable,
    endorsementCodes,
    refetch,
}: EndorsementUnitProps) => {
    const {
        code,
        description,
        offenceDate,
        convictionDate,
        disqualificationEndDate,
        points,
        endorsementId,
    } = endorsement;
    const [isBeingEdited, setIsBeingEdited] = useState(false);

    const { id } = useParams<{ id: string }>();

    const {
        editEndorsement,
        isEditingEndorsement,
        deleteEndorsement,
        isDeletingEndorsement,
    } = useEndorsement(id, endorsementId);

    const { handleSubmit, control, register, formState, reset } =
        useForm<DvlaEndorsement>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues: endorsement,
        });

    const handleEdit = async (data: DvlaEndorsement) => {
        if (!id) {
            errorToast("There was an error");
            return;
        }

        const response = await editEndorsement(data);

        if (response.success) {
            successToast("Endorsement edited successfully");
            setIsBeingEdited(false);
            refetch();
            reset();
        }

        if (!response.success) {
            errorToast("There was an error");
        }
    };

    const handleDelete = async () => {
        setIsBeingEdited(false);
        if (!id) {
            errorToast("There was an error");
            return;
        }

        const response = await deleteEndorsement();

        if (response.success) {
            successToast("Endorsement deleted successfully");
            refetch();
            reset();
        }

        if (!response.success) {
            errorToast("There was an error");
        }
    };

    return (
        <>
            <div
                className={clsx(
                    "flex w-full flex-col items-center justify-center gap-6 rounded-sm border p-4 md:flex-row md:justify-between",
                    +points >= 6
                        ? "border-S2D-error-40 bg-S2D-error-90"
                        : "border-S2D-warning-60 bg-S2D-warning-95"
                )}
            >
                <div className="flex ">
                    <div className="flex flex-col items-center justify-center pr-2">
                        <div className="rounded-sm bg-S2D-neutral-10 px-2 py-1 text-sm capitalize text-white">
                            {code}
                        </div>
                    </div>

                    <div className="flex flex-col px-2">
                        <span className="">{description}</span>
                        <div className="flex flex-row">
                            <span className="pr-2 text-xs">
                                <>
                                    Offence:{" "}
                                    {offenceDate &&
                                        convertDateStringToReadableString(
                                            offenceDate?.toString()
                                        )}
                                </>
                            </span>
                            {convictionDate && (
                                <span className="pl-2 text-xs">
                                    <>
                                        Conviction:{" "}
                                        {convictionDate &&
                                            convertDateStringToReadableString(
                                                convictionDate?.toString()
                                            )}
                                    </>
                                </span>
                            )}

                            {disqualificationEndDate && (
                                <span className="pl-2 text-xs">
                                    <>
                                        Disqualification End Date:{" "}
                                        {disqualificationEndDate &&
                                            convertDateStringToReadableString(
                                                disqualificationEndDate?.toString()
                                            )}
                                    </>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex w-full shrink-0 flex-row justify-end gap-4 md:w-fit lg:py-2">
                    <div className="flex font-semibold">
                        {points} penalty points
                    </div>
                    {showEditable && (
                        <>
                            <div className="flex items-center justify-center gap-4">
                                {!isBeingEdited ? (
                                    <Pencil
                                        size={24}
                                        weight="fill"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setIsBeingEdited(true);
                                        }}
                                    />
                                ) : (
                                    <X
                                        size={24}
                                        weight="fill"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setIsBeingEdited(false);
                                        }}
                                    />
                                )}
                                <Trash
                                    size={24}
                                    weight="fill"
                                    className={clsx(
                                        !isDeletingEndorsement &&
                                            "cursor-pointer",
                                        isDeletingEndorsement &&
                                            "cursor-not-allowed text-S2D-neutral-30"
                                    )}
                                    onClick={() => handleDelete()}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            {isBeingEdited && (
                <form
                    onSubmit={handleSubmit(handleEdit)}
                    className="mt-4 flex flex-col gap-2"
                >
                    <p className="text-xl font-semibold">Edit Endorsement</p>
                    <Controller
                        {...register("code")}
                        name="code"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdown
                                label="Endorsement code"
                                options={endorsementCodes}
                                value={value}
                                onChange={onChange}
                                required
                                pleaseSelectText="Enter endorsement code"
                                error={formState.errors.code}
                            />
                        )}
                    />
                    <Controller
                        name="offenceDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormDateInput
                                id="offenceDate"
                                label="Offense date"
                                value={value}
                                onChange={onChange}
                                error={formState.errors.offenceDate}
                                maxDate={new Date()}
                                required
                            />
                        )}
                    />
                    <Controller
                        name="convictionDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormDateInput
                                id="convictionDate"
                                label="Conviction date"
                                value={value}
                                onChange={onChange}
                                error={formState.errors.convictionDate}
                                maxDate={new Date()}
                            />
                        )}
                    />
                    <Controller
                        name="disqualificationEndDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormDateInput
                                id="disqualificationEndDate"
                                label="Disqualification end date"
                                helperText="if applicable"
                                value={value}
                                onChange={onChange}
                                error={formState.errors.disqualificationEndDate}
                                minDate={new Date()}
                            />
                        )}
                    />
                    <FormTextInput
                        label="Penalty points"
                        id="points"
                        placeholder="Enter penalty points"
                        error={formState.errors.points}
                        {...register("points")}
                        type="number"
                        min={0}
                        required
                    />

                    <div className="mt-4 flex justify-between">
                        <Button
                            variant="tertiary"
                            onClick={() => {
                                setIsBeingEdited(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="border border-black bg-S2D-neutral-100 hover:border-black hover:bg-S2D-neutral-10 hover:text-white"
                            type="submit"
                            loading={isEditingEndorsement}
                        >
                            Save Endorsement
                        </Button>
                    </div>
                    <div className="mt-4 border-t border-S2D-neutral-80" />
                </form>
            )}
        </>
    );
};

export default EndorsementUnit;
