import * as yup from "yup";
import { SchemaKeys } from "../../../../../models/shared/schemaKeysHelper";
import { ApiInvoiceReferenceType } from "../../../../../models/api/admin/invoiceReferenceNumber";

export type AddInvoiceReferenceNumberFormValues = {
    type: ApiInvoiceReferenceType;
    departmentId: string | null;
    numbers: { value: string }[];
};

export const AddInvoiceReferenceNumberFormValidationSchema: SchemaKeys<AddInvoiceReferenceNumberFormValues> = {
    departmentId: yup
        .string()
        .nullable()
        .required("Please select a department or company."),
    type: yup
        .mixed<ApiInvoiceReferenceType>()
        .oneOf(Object.values(ApiInvoiceReferenceType), "Invalid type.")
        .required("Please select a type."),
    numbers: yup
        .array()
        .of(
            yup.object().shape({
                value: yup.string().required("Please enter a number.").max(50, "Number must be less than 50 characters."),
            })
        )
        .min(1, "Please add at least one number.")
        .required("Please add numbers."),
};
