import { useEffect } from "react";
import useLocalStorage from "./useLocalStorage";

// locally stored value for scrolling to table when navigating to a page
export const useScrollToTableBool = () => {
    const [scrollToTable, setScrollToTable] = useLocalStorage<boolean>(
        "ScrollToTable",
        false
    );

    return {
        scrollToTable,
        setScrollToTable,
    };
};

// trigger this on the page you're navigating to
export const useScrollToTable = (
    hasLoaded: boolean,
    ref?: React.MutableRefObject<HTMLDivElement | null>
) => {
    const { scrollToTable, setScrollToTable } = useScrollToTableBool();

    useEffect(() => {
        if (!scrollToTable) return;
        if (!ref?.current) return;

        if (hasLoaded) {
            window.scrollTo(0, ref.current.offsetTop);
            setScrollToTable(false);
        }
    }, [hasLoaded]);
};
