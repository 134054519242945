import { PencilSimple, Trash, XCircle, Check } from "phosphor-react";
import {
    type ApiStaffMembersResponse,
    type S2DStaffMember,
} from "../../models/api/safe2DriveAdmin/staff";
import useLocalStorage from "../../hooks/useLocalStorage";
import { type TableHeader } from "../ReusableComponents/Table";
import {
    initStaffTableContents,
    initStaffTableHeaders,
} from "./StaffTableInit";
import LoadingMessage from "../ReusableComponents/LoadingMessage/LoadingMessage";
import Table from "../ReusableComponents/Table/Table";
import PaginationNav from "../PaginationNav";
import { TableMenuItem } from "../ReusableComponents/Table/TableMenu";
import ApiError from "../../api/common/apiError";
import FormMessageBox from "../FormMessageBox";
import { UserStatus } from "../../models/shared/statuses";
import useAuth from "../../auth";
import { canEditUser } from "../../auth/userAccessHelpers";

export type StaffTableProps = {
    staffData?: ApiStaffMembersResponse;
    isLoadingStaff: boolean;
    staffError?: ApiError;
    pageNumber: number;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    handleMenuSelect: (id: string, type: ValidStaffMenuItems) => void;
};

export type ValidStaffMenuItems = "edit" | "delete" | "disable" | "enable";

const StaffTable: React.FC<StaffTableProps> = ({
    staffData,
    isLoadingStaff,
    staffError,
    setPageNumber,
    pageSize,
    setPageSize,
    handleMenuSelect,
}) => {
    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "StaffTableHeaders",
        initStaffTableHeaders
    );

    const { decodedToken } = useAuth();
    const canEdit = canEditUser(decodedToken);

    const staffTableMenuItems = (data: S2DStaffMember): TableMenuItem[] => {
        const id = data.userId;
        const status = data.status;
        const isLive = status.toLowerCase() === UserStatus.Live.toLowerCase();

        return [
            {
                key: "editStaff",
                onClick: () => {
                    handleMenuSelect(id, "edit");
                },
                icon: <PencilSimple size={20} weight="bold" />,
                text: "Edit staff",
                isDisabled: !canEdit,
            },
            {
                key: "disable",
                onClick: () => {
                    handleMenuSelect(id, isLive ? "disable" : "enable");
                },
                icon: isLive ? (
                    <XCircle size={20} weight="fill" />
                ) : (
                    <Check size={20} weight="fill" />
                ),
                text: `${isLive ? "Disable" : "Enable"} staff`,
                isDisabled: !canEdit,
            },
            {
                key: "delete",
                onClick: () => {
                    handleMenuSelect(id, "delete");
                },
                icon: <Trash size={20} weight="fill" />,
                text: "Delete user",
                isDisabled: !canEdit,
            },
        ];
    };

    return (
        <>
            {isLoadingStaff && <LoadingMessage />}
            {staffError && (
                <FormMessageBox
                    variant="error"
                    title={"Error loading staff"}
                    message={
                        "There was an error loading staff data, please try again later."
                    }
                />
            )}
            <Table
                tableHeaders={tableHeaders}
                setTableHeaders={setTableHeaders}
                tableContents={initStaffTableContents(staffData?.items)}
                menuItems={staffTableMenuItems}
            />
            {!isLoadingStaff && staffData && (
                <>
                    {staffData?.items.length === 0 && (
                        <div className="rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-4 text-S2D-neutral-10">
                            No users found
                        </div>
                    )}
                    <PaginationNav
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalCount={staffData.totalCount}
                        totalPages={staffData.totalPages}
                        currentPage={staffData.pageNumber}
                        hasPreviousPage={staffData.hasPreviousPage}
                        hasNextPage={staffData.hasNextPage}
                    />
                </>
            )}
        </>
    );
};

export default StaffTable;
