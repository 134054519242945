import { yupResolver } from "@hookform/resolvers/yup";

// 24/11/23 use this function to debug your yup validation schema (see EditDriverForm.tsx for example)
export const resolverDebugger = async (
    data: any,
    context: any,
    options: any,
    validationSchema: any
) => {
    // you can debug your validation schema here
    const validationResult = await yupResolver(validationSchema)(
        data,
        context,
        options
    );
    // console.log("formData", data);
    // console.log("validation result", validationResult);

    return validationResult;
};
