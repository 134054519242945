import { useState } from "react";
import * as yup from "yup";

import EDeclarationFormLayout from "./EDeclarationFormLayout";
import Button from "../Button";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../FormTextInput";

import {
    EDeclarationFormValues,
    eDeclarationFormVerifyIdentitySchemaFields,
} from "./EDeclarationFormValues";
import FormMessageBox from "../FormMessageBox";
import FormDateInput from "../FormDateInput";
import { DriverValidationTypes } from "../../models/app/admin/company";

export type Step1VerifyIdentityFields = Pick<
    EDeclarationFormValues,
    "firstName" | "lastName" | "dateOfBirth" | "employeeId" | "emailAddress"
>;

type Step1VerifyIdentityProps = {
    defaultValues: Step1VerifyIdentityFields;
    isLoading: boolean;
    onSubmit: (
        values: Step1VerifyIdentityFields,
        callback: (error: true) => void
    ) => void;
    onBackClick: () => void;
    driverValidationType: DriverValidationTypes;
};

const Step1VerifyIdentity: React.FC<Step1VerifyIdentityProps> = ({
    defaultValues,
    isLoading,
    onSubmit,
    driverValidationType,
}) => {
    const [validationFailed, setValidationFailed] = useState(false);
    const validationSchema = yup
        .object()
        .shape(
            eDeclarationFormVerifyIdentitySchemaFields(driverValidationType)
        );

    const { handleSubmit, formState, register, control } =
        useForm<Step1VerifyIdentityFields>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues,
        });

    const handleVerificationSubmit = (data: Step1VerifyIdentityFields) => {
        onSubmit(data, setValidationFailed);
    };

    return (
        <form
            onSubmit={handleSubmit(handleVerificationSubmit)}
            className="flex flex-1 flex-col"
        >
            <EDeclarationFormLayout
                title="E-Declaration"
                subtitle="Verify your Identity"
                description="To verify your identity, please enter the requested information below about yourself. The information entered must match your DVLA record."
                content={
                    <>
                        {validationFailed && (
                            <FormMessageBox
                                title="Could not verify identity."
                                message="Some of the information entered does not
                            match our records. Please correct any
                            mistakes in the information entered. "
                                variant="error"
                            />
                        )}
                        <div className="sm:grid sm:grid-cols-2 sm:gap-8">
                            <div>
                                <FormTextInput
                                    label="First Name"
                                    required
                                    id="firstName"
                                    placeholder="Enter first name"
                                    error={formState.errors.firstName}
                                    {...register("firstName")}
                                />
                                <FormTextInput
                                    label="Surname"
                                    required
                                    id="lastName"
                                    placeholder="Enter surname"
                                    error={formState.errors.lastName}
                                    {...register("lastName")}
                                />
                                {driverValidationType ===
                                    DriverValidationTypes.DateOfBirth && (
                                    <Controller
                                        name="dateOfBirth"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FormDateInput
                                                required={
                                                    driverValidationType ===
                                                    DriverValidationTypes.DateOfBirth
                                                }
                                                id="dateOfBirth"
                                                label="Date of Birth"
                                                value={value}
                                                onChange={onChange}
                                                error={
                                                    formState.errors.dateOfBirth
                                                }
                                                maxDate={new Date()}
                                            />
                                        )}
                                    />
                                )}
                                {driverValidationType ===
                                    DriverValidationTypes.EmployeeId && (
                                    <FormTextInput
                                        label="Employee Id"
                                        required={
                                            driverValidationType ===
                                            DriverValidationTypes.EmployeeId
                                        }
                                        id="employeeId"
                                        placeholder="Enter employee id"
                                        error={formState.errors.employeeId}
                                        {...register("employeeId")}
                                    />
                                )}
                                <FormTextInput
                                    label="Email Address"
                                    required
                                    id="emailAddress"
                                    placeholder="Enter email address"
                                    error={formState.errors.emailAddress}
                                    {...register("emailAddress")}
                                />
                            </div>
                        </div>
                    </>
                }
                buttons={
                    <div className="flex justify-end gap-8">
                        <Button type="submit" loading={isLoading}>
                            Next
                        </Button>
                    </div>
                }
            />
        </form>
    );
};

export default Step1VerifyIdentity;
