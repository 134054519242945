import { useNavigate } from "react-router-dom";
import { useVerifiedUser } from "../../api/user";
import PasswordResetForm from "../../components/PasswordResetForm";
import { PasswordResetFormValues } from "../../components/PasswordResetForm/PasswordResetFormValues";
import { appPaths } from "../../Routes";
import { errorToast, successToast } from "../../toast";
import DialogBox from "../../components/DialogBox";
import useAuth from "../../auth/useAuth";
import ApiError from "../../api/common/apiError";

type PasswordResetModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const PasswordResetModal: React.FC<PasswordResetModalProps> = ({
    isOpen,
    onClose,
}) => {
    const { resetPassword, isResettingPassword } = useVerifiedUser();
    const { userDetails } = useAuth();
    const navigate = useNavigate();

    const id = userDetails?.userId;

    const handleResetPassword = async (data: PasswordResetFormValues) => {
        if (!id) {
            errorToast("No user details found.");
            return;
        }

        const d = {
            existingPassword: data.existingPassword,
            newPassword: data.newPassword,
        };

        const res = await resetPassword(id, d);
       
        if (res.success) {
            successToast("Password successfully reset");
            onClose();
            navigate(appPaths.dashboard);
        } else {
            errorToast(res.error.statusCode === 403 ? "Incorrect current password" : "There was an error");
            //errors could be user not found or network error
        }
    };

    return (
        <DialogBox isOpen={isOpen} onClose={onClose}>
            <div className="max-w-lg rounded-sm bg-white p-4">
                <PasswordResetForm
                    onSubmit={handleResetPassword}
                    onClose={onClose}
                    isLoading={isResettingPassword}
                />
            </div>
        </DialogBox>
    );
};

export default PasswordResetModal;
