import { type DriverDeclaration } from "../../models/api/admin/eDeclarations";
import { DeclarationStatus } from "../../models/shared/statuses";
import { type TableCheckbox } from "../ReusableComponents/Table/tableTypes";
import { type DriverDeclarationBulkActionType } from "./driverDeclarationsBulkActions";

const dataArrayType = (
    data: DriverDeclaration
): DriverDeclarationBulkActionType => {
    // completed declarations have no actions to take
    if (data.declarationStatus === DeclarationStatus.Completed) {
        return undefined;
    }
    switch (data.declarationType) {
        case "EDeclaration":
            return "eDeclaration";
        case "PaperDeclaration":
            return "paperDeclaration";
        default:
            return undefined;
    }
};

export const determineDriverDeclarationsBulkActionType = (
    selectedItems: TableCheckbox<DriverDeclaration>[]
) => {
    // if all types are the same or similar, return relevant set of options
    // otherwise return undefined.
    let optionType: DriverDeclarationBulkActionType;
    const dataArray: TableCheckbox<DriverDeclaration>[] = new Array(
        ...selectedItems
    );

    if (!dataArray.length) {
        optionType = undefined;
    } else if (dataArray.length === 1) {
        optionType = dataArrayType(dataArray[0].data);
    } else {
        // compare first two items.
        // if they don't have the same option type, return undefined.
        // if they are the same option type, remove first item and repeat.
        const determineOptionType = () => {
            const firstItemOptionType = dataArrayType(dataArray[0].data);
            const secondItemOptionType = dataArrayType(dataArray[1].data);

            if (
                firstItemOptionType !== secondItemOptionType ||
                firstItemOptionType === undefined
            ) {
                optionType = undefined;
            } else {
                optionType = firstItemOptionType;
                dataArray.shift();
                if (dataArray.length > 1) {
                    determineOptionType();
                }
            }
        };

        determineOptionType();
    }

    return optionType;
};
