import { NORTHERN_IRELAND } from "../../constants/countries";
import Button from "../Button";
import DialogBox from "../DialogBox";

type ConfirmForeignLicenceDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    licenceOrigin: "Foreign" | typeof NORTHERN_IRELAND;
    handleForeignLicenceSubmit: () => void;
    error: boolean;
};

const ConfirmForeignLicenceDialog: React.FC<
    ConfirmForeignLicenceDialogProps
> = ({ isOpen, onClose, licenceOrigin, handleForeignLicenceSubmit, error }) => {
    const licenceType =
        licenceOrigin === "Foreign" ? licenceOrigin : "Northern Irish";

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={onClose}
            title={`${licenceType} Licence Holders`}
            size="large"
        >
            <div className="flex flex-col gap-4 px-6 pb-6">
                <p>
                    If you are a {licenceType} Licence holder, a declaration
                    form must be completed. To confirm, please click on the
                    "Declare {licenceType} Licence" button below.
                </p>
                <p>
                    The E-Declaration process will be cancelled and you will be
                    sent an email to continue with our Paper Declaration form.
                    You will also receive a confirmation email.
                </p>
                {error && (
                    <div className="ml-1 mb-4 block text-xs text-S2D-error-40">
                        There was a problem with this request, please try again.
                    </div>
                )}
                <Button
                    type="button"
                    className="flex w-full justify-center"
                    onClick={handleForeignLicenceSubmit}
                >
                    Declare {licenceType} Licence
                </Button>
                <Button
                    type="button"
                    variant="tertiary"
                    className="flex w-full justify-center"
                    onClick={onClose}
                >
                    Back to E-Declaration
                </Button>
            </div>
        </DialogBox>
    );
};

export default ConfirmForeignLicenceDialog;
