import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import clsx from "clsx";
import AlertCountBadge from "../../DashboardAlerts/AlertCountBadge";

// NB: this component can only be used inside a headlessui/react Tab.List
const NewTabWithIcon = ({
    icon,
    title,
    count,
}: {
    icon: JSX.Element;
    title: string;
    count?: number;
}) => {
    return (
        <Tab as={Fragment}>
            {({ selected }) => (
                <div
                    className={clsx(
                        `flex h-11 cursor-pointer flex-row items-center justify-center gap-2 px-4 font-semibold `,
                        selected &&
                            "bg-S2D-light-green-80 text-S2D-text-button focus:outline-none"
                    )}
                >
                    {icon}
                    <div className="whitespace-nowrap">{title}</div>

                    {typeof count === "number" && (
                        <AlertCountBadge count={count} />
                    )}
                </div>
            )}
        </Tab>
    );
};

export default NewTabWithIcon;
