import * as yup from "yup";
import { SchemaKeys } from "../../models/shared/schemaKeysHelper";
import { AddCompanyUserDto } from "../../models/app/admin/companyUser";
import { UserRoles } from "../../auth/decodeToken";

export const AddCompanyUserFormValidationSchema: SchemaKeys<AddCompanyUserFormValues> =
    {
        firstName: yup.string().required("Please enter the user's first name."),
        lastName: yup.string().required("Please enter the user's last name."),
        emailAddress: yup
            .string()
            .email()
            .required("Please enter the user's email address."),
        departmentIds: yup.array().notRequired(),
        roleName: yup.string().required("Please select a permission level."),
        claims: yup.object().shape({
            "file-safe": yup.boolean().required(),
            "view-pii": yup.boolean().required(),
            "client-management": yup.boolean().required(),
        }),
        shouldReceiveEmailNotifications: yup.boolean().required()
    };

export type AddCompanyUserFormValues = AddCompanyUserDto;

export const addCompanyUserFormDefaultValues: AddCompanyUserFormValues = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    departmentIds: [],
    claims: {
        "file-safe": false,
        "view-pii": false,
        "client-management": false,
    },
    roleName: UserRoles.clientMember,
    shouldReceiveEmailNotifications: false
};
