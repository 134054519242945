import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { AddResellerDto } from "../../models/app/admin/reseller";
import Button from "../Button";
import FormTextInput from "../FormTextInput";
import {
    AddResellerFormValues,
    AddResellerValidationSchemaFields,
} from "./AddResellerFormValues";

type AddResellerFormProps = {
    onSubmit: (
        data: AddResellerFormValues,
        callback: (error: boolean) => void
    ) => Promise<void>;
    isLoading: boolean;
};

const validationSchema = yup.object().shape(AddResellerValidationSchemaFields);

const AddResellerForm: React.FC<AddResellerFormProps> = ({
    onSubmit,
    isLoading,
}) => {
    const { handleSubmit, formState, register, getValues, reset } =
        useForm<AddResellerFormValues>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues: {
                resellerName: "",
                logoUrl: "",
            },
        });
    const [logo, setLogo] = useState<string>();

    const handleLogo = () => {
        setLogo(getValues("logoUrl"));
    };
    const handleSubmitCallback = (error: boolean) => {
        if (!error) {
            reset();
            setLogo("");
        }
    };

    const handleAddSubmit = (data: AddResellerDto) => {
        onSubmit(data, handleSubmitCallback);
    };

    return (
        <form onSubmit={handleSubmit(handleAddSubmit)}>
            <div className="max-w-xl m-auto bg-white p-4">
                <FormTextInput
                    label="Reseller Name"
                    required
                    id="resellerName"
                    placeholder="Enter first name"
                    error={formState.errors.resellerName}
                    {...register("resellerName")}
                />
                <FormTextInput
                    label="Logo Url"
                    required
                    id="logoUrl"
                    placeholder="Enter logo url"
                    error={formState.errors.logoUrl}
                    {...register("logoUrl")}
                    onBlur={handleLogo}
                />
                {logo && (
                    <div className="p-4 m-4 max-w-fit">
                        <img src={logo} alt={logo} />
                    </div>
                )}
            </div>
            <div className="flex justify-end py-8">
                <Button type="submit" loading={isLoading}>
                    Add Reseller
                </Button>
            </div>
        </form>
    );
};

export default AddResellerForm;
