import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { S2DApiFetcherResponse } from "../../api/common/apiResponse";
import { type ApiDocumentResponse } from "../../models/api/admin/document";
import { DocumentTypes } from "../../models/app/admin/document";
import { DocumentBase64 } from "../../models/app/admin/document";
import { errorToast, successToast } from "../../toast";
import ConfirmationDialog from "../ReusableComponents/ConfirmationDialog";
import DocumentItem from "../DocumentItem";
import FormDateInput from "../FormDateInput";
import FormDocumentUpload from "../FormDocumentUpload/FormDocumentUpload";
import FormDropdown, { FormDropdownOption } from "../FormDropdown";
import EditableContentBlock from "../EditableContentBlock";
import { setYear } from "date-fns";
import { dateValidation } from "../../utils/dateValidationYup";
import { anchorDate } from "../../utils/anchorDate";
import { localToUTC } from "../../utils/localToUTC";
import { canEditPii, hasClientManagementAccess } from "../../auth/userAccessHelpers";
import useAuth from "../../auth";

export type EditDriverDocumentsFormProps = {
    onSubmit: (
        data: DocumentBase64,
        callback: (value: boolean) => void
    ) => Promise<void>;
    onDelete: (fileId: string) => Promise<S2DApiFetcherResponse<void>>;
    isDeleting: boolean;
    initialValues: ApiDocumentResponse[];
    documentUploadRef: React.MutableRefObject<HTMLButtonElement | null>;
};

// /**
//  * Maximum date is today, but must also be normalised to one second after midnight,
//  * as the values coming out of the datepicker will have the time defaulted
//  * to whatever the current time is, which could be as late as 11:59pm.
//  */
const today = anchorDate(localToUTC(new Date()), "max"),
    threeYearsAgo = today && setYear(today, today.getFullYear() - 3);

const documentTypeOptions: FormDropdownOption<DocumentTypes>[] = [
    {
        label: "Driver declaration",
        value: DocumentTypes.DriverDeclaration,
    },
    {
        label: "Other document",
        value: DocumentTypes.Other,
    },
];

const validationSchema = yup.object().shape({
    documentBase64: yup.string(),
    fileType: yup.string(),
    fileName: yup.string(),
    documentType: yup.number(),
    signedDate: dateValidation
        .max(today, "Date of signing cannot be in the future")
        .min(threeYearsAgo, "This declaration has already expired.")
        .when("documentType", {
            is: (documentType: number) => {
                if (documentType === DocumentTypes.DriverDeclaration) {
                    return true;
                }

                return false;
            },
            then: dateValidation.required("Date signed is required").nullable(),
        }),
});

export const editDriverDocumentButtonId = "document-upload-edit-button";

const EditDriverDocuments = ({
    onSubmit,
    onDelete,
    isDeleting,
    initialValues,
    documentUploadRef,
}: EditDriverDocumentsFormProps) => {
    const [fileIdToBeDeleted, setFileIdToBeDeleted] = useState<string>();
    
    const auth = useAuth();
    
    const isClientUserWithPii = hasClientManagementAccess(auth.decodedToken) && canEditPii(auth.decodedToken);
    const defaultDocumentType = isClientUserWithPii ? DocumentTypes.Other : DocumentTypes.DriverDeclaration;

    const { control, setValue, formState, trigger, handleSubmit } =
        useForm<DocumentBase64>({
            // mode is 'all' because validation does re-not run when selecting a valid date
            // after uploading a document when set to 'onSubmit'
            mode: "all",
            resolver: yupResolver(validationSchema),
            defaultValues: {
                documentBase64: "",
                fileType: "",
                fileName: "",
                documentType: defaultDocumentType,
                signedDate: new Date(),
            },
        });

    const documentType = useWatch({ control, name: "documentType" });
    const signedDate = useWatch({ control, name: "signedDate" });

    useEffect(() => {
        if (isClientUserWithPii) {
            setValue("documentType", DocumentTypes.Other);
        }
    }, [isClientUserWithPii, setValue]);

    const handleSetData = (
        documentBase64: string,
        fileType: DocumentBase64["fileType"],
        fileName: string
    ) => {
        setValue("documentType", documentType);
        const isDeclarationDocument =
            documentType === DocumentTypes.DriverDeclaration;
        setValue("signedDate", isDeclarationDocument ? signedDate : undefined);
        setValue("documentBase64", documentBase64);
        setValue("fileType", fileType);
        setValue("fileName", fileName);
        trigger();
    };

    const handleCancelAddDoc = () => {
        setValue("documentBase64", "");
        setValue("fileType", "");
        setValue("fileName", "");
    };

    const handleCloseDeleteFileConfirmation = () =>
        setFileIdToBeDeleted(undefined);

    const handleDeleteFileConfirmation = async () => {
        if (fileIdToBeDeleted) {
            const response = await onDelete(fileIdToBeDeleted);

            if (response.success) {
                handleCloseDeleteFileConfirmation();
                successToast("File has been deleted");
                return;
            }

            errorToast(
                "There was a problem deleting this file, please try again"
            );
        }
    };

    const handleFileTypeChange = (value: DocumentTypes) => {
        setValue("signedDate", null);
        setValue("documentType", value);
        trigger();
    };

    const shouldDisableDocUpload =
        documentType === DocumentTypes.DriverDeclaration &&
        !!formState.errors.signedDate;

    return (
        <>
            <EditableContentBlock
                title="Documents"
                editButtonId={editDriverDocumentButtonId}
                buttonRef={documentUploadRef}
            >
                {({ isEdit, setIsEdit }) => (
                    <>
                        {!isEdit && (
                            <div className="flex flex-col gap-4 space-y-2 border border-t-0 p-6">
                                {!initialValues.length && (
                                    <p>
                                        This driver has no associated documents.
                                    </p>
                                )}
                                {initialValues.map((document) => {
                                    const showDeleteButton = !(document.documentType === "DriverDeclaration" && isClientUserWithPii);
                                    return (
                                        <DocumentItem
                                            key={document.fileId}
                                            document={document}
                                            onDelete={setFileIdToBeDeleted}
                                            showDeleteButton={showDeleteButton}
                                        />
                                    );
                                })}
                            </div>
                        )}
                        {isEdit && (
                            <div className="border border-t-0 border-S2D-neutral-80 p-6">
                                <div className="relative flex flex-col gap-4">
                                    {!isClientUserWithPii && (
                                        <Controller
                                            name="documentType"
                                            control={control}
                                            render={({ field: { value } }) => (
                                                <FormDropdown
                                                    label="Document type"
                                                    options={documentTypeOptions}
                                                    pleaseSelectText="Select document type"
                                                    value={value}
                                                    onChange={handleFileTypeChange}
                                                />
                                            )}
                                        />
                                    )}
                                    {documentType ===
                                        DocumentTypes.DriverDeclaration && (
                                        <Controller
                                            name="signedDate"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <FormDateInput
                                                    required
                                                    id="dateSigned"
                                                    label="Date signed"
                                                    value={value ?? null}
                                                    onChange={onChange}
                                                    maxDate={today}
                                                    error={
                                                        formState.errors
                                                            .signedDate
                                                    }
                                                />
                                            )}
                                        />
                                    )}
                                    <FormDocumentUpload
                                        onAutoAdd={handleSubmit((value) =>
                                            onSubmit(value, setIsEdit)
                                        )}
                                        handleSetData={handleSetData}
                                        handleCancelAddDoc={handleCancelAddDoc}
                                        disabled={shouldDisableDocUpload}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </EditableContentBlock>
            <ConfirmationDialog
                open={Boolean(fileIdToBeDeleted)}
                title="Delete document?"
                caption="This action can't be reversed."
                cancelButtonLabel="Cancel"
                confirmButtonLabel="Delete"
                variant="danger"
                isLoading={isDeleting}
                onClose={handleCloseDeleteFileConfirmation}
                onConfirm={handleDeleteFileConfirmation}
            />
        </>
    );
};

export default EditDriverDocuments;
