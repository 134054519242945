import { Dispatch, SetStateAction } from "react";
import { errorToast, successToast } from "../../toast";
import { type DriversListFiltersFormValues } from "./DriversListFiltersForm/DriversListFiltersFormValues";
import { type SaveEditFilterValues } from "./DriversListSaveEditFilterValues";
import { useFilter } from "../../api/admin/filter";
import { type FilterType } from "../../models/app/admin/filter";
import { DriverCategory } from "../../utils/driverPageLocation";
import { driverListFiltersDefaultValues } from "./DriversListFiltersForm/driversListFiltersDefaultValues";

export const useSavedFilters = (
    isEdit: boolean,
    filterValues: DriversListFiltersFormValues | undefined,
    appliedFilter: FilterType,
    setAppliedFilter: (
        value:
            | {
                  filterId: string;
                  filterFields: DriversListFiltersFormValues;
              }
            | ((val: {
                  filterId: string;
                  filterFields: DriversListFiltersFormValues;
              }) => {
                  filterId: string;
                  filterFields: DriversListFiltersFormValues;
              })
    ) => void,
    setSelectedFilter: Dispatch<
        SetStateAction<DriversListFiltersFormValues | undefined>
    >,
    setFilterModalOpen: Dispatch<SetStateAction<false | "add" | "edit">>,
    refetchFilters: () => void,
    handleFetchDrivers: (
        filters?: DriversListFiltersFormValues,
        filterId?: string
    ) => Promise<boolean>,
    handleEditMode: (edit: boolean) => void,
    location?: DriverCategory
) => {
    const { refetchFilter, addFilter, editFilter, deleteFilter } = useFilter(
        appliedFilter.filterId ?? null
    );

    //when choosing to save the filter
    const handleSaveFilter = (filters: DriversListFiltersFormValues) => {
        if (isEdit) {
            setAppliedFilter({
                filterId: appliedFilter.filterId,
                filterFields: filters,
            });
        }
        setSelectedFilter(filters);
        setFilterModalOpen("edit");
    };

    //when directly saving a filter or selecting "save as new" inside edit
    const handleSubmitNewFilter = async (data: SaveEditFilterValues) => {
        const response = await addFilter(data);

        if (response.success && filterValues) {
            refetchFilters();
            refetchFilter();
            successToast("Filter successfully saved");
            setFilterModalOpen(false);
            handleFetchDrivers(filterValues, response.content);
            handleEditMode(false);
        } else {
            errorToast("There was an error");
        }
    };

    //editing a saved filter
    const handleSubmitEditedFilter = async (data: SaveEditFilterValues) => {
        if (!appliedFilter.filterId) {
            errorToast("No filter has been selected to edit");
            return;
        }

        const response = await editFilter(data, appliedFilter.filterId);

        if (response.success) {
            refetchFilters();
            refetchFilter();
            successToast("Filter has been updated");
            setFilterModalOpen(false);
            handleEditMode(false);
        } else {
            errorToast("There was an error");
        }
    };

    //deleting a saved filter
    const handleDeleteFilter = async () => {
        const response = await deleteFilter(appliedFilter.filterId);
        if (!appliedFilter.filterId) {
            errorToast("Could not delete filter");
            return;
        }
        if (response.success) {
            refetchFilters();
            successToast("Filter has been deleted");
            setFilterModalOpen(false);
            handleEditMode(false);
            setAppliedFilter({
                filterId: "",
                filterFields: driverListFiltersDefaultValues(location),
            });
        } else {
            errorToast("There was an error");
        }
    };

    return {
        handleSaveFilter,
        handleSubmitNewFilter,
        handleSubmitEditedFilter,
        handleDeleteFilter,
    };
};
