import { PencilSimple } from "phosphor-react";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import Table, { TableHeader } from "../../../ReusableComponents/Table";
import { TableMenuItem } from "../../../ReusableComponents/Table/TableMenu";
import {
    initialInvoiceAddressesTableHeaders,
    initInvoiceAddressesTableContents,
} from "./CompanyInvoiceAddressesTableInit";
import ConfirmationDialog from "../../../ReusableComponents/ConfirmationDialog";
import { SetStateAction, useState } from "react";
import {
    type ApiInvoiceAddressItem,
    type ApiInvoiceAddressResponse,
} from "../../../../models/api/admin/invoiceAddress";
import LoadingMessage from "../../../ReusableComponents/LoadingMessage/LoadingMessage";
import FormMessageBox from "../../../FormMessageBox";
import PaginationNav from "../../../PaginationNav";
import { AddOrEditModalStates } from "../CompanyBillingTab";
import { useInvoiceAddress } from "../../../../api/admin/invoiceAddress";
import { errorToast, successToast } from "../../../../toast";

interface Props {
    invoiceAddresses?: ApiInvoiceAddressResponse;
    isLoadingInvoiceAddresses?: boolean;
    invoiceAddressesError?: Error;
    refetchInvoiceAddresses?: () => void;
    selectedInvoiceAddress: ApiInvoiceAddressItem | undefined;
    setSelectedInvoiceAddress: (
        value: SetStateAction<ApiInvoiceAddressItem | undefined>
    ) => void;
    setAddOrEditModalState: (
        value: SetStateAction<AddOrEditModalStates>
    ) => void;
    pageNumber: number;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    canEdit: boolean;
}

export default function CompanyInvoiceAddressesTable({
    invoiceAddresses,
    isLoadingInvoiceAddresses,
    invoiceAddressesError,
    refetchInvoiceAddresses,
    selectedInvoiceAddress,
    setSelectedInvoiceAddress,
    setAddOrEditModalState,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    canEdit,
}: Props) {
    const [deleteInvoiceAddressDialogOpen, setDeleteInvoiceAddressDialogOpen] =
        useState(false);
    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "InvoiceAddressesTableHeaders",
        initialInvoiceAddressesTableHeaders
    );

    const { deleteInvoiceAddress, isDeletingInvoiceAddress } = useInvoiceAddress();

    const handleMenuSelect = (
        invoiceAddressId: string,
        option: "edit" | "delete"
    ) => {
        if (!invoiceAddresses) return console.error("invoiceAddresses is undefined");
        setSelectedInvoiceAddress(
            invoiceAddresses.items.find((iva) => iva.invoiceAddressId === invoiceAddressId)
        );
        if (option === "delete") setDeleteInvoiceAddressDialogOpen(true);
        if (option === "edit") setAddOrEditModalState("edit");
    };

    const handleDeleteInvoiceAddress = async () => {
        if (!selectedInvoiceAddress?.invoiceAddressId) return;

        const response = await deleteInvoiceAddress(
            selectedInvoiceAddress.invoiceAddressId
        );
        if (!response.success) {
            errorToast("Failed to delete invoice address, please try again later.");
        } else {
            successToast("Invoice address successfully deleted.");
            refetchInvoiceAddresses && refetchInvoiceAddresses();
            setDeleteInvoiceAddressDialogOpen(false);
        }
    };

    const shouldShowInvoiceAddresses =
        !invoiceAddressesError &&
        !isLoadingInvoiceAddresses &&
        invoiceAddresses &&
        !!invoiceAddresses.items.length;

    return (
        <>
            {isLoadingInvoiceAddresses && <LoadingMessage />}
            {invoiceAddressesError && <InvoiceAddressLoadingError />}
            {!isLoadingInvoiceAddresses &&
                !invoiceAddressesError &&
                !invoiceAddresses?.items.length && (
                    <div className=" mb-3 w-max  rounded-sm border border-S2D-neutral-80 bg-S2D-neutral-95 p-3 font-semibold">
                        You have no invoice addresses
                    </div>
                )}

            {shouldShowInvoiceAddresses && (
                <>
                    <Table
                        tableHeaders={tableHeaders}
                        setTableHeaders={setTableHeaders}
                        tableContents={initInvoiceAddressesTableContents(
                            invoiceAddresses?.items
                        )}
                        menuItems={(data) => invoiceAddressMenuItems(data, canEdit)}
                    />
                    <PaginationNav
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalCount={invoiceAddresses.totalCount}
                        totalPages={invoiceAddresses.totalPages}
                        currentPage={pageNumber}
                        hasPreviousPage={invoiceAddresses.hasPreviousPage}
                        hasNextPage={invoiceAddresses.hasNextPage}
                    />
                    {selectedInvoiceAddress && (
                        <>
                            <DeleteInvoiceAddressDialog />
                        </>
                    )}
                </>
            )}
        </>
    );

    function DeleteInvoiceAddressDialog() {
        return (
            <ConfirmationDialog
                variant="danger"
                open={deleteInvoiceAddressDialogOpen}
                onClose={() => setDeleteInvoiceAddressDialogOpen(false)}
                title={`Delete invoice address ${selectedInvoiceAddress?.addressName}?`}
                caption="This action is non-reversible."
                onConfirm={handleDeleteInvoiceAddress}
                isLoading={isDeletingInvoiceAddress}
            />
        );
    }

    function InvoiceAddressLoadingError() {
        return (
            <FormMessageBox
                variant="error"
                title={"Error loading invoice addresses"}
                message={
                    "We could not load your invoice addresses data, please try again in 10 minutes"
                }
            />
        );
    }

    function invoiceAddressMenuItems(
        data: ApiInvoiceAddressItem,
        canEdit: boolean
    ): TableMenuItem[] {
        return [
            {
                key: "editInvoiceAddress",
                onClick: () => handleMenuSelect(data.invoiceAddressId || "", "edit"),
                icon: <PencilSimple size={20} weight="bold" />,
                text: "Edit invoice address",
                isDisabled: !canEdit,
            },
            // Commenting out for now
            // {
            //     key: "deleteInvoiceAddress",
            //     onClick: () => handleMenuSelect(data.invoiceAddressId || "", "delete"),
            //     icon: <Trash size={20} weight="fill" />,
            //     text: "Delete invoice address",
            //     isDisabled: !canEdit,
            // },
        ];
    }
}
