import { useLocation } from "react-router-dom";
import ApiError from "../../api/common/apiError";
import useLocalStorage from "../../hooks/useLocalStorage";
import { type ApiDriversListResponse } from "../../models/api/admin/driver";
import { type AllDriversAnalysisCharts } from "../../models/app/admin/analysisChart";
import FormMessageBox from "../FormMessageBox";
import PaginationNav from "../PaginationNav";
import Table, { type TableHeader } from "../ReusableComponents/Table";
import {
    initDriverChartAnalysisTableContents,
    initDriverChartAnalysisTableHeaders,
} from "./driverChartAnalysisTableInit";
import { ApiCompanyResponse } from "../../models/api/admin/company";

type DriverChartAnalysisTableProps = {
    chartType: AllDriversAnalysisCharts;
    driversList?: ApiDriversListResponse;
    fetchDriversError?: ApiError;
    isFetchingDrivers?: boolean;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    selectedCompany?: ApiCompanyResponse;
};

const DriverChartAnalysisTable: React.FC<DriverChartAnalysisTableProps> = ({
    chartType,
    driversList,
    fetchDriversError,
    isFetchingDrivers,
    setPageNumber,
    pageSize,
    setPageSize,
    selectedCompany,
}) => {
    if (!isFetchingDrivers && driversList && !driversList.items.length) {
        return <div>No drivers found</div>;
    }

    const { pathname } = useLocation();

    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "DriverChartAnalysisTableHeaders",
        initDriverChartAnalysisTableHeaders(
            chartType,
            pathname.includes("professional-drivers")
        )
    );
    const initHiddenCols = new Array(0);
    tableHeaders.map((item) =>
        initHiddenCols.push({
            id: item.id,
            hidden: item.hide ?? false,
        })
    );

    return (
        <>
            {fetchDriversError && (
                <FormMessageBox
                    title={"error"}
                    message={
                        <span>
                            There was an error loading driver data.
                            <br />
                            Please try again in 10 minutes.
                        </span>
                    }
                    variant={"error"}
                />
            )}
            <Table
                tableHeaders={tableHeaders}
                setTableHeaders={setTableHeaders}
                tableContents={initDriverChartAnalysisTableContents(
                    driversList?.items ?? [],
                    selectedCompany?.companyRiskProfiles
                )}
                hiddenCols={initHiddenCols}
                isLoading={isFetchingDrivers}
            />
            {driversList && (
                <PaginationNav
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    totalCount={driversList.totalCount}
                    totalPages={driversList.totalPages}
                    currentPage={driversList.pageNumber}
                    hasPreviousPage={driversList.hasPreviousPage}
                    hasNextPage={driversList.hasNextPage}
                />
            )}
        </>
    );
};

export default DriverChartAnalysisTable;
