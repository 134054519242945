import clsx from "clsx";
import { type ForwardRefExoticComponent, type RefAttributes } from "react";
import { ArrowSquareOut, type IconProps } from "phosphor-react";
import { useIsSmallScreen } from "../../hooks/screenSizes";

type StatisticSummaryBoxProps = {
    icon?:
        | ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>
        | undefined;
    iconColor?: string;
    customImg?: string;
    label: string;
    statistic?: string | number;
    onLinkClick?: () => void;
};

export default function DriverSummaryCard({
    icon: Icon,
    iconColor,
    customImg,
    label,
    statistic,
    onLinkClick,
}: StatisticSummaryBoxProps) {
    const isBiggerScreen = useIsSmallScreen();

    return (
        <div className="flex grow items-start items-center justify-between rounded-sm bg-S2D-neutral-100 p-4">
            <div className="flex items-center gap-2">
                <div
                    className={clsx(
                        "flex h-10 items-center justify-center rounded-sm p-2 text-S2D-neutral-10",
                        iconColor && iconColor
                    )}
                >
                    {Icon && <Icon size={isBiggerScreen ? 35 : 25} />}
                    {customImg && <img src={customImg} className="w-12" />}
                </div>
                <span className={"text-2xl font-medium md:text-4xl"}>
                    {statistic ?? "-"}
                </span>
                {/* inner div width makes words break nicely, outer span makes any text overflow remain visible */}
                <span className="w-3/4">
                    <div className="w-2/3 break-normal text-xs font-semibold uppercase md:text-sm">
                        {/* replace regular hyphen with unicode non-breaking hyphen */}
                        {label.replace("-", "‑")}
                    </div>
                </span>
            </div>
            {onLinkClick && (
                <div className="flex h-10 w-10 items-center justify-center rounded-sm border-2 border-S2D-neutral-100 hover:cursor-pointer">
                    <ArrowSquareOut size={isBiggerScreen ? 25 : 16} />
                </div>
            )}
        </div>
    );
}
