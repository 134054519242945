import { Controller, UseFormReturn } from "react-hook-form";
import FormDropdown from "../../FormDropdown";
import { DriversListFiltersFormValues } from "./DriversListFiltersFormValues";
import {
    handleLicenceAnalysisDropdownSelect,
    analysisSelectedValue,
} from "./handleAnalysisDropdownSelect";
import {
    DriversByAgeRange,
    DriversByAgeRangeOptions,
    DriversByPoints,
    DriversByPointsOptions,
    FilterLicenceTypeOptions,
    LicenceTypes,
    YearsFullLicenceHeld,
    YearsFullLicenceHeldOptions,
} from "../../../models/app/admin/licenceAnalysis";
import {
    driversByAgeRangeOptions,
    driversByPointsOptions,
    filterLicenceTypeOptions,
    yearsFullLicenceHeldOptions,
} from "../../../constants/driverLicenceAnalysisOptions";
import { addNullDropdownOption } from "../../../constants/dropdownOptions";
import { getEntitlementRestrictionCodes } from "../../../api/admin/dvla";
import AccordionItem from "../../AccordionItem";

type FilterLicenceAnalysisProps = {
    methods: UseFormReturn<DriversListFiltersFormValues>;
};

const FilterLicenceAnalysis: React.FC<FilterLicenceAnalysisProps> = ({
    methods,
}) => {
    const {
        entitlementRestrictionCodes,
        entitlementRestrictionCodesError,
        isLoadingEntitlementRestrictionCodes,
    } = getEntitlementRestrictionCodes();

    const restrictionCodeOptions =
        entitlementRestrictionCodes?.map((restriction) => ({
            label: restriction.code,
            value: restriction.code,
        })) || [];

    return (
        <AccordionItem title="Licence Analysis" modalStyling>
            <>
                <Controller
                    name="driverLicenceAnalysis.driversByPoints"
                    control={methods.control}
                    render={({ field: { value } }) => {
                        const selectedValue = analysisSelectedValue(
                            value ?? {}
                        ) as DriversByPointsOptions | null;

                        return (
                            <FormDropdown
                                label="Drivers by points"
                                options={addNullDropdownOption(
                                    driversByPointsOptions
                                )}
                                value={selectedValue}
                                onChange={(
                                    value: keyof DriversByPoints | null
                                ) =>
                                    handleLicenceAnalysisDropdownSelect(
                                        methods,
                                        "driversByPoints",
                                        value
                                    )
                                }
                            />
                        );
                    }}
                />
                <Controller
                    name="driverLicenceAnalysis.driversByAgeRange"
                    control={methods.control}
                    render={({ field: { value } }) => {
                        const selectedValue = analysisSelectedValue(
                            value ?? {}
                        ) as DriversByAgeRangeOptions | null;

                        return (
                            <FormDropdown
                                label="Drivers by age range"
                                options={addNullDropdownOption(
                                    driversByAgeRangeOptions
                                )}
                                value={selectedValue}
                                onChange={(
                                    value: keyof DriversByAgeRange | null
                                ) =>
                                    handleLicenceAnalysisDropdownSelect(
                                        methods,
                                        "driversByAgeRange",
                                        value
                                    )
                                }
                            />
                        );
                    }}
                />
                <Controller
                    name="driverLicenceAnalysis.licenceTypes"
                    control={methods.control}
                    render={({ field: { value } }) => {
                        const selectedValue = analysisSelectedValue(
                            value ?? {}
                        ) as FilterLicenceTypeOptions | null;

                        return (
                            <FormDropdown
                                label="Licence types"
                                options={addNullDropdownOption(
                                    filterLicenceTypeOptions
                                )}
                                value={selectedValue}
                                onChange={(value: keyof LicenceTypes | null) =>
                                    handleLicenceAnalysisDropdownSelect(
                                        methods,
                                        "licenceTypes",
                                        value
                                    )
                                }
                            />
                        );
                    }}
                />
                {isLoadingEntitlementRestrictionCodes && <p>...loading</p>}
                {!entitlementRestrictionCodesError &&
                    entitlementRestrictionCodes && (
                        <Controller
                            name="driverLicenceAnalysis.driverRestrictions.restrictionCode"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    label="Driver restrictions"
                                    options={addNullDropdownOption(
                                        restrictionCodeOptions
                                    )}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    )}
                <Controller
                    name="driverLicenceAnalysis.yearsFullLicenceHeld"
                    control={methods.control}
                    render={({ field: { value } }) => {
                        const selectedValue = analysisSelectedValue(
                            value ?? {}
                        ) as YearsFullLicenceHeldOptions | null;

                        return (
                            <FormDropdown
                                label="Years full licence held"
                                options={addNullDropdownOption(
                                    yearsFullLicenceHeldOptions
                                )}
                                value={selectedValue}
                                onChange={(
                                    value: keyof YearsFullLicenceHeld | null
                                ) =>
                                    handleLicenceAnalysisDropdownSelect(
                                        methods,
                                        "yearsFullLicenceHeld",
                                        value
                                    )
                                }
                            />
                        );
                    }}
                />
            </>
        </AccordionItem>
    );
};

export default FilterLicenceAnalysis;
