import { Controller, UseFormReturn } from "react-hook-form";
import { useFormFieldCollectiveValidation } from "../../hooks/useFormFieldCollectiveValidation";
import AccordionItem from "../AccordionItem";
import FormDropdown from "../FormDropdown";
import { type AddCompanyFormValues } from "./AddCompanyFormValues";

type CompanyCheckFrequenciesFieldsProps = {
    methods: UseFormReturn<AddCompanyFormValues>;
};

const rangeOptions = new Array(0);
for (let i = 1; i <= 12; i++) {
    rangeOptions.push({
        label: `${i} months`,
        value: i,
    });
}
rangeOptions.push(
    {
        label: `${24} months`,
        value: 24,
    },
    {
        label: `${36} months`,
        value: 36,
    }
);

const CompanyCheckFrequenciesFields: React.FC<
    CompanyCheckFrequenciesFieldsProps
> = ({ methods }) => {
    const isSectionValid =
        useFormFieldCollectiveValidation<AddCompanyFormValues>(
            ["checkFrequency"],
            methods
        );
    return (
        <AccordionItem title="Check frequencies" error={!isSectionValid}>
            <Controller
                name="checkFrequency.range1"
                control={methods.control}
                render={({ field: { value, onChange } }) => (
                    <FormDropdown
                        label="Range 1 drivers checked every"
                        options={rangeOptions ?? []}
                        value={value ?? null}
                        required
                        onChange={onChange}
                        pleaseSelectText="Select check frequency"
                        error={methods.formState.errors.checkFrequency?.range1}
                    />
                )}
            />
            <Controller
                name="checkFrequency.range2"
                control={methods.control}
                render={({ field: { value, onChange } }) => (
                    <FormDropdown
                        label="Range 2 drivers checked every"
                        options={rangeOptions ?? []}
                        value={value ?? null}
                        required
                        onChange={onChange}
                        pleaseSelectText="Select check frequency"
                        error={methods.formState.errors.checkFrequency?.range2}
                    />
                )}
            />
            <Controller
                name="checkFrequency.range3"
                control={methods.control}
                render={({ field: { value, onChange } }) => (
                    <FormDropdown
                        label="Range 3 drivers checked every"
                        options={rangeOptions ?? []}
                        value={value ?? null}
                        required
                        onChange={onChange}
                        pleaseSelectText="Select check frequency"
                        error={methods.formState.errors.checkFrequency?.range3}
                    />
                )}
            />
        </AccordionItem>
    );
};

export default CompanyCheckFrequenciesFields;
