import { Trash } from "phosphor-react";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import Table, { TableHeader } from "../../../ReusableComponents/Table";
import { TableMenuItem } from "../../../ReusableComponents/Table/TableMenu";
import {
    initialInvoiceReferenceNumbersTableHeaders,
    initInvoiceReferenceNumbersTableContents,
} from "./CompanyInvoiceReferenceNumbersTableInit";
import ConfirmationDialog from "../../../ReusableComponents/ConfirmationDialog";
import { SetStateAction, useState } from "react";
import {
    type ApiInvoiceReferenceNumberItem,
    type ApiInvoiceReferenceNumberResponse,
} from "../../../../models/api/admin/invoiceReferenceNumber";
import LoadingMessage from "../../../ReusableComponents/LoadingMessage/LoadingMessage";
import FormMessageBox from "../../../FormMessageBox";
import PaginationNav from "../../../PaginationNav";
import { AddOrEditModalStates } from "../CompanyBillingTab";
import { useInvoiceReferenceNumber } from "../../../../api/admin/invoiceReferenceNumber";
import { errorToast, successToast } from "../../../../toast";

interface Props {
    companyId: string;
    invoiceReferenceNumbers?: ApiInvoiceReferenceNumberResponse;
    isLoadingInvoiceReferenceNumbers?: boolean;
    invoiceReferenceNumbersError?: Error;
    refetchInvoiceReferenceNumbers?: () => void;
    selectedInvoiceReferenceNumber: ApiInvoiceReferenceNumberItem | undefined;
    setSelectedInvoiceReferenceNumber: (
        value: SetStateAction<ApiInvoiceReferenceNumberItem | undefined>
    ) => void;
    setAddOrEditModalState: (
        value: SetStateAction<AddOrEditModalStates>
    ) => void;
    pageNumber: number;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    canEdit: boolean;
}

export default function CompanyInvoiceReferenceNumbersTable({
    companyId,
    invoiceReferenceNumbers,
    isLoadingInvoiceReferenceNumbers,
    invoiceReferenceNumbersError,
    refetchInvoiceReferenceNumbers,
    selectedInvoiceReferenceNumber,
    setSelectedInvoiceReferenceNumber,
    setAddOrEditModalState,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    canEdit,
}: Props) {
    const [deleteInvoiceReferenceNumberDialogOpen, setDeleteInvoiceReferenceNumberDialogOpen] =
        useState(false);
    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "InvoiceReferenceNumbersTableHeaders",
        initialInvoiceReferenceNumbersTableHeaders
    );

    // const { deleteInvoiceReferenceNumber, isDeletingInvoiceReferenceNumber } = useInvoiceReferenceNumber(companyId);

    const handleMenuSelect = (
        value: string,
        option: "edit" | "delete"
    ) => {
        if (!invoiceReferenceNumbers) return console.error("invoice reference numbers is undefined");
        setSelectedInvoiceReferenceNumber(
            invoiceReferenceNumbers.items.find((irn: { value: string; }) => irn.value === value)
        );
        if (option === "delete") setDeleteInvoiceReferenceNumberDialogOpen(true);
        if (option === "edit") setAddOrEditModalState("edit");
    };

    // const handleDeleteInvoiceReferenceNumber = async () => {
    //     if (!selectedInvoiceReferenceNumber?.value) return;

    //     const response = await deleteInvoiceReferenceNumber(
    //         selectedInvoiceReferenceNumber.value
    //     );
    //     if (!response.success) {
    //         errorToast("Failed to delete invoice reference number, please try again later.");
    //     } else {
    //         successToast("Invoice reference number successfully deleted.");
    //         refetchInvoiceReferenceNumbers && refetchInvoiceReferenceNumbers();
    //         setDeleteInvoiceReferenceNumberDialogOpen(false);
    //     }
    // };

    const shouldShowInvoiceReferenceNumbers =
        !invoiceReferenceNumbersError &&
        !isLoadingInvoiceReferenceNumbers &&
        invoiceReferenceNumbers &&
        !!invoiceReferenceNumbers.items.length;

    return (
        <>
            {isLoadingInvoiceReferenceNumbers && <LoadingMessage />}
            {invoiceReferenceNumbersError && <InvoiceReferenceNumberLoadingError />}
            {!isLoadingInvoiceReferenceNumbers &&
                !invoiceReferenceNumbersError &&
                !invoiceReferenceNumbers?.items.length && (
                    <div className=" mb-3 w-max  rounded-sm border border-S2D-neutral-80 bg-S2D-neutral-95 p-3 font-semibold">
                        You have no invoice reference numbers
                    </div>
                )}

            {shouldShowInvoiceReferenceNumbers && (
                <>
                    <Table
                        tableHeaders={tableHeaders}
                        setTableHeaders={setTableHeaders}
                        tableContents={initInvoiceReferenceNumbersTableContents(
                            invoiceReferenceNumbers?.items
                        )}
                        // Removing for now until confirmation on edit/delete
                        // menuItems={(data) => invoiceReferenceNumberMenuItems(data, canEdit)}
                    />
                    <PaginationNav
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalCount={invoiceReferenceNumbers.totalCount}
                        totalPages={invoiceReferenceNumbers.totalPages}
                        currentPage={pageNumber}
                        hasPreviousPage={invoiceReferenceNumbers.hasPreviousPage}
                        hasNextPage={invoiceReferenceNumbers.hasNextPage}
                    />
                    {/* {selectedInvoiceReferenceNumber && (
                        <>
                            <DeleteInvoiceReferenceNumberDialog />
                        </>
                    )} */}
                </>
            )}
        </>
    );

    // function DeleteInvoiceReferenceNumberDialog() {
    //     return (
    //         <ConfirmationDialog
    //             variant="danger"
    //             open={deleteInvoiceReferenceNumberDialogOpen}
    //             onClose={() => setDeleteInvoiceReferenceNumberDialogOpen(false)}
    //             title={`Delete invoice reference number ${selectedInvoiceReferenceNumber?.value}?`}
    //             caption="This action is non-reversible."
    //             onConfirm={handleDeleteInvoiceReferenceNumber}
    //             isLoading={isDeletingInvoiceReferenceNumber}
    //         />
    //     );
    // }

    function InvoiceReferenceNumberLoadingError() {
        return (
            <FormMessageBox
                variant="error"
                title={"Error loading invoice reference numbers"}
                message={
                    "We could not load your invoice reference numbers data, please try again in 10 minutes"
                }
            />
        );
    }

    function invoiceReferenceNumberMenuItems(
        data: ApiInvoiceReferenceNumberItem,
        canEdit: boolean
    ): TableMenuItem[] {
        return [
            {
                key: "deleteInvoiceReferenceNumber",
                onClick: () => handleMenuSelect(data.value, "delete"),
                icon: <Trash size={20} weight="fill" />,
                text: "Delete invoice reference number",
                isDisabled: !canEdit,
            },
        ];
    }
}
