import { FieldError } from "react-hook-form";
import clsx from "clsx";
import React, { useState } from "react";
import FormInputMessage from "./FormInputError";
import FormInputLabel from "./FormInputLabel";
import { Check } from "phosphor-react";

type FormCheckboxProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "type" | "className"
> & {
    label?: string;
    error?: FieldError;
    helpText?: string;
    required?: boolean;
    tooltip?: string;
    contained?: boolean;
    inline?: boolean;
};

const FormCheckbox = React.forwardRef<HTMLInputElement, FormCheckboxProps>(
    (
        {
            id,
            label,
            error,
            helpText,
            required,
            tooltip,
            contained,
            inline,
            ...inputProps
        },
        ref
    ) => {
        const [checked, setChecked] = useState(inputProps.defaultChecked);
        const [isFocused, setIsFocused] = useState(false);

        const handleCheckboxChange = (
            e: React.ChangeEvent<HTMLInputElement>
        ) => {
            setChecked(e.target.checked);

            if (inputProps && inputProps.onChange) {
                inputProps.onChange(e);
            }
        };

        return (
            <>
                <div
                    className={clsx(
                        "relative flex w-full items-center transition-all",
                        contained &&
                            !checked &&
                            !error &&
                            "mb-4 rounded-sm border border-black",
                        contained &&
                            checked &&
                            "mb-4 rounded-sm border-2 border-S2D-light-green-80 bg-S2D-dark-green-70.1",
                        contained &&
                            error &&
                            "mb-0 rounded-sm border-2 border-red-600",
                        inline && "py-3 pl-1",
                        !inline && "p-4"
                    )}
                >
                    <div
                        className={clsx(
                            "relative mr-2 flex h-[18px] w-[18px] flex-none translate-y-[1px] items-center justify-center rounded-xs p-[2px] transition-all",
                            checked
                                ? "bg-S2D-dark-green-30"
                                : "border-2 border-black",
                            isFocused && "ring-2 ring-S2D-light-green-80",
                            isFocused && error && "ring2 ring-S2D-error-40"
                        )}
                    >
                        <input
                            {...inputProps}
                            type="checkbox"
                            id={id}
                            aria-required={required}
                            ref={ref}
                            onChange={handleCheckboxChange}
                            className="absolute inset-0 opacity-0"
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        />
                        {checked && <Check className="text-white" />}
                    </div>
                    {label && (
                        <FormInputLabel
                            htmlFor={id}
                            required={required}
                            className={clsx(
                                contained && checked && "font-semibold"
                            )}
                            tooltipText={tooltip}
                        >
                            {label}
                        </FormInputLabel>
                    )}
                </div>
                <FormInputMessage
                    error={error}
                    helpText={helpText}
                    className={clsx(contained && "ml-0 mb-4 pl-4 pt-1")}
                />
            </>
        );
    }
);

export default FormCheckbox;
