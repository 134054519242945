import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { EditRiskCategoriesSchema } from "../CheckSettingsFormSchemas";
import { EditCompanyFormValues } from "../../../../EditCompanyForms/EditCompanyFormValues";
import { useEffect } from "react";
import { AddCompanyFormValues } from "../../../../AddCompanyForm/AddCompanyFormValues";

export type EditRiskCategoriesFormValues = Pick<
    AddCompanyFormValues,
    "riskCategory"
>;

export const defaultRiskCategories = {
    range1Name: "Safe2Drive",
    range2Name: "Medium Risk",
    range3Name: "High Risk",
    range1: 0,
    range2: 3,
    range3: 6,
};

const useEditRiskCategoriesForm = (initialValues: EditCompanyFormValues) => {
    const riskProfiles = initialValues?.companyRiskProfiles;

    const range1profile = riskProfiles?.find(
        (profile) => profile.severityLevel === 1
    );
    const range2profile = riskProfiles?.find(
        (profile) => profile.severityLevel === 2
    );
    const range3profile = riskProfiles?.find(
        (profile) => profile.severityLevel === 3
    );

    const displayedValues: EditRiskCategoriesFormValues = {
        riskCategory: {
            range1Name: range1profile?.name || defaultRiskCategories.range1Name,
            range2Name: range2profile?.name || defaultRiskCategories.range2Name,
            range3Name: range3profile?.name || defaultRiskCategories.range3Name,
            range1:
                range1profile?.lowerThreshold || defaultRiskCategories.range1,
            range2:
                range2profile?.lowerThreshold || defaultRiskCategories.range2,
            range3:
                range3profile?.lowerThreshold || defaultRiskCategories.range3,
        },
    };

    const validationSchema = yup.object().shape(EditRiskCategoriesSchema);

    const methods = useForm<EditRiskCategoriesFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: displayedValues,
    });

    useEffect(() => {
        methods.reset(displayedValues);
    }, [initialValues]);

    return {
        methods,
        displayedValues,
    };
};

export default useEditRiskCategoriesForm;
