import { useCompany } from "../../api/admin/company";
import { successToast } from "../../toast";
import AddCompanyForm from "../../components/AddCompanyForm/AddCompanyForm";
import PageLayout from "../../components/PageLayout";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../Routes";
import { type AddCompanyDto } from "../../models/app/admin/company";
import { capitalisedTermForCompany, termForCompany } from "../../copy/sharedCopy";

const AdminAddCompany: React.FC = () => {
    const { addCompany, isAddingCompany } = useCompany();
    const navigate = useNavigate();

    const handleAddCompany = async (
        data: AddCompanyDto,
        callback: (error: boolean) => void
    ) => {
        const response = await addCompany(data);

        if (response.success) {
            successToast(`${termForCompany} has been added`);
            navigate(appPaths.adminCompanies);
            callback(false);
        } else {
            callback(true);
            window.scrollTo(0, 0);
        }
    };

    const handleCancel = () => {
        navigate(appPaths.adminCompanies);
    };

    return (
        <PageLayout title={`Add ${capitalisedTermForCompany}`}>

            <AddCompanyForm
                onSubmit={handleAddCompany}
                isLoading={isAddingCompany}
                onCancel={handleCancel}
            />
        </PageLayout>
    );
};

export default AdminAddCompany;
