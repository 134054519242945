import { useEffect, useState } from "react";
import { BarChartItem } from "../ReusableComponents/Charts/BarChart";
import { PieChartItem } from "../ReusableComponents/Charts/PieChart";
import { useGreyFleetVehicleAnalysis } from "../../api/greyFleet";
import {
    ApiGreyFleetVehicleAnalysisResponse,
    KnownAgeRanges,
    KnownBusinessCoverTypes,
    KnownCo2EmissionRanges,
    KnownEuroRatings,
    KnownFuelTypes,
    KnownInsuranceTypes,
    EngineCapacities,
} from "../../models/api/greyFleet";

type ChartNames = {
    co2Emissions: {
        [Property in keyof KnownCo2EmissionRanges]: string;
    };
    fuelTypes: {
        [Property in keyof KnownFuelTypes]: string;
    };
    ageRanges: {
        [Property in keyof KnownAgeRanges]: string;
    };
    euroRatings: {
        [Property in keyof KnownEuroRatings]: string;
    };
    insuranceTypes: {
        [Property in keyof KnownInsuranceTypes]: string;
    };
    businessCover: {
        [Property in keyof KnownBusinessCoverTypes]: string;
    };
    engineCapacity: {
        [Property in keyof EngineCapacities]: string;
    };
};

const standardColors = {
    lightGreen50: "#648100",
    lightGreen60: "#92B91C",
    lightGreen70: "#92B91C",
    lightGreen80: "#ADD53A",
    lightGreen90: "#C8F255",
    darkGreen: "#204E47",
    amber: "#FFE252",
    red30: "#93000A",
    red40: "#BA1A1A",
    red50: "#DE3730",
    grey: "#ACABA4",
};

const useGreyFleetVehicleAnalysisCharts = (
    companyId?: string,
    departmentId?: string
) => {
    const [statsResponse, setStatsResponse] =
        useState<ApiGreyFleetVehicleAnalysisResponse | null>(null);

    const {
        data: vehicleAnalysisData,
        isLoading: isLoadingVehicleAnalysisData,
        error: errorVehicleAnalysisData,
    } = useGreyFleetVehicleAnalysis(companyId, departmentId);

    // setting the return value once vehicleAnalysisData is available
    useEffect(() => {
        setStatsResponse(vehicleAnalysisData ?? null);
    }, [vehicleAnalysisData]);

    const chartNames: ChartNames = {
        co2Emissions: {
            low: "0 - 90 (g/km)",
            medium: "91 - 170 (g/km)",
            high: "171 - 256+ (g/km)",
        },
        fuelTypes: {
            petrol: "Petrol",
            diesel: "Diesel",
            hybrid: "Hybrid",
            electric: "Electric",
            other: "Other",
        },
        ageRanges: {
            zeroToTwoYears: "0-2 years old",
            threeToFiveYears: "3-5 years old",
            sixToTenYears: "6-10 years old",
            elevenToFifteenYears: "11-15 years old",
            sixteenToTwentyYears: "16-20 years old",
            twentyOneAndOverYears: "21+ years old",
        },
        euroRatings: {
            euro1: "Euro 1",
            euro2: "Euro 2",
            euro3: "Euro 3",
            euro4: "Euro 4",
            euro5: "Euro 5",
            euro6: "Euro 6",
        },
        insuranceTypes: {
            fullyComprehensive: "Fully comprehensive",
            thirdParty: "Third party",
        },
        businessCover: {
            with: "With business cover",
            without: "Without business cover",
        },
        engineCapacity: {
            unknown: "Unknown",
            zeroToOnePointTwo: "0 - 1200 cc",
            onePointTwoToOnePointEight: "1200 - 1800 cc",
            onePointEightToTwoPointFive: "1800 - 2500 cc",
            twoPointFiveAndOver: "2500+ cc",
        },
    };

    const handleValue = (value?: number | undefined) => {
        if (typeof value === "number") {
            return value;
        } else {
            return 0;
        }
    };
    const createCo2EmissionStats = (): PieChartItem[] => {
        const pieChartItems: PieChartItem[] = [];
        const colors: Record<keyof KnownCo2EmissionRanges, string> = {
            low: standardColors.lightGreen70,
            medium: standardColors.amber,
            high: standardColors.red40,
        };

        for (const [key, value] of Object.entries(
            statsResponse?.co2Emission ?? {}
        )) {
            const color = colors[key as keyof KnownCo2EmissionRanges];

            pieChartItems.push({
                name: chartNames.co2Emissions[
                    key as keyof KnownCo2EmissionRanges
                ],
                color: color, // Alternate colors
                value: handleValue(value),
            });
        }
        return pieChartItems;
    };
    const co2Emissions = createCo2EmissionStats();

    const createFuelTypeStats = () => {
        const barChartItems: BarChartItem[] = [];
        const colors: Record<keyof KnownFuelTypes, string> = {
            petrol: standardColors.amber,
            diesel: standardColors.red40,
            hybrid: standardColors.lightGreen90,
            electric: standardColors.lightGreen70,
            other: standardColors.lightGreen50,
        };

        for (const [key, value] of Object.entries(
            statsResponse?.fuelType ?? {}
        )) {
            const color = colors[key as keyof KnownFuelTypes];

            barChartItems.push({
                name: chartNames.fuelTypes[key as keyof KnownFuelTypes],
                color: color,
                value: handleValue(value),
            });
        }

        return barChartItems;
    };
    const fuelTypes: BarChartItem[] = createFuelTypeStats();

    const createAgeRangeStats = (): PieChartItem[] => {
        const pieChartItems: PieChartItem[] = [];
        const colors: Record<keyof KnownAgeRanges, string> = {
            zeroToTwoYears: standardColors.lightGreen50,
            threeToFiveYears: standardColors.lightGreen80,
            sixToTenYears: standardColors.amber,
            elevenToFifteenYears: standardColors.red50,
            sixteenToTwentyYears: standardColors.red40,
            twentyOneAndOverYears: standardColors.red30,
        };

        for (const [key, value] of Object.entries(
            statsResponse?.ageRange ?? {}
        )) {
            const color = colors[key as keyof KnownAgeRanges];

            pieChartItems.push({
                name: chartNames.ageRanges[key as keyof KnownAgeRanges],
                color: color, // Alternate colors
                value: handleValue(value),
            });
        }
        return pieChartItems;
    };
    const ageRange = createAgeRangeStats();

    const createManufacturerTypesStats = (): PieChartItem[] => {
        const pieChartItems: PieChartItem[] = [];
        const colors = [standardColors.lightGreen70, standardColors.darkGreen];

        let index = 0;

        vehicleAnalysisData?.manufacturerType?.manufacturers.forEach(
            (manufacturer) => {
                pieChartItems.push({
                    name: manufacturer.name,
                    color: colors[index % 2], // Alternate colors
                    value: handleValue(manufacturer.total),
                });
                index++;
            }
        );
        return pieChartItems;
    };
    const manufacturerTypes = createManufacturerTypesStats();

    const createEuroRatingsStats = () => {
        const barChartItems: BarChartItem[] = [];
        const colors: Record<keyof KnownEuroRatings, string> = {
            euro6: standardColors.lightGreen70,
            euro5: standardColors.lightGreen80,
            euro4: standardColors.amber,
            euro3: standardColors.red50,
            euro2: standardColors.red40,
            euro1: standardColors.red30,
        };

        for (const [key, value] of Object.entries(
            statsResponse?.euroRatings ?? {}
        )) {
            const color = colors[key as keyof KnownEuroRatings];

            barChartItems.push({
                name: chartNames.euroRatings[key as keyof KnownEuroRatings],
                color: color,
                value: handleValue(value),
            });
        }

        return barChartItems;
    };
    const euroRatings: BarChartItem[] = createEuroRatingsStats();

    const createInsuranceTypeStats = () => {
        const pieChartItems: PieChartItem[] = [];
        const colors: Record<keyof KnownInsuranceTypes, string> = {
            fullyComprehensive: standardColors.lightGreen70,
            thirdParty: standardColors.amber,
        };

        for (const [key, value] of Object.entries(
            statsResponse?.insuranceType ?? {}
        )) {
            const color = colors[key as keyof KnownInsuranceTypes];

            pieChartItems.push({
                name: chartNames.insuranceTypes[
                    key as keyof KnownInsuranceTypes
                ],
                color: color,
                value: handleValue(value),
            });
        }

        return pieChartItems;
    };
    const insuranceTypes: BarChartItem[] = createInsuranceTypeStats();

    const createBusinessCoverStats = () => {
        const pieChartItems: PieChartItem[] = [];
        const colors: Record<keyof KnownBusinessCoverTypes, string> = {
            with: standardColors.lightGreen70,
            without: standardColors.red40,
        };

        for (const [key, value] of Object.entries(
            statsResponse?.businessCover ?? {}
        )) {
            const color = colors[key as keyof KnownBusinessCoverTypes];

            pieChartItems.push({
                name: chartNames.businessCover[
                    key as keyof KnownBusinessCoverTypes
                ],
                color: color,
                value: handleValue(value),
            });
        }

        return pieChartItems;
    };
    const businessCover: BarChartItem[] = createBusinessCoverStats();

    const createEngineCapacityStats = () => {
        const barChartItems: BarChartItem[] = new Array(0);
        const colors: Record<keyof EngineCapacities, string> = {
            unknown: standardColors.grey,
            zeroToOnePointTwo: standardColors.lightGreen80,
            onePointTwoToOnePointEight: standardColors.amber,
            onePointEightToTwoPointFive: standardColors.red40,
            twoPointFiveAndOver: standardColors.darkGreen,
        };

        for (const [key, value] of Object.entries(
            statsResponse?.engineCapacity ?? {}
        )) {
            const color = colors[key as keyof EngineCapacities];

            barChartItems.push({
                name: chartNames.engineCapacity[key as keyof EngineCapacities],
                color: color,
                value: handleValue(value),
            });
        }

        return barChartItems;
    };
    const engineCapacity: BarChartItem[] = createEngineCapacityStats();

    if (!statsResponse) return null;

    return {
        co2Emissions,
        fuelTypes,
        ageRange,
        manufacturerTypes,
        euroRatings,
        insuranceTypes,
        businessCover,
        engineCapacity,
        isLoadingVehicleAnalysisData,
        errorVehicleAnalysisData,
    };
};

export default useGreyFleetVehicleAnalysisCharts;
