import { GetAllDriversDto } from "../../models/app/admin/driver";
import { useS2DApiFetcher } from "../common/fetching";

export const useGetDriversReport = () => {
    const [getDriversReport, isGettingDriversReport] = useS2DApiFetcher<
        GetAllDriversDto,
        Blob,
        { dto: GetAllDriversDto }
    >(
        "POST",
        ({ dto }) => ({
            url: "api/drivers/download",
            body: dto,
            altToken: undefined,
            contentType: "application/json",
        }),
        [],
        true
    );

    return {
        getDriversReport: async (dto: GetAllDriversDto) => await getDriversReport({ dto }),
        isGettingDriversReport,
    };
};