export enum AnalysisChartType {
    DriversByPoints = "drivers-by-points",
    DriversByAgeRange = "drivers-by-age-range",
    EndorsementCodes = "endorsement-codes",
    DriversByLicenceType = "licence-types",
    DriversByLicenceCategory = "drivers-by-licence-category",
    DriverRestrictions = "driver-restrictions",
    DriversByLicenceOrigin = "drivers-by-licence-origin",
    YearsFullLicenceHeld = "years-full-licence-held",
    Co2Emissions = "co2-emissions",
    FuelType = "fuel-type",
    AgeRange = "age-range",
    Manufacturer = "manufacturer",
    EuroRatings = "euro-ratings",
    InsuranceTypes = "insurance-type",
    BusinessCover = "business-cover",
    EngineCapacity = "engine-capacity",
}

export type AllDriversAnalysisCharts =
    | AnalysisChartType.DriversByPoints
    | AnalysisChartType.DriversByAgeRange
    | AnalysisChartType.EndorsementCodes
    | AnalysisChartType.DriversByLicenceType
    | AnalysisChartType.DriversByLicenceCategory
    | AnalysisChartType.DriverRestrictions
    | AnalysisChartType.DriversByLicenceOrigin
    | AnalysisChartType.YearsFullLicenceHeld;

export type GreyFleetVehicleAnalysisCharts =
    | AnalysisChartType.Co2Emissions
    | AnalysisChartType.FuelType
    | AnalysisChartType.AgeRange
    | AnalysisChartType.Manufacturer
    | AnalysisChartType.EuroRatings
    | AnalysisChartType.InsuranceTypes
    | AnalysisChartType.BusinessCover
    | AnalysisChartType.EngineCapacity;
