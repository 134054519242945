import { useForm } from "react-hook-form";
import FormTextInput from "../FormTextInput";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../Button";
import { appPaths } from "../../Routes";
import { Link } from "react-router-dom";
import {
    ForgottenPasswordResetFormSchema,
    ForgottenPasswordResetFormValues,
} from "./ForgottenPasswordResetFormValues";
import usePasswordValidation from "../../hooks/usePasswordValidation";

type ForgottenPasswordResetFormProps = {
    onSubmit: (data: ForgottenPasswordResetFormValues) => Promise<void>;
    isLoading: boolean;
};

const validationSchema = Yup.object().shape(ForgottenPasswordResetFormSchema);

const ForgottenPasswordResetForm: React.FC<ForgottenPasswordResetFormProps> = ({
    onSubmit,
    isLoading,
}) => {
    const { handleSubmit, formState, register, trigger, watch } =
        useForm<ForgottenPasswordResetFormValues>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues: {
                newPassword: "",
                confirmNewPassword: "",
            },
        });

    const handleForgotPassword = (data: ForgottenPasswordResetFormValues) => {
        onSubmit(data);
    };

    usePasswordValidation({
        watch,
        trigger,
        passwordField: "newPassword",
        confirmPasswordField: "confirmNewPassword",
    });

    return (
        <div className="w-full max-w-lg rounded-sm bg-white p-8">
            <form onSubmit={handleSubmit(handleForgotPassword)}>
                <p className="pb-4 font-semibold">RESET PASSWORD</p>
                <FormTextInput
                    label="New password"
                    required
                    type="password"
                    id="newPassword"
                    placeholder="Enter new password"
                    error={formState.errors.newPassword}
                    {...register("newPassword")}
                />
                <FormTextInput
                    label="Confirm new password"
                    type="password"
                    required
                    id="confirmNewPassword"
                    placeholder="Re-enter new password"
                    error={formState.errors.confirmNewPassword}
                    {...register("confirmNewPassword")}
                />
                <div className="mt-8 flex justify-end gap-4">
                    <Link to={appPaths.login}>
                        <Button type="button" variant="tertiary">
                            Cancel
                        </Button>
                    </Link>
                    <Button type="submit" loading={isLoading}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ForgottenPasswordResetForm;
