import React from "react";
import clsx from "clsx";

type FileSelectorButtonProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "type" | "className"
> & {
    maxFileSizeKb: number;
    disabled?: boolean;
    required?: boolean;
};

const FileSelectorButton = React.forwardRef<
    HTMLInputElement,
    FileSelectorButtonProps
>(
    (
        { maxFileSizeKb, disabled = false, required = false, ...inputProps },
        ref
    ) => {
        return (
            <>
                <input
                    id="file"
                    type="file"
                    ref={ref}
                    aria-required={true}
                    className="hidden"
                    disabled={disabled}
                    {...inputProps}
                    required={required}
                />
                <button
                    type="button"
                    className={clsx(
                        "cursor-pointer text-primary-text font-bold py-2 px-4",
                        "border-2 border-black rounded-sm focus:outline whitespace-nowrap"
                    )}
                >
                    <label
                        htmlFor="file"
                        className={clsx("cursor-pointer py-2 px-4")}
                    >
                        Select file
                    </label>
                </button>
                <p className="text-xs">Size limit {maxFileSizeKb}Kb</p>
            </>
        );
    }
);

export default FileSelectorButton;
