import { type ApiAuditItem } from "../../models/api/admin/audits";
import { formatDateToString } from "../../utils/formatDateToString";
import { summarizeObjectToString } from "../../utils/summarizeObjectToString";
import {
    type TableContent,
    type TableHeader,
} from "../ReusableComponents/Table";
import UserIcon from "../UserIcon";

export type AuditsTableAllowedIds =
    | "dateTime"
    | "action"
    | "previousValues"
    | "newValues"
    | "editedBy"
    | "userEmail"
    | "userId";

export type AuditsTableHeader = TableHeader<AuditsTableAllowedIds>;
export type AuditsTableContent = TableContent<
    ApiAuditItem,
    AuditsTableAllowedIds
>;

export const initAuditTableHeaders: AuditsTableHeader[] = [
    {
        title: "Date and time",
        id: "dateTime",
        menuGroup: `Audit Details`,
    },
    {
        title: "Action",
        id: "action",
        menuGroup: `Audit Details`,
    },
    {
        title: "From",
        id: "previousValues",
        menuGroup: `Audit Details`,
    },
    {
        title: "To",
        id: "newValues",
        menuGroup: `Audit Details`,
    },
    {
        title: "Edited by",
        id: "editedBy",
        menuGroup: `Editor Details`,
    },
    {
        title: "Editor email",
        id: "userEmail",
        menuGroup: `Editor Details`,
        hide: true,
    },
    {
        title: "Editor id",
        id: "userId",
        menuGroup: `Editor Details`,
        hide: true,
    },
];

export function initAuditsTableContents(
    audits: ApiAuditItem[]
): AuditsTableContent[] {
    const tableContents = new Array<AuditsTableContent>(0);

    audits.map((audit) => {
        const prevValues: string[] = new Array(0);
        const newValues: string[] = new Array(0);
        audit.previousValues &&
            summarizeObjectToString(audit.previousValues, prevValues);
        audit.newValues && summarizeObjectToString(audit.newValues, newValues);

        tableContents.push({
            rowId: audit.auditId,
            rowData: audit,
            cellData: [
                {
                    id: "dateTime",
                    content: (
                        <p>
                            {formatDateToString(
                                new Date(audit.auditDate),
                                true
                            ) ?? ""}
                        </p>
                    ),
                },
                {
                    id: "action",
                    content: <p>{audit.changeType}</p>,
                },
                {
                    id: "previousValues",
                    content: (
                        <div className="flex max-w-[200px] flex-col gap-2 break-words">
                            {prevValues &&
                                prevValues.map((value) => <p>{value}</p>)}
                        </div>
                    ),
                },
                {
                    id: "newValues",
                    content: (
                        <div className="flex max-w-[200px] flex-col gap-2 break-words">
                            {newValues &&
                                newValues.map((value) => <p>{value}</p>)}
                        </div>
                    ),
                },
                {
                    id: "editedBy",
                    content: (
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-2">
                                {audit.user?.firstName && (
                                    <UserIcon
                                        firstName={audit.user.firstName}
                                        lastName={audit.user.lastName}
                                        size="small"
                                    />
                                )}
                                {/* user being null means there was an automatic system update, eg dvla checks */}
                                <div className="flex flex-wrap gap-2">
                                    <p>
                                        {audit.user
                                            ? `${audit.user?.firstName} ${audit.user?.lastName}`
                                            : "System"}
                                    </p>
                                    {audit.impersonatingUser && (
                                        <p>
                                            {"("}impersonated by{" "}
                                            <b>
                                                {
                                                    audit.impersonatingUser
                                                        .firstName
                                                }{" "}
                                                {
                                                    audit.impersonatingUser
                                                        .lastName
                                                }
                                            </b>
                                            {")"}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ),
                },
                {
                    id: "userEmail",
                    content: <p>{audit.user?.userName}</p>,
                },
                {
                    id: "userId",
                    content: <p>{audit.user?.userId}</p>,
                },
            ],
        });
    });

    return tableContents;
}
