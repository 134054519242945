import { UseFormReturn } from "react-hook-form";
import { type DriversListFiltersFormValues } from "./DriversListFiltersFormValues";
import FormTextInput from "../../FormTextInput";
import { type Dispatch, type SetStateAction } from "react";
import { generateSelectedValues } from "./generateSelectedValues";
import AccordionItem from "../../AccordionItem";

type FilterLinkedDriverProps = {
    methods: UseFormReturn<DriversListFiltersFormValues>;
    selectedValues: string[];
    setSelectedValues: Dispatch<SetStateAction<string[]>>;
};

const FilterLinkedDriver: React.FC<FilterLinkedDriverProps> = ({
    methods,
    selectedValues,
    setSelectedValues,
}) => {
    return (
        <AccordionItem
            title="Linked driver"
            valueSummary={selectedValues}
            modalStyling
        >
            <>
                <FormTextInput
                    label="Main driver first name contains"
                    placeholder="Enter text"
                    error={methods.formState.errors.linkedDriver?.firstName}
                    {...methods.register("linkedDriver.firstName")}
                    onBlur={(e) =>
                        generateSelectedValues(
                            selectedValues,
                            setSelectedValues,
                            "First name",
                            e.target.value
                        )
                    }
                />
                <FormTextInput
                    label="Main driver last name contains"
                    placeholder="Enter text"
                    error={methods.formState.errors.linkedDriver?.lastName}
                    {...methods.register("linkedDriver.lastName")}
                    onBlur={(e) =>
                        generateSelectedValues(
                            selectedValues,
                            setSelectedValues,
                            "Last name",
                            e.target.value
                        )
                    }
                />
            </>
        </AccordionItem>
    );
};

export default FilterLinkedDriver;
