import {
    useAuthLogin,
    useLogIn2FA,
    useUserImpersonation,
} from "../api/authLogIn";
import useLocalStorage from "../hooks/useLocalStorage";
import { ApiCompanyResponse } from "../models/api/admin/company";
import { ApiDepartmentItem } from "../models/api/admin/department";
import { LoginApiResponse } from "../models/api/login";
import { ImpersonateUserDto, LogIn2FADto, LogInDto } from "../models/app/logIn";
import { errorToast } from "../toast";
import { AuthContext, StoredAuthorization, UserDetails } from "./AuthContext";
import { SimplifiedDecodedToken, UserRoles, decodeToken } from "./decodeToken";

type AuthProviderProps = {
    children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken] = useLocalStorage<string | undefined>(
        "token",
        undefined
    );
    const [userDetails, setUserDetails] = useLocalStorage<
        UserDetails | undefined
    >("details", undefined);
    const [expires, setExpires] = useLocalStorage<string | undefined>(
        "expires",
        undefined
    );
    const [twofaEnabled, setTwofaEnabled] = useLocalStorage<boolean>(
        "twofaEnabled",
        false
    );
    const [decodedToken, setDecodedToken] = useLocalStorage<
        SimplifiedDecodedToken | undefined
    >("decodedToken", undefined);
    const [storedAuthorization, setStoredAuthorization] =
        useLocalStorage<StoredAuthorization | null>(
            "storedAuthorization",
            null
        );
    const [selectedCompany, setSelectedCompany] =
        useLocalStorage<ApiCompanyResponse | null>("SelectedCompany", null);
    const [selectedDepartment, setSelectedDepartment] =
        useLocalStorage<ApiDepartmentItem | null>("SelectedDepartment", null);
    const [
        tempImpersonatedSelectedCompany,
        setTempImpersonatedSelectedCompany,
    ] = useLocalStorage<ApiCompanyResponse | null>(
        "tempImpersonatedSelectedCompany",
        null
    );
    const [
        tempImpersonatedSelectedDepartment,
        setTempImpersonatedSelectedDepartment,
    ] = useLocalStorage<ApiDepartmentItem | null>(
        "tempImpersonatedSelectedDepartment",
        null
    );

    const { logIn, isLoggingIn } = useAuthLogin();
    const { logIn2FA, isLoggingIn2FA } = useLogIn2FA();
    const { impersonateUser, isImpersonatingUser } =
        useUserImpersonation(token);

    const logOut = () => {
        setToken(undefined);
        setDecodedToken(undefined);
        localStorage.clear();
    };

    const expiryTimer = (tokenExpiryTime: string) => {
        //sets timer on login, reloads are handled in Routes.tsx
        const expiryTime = new Date(tokenExpiryTime);
        const start = new Date();
        const millsToExpire = expiryTime.getTime() - start.getTime();
        setTimeout(logOut, millsToExpire);
    };

    const isDriverUserOnly = (role: UserRoles | undefined) => {
        if (!role || role === UserRoles.driver) {
            return true;
        }
    };

    const handleLoginSuccess = (content: LoginApiResponse) => {
        if (content.token) {
            setToken(content.token);
            const decodedToken = decodeToken(content.token ?? undefined);
            if (decodedToken) setDecodedToken(decodedToken);
            setUserDetails(content.user);
            setExpires(content.tokenExpiryTime);
            expiryTimer(content.tokenExpiryTime);

            if (isDriverUserOnly(decodedToken?.role)) {
                errorToast(
                    "You do not have access to the web version of Safe2Drive."
                );
                setToken(undefined);
                setDecodedToken(undefined);
                localStorage.clear();
            }
        } else {
            errorToast("Token returned null");
        }
    };

    const authorize = async (dto: LogInDto, callback: () => void) => {
        const res = await logIn(dto);

        if (res.success) {
            if (res.content.requires2fa) {
                setTwofaEnabled(res.content.requires2fa);
                callback();
            } else {
                handleLoginSuccess(res.content);
            }
        }
        return res;
    };

    const authorize2FA = async (dto: LogIn2FADto) => {
        const res = await logIn2FA(dto);

        if (res.success) {
            handleLoginSuccess(res.content);
        }
        return res;
    };

    const handleUserImpersonation = (content: LoginApiResponse) => {
        setSelectedCompany(null);
        setSelectedDepartment(null);

        // store current auth details for later retrieval
        setStoredAuthorization({
            token: token,
            decodedToken: decodedToken,
            userDetails: userDetails,
            twofaEnabled: twofaEnabled,
        });

        /* this is the same as handleLoginSuccess except expiry times 
            are carried over from the original logged in user. */
        if (content.token) {
            setToken(content.token);
            const decodedToken = decodeToken(content.token ?? undefined);
            if (decodedToken) setDecodedToken(decodedToken);
            setUserDetails(content.user);

            if (isDriverUserOnly(decodedToken?.role)) {
                errorToast(
                    "You do not have access to the web version of Safe2Drive."
                );
                setToken(undefined);
                setDecodedToken(undefined);
                localStorage.clear();
            }
        } else {
            errorToast("Token returned null");
        }
    };

    const authorizeImpersonatedUser = async (
        dto: ImpersonateUserDto,
        callback: () => void
    ) => {
        const response = await impersonateUser(dto);

        if (response.success) {
            handleUserImpersonation(response.content);
            callback();
        }
    };

    const restoreAuthUser = (callback: () => void) => {
        if (storedAuthorization) {
            setToken(storedAuthorization.token);
            setDecodedToken(storedAuthorization.decodedToken);
            setUserDetails(storedAuthorization.userDetails);
            setSelectedCompany(tempImpersonatedSelectedCompany);
            setSelectedDepartment(tempImpersonatedSelectedDepartment);
            setStoredAuthorization(null);
            callback();
        } else {
            errorToast("Failed to retrieve auth user details.");
        }
    };

    return (
        <AuthContext.Provider
            value={{
                token,
                setToken,
                decodedToken,
                userDetails,
                setUserDetails,
                expires,
                twofaEnabled,
                setTwofaEnabled,
                authorize,
                isAuthorizing: isLoggingIn,
                authorize2FA,
                isAuthorizing2FA: isLoggingIn2FA,
                storedAuthorization,
                setStoredAuthorization,
                authorizeImpersonatedUser,
                isAuthorizingImpersonatedUser: isImpersonatingUser,
                restoreAuthUser,
                tempImpersonatedSelectedCompany,
                setTempImpersonatedSelectedCompany,
                tempImpersonatedSelectedDepartment,
                setTempImpersonatedSelectedDepartment,
                logOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
