import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import {
    DriverAddressInformation,
    DriverVehicleInformation,
} from "../../models/api/admin/driverDetails";
import { type GreyFleetTableData } from "../../models/api/admin/greyFleet";
import { CompanyRiskProfile } from "../../models/app/admin/company";
import { driverPointsColor } from "../../utils/driverPointsColour";
import { formatDateToString } from "../../utils/formatDateToString";
import {
    DriverAddressTableContentAllowedIds,
    driverAddressTableContent,
} from "../ReusableComponents/SharedTableContent/driverAddressTableContent";
import {
    DriverVehicleTableContentAllowedIds,
    driverVehicleTableContent,
    driverVehicleTableHeaders,
} from "../ReusableComponents/SharedTableContent/driverVehicleTableContent";
import {
    type TableContent,
    type TableHeader,
} from "../ReusableComponents/Table";
import UserIcon from "../UserIcon";

type AllowedIds =
    | "driverId"
    | "name"
    | "email"
    | "DOB"
    | "declarationStatus"
    | "declarationExpiry"
    | "company"
    | "department"
    | "jobRole"
    | "employeeNumber"
    | "externalReference"
    | "licenceNumber"
    | "licenceOrigin"
    | "licenceExpires"
    | "licenceNextCheckDate"
    | "licenceLastCheckedAtDate"
    | "points"
    | "linkedDriverStatus"
    | "mainDriverName"
    | "comments"
    | DriverAddressTableContentAllowedIds
    | DriverVehicleTableContentAllowedIds;

export enum GreyfleetMenuGroups {
    PersonalInformation = "Personal information",
    Declaration = "Declaration",
    Employment = "Employment",
    DrivingLicence = "Driving licence",
    VehicleInformation = "Vehicle information",
    Address = "Address",
    LinkedDriver = "Linked driver",
    Comments = "Comments",
}

export const greyfleetMenuGroups: GreyfleetMenuGroups[] = (
    Object.keys(GreyfleetMenuGroups) as Array<keyof typeof GreyfleetMenuGroups>
).map((key) => GreyfleetMenuGroups[key]);

type GreyFleetTableContents = TableContent<GreyFleetTableData, AllowedIds>;

export const initGreyFleetTableHeaders: TableHeader<AllowedIds>[] = [
    {
        title: "Driver Id",
        id: "driverId",
        menuGroup: GreyfleetMenuGroups.PersonalInformation,
        hide: true,
    },
    {
        title: "Name",
        id: "name",
        menuGroup: GreyfleetMenuGroups.PersonalInformation,
        hide: false,
    },
    {
        title: "Email",
        id: "email",
        hide: false,
        menuGroup: "Driver Details",
    },
    {
        title: "Date of birth",
        id: "DOB",
        hide: true,
        orderBy: "DateOfBirth",
        menuGroup: GreyfleetMenuGroups.PersonalInformation,
    },
    {
        title: "Declaration status",
        id: "declarationStatus",
        hide: true,
        menuGroup: GreyfleetMenuGroups.Declaration,
    },
    {
        title: "Declaration expires",
        id: "declarationExpiry",
        hide: true,
        menuGroup: GreyfleetMenuGroups.Declaration,
    },
    {
        title: capitalisedTermForCompany,
        id: "company",
        menuGroup: GreyfleetMenuGroups.Employment,
        hide: false,
    },
    {
        title: "Department",
        id: "department",
        menuGroup: "Employment",
        hide: false,
    },
    {
        title: "Job role",
        id: "jobRole",
        hide: true,
        menuGroup: GreyfleetMenuGroups.Employment,
    },
    {
        title: "Employee number",
        id: "employeeNumber",
        hide: true,
        orderBy: "EmployeeId",
        menuGroup: GreyfleetMenuGroups.Employment,
    },
    {
        title: "External reference",
        id: "externalReference",
        hide: true,
        orderBy: "ExternalReference",
        menuGroup: GreyfleetMenuGroups.Employment,
    },
    {
        title: "Licence number",
        id: "licenceNumber",
        hide: true,
        orderBy: "LicenseNumber",
        menuGroup: GreyfleetMenuGroups.DrivingLicence,
    },
    {
        title: "Licence origin",
        id: "licenceOrigin",
        menuGroup: GreyfleetMenuGroups.DrivingLicence,
        hide: true,
    },
    {
        title: "Licence expires",
        id: "licenceExpires",
        menuGroup: GreyfleetMenuGroups.DrivingLicence,
        hide: true,
    },
    {
        title: "Points",
        id: "points",
        menuGroup: "Driving licence",
        hide: false,
    },
    {
        title: "Last Checked",
        id: "licenceLastCheckedAtDate",
        hide: true,
        orderBy: "licenceLastCheckedAtDate",
        menuGroup: GreyfleetMenuGroups.DrivingLicence,
    },
    {
        title: "Next Check",
        id: "licenceNextCheckDate",
        hide: true,
        orderBy: "licenceNextCheckDate",
        menuGroup: GreyfleetMenuGroups.DrivingLicence,
    },
    {
        title: "Linked driver status",
        id: "linkedDriverStatus",
        hide: true,
        menuGroup: GreyfleetMenuGroups.LinkedDriver,
    },
    {
        title: "Main driver name",
        id: "mainDriverName",
        hide: true,
        menuGroup: GreyfleetMenuGroups.LinkedDriver,
    },
    {
        title: "Comments",
        id: "comments",
        hide: true,
        menuGroup: GreyfleetMenuGroups.Comments,
    },
    ...driverVehicleTableHeaders,
];

export function initGreyFleetTableContents(
    drivers?: GreyFleetTableData[] | null,
    companyRiskProfile?: CompanyRiskProfile[]
): GreyFleetTableContents[] {
    const tableContents = new Array<GreyFleetTableContents>(0);
    drivers?.map((driver) => {
        const address: DriverAddressInformation = {
            postCode: driver.postCode,
            addressMatch: driver.addressMatch,
        };
        const vehicles: DriverVehicleInformation = {
            vehicleId: driver.vehicleId,
            vehicleRegistration: driver.vehicleRegistration,
            manufacturer: driver.manufacturer,
            euroRating: driver.euroRating,
            model: driver.model,
            yearOfManufacture: driver.yearOfManufacture,
            motExpiry: driver.motExpiry,
            engineCapacity: driver.engineCapacity,
            roadTaxExpiry: driver.roadTaxExpiry,
            co2Emissions: driver.co2Emissions,
            fuelType: driver.fuelType,
            insuranceType: driver.insuranceType,
            vehicleInsuranceUploaded: driver.vehicleInsuranceUploaded,
            vehicleInsuranceVerified: driver.vehicleInsuranceVerified,
            businessCover: driver.businessCover,
        };
        tableContents.push({
            rowId: driver.driverId,
            rowData: driver,
            cellData: [
                {
                    id: "driverId",
                    content: <p>{driver.driverId}</p>,
                },
                {
                    id: "name",
                    content: (
                        <div className="flex items-center gap-2">
                            <UserIcon
                                firstName={driver.firstName}
                                lastName={driver.lastName}
                                size="small"
                            />
                            <p>{`${driver.firstName} ${driver.lastName}`}</p>
                        </div>
                    ),
                },
                {
                    id: "email",
                    content: <p>{driver.emailAddress}</p>,
                },
                {
                    id: "DOB",
                    content: (
                        <p>
                            {driver.dateOfBirth
                                ? formatDateToString(
                                      new Date(driver.dateOfBirth)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "declarationStatus",
                    content: <p>{driver.declarationStatus}</p>,
                },
                {
                    id: "declarationExpiry",
                    content: (
                        <p>
                            {driver.declarationExpiry
                                ? formatDateToString(
                                      new Date(driver.declarationExpiry)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "company",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.companyName}
                        </p>
                    ),
                },
                {
                    id: "department",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.departmentName}
                        </p>
                    ),
                },
                {
                    id: "jobRole",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.jobRole}
                        </p>
                    ),
                },
                {
                    id: "employeeNumber",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.employeeNumber}
                        </p>
                    ),
                },
                {
                    id: "externalReference",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.externalReference}
                        </p>
                    ),
                },
                {
                    id: "licenceNumber",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.licenceNumber}
                        </p>
                    ),
                },
                {
                    id: "licenceOrigin",
                    content: <p>{driver.licenceOrigin}</p>,
                },
                {
                    id: "licenceExpires",
                    content: (
                        <p>
                            {driver.licenceExpires
                                ? formatDateToString(
                                      new Date(driver.licenceExpires)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "points",
                    content: (
                        <div className="pl-2">
                            <div
                                className={`h-7 w-7 rounded-full ${driverPointsColor(
                                    driver.totalPoints,
                                    companyRiskProfile
                                )} flex items-center justify-center`}
                            >
                                {driver.totalPoints ?? "-"}
                            </div>
                        </div>
                    ),
                },
                {
                    id: "licenceLastCheckedAtDate",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.licenceLastCheckedAtDate
                                ? formatDateToString(
                                      new Date(driver.licenceLastCheckedAtDate)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "licenceNextCheckDate",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.licenceNextCheckDate
                                ? formatDateToString(
                                      new Date(driver.licenceNextCheckDate)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "linkedDriverStatus",
                    content: <p>{driver.linkedDriverStatus}</p>,
                },
                {
                    id: "mainDriverName",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {driver.mainDriverName}
                        </p>
                    ),
                },
                {
                    id: "comments",
                    content: (
                        <div className="pl-6">
                            <div className="flex items-center justify-center rounded-full h-7 w-7 bg-S2D-light-green-80">
                                {driver.comments}
                            </div>
                        </div>
                    ),
                },
                ...driverAddressTableContent(address),
                ...driverVehicleTableContent(vehicles),
            ],
        });
    });
    return tableContents;
}
