import DvlaChecksTable from "../../components/DvlaChecksTable";
import DvlaChecksTableFilters from "../../components/DvlaChecksTableFilters";
import PageLayout from "../../components/PageLayout/PageLayout";
import HeaderOptions from "../../components/ReusableComponents/HeaderOptions";
import FormMessageBox from "../../components/FormMessageBox";
import { useDvlaChecks } from "./pageHooks/useDvlaChecks";
import AnalysisComponent, {
    type AnalysisComponentContentType,
} from "../../components/ReusableComponents/AnalysisComponent/AnalysisComponent";
import { dvlaChecksAnalysisContent } from "../../components/DvlaChecksAnalysis/dvlaChecksAnalysisContent";
import CollapsibleSection from "../../components/ReusableComponents/CollapsibleSection";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useEffect, useRef, useState } from "react";
import { type DvlaCheckItem } from "../../models/api/safe2DriveAdmin/dvlaChecks";
import { type TableCheckbox } from "../../components/ReusableComponents/Table/tableTypes";
import DvlaChecksPdfReport from "../../components/DvlaChecksPdfReport";
import { useScrollToTable } from "../../hooks/useScrollToTable";
import { useDvlaCheckAnalysisActions } from "../../components/DvlaChecksAnalysis/useDvlaChecksAnalysisActions";
import { useNavigate } from "react-router-dom";
import useAuth from "../../auth/useAuth";
import { isS2dUser } from "../../auth/userAccessHelpers";
import { appPaths } from "../../Routes";
import { DvlaAnalysisTabs } from "../../models/api/admin/dvlaAnalysis";
import { useDvlaChecksAnalysis } from "../../components/DvlaChecksAnalysis/useDvlaChecksAnalysis";

const DvlaChecks = () => {
    // redirect if the user is not s2d
    const navigate = useNavigate();
    const { decodedToken } = useAuth();
    useEffect(() => {
        if (!isS2dUser(decodedToken?.role)) navigate(appPaths.fourOFour);
    }, []);

    const {
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
        orderBy,
        setOrderBy,
        searchQuery,
        setSearchQuery,
        selectedCompany,
        selectedDepartment,
        dvlaCheckFilters,
        handleFormSubmit,
        dvlaChecksData,
        dvlaChecksError,
        isLoadingDvlaChecks,
        refetchDvlaChecks,
        handleCompanyChange,
        handleDepartmentChange,
        handleReset,
        isCsvReportDownloading,
        handleDownloadCsvReport,
    } = useDvlaChecks();

    const tableRef = useRef<HTMLDivElement | null>(null);

    const {
        trackChecksData,
        trackChecksError,
        isLoadingTrackChecks,
        tabOption,
        handleTabSelect,
        reportRef,
        showReportView,
        handleDownloadPdfReport,
        isLoadingPdfReport,
        dvlaPdfAnalysisData,
        refetchDvlaAnalysisData,
    } = useDvlaChecksAnalysis(selectedCompany, selectedDepartment);

    // analysis component
    const [isDvlaChecksAnalysisOpen, setIsDvlaChecksAnalysisOpen] =
        useLocalStorage<boolean>(`isDvlaChecksAnalysisComponentOpen`, true);

    const dvlaChecksAnalysisActions = useDvlaCheckAnalysisActions(
        handleFormSubmit,
        dvlaChecksData,
        tableRef
    );

    const analysisContent: AnalysisComponentContentType<DvlaAnalysisTabs>[] =
        dvlaChecksAnalysisContent(
            trackChecksData,
            trackChecksError,
            dvlaChecksAnalysisActions,
            isLoadingDvlaChecks
        );

    // table checkbox controller
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<
        TableCheckbox<DvlaCheckItem>[]
    >([]);

    const clearSelectedCheckboxes = () => {
        setSelectedCheckboxes([]);
    };

    // reset selected checkboxes when page data updates
    // eg through filtering or changing page
    useEffect(() => {
        clearSelectedCheckboxes();
    }, [dvlaChecksData]);

    const hasLoaded = !!dvlaChecksData && !isLoadingDvlaChecks;
    useScrollToTable(hasLoaded, tableRef);

    const refetchAll = () => {
        refetchDvlaChecks();
        refetchDvlaAnalysisData();
    };

    return (
        <PageLayout title="DVLA Checks" header={false}>
            <div className="flex flex-col gap-6">
                <HeaderOptions
                    selectedCompany={selectedCompany}
                    handleCompanyChange={handleCompanyChange}
                    selectedDepartment={selectedDepartment}
                    handleDepartmentChange={handleDepartmentChange}
                    clearCompanyDepartment={handleReset}
                    handleDownloadCsvReport={handleDownloadCsvReport}
                    handleDownloadPdfReport={handleDownloadPdfReport}
                    isReportDownloading={
                        isCsvReportDownloading || isLoadingPdfReport
                    }
                    isReportDisabled={isLoadingTrackChecks}
                />

                <CollapsibleSection
                    title={"Check analysis"}
                    isOpen={isDvlaChecksAnalysisOpen}
                    setIsOpen={setIsDvlaChecksAnalysisOpen}
                    content={
                        <AnalysisComponent
                            analysisContent={analysisContent}
                            onSelect={handleTabSelect}
                            selectedTab={tabOption}
                        />
                    }
                />
                <DvlaChecksTableFilters
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    dvlaCheckFilters={dvlaCheckFilters}
                    handleFormSubmit={handleFormSubmit}
                    selectedCheckboxes={selectedCheckboxes}
                    refetchDvlaChecks={refetchDvlaChecks}
                    isLoadingDvlaChecks={isLoadingDvlaChecks}
                />
                <DvlaChecksTable
                    dvlaChecks={dvlaChecksData}
                    refetchDvlaChecks={refetchAll}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    isLoading={isLoadingDvlaChecks}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    tableRef={tableRef}
                    selectedCompany={selectedCompany ?? undefined}
                />
                {dvlaChecksError && (
                    <FormMessageBox
                        variant="error"
                        title="Error loading data"
                        message={
                            "There was an error loading the DVLA checks table data, please try again later."
                        }
                    />
                )}
                {showReportView && (
                    // NB report is never actually intended to be seen by the user, only appended to the bottom of the page
                    <>
                        <div ref={reportRef}>
                            <DvlaChecksPdfReport
                                analysisData={dvlaPdfAnalysisData}
                                companyName={selectedCompany?.companyName}
                                departmentName={
                                    selectedDepartment?.departmentName
                                }
                            />
                        </div>
                    </>
                )}
            </div>
        </PageLayout>
    );
};

export default DvlaChecks;
