import {
    ArrowsClockwise,
    CalendarBlank,
    CalendarX,
    CheckCircle,
    DownloadSimple,
    Pencil,
    Timer,
    UserGear,
} from "phosphor-react";
import { appPaths } from "../../Routes";
import { NavigateFunction } from "react-router-dom";
import {
    type DvlaCheckItem,
    DvlaCheckOrderBy,
} from "../../models/api/safe2DriveAdmin/dvlaChecks";
import { type TableMenuItem } from "../ReusableComponents/Table/TableMenu";
import { GREAT_BRITAIN, NORTHERN_IRELAND } from "../../constants/countries";
import { CheckStatus, CheckType } from "../../models/shared/statuses";
import { DvlaCheckTableActions } from "./useDvlaChecksTableActions";

export const dvlaChecksTableMenuItems = (
    data: DvlaCheckItem,
    navigate: NavigateFunction,
    menuActions: DvlaCheckTableActions
): TableMenuItem[] => {
    const id = data.driverId;
    const {
        handleInstantCheck,
        handleOpenNextCheckDateDialog,
        handleOpenDisableDriverDialog,
        handleCheckStatus,
        handleCheckType,
        handleDownloadD906,
        handleDownloadDVA,
    } = menuActions;

    const editDriverItem: TableMenuItem = {
        key: "editDriver",
        onClick: () => navigate(appPaths.adminEditDriver(id)),
        icon: <Pencil size={20} weight="bold" />,
        text: "Edit Driver",
    };

    const instantCheckItem: TableMenuItem = {
        key: "instantCheck",
        onClick: () => handleInstantCheck(id, data.licenceNumber ?? undefined),
        icon: <CheckCircle size={20} weight="fill" />,
        text: "Instant check",
    };

    const changeToManualItem: TableMenuItem = {
        key: "changeToManual",
        onClick: () => handleCheckType(id, CheckType.DVLA),
        icon: <UserGear size={20} weight="fill" />,
        text: "Change to manual",
    };

    const editNextCheckDateItem: TableMenuItem = {
        key: "editNextCheckDate",
        onClick: () => handleOpenNextCheckDateDialog(id),
        icon: <CalendarBlank size={20} weight="fill" />,
        text: "Edit next check date",
    };

    const disableFurtherChecksItem: TableMenuItem = {
        key: "disableFurtherChecks",
        onClick: () => handleOpenDisableDriverDialog(id),
        icon: <CalendarX size={20} weight="fill" />,
        text: "Disable further checks",
    };

    const retryCheckItem: TableMenuItem = {
        key: "retryCheck",
        onClick: () => handleInstantCheck(id, data.licenceNumber ?? undefined),
        icon: <ArrowsClockwise size={20} weight="fill" />,
        text: "Retry check",
    };

    const enableAndSetNextCheckDateItem: TableMenuItem = {
        key: "enableAndSetNextCheckDate",
        onClick: () => handleOpenNextCheckDateDialog(id),
        icon: <CalendarBlank size={20} weight="fill" />,
        text: "Enable & set next check date",
    };

    const downloadD906FormItem: TableMenuItem = {
        key: "downloadD906Form",
        onClick: () => handleDownloadD906(id),
        icon: <DownloadSimple size={20} weight="fill" />,
        text: "Download D906 form",
    };

    const downloadDVAFormItem: TableMenuItem = {
        key: "downloadDVAForm",
        onClick: () => handleDownloadDVA(id),
        icon: <DownloadSimple size={20} weight="fill" />,
        text: "Download DVA form",
    };

    const markAsPendingItem: TableMenuItem = {
        key: "markAsPending",
        onClick: () => handleCheckStatus(CheckStatus.Pending, id),
        icon: <Timer size={20} weight="fill" />,
        text: "Mark as pending",
    };

    const markAsCheckedItem: TableMenuItem = {
        key: "markAsChecked",
        onClick: () => handleCheckStatus(CheckStatus.Checked, id),
        icon: <CheckCircle size={20} weight="fill" />,
        text: "Mark as checked",
    };

    // data deferring to the "default" response should generally be considered bad data
    // but they have the "edit driver" option for testing purposes.

    // DVLA Check States
    if (data.licenceCountryOrigin === GREAT_BRITAIN) {
        if (data.checkType === CheckType.DVLA) {
            switch (data.checkStatus) {
                case CheckStatus.Scheduled:
                case CheckStatus.Pending:
                case CheckStatus.Overdue:
                    return [
                        editDriverItem,
                        instantCheckItem,
                        changeToManualItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                case CheckStatus.DvlaError500:
                case CheckStatus.DvlaError503:
                case CheckStatus.DvlaError400:
                case CheckStatus.DvlaError403:
                case CheckStatus.DvlaErrorOther:
                case CheckStatus.Failed:
                    return [
                        editDriverItem,
                        retryCheckItem,
                        changeToManualItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                case CheckStatus.Checked:
                    return [
                        editDriverItem,
                        changeToManualItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                case CheckStatus.Disabled:
                case CheckStatus.None:
                    return [editDriverItem, enableAndSetNextCheckDateItem];
                default:
                    return [editDriverItem];
            }
        } else {
            switch (data.checkStatus) {
                case CheckStatus.Scheduled:
                    return [
                        editDriverItem,
                        downloadD906FormItem,
                        markAsPendingItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                case CheckStatus.Overdue:
                case CheckStatus.Pending:
                    return [
                        editDriverItem,
                        markAsCheckedItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                case CheckStatus.Checked:
                    return [
                        editDriverItem,
                        downloadD906FormItem,
                        editNextCheckDateItem,
                        disableFurtherChecksItem,
                    ];
                case CheckStatus.Disabled:
                case CheckStatus.None:
                    return [editDriverItem, enableAndSetNextCheckDateItem];
                default:
                    return [editDriverItem];
            }
        }
        // Manual (Irish) Check States
    } else if (data.licenceCountryOrigin === NORTHERN_IRELAND) {
        switch (data.checkStatus) {
            case CheckStatus.Scheduled:
                return [
                    editDriverItem,
                    downloadDVAFormItem,
                    markAsPendingItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case CheckStatus.Overdue:
            case CheckStatus.Pending:
                return [
                    editDriverItem,
                    markAsCheckedItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case CheckStatus.Checked:
                return [
                    editDriverItem,
                    downloadDVAFormItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case CheckStatus.Disabled:
            case CheckStatus.None:
                return [editDriverItem, enableAndSetNextCheckDateItem];
            default:
                return [editDriverItem];
        }
        // Manual (Foreign) Check States
    } else {
        switch (data.checkStatus) {
            case CheckStatus.Scheduled:
                return [
                    editDriverItem,
                    downloadD906FormItem,
                    markAsPendingItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case CheckStatus.Overdue:
            case CheckStatus.Pending:
                return [
                    editDriverItem,
                    markAsCheckedItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case CheckStatus.Checked:
                return [
                    editDriverItem,
                    downloadD906FormItem,
                    editNextCheckDateItem,
                    disableFurtherChecksItem,
                ];
            case CheckStatus.Disabled:
            case CheckStatus.None:
                return [editDriverItem, enableAndSetNextCheckDateItem];
            default:
                return [editDriverItem];
        }
    }
};

export const dvlaChecksTableMenuGroups = ["Driver Details", "Licence Details"];

export const dvlaChecksOrderByOptions = [
    DvlaCheckOrderBy.LastName,
    DvlaCheckOrderBy.Company,
    DvlaCheckOrderBy.Department,
];
