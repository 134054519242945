import clsx from "clsx";
import { capitaliseWord } from "../../utils/capitaliseWord";
import { addSpacesToCamelCase } from "../../utils/addSpacesToCamelCase";

type ActionableStatusTagProps = {
    text?: string;
    theme: keyof typeof actionableStatusTagTheme;
};

export const actionableStatusTagTheme = {
    safe2Drive: "bg-S2D-neutral-10 text-S2D-neutral-100",
    scheduled: "bg-S2D-success-80 text-S2D-neutral-10",
    completed: "bg-S2D-success-80 text-S2D-neutral-10",
    incomplete: "bg-S2D-error-40 text-S2D-neutral-100",
    vrm: "bg-S2D-neutral-80 text-S2D-neutral-10",
    pending: "bg-S2D-warning-90 text-S2D-neutral-10",
    dueToExpire: "bg-S2D-warning-90 text-S2D-neutral-10",
    expired: "bg-S2D-error-40 text-S2D-neutral-100",
    white: "bg-S2D-neutral-100 text-S2D-neutral-10",
    //E-Declaration
    notRequested: "bg-S2D-neutral-80 text-S2D-neutral-10",
    foreignLicence: "bg-S2D-error-40 text-S2D-neutral-100",
    dvlaError: "bg-S2D-error-40 text-S2D-neutral-100",
    //penalty points
    mediumRisk: "bg-S2D-neutral-10 text-S2D-neutral-100",
    highRisk: "bg-S2D-neutral-10 text-S2D-neutral-100",
    disqualified: "bg-S2D-neutral-10 text-S2D-neutral-100",
    previouslyDisqualified: "bg-S2D-neutral-10 text-S2D-neutral-100",
};

const ActionableStatusTag = ({ text, theme }: ActionableStatusTagProps) => {
    return (
        <div
            className={clsx(
                "flex w-full justify-center truncate rounded py-2 px-4 text-xs font-semibold",
                actionableStatusTagTheme[theme]
            )}
        >
            {text || capitaliseWord(addSpacesToCamelCase(theme))}
        </div>
    );
};

export default ActionableStatusTag;
