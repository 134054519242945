import { PencilSimple, Trash } from "phosphor-react";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import Table, { TableHeader } from "../../../ReusableComponents/Table";
import { TableMenuItem } from "../../../ReusableComponents/Table/TableMenu";
import {
    initialDepartmentsTableHeaders,
    initDepartmentsTableContents,
} from "./CompanyDepartmentsTableInit";
import ConfirmationDialog from "../../../ReusableComponents/ConfirmationDialog";
import { SetStateAction, useState } from "react";
import {
    type ApiDepartmentItem,
    type ApiDepartmentResponse,
} from "../../../../models/api/admin/department";
import LoadingMessage from "../../../ReusableComponents/LoadingMessage/LoadingMessage";
import FormMessageBox from "../../../FormMessageBox";
import PaginationNav from "../../../PaginationNav";
import { AddOrEditModalStates } from "../CompanyBillingTab";
import { useDepartment } from "../../../../api/admin/department";
import { errorToast, successToast } from "../../../../toast";

interface Props {
    companyId: string;
    departments?: ApiDepartmentResponse;
    isLoadingDepartments?: boolean;
    departmentsError?: Error;
    refetchDepartments?: () => void;
    selectedDepartment: ApiDepartmentItem | undefined;
    setSelectedDepartment: (
        value: SetStateAction<ApiDepartmentItem | undefined>
    ) => void;
    setAddOrEditModalState: (
        value: SetStateAction<AddOrEditModalStates>
    ) => void;
    pageNumber: number;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    canEdit: boolean;
}

export default function CompanyDepartmentsTable({
    companyId,
    departments,
    isLoadingDepartments,
    departmentsError,
    refetchDepartments,
    selectedDepartment,
    setSelectedDepartment,
    setAddOrEditModalState,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    canEdit,
}: Props) {
    const [deleteDepartmentDialogOpen, setDeleteDepartmentDialogOpen] =
        useState(false);
    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "DepartmentsTableHeaders",
        initialDepartmentsTableHeaders
    );

    const { deleteDepartment, isDeletingDepartment } = useDepartment(companyId);

    const handleMenuSelect = (
        departmentId: string,
        option: "edit" | "delete"
    ) => {
        if (!departments) return console.error("departments is undefined");
        setSelectedDepartment(
            departments.items.find((dep) => dep.departmentId === departmentId)
        );
        if (option === "delete") setDeleteDepartmentDialogOpen(true);
        if (option === "edit") setAddOrEditModalState("edit");
    };

    const handleDeleteDepartment = async () => {
        if (!selectedDepartment?.departmentId) return;

        const response = await deleteDepartment(
            selectedDepartment.departmentId
        );
        if (!response.success) {
            errorToast("Failed to delete department, please try again later.");
        } else {
            successToast("Department successfully deleted.");
            refetchDepartments && refetchDepartments();
            setDeleteDepartmentDialogOpen(false);
        }
    };

    const shouldShowDepartments =
        !departmentsError &&
        !isLoadingDepartments &&
        departments &&
        !!departments.items.length;

    return (
        <>
            {isLoadingDepartments && <LoadingMessage />}
            {departmentsError && <DepartmentLoadingError />}
            {!isLoadingDepartments &&
                !departmentsError &&
                !departments?.items.length && (
                    <div className=" mb-3 w-max  rounded-sm border border-S2D-neutral-80 bg-S2D-neutral-95 p-3 font-semibold">
                        You have no departments
                    </div>
                )}

            {shouldShowDepartments && (
                <>
                    <Table
                        tableHeaders={tableHeaders}
                        setTableHeaders={setTableHeaders}
                        tableContents={initDepartmentsTableContents(
                            departments?.items
                        )}
                        menuItems={(data) => departmentMenuItems(data, canEdit)}
                    />
                    <PaginationNav
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalCount={departments.totalCount}
                        totalPages={departments.totalPages}
                        currentPage={pageNumber}
                        hasPreviousPage={departments.hasPreviousPage}
                        hasNextPage={departments.hasNextPage}
                    />
                    {selectedDepartment && (
                        <>
                            <DeleteDepartmentDialog />
                        </>
                    )}
                </>
            )}
        </>
    );

    function DeleteDepartmentDialog() {
        return (
            <ConfirmationDialog
                variant="danger"
                open={deleteDepartmentDialogOpen}
                onClose={() => setDeleteDepartmentDialogOpen(false)}
                title={`Delete department ${selectedDepartment?.departmentName}?`}
                caption="This action is non-reversible."
                onConfirm={handleDeleteDepartment}
                isLoading={isDeletingDepartment}
            />
        );
    }

    function DepartmentLoadingError() {
        return (
            <FormMessageBox
                variant="error"
                title={"Error loading departments"}
                message={
                    "We could not load your departments data, please try again in 10 minutes"
                }
            />
        );
    }

    function departmentMenuItems(
        data: ApiDepartmentItem,
        canEdit: boolean
    ): TableMenuItem[] {
        return [
            {
                key: "editDepartment",
                onClick: () => handleMenuSelect(data.departmentId, "edit"),
                icon: <PencilSimple size={20} weight="bold" />,
                text: "Edit department",
                isDisabled: !canEdit,
            },
            {
                key: "deleteDepartment",
                onClick: () => handleMenuSelect(data.departmentId, "delete"),
                icon: <Trash size={20} weight="fill" />,
                text: "Delete department",
                isDisabled: !canEdit,
            },
        ];
    }
}
