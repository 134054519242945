import { useIsExtraLargeScreen } from "../../../hooks/screenSizes";
import TabbedAnalysisComponent from "./TabbedAnalysisComponent";
import DropdownAnalysisComponent from "./DropdownAnalysisComponent";
import { type DriverCategory } from "../../../utils/driverPageLocation";
import { useMemo } from "react";

export type AnalysisComponentContentType<
    AnalysisComponentTitles extends string
> = {
    title: AnalysisComponentTitles;
    content: JSX.Element;
    locations?: DriverCategory[];
    count?: number;
};

export type AnalysisComponentProps<AnalysisComponentTitles extends string> = {
    analysisContent?: AnalysisComponentContentType<AnalysisComponentTitles>[];
    location?: DriverCategory;
    selectedTab?: number;
    onSelect: (value: AnalysisComponentTitles) => void;
};

const AnalysisComponent = <AnalysisComponentTitles extends string>({
    analysisContent,
    location,
    selectedTab,
    onSelect,
}: AnalysisComponentProps<AnalysisComponentTitles>) => {
    const isExtraLargeScreen = useIsExtraLargeScreen();

    const pageSpecificContent = useMemo(() => {
        if (analysisContent && location) {
            return locationFilteredAnalysisContent(analysisContent, location);
        }
        return analysisContent;
    }, [analysisContent]);

    return (
        <div className="flex flex-col gap-5">
            {isExtraLargeScreen && !!pageSpecificContent?.length && (
                <TabbedAnalysisComponent
                    analysisContent={pageSpecificContent}
                    selectedTab={selectedTab ?? 0}
                    onSelect={onSelect}
                />
            )}
            {!isExtraLargeScreen && !!pageSpecificContent?.length && (
                <DropdownAnalysisComponent
                    analysisContent={pageSpecificContent}
                    selectedTab={selectedTab ?? 0}
                    onSelect={onSelect}
                />
            )}
        </div>
    );
};

export default AnalysisComponent;

export const locationFilteredAnalysisContent = <
    AnalysisComponentTitles extends string
>(
    analysisContent: AnalysisComponentContentType<AnalysisComponentTitles>[],
    location: DriverCategory
): AnalysisComponentContentType<AnalysisComponentTitles>[] => {
    const locationBasedAnalysisContent = new Array<
        AnalysisComponentContentType<AnalysisComponentTitles>
    >();

    analysisContent.map((content) => {
        if (location && content.locations?.includes(location)) {
            locationBasedAnalysisContent.push(content);
        }
    });
    return locationBasedAnalysisContent;
};
