import * as yup from "yup";
import { DriverTypes } from "../../models/api/admin/driver";
import {
    conditionalAddressValidation,
    exhaustiveAddressDependencyArray,
} from "../../utils/addressValidation";
import { termForCompany } from "../../copy/sharedCopy";
import { DriverAddress } from "../../models/shared/address";
import { GREAT_BRITAIN } from "../../constants/countries";
import { dateValidation } from "../../utils/dateValidationYup";
import { mobileNumberValidation } from "../../utils/mobileNumberValidationYup";
import { SchemaKeys } from "../../models/shared/schemaKeysHelper";

// Personal Information
export type DriverPersonalInformationData = Pick<
    EditDriverFormValues,
    "firstName" | "lastName" | "dateOfBirth" | "emailAddress" | "mobileNumber"
>;
export const driverPersonalInformationNulledData: DriverPersonalInformationData =
    {
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        emailAddress: null,
        mobileNumber: null,
    };
export const driverInformationValidationSchema: SchemaKeys<DriverPersonalInformationData> =
    {
        firstName: yup.string().trim().required("First name is required"),
        lastName: yup.string().trim().required("Surname is required"),
        dateOfBirth: dateValidation
            .notRequired()
            .max(new Date(), "Date of birth must be before today")
            .nullable(),
        emailAddress: yup
            .string()
            .trim()
            .email(
                "Email address must be a valid email address, eg. hello@example.com "
            )
            .nullable(),
        mobileNumber: mobileNumberValidation  
            .nullable()
            .notRequired(),
    };

// Employment
export type DriverEmploymentData = Pick<
    EditDriverFormValues,
    "company" | "department" | "jobRole" | "employeeId" | "externalReference"
>;
export const driverEmploymentNulledData: DriverEmploymentData = {
    company: null,
    department: null,
    jobRole: null,
    employeeId: null,
    externalReference: null,
};
export const driverEmploymentValidationSchema: SchemaKeys<DriverEmploymentData> =
    {
        company: yup.object().shape({
            companyId: yup
                .string()
                .nullable()
                .when("company.companyName", {
                    is: (companyName: string) => {
                        if (companyName) {
                            return true;
                        }

                        return false;
                    },
                    then: yup
                        .string()
                        .required(`${termForCompany} id is required`),
                })
                .notRequired(),
            companyName: yup
                .string()
                .nullable()
                .when("company.companyId", {
                    is: (companyId: string) => {
                        if (companyId) {
                            return true;
                        }

                        return false;
                    },
                    then: yup
                        .string()
                        .required(`${termForCompany} id is required`),
                })
                .notRequired(),
        }),
        department: yup.object().shape({
            departmentId: yup
                .string()
                .nullable()
                .when("department.departmentName", {
                    is: (departmentName: string) => {
                        if (departmentName) {
                            return true;
                        }

                        return false;
                    },
                    then: yup.string().required("department id is required"),
                })
                .notRequired(),
            departmentName: yup
                .string()
                .nullable()
                .when("department.departmentId", {
                    is: (departmentId: string) => {
                        if (departmentId) {
                            return true;
                        }

                        return false;
                    },
                    then: yup.string().required("department id is required"),
                })
                .notRequired(),
            costCentre: yup.string().nullable().notRequired(),
        }),
        jobRole: yup.string().nullable().notRequired(),
        employeeId: yup.string().trim().nullable().notRequired(),
        externalReference: yup.string().max(20, "'External Reference' must be 20 characters or fewer.").nullable().notRequired(),
    };

// Driving Licence
export type DriverDrivingLicenceData = Pick<
    EditDriverFormValues,
    "licenceCountryOrigin" | "licenceNumber"
>;
export const driverDrivingLicenceNulledData: DriverDrivingLicenceData = {
    licenceCountryOrigin: null,
    licenceNumber: null,
};
export const driverDrivingLicenceValidationSchema: SchemaKeys<DriverDrivingLicenceData> =
    {
        licenceNumber: yup
            .string()
            .nullable()
            .max(64, "Licence number must not exceed 64 characters.")
            .when("licenceCountryOrigin", {
                is: (licenceCountryOrigin: string) => {
                    if (licenceCountryOrigin === GREAT_BRITAIN) {
                        return true;
                    }

                    return false;
                },
                then: yup
                    .string()
                    .matches(
                        /^[A-Z9]{5}\d[0156]\d([0][1-9]|[12]\d|3[01])\d[A-Z9]{2}\d[A-Z]{2}$/,
                        "Licence number must be 16 characters with no spaces, eg. MORGA753116SM9IJ "
                    ),
            })
            .notRequired(),
        licenceCountryOrigin: yup.string().nullable().notRequired(),
    };

// Address
export type DriverAddressData = Pick<EditDriverFormValues, "address">;
export const driverAddressNulledData: DriverAddressData = {
    address: null,
};
export const driverAddressValidationSchema: SchemaKeys<DriverAddressData> = {
    address: yup.object().shape(
        {
            address1: conditionalAddressValidation(
                "address1",
                yup.string().required("Address line 1 is required").nullable()
            ).nullable(),
            address2: yup.string().nullable(),
            address3: yup.string().nullable(),
            address4: yup.string().nullable(),
            address5: yup.string().nullable(),
            addressTown: conditionalAddressValidation(
                "addressTown",
                yup.string().required("City is required")
            ).nullable(),
            addressPostcode: conditionalAddressValidation(
                "addressPostcode",
                yup
                    .string()
                    .matches(
                        /^([a-zA-Z][a-hj-yA-HJ-Y]?[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}|GIR ?0A{2})$/,
                        {
                            message: "Postcode is invalid",
                            excludeEmptyString: true,
                        }
                    )
                    .required("Postcode is required")
            ).nullable(),
        },
        exhaustiveAddressDependencyArray
    ),
};

// Linked Driver
type DriverLinkedDriverData = Pick<
    EditDriverFormValues,
    "primaryDriverId" | "primaryDriverName" | "linkedDrivers"
>;
export const driverLinkedDriverNulledData: DriverLinkedDriverData = {
    primaryDriverId: null,
    primaryDriverName: null,
    linkedDrivers: null,
};
export const driverLinkedDriverValidationSchema: SchemaKeys<DriverLinkedDriverData> =
    {
        linkedDrivers: yup.object().notRequired().nullable(),
        primaryDriverId: yup.string().nullable().notRequired(),
        primaryDriverName: yup.string().nullable().notRequired(),
    };

// Driver Type
export type DriverTypeData = Pick<EditDriverFormValues, "driverType">;
export const driverTypeNulledData: DriverTypeData = {
    driverType: null,
};
export const driverTypeValidationSchema: SchemaKeys<DriverTypeData> = {
    driverType: yup.string().nullable().notRequired(),
};

/* some required items are nullable in the model
    so we can edit other parts of the form without
    interacting with that particular field. */

export type EditDriverFormValues = {
    driverId: string;
    firstName: string | null;
    lastName: string | null;
    emailAddress: string | null;
    dateOfBirth: Date | null;
    mobileNumber: string | null;
    licenceNumber: string | null;
    foreignLicence: boolean;
    licenceCountryOrigin: string | null;
    address: DriverAddress | null;
    primaryDriverId: string | null;
    primaryDriverName: string | null;
    linkedDrivers: {
        [key: string]: string;
    } | null;
    company: {
        companyId: string | null;
        companyName: string | null;
    } | null;
    department: {
        departmentId: string | null;
        departmentName: string | null;
        costCentre: string | null;
    } | null;
    jobRole: string | null;
    employeeId: string | null;
    externalReference: string | null;
    driverType: DriverTypes | null;
};
