import useSWR from "swr";
import { ApiPostcodeLookupResponse } from "../models/api/postcodeLookup";
import { thirdPartyApiFetcher } from "./common/fetching";
import config from "../configuration";

const baseUrl = "https://ws.postcoder.com/pcw/";
const lines = 4;
const exclusions = ["organisation", "country"];

const buildPostcodeLookupUrl = (postcode: string) => {
    let url: string = baseUrl + config.postcoderApiKey + "/address/GB/";
    url += `${postcode}?`;
    url += `lines=${lines.toString()}`;
    url += "&exclude=";
    for (let i = 0; i < exclusions.length; i++) {
        url += `${exclusions[i]},`;
    }
    return url;
};

const usePostcodeLookup = (postcode: string) => {
    const {
        data = [],
        error,
        mutate,
        isValidating,
    } = useSWR<ApiPostcodeLookupResponse[]>(
        "postcodeLookup",
        () => thirdPartyApiFetcher(buildPostcodeLookupUrl(postcode)),
        {
            revalidateOnFocus: false,
            revalidateOnMount: false,
        }
    );

    return {
        postcodeLookupData: data,
        postcodeLookupError: error,
        postcodeLookupIsLoading: isValidating,
        mutate,
    };
};

export default usePostcodeLookup;
