import { UseFormReturn } from "react-hook-form";
import { DriversListFiltersFormValues } from "./DriversListFiltersFormValues";
import { DriverLicenceAnalysisFilterKey } from "../../ChartAnalysis/useDriverChartAnalysisFilters";
import {
    FilterDriverLicenceAnalysisDto,
    FilterVehicleAnalysisDto,
} from "../../../models/app/admin/driverFilters";
import {
    DriverLicenceAnalysisValue,
    DriversByAgeRange,
    DriversByLicenceOrigin,
    DriversByPoints,
    LicenceTypes,
    YearsFullLicenceHeld,
} from "../../../models/app/admin/licenceAnalysis";
import { VehicleAnalysisFilterKey } from "../../ChartAnalysis/useVehicleChartAnalysisFilters";
import { vehicleFilterDefaultValues } from "../../ChartAnalysis/vehicleFilterDefaultValues";
import { driverLicenceAnalysisDefaultValues } from "../../ChartAnalysis/driverLicenceAnalysisDefaultValues";
import {
    AgeRange,
    BusinessCover,
    Co2Emissions,
    EngineCapacity,
    EuroRating,
    FuelType,
    InsuranceType,
} from "../../../models/app/admin/vehicleAnalysis";

// returns the value we want to show as "selected" in the dropdown
export const analysisSelectedValue = <Section,>(
    sectionValues: DriverLicenceAnalysisValue<Section>
) => {
    for (const key in sectionValues) {
        if (sectionValues[key]) {
            return key;
        }
    }
    return null;
};

// when selecting one of the analysis dropdowns,
// set the selected value to true and the rest to null in that section.
export const handleLicenceAnalysisDropdownSelect = (
    methods: UseFormReturn<DriversListFiltersFormValues>,
    section: keyof FilterDriverLicenceAnalysisDto,
    key: DriverLicenceAnalysisFilterKey | null
) => {
    switch (section) {
        case "driversByPoints":
            if (key) {
                methods.setValue("driverLicenceAnalysis.driversByPoints", {
                    ...driverLicenceAnalysisDefaultValues.driversByPoints,
                    [key]: true,
                } as DriversByPoints);
            } else {
                methods.setValue("driverLicenceAnalysis.driversByPoints", {});
            }
            break;
        case "driversByAgeRange":
            if (key) {
                methods.setValue("driverLicenceAnalysis.driversByAgeRange", {
                    ...driverLicenceAnalysisDefaultValues.driversByAgeRange,
                    [key]: true,
                } as DriversByAgeRange);
            } else {
                methods.setValue("driverLicenceAnalysis.driversByAgeRange", {});
            }
            break;
        case "endorsementCodes":
            methods.setValue("driverLicenceAnalysis.endorsementCodes", {
                endorsementCode: key,
            });
            break;
        case "licenceTypes":
            if (key) {
                methods.setValue("driverLicenceAnalysis.licenceTypes", {
                    ...driverLicenceAnalysisDefaultValues.licenceTypes,
                    [key]: true,
                } as LicenceTypes);
            } else {
                methods.setValue("driverLicenceAnalysis.licenceTypes", {});
            }
            break;
        case "driverRestrictions":
            methods.setValue("driverLicenceAnalysis.driverRestrictions", {
                restrictionCode: key,
            });
            break;
        case "driversByLicenceCategory":
            methods.setValue("driverLicenceAnalysis.driversByLicenceCategory", {
                categoryCode: key,
            });
            break;
        case "driversByLicenceOrigin":
            if (key) {
                methods.setValue(
                    "driverLicenceAnalysis.driversByLicenceOrigin",
                    {
                        ...driverLicenceAnalysisDefaultValues.driversByLicenceOrigin,
                        [key]: true,
                    } as DriversByLicenceOrigin
                );
            } else {
                methods.setValue(
                    "driverLicenceAnalysis.driversByLicenceOrigin",
                    {}
                );
            }
            break;
        case "yearsFullLicenceHeld":
            if (key) {
                methods.setValue("driverLicenceAnalysis.yearsFullLicenceHeld", {
                    ...driverLicenceAnalysisDefaultValues.yearsFullLicenceHeld,
                    [key]: true,
                } as YearsFullLicenceHeld);
            } else {
                methods.setValue(
                    "driverLicenceAnalysis.yearsFullLicenceHeld",
                    {}
                );
            }
            break;
    }
};

// vehicle analysis is slightly different to above.
// If no value is selected, set whole object to null.
export const handleVehicleAnalysisDropdownSelect = (
    methods: UseFormReturn<DriversListFiltersFormValues>,
    section: keyof FilterVehicleAnalysisDto,
    key: VehicleAnalysisFilterKey | null
) => {
    const defaultVals = vehicleFilterDefaultValues.vehicleAnalysis;

    switch (section) {
        case "co2Emissions":
            if (key) {
                methods.setValue("vehicleAnalysis.co2Emissions", {
                    ...defaultVals.co2Emissions,
                    [key]: true,
                } as Co2Emissions);
            } else {
                methods.setValue("vehicleAnalysis.co2Emissions", {});
            }
            break;
        case "fuelType":
            if (key) {
                methods.setValue("vehicleAnalysis.fuelType", {
                    ...defaultVals.fuelType,
                    [key]: true,
                } as FuelType);
            } else {
                methods.setValue("vehicleAnalysis.fuelType", {});
            }
            break;
        case "ageRange":
            if (key) {
                methods.setValue("vehicleAnalysis.ageRange", {
                    ...defaultVals.ageRange,
                    [key]: true,
                } as AgeRange);
            } else {
                methods.setValue("vehicleAnalysis.ageRange", {});
            }
            break;
        case "manufacturer":
            methods.setValue(
                "vehicleAnalysis.manufacturer",
                key
                    ? {
                          name: key,
                      }
                    : null
            );
            break;
        case "euroRating":
            if (key) {
                methods.setValue("vehicleAnalysis.euroRating", {
                    ...defaultVals.euroRating,
                    [key]: true,
                } as EuroRating);
            } else {
                methods.setValue("vehicleAnalysis.euroRating", null);
            }
            break;
        case "insuranceType":
            if (key) {
                methods.setValue("vehicleAnalysis.insuranceType", {
                    ...defaultVals.insuranceType,
                    [key]: true,
                } as InsuranceType);
            } else {
                methods.setValue("vehicleAnalysis.insuranceType", null);
            }
            break;

        case "businessCover":
            if (key) {
                methods.setValue("vehicleAnalysis.businessCover", {
                    ...defaultVals.businessCover,
                    [key]: true,
                } as BusinessCover);
            } else {
                methods.setValue("vehicleAnalysis.businessCover", {});
            }
            break;
        case "engineCapacity":
            if (key) {
                methods.setValue("vehicleAnalysis.engineCapacity", {
                    ...defaultVals.engineCapacity,
                    [key]: true,
                } as EngineCapacity);
            } else {
                methods.setValue("vehicleAnalysis.engineCapacity", {});
            }
            break;
    }
};
