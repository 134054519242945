import { SetStateAction, useState } from "react";
import FormTextInput from "../../../../FormTextInput";
import SubmitOrCancelButtonBar from "../../../../ReusableComponents/SubmitOrCancelButtonBar/SubmitOrCancelButtonBar";
import { type EditDepartmentFormValues } from "./EditDepartmentFormValues";
import useEditDepartmentForm from "./useEditDepartmentForm";
import { errorToast, successToast } from "../../../../../toast";
import { AddOrEditModalStates } from "../../CompanyBillingTab";
import { type ApiDepartmentItem } from "../../../../../models/api/admin/department";
import ConfirmationDialog from "../../../../ReusableComponents/ConfirmationDialog";
import { yesNoOptions } from "../../../../../constants/dropdownOptions";
import FormDropdown, { FormDropdownOption } from "../../../../FormDropdown";
import { Controller } from "react-hook-form";
import { ApiInvoiceAddressItem } from "../../../../../models/api/admin/invoiceAddress";

type Props = {
    setModalState: (value: SetStateAction<AddOrEditModalStates>) => void;
    companyId: string | undefined;
    selectedDepartment: ApiDepartmentItem | undefined;
    refetchDepartments: () => void;
    invoiceAddresses: ApiInvoiceAddressItem[];
};

export default function EditDepartmentForm({
    setModalState,
    companyId,
    selectedDepartment,
    refetchDepartments,
    invoiceAddresses
}: Props) {
    const {
        methods,
        editDepartment,
        isEditingDepartment,
        deleteDepartment,
        isDeletingDepartment,
    } = useEditDepartmentForm(companyId, selectedDepartment);
    const { handleSubmit, register, control, formState } = methods;

    const [deleteDepartmentDialogOpen, setDeleteDepartmentDialogOpen] =
        useState(false);

    const handleEditDepartment = async (data: EditDepartmentFormValues) => {
        if (!selectedDepartment?.departmentId)
            return console.error("No departmentId");

        const response = await editDepartment(
            selectedDepartment?.departmentId,
            data
        );

        if (response.success) {
            refetchDepartments();
            successToast(`Department has been edited`);
            setModalState("closed");
        } else {
            errorToast(`Failed to edit department`);
        }
    };

    const handleDeleteDepartment = async () => {
        if (!selectedDepartment?.departmentId) return;

        const response = await deleteDepartment(
            selectedDepartment.departmentId
        );
        if (!response.success) {
            errorToast("Failed to delete department, please try again later.");
        } else {
            successToast("Department successfully deleted.");
            refetchDepartments();
            setModalState("closed");
        }
    };

    const invoiceAddressOptions: FormDropdownOption<string | number | null>[] = [
        { label: "No Invoice Address Selected", value: null },
        ...invoiceAddresses.map((invoiceAddress: ApiInvoiceAddressItem) => ({
            label: invoiceAddress.addressName,
            value: invoiceAddress.invoiceAddressId ?? "",
        }))
    ];

    return (
        <form onSubmit={handleSubmit(handleEditDepartment)}>
            <div className="mx-auto max-h-[600px] w-full max-w-3xl p-6 pb-8">
                <FormTextInput
                    label={`Department name`}
                    required
                    id="departmentName"
                    placeholder={`Enter department name`}
                    {...register("departmentName")}
                    error={formState.errors.departmentName}
                />
                <FormTextInput
                    label={`Contact name`}
                    id="contactName"
                    placeholder={`Enter contact name`}
                    {...register("contactName")}
                    error={formState.errors.contactName}
                />
                <FormTextInput
                    label={`Cost centre`}
                    id="costCentre"
                    placeholder={`Enter cost centre`}
                    {...register("costCentre")}
                    error={formState.errors.costCentre}
                />
                <Controller
                    name="invoiceAddressId"
                    control={methods.control}
                    defaultValue={null} // Default to "No Invoice Address Selected"
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Invoice Address"
                            options={invoiceAddressOptions}
                            value={value ?? null}
                            onChange={onChange}
                            pleaseSelectText="Select an invoice address"
                            error={methods.formState.errors.invoiceAddressId}
                            buttonClassName="h-[48px] border-black bg-S2D-dark-green-70.1"
                        />
                    )}
                />
                <Controller
                    name="isInvoiceDefault"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <FormDropdown
                            label="Invoice default?"
                            options={yesNoOptions}
                            value={field.value}
                            onChange={field.onChange}
                            required
                            pleaseSelectText="Select Yes or No"
                            error={formState.errors.isInvoiceDefault}
                            buttonClassName="h-[48px] border-black bg-S2D-dark-green-70.1"
                        />
                    )}
                />
            </div>

            <div className="mt-0 border-t-[1px] rounded-b-sm border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6">
                <SubmitOrCancelButtonBar
                    onCancel={() => setModalState("closed")}
                    onDelete={() => setDeleteDepartmentDialogOpen(true)}
                    isLoading={isEditingDepartment}
                    disabled={isEditingDepartment}
                />
            </div>

            <ConfirmationDialog
                variant="danger"
                open={deleteDepartmentDialogOpen}
                onClose={() => setDeleteDepartmentDialogOpen(false)}
                title={`Delete department ${selectedDepartment?.departmentName}?`}
                caption="This action is non-reversible."
                onConfirm={handleDeleteDepartment}
                isLoading={isDeletingDepartment}
            />
        </form>
    );
}
