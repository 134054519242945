import { Scroll } from "phosphor-react";
import ActionableStatus from "../../ActionableStatus";
import useDeclarationStatusActions from "./useDeclarationStatusActions";
import { ApiDriverByIdResponse } from "../../../models/api/admin/driver";
import { DriverValidationTypes } from "../../../models/app/admin/company";

export type DeclarationStatusEmptyProps = {
    driverData?: ApiDriverByIdResponse;
    handleScrollToDocumentUpload: () => void;
    validateByCompanyPreference?: DriverValidationTypes;
};

const DeclarationStatusEmpty = ({
    driverData,
    validateByCompanyPreference,
    handleScrollToDocumentUpload,
}: DeclarationStatusEmptyProps) => {
    const { sendNewEDeclarationAction, sendNewPaperDeclarationAction } =
        useDeclarationStatusActions(
            null,
            null,
            false,
            handleScrollToDocumentUpload,
            driverData
        );
    return (
        <>
            <ActionableStatus
                title="Declaration status"
                theme="grey"
                actionable
                actions={[
                    sendNewEDeclarationAction,
                    sendNewPaperDeclarationAction,
                ]}
                driverData={driverData}
                validateByCompanyPreference={validateByCompanyPreference}
            >
                <div className="flex items-center">
                    <div className="pr-3">
                        <Scroll size={24} weight="duotone" />
                    </div>
                    <div className="flex flex-col text-sm">
                        <span>
                            No declaration process (paper or electronic) has
                            begun
                        </span>
                    </div>
                </div>
            </ActionableStatus>
        </>
    );
};

export default DeclarationStatusEmpty;
