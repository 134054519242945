import { Controller, UseFormReturn } from "react-hook-form";
import { formatDateToString } from "../../../utils/formatDateToString";
import Button from "../../Button";
import EditableContentBlock from "../../EditableContentBlock";
import FormDateInput from "../../FormDateInput";
import FormDropdown from "../../FormDropdown";
import FormTextInput from "../../FormTextInput";
import {
    EditDvlaFormValues,
    EditDvlaPersonalInfoFormValues,
} from "../EditDvlaFormValues";

export type EditPersonalInformationFormProps = {
    initialValues: EditDvlaFormValues;
    methods: UseFormReturn<EditDvlaPersonalInfoFormValues>;
    isLoading: boolean;
    onSubmit: (
        data: EditDvlaPersonalInfoFormValues,
        callback: () => void
    ) => Promise<void>;
    canEdit: boolean;
};

export type Option = {
    label: string;
    value: string;
};

const genderOptions: Option[] = [
    {
        label: "Male",
        value: "M",
    },
    {
        label: "Female",
        value: "F",
    },
];

const EditPersonalInformationForm: React.FC<
    EditPersonalInformationFormProps
> = ({ initialValues, methods, isLoading, onSubmit, canEdit }) => {
    const gender = (val: string) => {
        const option = genderOptions.find((gen) => gen.value === val);
        return option?.label;
    };
    return (
        <EditableContentBlock
            title="Personal information"
            disableEdit={!canEdit}
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            <p>
                                <b>First name:</b> {initialValues.firstName}
                            </p>
                            <p>
                                <b>Last name:</b> {initialValues.lastName}
                            </p>
                            <p>
                                <b>Date of birth:</b>{" "}
                                {initialValues.dateOfBirth instanceof Date
                                    ? formatDateToString(
                                          initialValues.dateOfBirth
                                      )
                                    : initialValues.dateOfBirth &&
                                      formatDateToString(
                                          new Date(initialValues.dateOfBirth)
                                      )}
                            </p>
                            <p>
                                <b>Gender:</b> {gender(initialValues.gender)}
                            </p>
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit(
                                    (data: EditDvlaPersonalInfoFormValues) =>
                                        onSubmit(data, () => setIsEdit(false))
                                )}
                            >
                                <FormTextInput
                                    label="First name"
                                    id="firstName"
                                    placeholder="Enter first name"
                                    error={methods.formState.errors.firstName}
                                    {...methods.register("firstName")}
                                />
                                <FormTextInput
                                    label="Last name"
                                    id="lastName"
                                    placeholder="Enter last name"
                                    error={methods.formState.errors.lastName}
                                    {...methods.register("lastName")}
                                />
                                <Controller
                                    name="dateOfBirth"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            id="dateOfBirth"
                                            label="Date of birth"
                                            value={value}
                                            onChange={onChange}
                                            error={
                                                methods.formState.errors
                                                    .dateOfBirth
                                            }
                                            maxDate={new Date()}
                                        />
                                    )}
                                />
                                <div className="py-2" />
                                <Controller
                                    name="gender"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDropdown
                                            pleaseSelectText="select gender"
                                            options={genderOptions}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                                <div className="mt-8 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => {
                                            methods.reset();
                                            setIsEdit(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" loading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditPersonalInformationForm;
