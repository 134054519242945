import { SetStateAction, useState } from "react";
import { formatFileSize } from "../utils/formatFileSizeInKb";

type useFileTypeParams = {
    fileType: string[];
    maxFileSizeKb?: number;
    setIsDirty?: (value: SetStateAction<boolean>) => void;
};

const useFileType = ({
    fileType,
    maxFileSizeKb = 1000,
    setIsDirty,
}: useFileTypeParams) => {
    const [error, setError] = useState<string>();
    const loadFile = async (file: File) => {
        setIsDirty && setIsDirty(true);
        setError("");

        try {
            const fileNameParts = file.name.split(".");

            if (fileNameParts.length === 1) {
                const err = `Please upload one of these file types: ${fileType.join(
                    ", "
                )}.`;
                setError(err);
                return {
                    error: err,
                };
            }

            const originalFileExtension =
                fileNameParts[fileNameParts.length - 2];
            const fileHasMoreThanOneDot = fileNameParts.length > 2;
            const originalExtensionTooLong =
                fileHasMoreThanOneDot && originalFileExtension.length > 6;

            // this check is to prevent an error that was crashing the backend
            // file names such as properly-named.1234567 led to a 'truncation' error
            if (originalExtensionTooLong) {
                const err =
                    "File name invalid, please remove any '.' characters";
                setError(err);
                return {
                    error: err,
                };
            }

            const fileExtension =
                fileNameParts[fileNameParts.length - 1].toLocaleLowerCase();

            if (!fileType.includes(fileExtension)) {
                const err = `'${fileExtension}' is not a valid file type, please upload one of these file types: ${fileType.join(
                    ", "
                )}.`;
                setError(err);
                return {
                    error: err,
                };
            }
        } catch (error) {
            const err = `Invalid file type, please upload one of these file types: ${fileType.join(
                ", "
            )}`;
            setError(err);
            return {
                error: err,
            };
        }

        if (file.size / 1024 > maxFileSizeKb) {
            const err = `The maximum allowed file size is ${maxFileSizeKb}KB and your file is ${formatFileSize(
                file.size
            )}.`;
            setError(err);
            return {
                error: err,
            };
        }
    };

    return {
        loadFile,
        fileError: error,
    };
};

export default useFileType;
