export type ApiAuditsResponse = {
    items: ApiAuditItem[];
    totalCount: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
};

type AuditUser = {
    userId: string;
    userName: string;
    companyId: string;
    firstName: string;
    lastName: string;
    isDeleted: boolean;
    driverIds: string[];
} | null;

export type ApiAuditItem = {
    auditId: string;
    user: AuditUser;
    tableName: string;
    entityName: string;
    entityId: string;
    changeType: string;
    auditDate: string;
    previousValues: any;
    newValues: any;
    impersonatingUser: AuditUser;
};

export enum AuditAction {
    Added = "Added",
    Modified = "Modified",
    Deleted = "Deleted",
}
