import {
    capitalisedTermForCompany,
    termForCompany,
} from "../../copy/sharedCopy";
import { DownloadSimple, PencilSimple } from "phosphor-react";
import { type CompanyTableAllowedIds } from "./CompanyListInit";
import { type TableMenuItem } from "../ReusableComponents/Table/TableMenu";
import { type ApiCompanyResponse } from "../../models/api/admin/company";

export const companyMenuItems = (
    data: ApiCompanyResponse,
    handleTableMenuSelect: (id: string, key: CompanyListMenuKeys) => void
): TableMenuItem[] => {
    return [
        {
            key: CompanyListMenuKeys.clientProfile,
            onClick: () =>
                handleTableMenuSelect(
                    data.companyId,
                    CompanyListMenuKeys.clientProfile
                ),
            icon: <PencilSimple size={20} weight="bold" />,
            text: `Edit ${termForCompany}`,
        },
        {
            key: CompanyListMenuKeys.clientReport,
            onClick: () =>
                handleTableMenuSelect(
                    data.companyId,
                    CompanyListMenuKeys.clientReport
                ),
            icon: <DownloadSimple size={20} weight="bold" />,
            text: `Download ${termForCompany} report`,
        },
    ];
};

export const companyMenuGroups = [
    `${capitalisedTermForCompany} Details`,
    "Statistics",
];

export const companyOrderByOptions: CompanyTableAllowedIds[] = ["companyName"];

export enum CompanyListMenuKeys {
    clientProfile = "clientProfile",
    clientReport = "clientReport",
}
