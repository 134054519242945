import {
    CheckCircle,
    Warning,
    WarningOctagon,
    GlobeHemisphereWest,
} from "phosphor-react";
import StatisticSummaryBox from "../StatisticSummaryBox";
import UnitedKingdom from "../../assets/UnitedKingdom.png";
import NorthernIreland from "../../assets/NorthernIreland.png";
import { CompanyDriverDeclarationAnalysisResponse } from "../../models/api/admin/companyDeclarationAnalysis";
import { DeclarationStatusAnalysisTitles } from "../../models/app/eDeclarations";

type EDeclarationStatusAnalysisProps = {
    data?: CompanyDriverDeclarationAnalysisResponse["declarations"];
    onLinkClick?: (title: DeclarationStatusAnalysisTitles) => void;
    isLoading?: boolean;
};
export function EDeclarationStatusAnalysis({
    data,
    onLinkClick,
    isLoading,
}: EDeclarationStatusAnalysisProps) {
    return (
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <StatisticSummaryBox
                icon={CheckCircle}
                label="E-Declarations complete"
                statistic={data?.eDeclarationsComplete}
                onLinkClick={
                    onLinkClick
                        ? () =>
                              onLinkClick(
                                  DeclarationStatusAnalysisTitles.EDeclarationsComplete
                              )
                        : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="E-Declarations Incomplete / not returned"
                statistic={data?.eDeclarationsIncomplete}
                onLinkClick={
                    onLinkClick
                        ? () =>
                              onLinkClick(
                                  DeclarationStatusAnalysisTitles.EDeclarationsIncomplete
                              )
                        : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label="E-Declarations due to expire"
                statistic={data?.eDeclarationsDueToExpire}
                onLinkClick={
                    onLinkClick
                        ? () =>
                              onLinkClick(
                                  DeclarationStatusAnalysisTitles.EDeclarationsDueToExpire
                              )
                        : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="E-declarations Expired"
                statistic={data?.eDeclarationsExpired}
                onLinkClick={
                    onLinkClick
                        ? () =>
                              onLinkClick(
                                  DeclarationStatusAnalysisTitles.EDeclarationsExpired
                              )
                        : undefined
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type PaperDeclarationStatusAnalysisProps = {
    data?: CompanyDriverDeclarationAnalysisResponse["declarations"];
    onLinkClick?: (title: DeclarationStatusAnalysisTitles) => void;
    isLoading?: boolean;
};
export function PaperDeclarationStatusAnalysis({
    data,
    onLinkClick,
    isLoading,
}: PaperDeclarationStatusAnalysisProps) {
    return (
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <StatisticSummaryBox
                icon={CheckCircle}
                label="Paper Declarations complete"
                statistic={data?.paperDeclarationsComplete}
                onLinkClick={
                    onLinkClick
                        ? () =>
                              onLinkClick(
                                  DeclarationStatusAnalysisTitles.PaperDeclarationsComplete
                              )
                        : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="Paper Declarations Incomplete / not returned"
                statistic={data?.paperDeclarationsIncomplete}
                onLinkClick={
                    onLinkClick
                        ? () =>
                              onLinkClick(
                                  DeclarationStatusAnalysisTitles.PaperDeclarationsIncomplete
                              )
                        : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={Warning}
                label="Paper Declarations due to expire"
                statistic={data?.paperDeclarationsDueToExpire}
                onLinkClick={
                    onLinkClick
                        ? () =>
                              onLinkClick(
                                  DeclarationStatusAnalysisTitles.PaperDeclarationsDueToExpire
                              )
                        : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={WarningOctagon}
                label="Paper declarations Expired"
                statistic={data?.paperDeclarationsExpired}
                onLinkClick={
                    onLinkClick
                        ? () =>
                              onLinkClick(
                                  DeclarationStatusAnalysisTitles.PaperDeclarationsExpired
                              )
                        : undefined
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}

type LicenceOriginAnalysisProps = {
    data?: CompanyDriverDeclarationAnalysisResponse["licenceOrigins"];
    onLinkClick?: (title: DeclarationStatusAnalysisTitles) => void;
    isLoading?: boolean;
};
export function LicenceOriginAnalysis({
    data,
    onLinkClick,
    isLoading,
}: LicenceOriginAnalysisProps) {
    return (
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-3 ">
            <StatisticSummaryBox
                customImg={UnitedKingdom}
                customImgClassName="h-[26px]"
                iconColor="bg-S2D-neutral-100"
                label="Drivers with UK licences"
                statistic={data?.ukLicences}
                onLinkClick={
                    onLinkClick
                        ? () =>
                              onLinkClick(
                                  DeclarationStatusAnalysisTitles.DriversWithUKLicences
                              )
                        : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                customImg={NorthernIreland}
                iconColor="bg-S2D-neutral-100"
                customImgClassName="h-[27px]"
                label="Drivers with N.Irish licences"
                statistic={data?.northernIrishLicences}
                onLinkClick={
                    onLinkClick
                        ? () =>
                              onLinkClick(
                                  DeclarationStatusAnalysisTitles.DriversWithNILicences
                              )
                        : undefined
                }
                isLoadingAction={isLoading}
            />
            <StatisticSummaryBox
                icon={GlobeHemisphereWest}
                iconColor="bg-S2D-neutral-100"
                label="Drivers with foreign licences"
                statistic={data?.foreignLicences}
                onLinkClick={
                    onLinkClick
                        ? () =>
                              onLinkClick(
                                  DeclarationStatusAnalysisTitles.DriversWithForeignLicences
                              )
                        : undefined
                }
                isLoadingAction={isLoading}
            />
        </div>
    );
}
