import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { EditCompanyFormValues } from "../../../../EditCompanyForms/EditCompanyFormValues";
import { CheckFrequencySchema } from "../../../../../schemas/companySchemas";
import { useEffect } from "react";

export type EditCheckFrequenciesFormValues = {
    checkFrequency: {
        range1: number;
        range2: number;
        range3: number;
    };
};

export const defaultCheckFrequencies = {
    range1: 3,
    range2: 3,
    range3: 3,
};

const useEditCheckFrequenciesForm = (initialValues: EditCompanyFormValues) => {
    const riskProfiles = initialValues?.companyRiskProfiles;

    const range1 = riskProfiles?.find(
        (profile) => profile.severityLevel === 1
    )?.checkFrequency;
    const range2 = riskProfiles?.find(
        (profile) => profile.severityLevel === 2
    )?.checkFrequency;
    const range3 = riskProfiles?.find(
        (profile) => profile.severityLevel === 3
    )?.checkFrequency;

    const displayedValues: EditCheckFrequenciesFormValues = {
        checkFrequency: {
            range1: range1 ?? defaultCheckFrequencies.range1,
            range2: range2 ?? defaultCheckFrequencies.range2,
            range3: range3 ?? defaultCheckFrequencies.range3,
        },
    };

    const validationSchema = yup
        .object()
        .shape({ checkFrequency: CheckFrequencySchema });

    const methods = useForm<EditCheckFrequenciesFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: displayedValues || null,
    });

    useEffect(() => {
        methods.reset(displayedValues);
    }, [initialValues]);

    return {
        methods,
        displayedValues,
    };
};

export default useEditCheckFrequenciesForm;
