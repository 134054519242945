import { ApiInvoiceResponse, LineItem } from "../../models/api/admin/invoice";
import { AddInvoiceDto, BulkInvoiceDto } from "../../models/app/admin/invoice";
import { queryParamConnector } from "../../utils/queryParamHelpers";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useGetInvoices = (
    companyId?: string,
    pageNumber?: number,
    pageSize?: number
) => {
    const baseUrl = `api/invoices/${companyId}/list`;
    const pathArray = [baseUrl];

    if (baseUrl && pageNumber) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`PageNumber=${pageNumber}`);
    }

    if (baseUrl && pageSize) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`PageSize=${pageSize}`);
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiInvoiceResponse>(pathArray.join(""), {
            preventRedirectOn404: true,
        });

    return {
        invoicesData: data,
        refetchInvoices: refetch,
        invoicesError: error,
        isLoadingInvoices: isLoading,
    };
};

export const useGetInvoiceChecks = (
    companyId: string,
    departmentId: string,
    dateFrom?: Date,
    dateTo?: Date
) => {
    const baseUrl = `api/invoices/${companyId}/checks`;

    const queryParams = [];

    if (departmentId) {
        queryParams.push(`DepartmentId=${encodeURIComponent(departmentId)}`);
    }
    if (dateFrom) {
        queryParams.push(
            `DateFrom=${encodeURIComponent(dateFrom.toISOString())}`
        );
    }
    if (dateTo) {
        queryParams.push(`DateTo=${encodeURIComponent(dateTo.toISOString())}`);
    }

    const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const url = `${baseUrl}${queryString}`;

    const { data, error, isLoading, refetch } = useS2DApiSWR<LineItem[]>(url, {
        preventRedirectOn404: true,
    });

    return {
        lineItems: data,
        refetchLineItems: refetch,
        lineItemsError: error,
        isLoadingLineItems: isLoading,
    };
};

const useAddInvoice = () =>
    useS2DApiFetcher<AddInvoiceDto, string, { dto: AddInvoiceDto }>(
        "POST",
        ({ dto }) => ({
            url: `api/invoices`,
            body: dto,
        }),
        [],
        true
    );

export type GetInvoicePdfQuery = {
    FileName?: string;
};

const useInvoicePDF = () =>
    useS2DApiFetcher<
        GetInvoicePdfQuery,
        ArrayBuffer,
        { invoiceId: string; query: GetInvoicePdfQuery }
    >(
        "POST",
        ({ invoiceId, query }) => ({
            url: `api/invoices/${invoiceId}/pdf`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/pdf",
            },
            body: query,
        }),
        [],
        true
    );

const useChargeableItemsXLS = () =>
    useS2DApiFetcher<null, ArrayBuffer, { invoiceId: string }>(
        "GET",
        ({ invoiceId }) => ({
            url: `api/invoices/${invoiceId}/export`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/vnd.ms-excel",
            },
        }),
        [],
        true
    );

const useBulkInvoices = () =>
    useS2DApiFetcher<BulkInvoiceDto, string, { data: BulkInvoiceDto }>(
        "POST",
        ({ data }) => ({
            url: `api/invoices/bulk`,
            body: data,
        }),
        [400, 405],
        true
    );

export const useInvoice = () => {
    const [addInvoice, isAddingInvoice] = useAddInvoice();
    const [invoicePDF, isInvoicePDF] = useInvoicePDF();
    const [chargableItemsXLS, isLoadingChargeableItemsXLS] =
        useChargeableItemsXLS();
    const [getBulkInvoice, isGettingBulkInvoice] = useBulkInvoices();

    return {
        addInvoice: async (dto: AddInvoiceDto) => await addInvoice({ dto }),
        isAddingInvoice,
        invoicePDF: async (invoiceId: string, query: GetInvoicePdfQuery) =>
            await invoicePDF({ invoiceId, query }),
        isInvoicePDF,
        chargableItemsXLS: async (invoiceId: string) =>
            await chargableItemsXLS({ invoiceId }),
        isLoadingChargeableItemsXLS,
        getBulkInvoice: async (data: BulkInvoiceDto) =>
            await getBulkInvoice({ data }),
        isGettingBulkInvoice,
    };
};
