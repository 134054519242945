import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
    AddInvoiceFormValidationSchema,
    AddInvoiceFormValues,
} from "./AddInvoiceFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInvoice } from "../../../../../api/admin/invoice";

const useAddInvoiceForm = () => {

    const {addInvoice, isAddingInvoice} = useInvoice();

    const validationSchema = yup
        .object()
        .shape(AddInvoiceFormValidationSchema);

    const methods = useForm<AddInvoiceFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    return { methods, addInvoice, isAddingInvoice };
};

export default useAddInvoiceForm;

