import {
    DvlaAnalysisCategories,
    DvlaDriverAnalysisResponse,
} from "../../models/api/admin/dvlaAnalysis";
import {
    type ApiDvlaChecksResponse,
    type DvlaCheckFilters,
} from "../../models/api/safe2DriveAdmin/dvlaChecks";
import { breakDownApiQueryParams } from "../../utils/breakDownApiQueryParams";
import { queryParamConnector } from "../../utils/queryParamHelpers";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useGetDvlaChecks = (
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    filters: DvlaCheckFilters
) => {
    const pathArray = [`api/dvla`];

    pathArray.push(`?PageNumber=${pageNumber}`);
    pathArray.push(`&PageSize=${pageSize}`);
    pathArray.push(`&OrderBy=${orderBy}`);

    if (filters.companyId) {
        pathArray.push(`&CompanyId=${filters.companyId}`);
    }
    if (filters.departmentId) {
        pathArray.push(`&DepartmentId=${filters.departmentId}`);
    }
    if (filters.search) {
        pathArray.push(`&Search=${filters.search}`);
    }

    if (filters.ukDvlaChecks) {
        breakDownApiQueryParams(
            pathArray,
            filters.ukDvlaChecks,
            "ukDvlaChecks"
        );
    }
    if (filters.ukDvlaCheckErrors) {
        breakDownApiQueryParams(
            pathArray,
            filters.ukDvlaCheckErrors,
            "ukDvlaCheckErrors"
        );
    }
    if (filters.ukManualChecks) {
        breakDownApiQueryParams(
            pathArray,
            filters.ukManualChecks,
            "ukManualChecks"
        );
    }
    if (filters.northernIrishManualChecks) {
        breakDownApiQueryParams(
            pathArray,
            filters.northernIrishManualChecks,
            "northernIrishManualChecks"
        );
    }
    if (filters.foreignManualChecks) {
        breakDownApiQueryParams(
            pathArray,
            filters.foreignManualChecks,
            "foreignManualChecks"
        );
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiDvlaChecksResponse>(pathArray.join(""));

    return {
        dvlaChecksData: data,
        dvlaChecksError: error,
        isLoadingDvlaChecks: isLoading,
        refetchDvlaChecks: refetch,
    };
};

export const useTrackDvlaChecks = (
    companyId?: string,
    departmentId?: string,
    nextCheckDate?: Date,
    option?: DvlaAnalysisCategories
) => {
    const pathArray = ["api/dvla/trackChecks"];
    const pushConnector = () =>
        pathArray.length === 1 ? pathArray.push("?") : pathArray.push("&");

    // should only be able to have departmentId if companyId is present
    if (companyId) {
        pushConnector();
        pathArray.push(`CompanyId=${companyId}`);
        if (departmentId) {
            pushConnector();
            pathArray.push(`DepartmentId=${departmentId}`);
        }
    }

    if (nextCheckDate) {
        const year = nextCheckDate.getFullYear();
        const month = nextCheckDate.getMonth() + 1;
        pushConnector();
        pathArray.push(`CheckMonth.Year${year}&CheckMonth.Month${month}`);
    }

    if (option) {
        pushConnector();
        pathArray.push(`Includes=${option}`);
    }

    const { data, error, isLoading, refetch } =
        useS2DApiSWR<DvlaDriverAnalysisResponse>(pathArray.join(""));

    return {
        trackChecksData: data,
        trackChecksError: error,
        isLoadingTrackChecks: isLoading,
        refetchTrackChecks: refetch,
    };
};

// this hook is separate from 'useTrackDvlaChecks' so that the expensive
// report call (i.e. All dvlaCheck categories) is only made when asked for.
export const useTrackDvlaChecksReport = (
    companyId?: string,
    departmentId?: string,
    nextCheckDate?: Date
) => {
    const pathArray = ["api/dvla/trackChecks"];
    const pushConnector = () =>
        pathArray.length === 1 ? pathArray.push("?") : pathArray.push("&");

    // should only be able to have departmentId if companyId is present
    if (companyId) {
        pushConnector();
        pathArray.push(`CompanyId=${companyId}`);
        if (departmentId) {
            pushConnector();
            pathArray.push(`DepartmentId=${departmentId}`);
        }
    }

    if (nextCheckDate) {
        const year = nextCheckDate.getFullYear();
        const month = nextCheckDate.getMonth() + 1;
        pushConnector();
        pathArray.push(`CheckMonth.Year${year}&CheckMonth.Month${month}`);
    }
    const [getTrackChecksData, isGettingTrackChecksData] = useS2DApiFetcher<
        null,
        DvlaDriverAnalysisResponse,
        null
    >(
        "GET",
        () => ({
            url: pathArray.join(""),
            body: null,
            altToken: undefined,
        }),
        [],
        true
    );

    return {
        getTrackChecksData: async () => await getTrackChecksData(null),
        isGettingTrackChecksData,
    };
};

export const useDownloadDvlaChecksCsv = () => {
    const [getDvlaChecksReport, isGettingDvlaChecksReport] = useS2DApiFetcher<
        DvlaCheckFilters,
        Blob,
        { dto: DvlaCheckFilters }
    >(
        "POST",
        ({ dto }) => ({
            url: "api/dvla/download",
            body: dto,
            altToken: undefined,
            contentType: "application/json",
        }),
        [],
        true
    );

    return {
        getDvlaChecksReport: async (dto: DvlaCheckFilters) => await getDvlaChecksReport({ dto }),
        isGettingDvlaChecksReport,
    };
};
