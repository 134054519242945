import { Tab } from "@headlessui/react";
import { Plus, UsersFour, Files, MapPinLine, Tag } from "phosphor-react";
import { useState } from "react";
import CompanyDepartmentsTable from "./CompanyDepartmentsTable";
import Button from "../../Button";
import AddOrEditDepartmentModal from "./AddOrEditDepartmentModal";
import BillingInformationSection from "./BillingInformationSection";
import TabWithIcon from "../../ReusableComponents/HeadlessUiTabWithIcon/TabWithIcon";
import useDepartmentsTab from "./useDepartmentsTab";
import useInvoiceAddressesTab from "./useInvoiceAddressesTab";
import useInvoicesTab from "./useInvoicesTab";
import { type ApiDepartmentItem } from "../../../models/api/admin/department";
import useAuth from "../../../auth";
import { hasClientManagementClaim } from "../../../auth/userAccessHelpers";
import CompanyInvoiceAddressesTable from "./CompanyInvoiceAddressesTable";
import { ApiInvoiceAddressItem } from "../../../models/api/admin/invoiceAddress";
import AddOrEditInvoiceAddressModal from "./AddOrEditInvoiceAddressModal";
import CompanyInvoicesTable from "./CompanyInvoicesTable/CompanyInvoicesTable";
import AddInvoiceModal from "./AddInvoiceModal/AddInvoiceModal";
import AddInvoiceReferenceNumberModal from "./AddInvoiceReferenceNumberModal/AddInvoiceReferenceNumberModal";
import CompanyInvoiceReferenceNumbersTable from "./CompanyInvoiceReferenceNumbersTable";
import useInvoiceReferenceNumbersTab from "./useInvoiceReferenceNumbersTab";
import { ApiInvoiceReferenceNumberItem } from "../../../models/api/admin/invoiceReferenceNumber";
import { CompanyInvoiceSettings } from "../../../models/app/admin/company";

export type AddOrEditModalStates = "closed" | "edit" | "add";

type CompanyBillingTabProps = {
    companyId: string;
    parentId: string | undefined;
    companyName: string;
    departmentId: string;
    companyCreationDate?: Date;
    companyInvoiceSettings: CompanyInvoiceSettings | undefined;
    refetchCompany: () => void;
};

const CompanyBillingTab: React.FC<CompanyBillingTabProps> = ({
    companyId,
    parentId,
    companyName,
    departmentId,
    companyCreationDate,
    companyInvoiceSettings,
    refetchCompany
}) => {
    const [addOrEditModalState, setAddOrEditModalState] =
        useState<AddOrEditModalStates>("closed");
    const [selectedDepartment, setSelectedDepartment] = useState<
        ApiDepartmentItem | undefined
    >();

    const [selectedInvoiceAddress, setSelectedInvoiceAddress] = useState<
        ApiInvoiceAddressItem | undefined
    >();

    const [selectedInvoiceReferenceNumber, setSelectedInvoiceReferenceNumber] =
        useState<ApiInvoiceReferenceNumberItem | undefined>();
    // Might be needed in future iterations after testing etc
    // const [fromDate, setFromDate] = useState(companyCreationDate ? new Date(companyCreationDate) : new Date());
    // const [toDate, setToDate] = useState(new Date());

    const { decodedToken } = useAuth();
    const canEdit = hasClientManagementClaim(decodedToken);

    const {
        departments,
        isLoadingDepartments,
        departmentsError,
        refetchDepartments,
        pageNumber: departmentPageNumber,
        setPageNumber: setDepartmentPageNumber,
        pageSize: departmentPageSize,
        setPageSize: setDepartmentPageSize,
    } = useDepartmentsTab(companyId);

    const {
        invoiceAddresses,
        isLoadingInvoiceAddresses,
        invoiceAddressesError,
        refetchInvoiceAddresses,
        pageNumber: invoiceAddressPageNumber,
        setPageNumber: setInvoiceAddressPageNumber,
        pageSize: invoiceAddressPageSize,
        setPageSize: setInvoiceAddressPageSize,
    } = useInvoiceAddressesTab(companyId);

    const {
        invoices,
        refetchInvoices,
        isLoadingInvoices,
        invoicesError,
        pageNumber: invoicePageNumber,
        setPageNumber: setInvoicePageNumber,
        pageSize: invoicePageSize,
        setPageSize: setInvoicePageSize,
        handleDownloadInvoicePDF,
        isInvoicePDFDownloading,
        handleDownloadChargeableItemsXLS,
        isChargeableItemsXLSDownloading,
    } = useInvoicesTab(companyId, departmentId);

    const {
        invoiceReferenceNumbers,
        isLoadingInvoiceReferenceNumbers,
        invoiceReferenceNumbersError,
        refetchInvoiceReferenceNumbers,
        pageNumber: invoiceReferenceNumberPageNumber,
        setPageNumber: setInvoiceReferenceNumberPageNumber,
        pageSize: invoiceReferenceNumberPageSize,
        setPageSize: setInvoiceReferenceNumberPageSize,
    } = useInvoiceReferenceNumbersTab(companyId);

    const modalIsOpen = addOrEditModalState !== "closed";

    const numberOfDepartments = departments?.totalCount ?? 0;
    const numberOfInvoiceAddresses = invoiceAddresses?.totalCount ?? 0;
    const numberOfInvoices = invoices?.totalCount ?? 0;
    const numberOfInvoiceReferenceNumbers =
        invoiceReferenceNumbers?.totalCount ?? 0;
    return (
        <div>
            <BillingInformationSection companyId={companyId} parentId={parentId} companyInvoiceSettings={companyInvoiceSettings} refetchCompany={refetchCompany} />
            <div className="pb-5" />
            <Tab.Group>
                <Tab.List className="mb-4 flex min-w-fit flex-row divide-x-2 divide-S2D-neutral-10 rounded-sm border-2 border-S2D-neutral-10 font-semibold">
                    <TabWithIcon
                        icon={<UsersFour size={24} weight="fill" />}
                        title={`Departments (${numberOfDepartments})`}
                    />
                    {/* 02/05/23 REMOVED FROM SCOPE FOR NOW
                    <TabWithIcon
                        icon={<FolderNotch size={24} weight="fill" />}
                        title="Invoice Groups"
                    /> */}
                    <TabWithIcon
                        icon={<MapPinLine size={24} weight="fill" />}
                        title={`Invoice Addresses (${numberOfInvoiceAddresses})`}
                    />
                    <TabWithIcon
                        icon={<Files size={24} weight="fill" />}
                        title={`Invoices (${numberOfInvoices})`}
                    />
                    <TabWithIcon
                        icon={<Tag size={24} weight="fill" />}
                        title={`Invoice Reference Numbers (${numberOfInvoiceReferenceNumbers})`}
                    />
                </Tab.List>

                <Tab.Panels>
                    <Tab.Panel>
                        <div className="flex flex-col gap-2 py-2">
                            {/* <Divider text="Departments" /> */}
                            <div className="flex justify-end pb-2">
                                <Button
                                    variant="primary"
                                    icon={Plus}
                                    onClick={() =>
                                        setAddOrEditModalState("add")
                                    }
                                >
                                    Add Department
                                </Button>
                            </div>

                            <CompanyDepartmentsTable
                                companyId={companyId}
                                departments={departments}
                                departmentsError={departmentsError}
                                isLoadingDepartments={isLoadingDepartments}
                                refetchDepartments={refetchDepartments}
                                setSelectedDepartment={setSelectedDepartment}
                                selectedDepartment={selectedDepartment}
                                setAddOrEditModalState={setAddOrEditModalState}
                                pageNumber={departmentPageNumber}
                                setPageNumber={setDepartmentPageNumber}
                                pageSize={departmentPageSize}
                                setPageSize={setDepartmentPageSize}
                                canEdit={canEdit}
                            />
                            <AddOrEditDepartmentModal
                                isOpen={modalIsOpen}
                                setModalState={setAddOrEditModalState}
                                companyId={companyId}
                                selectedDepartment={selectedDepartment}
                                refetchDepartments={refetchDepartments}
                                type={addOrEditModalState}
                                invoiceAddresses={invoiceAddresses?.items ?? []}
                            />
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div className="flex flex-col gap-2 py-2">
                            {/* <Divider text="Invoice Addresses" /> */}
                            <div className="flex justify-end pb-2">
                                <Button
                                    variant="primary"
                                    icon={Plus}
                                    onClick={() =>
                                        setAddOrEditModalState("add")
                                    }
                                >
                                    Add Invoice Address
                                </Button>
                            </div>

                            <CompanyInvoiceAddressesTable
                                invoiceAddresses={invoiceAddresses}
                                invoiceAddressesError={invoiceAddressesError}
                                isLoadingInvoiceAddresses={
                                    isLoadingInvoiceAddresses
                                }
                                refetchInvoiceAddresses={
                                    refetchInvoiceAddresses
                                }
                                setSelectedInvoiceAddress={
                                    setSelectedInvoiceAddress
                                }
                                selectedInvoiceAddress={selectedInvoiceAddress}
                                setAddOrEditModalState={setAddOrEditModalState}
                                pageNumber={invoiceAddressPageNumber}
                                setPageNumber={setInvoiceAddressPageNumber}
                                pageSize={invoiceAddressPageSize}
                                setPageSize={setInvoiceAddressPageSize}
                                canEdit={canEdit}
                            />
                            <AddOrEditInvoiceAddressModal
                                companyId={companyId}
                                isOpen={modalIsOpen}
                                setModalState={setAddOrEditModalState}
                                selectedInvoiceAddress={selectedInvoiceAddress}
                                refetchInvoiceAddresses={
                                    refetchInvoiceAddresses
                                }
                                type={addOrEditModalState}
                            />
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div className="flex justify-end pb-2">
                            <Button
                                variant="primary"
                                icon={Plus}
                                onClick={() => setAddOrEditModalState("add")}
                            >
                                Add Invoice
                            </Button>
                        </div>
                        <CompanyInvoicesTable
                            invoices={invoices}
                            invoicesError={invoicesError}
                            isLoadingInvoices={isLoadingInvoices}
                            pageNumber={invoicePageNumber}
                            setPageNumber={setInvoicePageNumber}
                            pageSize={invoicePageSize}
                            setPageSize={setInvoicePageSize}
                            handleDownloadInvoicePDF={handleDownloadInvoicePDF}
                            isInvoicePDFDownloading={isInvoicePDFDownloading}
                            handleDownloadChargeableItemsXLS={
                                handleDownloadChargeableItemsXLS
                            }
                            isChargeableItemsXLSDownloading={
                                isChargeableItemsXLSDownloading
                            }
                        />
                        <AddInvoiceModal
                            companyId={companyId}
                            parentId={parentId}
                            departments={departments?.items ?? []}
                            invoiceReferenceNumbers={invoiceReferenceNumbers?.items ?? []}
                            isOpen={modalIsOpen}
                            setModalState={setAddOrEditModalState}
                            refetchInvoices={refetchInvoices}
                            type={addOrEditModalState}
                            shouldInvoiceParent={companyInvoiceSettings?.shouldInvoiceParent ?? false}
                        />
                    </Tab.Panel>
                    <Tab.Panel>
                        <div className="flex justify-end pb-2">
                            <Button
                                variant="primary"
                                icon={Plus}
                                onClick={() => setAddOrEditModalState("add")}
                            >
                                Add Invoice Reference Number
                            </Button>
                        </div>
                        <CompanyInvoiceReferenceNumbersTable
                            companyId={companyId}
                            invoiceReferenceNumbers={invoiceReferenceNumbers}
                            invoiceReferenceNumbersError={
                                invoiceReferenceNumbersError
                            }
                            isLoadingInvoiceReferenceNumbers={
                                isLoadingInvoiceReferenceNumbers
                            }
                            refetchInvoiceReferenceNumbers={
                                refetchInvoiceReferenceNumbers
                            }
                            setSelectedInvoiceReferenceNumber={
                                setSelectedInvoiceReferenceNumber
                            }
                            selectedInvoiceReferenceNumber={
                                selectedInvoiceReferenceNumber
                            }
                            setAddOrEditModalState={setAddOrEditModalState}
                            pageNumber={invoiceReferenceNumberPageNumber}
                            setPageNumber={setInvoiceReferenceNumberPageNumber}
                            pageSize={invoiceReferenceNumberPageSize}
                            setPageSize={setInvoiceReferenceNumberPageSize}
                            canEdit={canEdit}
                        />
                        <AddInvoiceReferenceNumberModal
                            companyId={companyId}
                            companyName={companyName}
                            isOpen={modalIsOpen}
                            setModalState={setAddOrEditModalState}
                            refetchInvoiceReferenceNumbers={
                                refetchInvoiceReferenceNumbers
                            }
                            type={addOrEditModalState}
                            departments={departments?.items ?? []}
                        />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};

export default CompanyBillingTab;
