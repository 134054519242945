import clsx from "clsx";
import { CaretDown, CaretUp } from "phosphor-react";

type Props = {
    content?: JSX.Element | string;
    open: boolean;
    flattenBaseOnOpen?: boolean;
    className?: string;
};
export default function CaretDropdown({
    content,
    open,
    flattenBaseOnOpen = false,
    className,
}: Props) {
    return (
        <div
            className={clsx(
                `flex items-center justify-between rounded-sm border-2 
            text-[16px] font-semibold text-S2D-neutral-10`,
                open && flattenBaseOnOpen && "rounded-b-none",
                className
            )}
        >
            {/* NB: the intention is for the height and width of the dropdown to be 
                determined by the content and its padding */}
            {content}
            <div className="pr-3">
                {open ? (
                    <CaretUp size={20} weight="fill" />
                ) : (
                    <CaretDown size={20} weight="fill" />
                )}
            </div>
        </div>
    );
}
