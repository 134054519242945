import Button from "../../Button";

export default function SubmitOrCancelButtonBar({
    onCancel,
    isLoading,
    disabled = false,
    onDelete,
}: {
    onCancel: () => void;
    isLoading?: boolean;
    disabled?: boolean;
    onDelete?: () => void;
}) {
    return (
        <div className="flex flex-col-reverse items-center justify-between gap-4 md:flex-row md:gap-6">
            <Button
                variant="tertiary"
                type="button"
                className="w-full justify-center md:w-auto"
                loading={false}
                onClick={onCancel}
            >
                Cancel
            </Button>
            <div className="flex w-full justify-center gap-3 md:w-auto">
                {onDelete && (
                    <Button
                        disabled={disabled}
                        type="button"
                        onClick={onDelete}
                        className="text-S2D-error-40"
                        variant="tertiary"
                    >
                        Delete
                    </Button>
                )}
                <Button
                    type="submit"
                    className="w-full  justify-center"
                    loading={isLoading}
                    disabled={disabled}
                >
                    Save
                </Button>
            </div>
        </div>
    );
}
