import { Button as MuiButton, Menu, MenuItem } from "@mui/material";
import { DotsThreeOutlineVertical } from "phosphor-react";
import { useState } from "react";
import { IconProps } from "../../../../node_modules/phosphor-react/dist/lib";

export type TableMenuItem = {
    key: string;
    onClick?: () => void;
    icon: JSX.Element | React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
    text: string;
    isLoading?: boolean;
    isDisabled?: boolean;
};

export type TableMenuProps = {
    menuItems: TableMenuItem[];
};

const TableMenu: React.FC<TableMenuProps> = ({ menuItems }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div key="muiMenu">
            <MuiButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <DotsThreeOutlineVertical
                    size={16}
                    weight="fill"
                    className="text-black"
                />
            </MuiButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    disablePadding: true,
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                disableScrollLock={true}
            >
                {menuItems.map((item) => (
                    <MenuItem
                        key={item.key}
                        disabled={item.isLoading || item.isDisabled}
                        onClick={() => {
                            item.onClick && item.onClick();
                            handleClose();
                        }}
                        sx={{
                            "&:hover": {
                                backgroundColor: "var(--secondary)",
                                color: "var(--button-text)",
                            },
                            height: 48,
                        }}
                    >
                        <div className="flex flex-row items-center">
                            <div>{item.icon as JSX.Element}</div>
                            <div className="pl-3">{item.text}</div>
                        </div>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default TableMenu;
