import PageLayout from "../../components/PageLayout";
import StaffTable from "../../components/Safe2DriveStaff/StaffTable";
import StaffFilters from "../../components/Safe2DriveStaff/StaffFilters";
import AddStaffMemberModal from "../../components/AddStaffMember/AddStaffMemberModal";
import EditStaffMemberModal from "../../components/EditStaffMember/EditStaffMemberModal";
import { useStaffPage } from "./pageHooks/useStaffPage";
import DeleteConfirmation from "../../components/ReusableComponents/DeleteConfirmation";
import ConfirmationDialog from "../../components/ReusableComponents/ConfirmationDialog";

export type StaffStatus = "Active" | "Disabled";

const Staff = () => {
    const {
        searchQuery,
        setSearchQuery,
        statusFilter,
        setStatusFilter,
        addStaffModalOpen,
        setAddStaffModalOpen,
        editStaffModalOpen,
        setEditStaffModalOpen,
        selectedUser,
        deleteDialogOpen,
        setDeleteDialogOpen,
        resetPasswordDialogOpen,
        setResetPasswordDialogOpen,
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
        staffData,
        isLoadingStaff,
        staffError,
        refetchStaff,
        handleMenuSelect,
        handleSubmit,
        handleDeleteStaffMember,
        handleResetPassword,
        handleToggleStaffStatus,
        isEditingStaffMember,
        isDeletingStaffMember,
        isResettingPassword,
    } = useStaffPage();

    return (
        <PageLayout title="Staff" header={false}>
            <div className="flex flex-col gap-6">
                <StaffFilters
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    setAddStaffModalOpen={setAddStaffModalOpen}
                    isLoadingStaff={isLoadingStaff}
                />
                <StaffTable
                    staffData={staffData}
                    isLoadingStaff={isLoadingStaff}
                    staffError={staffError}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    handleMenuSelect={handleMenuSelect}
                />
                <AddStaffMemberModal
                    isOpen={addStaffModalOpen}
                    setIsOpen={setAddStaffModalOpen}
                    refetchStaff={refetchStaff}
                />
                {selectedUser && (
                    <>
                        <EditStaffMemberModal
                            isOpen={editStaffModalOpen}
                            setIsOpen={setEditStaffModalOpen}
                            handleDelete={() =>
                                handleMenuSelect(selectedUser.userId, "delete")
                            }
                            handleResetPassword={() =>
                                setResetPasswordDialogOpen(true)
                            }
                            handleSubmit={handleSubmit}
                            handleToggleStaffStatus={handleToggleStaffStatus}
                            isEditingStaffMember={isEditingStaffMember}
                            initialValues={selectedUser}
                        />
                        <DeleteConfirmation
                            isOpen={deleteDialogOpen}
                            onClose={() => setDeleteDialogOpen(false)}
                            title={"You are about to delete a user!"}
                            onSubmit={handleDeleteStaffMember}
                            isLoading={isDeletingStaffMember}
                        />
                        <ConfirmationDialog
                            open={resetPasswordDialogOpen}
                            title={"Reset password"}
                            caption={`Send a reset password request to ${selectedUser.firstName} ${selectedUser.lastName}.`}
                            onClose={() => setResetPasswordDialogOpen(false)}
                            onConfirm={handleResetPassword}
                            isLoading={isResettingPassword}
                        />
                    </>
                )}
            </div>
        </PageLayout>
    );
};

export default Staff;
