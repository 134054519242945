import { Dispatch, SetStateAction, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ApiPostcodeLookupResponse } from "../../models/api/postcodeLookup";
import Button from "../Button";
import EditableContentBlock from "../EditableContentBlock";
import FormTextInput from "../FormTextInput";
import PostcodeAddressLookup from "../PostcodeAddressLookup";
import {
    EditDriverFormValues,
    driverAddressValidationSchema,
} from "./EditDriverFormValues";
import * as yup from "yup";
import { resolverDebugger } from "../../utils/yupResolverDebugger";
import { useStaleDataFormReset } from "../../hooks/useStaleDataFormReset";

type EditDriverAddressInformationProps = {
    initialValues: EditDriverFormValues;
    isLoading: boolean;
    onSubmit: (data: EditDriverFormValues, callback: () => void) => void;
};

const validationSchema = yup.object().shape(driverAddressValidationSchema);

const EditDriverAddressInformation: React.FC<
    EditDriverAddressInformationProps
> = ({ initialValues, isLoading, onSubmit }) => {
    const methods = useForm<EditDriverFormValues>({
        mode: "onSubmit",
        resolver: (data, context, options) =>
            resolverDebugger(data, context, options, validationSchema),
        defaultValues: initialValues,
    });

    const [selectedAddressValue, setSelectedAddressValue] = useState("");

    const handleAddressSelect = (address: ApiPostcodeLookupResponse) => {
        setSelectedAddressValue(address.summaryline);
        // 24/11/23 addressTown and addressPostcode are here as the API expects them
        // (despite the same endpoint needing town and postcode for GET)
        methods.setValue("address", {
            address1: address.addressline1,
            address2: address.addressline2 ?? null,
            address3: address.addressline3 ?? null,
            address4: address.addressline4 ?? null,
            addressTown: address.posttown,
            addressPostcode: address.postcode,
            town: address.posttown,
            postcode: address.postcode,
        });
        methods.trigger("address");
    };

    const handleFormSubmit = async (
        values: EditDriverFormValues,
        setIsEdit: Dispatch<SetStateAction<boolean>>
    ) => {
        onSubmit(values, () => setIsEdit(false));
    };

    const control = methods.control;
    const currentForm = useWatch({ control });

    useStaleDataFormReset(control, methods.reset, initialValues);

    return (
        <EditableContentBlock title="Address">
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            <p>
                                <b>Address line 1: </b>
                                {initialValues.address?.address1}
                            </p>
                            {initialValues.address?.address2 && (
                                <p>
                                    <b>Address line 2: </b>
                                    {initialValues.address?.address2}
                                </p>
                            )}
                            <p>
                                <b>Town/City: </b> {initialValues.address?.town}
                            </p>
                            <p>
                                <b>Postcode: </b>
                                {initialValues.address?.postcode}
                            </p>
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit((values) =>
                                    handleFormSubmit(values, setIsEdit)
                                )}
                            >
                                <div className="w-full">
                                    <PostcodeAddressLookup
                                        value={selectedAddressValue}
                                        onSelect={handleAddressSelect}
                                        autoFocus
                                    />
                                    <FormTextInput
                                        label="Address line 1"
                                        id="address1"
                                        placeholder="Enter address line 1"
                                        error={
                                            methods.formState.errors.address
                                                ?.address1
                                        }
                                        {...methods.register(
                                            "address.address1"
                                        )}
                                        required
                                    />
                                    <FormTextInput
                                        label="Address line 2"
                                        id="address2"
                                        placeholder="Enter address line 2"
                                        error={
                                            methods.formState.errors.address
                                                ?.address2
                                        }
                                        {...methods.register(
                                            "address.address2"
                                        )}
                                    />
                                    <FormTextInput
                                        label="Address line 3"
                                        id="address3"
                                        placeholder="Enter address line 3"
                                        error={
                                            methods.formState.errors.address
                                                ?.address3
                                        }
                                        {...methods.register(
                                            "address.address3"
                                        )}
                                    />
                                    <FormTextInput
                                        label="City/Town"
                                        id="city"
                                        placeholder="Enter city/town"
                                        error={
                                            methods.formState.errors.address
                                                ?.addressTown
                                        }
                                        {...methods.register(
                                            "address.addressTown"
                                        )}
                                        required
                                    />
                                    <FormTextInput
                                        label="Postcode"
                                        id="postcode"
                                        placeholder="Enter postcode"
                                        error={
                                            methods.formState.errors.address
                                                ?.addressPostcode
                                        }
                                        {...methods.register(
                                            "address.addressPostcode"
                                        )}
                                        required
                                    />
                                </div>
                                <div className="mt-8 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => {
                                            setIsEdit(false);
                                            methods.reset({
                                                ...currentForm,
                                                address: initialValues.address,
                                            });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" loading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditDriverAddressInformation;
