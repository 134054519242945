export type DriverLicenceAnalysisValue<Section> =
    | {
          [Property in keyof Section]: boolean | null;
      };

export enum DriversByPointsOptions {
    zeroPoints = "zeroPoints",
    oneToThreePoints = "oneToThreePoints",
    fourToSevenPoints = "fourToSevenPoints",
    eightAndOverPoints = "eightAndOverPoints",
}

export enum DriversByPointsValues {
    zeroPoints = "0 points",
    oneToThreePoints = "1-3 points",
    fourToSevenPoints = "4-7 points",
    eightAndOverPoints = "8+ Points",
}

export type DriversByPoints =
    | {
          [key in DriversByPointsOptions]: boolean | null;
      }
    | Record<string, never>;

export enum DriversByAgeRangeOptions {
    eighteenToTwentyOne = "eighteenToTwentyOne",
    twentyTwoToTwentyFive = "twentyTwoToTwentyFive",
    twentySixToThirtyFive = "twentySixToThirtyFive",
    thirtySixToFifty = "thirtySixToFifty",
    fiftyOneToSixtyFive = "fiftyOneToSixtyFive",
    sixtySixAndOver = "sixtySixAndOver",
}

export enum DriversByAgeRangeValues {
    eighteenToTwentyOne = "18-21",
    twentyTwoToTwentyFive = "22-25",
    twentySixToThirtyFive = "26-35",
    thirtySixToFifty = "36-50",
    fiftyOneToSixtyFive = "51-65",
    sixtySixAndOver = "66+",
}

export type DriversByAgeRange =
    | {
          [key in DriversByAgeRangeOptions]: boolean | null;
      }
    | Record<string, never>;

export type EndorsementCodes =
    | {
          endorsementCode: string | null;
      }
    | Record<string, never>;

export enum FilterLicenceTypeOptions {
    currentFullLicence = "currentFullLicence",
    currentProvisionalLicence = "currentProvisionalLicence",
    expiredFullLicence = "expiredFullLicence",
    expiredProvisionalLicence = "expiredProvisionalLicence",
    surrenderedFullLicence = "surrenderedFullLicence",
    surrenderedProvisionalLicence = "surrenderedProvisionalLicence",
    disqualified = "disqualified",
    previouslyDisqualified  = "previouslyDisqualified",
    disqualifiedDate = "disqualifiedDate",
    disqualifiedTest = "disqualifiedTest",
    disqualifiedSentence = "disqualifiedSentence",
    disqualifiedLife = "disqualifiedLife",
    revokedPassed = "revokedPassed",
    noEntitlement = "noEntitlement",
}

export type LicenceTypes =
    | {
          [key in FilterLicenceTypeOptions]: boolean | null;
      }
    | Record<string, never>;

export type DriverRestrictions = {
    restrictionCode: string | null;
};

export type DriversByLicenceCategory = {
    categoryCode: string | null;
};

export enum FilterLicenceOriginOptions {
    ukOrigin = "ukOrigin",
    niOrigin = "niOrigin",
    foreignOrigin = "foreignOrigin",
}

export enum FilterLicenceOriginValues {
    ukOrigin = "UK origin",
    niOrigin = "NI origin",
    foreignOrigin = "Foreign Origin",
}

export type DriversByLicenceOrigin =
    | {
          [key in FilterLicenceOriginOptions]: boolean | null;
      }
    | Record<string, never>;

export enum YearsFullLicenceHeldOptions {
    zeroToTwoYears = "zeroToTwoYears",
    threeToFiveYears = "threeToFiveYears",
    sixToTenYears = "sixToTenYears",
    elevenToTwentyYears = "elevenToTwentyYears",
    twentyOneAndOverYears = "twentyOneAndOverYears",
}

export enum YearsFullLicenceHeldValues {
    zeroToTwoYears = "0-2 years",
    threeToFiveYears = "3-5 years",
    sixToTenYears = "6-10 years",
    elevenToTwentyYears = "11-20 years",
    twentyOneAndOverYears = "21+ years",
}

export type YearsFullLicenceHeld =
    | {
          [key in YearsFullLicenceHeldOptions]: boolean | null;
      }
    | Record<string, never>;
