import {
    ArrowSquareOut,
    Warning,
    type IconProps,
    WarningOctagon,
    Barricade,
    ArrowUp,
} from "phosphor-react";
import { type ForwardRefExoticComponent, type RefAttributes } from "react";
import clsx from "clsx";
import { useIsSmallScreen } from "../hooks/screenSizes";

type StatisticSummaryBoxProps = {
    icon?:
        | ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>
        | undefined;
    iconColor?: string;
    customImg?: string;
    customImgClassName?: string;
    className?: string;
    label: string;
    statistic?: string | number | null;
    onLinkClick?: () => void;
    isLoadingAction?: boolean;
};

const StatisticSummaryBox: React.FC<StatisticSummaryBoxProps> = ({
    icon: Icon,
    iconColor,
    customImg,
    customImgClassName,
    className,
    label,
    statistic,
    onLinkClick,
    isLoadingAction,
}) => {
    const isBiggerScreen = useIsSmallScreen();
    const curatedTextAndBackgroundColors = () => {
        if (Icon === Warning) {
            return "bg-S2D-warning-90";
        } else if (Icon === WarningOctagon || Icon === ArrowUp) {
            return "bg-S2D-error-40 text-S2D-neutral-100";
        } else if (Icon === Barricade) {
            return "bg-S2D-neutral-10 text-S2D-neutral-100";
        } else {
            return "bg-S2D-success-80";
        }
    };

    return (
        <button
            onClick={onLinkClick}
            className={clsx(
                `flex grow items-center justify-between rounded-sm p-4`,
                isLoadingAction && "hover:cursor-wait",
                className ?? "bg-S2D-dark-green-30 text-S2D-text-menu",
                !onLinkClick && "cursor-default"
            )}
        >
            <div className="flex items-center gap-2 text-start">
                <div
                    className={clsx(
                        "flex h-10 items-center justify-center rounded-sm p-2 text-S2D-neutral-10",
                        !iconColor && curatedTextAndBackgroundColors(),
                        iconColor && iconColor
                    )}
                >
                    {Icon && <Icon size={isBiggerScreen ? 32 : 25} />}
                    {customImg && (
                        <img
                            src={customImg}
                            className={customImgClassName ?? "w-12"}
                        />
                    )}
                </div>
                <span className={"text-2xl font-medium md:text-4xl"}>
                    {statistic ?? "-"}
                </span>
                {/* inner div width makes words break nicely, outer span makes any text overflow remain visible */}
                <span className="w-3/4">
                    <div className="w-[70%] break-normal text-xs font-semibold uppercase md:text-sm">
                        {/* replace regular hyphen with unicode non-breaking hyphen */}
                        {label.replace("-", "‑")}
                    </div>
                </span>
            </div>
            {onLinkClick && isBiggerScreen && (
                <div
                    className={clsx(
                        `flex items-center justify-center 
                    rounded-sm border-2 border-S2D-neutral-100 hover:cursor-pointer hover:opacity-60`,
                        isLoadingAction && "opacity-60 hover:cursor-wait",
                        isBiggerScreen ? "p-2" : "p-1"
                    )}
                >
                    <ArrowSquareOut size={25} />
                </div>
            )}
        </button>
    );
};

export default StatisticSummaryBox;
