import { type GreyFleetAlertsData } from "../../models/api/admin/greyFleet";
import { useS2DApiSWR } from "../common/s2dApiSWR";
import { queryParamConnector } from "../../utils/queryParamHelpers";

export const useGreyFleetAlerts = (
    companyId?: string,
    departmentId?: string
) => {
    const pathArray = ["/api/admin/greyfleet/alerts"];

    if (companyId) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`CompanyId=${companyId}`);
    }
    if (departmentId) {
        pathArray.push(queryParamConnector(pathArray));
        pathArray.push(`DepartmentId=${departmentId}`);
    }
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<GreyFleetAlertsData>(pathArray.join(""));

    return { data, error, isLoading, refetch };
};
