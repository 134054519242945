import ApiError from "../../api/common/apiError";
import useLocalStorage from "../../hooks/useLocalStorage";
import { type ApiGetAllVehiclesResponse } from "../../models/api/admin/vehicle";
import { type GreyFleetVehicleAnalysisCharts } from "../../models/app/admin/analysisChart";
import FormMessageBox from "../FormMessageBox";
import PaginationNav from "../PaginationNav";
import Table, { type TableHeader } from "../ReusableComponents/Table";
import {
    initVehicleChartAnalysisTableContents,
    initVehicleChartAnalysisTableHeaders,
} from "./vehicleChartAnalysisInit";

type VehicleChartAnalysisTableProps = {
    chartType: GreyFleetVehicleAnalysisCharts;
    vehiclesList?: ApiGetAllVehiclesResponse;
    fetchVehiclesError?: ApiError;
    isFetchingVehicles?: boolean;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
};

const VehicleChartAnalysisTable: React.FC<VehicleChartAnalysisTableProps> = ({
    chartType,
    vehiclesList,
    fetchVehiclesError,
    isFetchingVehicles,
    setPageNumber,
    pageSize,
    setPageSize,
}) => {
    if (!isFetchingVehicles && vehiclesList && !vehiclesList.items.length) {
        return <div>No vehicles found</div>;
    }

    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "VehicleChartAnalysisTableHeaders",
        initVehicleChartAnalysisTableHeaders(chartType)
    );
    const initHiddenCols = new Array(0);
    tableHeaders.map((item) =>
        initHiddenCols.push({
            id: item.id,
            hidden: item.hide ?? false,
        })
    );

    return (
        <>
            {fetchVehiclesError && (
                <FormMessageBox
                    title={"error"}
                    message={
                        <span>
                            There was an error loading vehicle data.
                            <br />
                            Please try again in 10 minutes.
                        </span>
                    }
                    variant={"error"}
                />
            )}
            <Table
                tableHeaders={tableHeaders}
                setTableHeaders={setTableHeaders}
                tableContents={initVehicleChartAnalysisTableContents(
                    vehiclesList?.items ?? []
                )}
                hiddenCols={initHiddenCols}
                isLoading={isFetchingVehicles}
            />
            {vehiclesList && (
                <PaginationNav
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    totalCount={vehiclesList.totalCount}
                    totalPages={vehiclesList.totalPages}
                    currentPage={vehiclesList.pageNumber}
                    hasPreviousPage={vehiclesList.hasPreviousPage}
                    hasNextPage={vehiclesList.hasNextPage}
                />
            )}
        </>
    );
};

export default VehicleChartAnalysisTable;
