import clsx from "clsx";
import {
    ArrowLineLeft,
    ArrowLineRight,
    Buildings,
    Scroll,
    FileArrowUp,
    Gauge,
    IdentificationCard,
    Car,
    Truck,
    UserCircleGear,
    Briefcase,
    Wrench,
    UsersThree,
    // Megaphone,
    // Envelope,
    // Money,
    ChartBar,
} from "phosphor-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { appPaths } from "../../Routes";
import SideNavItem from "./SideNavItem";
import { capitalisedPluralTermForCompany } from "../../copy/sharedCopy";
import useAuth from "../../auth";
import SideNavDropDown from "./SideNavDropDown";
import useLocalStorage from "../../hooks/useLocalStorage";
import { isS2dUser } from "../../auth/userAccessHelpers";
import { useGetFileTransferNotifications } from "../../api/admin/fileSafe";

type SideNavProps = {
    open: boolean;
    expanded: boolean;
    closeSideMenu: () => void;
    toggleNavExpansion: () => void;
};

const SideNav: React.FC<SideNavProps> = ({
    open,
    expanded,
    closeSideMenu,
    toggleNavExpansion,
}) => {
    const location = useLocation();
    const { decodedToken } = useAuth();
    const [isDriverDropdownOpen, setIsDriverDropDownOpen] =
        useLocalStorage<boolean>("isDriverDropdownOpen", false);
    const [isToolsDropdownOpen, setIsToolsDropDownOpen] =
        useLocalStorage<boolean>("isToolsDropdownOpen", false);

    const toggleDriverDropdownOpen = () =>
        setIsDriverDropDownOpen(!isDriverDropdownOpen);
    const toggleToolsDropdownOpen = () =>
        setIsToolsDropDownOpen(!isToolsDropdownOpen);

    const { notificationCount, isNotificationLoading } = useGetFileTransferNotifications(decodedToken?.fileSafe);

    useEffect(() => {
        closeSideMenu();
    }, [location]);

    useEffect(() => {
        if (location.pathname.includes("driver")) {
            setIsDriverDropDownOpen(true);
        } else {
            setIsDriverDropDownOpen(false);
        }
    }, [location.pathname]);

    const displayNotificationCount = (count: string | undefined): string | null => {
        const parsedCount = parseInt(count || "");

        if (isNaN(parsedCount) || parsedCount <= 0) {
            return null;
        }

        return parsedCount > 999 ? "999+" : String(parsedCount);
    };

    return (
        <nav
            id="main-side-navigation"
            className={clsx(
                "fixed top-0 z-40 h-screen  w-[16.5rem] overflow-y-auto bg-S2D-dark-green-50 text-white md:top-[4.25rem] md:h-screen-minus-header",
                open ? "left-0 md:right-0" : "-right-full md:left-0",
                expanded ? " " : "md:w-[4.5rem]"
            )}
        >
            <ul>
                <li>
                    <button
                        className={clsx(
                            "hidden h-14 w-full items-center gap-4 border-b border-b-S2D-dark-green-20 bg-S2D-dark-green-30 text-S2D-light-green-80 transition-colors hover:bg-S2D-dark-green-20 md:flex",
                            expanded ? "px-6" : "justify-center"
                        )}
                        onClick={toggleNavExpansion}
                        aria-controls="main-side-navigation"
                        aria-expanded={expanded}
                    >
                        {expanded ? (
                            <ArrowLineLeft size={32} />
                        ) : (
                            <ArrowLineRight size={32} />
                        )}

                        <span className="sr-only">
                            {expanded ? "Minimise" : "Maximise"} side navigation
                        </span>
                    </button>
                </li>
                <SideNavItem to={appPaths.dashboard} expanded={expanded}>
                    <Gauge size={32} />
                    <span className={clsx(expanded ? "" : "md:sr-only")}>
                        Dashboard
                    </span>
                </SideNavItem>

                <SideNavDropDown
                    isDropDownOpen={isDriverDropdownOpen}
                    expanded={expanded}
                    toggleOpen={toggleDriverDropdownOpen}
                    dropDownText="Drivers"
                    icon={UsersThree}
                >
                    <SideNavItem
                        to={appPaths.adminDrivers}
                        expanded={expanded}
                        bgColor="bg-S2D-dark-green-20"
                    >
                        <IdentificationCard size={32} />
                        <span className={clsx(expanded ? "" : "md:sr-only")}>
                            All Drivers
                        </span>
                    </SideNavItem>
                    <SideNavItem
                        to={appPaths.adminProfessionalDrivers}
                        expanded={expanded}
                        bgColor="bg-S2D-dark-green-20"
                    >
                        <Truck size={32} />
                        <span className={clsx(expanded ? "" : "md:sr-only")}>
                            Professional Drivers
                        </span>
                    </SideNavItem>
                    <SideNavItem
                        to={appPaths.adminDeclarations}
                        expanded={expanded}
                        bgColor="bg-S2D-dark-green-20"
                    >
                        <Scroll size={32} />
                        <span className={clsx(expanded ? "" : "md:sr-only")}>
                            Driver Declarations
                        </span>
                    </SideNavItem>
                </SideNavDropDown>
                <SideNavItem to={appPaths.adminGreyFleet} expanded={expanded}>
                    <Car size={32} />
                    <span className={clsx(expanded ? "" : "md:sr-only")}>
                        Grey Fleet
                    </span>
                </SideNavItem>
                {isS2dUser(decodedToken?.role) && (
                    <>
                        <SideNavItem
                            to={appPaths.adminCompanies}
                            expanded={expanded}
                        >
                            <Briefcase size={32} />
                            <span
                                className={clsx(expanded ? "" : "md:sr-only")}
                            >
                                {`${capitalisedPluralTermForCompany}`}
                            </span>
                        </SideNavItem>

                        <SideNavItem
                            to={appPaths.s2dDvlaChecks}
                            expanded={expanded}
                        >
                            <Buildings size={32} />
                            <span
                                className={clsx(expanded ? "" : "md:sr-only")}
                            >
                                DVLA
                            </span>
                        </SideNavItem>
                    </>
                )}

                {decodedToken?.fileSafe && (
                    <SideNavItem to={appPaths.adminFileSafe} expanded={expanded}>
                        <div className="relative">
                            <FileArrowUp size={32} />
                            {displayNotificationCount(notificationCount) && !isNotificationLoading && (
                                <span className="absolute top-0 right-0 flex items-center justify-center min-w-[1rem] h-4 px-1 text-xs text-white bg-red-600 rounded-full">
                                    {displayNotificationCount(notificationCount)}
                                </span>
                            )}
                        </div>
                        <span className={clsx(expanded ? "" : "md:sr-only")}>
                            FileSafe
                        </span>
                    </SideNavItem>
                )}

                {isS2dUser(decodedToken?.role) && (
                    <SideNavItem to={appPaths.s2dStaff} expanded={expanded}>
                        <UserCircleGear size={32} />
                        <span className={clsx(expanded ? "" : "md:sr-only")}>
                            Staff
                        </span>
                    </SideNavItem>
                )}
                <SideNavDropDown
                    isDropDownOpen={isToolsDropdownOpen}
                    expanded={expanded}
                    toggleOpen={toggleToolsDropdownOpen}
                    dropDownText="Tools"
                    icon={Wrench}
                >
                    {/* <SideNavItem
                        to={appPaths.communications}
                        expanded={expanded}
                        bgColor="bg-S2D-dark-green-20"
                        disabled
                    >
                        <Megaphone size={32} />
                        <span className={clsx(expanded ? "" : "md:sr-only")}>
                            Communications
                        </span>
                    </SideNavItem>
                    <SideNavItem
                        to={appPaths.emailLog}
                        expanded={expanded}
                        bgColor="bg-S2D-dark-green-20"
                        disabled
                    >
                        <Envelope size={32} />
                        <span className={clsx(expanded ? "" : "md:sr-only")}>
                            Email log
                        </span>
                    </SideNavItem>
                     spellchecker keeps saying that "financials" is not a word however it is wrong (verified with google) 
                    <SideNavItem
                        to={appPaths.financials}
                        expanded={expanded}
                        bgColor="bg-S2D-dark-green-20"
                        disabled
                    >
                        <Money size={32} />
                        <span className={clsx(expanded ? "" : "md:sr-only")}>
                            Financials
                        </span>
                    </SideNavItem> */}
                    <SideNavItem
                        to={appPaths.reporting}
                        expanded={expanded}
                        bgColor="bg-S2D-dark-green-20"
                    >
                        <ChartBar size={32} />
                        <span className={clsx(expanded ? "" : "md:sr-only")}>
                            Reporting
                        </span>
                    </SideNavItem>
                </SideNavDropDown>
            </ul>
        </nav>
    );
};

export default SideNav;
