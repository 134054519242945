import { useS2DApiFetcher } from "../common/fetching";
import { DeclarationTableFilters } from "./eDeclarations";

export const useDownloadCompaniesDeclarationsReport = () => {
    const [getDeclarationsReport, isGettingDeclarationsReport] = useS2DApiFetcher<
        DeclarationTableFilters,
        Blob,
        { dto: DeclarationTableFilters }
    >(
        "POST",
        ({ dto }) => ({
            url: "api/companies/declarations/download",
            body: dto,
            altToken: undefined,
            contentType: "application/json",
        }),
        [],
        true
    );

    return {
        getDeclarationsReport: async (dto: DeclarationTableFilters) => await getDeclarationsReport({ dto }),
        isGettingDeclarationsReport,
    };
};
