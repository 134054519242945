import clsx from "clsx";
import React, { ReactNode } from "react";
import { FieldError } from "react-hook-form";
import { MagnifyingGlass, Warning } from "phosphor-react";

import FormInputLabel from "./FormInputLabel";
import FormInputMessage from "./FormInputError";
import FormInputMoreInfo from "./FormInputMoreInfo";

export type FormTextInputProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "type" | "className"
> & {
    label?: string;
    error?: FieldError | string;
    helpText?: string;
    required?: boolean;
    tooltip?: string;
    type?: "text" | "password" | "number" | "date";
    step?: number;
    inputFieldClassName?: string;
    labelClassName?: string;
    StartAdornmentIcon?: typeof MagnifyingGlass;
    ValueHelper?: ReactNode;
    fillStartAdornment?: boolean;
    moreInfo?: {
        dialogPrompt?: string;
        title?: string;
        description?: string;
        content?: React.ReactNode;
    };
    className?: string;
    inputHeight?: string;
    noLabelTextWrap?: boolean;
};

const FormTextInput = React.forwardRef<HTMLInputElement, FormTextInputProps>(
    (
        {
            id,
            label,
            error,
            helpText,
            required,
            tooltip,
            type = "text",
            step,
            inputFieldClassName,
            labelClassName,
            StartAdornmentIcon,
            ValueHelper,
            fillStartAdornment,
            moreInfo,
            className,
            inputHeight,
            noLabelTextWrap,
            ...inputProps
        },
        ref
    ) => {
        const shiftFieldValue = StartAdornmentIcon || ValueHelper;
        return (
            <div className={clsx("w-full", className)}>
                <div>
                    {label && (
                        <div
                            className={clsx(
                                `flex flex-row items-center justify-between ${
                                    noLabelTextWrap && "whitespace-nowrap"
                                }`
                            )}
                        >
                            <FormInputLabel
                                htmlFor={id}
                                required={required}
                                className={labelClassName}
                                tooltipText={tooltip}
                            >
                                {label}
                            </FormInputLabel>
                            {moreInfo && (
                                <FormInputMoreInfo moreInfo={moreInfo} />
                            )}
                        </div>
                    )}
                </div>
                <div className="relative">
                    <input
                        {...inputProps}
                        id={id}
                        step={step}
                        type={type}
                        ref={ref}
                        aria-required={required}
                        className={clsx(
                            "flex w-full items-center rounded-sm border border-black px-4 transition-background-color",
                            inputHeight ?? "base-height",
                            inputProps.disabled &&
                                "cursor-not-allowed opacity-40",
                            !inputProps.disabled &&
                                "bg-S2D-dark-green-70.1 hover:bg-white focus:border-S2D-light-green-80 focus:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-S2D-light-green-80",
                            inputFieldClassName,
                            error &&
                                " border-S2D-error-40 pr-10 outline-none ring-S2D-error-40 focus:border-S2D-error-40 focus:ring-S2D-error-40",
                            shiftFieldValue && "pl-12"
                        )}
                    />
                    {StartAdornmentIcon && (
                        <StartAdornmentIcon
                            weight={fillStartAdornment ? "fill" : undefined}
                            className="pointer-events-none absolute top-1/2 left-3 h-6 w-6 -translate-y-1/2 transform"
                        />
                    )}
                    {ValueHelper && (
                        <div className="pointer-events-none absolute top-1/2 left-3 -translate-y-1/2 transform">
                            {ValueHelper}
                        </div>
                    )}
                    {error && (
                        <Warning
                            className="pointer-events-none absolute top-1/2 right-3 h-6 w-6 -translate-y-1/2 transform text-S2D-error-40"
                            weight="fill"
                        />
                    )}
                </div>
                {(error || helpText) && (
                    <div className="pl-3 pt-1">
                        <FormInputMessage error={error} helpText={helpText} />
                    </div>
                )}
            </div>
        );
    }
);

export default FormTextInput;
