import { Dispatch, SetStateAction } from "react";
import { stringifyValue } from "../../../utils/stringifyValue";

export const generateSelectedValues = (
    selectedValues: string[],
    setSelectedValues: Dispatch<SetStateAction<string[]>>,
    label: string,
    value?: string | number | Date | string[] | null
) => {
    // if value already exists, find and update it (or remove it)
    // otherwise add it to the array

    const existingValue = selectedValues.find((valueString) =>
        valueString.includes(label)
    );
    const valueString = stringifyValue(value);

    if (existingValue) {
        const existingValueIndex = selectedValues.indexOf(existingValue, 0);
        const valuesPrecedingEditedValue = selectedValues.slice(
            0,
            existingValueIndex
        );
        const valuesFollowingEditedValue = selectedValues.slice(
            existingValueIndex + 1,
            selectedValues.length
        );

        if (valueString) {
            setSelectedValues([
                ...valuesPrecedingEditedValue,
                `${label} ${valueString}`,
                ...valuesFollowingEditedValue,
            ]);
        } else {
            setSelectedValues([
                ...valuesPrecedingEditedValue,
                ...valuesFollowingEditedValue,
            ]);
        }
    } else {
        if (!value) return;
        setSelectedValues([...selectedValues, `${label} ${valueString}`]);
    }
};
