import { Controller, useForm, useWatch } from "react-hook-form";
import {
    AddCompanyUserFormValidationSchema,
    addCompanyUserFormDefaultValues,
    type AddCompanyUserFormValues,
} from "./AddCompanyUserFormValues";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../FormTextInput";
import FormDropdown from "../FormDropdown";
import LoadingMessage from "../ReusableComponents/LoadingMessage/LoadingMessage";
import ApiError from "../../api/common/apiError";
import Button from "../Button";
import UserIcon from "../UserIcon";
import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import {
    companyUserRoles,
    yesNoOptions,
} from "../../constants/dropdownOptions";
import useAuth from "../../auth";
import { canControlClaims } from "../../auth/userAccessHelpers";
import { useEffect } from "react";
import { UserRoles } from "../../auth/decodeToken";
import { adminsCanViewPiiMessage } from "../../constants/dvlaConstants";
import FormDropdownMultiSelect from "../FormDropdownMultiSelect";

type AddCompanyUserFormProps = {
    isLoading: boolean;
    onSubmit: (value: AddCompanyUserFormValues, callback?: () => void) => void;
    onCancel: () => void;
    isLoadingDepartments: boolean;
    departmentsError?: ApiError;
    departmentOptions?: {
        label: string;
        value: string;
    }[];
    companyName?: string;
};

const validationSchema = yup.object().shape(AddCompanyUserFormValidationSchema);

const AddCompanyUserForm: React.FC<AddCompanyUserFormProps> = ({
    companyName,
    isLoading,
    onSubmit,
    onCancel,
    isLoadingDepartments,
    departmentsError,
    departmentOptions,
}) => {
    const { decodedToken } = useAuth();

    const { handleSubmit, register, formState, control, setValue } =
        useForm<AddCompanyUserFormValues>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues: addCompanyUserFormDefaultValues,
        });

    const handleFormSubmit = (data: AddCompanyUserFormValues) => {
        onSubmit(data, () => console.log("callback"));
    };

    const editedUserRole = useWatch({ control, name: "roleName" });
    const userIsAdmin = editedUserRole === UserRoles.clientAdmin;

    const departmentDropdownOptions = departmentOptions?.map((department) => ({
        label: department.label,
        value: department.value,
    }));

    useEffect(() => {
        if (userIsAdmin) setValue("claims.view-pii", true);
    }, [editedUserRole]);

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="mx-auto max-h-[600px] w-full max-w-3xl overflow-y-scroll p-6">
                <div className="flex w-full items-center justify-center p-2  ">
                    <UserIcon firstName="F" lastName="L" size="large" />
                </div>
                <FormTextInput
                    label={capitalisedTermForCompany}
                    disabled
                    placeholder={companyName}
                />
                {isLoadingDepartments || departmentsError ? (
                    <LoadingMessage />
                ) : (
                    <Controller
                        name="departmentIds"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdownMultiSelect
                                label="Departments"
                                options={departmentDropdownOptions ?? []}
                                pleaseSelectText="Select departments"
                                value={value ?? []}
                                onChange={onChange}
                            />
                        )}
                    />
                )}
                <Controller
                    name="roleName"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Permission"
                            options={companyUserRoles}
                            value={value ?? ""}
                            onChange={onChange}
                            pleaseSelectText="Select permission"
                            error={formState.errors.roleName}
                        />
                    )}
                />
                {canControlClaims(decodedToken) && (
                    <>
                        <Controller
                            name="claims.file-safe"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormDropdown
                                    label="Has access to FileSafe?"
                                    options={yesNoOptions}
                                    value={value}
                                    onChange={onChange}
                                    pleaseSelectText="Please select..."
                                    error={
                                        formState.errors.claims?.["file-safe"]
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="claims.view-pii"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormDropdown
                                    label="Can view personally identifiable information?"
                                    options={yesNoOptions}
                                    value={value}
                                    onChange={onChange}
                                    pleaseSelectText="Please select..."
                                    error={
                                        formState.errors.claims?.["view-pii"]
                                    }
                                    disabled={userIsAdmin}
                                    helpText={
                                        userIsAdmin
                                            ? adminsCanViewPiiMessage
                                            : ""
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="claims.client-management"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormDropdown
                                    label="Can manage client information?"
                                    options={yesNoOptions}
                                    value={value}
                                    onChange={onChange}
                                    pleaseSelectText="Please select..."
                                    error={
                                        formState.errors.claims?.[
                                            "client-management"
                                        ]
                                    }
                                />
                            )}
                        />
                    </>
                )}
                <Controller
                    name="shouldReceiveEmailNotifications"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Receives notifications?"
                            options={yesNoOptions}
                            value={value}
                            onChange={onChange}
                            pleaseSelectText="Please select..."
                            error={
                                formState.errors.shouldReceiveEmailNotifications
                            }
                        />
                    )}
                />
                <FormTextInput
                    label="First Name"
                    required
                    id="firstName"
                    placeholder="Enter first name"
                    error={formState.errors.firstName}
                    {...register("firstName")}
                />
                <FormTextInput
                    label="Surname"
                    required
                    id="lastName"
                    placeholder="Enter surname"
                    error={formState.errors.lastName}
                    {...register("lastName")}
                />
                <FormTextInput
                    label="Email Address"
                    required
                    id="emailAddress"
                    placeholder="Enter email address"
                    error={formState.errors.emailAddress}
                    {...register("emailAddress")}
                />
            </div>
            <div className="flex flex-col-reverse justify-between border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                <Button
                    variant="tertiary"
                    type="button"
                    onClick={onCancel}
                    className="w-full justify-center md:w-auto"
                >
                    Cancel
                </Button>

                <Button
                    type="submit"
                    loading={isLoading}
                    className="w-full justify-center md:w-auto"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default AddCompanyUserForm;
