export default function AlertBadge({ value }: { value: string }) {
    return (
        <div
            className={`flex h-[21px] w-[21px] items-center justify-center 
            rounded-full bg-S2D-error-40 text-[12px] font-normal text-[#FFFFFF]`}
        >
            {value}
        </div>
    );
}
