import { useState } from "react";
import { useCompanyReportSettings } from "../../api/admin/reports";
import FormDateInput from "../FormDateInput";
import Button from "../Button";
import { ScheduleMonthlyReportDto } from "../../models/app/admin/reportSetting";
import { formatDateToString } from "../../utils/formatDateToString";
import CollapsibleSection from "../ReusableComponents/CollapsibleSection";
import useLocalStorage from "../../hooks/useLocalStorage";
import { successToast } from "../../toast";
import moment from "moment";

// eslint-disable-next-line @typescript-eslint/ban-types
export type CompanyMonthlyReportSettingsProps = {};

// eslint-disable-next-line no-empty-pattern
const CompanyMonthlyReportSettings: React.FC<
    CompanyMonthlyReportSettingsProps
    // eslint-disable-next-line no-empty-pattern
> = ({}) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [dateError, setDateError] = useState<string | undefined>(undefined);
    const [isMontlyReportSettingsOpen, setIsMontlyReportSettingsOpen] =
        useLocalStorage<boolean>("isMontlyReportSettingsOpen", true);

    const {
        companyReportSettingsData,
        refetchMonthlyReportSettings,
        scheduleMonthlyReport,
        isSchedulingMonthlyReport,
        deleteScheduledReport,
        isDeletingScheduledReport,
    } = useCompanyReportSettings();

    const handleDateChange = (newDate: Date | null) => {
        setDateError(undefined);
        setSelectedDate(newDate);
    };

    const isDateValid = (newDate: Date | null) => {
        setDateError(undefined);

        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to start of today to ignore time part in comparison

        if (newDate && newDate < today) {
            setDateError("The date cannot be today or in the past.");
            return false;
        }

        if (!moment(newDate, "DD/MM/YYYY", true).isValid()) {
            setDateError("The date must be in DD/MM/YYYY format.");
            return false;
        }

        return true;
    };

    const handleScheduleMonthlyReport = async () => {
        if (!isDateValid(selectedDate)) {
            return;
        }

        const scheduleMonthlyReportDto: ScheduleMonthlyReportDto = {
            scheduledReportDate: selectedDate?.toISOString(),
        };
        const response = await scheduleMonthlyReport(scheduleMonthlyReportDto);

        if (response.success) {
            refetchMonthlyReportSettings();
            successToast("Monthly report scheduled successfully");
            return;
        }
    };

    const formatDate = (dateString: string | undefined) => {
        if (dateString) {
            const date = new Date(dateString);
            return formatDateToString(date);
        }
        return "";
    };

    const handleDeleteScheduledReport = async () => {
        const response = await deleteScheduledReport();

        if (response.success) {
            refetchMonthlyReportSettings();
            successToast("Monthly report stopped successfully");
            return;
        }
    };

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    const isDeleteDisabled = companyReportSettingsData === null || companyReportSettingsData?.monthlyReportState === "Deleted";
    return (
        <>
            <CollapsibleSection
                isOpen={isMontlyReportSettingsOpen}
                setIsOpen={setIsMontlyReportSettingsOpen}
                title={"Monthly Report Settings"}
                content={
                    <div className="flex flex-col gap-5">
                        <p>
                            <b>Current Scheduled Report Date: </b>
                            {companyReportSettingsData?.monthlyReportState !==
                                "Deleted" &&
                            companyReportSettingsData?.monthlyReportDate
                                ? formatDate(
                                      companyReportSettingsData.monthlyReportDate
                                  )
                                : "Not Set"}
                        </p>
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                            <FormDateInput
                                id="scheduleReportDate"
                                hideHelpText
                                label="Set Scheduled Report Date"
                                value={selectedDate}
                                onChange={handleDateChange}
                                minDate={minDate}
                                adornmentClassName="-mt-[0.22rem]"
                                error={dateError}
                            />
                            <Button
                                className="mt-9"
                                onClick={handleScheduleMonthlyReport}
                                variant="primary"
                                loading={isSchedulingMonthlyReport}
                            >
                                Schedule Report
                            </Button>
                            <Button
                                className="mt-9"
                                onClick={handleDeleteScheduledReport}
                                variant="danger"
                                loading={isDeletingScheduledReport}
                                disabled={isDeleteDisabled}
                            >
                                Stop Scheduled Report
                            </Button>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default CompanyMonthlyReportSettings;
