import clsx from "clsx";
import { IconProps } from "phosphor-react";
import React from "react";

export type ButtonProps = {
    type?: JSX.IntrinsicElements["button"]["type"];
    children?: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    loading?: boolean;
    value?: string;
    variant?: "primary" | "secondary" | "tertiary" | "danger";
    icon?: React.ForwardRefExoticComponent<
        IconProps & React.RefAttributes<SVGSVGElement>
    >;
    iconEnd?: boolean;
    className?: string;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            type,
            disabled,
            children,
            onClick,
            loading,
            value,
            variant = "primary",
            icon: Icon,
            iconEnd = false,
            className,
        },
        ref
    ) => {
        const _disabled = disabled || loading;

        return (
            <button
                value={value}
                className={clsx(
                    "base-height flex flex-nowrap items-center rounded-sm px-6 font-semibold transition-all",
                    variant === "primary" &&
                        !_disabled &&
                        "border-2 border-S2D-light-green-80 bg-S2D-light-green-80 text-S2D-text-button hover:brightness-75 focus:outline-none focus:ring-2 focus:ring-black",
                    variant === "primary" &&
                        _disabled &&
                        " cursor-not-allowed bg-S2D-neutral-70 text-S2D-text-button opacity-40",
                    variant === "secondary" &&
                        !_disabled &&
                        "border-2 border-black text-black hover:bg-S2D-surface-light-4 focus:outline-none focus:ring-1 focus:ring-black",
                    variant === "secondary" &&
                        _disabled &&
                        "cursor-not-allowed border-2 text-black opacity-30",
                    variant === "tertiary" &&
                        !_disabled &&
                        "border-2 border-S2D-surface-light-4 border-opacity-0 text-black hover:border-opacity-100 hover:bg-S2D-surface-light-4 focus:outline-none focus:ring-2 focus:ring-black",
                    variant === "tertiary" &&
                        _disabled &&
                        "cursor-not-allowed border-2 border-S2D-surface-light-4 border-opacity-0 text-black opacity-30",
                    variant === "danger" &&
                        !_disabled &&
                        "border-2 border-S2D-error-40 bg-S2D-error-40 text-white hover:border-red-800 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-black",
                    variant === "danger" &&
                        _disabled &&
                        "cursor-not-allowed bg-S2D-error-40 text-white opacity-40",
                    className
                )}
                type={type}
                disabled={_disabled || loading}
                onClick={onClick}
                ref={ref}
            >
                {Icon && !iconEnd && (
                    <div className="mr-2 text-black">
                        <Icon weight="bold" />
                    </div>
                )}
                {loading ? "Loading..." : children}
                {Icon && iconEnd && (
                    <div className="ml-2 text-black">
                        <Icon weight="bold" />
                    </div>
                )}
            </button>
        );
    }
);

export default Button;
