import { capitalisedTermForCompany } from "../../../copy/sharedCopy";
import { type ApiFileTransferItem } from "../../../models/api/admin/fileSafe";
import { formatDateToString } from "../../../utils/formatDateToString";
import StatusPill from "../../ReusableComponents/StatusPill/StatusPill";
import {
    type TableContent,
    type TableHeader,
} from "../../ReusableComponents/Table";
import UserIcon from "../../UserIcon";

type AllowedIds =
    | "to"
    | "transferDate"
    | "fileNumber"
    | "client"
    | "departments"
    | "status";

type FileSafeSentTableContent = TableContent<ApiFileTransferItem, AllowedIds>;

export const initFileSafeSentTableHeaders: TableHeader[] = [
    {
        title: "To",
        id: "to",
    },
    {
        title: "Transfer date",
        id: "transferDate",
    },
    {
        title: "No. of Files",
        id: "fileNumber",
    },
    {
        title: capitalisedTermForCompany,
        id: "client",
    },
    {
        title: "Departments",
        id: "departments",
    },
    {
        title: "Status",
        id: "status",
    },
];

const statusColor = (status: string | null) => {
    if (status === null || status === undefined) return "bg-S2D-neutral-70";

    if (status === "New") {
        return "bg-S2D-success-80";
    } else if (status === "Transferred") {
        return "";
    } else {
        return "bg-S2D-warning-90";
    }
};

export function initFileSafeSentTableContents(
    transfers: ApiFileTransferItem[]
): FileSafeSentTableContent[] {
    const tableContents = new Array<FileSafeSentTableContent>(0);
    transfers.map((transfer) => {
        tableContents.push({
            rowId: transfer.transferId,
            rowData: transfer,
            cellData: [
                {
                    id: "to",
                    content: (
                        <div className="flex items-center gap-2">
                            {transfer.to.firstName && (
                                <UserIcon
                                    firstName={transfer.to.firstName}
                                    lastName={transfer.to.lastName}
                                    size="small"
                                />
                            )}

                            <p>
                                {`${transfer.to.firstName} ${transfer.to.lastName}`}
                            </p>
                        </div>
                    ),
                },
                {
                    id: "transferDate",
                    content: (
                        <p>
                            {transfer.transferDate
                                ? formatDateToString(
                                      new Date(transfer.transferDate)
                                  )
                                : ""}
                        </p>
                    ),
                },
                {
                    id: "fileNumber",
                    content: <p>{transfer.fileCount}</p>,
                },
                {
                    id: "client",
                    content: <p>{transfer.client}</p>,
                },
                {
                    id: "departments",
                    content: (
                        <p>
                            {transfer.departments
                                ?.map((department) => department)
                                .join(", ")}
                        </p>
                    ),
                },
                {
                    id: "status",
                    content: (
                        <div className="pl-2">
                            <StatusPill
                                status={transfer.status}
                                bgColour={statusColor(transfer.status)}
                            />
                        </div>
                    ),
                },
            ],
        });
    });
    return tableContents;
}
