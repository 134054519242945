import { Controller, UseFormReturn } from "react-hook-form";
import { useFormFieldCollectiveValidation } from "../../hooks/useFormFieldCollectiveValidation";
import AccordionItem from "../AccordionItem";
import FormCheckbox from "../FormCheckbox";
import FormRadioInput from "../FormRadioInput";
import { type AddCompanyFormValues } from "./AddCompanyFormValues";
import { clientCopy } from "../../copy/sharedCopy";

type CompanyEDeclarationSettingsFieldsProps = {
    methods: UseFormReturn<AddCompanyFormValues>;
};

const CompanyEDeclarationSettingsFields: React.FC<
    CompanyEDeclarationSettingsFieldsProps
> = ({ methods }) => {
    const isSectionValid =
        useFormFieldCollectiveValidation<AddCompanyFormValues>(
            ["settings.edeclaration"],
            methods
        );

    return (
        <AccordionItem title="E-Declaration settings" error={!isSectionValid}>
            <h3 className="font-bold text-lg mb-2">E-Declaration Form</h3>
            <FormCheckbox
                {...methods.register("settings.edeclaration.useParentContact")}
                id="eDeclaration.parentCompanyForEmailContact"
                error={
                    methods.formState.errors.settings?.edeclaration
                        ?.useParentContact
                }
                label={clientCopy.useParentForEmailLabel}
                inline
            />
            <h4 className="mb-4">Validate driver identity with</h4>
            <div className="flex gap-6 mt-2">
                <Controller
                    name="settings.edeclaration.driverValidationType"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                        <FormRadioInput
                            name="validateBy"
                            value="DateOfBirth"
                            checked={value === "DateOfBirth"}
                            label="Date of birth"
                            onChange={() => onChange("DateOfBirth")}
                        />
                    )}
                />
                <Controller
                    name="settings.edeclaration.driverValidationType"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                        <FormRadioInput
                            name="validateBy"
                            value="EmployeeId"
                            checked={value === "EmployeeId"}
                            label="Employee Id"
                            onChange={() => onChange("EmployeeId")}
                        />
                    )}
                />
            </div>
        </AccordionItem>
    );
};

export default CompanyEDeclarationSettingsFields;
