import React, { useRef, useState } from "react";
import type {
    ApiAllDriversStatsResponse,
    ApiDriversListResponse,
} from "../../../models/api/admin/driver";
import { FilterType } from "../../../models/app/admin/filter";
import useDriversListPaginationLocalStorage from "../../../hooks/useDriversListPaginationLocalStorage";
import PageLayout from "../../../components/PageLayout";
import DriversListFilters from "../../../components/DriverListFilters/DriversListFilters";
import { useNavigate } from "react-router-dom";
import DriverListTable from "../../../components/DriverList/DriverListTable";
import { DriversListFiltersFormValues } from "../../../components/DriverListFilters/DriversListFiltersForm";
import ApiError from "../../../api/common/apiError";
import { type AnalysisComponentContentType } from "../../../components/ReusableComponents/AnalysisComponent";
import { DriverCategory } from "../../../utils/driverPageLocation";
import DriversSummary from "../../../components/DriversSummary/DriversSummary";
import CollapsibleSection from "../../../components/ReusableComponents/CollapsibleSection";
import DriverLicenceCharts from "../../../components/ChartGroups/DriverLicenceCharts";
import useLocalStorage from "../../../hooks/useLocalStorage";
import HGVCoachDriversSummary from "../../../components/DriversSummary/HGVCoachDriversSummary";
import useDownloadDriversReports from "../pageHooks/useDownloadDriversReports";
import { type ApiCompanyResponse } from "../../../models/api/admin/company";
import { type ApiDepartmentItem } from "../../../models/api/admin/department";
import useDriverLicenceCharts from "../../../components/ChartGroups/useDriverLicenceCharts";
import DriversPageReport from "./DriversPageReport";
import { appPaths } from "../../../Routes";
import HeaderOptions, {
    TailoredButton,
} from "../../../components/ReusableComponents/HeaderOptions";
import { driverAnalysisContent } from "./driverAnalysisContent";
import { driverListFiltersDefaultValues } from "../../../components/DriverListFilters/DriversListFiltersForm/driversListFiltersDefaultValues";
import { useScrollToTable } from "../../../hooks/useScrollToTable";
import {
    CompanyDriverAnalysisResponse,
    DriverAnalysisTabs,
} from "../../../models/api/admin/companyDriverAnalysis";
import DriversAnalysis from "../../../components/DriversAnalysis/DriversAnalysis";
import useAuth from "../../../auth";
import { hasClientManagementAccess } from "../../../auth/userAccessHelpers";
import { useGetCompanyDriverAnalysisReport } from "../../../api/admin/companyDriverAnalysisReport";
import CompanyDashboardAllDrivers from "../../../components/CompanyDashboard/CompanyDashboardAllDrivers";
import { Plus } from "phosphor-react";

type DriversPageContentProps = {
    title: string;
    driverPageLocation?: DriverCategory;
    filterModalOpen: false | "add" | "edit";
    setFilterModalOpen: React.Dispatch<
        React.SetStateAction<false | "add" | "edit">
    >;
    driversList?: ApiDriversListResponse;
    fetchDriversError?: ApiError;
    appliedFilter: FilterType;
    setAppliedFilter: React.Dispatch<React.SetStateAction<FilterType>>;
    paginationLocalStorage: ReturnType<
        typeof useDriversListPaginationLocalStorage
    >;
    handleFetchDrivers: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
    isFetchingDrivers: boolean;
    driversStats: ApiAllDriversStatsResponse | undefined;
    isGettingAllDriversStats: boolean;
    handleCompanyChange: (
        company: ApiCompanyResponse | null,
        callback?: (() => void) | undefined
    ) => void;
    handleDepartmentChange: (
        department: ApiDepartmentItem | null,
        callback?: (() => void) | undefined
    ) => void;
    selectedCompany: ApiCompanyResponse | null;
    selectedDepartment: ApiDepartmentItem | null;
    query: string | null;
    setQuery: (value: string | null) => void;
};

export default function DriversPageContent({
    title,
    driverPageLocation,
    filterModalOpen,
    setFilterModalOpen,
    driversList,
    fetchDriversError,
    appliedFilter,
    setAppliedFilter,
    paginationLocalStorage,
    handleFetchDrivers,
    isFetchingDrivers,
    driversStats,
    isGettingAllDriversStats,
    handleCompanyChange,
    handleDepartmentChange,
    selectedCompany,
    selectedDepartment,
    query,
    setQuery,
}: DriversPageContentProps) {
    const [isDriverLicenceAnalysisOpen, setIsDriverLicenceAnalysisOpen] =
        useLocalStorage<boolean>(
            `is${driverPageLocation}LicenceAnalysisOpen`,
            true
        );

    const {
        handleDownloadCsvReport,
        isReportDownloading,
        handleDownloadPdfReport,
        showReportView,
        reportRef,
    } = useDownloadDriversReports(
        driverPageLocation,
        selectedCompany,
        selectedDepartment,
        appliedFilter
    );

    const driverLicenceChartData = useDriverLicenceCharts(
        selectedCompany?.companyId,
        selectedDepartment?.departmentId
    );

    const defaultVals = driverListFiltersDefaultValues(driverPageLocation);

    const clearCompanyAndDepartment = () => {
        handleCompanyChange(null);
        handleDepartmentChange(null);
        setAppliedFilter({
            ...appliedFilter,
            filterFields: {
                ...appliedFilter.filterFields,
                company: defaultVals.company,
            },
        });
        handleFetchDrivers({
            ...appliedFilter.filterFields,
            company: defaultVals.company,
        });
    };

    const navigate = useNavigate();
    const tableRef = useRef<HTMLDivElement | null>(null);

    const [reportData, setReportData] =
        useState<CompanyDriverAnalysisResponse>();
    const [reportDataError, setReportDataError] = useState<ApiError>();

    const { getDriverAnalysisData, isGettingDriverAnalysisData } =
        useGetCompanyDriverAnalysisReport(
            selectedCompany?.companyId,
            selectedDepartment?.departmentId,
            driverPageLocation === "professionalDrivers"
        );

    const getReportDataAndDownloadPdf = async () => {
        try {
            const response = await getDriverAnalysisData();
            if (response.success) {
                setReportData(response.content);
            }
        } catch (error) {
            setReportDataError(error as ApiError);
        }
        handleDownloadPdfReport();
    };

    const reportAnalysisContent: AnalysisComponentContentType<DriverAnalysisTabs>[] =
        driverAnalysisContent(
            reportData,
            reportDataError,
            undefined,
            undefined,
            !!selectedCompany
        );

    const hasLoaded = !!driversList && !isFetchingDrivers;
    useScrollToTable(hasLoaded, tableRef);

    const { decodedToken } = useAuth();
    const clientManagementAccess = hasClientManagementAccess(decodedToken);

    const headerButtons: TailoredButton[] = [
        {
            title: `Add driver`,
            onClick: () => navigate(appPaths.adminAddDriver),
            icon: Plus,
        },
    ];

    return (
        <PageLayout header={false} title={title}>
            <div className="flex flex-col gap-6">
                <HeaderOptions
                    selectedDepartment={selectedDepartment}
                    handleDepartmentChange={handleDepartmentChange}
                    selectedCompany={selectedCompany}
                    handleCompanyChange={handleCompanyChange}
                    clearCompanyDepartment={clearCompanyAndDepartment}
                    handleDownloadCsvReport={handleDownloadCsvReport}
                    handleDownloadPdfReport={getReportDataAndDownloadPdf}
                    isReportDownloading={
                        isReportDownloading || isGettingDriverAnalysisData
                    }
                    isReportDisabled={isGettingAllDriversStats}
                    refetch={handleFetchDrivers}
                    tailoredButtons={headerButtons}
                />
                <DriversAnalysis
                    selectedCompany={selectedCompany}
                    selectedDepartment={selectedDepartment}
                    handleFetchDrivers={handleFetchDrivers}
                    tableRef={tableRef}
                    location={driverPageLocation}
                />
                <CollapsibleSection
                    isOpen={isDriverLicenceAnalysisOpen}
                    setIsOpen={setIsDriverLicenceAnalysisOpen}
                    content={
                        <DriverLicenceCharts data={driverLicenceChartData} />
                    }
                    title="Driver licence analysis"
                />
                {driverPageLocation === "professionalDrivers" && (
                    <HGVCoachDriversSummary
                        stats={driversStats}
                        loading={isGettingAllDriversStats}
                    />
                )}
                {driverPageLocation === "drivers" && (
                    <DriversSummary
                        type={driverPageLocation}
                        stats={driversStats}
                        loading={isGettingAllDriversStats}
                    />
                )}
                {clientManagementAccess && driverPageLocation === "drivers" && (
                    <CompanyDashboardAllDrivers
                        selectedCompany={selectedCompany}
                        selectedDepartment={selectedDepartment}
                        tableRef={tableRef}
                        handleFetchDrivers={handleFetchDrivers}
                    />
                )}
                <DriversListFilters
                    handleFetchDrivers={handleFetchDrivers}
                    filterModalOpen={filterModalOpen}
                    setFilterModalOpen={setFilterModalOpen}
                    appliedFilter={appliedFilter}
                    setAppliedFilter={setAppliedFilter}
                    isFetchingDrivers={isFetchingDrivers}
                    query={query}
                    setQuery={setQuery}
                />
                <DriverListTable
                    isLoading={isFetchingDrivers}
                    error={fetchDriversError}
                    driverList={driversList}
                    handleRefetchDriverList={handleFetchDrivers}
                    setPageNumber={paginationLocalStorage.setPageNumber}
                    pageSize={paginationLocalStorage.pageSize}
                    setPageSize={paginationLocalStorage.setPageSize}
                    sortOrder={
                        paginationLocalStorage.sortOrder as "asc" | "desc"
                    }
                    setSortOrder={paginationLocalStorage.setSortOrder}
                    orderBy={paginationLocalStorage.orderBy}
                    setOrderBy={paginationLocalStorage.setOrderBy}
                    tableRef={tableRef}
                    selectedCompany={selectedCompany ?? undefined}
                />
                {showReportView && (
                    // NB report is never actually intended to be seen by the user, only appended to the bottom of the page
                    <>
                        <div ref={reportRef}>
                            <DriversPageReport
                                companyName={selectedCompany?.companyName}
                                departmentName={
                                    selectedDepartment?.departmentName
                                }
                                analysisContent={reportAnalysisContent}
                                chartData={driverLicenceChartData}
                                driversStats={driversStats}
                                location={driverPageLocation}
                                isGettingAllDriversStats={
                                    isGettingAllDriversStats
                                }
                            />
                        </div>
                    </>
                )}
            </div>
        </PageLayout>
    );
}
