import { createContext, useEffect } from "react";
import { ApiCompanyThemeDto } from "../models/api/admin/themes";
import { useGetTheme } from "../api/admin/themes";
import { handleTheme, removeTheme } from "./handleTheme";
import useAuth from "../auth/useAuth";

export const ThemeContext = createContext<ApiCompanyThemeDto | null>(null);

export type ThemeProviderProps = {
    companyId?: string;
    children: React.ReactNode;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
    companyId,
    children,
}) => {
    const { token } = useAuth();
    const { themeData } = useGetTheme(companyId);

    useEffect(() => {
        if(themeData && token != undefined) handleTheme(themeData);
        else removeTheme();
    }, [themeData, token]);

    return (
        <ThemeContext.Provider value={themeData ?? null}>
            {children}
        </ThemeContext.Provider>
    );
};
