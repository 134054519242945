import { Pencil, Trash } from "phosphor-react";
import UserIcon from "../../../UserIcon";
import { CompanyContact } from "../../../../models/api/admin/company";
import clsx from "clsx";

interface ContactCardProps {
    contact: CompanyContact;
    contactBeingEdited?: string | null;
    toggleEditContact?: (contact: CompanyContact) => void;
    setContactToDelete?: (contactId: CompanyContact) => void;
}

export default function ContactCard({
    contact,
    contactBeingEdited,
    toggleEditContact,
    setContactToDelete,
}: ContactCardProps): JSX.Element {
    const isEditing = !!toggleEditContact;
    const toggleEdit = () => {
        toggleEditContact && toggleEditContact(contact);
    };
    const deleteContact = () => {
        setContactToDelete && setContactToDelete(contact);
    };
    const isBeingEdited = contactBeingEdited === contact.contactId;
    return (
        <div className="flex flex-row items-center justify-between rounded-sm border-[2px] bg-white p-5 pr-10 pb-4 text-sm text-S2D-neutral-30 md:text-base">
            <div className="flex items-center">
                <UserIcon
                    firstName={contact.firstName}
                    lastName={contact.lastName}
                    size="small"
                />
                <div className="flex flex-col">
                    <div>
                        {contact.firstName} {contact.lastName}
                    </div>
                    <div className="flex flex-wrap space-x-4">
                        <div>
                            Telephone: <b>{contact.telephone}</b>
                        </div>
                        <div>
                            Email: <b>{contact.emailAddress}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row items-center space-x-4">
                {isEditing && (
                    <button onClick={toggleEdit}>
                        <Pencil
                            className={clsx(isBeingEdited && "opacity-50")}
                            size={24}
                        />
                    </button>
                )}
                {isEditing && (
                    <button onClick={deleteContact}>
                        <Trash color="black" size={24} />
                    </button>
                )}
            </div>
        </div>
    );
}
