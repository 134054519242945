import { Controller, useForm, useWatch } from "react-hook-form";
import {
    type AddStaffMemberFormValues,
    addStaffMemberDefaultValues,
    addStaffMemberValidationSchema,
} from "./AddStaffMemberFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import UserIcon from "../UserIcon";
import FormTextInput from "../FormTextInput";
import Button from "../Button";
import FormDropdown from "../FormDropdown";
import {
    yesNoOptions,
    staffUserRoles,
} from "../../constants/dropdownOptions";
import { AddStaffMemberDto } from "../../models/app/safe2DriveAdmin/staff";
import useAuth from "../../auth";
import { canControlClaims } from "../../auth/userAccessHelpers";
import { UserRoles } from "../../auth/decodeToken";
import { useEffect } from "react";
import { adminsCanViewPiiMessage } from "../../constants/dvlaConstants";

type AddStaffMemberFormProps = {
    isLoading: boolean;
    onSubmit: (value: AddStaffMemberDto, callback?: () => void) => void;
    onCancel: () => void;
};

const AddStaffMemberForm: React.FC<AddStaffMemberFormProps> = ({
    isLoading,
    onSubmit,
    onCancel,
}) => {
    const { decodedToken } = useAuth();

    const methods = useForm<AddStaffMemberFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(addStaffMemberValidationSchema),
        defaultValues: addStaffMemberDefaultValues,
    });

    const { handleSubmit, register, formState, control, setValue } = methods;

    const handleFormSubmit = (data: AddStaffMemberFormValues) => {
        const { roles } = data;
        onSubmit({ ...data, roles: [roles] });
    };

    const editedUserRole = useWatch({ control, name: "roles" });
    const userIsAdmin = editedUserRole === UserRoles.s2dAdmin;

    useEffect(() => {
        if (userIsAdmin) {
            setValue("claims.hasViewPiiClaim", true);
            setValue("claims.hasFileSafeClaim", true);
        } else {
            setValue("claims.hasFileSafeClaim", false);
        }
    }, [editedUserRole]);

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="mx-auto max-h-[500px] w-full max-w-3xl overflow-y-scroll p-6">
                <div className="flex w-full items-center justify-center p-2  ">
                    <UserIcon firstName="F" lastName="L" size="large" />
                </div>

                <FormTextInput
                    label="First Name"
                    required
                    id="firstName"
                    placeholder="Enter first name"
                    error={formState.errors.firstName}
                    {...register("firstName")}
                />
                <FormTextInput
                    label="Surname"
                    required
                    id="lastName"
                    placeholder="Enter surname"
                    error={formState.errors.lastName}
                    {...register("lastName")}
                />
                <FormTextInput
                    label="Email Address"
                    required
                    id="emailAddress"
                    placeholder="Enter email address"
                    error={formState.errors.emailAddress}
                    {...register("emailAddress")}
                />
                <FormTextInput
                    label="Mobile number"
                    id="mobileNumber"
                    placeholder="Enter mobile number"
                    error={formState.errors.mobileNumber}
                    {...register("mobileNumber")}
                />
                <Controller
                    name="claims.hasFileSafeClaim"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Has access to FileSafe?"
                            options={yesNoOptions}
                            value={value}
                            onChange={onChange}
                            pleaseSelectText="Please select..."
                            error={formState.errors.claims?.hasFileSafeClaim}
                            disabled={!userIsAdmin}
                        />
                    )}
                />
                <Controller
                    name="roles"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Permission"
                            options={staffUserRoles}
                            value={value ?? ""}
                            onChange={onChange}
                            pleaseSelectText="Select permission"
                            error={formState.errors.roles}
                        />
                    )}
                />
                {canControlClaims(decodedToken) && (
                    <Controller
                        name="claims.hasViewPiiClaim"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <FormDropdown
                                label="Can view personally identifiable information?"
                                options={yesNoOptions}
                                value={value}
                                onChange={onChange}
                                pleaseSelectText="Please select..."
                                error={
                                    formState.errors.claims?.["hasViewPiiClaim"]
                                }
                                disabled={userIsAdmin}
                                helpText={
                                    userIsAdmin ? adminsCanViewPiiMessage : ""
                                }
                            />
                        )}
                    />
                )}
                <FormTextInput
                    label="Position"
                    id="position"
                    placeholder="Enter staff position"
                    error={formState.errors.position}
                    {...register("position")}
                />
            </div>
            <div className="flex flex-col-reverse justify-between rounded-b-md border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                <Button
                    variant="tertiary"
                    type="button"
                    onClick={onCancel}
                    className="w-full justify-center md:w-auto"
                >
                    Cancel
                </Button>

                <Button
                    type="submit"
                    loading={isLoading}
                    className="w-full justify-center md:w-auto"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default AddStaffMemberForm;
