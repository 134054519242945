import { DocumentBase64 } from "../models/app/admin/document";

export const validDocumentFileExtensions: Array<DocumentBase64["fileType"]> = [
    "tiff",
    "tif",
    "svg",
    "jpg",
    "jpeg",
    "ico",
    "gif",
    "webp",
    "png",
    "bmp",
    "avif",
    "pdf",
    "txt",
    "docx",
    "doc",
];
