import { useForm } from "react-hook-form";
import FormTextInput from "../FormTextInput";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../Button";
import FormMessageBox from "../FormMessageBox";
import { useState } from "react";
import { appPaths } from "../../Routes";
import { Link, useNavigate } from "react-router-dom";

type ForgottenPasswordFormProps = {
    onSubmit: (
        data: ForgottenPasswordFormValues,
        callback: (error: string) => void
    ) => Promise<void>;
    isLoading: boolean;
};

export type ForgottenPasswordFormValues = {
    userEmail: string;
};

const validationSchema = Yup.object().shape({
    userEmail: Yup.string().email().required("Please enter an email address"),
});

const ForgottenPasswordForm: React.FC<ForgottenPasswordFormProps> = ({
    onSubmit,
    isLoading,
}) => {
    const [message, setMessage] = useState<string>();
    const { handleSubmit, formState, register, getValues } =
        useForm<ForgottenPasswordFormValues>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues: {
                userEmail: "",
            },
        });

    const handleForgotPassword = (data: ForgottenPasswordFormValues) => {
        onSubmit(data, setMessage);
    };

    const userEmail = getValues("userEmail");

    const navigate = useNavigate();

    if (message?.toLowerCase() === "success") {
        return (
            <div className="w-full max-w-lg rounded-sm bg-white p-8">
                <p className="pb-4 font-semibold">FORGOTTEN PASSWORD</p>
                <FormMessageBox
                    variant="success"
                    message={`An email has been sent to ${userEmail}`}
                    title="Success"
                />
                <div className="flex justify-end">
                    <Button
                        type="button"
                        onClick={() => navigate(appPaths.login)}
                    >
                        Back to log in page
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full max-w-lg rounded-sm bg-white p-8">
            <form onSubmit={handleSubmit(handleForgotPassword)}>
                <p className="pb-4 font-semibold">FORGOTTEN PASSWORD</p>
                {message?.toLowerCase() === "error" && (
                    <FormMessageBox
                        variant="error"
                        message={"This email does not match an account"}
                        title="Error"
                    />
                )}
                <FormTextInput
                    label="Email address"
                    required
                    id="userEmail"
                    placeholder="Enter email address"
                    error={formState.errors.userEmail}
                    {...register("userEmail")}
                />
                <div className="mt-8 flex justify-end gap-4">
                    <Link to={appPaths.login}>
                        <Button type="button" variant="tertiary">
                            Cancel
                        </Button>
                    </Link>
                    <Button type="submit" loading={isLoading}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ForgottenPasswordForm;
