import { IconProps } from "phosphor-react";

interface StatusPillProps {
    status: string | null;
    textColour?: string;
    bgColour?: string;
    icon?: React.ForwardRefExoticComponent<
        IconProps & React.RefAttributes<SVGSVGElement>
    >;
}

export default function StatusPill({
    status,
    textColour,
    bgColour = "bg-gray-200",
    icon: Icon,
}: StatusPillProps) {
    const baseClasses =
        "flex w-fit justify-center h-7 whitespace-nowrap items-center px-2 rounded-md gap-2";
    const combinedClasses = `${baseClasses} ${textColour} ${bgColour}`;
    return (
        <div className={combinedClasses}>
            {status ?? "-"}
            {Icon && <Icon weight={"bold"} />}
        </div>
    );
}
