import { useMemo, useRef, useState } from "react";
import {
    useTrackDvlaChecks,
    useTrackDvlaChecksReport,
} from "../../api/safe2Drive/dvlaChecks";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import { ApiDepartmentItem } from "../../models/api/admin/department";
import {
    DvlaAnalysisCategories,
    DvlaAnalysisTabs,
    DvlaDriverAnalysisResponse,
} from "../../models/api/admin/dvlaAnalysis";
import { useDownloadComponentToPdf } from "../../hooks/useDownloadComponentToPdf";
import ApiError from "../../api/common/apiError";

export const useDvlaChecksAnalysis = (
    selectedCompany?: ApiCompanyResponse | null,
    selectedDepartment?: ApiDepartmentItem | null
) => {
    const [tabOption, setTabOption] = useLocalStorage<number>(
        `${location}AnalysisSelectedTab`,
        0
    );

    type Option = keyof typeof DvlaAnalysisTabs;
    const tabOptionKeys: Option[] = Object.keys(DvlaAnalysisTabs) as Array<
        keyof typeof DvlaAnalysisTabs
    >;

    const apiTabCategory: DvlaAnalysisCategories = useMemo(() => {
        const optionKey = tabOptionKeys[tabOption];
        return DvlaAnalysisCategories[
            optionKey as keyof typeof DvlaAnalysisCategories
        ];
    }, [tabOption]);

    const handleTabSelect = (value: DvlaAnalysisTabs) => {
        const optionKey =
            Object.keys(DvlaAnalysisTabs)[
                Object.values(DvlaAnalysisTabs).indexOf(value)
            ];
        const indexOfKey = tabOptionKeys.indexOf(optionKey as Option);
        setTabOption(indexOfKey);
    };

    const {
        trackChecksData,
        trackChecksError,
        isLoadingTrackChecks,
        refetchTrackChecks,
    } = useTrackDvlaChecks(
        selectedCompany?.companyId,
        selectedDepartment?.departmentId,
        // next check date options?
        undefined,
        apiTabCategory
    );

    // PDF report
    const { getTrackChecksData, isGettingTrackChecksData } =
        useTrackDvlaChecksReport(
            selectedCompany?.companyId,
            selectedDepartment?.departmentId
        );
    const [reportData, setReportData] = useState<DvlaDriverAnalysisResponse>();
    const [reportDataError, setReportDataError] = useState<ApiError>();

    const getReportDataAndDownloadPdf = async () => {
        try {
            const response = await getTrackChecksData();
            if (response.success) {
                setReportData(response.content);
            }
        } catch (error) {
            setReportDataError(error as ApiError);
        }
        handleDownloadPdfReport();
    };

    const reportRef = useRef<HTMLDivElement | null>(null);
    const { generatePDF } = useDownloadComponentToPdf(reportRef);
    const [showReportView, setShowReportView] = useState(false);
    const [isLoadingPdfReport, setIsLoadingPdfReport] = useState(false);

    const handleDownloadPdfReport = (): void => {
        setIsLoadingPdfReport(true);
        setShowReportView(true);

        const fileNameArray = [
            selectedCompany?.companyName ?? "All clients",
            selectedDepartment?.departmentName ?? "All departments",
            "DVLA report",
        ];
        generatePDF(() => {
            setShowReportView(false);
            setIsLoadingPdfReport(false);
        }, fileNameArray.join("-"));
    };

    const refetchDvlaAnalysisData = () => {
        refetchTrackChecks();
    };

    return {
        trackChecksData,
        trackChecksError,
        isLoadingTrackChecks,
        tabOption,
        handleTabSelect,
        reportRef,
        showReportView,
        handleDownloadPdfReport: getReportDataAndDownloadPdf,
        isLoadingPdfReport,
        dvlaPdfAnalysisData: reportData,
        dvlaPdfAnalysisError: reportDataError,
        isLoadingDvlaPdfAnalysisData: isGettingTrackChecksData,
        refetchDvlaAnalysisData,
    };
};
