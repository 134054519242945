import PdfReport from "../ReusableComponents/PdfReport";
import ReportAnalysisContainer from "../ReusableComponents/PdfReport/ReportAnalysisContainer";
import { dvlaChecksAnalysisContent } from "../DvlaChecksAnalysis/dvlaChecksAnalysisContent";
import { type DvlaDriverAnalysisResponse } from "../../models/api/admin/dvlaAnalysis";

type DvlaChecksPdfReportProps = {
    analysisData?: DvlaDriverAnalysisResponse;
    companyName?: string;
    departmentName?: string;
};
export default function DvlaChecksPdfReport({
    analysisData,
    companyName,
    departmentName,
}: DvlaChecksPdfReportProps) {
    const analysisContent = dvlaChecksAnalysisContent(analysisData, undefined);

    return (
        <PdfReport companyName={companyName} departmentName={departmentName}>
            <div className="flex flex-col">
                {analysisContent &&
                    analysisContent.map(({ title, content }) => {
                        return (
                            <div key={title}>
                                <ReportAnalysisContainer title={title}>
                                    {content}
                                </ReportAnalysisContainer>
                            </div>
                        );
                    })}
            </div>
        </PdfReport>
    );
}
