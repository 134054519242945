import { useState } from "react";
import { ApiBulkActionResponse } from "../../models/api/safe2DriveAdmin/dvlaBulkActions";
import { useS2DApiFetcher } from "../common/fetching";

export enum BulkActionTypes {
    InstantCheck = "instant-check",
    RetryCheck = "retry-check",
    EditCheckDate = "edit-next-check-date",
    DisableFurtherChecks = "disable-further-checks",
    MarkAsChecked = "mark-as-checked",
    MarkAsPending = "mark-as-pending",
    ChangeToManual = "change-to-manual",
}

// handles all bulk actions other than download forms and edit check date
const useBulkAction = () =>
    useS2DApiFetcher<
        string[],
        ApiBulkActionResponse,
        { type: BulkActionTypes; driverIds: string[] }
    >(
        "GET",
        ({ type, driverIds }) => ({
            url:
                `/api/dvla/bulk-actions/${type}` +
                `?DriverIds=${driverIds.join("&DriverIds=")}`,
        }),
        [],
        true
    );

export type EditNextCheckDateQueryParams = {
    driverIds: string[];
    newNextCheckDate: Date;
};
const useEditNextCheckDate = () =>
    useS2DApiFetcher<
        EditNextCheckDateQueryParams,
        ApiBulkActionResponse,
        { queryParams: EditNextCheckDateQueryParams }
    >(
        "GET",
        ({ queryParams }) => ({
            url:
                `/api/dvla/bulk-actions/edit-next-check-date` +
                `?DriverIds=${queryParams.driverIds.join("&DriverIds=")}` +
                `&NewNextCheckDate=${queryParams.newNextCheckDate.toISOString()}`,
        }),
        [],
        true
    );

export enum BulkActionFormTypes {
    D906 = "download-d906-forms",
    DVA = "download-dva-forms",
}

const useDownloadDeclarationForms = () =>
    useS2DApiFetcher<
        string[],
        ArrayBuffer,
        { type: BulkActionFormTypes; driverIds: string[] }
    >(
        "GET",
        ({ type, driverIds }) => ({
            url:
                `/api/dvla/bulk-actions/${type}` +
                `?DriverIds=${driverIds.join("&DriverIds=")}`,
        }),
        [],
        true
    );

export const useDvlaBulkActions = () => {
    const [triggerBulkAction, isTriggeringBulkAction] = useBulkAction();
    const [isEditNextCheckFilterOpen, setIsEditNextCheckFilterOpen] =
        useState(false);
    const [editNextCheckDate, isEditingNextCheckDate] = useEditNextCheckDate();
    const [downloadDeclarationForms, isDownloadingDeclarationForms] =
        useDownloadDeclarationForms();

    return {
        triggerBulkAction: async (type: BulkActionTypes, driverIds: string[]) =>
            await triggerBulkAction({ type, driverIds }),
        isTriggeringBulkAction,
        isEditNextCheckFilterOpen,
        setIsEditNextCheckFilterOpen,
        editNextCheckDate: async (queryParams: EditNextCheckDateQueryParams) =>
            await editNextCheckDate({ queryParams }),
        isEditingNextCheckDate,
        downloadDeclarationForms: async (
            type: BulkActionFormTypes,
            driverIds: string[]
        ) => await downloadDeclarationForms({ type, driverIds }),
        isDownloadingDeclarationForms,
    };
};
