import * as yup from "yup";
import {
    CheckFrequencySchema,
    CheckPricingSchema,
    CorrespondenceSchema,
    EdeclarationSettingsSchema,
    RiskCategoriesSchema,
    PrivacySettingsSchema
} from "../../schemas/companySchemas";
import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import { defaultCheckFrequencies } from "../CompanyProfile/CompanyCheckSettingsTab/CheckSettingsSubForms/EditCheckFrequenciesForm/useEditCheckFrequenciesForm";
import { defaultRiskCategories } from "../CompanyProfile/CompanyCheckSettingsTab/CheckSettingsSubForms/EditRiskCategoriesForm/useEditRiskCategoriesForm";
import {
    AddCompanyDto,
    DriverValidationTypes,
} from "../../models/app/admin/company";

export const AddCompanyValidationSchemaFields = {
    companyName: yup
        .string()
        .required(`${capitalisedTermForCompany} name is required.`),
    resellerId: yup.string().notRequired(),
    riskCategory: RiskCategoriesSchema,
    checkFrequency: CheckFrequencySchema,
    settings: yup.object().shape({
        pricing: CheckPricingSchema,
        correspondence: CorrespondenceSchema,
        edeclaration: EdeclarationSettingsSchema,
        privacySettings: PrivacySettingsSchema,
    }),
};

export type AddCompanyFormValues = Omit<AddCompanyDto, "companyRiskProfile"> & {
    // keeping these risk profile values as objects for the form
    // instead of the structure of "companyRiskProfiles" from AddCompanyDto
    // to keep the yup validation for the lower thresholds simple.
    riskCategory: {
        range1Name: string;
        range2Name: string;
        range3Name: string;
        range1: number;
        range2: number;
        range3: number;
    };
    checkFrequency: {
        range1: number;
        range2: number;
        range3: number;
    };
};

export const AddCompanyFormDefaultValues = {
    companyName: "",
    resellerId: "",
    riskCategory: {
        range1Name: defaultRiskCategories.range1Name,
        range1: defaultRiskCategories.range1,
        range2Name: defaultRiskCategories.range2Name,
        range2: defaultRiskCategories.range2,
        range3Name: defaultRiskCategories.range3Name,
        range3: defaultRiskCategories.range3,
    },
    checkFrequency: {
        range1: defaultCheckFrequencies.range1,
        range2: defaultCheckFrequencies.range2,
        range3: defaultCheckFrequencies.range3,
    },
    settings: {
        pricing: {
            licenceCheckPrice: null,
            manualCheckPrice: null,
            irishLicenceCheckPrice: null,
            foreignLicenceCheckPrice: null,
            greyFleetCheckPrice: null,
        },
        correspondence: {
            permitsEmailCorrespondence: true,
            permitsTelephoneCorrespondence: false,
            permitsSmsCorrespondence: false,
            permitsPostalCorrespondence: false,
        },
        edeclaration: {
            useParentContact: false,
            driverValidationType: DriverValidationTypes.DateOfBirth,
        },
        privacySettings: {
            isDataHidden: true
        }
    },
};
