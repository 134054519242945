import jwt_decode from "jwt-decode";

export const decodeToken = (token?: string) => {
    if (!token) return undefined;
    const decoded: DecodedToken = jwt_decode(token);
    return simplifyDecodedToken(decoded);
};

function simplifyDecodedToken(
    decodedToken: DecodedToken
): SimplifiedDecodedToken {
    const userRole =
        decodedToken[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
    const fileSafeClaimValue = decodedToken["file-safe"];

    // TODO 12/05/23 this logic should default to false once all users have role and 'file-safe' property?
    const areClaimsValid =
        fileSafeClaimValue && userRole
            ? checkIfRoleSupportsFileSafeClaimValue(
                  userRole,
                  fileSafeClaimValue
              )
            : true;
    return {
        nameIdentifier:
            decodedToken[
                "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
            ],
        name: decodedToken[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
        ],
        emailAddress:
            decodedToken[
                "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
            ],
        companyId: decodedToken["https://safe2drive.co.uk/claims/CompanyId"],
        role: decodedToken[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ],
        fileSafe: decodedToken["file-safe"],
        viewPii: decodedToken["view-pii"],
        clientManagement: decodedToken["client-management"],
        nbf: decodedToken.nbf,
        exp: decodedToken.exp,
        iat: decodedToken.iat,
        iss: decodedToken.iss,
        aud: decodedToken.aud,
        validClaims: areClaimsValid,
    };
}

function checkIfRoleSupportsFileSafeClaimValue(
    role: UserRoles,
    claimValue: FileSafeClaimValues
): boolean {
    switch (role) {
        case UserRoles.s2dAdmin:
        case UserRoles.s2dMember:
            if (claimValue === "safe2drive") return true;
            return false;
        case UserRoles.clientAdmin:
        case UserRoles.clientMember:
            if (claimValue === "client") return true;
            return false;
        case UserRoles.driver:
            return false;
        default:
            return false;
    }
}

export type SimplifiedDecodedToken = {
    nameIdentifier?: string;
    name?: string;
    emailAddress?: string;
    companyId?: string;
    role?: UserRoles;
    fileSafe?: FileSafeClaimValues;
    viewPii?: PiiValues;
    clientManagement?: ClientManagementClaimValues;
    validClaims?: boolean;
} & Partial<Pick<DecodedToken, "nbf" | "exp" | "iat" | "iss" | "aud">>;

export enum UserRoles {
    s2dAdmin = "safe2drive-administrator-role", // highest level of access
    s2dMember = "safe2drive-member-role",
    clientAdmin = "client-administrator-role",
    clientMember = "client-member-role",
    driver = "driver-role", // only allowed to log in on mobile app
}

export type FileSafeClaimValues = "safe2drive" | "client";
export type ClientManagementClaimValues = "client" | "safe2drive";
export type PiiValues = "client" | "safe2drive";

export type DecodedToken = {
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier": string;
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
    "https://safe2drive.co.uk/claims/CompanyId": string;
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": UserRoles;
    "file-safe"?: FileSafeClaimValues;
    "view-pii"?: PiiValues;
    "client-management": ClientManagementClaimValues;
    nbf: number;
    exp: number;
    iat: number;
    iss: string;
    aud: string;
};
