import { type ApiCompanyUserResponse } from "../../../models/api/admin/companyUser";
import { capitaliseWord } from "../../../utils/capitaliseWord";
import { formatDateToString } from "../../../utils/formatDateToString";
import { userStatusColor } from "../../../utils/userStatusColour";
import StatusPill from "../../ReusableComponents/StatusPill/StatusPill";
import {
    type TableContent,
    type TableHeader,
} from "../../ReusableComponents/Table";
import UserIcon from "../../UserIcon";

type AllowedIds =
    | "name"
    | "email"
    | "lastLogin"
    | "permission"
    | "departments"
    | "status";

type CompanyUsersTableContents = TableContent<
    ApiCompanyUserResponse,
    AllowedIds
>;

export const initCompanyUsersTableHeaders: TableHeader[] = [
    {
        title: "Name",
        id: "name",
    },
    {
        title: "Email",
        id: "email",
    },
    {
        title: "Last login",
        id: "lastLogin",
    },
    {
        title: "Permission",
        id: "permission",
    },
    {
        title: "Departments",
        id: "departments",
    },
    {
        title: "Status",
        id: "status",
    },
];

export function initCompanyUsersTableContents(
    users: ApiCompanyUserResponse[]
): CompanyUsersTableContents[] {
    const tableContents = new Array<CompanyUsersTableContents>(0);
    users.map((user) => {
        tableContents.push({
            rowId: user.userId,
            rowData: user,
            cellData: [
                {
                    id: "name",
                    content: (
                        <div className="flex items-center gap-2">
                            {user.firstName && (
                                <UserIcon
                                    firstName={user.firstName}
                                    lastName={user.lastName}
                                    size="small"
                                />
                            )}

                            <p>{`${user.firstName} ${user.lastName}`}</p>
                        </div>
                    ),
                },
                {
                    id: "email",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {user.emailAddress}
                        </p>
                    ),
                },
                {
                    id: "lastLogin",
                    content: (
                        <>
                            {user.lastLoginDate ? (
                                <p>
                                    {formatDateToString(
                                        new Date(user.lastLoginDate),
                                        true
                                    )}
                                </p>
                            ) : (
                                <></>
                            )}
                        </>
                    ),
                },
                {
                    id: "permission",
                    content: (
                        <div className="flex flex-col gap-2">
                            {user.roleNames.map((role) => (
                                <p key={role}>{role}</p>
                            ))}
                        </div>
                    ),
                },
                {
                    id: "departments",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {user.departments
                                ?.map((department) => department.name)
                                .join(", ")}
                        </p>
                    ),
                },
                {
                    id: "status",
                    content: (
                        <div className="pl-2">
                            <StatusPill
                                status={capitaliseWord(user.status)}
                                bgColour={userStatusColor(
                                    capitaliseWord(user.status)
                                )}
                            />
                        </div>
                    ),
                },
            ],
        });
    });
    return tableContents;
}
