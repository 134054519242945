import BarChart, { BarChartItem } from "../ReusableComponents/Charts/BarChart";
import PieChart, { PieChartItem } from "../ReusableComponents/Charts/PieChart";
import StackedBarChart, {
    StackedBarChartItem,
} from "../ReusableComponents/Charts/StackedBarChart";
import useDriverLicenceCharts from "./useDriverLicenceCharts";
import LoadingMessage from "../ReusableComponents/LoadingMessage/LoadingMessage";
import ChartContainer from "../ReusableComponents/Charts/ChartContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { appPaths } from "../../Routes";
import { AnalysisChartType } from "../../models/app/admin/analysisChart";

type DriverLicenceChartsProps = {
    data: ReturnType<typeof useDriverLicenceCharts> | undefined;
};
const DriverLicenceCharts = ({ data }: DriverLicenceChartsProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleClick = (chartType: AnalysisChartType) => {
        if (location.pathname.includes("professional")) {
            navigate(
                appPaths.adminAnalysisCharts("professional-drivers", chartType)
            );
        } else {
            navigate(appPaths.adminAnalysisCharts("drivers", chartType));
        }
    };

    const hasNoData = (
        data: BarChartItem[] | PieChartItem[] | StackedBarChartItem[]
    ) => {
        for (const item of data) {
            if (item.value !== 0) {
                return false;
            }
        }
        return true;
    };

    return (
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            {!data && <LoadingMessage />}

            {data && (
                <>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.DriversByPoints)
                        }
                        title={"Drivers by points"}
                        hasNoData={hasNoData(data.driversByPoints)}
                    >
                        <BarChart data={data.driversByPoints} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.DriversByAgeRange)
                        }
                        title={"Drivers by age range"}
                        hasNoData={hasNoData(data.driversByAgeRange)}
                    >
                        <PieChart data={data.driversByAgeRange} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.EndorsementCodes)
                        }
                        title={"Endorsement Codes"}
                        hasNoData={hasNoData(data.endorsementCodes)}
                    >
                        <BarChart data={data.endorsementCodes} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.DriversByLicenceType)
                        }
                        title={"Licence types"}
                        hasNoData={hasNoData(data.driversByLicenceType)}
                    >
                        <PieChart data={data.driversByLicenceType} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.DriverRestrictions)
                        }
                        title={"Driver restrictions"}
                        hasNoData={hasNoData(data.driverRestrictions)}
                    >
                        <PieChart data={data.driverRestrictions} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(
                                AnalysisChartType.DriversByLicenceCategory
                            )
                        }
                        title={"Drivers by licence category"}
                        hasNoData={hasNoData(data.driversByLicenceCategory)}
                    >
                        <StackedBarChart
                            data={data.driversByLicenceCategory}
                            topDataKey="full"
                            bottomDataKey="provisional"
                        />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(
                                AnalysisChartType.DriversByLicenceOrigin
                            )
                        }
                        title={"Drivers by licence origin"}
                        hasNoData={hasNoData(data.driversByLicenceOrigin)}
                    >
                        <PieChart data={data.driversByLicenceOrigin} />
                    </ChartContainer>
                    <ChartContainer
                        onLinkClick={() =>
                            handleClick(AnalysisChartType.YearsFullLicenceHeld)
                        }
                        title={"Years full licence held"}
                        hasNoData={hasNoData(data.yearsFullLicenceHeld)}
                    >
                        <BarChart data={data.yearsFullLicenceHeld} />
                    </ChartContainer>
                </>
            )}
        </div>
    );
};

export default DriverLicenceCharts;
