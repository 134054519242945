import { CheckStatus } from "../../../../models/shared/statuses";
import { StatusAction } from "../../../ActionableStatus/ActionableStatus";
import { LicenceChecksActions } from "../licenceCheckActions";
import LicenceCheckStatus from "../LicenceCheckStatus";

type InProgressLicenceCheckStatusProps = {
    driverId: string;
    isManual: boolean;
    lastCheckDate: string;
    nextCheckDate: string;
    checkStatus: CheckStatus;
    buildActions: (actionNames: LicenceChecksActions[]) => StatusAction[];
};

const InProgressLicenceCheckStatus = ({
    driverId,
    isManual,
    lastCheckDate,
    nextCheckDate,
    checkStatus,
    buildActions,
}: InProgressLicenceCheckStatusProps) => {
    const actions = buildActions(
        isManual
            ? [
                  "editCheckDate",
                  "markAsChecked",
                  "markAsFailed",
                  "disableFurtherChecks",
                  "changeToAutomatic",
              ]
            : ["stopCheck"]
    );

    return (
        <LicenceCheckStatus
            actions={actions}
            theme="pending"
            text="Pending"
            driverId={driverId}
            isManual={isManual}
        >
            <span>Last check: {lastCheckDate}</span>
            <span>
                {checkStatus === CheckStatus.Disabled
                    ? "Further checks disabled"
                    : `Next check: ${nextCheckDate}`}
            </span>
        </LicenceCheckStatus>
    );
};

export default InProgressLicenceCheckStatus;
