import { useRef, useState } from "react";
import { useGetCompanies } from "../../../api/admin/company";
import { useGreyFleetAlerts } from "../../../api/admin/greyFleet";
import { initGreyFleetTableHeaders } from "../../../components/AdminGreyFleetPage/GreyFleetTableInit";
import { TableHeader } from "../../../components/ReusableComponents/Table";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useDownloadComponentToPdf } from "../../../hooks/useDownloadComponentToPdf";
import useDriversPage from "./useDriversPage";
import { errorToast, successToast } from "../../../toast";
import { downloadBlobAsFile } from "../../../utils/downloadBlobAsFile";
import { useDownloadGreyFleet } from "../../../api/greyFleet";
import { GreyFleetReportTypes } from "../../../models/api/reporting";

const useAdminGreyFleet = () => {
    const {
        filterModalOpen,
        setFilterModalOpen,
        driversList,
        fetchDriversError,
        appliedFilter,
        setAppliedFilter,
        paginationLocalStorage,
        handleFetchDrivers,
        isFetchingDrivers,
        handleCompanyChange,
        handleDepartmentChange,
        constructAllDriversBodyForReport,
        selectedCompany,
        selectedDepartment,
        query,
        setQuery,
    } = useDriversPage(location.pathname);
    const [isReportDownloading, setIsReportDownloading] = useState(false);

    const { pageNumber, setPageNumber, pageSize, setPageSize } =
        paginationLocalStorage;

    const {
        data: greyFleetAlertsData,
        isLoading: isLoadingGreyFleetAlertsData,
        error: greyFleetAlertsError,
    } = useGreyFleetAlerts(
        selectedCompany?.companyId,
        selectedDepartment?.departmentId
    );

    const [tableHeaders, setTableHeaders] = useLocalStorage<TableHeader[]>(
        "GreyFleetTableHeaders",
        initGreyFleetTableHeaders
    );
    const [isVehicleAnalysisOpen, setIsVehicleAnalysisOpen] =
        useLocalStorage<boolean>("isGreyFleetVehicleAnalysisOpen", true);
    const [isGreyFleetDriversOpen, setIsGreyFleetDriversOpen] =
        useLocalStorage<boolean>("isGreyFleetDriversOpen", true);

    const { companyList } = useGetCompanies(1, 100);
    const companyOptions = new Array(0);
    companyList?.items.map((company) =>
        companyOptions.push({
            label: company.companyName,
            value: company.companyId,
        })
    );

    const handleResetCompanyAndDepartment = () => {
        handleCompanyChange(null);
    };

    // PDF report:
    const reportRef = useRef<HTMLDivElement | null>(null);
    const { generatePDF } = useDownloadComponentToPdf(reportRef);
    const [showReportView, setShowReportView] = useState(false);

    const handleDownloadPdfReport = (): void => {
        setShowReportView(true);

        const fileNameArray = [
            selectedCompany?.companyName ?? "All clients",
            selectedDepartment?.departmentName ?? "All departments",
            "Grey Fleet report",
        ];
        generatePDF(() => setShowReportView(false), fileNameArray.join("-"));
    };

    const {
        getGreyFleetReport
    } = useDownloadGreyFleet();

    const handleDownloadReport = async (reportFormatType: GreyFleetReportTypes) => {
        setIsReportDownloading(true);
    
        const fileExtension = reportFormatType === GreyFleetReportTypes.XLS ? "xlsx" : reportFormatType.toLowerCase();
        const fileNameArray = [`GreyFleetReport.${fileExtension}`];
        if (selectedDepartment)
            fileNameArray.unshift(selectedDepartment.departmentName);
        if (selectedCompany) fileNameArray.unshift(selectedCompany.companyName);
        const fileName = fileNameArray.join("-");
    
        try {
            const getAllDriversBody = constructAllDriversBodyForReport(appliedFilter.filterFields, reportFormatType, selectedCompany?.companyId, selectedDepartment?.departmentId);
            const response = await getGreyFleetReport(getAllDriversBody);
            if (response.success) {
                downloadBlobAsFile(response.content, fileName);
                successToast(`Successfully downloaded Grey Fleet report`);
                setTimeout(() => setIsReportDownloading(false), 1000);
            }
        } catch (error) {
            setTimeout(() => setIsReportDownloading(false), 1000);
            errorToast(`Failed to download Grey Fleet report`);
        }
    };

    return {
        greyFleetAlertsData,
        isLoadingGreyFleetAlertsData,
        greyFleetAlertsError,
        greyFleetDriversList: driversList,
        greyFleetdriversListError: fetchDriversError,
        isGettingGreyFleetDriversList: isFetchingDrivers,
        handleFetchDrivers,
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
        tableHeaders,
        setTableHeaders,
        isVehicleAnalysisOpen,
        setIsVehicleAnalysisOpen,
        isGreyFleetDriversOpen,
        setIsGreyFleetDriversOpen,
        selectedCompany,
        handleCompanyChange,
        selectedDepartment,
        handleDepartmentChange,
        handleResetCompanyAndDepartment,
        handleDownloadPdfReport,
        showReportView,
        reportRef,
        filterModalOpen,
        setFilterModalOpen,
        appliedFilter,
        setAppliedFilter,
        query,
        setQuery,
        isReportDownloading,
        handleDownloadReport,
    };
};

export default useAdminGreyFleet;
