import { Dispatch, SetStateAction } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useGetDepartments } from "../../api/admin/department";
import Button from "../Button";
import EditableContentBlock from "../EditableContentBlock";
import FormDropdown from "../FormDropdown";
import FormTextInput from "../FormTextInput";
import {
    EditDriverFormValues,
    driverEmploymentValidationSchema,
} from "./EditDriverFormValues";
import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import * as yup from "yup";
import { resolverDebugger } from "../../utils/yupResolverDebugger";
import { useStaleDataFormReset } from "../../hooks/useStaleDataFormReset";

type EditDriverEmploymentFormProps = {
    initialValues: EditDriverFormValues;
    isLoading: boolean;
    onSubmit: (data: EditDriverFormValues, callback: () => void) => void;
};

const validationSchema = yup.object().shape(driverEmploymentValidationSchema);

const EditDriverEmploymentForm: React.FC<EditDriverEmploymentFormProps> = ({
    initialValues,
    isLoading,
    onSubmit,
}) => {
    const methods = useForm<EditDriverFormValues>({
        mode: "onSubmit",
        resolver: (data, context, options) =>
            resolverDebugger(data, context, options, validationSchema),
        defaultValues: initialValues,
    });

    const companyId = methods.getValues("company.companyId");
    const { departmentsData, departmentsError, isLoadingDepartments } =
        useGetDepartments(companyId ?? undefined, 1, 100);

    const departmentsList = departmentsData?.items;

    const departmentOptions = departmentsList?.map((department) => ({
        label: department.departmentName,
        value: department.departmentId,
    }));

    const handleDepartmentChange = (
        value: string,
        option: {
            label: string;
            value: string;
        }
    ) => {
        const department = departmentsList?.find(
            (d) => d.departmentId === value
        );
        if (department) {
            methods.setValue("department.departmentId", value);
            methods.setValue(
                "department.departmentName",
                department.departmentName
            );
            methods.setValue("department.costCentre", department.costCentre);
        }
    };

    const handleFormSubmit = async (
        values: EditDriverFormValues,
        setIsEdit: Dispatch<SetStateAction<boolean>>
    ) => {
        onSubmit(values, () => setIsEdit(false));
    };

    const control = methods.control;
    const currentForm = useWatch({ control });

    useStaleDataFormReset(control, methods.reset, initialValues);

    return (
        <EditableContentBlock title="Employment">
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            <p>
                                <b>{capitalisedTermForCompany}:</b>{" "}
                                {initialValues.company?.companyName}
                            </p>
                            <p>
                                <b>Department:</b>{" "}
                                {initialValues.department?.departmentName}
                            </p>
                            <p>
                                <b>Job role:</b> {initialValues.jobRole}
                            </p>
                            <p>
                                <b>Employee number:</b>{" "}
                                {initialValues.employeeId}
                            </p>
                            <p>
                                <b>External reference:</b>{" "}
                                {initialValues.externalReference}
                            </p>
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <form
                                onSubmit={methods.handleSubmit((values) =>
                                    handleFormSubmit(values, setIsEdit)
                                )}
                            >
                                <div className="w-full">
                                    {isLoadingDepartments ||
                                    departmentsError ? (
                                        <p>...loading departments</p>
                                    ) : (
                                        <Controller
                                            name="department.departmentId"
                                            control={methods.control}
                                            render={({ field: { value } }) => (
                                                <FormDropdown
                                                    label="Department"
                                                    options={
                                                        departmentOptions ?? []
                                                    }
                                                    value={value ?? ""}
                                                    onChange={
                                                        handleDepartmentChange
                                                    }
                                                    pleaseSelectText="Select department"
                                                    error={
                                                        methods.formState.errors
                                                            .department
                                                            ?.departmentId
                                                    }
                                                    noOptionsText="No departments available..."
                                                />
                                            )}
                                        />
                                    )}
                                    <FormTextInput
                                        label="Job role"
                                        id="jobRole"
                                        placeholder="Enter job role"
                                        error={methods.formState.errors.jobRole}
                                        {...methods.register("jobRole")}
                                    />
                                    <FormTextInput
                                        label="Employee number"
                                        id="employeeId"
                                        placeholder="Enter employee number"
                                        error={
                                            methods.formState.errors.employeeId
                                        }
                                        {...methods.register("employeeId")}
                                    />
                                    <FormTextInput
                                        label="External reference"
                                        id="externalReference"
                                        placeholder="Enter external reference"
                                        error={
                                            methods.formState.errors
                                                .externalReference
                                        }
                                        {...methods.register(
                                            "externalReference"
                                        )}
                                    />
                                </div>
                                <div className="mt-8 flex justify-between">
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={() => {
                                            setIsEdit(false);
                                            methods.reset({
                                                ...currentForm,
                                                department:
                                                    initialValues.department,
                                                jobRole: initialValues.jobRole,
                                                employeeId:
                                                    initialValues.employeeId,
                                                externalReference:
                                                    initialValues.externalReference,
                                            });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" loading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditDriverEmploymentForm;
