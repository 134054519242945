import React from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CaretDown, CaretUp, Check, X } from "phosphor-react";
import clsx from "clsx";
import { FieldError } from "react-hook-form";
import FormInputLabel from "./FormInputLabel";
import FormInputMessage from "./FormInputError";

// Redecalare forwardRef (https://fettblog.eu/typescript-react-generic-forward-refs/)
declare module "react" {
    function forwardRef<T, P = Record<string, unknown>>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export type FormDropdownMultiSelectOption<TValue extends string> = {
    label: string;
    value: TValue;
};

export type FormDropdownMultiSelectProps<
    TOption extends FormDropdownMultiSelectOption<string>
> = {
    options: TOption[];
    label?: string;
    pleaseSelectText?: string;
    value: string[];
    onChange: (value: string[], option: TOption[]) => void;
    onBlur?: (label: string[]) => void;
    error?: FieldError;
    helpText?: string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    tooltip?: string;
    buttonClassName?: string;
};

const FormDropdownMultiSelect = <
    TOption extends FormDropdownMultiSelectOption<string>
>(
    {
        label,
        pleaseSelectText = "Select an option",
        options,
        value,
        onChange,
        onBlur,
        error,
        helpText,
        required,
        disabled,
        className,
        tooltip,
        buttonClassName,
        ...inputProps
    }: FormDropdownMultiSelectProps<TOption>,
    ref: React.ForwardedRef<HTMLButtonElement>
) => {
    const selectedItems = options.filter((option) =>
        value.includes(option.value)
    );

    function isSelected(newValue: TOption["value"]) {
        return selectedItems.find((opt) => opt.value === newValue || opt.label === newValue)
            ? true
            : false;
    }

    function handleDeselect(oldValue: TOption["value"]) {
        const selectedItemsUpdated = selectedItems.filter(
            (el) => el.value !== oldValue
        );
        const selectedOptions = options.filter((option) =>
            selectedItemsUpdated.includes(option)
        );
        onChange(
            selectedItemsUpdated.map((opt) => opt.value),
            selectedOptions
        );
    }

    const handleChange = (chosenOption: TOption) => {
        if (!isSelected(chosenOption.value)) {
            const findOption = options.find(
                (allOptions) =>
                    JSON.stringify(allOptions) === JSON.stringify(chosenOption)
            );

            if (!findOption)
                throw Error(
                    `Couldn't find option with value ${chosenOption.value}.`
                );

            const selectedItemsUpdated = [...selectedItems, chosenOption];

            const selectedOptions = options.filter((option) =>
                selectedItemsUpdated.includes(option)
            );

            onChange(
                selectedItemsUpdated.map((opt) => opt.value),
                selectedOptions
            );
        } else {
            handleDeselect(chosenOption.value);
        }
    };

    return (
        <div className={className}>
            {label && (
                <FormInputLabel
                    className="whitespace-nowrap"
                    required={required}
                    tooltipText={tooltip}
                >
                    {label}
                </FormInputLabel>
            )}
            <Listbox
                as="div"
                className="space-y-1"
                value={selectedItems}
                onChange={(value: any) => handleChange(value)}
                {...inputProps}
            >
                {({ open }) => (
                    <>
                        <div className="relative">
                            <span className="inline-block w-full shadow-sm">
                                <Listbox.Button
                                    className={clsx(
                                        "min-h-14 flex h-fit w-full cursor-default items-center rounded-sm border py-2 pl-4 pr-8 transition-background-color",
                                        "focus:border-S2D-light-green-80 focus:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-S2D-light-green-80",
                                        "text-left transition duration-150 ease-in-out hover:bg-white sm:text-sm sm:leading-5",
                                        `${
                                            buttonClassName
                                                ? buttonClassName
                                                : "border-black bg-S2D-dark-green-70.1"
                                        }`,
                                        error &&
                                            "border-S2D-error-40 pr-10 outline-none ring-S2D-error-40 focus:border-S2D-error-40 focus:ring-S2D-error-40",
                                        open &&
                                            "z-50 ring-2 ring-S2D-light-green-80",
                                        open && error && "ring-0"
                                    )}
                                    ref={ref}
                                >
                                    <span
                                        className={clsx(
                                            "block truncate text-base",
                                            "opacity-60",
                                            disabled && "opacity-40"
                                        )}
                                    >
                                        {selectedItems.length === 0 &&
                                            pleaseSelectText}
                                    </span>
                                    <span className="flex w-full flex-row flex-wrap gap-2">
                                        {selectedItems.map((item) => (
                                            <div
                                                className="mr-2 flex flex-none rounded-sm border-transparent bg-S2D-dark-green-30 p-2 text-white"
                                                key={`${item.value}-selected`}
                                            >
                                                {item.label}
                                                <span className="ml-2">
                                                    <X
                                                        weight="fill"
                                                        className="text-primary-text h-5 w-5"
                                                        aria-hidden
                                                        onClick={() =>
                                                            handleDeselect(
                                                                item.value
                                                            )
                                                        }
                                                    />
                                                </span>
                                            </div>
                                        ))}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                                        {open ? (
                                            <CaretUp
                                                weight="fill"
                                                className="text-primary-text h-5 w-5"
                                                aria-hidden
                                            />
                                        ) : (
                                            <CaretDown
                                                weight="fill"
                                                className="text-primary-text h-5 w-5"
                                                aria-hidden
                                            />
                                        )}
                                    </span>
                                </Listbox.Button>
                            </span>

                            <Transition
                                unmount={false}
                                show={open}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
                            >
                                <Listbox.Options
                                    static
                                    className="absolute z-50 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base drop-shadow-lg focus:outline-none sm:text-sm"
                                >
                                    {!options.length && (
                                        <Listbox.Option value={null} disabled>
                                            <div className="py-2 pl-8 text-S2D-neutral-50">
                                                ...no options
                                            </div>
                                        </Listbox.Option>
                                    )}
                                    {options.map((person) => {
                                        const selected = isSelected(
                                            person.label
                                        );
                                        return (
                                            <Listbox.Option
                                                key={person.value}
                                                value={person}
                                                onBlur={() =>
                                                    onBlur && onBlur(value)
                                                }
                                            >
                                                {({ active }) => (
                                                    <div
                                                        className={clsx(
                                                            "relative cursor-default select-none py-2 pl-8 pr-4 ",
                                                            {
                                                                "bg-S2D-neutral-95 text-S2D-neutral-10":
                                                                    active,
                                                                "text-S2D-neutral-10 ":
                                                                    !active,
                                                                "bg-S2D-light-green-80":
                                                                    selected,
                                                                "hover:bg-S2D-neutral-95":
                                                                    !selected,
                                                            }
                                                        )}
                                                    >
                                                        <span
                                                            className={`${
                                                                selected
                                                                    ? "font-semibold"
                                                                    : "font-normal"
                                                            } block truncate`}
                                                        >
                                                            {person.label}
                                                        </span>
                                                        {selected && (
                                                            <span
                                                                className={`${
                                                                    active
                                                                        ? "text-white"
                                                                        : "text-S2D-neutral-10"
                                                                } absolute inset-y-0 right-0 flex items-center pr-4`}
                                                            >
                                                                <Check
                                                                    size={18}
                                                                />
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </Listbox.Option>
                                        );
                                    })}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
            <div className="pl-3 pt-1">
                <FormInputMessage error={error} helpText={helpText} />
            </div>
        </div>
    );
};

export default React.forwardRef(FormDropdownMultiSelect);
