import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../Routes";
import DialogBox from "../DialogBox";
import FileSafeFilterForm from "./FileSafeFilterForm";
import useFileSafeFilterForm, {
    FileSafeFilterFormValues,
} from "./useFileSafeFilterForm";
import TableFiltersAndActions from "../ReusableComponents/TableFiltersAndActions";
import { isS2dUser } from "../../auth/userAccessHelpers";
import useAuth from "../../auth/useAuth";
import { useWatch } from "react-hook-form";
import { useGetCompanies } from "../../api/admin/company";
import { useGetDepartments } from "../../api/admin/department";

export type FileSafeHeaderProps = {
    setFileSafeFilters: Dispatch<
        SetStateAction<FileSafeFilterFormValues | undefined>
    >;

    searchQuery: string;
    setSearchQuery: Dispatch<SetStateAction<string>>;
    listsLoading: boolean;
};

const FileSafeHeader: React.FC<FileSafeHeaderProps> = ({
    setFileSafeFilters,
    searchQuery,
    setSearchQuery,
    listsLoading
}) => {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const { methods } = useFileSafeFilterForm();
    const { decodedToken } = useAuth();

    const clearAll = () => {
        setFileSafeFilters({
            departmentId: null,
            companyId: null,
            from: null,
            to: null,
        });
        methods.reset();
    };

    const handleApplyFilters = (data: FileSafeFilterFormValues) => {
        setFileSafeFilters({
            departmentId: data.departmentId || null,
            companyId: data.companyId || null,
            from: data.from,
            to: data.to,
        });
        setIsFilterModalOpen(false);
    };

    const handleCloseFilterModal = () => {
        setIsFilterModalOpen(false);
        clearAll();
    };

    const { control } = methods;
    const values = useWatch({ control });

    const filterIsApplied = !!(
        values.companyId ||
        values.departmentId ||
        values.from ||
        values.to
    );

    const { companyList, isCompanyListLoading } = useGetCompanies(1, 100);

    const { departmentsData, isLoadingDepartments } = useGetDepartments(
        values.companyId ?? undefined,
        1,
        100
    );

    const navigate = useNavigate();

    return (
        <>
            <TableFiltersAndActions
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                searchPlaceholder="Search transfers"
                tailoredAddButtonOnClick={() =>
                    navigate(appPaths.adminNewTransfer())
                }
                tailoredAddButtonText="New transfer"
                hideTailoredButton={!isS2dUser(decodedToken?.role)}
                isFilterModalOpen={isFilterModalOpen}
                setIsFilterModalOpen={setIsFilterModalOpen}
                isModalFilterApplied={filterIsApplied}
                isListLoading={listsLoading}
            />
            <DialogBox
                title="Filters"
                size="xl"
                closeButton
                isOpen={isFilterModalOpen}
                onClose={handleCloseFilterModal}
            >
                <FileSafeFilterForm
                    methods={methods}
                    clearAll={clearAll}
                    handleApplyFilters={handleApplyFilters}
                    companyList={companyList}
                    isCompanyListLoading={isCompanyListLoading}
                    departmentsList={departmentsData}
                    isDepartmentsListLoading={isLoadingDepartments}
                />
            </DialogBox>
        </>
    );
};

export default FileSafeHeader;
