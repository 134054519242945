import { DriverAlertDto } from "../../models/api/admin/alert";
import { useS2DApiFetcher } from "../common/fetching";

export const useInstantEmailAlert = () =>
    useS2DApiFetcher<
        DriverAlertDto,
        string,
        { driverId: string; dto: DriverAlertDto }
    >(
        "POST",
        ({ driverId, dto }) => ({
            url: `api/drivers/${driverId}/alert`,
            body: dto,
        }),
        [],
        true
    );
