import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
    AddInvoiceAddressFormValidationSchema,
    AddInvoiceAddressFormValues,
} from "./AddInvoiceAddressFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInvoiceAddress } from "../../../../../api/admin/invoiceAddress";

const useAddInvoiceAddressForm = () => {

    const {addInvoiceAddress, isAddingInvoiceAddress} = useInvoiceAddress();

    const validationSchema = yup
        .object()
        .shape(AddInvoiceAddressFormValidationSchema);

    const methods = useForm<AddInvoiceAddressFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    return { methods, addInvoiceAddress, isAddingInvoiceAddress };
};

export default useAddInvoiceAddressForm;

