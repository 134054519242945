import { DriverCategory } from "../../../utils/driverPageLocation";
import { DriversListFiltersFormValues } from "./DriversListFiltersFormValues";

/* We want all chosen values here to be required 
since its used for prefilling required values in a request, 
however some values do not needed to be padded out so we omit them.*/

export const driverListFiltersDefaultValues = (
    location?: DriverCategory | string
): Required<
    Omit<
        DriversListFiltersFormValues,
        "driverLicenceAnalysis" | "vehicleAnalysis"
    >
> => {
    return {
        driverIds: [],
        riskProfiles: [],
        greyFleet: location?.includes("greyfleet") ? true : null,
        company: {
            companyIds: null,
            departmentIds: null,
            employeeId: null,
            companyDashboard: {
                month: null,
                companyDrivers: {
                    driversChecked: null,
                    driversAdded: null,
                    driversRemoved: null,
                },
                companyGreyfleetDrivers: {
                    invitesComplete: null,
                    invitesIncomplete: null,
                },
                companyDeclarationsQuery: {
                    withDeclarations: null,
                    completedDeclarations: null,
                    expiringDeclarations: null,
                    declarationsExpiredOrNotReturned: null,
                },
                companyChecksThisMonth: {
                    licencesChecked: null,
                    ukLicencesCheckedThisMonth: null,
                    ukLicencesToBeCheckedThisMonth: null,
                    niLicencesCheckedThisMonth: null,
                    niLicencesToBeCheckedThisMonth: null,
                    foreignLicencesCheckedThisMonth: null,
                    foreignLicencesToBeCheckedThisMonth: null,
                    licencesToBeChecked: null,
                },
                companyUpcomingChecks: {
                    checksComingUpInOneMonth: null,
                    checksComingUpInThreeMonths: null,
                    checksComingUpInTwoMonths: null,
                },
            },
        },
        personalInformation: {
            firstName: null,
            lastName: null,
            email: null,
            dateOfBirthBefore: null,
            dateOfBirthAfter: null,
            deleted: null,
        },
        driverAddress: {
            postcode: null,
        },
        linkedDriver: {
            firstName: null,
            lastName: null,
        },
        licence: {
            number: null,
            isExpired: null,
            hasExpiredPhotocard: null,
            isRevoked: null,
            licenceCountryOrigin: null,
            isDisqualified: null,
            hasCpc: null,
            hasExpiredCpc: null,
            hasTacho: null,
            hasExpiredTacho: null,
            hasExpiredEntitlements: null,
            hasExpiringEntitlements: null,
            hasExpiringCpc: null,
            hasExpiringTacho: null,
            hasExpiringPhotocard: null,
            endorsements: {
                values: null,
                hasCount: {
                    from: null,
                    to: null,
                },
            },
            categories: {
                values: null,
                exclude: null,
            },
            points: {
                from: null,
                to: null,
            },
            type: null,
        },
        dvlaQuery: {
            hasInvalidLicence: null,
            hasFailedChecks: null,
            hasCheckDueThisMonth: null,
            hasCheckScheduled: null,
        },
        greyFleetQuery: {
            hasCompletedInvite: null,
            hasExpiringMot: null,
            hasMotExpired: null,
            hasExpiringRoadTax: null,
            hasRoadTaxExpired: null,
            hasExpiringInsurancePolicy: null,
            hasInsurancePolicyExpired: null,
            hasPolicyWithoutBusinessCover: null,
            hasBreakdownCoverExpiring: null,
            hasNoOrExpiredBreakdownCover: null,
            hasInsuranceCertificateToUpload: null,
            hasInsuranceUnverified: null,
        },
        declarationsQuery: {
            declarationTypes: null,
            isVerifiedDriver: null,
            hasExpiringDeclaration: null,
            hasExpiredDeclaration: null,
            hasCompletedDeclaration: null,
            hasIncompleteDeclaration: null,
            hasRequestedDeclaration: null,
            hasNotRequestedDeclaration: null
        },
        driverAnalysis: {
            cpcAndTacho: null,
            licenceStatus: null,
            licenceCategories: null,
            photocard: null,
            newDriver: null,
            eyesight: null,
            nonAddMatch: null,
        },
        licenceChanges: {
            hasGainedEndorsements: null,
            hasGainedPoints: null,
            hasLostEndorsements: null,
            hasLostPoints: null,
        },
        professional: location?.includes("professionalDrivers") ? true : null
    };
};
