import EditDriverAddressInformation from "./EditDriverAddressInformation";
import {
    EditDriverFormValues,
    driverAddressNulledData,
    driverDrivingLicenceNulledData,
    driverEmploymentNulledData,
    driverLinkedDriverNulledData,
    driverPersonalInformationNulledData,
    driverTypeNulledData,
} from "./EditDriverFormValues";
import EditDriverLicenceInformation from "./EditDriverLicenceInformationForm";
import EditDriverLinkedDriver from "./EditDriverLinkedDriver";
import EditPersonalInformationForm from "./EditPersonalInformationForm";
import EditDriverEmploymentForm from "./EditDriverEmploymentForm";
import EditDriverTypeForm from "./EditDriverTypeForm";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import useAuth from "../../auth/useAuth";
import { canEditPii } from "../../auth/userAccessHelpers";
import { useMemo } from "react";

type EditDriverFormProps = {
    initialValues: EditDriverFormValues;
    companyData?: ApiCompanyResponse;
    isLoading: boolean;
    onSubmit: (
        data: EditDriverFormValues,
        callback: () => void
    ) => Promise<void>;
};

const EditDriverForm: React.FC<EditDriverFormProps> = ({
    initialValues,
    companyData,
    isLoading,
    onSubmit,
}) => {
    /* each individual block is now its own form, 
    it uses nulled values for each other form section 
    so it does change anything unrelated to that section */
    const nulledValues: EditDriverFormValues = {
        ...driverPersonalInformationNulledData,
        ...driverEmploymentNulledData,
        ...driverDrivingLicenceNulledData,
        ...driverAddressNulledData,
        ...driverLinkedDriverNulledData,
        ...driverTypeNulledData,
        driverId: initialValues.driverId,
        foreignLicence: initialValues.foreignLicence,
    };

    const driverInformationInitialValues = useMemo(() => {
        return {
            ...nulledValues,
            ...(({
                firstName,
                lastName,
                dateOfBirth,
                emailAddress,
                mobileNumber,
            }) => ({
                firstName,
                lastName,
                dateOfBirth,
                emailAddress,
                mobileNumber,
            }))(initialValues),
        };
    }, [initialValues]);

    const driverEmploymentInitialValues = useMemo(() => {
        return {
            ...nulledValues,
            ...(({
                company,
                department,
                jobRole,
                employeeId,
                externalReference,
            }) => ({
                company,
                department,
                jobRole,
                employeeId,
                externalReference,
            }))(initialValues),
        };
    }, [initialValues]);

    const driverDrivingLicenceInitialValues = useMemo(() => {
        return {
            ...nulledValues,
            ...(({ licenceCountryOrigin, licenceNumber }) => ({
                licenceCountryOrigin,
                licenceNumber,
            }))(initialValues),
        };
    }, [initialValues]);

    const driverAddressInitialValues = useMemo(() => {
        return {
            ...nulledValues,
            ...(({ address }) => ({
                address,
            }))(initialValues),
        };
    }, [initialValues]);

    const driverLinkedDriverInitialValues = useMemo(() => {
        return {
            ...nulledValues,
            ...(({
                linkedDrivers,
                primaryDriverId,
                primaryDriverName,
                company,
            }) => ({
                linkedDrivers,
                primaryDriverId,
                primaryDriverName,
                company,
            }))(initialValues),
        };
    }, [initialValues]);

    const driverTypeInitialValues = useMemo(() => {
        // sending email address also as this is required to send a gf invite
        return {
            ...nulledValues,
            ...(({ driverType, emailAddress }) => ({
                driverType,
                emailAddress,
            }))(initialValues),
        };
    }, [initialValues]);

    const { decodedToken } = useAuth();

    return (
        <>
            <EditPersonalInformationForm
                initialValues={driverInformationInitialValues}
                isLoading={isLoading}
                onSubmit={onSubmit}
            />
            <EditDriverEmploymentForm
                initialValues={driverEmploymentInitialValues}
                isLoading={isLoading}
                onSubmit={onSubmit}
            />
            <EditDriverLicenceInformation
                initialValues={driverDrivingLicenceInitialValues}
                isLoading={isLoading}
                onSubmit={onSubmit}
            />
            {canEditPii(decodedToken) && (
                <EditDriverAddressInformation
                    initialValues={driverAddressInitialValues}
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                />
            )}
            <EditDriverLinkedDriver
                initialValues={driverLinkedDriverInitialValues}
                isLoading={isLoading}
                onSubmit={onSubmit}
            />
            <EditDriverTypeForm
                initialValues={driverTypeInitialValues}
                companyData={companyData}
                isLoading={isLoading}
                onSubmit={onSubmit}
            />
        </>
    );
};

export default EditDriverForm;
