import { ApiCommentsResponse } from "../../models/api/admin/comment";
import { AddCommentDto, EditCommentDto } from "../../models/app/admin/comment";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

const useAddDriverComment = () =>
    useS2DApiFetcher<
        AddCommentDto,
        string,
        { driverId: string; dto: AddCommentDto }
    >(
        "POST",
        ({ driverId, dto }) => ({
            url: `/api/driver/${driverId}/comment`,
            body: dto,
        }),
        [],
        true
    );

const useEditDriverComment = () =>
    useS2DApiFetcher<
        EditCommentDto,
        string,
        { driverId: string; commentId: string; dto: EditCommentDto }
    >(
        "PUT",
        ({ driverId, commentId, dto }) => ({
            url: `api/driver/${driverId}/comment/${commentId}`,
            body: dto,
        }),
        [],
        true
    );

const useDeleteDriverComment = () =>
    useS2DApiFetcher<void, string, { driverId: string; commentId: string }>(
        "DELETE",
        ({ driverId, commentId }) => ({
            url: `api/driver/${driverId}/comment/${commentId}`,
        }),
        [],
        true
    );

export const useDriverComment = (id?: string) => {
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<ApiCommentsResponse>(
            id ? `api/driver/${id}/comment` : null
        );

    const [addDriverComment, isAddingDriverComment] = useAddDriverComment();
    const [editDriverComment, isEditingDriverComment] = useEditDriverComment();
    const [deleteDriverComment, isDeletingDriverComment] =
        useDeleteDriverComment();

    return {
        data,
        error,
        isLoading,
        addDriverComment: async (driverId: string, dto: AddCommentDto) =>
            await addDriverComment({ driverId, dto }),
        isAddingDriverComment,
        refetchDriverComments: refetch,
        editDriverComment: async (
            driverId: string,
            commentId: string,
            dto: EditCommentDto
        ) => await editDriverComment({ driverId, commentId, dto }),
        isEditingDriverComment,
        deleteDriverComment: async (driverId: string, commentId: string) =>
            await deleteDriverComment({ driverId, commentId }),
        isDeletingDriverComment,
    };
};
