import { type ApiDepartmentResponse } from "./department";
import {
    type CompanyRiskProfile,
    type CompanySettings,
} from "../../app/admin/company";
import { type Address } from "../../shared/address";

export type ApiCompaniesResponse = {
    items: ApiCompanyResponse[];
    totalCount: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
};

export type ApiCompaniesBreakdownResponse = {
    totalClients: number;
    totalDrivers: number;
    totalNonApi: number;
    totalApi: number;
    totalLive: number;
    totalDisabledNonPayment: number;
    totalDisabledPermanently: number;
};

export enum CompanyStatusEnum {
    Live = 0,
    DisabledNonPayment = 1,
    DisabledPermanent = 2,
}
export type CompanyStatusFilters = keyof typeof CompanyStatusEnum;

export type CompanyStatusValues =
    (typeof CompanyStatusEnum)[CompanyStatusFilters]; // translates to 0 | 1 | 2

export type CompanyDashboardDetails = {
    riskProfile: {
        safe2DriveCount: number;
        safe2DriveDriverIds: string[];
        mediumRiskCount: number;
        mediumRiskDriverIds: string[];
        highRiskCount: number;
        highRiskDriverIds: string[];
        disqualifiedCount: number;
        disqualifiedDriverIds: string[];
        prevDisqualifiedCount: number;
        prevDisqualifiedDriverIds: string[];
    };
    greyFleetStatuses: {
        complete: number;
        incomplete: number;
        expired: number;
    };
    declarationStatuses: {
        complete: number;
        pending: number;
        incomplete: number;
        expired: number;
        foreignLicence: number;
    };
    checksCompleted: {
        name: string;
        standard: number;
        manual: number;
        irish: number;
        foreign: number;
        greyFleet: number;
    };
};

export type ApiCompanyResponse = {
    address: Address;
    childCompanies: string[];
    companyDashboardDetails: CompanyDashboardDetails;
    companyId: string;
    companyName: string;
    departments: ApiDepartmentResponse[] | null;
    departmentCount: number;
    driverCount: number;
    greyFleetDriverCount: number;
    logoUrl: string;
    postcode: string;
    resellerId: string;
    statusId: CompanyStatusValues;
    status: CompanyStatusFilters;
    userCount: number;
    createdAt: Date;
    updatedAt: Date;
    lastStatusLog: StatusLog;

    // account managers & parent company not implemented
    accountManager?: string;
    parentCompanyName?: string;
} & CheckSettings;

type StatusLog = {
    createdAt: string;
    statusId: number;
    statusDescription: string;
    comment: string;
    createdByUser: StatusLogUser;
};

type StatusLogUser = {
    userId: string;
    userName: string; // email
    companyId: string | null;
    firstName: string;
    lastName: string;
    driverIds: string[] | null;
    driverId: string | null;
};

export type CheckSettings = {
    companyRiskProfiles: CompanyRiskProfile[];
    settings: CompanySettings;
};

export type CompanyContact = {
    firstName: string;
    lastName: string;
    telephone: string;
    emailAddress: string;
    contactId?: string;
};

export type ApiInvoiceSettingsItem = {
    shouldInvoiceParent: boolean;
    lastInvoicedAt: Date | null;
    annualFee: number | null;
};