import { ImpersonateUserDto, LogIn2FADto, LogInDto } from "../models/app/logIn";
import { useS2DApiFetcher } from "./common/fetching";
import { LoginApiResponse } from "../models/api/login";

export const useAuthLogin = () => {
    const [logIn, isLoggingIn] = useS2DApiFetcher<
        LogInDto,
        LoginApiResponse,
        { dto: LogInDto }
    >(
        "POST",
        ({ dto }) => ({
            url: "api/auth/login",
            body: dto,
        }),
        [400],
        false
    );

    return {
        logIn: async (dto: LogInDto) => await logIn({ dto }),
        isLoggingIn,
    };
};

export const useLogIn2FA = () => {
    const [logIn2FA, isLoggingIn2FA] = useS2DApiFetcher<
        LogIn2FADto,
        LoginApiResponse,
        { dto: LogIn2FADto }
    >(
        "POST",
        ({ dto }) => ({
            url: "api/auth/login2fa",
            body: dto,
        }),
        [400],
        false
    );

    return {
        logIn2FA: async (dto: LogIn2FADto) => await logIn2FA({ dto }),
        isLoggingIn2FA,
    };
};

// requires alt token as handler is outside of AuthContext
export const useUserImpersonation = (token?: string) => {
    const [impersonateUser, isImpersonatingUser] = useS2DApiFetcher<
        ImpersonateUserDto,
        LoginApiResponse,
        { dto: ImpersonateUserDto }
    >(
        "POST",
        ({ dto }) => ({
            url: "api/auth/impersonate",
            altToken: token,
            body: dto,
        }),
        [400],
        false
    );

    return {
        impersonateUser: async (dto: ImpersonateUserDto) =>
            await impersonateUser({ dto }),
        isImpersonatingUser,
    };
};
