import {
    type TableContent,
    type TableHeader,
} from "../ReusableComponents/Table";
import StatusPill from "../ReusableComponents/StatusPill/StatusPill";
import UserIcon from "../UserIcon";
import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import { type ApiCompanyResponse } from "../../models/api/admin/company";

export type CompanyTableAllowedIds =
    | "companyId"
    | "companyName"
    | "parentCompany"
    | "isParentClientDataHidden"
    | "postcode"
    | "departments"
    | "totalDrivers"
    | "greyFleetDrivers"
    | "users"
    | "status";

export type CompanyTableHeader = TableHeader<CompanyTableAllowedIds>;
export type CompanyTableContent = TableContent<
    ApiCompanyResponse,
    CompanyTableAllowedIds
>;

export const initCompanyTableHeaders: CompanyTableHeader[] = [
    {
        title: `${capitalisedTermForCompany} Id`,
        id: "companyId",
        menuGroup: `${capitalisedTermForCompany} Details`,
    },
    {
        title: `${capitalisedTermForCompany} Name`,
        id: "companyName",
        menuGroup: `${capitalisedTermForCompany} Details`,
    },
    {
        title: `Parent ${capitalisedTermForCompany}`,
        id: "parentCompany",
        menuGroup: `${capitalisedTermForCompany} Details`,
    },
    {
        title: "Hidden From Parent Company?",
        id: "isParentClientDataHidden",
        menuGroup: `${capitalisedTermForCompany} Details`,
    },
    {
        title: "Postcode",
        id: "postcode",
        menuGroup: `${capitalisedTermForCompany} Details`,
    },
    {
        title: "Departments",
        id: "departments",
        menuGroup: `${capitalisedTermForCompany} Details`,
    },
    {
        title: "Total Drivers",
        id: "totalDrivers",
        menuGroup: "Statistics",
    },
    {
        title: "Grey Fleet Drivers",
        id: "greyFleetDrivers",
        menuGroup: "Statistics",
    },
    {
        title: "Users",
        id: "users",
        menuGroup: "Statistics",
    },
    {
        title: "Status",
        id: "status",
        menuGroup: `${capitalisedTermForCompany} Details}`,
    },
];

const statusColor = (status: string | null) => {
    if (status === null || status === undefined) return "bg-S2D-neutral-70";

    if (status === "Live") {
        return "bg-S2D-success-80";
    } else {
        return "bg-S2D-warning-90";
    }
};

export function initCompanyTableContents(
    companies: ApiCompanyResponse[]
): CompanyTableContent[] {
    const tableContents = new Array<CompanyTableContent>(0);
    companies.map((company) => {
        const parentCompany = companies.find(
            (c) => c.companyId === company.resellerId
        );
        tableContents.push({
            rowId: company.companyId,
            rowData: company,
            cellData: [
                {
                    id: "companyId",
                    content: <p>{company.companyId}</p>,
                },
                {
                    id: "companyName",
                    content: (
                        <div className="flex items-center gap-2">
                            <UserIcon
                                firstName={company.companyName}
                                size="small"
                            />
                            <p className="max-w-[15rem] truncate">
                                {company.companyName}
                            </p>
                        </div>
                    ),
                },
                {
                    id: "isParentClientDataHidden",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {company.settings.privacySettings?.isDataHidden ? "Yes" : "No"}
                        </p>
                    ),
                },
                {
                    id: "parentCompany",
                    content: (
                        <p className="max-w-[15rem] truncate">
                            {parentCompany?.companyName ?? undefined}
                        </p>
                    ),
                },
                {
                    id: "postcode",
                    content: <p>{company.postcode}</p>,
                },
                {
                    id: "departments",
                    content: <p>{company.departmentCount}</p>,
                },
                {
                    id: "totalDrivers",
                    content: <p>{company.driverCount}</p>,
                },
                {
                    id: "greyFleetDrivers",
                    content: <p>{company.greyFleetDriverCount}</p>,
                },
                {
                    id: "users",
                    content: <p>{company.userCount}</p>,
                },
                {
                    id: "status",
                    content: (
                        <div className="pl-2">
                            <StatusPill
                                status={company.status}
                                bgColour={statusColor(company.status)}
                            />
                        </div>
                    ),
                },
            ],
        });
    });
    return tableContents;
}
