import ApiError from "../../api/common/apiError";
import { type S2DApiFetcherResponse } from "../../api/common/apiResponse";
import { type ApiCommentsResponse } from "../../models/api/admin/comment";
import { type EditCommentDto } from "../../models/app/admin/comment";
import CommentForm from "../CommentForm/CommentForm";
import { type CommentFormValues } from "../CommentForm/CommentFormValues";
import CommentList from "../CommentList";
import EditableContentBlock from "../EditableContentBlock";
import FormMessageBox from "../FormMessageBox";

type EditDriverCommentsFormProps = {
    driverId?: string;
    isLoading: boolean;
    error?: ApiError;
    driverCommentList?: ApiCommentsResponse;
    deleteDriverComment: (
        driverId: string,
        commentId: string
    ) => Promise<S2DApiFetcherResponse<string>>;
    isDeletingDriverComment: boolean;
    refetchDriverComments: () => void;
    editDriverComment: (
        driverId: string,
        commentId: string,
        dto: EditCommentDto
    ) => Promise<S2DApiFetcherResponse<string>>;
    isEditingDriverComment: boolean;
    handleAddDriverComment: (
        dto: CommentFormValues,
        callback: (success: boolean) => void
    ) => Promise<void>;
    isAddingDriverComment: boolean;
};

const EditDriverCommentsForm: React.FC<EditDriverCommentsFormProps> = ({
    driverId,
    isLoading,
    error,
    driverCommentList,
    deleteDriverComment,
    isDeletingDriverComment,
    refetchDriverComments,
    editDriverComment,
    isEditingDriverComment,
    handleAddDriverComment,
    isAddingDriverComment,
}) => {
    const handleDeleteComment = async (
        commentId: string,
        callback: (success: boolean) => void
    ) => {
        if (!driverId) return;
        const response = await deleteDriverComment(driverId, commentId);

        if (response.success) {
            refetchDriverComments();
            callback(true);
            return;
        }

        callback(false);
    };

    const handleEditDriverComment = async (
        commentId: string,
        dto: CommentFormValues,
        callback: (success: boolean) => void
    ) => {
        if (!driverId) return;
        const response = await editDriverComment(driverId, commentId, dto);

        if (response.success) {
            refetchDriverComments();
            callback(true);
            return;
        }

        callback(false);
    };

    return (
        <>
            {!isLoading && driverCommentList && (
                <EditableContentBlock title="Comments">
                    {({ isEdit, setIsEdit }) => (
                        <>
                            <div className="p-4">
                                {error && (
                                    <FormMessageBox
                                        variant="error"
                                        title={"Error loading comments"}
                                        message={"Please try again."}
                                    />
                                )}
                                <CommentList
                                    comments={driverCommentList}
                                    disabledEditing={!isEdit}
                                    onDelete={handleDeleteComment}
                                    isDeleting={isDeletingDriverComment}
                                    onEdit={handleEditDriverComment}
                                    isEditing={isEditingDriverComment}
                                />
                                {isEdit && (
                                    <CommentForm
                                        isLoading={isAddingDriverComment}
                                        onCancel={() => setIsEdit(false)}
                                        onSubmit={handleAddDriverComment}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </EditableContentBlock>
            )}
        </>
    );
};

export default EditDriverCommentsForm;
