import { CheckStatus } from "../../../../models/shared/statuses";
import { StatusAction } from "../../../ActionableStatus/ActionableStatus";
import { LicenceChecksActions } from "../licenceCheckActions";
import LicenceCheckStatus from "../LicenceCheckStatus";

type NoGbEntitlementLicenceCheckStatusProps = {
    driverId: string;
    isManual: boolean;
    lastCheckDate: string;
    nextCheckDate: string;
    checkStatus: CheckStatus;
    buildActions: (actionNames: LicenceChecksActions[]) => StatusAction[];
};

const NoGbEntitlementLicenceCheckStatus = ({
    driverId,
    isManual,
    lastCheckDate,
    nextCheckDate,
    checkStatus,
    buildActions,
}: NoGbEntitlementLicenceCheckStatusProps) => {
    const actions = buildActions(
        isManual
            ? [
                  "editCheckDate",
                  "markAsChecked",
                  "markAsPending",
                  "markAsFailed",
                  "disableFurtherChecks",
                  "changeToAutomatic",
              ]
            : [
                  "editCheckDate",
                  "retryCheck",
                  "changeToManual",
                  "disableFurtherChecks",
              ]
    );

    return (
        <LicenceCheckStatus
            actions={actions}
            theme="vrm"
            text="No GB entitlement"
            driverId={driverId}
            isManual={isManual}
        >
            <span>Last check: {lastCheckDate}</span>
            <span>
                {checkStatus === CheckStatus.Disabled
                    ? "Further checks disabled"
                    : `Next check: ${nextCheckDate}`}
            </span>
        </LicenceCheckStatus>
    );
};

export default NoGbEntitlementLicenceCheckStatus;
