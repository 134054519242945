import type { CompanyApiSettingsResponse } from "../../models/api/admin/companyApiSettings";
import { EditCompanyApiSettingsDto } from "../../models/app/admin/companyApiSettings";
import { useS2DApiFetcher } from "../common/fetching";
import { useS2DApiSWR } from "../common/s2dApiSWR";

export const useGetCompanyApiSettings = (companyId?: string) => {
    const path = `api/companies/${companyId}/settings/api`;
    const { data, error, isLoading, refetch } =
        useS2DApiSWR<CompanyApiSettingsResponse>(companyId ? path : null, {
            preventRedirectOn404: true,
        });

    return {
        companyApiSettings: data,
        isCompanyApiSettingsLoading: isLoading,
        companyApiSettingsError: error,
        refetchCompanyApiSettings: refetch,
    };
};

const useEditCompanyApiSettings = () =>
    useS2DApiFetcher<
        EditCompanyApiSettingsDto,
        string,
        { companyId: string; dto: EditCompanyApiSettingsDto }
    >(
        "PUT",
        ({ companyId, dto }) => ({
            url: `api/companies/${companyId}/settings/api`,
            body: dto,
        }),
        [],
        true
    );

const usePostApiKey = () =>
    useS2DApiFetcher<undefined, string, { companyId: string }>(
        "POST",
        ({ companyId }) => ({
            url: `/api/companies/${companyId}/settings/api`,
        }),
        [],
        true
    );

export const useCompanyApiSettings = () => {
    const [editCompanyApiSettings, isEditingCompanyApiSettings] =
        useEditCompanyApiSettings();
    const [postNewApiKey, isPostingNewApiKey] = usePostApiKey();

    return {
        editCompanyApiSettings: async (
            companyId: string,
            dto: EditCompanyApiSettingsDto
        ) => await editCompanyApiSettings({ companyId, dto }),
        isEditingCompanyApiSettings,
        postNewApiKey: async (companyId: string) =>
            await postNewApiKey({ companyId }),
        isPostingNewApiKey,
    };
};
