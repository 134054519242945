import { useParams, useSearchParams } from "react-router-dom";
import { useGreyFleetBaseData } from "../api/greyFleet";
import FormMessageBox from "../components/FormMessageBox";
import GreyFleetForm from "../components/GreyFleetWizard/GreyFleetForm";

import Logo from "../s2d-dark-logo.svg";
import { ThemeProvider } from "../theme/ThemeContext";

type PageParams = {
    id: string;
};

const GreyFleetPage: React.FC = () => {
    const { id } = useParams<PageParams>();
    const { greyFleetBaseData, error } = useGreyFleetBaseData(id);
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const vehicleId = searchParams.get("vehicleId");
    const update = searchParams.get("update");

    return (
        <ThemeProvider companyId={greyFleetBaseData?.companyId}>
            <div className="flex min-h-screen w-screen flex-col items-center justify-center bg-S2D-dark-green-40 sm:px-4 sm:py-8">
                <div className="flex w-full max-w-6xl flex-1 flex-col rounded-sm bg-white">
                    <header className="flex flex-none flex-col items-center justify-between px-6 py-4 sm:flex-row sm:px-8">
                        <div className="mb-4 flex h-12 items-center text-2xl font-semibold sm:mb-0">
                            <img src={Logo} alt="Safe 2 Drive Logo" />
                        </div>
                        <div className="flex items-center justify-center gap-8 sm:ml-auto">
                            {greyFleetBaseData?.resellerLogoUrl && (
                                <div className="h-12">
                                    <img
                                        className="h-full w-full object-contain"
                                        src={greyFleetBaseData.resellerLogoUrl}
                                        alt={greyFleetBaseData.resellerName}
                                    />
                                </div>
                            )}
                            {greyFleetBaseData?.companyLogoUrl && (
                                <div className="h-12">
                                    <img
                                        className="h-full w-full object-contain"
                                        src={greyFleetBaseData.companyLogoUrl}
                                        alt={greyFleetBaseData.companyName}
                                    />
                                </div>
                            )}
                        </div>
                    </header>
                    <main className="flex flex-1 flex-col px-6 py-8 pt-0 sm:px-8">
                        {id && (
                            <GreyFleetForm
                                greyFleetInviteId={id}
                                defaultStep={token ? 6 : 0}
                                jointEdecGreyFleet={token ? true : false}
                                token={token}
                                vehicleId={vehicleId}
                                update={update}
                            />
                        )}
                        {error?.statusCode === 404 && (
                            <FormMessageBox
                                variant="error"
                                title="Invalid invitation"
                                message="The link to your grey fleet invitation is no longer valid, please contact your Safe2Drive administrator."
                            />
                        )}
                    </main>
                </div>
            </div>
        </ThemeProvider>
    );
};

export default GreyFleetPage;
