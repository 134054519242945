import { SetStateAction } from "react";
import DialogBox from "../DialogBox";
import AddCompanyUserForm from "./AddCompanyUserForm";
import { type AddCompanyUserFormValues } from "./AddCompanyUserFormValues";
import { useGetDepartments } from "../../api/admin/department";
import useCompanyUser from "../../api/admin/companyUser";
import { errorToast, successToast } from "../../toast";

type AddCompanyUserModalProps = {
    isOpen: boolean;
    setIsOpen: (value: SetStateAction<boolean>) => void;
    companyId?: string;
    companyName?: string;
    refetchCompanyUsers: () => void;
};

const AddCompanyUserModal: React.FC<AddCompanyUserModalProps> = ({
    isOpen,
    setIsOpen,
    companyId,
    companyName,
    refetchCompanyUsers,
}) => {
    const { addCompanyUser, isAddingCompanyUser } = useCompanyUser();

    const handleSubmit = async (
        data: AddCompanyUserFormValues,
        callback?: () => void
    ) => {
        if (!companyId) {
            errorToast("Error adding user");
            return;
        }
        const response = await addCompanyUser(data, companyId);
        if (response.success) {
            successToast("User added successfully");
            setIsOpen(false);
            refetchCompanyUsers();
            if (callback) callback();
        } 
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const { departmentsData, departmentsError, isLoadingDepartments } =
        useGetDepartments(companyId ?? undefined, 1, 100);

    const departmentOptions = departmentsData?.items.map((department) => ({
        label: department.departmentName,
        value: department.departmentId,
    }));

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title="Add user"
            size="large"
            closeButton
        >
            <AddCompanyUserForm
                companyName={companyName}
                isLoading={isAddingCompanyUser}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                isLoadingDepartments={isLoadingDepartments}
                departmentsError={departmentsError}
                departmentOptions={departmentOptions}
            />
        </DialogBox>
    );
};

export default AddCompanyUserModal;
