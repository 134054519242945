import { useVerifiedUser } from "../../api/user";
import useAuth from "../../auth";
import DialogBox from "../../components/DialogBox";
import LoadingMessage from "../../components/ReusableComponents/LoadingMessage/LoadingMessage";
import Toggle from "../../components/Toggle";
import { successToast } from "../../toast";

type Toggle2FAModalProps = {
    isOpen: boolean;
    onClose: () => void;
    enforced2FA?: boolean;
    waitForCompanyLoad?: boolean;
};

const Toggle2FAModal: React.FC<Toggle2FAModalProps> = ({
    isOpen,
    onClose,
    enforced2FA,
    waitForCompanyLoad,
}) => {
    const { userDetails, twofaEnabled, setTwofaEnabled } = useAuth();

    const { enable2fa, isEnabling2fa } = useVerifiedUser();
    const handleEnable2fa = async (value: boolean) => {
        if (!userDetails?.userId) return;
        if (enforced2FA) return;

        const response = await enable2fa(userDetails.userId, {
            enableTwoFactorAuthentication: value,
        });

        if (response.success) {
            successToast(
                value
                    ? "Switched to two factor authentication"
                    : "Removed two factor authentication"
            );
        }
        setTwofaEnabled(value);
    };

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={onClose}
            closeButton
            title="2FA Settings"
        >
            <div className="p-4">
                {!enforced2FA ? (
                    <>
                        {waitForCompanyLoad ? (
                            <LoadingMessage message="Loading 2FA settings" />
                        ) : (
                            <Toggle
                                text="Enable 2fa"
                                value={twofaEnabled}
                                onChange={handleEnable2fa}
                                loading={isEnabling2fa}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <Toggle
                            text="Enable 2fa"
                            value={true}
                            onChange={() => undefined}
                            disabled={true}
                            helpText="2FA has been enforced by a higher level."
                        />
                    </>
                )}
            </div>
        </DialogBox>
    );
};

export default Toggle2FAModal;
