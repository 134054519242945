type DividerProps = {
    text: string;
};

const Divider: React.FC<DividerProps> = ({ text }) => {
    return (
        <div>
            <span className="text-lg font-semibold text-S2D-neutral-10">
                {text}
            </span>
            <div className="py-1" />
            <div className="mb-6 border border-t-0 border-S2D-neutral-80" />
        </div>
    );
};

export default Divider;
