import Table from "../ReusableComponents/Table";
import {
    driverDeclarationsTableContents,
    driverDeclarationsMenuGroups,
} from "./DriverDeclarationsTableInit";
import { driverDeclarationsMenuItems } from "./DriverDeclarationsTableMenuOptions";
import useDriverDeclarationsTable from "./useDriverDeclarationsTable";
import PaginationNav from "../PaginationNav";
import DriverDeclarationsTableFilters from "./DriverDeclarationsTableFilters";
import {
    ApiEDeclarationsResponse,
    DriverDeclaration,
} from "../../models/api/admin/eDeclarations";
import ApiError from "../../api/common/apiError";
import { useEffect, useState } from "react";
import { TableCheckbox } from "../ReusableComponents/Table/tableTypes";
import { DeclarationTableFilters } from "../../api/admin/eDeclarations";

type DriverDeclarationsTableProps = {
    eDeclarationsList?: ApiEDeclarationsResponse;
    isLoadingEDeclarationsList: boolean;
    eDeclarationsListError?: ApiError;
    pageNumber: number;
    setPageNumber: (value: number | ((val: number) => number)) => void;
    pageSize: number;
    setPageSize: (value: number | ((val: number) => number)) => void;
    refetchEDeclarationsList: () => void;
    refetchCompanyDeclarationAnalysis: () => void;
    searchQuery: string;
    setSearchQuery: (value: string) => void;
    declarationTableFiltersStore: DeclarationTableFilters;
    handleResetFilters: () => void;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
};
export default function DriverDeclarationsTable({
    eDeclarationsList,
    isLoadingEDeclarationsList,
    eDeclarationsListError,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    refetchEDeclarationsList,
    refetchCompanyDeclarationAnalysis,
    searchQuery,
    setSearchQuery,
    declarationTableFiltersStore,
    handleResetFilters,
    tableRef,
}: DriverDeclarationsTableProps) {
    const handleRefetch = () => {
        refetchCompanyDeclarationAnalysis();
        refetchEDeclarationsList();
    };
    const {
        tableHeaders,
        setTableHeaders,
        hiddenCols,
        setHiddenCols,
        switchDeclarationTypeAction,
        isUpdatingDeclarationType,
        editDriverClickHandler,
        declarationRequestAction,
    } = useDriverDeclarationsTable(handleRefetch);

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<
        TableCheckbox<DriverDeclaration>[]
    >([]);

    // reset selected checkboxes when table page changes
    useEffect(() => {
        setSelectedCheckboxes([]);
    }, [pageNumber]);

    return (
        <div>
            <div className="flex flex-col gap-6">
                <DriverDeclarationsTableFilters
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    refetchEDeclarationsList={handleRefetch}
                    declarationTableFiltersStore={declarationTableFiltersStore}
                    handleResetFilters={handleResetFilters}
                    isLoadingEDeclarationsList={isLoadingEDeclarationsList}
                />
                <>
                    <Table
                        tableHeaders={tableHeaders}
                        setTableHeaders={setTableHeaders}
                        tableContents={driverDeclarationsTableContents(
                            eDeclarationsList?.items
                        )}
                        menuItems={(data) =>
                            driverDeclarationsMenuItems(
                                data,
                                editDriverClickHandler,
                                declarationRequestAction,
                                switchDeclarationTypeAction,
                                isUpdatingDeclarationType
                            )
                        }
                        menuGroups={driverDeclarationsMenuGroups}
                        hiddenCols={hiddenCols}
                        setHiddenCols={setHiddenCols}
                        isLoading={isLoadingEDeclarationsList}
                        tableRef={tableRef}
                        checkedBoxes={selectedCheckboxes}
                        setCheckedBoxes={setSelectedCheckboxes}
                    />
                    {eDeclarationsListError && errorMessage}
                    {eDeclarationsList && (
                        <>
                            {eDeclarationsList.items.length === 0 && (
                                <div className=" mt-3 rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-4 text-S2D-neutral-10">
                                    No declarations found
                                </div>
                            )}
                            <PaginationNav
                                setPageNumber={setPageNumber}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                totalCount={eDeclarationsList.totalCount}
                                totalPages={eDeclarationsList.totalPages}
                                currentPage={eDeclarationsList.pageNumber}
                                hasPreviousPage={
                                    eDeclarationsList.hasPreviousPage
                                }
                                hasNextPage={eDeclarationsList.hasNextPage}
                            />
                        </>
                    )}
                </>
            </div>
        </div>
    );
}

const errorMessage = (
    <div className=" mt-3 rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-4 text-S2D-neutral-10">
        Error loading declarations
    </div>
);
