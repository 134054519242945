import {
    findHiddenTableContent,
    findTableCellContent,
} from "../../../utils/tableUtils";
import TableMenu, { type TableMenuItem } from "./TableMenu";
import {
    type TableHeader,
    type TableContent,
    type HiddenCols,
    TableCheckbox,
} from "./tableTypes";
import clsx from "clsx";
import { Checkbox } from "@mui/material";
import IconButton from "../../IconButton";
import { IconProps } from "../../../../node_modules/phosphor-react/dist/lib";

type TableRowProps<ContentType> = {
    rowItem: TableContent<ContentType>;
    tableHeaders: TableHeader[];
    hiddenCols?: HiddenCols[];
    menuItems?: (data: ContentType) => TableMenuItem[];
    menuGroups?: string[];
    checkedBoxes: TableCheckbox<ContentType>[];
    hideCheckboxes: boolean;
    handleCheckBoxes: (id: string, data: ContentType) => void;
    exposeInlineItems?: boolean;
};

const TableRow = <ContentType,>({
    rowItem,
    tableHeaders,
    hiddenCols,
    menuItems,
    menuGroups,
    checkedBoxes,
    hideCheckboxes,
    handleCheckBoxes,
    exposeInlineItems = false,
}: TableRowProps<ContentType>) => {
    const isChecked = !!checkedBoxes.find(
        (value) => value.id === rowItem.rowId
    );
    return (
        <>
            <td key={`${rowItem.rowId}checkboxContent`}>
                {hideCheckboxes ? (
                    //  replacing the styling of the checkbox
                    <div
                        className="my-6 mx-2 flex"
                        key={`${rowItem.rowId}checkboxDiv`}
                    />
                ) : (
                    <div
                        className="my-1 flex justify-start"
                        key={`${rowItem.rowId}checkboxDiv`}
                    >
                        <Checkbox
                            className="text-sm"
                            onChange={() =>
                                handleCheckBoxes(rowItem.rowId, rowItem.rowData)
                            }
                            checked={isChecked}
                            key={`${rowItem.rowId}checkbox`}
                            sx={{
                                color: ["#474741"],
                                "&.Mui-checked": {
                                    color: ["#474741"],
                                },
                            }}
                        />
                    </div>
                )}
            </td>

            {tableHeaders
                .filter(
                    (header) =>
                        findHiddenTableContent(header.id, hiddenCols) === false
                )
                .map((header: TableHeader) => (
                    <td
                        key={`${header.id}Content`}
                        className={clsx("py-1 px-2 text-left text-sm")}
                    >
                        {
                            findTableCellContent(rowItem.cellData, header.id)
                                ?.content
                        }
                    </td>
                ))}
            {menuItems && (
                <td className="my-3 flex justify-end">
                    {/* Render menu items as individual buttons if exposeInlineItems is true */}
                    {exposeInlineItems ? (
                        <div className="pr-3">
                            {menuItems(rowItem.rowData).map((item, index) => (
                                <IconButton
                                    icon={
                                        item.icon as React.ForwardRefExoticComponent<
                                            IconProps &
                                                React.RefAttributes<SVGSVGElement>
                                        >
                                    }
                                    key={index}
                                    onClick={item.onClick}
                                    label={item.text}
                                    disabled={item.isDisabled}
                                    loading={item.isLoading}
                                />
                            ))}
                        </div>
                    ) : (
                        <TableMenu menuItems={menuItems(rowItem.rowData)} />
                    )}
                </td>
            )}
            {!menuItems && menuGroups && menuGroups.length && (
                <td key={`${rowItem.rowId}NoMenuContent`}></td>
            )}
        </>
    );
};

export default TableRow;
