import { AnalysisComponentContentType } from "../ReusableComponents/AnalysisComponent/AnalysisComponent";
import {
    GreyFleetInvitesAnalysis,
    MotsAnalysis,
    RoadTaxAnalysis,
    InsuranceAnalysis,
    BreakdownCoverAnalysis,
} from "../AnalysisViews/GreyFleetAnalysisViews";
import { type GreyFleetAlertsData } from "../../models/api/admin/greyFleet";
import ApiError from "../../api/common/apiError";
import { type DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm";

const greyFleetAnalysisContent = (
    data?: GreyFleetAlertsData,
    error?: ApiError,
    refetchData?: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>,
    tableRef?: React.MutableRefObject<HTMLDivElement | null>
): AnalysisComponentContentType<string>[] => {
    return [
        {
            title: "Grey Fleet Invites",
            content: (
                <>
                    {!error && (
                        <GreyFleetInvitesAnalysis
                            completed={data?.invitesComplete}
                            pending={data?.invitesPending}
                            refetchData={refetchData}
                            tableRef={tableRef}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
        {
            title: "MOTs",
            content: (
                <>
                    {!error && (
                        <MotsAnalysis
                            expired={data?.motsExpired}
                            expiring={data?.motsExpiringThisMonth}
                            refetchData={refetchData}
                            tableRef={tableRef}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
        {
            title: "Road Tax",
            content: (
                <>
                    {!error && (
                        <RoadTaxAnalysis
                            expiring={data?.roadTaxDueToExpire}
                            expired={data?.roadTaxExpired}
                            refetchData={refetchData}
                            tableRef={tableRef}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
        {
            title: "Insurance",
            content: (
                <>
                    {!error && (
                        <InsuranceAnalysis
                            expired={data?.insuranceExpired}
                            expiring={data?.insuranceExpiringThisMonth}
                            noBusinessCover={data?.policiesWithoutBusinessCover}
                            unverified={data?.insuranceUnverified}
                            notUploaded={data?.insuranceCertificateToBeUploaded}
                            refetchData={refetchData}
                            tableRef={tableRef}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
        {
            title: "Breakdown Cover",
            content: (
                <>
                    {!error && (
                        <BreakdownCoverAnalysis
                            expiredOrNoCover={data?.expiredOrNoBreakdownCover}
                            expiring={data?.breakdownExpiringThisMonth}
                            refetchData={refetchData}
                            tableRef={tableRef}
                        />
                    )}
                    {error && <ErrorMessage />}
                </>
            ),
        },
    ];
};

const ErrorMessage = () => {
    return (
        <div className="rounded border-2 border-S2D-error-40 bg-S2D-error-90 p-4 text-S2D-neutral-10">
            Error fetching grey fleet analysis data
        </div>
    );
};

export default greyFleetAnalysisContent;
