import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useEndorsementCodes } from "../../../api/admin/endorsementCodes";
import { useEndorsement } from "../../../api/admin/endorsements";
import { DvlaEndorsement } from "../../../models/api/admin/dvla";
import { errorToast, successToast } from "../../../toast";
import Button from "../../Button";
import EditableContentBlock from "../../EditableContentBlock";
import FormDateInput from "../../FormDateInput";
import FormDropdown from "../../FormDropdown";
import FormTextInput from "../../FormTextInput";
import {
    EditDvlaEndorsementFormValidationSchema,
    EditDvlaEndorsementFormValues,
} from "../EditDvlaFormValues";
import EndorsementUnit from "../EndorsementUnit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type EditEndorsementsFormProps = {
    endorsements: EditDvlaEndorsementFormValues;
    refetch: () => void;
    canEdit: boolean;
};

export type EndorsementCodeOption = {
    value: string;
    label: string;
    code: string;
    description: string;
};

const validationSchema = yup.object().shape({
    ...EditDvlaEndorsementFormValidationSchema,
});

const EditEndorsementsForm = ({
    endorsements,
    refetch,
    canEdit,
}: EditEndorsementsFormProps) => {
    const [isAddingNewEndorsement, setIsAddingNewEndorsement] = useState(false);
    const [endorsementCodeOptions, setEndorsementCodeOptions] = useState<
        EndorsementCodeOption[]
    >([]);

    const { id } = useParams<{ id: string }>();

    const { addEndorsement, isAddingEndorsement } = useEndorsement(id);

    const { endorsementCodes } = useEndorsementCodes();

    const { control, register, formState, handleSubmit, reset } =
        useForm<DvlaEndorsement>({
            mode: "onSubmit",
            resolver: yupResolver(validationSchema),
            defaultValues: {
                code: "",
                offenceDate: null,
                convictionDate: null,
                points: 0,
            },
        });

    useEffect(() => {
        if (endorsementCodes) {
            const options = endorsementCodes.map(
                (endorsementCode): EndorsementCodeOption => ({
                    value: endorsementCode.code,
                    label: endorsementCode.code,
                    code: endorsementCode.code,
                    description: endorsementCode.description,
                })
            );
            setEndorsementCodeOptions(options);
        }
    }, [endorsementCodes]);

    const handleAddEndorsement = async (
        data: DvlaEndorsement,
        setIsEdit: (isEdit: boolean) => void
    ) => {
        if (!id) {
            errorToast("There was an error");
            return;
        }
        const response = await addEndorsement(id, data);

        if (response.success) {
            successToast("Endorsement added successfully");
            refetch();
            reset();
            setIsEdit(false);
            setIsAddingNewEndorsement(false);
        }

        if (!response.success) {
            errorToast("There was an error");
        }
    };

    return (
        <EditableContentBlock title="Endorsements" disableEdit={!canEdit}>
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && (
                        <div className="space-y-2 p-6">
                            {endorsements.length ? (
                                <>
                                    {endorsements.map((endorsement, index) => (
                                        <EndorsementUnit
                                            key={index}
                                            index={index}
                                            endorsement={endorsement}
                                            showEditable={isEdit}
                                            endorsementCodes={
                                                endorsementCodeOptions
                                            }
                                            refetch={refetch}
                                        />
                                    ))}
                                </>
                            ) : (
                                <p>
                                    This driver does not have any endorsements.
                                </p>
                            )}
                        </div>
                    )}
                    {isEdit && (
                        <div className="p-6">
                            <div className="flex flex-col gap-4">
                                <div className="w-full">
                                    {endorsements.map(
                                        (endorsement, index, arr) => (
                                            <>
                                                <EndorsementUnit
                                                    key={index}
                                                    index={index}
                                                    endorsement={endorsement}
                                                    showEditable={isEdit}
                                                    endorsementCodes={
                                                        endorsementCodeOptions
                                                    }
                                                    refetch={refetch}
                                                />
                                                {index !== arr.length - 1 && (
                                                    <div className="py-1" />
                                                )}
                                            </>
                                        )
                                    )}
                                </div>
                                {!isAddingNewEndorsement && (
                                    <Button
                                        variant="secondary"
                                        className="w-fit"
                                        onClick={() =>
                                            setIsAddingNewEndorsement(true)
                                        }
                                    >
                                        Add Endorsement
                                    </Button>
                                )}

                                {isAddingNewEndorsement && (
                                    <form
                                        onSubmit={handleSubmit(
                                            (data: DvlaEndorsement) =>
                                                handleAddEndorsement(
                                                    data,
                                                    setIsEdit
                                                )
                                        )}
                                    >
                                        <p className="text-xl font-semibold">
                                            New Endorsements
                                        </p>
                                        <Controller
                                            name="code"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <FormDropdown
                                                    label="Endorsement code"
                                                    options={
                                                        endorsementCodeOptions
                                                    }
                                                    value={value}
                                                    onChange={onChange}
                                                    pleaseSelectText="Select endorsement code"
                                                    required
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="offenceDate"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <FormDateInput
                                                    id="offenceDate"
                                                    label="Offence date"
                                                    value={value}
                                                    onChange={onChange}
                                                    error={
                                                        formState.errors
                                                            .offenceDate
                                                    }
                                                    maxDate={new Date()}
                                                    required
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="convictionDate"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <FormDateInput
                                                    id="convictionDate"
                                                    label="Conviction date"
                                                    value={value}
                                                    onChange={onChange}
                                                    error={
                                                        formState.errors
                                                            .convictionDate
                                                    }
                                                    maxDate={new Date()}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="disqualificationEndDate"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <FormDateInput
                                                    id="disqualificationEndDate"
                                                    label="Disqualification end date"
                                                    helperText="if applicable"
                                                    value={value}
                                                    onChange={onChange}
                                                    error={
                                                        formState.errors
                                                            .disqualificationEndDate
                                                    }
                                                    minDate={new Date()}
                                                />
                                            )}
                                        />
                                        <FormTextInput
                                            label="Penalty points"
                                            id="points"
                                            placeholder="Enter penalty points"
                                            error={formState.errors.points}
                                            autoFocus
                                            type="number"
                                            min={0}
                                            {...register("points")}
                                            required
                                        />
                                        <div className="mt-8 flex justify-between">
                                            <Button
                                                variant="tertiary"
                                                type="button"
                                                onClick={() => {
                                                    setIsAddingNewEndorsement(
                                                        false
                                                    );
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                loading={isAddingEndorsement}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </EditableContentBlock>
    );
};

export default EditEndorsementsForm;
