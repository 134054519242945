import { RadioGroup as HUIRadioGroup } from "@headlessui/react";
import { FieldError } from "react-hook-form";
import FormInputError from "./FormInputError";
import FormInputLabel from "./FormInputLabel";
import FormRadioButton from "./FormRadioButton";

type FormRadioGroupOption<T> = {
    id: T;
    label: string;
    caption?: string;
};

type FormRadioGroupProps<T> = {
    value?: T;
    options: FormRadioGroupOption<T>[];
    label?: string;
    error?: FieldError;
    onChange: (value: T) => void;
    required?: boolean;
};

const FormRadioGroup = <T extends number | string>({
    value,
    options,
    label,
    error,
    onChange,
    required,
}: FormRadioGroupProps<T>) => {
    return (
        <div>
            <div className="w-full">
                <div>
                    <HUIRadioGroup value={value} onChange={onChange}>
                        {label &&
                            <div className="pt-2">
                                <FormInputLabel required={required}>
                                    {label}
                                </FormInputLabel>
                            </div>}
                        <div className="flex">
                            {options.map((option) => (
                                <HUIRadioGroup.Option
                                    key={option.id}
                                    value={option.id}
                                    className="relative flex cursor-pointer rounded-sm gap-2 pt-2 pl-3"
                                >
                                    {({ active, checked }) => (
                                        <>
                                            <div className="flex flex-1 mb-2 md:mb-0">
                                                <FormRadioButton
                                                    checked={checked}
                                                    active={active}
                                                    isError={!!error}
                                                />
                                                <div className="relative flex-1 md:-top-[2px]">
                                                    <HUIRadioGroup.Label
                                                        as="p"
                                                        className="font-medium"
                                                    >
                                                        {option.label}
                                                    </HUIRadioGroup.Label>
                                                </div>
                                            </div>
                                            <div
                                                className="relative md:-top-[2px]"
                                            >
                                                <HUIRadioGroup.Label
                                                    as="p"
                                                    className="font-medium text-sm"
                                                >
                                                    {option.caption}
                                                </HUIRadioGroup.Label>
                                            </div>
                                        </>
                                    )}
                                </HUIRadioGroup.Option>
                            ))}
                        </div>
                    </HUIRadioGroup>
                </div>
            </div>
            <div className="pl-3 text-start">
            <FormInputError error={error} warningTriangle/>
            </div>
        </div>
    );
};

export default FormRadioGroup;
