import { Scroll } from "phosphor-react";
import { type MandateDetails } from "../../../models/api/admin/mandateDetails";
import { convertDateStringToReadableString } from "../../../utils/convertDateStringToReadableString";
import ActionableStatus from "../../ActionableStatus";
import ActionableStatusTag from "../../ActionableStatus/ActionableStatusTag";
import useDeclarationStatusActions from "./useDeclarationStatusActions";
import { ApiDriverByIdResponse } from "../../../models/api/admin/driver";

export type DeclarationStatusPendingProps = {
    mandateDetails: MandateDetails;
    driverData?: ApiDriverByIdResponse;
    isS2DUser: boolean;
    handleScrollToDocumentUpload: () => void;
};

const DeclarationStatusPending: React.FC<DeclarationStatusPendingProps> = ({
    mandateDetails,
    driverData,
    isS2DUser,
    handleScrollToDocumentUpload,
}) => {
    const {
        declarationId,
        declarationType,
        declarationRequested,
        licenceCountryOrigin,
        firstReminderScheduledDate,
        firstReminderSentDate,
        secondReminderScheduledDate,
        secondReminderSentDate,
        thirdReminderScheduledDate,
        thirdReminderSentDate,
    } = mandateDetails;

    const {
        resendEDeclarationAction,
        resendPaperDeclarationAction,
        switchToEDeclarationAction,
        switchToPaperDeclarationAction,
        uploadSignedDeclarationAction,
    } = useDeclarationStatusActions(
        declarationId,
        mandateDetails,
        false,
        handleScrollToDocumentUpload,
        driverData
    );

    const statusIndicators = new Array(0);
    if (declarationType === "PaperDeclaration") {
        statusIndicators.push(
            <ActionableStatusTag theme="white" text="Paper" />
        );
    }
    //status tag will either be pending or incomplete no matter the status
    if (thirdReminderSentDate) {
        statusIndicators.push(<ActionableStatusTag theme="incomplete" />);
    } else {
        statusIndicators.push(<ActionableStatusTag theme="pending" />);
    }

    const sendStatusActions = () => {
        if (declarationType === "EDeclaration") {
            return [resendEDeclarationAction, switchToPaperDeclarationAction];
        } else {
            return isS2DUser
                ? [
                      uploadSignedDeclarationAction,
                      resendPaperDeclarationAction,
                      switchToEDeclarationAction,
                  ]
                : [resendPaperDeclarationAction, switchToEDeclarationAction];
        }
    };
    const today = new Date();

    const showScheduledReminderInfo = (
        nextReminderScheduled: string | null,
        currentReminderSent: string | null
    ) => {
        if (!currentReminderSent || !nextReminderScheduled) return false;
        const currentReminderSentDate = new Date(currentReminderSent);
        const nextReminderScheduledDate = new Date(nextReminderScheduled);
        if (currentReminderSentDate > nextReminderScheduledDate) {
            console.error("Next reminder occurs before current reminder sent.");
        }

        const isNextReminderInTheFuture = today < nextReminderScheduledDate;

        return isNextReminderInTheFuture && !!currentReminderSent;
    };

    const StatusContent = () => {
        if (declarationType === "EDeclaration") {
            return (
                <div className="flex flex-col text-sm">
                    {/* if no reminders */}
                    {firstReminderScheduledDate &&
                        today < new Date(firstReminderScheduledDate) && (
                            <>
                                <span>
                                    E-Declaration request sent:{" "}
                                    {convertDateStringToReadableString(
                                        declarationRequested
                                    )}
                                </span>
                                <span>
                                    First reminder Scheduled:{" "}
                                    {convertDateStringToReadableString(
                                        firstReminderScheduledDate
                                    )}
                                </span>
                            </>
                        )}

                    {/* if first reminder sent and it's not past 2nd reminder sent date*/}
                    {showScheduledReminderInfo(
                        secondReminderScheduledDate,
                        firstReminderSentDate
                    ) && (
                        <>
                            <span>
                                First reminder sent:
                                {convertDateStringToReadableString(
                                    firstReminderSentDate
                                )}
                            </span>
                            <span>
                                Second reminder scheduled:{" "}
                                {convertDateStringToReadableString(
                                    secondReminderScheduledDate
                                )}
                            </span>
                        </>
                    )}

                    {/* if second reminder sent, and it's not past the 3rd Reminder scheduled date */}
                    {showScheduledReminderInfo(
                        thirdReminderScheduledDate,
                        secondReminderSentDate
                    ) && (
                        <>
                            <span>
                                Second reminder sent:{" "}
                                {convertDateStringToReadableString(
                                    secondReminderSentDate
                                )}
                            </span>
                            <span>
                                Third reminder scheduled:{" "}
                                {convertDateStringToReadableString(
                                    thirdReminderScheduledDate
                                )}
                            </span>
                        </>
                    )}

                    {/* if third reminder has been sent */}
                    {thirdReminderScheduledDate &&
                        today > new Date(thirdReminderScheduledDate) &&
                        thirdReminderSentDate && (
                            <>
                                <span>
                                    E-Declaration request sent:{" "}
                                    {convertDateStringToReadableString(
                                        declarationRequested
                                    )}
                                </span>

                                <span>
                                    Third reminder sent:{" "}
                                    {convertDateStringToReadableString(
                                        thirdReminderSentDate
                                    )}
                                </span>
                            </>
                        )}

                    {/*If no reminders scheduled*/}
                    {!firstReminderScheduledDate && (
                        <span>
                            E-Declaration request sent:{" "}
                            {convertDateStringToReadableString(
                                declarationRequested
                            )}
                        </span>
                    )}
                </div>
            );
        } else {
            return (
                <div className="flex flex-col text-sm">
                    <span>
                        Paper Declaration Requested:{" "}
                        {convertDateStringToReadableString(
                            declarationRequested
                        )}
                    </span>
                    <span>No signed declaration uploaded.</span>
                </div>
            );
        }
    };

    return (
        <ActionableStatus
            title="Declaration status"
            theme="grey"
            actionable
            statusIndicators={statusIndicators}
            actions={sendStatusActions()}
            hasEmailAddress={!!driverData?.emailAddress}
            declarationComplete={false}
            licenceCountryOrigin={licenceCountryOrigin ?? undefined}
        >
            <div className="flex items-center">
                <div className="pr-3">
                    <Scroll size={24} weight="duotone" />
                </div>
                <StatusContent />
            </div>
        </ActionableStatus>
    );
};

export default DeclarationStatusPending;
