import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    useGetCompanies,
    useDownloadCompaniesReport,
} from "../../../api/admin/company";
import {
    pluralTermForCompany,
    capitalisedPluralTermForCompany,
} from "../../../copy/sharedCopy";
import useDebounceValue from "../../../hooks/useDebounceValue";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { CompanyStatusFilters } from "../../../models/api/admin/company";
import { successToast, errorToast } from "../../../toast";
import { downloadBlobAsFile } from "../../../utils/downloadBlobAsFile";
import { useGetAllCompanyStats } from "../../../api/admin/companyStats";
import { CompanyStatsCategories } from "../../../models/api/admin/companyStats";
import { SEARCH_BAR_DEBOUNCE_MS } from "../../../constants/filterConstants";

export const useAdminCompanies = () => {
    const navigate = useNavigate();
    // Filtering:
    const [statusFilter, setStatusFilter] =
        useState<CompanyStatusFilters | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const query = useDebounceValue(searchQuery, SEARCH_BAR_DEBOUNCE_MS);

    // Pagination:
    const [pageNumber, setPageNumber] = useLocalStorage<number>(
        "companyTablePageNumber",
        1
    );
    const [pageSize, setPageSize] = useLocalStorage<number>(
        "companyTablePageSize",
        25
    );
    const [orderBy, setOrderBy] = useLocalStorage<string>(
        "companyTableOrderBy",
        "companyName"
    );

    // Page Data:
    const { companyList, companyListError, isCompanyListLoading } =
        useGetCompanies(pageNumber, pageSize, statusFilter, query);
    // save selected tab index and translate for api query
    const [tabOption, setTabOption] = useLocalStorage<number>(
        `ClientAnalysisSelectedTab`,
        0
    );
    const tabOptions = Object.values(CompanyStatsCategories);
    const currentOption = useMemo(() => tabOptions[tabOption], [tabOption]);
    const {
        allCompanyStatsData: tabbedStatsData,
        allCompanyStatsError: tabbedStatsError,
        isAllCompanyStatsLoading: isLoadingTabbedStats,
        refetchAllCompanyStats: refetchTabbedStats,
    } = useGetAllCompanyStats(undefined, undefined, currentOption);

    // Page CSV Report:
    const { getCompaniesReport: getCompaniesCsvReport } =
        useDownloadCompaniesReport();

    const [isCsvReportDownloading, setIsCsvReportDownloading] =
        useState<boolean>(false);

    const handleDownloadCsvReport = async () => {
        setIsCsvReportDownloading(true);
        try {
            const statusFiltersArray: CompanyStatusFilters[] = statusFilter ? [statusFilter] : [];
            const response = await getCompaniesCsvReport(query, statusFiltersArray);
            if (response.success) {
                downloadBlobAsFile(
                    response.content,
                    `${pluralTermForCompany}.csv`
                );
                successToast(
                    `successfully downloaded ${capitalisedPluralTermForCompany} report`
                );
                setTimeout(() => setIsCsvReportDownloading(false), 1000);
            }
        } catch (error) {
            setTimeout(() => setIsCsvReportDownloading(false), 1000);
            errorToast(
                `failed to download ${capitalisedPluralTermForCompany} report`
            );
        }
    };

    return {
        companyList,
        companyListError,
        isCompanyListLoading,
        tabbedStatsData,
        tabbedStatsError,
        isLoadingTabbedStats,
        tabOption,
        setTabOption,
        refetchTabbedStats,
        statusFilter,
        setStatusFilter,
        searchQuery,
        setSearchQuery,
        query,
        setPageNumber,
        pageSize,
        setPageSize,
        setOrderBy,
        isCsvReportDownloading,
        handleDownloadCsvReport,
        navigate,
    };
};
