import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../Button";
import FormTextInput from "../FormTextInput";
import EDeclarationFormLayout from "./EDeclarationFormLayout";
import {
    eDeclarationFormValidationSchemaFields,
    EDeclarationFormValues,
} from "./EDeclarationFormValues";
import { ApiPostcodeLookupResponse } from "../../models/api/postcodeLookup";
import { useState } from "react";
import PostcodeAddressLookup from "../PostcodeAddressLookup";

export type Step3AddressFields = Omit<
    EDeclarationFormValues,
    "driverId" | "employerPermission" | "confirmValidLicence"
>;

type Step3AddressProps = {
    defaultValues: Step3AddressFields;
    onSubmit: (value: Step3AddressFields) => void;
    onBackClick: () => void;
};

const validationSchema = yup.object().shape({
    address: eDeclarationFormValidationSchemaFields.address,
});

const Step3Address: React.FC<Step3AddressProps> = ({
    defaultValues,
    onSubmit,
    onBackClick,
}) => {
    const [selectedAddressValue, setSelectedAddressValue] = useState("");

    const { handleSubmit, formState, register, setValue, trigger } =
        useForm<Step3AddressFields>({
            mode: "onChange",
            resolver: yupResolver(validationSchema),
            defaultValues,
        });

    const handleAddressSelect = (address: ApiPostcodeLookupResponse) => {
        setSelectedAddressValue(address.summaryline);
        setValue("address", {
            address1: address.addressline1,
            address2: address.addressline2,
            address3: address.addressline3,
            address4: address.addressline4,
            addressTown: address.posttown,
            addressPostcode: address.postcode,
        });
        trigger("address");
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <EDeclarationFormLayout
                title="E-Declaration"
                subtitle="Your Details"
                description="Please enter the information requested below. To verify your identity, the information entered must match your DVLA record."
                content={
                    <div className="max-w-lg ">
                        <div className="">
                            <PostcodeAddressLookup
                                value={selectedAddressValue}
                                onSelect={handleAddressSelect}
                            />
                            <FormTextInput
                                label="Address line 1"
                                id="address1"
                                placeholder="Enter address line 1"
                                error={formState.errors.address?.address1}
                                {...register("address.address1")}
                                required
                            />
                            <FormTextInput
                                label="Address line 2"
                                id="address2"
                                placeholder="Enter address line 2"
                                error={formState.errors.address?.address2}
                                {...register("address.address2")}
                            />
                            <FormTextInput
                                label="Address line 3"
                                id="address3"
                                placeholder="Enter address line 3"
                                error={formState.errors.address?.address3}
                                {...register("address.address3")}
                            />
                            <FormTextInput
                                label="City/Town"
                                id="city"
                                placeholder="Enter city/town"
                                error={formState.errors.address?.addressTown}
                                {...register("address.addressTown")}
                                required
                            />
                            <FormTextInput
                                label="Postcode"
                                id="postcode"
                                placeholder="Enter postcode"
                                error={
                                    formState.errors.address?.addressPostcode
                                }
                                {...register("address.addressPostcode")}
                                required
                            />
                        </div>
                    </div>
                }
                buttons={
                    <div className="mt-8 flex justify-end gap-8">
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={onBackClick}
                        >
                            Back
                        </Button>
                        <Button type="submit" variant="primary">
                            Next
                        </Button>
                    </div>
                }
            />
        </form>
    );
};

export default Step3Address;
