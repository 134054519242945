import { useParams } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import {
    AllDriversAnalysisCharts,
    AnalysisChartType,
    GreyFleetVehicleAnalysisCharts,
} from "../../models/app/admin/analysisChart";
import { DriverCategory } from "../../utils/driverPageLocation";
import { capitaliseWord } from "../../utils/capitaliseWord";
import { addSpacesToKebabCase } from "../../utils/addSpacesToKebabCase";
import FormMessageBox from "../../components/FormMessageBox";
import DriverChartAnalysis from "../../components/ChartAnalysis/DriverChartAnalysis";
import CompanyDepartmentSelector from "../../components/ReusableComponents/CompanyDepartmentSelector/CompanyDepartmentSelector";
import Button from "../../components/Button";
import VehicleChartAnalysis from "../../components/ChartAnalysis/VehicleChartAnalysis";
import { useCompanyDepartmentSelector } from "../../components/ReusableComponents/CompanyDepartmentSelector";

type PageParams = {
    path: DriverCategory;
    chartType: AnalysisChartType;
};

const AdminAnalysisCharts = () => {
    const { path, chartType } = useParams<PageParams>();

    const definedChartType =
        chartType && Object.values(AnalysisChartType).includes(chartType);
    if (!definedChartType) {
        return (
            <PageLayout title={"invalid path"} header={false}>
                <FormMessageBox
                    variant="error"
                    title={"Page not found"}
                    message={"Please navigate elsewhere."}
                />
            </PageLayout>
        );
    }

    const driverType =
        path === "professional-drivers"
            ? capitaliseWord(addSpacesToKebabCase(path))
            : path === "drivers"
            ? "All drivers"
            : "Grey Fleet";

    const pageTitle =
        chartType &&
        path &&
        `${capitaliseWord(addSpacesToKebabCase(chartType))} (${driverType})`;

    const {
        selectedCompany,
        handleCompanyChange,
        currentDepartment: selectedDepartment,
        handleDepartmentChange,
    } = useCompanyDepartmentSelector();

    const showLicenceAnalysisPage =
        path === "drivers" || path === "professional-drivers";
    const showVehicleAnalysisPage = path === "greyfleet";

    return (
        <PageLayout
            title={pageTitle ?? ""}
            actions={[
                <CompanyDepartmentSelector
                    currentDepartment={selectedDepartment}
                    selectedCompany={selectedCompany}
                    handleCompanyChange={handleCompanyChange}
                    handleDepartmentChange={handleDepartmentChange}
                />,
                <>
                    {selectedCompany && (
                        <Button
                            variant="tertiary"
                            onClick={() => handleCompanyChange(null)}
                            className="min-w-fit grow justify-center md:flex-none"
                        >
                            Reset
                        </Button>
                    )}
                </>,
            ]}
        >
            <>
                {showLicenceAnalysisPage && (
                    <DriverChartAnalysis
                        chartType={chartType as AllDriversAnalysisCharts}
                        selectedCompany={selectedCompany}
                        selectedDepartment={selectedDepartment}
                    />
                )}
                {showVehicleAnalysisPage && (
                    <VehicleChartAnalysis
                        chartType={chartType as GreyFleetVehicleAnalysisCharts}
                        selectedCompany={selectedCompany}
                        selectedDepartment={selectedDepartment}
                    />
                )}
            </>
        </PageLayout>
    );
};

export default AdminAnalysisCharts;
