import { UseFormReturn, useWatch } from "react-hook-form";
import { AddDriverFormValues } from "./AddDriverFormValues";
import LinkedDriverSearchDropdown, {
    LinkedDriver,
} from "../LinkedDriverSearchDropdown";
import AccordionItem from "../AccordionItem";
import { FormSearchDropdownOption } from "../FormSearchDropdown/FormSearchDropdown";
import useAuth from "../../auth/useAuth";

type DriverLinkedDriverFieldsProps = {
    methods: UseFormReturn<AddDriverFormValues>;
};

const DriverLinkedDriverFields: React.FC<DriverLinkedDriverFieldsProps> = ({
    methods,
}) => {
    const handleLinkedDriverValues = (linkedDriver: LinkedDriver) => {
        const primaryDriverName =
            linkedDriver.firstName + " " + linkedDriver.lastName;
        methods.setValue("primaryDriverId", linkedDriver.driverId);
        methods.setValue("primaryDriverName", primaryDriverName);
        methods.trigger(["primaryDriverId", "primaryDriverName"]);
    };

    const handleLinkedDriverSelect = (
        value: string | null,
        option: FormSearchDropdownOption | null
    ) => {
        if (!value) {
            handleLinkedDriverValues({
                driverId: null,
                firstName: null,
                lastName: null,
            });
        } else {
            const selected: string[] = value.split(" ");
            const primaryDriver: LinkedDriver = {
                driverId: selected[0],
                firstName: selected[1],
                lastName: selected[2],
            };
            handleLinkedDriverValues(primaryDriver);
        }
    };

    // for if the user has an associate client
    const { userDetails } = useAuth();
    const userCompanyId = userDetails?.companyId;

    const control = methods.control;
    const primaryDriverName = useWatch({ control, name: "primaryDriverName" });
    // for if the user has no associate client
    const formCompanyId = useWatch({ control, name: "companyId" });

    const companyId = formCompanyId ?? userCompanyId ?? undefined;
    return (
        <AccordionItem title="Add linked driver">
            <LinkedDriverSearchDropdown
                value={primaryDriverName ?? ""}
                onChange={handleLinkedDriverSelect}
                companyId={companyId}
                disabled={!companyId}
            />
        </AccordionItem>
    );
};

export default DriverLinkedDriverFields;
