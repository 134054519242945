import { ApiCompanyThemeDto } from "../models/api/admin/themes";

const setCssVariable = (title: string, color: string) => {
    document.documentElement.style.setProperty(title, `#${color}`);
};

const handleThemeKey = (key: string) => {
    let moddedKey = key;

    // change to kebab-case
    moddedKey = key.split(/(?=[A-Z])/).join("-");
    // remove superfluous string
    moddedKey = moddedKey.replace("-Colour", "");
    // lowercase
    moddedKey = moddedKey.toLowerCase();

    return `--${moddedKey}`;
};

/* use this to set all css color variables 
    when a client has their own app theme */
export const handleTheme = (theme: ApiCompanyThemeDto) => {
    const themeArray = Object.entries(theme);

    themeArray.forEach(([key, value]) => {
        setCssVariable(handleThemeKey(key), value);
    });
};

//use this to remove custom theming, will remove all the css variables set in handleTheme
export const removeTheme = () => {
    document.querySelectorAll("[style]").forEach(el => el.removeAttribute("style"));
}