import { useIsExtraLargeScreen } from "../../hooks/screenSizes";

type ClickAwayOverlayProps = {
    isOpen: boolean;
    closeSideMenu: () => void;
};

const ClickAwayOverlay = ({
    isOpen: open,
    closeSideMenu: close,
}: ClickAwayOverlayProps) => {
    const isExtraLarge = useIsExtraLargeScreen();

    if (!open || isExtraLarge) return null;

    return (
        <div
            className="md:hidden fixed inset-0 bg-S2D-neutral-50/75 pointer-events-auto z-30"
            onClick={() => close()}
        />
    );
};

export default ClickAwayOverlay;
