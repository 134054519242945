import { CalendarBlank } from "phosphor-react";
import { type CompanyUpcomingChecksStats } from "../../models/api/admin/companyStats";
import StatisticSummaryBox from "../StatisticSummaryBox";
import { type CompanyUpcomingChecksDto } from "../../models/app/admin/companyDashboard";

type CompanyDashboardUpcomingChecksTabProps = {
    data?: CompanyUpcomingChecksStats;
    onLinkClick?: (key: keyof CompanyUpcomingChecksDto) => void;
};

const CompanyDashboardUpcomingChecksTab: React.FC<
    CompanyDashboardUpcomingChecksTabProps
> = ({ data, onLinkClick }) => {
    function getFutureMonth(monthsInTheFuture: number): string {
        const date = new Date();

        date.setMonth(date.getMonth() + monthsInTheFuture);

        const month = date
            .toLocaleString("default", { month: "short" })
            .toUpperCase();

        return month;
    }
    // NB avoiding 'flex-wrap' in the PDF report as it causes issues with domtoimage
    const compatibleStyle = onLinkClick
        ? "flex grow flex-wrap gap-4"
        : "flex flex-col gap-4 xl:flex-row";

    return (
        <div className={compatibleStyle}>
            <StatisticSummaryBox
                icon={CalendarBlank}
                iconColor="bg-S2D-neutral-100"
                label={`upcoming rechecks-${getFutureMonth(1)}`}
                statistic={data?.checksInOneMonth}
                onLinkClick={() =>
                    onLinkClick
                        ? onLinkClick("checksComingUpInOneMonth")
                        : undefined
                }
            />
            <StatisticSummaryBox
                icon={CalendarBlank}
                iconColor="bg-S2D-neutral-100"
                label={`upcoming rechecks-${getFutureMonth(2)}`}
                statistic={data?.checksInTwoMonths}
                onLinkClick={() =>
                    onLinkClick
                        ? onLinkClick("checksComingUpInTwoMonths")
                        : undefined
                }
            />
            <StatisticSummaryBox
                icon={CalendarBlank}
                iconColor="bg-S2D-neutral-100"
                label={`upcoming rechecks-${getFutureMonth(3)}`}
                statistic={data?.checksInThreeMonths}
                onLinkClick={() =>
                    onLinkClick
                        ? onLinkClick("checksComingUpInThreeMonths")
                        : undefined
                }
            />
        </div>
    );
};

export default CompanyDashboardUpcomingChecksTab;
