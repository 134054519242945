import * as yup from "yup";
import GreyFleetFormLayout from "./GreyFleetFormLayout";
import Button from "../Button";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../FormTextInput";
import {
    GreyFleetVehicleDetailsFormValidationSchemaFields,
    GreyFleetFormVehicleDetailsValues,
} from "./GreyFleetFormValues";
import FormDateInput from "../FormDateInput";
import React, { useEffect, useState } from "react";
import { MagnifyingGlass } from "phosphor-react";
import { useVehicleRegLookup } from "../../api/vehicleRegLookup";
import FormMessageBox from "../FormMessageBox";
import { anchorDate } from "../../utils/anchorDate";

export type Step2VehicleFields = Pick<
    GreyFleetFormVehicleDetailsValues,
    "vrm" | "make" | "taxExpiryDate" | "motExpiryDate"
>;

type Step2VehicleDetailsProps = {
    defaultValues: Step2VehicleFields;
    isLoading: boolean;
    onSubmit: (value: Step2VehicleFields) => void;
    company: string;
    department: string;
    setVehicleReg: (value: string) => void;
    vehicleExpiryErrorText: string;
    setVehicleExpiryErrorText: (value: string) => void;
    greyFleetToken: string;
};

type ValidationFailureResult = {
    isValid: false;
    errorMessage: string;
};

type ValidationSuccessResult = {
    isValid: true;
};

export type ValidationResult =
    | ValidationFailureResult
    | ValidationSuccessResult;

const validationSchema = yup.object().shape({
    vrm: GreyFleetVehicleDetailsFormValidationSchemaFields.vrm,
    make: GreyFleetVehicleDetailsFormValidationSchemaFields.make,
    taxExpiryDate:
        GreyFleetVehicleDetailsFormValidationSchemaFields.taxExpiryDate,
    motExpiryDate:
        GreyFleetVehicleDetailsFormValidationSchemaFields.motExpiryDate,
});

const Step2VehicleDetails: React.FC<Step2VehicleDetailsProps> = ({
    defaultValues,
    isLoading,
    onSubmit,
    company,
    department,
    setVehicleReg,
    vehicleExpiryErrorText,
    setVehicleExpiryErrorText,
    greyFleetToken,
}) => {
    const methods = useForm<Step2VehicleFields>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const { handleSubmit, formState, register, control, setValue } = methods;

    const [expiredTax, setExpiredTax] = useState(false);
    const [expiredMOT, setExpiredMOT] = useState(false);
    const [validatedVehicleReg, setValidatedVehicleReg] = useState<
        string | null
    >("");
    const [vehicleRegValidError, setVehicleRegValidError] = useState<
        string | undefined
    >(undefined);
    const [vehicleRegApiError, setVehicleRegApiError] = useState(false);

    const vehicleReg = useWatch({ control, name: "vrm" });
    useEffect(() => {
        setVehicleReg(vehicleReg);
    }, [vehicleReg]);

    const { vehicleRegLookupData, vehicleRegLookupIsLoading } =
        useVehicleRegLookup();

    const handleTaxExpired = (value: Date | null) => {
        if (value) {
            setExpiredTax(new Date(value) < new Date());
        }
    };

    const handleMOTExpired = (value: Date | null) => {
        if (value) {
            setExpiredMOT(new Date(value) < new Date());
        }
    };

    const handleVehicleRegLookup = async () => {
        if (!vehicleReg) return;
        setVehicleRegApiError(false);

        setValidatedVehicleReg(vehicleReg.toLocaleUpperCase());
        const response = await vehicleRegLookupData(
            vehicleReg.toLocaleUpperCase(),
            greyFleetToken
        );

        if (!response.success) {
            setVehicleRegApiError(true);
            return;
        }

        setValue("make", response.content.make);
        setValue("taxExpiryDate", response.content.taxExpiryDate);
        setValue("motExpiryDate", response.content.motExpiryDate);
        handleTaxExpired(response.content.taxExpiryDate);
        handleMOTExpired(response.content.motExpiryDate);
        setVehicleRegApiError(false);

        setValidatedVehicleReg(null);
    };

    const handleFormSubmit = async (value: Step2VehicleFields) => {
        onSubmit(value);
    };

    // if prefilled details, rechecked vrm against dvla details when the page loads
    useEffect(() => {
        if (vehicleReg) {
            handleVehicleRegLookup();
        }
    }, []);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter") {
            e.preventDefault();
            handleVehicleRegLookup();
        }
    };

    useEffect(() => {
        if (expiredTax && expiredMOT) {
            setVehicleExpiryErrorText("Road Tax and MOT");
            return;
        }
        if (expiredTax) {
            setVehicleExpiryErrorText("Road Tax");
            return;
        }
        if (expiredMOT) {
            setVehicleExpiryErrorText("MOT");
            return;
        } else {
            setVehicleExpiryErrorText("");
            return;
        }
    }, [expiredTax, expiredMOT]);

    return (
        <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="flex flex-1 flex-col"
        >
            <GreyFleetFormLayout
                title="Grey Fleet invitation"
                subtitle="Vehicle details"
                description="Please confirm your current registration number below."
                company={company}
                department={department}
                content={
                    <>
                        {vehicleRegApiError && (
                            <FormMessageBox
                                title={"Vehicle details could not be found"}
                                message={
                                    "If you are sure that you entered the correct registration number please enter the requested information manually."
                                }
                                variant="error"
                            />
                        )}
                        {vehicleExpiryErrorText && (
                            <FormMessageBox
                                title={`Your ${vehicleExpiryErrorText} ${
                                    validatedVehicleReg && !vehicleRegApiError
                                        ? `for ${vehicleReg.toLocaleUpperCase()}`
                                        : ""
                                } ${
                                    vehicleExpiryErrorText ===
                                    "Road Tax and MOT"
                                        ? "have"
                                        : "has"
                                } expired`}
                                message={`You can continue with this form now, but please update your ${vehicleExpiryErrorText} 
                                to be able to drive this vehicle for business purposes. You will receive a reminder in a week to come 
                                back to this form and re-enter your registration number. When you do so, the form will pre-populate 
                                with the updated ${vehicleExpiryErrorText} expiry ${
                                    vehicleExpiryErrorText ===
                                    "Road Tax and MOT"
                                        ? "dates"
                                        : "date"
                                }.`}
                                variant="error"
                            />
                        )}
                        <div className="sm:grid sm:grid-cols-2 sm:gap-8">
                            <div>
                                <div className="sm:flex sm:flex-1 sm:gap-4">
                                    <Controller
                                        name="vrm"
                                        control={methods.control}
                                        render={({
                                            field: { onChange, value },
                                        }) => {
                                            // Force registration number to uppercase
                                            const upperCaseVrm =
                                                value.toUpperCase();
                                            return (
                                                <FormTextInput
                                                    label="Vehicle registration number (number plate)"
                                                    id="vrm"
                                                    labelClassName="font-bold mx-0"
                                                    placeholder="Enter registration number"
                                                    error={
                                                        formState.errors.vrm ??
                                                        vehicleRegValidError
                                                    }
                                                    onChange={onChange}
                                                    value={upperCaseVrm}
                                                    noLabelTextWrap
                                                    required
                                                    helpText="For example, CU57ABC"
                                                    onKeyDown={(e) => {
                                                        handleKeyDown(e);
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                    <div className="flex justify-end sm:translate-y-9 ">
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            onClick={handleVehicleRegLookup}
                                            loading={vehicleRegLookupIsLoading}
                                            className="h-14"
                                        >
                                            <div className="pr-2">
                                                <MagnifyingGlass
                                                    weight="fill"
                                                    size={20}
                                                />
                                            </div>
                                            Search
                                        </Button>
                                    </div>
                                </div>
                                <FormTextInput
                                    label="Make of vehicle"
                                    required
                                    id="make"
                                    placeholder="Enter make and model"
                                    error={formState.errors.make}
                                    {...register("make")}
                                />
                                <Controller
                                    name="taxExpiryDate"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            required
                                            id="taxExpiryDate"
                                            label="Road Tax expiry date"
                                            value={anchorDate(value, "max") ?? null}
                                            onChange={(val) => {
                                                onChange(anchorDate(val, "max"));
                                            }}
                                            error={
                                                formState.errors.taxExpiryDate
                                            }
                                            onBlur={handleTaxExpired}
                                        />
                                    )}
                                />
                                <Controller
                                    name="motExpiryDate"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormDateInput
                                            required
                                            id="motExpiryDate"
                                            label="MOT expiry date"
                                            value={anchorDate(value, "max") ?? null}
                                            onChange={(val) => {
                                                onChange(anchorDate(val, "max"));
                                            }}
                                            error={
                                                formState.errors.motExpiryDate
                                            }
                                            onBlur={handleMOTExpired}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </>
                }
                buttons={
                    <div className="flex justify-end gap-8">
                        <Button
                            type="submit"
                            variant="primary"
                            loading={isLoading}
                        >
                            Next
                        </Button>
                    </div>
                }
            />
        </form>
    );
};

export default Step2VehicleDetails;
