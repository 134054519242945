import { IdentificationBadge } from "phosphor-react";
import { useInstantEmailAlert } from "../../api/admin/alerts";
import { AlertType } from "../../models/api/admin/alert";
import { errorToast, successToast } from "../../toast";
import { convertDateStringToReadableString } from "../../utils/convertDateStringToReadableString";
import ActionableStatus from "../ActionableStatus";
import {
    ActionableStatusThemes,
    StatusAction,
} from "../ActionableStatus/ActionableStatus";
import ActionableStatusTag from "../ActionableStatus/ActionableStatusTag";
import {
    LicenceStatusQualifier,
    LicenceStatus as LicenceStatusType,
    LicenceType,
} from "../../models/shared/statuses";

type LicenceStatusProps = {
    licenceNumber: string | null;
    licenceCountryOrigin: string | null;
    licenceExpiryDate: string | null;
    licenceStatus: LicenceStatusType | null;
    licenceType: LicenceType | null;
    licenceStatusQualifier: LicenceStatusQualifier | null;
    licenceExpiryReminderScheduledDate: string | null;
    disqualificationDate: string | null;
    disqualifiedUntil: string | null;
    isLicenceDueToExpire: boolean;
    driverId?: string;
};

type LicenceStatusDetails = {
    theme: ActionableStatusThemes;
    status: string;
    text?: string;
};

const getStatusIndicators = (
    licenceStatus: LicenceStatusType | null,
    licenceType: LicenceType | null,
    isLicenceDueToExpire: boolean,
    licenceExpired: boolean
): JSX.Element[] => {
    const statusIndicatorArray = new Array(0);
    if (licenceType) {
        statusIndicatorArray.push(
            <ActionableStatusTag text={licenceType} theme="white" />
        );
    }

    if (licenceExpired) {
        statusIndicatorArray.push(
            <ActionableStatusTag text={"Expired"} theme="safe2Drive" />
        );
    } else if (isLicenceDueToExpire) {
        statusIndicatorArray.push(
            <ActionableStatusTag text={"Due to Expire"} theme="safe2Drive" />
        );
    } else if (licenceStatus) {
        statusIndicatorArray.push(
            <ActionableStatusTag text={licenceStatus} theme="safe2Drive" />
        );
    } else {
        statusIndicatorArray.push(
            <ActionableStatusTag
                text={"No current licence"}
                theme="safe2Drive"
            />
        );
    }

    return statusIndicatorArray;
};

const LicenceStatus = ({
    licenceNumber,
    licenceCountryOrigin,
    licenceExpiryDate,
    licenceStatus,
    licenceType,
    licenceStatusQualifier,
    licenceExpiryReminderScheduledDate,
    disqualificationDate,
    disqualifiedUntil,
    isLicenceDueToExpire,
    driverId,
}: LicenceStatusProps) => {
    const getLicenceStatusDetails = (
        licenceStatus: LicenceStatusType | null,
        licenceType: LicenceType | null,
        isLicenceDueToExpire: boolean,
        licenceExpired: boolean
    ): LicenceStatusDetails => {
        let theme: ActionableStatusThemes = "grey";
        // Priority 1: Licence Status -> Priority 2: Expiry Date -> Priority 3: Licence Type
        if (licenceStatus === LicenceStatusType.Valid) {
            theme = "green";

            if (licenceExpired) {
                theme = "red";
            } else if (isLicenceDueToExpire) {
                theme = "yellow";
            } else {
                switch (licenceType) {
                    case LicenceType.Full:
                        theme = "green";
                        break;
                    case LicenceType.Provisional:
                        theme = "yellow";
                        break;
                    default:
                        theme = "red";
                        break;
                }
            }
        } else {
            theme = "red";
        }

        if (licenceStatus) {
            return {
                theme: theme,
                status: licenceStatus,
                text: licenceType ?? undefined,
            };
        } else {
            return {
                theme: "grey",
                status: "white",
                text: licenceType ?? undefined,
            };
        }
    };

    const licenceExpired = licenceExpiryDate
        ? new Date(licenceExpiryDate) < new Date()
        : false;

    const licenceStatusDetail = getLicenceStatusDetails(
        licenceStatus,
        licenceType,
        isLicenceDueToExpire,
        licenceExpired
    );
    const statusIndicators = getStatusIndicators(
        licenceStatus,
        licenceType,
        isLicenceDueToExpire,
        licenceExpired
    );

    const isActionable =
        licenceStatusDetail.status === "Due to expire" ||
        licenceStatusDetail.status === "Expired";

    const [instantEmailAlert] = useInstantEmailAlert();
    const modalButtonAction = async (alertIds: AlertType[]) => {
        if (!driverId) return;
        const response = await instantEmailAlert({
            driverId: driverId,
            dto: {
                alertIds: alertIds,
            },
        });

        if (response.success) {
            successToast("Instant email reminder sent");
        } else {
            errorToast("Failed to send instant email reminder");
        }
    };

    const actions: StatusAction[] = !isActionable
        ? []
        : [
            {
                dropdownItem: "sendInstantReminder",
                modalButtonAction: () =>
                    modalButtonAction(
                        licenceStatusDetail.status === "Due to expire"
                            ? [AlertType.PhotocardDueToExpire]
                            : [AlertType.PhotocardExpired]
                    ),
                modalHeading: "Send instant reminder?",
                modalButtonText: "Send instant reminder",
            },
        ];

    return (
        <ActionableStatus
            title="Licence status"
            theme={licenceStatusDetail.theme}
            actionable={isActionable}
            actions={actions}
            statusIndicators={statusIndicators}
        >
            <div className="flex items-center">
                <div className="pr-3">
                    <IdentificationBadge size={24} weight="duotone" />
                </div>
                <div className="text-sm">
                    {!licenceStatus ? (
                        <div>No current GB entitlement held</div>
                    ) : (
                        <>
                            {licenceNumber && <div>{licenceNumber}</div>}

                            {isLicenceDueToExpire && (
                                <>
                                    {licenceExpiryDate && !licenceExpired && (
                                        <div>
                                            Photocard expires:{" "}
                                            {convertDateStringToReadableString(
                                                licenceExpiryDate
                                            )}
                                        </div>
                                    )}
                                    {licenceExpired && (
                                        <div>
                                            Photocard expired:{" "}
                                            {convertDateStringToReadableString(
                                                licenceExpiryDate
                                            )}
                                        </div>
                                    )}
                                    {licenceExpiryReminderScheduledDate && (
                                        <div>
                                            Email reminder scheduled:{" "}
                                            {convertDateStringToReadableString(
                                                licenceExpiryReminderScheduledDate
                                            )}
                                        </div>
                                    )}
                                </>
                            )}

                            {licenceStatus === LicenceStatusType.Expired &&
                                licenceExpiryDate && (
                                    <div>
                                        Email reminder sent:{" "}
                                        {convertDateStringToReadableString(
                                            licenceExpiryDate
                                        )}
                                    </div>
                                )}

                            {licenceStatus === LicenceStatusType.Valid && (
                                <>
                                    {licenceCountryOrigin && (
                                        <div>
                                            Origin: {licenceCountryOrigin}
                                        </div>
                                    )}
                                    {licenceExpiryDate && !isLicenceDueToExpire && (
                                        <div>
                                            Photocard expires:{" "}
                                            {convertDateStringToReadableString(
                                                licenceExpiryDate
                                            )}
                                        </div>
                                    )}
                                </>
                            )}

                            {licenceStatus === LicenceStatusType.Revoked && (
                                <div>
                                    {licenceStatusQualifier ??
                                        "No details provided"}
                                </div>
                            )}

                            {licenceStatus ===
                                LicenceStatusType.Disqualified && (
                                    <>
                                        {disqualificationDate && (
                                            <div>
                                                Date disqualified:{" "}
                                                {convertDateStringToReadableString(
                                                    disqualificationDate
                                                )}
                                            </div>
                                        )}
                                        {disqualifiedUntil && (
                                            <div>
                                                Disqualified until:{" "}
                                                {convertDateStringToReadableString(
                                                    disqualifiedUntil
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                        </>
                    )}
                </div>
            </div>
        </ActionableStatus>
    );
};

export default LicenceStatus;
