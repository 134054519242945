import useAuth from "../../auth/useAuth";

const UserImpersonationBar: React.FC = () => {
    const { userDetails, restoreAuthUser } = useAuth();

    return (
        <header className=" fixed bottom-0 left-0 z-40 flex h-[3rem] w-full flex-row items-center justify-between border-b border-b-S2D-dark-green-30 bg-S2D-dark-green-40 px-6">
            <p className="text-S2D-neutral-99">
                You are currently logged in as <b>{userDetails?.userName}</b>
            </p>
            <div className="flex flex-row gap-2">
                <button
                    className="font-bold text-S2D-light-green-80 underline "
                    onClick={() =>
                        restoreAuthUser(() => window.location.reload())
                    }
                >
                    Click here
                </button>
                <p className="text-S2D-neutral-99">to return to your account</p>
            </div>
        </header>
    );
};

export default UserImpersonationBar;
