import EditableContentBlock from "../../../EditableContentBlock";
import { EditCompanyFormValues } from "../../EditCompanyFormValues";
import { useState } from "react";
import { useEditAccountInfoForm } from "./useEditAccountInfoForm";
import { formatDateToString } from "../../../../utils/formatDateToString";

interface EditAccountInfoFormProps {
    initialValues: EditCompanyFormValues;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetFormCallback: () => void
    ) => void;
}

export default function EditAccountInfoForm({
    initialValues,
    handleEditCompany,
}: EditAccountInfoFormProps) {
    const [userSearch, setUserSearch] = useState<string>("");

    const { methods, isLoading, displayedValues } =
        useEditAccountInfoForm(initialValues);
    const { handleSubmit, formState, setValue, trigger, reset } = methods;

    return (
        <EditableContentBlock
            title="Account Information"
            onClosed={() => reset()}
            disableEdit
        >
            {({ isEdit, setIsEdit }) => (
                <>
                    {!isEdit && nonEditableContent()}

                    {/* {isEdit && editableContent(setIsEdit)} */}
                </>
            )}
        </EditableContentBlock>
    );

    // function editableContent(setIsEdit: (isEdit: boolean) => void) {
    //     const onSubmit = (data: EditCompanyFormValues) => {
    //         alert(
    //             "Api to be implemented. Data to submit: " + JSON.stringify(data)
    //         );

    //         const partialCompanyData: Partial<ApiCompanyResponse> = {
    //             ...data,
    //         };
    //         // TODO 01/05/23: uncomment once connected:
    //         // isLoading.current = true;
    //         // handleEditCompany(partialCompanyData, resetAndCloseForm);
    //     };

    //     const resetAndCloseForm = () => {
    //         // Timing out, in-case the hook's useEffect is not triggered
    //         // (0ms as the component will then update when its props update)
    //         setTimeout(() => (isLoading.current = false), 0);
    //         reset(displayedValues);
    //         setIsEdit(false);
    //     };
    //     const handleSelect = (newUserId: string | null) => {
    //         if (newUserId) setValue("accountManager", newUserId);
    //         trigger(["accountManager"]);
    //         console.log("newUserId", newUserId);
    //         setUserSearch("");
    //     };
    //     return (
    //         <div className="pb-4 pt-6 px-6">
    //             <form onSubmit={handleSubmit(onSubmit)}>
    //                 <div className="flex flex-col space-y-3">
    //                     {/* this was in the designs as a field... changed to display only: */}
    //                     <FormDateInput
    //                         id="createdAt"
    //                         label="Date Created"
    //                         value={initialValues.createdAt || null}
    //                         disabled={true}
    //                         onChange={() => ""}
    //                     />
    //                     <UserSearchDropdown
    //                         value={userSearch}
    //                         onChange={handleSelect}
    //                         error={formState.errors.accountManager}
    //                         label="Account Manager"
    //                     />
    //                 </div>

    //                 <div className="pt-7" />
    //                 <SubmitOrCancelButtonBar
    //                     isLoading={isLoading.current}
    //                     onCancel={resetAndCloseForm}
    //                 />
    //             </form>
    //         </div>
    //     );
    // }

    function nonEditableContent() {
        // const { accountManager } = displayedValues;
        const { createdAt } = initialValues;
        return (
            <div className="space-y-2 p-6">
                <p>
                    <b>Date account opened: </b>
                    {createdAt
                        ? formatDateToString(new Date(createdAt))
                        : "No date saved"}
                </p>

                {/* <p>
                    <b>Account Manager: </b>
                    {isLoading.current && <LoadingMessage />}
                    {!isLoading.current &&
                        (accountManager ?? "No account manager saved")}
                </p> */}
            </div>
        );
    }
}
