export enum PrebuiltReports {
    Drivers = "Drivers",
    Endorsements = "Endorsements",
    Categories = "Categories",
    CategoriesDueToExpire = "CategoriesDueToExpire",
    CpcAndTacho = "CpcAndTacho",
    GreyFleet = "GreyFleet",
}

export enum GreyFleetReportTypes { 
   XLS = "xls",
   CSV = "csv" 
}