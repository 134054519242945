import { Controller, useForm } from "react-hook-form";
import { ApiDriverByIdResponse } from "../../models/api/admin/driver";
import { DriverValidationTypes } from "../../models/app/admin/company";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DialogBox from "../DialogBox";
import FormRadioGroup from "../FormRadioGroup";
import useAuth from "../../auth/useAuth";
import { isS2dUser } from "../../auth/userAccessHelpers";
import { errorToast, successToast } from "../../toast";
import { useDriver } from "../../api/admin/driver";
import { DeclarationType } from "../../models/app/eDeclarations";
import FormMessageBox from "../FormMessageBox";
import Button from "../Button";

type ValidateByModalProps = {
    isOpen: boolean;
    onClose: () => void;
    driverData?: ApiDriverByIdResponse;
    validateByCompanyPreference?: DriverValidationTypes;
};

type ValidateByModalFormValues = {
    driverValidationType: DriverValidationTypes;
};

const validationSchema = yup.object().shape({
    driverValidationType: yup.string().required(),
});

const ValidateByModal: React.FC<ValidateByModalProps> = ({
    isOpen,
    onClose,
    driverData,
    validateByCompanyPreference,
}) => {
    const { decodedToken } = useAuth();
    const { sendDeclaration, isSendingDeclaration, refetch } = useDriver(
        driverData?.driverId
    );

    const { handleSubmit, control } = useForm<ValidateByModalFormValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            driverValidationType:
                validateByCompanyPreference ??
                DriverValidationTypes.DateOfBirth,
        },
    });

    const handleSendDeclaration = async (data: ValidateByModalFormValues) => {
        if (!driverData) {
            errorToast("Error loading driver data, please try again.");
            return;
        }
        // since this is how we'll be validating the e-dec,
        // we'll need to check if the required values are present in the driver data
        if (
            data.driverValidationType === DriverValidationTypes.DateOfBirth &&
            !driverData.dateOfBirth
        ) {
            errorToast(
                "The driver requires a date of birth in order to be validated this way."
            );
            return;
        }
        if (
            data.driverValidationType === DriverValidationTypes.EmployeeId &&
            !driverData.employeeId
        ) {
            errorToast(
                "The driver requires an employee ID in order to be validated this way."
            );
            return;
        }

        const response = await sendDeclaration(driverData.driverId, {
            declarationType: DeclarationType.EDeclaration,
            driverValidationType: data.driverValidationType,
        });

        if (!response.success) {
            errorToast("Failed to create a new declaration");
            return;
        }

        successToast("Successfully created a new declaration");
        refetch();
        onClose();
    };

    const isClientPreferredValue =
        "This is the client's chosen preferred value.";

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={() => onClose()}
            title="Send new E-Declaration request?"
            size="medium"
        >
            <form
                onSubmit={handleSubmit(handleSendDeclaration)}
                className="p-4"
            >
                {!validateByCompanyPreference ? (
                    <FormMessageBox
                        variant="error"
                        title={"Error loading Client data for this driver"}
                        message={"Please try again."}
                    />
                ) : (
                    <>
                        {isS2dUser(decodedToken?.role) && (
                            <Controller
                                name="driverValidationType"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <FormRadioGroup
                                        label="Choose validation type"
                                        options={[
                                            {
                                                id: DriverValidationTypes.DateOfBirth,
                                                label: "Date of birth",
                                                caption:
                                                    validateByCompanyPreference ===
                                                    DriverValidationTypes.DateOfBirth
                                                        ? isClientPreferredValue
                                                        : "",
                                            },
                                            {
                                                id: DriverValidationTypes.EmployeeId,
                                                label: "Employee ID",
                                                caption:
                                                    validateByCompanyPreference ===
                                                    DriverValidationTypes.EmployeeId
                                                        ? isClientPreferredValue
                                                        : "",
                                            },
                                        ]}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        )}
                        <div className="flex flex-col items-center justify-center gap-2">
                            <Button
                                type="submit"
                                loading={isSendingDeclaration}
                            >
                                Send new EDeclaration request
                            </Button>
                            <Button
                                type="button"
                                variant="tertiary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </>
                )}
            </form>
        </DialogBox>
    );
};

export default ValidateByModal;
