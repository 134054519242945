import { useNavigate } from "react-router-dom";
import { appPaths } from "../../Routes";
import { ApiDriverDetailsResponse } from "../../models/api/admin/driver";
import { errorToast, successToast } from "../../toast";
import { GreyFleetMenuKeys } from "./greyFleetTableMenuOptions";
import { useGreyFleet } from "../../api/greyFleet";
import { useState } from "react";
import { driverListFiltersDefaultValues } from "../DriverListFilters/DriversListFiltersForm/driversListFiltersDefaultValues";
import { DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm";

export const useGreyFleetTableActions = (
    handleFetchDrivers: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>
) => {
    const filterDefaultValues = driverListFiltersDefaultValues("greyfleet");
    const handleRefetch = () => handleFetchDrivers(filterDefaultValues);

    const navigate = useNavigate();
    const {
        incompleteReminder,
        isSendingIncompleteReminder,
        verifyInsurance,
        isVerifyingInsurance,
        removeVehicle,
        isRemovingVehicle,
    } = useGreyFleet();

    const handleSendGreyFleetReminder = async (
        data: ApiDriverDetailsResponse
    ) => {
        const inviteId = data.greyFleetInviteId;
        if (!inviteId) {
            errorToast("This driver does not have a grey fleet invitation");
            return;
        }

        const response = await incompleteReminder(inviteId);
        if (!response.success) {
            errorToast("Failed to send email reminder, please try again.");
            return;
        }

        successToast("Email reminder sent.");
    };

    const [isVerifyInsuranceDialogOpen, setVerifyInsuranceDialogOpen] =
        useState(false);
    const [selectedDriverData, setSelectedDriverData] =
        useState<ApiDriverDetailsResponse | null>(null);
    const handleVerifyInsurance = async (
        vehicleId: string | null,
        inviteId?: string
    ) => {
        if (!inviteId || !vehicleId) {
            errorToast("Failed to load driver data, please try again.");
            return;
        }

        const response = await verifyInsurance(vehicleId, inviteId);

        if (!response.success) {
            errorToast("Failed to verify insurance, please try again.");
            return;
        }

        successToast("Successfully verified vehicle insurance.");
        handleRefetch();
        setVerifyInsuranceDialogOpen(false);
    };

    const [isRemoveVehicleDialogOpen, setRemoveVehicleDialogOpen] =
        useState(false);
    const handleRemoveVehicle = async (
        vehicleId: string | null,
        inviteId?: string
    ) => {
        if (!inviteId || !vehicleId) {
            errorToast("Failed to load driver data, please try again.");
            return;
        }

        const response = await removeVehicle(vehicleId, inviteId);
        if (!response.success) {
            errorToast("Failed to remove vehicle, please try again.");
            return;
        }

        successToast(`Successfully removed vehicle.`);
        handleRefetch();
        setRemoveVehicleDialogOpen(false);
    };

    const handleTableMenuSelect = (
        data: ApiDriverDetailsResponse,
        key: GreyFleetMenuKeys
    ) => {
        switch (key) {
            case GreyFleetMenuKeys.editDriver:
                navigate(appPaths.adminEditDriver(data.driverId));
                break;
            case GreyFleetMenuKeys.emailReminder:
                handleSendGreyFleetReminder(data);
                break;
            case GreyFleetMenuKeys.markVerified:
                setSelectedDriverData(data);
                setVerifyInsuranceDialogOpen(true);
                break;
            case GreyFleetMenuKeys.removeVehicle:
                setSelectedDriverData(data);
                setRemoveVehicleDialogOpen(true);
                break;
        }
    };

    const isLoadingTableMenu =
        isSendingIncompleteReminder ||
        isVerifyingInsurance ||
        isRemovingVehicle;

    return {
        handleTableMenuSelect,
        isLoadingTableMenu,
        isVerifyInsuranceDialogOpen,
        setVerifyInsuranceDialogOpen,
        isRemoveVehicleDialogOpen,
        setRemoveVehicleDialogOpen,
        selectedDriverData,
        resetSelectedDriverData: () => setSelectedDriverData(null),
        handleVerifyInsurance,
        handleRemoveVehicle,
    };
};
