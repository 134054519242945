import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDriver } from "../../api/admin/driver";
import AuditTable from "../../components/AuditTable";
import DriverProfileHeader from "../../components/DriverProfileHeader";
import FormMessageBox from "../../components/FormMessageBox";
import PageLayout from "../../components/PageLayout";
import useLocalStorage from "../../hooks/useLocalStorage";
import { type DateTimeRange, useAudits } from "../../api/admin/audits";
import { formatDateToString } from "../../utils/formatDateToString";
import LoadingMessage from "../../components/ReusableComponents/LoadingMessage/LoadingMessage";
import { type AuditAction } from "../../models/api/admin/audits";
import Button from "../../components/Button";
import { UndeleteDriverDto } from "../../models/app/admin/driver";
import { successToast } from "../../toast";

type PageParams = {
    id: string;
};

const AdminAuditDriver = () => {
    const { id } = useParams<PageParams>();

    // Storing pageSize in local storage but not pageNumber as this page
    // will be used for various drivers with varying lenghts of audit trail.
    // The user should start at the top of the list every time,
    // but probably has a persisting preference for page size.

    const [pageSize, setPageSize] = useLocalStorage<number>(
        "driverAuditListPageSize",
        25
    );
    const [pageNumber, setPageNumber] = useState<number>(1);

    // query params
    const [dateTimeRange, setDateTimeRange] = useState<DateTimeRange>({
        from: null,
        to: null,
    });
    const [userId, setUserId] = useState<string>();
    // TODO add query and action to audits endpoint once implemented in api
    const [query, setQuery] = useState<string>("");
    const [actionFilter, setActionFilter] = useState<AuditAction | null>(null);
    const [isUndeletingDriver, setIsUndeletingDriver] =
        useState<boolean>(false);

    const { auditsData, auditsError, isLoadingAudits, refetchAudits } = useAudits(
        {
            entityName: "DriverEntity",
            entityId: id,
            userId: userId,
            dateTimeRange: dateTimeRange,
            includeRelatedEntities: true,
        },
        pageNumber,
        pageSize
    );

    const { driverData, deleteDriver, editDriver, refetch, isDeletingDriver } = useDriver(id);

    const isDriverDeleted = driverData?.deletedAt ? true : false;

    const handleUndeleteDriver = async () => {
        setIsUndeletingDriver(true);
        if (id) {
            try {
                const undeleteDriverDto: UndeleteDriverDto = {
                    isDeleted: false,
                };
                await editDriver(id, undeleteDriverDto);
                await refetch();
                await refetchAudits();
                setIsUndeletingDriver(false);
                successToast(`Successfully undeleted driver.`);
            } catch (error) {
                console.error("Error undeleting driver", error);
            } finally {
                setIsUndeletingDriver(false);
            }
        } else {
            setIsUndeletingDriver(false);
        }
    };

    return (
        <PageLayout title="Driver Audit">
            <div className="mx-auto w-full">
                {isLoadingAudits && <LoadingMessage />}
                {auditsError && (
                    <FormMessageBox
                        title={"error"}
                        message={
                            <span>
                                There was an error loading driver audit data.
                                <br />
                                Please try again in 10 minutes.
                            </span>
                        }
                        variant={"error"}
                    />
                )}
                <>
                    {driverData && (
                        <>
                            {isDriverDeleted && (
                                <FormMessageBox
                                    variant="error"
                                    title={"Driver deleted"}
                                    message={
                                        "This driver record has been deleted and cannot be updated without being restored."
                                    }
                                    actions={
                                        <Button
                                            className="border-S2D-error-40 bg-S2D-error-90 p-3"
                                            onClick={handleUndeleteDriver}
                                            loading={isUndeletingDriver}
                                        >
                                            Undelete
                                        </Button>
                                    }
                                />
                            )}
                            <DriverProfileHeader
                                {...driverData}
                                isDeletingDriver={isDeletingDriver}
                                id={id}
                                deleteDriver={deleteDriver}
                                isDriverDeleted={isDriverDeleted}
                            />
                            {/* Profile History Summary */}

                            <p className="pb-2 font-semibold">
                                Profile History
                            </p>

                            <div className="mb-8 border-b border-S2D-neutral-80"></div>

                            <div className="mb-8 rounded-sm border border-S2D-neutral-80 bg-S2D-dark-green-70.1 p-4">
                                <p className="pb-1 font-semibold">
                                    Created by:{" "}
                                    <span className="font-light">
                                        {driverData.createdByUser &&
                                            `${driverData.createdByUser.firstName} ${driverData.createdByUser.lastName}`}
                                        {!driverData.createdByUser && "Unknown"}
                                    </span>
                                </p>
                                <p className="pb-1 font-semibold">
                                    Date Created:{" "}
                                    <span className="font-light">
                                        {driverData.dateCreated &&
                                            formatDateToString(
                                                new Date(
                                                    driverData.dateCreated
                                                ),
                                                true
                                            )}
                                        {!driverData.dateCreated && ""}
                                    </span>
                                </p>
                                <p className="pb-1 font-semibold">
                                    Last Edited by:{" "}
                                    <span className="font-light">
                                        {driverData.lastUpdatedByUser &&
                                            `${driverData.lastUpdatedByUser.firstName} ${driverData.lastUpdatedByUser.lastName}`}
                                        {!driverData.lastUpdatedByUser && ""}
                                    </span>
                                </p>
                                <p className="pb-1 font-semibold">
                                    Date last edited:{" "}
                                    <span className="font-light">
                                        {driverData.dateLastUpdated &&
                                            formatDateToString(
                                                new Date(
                                                    driverData.dateLastUpdated
                                                ),
                                                true
                                            )}
                                        {!driverData.dateLastUpdated && ""}
                                    </span>
                                </p>
                            </div>
                        </>
                    )}
                    <AuditTable
                        auditList={auditsData}
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        searchQuery={query}
                        setSearchQuery={setQuery}
                        actionFilter={actionFilter}
                        setActionFilter={setActionFilter}
                        dateTimeRange={dateTimeRange}
                        setDateTimeRange={setDateTimeRange}
                        userId={userId}
                        setUserId={setUserId}
                    />
                </>
            </div>
        </PageLayout>
    );
};

export default AdminAuditDriver;
