import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";
import { useIsExtraLargeScreen } from "../../hooks/screenSizes";
import useLocalStorage from "../../hooks/useLocalStorage";
import ClickAwayOverlay from "./ClickAwayOverlay";
import PageHeader from "./PageHeader";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import UserImpersonationBar from "./UserImpersonationBar";
import useAuth from "../../auth";

export type PageLayoutProps = {
    title: string;
    children: React.ReactElement;
    actions?: ReactNode[];
    header?: boolean;
};

const PageLayout: React.FC<PageLayoutProps> = ({
    title,
    children,
    actions,
    header = true,
}) => {
    const [navIsExpanded, setNavIsExpanded] = useLocalStorage(
        "navIsExpanded",
        true
    );
    const isExtraLarge = useIsExtraLargeScreen();

    const toggleNavExpansion = () => setNavIsExpanded((prev) => !prev);

    const { storedAuthorization } = useAuth();

    return (
        <Disclosure as="div" defaultOpen={isExtraLarge}>
            {({ open, close }) => (
                <>
                    <TopNav />
                    <ClickAwayOverlay isOpen={open} closeSideMenu={close} />
                    <SideNav
                        open={open}
                        expanded={navIsExpanded}
                        closeSideMenu={close}
                        toggleNavExpansion={toggleNavExpansion}
                    />
                    <main
                        className={clsx(
                            "w-full bg-S2D-neutral-95 pt-[4.25rem]",
                            navIsExpanded ? "md:pl-[16.5rem]" : "md:pl-[4.5rem]"
                        )}
                    >
                        <div className="min-h-screen bg-white px-6">
                            {header && (
                                <PageHeader title={title} actions={actions} />
                            )}
                            <div className={clsx(!header && "pt-6")}>
                                {children}
                            </div>
                        </div>
                    </main>
                    {storedAuthorization && <UserImpersonationBar />}
                </>
            )}
        </Disclosure>
    );
};

export default PageLayout;
