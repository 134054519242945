// 19/09 licence statuses taken from
// https://developer-portal.driver-vehicle-licensing.api.gov.uk/apis/driver-view/driver-view-external-1.5.0.html#tocSlicencetype
export enum LicenceType {
    Full = "Full",
    Provisional = "Provisional",
}

export enum LicenceStatus {
    Valid = "Valid",
    Disqualified = "Disqualified",
    Revoked = "Revoked",
    Surrendered = "Surrendered",
    Expired = "Expired",
    Exchanged = "Exchanged",
    Refused = "Refused",
}

export enum LicenceStatusQualifier {
    ReAssess = "For re-assessment only",
    PhotoLicenceInvite = "Photo licence invitation sent",
    ShortPeriodDis = "Short period disqualification",
    UntilPassed = "Until test passed",
    UntilExtendedPassed = "Until extended test passed",
    PendingSentence = "Pending sentence",
    ForLife = "For life",
}

// ###################

export type CategoryStatus = "Full" | "Provisional";

// TBD - unspecified in designs
export type PhotocardStatus = "Full" | "Provisional";

export type TachocardStatus =
    | "Expired"
    | "Revoked"
    | "Full"
    | "Provisional"
    | "Disqualified";

export type CpcCardStatus =
    | "Expired"
    | "Revoked"
    | "Full"
    | "Provisional"
    | "Disqualified";

export type MotStatus = "Pass" | "Fail";

export type RoadTaxStatus = "Valid" | "Invalid";

export type InsuranceStatus = "Provided" | "Not provided";

export enum CheckStatus {
    Checked = "Checked",
    Scheduled = "Scheduled",
    Overdue = "Overdue",
    Disabled = "Disabled",
    Pending = "Pending",
    DvlaError500 = "Error500",
    DvlaError503 = "Error503",
    DvlaError400 = "Error400",
    DvlaError403 = "Error403",
    DvlaErrorOther = "ErrorOther",

    // 15/08/23 below are not specified in figma design but are used in DB and API so will break if not specified (currently)
    Failed = "Failed",
    None = "None",
}

export enum CheckType {
    Manual = "Manual",
    DVLA = "DVLA",
}

export enum DeclarationStatus {
    Completed = "Completed",
    Requested = "Requested",
    DueToExpire = "DueToExpire",
    Expired = "Expired",
    Revoked = "Revoked",
    None = "None",
}

export enum UserStatus {
    Live = "Live",
    Disabled = "Disabled",
}
