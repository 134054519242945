import { SimplifiedDecodedToken, UserRoles } from "./decodeToken";

export const hasClientManagementClaim = (
    decodedToken: SimplifiedDecodedToken | undefined
): boolean => {
    if (!decodedToken) return false;
    return (
        decodedToken?.clientManagement === "client" ||
        decodedToken?.clientManagement === "safe2drive" ||
        decodedToken.role === UserRoles.clientAdmin
    );
};

export const isS2dUser = (userRole?: UserRoles) => {
    switch (userRole) {
        case UserRoles.s2dAdmin:
        case UserRoles.s2dMember:
            return true;
        default:
            return false;
    }
};

export const canEditUser = (decodedToken?: SimplifiedDecodedToken) => {
    switch (decodedToken?.role) {
        case UserRoles.s2dAdmin:
        case UserRoles.clientAdmin:
            return true;
        default:
            return false;
    }
};

export const canEditPii = (decodedToken?: SimplifiedDecodedToken) => {
    return (
        decodedToken?.viewPii === "safe2drive" ||
        decodedToken?.viewPii === "client"
    );
};

export const hasS2dFileSafeClaim = (decodedToken?: SimplifiedDecodedToken) => {
    if (!decodedToken) return false;
    return decodedToken?.fileSafe === "safe2drive";
};

export const canControlClaims = (decodedToken?: SimplifiedDecodedToken) => {
    switch (decodedToken?.role) {
        case UserRoles.s2dAdmin:
        case UserRoles.s2dMember:
        case UserRoles.clientAdmin:
            return true;
        default:
            return false;
    }
};

export const hasClientManagementAccess = (decodedToken?: SimplifiedDecodedToken) => {
    return decodedToken?.role === UserRoles.clientAdmin || decodedToken?.role === UserRoles.clientMember;
};

export const isS2dAdminOrMember = (decodedToken?: SimplifiedDecodedToken) => {
    return decodedToken?.role === UserRoles.s2dAdmin || decodedToken?.role === UserRoles.s2dMember;
};