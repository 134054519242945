import * as yup from "yup";
import {
    EditAccountInfoSchema,
    EditCompanyFormValues,
} from "../../EditCompanyFormValues";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";

export const useEditAccountInfoForm = (
    initialValues: EditCompanyFormValues
) => {
    const validationSchema = yup.object().shape(EditAccountInfoSchema);

    const isLoading = useRef(false);

    type formValues = Pick<EditCompanyFormValues, "accountManager">;
    const displayedValues: formValues = {
        accountManager: initialValues.accountManager ?? undefined,
    };

    const methods = useForm<formValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues: displayedValues,
    });

    useEffect(() => {
        // if the values from the API update, update the form to reflect this:
        methods.reset(displayedValues);
        isLoading.current = false;
    }, [initialValues]);

    return {
        methods,
        isLoading,
        displayedValues,
    };
};
