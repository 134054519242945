import clsx from "clsx";
import { Circle, RadioButton } from "phosphor-react";

export type FormRadioButtonProps = {
    checked: boolean
    active: boolean
    isError: boolean
    disabled?: boolean;
}

const FormRadioButton = ({
    checked,
    active,
    isError,
    disabled,
}: FormRadioButtonProps) => {
    return (
        <>
            <div
                className={clsx(
                    "flex-none -translate-y-[2px] relative mr-2 w-6 h-6 rounded-full",
                    active &&
                        isError &&
                        "ring2 ring-S2D-error-40",
                    disabled && "opacity-40 cursor-not-allowed",
                )}
            >
                {!checked && (
                    <Circle
                        weight="regular"
                        className="w-6 h-6 fill-S2D-dark-green-30"
                    />
                )}
                {checked && (
                    <RadioButton
                        className="w-6 h-6 fill-S2D-dark-green-30"
                        weight="fill"
                    />
                )}
            </div>
        </>
    );
};

export default FormRadioButton;