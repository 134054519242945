import * as yup from "yup";
import { SchemaKeys } from "../models/shared/schemaKeysHelper";
import { Address } from "../models/shared/address";

const transformEmptyString = (value: string) => {
    if (value?.length !== 0) return value;
    return null;
};

export const postcodeValidationSchema = yup
    .string()
    .matches(
        /^([a-zA-Z][a-hj-yA-HJ-Y]?[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}|GIR ?0A{2})$/,
        "Please enter a valid postcode"
    );

// 18/04/23 This is the DTO format that the /companies/{companyId} endpoint currently expects
// we should endeavor to have all addresses throughout the app in this format
export const EditAddressSchema: SchemaKeys<Address> = {
    addressLine1: yup
        .string()
        .required("Address line 1 is required")
        .nullable()
        .transform(transformEmptyString),
    addressLine2: yup
        .string()
        .notRequired()
        .nullable()
        .transform(transformEmptyString),
    addressLine3: yup
        .string()
        .notRequired()
        .nullable()
        .transform(transformEmptyString),
    addressLine4: yup
        .string()
        .notRequired()
        .nullable()
        .transform(transformEmptyString),
    town: yup
        .string()
        .required("City/Town is required")
        .nullable()
        .transform(transformEmptyString),
    postcode: postcodeValidationSchema
        .required("Postcode is required.")
        .nullable()
        .transform(transformEmptyString),
};
