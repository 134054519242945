import * as yup from "yup";

// Custom schema to handle empty strings in number fields
export const nullableNumber = yup
    .number()
    .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
            return originalValue.trim() === "" ? null : value;
        }
        return value;
    })
    .nullable();
