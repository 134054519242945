import { SetStateAction, useState } from "react";
import DialogBox from "../DialogBox";
import EditStaffMemberForm from "./EditStaffMemberForm";
import { type S2DStaffMember } from "../../models/api/safe2DriveAdmin/staff";
import EditStaffHeader from "./EditStaffHeader";
import { UserStatus } from "../../models/shared/statuses";
import { EditStaffMemberDto } from "../../models/app/safe2DriveAdmin/staff";

type EditStaffMemberModalProps = {
    isOpen: boolean;
    setIsOpen: (value: SetStateAction<boolean>) => void;
    handleDelete: () => void;
    handleResetPassword: () => void;
    initialValues: S2DStaffMember;
    handleSubmit: (
        values: EditStaffMemberDto,
        callback?: () => void
    ) => Promise<void>;
    isEditingStaffMember: boolean;
    handleToggleStaffStatus: (
        status: UserStatus,
        staffMember: S2DStaffMember
    ) => void;
};

const EditStaffMemberModal: React.FC<EditStaffMemberModalProps> = ({
    isOpen,
    setIsOpen,
    handleDelete,
    handleResetPassword,
    handleSubmit,
    handleToggleStaffStatus,
    isEditingStaffMember,
    initialValues,
}) => {
    const handleCancel = () => {
        setIsOpen(false);
    };

    const [selectedTab, setSelectedTab] = useState<"edit" | "audit">("edit");
    // 18/09/23 removed staff audits page for v1.0 release

    // const [pageSize, setPageSize] = useLocalStorage<number>(
    //     "staffAuditListPageSize",
    //     25
    // );
    // const [pageNumber, setPageNumber] = useState<number>(1);

    // // query params
    // const [dateTimeRange, setDateTimeRange] = useState<DateTimeRange>({
    //     from: null,
    //     to: null,
    // });
    // const [userId, setUserId] = useState<string>();
    // TODO add query and action to audits endpoint once implemented in api
    // const [query, setQuery] = useState<string>("");
    // const [actionFilter, setActionFilter] = useState<AuditAction | null>(null);

    // 1/6/23 TODO entity doesn't exist yet

    // const { auditsData, auditsError, isLoadingAudits } = useAudits(
    //     {
    //         entityName: "StaffEntity",
    //         entityId: staffId,
    //         userId: userId,
    //         dateTimeRange: dateTimeRange,
    //         includeRelatedEntities: true,
    //     },
    //     pageNumber,
    //     pageSize
    // );

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={() => {
                setIsOpen(false);
                setSelectedTab("edit");
            }}
            title="Edit staff member"
            size="xl"
            closeButton
        >
            <EditStaffHeader
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                initialValues={initialValues}
            />
            {/* {selectedTab === "edit" && ( */}
            <EditStaffMemberForm
                onSubmit={handleSubmit}
                onDelete={handleDelete}
                onResetPassword={handleResetPassword}
                isLoading={isEditingStaffMember}
                onCancel={handleCancel}
                initialValues={initialValues}
                handleToggleStaffStatus={handleToggleStaffStatus}
            />
            {/* )} */}
            {/* {selectedTab === "audit" && (
                <div className="p-6">
                    <AuditTable
                        auditList={auditsData}
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        searchQuery={query}
                        setSearchQuery={setQuery}
                        actionFilter={actionFilter}
                        setActionFilter={setActionFilter}
                        dateTimeRange={dateTimeRange}
                        setDateTimeRange={setDateTimeRange}
                        userId={userId}
                        setUserId={setUserId}
                    />
                </div>
            )} */}
        </DialogBox>
    );
};

export default EditStaffMemberModal;
