import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../Button";
import FormTextInput from "../FormTextInput";
import EDeclarationFormLayout from "./EDeclarationFormLayout";
import {
    eDeclarationFormValidationSchemaFields,
    EDeclarationFormValues,
} from "./EDeclarationFormValues";
import licenseSample from "../../licence-sample.png";
import { useState } from "react";
import FormDropdown, { FormDropdownOption } from "../FormDropdown";
import { GREAT_BRITAIN, NORTHERN_IRELAND } from "../../constants/countries";
import { ArrowRight } from "phosphor-react";
import ConfirmForeignLicenceDialog from "./ConfirmForeignLicenceDialog";

export type Step2LicenceInformationFields = Omit<
    EDeclarationFormValues,
    "driverId" | "employerPermission" | "confirmValidLicence" | "address"
>;

type Step2LicenceInformationProps = {
    defaultValues: Step2LicenceInformationFields;
    onSubmit: (value: Step2LicenceInformationFields) => void;
    onForeignLicenceSubmit: (
        value: Step2LicenceInformationFields,
        errorCallback: () => void
    ) => void;
    onBackClick: () => void;
};

type LicenceOptions =
    | typeof GREAT_BRITAIN
    | typeof NORTHERN_IRELAND
    | "Foreign";

// when choosing "foreign licence", set foreignLicence to true and set licence origin to null
// this is handled when we submit the foreign value in the modal.
const licenceOriginOptions: FormDropdownOption<LicenceOptions>[] = [
    {
        label: "UK (DVLA)",
        value: GREAT_BRITAIN,
    },
    {
        label: "Northern Irish (DVA)",
        value: NORTHERN_IRELAND,
    },
    {
        label: "Foreign licence",
        value: "Foreign",
    },
];

const validationSchema = yup.object().shape({
    licenceNumber: eDeclarationFormValidationSchemaFields.licenceNumber,
    licenceCountryOrigin:
        eDeclarationFormValidationSchemaFields.licenceCountryOrigin,
});

const Step2LicenceInformation: React.FC<Step2LicenceInformationProps> = ({
    defaultValues,
    onSubmit,
    onForeignLicenceSubmit,
    onBackClick,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [foreignLicenceError, setForeignLicenceError] = useState(false);

    const { handleSubmit, formState, register, control, getValues } =
        useForm<Step2LicenceInformationFields>({
            mode: "onChange",
            resolver: yupResolver(validationSchema),
            defaultValues,
        });

    const licenceType = useWatch({ control, name: "licenceCountryOrigin" });
    let confirmLicenceString;
    if (licenceType === NORTHERN_IRELAND) {
        confirmLicenceString = "Confirm Northern Irish Licence";
    } else {
        confirmLicenceString = "Confirm Foreign Licence";
    }

    const nonUKLicence = licenceType !== GREAT_BRITAIN;

    const handleForeignLicenceSubmit = () => {
        const values = getValues();
        onForeignLicenceSubmit(values, () => setForeignLicenceError(true));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <EDeclarationFormLayout
                title="E-Declaration"
                subtitle="Your Details"
                description="Please enter the information requested below. To verify your identity, the information entered must match your DVLA record."
                content={
                    <div className="flex max-w-lg flex-col gap-4">
                        <Controller
                            name="licenceCountryOrigin"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormDropdown
                                    label={
                                        "Which type of driving licence do you have?"
                                    }
                                    options={licenceOriginOptions}
                                    value={value}
                                    onChange={onChange}
                                    pleaseSelectText={`Select driving licence type`}
                                    error={
                                        formState.errors.licenceCountryOrigin
                                    }
                                    required
                                />
                            )}
                        />
                        {!nonUKLicence && (
                            <FormTextInput
                                label="Licence Number"
                                required
                                id="licence-number"
                                placeholder="Enter licence number"
                                error={formState.errors.licenceNumber}
                                helpText="Licence number must be 16 characters with no spaces"
                                {...register("licenceNumber")}
                                moreInfo={{
                                    dialogPrompt: "Where is my licence number?",
                                    content: (
                                        <div className="flex flex-col gap-4">
                                            <p className="text-sm">
                                                Your licence number can be found
                                                on your UK Driving Licence at
                                                the position illustrated below,
                                                this will be 16 characters with
                                                no spaces.
                                            </p>
                                            <img
                                                className="w-full max-w-xl"
                                                src={licenseSample}
                                                alt="licence sample MORGA753116SM9IJ"
                                            />
                                        </div>
                                    ),
                                }}
                            />
                        )}
                        {nonUKLicence && (
                            <>
                                <Button
                                    className="flex w-full justify-center"
                                    onClick={() => setIsDialogOpen(true)}
                                    iconEnd
                                    icon={ArrowRight}
                                    type="button"
                                >
                                    {confirmLicenceString}
                                </Button>
                                <ConfirmForeignLicenceDialog
                                    isOpen={isDialogOpen}
                                    onClose={() => setIsDialogOpen(false)}
                                    licenceOrigin={
                                        licenceType as
                                            | typeof NORTHERN_IRELAND
                                            | "Foreign"
                                    }
                                    handleForeignLicenceSubmit={
                                        handleForeignLicenceSubmit
                                    }
                                    error={foreignLicenceError}
                                />
                            </>
                        )}
                    </div>
                }
                buttons={
                    <div className="mt-8 flex justify-end gap-6">
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={onBackClick}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={nonUKLicence}
                        >
                            Next
                        </Button>
                    </div>
                }
            />
        </form>
    );
};

export default Step2LicenceInformation;
