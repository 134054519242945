import { Controller, useWatch } from "react-hook-form";
import FormTextInput from "../../FormTextInput";
import FormDropdown from "../../FormDropdown";
import LoadingMessage from "../../ReusableComponents/LoadingMessage/LoadingMessage";
import ApiError from "../../../api/common/apiError";
import Button from "../../Button";
import { type EditCompanyUserFormValues } from "../EditCompanyUserFormValues";
import { type ApiCompanyUserResponse } from "../../../models/api/admin/companyUser";
import { Warning } from "phosphor-react";
import { capitalisedTermForCompany } from "../../../copy/sharedCopy";
import useEditCompanyUserForm from "./useEditCompanyUserForm";
import {
    companyUserRoles,
    yesNoOptions,
} from "../../../constants/dropdownOptions";
import useAuth from "../../../auth";
import { UserStatus } from "../../../models/shared/statuses";
import { canControlClaims } from "../../../auth/userAccessHelpers";
import { useEffect, useState } from "react";
import { UserRoles } from "../../../auth/decodeToken";
import { adminsCanViewPiiMessage } from "../../../constants/dvlaConstants";
import FormDropdownMultiSelect from "../../FormDropdownMultiSelect";

type EditCompanyUserFormProps = {
    isLoading: boolean;
    onSubmit: (value: EditCompanyUserFormValues, callback?: () => void) => void;
    onCancel: () => void;
    isLoadingDepartments: boolean;
    departmentsError?: ApiError;
    departmentOptions?: {
        label: string;
        value: string;
    }[];
    selectedUser: ApiCompanyUserResponse;
    onDelete: () => void;
    onResetPassword: () => void;
    onDisable: () => void;
    companyName?: string;
};

const EditCompanyUserForm: React.FC<EditCompanyUserFormProps> = ({
    isLoading,
    onSubmit,
    onCancel,
    isLoadingDepartments,
    departmentsError,
    departmentOptions,
    selectedUser,
    onDelete,
    onResetPassword,
    onDisable,
    companyName,
}) => {
    const { methods, handleFormSubmit } = useEditCompanyUserForm(
        selectedUser,
        onSubmit
    );
    const { handleSubmit, register, formState, control, setValue } = methods;

    const editedUserRole = useWatch({ control, name: "roleName" });
    const userIsAdmin = editedUserRole === UserRoles.clientAdmin;

    const departmentDropdownOptions = departmentOptions?.map((department) => ({
        label: department.label,
        value: department.value,
    }));

    const [departmentDropdownValues, setDepartmentDropdownValues] = useState<
        string[]
    >([]);

    useEffect(() => {
        // Initialise departmentDropdownValues from selectedUser
        const initialDepartmentIds =
            selectedUser.departments?.map((department) => department.id) ?? [];
        setDepartmentDropdownValues(initialDepartmentIds);
    }, [selectedUser.departments]);

    const handleChange = (selectedValues: string[]) => {
        setDepartmentDropdownValues(selectedValues);
        setValue("departmentIds", selectedValues);
    };

    useEffect(() => {
        if (userIsAdmin) setValue("claims.view-pii", true);
    }, [editedUserRole]);

    const { decodedToken } = useAuth();

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="mx-auto max-h-[400px] w-full max-w-3xl overflow-y-scroll p-6">
                <FormTextInput
                    label={`${capitalisedTermForCompany}`}
                    disabled
                    placeholder={companyName ?? ""}
                />
                {isLoadingDepartments ? (
                    <LoadingMessage />
                ) : (
                    <>
                        {departmentsError && (
                            <div className="flex flex-row text-S2D-error-40">
                                <Warning
                                    weight="fill"
                                    size={32}
                                    className="text-S2D-error-40"
                                />
                                <p>error loading departments</p>
                            </div>
                        )}
                        <FormDropdownMultiSelect
                            label="Departments"
                            options={departmentDropdownOptions ?? []}
                            pleaseSelectText="Select departments"
                            value={departmentDropdownValues}
                            onChange={(value) => handleChange(value)}
                        />
                    </>
                )}
                <Controller
                    name="roleName"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Permission"
                            options={companyUserRoles}
                            value={value ?? ""}
                            onChange={onChange}
                            pleaseSelectText="Select permission"
                            error={formState.errors.roleName}
                        />
                    )}
                />
                {canControlClaims(decodedToken) && (
                    <>
                        <Controller
                            name="claims.file-safe"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormDropdown
                                    label="Has access to FileSafe?"
                                    options={yesNoOptions}
                                    value={value}
                                    onChange={onChange}
                                    pleaseSelectText="Please select..."
                                    error={
                                        formState.errors.claims?.["file-safe"]
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="claims.view-pii"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormDropdown
                                    label="Can view personally identifiable information?"
                                    options={yesNoOptions}
                                    value={value}
                                    onChange={onChange}
                                    pleaseSelectText="Please select..."
                                    error={
                                        formState.errors.claims?.["view-pii"]
                                    }
                                    disabled={userIsAdmin}
                                    helpText={
                                        userIsAdmin
                                            ? adminsCanViewPiiMessage
                                            : ""
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="claims.client-management"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormDropdown
                                    label="Can manage client information?"
                                    options={yesNoOptions}
                                    value={value}
                                    onChange={onChange}
                                    pleaseSelectText="Please select..."
                                    error={
                                        formState.errors.claims?.[
                                            "client-management"
                                        ]
                                    }
                                />
                            )}
                        />
                    </>
                )}
                <Controller
                    name="shouldReceiveEmailNotifications"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FormDropdown
                            label="Receives notifications?"
                            options={yesNoOptions}
                            value={value}
                            onChange={onChange}
                            pleaseSelectText="Please select..."
                            error={
                                formState.errors.shouldReceiveEmailNotifications
                            }
                        />
                    )}
                />
                <FormTextInput
                    label="First Name"
                    required
                    id="firstName"
                    placeholder="Enter first name"
                    error={formState.errors.firstName}
                    {...register("firstName")}
                />
                <FormTextInput
                    label="Surname"
                    required
                    id="lastName"
                    placeholder="Enter surname"
                    error={formState.errors.lastName}
                    {...register("lastName")}
                />
                <FormTextInput
                    label="Email Address"
                    required
                    id="emailAddress"
                    placeholder="Enter email address"
                    error={formState.errors.emailAddress}
                    {...register("emailAddress")}
                />
            </div>
            <div className="flex flex-col-reverse justify-between rounded-b-sm border-t border-S2D-neutral-80 bg-S2D-neutral-90 py-3 px-6 md:flex-row">
                <Button
                    variant="tertiary"
                    type="button"
                    onClick={onCancel}
                    className="w-full justify-center md:w-auto"
                >
                    Cancel
                </Button>
                <div className="flex flex-col-reverse gap-4 md:flex-row">
                    <div className="flex grow flex-col-reverse gap-4 md:flex-row">
                        <Button
                            variant="tertiary"
                            type="button"
                            className="w-full justify-center text-S2D-error-40 md:w-auto"
                            onClick={onDelete}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="tertiary"
                            type="button"
                            className="w-full justify-center md:w-auto"
                            onClick={onDisable}
                        >
                            {selectedUser.status.toLowerCase() ===
                            UserStatus.Disabled.toLowerCase()
                                ? "Enable"
                                : "Disable"}
                        </Button>
                    </div>
                    <div className="flex grow flex-col-reverse gap-4 md:flex-row">
                        <Button
                            variant="secondary"
                            type="button"
                            className="w-full justify-center md:w-auto"
                            onClick={onResetPassword}
                        >
                            Reset Password
                        </Button>
                        <Button
                            type="submit"
                            loading={isLoading}
                            className="w-full justify-center md:w-auto"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EditCompanyUserForm;
