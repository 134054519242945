import { Download } from "phosphor-react";
import { type ApiFileSafeItem } from "../../models/api/admin/fileSafe";
import { useGetTransferDetails } from "../../api/admin/fileSafe";
import { useState } from "react";
import clsx from "clsx";
import { errorToast } from "../../toast";
import { downloadFromArrayBuffer } from "../../utils/downloadFromArrayBuffer";

export type AttachedFilesProps = {
    data: ApiFileSafeItem;
};

const AttachedFiles: React.FC<AttachedFilesProps> = ({ data }) => {
    const {
        getSingleTransferredFile,
        // isGettingSingleTransferredFile
    } = useGetTransferDetails(data.fileTransferId);
    const [downloading, setDownloading] = useState<boolean>(false);

    const handleSingleFileDownload = async (
        fileId: string,
        fileName: string
    ) => {
        setDownloading(true);

        const response = await getSingleTransferredFile(
            data?.fileTransferId,
            fileId
        );

        if (!response.success) {
            errorToast(`Failed to fetch ${fileName}.`);
            return;
        }

        downloadFromArrayBuffer(response.content, `FileSafeTransfer${fileId}`);
        setDownloading(false);
    };

    return (
        <div>
            <div className="flex h-16 w-full items-center rounded-t-sm bg-S2D-neutral-30 px-6 text-S2D-neutral-100">
                <h2 className=" text-base">Attached files</h2>
            </div>
            <div className="flex flex-col divide-y-[1px] divide-S2D-neutral-80 rounded-b-sm border border-t-0 border-S2D-neutral-80 bg-white text-S2D-neutral-10">
                {data?.files.map((fileObj) => (
                    <div
                        className="flex justify-between px-5 py-3"
                        key={fileObj.file.fileName}
                    >
                        <div className="flex flex-col">
                            {fileObj.file.fileName}
                        </div>
                        <button
                            onClick={() =>
                                handleSingleFileDownload(
                                    fileObj.file.fileId,
                                    fileObj.file.fileName
                                )
                            }
                            disabled={downloading}
                            className={clsx(downloading && "opacity-40")}
                        >
                            <Download size={28} weight="fill" />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AttachedFiles;
