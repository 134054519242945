import { createContext } from "react";
import { EditCompanyFormValues } from "../../EditCompanyForms/EditCompanyFormValues";
import EditAccountInfoForm from "../../EditCompanyForms/CompanySubForms/EditAccountInfoForm/EditAccountInfoForm";
import EditCompanyLogoForm from "../../EditCompanyForms/CompanySubForms/EditCompanyLogoForm/EditCompanyLogoForm";
import EditCompanyDetailsForm from "../../EditCompanyForms/CompanySubForms/EditCompanyDetailsForm";
import EditCompanyContactsForm from "./EditCompanyContactsForm/EditCompanyContactsForm";
import EditCompanyCommunicationSettingsForm from "../../EditCompanyForms/CompanySubForms/EditCompanyCommunicationSettingsForm";
import { useGetCompanies } from "../../../api/admin/company";
import useAuth from "../../../auth";
import { hasClientManagementClaim } from "../../../auth/userAccessHelpers";

export type EditCompanyFormsProps = {
    initialValues: EditCompanyFormValues;
    isEditingCompany: boolean;
    handleEditCompany: (
        data: EditCompanyFormValues,
        resetForm: () => void
    ) => Promise<void>;
    refetchCompany: () => void;
};

export const IsEditingContext = createContext(false);

const CompanyAccountTab: React.FC<EditCompanyFormsProps> = ({
    initialValues,
    handleEditCompany,
    isEditingCompany,
    refetchCompany,
}) => {
    const { companyList } = useGetCompanies(1, 100);
    const { decodedToken } = useAuth();
    const canEdit = hasClientManagementClaim(decodedToken);

    return (
        <IsEditingContext.Provider value={isEditingCompany}>
            <EditAccountInfoForm
                initialValues={initialValues}
                handleEditCompany={handleEditCompany}
            />
            <EditCompanyDetailsForm
                initialValues={initialValues}
                handleEditCompany={handleEditCompany}
                companies={companyList}
                canEdit={canEdit}
            />
            <EditCompanyLogoForm
                initialValues={initialValues}
                refetchCompany={refetchCompany}
                canEdit={canEdit}
            />
            <EditCompanyContactsForm
                handleEditCompany={handleEditCompany}
                isEditingCompany={isEditingCompany}
                contacts={initialValues.settings?.contactSettings?.contacts}
                refetchCompany={refetchCompany}
                canEdit={canEdit}
            />
            <EditCompanyCommunicationSettingsForm
                commsSettings={initialValues.settings?.correspondence}
                handleEditCompany={handleEditCompany}
                canEdit={canEdit}
            />
        </IsEditingContext.Provider>
    );
};

export default CompanyAccountTab;
