import React, { useMemo } from "react";
import CollapsibleSection from "../ReusableComponents/CollapsibleSection";
import { capitalisedTermForCompany } from "../../copy/sharedCopy";
import useLocalStorage from "../../hooks/useLocalStorage";
import AnalysisComponent, {
    AnalysisComponentContentType,
} from "../ReusableComponents/AnalysisComponent";
import {
    CompanyStatsCategories,
    CompanyStatsTabs,
} from "../../models/api/admin/companyStats";
import { companyDashboardContents } from "./companyDashboardContent";
import { ApiCompanyResponse } from "../../models/api/admin/company";
import { ApiDepartmentItem } from "../../models/api/admin/department";
import { DriversListFiltersFormValues } from "../DriverListFilters/DriversListFiltersForm";
import {
    useGetAllCompanyStats,
    useGetCompanyStats,
} from "../../api/admin/companyStats";
import useAuth from "../../auth";
import { isS2dAdminOrMember } from "../../auth/userAccessHelpers";

export type CompanyDashboardAllDriversProps = {
    selectedCompany: ApiCompanyResponse | null;
    selectedDepartment: ApiDepartmentItem | null;
    tableRef?: React.MutableRefObject<HTMLDivElement | null>;
    handleFetchDrivers: (
        filters?: DriversListFiltersFormValues | undefined,
        filterId?: string | undefined
    ) => Promise<boolean>;
};

export default function CompanyDashboardAllDrivers({
    selectedCompany,
    selectedDepartment,
    tableRef,
    handleFetchDrivers,
}: CompanyDashboardAllDriversProps) {
    const [isClientDashboardOpen, setIsClientDashboardOpen] =
        useLocalStorage<boolean>(`isAllDriversClientDashboardOpen`, true);

    const [singleCompanyTabIndex, setSingleCompanyTabIndex] =
        useLocalStorage<number>(`allDriversClientDashboardTabIndex`, 0);
    const [allCompaniesTabIndex, setAllCompaniesTabIndex] =
        useLocalStorage<number>(`allDriversAllClientsDashboardTabIndex`, 0);

    const handleTabSelect = (value: CompanyStatsTabs) => {
        const indexOfCategory = Object.values(CompanyStatsTabs).indexOf(
            value as unknown as CompanyStatsTabs
        );
        setAllCompaniesTabIndex(indexOfCategory);
        // the single company dashboard has no "summary" tab so shift index to match content array
        setSingleCompanyTabIndex(indexOfCategory - 1);
    };

    const tabOptions = Object.values(CompanyStatsCategories);
    // to get the correct category, have to add back the 1 removed above:
    const singleCompanyStatsCategory = useMemo(
        () => tabOptions[singleCompanyTabIndex + 1],
        [singleCompanyTabIndex]
    );
    const allCompaniesStatsCategory = useMemo(
        () => tabOptions[allCompaniesTabIndex],
        [allCompaniesTabIndex]
    );

    // Fetching the stats within this component as its not always needed on the All Drivers page.
    const { companyStatsData } = useGetCompanyStats(
        selectedCompany?.companyId,
        selectedDepartment?.departmentId,
        undefined,
        undefined,
        singleCompanyStatsCategory === CompanyStatsCategories.Company
            ? CompanyStatsCategories.Drivers
            : singleCompanyStatsCategory
    );
    // Fetching all company stats if no company is selected
    const shouldDisableAllStatsCall = !!selectedCompany;
    const {
        allCompanyStatsData,
        allCompanyStatsError,
        isAllCompanyStatsLoading,
    } = useGetAllCompanyStats(
        undefined,
        undefined,
        allCompaniesStatsCategory,
        shouldDisableAllStatsCall
    );

    const { decodedToken } = useAuth();
    const shouldRemoveSummary = !isS2dAdminOrMember(decodedToken);

    const allCompaniesStatsContent: AnalysisComponentContentType<CompanyStatsTabs>[] =
        companyDashboardContents(
            allCompanyStatsData,
            undefined,
            tableRef,
            shouldRemoveSummary,
            "driversPageContent",
            handleFetchDrivers
        );

    const singleCompanyStatsContent: AnalysisComponentContentType<CompanyStatsTabs>[] =
        companyDashboardContents(
            companyStatsData,
            undefined,
            tableRef,
            true,
            "driversPageContent",
            handleFetchDrivers
        );

    return (
        <CollapsibleSection
            title={`${capitalisedTermForCompany} dashboard`}
            isOpen={isClientDashboardOpen}
            setIsOpen={setIsClientDashboardOpen}
            content={
                <>
                    {!allCompanyStatsError && !selectedCompany && (
                        <AnalysisComponent
                            analysisContent={allCompaniesStatsContent}
                            selectedTab={allCompaniesTabIndex}
                            onSelect={(value) => handleTabSelect(value)}
                        />
                    )}
                    {selectedCompany && (
                        <AnalysisComponent
                            analysisContent={singleCompanyStatsContent}
                            selectedTab={singleCompanyTabIndex}
                            onSelect={handleTabSelect}
                        />
                    )}

                    {allCompanyStatsError && !isAllCompanyStatsLoading && (
                        <p className="flex justify-start">
                            Error fetching {capitalisedTermForCompany} stats
                        </p>
                    )}
                </>
            }
        />
    );
}
