import { SetStateAction, useEffect } from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { useFormFieldCollectiveValidation } from "../../hooks/useFormFieldCollectiveValidation";
import { EmailRequest } from "../../pages/Admin/AdminAddDriver";
import AccordionItem from "../AccordionItem";
import FormDropdown from "../FormDropdown";
import { AddDriverFormValues } from "./AddDriverFormValues";

import { driverTypeOptions } from "../../constants/dvlaConstants";

type DriverDriverTypeFieldsProps = {
    methods: UseFormReturn<AddDriverFormValues>;
    emailRequest: EmailRequest;
    setEmailRequest: (value: SetStateAction<EmailRequest>) => void;
};

const DriverDriverTypeFields: React.FC<DriverDriverTypeFieldsProps> = ({
    methods,
    emailRequest,
    setEmailRequest,
}) => {
    const isSectionValid =
        useFormFieldCollectiveValidation<AddDriverFormValues>(
            ["driverType"],
            methods
        );

    const control = methods.control;
    const driverTypeValue = useWatch({ control, name: "driverType" });

    //set grey fleet email to false if no longer grey fleet
    useEffect(() => {
        if (driverTypeValue === "GreyFleet") return;
        setEmailRequest({
            eDeclaration: emailRequest.eDeclaration,
            greyFleet: false,
        });
    }, [driverTypeValue]);

    return (
        <AccordionItem title="Add driver type" error={!isSectionValid}>
            <div className="flex flex-col gap-4">
                <Controller
                    name="driverType"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                        <FormDropdown
                            label="Driver type"
                            options={driverTypeOptions}
                            value={value}
                            onChange={onChange}
                            pleaseSelectText="Select driver type"
                        />
                    )}
                />
            </div>
        </AccordionItem>
    );
};

export default DriverDriverTypeFields;
