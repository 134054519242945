import { useReseller } from "../../api/admin/reseller";
import { AddResellerFormValues } from "../../components/AddResellerForm/AddResellerFormValues";
import { successToast } from "../../toast";
import AddResellerForm from "../../components/AddResellerForm/AddResellerForm";
import PageLayout from "../../components/PageLayout";

const AdminAddReseller: React.FC = () => {
    const { addReseller, isAddingReseller } = useReseller();

    const handleAddReseller = async (
        data: AddResellerFormValues,
        callback: (error: boolean) => void
    ) => {
        const response = await addReseller(data);

        if (response.success) {
            successToast("Reseller has been added");
            callback(false);
        }
    };

    return (
        <PageLayout title="Add New Reseller">
            <div className="w-full">
                <AddResellerForm
                    onSubmit={handleAddReseller}
                    isLoading={isAddingReseller}
                />
            </div>
        </PageLayout>
    );
};

export default AdminAddReseller;
